import React, { Fragment } from 'react';

import { Typography, Collapse, Hidden } from '@mui/material';

import { Subsection, List, ButtonMore } from 'components';
import { useMdUp } from 'hooks';

const features = [
  'Provide quick response and are accessible on our 24/7 breakdown assistance hotline',
  'Manage the diagnosis and repair of mechanical breakdown events that occur when your aircraft is away from its home base',
  'Determine whether or not your aircraft is safe to fly',
  'Assess whether or not you are comfortable flying your aircraft in its condition',
  'Determine which repairs and/or further diagnostics are necessary prior to further flight',
];

const fees = ['Piston single: $99/year', 'Piston twin: $149/year', 'Turboprop single: $199/year'];

const SavvyBreakdownDescription: React.FC = () => {
  const [open, setOpen] = React.useState(false);
  const isMdUp = useMdUp();

  return (
    <Fragment>
      <Subsection>
        <Typography paragraph>
          There’s nothing more frustrating than experiencing a breakdown away from home. Should you encounter an unfortunate situation, our
          professional A&P/IA experts will guide you every step of the way.
          <br />
          SavvyBreakdown is a Nationwide 24/7 Quick-Response Breakdown Assistance Program. Simply put, it is to GA what “AAA” is to
          automobiles.
        </Typography>
      </Subsection>
      <Collapse in={open || isMdUp}>
        <Subsection>
          <Typography variant="h4" gutterBottom>
            With SavvyBreakdown service, our experienced A&P/IA specialists:
          </Typography>
          <List list={features} />
        </Subsection>
        <Subsection>
          <Typography variant="h4" gutterBottom>
            SavvyBreakdown Service Annual Fees:
          </Typography>
          <List list={fees} />
          <Typography
            variant="body1"
            style={{
              fontStyle: 'italic',
            }}
          >
            *Turboprop twin & Turbojet single not supported
          </Typography>
        </Subsection>
      </Collapse>
      {!isMdUp && (
        <Hidden mdUp>
          <Subsection>
            <ButtonMore open={open} setOpen={setOpen} />
          </Subsection>
        </Hidden>
      )}
    </Fragment>
  );
};

export default SavvyBreakdownDescription;
