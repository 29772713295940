import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Dialog, DialogContent, DialogTitle, Box, Button, Grid } from '@mui/material';
import { Formik, Form } from 'formik';
import { MuiFormikInput, ButtonSubmit } from 'components';

import { useMutation } from '@apollo/client';
import { MUTATION_UPDATE_POST } from 'gql';
import { setAlert } from 'state';
import { useSmDown } from 'hooks';

import * as Yup from 'yup';

interface EditPostDialogProps {
  openDialog: boolean;
  setOpenDialog: (value: boolean) => void;
  postId: string;
  body: string;
}

const editPostSchema = Yup.object().shape({
  body: Yup.string().required('Required'),
});

const EditPostDialog: React.FC<EditPostDialogProps> = (props) => {
  const { openDialog, setOpenDialog, postId, body } = props;

  const [updatePost, { data: dataUpdatePost, loading: loadingUpdatePost, error: errorUpdatePost }] = useMutation(MUTATION_UPDATE_POST, {
    errorPolicy: 'all',
  });

  const dispatch = useDispatch();
  const isSmDown = useSmDown();

  const handleClose = () => {
    setOpenDialog(false);
  };

  const onSubmit = async (values: any) => {
    await updatePost({
      variables: {
        postId,
        body: values.body,
      },
    });
  };

  useEffect(() => {
    if (errorUpdatePost) {
      dispatch(setAlert('error', 'Unable to update post'));
    } else if (dataUpdatePost) {
      if (dataUpdatePost.updatePost?.ok) {
        dispatch(setAlert('success', 'Updated Post'));
        setOpenDialog(false);
      } else {
        dispatch(setAlert('error', dataUpdatePost.updatePost?.error || 'Unable to update post'));
      }
    }
  }, [dataUpdatePost, dispatch, setOpenDialog, errorUpdatePost]);

  return (
    <Dialog
      open={openDialog}
      fullWidth
      disableEscapeKeyDown={true}
      onBackdropClick={() => {
        return false;
      }}
      PaperProps={{
        sx: {
          width: '50vw',
          maxWidth: '50vw',
          '@media (max-width: 768px)': {
            width: 'calc(100% - 16px)',
            mx: 1,
          },
        },
      }}
    >
      <DialogTitle
        sx={{
          textAlign: 'center',
          fontSize: '24px',
          fontWeight: 700,
        }}
      >
        Edit Post
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            body: body ? body : '',
          }}
          onSubmit={onSubmit}
          validationSchema={editPostSchema}
        >
          {({ isSubmitting, handleChange, handleBlur, touched, errors }) => {
            return (
              <Form>
                <Box py={1}>
                  <MuiFormikInput
                    name="body"
                    label="Message"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    errors={errors}
                    touched={touched}
                    multiline={true}
                    rows={isSmDown ? 15 : 10}
                    maxLength={Number.MAX_SAFE_INTEGER}
                  />
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Button variant="outlined" fullWidth onClick={handleClose} disabled={isSubmitting || loadingUpdatePost}>
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <ButtonSubmit text="Save" loading={isSubmitting || loadingUpdatePost} disabled={isSubmitting || loadingUpdatePost} />
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default EditPostDialog;
