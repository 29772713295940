import React from 'react';
import { useHistory } from 'react-router-dom';
import { Dialog, DialogTitle, DialogContent, Typography, Link, Box, List, ListItem } from '@mui/material';
import { PATH_TICKETS_CREATE } from 'const';
import { ctrlCmdClick } from 'helpers';

interface BreakdownPromoDialogProps {
  open: boolean;
  setOpen: (value: boolean) => void;
}

const BreakdownPromoDialog: React.FC<BreakdownPromoDialogProps> = (props) => {
  const { open, setOpen } = props;
  const history = useHistory();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle
        sx={{
          textAlign: 'center',
          fontSize: '24px',
          fontWeight: 700,
        }}
      >
        Breakdown Assistance
      </DialogTitle>
      <DialogContent>
        <Box py={0.5}>
          <Typography>Congratulations! You are eligible for our Breakdown Assistance service.</Typography>
        </Box>
        <Box py={0.5}>
          <Typography>If you are broken down more than 50 miles away from your home base, you can either:</Typography>
        </Box>
        <Box py={0.5}>
          <List
            sx={{
              py: 0,
            }}
          >
            <ListItem
              sx={{
                display: 'list-item',
                listStyle: 'disc',
                left: '20px',
                cursor: 'pointer',
                px: 1,
                py: 0.5,
              }}
            >
              <Link
                href={PATH_TICKETS_CREATE}
                onClick={(e: any) => {
                  e.preventDefault();
                  e.stopPropagation();
                  ctrlCmdClick(e, PATH_TICKETS_CREATE, history);
                }}
              >
                Create a ticket...
              </Link>
            </ListItem>
            <ListItem
              sx={{
                display: 'list-item',
                listStyle: 'disc',
                left: '20px',
                cursor: 'pointer',
                px: 1,
                py: 0.5,
              }}
            >
              <Typography>
                Call our 24/7 hotline: <Link href="tel:+1-888-588-6655">1-888-588-6655</Link>
              </Typography>
            </ListItem>
          </List>
        </Box>
        <Box py={0.5}>
          <Typography gutterBottom>A Savvy maintenance expert will contact you within 15 minutes.</Typography>
          <Typography>
            Please note: <b>Do not use either of the links above for issues relating to scheduled maintenance.</b> We will not be able to
            help you. Instead, please create a{' '}
            <Link
              href={PATH_TICKETS_CREATE}
              onClick={(e: any) => {
                e.preventDefault();
                e.stopPropagation();
                ctrlCmdClick(e, PATH_TICKETS_CREATE, history);
              }}
            >
              new ticket here
            </Link>
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default BreakdownPromoDialog;
