import React, { useEffect, useState } from 'react';
import { Box, Typography, Container, Slide } from '@mui/material';
import { LayoutDashboard, PageLoading } from 'components';
import { QUERY_ME_TECHNICIAN_DETAIL } from 'gql';
import { useQuery } from '@apollo/client';
import { IContactType, IClassification } from 'types';
import { MainInfo, Classifications } from './components';

const UpdateTechnician: React.FC = () => {
  const { data: dataTechnician, loading: loadingTechnician } = useQuery(QUERY_ME_TECHNICIAN_DETAIL);

  const [userData, setUserData] = useState<IContactType>();
  const [selectedClassifications, setSelectedClassifications] = useState<string[]>([]);

  const [slideNum, setSlideNum] = useState(0);
  const [clickedBack, setClickedBack] = useState(false);

  useEffect(() => {
    if (dataTechnician?.me) {
      setUserData(dataTechnician?.me);
      setSelectedClassifications([...dataTechnician.me.classifications.map((classification: IClassification) => classification.id)]);
    }
  }, [dataTechnician]);

  return (
    <LayoutDashboard backgroundColor="#fff">
      {loadingTechnician && <PageLoading />}
      <Box p={5}>
        <Container maxWidth="lg" fixed>
          {slideNum === 0 && (
            <Typography
              variant="h3"
              style={{
                textAlign: 'center',
              }}
              gutterBottom
            >
              Please update your information
            </Typography>
          )}
          {slideNum === 1 && (
            <Typography
              variant="h3"
              style={{
                textAlign: 'center',
              }}
              gutterBottom
            >
              Update Ratings & Capabilities
            </Typography>
          )}
        </Container>
        <Container maxWidth="lg" fixed>
          {slideNum === 0 && (
            <Slide in={slideNum === 0} direction={clickedBack ? 'right' : 'left'} unmountOnExit>
              <Box>{userData && <MainInfo userData={userData} setSlideNum={setSlideNum} setUserData={setUserData} />}</Box>
            </Slide>
          )}
          {slideNum === 1 && (
            <Slide in={slideNum === 1} direction={clickedBack ? 'right' : 'left'} unmountOnExit>
              <Box>
                {userData && (
                  <Classifications
                    userData={userData}
                    setSlideNum={setSlideNum}
                    setClickedBack={setClickedBack}
                    selectedClassifications={selectedClassifications}
                    setSelectedClassifications={setSelectedClassifications}
                  />
                )}
              </Box>
            </Slide>
          )}
          {slideNum === 2 && (
            <Slide in={slideNum === 2} direction={clickedBack ? 'right' : 'left'} unmountOnExit>
              <Box p={5}>
                <Typography
                  variant="h2"
                  sx={{
                    color: 'success.main',
                  }}
                  align="center"
                  gutterBottom
                >
                  Success
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Box>
                    <Box py={1}>
                      <Typography align="center">Thank you for updating your information.</Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Slide>
          )}
        </Container>
      </Box>
    </LayoutDashboard>
  );
};

export default UpdateTechnician;
