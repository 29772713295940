import React, { useState, useEffect } from 'react';

import { Dialog, DialogContent, DialogActions, Typography, Button, Grid, CircularProgress } from '@mui/material';
import { AircraftSuggestions } from 'pages/SignupService/components/DialogAddAircraft/components';

import { useFuzzyManufacturers } from 'hooks';

import { IOptionIdName } from 'types';

import { useInputWithSuggestions } from 'hooks';

import * as Yup from 'yup';
import { ButtonSubmit } from 'components';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-mui';
import { MUTATION_CREATE_AIRCRAFT_MANUFACTURER } from 'gql';
import { useMutation } from '@apollo/client';

interface IDialogAddAircraftSuggestions {
  manufacturer: IOptionIdName; // Autocomplete value
  setManufacturer: (value: IOptionIdName | null) => void; // Set Autocomplete value
  open: boolean;
  setOpen: (value: boolean) => void;
}

const validationSchema = Yup.object({
  manufacturer: Yup.string()
    .min(1, 'Too short: 1-50 characters required')
    .max(50, 'Too long: 1-50 characters required')
    .required('Required'),
});

interface createFormValues {
  manufacturer: string;
}

const DialogAddAircraftManufacturer: React.FC<IDialogAddAircraftSuggestions> = (props) => {
  const { manufacturer, setManufacturer, open, setOpen } = props;

  const [addState, setAddState] = useState(false);

  // Monitor Manufacturer and Model Inputs Logic Start
  // -----------------------------------------
  const [manufacturerInput, setManufacturerInput] = useState('');

  const [manufacturerInputTmp, setManufacturerInputTmp] = useState('');

  const [createManufacturer, { data: dataCreateManufacturer, error: errorCreateManufacturer, loading: loadingCreateManufacturer }] =
    useMutation(MUTATION_CREATE_AIRCRAFT_MANUFACTURER);

  const { showFuzzy: showFuzzyManufacturers } = useInputWithSuggestions({ input: manufacturerInput }); //  Manufacturer Input id/name with Suggestions

  const handleSuggestionClickManufacturerHelper = (suggestion: IOptionIdName) => {
    setManufacturer(suggestion);
    handleClose();
  };

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (manufacturer?.id) return;
    setManufacturerInput(manufacturer?.name ? manufacturer.name : '');
  }, [manufacturer, setManufacturerInput]);

  useEffect(() => {
    if (!manufacturerInputTmp) {
      setManufacturerInput('');
      setLoading(false);
      return;
    }

    setLoading(true);

    const timeout: any = setTimeout(() => {
      setManufacturerInput(manufacturerInputTmp);
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [manufacturerInputTmp, setManufacturerInput]);

  // Fuzzy Logic
  const fuzzyManufacturers = useFuzzyManufacturers({ manufacturer, manufacturerInput }); // Fuzzy Manufacturers

  React.useEffect(() => {
    setLoading(false);
    if (!fuzzyManufacturers.length) setAddState(true);
    else setAddState(false);
  }, [fuzzyManufacturers]);

  // Close (Cancel click)
  const handleClose = () => {
    setOpen(false);
    setAddState(false);
    setManufacturerInput('');
  };

  const onSubmit = async (values: createFormValues) => {
    await createManufacturer({
      variables: {
        name: values.manufacturer,
      },
    });
  };

  const [responseError, setResponseError] = useState<any>({});

  React.useEffect(() => {
    if (errorCreateManufacturer) {
      setResponseError({
        manufacturer: 'Failed to create engine manufacturer, please reload page and retry.',
      });
    } else if (dataCreateManufacturer) {
      const { ok, aircraftManufacturer: newManufacturer, error } = dataCreateManufacturer.createAircraftManufacturer;

      if (ok) {
        setManufacturer(newManufacturer);
        setManufacturerInput('');
        setOpen(false);
      } else {
        setResponseError({
          engineManufacturer: error || 'Failed to create engine manufacturer, please reload page and retry.',
        });
      }
    }
  }, [
    createManufacturer,
    dataCreateManufacturer,
    errorCreateManufacturer,
    setResponseError,
    setManufacturer,
    setManufacturerInput,
    setOpen,
  ]);

  const notListClick = () => {
    setAddState(true);
  };

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={handleClose}
      PaperProps={{
        style: {
          height: '550px',
        },
      }}
    >
      <Formik
        initialValues={{
          manufacturer: manufacturer?.name,
        }}
        onSubmit={onSubmit}
        keepMounted
        validationSchema={validationSchema}
      >
        {({ isSubmitting, handleChange, handleBlur }) => {
          return (
            <Form
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <DialogContent>
                <Typography variant="h2" gutterBottom align="center">
                  Add custom manufacturer
                </Typography>
                <Field
                  name="manufacturer"
                  label="Manufacturer *"
                  onBlur={handleBlur}
                  onChange={(e: any) => {
                    handleChange(e);
                    setManufacturerInputTmp(e.target.value);
                  }}
                  placeholder="Start typing to search..."
                  component={TextField}
                  helperText={responseError && responseError.manufacturer}
                  fullWidth
                  variant="outlined"
                  FormHelperTextProps={{
                    style: {
                      color: 'red',
                      marginLeft: 0,
                    },
                  }}
                  autoFocus
                  inputProps={{ maxLength: 50 }}
                />
                {loading && <CircularProgress color="inherit" size={20} variant="indeterminate" />}
                {!loading && !addState && showFuzzyManufacturers && (
                  <AircraftSuggestions
                    label="Did you perhaps mean:"
                    suggestions={fuzzyManufacturers}
                    handleSuggestionClick={handleSuggestionClickManufacturerHelper}
                    notListLabel="My aircraft manufacturer is not listed"
                    notListClick={notListClick}
                  />
                )}
              </DialogContent>

              <DialogActions>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Button color="secondary" variant="outlined" onClick={handleClose} fullWidth>
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <ButtonSubmit text="Add" disabled={!addState} loading={isSubmitting || loadingCreateManufacturer} />
                  </Grid>
                </Grid>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default DialogAddAircraftManufacturer;
