import React, { useState, useEffect } from 'react';
import { Dialog, Box, DialogContent, DialogTitle, Button, MenuItem, TextField, Typography } from '@mui/material';

import { QUERY_GET_USER_AIRCRAFTS } from 'gql';
import { useLazyQuery } from '@apollo/client';

import { IAircraftDetail } from 'types';

import { aircraftRegistrationMakeModel } from 'helpers';
import { ComponentLoading } from 'components';

interface MergeAircraftDialogProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  sourceAircraftId: string;
  setSourceAircraftId: (value: string) => void;
  onSubmitAction: () => void;
  destinationAircraftId: string;
  contactId: string;
}

const MergeAircraftDialog: React.FC<MergeAircraftDialogProps> = (props) => {
  const { open, setOpen, sourceAircraftId, setSourceAircraftId, onSubmitAction, destinationAircraftId, contactId } = props;

  const [fetchAircraft, { data: dataAircraft, loading: loadingAircraft }] = useLazyQuery(QUERY_GET_USER_AIRCRAFTS, {
    fetchPolicy: 'cache-and-network',
  });

  const [aircraftError, setAircraftError] = useState('');

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = () => {
    if (!sourceAircraftId) {
      setAircraftError('Please select aircraft to merge');
      return;
    }

    setOpen(false);
    onSubmitAction();
  };

  useEffect(() => {
    if (open && contactId) {
      fetchAircraft({
        variables: {
          contactId,
        },
      });
    }
  }, [open, contactId, fetchAircraft]);

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={handleClose}
      PaperProps={{
        sx: {
          maxWidth: '400px',
        },
      }}
    >
      <DialogTitle
        sx={{
          textAlign: 'center',
          fontSize: '24px',
          fontWeight: 700,
        }}
      >
        Merge Aircraft
      </DialogTitle>
      <DialogContent>
        <ComponentLoading loading={loadingAircraft}>
          <Box>
            <Box py={1}>
              {!!dataAircraft?.savvy?.contacts[0]?.aircraft?.length && dataAircraft.savvy.contacts[0].aircraft.length > 1 && (
                <TextField
                  name="sourceAircraft"
                  label="Source Aircraft *"
                  select
                  value={sourceAircraftId}
                  onChange={(e) => {
                    setSourceAircraftId(e.target.value);
                    setAircraftError('');
                  }}
                  sx={{
                    mb: 0,
                  }}
                  fullWidth
                  error={!!aircraftError}
                  helperText={aircraftError}
                  FormHelperTextProps={{
                    sx: {
                      ml: 0,
                    },
                  }}
                >
                  {dataAircraft?.savvy?.contacts[0]?.aircraft.map((item: IAircraftDetail, key: number) => {
                    if (item.id === destinationAircraftId) {
                      return null;
                    }
                    return (
                      <MenuItem
                        value={item.id}
                        key={key}
                        sx={{
                          textDecoration: item.hidden ? 'line-through' : 'none',
                          '&:hover': {
                            textDecoration: item.hidden ? 'line-through' : 'none',
                          },
                        }}
                      >
                        {aircraftRegistrationMakeModel(item)}
                      </MenuItem>
                    );
                  })}
                </TextField>
              )}
              {(!dataAircraft?.savvy?.contacts[0]?.aircraft?.length || dataAircraft.savvy.contacts[0].aircraft.length <= 1) && (
                <Typography
                  sx={{
                    color: 'error.main',
                  }}
                  align="center"
                >
                  This owner doesn't have aircraft to merge.
                </Typography>
              )}
            </Box>
            <Box
              sx={{
                textAlign: 'center',
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  width: '100px',
                }}
                onClick={handleClose}
              >
                Cancel
              </Button>
              {!!dataAircraft?.savvy?.contacts[0]?.aircraft?.length && dataAircraft.savvy.contacts[0].aircraft.length > 1 && (
                <Button
                  color="primary"
                  variant="contained"
                  sx={{
                    width: '100px',
                    ml: 2,
                  }}
                  onClick={() => {
                    onSubmit();
                  }}
                >
                  Merge
                </Button>
              )}
            </Box>
          </Box>
        </ComponentLoading>
      </DialogContent>
    </Dialog>
  );
};

export default MergeAircraftDialog;
