import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid, Button, Box, Checkbox, Typography } from '@mui/material';
import { DataGridPro, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { useDispatch } from 'react-redux';

import { ComponentLoading } from 'components';

import { QUERY_SAVVY_AIRCRAFT_LOGBOOKS, MUTATION_CREATE_SHARE_MAINTENANCE_LOGS_TAG, QUERY_SAVVY_TICKET_SERVICE_CENTER } from 'gql';
import { useLazyQuery, useMutation } from '@apollo/client';

import { setAlert } from 'state';

interface ShareLogsDialogProps {
  aircraftId: string;
  ticketId: string;
  open: boolean;
  setOpen: (value: boolean) => void;
  previousLogIds: string[];
}

const ShareLogsDialog: React.FC<ShareLogsDialogProps> = (props) => {
  const { open, aircraftId, ticketId, setOpen, previousLogIds } = props;

  const [logbookIds, setLogbookIds] = useState<string[]>([]);

  const [fetchLogs, { data: dataLogs, error: errorLogs, loading: loadingLogs }] = useLazyQuery(QUERY_SAVVY_AIRCRAFT_LOGBOOKS, {
    fetchPolicy: 'cache-and-network',
  });
  const [fetchServiceCenter, { data: dataServiceCenter, error: errorServiceCenter, loading: loadingServiceCenter }] = useLazyQuery(
    QUERY_SAVVY_TICKET_SERVICE_CENTER,
    {
      fetchPolicy: 'cache-and-network',
    },
  );
  const [createShareMaintenanceLogsTag, { data: dataShareLog, error: errorShareLog, loading: loadingShareLog }] = useMutation(
    MUTATION_CREATE_SHARE_MAINTENANCE_LOGS_TAG,
  );
  const dispatch = useDispatch();

  const [logbookError, setLogbookError] = useState('');

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async () => {
    if (!logbookIds.length) {
      setLogbookError('Please select logbooks');
      return;
    }

    await createShareMaintenanceLogsTag({
      variables: {
        aircraftId: parseInt(aircraftId),
        logbookIds,
        ticketId,
      },
    });
  };

  useEffect(() => {
    if (open && aircraftId) {
      fetchLogs({
        variables: {
          aircraftId,
        },
      });
    }
  }, [open, fetchLogs, aircraftId]);

  useEffect(() => {
    if (open && ticketId) {
      fetchServiceCenter({
        variables: {
          ticketId: parseInt(ticketId),
        },
      });
    }
  }, [open, ticketId, fetchServiceCenter]);

  useEffect(() => {
    if (errorLogs) {
      dispatch(setAlert('error', 'Unable to load aircraft logbooks'));
    }
  }, [errorLogs, dispatch]);

  useEffect(() => {
    if (errorServiceCenter) {
      dispatch(setAlert('error', "Unable to load ticket's service center"));
    }
  }, [errorServiceCenter, dispatch]);

  useEffect(() => {
    if (errorShareLog) {
      dispatch(setAlert('error', 'Unable to share logs'));
    } else if (dataShareLog) {
      if (dataShareLog.createShareMaintenanceLogsTag?.ok) {
        dispatch(setAlert('success', 'Shared logs'));
        setOpen(false);
      } else {
        dispatch(setAlert('error', dataShareLog.createShareMaintenanceLogsTag?.error || 'Unable to share logs'));
      }
    }
  }, [errorShareLog, dataShareLog, dispatch, setOpen]);

  useEffect(() => {
    if (open) {
      setLogbookIds([...previousLogIds]);
    }
  }, [open, previousLogIds]);

  return (
    <Dialog open={open} fullWidth onClose={handleClose}>
      <DialogTitle
        sx={{
          textAlign: 'center',
          fontSize: '24px',
          fontWeight: 700,
        }}
      >
        Share Logs
      </DialogTitle>
      <DialogContent
        sx={{
          px: {
            md: 5,
            xs: 2,
          },
          position: 'relative',
        }}
      >
        <ComponentLoading loading={loadingLogs || loadingShareLog || loadingServiceCenter}>
          {dataServiceCenter?.me?.tickets[0]?.serviceCenter && (
            <Box py={1}>
              <Typography align="center">
                Share the following logbooks with{' '}
                <b>{dataServiceCenter?.me?.tickets[0]?.serviceCenter ? dataServiceCenter?.me?.tickets[0]?.serviceCenter.company : ''}</b>{' '}
                while this ticket is open.
              </Typography>
              {!!logbookError && (
                <Typography
                  align="center"
                  sx={{
                    color: 'error.main',
                    mt: 1,
                  }}
                >
                  {logbookError}
                </Typography>
              )}
              <Box py={1}>
                <DataGridPro
                  sx={{
                    width: '100%',
                    minWidth: '100px',
                    '& .MuiDataGrid-columnSeparator': {
                      display: 'none',
                    },
                    '& .MuiDataGrid-cell:focus': {
                      outline: 'none',
                    },
                    '& .MuiDataGrid-row': {
                      cursor: 'pointer',
                      borderBottom: '1px solid',
                      borderColor: 'grey.200',
                    },
                    '& .MuiDataGrid-columnHeader:focus': {
                      outline: 'none',
                    },
                    '& .MuiDataGrid-cellContent': {
                      whiteSpace: 'break-spaces',
                    },
                    border: 'none',
                  }}
                  autoHeight
                  disableColumnResize={true}
                  disableSelectionOnClick={true}
                  rowsPerPageOptions={[5, 10, 25, 100]}
                  pagination={false}
                  showColumnRightBorder={false}
                  disableColumnMenu={true}
                  rows={dataLogs?.savvy?.aircraft?.logbooks || []}
                  columns={[
                    {
                      field: 'name',
                      headerName: 'Name',
                      flex: 1,
                    },
                    {
                      field: 'entriesCount',
                      headerName: 'Entries',
                      flex: 1,
                    },
                    {
                      field: 'id',
                      headerName: '',
                      sortable: false,
                      flex: 0.8,
                      renderCell: (params: GridRenderCellParams<string>) => {
                        if (!params.value) {
                          return null;
                        }
                        return (
                          <Box>
                            <Checkbox
                              checked={logbookIds.includes(params.value)}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                if (!params.value) return;
                                const index = logbookIds.indexOf(params.value || '');
                                if (index < 0) {
                                  logbookIds.push(params.value);
                                  setLogbookIds([...logbookIds]);
                                } else {
                                  logbookIds.splice(index, 1);
                                  setLogbookIds([...logbookIds]);
                                }
                              }}
                            />
                          </Box>
                        );
                      },
                    },
                  ]}
                />
              </Box>
            </Box>
          )}
          {!dataServiceCenter?.me?.tickets[0]?.serviceCenter && (
            <Box py={1}>
              <Typography
                sx={{
                  color: 'error.main',
                  fontWeight: 'bold',
                  fontSize: '20px',
                }}
                align="center"
                gutterBottom
              >
                No Service Center
              </Typography>
              <Typography align="center">
                There is no Service Center watching this ticket. Please add a Service Center watcher first
              </Typography>
            </Box>
          )}
        </ComponentLoading>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button variant="outlined" onClick={handleClose} disabled={loadingShareLog} fullWidth>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              onClick={onSubmit}
              disabled={loadingShareLog || !dataServiceCenter?.me?.tickets[0]?.serviceCenter}
              fullWidth
            >
              Share
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default ShareLogsDialog;
