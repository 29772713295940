import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Typography, Button } from '@mui/material';

import { ComponentLoading } from 'components';

import { MUTATION_COMPLETE_PURCHASE } from 'gql';
import { useMutation } from '@apollo/client';
import { setAlert } from 'state';
import { ISubscriptionDetail } from 'types';

interface CompletePurchaseProps {
  subscription: ISubscriptionDetail;
}

const CompletePurchase: React.FC<CompletePurchaseProps> = (props) => {
  const { subscription } = props;
  const dispatch = useDispatch();

  const [completePurchase, { data: dataPurchase, loading: loadingPurchase, error: errorPurchase }] =
    useMutation(MUTATION_COMPLETE_PURCHASE);

  const handleCompletePurchase = async () => {
    if (!subscription.id) return;

    await completePurchase({
      variables: {
        subscriptionId: subscription.id,
      },
    });
  };

  useEffect(() => {
    if (errorPurchase) {
      dispatch(setAlert('error', 'Unable to complete purchase'));
    } else if (dataPurchase) {
      if (dataPurchase?.completePurchase?.ok) {
        dispatch(setAlert('success', 'Completed purchase'));
      } else {
        dispatch(setAlert('error', dataPurchase?.completePurchase?.error || 'Unable to complete purchase'));
      }
    }
  }, [errorPurchase, dispatch, dataPurchase]);

  return (
    <Box>
      <ComponentLoading loading={loadingPurchase}>
        <Box>
          <Typography
            variant="h3"
            sx={{
              color: 'error.main',
              fontWeight: 'bold',
            }}
          >
            Complete Purchase
          </Typography>
          <Box py={1}>
            <Typography>
              Use this if the client somehow did not finish Step 3 of the signup process and is unable to return to it. The aircraft may be
              missing some information, so you may need to track those down manually. When you press this button the system will generate
              two tickets - one for logbooks, and one for manager assignment.
            </Typography>
          </Box>
          <Box
            py={1}
            sx={{
              textAlign: 'left',
            }}
          >
            <Button variant="contained" onClick={handleCompletePurchase}>
              Complete Purchase
            </Button>
          </Box>
        </Box>
      </ComponentLoading>
    </Box>
  );
};

export default CompletePurchase;
