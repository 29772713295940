import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Dialog, DialogContent, DialogTitle, Box, Button, Grid, TextField, MenuItem } from '@mui/material';
import { Formik, Form } from 'formik';
import { ButtonSubmit, ComponentLoading } from 'components';

import { useMutation, useLazyQuery } from '@apollo/client';
import { MUTATION_MOVE_COPY_POST_TO_TICKET, QUERY_POSSIBLE_DESTINATION_TICKETS } from 'gql';
import { setAlert } from 'state';

interface MoveCopyPostDialogProps {
  openDialog: boolean;
  setOpenDialog: (value: boolean) => void;
  postId: string;
}

const MoveCopyPostDialog: React.FC<MoveCopyPostDialogProps> = (props) => {
  const { openDialog, setOpenDialog, postId } = props;

  const [copyMovePost, { data: dataUpdatePost, loading: loadingUpdatePost, error: errorUpdatePost }] = useMutation(
    MUTATION_MOVE_COPY_POST_TO_TICKET,
    {
      errorPolicy: 'all',
    },
  );
  const [fetchAvailableTickets, { data: dataAvailableTickets, loading: loadingAvailableTickets }] =
    useLazyQuery(QUERY_POSSIBLE_DESTINATION_TICKETS);

  const [copyMove, setCopyMove] = useState('-1');
  const [copyMoveError, setCopyMoveError] = useState('');

  const [targetTicketId, setTargetTicketId] = useState('-1');
  const [targetTicketError, setTargetTicketError] = useState('');

  const dispatch = useDispatch();

  const handleClose = () => {
    setOpenDialog(false);
  };

  const onSubmit = async () => {
    if (copyMove === '-1') {
      setCopyMoveError('Please select action');
      return;
    }

    if (targetTicketId === '-1') {
      setTargetTicketError('Please select target ticket');
      return;
    }

    await copyMovePost({
      variables: {
        postId,
        copyMove,
        targetTicketId,
      },
    });
  };

  useEffect(() => {
    if (openDialog && postId) {
      fetchAvailableTickets({
        variables: {
          postId,
        },
      });
    }
  }, [openDialog, postId, fetchAvailableTickets]);

  useEffect(() => {
    if (errorUpdatePost) {
      dispatch(setAlert('error', 'Unable to copy/move post'));
    } else if (dataUpdatePost) {
      if (dataUpdatePost.moveCopyPostToTicket?.ok) {
        dispatch(setAlert('success', 'Copy/Move post'));
        setOpenDialog(false);
      } else {
        dispatch(setAlert('error', dataUpdatePost.moveCopyPostToTicket?.error || 'Unable to copy/move post'));
      }
    }
  }, [dataUpdatePost, dispatch, setOpenDialog, errorUpdatePost]);

  return (
    <Dialog
      open={openDialog}
      fullWidth
      disableEscapeKeyDown={true}
      onBackdropClick={() => {
        return false;
      }}
      PaperProps={{
        sx: {
          '@media (max-width: 768px)': {
            width: 'calc(100% - 16px)',
            mx: 1,
          },
          maxWidth: '400px',
        },
      }}
    >
      <DialogTitle
        sx={{
          textAlign: 'center',
          fontSize: '24px',
          fontWeight: 700,
        }}
      >
        Copy/Move post to another ticket
      </DialogTitle>
      <DialogContent>
        <ComponentLoading loading={loadingAvailableTickets}>
          <Formik
            initialValues={{
              copyMove: copyMove,
            }}
            onSubmit={onSubmit}
          >
            {({ isSubmitting }) => {
              return (
                <Form>
                  <Box py={1}>
                    <TextField
                      select
                      name="copyMove"
                      label="Copy/Move *"
                      value={copyMove}
                      onChange={(e) => {
                        setCopyMove(e.target.value);
                      }}
                      fullWidth
                      error={!!copyMoveError}
                      helperText={copyMoveError}
                      FormHelperTextProps={{
                        sx: {
                          ml: 0,
                        },
                      }}
                    >
                      <MenuItem value="-1">Select Action</MenuItem>
                      <MenuItem value="copy">Copy this post to...</MenuItem>
                      <MenuItem value="copy_rest">Copy this post and newer posts to...</MenuItem>
                      <MenuItem value="move">Move this post to...</MenuItem>
                      <MenuItem value="move_rest">Move this post and newer posts to...</MenuItem>
                    </TextField>
                  </Box>
                  {dataAvailableTickets?.possibleDestinationTickets && (
                    <Box py={1}>
                      <TextField
                        select
                        name="targetTicketId"
                        label="Target Ticket *"
                        value={targetTicketId}
                        onChange={(e) => {
                          setTargetTicketId(e.target.value);
                        }}
                        fullWidth
                        error={!!targetTicketError}
                        helperText={targetTicketError}
                        FormHelperTextProps={{
                          sx: {
                            ml: 0,
                          },
                        }}
                      >
                        <MenuItem value="-1">Select Ticket</MenuItem>
                        {dataAvailableTickets?.possibleDestinationTickets?.map((ticket: any, key: number) => {
                          return (
                            <MenuItem value={ticket.id} key={key}>
                              {ticket.subject}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    </Box>
                  )}
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Button variant="outlined" fullWidth onClick={handleClose} disabled={isSubmitting || loadingUpdatePost}>
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <ButtonSubmit
                        text="Submit"
                        loading={isSubmitting || loadingUpdatePost}
                        disabled={isSubmitting || loadingUpdatePost}
                      />
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </ComponentLoading>
      </DialogContent>
    </Dialog>
  );
};

export default MoveCopyPostDialog;
