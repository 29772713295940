import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { Box, TextField, MenuItem, Typography, Button, Tooltip, Collapse, IconButton, Popover, List, ListItem } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import AddIcon from '@mui/icons-material/Add';
import PeopleIcon from '@mui/icons-material/People';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SummarizeIcon from '@mui/icons-material/Summarize';
import { DataGridPro, GridValueGetterParams, GridRowParams, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { aircraftRegistrationMakeModel, getEngineNameFromBorescopeLabel, ctrlCmdClick } from 'helpers';
import dayjs from 'dayjs';

import {
  PATH_BORESCOPE_UPLOAD,
  SINGLE_ENGINE,
  BORESCOPE_ANALYSIS_TICKET_URL_QUERY,
  BORESCOPE_PROGRESS_STATUS,
  BORESCOPE_REQUEST_STATUS,
  BORESCOPE_SENT_STATUS,
  PATH_CREATE_BORESCOPE_REPORT,
  PATH_CREATE_OWN_BORESCOPE_REPORT,
  PATH_AIRCRAFT_VIEW,
} from 'const';

import { storeSelectedAircraft } from 'state';
import { IAircraft, IBorescopeImageSet } from 'types';

import { ComponentLoading, DeleteDialog } from 'components';

import {
  QUERY_BORESCOPE_IMAGE_SETS,
  QUERY_AIRCRAFT_ENGINES_COUNT,
  MUTATION_DELETE_BORESCOPE_IMAGE_SET,
  QUERY_SHARED_AIRCRAFT_BORESCOPE_IMAGE_SETS,
  QUERY_SHARED_AIRCRAFT_ENGINES_COUNT,
} from 'gql';
import { useLazyQuery, useMutation } from '@apollo/client';
import { setAlert } from 'state';

import { default as AddBorescopeImageSetDialog } from './AddBorescopeImageSetDialog';
import { UpdateBorescopeImageSetDialog } from 'pages/Aircrafts/components/Dialog';
import { isNonCreatePermission, isNonUploadPermission } from 'helpers';

interface SelectBorescopeImageSetProps {
  aircrafts: IAircraft[];
  setSlideNum: (value: number) => void;
  setBorescopeImageSet: (value: IBorescopeImageSet | undefined) => void;
  cylinderCount: number | undefined;
  selectedAircraft: IAircraft | undefined;
  setSelectedAircraft: (value: IAircraft | undefined) => void;
}

const SelectBorescopeImageSet: React.FC<SelectBorescopeImageSetProps> = (props) => {
  const { aircrafts: propsAircraft, setSlideNum, setBorescopeImageSet, cylinderCount, selectedAircraft, setSelectedAircraft } = props;

  const [fetchBorescopeImageSets, { data: dataImageSets, loading: loadingImageSets }] = useLazyQuery(QUERY_BORESCOPE_IMAGE_SETS);
  const [fetchSharedImageSets, { data: dataSharedSets, loading: loadingSharedSets }] = useLazyQuery(
    QUERY_SHARED_AIRCRAFT_BORESCOPE_IMAGE_SETS,
  );

  const [fetchEnginesCount, { data: dataEnginesCount, loading: loadingEnginesCount }] = useLazyQuery(QUERY_AIRCRAFT_ENGINES_COUNT);
  const [fetchSharedEnginesCount, { data: dataSharedEnginesCount, loading: loadingSharedEnginesCount }] =
    useLazyQuery(QUERY_SHARED_AIRCRAFT_ENGINES_COUNT);

  const [deleteImageSet, { data: dataDeleteSet, loading: loadingDeleteSet, error: errorDeleteSet }] =
    useMutation(MUTATION_DELETE_BORESCOPE_IMAGE_SET);

  const dispatch = useDispatch();
  const history = useHistory();

  const [showDescription, setShowDescription] = useState(true);

  const { id } = useParams<{
    id: string;
  }>();
  const { search } = useLocation();

  const [aircrafts, setAircrafts] = useState<IAircraft[]>([]);
  const [pageSize, setPageSize] = useState(5);
  const [page, setPage] = useState(0);

  const [showCreate, setShowCreate] = useState(false);

  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [selectedBorescopeImageSet, setSelectedBorescopeImageSet] = useState<IBorescopeImageSet>();

  const [showDelete, setShowDelete] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [deleteName, setDeleteName] = useState('');

  const [success, setSuccess] = useState(false);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const popoverId = open ? 'simple-popover' : undefined;

  const deleteBorescopeImageSet = async () => {
    if (!deleteId) {
      return;
    }
    await deleteImageSet({
      variables: {
        borescopeImageSetId: deleteId,
      },
    });
  };

  const getStatusWeight = (value: string) => {
    switch (value) {
      case BORESCOPE_PROGRESS_STATUS:
        return 1;
      case BORESCOPE_REQUEST_STATUS:
        return 2;
      default:
        return 3;
    }
  };

  useEffect(() => {
    if (propsAircraft && id) {
      for (let i = 0; i < propsAircraft.length; i++) {
        if (propsAircraft[i].id === id) {
          setSelectedAircraft(propsAircraft[i]);
        }
      }
    } else {
      setSelectedAircraft(undefined);
    }
  }, [id, propsAircraft]);

  useEffect(() => {
    if (propsAircraft.length) {
      const tmp = [...propsAircraft];
      setAircrafts(
        tmp.sort((firstAircraft, secondAircraft) => {
          if (firstAircraft.registration > secondAircraft.registration) {
            return 1;
          } else {
            return -1;
          }
        }),
      );
    }
  }, [propsAircraft]);

  useEffect(() => {
    if (selectedAircraft) {
      if (!selectedAircraft.shared) {
        fetchEnginesCount({
          variables: {
            aircraftId: parseInt(id),
          },
        });
      } else {
        fetchSharedEnginesCount({
          variables: {
            aircraftId: selectedAircraft.id,
          },
        });
      }
    }
  }, [selectedAircraft]);

  useEffect(() => {
    if (selectedAircraft) {
      if (!selectedAircraft.shared) {
        fetchBorescopeImageSets({
          variables: {
            aircraftId: parseInt(selectedAircraft.id),
          },
          fetchPolicy: 'cache-and-network',
        });
      } else {
        fetchSharedImageSets({
          variables: {
            aircraftId: selectedAircraft.id,
          },
          fetchPolicy: 'cache-and-network',
        });
      }
      setBorescopeImageSet(undefined);
    }
  }, [selectedAircraft]);

  useEffect(() => {
    if (success && selectedAircraft) {
      if (!selectedAircraft.shared) {
        fetchBorescopeImageSets({
          variables: {
            aircraftId: parseInt(selectedAircraft.id),
          },
          fetchPolicy: 'cache-and-network',
        });
      } else {
        fetchSharedImageSets({
          variables: {
            aircraftId: selectedAircraft.id,
          },
          fetchPolicy: 'cache-and-network',
        });
      }
    }
  }, [success, selectedAircraft]);

  useEffect(() => {
    if (errorDeleteSet) {
      dispatch(setAlert('error', 'Unable to delete selected borescope image set'));
    } else if (dataDeleteSet) {
      const { ok, error } = dataDeleteSet.deleteBorescopeImageSet;
      if (ok) {
        dispatch(setAlert('success', 'Deleted borescope image set'));
        setSuccess(true);
      } else {
        dispatch(setAlert('error', error));
      }
    }
  }, [dispatch, errorDeleteSet, dataDeleteSet]);

  useEffect(() => {
    if (search) {
      const str = new URLSearchParams(search);
      const setId = str.get(BORESCOPE_ANALYSIS_TICKET_URL_QUERY);

      if (setId) {
        const matched = dataImageSets?.me?.aircraft[0]?.borescopeImageSets?.find((item: any) => item.id === setId);
        if (matched) {
          setBorescopeImageSet(matched);
          setSlideNum(1);
        }
      }
    }
  }, [search, dataImageSets]);

  return (
    <Box>
      <ComponentLoading
        loading={loadingImageSets || loadingSharedSets || loadingEnginesCount || loadingSharedEnginesCount || loadingDeleteSet}
      >
        {aircrafts.length !== 0 && selectedAircraft && (
          <Box>
            <Box
              sx={{
                display: 'flex',
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                }}
                py={2}
                px={4}
              >
                <Box>
                  {cylinderCount === 0 && (
                    <Box pt={1} pb={1}>
                      <Box
                        py={1.5}
                        sx={{
                          backgroundColor: 'error.main',
                          borderRadius: '5px',
                          margin: '0 auto',
                          width: {
                            md: '66%',
                            sm: '100%',
                          },
                          px: {
                            md: 2.5,
                            xs: 3.5,
                          },
                          position: 'relative',
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: '16px',
                            fontWeight: 500,
                            lineHeight: '22px',
                            color: 'background.default',
                            '& a': {
                              color: 'background.default',
                              textDecoration: 'underline',
                            },
                            textAlign: 'center',
                          }}
                        >
                          The number of cylinders was not set. Please set the number of cylinders.
                          <Box
                            component="span"
                            sx={{
                              textDecoration: 'underline',
                              ml: 1,
                              cursor: 'pointer',
                            }}
                            onClick={(event) => {
                              ctrlCmdClick(event, `${PATH_AIRCRAFT_VIEW}/${selectedAircraft?.id}`, history);
                            }}
                          >
                            Set Cylinder Count
                          </Box>
                        </Typography>
                      </Box>
                    </Box>
                  )}
                  <Box py={1}>
                    <Typography variant="h2" gutterBottom>
                      {isNonUploadPermission(selectedAircraft) ? 'Create Borescope Report' : 'Create or Edit Borescope Image Sets'}
                    </Typography>
                    <TextField
                      select
                      value={selectedAircraft ? selectedAircraft.id : ''}
                      label="Aircraft"
                      variant="outlined"
                      sx={{
                        mb: 0,
                        minWidth: '300px',
                        textAlign: 'left',
                      }}
                      onChange={(e: any) => {
                        dispatch(storeSelectedAircraft(e.target.value));
                        history.push(`${PATH_BORESCOPE_UPLOAD}/${e.target.value}`);
                      }}
                    >
                      {!!aircrafts?.length &&
                        aircrafts?.map((item: IAircraft, key: number) => {
                          return (
                            <MenuItem key={key} value={item.id} title={aircraftRegistrationMakeModel(item)}>
                              <Typography
                                sx={{
                                  fontSize: '20px',
                                  fontWeight: 700,
                                  color: 'text.primary',
                                  lineHeight: '27px',
                                  maxWidth: '100%',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                  display: 'flex',
                                }}
                              >
                                {item.shared && <PeopleIcon sx={{ mr: 0.5 }} />}
                                {aircraftRegistrationMakeModel(item)}
                              </Typography>
                            </MenuItem>
                          );
                        })}
                    </TextField>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      px: 2,
                      backgroundColor: 'grey.800',
                      justifyContent: 'space-between',
                      py: 2.5,
                    }}
                  >
                    <Typography
                      color="text.secondary"
                      sx={{
                        fontWeight: 700,
                        fontSize: '24px',
                        lineHeight: '32px',
                      }}
                    >
                      All Sets
                    </Typography>
                    {!isNonUploadPermission(selectedAircraft) && (
                      <Box>
                        <Button
                          variant="outlined"
                          onClick={() => {
                            setShowCreate(true);
                          }}
                          disabled={cylinderCount === 0}
                        >
                          <AddIcon
                            sx={{
                              mr: 1,
                            }}
                          />
                          Create New Set
                        </Button>
                      </Box>
                    )}
                  </Box>
                  <Box py={2}>
                    <DataGridPro
                      sx={{
                        width: '100%',
                        minWidth: '100px',
                        '& .MuiDataGrid-columnSeparator': {
                          display: 'none',
                        },
                        '& .MuiDataGrid-cell:focus': {
                          outline: 'none',
                        },
                        '& .MuiDataGrid-row': {
                          cursor: 'pointer',
                          borderBottom: '1px solid',
                          borderColor: 'grey.200',
                        },
                        '& .MuiDataGrid-columnHeader:focus': {
                          outline: 'none',
                        },
                        border: 'none',
                      }}
                      autoHeight
                      disableColumnResize={true}
                      disableSelectionOnClick={true}
                      rowsPerPageOptions={[5, 10, 20, 100]}
                      pageSize={pageSize}
                      onPageSizeChange={(newPage) => setPageSize(newPage)}
                      pagination
                      showColumnRightBorder={false}
                      disableColumnMenu={true}
                      page={page}
                      onPageChange={(newPage) => setPage(newPage)}
                      rows={
                        (!selectedAircraft.shared
                          ? dataImageSets?.me?.aircraft[0]?.borescopeImageSets?.length
                            ? [...dataImageSets.me.aircraft[0].borescopeImageSets]
                            : []
                          : dataSharedSets?.sharedAircraftWithUploadBorescope[0]?.aircraft?.borescopeImageSets?.length
                            ? [...dataSharedSets.sharedAircraftWithUploadBorescope[0].aircraft.borescopeImageSets]
                            : []
                        )
                          .sort((item1: IBorescopeImageSet, item2: IBorescopeImageSet) => {
                            if (getStatusWeight(item1.status) < getStatusWeight(item2.status)) {
                              return -1;
                            } else if (getStatusWeight(item1.status) > getStatusWeight(item2.status)) {
                              return 1;
                            } else {
                              return item1.created > item2.created ? -1 : 1;
                            }
                          })
                          .filter(
                            (item: IBorescopeImageSet) =>
                              (isNonUploadPermission(selectedAircraft) && item.status === BORESCOPE_REQUEST_STATUS) ||
                              !isNonUploadPermission(selectedAircraft),
                          ) || []
                      }
                      columnVisibilityModel={{
                        engine:
                          dataEnginesCount?.me?.aircraft[0]?.aircraftModel?.enginesCount !== SINGLE_ENGINE &&
                          dataSharedEnginesCount?.sharedAircraftWithUploadBorescope[0]?.aircraft?.aircraftModel?.enginesCount !==
                            SINGLE_ENGINE,
                      }}
                      columns={[
                        {
                          field: 'name',
                          headerName: 'Set Name',
                          renderCell: (params: GridRenderCellParams) => {
                            return (
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <Typography>{params.value}</Typography>
                                {params.row.status === BORESCOPE_SENT_STATUS && (
                                  <Tooltip
                                    title={
                                      <Typography
                                        sx={{
                                          fontSize: '16px',
                                          lineHeight: '24px',
                                        }}
                                      >
                                        A report for this image set has already been created. To request additional analysis, please create
                                        a new image set.
                                      </Typography>
                                    }
                                  >
                                    <LockIcon
                                      sx={{
                                        ml: 1.5,
                                        width: '0.8em',
                                        height: '0.8em',
                                      }}
                                    />
                                  </Tooltip>
                                )}
                              </Box>
                            );
                          },
                          flex: 1,
                        },
                        {
                          field: 'created',
                          headerName: 'Created',
                          valueGetter: (params: GridValueGetterParams) => {
                            return params.value ? dayjs(params.value).format('YYYY-MM-DD') : '';
                          },
                          flex: 1,
                        },
                        {
                          field: 'images',
                          headerName: 'Number of Images',
                          valueGetter: (params: GridValueGetterParams) => {
                            return params.value ? params.value.length : 0;
                          },
                          flex: 1,
                        },
                        {
                          field: 'engine',
                          headerName: 'Engine',
                          valueGetter: (params: GridValueGetterParams) => {
                            return params.value ? getEngineNameFromBorescopeLabel(params.value) : '';
                          },
                          flex: 1,
                        },
                        {
                          field: 'status',
                          headerName: 'Status',
                          renderCell: (params: GridRenderCellParams<string>) => {
                            const status = params.value;
                            return (
                              <Box
                                sx={{
                                  p: '5px 10px',
                                  borderRadius: '6px',
                                  backgroundColor:
                                    status === BORESCOPE_PROGRESS_STATUS
                                      ? '#F7E8D3'
                                      : status === BORESCOPE_SENT_STATUS
                                        ? '#F5F6FA'
                                        : '#D1F2D8',
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontWeight: 700,
                                    color:
                                      status === BORESCOPE_PROGRESS_STATUS
                                        ? '#D68E21'
                                        : status === BORESCOPE_SENT_STATUS
                                          ? 'text.primary'
                                          : '#4C8F5B',
                                  }}
                                >
                                  {status?.replace('_', ' ')}
                                </Typography>
                              </Box>
                            );
                          },
                          flex: 1,
                        },
                        {
                          field: 'id',
                          headerName: 'Action',
                          renderCell: (params: GridRenderCellParams<string>) => {
                            return (
                              <Box>
                                <IconButton
                                  onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    const selectedImageSet = (
                                      !selectedAircraft?.shared
                                        ? dataImageSets.me.aircraft[0].borescopeImageSets
                                        : dataSharedSets.sharedAircraftWithUploadBorescope[0].aircraft.borescopeImageSets
                                    ).find((item: any) => item.id === params.value);
                                    if (selectedImageSet) {
                                      setSelectedBorescopeImageSet(selectedImageSet);
                                    }
                                    setAnchorEl(e.currentTarget);
                                  }}
                                >
                                  <MoreVertIcon />
                                </IconButton>
                              </Box>
                            );
                          },
                          flex: 0.5,
                        },
                      ]}
                      onRowClick={(params: GridRowParams) => {
                        if (params.row?.status === BORESCOPE_SENT_STATUS) {
                          dispatch(
                            setAlert(
                              'warning',
                              'A report for this image set has already been created.  To request additional analysis, please create a new image set.',
                            ),
                          );
                          return;
                        }
                        if (isNonUploadPermission(selectedAircraft)) {
                          history.push(`${PATH_CREATE_BORESCOPE_REPORT}/${selectedAircraft.id}/${params.row?.id}`);
                        } else {
                          setBorescopeImageSet(params.row as IBorescopeImageSet);
                          setSlideNum(1);
                        }
                      }}
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  backgroundColor: 'grey.800',
                  position: 'relative',
                  minHeight: {
                    md: 'inherit',
                    lg: 'calc(100vh - 75px)',
                  },
                  borderLeft: '1px solid',
                  borderLeftColor: 'grey.200',
                  maxWidth: '450px',
                }}
              >
                <Collapse in={showDescription} orientation="horizontal" timeout={0}>
                  <Box px={6} py={5}>
                    <Box py={1}>
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: '20px',
                          lineHeight: '28px',
                        }}
                        color="text.secondary"
                      >
                        How to use
                      </Typography>
                    </Box>
                    <Box py={1} mb={2}>
                      <Typography
                        sx={{
                          fontSize: '16px',
                          lineHeight: '24px',
                          color: 'grey.600',
                        }}
                      >
                        To use the borescope image analysis feature, follow these steps
                      </Typography>
                    </Box>
                    <Box
                      py={2}
                      sx={{
                        borderBottom: '1px solid',
                        borderColor: '#E9EAEF',
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: '16px',
                          lineHeight: '22px',
                          fontWeight: 700,
                          mb: 1,
                        }}
                        color="text.secondary"
                      >
                        Upload Image Set
                      </Typography>
                      <Typography>For each engine, upload a complete set of images showcasing various views of each cylinder.</Typography>
                    </Box>
                    <Box
                      py={2}
                      sx={{
                        borderBottom: '1px solid',
                        borderColor: '#E9EAEF',
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: '16px',
                          lineHeight: '22px',
                          fontWeight: 700,
                          mb: 1,
                        }}
                        color="text.secondary"
                      >
                        Multiple Engines
                      </Typography>
                      <Typography>If analyzing multiple engines, create a separate image set of each.</Typography>
                    </Box>
                    <Box
                      py={2}
                      sx={{
                        borderBottom: '1px solid',
                        borderColor: '#E9EAEF',
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: '16px',
                          lineHeight: '22px',
                          fontWeight: 700,
                          mb: 1,
                        }}
                        color="text.secondary"
                      >
                        Final Reports
                      </Typography>
                      <Typography>Once a report is generated for an image set, no additional images can be added to that set.</Typography>
                    </Box>
                    <Box py={2}>
                      <Typography
                        sx={{
                          fontSize: '16px',
                          lineHeight: '22px',
                          fontWeight: 700,
                          mb: 1,
                        }}
                        color="text.secondary"
                      >
                        New or Existing Image Set
                      </Typography>
                      <Typography>
                        You have the option to either create a new image set or continue with an existing set that is currently in progress.
                      </Typography>
                    </Box>
                  </Box>
                </Collapse>
                <IconButton
                  sx={{
                    position: 'absolute',
                    left: 0,
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                    '&:hover': {
                      backgroundColor: 'background.default',
                    },
                    borderRadius: '6px',
                    border: '1px solid',
                    borderColor: 'grey.200',
                    backgroundColor: 'background.default',
                    zIndex: 999,
                  }}
                  onClick={() => {
                    setShowDescription(!showDescription);
                  }}
                >
                  {showDescription ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                </IconButton>
              </Box>
            </Box>
          </Box>
        )}
      </ComponentLoading>
      <AddBorescopeImageSetDialog
        open={showCreate}
        setOpen={setShowCreate}
        aircraftId={id}
        setBorescopeImageSet={setBorescopeImageSet}
        setSlideNum={setSlideNum}
        isSingle={
          Boolean(!selectedAircraft?.shared && dataEnginesCount?.me?.aircraft[0]?.aircraftModel?.enginesCount === SINGLE_ENGINE) ||
          Boolean(
            selectedAircraft?.shared &&
              dataSharedEnginesCount?.sharedAircraftWithUploadBorescope[0]?.aircraft?.aircraftModel?.enginesCount === SINGLE_ENGINE,
          )
        }
        cylinderCount={cylinderCount}
        isShared={selectedAircraft?.shared}
      />
      {selectedBorescopeImageSet && (
        <UpdateBorescopeImageSetDialog
          open={showUpdateModal}
          setOpen={setShowUpdateModal}
          selectedImageSet={selectedBorescopeImageSet}
          isSingle={
            Boolean(!selectedAircraft?.shared && dataEnginesCount?.me?.aircraft[0]?.aircraftModel?.enginesCount === SINGLE_ENGINE) ||
            Boolean(
              selectedAircraft?.shared &&
                dataSharedEnginesCount?.sharedAircraftWithUploadBorescope[0]?.aircraft?.aircraftModel?.enginesCount === SINGLE_ENGINE,
            )
          }
        />
      )}
      <DeleteDialog
        open={showDelete}
        setOpen={setShowDelete}
        onSubmitAction={deleteBorescopeImageSet}
        title={`Delete "${deleteName}"?`}
        text="Are you sure?"
      />
      {selectedAircraft && (
        <Popover
          id={popoverId}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
        >
          <List component="div">
            {!isNonUploadPermission(selectedAircraft) && (
              <React.Fragment>
                <ListItem
                  ContainerComponent="div"
                  button
                  onClick={() => {
                    setAnchorEl(null);
                    if (selectedBorescopeImageSet?.status === BORESCOPE_SENT_STATUS) {
                      dispatch(
                        setAlert(
                          'warning',
                          'A report for this image set has already been created.  To request additional analysis, please create a new image set.',
                        ),
                      );
                      return;
                    }
                    setBorescopeImageSet(selectedBorescopeImageSet);
                    setSlideNum(1);
                  }}
                >
                  <LocalOfferIcon
                    sx={{
                      transform: 'rotate(135deg)',
                      mr: 1,
                    }}
                  />
                  Upload and Tag Images
                </ListItem>
                <ListItem
                  ContainerComponent="div"
                  button
                  onClick={() => {
                    setShowUpdateModal(true);
                    setAnchorEl(null);
                  }}
                >
                  <EditIcon sx={{ mr: 1 }} />
                  Rename Image Set
                </ListItem>
                <ListItem
                  ContainerComponent="div"
                  button
                  onClick={() => {
                    setDeleteId(selectedBorescopeImageSet?.id || '');
                    setDeleteName(selectedBorescopeImageSet?.name || '');
                    setShowDelete(true);
                    setSuccess(false);
                    setAnchorEl(null);
                  }}
                >
                  <DeleteIcon sx={{ mr: 1 }} />
                  Delete Set
                </ListItem>
              </React.Fragment>
            )}
            {!isNonCreatePermission(selectedAircraft) && (
              <ListItem
                ContainerComponent="div"
                button
                onClick={() => {
                  if (selectedBorescopeImageSet?.status === BORESCOPE_SENT_STATUS) {
                    dispatch(
                      setAlert(
                        'warning',
                        'A report for this image set has already been created.  To request additional analysis, please create a new image set.',
                      ),
                    );
                    return;
                  }
                  if (selectedAircraft?.shared) {
                    history.push(`${PATH_CREATE_BORESCOPE_REPORT}/${selectedAircraft.id}/${selectedBorescopeImageSet?.id}`);
                  } else {
                    history.push(`${PATH_CREATE_OWN_BORESCOPE_REPORT}/${selectedAircraft.id}/${selectedBorescopeImageSet?.id}`);
                  }
                  setAnchorEl(null);
                }}
              >
                <SummarizeIcon sx={{ mr: 1 }} />
                Create Report
              </ListItem>
            )}
          </List>
        </Popover>
      )}
    </Box>
  );
};

export default SelectBorescopeImageSet;
