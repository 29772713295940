export const AircraftTabs = [
  {
    index: 0,
    suffix: '',
  },
  {
    index: 1,
    suffix: 'owner',
  },
  {
    index: 2,
    suffix: 'comments',
  },
  {
    index: 3,
    suffix: 'subscriptions',
  },
  {
    index: 4,
    suffix: 'tickets',
  },
  {
    index: 5,
    suffix: 'managers',
  },
  {
    index: 6,
    suffix: 'documents',
  },
  {
    index: 7,
    suffix: 'flights',
  },
  {
    index: 8,
    suffix: 'coi',
  },
  {
    index: 9,
    suffix: 'mdr',
  },
  {
    index: 10,
    suffix: 'borescope-image-sets',
  },
  {
    index: 11,
    suffix: 'edf',
  },
  {
    index: 12,
    suffix: 'analysis-reports',
  },
  {
    index: 13,
    suffix: 'reports',
  },
];

export const ContactTabs = [
  {
    index: 0,
    suffix: '',
  },
  {
    index: 1,
    suffix: 'classifications',
  },
  {
    index: 2,
    suffix: 'comments',
  },
  {
    index: 3,
    suffix: 'aircraft',
  },
  {
    index: 4,
    suffix: 'transactions',
  },
  {
    index: 5,
    suffix: 'tickets',
  },
  {
    index: 6,
    suffix: 'emailcc',
  },
  {
    index: 7,
    suffix: 'assigned',
  },
];

export const ClientAircraftTabs = [
  {
    index: 0,
    suffix: '',
  },
  {
    index: 1,
    suffix: 'unit-conversions',
  },
  {
    index: 2,
    suffix: 'mdr',
  },
  {
    index: 3,
    suffix: 'coi',
  },
  {
    index: 4,
    suffix: 'files',
  },
  {
    index: 7,
    suffix: 'mgl',
  },
  {
    index: 7,
    suffix: 'ubg',
  },
  {
    index: 5,
    suffix: 'borescope-image-sets',
  },
  {
    index: 6,
    suffix: 'aircraft-access',
  },
];
