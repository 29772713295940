import React, { Fragment, useState, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { setAlert } from 'state';
import { CHANGE_PERSONAL_INFO_SUCCESS } from 'state/types';

import { useMutation, useQuery } from '@apollo/client';

import { Typography, Grid } from '@mui/material';

import * as Yup from 'yup';

import { Subsection, Autocomplete, ButtonSubmit, MuiFormikInput } from 'components';

import { useAutocomplete, useService } from 'hooks';

import { Formik, Form, Field } from 'formik';
import { CheckboxWithLabel } from 'formik-mui';

import { getOptionLabelFlagCountry, formatOptionIdName, handleSendError } from 'helpers';

import { logAmplitudeEvent } from 'services';

import { countries } from 'api';

import {
  CHANGE_PERSONAL_INFO_SUCCESS_MESSAGE,
  SIGNUP_PAGE_LOADED_ADDITIONAL_INFO,
  SIGNUP_ADDITIONAL_INFO_ERROR,
  SIGNUP_ADDITIONAL_INFO_SUCCESS,
  SIGNUP_SERVICE_SUCCESS,
  AIRCRAFT_INFO_SUCCESS_MESSAGE,
  PATH_WELCOME,
} from 'const';

import { MUTATION_UPDATE_AIRCRAFT, MUTATION_CHANGE_PERSONAL_INFO, MUTATION_COMPLETE_PURCHASE, QUERY_USER_DATA } from 'gql';

import { EngineMakeModel } from './EngineMakeModel';
import { EngineMonitorMakeModel } from './EngineMonitorMakeModel';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const AdditionalValidation = Yup.object({
  serial: Yup.string().max(50, 'Too long: Maximum 50 characters').required('Required'),
  modelYear: Yup.string().max(4, 'Too long: Maximum 4 characters').required('Required'),
  airportId: Yup.string().max(4, 'Too long: Maximum 4 characters').required('Required'),
  mobilePhone: Yup.string().max(20, 'Too long: Maximum 20 characters').matches(phoneRegExp, 'Invalid phone').required('Required'),
});

interface AdditionalFormProps {
  setRegistration?: (value: string) => void;
}

const SignupServiceAdditionalInfoForm: React.FC<AdditionalFormProps> = (props) => {
  const { setRegistration } = props;
  const dispatch = useDispatch();

  const service = useService();

  const [aircraftId, setAircraftId] = useState('');
  const [subscriptionId, setSubscriptionId] = useState('');

  const { data: dataUserData, loading: loadingUserData } = useQuery(QUERY_USER_DATA, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    // Amplitude Page Loaded
    logAmplitudeEvent(SIGNUP_PAGE_LOADED_ADDITIONAL_INFO);
  }, []);

  const { value: country, error: errorCountry, setError: setErrorCountry, onChange: onChangeCountry } = useAutocomplete();
  const {
    value: engineManufacturer,
    setValue: setEngineManufacturer,
    error: errorEngineManufacturer,
    setError: setErrorEngineManufacturer,
    onChange: onChangeEngineManufacturer,
  } = useAutocomplete();

  const {
    value: engineModel,
    setValue: setEngineModel,
    error: errorEngineModel,
    setError: setErrorEngineModel,
    onChange: onChangeEngineModel,
  } = useAutocomplete();

  const {
    value: engineMonitorManufacturer,
    setValue: setEngineMonitorManufacturer,
    error: errorEngineMonitorManufacturer,
    setError: setErrorEngineMonitorManufacturer,
    onChange: onChangeEngineMonitorManufacturer,
  } = useAutocomplete();

  const {
    value: engineMonitorModel,
    setValue: setEngineMonitorModel,
    error: errorEngineMonitorModel,
    setError: setErrorEngineMonitorModel,
    onChange: onChangeEngineMonitorModel,
  } = useAutocomplete();

  const [installedEngineMonitor, setInstalledEngineMonitor] = useState(false);

  const [changePersonalInfo, { data, error, loading }] = useMutation(MUTATION_CHANGE_PERSONAL_INFO);
  const [updateAircraft, { data: dataUpdateAircraft, loading: loadingUpdateAircraft }] = useMutation(MUTATION_UPDATE_AIRCRAFT);

  const [completPurchase, { data: dataCompletePurchase, error: errorCompletePurchase, loading: loadingCompletePurchase }] = useMutation(
    MUTATION_COMPLETE_PURCHASE,
    {
      errorPolicy: 'all',
    },
  );

  useEffect(() => {
    if (dataUserData && dataUserData.me) {
      const { me } = dataUserData;
      if (me?.incompleteAircraft?.length) {
        setAircraftId(me.incompleteAircraft[0].id);
        if (me?.incompleteAircraft[0].subscriptions.length) {
          setSubscriptionId(me.incompleteAircraft[0].subscriptions[0].id);
        }
        if (setRegistration) setRegistration(me.incompleteAircraft[0].registration);
      }
    }
  }, [dataUserData, loadingUserData, service, setRegistration]);

  // Form Submit
  const onSubmit = async (values: any) => {
    // e.preventDefault(); // Prevent sending

    let hasError = false;

    if (!country) {
      setErrorCountry('Required');
      hasError = true;
    }

    if (!engineManufacturer?.id) {
      setErrorEngineManufacturer('Required');
      hasError = true;
    }

    if (!engineModel?.id) {
      setErrorEngineModel('Required');
      hasError = true;
    }

    if (!installedEngineMonitor) {
      if (!engineMonitorManufacturer?.id) {
        setErrorEngineMonitorManufacturer('Required');
        hasError = true;
      }

      if (!engineMonitorModel?.id) {
        setErrorEngineMonitorModel('Required');
        hasError = true;
      }
    }

    if (hasError) return;

    await changePersonalInfo({
      variables: {
        airportId: values.airportId,
        countryCode: country.id,
        mobilePhone: values.mobilePhone,
      },
    });

    await updateAircraft({
      variables: {
        aircraftId: parseInt(aircraftId),
        aircraftHasNoMonitor: installedEngineMonitor,
        engineManufacturerId: parseInt(engineManufacturer.id),
        engineModelId: parseInt(engineModel.id),
        engineMonitorManufacturerId: parseInt(engineMonitorManufacturer?.id),
        engineMonitorModelId: parseInt(engineMonitorModel?.id),
        serial: values.serial,
        year: values.modelYear ? parseInt(values.modelYear) : null,
      },
    });
    if (subscriptionId)
      await completPurchase({
        variables: {
          subscriptionId: subscriptionId,
        },
      });
  };
  // Send Error
  error && handleSendError();

  // Response
  useEffect(() => {
    if (data) {
      const { error } = data.changePersonalInfo;
      // Errors
      if (error) {
        dispatch(setAlert('error', error));

        // Amplitude
        logAmplitudeEvent(SIGNUP_ADDITIONAL_INFO_ERROR);
      } else {
        // Success
        dispatch(setAlert('success', CHANGE_PERSONAL_INFO_SUCCESS_MESSAGE));
        dispatch({ type: CHANGE_PERSONAL_INFO_SUCCESS });

        // Amplitude
        logAmplitudeEvent(SIGNUP_ADDITIONAL_INFO_SUCCESS);
        logAmplitudeEvent(SIGNUP_SERVICE_SUCCESS);
      }
    }
  }, [data, dispatch]);

  useEffect(() => {
    if (dataUpdateAircraft) {
      const { error: aircraftErrors } = dataUpdateAircraft.updateAircraft;

      if (aircraftErrors) {
        dispatch(
          setAlert('error', aircraftErrors || 'Unable to complete the signup process. If this error persists, please contact support.'),
        );
      } else {
        dispatch(setAlert('success', AIRCRAFT_INFO_SUCCESS_MESSAGE));

        if (!subscriptionId) {
          window.location.href = `/`;
        }
      }
    }
  }, [dispatch, dataUpdateAircraft, subscriptionId]);

  useEffect(() => {
    if (errorCompletePurchase) {
      dispatch(setAlert('error', 'Unable to complete the signup process. If this error persists, please contact support.'));
    } else if (dataCompletePurchase) {
      if (dataCompletePurchase.completePurchase?.ok) {
        window.location.href = `${PATH_WELCOME}/${aircraftId}/${service?.id}?category=${dataUserData?.me?.incompleteAircraft[0]?.aircraftModel?.propulsion?.toLowerCase()}-${dataUserData?.me?.incompleteAircraft[0]?.aircraftModel?.enginesCount?.toLowerCase()}`;
      } else {
        dispatch(
          setAlert(
            'error',
            dataCompletePurchase.completePurchase?.error ||
              'Unable to complete the signup process. If this error persists, please contact support.',
          ),
        );
      }
    }
  }, [dataCompletePurchase, service, aircraftId, errorCompletePurchase, dispatch]);

  return (
    <Fragment>
      {service && (
        <Subsection>
          <Typography paragraph align="center">
            {'Almost done! Just a few more items to get your service started:'}
          </Typography>
        </Subsection>
      )}
      <Formik
        initialValues={{
          serial: '',
          modelYear: '',
          airportId: '',
          mobilePhone: '',
          installedEngineMonitor: false,
        }}
        onSubmit={onSubmit}
        validationSchema={AdditionalValidation}
      >
        {(formik) => {
          return (
            <Form>
              <Subsection>
                <Typography variant="h4" gutterBottom align="center">
                  Account
                </Typography>

                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12}>
                    <MuiFormikInput
                      label="Mobile Phone *"
                      errors={formik.errors}
                      touched={formik.touched}
                      disabled={formik.isSubmitting || loading || loadingUpdateAircraft || loadingCompletePurchase}
                      {...formik.getFieldProps('mobilePhone')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <MuiFormikInput
                      label="Home Airport ID *"
                      errors={formik.errors}
                      touched={formik.touched}
                      disabled={formik.isSubmitting || loading || loadingUpdateAircraft || loadingCompletePurchase}
                      {...formik.getFieldProps('airportId')}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      label="Country *"
                      name="country"
                      value={country}
                      error={errorCountry}
                      onChange={(e) => onChangeCountry(e)}
                      options={countries}
                      getOptionLabel={getOptionLabelFlagCountry}
                      formatOption={formatOptionIdName}
                      disabled={formik.isSubmitting || loading || loadingUpdateAircraft || loadingCompletePurchase}
                    />
                  </Grid>
                </Grid>
              </Subsection>
              <Subsection>
                <Typography variant="h4" gutterBottom align="center">
                  Aircraft
                </Typography>

                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <MuiFormikInput
                      label="Serial *"
                      errors={formik.errors}
                      touched={formik.touched}
                      disabled={formik.isSubmitting || loading || loadingUpdateAircraft || loadingCompletePurchase}
                      {...formik.getFieldProps('serial')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MuiFormikInput
                      label="Model Year *"
                      errors={formik.errors}
                      touched={formik.touched}
                      disabled={formik.isSubmitting || loading || loadingUpdateAircraft || loadingCompletePurchase}
                      {...formik.getFieldProps('modelYear')}
                    />
                  </Grid>
                  <EngineMakeModel
                    engineManufacturer={engineManufacturer}
                    setEngineManufacturer={setEngineManufacturer}
                    errorEngineManufacturer={errorEngineManufacturer}
                    onChangeEngineManufacturer={onChangeEngineManufacturer}
                    engineModel={engineModel}
                    setEngineModel={setEngineModel}
                    errorEngineModel={errorEngineModel}
                    onChangeEngineModel={onChangeEngineModel}
                    disabled={formik.isSubmitting || loading || loadingUpdateAircraft || loadingCompletePurchase}
                  />
                  <EngineMonitorMakeModel
                    engineMonitorManufacturer={engineMonitorManufacturer}
                    setEngineMonitorManufacturer={setEngineMonitorManufacturer}
                    errorEngineMonitorManufacturer={errorEngineMonitorManufacturer}
                    onChangeEngineMonitorManufacturer={onChangeEngineMonitorManufacturer}
                    engineMonitorModel={engineMonitorModel}
                    setEngineMonitorModel={setEngineMonitorModel}
                    errorEngineMonitorModel={errorEngineMonitorModel}
                    onChangeEngineMonitorModel={onChangeEngineMonitorModel}
                    installedEngineMonitor={installedEngineMonitor}
                    disabled={formik.isSubmitting || loading || loadingUpdateAircraft || loadingCompletePurchase}
                  />
                  <Grid item xs={12}>
                    <Field
                      type="checkbox"
                      name="installedEngineMonitor"
                      checked={installedEngineMonitor}
                      component={CheckboxWithLabel}
                      Label={{ label: 'Engine Monitor Not Installed' }}
                      onChange={(e: any) => {
                        setInstalledEngineMonitor(!installedEngineMonitor);
                        formik.handleChange(e);
                      }}
                      disabled={formik.isSubmitting || loading || loadingUpdateAircraft || loadingCompletePurchase}
                    />
                  </Grid>
                </Grid>
              </Subsection>
              <Subsection>
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={12} md={6}>
                    <ButtonSubmit
                      text="Done"
                      loading={formik.isSubmitting || loading || loadingUpdateAircraft || loadingCompletePurchase}
                      disabled={formik.isSubmitting || loading || loadingUpdateAircraft || loadingCompletePurchase}
                    />
                  </Grid>
                </Grid>
              </Subsection>
            </Form>
          );
        }}
      </Formik>
    </Fragment>
  );
};

export default SignupServiceAdditionalInfoForm;
