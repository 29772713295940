import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button } from '@mui/material';
import { useMutation } from '@apollo/client';
import { MUTATION_CREATE_BREAKDOWN_TAG } from 'gql';
import { setAlert } from 'state';

interface BreakdownTagProps {
  handleClose: () => void;
  setLoading: (value: boolean) => void;
  ticketId: string;
}

const BreakdownTag: React.FC<BreakdownTagProps> = (props) => {
  const { ticketId, setLoading, handleClose } = props;

  const [createBreakdownTag, { data: dataBreakdownTag, error: errorBreakdownTag, loading: loadingBreakdownTag }] =
    useMutation(MUTATION_CREATE_BREAKDOWN_TAG);
  const dispatch = useDispatch();

  const onSubmit = async () => {
    await createBreakdownTag({
      variables: {
        ticketId,
      },
    });
  };

  useEffect(() => {
    if (errorBreakdownTag) {
      dispatch(setAlert('error', 'Unable to create breakdown tag'));
    } else if (dataBreakdownTag && dataBreakdownTag.createBreakdownTag?.ok) {
      dispatch(setAlert('success', 'Created breakdown tag'));
      handleClose();
    }
  }, [errorBreakdownTag, dataBreakdownTag, dispatch, handleClose]);

  useEffect(() => {
    setLoading(loadingBreakdownTag);
  }, [loadingBreakdownTag, setLoading]);

  return (
    <React.Fragment>
      <Box
        sx={{
          textAlign: 'right',
        }}
        py={1}
      >
        <Button
          variant="outlined"
          sx={{
            width: '100px',
            mr: 2,
          }}
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          sx={{
            width: '100px',
          }}
          onClick={() => {
            onSubmit();
          }}
        >
          Create
        </Button>
      </Box>
    </React.Fragment>
  );
};

export default BreakdownTag;
