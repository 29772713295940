import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { SET_START_TIME, SET_STOP_TIME, SET_STOPPED_TIME, SET_INITIAL_TIMER_OPTIONS } from 'state/types';

interface LogTimerProps {
  ticketId: string;
  isResetTimer: boolean;
  setIsResetTimer: (value: boolean) => void;
}

const LogTimer: React.FC<LogTimerProps> = (props) => {
  const { ticketId, isResetTimer, setIsResetTimer } = props;

  const { ticketId: storedTicketId, startTime, isStoppedTimer, stoppedTime } = useSelector((state: any) => state.ticket);
  const [timeCounter, setTimeCounter] = useState(stoppedTime);
  const dispatch = useDispatch();

  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const setStoppedTime = (value: number) => {
    dispatch({
      type: SET_STOPPED_TIME,
      payload: {
        stoppedTime: value,
      },
    });
  };

  const setIsStoppedTimer = (value: boolean) => {
    dispatch({
      type: SET_STOP_TIME,
      payload: {
        isStoppedTimer: value,
      },
    });
  };

  useEffect(() => {
    if (ticketId === storedTicketId && !isStoppedTimer) {
      if (intervalRef?.current) {
        clearInterval(intervalRef.current);
      }

      setTimeCounter(0);

      intervalRef.current = setInterval(() => {
        setTimeCounter(stoppedTime + (new Date().getTime() - startTime) / 1000);
      }, 1000);
    } else if (ticketId !== storedTicketId) {
      if (intervalRef?.current) {
        clearInterval(intervalRef.current);
      }

      setTimeCounter(0);
      const startTime = new Date();
      dispatch({
        type: SET_INITIAL_TIMER_OPTIONS,
        payload: {
          ticketId,
          startTime: startTime.getTime(),
        },
      });
      intervalRef.current = setInterval(() => {
        setTimeCounter((new Date().getTime() - startTime.getTime()) / 1000);
      }, 1000);
    }
  }, [ticketId, startTime, storedTicketId, isStoppedTimer, stoppedTime, dispatch]);

  useEffect(() => {
    if (ticketId && ticketId !== storedTicketId) {
      dispatch({
        type: SET_START_TIME,
        payload: {
          startTime: new Date().getTime(),
          ticketId,
        },
      });
    }
  }, [ticketId, storedTicketId, dispatch]);

  useEffect(() => {
    if (isResetTimer) {
      setTimeCounter(0);

      if (intervalRef?.current) {
        clearInterval(intervalRef.current);
        dispatch({
          type: SET_STOPPED_TIME,
          payload: {
            stoppedTime: 0,
          },
        });
      }

      if (!isStoppedTimer) {
        const startTime = new Date();
        dispatch({
          type: SET_START_TIME,
          payload: {
            startTime: new Date().getTime(),
            ticketId,
          },
        });
        intervalRef.current = setInterval(() => {
          setTimeCounter((new Date().getTime() - startTime.getTime()) / 1000);
        }, 1000);
      }

      setIsResetTimer(false);
    }
  }, [isResetTimer, setIsResetTimer, ticketId, dispatch, isStoppedTimer]);

  useEffect(() => {
    return () => {
      if (intervalRef?.current) {
        clearInterval(intervalRef?.current);
      }
      intervalRef.current = null;
    };
  }, []);

  const getMinuteSeconds = (value: number) => {
    const minute = Math.floor(value / 60);

    const seconds = Math.floor(value - 60 * minute);
    return `${('0' + minute).slice(-2)}m ${('0' + seconds).slice(-2)}s`;
  };

  const StopOrStartTimer = () => {
    setIsStoppedTimer(!isStoppedTimer);

    if (!isStoppedTimer && intervalRef?.current) {
      clearInterval(intervalRef.current);
      setStoppedTime(timeCounter);
    }

    if (isStoppedTimer) {
      const startTime = new Date();
      dispatch({
        type: SET_START_TIME,
        payload: {
          startTime: new Date().getTime(),
          ticketId,
        },
      });
      intervalRef.current = setInterval(() => {
        setTimeCounter(stoppedTime + (new Date().getTime() - startTime.getTime()) / 1000);
      }, 1000);
    }
  };

  const resetTimer = () => {
    setTimeCounter(0);

    if (intervalRef?.current) {
      clearInterval(intervalRef.current);
      setStoppedTime(0);
    }

    if (!isStoppedTimer) {
      const startTime = new Date();
      dispatch({
        type: SET_START_TIME,
        payload: {
          startTime: new Date().getTime(),
          ticketId,
        },
      });
      intervalRef.current = setInterval(() => {
        setTimeCounter((new Date().getTime() - startTime.getTime()) / 1000);
      }, 1000);
    }
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
        }}
      >
        <Typography>{getMinuteSeconds(timeCounter)}</Typography>
        <Typography
          sx={{
            cursor: 'pointer',
            textDecoration: 'underline',
            mx: 1,
          }}
          onClick={StopOrStartTimer}
        >
          {!isStoppedTimer ? 'Stop' : 'Start'}
        </Typography>
        <Typography
          sx={{
            cursor: 'pointer',
            textDecoration: 'underline',
          }}
          onClick={resetTimer}
        >
          Reset
        </Typography>
      </Box>
    </Box>
  );
};

export default LogTimer;
