import React from 'react';

import { Box } from '@mui/material';

import { AppBarLoggedOut, Content, Footer } from './components';

import { ILayout } from 'types';

const LayoutLoggedOut: React.FC<ILayout> = (props) => {
  const { children } = props;
  return (
    <Box display="flex" flexDirection="column" height="100%">
      <AppBarLoggedOut />
      <Content>{children}</Content>
      <Footer />
    </Box>
  );
};

export default LayoutLoggedOut;
