import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Box, Typography, Link, Tabs, Tab, TextField, MenuItem, CSSObject, IconButton, Button } from '@mui/material';
import { DataGridPro, GridValueGetterParams, GridRowParams, GridRenderCellParams } from '@mui/x-data-grid-pro';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DownloadIcon from '@mui/icons-material/Download';

import {
  QUERY_SHARED_LOGS,
  MUTATION_CREATE_MAINTENANCE_RECORDS_PDF,
  QUERY_SHARED_LOG_DOCUMENT_DOWNLOAD_URL,
  QUERY_SHARED_LOG_DOCUMENT_PREVIEW_URL,
} from 'gql';
import { useLazyQuery, useMutation } from '@apollo/client';

import { PATH_TICKETS } from 'const';
import { ctrlCmdClick, downloadFileInNewTab } from 'helpers';

import { PageLoading, ComponentLoading, LayoutDashboard, a11yProps, SimpleTabPanel } from 'components';
import { setAlert } from 'state';

import { ILogbook } from 'types';

import { PdfDialog } from 'pages/Tickets/components/ContentComponent';
import { apolloClient } from 'services';

const SharedLogs: React.FC = () => {
  const { id } = useParams<{
    id: string;
  }>();

  const [fetchSharedLogs, { data: dataLogs, loading, error }] = useLazyQuery(QUERY_SHARED_LOGS, {
    fetchPolicy: 'cache-and-network',
  });

  const [createMaintenancePdf, { data: dataPdf, loading: loadingPdf, error: errorPdf }] = useMutation(
    MUTATION_CREATE_MAINTENANCE_RECORDS_PDF,
  );

  const [showFileName, setShowFileName] = useState('');
  const [showFile, setShowFile] = useState('');
  const [showPdfFileDialog, setShowPdfFileDialog] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const [selectedLogbookId, setSelectedLogbookId] = useState('');
  const [loadingDownloadUrl, setLoadingDownloadUrl] = useState(false);

  const [tabValue, setTabValue] = useState(0);
  const handleChange = (event: React.ChangeEvent<unknown>, newValue: number) => {
    setTabValue(newValue);
  };

  const getSharedTagId = () => {
    for (let i = 0; i < dataLogs.me.tickets[0].tags.length; i++) {
      if (dataLogs.me.tickets[0].tags[i].__typename === 'ShareMaintenanceLogsTagType') {
        return dataLogs.me.tickets[0].tags[i].id;
      }
    }

    return '';
  };

  const handleClickCreate = async () => {
    const shareLogsTagId = getSharedTagId();
    if (!shareLogsTagId) {
      return;
    }
    await createMaintenancePdf({
      variables: {
        shareLogsTagId,
      },
    });
  };

  const downloadDocument = async (logbookId: string, maintenanceEntryId: string) => {
    if (!logbookId || !maintenanceEntryId) {
      return;
    }
    setLoadingDownloadUrl(true);
    const { data: dataDownloadUrl } = await apolloClient.query({
      query: QUERY_SHARED_LOG_DOCUMENT_DOWNLOAD_URL,
      variables: {
        ticketId: parseInt(id),
        logbookId: parseInt(logbookId),
        maintenanceEntryId: parseInt(maintenanceEntryId),
      },
      fetchPolicy: 'no-cache',
    });

    setLoadingDownloadUrl(false);

    if (dataDownloadUrl) {
      if (dataDownloadUrl?.me?.tickets[0]?.sharedLogs?.maintenanceLogbooks[0]?.maintenanceEntries[0]?.document?.downloadUrl) {
        downloadFileInNewTab(dataDownloadUrl.me?.tickets[0].sharedLogs.maintenanceLogbooks[0].maintenanceEntries[0].document.downloadUrl);
      }
    }
  };

  const previewDocument = async (logbookId: string, maintenanceEntryId: string) => {
    if (!logbookId || !maintenanceEntryId) {
      return;
    }
    setLoadingDownloadUrl(true);
    const { data: dataPreviewUrl } = await apolloClient.query({
      query: QUERY_SHARED_LOG_DOCUMENT_PREVIEW_URL,
      variables: {
        ticketId: parseInt(id),
        logbookId: parseInt(logbookId),
        maintenanceEntryId: parseInt(maintenanceEntryId),
      },
      fetchPolicy: 'no-cache',
    });

    setLoadingDownloadUrl(false);

    if (dataPreviewUrl) {
      if (dataPreviewUrl?.me?.tickets[0]?.sharedLogs?.maintenanceLogbooks[0]?.maintenanceEntries[0]?.document?.previewUrl) {
        setShowFileName(dataPreviewUrl.me?.tickets[0].sharedLogs.maintenanceLogbooks[0].maintenanceEntries[0].document.fileName);
        setShowFile(dataPreviewUrl.me?.tickets[0].sharedLogs.maintenanceLogbooks[0].maintenanceEntries[0].document.previewUrl);
        setShowPdfFileDialog(true);
      }
    }
  };

  const DataGridSx: CSSObject = {
    width: '100%',
    minWidth: '100px',
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    '& .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
    '& .MuiDataGrid-row': {
      cursor: 'pointer',
      borderBottom: '1px solid',
      borderColor: 'grey.200',
    },
    '& .MuiDataGrid-columnHeader:focus': {
      outline: 'none',
    },
    '& .MuiDataGrid-cellContent': {
      whiteSpace: 'break-spaces',
    },
    border: 'none',
  };

  const [pageSize, setPageSize] = useState(10);

  const [entryPageSize, setEntryPageSize] = useState(10);

  const getEntries = (logbooks: any[], id: string) => {
    for (let i = 0; i < logbooks.length; i++) {
      if (logbooks[i].id === id) {
        return logbooks[i].maintenanceEntries;
      }
    }

    return [];
  };

  useEffect(() => {
    if (id && parseInt(id)) {
      fetchSharedLogs({
        variables: {
          ticketId: parseInt(id),
        },
      });
    }
  }, [id, fetchSharedLogs]);

  useEffect(() => {
    if (error) {
      dispatch(setAlert('error', 'Unable to load shared logs'));
    }
  }, [error, dispatch]);

  useEffect(() => {
    if (errorPdf) {
      dispatch(setAlert('error', 'Unable to create logbooks pdf'));
    } else if (dataPdf) {
      const { ok, error } = dataPdf.createMaintenanceRecordsPdf;
      if (error) {
        dispatch(setAlert('error', error));
      } else if (ok) {
        dispatch(
          setAlert(
            'success',
            'PDF Logbook creation has started.  A post to this ticket will be generated when this is complete.  It may take a few minutes.',
          ),
        );
      }
    }
  }, [errorPdf, dataPdf, dispatch]);

  return (
    <LayoutDashboard backgroundColor="#fff">
      {loading && <PageLoading />}
      <ComponentLoading loading={loadingPdf}>
        <Box p={4}>
          <Typography variant="h2" gutterBottom>
            Logs for {dataLogs?.me?.tickets[0]?.sharedLogs?.aircraft?.registration || ''} (
            <Link
              href={`${PATH_TICKETS}/${id}`}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                ctrlCmdClick(e, `${PATH_TICKETS}/${id}`, history);
              }}
            >
              {dataLogs?.me?.tickets[0]?.subject}
            </Link>
            )
          </Typography>
          <Box py={1}>
            <Button variant="contained" onClick={handleClickCreate}>
              Create PDF logbook
            </Button>
          </Box>
          <Box py={1}>
            <Tabs
              value={tabValue}
              onChange={handleChange}
              aria-label="basic tabs example"
              sx={{
                '& .MuiTabs-indicator': {
                  backgroundColor: 'error.dark',
                },
                '& .MuiTabs-flexContainer': {
                  justifyContent: 'flex-start',
                },
              }}
              variant={'standard'}
            >
              <Tab
                label="Logbooks"
                {...a11yProps(0)}
                sx={{
                  py: 2,
                  px: 1,
                  '&.Mui-selected': {
                    color: 'error.dark',
                  },
                  fontSize: '16px',
                  lineHeight: '22px',
                  fontWeight: 600,
                }}
              />
              <Tab
                label="Maintenance Entries"
                {...a11yProps(1)}
                sx={{
                  py: 2,
                  px: 1,
                  '&.Mui-selected': {
                    color: 'error.dark',
                  },
                  fontSize: '16px',
                  lineHeight: '22px',
                  fontWeight: 600,
                }}
              />
            </Tabs>
            <Box>
              <SimpleTabPanel value={tabValue} index={0}>
                {dataLogs?.me?.tickets[0]?.sharedLogs && (
                  <Box py={1}>
                    <DataGridPro
                      sx={DataGridSx}
                      autoHeight
                      disableColumnResize={true}
                      disableSelectionOnClick={true}
                      rowsPerPageOptions={[5, 10, 25, 100]}
                      pageSize={pageSize}
                      onPageSizeChange={(newPage) => setPageSize(newPage)}
                      pagination
                      showColumnRightBorder={false}
                      disableColumnMenu={true}
                      rows={dataLogs?.me?.tickets[0]?.sharedLogs?.maintenanceLogbooks || []}
                      columns={[
                        {
                          field: 'name',
                          headerName: 'Name',
                          flex: 1,
                        },
                        {
                          field: 'entriesCount',
                          headerName: 'Entries',
                          flex: 1,
                        },
                      ]}
                      onRowClick={(params: GridRowParams) => {
                        const { row } = params;
                        if (row?.id) {
                          setSelectedLogbookId(row.id);
                          setTabValue(1);
                        }
                      }}
                    />
                  </Box>
                )}
              </SimpleTabPanel>
              <SimpleTabPanel value={tabValue} index={1}>
                {dataLogs?.me?.tickets[0]?.sharedLogs && (
                  <Box py={1}>
                    <Box>
                      <TextField
                        select
                        value={selectedLogbookId}
                        label="Select Logbook"
                        name="logbook"
                        onChange={(e) => {
                          setSelectedLogbookId(e.target.value);
                        }}
                        sx={{
                          mb: 0,
                          minWidth: '200px',
                        }}
                      >
                        {dataLogs?.me?.tickets[0]?.sharedLogs.maintenanceLogbooks.map((logbook: ILogbook, key: number) => {
                          if (logbook.deleted) {
                            return null;
                          }
                          return (
                            <MenuItem key={key} value={logbook.id}>
                              {logbook.name}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    </Box>
                    {selectedLogbookId && (
                      <Box py={1}>
                        <ComponentLoading loading={loadingDownloadUrl}>
                          <DataGridPro
                            sx={DataGridSx}
                            autoHeight
                            disableColumnResize={true}
                            disableSelectionOnClick={true}
                            rowsPerPageOptions={[5, 10, 25, 100]}
                            pageSize={entryPageSize}
                            onPageSizeChange={(newPage) => setEntryPageSize(newPage)}
                            pagination
                            showColumnRightBorder={false}
                            disableColumnMenu={true}
                            rows={getEntries(dataLogs?.me?.tickets[0]?.sharedLogs.maintenanceLogbooks, selectedLogbookId)}
                            columns={[
                              {
                                field: 'isPerformedMaintenance',
                                headerName: 'Maintenance',
                                valueGetter: (params: GridValueGetterParams<boolean>) => {
                                  return params.value ? 'Yes' : 'No';
                                },
                                flex: 0.7,
                              },
                              {
                                field: 'isPerformedInspection',
                                headerName: 'Inspection',
                                valueGetter: (params: GridValueGetterParams<boolean>) => {
                                  return params.value ? 'Yes' : 'No';
                                },
                                flex: 0.7,
                              },
                              {
                                field: 'document',
                                headerName: 'Document',
                                valueGetter: (params: GridValueGetterParams) => {
                                  return params.row.document?.fileName ? params.row.document?.fileName : '';
                                },
                                flex: 1,
                              },
                              {
                                field: 'maintenanceEntryCategory',
                                headerName: 'Record Type',
                                valueGetter: (params: GridValueGetterParams) => {
                                  return params.row?.maintenanceEntryCategory ? params.row.maintenanceEntryCategory.name : '';
                                },
                                flex: 0.7,
                              },
                              {
                                field: 'id',
                                headerName: 'Actions',
                                sortable: false,
                                flex: 0.8,
                                renderCell: (params: GridRenderCellParams<string>) => {
                                  return (
                                    <Box>
                                      <IconButton
                                        title="Preview"
                                        onClick={() => {
                                          if (params.row?.document) {
                                            previewDocument(selectedLogbookId, params.value || '');
                                          }
                                        }}
                                      >
                                        <VisibilityIcon />
                                      </IconButton>
                                      <IconButton
                                        title="Download"
                                        sx={{
                                          ml: 1,
                                        }}
                                        onClick={() => {
                                          if (params?.row?.document) {
                                            downloadDocument(selectedLogbookId, params.value || '');
                                          }
                                        }}
                                      >
                                        <DownloadIcon />
                                      </IconButton>
                                    </Box>
                                  );
                                },
                              },
                            ]}
                          />
                        </ComponentLoading>
                      </Box>
                    )}
                    {!selectedLogbookId && (
                      <Box py={2}>
                        <Typography
                          sx={{
                            color: 'error.main',
                          }}
                          align="center"
                        >
                          Please select logbook to view maintenance entries
                        </Typography>
                      </Box>
                    )}
                  </Box>
                )}
              </SimpleTabPanel>
            </Box>
          </Box>
        </Box>
      </ComponentLoading>
      <PdfDialog
        showFileDialog={showPdfFileDialog}
        setShowFileDialog={setShowPdfFileDialog}
        showFileName={showFileName}
        showFile={showFile}
        previewAttachmentId={''}
      />
    </LayoutDashboard>
  );
};

export default SharedLogs;
