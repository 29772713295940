export const MAX_ROW_TO_SHOW_STAGED_PAGINATION = 10;
export const MAX_DURATION_OF_SHORT_FLIGHT = 300;
export const FLIGHT_REPORT_SELECTOR = [
  {
    value: '0',
    label: 'Pick a value',
  },
  {
    value: 'Satisfactory',
    label: 'Satisfactory',
  },
  {
    value: 'Caution',
    label: 'Caution',
  },
  {
    value: 'Alert',
    label: 'Alert',
  },
  {
    value: 'N/A',
    label: 'N/A',
  },
];

export const FLIGHT_REPORT_NORMAL_MAX_LENGTH = 45;
export const FLIGHT_REPORT_LARGE_MAX_LENGTH = 175;
export const NOTIFICATION_BAR_ID = 'notification-bar';

export const FLIGHT_FILE_MAX_SIZE = 100;
export const RAW_SERIES_NAMES = true;
export const FLIGHT_NOTES_MAX_LENGTH = 500;
export const FLIGHT_DEPARTURE_MAX_LENGTH = 50;
export const FLIGHT_DESTINATION_MAX_LENGTH = 50;
