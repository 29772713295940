import React from 'react';

import { Stepper as MuiStepper, Step, StepLabel } from '@mui/material';

import { Subsection } from 'components';

interface IStepper {
  steps: string[];
  activeStep: number;
}

/*
  Usage:
  const steps = ['Sign Up', 'Payment', 'Additional Info'];
  ...
  const [activeStep, setActiveStep] = useState(0);
  ...
  <Stepper steps={steps} activeStep={activeStep} />
*/
const Stepper: React.FC<IStepper> = (props) => {
  const { steps, activeStep } = props;
  return (
    <Subsection>
      <MuiStepper activeStep={activeStep} alternativeLabel>
        {steps.map((item: string) => (
          <Step key={item}>
            <StepLabel>{item}</StepLabel>
          </Step>
        ))}
      </MuiStepper>
    </Subsection>
  );
};

export default Stepper;
