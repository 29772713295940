import { ALERT_SET, ALERT_REMOVE } from 'state/types';

import { IAlert } from 'types';

const initialState: IAlert[] = []; // Empty alerts array

const alertReducer = (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case ALERT_SET:
      return state.concat({
        id: payload.id,
        color: payload.color,
        content: payload.content,
      });
    case ALERT_REMOVE:
      return state.filter((alert) => alert.id !== payload.id); // Return existing alerts except alert with id to remove
    default:
      return state;
  }
};

export default alertReducer;
