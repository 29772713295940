import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setAlert } from 'state';
import { Typography, Box, FormHelperText } from '@mui/material';

import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { inputPadding, inputBorderColor, borderRadius, errorMain } from 'theme/variables';

import { useMutation, useQuery } from '@apollo/client';
import { MUTATION_CHANGE_CREDIT_CARD, QUERY_USER_CREDIT } from 'gql';
import { AbsoluteLoading, ButtonSubmit } from 'components';
import { PAYMENT_UPDATE_MESSAGE } from 'const';

const CreditCard: React.FC = () => {
  const { data, loading: loadingCredit, error } = useQuery(QUERY_USER_CREDIT);
  const dispatch = useDispatch();
  let cardInfo;

  if (error) {
    dispatch(setAlert('error', 'Unable to load your credit info.'));
  } else if (data) {
    if (data?.me?.creditCard) {
      cardInfo = data.me.creditCard;
    }
  }

  const [loading, setLoading] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const [updateToken, { data: dataUpdateToken, error: errorUpdateToken }] = useMutation(MUTATION_CHANGE_CREDIT_CARD, {
    errorPolicy: 'all',
  });

  // Stripe.js has not loaded yet. Make sure to disable form submission until Stripe.js has loaded.
  const stripeLoading = !stripe || !elements;

  const CARD_ELEMENT_OPTIONS = {
    style: {
      invalid: {
        color: errorMain,
        borderColor: errorMain,
      },
      base: {
        padding: inputPadding,
        border: `${inputBorderColor} 1px solid`,
        borderRadius: borderRadius,
      },
    },
  };

  const [stripeError, setStripeError] = useState('');

  const onChangeStripe = () => {
    setStripeError('');
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();

    if (stripe === null || elements === null) return;

    const cardElement = elements.getElement(CardElement);

    // Prevent Typescript errors if Stripe still loading
    if (cardElement === null) {
      return;
    }

    setLoading(true);

    const { error, token } = await stripe.createToken(cardElement);

    if (error) {
      const { message } = error;

      if (message) {
        setStripeError(message);
        setLoading(false);
        return;
      }
    } else if (token) {
      await updateToken({
        variables: {
          token: token.id,
        },
      });
    }
  };

  useEffect(() => {
    if (errorUpdateToken) {
      if (errorUpdateToken.graphQLErrors) setStripeError(errorUpdateToken.graphQLErrors[0].message);
      else setStripeError(errorUpdateToken.message);
      setLoading(false);
    } else if (dataUpdateToken) {
      const { changeCreditCardToken } = dataUpdateToken;

      if (changeCreditCardToken.ok) {
        setLoading(false);
        dispatch(setAlert('success', PAYMENT_UPDATE_MESSAGE));
      } else {
        dispatch(setAlert('error', changeCreditCardToken.error));
      }
    }
  }, [dataUpdateToken, errorUpdateToken, dispatch, setLoading]);

  return (
    <Box
      sx={{
        border: '1px solid',
        borderColor: 'grey.200',
        backgroundColor: 'background.default',
        position: 'relative',
      }}
    >
      <form onSubmit={onSubmit}>
        <Box
          pl={2.5}
          py={2.5}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderBottom: '1px solid #E8E9EF',
          }}
        >
          <Typography
            variant="h3"
            gutterBottom
            style={{
              marginBottom: 0,
              lineHeight: '27px',
              color: 'primaryDark',
              fontWeight: 700,
            }}
          >
            Credit Card
          </Typography>
        </Box>
        <Box px={2.5}>
          {cardInfo && (
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 400,
                py: 2.5,
              }}
            >
              We have a {cardInfo?.cardType} credit card ending with {cardInfo?.last4} on file for you.
              <br />
              It expires on {cardInfo?.expirationMonth}/{cardInfo?.expirationYear}.
            </Typography>
          )}
          {!cardInfo && (
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 400,
                py: 2.5,
              }}
            >
              We don't have a credit card on file for you.
            </Typography>
          )}
          <Box
            sx={{
              '& .StripeElement': {
                padding: inputPadding,
                border: `${inputBorderColor} 1px solid`,
                borderRadius: `${borderRadius}px`,
                '&.StripeElement--invalid': {
                  borderColor: errorMain,
                  color: errorMain,
                },
              },
            }}
          >
            <CardElement options={CARD_ELEMENT_OPTIONS} onChange={onChangeStripe} />
          </Box>
          {stripeError && <FormHelperText error>{stripeError}</FormHelperText>}
        </Box>
        <Box
          px={2.5}
          py={2.5}
          sx={{
            display: 'flex',
            justifyContent: 'end',
          }}
        >
          <Box
            sx={{
              maxWidth: '120px',
            }}
          >
            <ButtonSubmit variant="outlined" loading={loading || stripeLoading || loadingCredit} text="Update" />
          </Box>
        </Box>
      </form>
      <AbsoluteLoading loading={stripeLoading || loadingCredit} />
    </Box>
  );
};

export default CreditCard;
