import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Box, Typography, Link, Button, IconButton, Tooltip } from '@mui/material';
import { IServiceCenter, IClassification } from 'types';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import { PATH_INTERNAL_SERVICE_CENTER } from 'const';
import { ComponentLoading } from 'components';
import { getContactAddress, copyClipboardTextArea } from 'helpers';
import { setAlert } from 'state';

interface ServiceCenterInfoProps {
  selectedSC: IServiceCenter | undefined;
  loading: boolean;
  setSlideNum?: (value: number) => void;
  addShortList: (value: IServiceCenter) => void;
}

const ServiceCenterInfo: React.FC<ServiceCenterInfoProps> = (props) => {
  const { selectedSC, loading, setSlideNum, addShortList } = props;

  const history = useHistory();
  const dispatch = useDispatch();

  const getRating = (sc: IServiceCenter) => {
    if (sc.dashRating) {
      let sum = 0;
      sum +=
        sc.dashRating.communications.score +
        sc.dashRating.approval.score +
        sc.dashRating.quality.score +
        sc.dashRating.philosophy.score +
        sc.dashRating.cost.score;

      return (sum / 5).toFixed(2);
    }

    return 'None';
  };

  const getLastYearTickets = (sc: IServiceCenter) => {
    const now = new Date();
    const lastYear = new Date(`${now.getFullYear() - 1}-01-01`);

    if (!sc.tickets?.length) return 0;
    const lastYearTickets = sc.tickets.filter((ticket) => new Date(ticket.updated) >= lastYear);

    return lastYearTickets.length;
  };

  const copyClipboard = (sc: IServiceCenter) => {
    const str =
      sc.company +
      '\n' +
      (sc.street ? sc.street + '\n' : '') +
      (sc.city ? `${sc.city}, ${sc.state}, ${sc.zip}\n` : '') +
      (sc.country && sc.country !== 'US' ? sc.country + '\n' : '') +
      (sc.airportId ? 'Airport: ' + sc.airportId + '\n' : '') +
      sc.phoneWork;
    copyClipboardTextArea(str);
    dispatch(setAlert('success', 'Copied service center'));
  };

  const getSumOfAircraftType = (
    values: {
      name: string;
      count: number;
    }[],
  ) => {
    return values.reduce((a, b) => a + b.count, 0);
  };

  if (!selectedSC) return null;

  return (
    <Box py={1}>
      <ComponentLoading loading={loading}>
        {setSlideNum && (
          <Box py={1}>
            <Link
              href="#"
              onClick={(e: any) => {
                e.preventDefault();
                e.stopPropagation();
                setSlideNum(1);
              }}
            >
              {'<  '}Go to map
            </Link>
          </Box>
        )}
        <Box
          py={1}
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              width: '40%',
              fontWeight: 'bold',
            }}
          >
            Name:
          </Typography>
          <Box
            sx={{
              width: '60%',
            }}
          >
            <Typography
              sx={{
                display: 'inline-block',
              }}
            >
              {selectedSC.company} {getContactAddress(selectedSC) ? `(${getContactAddress(selectedSC)})` : ''}
            </Typography>
          </Box>
        </Box>
        <Box
          py={1}
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              width: '40%',
              fontWeight: 'bold',
            }}
          >
            Airport ID:
          </Typography>
          <Box
            sx={{
              width: '60%',
            }}
          >
            <Typography
              sx={{
                display: 'inline-block',
              }}
            >
              {selectedSC.airportId}
            </Typography>
          </Box>
        </Box>
        <Box
          py={1}
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              width: '40%',
              fontWeight: 'bold',
            }}
          >
            Ratings:
          </Typography>
          <Box
            sx={{
              width: '60%',
            }}
          >
            <Typography
              sx={{
                display: 'inline-block',
              }}
            >
              {getRating(selectedSC)}
            </Typography>
          </Box>
        </Box>
        <Box
          py={1}
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              width: '40%',
              fontWeight: 'bold',
            }}
          >
            Number of Tickets:
          </Typography>
          <Box
            sx={{
              width: '60%',
            }}
          >
            <Typography
              sx={{
                display: 'inline-block',
              }}
            >
              Total {selectedSC.tickets.length}, Last Year {getLastYearTickets(selectedSC)}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
          }}
        >
          <Box
            py={1}
            sx={{
              width: '40%',
            }}
          >
            <Typography
              sx={{
                fontWeight: 'bold',
              }}
            >
              Classifications
            </Typography>
          </Box>
          <Box
            sx={{
              width: '60%',
            }}
          >
            {!!selectedSC.classifications.length &&
              selectedSC.classifications.map((classification: IClassification, key: number) => {
                return (
                  <Box key={key} py={1}>
                    <Typography>{classification.name}</Typography>
                  </Box>
                );
              })}
            {!selectedSC.classifications.length && (
              <Box py={1}>
                <Typography>No classifications</Typography>
              </Box>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
          }}
        >
          <Box
            py={1}
            sx={{
              width: '40%',
            }}
          >
            <Typography
              sx={{
                fontWeight: 'bold',
              }}
            >
              Aircraft Types
            </Typography>
          </Box>
          <Box
            sx={{
              width: '60%',
            }}
          >
            {!!selectedSC.aircraftTypes.length &&
              selectedSC.aircraftTypes.map((aircraftType, key: number) => {
                return (
                  <Box key={key} py={1}>
                    <Tooltip
                      title={aircraftType.models.map((item, key1: number) => (
                        <Box key={`${key}_${key1}`} py={0.5}>
                          <Typography>
                            {item.name}: {item.count}
                          </Typography>
                        </Box>
                      ))}
                      PopperProps={{
                        sx: {
                          '& .MuiTooltip-tooltip': {
                            backgroundColor: 'background.default',
                            boxShadow:
                              '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
                            borderRadius: '6px !important',
                            '& p': {
                              color: 'text.primary',
                            },
                          },
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          cursor: 'pointer',
                          display: 'inline-block',
                        }}
                      >
                        {aircraftType.make}: {getSumOfAircraftType(aircraftType.models)}
                      </Typography>
                    </Tooltip>
                  </Box>
                );
              })}
            {!selectedSC.aircraftTypes.length && (
              <Box py={1}>
                <Typography>No aircraft types</Typography>
              </Box>
            )}
          </Box>
        </Box>
        <Box
          py={1}
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              width: '40%',
              fontWeight: 'bold',
            }}
          >
            Number of Comments:
          </Typography>
          <Box
            sx={{
              width: '60%',
            }}
          >
            <Typography
              sx={{
                display: 'inline-block',
              }}
            >
              {selectedSC.comments.length}
            </Typography>
          </Box>
        </Box>
        <Box
          py={1}
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Link
            href={PATH_INTERNAL_SERVICE_CENTER.replace(':id', selectedSC.id)}
            onClick={(e: any) => {
              e.preventDefault();
              e.stopPropagation();
              history.push(PATH_INTERNAL_SERVICE_CENTER.replace(':id', selectedSC.id));
            }}
          >
            Go To Service Center
          </Link>
          <IconButton
            sx={{
              ml: 1,
            }}
            onClick={() => {
              window.open(PATH_INTERNAL_SERVICE_CENTER.replace(':id', selectedSC.id), '_blank');
            }}
          >
            <OpenInNewIcon
              sx={{
                color: 'primary.main',
              }}
            />
          </IconButton>
        </Box>
        <Box py={1}>
          <Button
            variant="contained"
            onClick={() => {
              addShortList(selectedSC);
            }}
            sx={{
              width: '150px',
            }}
          >
            Add to Shortlist
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              copyClipboard(selectedSC);
            }}
            sx={{
              ml: 2,
              width: '150px',
            }}
          >
            Copy
          </Button>
        </Box>
      </ComponentLoading>
    </Box>
  );
};

export default ServiceCenterInfo;
