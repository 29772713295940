import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button } from '@mui/material';
import { useMutation } from '@apollo/client';
import { MUTATION_CREATE_SERVICE_ANNUAL_TAG } from 'gql';
import { setAlert } from 'state';

interface ServiceAnnualTagProps {
  handleClose: () => void;
  setLoading: (value: boolean) => void;
  ticketId: string;
}

const ServiceAnnualTag: React.FC<ServiceAnnualTagProps> = (props) => {
  const { ticketId, setLoading, handleClose } = props;

  const [createServiceAnnualTag, { data: dataServiceAnnualTag, error: errorServiceAnnualTag, loading: loadingServiceAnnualTag }] =
    useMutation(MUTATION_CREATE_SERVICE_ANNUAL_TAG);
  const dispatch = useDispatch();

  const onSubmit = async () => {
    await createServiceAnnualTag({
      variables: {
        ticketId,
      },
    });
  };

  useEffect(() => {
    if (errorServiceAnnualTag) {
      dispatch(setAlert('error', 'Unable to create service annual tag'));
    } else if (dataServiceAnnualTag && dataServiceAnnualTag.createServiceAnnualTag?.ok) {
      dispatch(setAlert('success', 'Created service annual tag'));
      handleClose();
    }
  }, [errorServiceAnnualTag, dataServiceAnnualTag, dispatch, handleClose]);

  useEffect(() => {
    setLoading(loadingServiceAnnualTag);
  }, [loadingServiceAnnualTag, setLoading]);

  return (
    <React.Fragment>
      <Box
        sx={{
          textAlign: 'right',
        }}
        py={1}
      >
        <Button
          variant="outlined"
          sx={{
            width: '100px',
            mr: 2,
          }}
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          sx={{
            width: '100px',
          }}
          onClick={() => {
            onSubmit();
          }}
        >
          Create
        </Button>
      </Box>
    </React.Fragment>
  );
};

export default ServiceAnnualTag;
