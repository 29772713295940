import React from 'react';
import { Box, TextField, MenuItem } from '@mui/material';

interface UbgMappingSeriesSelectorProps {
  seriesValue: string[];
  setSeriesValue: (value: string[]) => void;
  index: number;
  label: string;
}

const UbgMappingSeriesSelector: React.FC<UbgMappingSeriesSelectorProps> = (props) => {
  const { seriesValue, setSeriesValue, index, label } = props;

  const UBG_SERIES = [
    ['None', 'None'],
    ['EGT1', 'EGT1'],
    ['EGT2', 'EGT2'],
    ['EGT3', 'EGT3'],
    ['EGT4', 'EGT4'],
    ['EGT5', 'EGT5'],
    ['EGT6', 'EGT6'],
    ['EGT7', 'EGT7'],
    ['EGT8', 'EGT8'],
    ['L-EGT1', 'L-EGT1'],
    ['L-EGT2', 'L-EGT2'],
    ['L-EGT3', 'L-EGT3'],
    ['L-EGT4', 'L-EGT4'],
    ['L-EGT5', 'L-EGT5'],
    ['L-EGT6', 'L-EGT6'],
    ['L-EGT7', 'L-EGT7'],
    ['L-EGT8', 'L-EGT8'],
    ['R-EGT1', 'R-EGT1'],
    ['R-EGT2', 'R-EGT2'],
    ['R-EGT3', 'R-EGT3'],
    ['R-EGT4', 'R-EGT4'],
    ['R-EGT5', 'R-EGT5'],
    ['R-EGT6', 'R-EGT6'],
    ['R-EGT7', 'R-EGT7'],
    ['R-EGT8', 'R-EGT8'],
    ['CHT1', 'CHT1'],
    ['CHT2', 'CHT2'],
    ['CHT3', 'CHT3'],
    ['CHT4', 'CHT4'],
    ['CHT5', 'CHT5'],
    ['CHT6', 'CHT6'],
    ['CHT7', 'CHT7'],
    ['CHT8', 'CHT8'],
    ['L-CHT1', 'L-CHT1'],
    ['L-CHT2', 'L-CHT2'],
    ['L-CHT3', 'L-CHT3'],
    ['L-CHT4', 'L-CHT4'],
    ['L-CHT5', 'L-CHT5'],
    ['L-CHT6', 'L-CHT6'],
    ['L-CHT7', 'L-CHT7'],
    ['L-CHT8', 'L-CHT8'],
    ['R-CHT1', 'R-CHT1'],
    ['R-CHT2', 'R-CHT2'],
    ['R-CHT3', 'R-CHT3'],
    ['R-CHT4', 'R-CHT4'],
    ['R-CHT5', 'R-CHT5'],
    ['R-CHT6', 'R-CHT6'],
    ['R-CHT7', 'R-CHT7'],
    ['R-CHT8', 'R-CHT8'],
    ['TIT1', 'TIT1'],
    ['R-TIT1', 'R-TIT1'],
    ['L-TIT1', 'L-TIT1'],
    ['TIT2', 'TIT2'],
    ['R-TIT2', 'R-TIT2'],
    ['L-TIT2', 'L-TIT2'],
    ['LAT', 'LAT'],
    ['LON', 'LON'],
    ['OAT', 'OAT'],
    ['RPM', 'RPM'],
    ['L-RPM', 'L-RPM'],
    ['R-RPM', 'R-RPM'],
    ['MAP', 'MAP'],
    ['L-MAP', 'L-MAP'],
    ['R-MAP', 'R-MAP'],
    ['HP', 'HP'],
    ['L-HP', 'L-HP'],
    ['R-HP', 'R-HP'],
    ['FF', 'FF'],
    ['L-FF', 'L-FF'],
    ['R-FF', 'R-FF'],
    ['OIL_TEMP', 'OIL_TEMP'],
    ['L-OIL_TEMP', 'L-OIL_TEMP'],
    ['R-OIL_TEMP', 'R-OIL_TEMP'],
    ['OIL_PRESS', 'OIL_PRESS'],
    ['L-OIL_PRESS', 'L-OIL_PRESS'],
    ['R-OIL_PRESS', 'R-OIL_PRESS'],
    ['BAT', 'BAT'],
    ['AMP', 'AMP'],
    ['CARB_TEMP', 'CARB_TEMP'],
    ['L-CARB_TEMP', 'L-CARB_TEMP'],
    ['R-CARB_TEMP', 'R-CARB_TEMP'],
    ['ALT', 'ALT'],
  ];

  return (
    <Box py={1}>
      <TextField
        select
        label={label}
        value={seriesValue[index]}
        onChange={(e) => {
          seriesValue[index] = e.target.value;
          setSeriesValue([...seriesValue]);
        }}
        fullWidth
      >
        {UBG_SERIES.map((item: string[], key: number) => {
          return (
            <MenuItem key={key} value={item[0]}>
              {item[1]}
            </MenuItem>
          );
        })}
      </TextField>
    </Box>
  );
};

export default UbgMappingSeriesSelector;
