import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { Snackbar } from '@mui/material';
import Alert from '@mui/material/Alert';

import { removeAlert } from 'state';

import { IAlert } from 'types';

const Alerts: React.FC = () => {
  const dispatch = useDispatch();
  const alerts = useSelector((state: any) => state.alert); // Access alert state

  // Opened if alerts exist
  const [open, setOpen] = useState(false);
  useEffect(() => {
    setOpen(alerts.length > 0);
  }, [alerts]);

  return (
    <Snackbar open={open} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} disableWindowBlurListener>
      <div>
        {alerts.map((alert: IAlert) => (
          <Alert key={alert.id} id={alert.id} severity={alert.color} variant="filled" onClose={() => dispatch(removeAlert(alert.id))}>
            {alert.content}
          </Alert>
        ))}
      </div>
    </Snackbar>
  );
};

export default Alerts;
