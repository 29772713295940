import React from 'react';

import { LayoutDashboard } from 'components';
import { BorescopeOwnAnalysisReports } from './components';

const CreateOwnBorescopeAnalysisReports: React.FC = () => {
  return (
    <LayoutDashboard backgroundColor="#fff">
      <BorescopeOwnAnalysisReports />
    </LayoutDashboard>
  );
};

export default CreateOwnBorescopeAnalysisReports;
