import React, { Fragment } from 'react';

import { FormHelperText } from '@mui/material';

import { IInputError } from 'types';

interface IInputErrorUI {
  error: IInputError;
  hasError: boolean;
}

const InputError: React.FC<IInputErrorUI> = (props) => {
  const { error, hasError } = props;
  return (
    <Fragment>
      {
        // Check is error an array of strings (multiple errors)
        hasError && (
          <FormHelperText
            style={{
              color: 'red',
            }}
            error
          >
            {
              // If multiple errors (array)
              Array.isArray(error)
                ? error.map((error: any) => (
                    <Fragment>
                      {error}
                      <br />
                    </Fragment>
                  ))
                : // If single string/component error
                  error
            }
          </FormHelperText>
        )
      }
    </Fragment>
  );
};

export default InputError;
