import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Typography, Badge } from '@mui/material';
import { DataGridPro, GridRenderCellParams, GridToolbar, GridRowParams } from '@mui/x-data-grid-pro';

import { PATH_TICKETS_VIEW, PTD_ROW_HEIGHT } from 'const';
import { getGapTime, ctrlCmdClick } from 'helpers';

interface IPersonalTicket {
  id: string;
  hasUnread: boolean;
  subject: string;
  client: string;
  updated: Date;
  poster: string;
  state: string;
  tags: any;
  firstUnreadDatetime: Date;
  color: string;
}

interface IGroup {
  name: string;
  description: string;
  tickets: IPersonalTicket[];
}

interface TicketTableProps {
  group: IGroup;
  checkUnread: boolean;
}

const TicketTable: React.FC<TicketTableProps> = (props) => {
  const { group, checkUnread } = props;
  const history = useHistory();

  const [pageSize, setPageSize] = useState(20);

  useEffect(() => {
    if (group) {
      setPageSize(group.tickets.length);
    }
  }, [group]);

  return (
    <Box>
      <Box
        py={2}
        px={2.5}
        sx={{
          backgroundColor: 'grey.200',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{
            fontSize: '18px',
            fontWeight: 'bold',
            lineHeight: '25px',
            mr: 2.5,
          }}
        >
          {group.name}
        </Typography>
        <Box
          component="span"
          sx={{
            fontSize: '12px',
            lineHeight: '16px',
          }}
        >
          {group.description}
        </Box>
      </Box>
      <Box py={1} px={2.5}>
        <DataGridPro
          sx={{
            width: '100%',
            minWidth: '100px',
            '& .MuiDataGrid-cell:focus': {
              outline: 'none',
            },
            '& .MuiDataGrid-row': {
              cursor: 'pointer',
              borderBottom: '1px solid',
              borderColor: 'grey.200',
            },
            '& .MuiDataGrid-columnHeader:focus': {
              outline: 'none',
            },
            '& .MuiDataGrid-cellContent': {
              whiteSpace: 'break-spaces',
            },
            '& .MuiDataGrid-toolbarContainer': {
              '& .MuiTextField-root': {
                mb: 0,
              },
              '& button': {
                display: 'none',
              },
            },
            '& .MuiDataGrid-columnHeader:nth-of-type(1)': {
              px: 0,
            },
            border: 'none',
          }}
          autoHeight
          rowsPerPageOptions={[group.tickets.length, 10, 20, 40, 100]}
          pageSize={pageSize}
          onPageSizeChange={(newPage) => setPageSize(newPage)}
          pagination
          showColumnRightBorder={false}
          disableColumnSelector
          disableDensitySelector
          disableColumnMenu
          disableSelectionOnClick
          getRowHeight={() => PTD_ROW_HEIGHT}
          rows={[...group.tickets].filter((ticket: IPersonalTicket) => !checkUnread || (checkUnread && ticket.hasUnread))}
          columns={[
            {
              field: 'unreadCount',
              headerName: 'Unread Count',
              renderCell: (params: GridRenderCellParams<number>) => {
                if (!params?.value) {
                  return '';
                }
                return (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '100%',
                    }}
                  >
                    <Badge badgeContent={params.value} color="primary"></Badge>
                  </Box>
                );
              },
              flex: 0.4,
            },
            {
              field: 'subject',
              headerName: 'Subject',
              flex: 0.8,
            },
            {
              field: 'client',
              headerName: 'Client / Aircraft',
              flex: 1,
            },
            {
              field: 'updated',
              headerName: 'Updated',
              valueFormatter: (params) => {
                return params?.value ? getGapTime(params.value) : '';
              },
              flex: 0.7,
            },
            {
              field: 'firstUnreadDatetime',
              headerName: 'Last Update / First Unread',
              valueFormatter: (params) => {
                return params?.value ? params.value.toString().substring(0, 10) : '';
              },
              flex: 1,
            },
            {
              field: 'poster',
              headerName: 'Last Poster',
              flex: 0.7,
            },
          ]}
          onRowClick={(params: GridRowParams, event?: any) => {
            const { row } = params;
            if (row.id) {
              event?.preventDefault();
              event?.stopPropagation();
              ctrlCmdClick(event, `${PATH_TICKETS_VIEW}/${row.id}`, history);
            } else {
              return false;
            }
          }}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
            },
            filterPanel: {
              sx: {
                '& .MuiDataGrid-filterForm': {
                  '& .MuiTextField-root': {
                    mb: 0,
                  },
                },
              },
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default TicketTable;
