import { gql } from '@apollo/client';

export const QUERY_USER_FLIGHTS_PER_AIRCRAFT = gql`
  query FlightsByAircraft($id: Int, $hideShortFlights: Boolean) {
    me {
      id
      aircraft(id: $id) {
        id
        flights(hideShortFlights: $hideShortFlights) {
          id
          date
          duration
          publicUuid
          departureId
          destinationId
          departureName
          destinationName
          userDepartureName
          userDestinationName
          maxChtTemperature
          departureAirport
          destinationAirport
          importFile {
            id
            name
            uploadDate
          }
          chtTemperatureAlert
        }
      }
    }
  }
`;

export const QUERY_USER_FLIGHTS_PER_FILE = gql`
  query FlightsByFile($id: Int, $engineDataFileId: ID) {
    me {
      id
      aircraft(id: $id) {
        id
        engineDataFiles(engineDataFileId: $engineDataFileId) {
          id
          name
          uploadDate
          flights {
            id
            date
            duration
            publicUuid
            departureId
            destinationId
            departureName
            destinationName
            userDepartureName
            userDestinationName
            maxChtTemperature
            departureAirport
            destinationAirport
            importFile {
              id
              name
              uploadDate
            }
            chtTemperatureAlert
          }
        }
      }
    }
  }
`;

export const QUERY_USER_FLIGHTS = gql`
  query MeFlights {
    me {
      id
      aircraft {
        id
        registration
        aircraftManufacturer {
          id
          name
        }
        aircraftModel {
          id
          name
        }
        flights {
          id
          date
          duration
          publicUuid
          departureId
          destinationId
          departureName
          destinationName
          userDepartureName
          userDestinationName
          maxChtTemperature
          departureAirport
          destinationAirport
          importFile {
            id
            name
            uploadDate
          }
        }
      }
    }
  }
`;

export const QUERY_FLIGHT_DETAIL_BY_ID = gql`
  query MeFlightDetailById($id: Int, $serieNames: [String]) {
    me {
      id
      flight(id: $id) {
        id
        date
        data(serieNames: $serieNames)
        aircraft {
          id
          registration
          aircraftManufacturer {
            id
            name
          }
          aircraftModel {
            id
            name
            enginesCount
          }
          serial
          year
          engineManufacturer {
            id
            name
          }
          engineModel {
            id
            name
          }
          engineMonitorManufacturer {
            id
            name
          }
          engineMonitorModel {
            id
            name
          }
          cylinderCount
          chtWarningTemperature
          subscriptions {
            id
            serviceName
            serviceCode
            status
          }
          owner {
            id
          }
        }
        duration
        publicUuid
        departureId
        destinationId
        departureName
        destinationName
        userDepartureName
        userDestinationName
        maxChtTemperature
        departureAirport
        destinationAirport
        importFile {
          id
          name
          uploadDate
        }
        notes
        initialSeries {
          chartNumber
          seriesSelector
          seriesNames
        }
        canDownloadEdf
        previousFlight {
          id
        }
        nextFlight {
          id
        }
        flightData {
          samplingRate
        }
      }
    }
  }
`;

export const QUERY_FLIGHT_CHART_SCALE_SETTINGS = gql`
  query MeFlightChartScaleSettings($id: Int) {
    me {
      id
      flight(id: $id) {
        id
        aircraft {
          id
          settings {
            id
            seriesName
            scaleMin
            scaleMax
            enabled
          }
        }
      }
    }
  }
`;

export const QUERY_FLIGHT_CHART_DETAIL_BY_ID = gql`
  query MeFlightChartById($id: Int, $serieNames: [String]) {
    me {
      id
      flight(id: $id) {
        id
        data(serieNames: $serieNames)
      }
    }
  }
`;

export const QUERY_SHARED_FLIGHT_BY_ID = gql`
  query SharedFlight($flightId: ID, $flightUuid: UUID) {
    sharedFlight(flightId: $flightId, flightUuid: $flightUuid) {
      id
      date
      data
      aircraft {
        registration
        aircraftManufacturer {
          id
          name
        }
        aircraftModel {
          id
          name
          enginesCount
        }
        serial
        year
        engineManufacturer {
          id
          name
        }
        engineModel {
          id
          name
        }
        engineMonitorManufacturer {
          id
          name
        }
        engineMonitorModel {
          id
          name
        }
        cylinderCount
      }
      duration
      publicUuid
      departureId
      destinationId
      departureName
      destinationName
      userDepartureName
      userDestinationName
      maxChtTemperature
      departureAirport
      destinationAirport
      initialSeries {
        chartNumber
        seriesSelector
        seriesNames
      }
    }
  }
`;

export const QUERY_FLIGHT_AIRCRAFT_RECENT_SERIES = gql`
  query FlightAircraftRecentSeries($id: Int, $rawSeriesNames: Boolean) {
    me {
      id
      flight(id: $id) {
        id
        aircraft {
          id
          recentFlightSeries(rawSeriesNames: $rawSeriesNames)
        }
      }
    }
  }
`;

export const QUERY_SHARED_FLIGHT_CHART_DETAIL_BY_ID = gql`
  query SharedFlightChartDetail($flightId: ID, $flightUuid: UUID, $serieNames: [String]) {
    sharedFlight(flightId: $flightId, flightUuid: $flightUuid) {
      id
      data(serieNames: $serieNames)
    }
  }
`;

export const QUERY_CONTACT_AIRCRAFT_FLIGHTS = gql`
  query GetUserAircraftFlights($contactId: ID, $aircraftId: Int) {
    savvy {
      id
      contacts(contactId: $contactId) {
        id
        aircraft(id: $aircraftId) {
          id
          flights {
            id
            date
            duration
            publicUuid
            departureId
            destinationId
            departureName
            destinationName
            userDepartureName
            userDestinationName
            maxChtTemperature
            departureAirport
            destinationAirport
            importFile {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const QUERY_FLIGHT_CHART_SETTINGS = gql`
  query FlightPanelSettings {
    me {
      id
      settings {
        isFlightPanelVisible
        chartsNumber
        chartsSize
      }
    }
  }
`;

export const QUERY_PINNED_COMMENTS_BY_FLIGHT = gql`
  query PinnedCommentsByFlight($id: Int) {
    me {
      id
      flight(id: $id) {
        aircraft {
          id
          comments {
            id
            pinned
            comment
            date
          }
          owner {
            comments {
              id
              pinned
              comment
              date
            }
          }
        }
      }
    }
  }
`;

export const QUERY_SEARCH_FLIGHTS = gql`
  query SearchFlights($search: String, $offset: Int, $limit: Int) {
    searchFlights(search: $search, offset: $offset, limit: $limit) {
      id
      destinationAirport
      departureAirport
      destinationName
      departureName
      date
      duration
    }
  }
`;

export const QUERY_SHARE_FLIGHTS_BY_AIRCRAFT = gql`
  query ShareFlightsByAircraft($aircraftUuid: String) {
    shareFlightsByAircraft(aircraftUuid: $aircraftUuid) {
      id
      aircraft {
        registration
        serial
        aircraftManufacturer {
          name
        }
        aircraftModel {
          name
        }
      }
      date
      duration
      publicUuid
      departureName
      destinationName
      departureAirport
      destinationAirport
    }
  }
`;

export const QUERY_SHARE_FLIGHTS_BY_CONTACT = gql`
  query ShareFlightsByContact($contactUuid: String) {
    shareFlightsByContact(contactUuid: $contactUuid) {
      id
      aircraft {
        registration
        serial
        aircraftManufacturer {
          name
        }
        aircraftModel {
          name
        }
      }
      date
      duration
      publicUuid
      departureName
      destinationName
      departureAirport
      destinationAirport
    }
  }
`;

export const QUERY_EDF_DOWNLOAD_URL = gql`
  query EdfDownloadUrl($fileId: Int) {
    edfDownloadUrl(fileId: $fileId)
  }
`;

export const QUERY_DOWNLOAD_FLIGHT_URL = gql`
  query DownloadFlightUrl($flightId: Int) {
    downloadFlightUrl(flightId: $flightId)
  }
`;

export const QUERY_KML_DATA = gql`
  query KmlData($flightId: Int) {
    kmlData(flightId: $flightId) {
      flight {
        id
        date
        aircraft {
          id
          registration
        }
      }
      points {
        lat
        lon
        alt
      }
    }
  }
`;
