import React from 'react';
import { Box, Collapse, Typography } from '@mui/material';
import { ICylinderAndSubject, IBorescopeImageFileType } from 'types';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckIcon from '@mui/icons-material/Check';

interface CylinderListProps {
  subjectList: string[][];
  cylinderCount: number;
  selectedIndex: ICylinderAndSubject;
  setSelectedIndex: (value: ICylinderAndSubject) => void;
  uploadedFiles: IBorescopeImageFileType[];
}

const CylinderList: React.FC<CylinderListProps> = (props) => {
  const { subjectList, cylinderCount, selectedIndex, setSelectedIndex, uploadedFiles } = props;

  const isFilledCylinderSubject = (cylinder: number, subject: string) => {
    const matched = uploadedFiles.find((file) => file.cylinder === cylinder && file.subject === subject);
    return Boolean(matched);
  };

  const isFilledCylinder = (cylinder: number) => {
    for (let i = 0; i < subjectList.length; i++) {
      if (!isFilledCylinderSubject(cylinder, subjectList[i][0])) {
        return false;
      }
    }

    return true;
  };

  return (
    <Box>
      {Array.apply(null, Array(cylinderCount)).map((item, key: number) => {
        return (
          <Box key={key}>
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  px: 2,
                  backgroundColor: selectedIndex.cylinder === key + 1 ? 'rgba(75, 84, 112, 0.1)' : 'none',
                  cursor: 'pointer',
                  py: 1,
                }}
                onClick={() => {
                  setSelectedIndex({
                    cylinder: key + 1,
                    subject: subjectList[0][0],
                  });
                }}
              >
                {isFilledCylinder(key + 1) ? (
                  <CheckCircleIcon
                    sx={{
                      color: 'success.main',
                    }}
                  />
                ) : (
                  <CircleOutlinedIcon
                    sx={{
                      color: 'grey.600',
                    }}
                  />
                )}
                <Typography
                  sx={{
                    color: 'text.secondary',
                    fontWeight: 700,
                    fontSize: '16px',
                    lineHeight: '22px',
                    ml: 2,
                  }}
                >
                  Cylinder {key + 1}
                </Typography>
              </Box>
              <Collapse in={selectedIndex.cylinder === key + 1}>
                <Box>
                  {subjectList.map((item: string[], index: number) => {
                    return (
                      <Box
                        key={index}
                        sx={{
                          cursor: 'pointer',
                          backgroundColor:
                            selectedIndex.cylinder === key + 1 && selectedIndex.subject === item[0] ? 'rgba(75, 84, 112, 0.1)' : 'none',
                          display: 'flex',
                          py: 0.5,
                          alignItems: 'center',
                        }}
                        onClick={() => {
                          setSelectedIndex({
                            cylinder: key + 1,
                            subject: item[0],
                          });
                        }}
                      >
                        <Box
                          sx={{
                            width: '80px',
                            px: 1,
                            textAlign: 'right',
                          }}
                        >
                          {isFilledCylinderSubject(key + 1, item[0]) && (
                            <CheckIcon
                              sx={{
                                color: 'success.main',
                              }}
                            />
                          )}
                        </Box>
                        <Typography
                          sx={{
                            fontWeight: selectedIndex.cylinder === key + 1 && selectedIndex.subject === item[0] ? '700' : '400',
                            color:
                              selectedIndex.cylinder === key + 1 && selectedIndex.subject === item[0]
                                ? 'text.primary'
                                : 'rgba(75, 84, 112, 0.7)',
                            fontSize: '15px',
                            lineHeight: '24px',
                            textTransform: 'capitalize',
                          }}
                        >
                          {item[1]}
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
              </Collapse>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default CylinderList;
