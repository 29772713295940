import React, { useEffect } from 'react';

import { Box, Grid, Typography, Button, Container } from '@mui/material';
import { IServiceCenter } from 'types';

import { Form, Formik } from 'formik';
import { ComponentLoading, ButtonSubmit } from 'components';
import { ClassificationType } from 'pages/Internal/components';

import { useLazyQuery } from '@apollo/client';
import { QUERY_SERVICE_CENTER_CLASSIFICATION_CATEGORIES, QUERY_SERVICE_CENTER_CLASSIFICATIONS } from 'gql';

import { Field } from 'formik';
import { CheckboxWithLabel } from 'formik-mui';
import { IServiceCenterClassificationCategory } from 'types';

interface ClassificationsTabProps {
  serviceCenter: IServiceCenter | undefined;
  setServiceCenter: (value: IServiceCenter | undefined) => void;
  setSlideNum: (value: number) => void;
  setClickedBack: (value: boolean) => void;
  selectedClassifications: string[];
  setSelectedClassifications: (value: string[]) => void;
}

type CheckBoxLabelProps = {
  id: string;
  value: string;
  group: string;
};

const CheckBoxLabel: React.FC<CheckBoxLabelProps> = (props) => {
  return <Field type="checkbox" name="classifications" value={props.id} component={CheckboxWithLabel} Label={{ label: props.value }} />;
};

const ClassificationsTab: React.FC<ClassificationsTabProps> = (props) => {
  const { serviceCenter = undefined, setSlideNum, setClickedBack, selectedClassifications, setSelectedClassifications } = props;

  const [fetchCategories, { data: dataCategories, loading: loadingCategories }] = useLazyQuery(
    QUERY_SERVICE_CENTER_CLASSIFICATION_CATEGORIES,
  );
  const [fetchClassifications, { data: dataClassifications, loading: loadingClassifications }] = useLazyQuery(
    QUERY_SERVICE_CENTER_CLASSIFICATIONS,
  );

  useEffect(() => {
    if (serviceCenter) {
      fetchCategories();
      fetchClassifications();
    }
  }, [fetchCategories, fetchClassifications, serviceCenter]);

  if (!serviceCenter) {
    return null;
  }

  return (
    <Box p={4}>
      <ComponentLoading loading={loadingCategories || loadingClassifications}>
        <Box>
          <Formik
            enableReinitialize
            initialValues={{
              classifications: selectedClassifications,
            }}
            onSubmit={async (values) => {
              setSelectedClassifications([...values.classifications]);
              setSlideNum(2);
            }}
          >
            {({ isSubmitting, values }) => {
              return (
                <Form>
                  {!loadingCategories && !loadingClassifications && (
                    <Grid container spacing={2}>
                      {!!dataCategories?.serviceCenterClassificationCategories?.length &&
                        [...dataCategories.serviceCenterClassificationCategories]
                          .sort((category1: IServiceCenterClassificationCategory, category2: IServiceCenterClassificationCategory) => {
                            if (category1.order && category2.order) {
                              return category1.order < category2.order ? -1 : 1;
                            } else if (category1.order) {
                              return -1;
                            } else if (category2.order) {
                              return 1;
                            } else {
                              return -1;
                            }
                          })
                          .map((category: IServiceCenterClassificationCategory) => {
                            const sortClassifications = dataClassifications?.serviceCenterClassifications
                              ? [...dataClassifications.serviceCenterClassifications]
                              : [];

                            const classifications = sortClassifications
                              .sort((item1: ClassificationType, item2: ClassificationType) => {
                                if (item1.order && item2.order) {
                                  return item1.order < item2.order ? -1 : 1;
                                } else if (item1.order) {
                                  return -1;
                                } else if (item2.order) {
                                  return 1;
                                } else {
                                  return -1;
                                }
                              })
                              ?.filter((x: ClassificationType) => x.category === category.name)
                              ?.map((classification: ClassificationType) => {
                                return (
                                  <CheckBoxLabel
                                    key={classification.id}
                                    id={classification.id}
                                    group={category.name}
                                    value={classification.name}
                                  />
                                );
                              });
                            const filterClassifications = classifications.filter((item: React.ReactNode | null) => item !== null);
                            if (filterClassifications.length) {
                              return (
                                <Grid item key={category.id} md={2}>
                                  <Box pb={1}>
                                    <Typography variant="h5">{category.name}</Typography>
                                  </Box>
                                  <Box
                                    role="group"
                                    aria-labelledby="checkbox-group"
                                    key={category.id}
                                    sx={{
                                      display: 'flex',
                                      flexFlow: 'column',
                                    }}
                                  >
                                    {filterClassifications}
                                  </Box>
                                </Grid>
                              );
                            } else {
                              return null;
                            }
                          })}
                    </Grid>
                  )}
                  <Box py={2}>
                    <Container maxWidth="md" fixed>
                      <Grid container spacing={2}>
                        <Grid item md={6} xs={6}>
                          <ButtonSubmit text="Next" loading={isSubmitting} />
                        </Grid>
                        <Grid item md={6} xs={6}>
                          <Button
                            variant="outlined"
                            onClick={() => {
                              setSelectedClassifications([...values.classifications]);
                              setClickedBack(true);
                              setSlideNum(0);
                            }}
                            fullWidth
                          >
                            Back
                          </Button>
                        </Grid>
                      </Grid>
                    </Container>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </ComponentLoading>
    </Box>
  );
};

export default ClassificationsTab;
