import { SET_TABLE_VIEW_CONFIG } from 'state/types';

// Initial state (user data)
const initialState = {
  density: 'standard',
};

// Reducer
const tableConfigReducer = (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    // Login
    case SET_TABLE_VIEW_CONFIG:
      return {
        ...state,
        density: payload.density,
      };
    default:
      return state;
  }
};

export default tableConfigReducer;
