import React, { useState } from 'react';
import { Box, Typography, Popover, TextField, MenuItem } from '@mui/material';
import { AppearanceIcon } from 'customIcons';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { IChartVisibleSetting, IUnitConversion } from 'types';
import { CHART_VISIBLE_NORMAL, CHART_VISIBLE_BOLD, CHART_VISIBLE_HIDE, CHART_VISIBLE_ISOLATE } from 'const';

import { getSerieColor } from 'helpers';

interface AppearanceSetsProps {
  appearanceSets: string[];
  chartSettings: IChartVisibleSetting[];
  setChartSettings: (value: IChartVisibleSetting[]) => void;
  conversions: IUnitConversion[];
  setConversion: (value: IUnitConversion | undefined) => void;
  conversion: IUnitConversion | undefined;
}

const AppearanceSets: React.FC<AppearanceSetsProps> = (props) => {
  const { appearanceSets, chartSettings, setChartSettings, conversions, setConversion, conversion } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (appearanceSets.length === 0) {
      setAnchorEl(null);
      return;
    }
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const chartVisibleOptions = [CHART_VISIBLE_NORMAL, CHART_VISIBLE_BOLD, CHART_VISIBLE_HIDE, CHART_VISIBLE_ISOLATE];

  const existSetting = (category: string, value: string) => {
    // return true;
    for (let i = 0; i < chartSettings.length; i++) {
      if (chartSettings[i].name === category && chartSettings[i].value === value) {
        return true;
      }
    }

    return false;
  };

  const existAllSetting = (value: string) => {
    if (!chartSettings.length) {
      return false;
    }

    if (chartSettings.length === 1) {
      if (chartSettings[0].value === value) {
        return true;
      }
      return false;
    }

    if (chartSettings.length !== appearanceSets.length) {
      return false;
    }

    for (let i = 0; i < chartSettings.length - 1; i++) {
      if (chartSettings[i].value !== chartSettings[i + 1].value || chartSettings[i].value !== value) {
        return false;
      }
    }

    return true;
  };

  const existOddSetting = (value: string) => {
    if (existAllSetting(value)) return false;
    const series_match = new RegExp(/(?:[LR]-)?(EGT|CHT|TIT)\s*(\d)/);

    if (!chartSettings.length) return false;

    for (let i = 0; i < chartSettings.length; i++) {
      const match = series_match.exec(chartSettings[i].name);

      if (!match) return false;

      if (parseFloat(match[2]) % 2 === 1) {
        if (chartSettings[i].value !== value) return false;
      } else {
        return false;
      }
    }

    return true;
  };

  const existEvenSetting = (value: string) => {
    if (existAllSetting(value)) return false;
    const series_match = new RegExp(/(?:[LR]-)?(EGT|CHT|TIT)\s*(\d)/);

    if (!chartSettings.length) return false;

    for (let i = 0; i < chartSettings.length; i++) {
      const match = series_match.exec(chartSettings[i].name);

      if (!match) return false;

      if (parseFloat(match[2]) % 2 === 0) {
        if (chartSettings[i].value !== value) return false;
      } else {
        return false;
      }
    }

    return true;
  };

  const addSetting = (category: string, value: string) => {
    let exists = false;

    if (category === 'all') {
      let tmp: IChartVisibleSetting[] = [];

      tmp = appearanceSets.map((appearanceSet: string) => {
        return {
          name: appearanceSet,
          value: value,
        };
      });

      setChartSettings([...tmp]);
      return;
    }

    const series_match = new RegExp(/(?:[LR]-)?(EGT|CHT|TIT)\s*(\d)/);

    if (category === 'odd' || category === 'even') {
      let tmpChartSettings = [...chartSettings];

      if (value === CHART_VISIBLE_ISOLATE) {
        tmpChartSettings = [];
      }

      appearanceSets.map((appearanceSet: string) => {
        const match = series_match.exec(appearanceSet);
        if (!match) return null;

        if (parseInt(match[2]) % 2 === (category === 'odd' ? 1 : 0)) {
          let isExistingSerie = false;
          for (let i = 0; i < tmpChartSettings.length; i++) {
            if (tmpChartSettings[i].name === appearanceSet) {
              tmpChartSettings[i].value = value;
              isExistingSerie = true;
            }
          }

          if (!isExistingSerie) {
            tmpChartSettings.push({
              name: appearanceSet,
              value: value,
            });
          }

          return null;
        } else {
          return null;
        }
      });

      setChartSettings([...tmpChartSettings]);
      return;
    }

    if (value === CHART_VISIBLE_ISOLATE) {
      const tmpChartSettings: IChartVisibleSetting[] = [];
      tmpChartSettings.push({
        name: category,
        value: CHART_VISIBLE_ISOLATE,
      });

      setChartSettings([...tmpChartSettings]);
      return;
    }

    for (let i = 0; i < chartSettings.length; i++) {
      if (chartSettings[i].name === category) {
        chartSettings[i].value = value;
        exists = true;
      }
    }

    if (!exists) {
      chartSettings.push({
        name: category,
        value: value,
      });
    }

    setChartSettings([...chartSettings]);
  };

  return (
    <React.Fragment>
      <Box
        px={2}
        sx={{
          display: 'flex',
          alignItems: 'center',
          borderLeft: '1px solid',
          borderColor: 'text.primary',
          cursor: 'pointer',
        }}
        onClick={handleClick}
      >
        <AppearanceIcon
          sx={{
            width: '18px',
            height: '15px',
            mr: 1,
          }}
        />
        <Typography
          sx={{
            color: 'text.secondary',
            fontWeight: 'bold',
            fontSize: '13px',
          }}
        >
          Appearance
        </Typography>
        <ArrowDropDownIcon
          sx={{
            color: 'text.secondary',
            width: '15px',
            height: '15px',
          }}
        />
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box
          p={1.5}
          sx={{
            backgroundColor: 'background.default',
          }}
        >
          <Box>
            <Box mb={1}>
              <Typography
                sx={{
                  color: 'grey.600',
                  fontWeight: 600,
                  marginBottom: '6px',
                }}
              >
                All Parameters
              </Typography>
              <Box
                py={1}
                sx={{
                  backgroundColor: 'grey.800',
                  borderRadius: '6px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Box
                  px={1.75}
                  sx={{
                    borderRight: '1px solid',
                    borderRightColor: 'rgba(75, 84, 112, 0.2)',
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      cursor: 'pointer',
                      color: existAllSetting(CHART_VISIBLE_NORMAL) ? 'text.primary' : 'grey.900',
                    }}
                    onClick={() => {
                      addSetting('all', CHART_VISIBLE_NORMAL);
                    }}
                  >
                    {CHART_VISIBLE_NORMAL}
                  </Typography>
                </Box>
                <Box px={1.75}>
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      color: existAllSetting(CHART_VISIBLE_BOLD) ? 'text.primary' : 'grey.900',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      addSetting('all', CHART_VISIBLE_BOLD);
                    }}
                  >
                    {CHART_VISIBLE_BOLD}
                  </Typography>
                </Box>
              </Box>
            </Box>
            {!!appearanceSets.length && appearanceSets.length > 1 && (
              <Box mb={1}>
                <Typography
                  sx={{
                    color: 'grey.600',
                    fontWeight: 600,
                    marginBottom: '6px',
                  }}
                >
                  Odd
                </Typography>
                <Box
                  py={1}
                  sx={{
                    backgroundColor: 'grey.800',
                    borderRadius: '6px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {chartVisibleOptions.map((value: string, index: number) => {
                    return (
                      <Box
                        px={1.75}
                        sx={{
                          borderLeft: index ? '1px solid' : 0,
                          borderLeftColor: 'rgba(75, 84, 112, 0.2)',
                        }}
                        key={index}
                      >
                        <Typography
                          sx={{
                            fontWeight: 'bold',
                            cursor: 'pointer',
                            color: existOddSetting(value) ? 'text.primary' : 'grey.900',
                          }}
                          onClick={() => {
                            addSetting('odd', value);
                          }}
                        >
                          {value}
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            )}
            {!!appearanceSets.length && appearanceSets.length > 1 && (
              <Box mb={1}>
                <Typography
                  sx={{
                    color: 'grey.600',
                    fontWeight: 600,
                    marginBottom: '6px',
                  }}
                >
                  Even
                </Typography>
                <Box
                  py={1}
                  sx={{
                    backgroundColor: 'grey.800',
                    borderRadius: '6px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {chartVisibleOptions.map((value: string, index: number) => {
                    return (
                      <Box
                        px={1.75}
                        sx={{
                          borderLeft: index ? '1px solid' : 0,
                          borderLeftColor: 'rgba(75, 84, 112, 0.2)',
                        }}
                        key={index}
                      >
                        <Typography
                          sx={{
                            fontWeight: 'bold',
                            cursor: 'pointer',
                            color: existEvenSetting(value) ? 'text.primary' : 'grey.900',
                          }}
                          onClick={() => {
                            addSetting('even', value);
                          }}
                        >
                          {value}
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            )}
            {appearanceSets.map((apperanceSet: string, key: number) => {
              return (
                <Box key={key} mb={1}>
                  <Typography
                    sx={{
                      color: getSerieColor(apperanceSet),
                      fontWeight: 600,
                      marginBottom: '6px',
                    }}
                  >
                    {apperanceSet}
                  </Typography>
                  <Box
                    py={1}
                    sx={{
                      backgroundColor: 'grey.800',
                      borderRadius: '6px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {chartVisibleOptions.map((value: string, index: number) => {
                      return (
                        <Box
                          px={1.75}
                          sx={{
                            borderLeft: index ? '1px solid' : 0,
                            borderLeftColor: 'rgba(75, 84, 112, 0.2)',
                          }}
                          key={index}
                        >
                          <Typography
                            sx={{
                              fontWeight: 'bold',
                              cursor: 'pointer',
                              color: existSetting(apperanceSet, value) ? 'text.primary' : 'grey.900',
                            }}
                            onClick={() => {
                              addSetting(apperanceSet, value);
                            }}
                          >
                            {value}
                          </Typography>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              );
            })}
          </Box>
          <Box pt={2}>
            <TextField
              select
              name="conversion"
              label="Select Conversion"
              value={conversion ? conversion.id : '-1'}
              onChange={(e: any) => {
                if (e.target.value === '-1') {
                  setConversion(undefined);
                  return;
                }
                for (let i = 0; i < conversions.length; i++) {
                  if (conversions[i].id === e.target.value) {
                    setConversion(conversions[i]);
                    return;
                  }
                }
              }}
              fullWidth
            >
              <MenuItem value="-1">None</MenuItem>
              {conversions.map((item: IUnitConversion, key: number) => {
                return (
                  <MenuItem value={item.id} key={key}>
                    {`${item.fromName} -> ${item.toName}`}
                  </MenuItem>
                );
              })}
            </TextField>
          </Box>
        </Box>
      </Popover>
    </React.Fragment>
  );
};

export default AppearanceSets;
