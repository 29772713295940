import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Dialog, DialogTitle, DialogContent, Box, Button, TextField, MenuItem } from '@mui/material';
import { ComponentLoading } from 'components';

import { useMutation } from '@apollo/client';
import { MUTATION_MERGE_TICKETS } from 'gql';
import { PATH_SAVVY_TICKETS } from 'const';

import { setAlert } from 'state';

interface MergeTicketDialogProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  targetTicketId: string;
  targetTicketSubject: string;
  sourceTicketId: string;
  sourceTicketSubject: string;
}

const MergeTicketDialog: React.FC<MergeTicketDialogProps> = (props) => {
  const { open, setOpen, targetTicketId, targetTicketSubject, sourceTicketId, sourceTicketSubject } = props;

  const [mergeTicketMutation, { data: dataMergeTicket, error, loading }] = useMutation(MUTATION_MERGE_TICKETS);

  const dispatch = useDispatch();
  const [direction, setDirection] = useState('right');
  const [subject, setSubject] = useState('');

  const [customSubject, setCustomSubject] = useState('');
  const [customSubjectError, setCustomSubjectError] = useState(false);

  const history = useHistory();

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async () => {
    const submitData = {
      sourceTicketId: '',
      destinationTicketId: '',
      subject: '',
    };

    if (direction === 'right') {
      submitData.sourceTicketId = sourceTicketId;
      submitData.destinationTicketId = targetTicketId;
    } else {
      submitData.sourceTicketId = targetTicketId;
      submitData.destinationTicketId = sourceTicketId;
    }

    if (subject === '-1') {
      if (!customSubject) {
        setCustomSubjectError(true);
        return;
      }
      submitData.subject = customSubject;
    } else {
      submitData.subject = subject;
    }

    await mergeTicketMutation({
      variables: {
        ...submitData,
      },
    });
  };

  useEffect(() => {
    if (open) {
      setSubject(targetTicketSubject);
      setCustomSubject('');
      setDirection('right');
    }
  }, [open, targetTicketSubject]);

  useEffect(() => {
    if (error) {
      dispatch(setAlert('error', 'Unable to merge these tickets'));
    } else if (dataMergeTicket) {
      if (dataMergeTicket.mergeTickets?.ok) {
        dispatch(setAlert('success', 'Merged tickets'));
        setOpen(false);
        history.push(`${PATH_SAVVY_TICKETS}/${dataMergeTicket.mergeTickets?.ticket.id}`);
      } else {
        dispatch(setAlert('error', dataMergeTicket.mergeTickets?.error || 'Unable to merge these tickets'));
      }
    }
  }, [dataMergeTicket, error, setOpen, dispatch, history]);

  return (
    <Dialog open={open} fullWidth onClose={handleClose}>
      <DialogTitle
        sx={{
          textAlign: 'center',
          fontSize: '24px',
          fontWeight: 700,
        }}
      >
        Merge Tickets
      </DialogTitle>
      <DialogContent
        sx={{
          px: {
            md: 5,
            xs: 2,
          },
        }}
      >
        <ComponentLoading loading={loading}>
          <Box>
            <Box pt={2}>
              <TextField
                select
                label="Direction"
                name="direction"
                value={direction}
                onChange={(e) => {
                  setDirection(e.target.value);
                }}
                fullWidth
              >
                <MenuItem value="right">
                  #{sourceTicketId} {sourceTicketSubject} &rarr; #{targetTicketId} {targetTicketSubject}
                </MenuItem>
                <MenuItem value="left">
                  #{targetTicketId} {targetTicketSubject} &rarr; #{sourceTicketId} {sourceTicketSubject}
                </MenuItem>
              </TextField>
              <TextField
                select
                label="Subject"
                name="subject"
                value={subject}
                onChange={(e) => {
                  setSubject(e.target.value);
                }}
                fullWidth
              >
                <MenuItem value={sourceTicketSubject}>{sourceTicketSubject}</MenuItem>
                <MenuItem value={targetTicketSubject}>{targetTicketSubject}</MenuItem>
                <MenuItem value="-1">Other</MenuItem>
              </TextField>
              {subject === '-1' && (
                <TextField
                  multiline
                  rows={5}
                  label="Custom Subject"
                  value={customSubject}
                  onChange={(e) => {
                    setCustomSubjectError(false);
                    setCustomSubject(e.target.value);
                  }}
                  error={customSubjectError}
                  helperText={customSubjectError ? 'Required' : ''}
                  FormHelperTextProps={{
                    sx: {
                      ml: 0,
                    },
                  }}
                  fullWidth
                />
              )}
            </Box>
            <Box
              sx={{
                textAlign: 'right',
              }}
            >
              <Button
                color="primary"
                variant="contained"
                sx={{
                  width: '100px',
                  mr: 2,
                }}
                onClick={() => {
                  onSubmit();
                }}
              >
                Merge
              </Button>
              <Button
                variant="outlined"
                sx={{
                  width: '100px',
                }}
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </ComponentLoading>
      </DialogContent>
    </Dialog>
  );
};

export default MergeTicketDialog;
