import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Typography, Collapse, IconButton, Tooltip } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CloseIcon from '@mui/icons-material/Close';
import { IBorescopeImageFile, IBorescopeImageFileType } from 'types';
import { UPLOAD_STATUS_PROCESSING } from 'const';
import { MUTATION_UPDATE_BORESCOPE_IMAGE } from 'gql';
import { useMutation } from '@apollo/client';

import { ComponentLoading } from 'components';
import { setAlert } from 'state';
import { default as UploadingStatusBar } from './UploadingStatusBar';
import { TicketInfoIcon } from 'customIcons';

interface NoCylinderFilesProps {
  cylinderCount: number;
  uploadedFiles: IBorescopeImageFileType[];
  uploadingFiles: IBorescopeImageFile[];
  isBorder: boolean;
  deleteBorescopeImage: (id: string) => void;
}

const NoCylinderFiles: React.FC<NoCylinderFilesProps> = (props) => {
  const { cylinderCount, uploadedFiles, uploadingFiles, isBorder, deleteBorescopeImage } = props;
  const [openCollapse, setOpenCollapse] = useState(false);

  const [updateImage, { data: dataUpdateImage, loading: loadingUpdateImage, error: errorUpdateImage }] =
    useMutation(MUTATION_UPDATE_BORESCOPE_IMAGE);
  const dispatch = useDispatch();

  const [showTooltip, setShowTooltip] = useState(true);

  const updateImageLocation = async (borescopeImageId: string, cylinder: number, subject: string) => {
    await updateImage({
      variables: {
        borescopeImageId,
        cylinder,
        subject,
      },
    });
  };

  useEffect(() => {
    if (uploadingFiles.length) {
      const isProcessing = uploadingFiles.filter((file) => !file.cylinderIndex && file.status === UPLOAD_STATUS_PROCESSING);
      if (isProcessing) {
        setOpenCollapse(true);
      }
    }
  }, [uploadingFiles]);

  useEffect(() => {
    if (errorUpdateImage) {
      dispatch(setAlert('error', 'Unable to change location'));
    } else if (dataUpdateImage) {
      const { ok, error } = dataUpdateImage.updateBorescopeImage;
      if (ok) {
        dispatch(setAlert('success', 'Tagged image with cylinder', undefined, 3000));
      } else {
        dispatch(setAlert('error', error || 'Unable to change location'));
      }
    }
  }, [errorUpdateImage, dataUpdateImage, dispatch]);

  useEffect(() => {
    if (uploadedFiles.length) {
      setOpenCollapse(true);
    }
  }, [uploadedFiles]);

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          py: 1.25,
          backgroundColor: 'rgba(245, 246, 250, 0.5)',
          px: 2.5,
          borderBottom: isBorder ? '1px solid' : '0px',
          borderColor: 'grey.200',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box
            onClick={() => {
              if (!uploadedFiles.length && !uploadingFiles.length) {
                setOpenCollapse(false);
              } else {
                setOpenCollapse(!openCollapse);
              }
            }}
            sx={{
              cursor: 'pointer',
              mr: 1,
            }}
          >
            {openCollapse ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
          </Box>
          <Typography
            sx={{
              color: 'text.secondary',
              fontWeight: 700,
              fontSize: '18px',
              lineHeight: '26px',
              mr: 1,
            }}
          >
            Not tagged
          </Typography>
          <Box
            sx={{
              display: 'inline-block',
              backgroundColor: 'grey.400',
              borderRadius: '4px',
              padding: '1px 5px',
            }}
          >
            <Typography
              sx={{
                color: 'background.default',
                fontWeight: 600,
              }}
            >
              {uploadedFiles.length + uploadingFiles.length}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Collapse in={openCollapse}>
        <Box>
          <ComponentLoading loading={loadingUpdateImage}>
            <Box>
              <Box pt={2} pb={1}>
                {showTooltip && (
                  <Box
                    sx={{
                      backgroundColor: 'rgba(63, 117, 255, 0.1)',
                      px: 2,
                      py: 1.5,
                      borderRadius: '6px',
                      display: 'flex',
                    }}
                  >
                    <TicketInfoIcon
                      sx={{
                        fill: 'none',
                        height: '36px',
                        width: '36px',
                      }}
                    />
                    <Box ml={1.5}>
                      <Typography
                        sx={{
                          fontWeight: 700,
                          mb: 0.5,
                          lineHeight: '19px',
                        }}
                      >
                        Tag the images
                      </Typography>
                      <Typography
                        sx={{
                          mb: 0.5,
                          lineHeight: '22px',
                        }}
                      >
                        Once you have images uploaded you need to tag each image with the correct cylinder and view. For example, an image
                        can be tagged as "Intake Valve" for "Cylinder #2". Use the appropriate buttons for each row below. When you have
                        finished tagging, click on the "Request Analysis" button.
                      </Typography>
                      <Typography
                        sx={{
                          color: 'primary.main',
                          textDecoration: 'underline',
                          fontWeight: 600,
                          cursor: 'pointer',
                          lineHeight: '19px',
                        }}
                        onClick={() => {
                          setShowTooltip(false);
                        }}
                      >
                        Don't show more
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Box>
              <Box
                py={1}
                px={3}
                sx={{
                  display: 'flex',
                }}
              >
                <Typography
                  sx={{
                    width: '40%',
                    color: 'grey.600',
                  }}
                >
                  Image
                </Typography>
                <Typography
                  sx={{
                    width: '60%',
                    color: 'grey.600',
                  }}
                >
                  Cylinder
                </Typography>
              </Box>
              {uploadedFiles.map((file: IBorescopeImageFileType, key: number) => {
                return (
                  <Box
                    key={key}
                    py={1}
                    px={3}
                    sx={{
                      display: 'flex',
                    }}
                  >
                    <Box
                      sx={{
                        width: '40%',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {!!file.previewUrl && (
                        <Tooltip
                          title={
                            <Box
                              component="img"
                              src={file.previewUrl}
                              sx={{
                                objectFit: 'cover',
                                width: '320px',
                                height: '200px',
                              }}
                            />
                          }
                          placement="right"
                        >
                          <Box
                            component="img"
                            src={file.previewUrl}
                            sx={{
                              objectFit: 'cover',
                              width: '80px',
                              height: '50px',
                              cursor: 'pointer',
                              mr: 2,
                            }}
                          />
                        </Tooltip>
                      )}
                      <Typography color="text.secondary">{file.filename}</Typography>
                    </Box>
                    <Box
                      sx={{
                        width: '60%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                        }}
                      >
                        {Array.apply(null, Array(cylinderCount)).map((item, index: number) => {
                          return (
                            <Box
                              key={index}
                              sx={{
                                width: '38px',
                                height: '28px',
                                borderRadius: '2px',
                                border: '1px solid #B5BDD8',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                cursor: 'pointer',
                                mr: 0.5,
                              }}
                              onClick={async () => {
                                await updateImageLocation(file.id, index + 1, file.subject || '');
                              }}
                            >
                              <Typography>{index + 1}</Typography>
                            </Box>
                          );
                        })}
                      </Box>
                      <IconButton
                        onClick={async () => {
                          await deleteBorescopeImage(file.id);
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  </Box>
                );
              })}
              {uploadingFiles.map((file: IBorescopeImageFile, key: number) => {
                return (
                  <ComponentLoading loading key={key}>
                    <Box
                      py={1}
                      px={3}
                      sx={{
                        display: 'flex',
                      }}
                    >
                      <Box
                        sx={{
                          width: '40%',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Box
                          component="img"
                          src={file.src || '/images/tempImage.png'}
                          sx={{
                            objectFit: 'cover',
                            width: '80px',
                            height: '50px',
                            mr: 2,
                          }}
                        />
                        <Box>
                          <UploadingStatusBar uploadedFile={file} />
                        </Box>
                      </Box>
                    </Box>
                  </ComponentLoading>
                );
              })}
            </Box>
          </ComponentLoading>
        </Box>
      </Collapse>
    </Box>
  );
};

export default NoCylinderFiles;
