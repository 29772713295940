import validator from 'email-validator';

import { IInputError, IInputSetError } from 'types';

import { EMAIL_EMPTY_ERROR, EMAIL_INVALID_ERROR, PASSWORD_MIN_LENGTH, PASSWORD_MIN_LENGTH_ERROR } from 'const';

// Check white space
export function hasWhiteSpace(value: string) {
  return /\s/g.test(value);
}

// Check empty (or only white spaces)
export function isEmpty(value: string | null) {
  // Add null check for Autocomplete. Sometimes empty value is null, not string
  if (!value) return true;
  return value.trim().length === 0;
}

// Email validation
export function isEmail(value: string) {
  return validator.validate(value);
}

// Check email. Not empty and match pattern
export function checkEmail(value: string, serError: IInputSetError) {
  // Empty
  if (isEmpty(value)) {
    serError(EMAIL_EMPTY_ERROR);
    return false;
  }

  // Match pattern
  if (!isEmail(value)) {
    serError(EMAIL_INVALID_ERROR);
    return false;
  }

  return true;
}

// Check password
export function checkPassword(value: string, serError: IInputSetError) {
  // Password MIN length. Password MAX length is handled by useInput hook
  if (value.length < PASSWORD_MIN_LENGTH) {
    serError(PASSWORD_MIN_LENGTH_ERROR);
    return false;
  }
  return true;
}

export function snakeToCamelCase(value: string) {
  let newStr = '';
  const splits = value.split('_');
  for (let i = 0; i < splits.length; i++) {
    if (i > 0) {
      newStr += splits[i].charAt(0).toUpperCase() + splits[i].slice(1);
    } else {
      newStr += splits[i];
    }
  }

  return newStr;
}

/*
  Check input has error
  Usage:
  const hasError = hasInputError(error);
  Used in form components: Input, Autocomplete
*/
export function hasInputError(error: IInputError) {
  // If error not passed or null (initial). Error is optional and inputs without validation doesn't use it
  if (typeof error === 'undefined' || error === null) {
    return false; // No error
  }

  // If error is string
  if (typeof error === 'string') {
    return error.length > 0; // Check length. Empty string or array means no error. Not empty string = error
  }

  // Case error is react component (HTML markup)
  return true;
}
