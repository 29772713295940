import {
  AUTH_LOG_IN,
  AUTH_LOG_OUT,
  AUTH_MISSING_TOKEN,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  SET_MISSING_TOKEN,
  LOGOUT_SUCCESS,
  CREATE_ACCOUNT_SUCCESS,
  CREATE_ACCOUNT_ERROR,
  CHANGE_PERSONAL_INFO_SUCCESS,
  SIGNUP_SERVICE_SUCCESS,
  SET_SELECTED_AIRCRAFT,
  SET_VIEW_ANONYMIZED,
} from 'state/types';

// Initial state (user data)
const initialState = {
  loggedIn: null,
  missingToken: false,
  storedAircraftId: '',
  showAnonymized: false,
};

// Reducer
const authReducer = (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    // Login
    case LOGIN_SUCCESS:
    case AUTH_LOG_IN:
    case CHANGE_PERSONAL_INFO_SUCCESS:
    case SIGNUP_SERVICE_SUCCESS:
    case CREATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        ...payload,
        loggedIn: true,
        missingToken: false,
      };
    case SET_SELECTED_AIRCRAFT:
      return {
        ...state,
        ...payload,
      };
    // Logout
    case LOGOUT_SUCCESS:
    case AUTH_LOG_OUT:
    case LOGIN_ERROR:
    case CREATE_ACCOUNT_ERROR:
      return {
        ...state,
        ...initialState,
        loggedIn: false,
      };
    case AUTH_MISSING_TOKEN:
      return {
        ...state,
        ...initialState,
        loggedIn: false,
        missingToken: true,
      };
    case SET_MISSING_TOKEN:
      return {
        ...state,
        missingToken: false,
      };
    case SET_VIEW_ANONYMIZED:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

export default authReducer;
