import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { useSmDown } from 'hooks';
import { ComponentLoading, CustomGridToolbar } from 'components';

import { DataGridPro, GridValueGetterParams, GridRenderCellParams } from '@mui/x-data-grid-pro';

import { QUERY_GET_CLIENT_ANALYSIS_PACKS } from 'gql';
import { useLazyQuery } from '@apollo/client';

import { setAlert } from 'state';

interface ProPacksProps {
  isLoad: boolean;
}

const ProPacks: React.FC<ProPacksProps> = (props) => {
  const { isLoad } = props;
  const isSmDown = useSmDown();

  const [fetchAnalysisPacks, { data: dataPacks, loading: loadingPacks, error: errorPacks }] = useLazyQuery(QUERY_GET_CLIENT_ANALYSIS_PACKS);
  const dispatch = useDispatch();

  const [filterButtonEl, setFilterButtonEl] = useState<HTMLButtonElement | null>(null);

  const [pageSize, setPageSize] = useState(5);

  useEffect(() => {
    if (isLoad) {
      fetchAnalysisPacks();
    }
  }, [isLoad, fetchAnalysisPacks]);

  useEffect(() => {
    if (errorPacks) {
      dispatch(setAlert('error', 'Unable to load your pro packs data'));
    }
  }, [errorPacks, dispatch]);

  return (
    <Box p={isSmDown ? 2 : 5}>
      <ComponentLoading loading={loadingPacks}>
        <Box py={1}>
          {isLoad && (
            <DataGridPro
              sx={{
                width: '100%',
                minWidth: '100px',
                '& .MuiDataGrid-columnSeparator': {
                  display: 'none',
                },
                '& .MuiDataGrid-cell:focus': {
                  outline: 'none',
                },
                '& .MuiDataGrid-row': {
                  cursor: 'pointer',
                  borderBottom: '1px solid',
                  borderColor: 'grey.200',
                },
                '& .MuiDataGrid-columnHeader:focus': {
                  outline: 'none',
                },
                '& .MuiDataGrid-cellContent': {
                  whiteSpace: 'break-spaces',
                },
                '& .MuiDataGrid-toolbarContainer .MuiTextField-root': {
                  mb: 0,
                },
                border: 'none',
              }}
              autoHeight
              disableColumnResize={true}
              disableSelectionOnClick
              rowsPerPageOptions={[5, 10, 25, 100]}
              pageSize={pageSize}
              onPageSizeChange={(newPage) => setPageSize(newPage)}
              pagination
              showColumnRightBorder={false}
              disableColumnSelector
              disableColumnMenu
              rows={dataPacks?.me?.analysisPacks || []}
              columns={[
                {
                  field: 'startDate',
                  headerName: 'Start Date',
                  valueFormatter: (params) => {
                    return params.value ? params.value.toString().substring(0, 10) : '';
                  },
                  flex: 0.5,
                },
                {
                  field: 'endDate',
                  headerName: 'End Date',
                  // valueFormatter: (params) => {
                  //   return params.value ? params.value.toString().substring(0, 10) : ""
                  // },
                  renderCell: (params: GridRenderCellParams<Date>) => {
                    if (!params.value) {
                      return '';
                    }

                    return (
                      <Typography>
                        {params.value.toString().substring(0, 10)}
                        {new Date(params.value) < new Date() && (
                          <Box
                            component={'span'}
                            sx={{
                              color: 'error.main',
                            }}
                          >
                            &nbsp;(Expired)
                          </Box>
                        )}
                      </Typography>
                    );
                  },
                  flex: 1,
                },
                {
                  field: 'promoCode',
                  headerName: 'Promo Code',
                  flex: 0.5,
                },
                {
                  field: 'incidents',
                  headerName: 'Incidents',
                  flex: 0.5,
                },
                {
                  field: 'remainingIncidents',
                  headerName: 'Remaining Incidents',
                  renderCell: (params: GridRenderCellParams) => {
                    if (!params.value) {
                      return '';
                    }

                    return (
                      <Box
                        sx={{
                          width: '100%',
                          position: 'relative',
                          '&:before': {
                            content: '" "',
                            position: 'absolute',
                            top: '50%',
                            left: 0,
                            borderBottom: '1px solid #111',
                            width: '100%',
                            display: params?.row?.endDate && new Date(params?.row?.endDate) > new Date() ? 'none' : 'block',
                          },
                        }}
                      >
                        <Typography>{params.value}</Typography>
                      </Box>
                    );
                  },
                  flex: 1,
                },
                {
                  field: 'packDefinition',
                  headerName: 'Pack Definition',
                  valueGetter: (params: GridValueGetterParams) => {
                    return params.value ? params.value.longName : '';
                  },
                  flex: 1,
                },
              ]}
              components={{ Toolbar: CustomGridToolbar }}
              componentsProps={{
                toolbar: {
                  setFilterButtonEl,
                },
                filterPanel: {
                  sx: {
                    '& .MuiDataGrid-filterForm': {
                      '& .MuiTextField-root': {
                        mb: 0,
                      },
                    },
                  },
                },
                panel: {
                  anchorEl: filterButtonEl,
                  placement: 'bottom-end',
                },
              }}
            />
          )}
        </Box>
      </ComponentLoading>
    </Box>
  );
};

export default ProPacks;
