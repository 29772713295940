import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Box, Container, IconButton, Popover, Typography, Divider } from '@mui/material';
import {
  DataGridPro,
  GridRenderCellParams,
  GridRowParams,
  GridSelectionModel,
  getGridDateOperators,
  GridFilterModel,
  GridSortModel,
} from '@mui/x-data-grid-pro';

import { PATH_FLIGHT_VIEW } from 'const';

import { QUERY_AIRCRAFT_FLIGHT_LIST, MUTATION_DELETE_FLIGHTS } from 'gql';
import { useLazyQuery, useMutation } from '@apollo/client';
import { ComponentLoading, Checkbox, CustomGridToolbar, CustomGridSelectionFooter } from 'components';
import { ctrlCmdClick } from 'helpers';
import { useTableConfig } from 'hooks';

import { setAlert, setFlightTableConfig } from 'state';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { SET_TABLE_FLIGHT_FILTER, SET_TABLE_FLIGHT_SORT, SET_TABLE_FLIGHT_PAGE, SET_TABLE_FLIGHT_HIDE_SHORT } from 'state/types';

interface FlightsProps {
  aircraftId: string;
  isLoad: boolean;
}

const Flights: React.FC<FlightsProps> = (props) => {
  const { aircraftId, isLoad } = props;

  const [fetchFlights, { data: dataFlights, loading: loadingFlights }] = useLazyQuery(QUERY_AIRCRAFT_FLIGHT_LIST, {
    fetchPolicy: 'cache-and-network',
  });
  const [deleteFlights, { data: dataDeleteFlights, error: errorDeleteFlights, loading: loadingDeleteFlights }] =
    useMutation(MUTATION_DELETE_FLIGHTS);

  const [selectedFlight, setSelectedFlight] = useState<string[]>([]);
  const [flights, setFlights] = useState<any[]>([]);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const dispatch = useDispatch();
  const history = useHistory();

  const { density, setDensity } = useTableConfig();

  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);

  const { rowsPerPage: pageSize, flightFilter: filterModel, page, sortModel, hideShortFlights } = useSelector((state: any) => state.flight);
  const setPageSize = (rows: number) => {
    dispatch(
      setFlightTableConfig({
        rowsPerPage: rows,
      }),
    );
  };
  const setFilterModel = (newFilterModel: GridFilterModel) => {
    dispatch({
      type: SET_TABLE_FLIGHT_FILTER,
      payload: {
        filterModel: newFilterModel,
      },
    });
  };
  const setPage = (pageNum: number) => {
    if (page !== pageNum) {
      dispatch({
        type: SET_TABLE_FLIGHT_PAGE,
        payload: {
          page: pageNum,
        },
      });
    }
  };
  const setSortModel = (newSortModel: GridSortModel) => {
    if (sortModel !== newSortModel) {
      dispatch({
        type: SET_TABLE_FLIGHT_SORT,
        payload: {
          sortModel: newSortModel,
        },
      });
    }
  };
  const setHideShortFlights = (value: boolean) => {
    if (value !== hideShortFlights) {
      dispatch({
        type: SET_TABLE_FLIGHT_HIDE_SHORT,
        payload: {
          hideShortFlights: value,
        },
      });
    }
  };

  const deleteSelectedFlight = async (selectedFlights: GridSelectionModel) => {
    if (selectedFlights.length) {
      await deleteFlights({
        variables: {
          flightIds: selectedFlights as string[],
        },
      });
    }
  };

  const [filterButtonEl, setFilterButtonEl] = useState<HTMLButtonElement | null>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteFlightFromRow = async (removeFlights: string[]) => {
    await deleteFlights({
      variables: {
        flightIds: [...removeFlights],
      },
    });

    const removeIndex = flights.findIndex((flight: any) => flight.id === removeFlights[0]);
    const tmp = [...flights];

    if (removeIndex !== undefined) {
      tmp.splice(removeIndex, 1);
      setFlights([...tmp]);
    }
  };

  const changeShortFlgihts = async () => {
    setHideShortFlights(!hideShortFlights);
  };

  useEffect(() => {
    if (isLoad && aircraftId) {
      fetchFlights({
        variables: {
          aircraftId,
          hideShortFlights,
        },
      });
    }
  }, [aircraftId, isLoad, fetchFlights, hideShortFlights]);

  useEffect(() => {
    if (dataFlights?.savvy?.aircraft) {
      let tmp_flights: any[] = [];
      let tmp_flight: any = {};
      dataFlights?.savvy?.aircraft?.flights?.forEach((flight: any) => {
        tmp_flight = {
          ...flight,
          importFile: flight.importFile.name,
        };
        tmp_flights.push(tmp_flight);
      });
      setFlights(tmp_flights);
    }
  }, [dataFlights]);

  useEffect(() => {
    if (errorDeleteFlights) {
      dispatch(setAlert('error', 'Unable to delete selected flights'));
    } else if (dataDeleteFlights) {
      if (dataDeleteFlights.deleteFlights?.ok) {
        dispatch(setAlert('success', 'Deleted selected flights'));
        if (aircraftId) {
          fetchFlights({
            variables: {
              aircraftId,
              hideShortFlights,
            },
          });
        }
      } else {
        dispatch(setAlert('error', dataDeleteFlights.deleteFlights?.error || 'Unable to delete selected flights'));
      }
    }
  }, [errorDeleteFlights, dataDeleteFlights, dispatch, aircraftId, fetchFlights, hideShortFlights]);

  return (
    <Container fixed maxWidth="xl">
      <Box>
        <ComponentLoading loading={loadingFlights || loadingDeleteFlights}>
          <Box
            sx={{
              width: '100%',
              py: 1,
            }}
          >
            <Box
              sx={{
                textAlign: 'right',
              }}
            >
              <Box
                mb={1}
                sx={{
                  display: 'inline-flex',
                }}
              >
                <Checkbox
                  value={hideShortFlights}
                  checked={hideShortFlights}
                  label={
                    <Typography
                      sx={{
                        display: 'inline-block',
                      }}
                    >
                      Hide short flights
                    </Typography>
                  }
                  onChange={() => {
                    changeShortFlgihts();
                  }}
                  noMargin
                />
              </Box>
            </Box>
            {isLoad && (
              <DataGridPro
                sx={{
                  width: '100%',
                  minWidth: '100px',
                  '& .MuiDataGrid-columnSeparator': {
                    display: 'none',
                  },
                  '& .MuiDataGrid-cell': {
                    padding: '10px',
                  },
                  '& .MuiDataGrid-cell:focus': {
                    outline: 'none',
                  },
                  '& .MuiDataGrid-row': {
                    cursor: 'pointer',
                    borderBottom: '1px solid',
                    borderColor: 'grey.200',
                  },
                  '& .MuiDataGrid-columnHeader:focus': {
                    outline: 'none',
                  },
                  '& .MuiDataGrid-cellContent': {
                    whiteSpace: 'break-spaces',
                  },
                  '& .MuiDataGrid-toolbarContainer .MuiTextField-root': {
                    mb: 0,
                  },
                  border: 'none',
                }}
                autoHeight
                getRowHeight={() => 'auto'}
                disableColumnResize={true}
                checkboxSelection
                checkboxSelectionVisibleOnly
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                pageSize={pageSize}
                onPageSizeChange={(newPage) => setPageSize(newPage)}
                pagination
                page={page}
                onPageChange={(newPage) => setPage(newPage)}
                sortModel={sortModel}
                onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                showColumnRightBorder={false}
                disableColumnSelector
                disableColumnMenu
                onSelectionModelChange={(newSelectionModel) => {
                  setSelectionModel(newSelectionModel);
                }}
                density={density}
                onStateChange={(params) => {
                  if (params.density && params.density.value !== density) {
                    setDensity(params.density.value);
                  }
                }}
                filterModel={filterModel}
                onFilterModelChange={(newFilterModel) => {
                  setFilterModel(newFilterModel);
                }}
                selectionModel={selectionModel}
                rows={flights ? flights : []}
                columns={[
                  {
                    field: 'date',
                    headerName: 'Date',
                    valueFormatter: (params) => {
                      return params.value ? params.value.toString().substring(0, 10) : '';
                    },
                    flex: 0.7,
                    filterOperators: getGridDateOperators(),
                  },
                  {
                    field: 'departureAirport',
                    headerName: 'Departure Airport',
                    flex: 1,
                  },
                  {
                    field: 'destinationAirport',
                    headerName: 'Destination Airport',
                    flex: 1,
                  },
                  {
                    field: 'importFile',
                    headerName: 'File',
                    flex: 0.7,
                  },
                  {
                    field: 'duration',
                    headerName: 'Duration',
                    valueFormatter: (params) => {
                      if (!params?.value) return '';
                      const hour = Math.floor(params.value / 3600);
                      const min = Math.floor((params.value % 3600) / 60);
                      return `${hour}h ${(min < 10 ? '0' : '') + min}m`;
                    },
                    flex: 0.7,
                  },
                  {
                    field: 'chtTemperatureAlert',
                    headerName: 'High CHT Alert',
                    valueFormatter: (params) => {
                      return params.value ? 'HI CHT' : '';
                    },
                    flex: 0.7,
                  },
                  {
                    field: 'id',
                    headerName: '',
                    renderCell: (params: GridRenderCellParams<string>) => {
                      return (
                        <IconButton
                          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                            e.preventDefault();
                            e.stopPropagation();
                            if (selectedFlight.length > 1) {
                              return false;
                            }
                            setAnchorEl(e.currentTarget);
                            setSelectedFlight([params?.value || '']);
                          }}
                          sx={{
                            padding: 0,
                          }}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      );
                    },
                    sortable: false,
                    filterable: false,
                  },
                ]}
                onRowClick={(params: GridRowParams, event?: any) => {
                  const { row } = params;
                  if (row.id) {
                    ctrlCmdClick(event, `${PATH_FLIGHT_VIEW}/${row.id}`, history);
                  } else {
                    return false;
                  }
                }}
                components={{
                  Toolbar: CustomGridToolbar,
                  Footer: CustomGridSelectionFooter,
                }}
                componentsProps={{
                  toolbar: {
                    setFilterButtonEl,
                  },
                  filterPanel: {
                    sx: {
                      '& .MuiDataGrid-filterForm': {
                        '& .MuiTextField-root': {
                          mb: 0,
                        },
                      },
                    },
                  },
                  panel: {
                    anchorEl: filterButtonEl,
                    placement: 'bottom-end',
                  },
                  footer: {
                    selection: selectionModel,
                    setSelection: setSelectionModel,
                    fullData: flights.map((item: any) => item.id),
                    pageSize,
                    deleteAction: deleteSelectedFlight,
                  },
                }}
              />
            )}
          </Box>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'right',
            }}
          >
            <Typography
              paragraph
              sx={{
                padding: '10px',
                marginBlock: '0',
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: 'grey.200',
                },
              }}
              onClick={() => {
                deleteFlightFromRow(selectedFlight);
                setAnchorEl(null);
              }}
            >
              Delete Flight
            </Typography>
            <Divider
              sx={{
                width: '100%',
              }}
            />
            <Typography
              paragraph
              sx={{
                padding: '10px',
                marginBlock: '0',
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: 'grey.200',
                },
              }}
              onClick={() => {
                window.open(`${PATH_FLIGHT_VIEW}/${selectedFlight}`, '_blank');
                setAnchorEl(null);
              }}
            >
              Open in New Tab
            </Typography>
          </Popover>
        </ComponentLoading>
      </Box>
    </Container>
  );
};

export default Flights;
