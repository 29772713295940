// From https://bitbucket.org/atlassian/atlaskit-mk-2/src/master/packages/core/select/src/data/countries.ts
const countries = [
  { icon: '🇺🇲', name: 'United States', id: 'US', code: '+1', suggested: true },
  { icon: '🇦🇫', name: 'Afghanistan', id: 'AF', code: '+93' },
  { icon: '🇦🇽', name: 'Alland Islands', id: 'AX', code: '+358' },
  { icon: '🇦🇱', name: 'Albania', id: 'AL', code: '+355' },
  { icon: '🇩🇿', name: 'Algeria', id: 'DZ', code: '+213' },
  { icon: '🇦🇸', name: 'American Samoa', id: 'AS', code: '+1-684' },
  { icon: '🇦🇩', name: 'Andorra', id: 'AD', code: '+376' },
  { icon: '🇦🇴', name: 'Angola', id: 'AO', code: '+244' },
  { icon: '🇦🇮', name: 'Anguilla', id: 'AI', code: '+1-264' },
  { icon: '🇦🇶', name: 'Antarctica', id: 'AQ', code: '+672' },
  { icon: '🇦🇬', name: 'Antigua and Barbuda', id: 'AG', code: '+1-268' },
  { icon: '🇦🇷', name: 'Argentina', id: 'AR', code: '+54' },
  { icon: '🇦🇲', name: 'Armenia', id: 'AM', code: '+374' },
  { icon: '🇦🇼', name: 'Aruba', id: 'AW', code: '+297' },
  { icon: '🇦🇺', name: 'Australia', id: 'AU', code: '+61', suggested: true },
  { icon: '🇦🇹', name: 'Austria', id: 'AT', code: '+43' },
  { icon: '🇦🇿', name: 'Azerbaijan', id: 'AZ', code: '+994' },
  { icon: '🇧🇸', name: 'Bahamas', id: 'BS', code: '+1-242' },
  { icon: '🇧🇭', name: 'Bahrain', id: 'BH', code: '+973' },
  { icon: '🇧🇩', name: 'Bangladesh', id: 'BD', code: '+880' },
  { icon: '🇧🇧', name: 'Barbados', id: 'BB', code: '+1-246' },
  { icon: '🇧🇾', name: 'Belarus', id: 'BY', code: '+375' },
  { icon: '🇧🇪', name: 'Belgium', id: 'BE', code: '+32' },
  { icon: '🇧🇿', name: 'Belize', id: 'BZ', code: '+501' },
  { icon: '🇧🇯', name: 'Benin', id: 'BJ', code: '+229' },
  { icon: '🇧🇲', name: 'Bermuda', id: 'BM', code: '+1-441' },
  { icon: '🇧🇹', name: 'Bhutan', id: 'BT', code: '+975' },
  { icon: '🇧🇴', name: 'Bolivia', id: 'BO', code: '+591' },
  { icon: '🇧🇦', name: 'Bosnia and Herzegovina', id: 'BA', code: '+387' },
  { icon: '🇧🇼', name: 'Botswana', id: 'BW', code: '+267' },
  { icon: '🇧🇻', name: 'Bouvet Island', id: 'BV', code: '+47' },
  { icon: '🇧🇷', name: 'Brazil', id: 'BR', code: '+55' },
  {
    icon: '🇮🇴',
    name: 'British Indian Ocean Territory',
    id: 'IO',
    code: '+246',
  },
  { icon: '🇻🇬', name: 'British Virgin Islands', id: 'VG', code: '+1-284' },
  { icon: '🇧🇳', name: 'Brunei Darussalam', id: 'BN', code: '+673' },
  { icon: '🇧🇬', name: 'Bulgaria', id: 'BG', code: '+359' },
  { icon: '🇧🇫', name: 'Burkina Faso', id: 'BF', code: '+226' },
  { icon: '🇧🇮', name: 'Burundi', id: 'BI', code: '+257' },
  { icon: '🇰🇭', name: 'Cambodia', id: 'KH', code: '+855' },
  { icon: '🇨🇲', name: 'Cameroon', id: 'CM', code: '+237' },
  { icon: '🇨🇦', name: 'Canada', id: 'CA', code: '+1', suggested: true },
  { icon: '🇨🇻', name: 'Cape Verde', id: 'CV', code: '+238' },
  { icon: '🇰🇾', name: 'Cayman Islands', id: 'KY', code: '+1-345' },
  { icon: '🇨🇫', name: 'Central African Republic', id: 'CF', code: '+236' },
  { icon: '🇹🇩', name: 'Chad', id: 'TD', code: '+235' },
  { icon: '🇨🇱', name: 'Chile', id: 'CL', code: '+56' },
  { icon: '🇨🇳', name: 'China', id: 'CN', code: '+86' },
  { icon: '🇨🇽', name: 'Christmas Island', id: 'CX', code: '+61' },
  { icon: '🇨🇨', name: 'Cocos (Keeling) Islands', id: 'CC', code: '+61' },
  { icon: '🇨🇴', name: 'Colombia', id: 'CO', code: '+57' },
  { icon: '🇰🇲', name: 'Comoros', id: 'KM', code: '+269' },
  {
    icon: '🇨🇩',
    name: 'Congo, Democratic Republic of the',
    id: 'CG',
    code: '+243',
  },
  {
    icon: '🇨🇬',
    name: 'Congo, Republic of the',
    id: 'CD',
    code: '+242',
  },
  { icon: '🇨🇰', name: 'Cook Islands', id: 'CK', code: '+682' },
  { icon: '🇨🇷', name: 'Costa Rica', id: 'CR', code: '+506' },
  { icon: '🇨🇮', name: "Cote d'Ivoire", id: 'CI', code: '+225' },
  { icon: '🇭🇷', name: 'Croatia', id: 'HR', code: '+385' },
  { icon: '🇨🇺', name: 'Cuba', id: 'CU', code: '+53' },
  { icon: '🇨🇼', name: 'Curacao', id: 'CW', code: '+599' },
  { icon: '🇨🇾', name: 'Cyprus', id: 'CY', code: '+357' },
  { icon: '🇨🇿', name: 'Czech Republic', id: 'CZ', code: '+420' },
  { icon: '🇩🇰', name: 'Denmark', id: 'DK', code: '+45' },
  { icon: '🇩🇯', name: 'Djibouti', id: 'DJ', code: '+253' },
  { icon: '🇩🇲', name: 'Dominica', id: 'DM', code: '+1-767' },
  { icon: '🇩🇴', name: 'Dominican Republic', id: 'DO', code: '+1-809' },
  { icon: '🇪🇨', name: 'Ecuador', id: 'EC', code: '+593' },
  { icon: '🇪🇬', name: 'Egypt', id: 'EG', code: '+20' },
  { icon: '🇸🇻', name: 'El Salvador', id: 'SV', code: '+503' },
  { icon: '🇬🇶', name: 'Equatorial Guinea', id: 'GQ', code: '+240' },
  { icon: '🇪🇷', name: 'Eritrea', id: 'ER', code: '+291' },
  { icon: '🇪🇪', name: 'Estonia', id: 'EE', code: '+372' },
  { icon: '🇪🇹', name: 'Ethiopia', id: 'ET', code: '+251' },
  { icon: '🇫🇰', name: 'Falkland Islands (Malvinas)', id: 'FK', code: '+500' },
  { icon: '🇫🇴', name: 'Faroe Islands', id: 'FO', code: '+298' },
  { icon: '🇫🇯', name: 'Fiji', id: 'FJ', code: '+679' },
  { icon: '🇫🇮', name: 'Finland', id: 'FI', code: '+358' },
  { icon: '🇫🇷', name: 'France', id: 'FR', code: '+33', suggested: true },
  { icon: '🇬🇫', name: 'French Guiana', id: 'GF', code: '+594' },
  { icon: '🇵🇫', name: 'French Polynesia', id: 'PF', code: '+689' },
  { icon: '🇹🇫', name: 'French Southern Territories', id: 'TF', code: '+262' },
  { icon: '🇬🇦', name: 'Gabon', id: 'GA', code: '+241' },
  { icon: '🇬🇲', name: 'Gambia', id: 'GM', code: '+220' },
  { icon: '🇬🇪', name: 'Georgia', id: 'GE', code: '+995' },
  { icon: '🇩🇪', name: 'Germany', id: 'DE', code: '+49', suggested: true },
  { icon: '🇬🇭', name: 'Ghana', id: 'GH', code: '+233' },
  { icon: '🇬🇮', name: 'Gibraltar', id: 'GI', code: '+350' },
  { icon: '🇬🇷', name: 'Greece', id: 'GR', code: '+30' },
  { icon: '🇬🇱', name: 'Greenland', id: 'GL', code: '+299' },
  { icon: '🇬🇩', name: 'Grenada', id: 'GD', code: '+1-473' },
  { icon: '🇬🇵', name: 'Guadeloupe', id: 'GP', code: '+590' },
  { icon: '🇬🇺', name: 'Guam', id: 'GU', code: '+1-671' },
  { icon: '🇬🇹', name: 'Guatemala', id: 'GT', code: '+502' },
  { icon: '🇬🇬', name: 'Guernsey', id: 'GG', code: '+44' },
  { icon: '🇬🇼', name: 'Guinea-Bissau', id: 'GW', code: '+245' },
  { icon: '🇬🇳', name: 'Guinea', id: 'GN', code: '+224' },
  { icon: '🇬🇾', name: 'Guyana', id: 'GY', code: '+592' },
  { icon: '🇭🇹', name: 'Haiti', id: 'HT', code: '+509' },
  {
    icon: '🇭🇲',
    name: 'Heard Island and McDonald Islands',
    id: 'HM',
    code: '+672',
  },
  {
    icon: '🇻🇦',
    name: 'Holy See (Vatican City State)',
    id: 'VA',
    code: '+379',
  },
  { icon: '🇭🇳', name: 'Honduras', id: 'HN', code: '+504' },
  { icon: '🇭🇰', name: 'Hong Kong', id: 'HK', code: '+852' },
  { icon: '🇭🇺', name: 'Hungary', id: 'HU', code: '+36' },
  { icon: '🇮🇸', name: 'Iceland', id: 'IS', code: '+354' },
  { icon: '🇮🇳', name: 'India', id: 'IN', code: '+91' },
  { icon: '🇮🇩', name: 'Indonesia', id: 'ID', code: '+62' },
  { icon: '🇮🇷', name: 'Iran, Islamic Republic of', id: 'IR', code: '+98' },
  { icon: '🇮🇶', name: 'Iraq', id: 'IQ', code: '+964' },
  { icon: '🇮🇪', name: 'Ireland', id: 'IE', code: '+353' },
  { icon: '🇮🇲', name: 'Isle of Man', id: 'IM', code: '+44' },
  { icon: '🇮🇱', name: 'Israel', id: 'IL', code: '+972' },
  { icon: '🇮🇹', name: 'Italy', id: 'IT', code: '+39' },
  { icon: '🇯🇲', name: 'Jamaica', id: 'JM', code: '+1-876' },
  { icon: '🇯🇵', name: 'Japan', id: 'JP', code: '+81', suggested: true },
  { icon: '🇯🇪', name: 'Jersey', id: 'JE', code: '+44' },
  { icon: '🇯🇴', name: 'Jordan', id: 'JO', code: '+962' },
  { icon: '🇰🇿', name: 'Kazakhstan', id: 'KZ', code: '+7' },
  { icon: '🇰🇪', name: 'Kenya', id: 'KE', code: '+254' },
  { icon: '🇰🇮', name: 'Kiribati', id: 'KI', code: '+686' },
  {
    icon: '🇰🇵',
    name: "Korea, Democratic People's Republic of",
    id: 'KP',
    code: '+850',
  },
  { icon: '🇰🇷', name: 'Korea, Republic of', id: 'KR', code: '+82' },
  { icon: '🇽🇰', name: 'Kosovo', id: 'XK', code: '+383' },
  { icon: '🇰🇼', name: 'Kuwait', id: 'KW', code: '+965' },
  { icon: '🇰🇬', name: 'Kyrgyzstan', id: 'KG', code: '+996' },
  {
    icon: '🇱🇦',
    name: "Lao People's Democratic Republic",
    id: 'LA',
    code: '+856',
  },
  { icon: '🇱🇻', name: 'Latvia', id: 'LV', code: '+371' },
  { icon: '🇱🇧', name: 'Lebanon', id: 'LB', code: '+961' },
  { icon: '🇱🇸', name: 'Lesotho', id: 'LS', code: '+266' },
  { icon: '🇱🇷', name: 'Liberia', id: 'LR', code: '+231' },
  { icon: '🇱🇾', name: 'Libya', id: 'LY', code: '+218' },
  { icon: '🇱🇮', name: 'Liechtenstein', id: 'LI', code: '+423' },
  { icon: '🇱🇹', name: 'Lithuania', id: 'LT', code: '+370' },
  { icon: '🇱🇺', name: 'Luxembourg', id: 'LU', code: '+352' },
  { icon: '🇲🇴', name: 'Macao', id: 'MO', code: '+853' },
  {
    icon: '🇲🇰',
    name: 'Macedonia, the Former Yugoslav Republic of',
    id: 'MK',
    code: '+389',
  },
  { icon: '🇲🇬', name: 'Madagascar', id: 'MG', code: '+261' },
  { icon: '🇲🇼', name: 'Malawi', id: 'MW', code: '+265' },
  { icon: '🇲🇾', name: 'Malaysia', id: 'MY', code: '+60' },
  { icon: '🇲🇻', name: 'Maldives', id: 'MV', code: '+960' },
  { icon: '🇲🇱', name: 'Mali', id: 'ML', code: '+223' },
  { icon: '🇲🇹', name: 'Malta', id: 'MT', code: '+356' },
  { icon: '🇲🇭', name: 'Marshall Islands', id: 'MH', code: '+692' },
  { icon: '🇲🇶', name: 'Martinique', id: 'MQ', code: '+596' },
  { icon: '🇲🇷', name: 'Mauritania', id: 'MR', code: '+222' },
  { icon: '🇲🇺', name: 'Mauritius', id: 'MU', code: '+230' },
  { icon: '🇾🇹', name: 'Mayotte', id: 'YT', code: '+262' },
  { icon: '🇲🇽', name: 'Mexico', id: 'MX', code: '+52' },
  {
    icon: '🇫🇲',
    name: 'Micronesia, Federated States of',
    id: 'FM',
    code: '+691',
  },
  { icon: '🇲🇩', name: 'Moldova, Republic of', id: 'MD', code: '+373' },
  { icon: '🇲🇨', name: 'Monaco', id: 'MC', code: '+377' },
  { icon: '🇲🇳', name: 'Mongolia', id: 'MN', code: '+976' },
  { icon: '🇲🇪', name: 'Montenegro', id: 'ME', code: '+382' },
  { icon: '🇲🇸', name: 'Montserrat', id: 'MS', code: '+1-664' },
  { icon: '🇲🇦', name: 'Morocco', id: 'MA', code: '+212' },
  { icon: '🇲🇿', name: 'Mozambique', id: 'MZ', code: '+258' },
  { icon: '🇲🇲', name: 'Myanmar', id: 'MM', code: '+95' },
  { icon: '🇳🇦', name: 'Namibia', id: 'NA', code: '+264' },
  { icon: '🇳🇷', name: 'Nauru', id: 'NR', code: '+674' },
  { icon: '🇳🇵', name: 'Nepal', id: 'NP', code: '+977' },
  { icon: '🇳🇱', name: 'Netherlands', id: 'NL', code: '+31' },
  { icon: '🇳🇨', name: 'New Caledonia', id: 'NC', code: '+687' },
  { icon: '🇳🇿', name: 'New Zealand', id: 'NZ', code: '+64' },
  { icon: '🇳🇮', name: 'Nicaragua', id: 'NI', code: '+505' },
  { icon: '🇳🇪', name: 'Niger', id: 'NE', code: '+227' },
  { icon: '🇳🇬', name: 'Nigeria', id: 'NG', code: '+234' },
  { icon: '🇳🇺', name: 'Niue', id: 'NU', code: '+683' },
  { icon: '🇳🇫', name: 'Norfolk Island', id: 'NF', code: '+672' },
  { icon: '🇲🇵', name: 'Northern Mariana Islands', id: 'MP', code: '+1-670' },
  { icon: '🇳🇴', name: 'Norway', id: 'NO', code: '+47' },
  { icon: '🇴🇲', name: 'Oman', id: 'OM', code: '+968' },
  { icon: '🇵🇰', name: 'Pakistan', id: 'PK', code: '+92' },
  { icon: '🇵🇼', name: 'Palau', id: 'PW', code: '+680' },
  { icon: '🇵🇸', name: 'Palestine, State of', id: 'PS', code: '+970' },
  { icon: '🇵🇦', name: 'Panama', id: 'PA', code: '+507' },
  { icon: '🇵🇬', name: 'Papua New Guinea', id: 'PG', code: '+675' },
  { icon: '🇵🇾', name: 'Paraguay', id: 'PY', code: '+595' },
  { icon: '🇵🇪', name: 'Peru', id: 'PE', code: '+51' },
  { icon: '🇵🇭', name: 'Philippines', id: 'PH', code: '+63' },
  { icon: '🇵🇳', name: 'Pitcairn', id: 'PN', code: '+870' },
  { icon: '🇵🇱', name: 'Poland', id: 'PL', code: '+48' },
  { icon: '🇵🇹', name: 'Portugal', id: 'PT', code: '+351' },
  { icon: '🇵🇷', name: 'Puerto Rico', id: 'PR', code: '+1' },
  { icon: '🇶🇦', name: 'Qatar', id: 'QA', code: '+974' },
  { icon: '🇷🇪', name: 'Reunion', id: 'RE', code: '+262' },
  { icon: '🇷🇴', name: 'Romania', id: 'RO', code: '+40' },
  { icon: '🇷🇺', name: 'Russian Federation', id: 'RU', code: '+7' },
  { icon: '🇷🇼', name: 'Rwanda', id: 'RW', code: '+250' },
  { icon: '🇧🇱', name: 'Saint Barthelemy', id: 'BL', code: '+590' },
  { icon: '🇸🇭', name: 'Saint Helena', id: 'SH', code: '+290' },
  { icon: '🇰🇳', name: 'Saint Kitts and Nevis', id: 'KN', code: '+1-869' },
  { icon: '🇱🇨', name: 'Saint Lucia', id: 'LC', code: '+1-758' },
  { icon: '🇲🇫', name: 'Saint Martin (French part)', id: 'MF', code: '+590' },
  { icon: '🇵🇲', name: 'Saint Pierre and Miquelon', id: 'PM', code: '+508' },
  {
    icon: '🇻🇨',
    name: 'Saint Vincent and the Grenadines',
    id: 'VC',
    code: '+1-784',
  },
  { icon: '🇼🇸', name: 'Samoa', id: 'WS', code: '+685' },
  { icon: '🇸🇲', name: 'San Marino', id: 'SM', code: '+378' },
  { icon: '🇸🇹', name: 'Sao Tome and Principe', id: 'ST', code: '+239' },
  { icon: '🇸🇦', name: 'Saudi Arabia', id: 'SA', code: '+966' },
  { icon: '🇸🇳', name: 'Senegal', id: 'SN', code: '+221' },
  { icon: '🇷🇸', name: 'Serbia', id: 'RS', code: '+381' },
  { icon: '🇸🇨', name: 'Seychelles', id: 'SC', code: '+248' },
  { icon: '🇸🇱', name: 'Sierra Leone', id: 'SL', code: '+232' },
  { icon: '🇸🇬', name: 'Singapore', id: 'SG', code: '+65' },
  { icon: '🇸🇽', name: 'Sint Maarten (Dutch part)', id: 'SX', code: '+1-721' },
  { icon: '🇸🇰', name: 'Slovakia', id: 'SK', code: '+421' },
  { icon: '🇸🇮', name: 'Slovenia', id: 'SI', code: '+386' },
  { icon: '🇸🇧', name: 'Solomon Islands', id: 'SB', code: '+677' },
  { icon: '🇸🇴', name: 'Somalia', id: 'SO', code: '+252' },
  { icon: '🇿🇦', name: 'South Africa', id: 'ZA', code: '+27' },
  {
    icon: '🇬🇸',
    name: 'South Georgia and the South Sandwich Islands',
    id: 'GS',
    code: '+500',
  },
  { icon: '🇸🇸', name: 'South Sudan', id: 'SS', code: '+211' },
  { icon: '🇪🇸', name: 'Spain', id: 'ES', code: '+34' },
  { icon: '🇱🇰', name: 'Sri Lanka', id: 'LK', code: '+94' },
  { icon: '🇸🇩', name: 'Sudan', id: 'SD', code: '+249' },
  { icon: '🇸🇷', name: 'Suriname', id: 'SR', code: '+597' },
  { icon: '🇸🇯', name: 'Svalbard and Jan Mayen', id: 'SJ', code: '+47' },
  { icon: '🇸🇿', name: 'Swaziland', id: 'SZ', code: '+268' },
  { icon: '🇸🇪', name: 'Sweden', id: 'SE', code: '+46' },
  { icon: '🇨🇭', name: 'Switzerland', id: 'CH', code: '+41' },
  { icon: '🇸🇾', name: 'Syrian Arab Republic', id: 'SY', code: '+963' },
  { icon: '🇹🇼', name: 'Taiwan, Province of China', id: 'TW', code: '+886' },
  { icon: '🇹🇯', name: 'Tajikistan', id: 'TJ', code: '+992' },
  { icon: '🇹🇭', name: 'Thailand', id: 'TH', code: '+66' },
  { icon: '🇹🇱', name: 'Timor-Leste', id: 'TL', code: '+670' },
  { icon: '🇹🇬', name: 'Togo', id: 'TG', code: '+228' },
  { icon: '🇹🇰', name: 'Tokelau', id: 'TK', code: '+690' },
  { icon: '🇹🇴', name: 'Tonga', id: 'TO', code: '+676' },
  { icon: '🇹🇹', name: 'Trinidad and Tobago', id: 'TT', code: '+1-868' },
  { icon: '🇹🇳', name: 'Tunisia', id: 'TN', code: '+216' },
  { icon: '🇹🇷', name: 'Turkey', id: 'TR', code: '+90' },
  { icon: '🇹🇲', name: 'Turkmenistan', id: 'TM', code: '+993' },
  { icon: '🇹🇨', name: 'Turks and Caicos Islands', id: 'TC', code: '+1-649' },
  { icon: '🇹🇻', name: 'Tuvalu', id: 'TV', code: '+688' },
  { icon: '🇺🇬', name: 'Uganda', id: 'UG', code: '+256' },
  { icon: '🇺🇦', name: 'Ukraine', id: 'UA', code: '+380' },
  { icon: '🇦🇪', name: 'United Arab Emirates', id: 'AE', code: '+971' },
  { icon: '🇬🇧', name: 'United Kingdom', id: 'GB', code: '+44' },
  { icon: '🇹🇿', name: 'United Republic of Tanzania', id: 'TZ', code: '+255' },
  { icon: '🇺🇾', name: 'Uruguay', id: 'UY', code: '+598' },
  { icon: '🇻🇮', name: 'US Virgin Islands', id: 'VI', code: '+1-340' },
  { icon: '🇺🇿', name: 'Uzbekistan', id: 'UZ', code: '+998' },
  { icon: '🇻🇺', name: 'Vanuatu', id: 'VU', code: '+678' },
  { icon: '🇻🇪', name: 'Venezuela', id: 'VE', code: '+58' },
  { icon: '🇻🇳', name: 'Vietnam', id: 'VN', code: '+84' },
  { icon: '🇼🇫', name: 'Wallis and Futuna', id: 'WF', code: '+681' },
  { icon: '🇪🇭', name: 'Western Sahara', id: 'EH', code: '+212' },
  { icon: '🇾🇪', name: 'Yemen', id: 'YE', code: '+967' },
  { icon: '🇿🇲', name: 'Zambia', id: 'ZM', code: '+260' },
  { icon: '🇿🇼', name: 'Zimbabwe', id: 'ZW', code: '+263' },
];

export default countries;
