import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Dialog, DialogTitle, DialogContent, Box, Button, TextField } from '@mui/material';
import { ComponentLoading } from 'components';

import { useMutation } from '@apollo/client';
import { MUTATION_UPDATE_TICKET_SUBJECT } from 'gql';
import { setAlert } from 'state';

interface EditSubjectDialogProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  subject: string;
  ticketId: string;
}

const EditSubjectDialog: React.FC<EditSubjectDialogProps> = (props) => {
  const { open, setOpen, subject, ticketId } = props;

  const [editSubject, setEditSubject] = useState(subject);

  const dispatch = useDispatch();

  const [updateTicketSubject, { data: dataUpdateTicketSubject, error: errorUpdateTicketSubject, loading: loadingUpdateTicketSubject }] =
    useMutation(MUTATION_UPDATE_TICKET_SUBJECT);

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async () => {
    if (editSubject === '') return;

    await updateTicketSubject({
      variables: {
        ticketId: ticketId,
        subject: editSubject,
      },
    });

    setOpen(false);
  };

  useEffect(() => {
    if (open) setEditSubject(subject);
  }, [open, subject]);

  useEffect(() => {
    if (errorUpdateTicketSubject) {
      dispatch(setAlert('error', 'Unable to change subject'));
    } else if (dataUpdateTicketSubject) {
      if (dataUpdateTicketSubject.updateTicketSubject?.ok) {
        dispatch(setAlert('success', 'Updated subject'));
      } else {
        dispatch(setAlert('error', dataUpdateTicketSubject.updateTicketSubject?.error || 'Unable to change subject'));
      }
    }
  }, [errorUpdateTicketSubject, dataUpdateTicketSubject, dispatch]);

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={handleClose}
      PaperProps={{
        sx: {
          maxWidth: '440px',
        },
      }}
    >
      <DialogTitle
        sx={{
          textAlign: 'center',
          fontSize: '24px',
          fontWeight: 700,
        }}
      >
        Edit Subject
      </DialogTitle>
      <DialogContent
        sx={{
          px: {
            md: 5,
            xs: 2,
          },
        }}
      >
        <ComponentLoading loading={loadingUpdateTicketSubject}>
          <Box py={1}>
            <TextField
              fullWidth
              name="subject"
              label="Edit Subject"
              value={editSubject}
              onChange={(e) => {
                setEditSubject(e.target.value);
              }}
              error={editSubject === ''}
              helperText={editSubject === '' ? 'Required' : ''}
            />
            <Box
              sx={{
                textAlign: 'right',
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  width: '100px',
                }}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                variant="contained"
                sx={{
                  width: '100px',
                  ml: 2,
                }}
                onClick={() => {
                  onSubmit();
                }}
              >
                Confirm
              </Button>
            </Box>
          </Box>
        </ComponentLoading>
      </DialogContent>
    </Dialog>
  );
};

export default EditSubjectDialog;
