import React, { useState, useEffect } from 'react';
import { Box, Container, IconButton } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';

import { QUERY_SAVVY_AIRCRAFT_ENGINE_DATA_FILES, QUERY_SAVVY_AIRCRAFT_ENGINE_DATA_FILE_DOWNLOAD } from 'gql';
import { useLazyQuery } from '@apollo/client';
import { ComponentLoading } from 'components';

import { DataGridPro, GridValueGetterParams, GridRenderCellParams } from '@mui/x-data-grid-pro';

import { apolloClient } from 'services';
import { downloadFileInNewTab } from 'helpers';
import { ViewLogModal } from 'pages/Aircrafts/components/Dialog';

interface EngineDataFilesProps {
  isLoad: Boolean;
  aircraftId: string;
}

const EngineDataFiles: React.FC<EngineDataFilesProps> = (props) => {
  const { isLoad, aircraftId } = props;

  const [fetchEngineDataFiles, { data: dataFiles, loading: loadingFiles }] = useLazyQuery(QUERY_SAVVY_AIRCRAFT_ENGINE_DATA_FILES);

  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(0);

  const [fileId, setFileId] = useState('');
  const [showLogModal, setShowLogModal] = useState(false);

  const [loadingDownloadFile, setLoadingDownloadFile] = useState(false);

  const downloadFile = async (engineDataFileId: string) => {
    setLoadingDownloadFile(true);
    const { data: dataDownloadUrl } = await apolloClient.query({
      query: QUERY_SAVVY_AIRCRAFT_ENGINE_DATA_FILE_DOWNLOAD,
      variables: {
        aircraftId,
        engineDataFileId,
      },
      fetchPolicy: 'no-cache',
    });

    setLoadingDownloadFile(false);

    if (dataDownloadUrl) {
      if (dataDownloadUrl?.savvy?.aircraft?.engineDataFiles[0]?.resultFileS3Url) {
        downloadFileInNewTab(dataDownloadUrl.savvy.aircraft.engineDataFiles[0].resultFileS3Url);
      }
    }
  };

  useEffect(() => {
    if (aircraftId && isLoad) {
      fetchEngineDataFiles({
        variables: {
          aircraftId,
        },
      });
    }
  }, [isLoad, aircraftId]);

  return (
    <Container fixed maxWidth="xl">
      <Box>
        <ComponentLoading loading={loadingFiles || loadingDownloadFile}>
          <Box
            py={1}
            sx={{
              width: '100%',
            }}
          >
            {isLoad && (
              <DataGridPro
                sx={{
                  width: '100%',
                  minWidth: '100px',
                  '& .MuiDataGrid-columnSeparator': {
                    display: 'none',
                  },
                  '& .MuiDataGrid-cell': {
                    padding: '10px',
                  },
                  '& .MuiDataGrid-cell:focus': {
                    outline: 'none',
                  },
                  '& .MuiDataGrid-row': {
                    cursor: 'pointer',
                    borderBottom: '1px solid',
                    borderColor: 'grey.200',
                  },
                  '& .MuiDataGrid-columnHeader:focus': {
                    outline: 'none',
                  },
                  '& .MuiDataGrid-cellContent': {
                    whiteSpace: 'break-spaces',
                  },
                  '& .MuiDataGrid-toolbarContainer .MuiTextField-root': {
                    mb: 0,
                  },
                  border: 'none',
                }}
                autoHeight
                disableColumnResize={true}
                disableSelectionOnClick={true}
                rowsPerPageOptions={[5, 10, 20, 100]}
                pageSize={pageSize}
                onPageSizeChange={(newPage) => setPageSize(newPage)}
                pagination
                showColumnRightBorder={false}
                disableColumnMenu={true}
                page={page}
                onPageChange={(newPage) => setPage(newPage)}
                rows={dataFiles?.savvy?.aircraft?.engineDataFiles ? dataFiles.savvy.aircraft.engineDataFiles : []}
                columns={[
                  {
                    field: 'name',
                    headerName: 'Name',
                    flex: 1,
                  },
                  {
                    field: 'uploadDate',
                    headerName: 'Upload Date',
                    valueGetter: (value: GridValueGetterParams) => {
                      return value.row?.uploadDate ? value.row?.uploadDate.toString().substring(0, 10) : '';
                    },
                    flex: 0.5,
                  },
                  {
                    field: 'flightsCount',
                    headerName: 'Number of Flights',
                    valueGetter: (params: GridValueGetterParams) => {
                      return params.value ? params.value : 0;
                    },
                    flex: 0.8,
                  },
                  {
                    field: 'parseLogs',
                    headerName: 'View Logs',
                    renderCell: (params: GridRenderCellParams<string>) => {
                      return (
                        <IconButton
                          onClick={() => {
                            setFileId(params.row?.id || '');
                            setShowLogModal(true);
                          }}
                        >
                          <TextSnippetIcon />
                        </IconButton>
                      );
                    },
                  },
                  {
                    field: 'id',
                    headerName: 'Action',
                    renderCell: (params: GridRenderCellParams) => {
                      return (
                        <IconButton
                          title="Download"
                          sx={{
                            ml: 1,
                          }}
                          onClick={() => {
                            if (params.value) {
                              downloadFile(params.value);
                            }
                          }}
                        >
                          <DownloadIcon />
                        </IconButton>
                      );
                    },
                  },
                ]}
              />
            )}
          </Box>
        </ComponentLoading>
      </Box>
      <ViewLogModal open={showLogModal} setOpen={setShowLogModal} fileId={fileId} aircraftId={aircraftId} />
    </Container>
  );
};

export default EngineDataFiles;
