import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Box, Typography } from '@mui/material';

import { LayoutLoggedOut, MuiFormikInput, ComponentLoading, ButtonSubmit } from 'components';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';

import { MUTATION_ACCESS_VIEW } from 'gql';
import { useMutation } from '@apollo/client';
import { setAlert } from 'state';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid Email')
    .min(3, 'Too short: 3-50 characters required')
    .max(50, 'Too long: 3-50 characters required')
    .required('Required'),
});

const SecurityCheck: React.FC = () => {
  const [accessView, { data, loading, error }] = useMutation(MUTATION_ACCESS_VIEW);
  const dispatch = useDispatch();

  const onSubmit = async (values: any) => {
    await accessView({
      variables: {
        email: values.email,
      },
    });
  };

  useEffect(() => {
    if (error) {
      dispatch(setAlert('error', 'Unable to send security link to your email'));
    } else if (data) {
      if (data.accessView?.ok) {
        dispatch(setAlert('success', 'Sent security link to your email. Please check'));
      } else {
        dispatch(setAlert('error', data.accessView?.error || 'Unable to send security link to your email'));
      }
    }
  }, [data, error, dispatch]);

  return (
    <LayoutLoggedOut>
      <Box p={5}>
        <Typography
          variant="h2"
          sx={{
            color: 'error.main',
          }}
          align="center"
          gutterBottom
        >
          Access restricted to team Savvy members.
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Box>
            <Box py={1}>
              <Typography
                variant="h4"
                sx={{
                  color: 'error.main',
                }}
                align="center"
                gutterBottom
              >
                Please read below.
              </Typography>
              <Typography align="center">
                To login this site, you must have a Savvy email address.
                <br />
                <br />
                Enter your email below, and we'll send you a link that will get you past this screen.
                <br />
                <br />
                You will still need to login your account using whatever regular credenials you have.
              </Typography>
            </Box>
            <Box
              py={1}
              sx={{
                maxWidth: '350px',
                mx: 'auto',
              }}
            >
              <ComponentLoading loading={loading}>
                <Formik
                  initialValues={{
                    email: '',
                  }}
                  onSubmit={onSubmit}
                  validationSchema={validationSchema}
                >
                  {({ isSubmitting, handleChange, handleBlur, touched, errors }) => {
                    return (
                      <Form>
                        <Box py={1}>
                          <MuiFormikInput
                            name="email"
                            label="Email *"
                            placeholder="Email to send security link"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            noMargin
                          />
                        </Box>
                        <Box py={1}>
                          <ButtonSubmit text="Send" loading={isSubmitting || loading} />
                        </Box>
                      </Form>
                    );
                  }}
                </Formik>
              </ComponentLoading>
            </Box>
          </Box>
        </Box>
      </Box>
    </LayoutLoggedOut>
  );
};

export default SecurityCheck;
