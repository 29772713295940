import React from 'react';

import { Box, Typography } from '@mui/material';
import { Subsection } from 'components';

interface IPriceTotal {
  label: string;
  price: string;
}

const PriceTotal: React.FC<IPriceTotal> = (props) => {
  const { label, price } = props;

  return (
    <Subsection>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        border={1}
        borderLeft={0}
        borderRight={0}
        borderColor="grey.200"
        py={2}
      >
        <Typography variant="body1">{label}</Typography>
        <Typography variant="h4">
          <Box component="span" fontWeight="fontWeightBold">
            {price}
          </Box>
        </Typography>
      </Box>
    </Subsection>
  );
};

export default PriceTotal;
