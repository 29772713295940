// Apollo GraphQL client
import { ApolloClient, InMemoryCache, from, HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { store, setAlert } from 'state';

import Cookies from 'js-cookie';
import 'cross-fetch/polyfill';
import { GRAPHQL_NETWORK_ERROR_MESSAGE } from 'const';
import * as Sentry from '@sentry/browser';

// Set CSRF Token to headers to do GraphQL requests to Django
const authLink = setContext(() => ({
  headers: {
    'X-CSRFToken': Cookies.get('csrftoken'), // Use latest
  },
}));

// Handle GraphQL Errors - message/locations/path (show alerts in dev mode)
const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  // if (graphQLErrors) {
  //   graphQLErrors.forEach(({ message }) => {
  //     if (message === 'Unauthorized') return; // Skip Unauthorized because it shows alert on each page load
  //     // Show Alert for Each Server Error - ONLY in Development mode. To prevent show inner development errors to user
  //     process.env.NODE_ENV === 'development' && store.dispatch(setAlert('error', message));
  //   });
  // };
  // Network errors occur when a request fails
  if (networkError) {
    // Timeout to prevent inifinite immediate alerts
    setTimeout(function () {
      // Show One Alert for Network Error
      Sentry.setContext('network error with apollo', {
        query: operation.operationName,
        variables: {
          ...operation.variables,
        },
      });
      store.dispatch(setAlert('error', GRAPHQL_NETWORK_ERROR_MESSAGE, 'ALERT_NETWORK_ERROR_ID'));
    }, 10000);
  }
});

// Http Link
const httpLink = new HttpLink({
  credentials: 'same-origin',
  fetchOptions: {
    mode: 'same-origin',
  },
  uri: process.env.NODE_ENV === 'test' ? process.env.REACT_APP_GRAPHQL_SERVER : '',
});

// Concat links
const link = from([authLink, errorLink, httpLink]);
// const link = from([authLink, httpLink]);

// Init client
// We do not provide URI option to make custom error handling work
const apolloClient = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      SavvyType: {
        fields: {
          permissions: {
            merge(existing = {}, incoming: any) {
              return {
                ...existing,
                ...incoming,
              };
            },
          },
        },
      },
    },
  }),
  link: link,
});

export default apolloClient;
