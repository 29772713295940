import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Link, CircularProgress } from '@mui/material';
import AttachmentIcon from '@mui/icons-material/Attachment';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';
import { FileUploader } from 'react-drag-drop-files';

import { uploadFileDirectS3, createDocumentFromS3 } from 'helpers';
import { setAlert } from 'state';
import { FLIGHT_FILE_MAX_SIZE } from 'const';

export type IUploadedFile = {
  id: string;
  name: string;
  success: boolean;
};

interface AttachmentsProps {
  uploadedFileList: IUploadedFile[];
  setUploadedFileList: (value: IUploadedFile[]) => void;
  setOpenExistingFilesModal: (value: boolean) => void;
  dropFiles: File[];
  setDropFiles: (value: File[]) => void;
}

const Attachments: React.FC<AttachmentsProps> = (props) => {
  const { uploadedFileList, setUploadedFileList, setOpenExistingFilesModal, dropFiles, setDropFiles } = props;

  const [uploadingFiles, setUploadingFiles] = useState(false);
  const dispatch = useDispatch();

  const uploadAttachments = async (files: File[]) => {
    setUploadingFiles(true);
    setDropFiles([]);
    const tmp = [...uploadedFileList];
    for (let i = 0; i < files.length; i++) {
      const result = await uploadFileDirectS3(files[i], true, '');

      if (result.success) {
        const documentData = await createDocumentFromS3(result.key);
        if (!documentData.ok) {
          dispatch(setAlert('error', documentData.error || 'Unable to upload attachment'));
        } else {
          tmp.push({
            id: documentData.document?.id || '',
            name: files[i].name,
            success: true,
          });
        }
      } else {
        dispatch(setAlert('error', 'Unable to upload attachment'));
      }

      setUploadedFileList([...tmp]);
    }
    setUploadingFiles(false);
  };

  useEffect(() => {
    if (dropFiles.length) {
      uploadAttachments(dropFiles);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropFiles]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      {uploadingFiles && <CircularProgress color="inherit" size={20} variant="indeterminate" />}
      <Box ml={1}>
        <FileUploader
          handleChange={(files: File[]) => {
            uploadAttachments(files);
          }}
          maxSize={FLIGHT_FILE_MAX_SIZE}
          multiple={true}
          name="attachFile"
        >
          <AttachmentIcon
            sx={{
              transform: 'rotate(-45deg)',
              color: 'rgba(150, 158, 179, 0.6)',
              cursor: 'pointer',
            }}
          />
        </FileUploader>
        <Link
          title="Attach Existing Files"
          href="#"
          sx={{
            color: 'rgba(150, 158, 179, 0.6)',
            ml: 1,
          }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setOpenExistingFilesModal(true);
          }}
        >
          <FolderSpecialIcon />
        </Link>
      </Box>
    </Box>
  );
};

export default Attachments;
