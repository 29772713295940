import React from 'react';
import { Box, FormHelperText } from '@mui/material';
import { FORM_ERROR } from 'const';

// Used after submit button. To show user that form has errors (especially mobile users when form fields errors are outside of screen)
const FormErrorMessage: React.FC = () => {
  return (
    <Box textAlign="center" my={1}>
      <FormHelperText error component="span">
        {FORM_ERROR}
      </FormHelperText>
    </Box>
  );
};

export default FormErrorMessage;
