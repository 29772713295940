import { Redirect, Route } from 'react-router-dom';

import { useSelector } from 'react-redux';

import { PageLoading } from 'components';
import { NotFound } from 'pages';

import { PATH_LOGIN, PATH_SECURITY_CHECK } from 'const';

interface IPrivateRoute {
  component: any;
  path: string;
  exact: boolean;
}

const PrivateTechRoute = (props: IPrivateRoute) => {
  const { component: Component, ...rest } = props; // Get Component user try access
  const { loggedIn, incompleteAircraft, isTechnician, missingToken } = useSelector((state: any) => {
    return state.auth;
  }); // Get auth state (Redux)

  // If loading
  if (loggedIn === null) return <PageLoading />;

  if (missingToken) {
    return <Route render={() => <Redirect to={PATH_SECURITY_CHECK} />} {...rest} />;
  }

  // NOT logged in
  if (loggedIn !== null && !loggedIn) {
    // Get next prop from url
    const next = window.location.pathname;

    // Redirect to login
    return (
      <Route
        render={() => (
          <Redirect
            to={{
              pathname: PATH_LOGIN,
              state: { next: next },
            }}
          />
        )}
        {...rest}
      />
    );
  }

  if (loggedIn && incompleteAircraft?.length > 0) {
    if (incompleteAircraft[0].subscriptions?.length > 0) {
      return <Redirect to={`/signup/${incompleteAircraft[0].subscriptions[0].serviceCode}/additional-info`} />;
    }
  }

  // If logged IN - proceed to page
  return <Route render={() => (isTechnician ? <Component {...rest} /> : <NotFound />)} {...rest} />;
};

export default PrivateTechRoute;
