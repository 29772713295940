import React, { useState, useEffect } from 'react';

import { Link as RouterLink, useLocation, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import { useMutation } from '@apollo/client';
import { MUTATION_LOGIN } from 'gql';

import { Grid, Typography, Link, IconButton, InputAdornment, Box } from '@mui/material';
import { TextField, FormGroup } from '@mui/material';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { Formik, Form, ErrorMessage } from 'formik';

import { LayoutLoggedOut, Subsection, ButtonSubmit } from 'components';

import { setAlert } from 'state';

import {
  PATH_RESET_PASSWORD,
  LOGIN_SUCCESS_MESSAGE,
  PATH_LOGIN_WITH_EMAIL,
  PATH_SECURITY_CHECK,
  PATH_SIGNUP_COMPLETE_ADDITIONAL_INFO,
} from 'const';

interface LoginFormValues {
  email: string;
  password: string;
}

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .min(3, 'Too short: 3-100 characters required')
    .max(100, 'Too long: 3-100 characters required')
    .required('Required'),
  password: Yup.string().min(8, 'Too short: 8-50 characters required').max(50, 'Too long: 8-50 characters required').required('Required'),
});

const useQuery = () => new URLSearchParams(useLocation().search);
const REQUIRE_TOKEN_MSG = 'Need security token';

const Login: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [showPassword, setShowPassword] = useState(false);

  const query = useQuery();
  const nextUrl = query.get('next');

  const [login, { loading, data, error }] = useMutation(MUTATION_LOGIN);

  const [submitting, setSubmitting] = useState(false);

  const onSubmit = async (values: LoginFormValues) => {
    // Login: Call GraphQL mutation
    await login({ variables: { username: values.email, password: values.password } });
  };

  useEffect(() => {
    if (!URLSearchParams) {
      dispatch(setAlert('error', 'Your browser is out of date. Please update to a newer version.'));
    }
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      dispatch(setAlert('error', error.message));
      if (error.message === REQUIRE_TOKEN_MSG) {
        history.push(PATH_SECURITY_CHECK);
      }
    } else if (data) {
      const { errors, me, redirect, error } = data.login;

      if (!me && !redirect) {
        dispatch(setAlert('error', error || 'Invalid username or password'));
        return;
      }

      if (me && me?.isTechnician) {
        window.location.href = '/';
        return;
      }

      // If redirect received - superuser/admin. Check it first
      if (redirect) {
        // Use javascript redirect instead of react-router, because the link is outside the React app
        window.location.href = redirect;
        return;
      }

      // Errors
      // TODO: When would errors be true here?
      if (!errors) {
        setSubmitting(true);

        dispatch(setAlert('success', LOGIN_SUCCESS_MESSAGE, 'ALERT_LOGIN_SUCCESS_ID'));

        setTimeout(() => {
          setSubmitting(false);
          if (!me?.incompleteAircraft?.length) {
            if (nextUrl) window.location.href = nextUrl;
            else window.location.href = '/';
          } else {
            if (me?.incompleteAircraft[0]?.subscriptions?.length) {
              window.location.href = `/signup/${me.incompleteAircraft[0].subscriptions[0].serviceCode}/additional-info`;
            } else {
              window.location.href = PATH_SIGNUP_COMPLETE_ADDITIONAL_INFO;
            }
          }
        }, 500);
      }
    }
  }, [error, data, dispatch, nextUrl, history]);

  return (
    <LayoutLoggedOut>
      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12} sm={10} md={6} lg={5}>
          <Subsection>
            <Typography variant="h1" align="center" gutterBottom>
              Login
            </Typography>
          </Subsection>

          <Formik initialValues={{ email: '', password: '' }} onSubmit={onSubmit} validationSchema={LoginSchema}>
            {({ isSubmitting, handleChange, handleBlur }) => (
              <Form>
                <Subsection>
                  <FormGroup>
                    <TextField
                      id="email"
                      name="email"
                      label="Email"
                      variant="outlined"
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled={isSubmitting}
                    />
                    <ErrorMessage name="email">{(msg) => <div style={{ color: 'red' }}>{msg}</div>}</ErrorMessage>
                  </FormGroup>

                  <FormGroup>
                    <TextField
                      id="password"
                      name="password"
                      label="Password"
                      type={showPassword ? 'text' : 'password'}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={() => setShowPassword(!showPassword)}>
                              {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      disabled={isSubmitting}
                    />
                    <ErrorMessage name="password">{(msg) => <div style={{ color: 'red' }}>{msg}</div>}</ErrorMessage>
                  </FormGroup>
                  <ButtonSubmit text="Login" loading={loading || submitting} disabled={isSubmitting || submitting} />
                </Subsection>
              </Form>
            )}
          </Formik>

          <Subsection>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography>
                <RouterLink to={PATH_LOGIN_WITH_EMAIL}>
                  {' '}
                  <Link component="span">Login with Email</Link>
                </RouterLink>
              </Typography>
              <Typography px={1}>or</Typography>
              <Typography align="center">
                <RouterLink to={PATH_RESET_PASSWORD}>
                  {' '}
                  <Link component="span">Reset password</Link>
                </RouterLink>
              </Typography>
            </Box>
          </Subsection>
        </Grid>
      </Grid>
    </LayoutLoggedOut>
  );
};

export default Login;
