import React from 'react';

import { IconButton, Menu, MenuItem, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { NavLink as RouterLink } from 'react-router-dom';

import { IAppBarLink } from 'types';

import styles from './style.module.scss';

import { LOGGED_IN_LEFT_LINKS, LOGGED_IN_RIGHT_LINKS, PATH_FLIGHTS_UPLOAD } from 'const';
import { UserMenu, HelpMenu, UploadMenu } from '../';
import { logAmplitudeEvent } from 'services';

interface CollapsedMenuProps {
  role: string;
}

const CollapsedMenu: React.FC<CollapsedMenuProps> = (props) => {
  const { role } = props;

  const ALL_LINKS: IAppBarLink[] = [...LOGGED_IN_LEFT_LINKS, ...LOGGED_IN_RIGHT_LINKS];

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleClick}>
        <MenuIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {ALL_LINKS.map((link: IAppBarLink) => {
          if (link.path === PATH_FLIGHTS_UPLOAD) {
            return null;
          }
          return (
            <MenuItem
              key={link.path}
              onClick={() => {
                logAmplitudeEvent('menu_' + link.text.replace(' ', '_').toLowerCase(), {
                  role,
                });
              }}
            >
              {!link.legacy && (
                <RouterLink to={link.path} exact={link.exact} className={styles.navLink}>
                  <Typography variant="body1">{link.text}</Typography>
                </RouterLink>
              )}
              {link.legacy && (
                <a href={link.path}>
                  <Typography variant="body1">{link.text}</Typography>
                </a>
              )}
            </MenuItem>
          );
        })}
        <UploadMenu />
        <UserMenu role={role} />
        <HelpMenu />
      </Menu>
    </>
  );
};

export default CollapsedMenu;
