import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button, TextField, MenuItem } from '@mui/material';
import { useMutation } from '@apollo/client';
import { MUTATION_CREATE_SERVICE_SIGNUP_TAG } from 'gql';
import { IDropDownOption } from 'types';
import { setAlert } from 'state';

interface ServiceSignupTagProps {
  handleClose: () => void;
  setLoading: (value: boolean) => void;
  ticketId: string;
  services: IDropDownOption[];
}

const ServiceSignupTag: React.FC<ServiceSignupTagProps> = (props) => {
  const { ticketId, setLoading, handleClose, services } = props;

  const [createServiceSignupTag, { data: dataServiceSignupTag, error: errorServiceSignupTag, loading: loadingServiceSignupTag }] =
    useMutation(MUTATION_CREATE_SERVICE_SIGNUP_TAG);
  const dispatch = useDispatch();
  const [serviceId, setServiceId] = useState('');
  const [serviceError, setServiceError] = useState('');

  const onSubmit = async () => {
    if (!serviceId) {
      setServiceError('Please select service');
      return;
    }

    await createServiceSignupTag({
      variables: {
        ticketId,
        serviceId,
      },
    });
  };

  useEffect(() => {
    if (errorServiceSignupTag) {
      dispatch(setAlert('error', 'Unable to create service signup tag'));
    } else if (dataServiceSignupTag && dataServiceSignupTag.createServiceSignupTag?.ok) {
      dispatch(setAlert('success', 'Created service signup tag'));
      handleClose();
    }
  }, [errorServiceSignupTag, dataServiceSignupTag, dispatch, handleClose]);

  useEffect(() => {
    setLoading(loadingServiceSignupTag);
  }, [loadingServiceSignupTag, setLoading]);

  return (
    <React.Fragment>
      {!!services.length && (
        <Box py={1}>
          <TextField
            select
            label="Select Service"
            name="service"
            value={serviceId}
            onChange={(e) => {
              setServiceId(e.target.value);
              setServiceError('');
            }}
            fullWidth
            error={!!serviceError}
            helperText={serviceError ? serviceError : ''}
            FormHelperTextProps={{
              sx: {
                ml: 0,
              },
            }}
          >
            {services.map((service: IDropDownOption, key: number) => {
              return (
                <MenuItem key={key} value={service.id}>
                  {service.name}
                </MenuItem>
              );
            })}
          </TextField>
        </Box>
      )}
      <Box
        sx={{
          textAlign: 'right',
        }}
        py={1}
      >
        <Button
          variant="outlined"
          sx={{
            width: '100px',
            mr: 2,
          }}
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          sx={{
            width: '100px',
          }}
          onClick={() => {
            onSubmit();
          }}
        >
          Create
        </Button>
      </Box>
    </React.Fragment>
  );
};

export default ServiceSignupTag;
