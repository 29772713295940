import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import { Box, Grid, Typography, Container } from '@mui/material';
import { LayoutDashboard, PageLoading, Subsection, Section } from 'components';
import { UpgradePlanAction, NewPlanAction } from './components';

import { QUERY_AIRCRAFT_UPGRADE_PLAN_INFO } from 'gql';
import { useLazyQuery } from '@apollo/client';

import { PATH_AIRCRAFT_VIEW, NEW_PLAN, UPGRADE_PLAN, ADD_PLAN, CHANGE_PLAN, RENEW_PLAN } from 'const';
import { useService, useMdUp } from 'hooks';
import { getServiceName, ctrlCmdClick } from 'helpers';
import { setAlert } from 'state';
import { IAircraftDetail } from 'types';

const UpgradePlan: React.FC = () => {
  const {
    id,
    type,
    service: serviceCode,
  } = useParams<{
    id: string;
    type: string;
    service: string;
  }>();

  const [fetchAircraftInfo, { data: dataAircraftInfo, loading, error }] = useLazyQuery(QUERY_AIRCRAFT_UPGRADE_PLAN_INFO);
  const dispatch = useDispatch();

  const service = useService();
  const history = useHistory();
  const isMdUp = useMdUp();

  const makeDescription = (aircraft: IAircraftDetail) => {
    switch (type) {
      case ADD_PLAN:
        return `Adding ${getServiceName(serviceCode)} service today`;
      case NEW_PLAN:
        return `Adding ${getServiceName(serviceCode)} service today`;
      case CHANGE_PLAN:
        return `Changing ${aircraft.subscriptions[0]?.serviceName} to ${getServiceName(
          serviceCode,
        )} on renewal (${aircraft.subscriptions[0]?.endDate?.toString().substring(0, 10)})`;
      case UPGRADE_PLAN:
        return `Upgrading ${aircraft.subscriptions[0]?.serviceName} to ${getServiceName(serviceCode)} today`;
      case RENEW_PLAN:
        return `Renewing ${getServiceName(
          serviceCode,
        )}. You will be charged today. New plan terms starts on ${aircraft.subscriptions[0]?.endDate?.toString().substring(0, 10)}.`;
      default:
        return '';
    }
  };

  useEffect(() => {
    if (id) {
      fetchAircraftInfo({
        variables: {
          id: parseInt(id),
        },
      });
    }
  }, [id, fetchAircraftInfo]);

  useEffect(() => {
    if (error) {
      dispatch(setAlert('error', 'Unable to load your aircraft'));
    } else if (dataAircraftInfo?.me) {
      if (!dataAircraftInfo.me?.aircraft?.length) {
        dispatch(setAlert('error', 'Unable to access this aircraft'));
      }
    }
  }, [error, dataAircraftInfo, dispatch]);

  return (
    <LayoutDashboard backgroundColor="#fff">
      <Box
        width="100%"
        sx={{
          pl: {
            xs: 2,
            sm: 2,
            md: 2,
          },
          pr: {
            xs: 2,
            sm: 2,
            md: 2,
          },
        }}
      >
        {loading && <PageLoading />}
        <Section>
          <Container>
            <Subsection>
              <Typography variant="h1" component="h2" gutterBottom>
                {getServiceName(serviceCode)}
              </Typography>
            </Subsection>
            <Grid container spacing={isMdUp ? 4 : 1} justifyContent="space-between">
              <Grid item xs={12} md={6} lg={5}>
                {service?.description}
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="h3"
                  align="center"
                  sx={{
                    fontWeight: 'bold',
                    cursor: 'pointer',
                  }}
                  onClick={(e: any) => {
                    ctrlCmdClick(e, `${PATH_AIRCRAFT_VIEW}/${id}`, history);
                  }}
                  gutterBottom
                >
                  {dataAircraftInfo?.me?.aircraft[0]?.registration || ''}
                </Typography>
                <Typography align="center" gutterBottom>
                  {dataAircraftInfo?.me?.aircraft[0] ? makeDescription(dataAircraftInfo?.me?.aircraft[0]) : ''}
                </Typography>
                <Grid container justifyContent="center">
                  <Grid item xs={12} md={10} lg={11} xl={10}>
                    {dataAircraftInfo?.me && (type === UPGRADE_PLAN || type === CHANGE_PLAN || type === RENEW_PLAN) && (
                      <UpgradePlanAction aircraft={dataAircraftInfo?.me?.aircraft[0]} creditCard={dataAircraftInfo?.me?.creditCard} />
                    )}
                    {dataAircraftInfo?.me && (type === NEW_PLAN || type === ADD_PLAN) && (
                      <NewPlanAction aircraft={dataAircraftInfo?.me?.aircraft[0]} creditCard={dataAircraftInfo?.me?.creditCard} />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Section>
      </Box>
    </LayoutDashboard>
  );
};

export default UpgradePlan;
