import React, { useState, useRef, useEffect } from 'react';
import { Dialog, DialogContent, Box, Typography, IconButton, Grid } from '@mui/material';
import { ComponentLoading } from 'components';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';

interface ImgDialogProps {
  showFileDialog: boolean;
  setShowFileDialog: (value: boolean) => void;
  showFileName: string;
  showFile: string;
  previewAttachmentId: string;
  nextAttachmentLoad?: (id: string) => void;
  prevAttachmentLoad?: (id: string) => void;
  loadingAttachmentData?: boolean;
}

const ImgDialog: React.FC<ImgDialogProps> = (props) => {
  const {
    showFileDialog,
    setShowFileDialog,
    showFileName,
    showFile,
    previewAttachmentId,
    nextAttachmentLoad,
    prevAttachmentLoad,
    loadingAttachmentData = false,
  } = props;

  const [zoom, setZoom] = useState(0.9);
  const [rotate, setRotate] = useState(0);

  const dialogRef = useRef<HTMLDivElement>();

  const [imgWidth, setImgWidth] = useState(0);

  useEffect(() => {
    if (showFile && showFileDialog) {
      const img = new Image();

      img.onload = () => {
        const width = img.width;
        const height = img.height;

        const containerHeight = dialogRef.current ? Math.max(dialogRef.current.offsetHeight - 116, 0) : 0;
        const containerWidth = dialogRef.current ? Math.max(dialogRef.current.offsetWidth - 48, 0) : 0;

        if (!containerHeight || !containerWidth) {
          setImgWidth(width);
        }

        const minZoom = Math.min(containerWidth / width, containerHeight / height);

        setImgWidth(width * minZoom);
      };

      img.src = showFileDialog ? showFile : '';
    }
  }, [showFile, showFileDialog]);

  const handleClose = () => {
    setShowFileDialog(false);
    setZoom(0.9);
    setRotate(0);
  };

  const zoomIn = () => {
    if (zoom < 1.5) {
      setZoom(zoom + 0.1);
    }
  };

  const zoomOut = () => {
    if (zoom > 0.1) {
      setZoom(zoom - 0.1);
    }
  };

  const rotateDocument = () => {
    if (rotate === 270) {
      setRotate(0);
    } else {
      setRotate(rotate + 90);
    }
  };

  return (
    <Dialog
      open={showFileDialog}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
      PaperProps={{
        sx: {
          m: {
            xs: 0,
            sm: 4,
          },
          maxWidth: {
            xs: '100%',
            sm: 'calc(100% - 64px)',
          },
          width: {
            xs: '100%',
            sm: 'calc(100% - 64px)',
          },
          height: 'calc(100vh - 64px)',
        },
      }}
    >
      <DialogContent
        sx={{
          pt: 0,
        }}
        ref={dialogRef}
      >
        <ComponentLoading loading={loadingAttachmentData}>
          <Box
            sx={{
              textAlign: 'right',
            }}
          >
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box py={1}>
            <Grid container spacing={0}>
              <Grid item xs={3}>
                <Box
                  sx={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    variant="h2"
                    sx={{
                      fontWeight: 700,
                      fontSize: {
                        lg: '22px',
                        md: '18px',
                        xs: '16px',
                      },
                    }}
                  >
                    {showFileName}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                    }}
                  >
                    {prevAttachmentLoad && (
                      <IconButton
                        sx={{
                          mx: 1,
                        }}
                        onClick={() => {
                          prevAttachmentLoad(previewAttachmentId);
                        }}
                        title="Previous Attachment"
                      >
                        <SkipPreviousIcon />
                      </IconButton>
                    )}
                    <IconButton
                      sx={{
                        mx: 1,
                      }}
                      onClick={zoomIn}
                    >
                      <AddIcon />
                    </IconButton>
                    <IconButton
                      sx={{
                        mx: 1,
                      }}
                      onClick={zoomOut}
                    >
                      <RemoveIcon />
                    </IconButton>
                    <IconButton
                      sx={{
                        mx: 1,
                      }}
                      onClick={rotateDocument}
                    >
                      <RotateRightIcon />
                    </IconButton>
                    {nextAttachmentLoad && (
                      <IconButton
                        sx={{
                          mx: 1,
                        }}
                        onClick={() => {
                          nextAttachmentLoad(previewAttachmentId);
                        }}
                        title="Next Attachment"
                      >
                        <SkipNextIcon />
                      </IconButton>
                    )}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box
            sx={{
              background: '#B4B9CC',
              textAlign: 'center',
            }}
          >
            <Box
              component={'img'}
              src={showFile}
              width={imgWidth * zoom}
              sx={{
                margin: '0 auto',
                transform: `rotate(${rotate}deg)`,
              }}
            ></Box>
          </Box>
        </ComponentLoading>
      </DialogContent>
    </Dialog>
  );
};

export default ImgDialog;
