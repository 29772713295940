import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Popover, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { MUTATION_SET_TICKET_WATCHER_COLOR } from 'gql';
import { useMutation } from '@apollo/client';
import { setAlert } from 'state';

import { ComponentLoading } from 'components';
import { TICKET_COLOR_LIST } from 'const';

interface WatcherColorProps {
  setOpen: (value: HTMLElement | null) => void;
  ticketId: string;
  anchorEl: HTMLElement | null;
}

const WatcherColor: React.FC<WatcherColorProps> = (props) => {
  const { setOpen, ticketId, anchorEl } = props;

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const [setColorSubmit, { data: dataColor, loading: loadingColor, error: errorColor }] = useMutation(MUTATION_SET_TICKET_WATCHER_COLOR);
  const dispatch = useDispatch();

  const setColor = async (color: string) => {
    await setColorSubmit({
      variables: {
        ticketId,
        color,
      },
    });
  };

  const handleClose = () => {
    setOpen(null);
  };

  useEffect(() => {
    if (errorColor) {
      dispatch(setAlert('error', 'Unable to change ticket watcher color'));
    } else if (dataColor) {
      if (dataColor.setTicketWatcherColor?.ok) {
        dispatch(setAlert('success', 'Changed ticket watcher color'));
        setOpen(null);
      } else {
        dispatch(setAlert('error', dataColor.setTicketWatcherColor?.error || 'Unable to change ticket watcher color'));
      }
    }
  }, [errorColor, dataColor, dispatch, setOpen]);

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <ComponentLoading loading={loadingColor}>
        <Box p={1}>
          <Box
            sx={{
              display: 'flex',
              py: 1,
            }}
          >
            <Box
              sx={{
                width: '20px',
                height: '20px',
                cursor: 'pointer',
                display: 'block',
                mx: 0.5,
              }}
              onClick={() => {
                setColor('');
              }}
              title="Clear Color"
            >
              <CloseIcon
                sx={{
                  fill: 'red',
                  width: '20px',
                  height: '20px',
                }}
              />
            </Box>
            {TICKET_COLOR_LIST.map((color: string, key: number) => {
              if (key > 2) return null;
              return (
                <Box
                  sx={{
                    width: '20px',
                    height: '20px',
                    backgroundColor: color,
                    borderRadius: '2px',
                    border: '1px solid',
                    borderColor: 'text.secondary',
                    cursor: 'pointer',
                    display: 'block',
                    mx: 0.5,
                  }}
                  onClick={() => {
                    setColor(color);
                  }}
                  key={key}
                />
              );
            })}
          </Box>
          <Box
            sx={{
              display: 'flex',
              py: 1,
            }}
          >
            {TICKET_COLOR_LIST.map((color: string, key: number) => {
              if (key < 3) return null;
              return (
                <Box
                  sx={{
                    width: '20px',
                    height: '20px',
                    backgroundColor: color,
                    borderRadius: '2px',
                    border: '1px solid',
                    borderColor: 'text.secondary',
                    cursor: 'pointer',
                    display: 'block',
                    mx: 0.5,
                  }}
                  onClick={() => {
                    setColor(color);
                  }}
                  key={key}
                />
              );
            })}
          </Box>
        </Box>
      </ComponentLoading>
    </Popover>
  );
};

export default WatcherColor;
