import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { BreakdownPromoDialog } from './';

const BreakdownPromo: React.FC = () => {
  const [showPromo, setShowPromo] = useState(false);

  return (
    <Box
      p={2.5}
      sx={{
        border: '1px solid #E8E9EF',
        background: '#FFFFFF',
      }}
    >
      <Typography
        variant="h3"
        gutterBottom
        sx={{
          lineHeight: '25px',
          color: 'primaryDark',
          fontWeight: 700,
          textAlign: 'center',
        }}
      >
        Breakdown Assistance
      </Typography>
      <Typography
        sx={{
          fontSize: '18px',
          fontWeight: 500,
          textAlign: 'center',
          textDecoration: 'underline',
          color: 'primary.main',
          cursor: 'pointer',
        }}
        onClick={() => {
          setShowPromo(true);
        }}
      >
        More Information...
      </Typography>
      <BreakdownPromoDialog open={showPromo} setOpen={setShowPromo} />
    </Box>
  );
};

export default BreakdownPromo;
