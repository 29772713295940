import React from 'react';

import { Link as RouterLink } from 'react-router-dom';

import { Grid, Box } from '@mui/material';

import { PATH_SIGNUP_FREE, LOGO_PATH } from 'const';

import styles from './style.module.scss';

interface LogoProps {
  path?: string;
}

const Logo: React.FC<LogoProps> = (props) => {
  const { path = PATH_SIGNUP_FREE } = props;

  return (
    <Grid
      item
      sx={{
        '@media (max-width: 450px)': {
          mr: 1,
        },
      }}
    >
      <Box display="flex" alignItems="center" height="100%">
        <RouterLink to={path}>
          <img src={LOGO_PATH} alt="" className={styles.logo} />
        </RouterLink>
      </Box>
    </Grid>
  );
};

export default Logo;
