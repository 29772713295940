import React, { useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Box, Divider } from '@mui/material';

import { IFlightData, IDropDownOption, IComment, IFlightChartSetting, IAircraftSetting } from 'types';
import { FlightInfoBox } from './FlightInfoComponents';
import { ModeManager } from './chartComponents';

import { INFO_TAB_WIDTH, INFO_SAVVY_TAB_WIDTH, MIN_CHART_HEIGHT } from 'const';
import Highcharts from 'highcharts';

interface FlightInfoProps {
  flightData: IFlightData;
  modeManager: ModeManager;
  diffData: any;
  analysisMode: string;
  setAnalysisMode: (value: string) => void;
  chartNum: number;
  setChartNum: (value: number) => void;
  isShare?: boolean;
  anomalyTypes?: IDropDownOption[];
  showInfo: boolean;
  aircraftComments?: IComment[];
  contactComments?: IComment[];
  settings: IFlightChartSetting | undefined;
  setPresetMode?: (value: string) => void;
  presetMode?: string;
  aircraftSettings: IAircraftSetting[];
}

const FlightInfo: React.FC<FlightInfoProps> = (props) => {
  const {
    flightData,
    modeManager,
    diffData,
    analysisMode,
    setAnalysisMode,
    chartNum,
    setChartNum,
    isShare = false,
    anomalyTypes,
    showInfo,
    aircraftComments,
    contactComments,
    settings,
    setPresetMode,
    presetMode = '',
    aircraftSettings,
  } = props;

  const { isSavvy } = useSelector((state: any) => state.auth);
  const { isAnalysisTab } = useSelector((state: any) => state.analysis);

  const handleWindowResize = useCallback(() => {
    const size = settings?.chartsSize ? settings?.chartsSize : 'fitChart';
    let width: number | undefined = undefined;
    if (window.innerWidth >= 1024) {
      width = window.innerWidth - 48 - (showInfo ? (isSavvy && isAnalysisTab ? INFO_SAVVY_TAB_WIDTH : INFO_TAB_WIDTH) : 0) - 32; // padding, right pannel
    } else {
      width = window.innerWidth;
    }

    if (size === 'fitPage' || size === 'fitChart') {
      let height = 0;
      const chartContainer = document.getElementById('chart-container');

      const controllerHeight = 80 * chartNum; // 80 is height of apperance set

      if (size === 'fitPage') {
        height = (window.innerHeight - (chartContainer ? chartContainer.offsetTop : 0) - controllerHeight) / chartNum;
      } else if (size === 'fitChart') {
        height = (window.innerHeight - controllerHeight) / chartNum;
      }
      for (const chart of Highcharts.charts) {
        if (!chart) continue;
        chart.setSize(width, Math.max(height, MIN_CHART_HEIGHT));
      }
    } else {
      for (const chart of Highcharts.charts) {
        if (!chart) continue;
        chart.setSize(width, undefined);
      }
    }
  }, [settings, chartNum, showInfo, isSavvy, isAnalysisTab]);

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [handleWindowResize]);

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        position: 'relative',
        borderLeftColor: 'grey.200',
        width: {
          lg: showInfo ? `${isSavvy && isAnalysisTab ? INFO_SAVVY_TAB_WIDTH : INFO_TAB_WIDTH}px` : 0,
          xs: 'inherit',
        },
        mx: {
          lg: 0,
          xs: -1,
        },
        display: {
          lg: 'flex',
          xs: 'block',
        },
        py: 1,
        overflowX: 'hidden',
        '@media (min-width: 1280px)': {
          pr: showInfo ? 3 : 0,
          maxHeight: 'calc(100vh - 90px)',
          overflowY: 'auto',
          '&::-webkit-scrollbar': {
            width: '0.6em',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
            borderRadius: 2,
          },
        },
      }}
    >
      <Divider
        orientation="vertical"
        sx={{
          width: '2px',
          borderRightWidth: {
            xs: 0,
            lg: '2px',
          },
        }}
      />
      <Box
        sx={{
          width: {
            lg: showInfo ? `${isSavvy && isAnalysisTab ? INFO_SAVVY_TAB_WIDTH : INFO_TAB_WIDTH - 2}px` : 0,
            xs: 'inherit',
          },
          visibility: showInfo ? 'visible' : 'hidden',
        }}
      >
        <FlightInfoBox
          flightData={flightData}
          modeManager={modeManager}
          diffData={diffData}
          analysisMode={analysisMode}
          setAnalysisMode={setAnalysisMode}
          chartNum={chartNum}
          setChartNum={setChartNum}
          isShare={isShare}
          anomalyTypes={anomalyTypes}
          aircraftComments={aircraftComments}
          contactComments={contactComments}
          settings={settings}
          setPresetMode={setPresetMode}
          presetMode={presetMode}
          aircraftSettings={aircraftSettings}
        />
      </Box>
    </Box>
  );
};

export default FlightInfo;
