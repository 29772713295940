import {
  PATH_LOGIN,
  PATH_INTERNAL_TECHNICIAN,
  PATH_SERVICE_CENTER_CREATE,
  PATH_SERVICE_CENTER_MAP,
  PATH_INTERNAL_SERVICE_CENTER_RATING,
  PATH_INTERNAL_TICKETRATINGS,
  PATH_BETA_DASHBOARD,
  PATH_TICKETS_VIEW,
  PATH_SAVVY_TICKETS,
  PATH_TICKETS_CREATE,
  PATH_AIRCRAFT_VIEW,
  PATH_AIRCRAFT_CREATE,
  PATH_FLIGHTS,
  PATH_REPORTS_BETA,
  PATH_BETA_PLANS,
  PATH_INTERNAL_DASHBOARD,
  PATH_PERSONAL_TICKET_DASHBOARD,
  PATH_SERVICE_CENTER,
  PATH_CONTACT,
  PATH_SAVVY_AIRCRAFT_FLIGHTS,
  PATH_TICKETS_DASHBOARD,
  PATH_FILTERED_TICKETS,
  PATH_RENEWAL_TICKETS,
  PATH_UESR_TICKET_CREATE,
  PATH_SAVVY_AIRCRAFT,
  PATH_UNASSIGNED_TICKETS,
  PATH_INTERNAL_SERVICE_CENTER,
  PATH_SERVICE_CENTER_MANUAL_RATING,
  PATH_SAVVY_ACCOUNT,
  PATH_SAVVY_LOG_BACKFILL,
  PATH_SAVVY_COI_MANAGEMENT,
  PATH_MANAGER_CONFLICTS,
  PATH_SAVVY_COHORT_REPORTS,
  PATH_CREATE_TECHNICIAN,
  PATH_SAVVY_TIMESHEETS_DASHBOARD,
  PATH_SAVVY_AIRCRAFT_CREATE,
  PATH_SC_DASHBOARD,
  PATH_SC_FLIGHTS,
} from 'const';

export const PLAUSIBLE_TRACK = [
  {
    path: PATH_LOGIN,
    name: 'Login',
  },
  {
    path: PATH_INTERNAL_TECHNICIAN.replace(':id', ''),
    name: 'Technician page',
  },
  {
    path: PATH_SERVICE_CENTER_CREATE,
    name: 'Create Service Center',
  },
  {
    path: PATH_SERVICE_CENTER_MAP,
    name: 'Service Center Map',
  },
  {
    path: PATH_INTERNAL_SERVICE_CENTER_RATING.replace(':id', ''),
    name: 'Service Center Rating',
  },
  {
    path: PATH_INTERNAL_TICKETRATINGS,
    name: 'Ticket Rating',
  },
  {
    path: PATH_BETA_DASHBOARD,
    name: 'Dashboard',
  },
  {
    path: PATH_TICKETS_VIEW,
    name: 'View Ticket',
  },
  {
    path: PATH_SAVVY_TICKETS,
    name: 'View Ticket',
  },
  {
    path: PATH_TICKETS_CREATE,
    name: 'Create Ticket',
  },
  {
    path: PATH_AIRCRAFT_VIEW,
    name: 'View Aircraft',
  },
  {
    path: PATH_AIRCRAFT_CREATE,
    name: 'Create Aircraft',
  },
  {
    path: PATH_FLIGHTS,
    name: 'View Flights',
  },
  {
    path: PATH_REPORTS_BETA,
    name: 'Report',
  },
  {
    path: PATH_BETA_PLANS,
    name: 'Manage Plan',
  },
  {
    path: PATH_INTERNAL_DASHBOARD,
    name: 'Internal Dashboard',
  },
  {
    path: PATH_PERSONAL_TICKET_DASHBOARD,
    name: 'Personal Ticket Dashboard',
  },
  {
    path: PATH_SERVICE_CENTER,
    name: 'Service Centers',
  },
  {
    path: PATH_CONTACT,
    name: 'View Contact',
  },
  {
    path: PATH_SAVVY_AIRCRAFT_FLIGHTS,
    name: 'Savvy Flights',
  },
  {
    path: PATH_TICKETS_DASHBOARD,
    name: 'Ticket Dashboard',
  },
  {
    path: PATH_FILTERED_TICKETS,
    name: 'Filtered Tickets',
  },
  {
    path: PATH_RENEWAL_TICKETS,
    name: 'Renewal Tickets',
  },
  {
    path: PATH_UESR_TICKET_CREATE,
    name: 'Create User Ticket',
  },
  {
    path: PATH_SAVVY_AIRCRAFT,
    name: 'Savvy Aircraft',
  },
  {
    path: PATH_UNASSIGNED_TICKETS,
    name: 'Unassigned Tickets',
  },
  {
    path: PATH_INTERNAL_SERVICE_CENTER.replace(':id', ''),
    name: 'View Service Center',
  },
  {
    path: PATH_SERVICE_CENTER_MANUAL_RATING,
    name: 'Review Service Center',
  },
  {
    path: PATH_SAVVY_ACCOUNT,
    name: 'Savvy Account',
  },
  {
    path: PATH_SAVVY_LOG_BACKFILL,
    name: 'Backfill Logs',
  },
  {
    path: PATH_SAVVY_COI_MANAGEMENT,
    name: 'Manage Coi',
  },
  {
    path: PATH_MANAGER_CONFLICTS,
    name: 'Manage Conflicts',
  },
  {
    path: PATH_SAVVY_COHORT_REPORTS,
    name: 'Cohort Reports',
  },
  {
    path: PATH_CREATE_TECHNICIAN,
    name: 'Create Technician',
  },
  {
    path: PATH_SAVVY_TIMESHEETS_DASHBOARD,
    name: 'Timesheets Dashboard',
  },
  {
    path: PATH_SAVVY_AIRCRAFT_CREATE,
    name: 'Create Aircraft',
  },
  {
    path: PATH_SC_DASHBOARD,
    name: 'Technician Dashboard',
  },
  {
    path: PATH_SC_FLIGHTS,
    name: 'Technician Flights',
  },
];
