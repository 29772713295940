import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Box, Typography, Button, Grid, Link, IconButton } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import SummarizeIcon from '@mui/icons-material/Summarize';
import { IBorescopeImageSet, IBorescopeImageFile, IBorescopeImageFileType } from 'types';
import {
  UPLOAD_STATUS_PROCESSING,
  UPLOAD_STATUS_SUCCESS,
  BORESCOPE_SENT_STATUS,
  UPLOAD_STATUS_ERROR,
  PATH_BETA_PLANS,
  PATH_CREATE_BORESCOPE_REPORT,
  PATH_CREATE_OWN_BORESCOPE_REPORT,
} from 'const';

import {
  QUERY_ME_AIRCRAFT_BORESCOPE_IMAGE_SET_DETAIL,
  QUERY_BORESCOPE_IMAGE_SUBJECTS_LIST,
  QUERY_ELIGIBLE_BORESCOPE,
  QUERY_SHARED_AIRCRAFT_BORESCOPE_IMAGE_SET_DETAIL,
  MUTATION_DELETE_BORESCOPE_IMAGE,
} from 'gql';
import { useLazyQuery, useMutation } from '@apollo/client';

import {
  getS3UrlForBorescopeImage,
  uploadBorescopeImageDirectS3,
  joinOrString,
  ctrlCmdClick,
  getEngineNameFromBorescopeLabel,
} from 'helpers';
import { default as WarningCylindersDialog } from './WarningCylindersDialog';
import { ComponentLoading, OverlayLoading } from 'components';
import { AutoTagUploader, CollapsedFilesByCylinder, NoCylinderFiles, GuideMeDialog } from './UploadComponents';
import { setAlert } from 'state';
import { apolloClient } from 'services';
import { IAircraft } from 'types';

import { isNonCreatePermission } from 'helpers';

interface UploadBorescopeImagesProps {
  borescopeImageSet: IBorescopeImageSet;
  setSlideNum: (value: number) => void;
  cylinderCount: number;
  isLoaded: boolean;
  selectedAircraft: IAircraft;
}

const UploadBorescopeImages: React.FC<UploadBorescopeImagesProps> = (props) => {
  const { borescopeImageSet, setSlideNum, cylinderCount, isLoaded, selectedAircraft } = props;
  const history = useHistory();

  const [fetchBorescopeImages, { data: dataBorescopeImages, loading: loadingBorescopeImages }] = useLazyQuery(
    QUERY_ME_AIRCRAFT_BORESCOPE_IMAGE_SET_DETAIL,
    {
      fetchPolicy: 'cache-and-network',
    },
  );
  const [fetchSharedBorescopeImages, { data: dataSharedBorescopeImages, loading: loadingSharedBorescopeImages }] = useLazyQuery(
    QUERY_SHARED_AIRCRAFT_BORESCOPE_IMAGE_SET_DETAIL,
  );
  const [deleteBorescopeImage, { data: dataDeleteImage, loading: loadingDeleteImage, error: errorDeleteImage }] =
    useMutation(MUTATION_DELETE_BORESCOPE_IMAGE);
  const [fetchEligibility, { data: dataEligibility, loading: loadingEligibility }] = useLazyQuery(QUERY_ELIGIBLE_BORESCOPE);
  const [fetchSubjectsList, { data: dataSubjectsList }] = useLazyQuery(QUERY_BORESCOPE_IMAGE_SUBJECTS_LIST);
  const dispatch = useDispatch();

  const [uploadedFiles, setUploadedFiles] = useState<IBorescopeImageFile[]>([]);
  const [doneUpload, setDoneUpload] = useState(true);
  const [warningCylinders, setWarningCylinders] = useState<number[]>([]);
  const [showWarningCylinders, setShowWarningCylinders] = useState(false);
  const [showNotTaggedFiles, setShowNotTaggedFiles] = useState(false);

  const [eligibleViaPurchase, setEligibleViaPurchase] = useState<string[]>([]);

  const [isUploading, setIsUploading] = useState(false);

  const [images, setImages] = useState<IBorescopeImageFileType[]>([]);

  const [showGuideMe, setShowGuideMe] = useState(false);

  const fetchImagesTimer = async () => {
    const { data } = await apolloClient.query({
      query: !selectedAircraft?.shared ? QUERY_ME_AIRCRAFT_BORESCOPE_IMAGE_SET_DETAIL : QUERY_SHARED_AIRCRAFT_BORESCOPE_IMAGE_SET_DETAIL,
      variables: {
        aircraftId: !selectedAircraft?.shared ? parseInt(selectedAircraft.id) : selectedAircraft.id,
        borescopeImageSetId: borescopeImageSet.id,
        completedOnly: true,
      },
      fetchPolicy: 'network-only',
    });

    if (data) {
      if (selectedAircraft?.shared) {
        setImages([...data.sharedAircraftWithUploadBorescope[0].aircraft.borescopeImageSets[0].images]);
      } else {
        setImages([...data.me.aircraft[0].borescopeImageSets[0].images]);
      }
      const temp = uploadedFiles.filter((file) => {
        if (!selectedAircraft.shared) {
          for (let i = 0; i < data.me.aircraft[0].borescopeImageSets[0].images.length; i++) {
            if (file.borescopeImageId === data.me.aircraft[0].borescopeImageSets[0].images[i].id) {
              return false;
            } else if (file.status === UPLOAD_STATUS_ERROR) {
              return false;
            }
          }
          return true;
        } else {
          for (let i = 0; i < data.sharedAircraftWithUploadBorescope[0].aircraft.borescopeImageSets[0].images.length; i++) {
            if (file.borescopeImageId === data.sharedAircraftWithUploadBorescope[0].aircraft.borescopeImageSets[0].images[i].id) {
              return false;
            } else if (file.status === UPLOAD_STATUS_ERROR) {
              return false;
            }
          }
          return true;
        }
      });

      setUploadedFiles([...temp]);

      const successFiles = temp.filter((file) => file.status === UPLOAD_STATUS_SUCCESS);
      if (successFiles.length) {
        await setTimeout(async () => {
          await fetchImagesTimer();
        }, 1000);
      }
    }
  };

  const handleChangeFiles = async (files: File[]) => {
    setIsUploading(true);
    for (let i = 0; i < files.length; i++) {
      await uploadFile(files[i]);
    }

    await setTimeout(async () => {
      await fetchImagesTimer();
      setIsUploading(false);
    }, 1000);
  };

  const toBase64 = (file: File) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });

  const uploadFile = async (uploadedFile: File) => {
    const uploadData = await getS3UrlForBorescopeImage(uploadedFile.name, borescopeImageSet.id);
    if (!uploadData) {
      dispatch(setAlert('error', 'Unable to create new borescope image'));
      return;
    }
    if (!uploadData.ok && uploadData.error) {
      dispatch(setAlert('error', uploadData.error));
      return;
    }
    const { id: borescopeImageId, cylinder: cylinderNumber, subject } = uploadData.borescopeImage;
    if (!cylinderNumber && !subject) {
      dispatch(
        setAlert(
          'warning',
          "Some of the filenames you uploaded aren't recognizable. Please tag the images shown below with cylinder number and view name.",
          'unrecognizable-alert',
        ),
      );
    }
    const src = await toBase64(uploadedFile);
    const tmp = {
      fileName: uploadedFile.name,
      fileSize: uploadedFile.size,
      status: '',
      id: '',
      borescopeImageSetId: '',
      borescopeImageId: borescopeImageId,
      details: '',
      file: uploadedFile,
      cylinderIndex: cylinderNumber,
      subject: subject || '',
      src: src as string,
    };
    tmp.status = UPLOAD_STATUS_PROCESSING;

    const fileIndexOfArray = uploadedFiles.length;
    uploadedFiles.push(tmp);
    setUploadedFiles([...uploadedFiles]);
    const result = await uploadBorescopeImageDirectS3(uploadedFile, uploadData.url, borescopeImageId);
    if (result.success) {
      uploadedFiles[fileIndexOfArray].status = UPLOAD_STATUS_SUCCESS;
      uploadedFiles[fileIndexOfArray].id = result.key;
    } else {
      uploadedFiles[fileIndexOfArray].status = UPLOAD_STATUS_ERROR;
    }

    setUploadedFiles([...uploadedFiles]);
  };

  const deleteBorescopeImageAction = async (borescopeImageId: string) => {
    await deleteBorescopeImage({
      variables: {
        borescopeImageId,
      },
    });
    if (!selectedAircraft.shared) {
      await fetchBorescopeImages({
        variables: {
          aircraftId: parseInt(selectedAircraft.id),
          borescopeImageSetId: borescopeImageSet.id,
          completedOnly: true,
        },
        fetchPolicy: 'network-only',
      });
    } else {
      await fetchSharedBorescopeImages({
        variables: {
          aircraftId: selectedAircraft.id,
          borescopeImageSetId: borescopeImageSet.id,
          completedOnly: true,
        },
        fetchPolicy: 'network-only',
      });
    }
  };

  const onSubmitAction = () => {
    if (borescopeImageSet.status === 'ANALYSIS_REQUESTED') {
      dispatch(setAlert('warning', "You've requested to analysis this borescope image set already."));
      return;
    } else {
      setSlideNum(2);
    }
  };

  const getWarningList = () => {
    const tmp: number[] = [];
    const noCylinderOrSubjectFiles = images.filter((file) => !file.cylinder || !file.subject);
    if (noCylinderOrSubjectFiles.length) {
      setShowNotTaggedFiles(true);
      return true;
    } else {
      setShowNotTaggedFiles(false);
    }
    for (let i = 1; i < cylinderCount + 1; i++) {
      const isUploadedCylinder = images.find((item: any) => item.cylinder === i);
      if (!isUploadedCylinder) {
        tmp.push(i);
      }
    }
    setWarningCylinders([...tmp]);
    if (tmp.length) {
      return true;
    }

    return false;
  };

  const [showCreateReport, setShowCreateReport] = useState(false);

  useEffect(() => {
    const noCylinderOrSubjectFiles = images.filter((file) => !file.cylinder || !file.subject);
    if (noCylinderOrSubjectFiles.length) {
      setShowCreateReport(false);
      return;
    }
    if (images.length) setShowCreateReport(true);
    else setShowCreateReport(false);
  }, [images]);

  const requestAnalysis = () => {
    const tmp = getWarningList();
    if (tmp) {
      setShowWarningCylinders(true);
    } else {
      if (borescopeImageSet.status === 'ANALYSIS_REQUESTED') {
        dispatch(setAlert('warning', "You've requested to analysis this borescope image set already."));
        return;
      } else {
        setSlideNum(2);
      }
    }
  };

  useEffect(() => {
    if (isLoaded && selectedAircraft && borescopeImageSet.id) {
      if (!selectedAircraft.shared) {
        fetchBorescopeImages({
          variables: {
            aircraftId: parseInt(selectedAircraft.id),
            borescopeImageSetId: borescopeImageSet.id,
            completedOnly: true,
          },
        });
        fetchEligibility({
          variables: {
            id: parseInt(selectedAircraft.id),
          },
          fetchPolicy: 'cache-and-network',
        });
      } else {
        fetchSharedBorescopeImages({
          variables: {
            aircraftId: selectedAircraft.id,
            borescopeImageSetId: borescopeImageSet.id,
            completedOnly: true,
          },
        });
      }
    }
  }, [isLoaded, selectedAircraft, borescopeImageSet]);

  useEffect(() => {
    if (isLoaded) {
      fetchSubjectsList();
    }
  }, [isLoaded]);

  useEffect(() => {
    if (uploadedFiles.length) {
      for (let i = 0; i < uploadedFiles.length; i++) {
        if (uploadedFiles[i].status === UPLOAD_STATUS_PROCESSING) {
          return;
        }
      }
      setDoneUpload(true);
    }
  }, [uploadedFiles]);

  useEffect(() => {
    if (dataEligibility?.me) {
      if (
        dataEligibility.me?.aircraft[0]?.ticketEligibility?.borescopeAnalysis?.eligible &&
        !dataEligibility.me?.aircraft[0]?.ticketEligibility?.borescopeAnalysis?.currentlyEligibleVia?.length
      ) {
        setEligibleViaPurchase(dataEligibility.me?.aircraft[0]?.ticketEligibility?.borescopeAnalysis.eligibleViaPurchase);
      } else {
        setEligibleViaPurchase([]);
      }
    }
  }, [dataEligibility]);

  useEffect(() => {
    if (errorDeleteImage) {
      dispatch(setAlert('error', 'Unable to delete borescope image'));
    } else if (dataDeleteImage) {
      const { ok, error } = dataDeleteImage.deleteBorescopeImage;
      if (ok) {
        dispatch(setAlert('success', 'Deleted borescope image'));
      } else {
        dispatch(setAlert('error', error));
      }
    }
  }, [dataDeleteImage, errorDeleteImage, dispatch]);

  useEffect(() => {
    if (selectedAircraft?.shared) {
      if (dataSharedBorescopeImages && dataSharedBorescopeImages?.sharedAircraftWithUploadBorescope[0]?.aircraft?.borescopeImageSets[0]) {
        setImages([...dataSharedBorescopeImages.sharedAircraftWithUploadBorescope[0].aircraft.borescopeImageSets[0].images]);
      }
    } else {
      if (dataBorescopeImages && dataBorescopeImages?.me?.aircraft[0]?.borescopeImageSets[0]) {
        setImages([...dataBorescopeImages.me.aircraft[0].borescopeImageSets[0].images]);
      }
    }
  }, [dataSharedBorescopeImages, dataBorescopeImages]);

  return (
    <Box>
      <ComponentLoading loading={loadingEligibility}>
        {!!eligibleViaPurchase.length && !selectedAircraft.shared && (
          <Box
            sx={{
              backgroundColor: 'warning.light',
              borderRadius: '5px',
              width: '100%',
              px: 5,
              py: '14px',
            }}
          >
            <Typography
              sx={{
                fontSize: '16px',
                fontWeight: 500,
                lineHeight: '22px',
                color: 'warning.main',
                '& a': {
                  color: 'warning.main',
                  textDecoration: 'underline',
                },
              }}
            >
              To request analysis, {joinOrString(eligibleViaPurchase)} is required.{' '}
              <Link
                href={`${PATH_BETA_PLANS}/${selectedAircraft.id}`}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  ctrlCmdClick(e, `${PATH_BETA_PLANS}/${selectedAircraft.id}`, history);
                }}
                sx={{
                  fontWeight: 700,
                }}
              >
                Choose Your Plan
              </Link>
            </Typography>
          </Box>
        )}
        <Box px={5} pt={3}>
          <Grid container spacing={5}>
            <Grid item md={12} lg={4} xl={3}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <IconButton
                  sx={{
                    '&:hover': {
                      backgroundColor: 'grey.700',
                    },
                    borderRadius: '6px',
                    border: '1px solid',
                    borderColor: 'grey.200',
                    backgroundColor: 'grey.700',
                    p: '5px',
                  }}
                  onClick={() => {
                    setSlideNum(0);
                  }}
                >
                  <ChevronLeftIcon />
                </IconButton>
                <Box ml={3.75}>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: '20px',
                      lineHeight: '28px',
                      color: 'text.secondary',
                    }}
                  >
                    Upload and Tag Images
                  </Typography>
                  <Typography
                    sx={{
                      color: 'grey.600',
                    }}
                  >
                    {borescopeImageSet?.name}
                    {borescopeImageSet?.engine === 'S'
                      ? ''
                      : ` (engine: ${getEngineNameFromBorescopeLabel(borescopeImageSet?.engine || '')})`}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item md={12} lg={8} xl={9}>
              <Box
                sx={{
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    width: '100%',
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setShowGuideMe(true);
                    }}
                    sx={{
                      display: 'none',
                    }}
                  >
                    <PlayArrowRoundedIcon
                      sx={{
                        mr: 0.5,
                      }}
                    />
                    Guide Me
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      display: selectedAircraft.shared ? 'none' : 'block',
                    }}
                    disabled={!doneUpload || Boolean(uploadedFiles.length)}
                    onClick={requestAnalysis}
                  >
                    Request Analysis
                  </Button>
                  {!isNonCreatePermission(selectedAircraft) && showCreateReport && selectedAircraft.shared && (
                    <Button
                      variant="contained"
                      onClick={() => {
                        if (borescopeImageSet?.status === BORESCOPE_SENT_STATUS) {
                          dispatch(
                            setAlert(
                              'warning',
                              'A report for this image set has already been created.  To request additional analysis, please create a new image set.',
                            ),
                          );
                          return;
                        }
                        if (selectedAircraft?.shared) {
                          history.push(`${PATH_CREATE_BORESCOPE_REPORT}/${selectedAircraft.id}/${borescopeImageSet?.id}`);
                        } else {
                          history.push(`${PATH_CREATE_OWN_BORESCOPE_REPORT}/${selectedAircraft.id}/${borescopeImageSet?.id}`);
                        }
                      }}
                    >
                      <SummarizeIcon sx={{ mr: 1 }} />
                      Create Report
                    </Button>
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box
            pt={5}
            sx={{
              position: 'relative',
            }}
          >
            <Grid container spacing={5}>
              <Grid item md={12} lg={4} xl={3}>
                <Box>
                  <AutoTagUploader
                    handleChangeFiles={handleChangeFiles}
                    setDoneUpload={setDoneUpload}
                    borescopeImageSet={borescopeImageSet}
                    subjectList={dataSubjectsList?.borescopeImageSubjectsList || []}
                  />
                </Box>
              </Grid>
              <Grid item md={12} lg={8} xl={9}>
                {(!!uploadedFiles.length || !!images.length) && (
                  <Box
                    sx={{
                      backgroundColor: 'background.default',
                      py: 2,
                      px: 2.5,
                    }}
                  >
                    <Box>
                      {Array.apply(null, Array(cylinderCount + 1)).map((item, key: number) => {
                        if (key === 0) {
                          return (
                            <NoCylinderFiles
                              cylinderCount={cylinderCount}
                              uploadedFiles={images.filter((item: any) => !item.cylinder) || []}
                              uploadingFiles={uploadedFiles.filter((file) => !file.cylinderIndex) || []}
                              deleteBorescopeImage={deleteBorescopeImageAction}
                              isBorder={true}
                              key={key}
                            />
                          );
                        }
                        return (
                          <CollapsedFilesByCylinder
                            uploadedFiles={images.filter((item: any) => item.cylinder === key) || []}
                            uploadingFiles={uploadedFiles.filter((file) => file.cylinderIndex === key) || []}
                            subjectList={dataSubjectsList?.borescopeImageSubjectsList || []}
                            cylinderIndex={key}
                            isBorder={key !== cylinderCount}
                            key={key}
                            deleteBorescopeImage={deleteBorescopeImageAction}
                          />
                        );
                      })}
                    </Box>
                  </Box>
                )}
              </Grid>
            </Grid>
            <OverlayLoading loading={isUploading || loadingBorescopeImages || loadingDeleteImage || loadingSharedBorescopeImages} />
            <WarningCylindersDialog
              open={showWarningCylinders}
              setOpen={setShowWarningCylinders}
              onSubmitAction={onSubmitAction}
              cylinderList={warningCylinders}
              notTagged={showNotTaggedFiles}
            />
            <GuideMeDialog
              open={showGuideMe}
              setOpen={setShowGuideMe}
              uploadedFiles={images || []}
              subjectList={dataSubjectsList?.borescopeImageSubjectsList || []}
              cylinderCount={cylinderCount}
              borescopeImageSet={borescopeImageSet}
              deleteBorescopeImageAction={deleteBorescopeImageAction}
              requestAnalysis={requestAnalysis}
              eligibleViaPurchase={eligibleViaPurchase}
            />
          </Box>
        </Box>
      </ComponentLoading>
    </Box>
  );
};

export default UploadBorescopeImages;
