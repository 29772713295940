import React from 'react';
import { Box, Table, TableBody, TableRow, TableCell, Typography, FormControlLabel, Checkbox as MuiCheckbox } from '@mui/material';
import { IDuplicateFlight } from 'types';

interface DuplicateTableComponentProps {
  flights: IDuplicateFlight[];
  checkAction: (value: string) => void;
  duplicated: boolean;
  saveFlightIds: string[];
}

const DuplicateTableComponent: React.FC<DuplicateTableComponentProps> = (props) => {
  const { flights, checkAction, duplicated, saveFlightIds } = props;

  const changeDuration = (value: number) => {
    const hour = Math.floor(value / 3600);
    const min = Math.floor((value % 3600) / 60);
    const sec = value % 60;

    return `${hour}h ${min}m ${sec}s`;
  };

  return (
    <Box
      sx={{
        maxHeight: '500px',
        overflowY: 'auto',
        pb: 4,
        backgroundColor: duplicated ? 'backgroundColor.default' : 'rgba(245, 246, 250, 0.5)',
      }}
    >
      <Table>
        <TableBody>
          {flights.map((flight: IDuplicateFlight, key: number) => {
            return (
              <React.Fragment key={key}>
                <TableRow>
                  <TableCell
                    colSpan={5}
                    sx={{
                      padding: '2px 30px',
                      backgroundColor: 'warning.light',
                    }}
                  >
                    {duplicated && (
                      <Typography
                        sx={{
                          fontSize: '12px',
                          lineHeight: '16px',
                          color: 'warning.main',
                        }}
                      >
                        <b>Duplicate!</b>
                        {` This flight is duplicated in file ${flight.fileName}`}
                      </Typography>
                    )}
                    {!duplicated && (
                      <Typography
                        sx={{
                          fontSize: '12px',
                          lineHeight: '16px',
                          color: 'warning.main',
                        }}
                      >
                        <b>Short/Empty Flight</b>
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{
                    '& td': {
                      color: 'grey.600',
                      fontSize: '14px',
                      textDecoration: saveFlightIds.indexOf(flight.id) >= 0 ? 'initial' : 'line-through',
                    },
                  }}
                >
                  <TableCell>
                    <FormControlLabel
                      control={<MuiCheckbox color="primary" checked={saveFlightIds.indexOf(flight.id) >= 0} />}
                      label=""
                      labelPlacement="end"
                      onChange={() => {
                        checkAction(flight.id);
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    {flight.date ? flight.date.toString().substring(0, 10) + ' ' + flight.date.toString().substring(11, 16) : ''}
                  </TableCell>
                  <TableCell>{flight.departureAirport}</TableCell>
                  <TableCell>{flight.destinationAirport}</TableCell>
                  <TableCell>{changeDuration(flight.duration)}</TableCell>
                </TableRow>
              </React.Fragment>
            );
          })}
        </TableBody>
      </Table>
    </Box>
  );
};

export default DuplicateTableComponent;
