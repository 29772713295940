import React from 'react';

import { useSelector } from 'react-redux';

import { Box } from '@mui/material';

import { LayoutLoggedOut, LayoutDashboard } from 'components';

const NotFound: React.FC = () => {
  // Get loggedIn from state
  const { loggedIn } = useSelector((state: any) => state.auth);

  // Page content
  const PageContent: React.FC = () => {
    return (
      <Box textAlign="center">
        <h2>🤔 We are unable to find this page. Sorry...</h2>
        <p>
          Try starting <a href="/">over</a>?
        </p>
        {/*
        <p>Try starting <RouterLink to={PATH_HOME}>
          <Link component='span'>over</Link>
        </RouterLink>?</p>*/}
      </Box>
    );
  };

  // Define layout depending on loggedIn or not
  const Layout = loggedIn ? LayoutDashboard : LayoutLoggedOut;

  return (
    <Layout>
      <PageContent />
    </Layout>
  );
};

export default NotFound;
