import React from 'react';
import { Box, Typography, Skeleton } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';

import { sizeWithUnit } from 'helpers';
import { IUploadedFlightFile } from 'types';

interface UploadingFileStatusProps {
  uploadedFile: IUploadedFlightFile;
}

const UploadingFileStatusBar: React.FC<UploadingFileStatusProps> = (props) => {
  const { uploadedFile } = props;

  const getColorByStatus = (status: string) => {
    if (status === 'Success' || status === 'Warning') {
      return 'success.main';
    }

    if (status === 'Processing') {
      return 'primary.main';
    }

    if (status === 'Error') {
      return 'error.dark';
    }
  };

  return (
    <Box
      py={2}
      pl={2.5}
      sx={{
        display: 'flex',
        alignItems: 'center',
        borderTop: '1px solid',
        borderBottom: '1px solid',
        borderColor: 'grey.200',
        justifyContent: 'space-between',
      }}
    >
      <Typography
        sx={{
          fontSize: '16px',
          fontWeight: 700,
          color: 'text.primary',
          lineHeight: '22px',
          width: '35%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
        title={uploadedFile.fileName}
      >
        {uploadedFile.fileName}{' '}
        <Box
          component="span"
          sx={{
            fontSize: '14px',
            fontWeight: 'normal',
            lineHeight: '19px',
            color: 'text.primary',
            opacity: 0.5,
          }}
        >
          {sizeWithUnit(uploadedFile.fileSize)}
        </Box>
      </Typography>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '40%',
        }}
      >
        <Skeleton
          variant="rectangular"
          animation="wave"
          sx={{
            height: '12px',
            width: '220px',
            backgroundColor: getColorByStatus(uploadedFile.status),
            borderRadius: '6px',
            mr: 1,
          }}
        />
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '19px',
            color: getColorByStatus(uploadedFile.status),
          }}
        >
          {uploadedFile.status}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          width: '25%',
          pl: 2,
        }}
      >
        {uploadedFile.status === 'Warning' && (
          <WarningIcon
            sx={{
              color: 'warning.main',
            }}
          />
        )}
        <Typography
          sx={{
            fontSize: '14px',
            lineHeight: '19px',
            color: uploadedFile.status === 'Error' ? 'error.dark' : 'text.primary',
          }}
        >
          {uploadedFile.status === 'Success' ? '' : uploadedFile.details}
        </Typography>
      </Box>
    </Box>
  );
};

export default UploadingFileStatusBar;
