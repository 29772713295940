import React from 'react';
import { Button } from '@mui/material';
import { ButtonLoading } from 'components';

interface IButtonSubmit {
  text: string;
  loading?: boolean;
  disabled?: boolean;
  variant?: 'text' | 'contained' | 'outlined' | undefined;
}

/*
  Form submit button with loading state
  Usage:
  <ButtonSubmit text='Submit' loading={loading} disabled={formError} />
*/
const ButtonSubmit: React.FC<IButtonSubmit> = (props) => {
  const { text, loading, disabled, variant = 'contained' } = props;
  return (
    <Button
      type="submit"
      variant={variant}
      color="primary"
      // disabled={disabled || loading}
      disabled={disabled && disabled}
      fullWidth
    >
      {loading ? <ButtonLoading /> : text}
    </Button>
  );
};

export default ButtonSubmit;
