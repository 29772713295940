import React from 'react';

import { Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import styles from './style.module.scss';

interface IButtonMore {
  open: boolean;
  setOpen: (value: boolean) => void;
}

const ButtonMore: React.FC<IButtonMore> = (props) => {
  const { open, setOpen } = props;
  const toggle = () => {
    setOpen(!open);
  };
  return (
    <Button color="primary" endIcon={open ? <ExpandLessIcon /> : <ExpandMoreIcon />} onClick={toggle} className={styles.buttonMore}>
      {open ? 'Show less' : 'Show more'}
    </Button>
  );
};

export default ButtonMore;
