import React from 'react';

import { useSelector } from 'react-redux';

import { AppBar as MuiAppBar, Toolbar, Grid } from '@mui/material';

import { Logo, SignupMenu } from './components';

import styles from './style.module.scss';

const AppBarLogoOnly: React.FC = () => {
  const { loggedIn } = useSelector((state: any) => {
    return state.auth;
  });

  return (
    <MuiAppBar position="static" color="transparent" className={styles.appBar}>
      <Toolbar className={styles.toolbar}>
        <Grid container alignItems="stretch" className="h-100">
          <Logo />
          {loggedIn && (
            <Grid
              item
              xs
              style={{
                textAlign: 'right',
              }}
            >
              <SignupMenu />
            </Grid>
          )}
        </Grid>
      </Toolbar>
    </MuiAppBar>
  );
};

export default AppBarLogoOnly;
