import React, { Fragment, useState } from 'react';

import { Box, Button, List, ListItem, Hidden, MenuItem, Collapse, Divider } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import { Dropdown, DropdownMenu } from 'components';
import { HELP_LINKS } from 'const';
import { IAppBarLink } from 'types';

const HelpMenu: React.FC = () => {
  const [open, setOpen] = useState(false);

  const toggleMenu = () => {
    setOpen(!open);
  };
  const closeMenu = () => {
    setOpen(false);
  };

  return (
    <Box
      height="100%"
      alignItems="center"
      sx={{
        display: {
          lg: 'block',
          xl: 'inline-flex',
        },
      }}
    >
      <Hidden xlDown>
        <Dropdown onClickAway={closeMenu}>
          <Box display="flex" height="100%">
            <Button
              color="inherit"
              onClick={toggleMenu}
              sx={{
                minWidth: 'inherit',
              }}
            >
              <HelpOutlineIcon />
            </Button>
          </Box>

          <DropdownMenu open={open}>
            <List
              component="div"
              sx={{
                minWidth: '200px',
                pb: 0,
              }}
            >
              {HELP_LINKS.map((item: IAppBarLink, key: number) => {
                return (
                  <Fragment key={key}>
                    {key !== 0 && key % 3 === 0 && <Divider />}
                    <ListItem
                      ContainerComponent="div"
                      button
                      onClick={() => {
                        window.open(item.path, '_blank');
                      }}
                      sx={{
                        py: key === HELP_LINKS.length - 1 ? 1.5 : 1,
                      }}
                    >
                      {item.text}
                    </ListItem>
                  </Fragment>
                );
              })}
            </List>
          </DropdownMenu>
        </Dropdown>
      </Hidden>
      <Hidden xlUp>
        <MenuItem onClick={toggleMenu}>Help</MenuItem>
        {open && (
          <Collapse in={open} timeout="auto">
            <Fragment>
              {HELP_LINKS.map((item: IAppBarLink, key: number) => {
                return (
                  <ListItem
                    key={key}
                    ContainerComponent="div"
                    button
                    onClick={() => {
                      window.open(item.path, '_blank');
                    }}
                  >
                    {item.text}
                  </ListItem>
                );
              })}
            </Fragment>
          </Collapse>
        )}
      </Hidden>
    </Box>
  );
};

export default HelpMenu;
