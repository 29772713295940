import { gql } from '@apollo/client';
import { FRAGMENT_AIRCRAFT_UPGRADE_CONTEXT } from 'gql';

export const MUTATION_PURCHASE_SERVICE = gql`
  mutation PurchaseService($aircraftId: ID!, $stripeToken: String!, $serviceCode: ID!) {
    purchaseService(aircraftId: $aircraftId, stripeToken: $stripeToken, serviceCode: $serviceCode) {
      ok
      subscription {
        id
      }
      error
    }
  }
`;

export const MUTATION_COMPLETE_PURCHASE = gql`
  mutation CompletePurchase($subscriptionId: ID!) {
    completePurchase(subscriptionId: $subscriptionId) {
      ok
      error
    }
  }
`;

export const MUTATION_CHARGE_CONTACT = gql`
  mutation ChargeContact($contactId: ID, $amount: Int, $category: String) {
    chargeContact(contactId: $contactId, amount: $amount, category: $category) {
      ok
      error
    }
  }
`;

export const MUTATION_REFUND_CONTACT = gql`
  mutation RefundContact($amount: Int, $paymentId: ID) {
    refundContact(amount: $amount, paymentId: $paymentId) {
      ok
      error
    }
  }
`;

export const MUTATION_CANCEL_SUBSCRIPTION = gql`
  mutation CancelSubscription($subscriptionId: ID, $reason: String, $note: String) {
    cancelSubscription(subscriptionId: $subscriptionId, reason: $reason, note: $note) {
      ok
      upgradeContext {
        title
        renewals {
          id
          name
          status
          expiration
          showDoNotRenew
          isCanceled
        }
        options {
          title
          titleColor
          price
          credit
          total
          description
          learnMoreLink
          actionLink
          buttonText
        }
      }
      error
    }
  }
`;

export const MUTATION_CREATE_NOTHAPPY_TICKET_FOR_CANCELATION = gql`
  mutation CreateNotHappyTicketForCancelation($subscriptionId: ID, $note: String) {
    createNotHappyTicketForCancelation(subscriptionId: $subscriptionId, note: $note) {
      ok
      subscription {
        id
      }
      error
    }
  }
`;

export const MUTATION_CHANGE_SUBSCRIPTION_END_DATE = gql`
  mutation ChangeSubscriptionEndDate($endDate: DateTime, $subscriptionId: ID) {
    changeSubscriptionEndDate(endDate: $endDate, subscriptionId: $subscriptionId) {
      ok
      subscription {
        id
        mxId
        creationDate
        testMode
        startDate
        endDate
        serviceName
        serviceCode
        terminationCode
        reasonToCancel
        cancelDate
        status
      }
      error
    }
  }
`;

export const MUTATION_CANCEL_SUBSCRIPTION_RENEWAL = gql`
  mutation CancelSubscriptionRenewal(
    $existingTicketId: ID
    $newTicketSubject: String
    $postBody: String
    $reason: String
    $subscriptionId: ID
    $ticketSwitcher: String
  ) {
    cancelSubscriptionRenewal(
      existingTicketId: $existingTicketId
      newTicketSubject: $newTicketSubject
      postBody: $postBody
      reason: $reason
      subscriptionId: $subscriptionId
      ticketSwitcher: $ticketSwitcher
    ) {
      ok
      subscription {
        id
        startDate
        endDate
        serviceName
        serviceCode
        terminationCode
        reasonToCancel
        cancelDate
        status
      }
      error
    }
  }
`;

export const MUTATION_UNCANCEL_SUBSCRIPTION_RENEWAL = gql`
  mutation UncancelSubscriptionRenewal($subscriptionId: ID) {
    uncancelSubscriptionRenewal(subscriptionId: $subscriptionId) {
      ok
      subscription {
        id
        startDate
        endDate
        serviceName
        serviceCode
        terminationCode
        reasonToCancel
        cancelDate
        status
      }
      upgradeContext {
        title
        renewals {
          id
          name
          status
          expiration
          showDoNotRenew
          isCanceled
        }
        options {
          title
          titleColor
          price
          credit
          total
          description
          learnMoreLink
          actionLink
          buttonText
        }
        lessExpensiveOptions {
          title
          titleColor
          description
          price
          credit
          total
          buttonText
          learnMoreLink
          actionLink
        }
        canTransferSubscriptions
      }
      error
    }
  }
`;

export const MUTATION_TERMINATE_SUBSCRIPTION = gql`
  mutation TerminateSubscription(
    $endDate: DateTime
    $existingTicketId: ID
    $newTicketSubject: String
    $postBody: String
    $terminationReason: String
    $subscriptionId: ID
    $ticketSwitcher: String
    $refund: Boolean
  ) {
    terminateSubscription(
      existingTicketId: $existingTicketId
      newTicketSubject: $newTicketSubject
      postBody: $postBody
      terminationReason: $terminationReason
      subscriptionId: $subscriptionId
      ticketSwitcher: $ticketSwitcher
      endDate: $endDate
      refund: $refund
    ) {
      ok
      ticket {
        id
        posts {
          id
          body
          visibility
        }
      }
      error
    }
  }
`;

export const MUTATION_TRANSFER_SUBSCRIPTION_TO_OWNED_AIRCRAFT_FOR_ADMIN = gql`
  mutation TransferSubscriptionsToOwnedAircraftForAdmin($sourceAircraftId: ID, $targetAircraftId: ID) {
    transferSubscriptionsToOwnedAircraft(sourceAircraftId: $sourceAircraftId, targetAircraftId: $targetAircraftId) {
      ok
      error
    }
  }
`;

export const MUTATION_UPGRADE_AIRCRAFT_SUBSCRIPTION = gql`
  ${FRAGMENT_AIRCRAFT_UPGRADE_CONTEXT}
  mutation UpgradeAircraftSubscription($aircraftId: ID, $serviceCode: String) {
    upgradeAircraftSubscription(aircraftId: $aircraftId, serviceCode: $serviceCode) {
      ok
      aircraft {
        id
        subscriptions {
          id
          serviceName
          serviceCode
          status
        }
        ...upgradeContext
      }
      error
    }
  }
`;

export const MUTATION_END_PREBUY = gql`
  ${FRAGMENT_AIRCRAFT_UPGRADE_CONTEXT}
  mutation EndPrebuy($aircraftId: ID, $createCoi: Boolean) {
    endPrebuy(aircraftId: $aircraftId, createCoi: $createCoi) {
      ok
      aircraft {
        id
        subscriptions {
          id
          serviceName
          serviceCode
          status
        }
        prebuy
        ...upgradeContext
      }
      error
    }
  }
`;

export const MUTATION_ADD_SUBSCRIPTION = gql`
  ${FRAGMENT_AIRCRAFT_UPGRADE_CONTEXT}
  mutation AddSubscription($aircraftId: ID, $serviceCode: String) {
    addSubscription(aircraftId: $aircraftId, serviceCode: $serviceCode) {
      ok
      aircraft {
        id
        subscriptions {
          id
          serviceName
          serviceCode
          status
        }
        ...upgradeContext
      }
      error
    }
  }
`;

export const MUTATION_RENEW_SUBSCRIPTION = gql`
  ${FRAGMENT_AIRCRAFT_UPGRADE_CONTEXT}
  mutation RenewSubscription($subscriptionId: ID, $serviceCode: String) {
    renewSubscription(subscriptionId: $subscriptionId, serviceCode: $serviceCode) {
      ok
      aircraft {
        id
        subscriptions {
          id
          serviceName
          serviceCode
          status
        }
        ...upgradeContext
      }
      error
    }
  }
`;
