import { PASSWORD_MIN_LENGTH, PASSWORD_MAX_LENGTH, AIRCRAFT_MODEL_START_YEAR, CURRENT_YEAR } from 'const';

// Form messages
// Alert
export const TIMEOUT_HIDE_INPUT_ERROR = 4000;
export const TIMEOUT_HIDE_ALERT = 10000;

// Common
export const WHITE_SPACE_ERROR = "Please don't use white space";
export const FORM_ERROR = 'Please fix form errors above';
export const FORM_FIELDS_REQUIRED_ERORR = '* Required fields';

// Email
export const EMAIL_EMPTY_ERROR = 'Please enter email';
export const EMAIL_INVALID_ERROR = 'Please enter email in correct format. Sample: email@email.com';

// Name
export const NAME_FIRST_EMPTY_ERROR = 'Please enter first name';
export const NAME_LAST_EMPTY_ERROR = 'Please enter last name';

// Account
export const CREATE_ACCOUNT_SUCCESS_MESSAGE = 'Account created';
export const LOGIN_SUCCESS_MESSAGE = 'Login Success';

// Password
export const PASSWORD_HELPER_TEXT = `From ${PASSWORD_MIN_LENGTH} to ${PASSWORD_MAX_LENGTH} characters`;
export const PASSWORD_MIN_LENGTH_ERROR = `Please enter at least ${PASSWORD_MIN_LENGTH}  characters`;

// Aircraft
export const REGISTRATION_NUMBER_EMPTY_ERROR = 'Please enter your registration number';
export const AIRCRAFT_MODEL_SELECT_MANUFACTURER_ERROR = 'Please enter manufacturer first';
export const AIRCRAFT_MANUFACTURER_EMPTY_ERROR = 'Please enter manufacturer';
export const AIRCRAFT_MODEL_EMPTY_ERROR = 'Please enter model';
export const AIRCRAFT_MODEL_YEAR_ERROR = `Please enter model year between ${AIRCRAFT_MODEL_START_YEAR} and ${CURRENT_YEAR}`;
export const AIRCRAFT_MODEL_YEAR_HELPER_TEXT = `From ${AIRCRAFT_MODEL_START_YEAR} to ${CURRENT_YEAR} year`;

// Aircraft Engine
export const AIRCRAFT_ENGINE_MANUFACTURER_EMPTY_ERROR = 'Please enter engine manufacturer';
export const AIRCRAFT_ENGINE_MODEL_SELECT_MANUFACTURER_ERROR = 'Please enter engine manufacturer first';

// Aircraft Monitor
export const AIRCRAFT_MONITOR_MANUFACTURER_EMPTY_ERROR = 'Please enter monitor manufacturer';
export const AIRCRAFT_MONITOR_MODEL_SELECT_MANUFACTURER_ERROR = 'Please enter monitor manufacturer first';

// Helpers
// Autocomplete
export const ADD_OPTION_CLICKED_NO_VALUE = 'ADD_OPTION_CLICKED_NO_VALUE';
export const ADD_OPTION_CLICKED_TYPED_VALUE = 'ADD_OPTION_CLICKED_TYPED_VALUE';

// Signup for a Service
export const SIGNUP_SERVICE_SUCCESS_MESSAGE = 'Signup success';

// Additional Info
export const PHONE_EMPTY_ERROR = 'Please enter phone';
export const COUNTRY_EMPTY_ERROR = 'Please select country';
export const CITY_EMPTY_ERROR = 'Please enter city';
export const CHANGE_PERSONAL_INFO_SUCCESS_MESSAGE = 'Personal info saved';
export const AIRCRAFT_INFO_SUCCESS_MESSAGE = 'Aircraft info saved';

// Payment
export const PAYMENT_ERROR_MESSAGE = 'Payment error';
export const PAYMENT_SUCCESS_MESSAGE = 'Payment success';
export const PAYMENT_UPDATE_MESSAGE = 'Updated payment';

// Checkbox
export const ACCEPT_AGREEMENT_ERROR_MESSAGE = 'Please accept agreement';

// GraphQL, Server
export const SERVER_ERROR = 'Server error';
export const GRAPHQL_NETWORK_ERROR_MESSAGE = 'Server Error. Please try again or reload page';

export const VERIFY_EMAIL_SUCCESS = 'Email Verification Successful';
