import React from 'react';
import { Box } from '@mui/material';
import { useSmDown } from 'hooks';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel: React.FC<TabPanelProps> = (props) => {
  const { children, value, index, ...other } = props;

  const isSmDown = useSmDown();

  return (
    <div
      role="tabpanel"
      hidden={value !== index && !isSmDown}
      id={`scrollable-prevent-tabpanel-${index}`}
      aria-labelledby={`scrollable-prevent-tab-${index}`}
      {...other}
    >
      <Box>{children}</Box>
    </div>
  );
};

export default TabPanel;
