import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, Box, DialogTitle, DialogContent, TextField, DialogActions, Grid, Button } from '@mui/material';
import { MUTATION_CREATE_MAINTENACE_LOGBOOK } from 'gql';
import { useMutation } from '@apollo/client';
import { setAlert } from 'state';

import { ComponentLoading } from 'components';

interface CreateLogbookDialogProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  aircraftId: string;
}

const CreateLogbookDialog: React.FC<CreateLogbookDialogProps> = (props) => {
  const { open, setOpen, aircraftId } = props;

  const [createLogbook, { data: dataCreateLogbook, error: errorCreateLogbook, loading: loadingCreateLogbook }] =
    useMutation(MUTATION_CREATE_MAINTENACE_LOGBOOK);
  const dispatch = useDispatch();
  const { isSavvy } = useSelector((state: any) => state.auth);

  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async () => {
    if (!name) {
      setNameError('Please input logbook name.');
      return;
    }

    if (!isSavvy) {
      dispatch(setAlert('error', "You don't have permission to create logbook"));
      return;
    }

    await createLogbook({
      variables: {
        aircraftId,
        name,
      },
    });
  };

  useEffect(() => {
    if (errorCreateLogbook) {
      dispatch(setAlert('error', 'Unable to create logbook'));
    } else if (dataCreateLogbook) {
      if (dataCreateLogbook?.createMaintenanceLogbook?.ok) {
        dispatch(setAlert('success', 'Created logbook.'));
        setOpen(false);
      } else {
        dispatch(setAlert('error', dataCreateLogbook?.createMaintenanceLogbook.error || 'Unable to create logbook'));
      }
    }
  }, [dataCreateLogbook, errorCreateLogbook, setOpen, dispatch]);

  useEffect(() => {
    if (open) {
      setName('');
      setNameError('');
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={handleClose}
      PaperProps={{
        sx: {
          maxWidth: '400px',
        },
      }}
    >
      <DialogTitle
        sx={{
          textAlign: 'center',
          fontSize: '24px',
          fontWeight: 700,
        }}
      >
        Create Logbook
      </DialogTitle>
      <DialogContent>
        <ComponentLoading loading={loadingCreateLogbook}>
          <Box py={1}>
            <TextField
              name="logbook"
              label="Logbook Name *"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
                setNameError('');
              }}
              error={!!nameError}
              helperText={nameError}
              FormHelperTextProps={{
                sx: {
                  ml: 0,
                },
              }}
              fullWidth
            />
          </Box>
        </ComponentLoading>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button variant="outlined" onClick={handleClose} disabled={loadingCreateLogbook} fullWidth>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="contained" onClick={onSubmit} disabled={loadingCreateLogbook} fullWidth>
              Submit
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default CreateLogbookDialog;
