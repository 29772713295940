import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Dialog, DialogTitle, DialogContent, DialogActions, Box, TextField, MenuItem, Grid, Button, Typography } from '@mui/material';
import { ICohort, IDropDownOption } from 'types';
import { ComponentLoading, Checkbox } from 'components';

import {
  QUERY_AIRCRAFT_MANUFACTURERS,
  QUERY_ENGINE_MANUFACTURER,
  QUERY_ENGINE_PARAMETERS,
  QUERY_COHORTS_BRIEF,
  MUTATION_UPDATE_COHORT,
} from 'gql';
import { useLazyQuery, useMutation } from '@apollo/client';

import { setAlert } from 'state';

interface EditCohortDialogProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  selectedCohort: ICohort;
}

const EditCohortDialog: React.FC<EditCohortDialogProps> = (props) => {
  const { open, setOpen, selectedCohort } = props;

  const [
    fetchAircraftManufacturers,
    { data: dataAircraftManufacturers, loading: loadingAircraftManufacturers, error: errorAircraftManufacturers },
  ] = useLazyQuery(QUERY_AIRCRAFT_MANUFACTURERS, {
    fetchPolicy: 'cache-and-network',
  });
  const [
    fetchEngineManufacturers,
    { data: dataEngineManufacturers, loading: loadingEngineManufacturers, error: errorEngineManufacturers },
  ] = useLazyQuery(QUERY_ENGINE_MANUFACTURER, {
    fetchPolicy: 'cache-and-network',
  });
  const [fetchEngineParameters, { data: dataEngineParameters, loading: loadingEngineParameters, error: errorEngineParameters }] =
    useLazyQuery(QUERY_ENGINE_PARAMETERS, {
      fetchPolicy: 'cache-and-network',
    });
  const [fetchParentCohorts, { data: dataCohorts, loading: loadingParentCohorts, error: errorParentCohorts }] =
    useLazyQuery(QUERY_COHORTS_BRIEF);

  const [updateCohort, { data: dataCohort, loading: loadingCohort, error: errorCohort }] = useMutation(MUTATION_UPDATE_COHORT);

  const dispatch = useDispatch();

  const [aircraftModelId, setAircraftModelId] = useState('');
  const [engineModelId, setEngineModelId] = useState('');
  const [active, setActive] = useState(false);
  const [engineParametersId, setEngineParametersId] = useState('');
  const [parentCohortId, setParentCohortId] = useState('-1');

  const [aircraftModelList, setAircraftModelList] = useState<IDropDownOption[]>([]);
  const [engineModelList, setEngineModelList] = useState<IDropDownOption[]>([]);

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async () => {
    await updateCohort({
      variables: {
        aircraftModelId,
        engineModelId,
        engineParametersId,
        parentCohortId: parentCohortId === '-1' ? '' : parentCohortId,
        active,
        cohortId: selectedCohort.id,
      },
    });
  };

  useEffect(() => {
    if (open) {
      fetchAircraftManufacturers();
      fetchEngineManufacturers();
      fetchEngineParameters();
      fetchParentCohorts();
    }
  }, [open, fetchAircraftManufacturers, fetchEngineManufacturers, fetchEngineParameters]);

  useEffect(() => {
    if (open) {
      setAircraftModelId(selectedCohort.aircraftModel.id);
      setEngineModelId(selectedCohort.engineModel.id);
      setEngineParametersId(selectedCohort.engineParameters.id);
      setActive(selectedCohort.active);
      setParentCohortId(selectedCohort?.parentCohort?.id || '-1');
    }
  }, [open, selectedCohort]);

  useEffect(() => {
    if (errorAircraftManufacturers) {
      dispatch(setAlert('error', 'Unable to load aircraft models'));
    } else if (dataAircraftManufacturers?.aircraftManufacturer?.length) {
      const tmp: IDropDownOption[] = [];
      dataAircraftManufacturers?.aircraftManufacturer?.map((item: any) => {
        item?.aircraftmodelSet?.map((modelItem: IDropDownOption) => {
          tmp.push({
            id: modelItem.id,
            name: item.name + ': ' + modelItem.name,
          });

          return null;
        });

        return null;
      });

      const matchedModel = tmp.find((item) => item.id === selectedCohort.aircraftModel.id);

      if (!matchedModel && selectedCohort.aircraftModel) {
        tmp.push({
          id: selectedCohort.aircraftModel.id,
          name: selectedCohort.aircraftModel.manufacturer.name + ': ' + selectedCohort.aircraftModel.name,
        });
      }

      setAircraftModelList([...tmp]);
    }
  }, [errorAircraftManufacturers, dispatch, dataAircraftManufacturers, selectedCohort]);

  useEffect(() => {
    if (errorEngineManufacturers) {
      dispatch(setAlert('error', 'Unable to load engine models'));
    } else if (dataEngineManufacturers?.engineManufacturer?.length) {
      const tmp: IDropDownOption[] = [];
      dataEngineManufacturers?.engineManufacturer?.map((item: any) => {
        item?.enginemodelSet?.map((modelItem: IDropDownOption) => {
          tmp.push({
            id: modelItem.id,
            name: item.name + ': ' + modelItem.name,
          });

          return null;
        });
        return null;
      });

      const matchedModel = tmp.find((item) => item.id === selectedCohort.engineModel.id);

      if (!matchedModel && selectedCohort.engineModel) {
        tmp.push({
          id: selectedCohort.engineModel.id,
          name: selectedCohort.engineModel.manufacturer.name + ': ' + selectedCohort.engineModel.name,
        });
      }

      setEngineModelList([...tmp]);
    }
  }, [errorEngineManufacturers, dispatch, dataEngineManufacturers]);

  useEffect(() => {
    if (errorEngineParameters) {
      dispatch(setAlert('error', 'Unable to load engine parameters'));
    }
  }, [errorEngineParameters, dispatch]);

  useEffect(() => {
    if (errorParentCohorts) {
      dispatch(setAlert('error', 'Unable to load parent cohorts'));
    }
  }, [errorParentCohorts, dispatch]);

  useEffect(() => {
    if (errorCohort) {
      dispatch(setAlert('error', 'Unable to update cohort'));
    } else if (dataCohort) {
      if (dataCohort.updateCohort?.ok) {
        dispatch(setAlert('success', 'Updated cohort'));
        setOpen(false);
      } else {
        dispatch(setAlert('error', dataCohort.updateCohort?.error || 'Unable to update cohort'));
      }
    }
  }, [errorCohort, dataCohort, dispatch, setOpen]);

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={handleClose}
      PaperProps={{
        sx: {
          maxWidth: '400px',
        },
      }}
    >
      <DialogTitle
        sx={{
          textAlign: 'center',
          fontSize: '24px',
          fontWeight: 700,
        }}
      >
        Create Cohort
      </DialogTitle>
      <DialogContent>
        <ComponentLoading
          loading={loadingAircraftManufacturers || loadingEngineManufacturers || loadingEngineParameters || loadingParentCohorts}
        >
          <Box py={1}>
            {!!aircraftModelList.length && (
              <TextField
                select
                name="aircraft_model"
                label="Aircraft Model *"
                value={aircraftModelId}
                onChange={(e) => {
                  setAircraftModelId(e.target.value);
                }}
                sx={{
                  mb: 0,
                }}
                fullWidth
              >
                {aircraftModelList?.map((item: IDropDownOption, key: number) => {
                  return (
                    <MenuItem value={item.id} key={key}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </TextField>
            )}
          </Box>
          <Box py={1}>
            {!!engineModelList.length && (
              <TextField
                select
                name="engine_model"
                label="Engine Model *"
                value={engineModelId}
                onChange={(e) => {
                  setEngineModelId(e.target.value);
                }}
                sx={{
                  mb: 0,
                }}
                fullWidth
              >
                {engineModelList?.map((item: IDropDownOption, key: number) => {
                  return (
                    <MenuItem value={item.id} key={key}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </TextField>
            )}
          </Box>
          <Box py={1}>
            {!!dataEngineParameters?.engineParameters?.length && (
              <TextField
                select
                name="engine_parameters"
                label="Engine Parameters *"
                value={engineParametersId}
                onChange={(e) => {
                  setEngineParametersId(e.target.value);
                }}
                FormHelperTextProps={{
                  sx: {
                    ml: 0,
                  },
                }}
                sx={{
                  mb: 0,
                }}
                fullWidth
              >
                {dataEngineParameters?.engineParameters?.map((item: IDropDownOption, key: number) => {
                  return (
                    <MenuItem value={item.id} key={key}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </TextField>
            )}
          </Box>
          <Box py={1}>
            {!!dataCohorts?.cohorts?.length && (
              <TextField
                select
                name="parent_cohort"
                label="Parent Cohort"
                value={parentCohortId}
                onChange={(e) => {
                  setParentCohortId(e.target.value);
                }}
                sx={{
                  mb: 0,
                }}
                fullWidth
              >
                <MenuItem value="-1">No Parent</MenuItem>
                {dataCohorts?.cohorts?.map((item: ICohort, key: number) => {
                  if (item.id === selectedCohort.id) {
                    return null;
                  }
                  return (
                    <MenuItem value={item.id} key={key}>
                      Cohort #{item.id}
                    </MenuItem>
                  );
                })}
              </TextField>
            )}
          </Box>
          <Box py={1}>
            <Checkbox
              value={active}
              checked={active}
              label={
                <Typography
                  sx={{
                    display: 'inline-block',
                  }}
                >
                  Active?
                </Typography>
              }
              onChange={() => {
                setActive(!active);
              }}
              noMargin
            />
          </Box>
        </ComponentLoading>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button variant="outlined" onClick={handleClose} disabled={loadingCohort} fullWidth>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="contained" onClick={onSubmit} disabled={loadingCohort} fullWidth>
              Update
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default EditCohortDialog;
