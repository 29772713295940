export const enginesForBorescope = [
  {
    label: 'S',
    value: 'Single',
  },
  {
    label: 'L',
    value: 'Left',
  },
  {
    label: 'R',
    value: 'Right',
  },
  {
    label: 'F',
    value: 'Front',
  },
  {
    label: 'B',
    value: 'Back',
  },
];
