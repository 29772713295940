import React, { Fragment } from 'react';

import { CircularProgress } from '@mui/material';

/*
  Usage:
  <Button>
    {loading ? <ButtonLoading /> : 'Button text'}
  </Button>
*/
const ButtonLoading: React.FC = () => {
  return (
    <Fragment>
      &nbsp; {/* white space - to prevent button height jumping when there is only loader */}
      <CircularProgress color="inherit" size={20} variant="indeterminate" />
      &nbsp; {/* second white space - to center loader correctly */}
    </Fragment>
  );
};

export default ButtonLoading;
