import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Container, styled } from '@mui/material';
import { FileUploader } from 'react-drag-drop-files';

import { ComponentLoading } from 'components';
import { uploadFileDirectS3 } from 'helpers';

import { MUTATION_PARSE_FLIGHTS_FROM_S3 } from 'gql';
import { useMutation } from '@apollo/client';
import { setAlert } from 'state';
import { FLIGHT_FILE_MAX_SIZE } from 'const';

interface FileUploadProps {
  aircraftId: string;
  registration: string;
}

const classes = {
  dragDropUpload: 'dragDropUpload',
};

const StyledDiv = styled('div')({
  [`& .${classes.dragDropUpload}`]: {
    height: '240px !important',
    maxWidth: '1000px !important',
    border: '2px dashed #BBC0D4 !important',
    borderRadius: '6px !important',
    '& svg': {
      display: 'none',
    },
    '& div': {
      display: 'block',
      textAlign: 'center',
      '& span': {
        fontSize: '20px',
        fontWeight: 400,
        color: 'rgba(75, 84, 112, 0.5)',
        lineHeight: '27px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& span': {
          textDecoration: 'none',
          maxWidth: '19ch',
          display: 'block',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          marginRight: '5px',
        },
      },
    },
  },
});

const FileUpload: React.FC<FileUploadProps> = (props) => {
  const { aircraftId, registration } = props;

  const [parseFlight, { data: dataParseFlight, loading: loadingParseFlight, error: errorParseFlight }] =
    useMutation(MUTATION_PARSE_FLIGHTS_FROM_S3);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleUpload = async (file: File) => {
    setLoading(true);

    const uploadResult = await uploadFileDirectS3(file, false, aircraftId);
    setLoading(false);

    if (uploadResult.success) {
      await parseFlight({
        variables: {
          aircraftId,
          documentPath: uploadResult.key,
          stage: true,
        },
      });
    } else {
      dispatch(setAlert('error', 'Unable to upload flight file'));
    }
  };

  useEffect(() => {
    if (errorParseFlight) {
      dispatch(setAlert('error', 'Unable to parse flight file'));
    } else if (dataParseFlight) {
      if (dataParseFlight.parseFlightsFromS3File?.ok) {
        dispatch(setAlert('success', 'Parsed flight file'));
      } else {
        dispatch(setAlert('error', 'Unable to parse flight file'));
      }
    }
  }, [errorParseFlight, dataParseFlight, dispatch]);

  return (
    <Container fixed maxWidth="xl">
      <ComponentLoading loading={loading || loadingParseFlight}>
        <StyledDiv
          sx={{
            py: 2,
          }}
        >
          <FileUploader
            handleChange={(file: File) => {
              handleUpload(file);
            }}
            name={'fileUploader'}
            label={`Drag and Drop files here; or click to upload to ${registration}`}
            classes={[classes.dragDropUpload]}
            maxSize={FLIGHT_FILE_MAX_SIZE}
            multiple={false}
          />
        </StyledDiv>
      </ComponentLoading>
    </Container>
  );
};

export default FileUpload;
