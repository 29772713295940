import React, { useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { QUERY_DOWNLOAD_REPORT_CARD_URL } from 'gql';
import { IReportContext } from 'types';
import { humanDate, downloadFileInNewTab } from 'helpers';
import { ComponentLoading } from 'components';
import { apolloClient } from 'services';

interface ReportContextProps {
  reportContext: IReportContext;
  reportId: string;
}

const ReportContext: React.FC<ReportContextProps> = (props) => {
  const { reportContext, reportId } = props;
  const [loading, setLoading] = useState(false);

  const downloadCard = async (reportId: string) => {
    if (!reportId) {
      return;
    }

    if (reportId && parseInt(reportId)) {
      setLoading(true);
      const { data } = await apolloClient.query({
        query: QUERY_DOWNLOAD_REPORT_CARD_URL,
        variables: {
          reportId: parseInt(reportId),
        },
        fetchPolicy: 'no-cache',
      });
      setLoading(false);
      if (data?.downloadReportCardUrl) {
        downloadFileInNewTab(data.downloadReportCardUrl);
      }
    }
  };

  if (!reportContext) {
    return null;
  }

  return (
    <ComponentLoading loading={loading}>
      <Box
        sx={{
          width: '1000px',
          border: '1px solid',
          borderColor: 'grey.200',
        }}
      >
        <Box
          px={2}
          py={1}
          sx={{
            backgroundColor: 'grey.800',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box component="img" src={'/images/logo.svg'} />
            <Box>
              <IconButton
                onClick={() => {
                  downloadCard(reportId);
                }}
              >
                <DownloadIcon />
              </IconButton>
            </Box>
          </Box>
        </Box>
        <Box py={4}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '30px',
              lineHeight: '41px',
              textAlign: 'center',
            }}
          >
            {reportContext.aircraft.registration} {reportContext.aircraft.aircraftManufacturer.name}{' '}
            {reportContext.aircraft.aircraftModel.name}
          </Typography>
        </Box>
        <Box
          py={2.5}
          px={3.75}
          sx={{
            backgroundColor: 'grey.800',
          }}
        >
          <Typography
            sx={{
              fontSize: '16px',
            }}
          >
            Includes <b>{reportContext.numberOfFlights} flights</b> between {humanDate(reportContext.startDate)} and{' '}
            {humanDate(reportContext.endDate)}
            {parseInt(reportContext.cohortSizeFlights) > 0 && (
              <span>
                , compared with{' '}
                {parseInt(reportContext.cohortSizeFlights)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}{' '}
                flights by a cohort of {reportContext.cohortSizeAircraft} {reportContext.aircraft.aircraftManufacturer.name}{' '}
                {reportContext.aircraft.aircraftModel.name}.
              </span>
            )}
            {parseInt(reportContext.cohortSizeFlights) === 0 && <span>.</span>}
          </Typography>
        </Box>
        {reportContext.elements.map(
          (
            element: {
              heading: string;
              uri: string;
              comment: string;
              description: string;
            },
            key: number,
          ) => {
            return (
              <Box
                p={3.75}
                key={key}
                sx={{
                  borderBottom: '1px solid',
                  borderColor: 'grey.200',
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 'bold',
                    fontSize: '18px',
                    lineHeight: '25px',
                    color: 'error.dark',
                    textTransform: 'uppercase',
                    mb: 1,
                  }}
                >
                  {element.heading}
                </Typography>
                <Typography
                  sx={{
                    fontSize: '16px',
                    lineHeight: '22px',
                    color: 'text.primary',
                    mb: 1.5,
                  }}
                >
                  {element.description}
                </Typography>
                <Box
                  component={'img'}
                  src={element.uri}
                  sx={{
                    maxWidth: '100%',
                  }}
                ></Box>
                {element.comment.length > 0 && (
                  <Box pt={1.5}>
                    <Typography
                      sx={{
                        fontSize: '16px',
                        lineHeight: '22px',
                        color: 'text.primary',
                        textTransform: 'uppercase',
                        fontWeight: 'bold',
                        mb: 1.5,
                      }}
                    >
                      Savvy Says...
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '16px',
                        lineHeight: '22px',
                        color: 'text.primary',
                      }}
                    >
                      {element.comment}
                    </Typography>
                  </Box>
                )}
              </Box>
            );
          },
        )}
      </Box>
    </ComponentLoading>
  );
};

export default ReportContext;
