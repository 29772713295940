import React from 'react';
import { Typography, Grid, Box } from '@mui/material';
import { LayoutDashboard } from 'components';
import { AircraftCreateForm } from './components';

const AircraftCreate: React.FC = () => {
  return (
    <LayoutDashboard backgroundColor="#FFFFFF">
      <Grid container spacing={2}>
        <Grid item xl={3} lg={2} xs={false}></Grid>
        <Grid item xl={6} lg={8} xs={12}>
          <Box pt={5}>
            <Typography variant="h2" gutterBottom textAlign="center">
              Create Aircraft
            </Typography>
            <AircraftCreateForm />
          </Box>
        </Grid>
        <Grid item xl={3} lg={2} xs={false}></Grid>
      </Grid>
    </LayoutDashboard>
  );
};

export default AircraftCreate;
