import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Typography, Divider, FormControlLabel, Switch } from '@mui/material';

import { IDropDownOption, IFlightData, ISerieSet, IComment, IFlightChartSetting, IAircraftSetting } from 'types';
import { ModeManager } from '../../components/chartComponents';
import { DiffTable, DiffRateTable, GamiTable, AnomalyComponent } from './AnalysisTable';
import { InformationTab } from './';
import { isMag, isGami, isPresetToMode } from 'helpers';
import { X_MODE, TO_MODE, TO_MODE_LEFT, TO_MODE_RIGHT } from 'const';
import { useLazyQuery } from '@apollo/client';
import { QUERY_VIEW_ANONYMIZE } from 'gql';
import { SET_VIEW_ANONYMIZED } from 'state/types';

interface AnalysisTabProps {
  flightData: IFlightData;
  modeManager: ModeManager;
  diffData: any;
  analysisMode: string;
  setAnalysisMode: (value: string) => void;
  isTwin: boolean;
  anomalyTypes?: IDropDownOption[];
  isShare?: boolean;
  chartNum: number;
  setChartNum: (value: number) => void;
  aircraftComments?: IComment[];
  contactComments?: IComment[];
  settings: IFlightChartSetting | undefined;
  setPresetMode?: (value: string) => void;
  presetMode: string;
  aircraftSettings: IAircraftSetting[];
}

const AnalysisTab: React.FC<AnalysisTabProps> = (props) => {
  const {
    flightData,
    modeManager,
    diffData,
    analysisMode,
    setAnalysisMode,
    isTwin,
    anomalyTypes,
    isShare = false,
    chartNum,
    setChartNum,
    aircraftComments,
    contactComments,
    settings,
    setPresetMode,
    presetMode,
    aircraftSettings,
  } = props;
  const { isSavvy, showAnonymized } = useSelector((state: any) => state.auth);
  const [fetchAnonymizePermission, { data: dataPermission }] = useLazyQuery(QUERY_VIEW_ANONYMIZE);
  const dispatch = useDispatch();

  const setShowAnonymized = (showAnonymized: boolean) => {
    dispatch({
      type: SET_VIEW_ANONYMIZED,
      payload: {
        showAnonymized,
      },
    });
  };

  const [rateUnit, setRateUnit] = useState('min');

  const isDiff = (str: string) => {
    if (str === 'diff') {
      return true;
    }

    return false;
  };

  const isDiffRate = (str: string) => {
    if (str === 'diffRate') {
      return true;
    }

    return false;
  };

  const isAnomaly = (str: string) => {
    if (str === 'anomaly') {
      return true;
    }

    return false;
  };

  const isMinMax = (str: string) => {
    if (str === 'minMax') {
      return true;
    }

    return false;
  };

  const isExistSerie = (serieName: string) => {
    if (!flightData) return false;

    const chartData = JSON.parse(flightData.data);

    const seriesWithName = chartData.series_sets.filter((series_set: ISerieSet) => series_set.name === serieName);

    if (seriesWithName?.length) return true;

    return false;
  };

  const chartData = JSON.parse(flightData.data);

  const SERIES_SETS = chartData?.series_sets
    ? chartData.series_sets
        .filter((serie_set: ISerieSet) => {
          const tmp_serie = {
            name: serie_set.name,
            series: [] as string[],
          };

          for (const serie of serie_set.series) {
            if (chartData.series_data[serie]?.length) {
              if (chartData.series_data[serie][0].toString().includes('0x')) continue;
              else {
                tmp_serie.series.push(serie);
              }
            } else {
              tmp_serie.series.push(serie);
            }
          }

          if (!tmp_serie.series.length && serie_set.name !== 'None') return false;
          return tmp_serie;
        })
        .map((serie_set: ISerieSet) => {
          const sorted = [...serie_set.series].sort((item1, item2) => {
            return item1.replaceAll(' ', '') > item2.replaceAll(' ', '') ? 1 : -1;
          });
          return {
            name: serie_set.name,
            series: sorted,
          };
        })
    : [];

  useEffect(() => {
    if (isSavvy) {
      fetchAnonymizePermission();
    }
  }, [isSavvy]);

  return (
    <Box>
      <Box
        py={2.5}
        sx={{
          textAlign: 'center',
        }}
      >
        <Box
          sx={{
            borderRadius: '6px',
            display: 'inline-flex',
            backgroundColor: 'grey.500',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              py: 1.5,
              px: '11px',
              display: 'flex',
              alignItems: 'center',
              backgroundColor: analysisMode === 'diff' ? 'text.primary' : 'grey.500',
              borderTopLeftRadius: '6px',
              borderBottomLeftRadius: '6px',
              cursor: 'pointer',
            }}
            onClick={() => {
              modeManager.toggle_diff();

              if (isDiff(analysisMode)) {
                setAnalysisMode('');
              } else {
                setAnalysisMode('diff');
              }
            }}
            title="Diff"
          >
            <Typography
              sx={{
                fontSize: '15px',
                color: analysisMode === 'diff' ? 'white' : 'grey.900',
                mr: isSavvy ? 0 : 0.7,
                fontWeight: 'bold',
              }}
            >
              Δ
            </Typography>
            {!isSavvy && (
              <Typography
                sx={{
                  fontWeight: 'bold',
                  fontSize: '11px',
                  lineHeight: '15px',
                  color: analysisMode === 'diff' ? 'white' : 'text.primary',
                }}
              >
                Diff
              </Typography>
            )}
          </Box>
          <Divider
            orientation="vertical"
            sx={{
              height: '16px',
              borderColor: 'text.primary',
              opacity: 0.2,
            }}
          />
          <Box
            sx={{
              py: 1.5,
              px: '11px',
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              backgroundColor: analysisMode === 'diffRate' ? 'text.primary' : 'grey.500',
            }}
            onClick={() => {
              modeManager.toggle_rate();

              if (isDiffRate(analysisMode)) {
                setAnalysisMode('');
              } else {
                setAnalysisMode('diffRate');
              }
            }}
            title="Rate of Change"
          >
            <Typography
              sx={{
                fontSize: '15px',
                color: analysisMode === 'diffRate' ? 'white' : 'grey.900',
                mr: isSavvy ? 0 : 0.7,
                fontWeight: 'bold',
              }}
            >
              Δ/t
            </Typography>
            {!isSavvy && (
              <Typography
                sx={{
                  fontWeight: 'bold',
                  fontSize: '11px',
                  lineHeight: '15px',
                  color: analysisMode === 'diffRate' ? 'white' : 'text.primary',
                }}
              >
                Rate of Change
              </Typography>
            )}
          </Box>
          <Divider
            orientation="vertical"
            sx={{
              height: '16px',
              borderColor: 'text.primary',
              opacity: 0.2,
            }}
          />
          <Box
            sx={{
              py: 1.5,
              px: '11px',
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              backgroundColor: isGami(analysisMode) ? 'text.primary' : 'grey.500',
            }}
            onClick={() => {
              if (!isExistSerie('FF') && !isExistSerie('L-FF') && !isExistSerie('R-FF')) return;

              if (!isGami(analysisMode)) {
                if (isTwin) {
                  setAnalysisMode('gami-left');
                  modeManager.toggle_gami('gami-left');
                } else {
                  setAnalysisMode('gami');
                  modeManager.toggle_gami('gami');
                }
              } else {
                setAnalysisMode('');
                modeManager.set_mode_gami_off();
              }
            }}
            title={isExistSerie('FF') || isExistSerie('L-FF') || isExistSerie('R-FF') ? '' : 'No FF series. GAMI mode is disabled.'}
          >
            <Typography
              sx={{
                fontSize: '15px',
                color: isGami(analysisMode) ? 'white' : 'grey.900',
                mr: isSavvy ? 0 : 0.7,
                fontWeight: 'bold',
              }}
            >
              G
            </Typography>
            {!isSavvy && (
              <Typography
                sx={{
                  fontWeight: 'bold',
                  fontSize: '11px',
                  lineHeight: '15px',
                  color: isGami(analysisMode) ? 'white' : 'text.primary',
                }}
              >
                GAMI
              </Typography>
            )}
          </Box>
          <Divider
            orientation="vertical"
            sx={{
              height: '16px',
              borderColor: 'text.primary',
              opacity: 0.2,
            }}
          />
          <Box
            sx={{
              py: 1.5,
              px: '11px',
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              borderTopRightRadius: isSavvy ? '0px' : '6px',
              borderBottomRightRadius: isSavvy ? '0px' : '6px',
              backgroundColor: isMag(analysisMode) ? 'text.primary' : 'grey.500',
            }}
            onClick={() => {
              if (!isMag(analysisMode)) {
                if (isTwin) {
                  setAnalysisMode('mag-left');
                  modeManager.toggle_mag('mag-left');
                } else {
                  setAnalysisMode('mag');
                  modeManager.toggle_mag('mag');
                }
                const chartContainer = document.getElementById('chart-container');
                window.scroll({ top: chartContainer ? chartContainer.offsetTop : 0, behavior: 'smooth' });
              } else {
                setAnalysisMode('');
                modeManager.set_mode_mag_off();
              }
            }}
            title="Mag-Check"
          >
            <Typography
              sx={{
                fontSize: '15px',
                color: isMag(analysisMode) ? 'white' : 'grey.900',
                mr: isSavvy ? 0 : 0.7,
                fontWeight: 'bold',
              }}
            >
              M
            </Typography>
            {!isSavvy && (
              <Typography
                sx={{
                  fontWeight: 'bold',
                  fontSize: '11px',
                  lineHeight: '15px',
                  color: isMag(analysisMode) ? 'white' : 'text.primary',
                }}
              >
                Mag-Check
              </Typography>
            )}
          </Box>
          {anomalyTypes && anomalyTypes.length && (
            <Divider
              orientation="vertical"
              sx={{
                height: '16px',
                borderColor: 'text.primary',
                opacity: 0.2,
              }}
            />
          )}
          {anomalyTypes && anomalyTypes.length !== 0 && (
            <Box
              sx={{
                py: 1.5,
                px: '11px',
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                backgroundColor: isAnomaly(analysisMode) ? 'text.primary' : 'grey.500',
                height: '100%',
              }}
              onClick={() => {
                modeManager.toggle_anomaly();
                if (!isAnomaly(analysisMode)) {
                  setAnalysisMode('anomaly');
                } else {
                  setAnalysisMode('');
                }
              }}
              title="Anomaly"
            >
              <Typography
                sx={{
                  fontSize: '15px',
                  color: isAnomaly(analysisMode) ? 'white' : 'grey.900',
                  mr: isSavvy ? 0 : 0.7,
                  fontWeight: 'bold',
                }}
              >
                A
              </Typography>
            </Box>
          )}
          <Divider
            orientation="vertical"
            sx={{
              height: '16px',
              borderColor: 'text.primary',
              opacity: 0.2,
            }}
          />
          {isSavvy && (
            <Box
              sx={{
                py: 1.5,
                px: '11px',
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                backgroundColor: analysisMode === 'minMax' ? 'text.primary' : 'grey.500',
                borderTopRightRadius: '6px',
                borderBottomRightRadius: '6px',
              }}
              onClick={() => {
                modeManager.toggle_minMax();
                modeManager.drawMinMax();

                if (isMinMax(analysisMode)) {
                  setAnalysisMode('');
                } else {
                  setAnalysisMode('minMax');
                }
              }}
              title="Min/Max"
            >
              <Typography
                sx={{
                  fontSize: '15px',
                  color: isMinMax(analysisMode) ? 'white' : 'grey.900',
                  mr: isSavvy ? 0 : 0.7,
                  fontWeight: 'bold',
                }}
              >
                V
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      {isSavvy && (
        <Box
          pb={1.5}
          sx={{
            textAlign: 'center',
          }}
        >
          <Box
            sx={{
              borderRadius: '6px',
              display: 'inline-flex',
              backgroundColor: 'grey.500',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                py: 1.5,
                px: '11px',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: presetMode === X_MODE ? 'text.primary' : 'grey.500',
                borderTopLeftRadius: '6px',
                borderBottomLeftRadius: '6px',
                cursor: 'pointer',
              }}
              onClick={() => {
                if (setPresetMode) {
                  if (presetMode === X_MODE) {
                    setPresetMode('');
                  } else {
                    setPresetMode(X_MODE);
                  }
                }
              }}
              title="X preset"
            >
              <Typography
                sx={{
                  fontSize: '15px',
                  color: presetMode === X_MODE ? 'white' : 'grey.900',
                  mr: 0,
                  fontWeight: 'bold',
                }}
              >
                X
              </Typography>
            </Box>
            <Divider
              orientation="vertical"
              sx={{
                height: '16px',
                borderColor: 'text.primary',
                opacity: 0.2,
              }}
            />
            <Box
              sx={{
                py: 1.5,
                px: '11px',
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                borderTopRightRadius: '12px',
                borderBottomRightRadius: '12px',
                backgroundColor: isPresetToMode(presetMode) ? 'text.primary' : 'grey.500',
              }}
              onClick={() => {
                if (setPresetMode) {
                  if (isPresetToMode(presetMode)) {
                    setPresetMode('');
                  } else {
                    setPresetMode(isTwin ? TO_MODE_LEFT : TO_MODE);
                  }
                }
              }}
              title="T/O preset"
            >
              <Typography
                sx={{
                  fontSize: '15px',
                  color: isPresetToMode(presetMode) ? 'white' : 'grey.900',
                  mr: 0,
                  fontWeight: 'bold',
                }}
              >
                T/O
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
      {isMag(analysisMode) && isTwin && (
        <Box px={2.5} py={1}>
          <Box
            sx={{
              display: 'inline-flex',
            }}
          >
            <Box
              sx={{
                cursor: 'pointer',
                borderTopLeftRadius: '12px',
                borderBottomLeftRadius: '12px',
                padding: '4px 8px',
                backgroundColor: analysisMode === 'mag-left' ? 'text.primary' : 'grey.500',
              }}
              onClick={() => {
                setAnalysisMode('mag-left');
                modeManager.toggle_mag('mag-left');
              }}
            >
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: '12px',
                  lineHeight: '16px',
                  color: analysisMode === 'mag-left' ? 'white' : 'rgba(75, 84, 112, 0.5)',
                }}
              >
                Mag-Left
              </Typography>
            </Box>
            <Box
              sx={{
                cursor: 'pointer',
                borderTopRightRadius: '12px',
                borderBottomRightRadius: '12px',
                padding: '4px 8px',
                backgroundColor: analysisMode === 'mag-right' ? 'text.primary' : 'grey.500',
              }}
              onClick={() => {
                setAnalysisMode('mag-right');
                modeManager.toggle_mag('mag-right');
              }}
            >
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: '12px',
                  lineHeight: '16px',
                  color: analysisMode === 'mag-right' ? 'white' : 'rgba(75, 84, 112, 0.5)',
                }}
              >
                Mag-Right
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
      {isPresetToMode(presetMode) && isTwin && (
        <Box px={2.5} py={1}>
          <Box
            sx={{
              display: 'inline-flex',
            }}
          >
            <Box
              sx={{
                cursor: 'pointer',
                borderTopLeftRadius: '12px',
                borderBottomLeftRadius: '12px',
                padding: '4px 8px',
                backgroundColor: presetMode === TO_MODE_LEFT ? 'text.primary' : 'grey.500',
              }}
              onClick={() => {
                setPresetMode && setPresetMode(TO_MODE_LEFT);
              }}
            >
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: '12px',
                  lineHeight: '16px',
                  color: presetMode === TO_MODE_LEFT ? 'white' : 'rgba(75, 84, 112, 0.5)',
                }}
              >
                Left
              </Typography>
            </Box>
            <Box
              sx={{
                cursor: 'pointer',
                borderTopRightRadius: '12px',
                borderBottomRightRadius: '12px',
                padding: '4px 8px',
                backgroundColor: presetMode === TO_MODE_RIGHT ? 'text.primary' : 'grey.500',
              }}
              onClick={() => {
                setPresetMode && setPresetMode(TO_MODE_RIGHT);
              }}
            >
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: '12px',
                  lineHeight: '16px',
                  color: presetMode === TO_MODE_RIGHT ? 'white' : 'rgba(75, 84, 112, 0.5)',
                }}
              >
                Right
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
      {isGami(analysisMode) && isTwin && (
        <Box px={2.5} py={1}>
          <Box
            sx={{
              display: 'inline-flex',
            }}
          >
            <Box
              sx={{
                cursor: 'pointer',
                borderTopLeftRadius: '12px',
                borderBottomLeftRadius: '12px',
                padding: '4px 8px',
                backgroundColor: analysisMode === 'gami-left' ? 'text.primary' : 'grey.500',
              }}
              onClick={() => {
                setAnalysisMode('gami-left');
                modeManager.toggle_gami('gami-left');
              }}
            >
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: '12px',
                  lineHeight: '16px',
                  color: analysisMode === 'gami-left' ? 'white' : 'rgba(75, 84, 112, 0.5)',
                }}
              >
                Gami-Left
              </Typography>
            </Box>
            <Box
              sx={{
                cursor: 'pointer',
                borderTopRightRadius: '12px',
                borderBottomRightRadius: '12px',
                padding: '4px 8px',
                backgroundColor: analysisMode === 'gami-right' ? 'text.primary' : 'grey.500',
              }}
              onClick={() => {
                setAnalysisMode('gami-right');
                modeManager.toggle_gami('gami-right');
              }}
            >
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: '12px',
                  lineHeight: '16px',
                  color: analysisMode === 'gami-right' ? 'white' : 'rgba(75, 84, 112, 0.5)',
                }}
              >
                Gami-Right
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
      {diffData.selectedPoint && !isMag(analysisMode) && analysisMode !== '' && (
        <Box
          px={2.5}
          pb={2.5}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {isSavvy && analysisMode !== 'diffRate' && (
              <Typography
                sx={{
                  fontSize: '13px',
                  fontWeight: 600,
                  lineHeight: '18px',
                  color: 'grey.600',
                  mr: '10px',
                }}
              >
                Time
              </Typography>
            )}
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: '18px',
                lineHeight: '25px',
              }}
            >
              {`${diffData.selectedPoint}${diffData.secondaryPoint ? ' - ' + diffData.secondaryPoint : ''}`}
            </Typography>
          </Box>
          {analysisMode === 'diffRate' && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  cursor: 'pointer',
                  borderTopLeftRadius: '12px',
                  borderBottomLeftRadius: '12px',
                  padding: '4px 8px',
                  backgroundColor: rateUnit === 'min' ? 'text.primary' : 'grey.500',
                }}
                onClick={() => {
                  setRateUnit('min');
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: '12px',
                    lineHeight: '16px',
                    color: rateUnit === 'min' ? 'white' : 'rgba(75, 84, 112, 0.5)',
                  }}
                >
                  Δt/min
                </Typography>
              </Box>
              <Box
                sx={{
                  cursor: 'pointer',
                  borderTopRightRadius: '12px',
                  borderBottomRightRadius: '12px',
                  padding: '4px 8px',
                  backgroundColor: rateUnit === 'sec' ? 'text.primary' : 'grey.500',
                }}
                onClick={() => {
                  setRateUnit('sec');
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: '12px',
                    lineHeight: '16px',
                    color: rateUnit === 'sec' ? 'white' : 'rgba(75, 84, 112, 0.5)',
                  }}
                >
                  Δt/sec
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      )}
      {analysisMode === 'diff' && <DiffTable diffData={diffData} />}
      {analysisMode === 'diffRate' && <DiffRateTable diffData={diffData} rateUnit={rateUnit} />}
      {isGami(analysisMode) && <GamiTable diffData={diffData} />}
      {isMag(analysisMode) && (
        <Box py={1} px={2}>
          <Typography
            sx={{
              fontSize: '14px',
              textAlign: 'center',
            }}
          >
            Magneto Mode is active. Top chart: odd cylinders, bottom chart even cylinders.
          </Typography>
        </Box>
      )}
      {(isAnomaly(analysisMode) || isDiff(analysisMode) || isDiffRate(analysisMode) || isGami(analysisMode)) &&
        !diffData.selectedPoint &&
        !diffData.secondaryPoint && (
          <Box py={1} px={2}>
            <Typography
              sx={{
                fontSize: '14px',
                textAlign: 'center',
              }}
            >
              Select start and end points.
            </Typography>
          </Box>
        )}
      {isAnomaly(analysisMode) && (
        <AnomalyComponent diffData={diffData} flightId={flightData.id} anomalyTypes={anomalyTypes} seriesList={SERIES_SETS} />
      )}
      {isSavvy && !showAnonymized && (
        <InformationTab
          flightData={flightData}
          chartNum={chartNum}
          setChartNum={setChartNum}
          isShare={isShare}
          aircraftComments={aircraftComments}
          contactComments={contactComments}
          settings={settings}
          underAnalysis={false}
          aircraftSettings={aircraftSettings}
        />
      )}
      {dataPermission?.savvy?.permissions?.flight?.viewAnonymizedFlights && (
        <FormControlLabel
          control={
            <Switch
              checked={showAnonymized}
              onChange={() => {
                setShowAnonymized(!showAnonymized);
              }}
              name="checkedB"
              color="primary"
            />
          }
          sx={{
            ml: 2,
          }}
          label="Anonymize"
        />
      )}
    </Box>
  );
};

export default AnalysisTab;
