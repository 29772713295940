import React, { useState } from 'react';
import { Box, Collapse, IconButton } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { ISavvyTicket, ICannedResponse, IAssignee } from 'types';

import TicketInfoBox from './TicketInfoBox';

interface TicketInfoProps {
  ticket: ISavvyTicket | undefined;
  closeTicket?: (id: number, close: boolean) => void;
  cannedResponses: ICannedResponse[];
  setCannedResponseText: (value: string) => void;
  allowedAssignees: IAssignee[];
  canResetUnhappy?: boolean;
}

const TicketInfo: React.FC<TicketInfoProps> = (props) => {
  const { ticket = undefined, closeTicket, cannedResponses, setCannedResponseText, allowedAssignees, canResetUnhappy = false } = props;

  const [leftSlideOn, setLeftSlideOn] = useState(true);

  if (!ticket) return null;

  return (
    <Box
      sx={{
        background: 'background.default',
        position: 'relative',
        minHeight: {
          md: 'inherit',
          lg: 'calc(100vh - 75px)',
        },
        borderLeft: '1px solid',
        borderLeftColor: 'grey.200',
        '@media (min-width: 1350px)': {
          maxWidth: '400px',
        },
      }}
    >
      <Collapse
        in={leftSlideOn}
        orientation="horizontal"
        sx={{
          minWidth: '400px',
        }}
      >
        <TicketInfoBox
          ticket={ticket}
          closeTicket={closeTicket}
          cannedResponses={cannedResponses}
          setCannedResponseText={setCannedResponseText}
          allowedAssignees={allowedAssignees}
          canResetUnhappy={canResetUnhappy}
        />
      </Collapse>
      <IconButton
        sx={{
          position: 'absolute',
          left: 0,
          top: '50%',
          transform: 'translate(-50%, -50%)',
          '&:hover': {
            backgroundColor: 'background.default',
          },
          borderRadius: '6px',
          boxShadow: '0px 1px 1px rgba(19, 30, 64, 0.05)',
          border: '1px solid',
          borderColor: 'grey.200',
          backgroundColor: 'background.default',
          zIndex: 999,
        }}
        onClick={() => {
          setLeftSlideOn(!leftSlideOn);
        }}
      >
        {leftSlideOn ? <ChevronRightIcon /> : <ChevronLeftIcon />}
      </IconButton>
    </Box>
  );
};

export default TicketInfo;
