import React from 'react';
import { Dialog, Box, Typography, DialogContent, Button } from '@mui/material';
import { IBorescopeImageFileType } from 'types';
import { convertTagNameToLabel } from 'helpers';

interface WarningDialogProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  warningList: IBorescopeImageFileType[];
}

const WarningDialog: React.FC<WarningDialogProps> = (props) => {
  const { open, setOpen, warningList } = props;

  const getText = () => {
    const text = warningList.map((item) => `[Cylinder ${item.cylinder}, ${convertTagNameToLabel(item.subject)}]`).join(', ');
    return text;
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={handleClose}
      PaperProps={{
        sx: {
          maxWidth: '450px',
        },
      }}
    >
      <DialogContent>
        <Box>
          <Typography
            variant="body1"
            align="center"
            sx={{
              fontSize: '18px',
              lineHeight: '24px',
              mb: 4,
            }}
          >
            You didn't select statuses for next image(s) {getText()}
          </Typography>
          <Box
            sx={{
              textAlign: 'center',
            }}
          >
            <Button
              variant="outlined"
              sx={{
                width: '100px',
              }}
              color="warning"
              onClick={handleClose}
            >
              Go back
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default WarningDialog;
