import { useEffect, useState } from 'react';

import { IOptionIdName } from 'types';

interface IUseInputWithSuggestions {
  input: string; // Custom input typed value to add if no suggestions found
  initialId?: string;
  initialName?: string;
}

// Input with suggestions (fuzzy) in format id/name to send to server
const useInputWithSuggestions = (props: IUseInputWithSuggestions) => {
  const { input, initialId, initialName } = props;

  // Separated states for id and name to change values from both input type and suggestion click
  const [id, setId] = useState(typeof initialId !== 'undefined' ? initialId : '');
  const [name, setName] = useState(typeof initialName !== 'undefined' ? initialName : '');

  // Show/hide suggestions state
  const [showFuzzy, setShowFuzzy] = useState(true);

  // On Input Change
  useEffect(() => {
    setId(''); // Reset id. Because we enter new custom value and it has no id yet
    setName(input); // Set value from input (typed custom value)
    setShowFuzzy(true); // Show suggestions
  }, [input]);

  // On Suggestion Click
  const handleSuggestionClick = (value: IOptionIdName) => {
    setId(value.id); // Set id from clicked suggestion
    setName(value.name); // Set name from clicked suggestion
    setShowFuzzy(false); // Hide suggestions
  };

  // Return values to use in component
  return {
    id,
    name,
    setId,
    setName,
    handleSuggestionClick,
    showFuzzy,
  };
};

export default useInputWithSuggestions;
