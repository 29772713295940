import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Grid, Typography, Link } from '@mui/material';

import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { MUTATION_SEND_ONE_TIME_LOGIN_LINK } from 'gql';
import { useMutation } from '@apollo/client';

import { LayoutLoggedOut, Subsection, ButtonSubmit, FormGroupInput } from 'components';

import { PATH_LOGIN } from 'const';

import { setAlert } from 'state';

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .min(3, 'Too short: 3-100 characters required')
    .max(100, 'Too long: 3-100 characters required')
    .required('Required'),
});

interface LoginFormValues {
  email: string;
}

const LoginWithEmail: React.FC = () => {
  const [sendOneTimeLoginLink, { data: dataOneTimeLoginLink, error: errorOneTimeLoginLink, loading }] = useMutation(
    MUTATION_SEND_ONE_TIME_LOGIN_LINK,
    {
      errorPolicy: 'all',
    },
  );

  const dispatch = useDispatch();

  const [sentEmail, setSentEmail] = useState(false);

  const [emailAddress, setEmailAddress] = useState('');

  const onSubmit = async (value: LoginFormValues) => {
    if (!value.email) return;

    await sendOneTimeLoginLink({
      variables: {
        email: value.email,
      },
    });

    setEmailAddress(value.email);
  };

  useEffect(() => {
    if (errorOneTimeLoginLink) {
      dispatch(setAlert('error', 'Unable to send login link to your email.'));
    } else if (dataOneTimeLoginLink) {
      if (dataOneTimeLoginLink?.sendOneTimeLoginLink?.ok) {
        setSentEmail(true);
      } else {
        dispatch(setAlert('error', 'Unable to send login link to your email.'));
      }
    }
  }, [errorOneTimeLoginLink, dataOneTimeLoginLink, dispatch]);

  return (
    <LayoutLoggedOut>
      <Grid container spacing={4} justifyContent="center">
        {!sentEmail && (
          <Grid item xs={12} sm={10} md={6} lg={5}>
            <Subsection>
              <Typography variant="h1" align="center" gutterBottom>
                Login With Email
              </Typography>
            </Subsection>
            <Formik
              initialValues={{
                email: '',
              }}
              onSubmit={onSubmit}
              validationSchema={LoginSchema}
            >
              {({ isSubmitting, handleChange, handleBlur, touched, errors }) => (
                <Form>
                  <Subsection>
                    <FormGroupInput
                      name="email"
                      label="Email *"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      maxLength={100}
                      disabled={isSubmitting}
                    />
                    <ButtonSubmit text="Login" loading={isSubmitting || loading} disabled={isSubmitting || loading} />
                  </Subsection>
                  <Subsection>
                    <Typography paragraph align="center">
                      <RouterLink to={PATH_LOGIN}>
                        {' '}
                        <Link component="span">Login with Password</Link>
                      </RouterLink>
                    </Typography>
                  </Subsection>
                </Form>
              )}
            </Formik>
          </Grid>
        )}
        {sentEmail && (
          <Grid item xs={12} sm={10} md={6} lg={5}>
            <Subsection>
              <Typography variant="h1" align="center" gutterBottom>
                Check your email
              </Typography>
            </Subsection>
            <Subsection>
              <Typography variant="h5" align="center">
                We've sent you a temporary login link at {emailAddress}
              </Typography>
            </Subsection>
          </Grid>
        )}
      </Grid>
    </LayoutLoggedOut>
  );
};

export default LoginWithEmail;
