import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, CircularProgress, TextField, Autocomplete } from '@mui/material';

import { useLazyQuery, useMutation } from '@apollo/client';
import { QUERY_SAVVY_ALLOWED_WATCHERS, MUTATION_ADD_WATCHER_TO_TICKET } from 'gql';
import { setAlert } from 'state';

interface AddWatcherPopupDialogProps {
  ticketId: string;
}

interface IContact {
  id: string;
  firstName: string;
  lastName: string;
  serviceCenter: {
    id: string;
    company: string;
    state: string;
    country: string;
  } | null;
}

const AddWatcherPopupDialog: React.FC<AddWatcherPopupDialogProps> = (props) => {
  const { ticketId } = props;

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const [searchText, setSearchText] = useState('');
  const [autocompleteValue, setAutocompleteValue] = useState<IContact | undefined>(undefined);

  const [availableContact, setAvailableContacts] = useState<IContact[]>([]);

  const [fetchAllowedContacts, { data: dataAllowedContacts, loading: loadingAllowedContacts, error: errorAllowedContacts }] = useLazyQuery(
    QUERY_SAVVY_ALLOWED_WATCHERS,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const [addWathcerMutation, { data: dataAddWatcherMutation, error: errorAddWatcherMutation }] =
    useMutation(MUTATION_ADD_WATCHER_TO_TICKET);

  const [fullBox, setFullBox] = useState(false);

  useEffect(() => {
    if (!searchText || searchText?.length < 2) {
      setAvailableContacts([]);
      setLoading(false);
      return;
    }

    setLoading(true);
    const timeout: NodeJS.Timeout = setTimeout(() => {
      fetchAllowedContacts({
        variables: {
          queryFilter: searchText,
        },
      });
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [searchText, fetchAllowedContacts]);

  useEffect(() => {
    if (errorAllowedContacts) {
      setAvailableContacts([]);
      setLoading(false);
    } else if (dataAllowedContacts) {
      setLoading(false);

      if (dataAllowedContacts.savvy?.allowedWatchers?.length) {
        setAvailableContacts([...dataAllowedContacts.savvy.allowedWatchers]);
      } else {
        setAvailableContacts([]);
      }
    }
  }, [dataAllowedContacts, errorAllowedContacts, loadingAllowedContacts]);

  const addWatcher = async (contactId: string, ticketId: string) => {
    setLoading(true);
    await addWathcerMutation({
      variables: {
        contactId,
        ticketId,
      },
    });
    setSearchText('');

    setLoading(false);
  };

  useEffect(() => {
    if (errorAddWatcherMutation) {
      dispatch(setAlert('error', 'Failed to add watcher'));
    } else if (dataAddWatcherMutation) {
      if (dataAddWatcherMutation.addWatcherToTicket?.ok) {
        dispatch(setAlert('success', 'Added new watcher'));
        setAutocompleteValue(undefined);
        setAvailableContacts([]);
      } else {
        dispatch(setAlert('error', dataAddWatcherMutation.addWatcherToTicket?.error || 'Failed to add watcher'));
      }
    }
  }, [dataAddWatcherMutation, errorAddWatcherMutation, dispatch]);

  return (
    <Box
      sx={{
        display: 'flex',
        pb: 1,
        width: '100%',
      }}
    >
      <Box
        sx={{
          width: fullBox ? 0 : '40%',
        }}
      ></Box>
      <Box
        sx={{
          width: fullBox ? '100%' : '60%',
        }}
      >
        <Box py={2}>
          <Autocomplete
            options={availableContact}
            getOptionLabel={(option) => (option?.id ? `${option.firstName} ${option.lastName}` : '')}
            value={autocompleteValue ? autocompleteValue : null}
            onChange={(event: any, value: any) => {
              if (value && value.id) {
                setAutocompleteValue(value);
                addWatcher(value.id, ticketId);
              }
            }}
            loading={loading}
            filterOptions={() => {
              return availableContact;
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Add watcher"
                placeholder="Type watcher's name"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
                fullWidth
                sx={{
                  mb: 0,
                }}
              />
            )}
            renderOption={(props: any, option: IContact) => {
              return option?.id ? (
                <li {...props} key={option.id}>
                  {`${option.firstName} ${option.lastName}`}
                  {option.serviceCenter
                    ? ` (${option.serviceCenter.company}${option.serviceCenter.state || option.serviceCenter.country ? ' - ' : ''}${
                        option.serviceCenter.state
                          ? option.serviceCenter.state
                          : option.serviceCenter.country
                          ? option.serviceCenter.country
                          : ''
                      })`
                    : ''}
                </li>
              ) : null;
            }}
            onOpen={() => {
              setFullBox(true);
            }}
            onClose={() => {
              setFullBox(false);
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default AddWatcherPopupDialog;
