import { setAlert } from 'state';

import { IServerErrors, IDispatch } from 'types';

import { IInputSetError } from 'types';

import { SERVER_ERROR } from 'const';

// GraphQL Errors and GraphQL Network Errors are handled by apolloClient.ts

// Handle GraphQL Send Error. Means GraphQL couldn't execute query, mutation etc. Something like no server response
export const handleSendError = () => (dispatch: IDispatch) => {
  // Show only one alert for server error
  dispatch(setAlert('error', SERVER_ERROR, 'ALERT_SERVER_ERROR_ID'));
};

/*
  Handle Server Errors. When GraphQL Response Success and includes errors array. Format:
  errors: [
    {
      field: 'email',
      messages: ['Email error', 'Some other email error']
    },
  ]
*/
export const handleInputServerErrors = (serverErrors: IServerErrors, inputName: string, setError: IInputSetError) => {
  const inputErrors: string[] = [];
  // Select error object by input name match field and assign error messages to input
  serverErrors.forEach((error) => error.field === inputName && inputErrors.push(...error.messages));

  // Set errors
  inputErrors.length > 0 && setError(inputErrors);
};
