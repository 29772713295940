import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Typography, Tooltip } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import { IAircraftEligibility, IAnalysisPack } from 'types';
import { TicketInfoIcon } from 'customIcons';
import { PATH_TICKETS_CREATE } from 'const';
import { makeEligibilityLabel, changeLabelToTicketType, isFreeEligibility, hasAnalysisPack } from 'helpers';

interface TicketCategoryProps {
  icon: React.ReactNode;
  title: string;
  mappingArr: {
    field: string;
    label: string;
    urlType: string;
  }[];
  aircraft: IAircraftEligibility;
  analysisPacks?: IAnalysisPack[];
}

const TicketCategory: React.FC<TicketCategoryProps> = (props) => {
  const { icon, title, mappingArr, aircraft, analysisPacks = [] } = props;

  const { isTechnician } = useSelector((state: any) => state.auth);

  const CategoryIcon: React.FC<{ icon: React.ReactNode }> = (categoryIconProps) => {
    const { icon } = categoryIconProps;

    return (
      <Box
        sx={{
          width: '100px',
          height: '100px',
          backgroundColor: 'grey.800',
          borderRadius: '100%',
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {icon}
      </Box>
    );
  };

  const changeLabelTypes = (value: string) => {
    return value as
      | 'analysis'
      | 'service'
      | 'serviceAnnual'
      | 'breakdown'
      | 'techSupport'
      | 'accountSupport'
      | 'prebuy'
      | 'preliminaryLogbookReview';
  };

  if (
    isTechnician &&
    !hasAnalysisPack(analysisPacks) &&
    !isFreeEligibility('analysis', aircraft?.ticketEligibility[changeLabelToTicketType('analysis')])
  ) {
    return null;
  }

  return (
    <Box
      pt={2.5}
      mx={2}
      sx={{
        border: '1px solid #E8E9EF',
        borderRadius: '6px',
        width: {
          md: 'calc(50% - 15px)',
          xs: '100%',
        },
        mb: {
          md: 0,
          xs: 1.5,
        },
        textAlign: 'center',
      }}
    >
      <CategoryIcon icon={icon} />
      <Box py={1.25}>
        <Typography
          variant="h2"
          sx={{
            fontWeight: 700,
            fontSize: '20px',
            lineHeight: '27px',
            color: 'text.secondary',
          }}
        >
          {title}
        </Typography>
      </Box>
      <Box py={1.5} pl={3.5}>
        {mappingArr.map((item: any, key: number) => {
          if (!aircraft.ticketEligibility[changeLabelTypes(item.field)]) return null;

          if (isTechnician && item.field !== 'analysis') return null;

          return (
            <Box key={key} mb={1.5}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Tooltip title={item.tooltip ? item.tooltip : ''} placement="top" arrow>
                  <Box
                    sx={{
                      cursor: 'pointer',
                      mr: 1,
                      display: 'inline-flex',
                    }}
                  >
                    <TicketInfoIcon
                      sx={{
                        fill: 'none',
                      }}
                    />
                  </Box>
                </Tooltip>
                <NavLink to={`${PATH_TICKETS_CREATE}/${item.urlType}/${aircraft.id}`}>
                  <Typography
                    sx={{
                      color: 'primary.main',
                      fontWeight: 600,
                      fontSize: '14px',
                      lineHeight: '19px',
                      textAlign: 'left',
                      cursor: 'pointer',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {item.label}
                    <Box
                      component="span"
                      sx={{
                        fontSize: '13px',
                        fontWeight: 500,
                        color: isFreeEligibility(item.field, aircraft?.ticketEligibility[changeLabelToTicketType(item.field)])
                          ? 'grey.400'
                          : '#F93728',
                        whiteSpace: 'break-spaces',
                        marginLeft: !isFreeEligibility(item.field, aircraft?.ticketEligibility[changeLabelToTicketType(item.field)])
                          ? '4px'
                          : '8px',
                      }}
                    >
                      {!isFreeEligibility(item.field, aircraft?.ticketEligibility[changeLabelToTicketType(item.field)])
                        ? ''
                        : makeEligibilityLabel(item.field, aircraft?.ticketEligibility[changeLabelToTicketType(item.field)])}
                    </Box>
                  </Typography>
                </NavLink>
                {!isFreeEligibility(item.field, aircraft?.ticketEligibility[changeLabelToTicketType(item.field)]) &&
                  (item.field !== 'analysis' || !hasAnalysisPack(analysisPacks)) && (
                    <LockIcon
                      sx={{
                        width: '18px',
                        height: '18px',
                        color: 'grey.600',
                      }}
                    />
                  )}
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default TicketCategory;
