import { useState, useEffect } from 'react';

import { useLazyQuery } from '@apollo/client';
import { QUERY_FUZZY_AIRCRAFT_ENGINE_MONITOR_MANUFACTURERS } from 'gql';

import { IOptionIdName } from 'types';

import { isEmpty } from 'helpers';

interface IUseFuzzyEngineMonitorManufacturers {
  engineMonitorManufacturer: IOptionIdName;
  engineMonitorManufacturerInput: string;
}

// Get Aircraft Monitor Manufacturer Suggestions (fuzzy). When type in input
const useFuzzyEngineMonitorManufacturers = (props: IUseFuzzyEngineMonitorManufacturers) => {
  const { engineMonitorManufacturer, engineMonitorManufacturerInput } = props;

  // Suggestions Initial State
  const [fuzzy, setFuzzy] = useState<IOptionIdName[]>([]);

  // GraphQL Definition
  const [refetch, { data }] = useLazyQuery(QUERY_FUZZY_AIRCRAFT_ENGINE_MONITOR_MANUFACTURERS, { fetchPolicy: 'no-cache' });

  // Response
  useEffect(() => {
    // Success
    setFuzzy(data?.fuzzyEnginemonitorManufacturers ? data.fuzzyEnginemonitorManufacturers : []); // Set suggestions array - id/name
  }, [data]);

  // Refetch Suggestions. When input changed
  useEffect(() => {
    if (isEmpty(engineMonitorManufacturerInput) || engineMonitorManufacturerInput === '-- Add your own --') {
      return setFuzzy([]); // Clean suggestions and stop (return) (do NOT refetch)
    }
    refetch({ variables: { search: engineMonitorManufacturerInput } });
  }, [engineMonitorManufacturer, engineMonitorManufacturerInput, refetch]);

  return fuzzy;
};

export default useFuzzyEngineMonitorManufacturers;
