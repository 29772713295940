import React, { useState, useEffect } from 'react';
import { Dialog, Box, Typography, DialogContent, DialogTitle, Button } from '@mui/material';
import { ComponentLoading } from 'components';
import { QUERY_DOWNLOAD_POST_ATTACHMENTS_S3_URL } from 'gql';
import { useLazyQuery } from '@apollo/client';
import { NOT_READY_ARCHIVE_FOR_AIRCRAFT } from 'const';
import { useTimer } from 'hooks';
import { downloadFileInNewTab } from 'helpers';

interface PostArchiveDialogProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  workflowId: string;
}

const FETCH_INTERVAL = 3;

const PostArchiveDialog: React.FC<PostArchiveDialogProps> = (props) => {
  const { open, setOpen, workflowId } = props;
  const [loading, setLoading] = useState(false);

  const [fetchEdfArchiveUrl, { data: dataArchiveUrl }] = useLazyQuery(QUERY_DOWNLOAD_POST_ATTACHMENTS_S3_URL, {
    fetchPolicy: 'no-cache',
  });
  const [readyDownload, setReadyDownload] = useState(false);

  const timerAction = () => {
    if (!workflowId) {
      return;
    }
    fetchEdfArchiveUrl({
      variables: {
        workflowId,
      },
    });
  };

  const {
    create: createTimer,
    created: createdTimer,
    clear: clearTimer,
  } = useTimer({
    timeoutValue: FETCH_INTERVAL,
    timerAction,
  });

  useEffect(() => {
    if (open && workflowId) {
      setReadyDownload(false);
      setLoading(true);
      if (!createdTimer) {
        createTimer();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, workflowId]);

  useEffect(() => {
    if (dataArchiveUrl && dataArchiveUrl.downloadPostAttachmentsS3Url) {
      if (dataArchiveUrl.downloadPostAttachmentsS3Url === NOT_READY_ARCHIVE_FOR_AIRCRAFT) {
        return;
      } else {
        clearTimer();
        setLoading(false);
        setReadyDownload(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataArchiveUrl]);

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={() => {
        return;
      }}
      disableEscapeKeyDown
      onBackdropClick={() => {
        return;
      }}
    >
      <DialogTitle
        sx={{
          textAlign: 'center',
          fontSize: '24px',
          fontWeight: 700,
        }}
      >
        {readyDownload ? 'Download' : 'Compressing'} Attachments
      </DialogTitle>
      <DialogContent>
        <Box>
          {!readyDownload && (
            <Typography
              align="center"
              sx={{
                color: 'warning.main',
              }}
            >
              This may take a bit...
            </Typography>
          )}
          {loading && (
            <ComponentLoading loading={loading}>
              <Box py={2}></Box>
            </ComponentLoading>
          )}
          {readyDownload && (
            <Box
              sx={{
                textAlign: 'center',
              }}
            >
              <Button
                variant="contained"
                onClick={() => {
                  downloadFileInNewTab(dataArchiveUrl.downloadPostAttachmentsS3Url);
                  setOpen(false);
                }}
              >
                Download Attachments
              </Button>
            </Box>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default PostArchiveDialog;
