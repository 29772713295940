import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography, IconButton, TextField, Button, Link } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { InfoLine } from './components';
import { getDuration } from 'helpers';

import { ComponentLoading } from 'components';
import dayjs, { Dayjs } from 'dayjs';

import { useMutation } from '@apollo/client';
import {
  MUTATION_UPDATE_FLIGHT_DEPARTURE_AIRPORT,
  MUTATION_UPDATE_FLIGHT_DESTINATION_AIRPORT,
  MUTATION_UPDATE_FLIGHT_USER_DATE,
  QUERY_DOWNLOAD_FLIGHT_URL,
} from 'gql';

import SaveNoteDialog from './SaveNoteDialog';
import { ViewLogModal } from 'pages/Aircrafts/components/Dialog';

import { IFlightData } from 'types';
import { apolloClient } from 'services';
import { downloadFileInNewTab } from 'helpers';
import { CustomDateTimePicker } from 'components';
import { FLIGHT_DEPARTURE_MAX_LENGTH, FLIGHT_DESTINATION_MAX_LENGTH } from 'const';

interface FlightMainInfoProps {
  flightData: IFlightData;
  isShare?: boolean;
  smallPadding?: boolean;
}

const FlightMainInfo: React.FC<FlightMainInfoProps> = (props) => {
  const { flightData, isShare = false, smallPadding = false } = props;

  const { isSavvy } = useSelector((state: any) => state.auth);

  const [saveDepartureMutation, { data: dataDepartureMutation, loading: loadingDepartureMutation }] = useMutation(
    MUTATION_UPDATE_FLIGHT_DEPARTURE_AIRPORT,
  );
  const [saveDestinationMutation, { data: dataDestinationMutation, loading: loadingDestinationMutation }] = useMutation(
    MUTATION_UPDATE_FLIGHT_DESTINATION_AIRPORT,
  );
  const [updateFlightDate, { data: dataFlightDate, loading: loadingFlightDate }] = useMutation(MUTATION_UPDATE_FLIGHT_USER_DATE);

  const [departureAirport, setDepartureAirport] = useState(flightData?.departureAirport);
  const [destinationAirport, setDestinationAirport] = useState(flightData?.destinationAirport);

  const [flightNote, setFlightNote] = useState(flightData?.notes ? flightData.notes : '');
  const [editDeparture, setEditDeparture] = useState(false);
  const [editDestination, setEditDestination] = useState(false);
  const [editNote, setEditNote] = useState(false);

  const [userDate, setUserDate] = useState<Dayjs | null>(flightData?.date ? dayjs(flightData?.date) : null);
  const [editDate, setEditDate] = useState(false);

  const [openLogModal, setOpenLogModal] = useState(false);

  const saveDepartureAirport = async () => {
    await saveDepartureMutation({
      variables: {
        flightId: flightData.id,
        departureName: departureAirport,
      },
    });
  };

  useEffect(() => {
    if (!loadingDepartureMutation && dataDepartureMutation) {
      if (dataDepartureMutation.updateFlightDepartureAirport?.ok) {
        setEditDeparture(false);
        setDepartureAirport(dataDepartureMutation.updateFlightDepartureAirport.flight.departureAirport);
      }
    }
  }, [dataDepartureMutation, loadingDepartureMutation]);

  const saveDestinationAirport = async () => {
    await saveDestinationMutation({
      variables: {
        flightId: flightData.id,
        destinationName: destinationAirport,
      },
    });
  };

  const [loadingDownloadFlightUrl, setLoadingDownloadFlightUrl] = useState(false);
  const downloadFlight = async (flightId: string) => {
    if (flightId && parseInt(flightId)) {
      setLoadingDownloadFlightUrl(true);
      const { data: dataDownloadFlightUrl } = await apolloClient.query({
        query: QUERY_DOWNLOAD_FLIGHT_URL,
        variables: {
          flightId: parseInt(flightId),
        },
        fetchPolicy: 'no-cache',
      });
      setLoadingDownloadFlightUrl(false);
      if (dataDownloadFlightUrl?.downloadFlightUrl) {
        downloadFileInNewTab(dataDownloadFlightUrl.downloadFlightUrl);
      }
    }
  };

  useEffect(() => {
    if (!loadingDestinationMutation && dataDestinationMutation) {
      if (dataDestinationMutation.updateFlightDestinationAirport?.ok) {
        setEditDestination(false);
        setDestinationAirport(dataDestinationMutation.updateFlightDestinationAirport.flight.destinationAirport);
      }
    }
  }, [dataDestinationMutation, loadingDestinationMutation]);

  const saveFlightDate = async () => {
    await updateFlightDate({
      variables: {
        flightId: flightData.id,
        userDate,
      },
    });
  };

  useEffect(() => {
    if (!loadingFlightDate && dataFlightDate) {
      if (dataFlightDate.updateFlightUserDate?.ok) {
        setEditDate(false);
        setUserDate(dayjs(dataFlightDate.updateFlightUserDate.flight.date));
      }
    }
  }, [dataFlightDate, loadingFlightDate]);

  return (
    <Box>
      <ComponentLoading loading={loadingDepartureMutation || loadingDestinationMutation || loadingDownloadFlightUrl}>
        <Box
          py={1}
          sx={{
            backgroundColor: 'grey.800',
            pl: {
              xl: smallPadding ? 2 : 5,
              xs: 2,
            },
          }}
        >
          <Typography
            sx={{
              color: 'text.secondary',
              fontSize: '16px',
              lineHeight: '22px',
              fontWeight: 'bold',
            }}
          >
            Flight
          </Typography>
        </Box>
        <Box
          py={1}
          sx={{
            pl: {
              xl: smallPadding ? 2 : 5,
              xs: 2,
            },
            pr: {
              xl: 0,
              xs: 1,
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              py: 1,
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography
              sx={{
                width: '40%',
                color: 'grey.600',
                fontWeight: 600,
                lineHeight: '19px',
              }}
            >
              Time
            </Typography>
            {!editDate && (
              <Box
                sx={{
                  width: '60%',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography
                  sx={{
                    color: 'text.secondary',
                    fontSize: '14px',
                    lineHeight: '19px',
                  }}
                >
                  {userDate ? userDate.format('MMM D, YYYY HH:mm Z') : ''}
                </Typography>
                {!isShare && (
                  <IconButton
                    onClick={() => {
                      setEditDate(true);
                    }}
                    sx={{
                      ml: '10px',
                      padding: 0,
                    }}
                  >
                    <EditIcon
                      sx={{
                        width: '19px',
                        height: '19px',
                      }}
                    />
                  </IconButton>
                )}
              </Box>
            )}
            {editDate && (
              <Box
                sx={{
                  width: '60%',
                }}
              >
                <CustomDateTimePicker label="Flight Date" value={userDate} setValue={setUserDate} />
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      width: '45%',
                    }}
                    onClick={saveFlightDate}
                    size="small"
                  >
                    Save
                  </Button>
                  <Button
                    variant="outlined"
                    color="warning"
                    sx={{
                      width: '45%',
                    }}
                    onClick={() => {
                      setUserDate(dayjs(flightData.date));
                      setEditDate(false);
                    }}
                    size="small"
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
          <InfoLine title="Duration" value={getDuration(flightData.duration)} />
          {flightData.flightData && <InfoLine title="Interval" value={`~${flightData.flightData.samplingRate}s`} />}
          <Box
            sx={{
              display: 'flex',
              py: 1,
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography
              sx={{
                width: '40%',
                color: 'grey.600',
                fontWeight: 600,
                lineHeight: '19px',
              }}
            >
              Departure
            </Typography>
            {!editDeparture && (
              <Box
                sx={{
                  width: '60%',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography
                  sx={{
                    color: 'text.secondary',
                    fontSize: '14px',
                    lineHeight: '19px',
                  }}
                >
                  {departureAirport}
                </Typography>
                {!isShare && (
                  <IconButton
                    onClick={() => {
                      setEditDeparture(true);
                    }}
                    sx={{
                      ml: '10px',
                      padding: 0,
                    }}
                  >
                    <EditIcon
                      sx={{
                        width: '19px',
                        height: '19px',
                      }}
                    />
                  </IconButton>
                )}
              </Box>
            )}
            {editDeparture && (
              <Box
                sx={{
                  width: '60%',
                }}
              >
                <TextField
                  name="editDeparture"
                  placeholder=""
                  label=""
                  value={departureAirport}
                  onChange={(e) => {
                    setDepartureAirport(e.target.value);
                  }}
                  inputProps={{
                    maxLength: FLIGHT_DEPARTURE_MAX_LENGTH,
                  }}
                />
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      width: '45%',
                    }}
                    onClick={saveDepartureAirport}
                    size="small"
                  >
                    Save
                  </Button>
                  <Button
                    variant="outlined"
                    color="warning"
                    sx={{
                      width: '45%',
                    }}
                    onClick={() => {
                      setDepartureAirport(flightData.departureAirport);
                      setEditDeparture(false);
                    }}
                    size="small"
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              py: 1,
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography
              sx={{
                width: '40%',
                color: 'grey.600',
                fontWeight: 600,
                lineHeight: '19px',
              }}
            >
              Destination
            </Typography>
            {!editDestination && (
              <Box
                sx={{
                  width: '60%',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography
                  sx={{
                    color: 'text.secondary',
                    fontSize: '14px',
                    lineHeight: '19px',
                  }}
                >
                  {destinationAirport}
                </Typography>
                {!isShare && (
                  <IconButton
                    onClick={() => {
                      setEditDestination(true);
                    }}
                    sx={{
                      ml: '10px',
                      padding: 0,
                    }}
                  >
                    <EditIcon
                      sx={{
                        width: '19px',
                        height: '19px',
                      }}
                    />
                  </IconButton>
                )}
              </Box>
            )}
            {editDestination && (
              <Box
                sx={{
                  width: '60%',
                }}
              >
                <TextField
                  name="editDestination"
                  placeholder=""
                  label=""
                  value={destinationAirport}
                  onChange={(e) => {
                    setDestinationAirport(e.target.value);
                  }}
                  inputProps={{
                    maxLength: FLIGHT_DESTINATION_MAX_LENGTH,
                  }}
                />
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      width: '45%',
                    }}
                    onClick={saveDestinationAirport}
                    size="small"
                  >
                    Save
                  </Button>
                  <Button
                    variant="outlined"
                    color="warning"
                    sx={{
                      width: '45%',
                    }}
                    onClick={() => {
                      setDestinationAirport(flightData.destinationAirport);
                      setEditDestination(false);
                    }}
                    size="small"
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              py: 1,
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography
              sx={{
                width: '40%',
                color: 'grey.600',
                fontWeight: 600,
                lineHeight: '19px',
              }}
            >
              Notes
            </Typography>
            {!editNote && (
              <Box
                sx={{
                  width: '60%',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography
                  sx={{
                    color: 'text.secondary',
                    fontSize: '14px',
                    lineHeight: '19px',
                  }}
                >
                  {flightNote}
                </Typography>
                {!isShare && (
                  <IconButton
                    onClick={() => {
                      setEditNote(true);
                    }}
                    sx={{
                      ml: '10px',
                      padding: 0,
                    }}
                  >
                    <EditIcon
                      sx={{
                        width: '19px',
                        height: '19px',
                      }}
                    />
                  </IconButton>
                )}
              </Box>
            )}
            <SaveNoteDialog
              open={editNote}
              setOpen={setEditNote}
              originalNote={flightNote}
              setFlightNote={setFlightNote}
              flightId={flightData.id}
            />
          </Box>
          {flightData.data && JSON.parse(flightData.data).parser_name && (
            <Box
              sx={{
                display: 'flex',
                py: 1,
              }}
            >
              <Typography
                sx={{
                  width: '40%',
                  color: 'grey.600',
                  fontWeight: 600,
                }}
              >
                Parser
              </Typography>
              <Typography
                sx={{
                  width: '60%',
                  color: 'text.secondary',
                  fontSize: '14px',
                  lineHeight: '19px',
                }}
              >
                {`${JSON.parse(flightData.data).parser_name}` +
                  (JSON.parse(flightData.data).parser_version ? ` v${JSON.parse(flightData.data).parser_version}` : '')}
                <br />
                {flightData.importFile && (
                  <Link
                    href="#"
                    onClick={(e: any) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setOpenLogModal(true);
                    }}
                  >
                    View Parser Logs
                  </Link>
                )}
                {isSavvy && (
                  <React.Fragment>
                    <br />
                    <Link
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        downloadFlight(flightData.id);
                      }}
                    >
                      Download
                    </Link>
                  </React.Fragment>
                )}
              </Typography>
            </Box>
          )}
        </Box>
        <ViewLogModal
          open={openLogModal}
          setOpen={setOpenLogModal}
          aircraftId={flightData.aircraft?.id || ''}
          fileId={flightData.importFile?.id || ''}
        />
      </ComponentLoading>
    </Box>
  );
};

export default FlightMainInfo;
