import React from 'react';
import toPath from 'lodash/toPath';
import { useHistory } from 'react-router-dom';
import { Link } from '@mui/material';
import { PATH_LOGIN } from 'const';

interface ErrorMessageProps {
  name: string;
  className?: string;
  errors: any;
  touched: any;
  existEmail: boolean;
  existCompany: boolean;
}

// Input UI
const FormInputError: React.FC<ErrorMessageProps> = (props) => {
  const history = useHistory();

  const getIn = function (obj: any, key: string | string[], def?: any, p = 0) {
    const path = toPath(key);
    while (obj && p < path.length) {
      obj = obj[path[p++]];
    }
    return obj === undefined ? def : obj;
  };

  const { name, touched, errors, existEmail, existCompany } = props;

  const touch = getIn(touched, name);
  const error = getIn(errors, name);

  return (
    <React.Fragment>
      {touch && error ? (
        <div style={{ color: 'red' }}>
          {existEmail ? (
            <React.Fragment>
              This account already exists.{' '}
              <Link
                href={PATH_LOGIN}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  history.push(PATH_LOGIN);
                }}
              >
                Please login instead.
              </Link>
              <br />
              To add/change service plans, use the "Manage Plans" link from your dashboard.
            </React.Fragment>
          ) : (
            error
          )}
        </div>
      ) : existCompany ? (
        'This company already exists'
      ) : null}
    </React.Fragment>
  );
};

export default FormInputError;
