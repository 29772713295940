import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { PageLoading } from 'components';

import { useQuery } from '@apollo/client';
import { QUERY_USER_TICKET_ELIGIBILITY } from 'gql';
import { setAlert } from 'state';
import { IAircraftEligibility } from 'types';

import { NewTicketCategory, LayoutTicketCreate } from './components';

const SelectTicketType: React.FC = () => {
  const { data, loading, error } = useQuery(QUERY_USER_TICKET_ELIGIBILITY);

  const [ticketAircraft, setTicketAircraft] = useState<IAircraftEligibility | undefined>(undefined);

  const dispatch = useDispatch();

  useEffect(() => {
    if (error) {
      dispatch(setAlert('error', 'Unable to load user data'));
    }
  }, [error, dispatch]);

  return (
    <React.Fragment>
      {loading && <PageLoading />}
      <LayoutTicketCreate
        activeStep={0}
        component={
          <NewTicketCategory
            aircrafts={data?.me?.aircraft ? data.me.aircraft : []}
            setTicketAircraft={setTicketAircraft}
            ticketAircraft={ticketAircraft}
          />
        }
      />
    </React.Fragment>
  );
};

export default SelectTicketType;
