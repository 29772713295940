import React, { useEffect } from 'react';

import { Grid, Box, MenuItem } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-mui';
import { MuiFormikInput, ButtonSubmit, ComponentLoading, Autocomplete } from 'components';
import * as Yup from 'yup';

import { useQuery } from '@apollo/client';
import { QUERY_AVAILABLE_TIMEZONES } from 'gql';
import { IContactType } from 'types';
import { useAutocomplete } from 'hooks';
import { countries } from 'api';
import { getOptionLabelFlagCountry, formatOptionIdName } from 'helpers';

const ValidPersonalSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid Email')
    .min(3, 'Too short: 3-50 characters required')
    .max(50, 'Too long: 3-50 characters required')
    .required('Required'),
  firstName: Yup.string().min(1, 'Too short: 1-20 characters required').max(20, 'Too long: 1-20 characters required').required('Required'),
  lastName: Yup.string().min(1, 'Too short: 1-20 characters required').max(20, 'Too long: 1-20 characters required').required('Required'),
  airportId: Yup.string().max(4, 'Too long: Maximum 4 characters').required('Required'),
  phoneMobile: Yup.string()
    .min(1, 'Too short: 1-20 characters required')
    .max(20, 'Too long: 1-20 characters required')
    .required('Required'),
  timezone: Yup.string(),
  street: Yup.string().min(1, 'Too short: 1-40 characters required').max(40, 'Too long: 1-40 characters required'),
  city: Yup.string().min(1, 'Too short: 1-20 characters required').max(20, 'Too long: 1-20 characters required'),
  state: Yup.string().min(1, 'Too short: 1-20 characters required').max(20, 'Too long: 1-20 characters required'),
});

interface MainInfoProps {
  userData: IContactType;
  setSlideNum: (value: number) => void;
  setUserData: (value: IContactType) => void;
}

const MainInfo: React.FC<MainInfoProps> = (props) => {
  const { email, firstName, lastName, phoneMobile, airportId, timezone, country: countryProps } = props.userData;
  const { userData, setSlideNum, setUserData } = props;
  const { data: dataTimezones, loading: loadingTimezones } = useQuery(QUERY_AVAILABLE_TIMEZONES);
  const {
    value: country,
    error: errorCountry,
    setError: setErrorCountry,
    onChange: onChangeCountry,
    setValue: setCountry,
  } = useAutocomplete();

  const onSubmit = (values: any) => {
    if (!country) {
      setErrorCountry('Required');
      return;
    }
    setUserData({
      ...userData,
      country: country.id,
      ...values,
    });
    setSlideNum(1);
  };

  useEffect(() => {
    if (countryProps) {
      const filteredCountry = countries.filter((country) => country.id === countryProps);
      if (filteredCountry?.length) {
        setCountry(filteredCountry[0]);
      }
    }
  }, [countryProps]);

  return (
    <Box p={1}>
      <ComponentLoading loading={loadingTimezones}>
        <Formik
          initialValues={{
            email: email,
            firstName: firstName ? firstName : '',
            lastName: lastName ? lastName : '',
            phoneMobile: phoneMobile ? phoneMobile : '',
            airportId: airportId ? airportId : '',
            timezone: timezone ? timezone : '',
            street: userData.street ? userData.street : '',
            zip: userData.zip ? userData.zip : '',
            city: userData.city ? userData.city : '',
            state: userData.state ? userData.state : '',
          }}
          onSubmit={onSubmit}
          validationSchema={ValidPersonalSchema}
        >
          {({ isSubmitting, handleChange, handleBlur, touched, errors }) => {
            return (
              <Form>
                <Grid container spacing={2}>
                  <Grid item lg={4} md={6} xs={12}>
                    <MuiFormikInput
                      name="email"
                      label="Email *"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                    />
                  </Grid>
                  <Grid item lg={4} md={6} xs={12}>
                    <MuiFormikInput
                      name="firstName"
                      label="First Name *"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                    />
                  </Grid>
                  <Grid item lg={4} md={6} xs={12}>
                    <MuiFormikInput
                      name="lastName"
                      label="Last Name *"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                    />
                  </Grid>
                  <Grid item lg={4} md={6} xs={12}>
                    <MuiFormikInput
                      name="phoneMobile"
                      label="Mobile Phone *"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                    />
                  </Grid>
                  <Grid item lg={4} md={6} xs={12}>
                    <MuiFormikInput
                      name="airportId"
                      label="Home Airport Id *"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                    />
                  </Grid>
                  <Grid item lg={4} md={6} xs={12}>
                    <Autocomplete
                      label="Country *"
                      name="country"
                      value={country}
                      error={errorCountry}
                      onChange={(e) => onChangeCountry(e)}
                      options={countries}
                      getOptionLabel={getOptionLabelFlagCountry}
                      formatOption={formatOptionIdName}
                      disabled={isSubmitting}
                    />
                  </Grid>
                  <Grid item lg={4} md={6} xs={12}>
                    <MuiFormikInput
                      name="street"
                      label="Street"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                    />
                  </Grid>
                  <Grid item lg={4} md={6} xs={12}>
                    <MuiFormikInput
                      name="city"
                      label="City"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                    />
                  </Grid>
                  <Grid item lg={4} md={6} xs={12}>
                    <MuiFormikInput
                      name="state"
                      label="State"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                    />
                  </Grid>
                  <Grid item lg={4} md={6} xs={12}>
                    <MuiFormikInput name="zip" label="Zip" onBlur={handleBlur} onChange={handleChange} errors={errors} touched={touched} />
                  </Grid>
                  {dataTimezones?.availableTimezones && (
                    <Grid item lg={4} md={6} xs={12}>
                      <Field
                        name="timezone"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        component={TextField}
                        label="Timezone"
                        select
                        variant="outlined"
                        fullWidth
                        FormHelperTextProps={{
                          sx: {
                            color: 'red',
                            marginLeft: 0,
                          },
                        }}
                        sx={{
                          mb: 0,
                        }}
                      >
                        {dataTimezones.availableTimezones.map((item: string, key: number) => (
                          <MenuItem key={key} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>
                  )}
                </Grid>
                <Box py={2}>
                  <Grid container spacing={2}>
                    <Grid item md={3} xs={false}></Grid>
                    <Grid item md={6} xs={12}>
                      <ButtonSubmit text="Next" loading={isSubmitting} />
                    </Grid>
                  </Grid>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </ComponentLoading>
    </Box>
  );
};

export default MainInfo;
