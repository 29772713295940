import { IOptionIdName } from 'types';

import { AIRCRAFT_MODEL_START_YEAR, CURRENT_YEAR } from 'const';

// List of years for aircraft model. Used in Autocomplete
const aircraftModelYears: IOptionIdName[] = []; // Initial empty array

let aircraftModelStartYear = AIRCRAFT_MODEL_START_YEAR; // Year to start from

// Get all years from startYear to current
while (aircraftModelStartYear <= CURRENT_YEAR) {
  const year = aircraftModelStartYear.toString(); // Convert to string for typescript and length checks in inputs
  aircraftModelYears.push({
    id: year,
    name: year,
  });
  aircraftModelStartYear++;
}

export default aircraftModelYears;
