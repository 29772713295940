import { gql } from '@apollo/client';
import {
  FRAGMENT_COI,
  FRAGMENT_MAINTENANCE_ENTRY,
  FRAGMENT_DOCUMENT,
  FRAGMENT_AIRCRAFT_LOGBOOK,
  FRAGMENT_FLIGHT_REPORT,
  FRAGMENT_COHORTS,
  FRAGEMENT_SERVICE_CENTER,
} from 'gql';

// Create Aircraft
export const MUTATION_CREATE_AIRCRAFT = gql`
  mutation CreateAircraft(
    $aircraftHasNoMonitor: Boolean
    $aircraftManufacturerId: ID
    $aircraftModelId: ID
    $engineManufacturerId: ID
    $engineModelId: ID
    $engineMonitorManufacturerId: ID
    $engineMonitorModelId: ID
    $chtWarningTemperature: Int
    $cylinderCount: Int
    $registration: String
    $serial: String
    $year: Int
    $ownerId: Int
    $prebuy: Boolean
  ) {
    createAircraft(
      aircraftHasNoMonitor: $aircraftHasNoMonitor
      aircraftManufacturerId: $aircraftManufacturerId
      aircraftModelId: $aircraftModelId
      engineManufacturerId: $engineManufacturerId
      engineModelId: $engineModelId
      engineMonitorManufacturerId: $engineMonitorManufacturerId
      engineMonitorModelId: $engineMonitorModelId
      chtWarningTemperature: $chtWarningTemperature
      cylinderCount: $cylinderCount
      registration: $registration
      serial: $serial
      year: $year
      ownerId: $ownerId
      prebuy: $prebuy
    ) {
      ok
      aircraft {
        id
      }
      error
    }
  }
`;

export const MUTATION_CREATE_AIRCRAFT_MANUFACTURER = gql`
  mutation CreateAircraftManufacturer($name: String!) {
    createAircraftManufacturer(name: $name) {
      ok
      aircraftManufacturer {
        id
        name
      }
      error
    }
  }
`;

export const MUTATION_CREATE_AIRCRAFT_MODEL = gql`
  mutation CreateAircraftModel($name: String!, $manufacturerId: ID!, $certification: String, $enginesCount: String, $propulsion: String) {
    createAircraftModel(
      name: $name
      manufacturerId: $manufacturerId
      certification: $certification
      enginesCount: $enginesCount
      propulsion: $propulsion
    ) {
      ok
      aircraftModel {
        id
        name
      }
      error
    }
  }
`;

export const MUTATION_UPDATE_AIRCRAFT = gql`
  ${FRAGEMENT_SERVICE_CENTER}
  mutation UpdateAircraft(
    $aircraftId: ID!
    $aircraftManufacturerId: ID
    $aircraftModelId: ID
    $aircraftHasNoMonitor: Boolean
    $engineManufacturerId: ID
    $engineModelId: ID
    $engineMonitorManufacturerId: ID
    $engineMonitorModelId: ID
    $serial: String
    $year: Int
    $registration: String
    $cylinderCount: Int
    $annualDue: Date
    $leftIgnition: String
    $rightIgnition: String
    $chtWarningTemperature: Int
    $preferredServiceCenterId: Int
  ) {
    updateAircraft(
      aircraftId: $aircraftId
      aircraftManufacturerId: $aircraftManufacturerId
      aircraftModelId: $aircraftModelId
      aircraftHasNoMonitor: $aircraftHasNoMonitor
      engineManufacturerId: $engineManufacturerId
      engineModelId: $engineModelId
      engineMonitorManufacturerId: $engineMonitorManufacturerId
      engineMonitorModelId: $engineMonitorModelId
      serial: $serial
      year: $year
      registration: $registration
      cylinderCount: $cylinderCount
      annualDue: $annualDue
      leftIgnition: $leftIgnition
      rightIgnition: $rightIgnition
      chtWarningTemperature: $chtWarningTemperature
      preferredServiceCenterId: $preferredServiceCenterId
    ) {
      ok
      aircraft {
        id
        created
        updated
        registration
        serial
        year
        aircraftManufacturer {
          id
          name
          aircraftmodelSet {
            id
            name
          }
        }
        aircraftModel {
          id
          name
        }
        engineManufacturer {
          id
          name
          enginemodelSet {
            id
            name
          }
        }
        engineModel {
          id
          name
        }
        engineMonitorManufacturer {
          id
          name
          enginemonitormodelSet {
            id
            name
          }
        }
        engineMonitorModel {
          id
          name
        }
        showMglSettings
        showUbg16Settings
        aircraftHasNoMonitor
        cylinderCount
        chtWarningTemperature
        engineDataFiles {
          id
          name
          uploadDate
        }
        flights {
          id
          importFile {
            id
            name
            uploadDate
          }
          date
          departureName
          destinationName
          duration
          departureAirport
          destinationAirport
        }
        subscriptions {
          creationDate
          serviceName
          serviceCode
          status
          amountPaid
          amountRefunded
        }
        canUpdateAircraftMakeModel
        annualDue
        leftIgnition
        rightIgnition
        preferredServiceCenter {
          ...ServiceCenter
        }
        profileComplete
      }
      error
    }
  }
`;

export const MUTATION_CREATE_ENGINE_MANUFACTURER = gql`
  mutation CreateEngineManufacturer($name: String) {
    createEngineManufacturer(name: $name) {
      ok
      engineManufacturer {
        id
        name
        enginemodelSet {
          id
          name
        }
      }
      error
    }
  }
`;

export const MUTATION_CREATE_ENGINE_MODEL = gql`
  mutation CreateEngineModel($name: String, $manufacturerId: ID, $cylinderCount: Int) {
    createEngineModel(name: $name, manufacturerId: $manufacturerId, cylinderCount: $cylinderCount) {
      ok
      engineModel {
        id
        name
      }
      error
    }
  }
`;

export const MUTATION_CREATE_ENGINE_MONITOR_MANUFACTURER = gql`
  mutation CreateEngineMonitorManufacturer($name: String) {
    createEngineMonitorManufacturer(name: $name) {
      ok
      engineMonitorManufacturer {
        id
        name
      }
      error
    }
  }
`;

export const MUTATION_CREATE_ENGINE_MONITOR_MODEL = gql`
  mutation CreateEngineMonitorModel($manufacturerId: ID, $name: String) {
    createEngineMonitorModel(manufacturerId: $manufacturerId, name: $name) {
      ok
      engineMonitorModel {
        id
        name
      }
      error
    }
  }
`;

export const MUTATION_SET_DEFAULT_AIRCRAFT = gql`
  mutation SetDefaultAircraft($aircraftId: ID) {
    setDefaultAircraft(aircraftId: $aircraftId) {
      ok
      aircraft {
        id
        default
      }
      error
    }
  }
`;

export const MUTATION_DELETE_AIRCRAFT_CONVERSION = gql`
  mutation DeleteAircraftConversion($conversionId: ID!) {
    deleteAircraftConversion(conversionId: $conversionId) {
      ok
      aircraft {
        id
        aircraftConversions {
          id
          seriesName
          unitconversion {
            id
          }
        }
      }
      error
    }
  }
`;

export const MUTATION_CREATE_AIRCRAFT_CONVERSION = gql`
  mutation CreateAircraftConversion($aircraftId: ID, $series: String, $unitConversionId: ID) {
    createAircraftConversion(aircraftId: $aircraftId, series: $series, unitConversionId: $unitConversionId) {
      ok
      aircraft {
        id
        aircraftConversions {
          id
          seriesName
          unitconversion {
            id
            fromName
            toName
            groupName
            aParam
            bParam
          }
        }
      }
      error
    }
  }
`;

export const MUTATION_SET_MONITOR_CONFIG = gql`
  mutation SetMonitorConfig($aircraftId: ID, $monitorConfig: String) {
    setMonitorConfig(aircraftId: $aircraftId, monitorConfig: $monitorConfig) {
      ok
      aircraft {
        id
        monitorConfig
      }
      error
    }
  }
`;

export const MUTATION_POST_AIRCRAFT_COMMENT = gql`
  mutation PostAircraftComment($aircraftId: ID, $comment: String, $pinned: Boolean) {
    postAircraftComment(aircraftId: $aircraftId, comment: $comment, pinned: $pinned) {
      ok
      error
    }
  }
`;

export const MUTATION_PIN_AIRCRAFT_COMMENT = gql`
  mutation PinAircraftComment($aircraftCommentId: ID, $pinned: Boolean) {
    pinAircraftComment(aircraftCommentId: $aircraftCommentId, pinned: $pinned) {
      ok
      comment {
        id
        pinned
      }
      error
    }
  }
`;

export const MUTATION_TRANSFER_SUBSCRIPTION_TO_OWNED_AIRCRAFT = gql`
  mutation TransferSubscriptionsToOwnedAircraft($sourceAircraftId: ID, $targetAircraftId: ID) {
    transferSubscriptionsToOwnedAircraft(sourceAircraftId: $sourceAircraftId, targetAircraftId: $targetAircraftId) {
      ok
      aircraft {
        id
        upgradeContext {
          title
          renewals {
            id
            name
            status
            expiration
            showDoNotRenew
          }
          options {
            title
            price
            credit
            discount
            total
            description
            learnMoreLink
          }
        }
      }
      error
    }
  }
`;

export const MUTATION_TRANSFER_SUBSCRIPTION_TO_CONTACT = gql`
  mutation TransferSubscriptionsToContact($email: String, $aircraftId: ID) {
    transferSubscriptionsToContact(email: $email, aircraftId: $aircraftId) {
      ok
      aircraft {
        id
        upgradeContext {
          title
          renewals {
            id
            name
            status
            expiration
            showDoNotRenew
          }
          options {
            title
            price
            credit
            discount
            total
            description
            learnMoreLink
          }
        }
      }
      error
    }
  }
`;

export const MUTATION_UPDATE_AIRCRAFT_MANAGERS = gql`
  mutation UpdateAircraftManagers($aircraftId: ID, $accountManagerId: ID, $backupManagerId: ID, $initialUpdateTicketId: ID) {
    updateAircraftManagers(
      aircraftId: $aircraftId
      accountManagerId: $accountManagerId
      backupManagerId: $backupManagerId
      initialUpdateTicketId: $initialUpdateTicketId
    ) {
      ok
      aircraft {
        id
        accountManager {
          id
          firstName
          lastName
        }
        backupManager {
          id
          firstName
          lastName
        }
      }
      error
    }
  }
`;

export const MUTATION_DELETE_AIRCRAFT = gql`
  mutation DeleteAircraft($aircraftId: ID) {
    deleteAircraft(aircraftId: $aircraftId) {
      ok
      aircraft {
        id
        canDelete
        hidden
      }
      error
    }
  }
`;

export const MUTATION_CREATE_CERTIFICATE_OF_INSURANCE = gql`
  ${FRAGMENT_COI}
  mutation CreateCertificateOfInsurance(
    $agent: String
    $aircraftId: ID
    $documentPath: String
    $expirationDate: DateTime
    $receivedDate: DateTime
    $underwriter: String
    $approve: Boolean
  ) {
    createCertificateOfInsurance(
      agent: $agent
      aircraftId: $aircraftId
      documentPath: $documentPath
      expirationDate: $expirationDate
      receivedDate: $receivedDate
      underwriter: $underwriter
      approve: $approve
    ) {
      ok
      aircraft {
        id
        certificatesOfInsurance {
          ...AircraftCOI
        }
      }
      error
    }
  }
`;

export const MUTATION_REQUEST_CERTIFICATE_OF_INSURANCE = gql`
  mutation RequestCertificateOfInsurance($aircraftId: ID, $postBody: String) {
    requestCertificateOfInsurance(aircraftId: $aircraftId, postBody: $postBody) {
      ok
      ticket {
        id
      }
      error
    }
  }
`;

export const MUTATION_CREATE_MAINTENANCE_ENTRY_FROM_DOC = gql`
  ${FRAGMENT_MAINTENANCE_ENTRY}
  ${FRAGMENT_AIRCRAFT_LOGBOOK}
  mutation CreateMaintenanceEntryWithExistingDocument(
    $aircraftId: ID
    $body: String
    $documentId: ID
    $isPerformedInspection: Boolean
    $isPerformedMaintenance: Boolean
    $maintenanceEntryCategoryId: ID
    $logbookId: ID
    $entryDate: DateTime
  ) {
    createMaintenanceEntryWithExistingDocument(
      aircraftId: $aircraftId
      body: $body
      documentId: $documentId
      isPerformedInspection: $isPerformedInspection
      isPerformedMaintenance: $isPerformedMaintenance
      maintenanceEntryCategoryId: $maintenanceEntryCategoryId
      logbookId: $logbookId
      entryDate: $entryDate
    ) {
      ok
      aircraft {
        id
        maintenanceEntries {
          ...MaintenanceEntry
        }
        logbooks {
          ...Logbook
        }
      }
      error
    }
  }
`;

export const MUTATION_CREATE_CERTIFICATE_OF_INSURANCE_FROM_DOC = gql`
  ${FRAGMENT_COI}
  mutation CreateCertificateOfInsuranceWithExistingDocument(
    $agent: String
    $aircraftId: ID
    $documentId: ID
    $expirationDate: DateTime
    $receivedDate: DateTime
    $underwriter: String
    $approve: Boolean
  ) {
    createCertificateOfInsuranceWithExistingDocument(
      agent: $agent
      aircraftId: $aircraftId
      documentId: $documentId
      expirationDate: $expirationDate
      receivedDate: $receivedDate
      underwriter: $underwriter
      approve: $approve
    ) {
      ok
      aircraft {
        id
        certificatesOfInsurance {
          ...AircraftCOI
        }
      }
    }
  }
`;

export const MUTATION_CREATE_CERTIFICATE_OF_INSURANCE_FROM_ATTACHMENT = gql`
  ${FRAGMENT_COI}
  mutation CreateCertificateOfInsuranceFromAttachment(
    $agent: String
    $attachmentId: ID
    $expirationDate: DateTime
    $receivedDate: DateTime
    $underwriter: String
    $approve: Boolean
    $aircraftId: ID
  ) {
    createCertificateOfInsuranceFromAttachment(
      agent: $agent
      attachmentId: $attachmentId
      expirationDate: $expirationDate
      receivedDate: $receivedDate
      underwriter: $underwriter
      approve: $approve
      aircraftId: $aircraftId
    ) {
      ok
      aircraft {
        id
        certificatesOfInsurance {
          ...AircraftCOI
        }
      }
      error
    }
  }
`;

export const MUTATION_DELETE_CERTIFICATE_OF_INSURANCE = gql`
  ${FRAGMENT_COI}
  mutation DeleteCertificateOfInsurance($certificateOfInsuranceId: ID) {
    deleteCertificateOfInsurance(certificateOfInsuranceId: $certificateOfInsuranceId) {
      ok
      aircraft {
        id
        certificatesOfInsurance {
          ...AircraftCOI
        }
      }
      error
    }
  }
`;

export const MUTATION_APPROVE_CERTIFICATE_OF_INSURANCE = gql`
  ${FRAGMENT_COI}
  mutation ApproveCertificateOfInsurance($certificateOfInsuranceId: ID) {
    approveCertificateOfInsurance(certificateOfInsuranceId: $certificateOfInsuranceId) {
      ok
      certificateOfInsurance {
        ...AircraftCOI
      }
      error
    }
  }
`;

export const MUTATION_CREATE_MAINTENANCE_ENTRY = gql`
  ${FRAGMENT_MAINTENANCE_ENTRY}
  ${FRAGMENT_AIRCRAFT_LOGBOOK}
  mutation CreateMaintenanceEntry(
    $aircraftId: ID
    $body: String
    $documentPath: String
    $isPerformedInspection: Boolean
    $isPerformedMaintenance: Boolean
    $maintenanceEntryCategoryId: ID
    $entryDate: DateTime
    $logbookId: ID
  ) {
    createMaintenanceEntry(
      aircraftId: $aircraftId
      body: $body
      documentPath: $documentPath
      isPerformedInspection: $isPerformedInspection
      isPerformedMaintenance: $isPerformedMaintenance
      maintenanceEntryCategoryId: $maintenanceEntryCategoryId
      entryDate: $entryDate
      logbookId: $logbookId
    ) {
      ok
      maintenanceEntry {
        id
      }
      aircraft {
        id
        maintenanceEntries {
          ...MaintenanceEntry
        }
        logbooks {
          ...Logbook
        }
      }
      error
    }
  }
`;

export const MUTATION_CREATE_MAINTENANCE_ENTRY_FROM_ATTACHMENT = gql`
  mutation CreateMaintenanceEntryFromAttachment(
    $aircraftId: ID
    $body: String
    $attachmentId: ID
    $isPerformedInspection: Boolean
    $isPerformedMaintenance: Boolean
    $maintenanceEntryCategoryId: ID
    $entryDate: DateTime
    $logbookId: ID
  ) {
    createMaintenanceEntryFromAttachment(
      aircraftId: $aircraftId
      body: $body
      attachmentId: $attachmentId
      isPerformedInspection: $isPerformedInspection
      isPerformedMaintenance: $isPerformedMaintenance
      maintenanceEntryCategoryId: $maintenanceEntryCategoryId
      entryDate: $entryDate
      logbookId: $logbookId
    ) {
      ok
      maintenanceEntry {
        id
      }
      error
    }
  }
`;

export const MUTATION_UPDATE_ATTACHMENT_DESCRIPTION = gql`
  ${FRAGMENT_DOCUMENT}
  mutation UpdateAttachmentDescription($attachmentId: ID, $description: String) {
    updateAttachmentDescription(attachmentId: $attachmentId, description: $description) {
      ok
      document {
        ...Document
      }
      error
    }
  }
`;

export const MUTATION_DELETE_MAINTENANCE_ENTRY = gql`
  mutation DeleteMaintenanceEntry($maintenanceEntryId: ID) {
    deleteMaintenanceEntry(maintenanceEntryId: $maintenanceEntryId) {
      ok
      aircraft {
        id
        maintenanceEntries {
          id
        }
      }
      error
    }
  }
`;

export const MUTATION_UPDATE_MAINTENANCE_ENTRY = gql`
  ${FRAGMENT_MAINTENANCE_ENTRY}
  mutation UpdateMaintenanceEntry(
    $maintenanceEntryId: ID
    $body: String
    $isPerformedInspection: Boolean
    $isPerformedMaintenance: Boolean
    $maintenanceEntryCategoryId: ID
    $entryDate: DateTime
    $logbookId: ID
  ) {
    updateMaintenanceEntry(
      maintenanceEntryId: $maintenanceEntryId
      body: $body
      isPerformedInspection: $isPerformedInspection
      isPerformedMaintenance: $isPerformedMaintenance
      maintenanceEntryCategoryId: $maintenanceEntryCategoryId
      entryDate: $entryDate
      logbookId: $logbookId
    ) {
      ok
      maintenanceEntry {
        ...MaintenanceEntry
      }
      error
    }
  }
`;

export const MUTATION_UPDATE_CERTIFICATE_OF_INSURANCE = gql`
  ${FRAGMENT_COI}
  mutation UpdateCertificateOfInsurance(
    $certificateOfInsuranceId: ID
    $agent: String
    $expirationDate: DateTime
    $underwriter: String
    $receivedDate: DateTime
  ) {
    updateCertificateOfInsurance(
      certificateOfInsuranceId: $certificateOfInsuranceId
      agent: $agent
      expirationDate: $expirationDate
      underwriter: $underwriter
      receivedDate: $receivedDate
    ) {
      ok
      certificateOfInsurance {
        ...AircraftCOI
      }
      error
    }
  }
`;

export const MUTATION_CREATE_MAINTENACE_LOGBOOK = gql`
  ${FRAGMENT_AIRCRAFT_LOGBOOK}
  mutation CreateMaintenanceLogbook($aircraftId: ID, $name: String) {
    createMaintenanceLogbook(aircraftId: $aircraftId, name: $name) {
      ok
      aircraft {
        id
        logbooks {
          ...Logbook
        }
      }
      error
    }
  }
`;

export const MUTATION_UPDATE_LOGBOOK = gql`
  ${FRAGMENT_AIRCRAFT_LOGBOOK}
  mutation UpdateMaintenanceLogbook($maintenanceLogbookId: ID, $name: String) {
    updateMaintenanceLogbook(maintenanceLogbookId: $maintenanceLogbookId, name: $name) {
      ok
      aircraft {
        id
        logbooks {
          ...Logbook
        }
      }
      error
    }
  }
`;

export const MUTATION_DELETE_LOGBOOK = gql`
  ${FRAGMENT_AIRCRAFT_LOGBOOK}
  mutation DeleteMaintenanceLogbook($maintenanceLogbookId: ID, $transferEntriesToLogbookId: ID) {
    deleteMaintenanceLogbook(maintenanceLogbookId: $maintenanceLogbookId, transferEntriesToLogbookId: $transferEntriesToLogbookId) {
      ok
      aircraft {
        id
        logbooks {
          ...Logbook
        }
      }
      error
    }
  }
`;

export const MUTATION_UPDATE_FLIGHT_REPORT = gql`
  ${FRAGMENT_FLIGHT_REPORT}
  mutation UpdateFlightReport($reports: [UpdateFlightReportInput]) {
    updateFlightReport(reports: $reports) {
      ok
      error
      report {
        ...FlightReport
      }
    }
  }
`;

export const MUTATION_CREATE_COHORT = gql`
  mutation CreateCohort($active: Boolean, $aircraftModelId: ID, $engineModelId: ID, $engineParametersId: ID, $parentCohortId: ID) {
    createCohort(
      active: $active
      aircraftModelId: $aircraftModelId
      engineModelId: $engineModelId
      engineParametersId: $engineParametersId
      parentCohortId: $parentCohortId
    ) {
      ok
      error
    }
  }
`;

export const MUTATION_CLEAR_COHORT = gql`
  mutation ClearCohort($cohortId: ID) {
    clearCohort(cohortId: $cohortId) {
      ok
      error
      cohort {
        id
        clearOnNextCycle
      }
    }
  }
`;

export const MUTATION_UPDATE_COHORT = gql`
  ${FRAGMENT_COHORTS}
  mutation UpdateCohort(
    $active: Boolean
    $aircraftModelId: ID
    $cohortId: ID
    $engineModelId: ID
    $engineParametersId: ID
    $parentCohortId: ID
  ) {
    updateCohort(
      active: $active
      aircraftModelId: $aircraftModelId
      cohortId: $cohortId
      engineModelId: $engineModelId
      engineParametersId: $engineParametersId
      parentCohortId: $parentCohortId
    ) {
      ok
      error
      cohort {
        ...Cohort
      }
    }
  }
`;

export const MUTATION_IGNORE_SUBMITTED_DATA = gql`
  mutation IgnoreUserSubmittedData($dataType: String, $objId: ID) {
    ignoreUserSubmittedData(dataType: $dataType, objId: $objId) {
      ok
      error
    }
  }
`;

export const MUTATION_MARK_USER_SUBMITTED_DATA_AS_DUPLICATE = gql`
  mutation MarkUserSubmittedDataAsDuplicate($dataType: String, $duplicateId: ID, $objId: ID) {
    markUserSubmittedDataAsDuplicate(dataType: $dataType, duplicateId: $duplicateId, objId: $objId) {
      ok
      error
    }
  }
`;

export const MUTATION_ACCEPT_USER_SUBMITTED_DATA = gql`
  mutation AcceptUserSubmittedData($dataType: String, $objId: ID) {
    acceptUserSubmittedData(dataType: $dataType, objId: $objId) {
      ok
      error
    }
  }
`;

export const MUTATION_EDIT_ACCEPT_USER_SUBMITTED_DATA = gql`
  mutation EditAcceptUserSubmittedData($dataType: String, $newName: String, $objId: ID) {
    acceptUserSubmittedData(dataType: $dataType, newName: $newName, objId: $objId) {
      ok
      error
    }
  }
`;

export const MUTATION_DELETE_USER_SUBMITTED_DATA = gql`
  mutation DeleteUserSubmittedData($dataType: String, $objId: ID) {
    deleteUserSubmittedData(dataType: $dataType, objId: $objId) {
      ok
      error
    }
  }
`;

export const MUTATION_CREATE_POWER_MODEL = gql`
  mutation CreatePowerModel(
    $altCompensationMultiplier: Float!
    $compressionRatio: Float!
    $curve1: String
    $curve10: String
    $curve11: String
    $curve12: String
    $curve13: String
    $curve14: String
    $curve15: String
    $curve2: String
    $curve3: String
    $curve4: String
    $curve5: String
    $curve6: String
    $curve7: String
    $curve8: String
    $curve9: String
    $horsePower: Int!
    $hp18RpmMax: Float!
    $hpMax: Int!
    $mapDecay: Float!
    $mapMax: Float!
    $name: String!
    $rpmDecay1: Float!
    $rpmDecay2: Float!
    $rpmMax: Float!
    $rpmRatio: Float!
  ) {
    createPowerModel(
      altCompensationMultiplier: $altCompensationMultiplier
      compressionRatio: $compressionRatio
      curve1: $curve1
      curve10: $curve10
      curve11: $curve11
      curve12: $curve12
      curve13: $curve13
      curve14: $curve14
      curve15: $curve15
      curve2: $curve2
      curve3: $curve3
      curve4: $curve4
      curve5: $curve5
      curve6: $curve6
      curve7: $curve7
      curve8: $curve8
      curve9: $curve9
      horsePower: $horsePower
      hp18RpmMax: $hp18RpmMax
      hpMax: $hpMax
      mapDecay: $mapDecay
      mapMax: $mapMax
      name: $name
      rpmDecay1: $rpmDecay1
      rpmDecay2: $rpmDecay2
      rpmMax: $rpmMax
      rpmRatio: $rpmRatio
    ) {
      ok
      error
      powerModel {
        id
      }
    }
  }
`;

export const MUTATION_UPDATE_POWER_MODEL = gql`
  mutation UpdatePowerModel(
    $altCompensationMultiplier: Float!
    $compressionRatio: Float!
    $curve1: String
    $curve10: String
    $curve11: String
    $curve12: String
    $curve13: String
    $curve14: String
    $curve15: String
    $curve2: String
    $curve3: String
    $curve4: String
    $curve5: String
    $curve6: String
    $curve7: String
    $curve8: String
    $curve9: String
    $horsePower: Int!
    $hp18RpmMax: Float!
    $hpMax: Int!
    $mapDecay: Float!
    $mapMax: Float!
    $name: String!
    $rpmDecay1: Float!
    $rpmDecay2: Float!
    $rpmMax: Float!
    $rpmRatio: Float!
    $powerModelId: ID
  ) {
    updatePowerModel(
      altCompensationMultiplier: $altCompensationMultiplier
      compressionRatio: $compressionRatio
      curve1: $curve1
      curve10: $curve10
      curve11: $curve11
      curve12: $curve12
      curve13: $curve13
      curve14: $curve14
      curve15: $curve15
      curve2: $curve2
      curve3: $curve3
      curve4: $curve4
      curve5: $curve5
      curve6: $curve6
      curve7: $curve7
      curve8: $curve8
      curve9: $curve9
      horsePower: $horsePower
      hp18RpmMax: $hp18RpmMax
      hpMax: $hpMax
      mapDecay: $mapDecay
      mapMax: $mapMax
      name: $name
      rpmDecay1: $rpmDecay1
      rpmDecay2: $rpmDecay2
      rpmMax: $rpmMax
      rpmRatio: $rpmRatio
      powerModelId: $powerModelId
    ) {
      ok
      error
      powerModel {
        id
      }
    }
  }
`;

export const MUTATION_DELETE_POWER_MODEL = gql`
  mutation DeletePowerModel($powerModelId: ID) {
    deletePowerModel(powerModelId: $powerModelId) {
      ok
      error
    }
  }
`;

export const MUTATION_COPY_FLIGHT_REPORT = gql`
  mutation CopyFlightReport($reportId: ID) {
    copyFlightReport(reportId: $reportId) {
      ok
      error
    }
  }
`;

export const MUTATION_SEND_FLIGHT_REPORT = gql`
  mutation SendFlightReport($analystComments: String, $reportId: ID, $snoozeAndClose: Boolean) {
    sendFlightReport(analystComments: $analystComments, reportId: $reportId, snoozeAndClose: $snoozeAndClose) {
      ok
      error
    }
  }
`;

export const MUTATION_DELETE_FLIGHT_REPORT = gql`
  mutation DeleteFlightReport($reportId: ID) {
    deleteFlightReport(reportId: $reportId) {
      ok
      error
    }
  }
`;

export const MUTATION_CREATE_FLIGHT_REPORT = gql`
  ${FRAGMENT_FLIGHT_REPORT}
  mutation CreateFlightReport($analysisTagId: ID, $reports: [CreateFlightReportInput]) {
    createFlightReport(analysisTagId: $analysisTagId, reports: $reports) {
      ok
      error
      report {
        ...FlightReport
      }
    }
  }
`;

export const MUTATION_UPDATE_AIRCRAFT_COMMENT = gql`
  mutation UpdateAircraftComment($comment: String, $commentId: ID, $pinned: Boolean) {
    updateAircraftComment(comment: $comment, commentId: $commentId, pinned: $pinned) {
      ok
      error
      comment {
        id
        pinned
        comment
        date
      }
    }
  }
`;

export const MUTATION_MERGE_AIRCRAFT = gql`
  mutation MergeAircraft($sourceAircraftId: ID, $destinationAircraftId: ID) {
    mergeAircraft(sourceAircraftId: $sourceAircraftId, destinationAircraftId: $destinationAircraftId) {
      ok
      error
      aircraft {
        id
        registration
        serial
        aircraftManufacturer {
          id
          name
        }
        aircraftModel {
          id
          name
        }
        year
        updated
      }
    }
  }
`;

export const MUTATION_DUPLICATE_COHORT_PARAMS = gql`
  mutation DuplicateCohortParams($sourceCohortId: ID, $destinationCohortId: ID) {
    duplicateCohortParams(sourceCohortId: $sourceCohortId, destinationCohortId: $destinationCohortId) {
      ok
      error
    }
  }
`;

export const MUTATION_CREATE_MAINTENANCE_RECORDS_PDF = gql`
  mutation CreateMaintenanceRecordsPDF($shareLogsTagId: ID) {
    createMaintenanceRecordsPdf(shareLogsTagId: $shareLogsTagId) {
      ok
      error
    }
  }
`;

export const MUTATION_CREATE_BACKFILL_MAINTENANCE_ENTRY = gql`
  ${FRAGMENT_MAINTENANCE_ENTRY}
  mutation BackfillMaintenanceEntry($aircraftId: ID, $documentId: ID, $entryDate: DateTime) {
    backfillMaintenanceEntry(aircraftId: $aircraftId, documentId: $documentId, entryDate: $entryDate) {
      ok
      aircraft {
        id
        maintenanceEntries {
          ...MaintenanceEntry
        }
      }
      error
    }
  }
`;

export const MUTATION_CREATE_DEFAULT_SET_OF_LOGBOOKS = gql`
  ${FRAGMENT_AIRCRAFT_LOGBOOK}
  mutation CreateDefaultSetOfLogbooks($aircraftId: ID) {
    createDefaultSetOfLogbooks(aircraftId: $aircraftId) {
      ok
      error
      aircraft {
        id
        logbooks {
          ...Logbook
        }
      }
    }
  }
`;

export const MUTATION_CREATE_DOCUMENT_FROM_S3_PATH = gql`
  mutation CreateDocumentFromS3Path($documentPath: String) {
    createDocumentFromS3Path(documentPath: $documentPath) {
      ok
      error
      document {
        id
      }
    }
  }
`;

export const MUTATION_CREATE_EDF_ARCHIVE = gql`
  mutation CreateEdfArchive($aircraftId: Int) {
    createEdfArchive(aircraftId: $aircraftId) {
      ok
      error
      workflowId
    }
  }
`;

export const MUTATION_CREATE_REPORT_CARD_PARAMETER = gql`
  mutation CreateReportCardParameter(
    $cohortId: ID
    $colorBarReverseStatus: Int
    $name: String
    $outlierLeftStdDevLimit: Float
    $outlierLeftValueLimit: Float
    $outlierRightStdDevLimit: Float
    $outlierRightValueLimit: Float
    $presentationOrder: Int
    $scalingMultiplier: Int
    $specMax: Float
    $specMin: Float
    $titleName: String
    $units: String
  ) {
    createReportCardParameter(
      cohortId: $cohortId
      colorBarReverseStatus: $colorBarReverseStatus
      name: $name
      outlierLeftStdDevLimit: $outlierLeftStdDevLimit
      outlierLeftValueLimit: $outlierLeftValueLimit
      outlierRightStdDevLimit: $outlierRightStdDevLimit
      outlierRightValueLimit: $outlierRightValueLimit
      presentationOrder: $presentationOrder
      scalingMultiplier: $scalingMultiplier
      specMax: $specMax
      specMin: $specMin
      titleName: $titleName
      units: $units
    ) {
      ok
      error
      reportCardParameter {
        id
      }
    }
  }
`;

export const MUTATION_UPDATE_REPORT_CARD_PARAMETER = gql`
  mutation UpdateReportCardParameter(
    $reportCardParamId: ID
    $colorBarReverseStatus: Int
    $name: String
    $outlierLeftStdDevLimit: Float
    $outlierLeftValueLimit: Float
    $outlierRightStdDevLimit: Float
    $outlierRightValueLimit: Float
    $presentationOrder: Int
    $scalingMultiplier: Int
    $specMax: Float
    $specMin: Float
    $titleName: String
    $units: String
  ) {
    updateReportCardParameter(
      reportCardParamId: $reportCardParamId
      colorBarReverseStatus: $colorBarReverseStatus
      name: $name
      outlierLeftStdDevLimit: $outlierLeftStdDevLimit
      outlierLeftValueLimit: $outlierLeftValueLimit
      outlierRightStdDevLimit: $outlierRightStdDevLimit
      outlierRightValueLimit: $outlierRightValueLimit
      presentationOrder: $presentationOrder
      scalingMultiplier: $scalingMultiplier
      specMax: $specMax
      specMin: $specMin
      titleName: $titleName
      units: $units
    ) {
      ok
      error
      reportCardParameter {
        id
        name
        titleName
        outlierLeftValueLimit
        outlierRightValueLimit
        outlierLeftStdDevLimit
        outlierRightStdDevLimit
        specMin
        specMax
        presentationOrder
        scalingMultiplier
        units
        colorBarReverseStatus
      }
    }
  }
`;

export const MUTATION_DELETE_REPORT_CARD_PARAMETER = gql`
  mutation DeleteReportCardParameter($reportCardParamId: ID) {
    deleteReportCardParameter(reportCardParamId: $reportCardParamId) {
      ok
      error
    }
  }
`;

export const MUTATION_CREATE_BORESCOPE_IMAGE_SET = gql`
  mutation CreateBorescopeImageSet($aircraftId: ID, $engine: String, $name: String) {
    createBorescopeImageSet(aircraftId: $aircraftId, engine: $engine, name: $name) {
      ok
      error
      borescopeImageSet {
        id
        name
        engine
        clientComments
      }
    }
  }
`;

export const MUTATION_UPDATE_BORESCOPE_IMAGE_SET = gql`
  mutation UpdateBorescopeImageSet($borescopeImageSetId: ID, $engine: String, $name: String) {
    updateBorescopeImageSet(borescopeImageSetId: $borescopeImageSetId, engine: $engine, name: $name) {
      ok
      error
      borescopeImageSet {
        id
        created
        updated
        name
        engine
        clientComments
        deleted
      }
    }
  }
`;

export const MUTATION_DELETE_BORESCOPE_IMAGE_SET = gql`
  mutation DeleteBorescopeImageSet($borescopeImageSetId: ID) {
    deleteBorescopeImageSet(borescopeImageSetId: $borescopeImageSetId) {
      ok
      error
      borescopeImageSet {
        id
        deleted
      }
    }
  }
`;

export const MUTATION_CREATE_BORESCOPE_IMAGE = gql`
  mutation CreateBorescopeImage($borescopeImageSetId: ID, $filename: String) {
    createBorescopeImage(borescopeImageSetId: $borescopeImageSetId, filename: $filename) {
      ok
      error
      url
      borescopeImage {
        id
        cylinder
        subject
      }
    }
  }
`;

export const MUTATION_UPDATE_BORESCOPE_IMAGE = gql`
  mutation UpdateBorescopeImage($borescopeImageId: ID, $cylinder: Int, $subject: String) {
    updateBorescopeImage(borescopeImageId: $borescopeImageId, cylinder: $cylinder, subject: $subject) {
      ok
      error
      borescopeImage {
        id
        cylinder
        subject
      }
    }
  }
`;

export const MUTATION_DELETE_BORESCOPE_IMAGE = gql`
  mutation DeleteBorescopeImage($borescopeImageId: ID) {
    deleteBorescopeImage(borescopeImageId: $borescopeImageId) {
      ok
      error
    }
  }
`;

export const MUTATION_UPDATE_BORESCOPE_IMAGE_STATUS = gql`
  mutation UpdateBorescopeImageStatus($borescopeImageId: ID, $statuses: [String]) {
    updateBorescopeImageStatus(borescopeImageId: $borescopeImageId, statuses: $statuses) {
      ok
      error
      borescopeImage {
        id
        status
        textualStatus
      }
    }
  }
`;

export const MUTATION_SEND_BORESCOPE_REPORT = gql`
  mutation SendBorescopeReport($borescopeImageSetId: ID, $message: String, $autoCloseTicket: Boolean) {
    sendBorescopeReport(imageSetId: $borescopeImageSetId, message: $message, autoCloseTicket: $autoCloseTicket) {
      ok
      error
      imageSet {
        id
        status
      }
    }
  }
`;

export const MUTATION_CREATE_COMBO_LOGS = gql`
  mutation CreateComboLogs($logbooksIdList: [Int]) {
    createComboLogsPdfForClient(logbooksIdList: $logbooksIdList) {
      ok
      error
      workflowId
    }
  }
`;

export const MUTATION_UPDATE_BORESCOPE_IMAGE_SET_COMMENTS = gql`
  mutation UpdateBorescopeImageSetComments($imageSetId: ID, $comments: String) {
    updateBorescopeImageSetComments(imageSetId: $imageSetId, comments: $comments) {
      ok
      error
      borescopeImageSet {
        id
        comments
        reportCommentsDraft
      }
    }
  }
`;

export const MUTATION_REQUEST_UPDATED_IMAGES = gql`
  mutation RequestUpdatedImages($imageSetId: ID, $post: String) {
    requestUpdatedImages(imageSetId: $imageSetId, post: $post) {
      ok
      error
    }
  }
`;

export const MUTATION_CREATE_AIRCRAFT_ACCESS_PERMISSION = gql`
  mutation CreateOrUpdateAircraftAccessPermission($aircraftId: ID, $email: String, $permissions: [String]) {
    createOrUpdateAircraftAccessPermission(aircraftId: $aircraftId, email: $email, permissions: $permissions) {
      ok
      error
      accessPermission {
        id
        permissions
      }
    }
  }
`;

export const MUTATION_DELETE_AIRCRAFT_ACCESS_PERMISSION = gql`
  mutation DeleteAircraftAccessPermission($accessId: ID) {
    deleteAircraftAccessPermission(accessId: $accessId) {
      ok
      error
    }
  }
`;

export const MUTATION_CLEAR_ALL_BORESCOPE_TAG = gql`
  mutation ClearAllBorescopeTag($imageSetId: ID) {
    clearAllBorescopeTag(imageSetId: $imageSetId) {
      ok
      imageSet {
        id
        images {
          id
          status
        }
        reportCreator {
          id
          firstName
          lastName
        }
        comments
      }
    }
  }
`;

export const MUTATION_COMPLETE_REPORT = gql`
  mutation CompleteBorescopeReport($borescopeImageSetId: ID) {
    completeBorescopeReport(imageSetId: $borescopeImageSetId) {
      ok
      error
      imageSet {
        id
        status
      }
    }
  }
`;
