import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Dialog, DialogContent, DialogActions, Grid, Typography, Button, InputAdornment, IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Formik, Form } from 'formik';
import { FormGroupInput, ButtonSubmit, Subsection } from 'components';
import * as Yup from 'yup';

import { useMutation } from '@apollo/client';
import { MUTATION_CHANGE_PASSWORD } from 'gql';

import { setAlert } from 'state';

interface PasswordDialogProps {
  open: boolean;
  setOpen: (value: boolean) => void;
}

const ValidateFormSchema = Yup.object().shape({
  password: Yup.string().min(8, 'Too short: 8-30 characters required').max(30, 'Too long: 8-30 characters required').required('Required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
    .required('Required'),
  newPassword: Yup.string()
    .min(8, 'Too short: 8-30 characters required')
    .max(30, 'Too long: 8-30 characters required')
    .required('Required'),
});

const ChangePasswordDialog: React.FC<PasswordDialogProps> = (props) => {
  const { open, setOpen } = props;

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  const [changePassword, { data: dataChangePassword, error: errorChangePassword, loading: loadingChangePassword }] = useMutation(
    MUTATION_CHANGE_PASSWORD,
    {
      errorPolicy: 'all',
    },
  );

  const dispatch = useDispatch();

  const onSubmit = async (values: any) => {
    await changePassword({
      variables: {
        currentPassword: values.password,
        newPassword: values.newPassword,
      },
    });
  };

  const [responseError, setResponseError] = useState('');

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (dataChangePassword?.changePassword) {
      const { ok, error } = dataChangePassword.changePassword;

      if (ok) {
        dispatch(setAlert('success', 'Changed Password'));
        setOpen(false);
      } else if (error) {
        setResponseError(error);
        return;
      }
    }
  }, [changePassword, dataChangePassword, dispatch, setOpen]);

  useEffect(() => {
    if (errorChangePassword) {
      setResponseError(errorChangePassword.graphQLErrors[0].message);
      return;
    }
  }, [errorChangePassword]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      fullWidth
    >
      <Formik
        initialValues={{
          password: '',
          confirmPassword: '',
          newPassword: '',
        }}
        onSubmit={onSubmit}
        validationSchema={ValidateFormSchema}
      >
        {({ isSubmitting, handleChange, handleBlur, touched, errors }) => {
          if (responseError && !errors?.password) {
            errors.password = responseError;
          }

          return (
            <Form
              onChange={() => {
                setResponseError('');
              }}
            >
              <DialogContent>
                <Typography
                  variant="h2"
                  gutterBottom
                  align="center"
                  style={{
                    fontWeight: 'bold',
                  }}
                >
                  Change Password
                </Typography>
                <Typography gutterBottom align="center">
                  This form will allow you to change your password
                </Typography>
                <Subsection>
                  <FormGroupInput
                    name="password"
                    label="Old Password *"
                    type={showPassword ? 'text' : 'password'}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    errors={errors}
                    touched={touched}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setShowPassword(!showPassword)}>
                            {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <FormGroupInput
                    name="newPassword"
                    label="New Password *"
                    type={showNewPassword ? 'text' : 'password'}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    errors={errors}
                    touched={touched}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setShowNewPassword(!showNewPassword)}>
                            {showNewPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <FormGroupInput
                    name="confirmPassword"
                    label="Confirm New Password *"
                    type={showConfirmPassword ? 'text' : 'password'}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    errors={errors}
                    touched={touched}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                            {showConfirmPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Typography paragraph>
                    - can't be too similiar to the old password
                    <br />
                    - must contain at least 8 characters
                    <br />- can't be entirely numeric
                  </Typography>
                </Subsection>
              </DialogContent>
              <DialogActions>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Button color="secondary" variant="outlined" onClick={handleClose} fullWidth>
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <ButtonSubmit text="Confirm" loading={isSubmitting || loadingChangePassword} />
                  </Grid>
                </Grid>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default ChangePasswordDialog;
