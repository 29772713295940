import { gql } from '@apollo/client';

export const QUERY_POSTMARK_TEMPLATES = gql`
  query PostmarkTemapltes {
    postmarkTemplates {
      filename
      subject
      templateName
    }
  }
`;

export const QUERY_API_TEMPLATES = gql`
  query APITemapltes {
    apiTemplates {
      filename
      dirname
      templateId
    }
  }
`;
