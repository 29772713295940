import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { AUTH_LOG_IN, AUTH_LOG_OUT, AUTH_MISSING_TOKEN } from 'state/types';

import { useQuery } from '@apollo/client';
import { QUERY_USER_DATA } from 'gql';

const REQUIRE_TOKEN_MSG = 'Need security token';

// Verify auth. Check is the user loggedIn on each page reload. Set CSRF Token
const Auth: React.FC = () => {
  const dispatch = useDispatch();

  // GraphQL
  // Check does the user is loggedIn in Django and have accesss to GraphQL data
  const { data, error } = useQuery(QUERY_USER_DATA, {
    errorPolicy: 'all',
  }); // Try to get user data

  // Fix warning with useEffect: Cannot update a component (`RedirectLoggedIn`) while rendering a different component (`Auth`)
  useEffect(() => {
    // Send Error
    if (error) {
      if (error.graphQLErrors?.length && error.graphQLErrors[0].message === REQUIRE_TOKEN_MSG) {
        dispatch({ type: AUTH_MISSING_TOKEN });
      } else {
        dispatch({ type: AUTH_LOG_OUT });
      }
    }
  }, [error, dispatch]);

  useEffect(() => {
    // Response
    if (data) {
      const { errors } = data;
      // Errors
      if (errors || !data.me) {
        dispatch({ type: AUTH_LOG_OUT }); // Update state
      } else {
        // Success
        dispatch({
          type: AUTH_LOG_IN,
          payload: {
            ...data.me,
          },
        }); // Update state
      }
    }
  }, [data, dispatch]);

  // Return null because we don't render anything, just check loggedIn or not
  return null;
};

export default Auth;
