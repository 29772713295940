import React, { useState, useRef, useEffect } from 'react';
import { Box, TextField, InputAdornment, IconButton, Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  ContactSearch,
  AircraftSearch,
  TicketSearch,
  ServiceCenterSearch,
  SubscriptionSearch,
  FlightReportSearch,
  PrebuyInquirySearch,
} from './components';

const SearchBar: React.FC = () => {
  const [searchText, setSearchText] = useState('');

  const inputRef = useRef<HTMLInputElement>(null);

  const addFocusEvent = (event: any) => {
    if (!event.ctrlKey && !event.metaKey) {
      return;
    }
    if (event.key === '/') {
      event.preventDefault();
      event.stopPropagation();
      inputRef.current && inputRef.current.focus();
    }

    return;
  };

  useEffect(() => {
    document.addEventListener('keydown', addFocusEvent);
    return () => {
      document.removeEventListener('keydown', addFocusEvent);
    };
  }, []);

  return (
    <Box
      sx={{
        position: 'relative',
        width: '500px',
        '@media (max-width: 1024px)': {
          width: '100%',
        },
      }}
    >
      <Box>
        <TextField
          label="Search"
          name="dashboardSearch"
          id="dashboardSearch"
          placeholder="Type text to search"
          value={searchText}
          onChange={(e: any) => {
            setSearchText(e.target.value);
          }}
          sx={{
            mb: 0,
          }}
          inputRef={inputRef}
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {searchText ? (
                  <IconButton
                    aria-label="clear search"
                    onClick={() => {
                      setSearchText('');
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                ) : null}
              </InputAdornment>
            ),
          }}
        />
      </Box>
      {!!searchText && (
        <Box
          sx={{
            position: 'absolute',
            zIndex: 999,
            backgroundColor: 'background.default',
            width: '900px',
            boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
            p: 2,
            left: '50%',
            transform: 'translate(-60%)',
            top: '55px',
            '@media (max-width: 1024px)': {
              width: '90vw',
              left: 0,
              maxHeight: 'calc(100vh - 55px)',
              overflowY: 'auto',
              transform: 'translate(-30vw)',
            },
            '@media (max-width: 750px)': {
              transform: 'translate(-40vw)',
            },
          }}
          onClick={() => {
            setSearchText('');
          }}
        >
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <ContactSearch searchQuery={searchText} />
              <AircraftSearch searchQuery={searchText} />
              <TicketSearch searchQuery={searchText} />
            </Grid>
            <Grid item md={6} xs={12}>
              <ServiceCenterSearch searchQuery={searchText} />
              <SubscriptionSearch searchQuery={searchText} />
              <FlightReportSearch searchQuery={searchText} />
              <PrebuyInquirySearch searchQuery={searchText} />
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default SearchBar;
