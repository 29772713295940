import uniqid from 'uniqid';

import { ALERT_SET, ALERT_REMOVE } from 'state/types';

import { TIMEOUT_HIDE_ALERT } from 'const';

import { IDispatch, IAlertColor, IAlertContent, IAlert } from 'types';

// Alert. Messages for user. For example, "Signed up success"
export const setAlert =
  (color: IAlertColor, content: IAlertContent, customId?: string, hideTime?: number) => (dispatch: IDispatch, getState: any) => {
    const id = customId ? customId : uniqid(); // Use customId if passed. If not passed generate id

    // Prevent Alert Duplicate
    const alerts = getState().alert; // Get alerts from state
    if (alerts.some((alert: IAlert) => alert.id === customId)) return; // Check does Alert exist by customId

    // Show alert
    dispatch({
      type: ALERT_SET,
      payload: { id, color, content },
    });

    // Remove after timeout
    setTimeout(function () {
      dispatch(removeAlert(id));
    }, hideTime || TIMEOUT_HIDE_ALERT);
  };

// Remove alert. On close button click
export const removeAlert = (id: string) => (dispatch: IDispatch) => {
  dispatch({
    type: ALERT_REMOVE,
    payload: { id },
  });
};
