import { SET_FLIGHT_TABLE_CONFIG } from 'state/types';

import { IDispatch, IFlightTableConfig } from 'types';

// Alert. Messages for user. For example, "Signed up success"
export const setFlightTableConfig = (payload: IFlightTableConfig) => (dispatch: IDispatch) => {
  dispatch({
    type: SET_FLIGHT_TABLE_CONFIG,
    payload,
  });
};
