import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';

import { LayoutDashboard, PageLoading, CustomGridToolbar } from 'components';

import { QUERY_FUTURE_COHORTS } from 'gql';
import { useQuery } from '@apollo/client';

import { setAlert } from 'state';

const FutureCohorts: React.FC = () => {
  const { data: dataCohorts, error: errorCohorts, loading: loadingCohorts } = useQuery(QUERY_FUTURE_COHORTS);
  const dispatch = useDispatch();

  const [pageSize, setPageSize] = useState(25);

  const [filterButtonEl, setFilterButtonEl] = useState<HTMLButtonElement | null>(null);

  const [list, setList] = useState<
    {
      id: number;
      aircraft: string;
      engine: string;
      count: number;
      subscriptions: number;
    }[]
  >([]);

  useEffect(() => {
    if (errorCohorts) {
      dispatch(setAlert('error', 'Unable to load future cohorts'));
    }
  }, [errorCohorts, dispatch]);

  useEffect(() => {
    if (dataCohorts?.futureCohorts?.length) {
      const tmp = dataCohorts.futureCohorts.map((item: any, key: number) => {
        return {
          id: key,
          aircraft: item.aircraft,
          engine: item.engine,
          count: item.count,
          subscriptions: item.subscriptions,
        };
      });

      setList(tmp);
    }
  }, [dataCohorts]);

  return (
    <LayoutDashboard backgroundColor="#fff">
      {loadingCohorts && <PageLoading />}
      <Box p={4}>
        <Typography variant="h2" gutterBottom>
          Future Cohorts
        </Typography>
        <Box py={1}>
          <Box py={1}>
            <DataGridPro
              sx={{
                width: '100%',
                minWidth: '100px',
                '& .MuiDataGrid-cell:focus': {
                  outline: 'none',
                },
                '& .MuiDataGrid-row': {
                  cursor: 'pointer',
                  borderBottom: '1px solid',
                  borderColor: 'grey.200',
                },
                '& .MuiDataGrid-columnHeader:focus': {
                  outline: 'none',
                },
                '& .MuiDataGrid-toolbarContainer .MuiTextField-root': {
                  mb: 0,
                },
                border: 'none',
              }}
              autoHeight
              disableSelectionOnClick
              rowsPerPageOptions={[5, 10, 25, 100]}
              pageSize={pageSize}
              onPageSizeChange={(newPage) => {
                setPageSize(newPage);
              }}
              pagination
              showColumnRightBorder={false}
              disableColumnSelector
              disableColumnMenu
              rows={list}
              columns={[
                {
                  field: 'aircraft',
                  headerName: 'Aircraft',
                  flex: 1,
                },
                {
                  field: 'engine',
                  headerName: 'Engine',
                  flex: 1,
                },
                {
                  field: 'count',
                  headerName: 'Aircraft Count',
                  flex: 0.4,
                },
                {
                  field: 'subscriptions',
                  headerName: 'Subscription Count',
                  flex: 0.5,
                },
              ]}
              components={{ Toolbar: CustomGridToolbar }}
              componentsProps={{
                toolbar: {
                  setFilterButtonEl,
                },
                filterPanel: {
                  sx: {
                    '& .MuiDataGrid-filterForm': {
                      '& .MuiTextField-root': {
                        mb: 0,
                      },
                    },
                  },
                },
                panel: {
                  anchorEl: filterButtonEl,
                  placement: 'bottom-end',
                },
              }}
            />
          </Box>
        </Box>
      </Box>
    </LayoutDashboard>
  );
};

export default FutureCohorts;
