import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { Box, Typography, Button, Fade } from '@mui/material';
import { PageLoading, LayoutDashboard } from 'components';

import { PATH_BORESCOPE_UPLOAD, PATH_AIRCRAFT_CREATE } from 'const';

import {
  QUERY_USER_AIRCRAFTS_WITHOUT_SUBSCRIPTION,
  QUERY_ME_AIRCRAFT_CYLINDER_COUNT,
  QUERY_SHARED_AIRCRAFT_WITH_UPLOAD_BORESCOPE_ACCESS,
  QUERY_SHARED_AIRCRAFT_CYLINDER_COUNT,
} from 'gql';
import { useQuery, useLazyQuery } from '@apollo/client';

import { setAlert, storeSelectedAircraft } from 'state';
import { SelectBorescopeImageSet, UploadBorescopeImages, RequestAnalysis } from './components';
import { IBorescopeImageSet, IAircraft } from 'types';

const BorescopeUpload: React.FC = () => {
  const { data: aircraftData, loading, error } = useQuery(QUERY_USER_AIRCRAFTS_WITHOUT_SUBSCRIPTION);
  const { data: dataSharedAircraft, loading: loadingSharedAircraft } = useQuery(QUERY_SHARED_AIRCRAFT_WITH_UPLOAD_BORESCOPE_ACCESS);
  const [fetchCylinderCount, { data: dataCylinderCount }] = useLazyQuery(QUERY_ME_AIRCRAFT_CYLINDER_COUNT);
  const [fetchSharedCylinderCount, { data: dataSharedCylinderCount }] = useLazyQuery(QUERY_SHARED_AIRCRAFT_CYLINDER_COUNT);
  const [selectedAircraft, setSelectedAircraft] = useState<IAircraft | undefined>(undefined);

  const { storedAircraftId } = useSelector((state: any) => state.auth);
  const dispatch = useDispatch();

  const [aircraft, setAircraft] = useState<IAircraft[]>([]);

  const history = useHistory();

  const { id } = useParams<{
    id: string;
  }>();

  const [slideNum, setSlideNum] = useState(0);

  const [borescopeImageSet, setBorescopeImageSet] = useState<IBorescopeImageSet>();

  useEffect(() => {
    if (!id && aircraft.length) {
      if (storedAircraftId) {
        history.push(`${PATH_BORESCOPE_UPLOAD}/${storedAircraftId}`);
        return;
      }

      let existingDefault = false;

      for (let i = 0; i < aircraft.length; i++) {
        if (aircraft[i]?.default) {
          history.push(`${PATH_BORESCOPE_UPLOAD}/${aircraft[i].id}`);
          dispatch(storeSelectedAircraft(aircraft[i].id));
          existingDefault = true;
        }
      }

      if (!existingDefault) {
        dispatch(storeSelectedAircraft(aircraft[0].id));
        history.push(`${PATH_BORESCOPE_UPLOAD}/${aircraft[0].id}`);
      }
    }
  }, [id, aircraft, storedAircraftId, history, dispatch]);

  useEffect(() => {
    if (aircraftData?.me?.aircraft?.length) {
      setAircraft((aircraft: IAircraft[]) => aircraft.concat(aircraftData.me.aircraft));
    }
  }, [aircraftData]);

  useEffect(() => {
    if (dataSharedAircraft?.sharedAircraftWithUploadBorescope?.length) {
      setAircraft((aircraft: IAircraft[]) =>
        aircraft.concat(
          dataSharedAircraft.sharedAircraftWithUploadBorescope.map((item: any) => {
            return {
              ...item.aircraft,
              shared: true,
              permissions: item.permissions,
            };
          }),
        ),
      );
    }
  }, [dataSharedAircraft]);

  useEffect(() => {
    if (error) {
      dispatch(setAlert('error', 'Unable to load aircraft data'));
    }
  }, [error, dispatch]);

  useEffect(() => {
    setSelectedAircraft(undefined);
  }, []);

  useEffect(() => {
    if (selectedAircraft) {
      if (!selectedAircraft.shared)
        fetchCylinderCount({
          variables: {
            id: parseInt(selectedAircraft.id),
          },
        });
      else {
        fetchSharedCylinderCount({
          variables: {
            aircraftId: selectedAircraft.id,
          },
        });
      }
    }
    setSlideNum(0);
  }, [selectedAircraft]);

  return (
    <LayoutDashboard backgroundColor={slideNum === 0 ? '#FFFFFF' : '#F5F6FA'} flexGrow={1}>
      <Box>
        {(loading || loadingSharedAircraft) && <PageLoading />}
        {!loading && loadingSharedAircraft && !aircraft.length && (
          <Box
            py={1}
            sx={{
              textAlign: 'center',
            }}
          >
            <Typography
              sx={{
                fontSize: '20px',
              }}
              gutterBottom
            >
              You have no aircraft.
            </Typography>
            <Button
              variant="contained"
              onClick={() => {
                history.push(PATH_AIRCRAFT_CREATE);
              }}
            >
              Add Aircraft...
            </Button>
          </Box>
        )}
        {!!aircraft.length && (
          <Box>
            {slideNum === 0 && (
              <Fade in={slideNum === 0} unmountOnExit>
                <Box>
                  <SelectBorescopeImageSet
                    aircrafts={aircraft}
                    setSlideNum={setSlideNum}
                    setBorescopeImageSet={setBorescopeImageSet}
                    cylinderCount={
                      !selectedAircraft?.shared
                        ? dataCylinderCount?.me?.aircraft[0]?.cylinderCount || undefined
                        : dataSharedCylinderCount?.sharedAircraftWithUploadBorescope[0]?.aircraft?.cylinderCount || undefined
                    }
                    selectedAircraft={selectedAircraft}
                    setSelectedAircraft={setSelectedAircraft}
                  />
                </Box>
              </Fade>
            )}
            {slideNum === 1 && (
              <Fade in={slideNum === 1} unmountOnExit>
                <Box>
                  {!!borescopeImageSet && selectedAircraft && (
                    <UploadBorescopeImages
                      borescopeImageSet={borescopeImageSet}
                      setSlideNum={setSlideNum}
                      cylinderCount={
                        dataCylinderCount?.me?.aircraft[0]?.cylinderCount ||
                        dataSharedCylinderCount?.sharedAircraftWithUploadBorescope[0]?.aircraft?.cylinderCount ||
                        0
                      }
                      isLoaded={slideNum === 1}
                      selectedAircraft={selectedAircraft}
                    />
                  )}
                </Box>
              </Fade>
            )}
            {slideNum === 2 && (
              <Fade in={slideNum === 2} unmountOnExit>
                <Box>
                  {!!borescopeImageSet && (
                    <RequestAnalysis borescopeImageSet={borescopeImageSet} setSlideNum={setSlideNum} isLoaded={slideNum === 2} />
                  )}
                </Box>
              </Fade>
            )}
          </Box>
        )}
      </Box>
    </LayoutDashboard>
  );
};

export default BorescopeUpload;
