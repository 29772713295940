// Git test
import React, { useState, useEffect } from 'react';

import { Redirect } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { setAlert } from 'state';
import { PAYMENT_SUCCESS, PAYMENT_ERROR, SET_SIGNUP_INFO } from 'state/types';

import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';

import { Typography, Grid, FormHelperText, Link, Box } from '@mui/material';

import { Subsection, ButtonSubmit, ComponentLoading, Checkbox, FormErrorMessage } from 'components';

import { inputPadding, inputBorderColor, borderRadius, errorMain } from 'theme/variables';

import { useCheckbox, useFormError, useService } from 'hooks';

import { logAmplitudeEvent } from 'services';

import { useMutation, useQuery } from '@apollo/client';
import { MUTATION_PURCHASE_SERVICE, QUERY_USER_DATA } from 'gql';

import {
  ACCEPT_AGREEMENT_ERROR_MESSAGE,
  PAYMENT_SUCCESS_MESSAGE,
  SIGNUP_PAGE_LOADED_PAYMENT,
  SIGNUP_PAYMENT_ERROR,
  SIGNUP_PAYMENT_SUCCESS,
} from 'const';

const SignupServicePaymentForm: React.FC = () => {
  const dispatch = useDispatch();

  // const aircraftID = useSelector((state: any) => {
  //   return state.signup.aircraftId
  // });
  const [aircraftId, setAircraftId] = useState('');
  const { data: dataUserData, loading: loadingUserData } = useQuery(QUERY_USER_DATA, {
    fetchPolicy: 'no-cache',
  });

  const service = useService();

  useEffect(() => {
    // Amplitude Page Loaded
    logAmplitudeEvent(SIGNUP_PAGE_LOADED_PAYMENT);
  }, []);

  useEffect(() => {
    if (dataUserData && dataUserData.me) {
      const { me } = dataUserData;
      if (me?.incompleteAircraft.length) {
        setAircraftId(me.incompleteAircraft[0].id);
      }
    }
  }, [dataUserData, loadingUserData]);

  const [createPurchase, { data: dataCreatePurchase, loading: loadingCreatePurchase, error: errorCreatePurchase }] = useMutation(
    MUTATION_PURCHASE_SERVICE,
    {
      errorPolicy: 'all',
    },
  );

  // Stripe Hooks
  const stripe = useStripe();
  const elements = useElements();

  // Stripe.js has not loaded yet. Make sure to disable form submission until Stripe.js has loaded.
  const stripeLoading = !stripe || !elements;

  const CARD_ELEMENT_OPTIONS = {
    style: {
      invalid: {
        color: errorMain,
      },
    },
  };

  // Agreement Checkbox
  const { value: valueAgreeEdas, error: errorAgreeEdas, setError: setErrorAgreeEdas, onChange: onChangeAgreeEdas } = useCheckbox();

  // Form states
  const [errorStripe, setErrorStripe] = useState('');
  const [loading, setLoading] = useState(false);
  const [stepCompleted, setStepCompleted] = useState(false);

  // Input Change
  const onChangeStripe = () => {
    setErrorStripe(''); // Clear error
  };

  // Form error. For Component UI
  const formError = useFormError([errorStripe, errorAgreeEdas]);

  // Submit
  const onSubmit = async (e: any) => {
    e.preventDefault(); // Prevent native send
    setErrorStripe(''); // Clear error

    // For onSubmit function. React useState/setState doesn't prevent sending. Plain variable used
    let formErrorFlg = false;

    // Prevent Typescript errors if Stripe still loading
    if (stripe === null || elements === null) return;

    // Card Element
    const cardElement = elements.getElement(CardElement);

    // Prevent Typescript errors if Stripe still loading
    if (cardElement === null) return;

    // Check Agreement Checkbox
    if (!valueAgreeEdas) {
      setErrorAgreeEdas(ACCEPT_AGREEMENT_ERROR_MESSAGE);
      formErrorFlg = true;
    }

    // Stop if at least one error
    if (formErrorFlg) return;

    setLoading(true);

    // Run Stripe Payment
    const { error, token } = await stripe.createToken(cardElement);

    // Send to GraphQL
    // aircraft_id, credit card id...

    if (error) {
      // Error
      const { message } = error;
      message && setErrorStripe(message);
      dispatch({ type: PAYMENT_ERROR });
      logAmplitudeEvent(SIGNUP_PAYMENT_ERROR);
      setLoading(false);
    } else if (token) {
      setLoading(false);

      if (service?.id && aircraftId) {
        await createPurchase({
          variables: {
            serviceCode: service?.id,
            aircraftId: aircraftId,
            stripeToken: token.id,
          },
        });
      } else {
        setErrorStripe(
          "Sorry, but we're unable to complete this transaction. Please reload the page and try again.  You may need to enter your credentials to login.",
        );
        return;
      }
    }
  };

  useEffect(() => {
    if (errorCreatePurchase) {
      const { graphQLErrors } = errorCreatePurchase;
      if (graphQLErrors) {
        setErrorStripe(graphQLErrors[0].message);
      }
      dispatch({ type: PAYMENT_ERROR });
    } else if (dataCreatePurchase) {
      const { purchaseService, errors, error } = dataCreatePurchase;

      if (errors) {
        dispatch({ type: PAYMENT_ERROR });
        return;
      }

      if (purchaseService.ok) {
        dispatch(setAlert('success', PAYMENT_SUCCESS_MESSAGE));
        dispatch({ type: PAYMENT_SUCCESS });

        logAmplitudeEvent(SIGNUP_PAYMENT_SUCCESS);
        dispatch({
          type: SET_SIGNUP_INFO,
          payload: {
            aircraftId: aircraftId,
            subscriptionId: purchaseService.subscription.id,
          },
        });

        setStepCompleted(true);
      } else {
        dispatch(setAlert('error', error));
      }
    }
  }, [createPurchase, loadingCreatePurchase, dataCreatePurchase, errorCreatePurchase, dispatch, aircraftId]);

  // Redirect to Additioanal Info step
  if (stepCompleted)
    return (
      <Redirect
        to={`/signup/${service?.id}/additional-info?category=${dataUserData?.me?.incompleteAircraft[0]?.aircraftModel?.propulsion?.toLowerCase()}-${dataUserData?.me?.incompleteAircraft[0]?.aircraftModel?.enginesCount?.toLowerCase()}`}
      />
    );

  return (
    <ComponentLoading loading={stripeLoading}>
      <Subsection>
        <Typography paragraph align="center">
          Enter your card details to complete the purchase. We don't share your data with third parties.
        </Typography>
      </Subsection>

      <form onSubmit={(e) => onSubmit(e)}>
        <Subsection>
          <Box
            sx={{
              '& .StripeElement': {
                padding: inputPadding,
                border: `${inputBorderColor} 1px solid`,
                borderRadius: `${borderRadius}px`,
                '&.StripeElement--invalid': {
                  borderColor: errorMain,
                  color: errorMain,
                },
              },
            }}
          >
            <CardElement options={CARD_ELEMENT_OPTIONS} onChange={onChangeStripe} />
          </Box>
          {errorStripe && <FormHelperText error>{errorStripe}</FormHelperText>}
        </Subsection>

        <Subsection>
          <Checkbox
            value={valueAgreeEdas}
            error={errorAgreeEdas}
            label={
              <Typography>
                I accept the terms and conditions of the{' '}
                <Link
                  href="https://www.savvyaviation.com/master-service-agreement"
                  download="savvy-aviation-master-service-agreement.pdf"
                  target="_blank"
                >
                  SavvyAviation Master Services Agreement
                </Link>
                . A copy of the agreement will be emailed to you.
              </Typography>
            }
            onChange={(e) => onChangeAgreeEdas(e)}
          />
        </Subsection>

        <Subsection>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} sm={6}>
              <ButtonSubmit
                text="Pay and Continue"
                loading={loading || loadingCreatePurchase}
                disabled={loading || loadingCreatePurchase}
              />
              {formError && <FormErrorMessage />}
            </Grid>
          </Grid>
        </Subsection>
      </form>
    </ComponentLoading>
  );
};

export default SignupServicePaymentForm;
