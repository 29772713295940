import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Typography, Button } from '@mui/material';
import { DataGridPro, GridSelectionModel } from '@mui/x-data-grid-pro';

import { LayoutDashboard, ComponentLoading, CustomGridToolbar } from 'components';

import { QUERY_API_TEMPLATES, MUTATION_TEST_API_TEMPLATES } from 'gql';
import { useLazyQuery, useMutation } from '@apollo/client';

import { setAlert } from 'state';

const APItemplate: React.FC = () => {
  const [fetchTemplates, { data: dataTemplates, error: errorTemplates, loading: loadingTemplates }] = useLazyQuery(QUERY_API_TEMPLATES, {
    fetchPolicy: 'cache-and-network',
  });
  const [testTemplates, { data: dataTest, loading: loadingTest, error: errorTest }] = useMutation(MUTATION_TEST_API_TEMPLATES);
  const dispatch = useDispatch();

  const [filterButtonEl, setFilterButtonEl] = useState<HTMLButtonElement | null>(null);
  const [selectionModel, setSelectionModel] = React.useState<GridSelectionModel>([]);

  useEffect(() => {
    if (errorTemplates) {
      dispatch(setAlert('error', 'Unable to load templates'));
    }
  }, [errorTemplates, dispatch]);

  useEffect(() => {
    if (errorTest) {
      dispatch(setAlert('error', 'Unable to test templates'));
    } else if (dataTest) {
      dispatch(setAlert('success', 'Success'));
      if (dataTest.testApiTemplate?.downloadUrls?.length > 0) {
        dataTest.testApiTemplate.downloadUrls.forEach((element: string) => {
          window.open(element, '_blank');
        });
      }
    }
  }, [errorTest, dataTest, dispatch]);

  useEffect(() => {
    fetchTemplates();
  }, [fetchTemplates]);

  const onTest = async (templateIds: Array<any>) => {
    await testTemplates({
      variables: {
        templateIds,
      },
    });
  };

  return (
    <LayoutDashboard backgroundColor="#fff">
      <Box p={4}>
        <Typography variant="h2" gutterBottom>
          API Templates
        </Typography>
        <Box py={1}>
          {selectionModel.length > 0 && (
            <Button
              variant="contained"
              disabled={loadingTest}
              onClick={() => {
                onTest(selectionModel);
              }}
            >
              Selected Templates Test
            </Button>
          )}
        </Box>
        <Box py={1}>
          <ComponentLoading loading={loadingTemplates}>
            <Box py={1}>
              <DataGridPro
                sx={{
                  width: '100%',
                  minWidth: '100px',
                  '& .MuiDataGrid-cell:focus': {
                    outline: 'none',
                  },
                  '& .MuiDataGrid-row': {
                    cursor: 'pointer',
                    borderBottom: '1px solid',
                    borderColor: 'grey.200',
                  },
                  '& .MuiDataGrid-columnHeader:focus': {
                    outline: 'none',
                  },
                  '& .MuiDataGrid-toolbarContainer .MuiTextField-root': {
                    mb: 0,
                  },
                  border: 'none',
                }}
                autoHeight
                checkboxSelection
                onSelectionModelChange={(newSelectionModel) => {
                  setSelectionModel(newSelectionModel);
                }}
                selectionModel={selectionModel}
                rowsPerPageOptions={
                  dataTemplates?.apiTemplates?.length && dataTemplates.apiTemplates.length > 100
                    ? [5, 10, 25, dataTemplates.apiTemplates.length]
                    : [5, 10, 25, 100]
                }
                autoPageSize
                showColumnRightBorder={false}
                disableColumnSelector
                disableColumnMenu
                rows={dataTemplates?.apiTemplates?.length ? dataTemplates.apiTemplates : []}
                getRowId={(row) => row.templateId}
                columns={[
                  {
                    field: 'filename',
                    headerName: 'File Name',
                    flex: 0.3,
                  },
                  {
                    field: 'dirname',
                    headerName: 'Directory',
                    flex: 1,
                  },
                  {
                    field: 'templateId',
                    headerName: 'Template ID',
                    flex: 1,
                  },
                ]}
                components={{ Toolbar: CustomGridToolbar }}
                componentsProps={{
                  toolbar: {
                    setFilterButtonEl,
                  },
                  filterPanel: {
                    sx: {
                      '& .MuiDataGrid-filterForm': {
                        '& .MuiTextField-root': {
                          mb: 0,
                        },
                      },
                    },
                  },
                  panel: {
                    anchorEl: filterButtonEl,
                    placement: 'bottom-end',
                  },
                }}
              />
            </Box>
          </ComponentLoading>
        </Box>
      </Box>
    </LayoutDashboard>
  );
};

export default APItemplate;
