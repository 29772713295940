import * as Yup from 'yup';

const ReportCardParameterSchema = Yup.object().shape({
  colorBarReverseStatus: Yup.number().integer(),
  name: Yup.string().max(30, 'Too long: 1-30 characters required'),
  outlierLeftStdDevLimit: Yup.number(),
  outlierLeftValueLimit: Yup.number(),
  outlierRightStdDevLimit: Yup.number(),
  outlierRightValueLimit: Yup.number(),
  presentationOrder: Yup.number().integer(),
  scalingMultiplier: Yup.number().integer(),
  specMax: Yup.number(),
  specMin: Yup.number(),
  titleName: Yup.string().max(40, 'Too long: 1-40 characters required'),
  units: Yup.string().max(10, 'Too long: 1-10 characters required'),
});

export default ReportCardParameterSchema;
