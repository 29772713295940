import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography, Popover, Autocomplete, TextField } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { ISerieSet } from 'types';
import { limitSerieSet, getSeriesOrderIndex, getRenderSerieName } from 'helpers';

import { useMutation } from '@apollo/client';
import { MUTATION_UPDATE_AIRCRAFT_CHART_SETTINGS } from 'gql';

interface SelectSeriesProps {
  serie: ISerieSet | undefined;
  setSerie: (value: ISerieSet) => void;
  seriesList: ISerieSet[];
  cylinderCount: number | undefined;
  aircraftId: string;
  chartNumber: number;
  seriesSelector: string;
  setPresetMode?: (value: string) => void;
}

const SelectSeries: React.FC<SelectSeriesProps> = (props) => {
  const { serie, setSerie, seriesList, cylinderCount, aircraftId, chartNumber, seriesSelector, setPresetMode } = props;

  const SELECTOR_ID = 'series-selector';

  const [updateAircraftChartSettings] = useMutation(MUTATION_UPDATE_AIRCRAFT_CHART_SETTINGS);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const { loggedIn } = useSelector((state: any) => state.auth);

  const [openOnFocus, setOpenOnFocus] = useState(false);

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const selectSerie = async (value: ISerieSet) => {
    if (loggedIn && !!aircraftId) {
      await updateAircraftChartSettings({
        variables: {
          aircraftId,
          chartNumber,
          seriesName: value.name,
          seriesSelector,
        },
      });
    }

    if (setPresetMode) {
      setPresetMode('');
    }

    setSerie(limitSerieSet(value, cylinderCount));
    handleClose();
  };

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setOpenOnFocus(true);
      }, 200);
    } else {
      setOpenOnFocus(false);
    }
  }, [open]);

  return (
    <React.Fragment>
      <Box
        px={2}
        sx={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
        }}
        onClick={handleClick}
      >
        <Typography
          sx={{
            color: 'text.secondary',
            fontWeight: 'bold',
            fontSize: '13px',
          }}
        >
          {serie ? getRenderSerieName(serie) : 'Select'}
        </Typography>
        <ArrowDropDownIcon
          sx={{
            color: 'text.secondary',
            width: '15px',
            height: '15px',
          }}
        />
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{
          id: SELECTOR_ID,
          sx: {
            width: '200px',
          },
        }}
      >
        <Box py={open ? 1 : 0}>
          <Autocomplete
            options={[...seriesList].sort((seriesSet1, seriesSet2) => {
              if (getSeriesOrderIndex(seriesSet1.name) !== getSeriesOrderIndex(seriesSet2.name)) {
                return getSeriesOrderIndex(seriesSet1.name) - getSeriesOrderIndex(seriesSet2.name);
              }

              return seriesSet1.name < seriesSet2.name ? -1 : 1;
            })}
            getOptionLabel={(option: ISerieSet) => getRenderSerieName(option)}
            isOptionEqualToValue={(option: ISerieSet, value: ISerieSet) => option.name === value.name}
            value={serie}
            onChange={(event: any, value: ISerieSet | null) => {
              if (value) selectSerie(value);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select series"
                placeholder="Type sereis name"
                fullWidth
                sx={{
                  mb: 0,
                }}
                autoFocus={open}
              />
            )}
            renderOption={(props: any, option: ISerieSet) => {
              return (
                <li {...props} key={option.name}>
                  {getRenderSerieName(option)}
                </li>
              );
            }}
            open={openOnFocus}
          />
        </Box>
      </Popover>
    </React.Fragment>
  );
};

export default SelectSeries;
