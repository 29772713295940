import React, { useState, useEffect } from 'react';
import { Box, Collapse, Typography, IconButton, Link, TextField, InputAdornment, Popover, List, ListItem } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SearchIcon from '@mui/icons-material/Search';
import { ISavvyTicket, ITicket, IExsitingFiles, IAttachment } from 'types';
import { ComponentLoading } from 'components';

import { QUERY_ATTACHMENT_DETAIL, QUERY_ATTACHMENT_DOWNLOAD_URL, QUERY_SAVVY_TICKET_AIRCRAFT_ATTACHMENT } from 'gql';
import { useLazyQuery } from '@apollo/client';

import { PdfDialog, ImgDialog } from 'pages/Tickets/components/ContentComponent';

import { SET_EXISTING_FILES } from 'state/types';

import { default as CreateCoiFromAttachment } from './CreateCoiFromAttachment';
import { default as CreateLogFromAttachment } from './CreateLogFromAttachment';
import { default as ParseFlightsFromAttachment } from './ParseFlightsFromAttachment';
import { default as AttachmentDescriptionDialog } from 'pages/SavvyAircraft/components/Tabs/AttachmentDescriptionDialog';

import { isHeic, downloadFileInNewTab } from 'helpers';
import { apolloClient } from 'services';
import { setAlert } from 'state';

interface AttachmentsListProps {
  ticket: ISavvyTicket | ITicket;
}

const AttachmentsList: React.FC<AttachmentsListProps> = (props) => {
  const { ticket } = props;
  const [fetchAttachmentsList, { data: dataAttachmentsList, loading: loadingAttachmentsList }] = useLazyQuery(
    QUERY_SAVVY_TICKET_AIRCRAFT_ATTACHMENT,
    {
      fetchPolicy: 'cache-and-network',
    },
  );
  const [fetchAttachmentData, { data: dataAttachmentData, loading: loadingAttachmentData, error: errorAttachmentData }] =
    useLazyQuery(QUERY_ATTACHMENT_DETAIL);

  const [showList, setShowList] = useState(false);

  const [attachments, setAttachments] = useState<IAttachment[]>([]);

  const [showPdfFileDialog, setPdfShowFileDialog] = useState(false);
  const [showImgFileDialog, setImgShowFileDialog] = useState(false);
  const [showFile, setShowFile] = useState('');
  const [showFileName, setShowFileName] = useState('');
  const [previewAttachmentId, setPreviewAttachmentId] = useState('');

  const [searchAttachment, setSearchAttachment] = useState('');

  const [showCoi, setShowCoi] = useState(false);
  const [attachmentId, setAttachmentId] = useState('');
  const [attachmentName, setAttachmentName] = useState('');
  const [showMaintenanceEntry, setShowMaintenanceEntry] = useState(false);

  const [documentId, setDocumentId] = useState('');
  const [showParseDialog, setShowParseDialog] = useState(false);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const showPopover = Boolean(anchorEl);
  const id = showPopover ? 'simple-popover' : undefined;

  const [showAttachmentDialog, setShowAttachmentDialog] = useState(false);

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedAttachment(undefined);
  };

  const [selectedAttachment, setSelectedAttachment] = useState<IAttachment>();

  const dispatch = useDispatch();
  const attachExistingFiles = useSelector((state: any) => {
    return state.ticket.attachExistingFiles;
  });

  const setAttachExistingFiles = (attachExistingFiles: IExsitingFiles[]) => {
    dispatch({
      type: SET_EXISTING_FILES,
      payload: {
        attachExistingFiles,
      },
    });
  };

  const openAndHideCollpase = async () => {
    if (!showList) {
      await fetchAttachmentsList({
        variables: {
          ticketId: parseInt(ticket.id),
        },
      });

      setShowList(true);
    } else {
      setShowList(false);
    }
  };

  useEffect(() => {
    if (dataAttachmentsList?.me?.tickets[0]?.aircraft?.attachments?.length) {
      const sortedAttachments = [...dataAttachmentsList.me.tickets[0].aircraft.attachments].sort((a: IAttachment, b: IAttachment) => {
        return a.created > b.created ? -1 : 1;
      });
      setAttachments([...sortedAttachments]);
    } else if (ticket.posts.length) {
      const attachmentsTmp: IAttachment[] = [];

      for (let i = 0; i < ticket.posts.length; i++) {
        if (!ticket.posts[i].attachments) continue;

        if (ticket.posts[i].attachments?.length) {
          ticket.posts[i].attachments?.map((attachment: any) => {
            attachmentsTmp.push({
              ...attachment,
              postId: ticket.posts[i].id,
            });
            return null;
          });
        }
      }
      const sortedAttachments = attachmentsTmp.sort((a: IAttachment, b: IAttachment) => {
        return a.created > b.created ? -1 : 1;
      });

      setAttachments([...sortedAttachments]);
    }
  }, [ticket, dataAttachmentsList]);

  const loadAttachmentData = async (attachmentId: string) => {
    setPreviewAttachmentId(attachmentId);
    if (dataAttachmentData?.savvy?.attachment?.id === attachmentId) {
      const attachment = dataAttachmentData?.savvy?.attachment;
      if (isHeic(attachment.name)) {
        if (!attachment.base64Data) {
          dispatch(setAlert('error', 'Unable to load image'));
          return;
        }
        setPdfShowFileDialog(false);
        setImgShowFileDialog(true);
        setShowFile(attachment.base64Data);
        setShowFileName(attachment.name);
        return;
      }
    }
    await fetchAttachmentData({
      variables: {
        attachmentId,
      },
      fetchPolicy: 'network-only',
    });
  };

  const [loadingDownloadUrl, setLoadingDownloadUrl] = useState(false);

  const downloadAttachment = async (attachmentId: string) => {
    if (attachmentId) {
      setLoadingDownloadUrl(true);
      const { data: dataDownloadUrl } = await apolloClient.query({
        query: QUERY_ATTACHMENT_DOWNLOAD_URL,
        variables: {
          attachmentId,
        },
        fetchPolicy: 'no-cache',
      });
      setLoadingDownloadUrl(false);
      if (dataDownloadUrl?.savvy?.attachment?.downloadUrl) {
        downloadFileInNewTab(dataDownloadUrl?.savvy?.attachment?.downloadUrl);
      }
    }
  };

  const nextAttachmentLoad = (attachmentId: string) => {
    for (let i = 0; i < attachments.length - 1; i++) {
      if (attachmentId === attachments[i].id) {
        loadAttachmentData(attachments[i + 1].id);
      }
    }

    return;
  };

  const prevAttachmentLoad = (attachmentId: string) => {
    for (let i = 1; i < attachments.length; i++) {
      if (attachmentId === attachments[i].id) {
        loadAttachmentData(attachments[i - 1].id);
      }
    }

    return;
  };

  useEffect(() => {
    if (errorAttachmentData) {
      return;
    } else if (dataAttachmentData) {
      if (dataAttachmentData?.savvy?.attachment?.previewUrl) {
        const attachment = dataAttachmentData?.savvy?.attachment;
        if (attachment.isImage) {
          setImgShowFileDialog(true);
          setPdfShowFileDialog(false);
        } else if (attachment.isPdf) {
          setPdfShowFileDialog(true);
          setImgShowFileDialog(false);
        }
        if (isHeic(attachment.name)) {
          if (!attachment.base64Data) {
            dispatch(setAlert('error', 'Unable to load image'));
            return;
          }
          setShowFile(attachment.base64Data);
        } else {
          setShowFile(attachment.previewUrl);
        }
        setShowFileName(attachment.name);
      }
    }
  }, [dataAttachmentData, errorAttachmentData, setImgShowFileDialog, setPdfShowFileDialog, setShowFile, setShowFileName]);

  const addExistingFile = (id: string, name: string) => {
    const addedFile = attachExistingFiles.filter((file: IExsitingFiles) => file.id === id);

    if (addedFile && addedFile.length) {
      return;
    }

    attachExistingFiles.push({
      id,
      name,
    });

    setAttachExistingFiles([...attachExistingFiles]);
  };

  return (
    <Box>
      <ComponentLoading loading={loadingAttachmentData || loadingDownloadUrl || loadingAttachmentsList}>
        <Box
          sx={{
            py: 2,
            width: '100%',
            px: {
              xs: 2,
              sm: 3,
              md: 4,
              lg: 5,
            },
            background: 'rgba(245, 246, 250, 0.5)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            cursor: 'pointer',
          }}
          onClick={openAndHideCollpase}
        >
          <Typography
            sx={{
              color: 'text.secondary',
              fontWeight: 700,
              fontSize: '18px',
              lineHeight: '25px',
            }}
          >
            Attachments{ticket?.aircraft ? ` (${ticket.aircraft.registration})` : ''}
          </Typography>
          <IconButton>{showList ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}</IconButton>
        </Box>
        {attachments.length !== 0 && (
          <Collapse in={showList}>
            <Box py={1}>
              <Box
                sx={{
                  py: 1,
                  px: {
                    xs: 2,
                    sm: 3,
                    md: 4,
                    lg: 5,
                  },
                }}
              >
                <TextField
                  label="Search"
                  name="search"
                  value={searchAttachment}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setSearchAttachment(e.target.value);
                  }}
                  sx={{
                    mb: 0,
                  }}
                />
              </Box>
              <Box
                sx={{
                  width: '100%',
                  px: {
                    xs: 2,
                    sm: 3,
                    md: 4,
                    lg: 5,
                  },
                }}
              >
                {attachments.map((attachment: IAttachment, key: number) => {
                  if (searchAttachment !== '' && !attachment.name.toLowerCase().includes(searchAttachment.toLowerCase())) {
                    return null;
                  }
                  return (
                    <Box
                      key={key}
                      py={1}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography py={1} key={key}>
                        <Link
                          href={attachment.isImage || attachment.isPdf ? '#' : attachment.downloadUrl}
                          onClick={(e: any) => {
                            if (attachment.isImage || attachment.isPdf) {
                              e.preventDefault();
                              e.stopPropagation();
                              loadAttachmentData(attachment.id);
                            }
                          }}
                        >
                          {attachment.name}
                        </Link>
                        {attachment.description ? ` (${attachment.description})` : ''}
                      </Typography>
                      <IconButton
                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                          setAnchorEl(e.currentTarget);
                          setSelectedAttachment(attachment);
                        }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </Collapse>
        )}
      </ComponentLoading>
      <PdfDialog
        showFileDialog={showPdfFileDialog}
        setShowFileDialog={setPdfShowFileDialog}
        showFileName={showFileName}
        showFile={showFile}
        previewAttachmentId={previewAttachmentId}
        nextAttachmentLoad={nextAttachmentLoad}
        prevAttachmentLoad={prevAttachmentLoad}
        loadingAttachmentData={loadingAttachmentData}
      />
      <ImgDialog
        showFileDialog={showImgFileDialog}
        setShowFileDialog={setImgShowFileDialog}
        showFileName={showFileName}
        showFile={showFile}
        previewAttachmentId={previewAttachmentId}
        nextAttachmentLoad={nextAttachmentLoad}
        prevAttachmentLoad={prevAttachmentLoad}
        loadingAttachmentData={loadingAttachmentData}
      />
      <CreateCoiFromAttachment
        open={showCoi}
        setOpen={setShowCoi}
        attachmentId={attachmentId}
        attachmentName={attachmentName}
        aircraftId={ticket?.aircraft?.id ? ticket?.aircraft?.id : ''}
        ticketId={ticket.id}
      />
      <CreateLogFromAttachment
        open={showMaintenanceEntry}
        setOpen={setShowMaintenanceEntry}
        attachmentId={attachmentId}
        aircraftId={ticket?.aircraft?.id ? ticket?.aircraft?.id : ''}
        fileName={attachmentName}
        ticketId={ticket.id}
      />
      <ParseFlightsFromAttachment
        open={showParseDialog}
        setOpen={setShowParseDialog}
        documentId={documentId}
        fileName={attachmentName}
        ticketId={ticket.id}
        aircraftId={ticket?.aircraft?.id ? ticket?.aircraft?.id : ''}
        registration={ticket?.aircraft?.registration ? ticket?.aircraft.registration : ''}
      />
      {selectedAttachment && (
        <AttachmentDescriptionDialog open={showAttachmentDialog} setOpen={setShowAttachmentDialog} attachment={selectedAttachment} />
      )}
      <Popover
        id={id}
        open={showPopover}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
      >
        <List component="div">
          <ListItem
            ContainerComponent="div"
            button
            onClick={() => {
              if (!selectedAttachment) return;

              downloadAttachment(selectedAttachment.id);
              handleClose();
            }}
          >
            Download
          </ListItem>
          <ListItem
            ContainerComponent="div"
            button
            onClick={() => {
              if (!selectedAttachment) return;

              addExistingFile(selectedAttachment.id, selectedAttachment.name);
              handleClose();
            }}
          >
            Attach again
          </ListItem>
          <ListItem
            ContainerComponent="div"
            button
            onClick={() => {
              if (!selectedAttachment) return;

              setShowCoi(true);
              setAttachmentId(selectedAttachment.id);
              setAttachmentName(selectedAttachment.name);
              handleClose();
            }}
          >
            Create COI
          </ListItem>
          <ListItem
            ContainerComponent="div"
            button
            onClick={() => {
              if (!selectedAttachment) return;

              setShowMaintenanceEntry(true);
              setAttachmentId(selectedAttachment.id);
              setAttachmentName(selectedAttachment.name);
              handleClose();
            }}
          >
            Create Maintenance Record
          </ListItem>
          <ListItem
            ContainerComponent="div"
            button
            onClick={() => {
              if (!selectedAttachment) return;

              setShowParseDialog(true);
              setDocumentId(selectedAttachment.document.id);
              setAttachmentName(selectedAttachment.name);
              handleClose();
            }}
          >
            Upload as Engine Data File
          </ListItem>
          <ListItem
            ContainerComponent="div"
            button
            onClick={() => {
              if (!selectedAttachment) return;

              setShowAttachmentDialog(true);
              setAnchorEl(null);
            }}
          >
            Attachment Description
          </ListItem>
        </List>
      </Popover>
    </Box>
  );
};

export default AttachmentsList;
