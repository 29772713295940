import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Dialog, Box, Typography, DialogContent, DialogTitle, TextField, Button, MenuItem, Grid, Link, Tooltip } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';

import { ComponentLoading } from 'components';
import { IOptionValueLabel, ILessExpensiveOption } from 'types';
import { setAlert } from 'state';

import { MUTATION_CANCEL_SUBSCRIPTION, MUTATION_CREATE_NOTHAPPY_TICKET_FOR_CANCELATION } from 'gql';
import { useMutation } from '@apollo/client';

interface DoNotRenewDialogProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  subscriptionId: string;
  registration: string;
  setOpenTransferAircraft: (value: boolean) => void;
  setOpenTransferContact: (value: boolean) => void;
  lessExpensiveOptions: ILessExpensiveOption[];
}

const REASON_ARRAY: IOptionValueLabel[] = [
  {
    label: 'Sold airplane',
    value: 'sold_aircraft',
  },
  {
    label: 'Lost medical',
    value: 'lost_medical',
  },
  {
    label: 'Too expensive',
    value: 'too_expensive',
  },
  {
    label: 'I am not happy with the service',
    value: 'not_happy',
  },
  {
    label: "Don't need service anymore",
    value: 'dont_need',
  },
  {
    label: 'Other',
    value: 'custom',
  },
];

const DoNotRenewDialog: React.FC<DoNotRenewDialogProps> = (props) => {
  const { open, setOpen, subscriptionId, registration, setOpenTransferAircraft, setOpenTransferContact, lessExpensiveOptions } = props;

  const [cancelSubscription, { data: dataCancelSubscription, error: errorCancelSubscription, loading: loadingCancelSubscription }] =
    useMutation(MUTATION_CANCEL_SUBSCRIPTION);
  const [createNotHappyTicket, { data: dataNotHappyTicket, error: errorNotHappyTicket, loading: loadingNotHappyTicket }] = useMutation(
    MUTATION_CREATE_NOTHAPPY_TICKET_FOR_CANCELATION,
  );

  const dispatch = useDispatch();

  const [reason, setReason] = useState('');
  const [note, setNote] = useState('');

  const [reasonError, setReasonError] = useState('');

  const [reasonList, setReasonList] = useState<IOptionValueLabel[]>([]);

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async () => {
    if (!reason) {
      setReasonError('Please select the reason');
      return;
    }

    await cancelSubscription({
      variables: {
        reason,
        subscriptionId,
        note: hasNote(reason) ? note : '',
      },
    });
  };

  const hasNote = (value: string) => {
    if (value === 'dont_need' || value === 'custom' || value === 'not_happy') return true;

    return false;
  };

  const handleTicket = async () => {
    await createNotHappyTicket({
      variables: {
        subscriptionId,
        note,
      },
    });
  };

  const handleTransferAircraft = () => {
    setOpenTransferAircraft(true);
    setOpen(false);
  };

  const handleTransferContact = () => {
    setOpenTransferContact(true);
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      setReasonList((reasonList: IOptionValueLabel[]) => {
        reasonList = [...REASON_ARRAY];
        let currentIndex = 5,
          randomIndex = 0;

        while (currentIndex !== 0) {
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex--;
          [reasonList[currentIndex], reasonList[randomIndex]] = [reasonList[randomIndex], reasonList[currentIndex]];
        }

        return [...reasonList];
      });

      setReason('');
      setReasonError('');
      setNote('');
    }
  }, [open, setReasonList]);

  useEffect(() => {
    if (errorCancelSubscription) {
      dispatch(setAlert('error', 'Unable to cancel this subscription'));
    } else if (dataCancelSubscription) {
      if (dataCancelSubscription.cancelSubscription?.ok) {
        dispatch(setAlert('success', 'Cancelled your subscription'));
        setOpen(false);
      } else {
        dispatch(setAlert('error', dataCancelSubscription.cancelSubscription?.error || 'Unable to cancel this subscription'));
      }
    }
  }, [errorCancelSubscription, dataCancelSubscription, dispatch, setOpen]);

  useEffect(() => {
    if (errorNotHappyTicket) {
      dispatch(setAlert('error', 'Unable to create ticket'));
    } else if (dataNotHappyTicket) {
      if (dataNotHappyTicket.createNotHappyTicketForCancelation?.ok) {
        dispatch(setAlert('success', 'Created ticket'));
        setOpen(false);
      } else {
        dispatch(setAlert('error', dataNotHappyTicket.createNotHappyTicketForCancelation?.error || 'Unable to create ticket'));
      }
    }
  }, [errorNotHappyTicket, dataNotHappyTicket, dispatch, setOpen]);

  return (
    <Dialog open={open} fullWidth onClose={handleClose}>
      <DialogTitle
        sx={{
          textAlign: 'center',
          fontSize: '24px',
          fontWeight: 700,
        }}
      >
        Do Not Renew {registration}
      </DialogTitle>
      <DialogContent>
        <ComponentLoading loading={loadingCancelSubscription || loadingNotHappyTicket}>
          <Box>
            <Typography variant="body1" align="center" gutterBottom>
              To improve our service, please tell us why you are not renewing...
            </Typography>
            <TextField
              select
              label="Reason"
              value={reason}
              onChange={(e) => {
                setReason(e.target.value);
                setReasonError('');
              }}
              fullWidth
              error={Boolean(reasonError)}
              helperText={reasonError}
              FormHelperTextProps={{
                sx: {
                  ml: 0,
                },
              }}
            >
              {reasonList.map((item: IOptionValueLabel, key: number) => {
                return (
                  <MenuItem key={key} value={item.value}>
                    {item.label}
                  </MenuItem>
                );
              })}
            </TextField>
            {hasNote(reason) && (
              <TextField
                multiline
                name="note"
                label="Give us feedback"
                value={note}
                rows={5}
                onChange={(e) => {
                  setNote(e.target.value);
                }}
                fullWidth
              />
            )}
          </Box>
          {reason === 'not_happy' && (
            <Box
              sx={{
                textAlign: 'center',
                mb: 2,
              }}
            >
              <Typography gutterBottom>Give us a chance to fix the problem.</Typography>
              <Button
                variant="outlined"
                color="warning"
                sx={{
                  width: '150px',
                }}
                onClick={handleTicket}
              >
                Create Ticket
              </Button>
            </Box>
          )}
          {reason === 'sold_aircraft' && (
            <Box
              sx={{
                textAlign: 'center',
                mb: 2,
              }}
            >
              <Typography gutterBottom>You can use your remaining plan with one of the following options:</Typography>
              <Box>
                <Button variant="outlined" sx={{}} onClick={handleTransferAircraft}>
                  Transfer plan to another aircraft you own...
                </Button>
                <Button
                  variant="outlined"
                  sx={{
                    ml: 2,
                  }}
                  onClick={handleTransferContact}
                >
                  Transfer plan to new owner...
                </Button>
              </Box>
            </Box>
          )}
          {reason === 'too_expensive' && Boolean(lessExpensiveOptions.length) && (
            <Box>
              <Typography gutterBottom align="center">
                We have less expensive options for you:
              </Typography>
              <Box mb={2}>
                {lessExpensiveOptions.map((option: ILessExpensiveOption, key: number) => {
                  return (
                    <Box
                      py={1}
                      key={key}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Box
                        sx={{
                          width: '50%',
                        }}
                      >
                        <Button variant="outlined" href={option.actionLink ? option.actionLink : '#'} component="a" fullWidth>
                          {option.title}
                        </Button>
                      </Box>
                      <Tooltip title={option.description}>
                        <HelpIcon />
                      </Tooltip>
                      <Link href={option.learnMoreLink} target="_blank">
                        Learn More...
                      </Link>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          )}
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button color="secondary" variant="outlined" onClick={handleClose} fullWidth>
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  onSubmit();
                }}
                fullWidth
              >
                Do Not Renew
              </Button>
            </Grid>
          </Grid>
        </ComponentLoading>
      </DialogContent>
    </Dialog>
  );
};

export default DoNotRenewDialog;
