import React from 'react';

import { Box, Grid, Typography, Hidden } from '@mui/material';

import { Subsection, StepperSignup, PriceTotal } from 'components';

import { AppBarLogoOnly, Content, Footer } from './components';

import { useMdUp } from 'hooks';

import { IDescription } from 'types';

interface ILayoutSignupService {
  service: {
    name: string;
    description: IDescription;
    id?: string;
  };
  form: React.ReactNode;
  activeStep: number;
  showPriceTotalInForm?: boolean;
  servicePlan?: number;
}

const LayoutSignupService: React.FC<ILayoutSignupService> = (props) => {
  const { service, form, activeStep, showPriceTotalInForm, servicePlan = 0 } = props;
  const { name, description } = service;
  const isMdUp = useMdUp();

  const planLabel =
    activeStep === 0
      ? 'Please select aircraft manufacturer & model to see pricing.'
      : service?.id === 'prebuy'
      ? 'Fixed fee for your plan:'
      : 'Fixed annual fee for your plan:';

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <AppBarLogoOnly />
      <Content>
        <Grid container spacing={isMdUp ? 4 : 2} justifyContent="space-between">
          <Grid item xs={12} md={6} xl={5}>
            <Box>
              <Hidden smDown>
                <Subsection>
                  <Typography variant="h1" component="h2" gutterBottom>
                    {name}
                  </Typography>
                </Subsection>
              </Hidden>

              <Hidden mdUp>
                <Typography variant="h1" gutterBottom>
                  Sign Up for {name}
                </Typography>
              </Hidden>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={isMdUp ? 4 : 1} justifyContent="space-between">
          <Grid item xs={12} md={6} lg={5}>
            <Box>{description}</Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <StepperSignup activeStep={activeStep} />

            <Grid container justifyContent="center">
              <Grid item xs={12} md={10} lg={11} xl={10}>
                {showPriceTotalInForm && <PriceTotal label={planLabel} price={servicePlan && activeStep ? `$${servicePlan}/year` : ''} />}

                {/* Form - signup, pament or additional info */}
                <Box
                  sx={{
                    padding: {
                      xs: 1,
                      sm: 0,
                    },
                  }}
                >
                  {form}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Content>
      <Footer />
    </Box>
  );
};

export default LayoutSignupService;
