import { useState, useRef } from 'react';

interface IUseTimerReturn {
  create: () => void;
  created: boolean;
  clear: () => void;
}

interface IUseTimer {
  timeoutValue: number;
  timerAction: () => void;
}

const useTimer = (props: IUseTimer): IUseTimerReturn => {
  const { timeoutValue, timerAction } = props;

  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const [created, setCreated] = useState(false);

  const create = () => {
    if (intervalRef?.current) {
      clearInterval(intervalRef?.current);
    }

    intervalRef.current = setInterval(() => {
      timerAction();
    }, 1000 * timeoutValue);

    setCreated(true);
  };

  const clear = () => {
    intervalRef?.current && clearInterval(intervalRef.current);
    setCreated(false);
  };

  return { create, created, clear };
};

export default useTimer;
