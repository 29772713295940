import React, { useState, useEffect } from 'react';
import { Field } from 'formik';
import { TextField } from 'formik-mui';

interface MuiFormikTextareaInterface {
  name: string;
  label: string;
  errors: any;
  touched: any;
  type?: string;
  onChange: (event: any) => void;
  onBlur?: (event: any) => void;
  maxLength?: number;
  disabled?: boolean;
  multiline?: boolean;
  rows: number;
  maxLine?: number;
  noMargin?: boolean;
}

// Input UI
const MuiFormikTextarea: React.FC<MuiFormikTextareaInterface> = (props) => {
  const {
    name,
    label,
    onBlur,
    onChange,
    errors,
    touched,
    maxLength = 50,
    type = 'text',
    disabled = false,
    rows,
    maxLine = 0,
    noMargin = false,
    ...rest
  } = props;

  const [numRow, setNumRow] = useState(rows);
  const [bodyString, setBodyString] = useState('');

  useEffect(() => {
    if (bodyString) {
      const num = (bodyString.match(/\n/g) || []).length;
      if (num > rows - 1) {
        if (maxLine) {
          setNumRow(Math.min(num + 1, maxLine));
        } else {
          setNumRow(num + 1);
        }
      } else {
        setNumRow(rows);
      }
    }
  }, [bodyString, rows, maxLine]);

  return (
    <Field
      name={name}
      label={label}
      component={TextField}
      type={type}
      onBlur={onBlur}
      sx={{
        mb: noMargin ? '0px' : '15px',
      }}
      onChange={(e: any) => {
        setBodyString(e.target.value);
        onChange(e);
      }}
      errors={errors}
      touched={touched}
      variant="outlined"
      fullWidth={true}
      FormHelperTextProps={{
        sx: {
          color: 'red',
          marginLeft: '0',
        },
      }}
      inputProps={{ maxLength: maxLength }}
      disabled={disabled}
      multiline={true}
      rows={numRow}
      {...rest}
    />
  );
};

export default MuiFormikTextarea;
