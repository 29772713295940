import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box, Container, Typography, TextField, Grid } from '@mui/material';
import {
  QUERY_SAVVY_AIRCRAFT_BORESCOPE_IMAGES_WITH_PREVIEW_URL,
  QUERY_SHARED_AIRCRAFT_BORESCOPE_IMAGES_WITH_PREVIEW_URL,
  QUERY_ME_AIRCRAFT_BORESCOPE_IMAGES_WITH_PREVIEW_URL,
} from 'gql';
import { useLazyQuery } from '@apollo/client';
import { ComponentLoading, Checkbox } from 'components';
import { IBorescopeImageFileType } from 'types';
import { getSubjectLabel } from 'helpers';

interface PreviewReportProps {
  aircraftId: string;
  borescopeImageSetId: string;
  message: string;
  setMessage: (value: string) => void;
  messageError: string;
  setMessageError: (value: string) => void;
  autoCloseTicket: boolean;
  setAutoCloseTicket: (value: boolean) => void;
  sentReport: boolean;
  loadingSendReport: boolean;
  setLoadingPreviewReport: (value: boolean) => void;
  cylinderCount: number;
  subjectList: string[][];
  isOwn?: boolean;
}

const PreviewReport: React.FC<PreviewReportProps> = (props) => {
  const {
    aircraftId,
    borescopeImageSetId,
    message,
    setMessage,
    messageError,
    setMessageError,
    autoCloseTicket,
    setAutoCloseTicket,
    sentReport,
    loadingSendReport,
    setLoadingPreviewReport,
    cylinderCount,
    subjectList,
    isOwn = false,
  } = props;

  const { isSavvy } = useSelector((state: any) => state.auth);
  const [images, setImages] = useState<IBorescopeImageFileType[]>([]);

  const [fetchPreviewUrlsAtOnce, { data: dataPreviewUrlAtOnce, loading: loadingPreviewUrlAtOnce }] = useLazyQuery(
    QUERY_SAVVY_AIRCRAFT_BORESCOPE_IMAGES_WITH_PREVIEW_URL,
    {
      fetchPolicy: 'network-only',
    },
  );

  const [fetchSharedPreviewUrlsAtOnce, { data: dataSharedPreviewUrlAtOnce, loading: loadingSharedPreviewUrlAtOnce }] = useLazyQuery(
    QUERY_SHARED_AIRCRAFT_BORESCOPE_IMAGES_WITH_PREVIEW_URL,
    {
      fetchPolicy: 'network-only',
    },
  );

  const [fetchOwnPreviewUrlsAtOnce, { data: dataOwnPreviewUrlAtOnce, loading: loadingOwnPreviewUrlAtOnce }] = useLazyQuery(
    QUERY_ME_AIRCRAFT_BORESCOPE_IMAGES_WITH_PREVIEW_URL,
    {
      fetchPolicy: 'network-only',
    },
  );

  const getSubjectIndex = (subject: string) => {
    for (let i = 0; i < subjectList.length; i++) {
      if (subjectList[i][0] === subject) {
        return i;
      }
    }
    return -1;
  };

  useEffect(() => {
    if (aircraftId && borescopeImageSetId) {
      if (isSavvy) {
        fetchPreviewUrlsAtOnce({
          variables: {
            aircraftId,
            borescopeImageSetId,
          },
        });
      } else if (!isOwn) {
        fetchSharedPreviewUrlsAtOnce({
          variables: {
            aircraftId,
            borescopeImageSetId,
          },
        });
      } else {
        fetchOwnPreviewUrlsAtOnce({
          variables: {
            aircraftId: parseInt(aircraftId),
            borescopeImageSetId,
          },
        });
      }
    }
  }, [aircraftId, borescopeImageSetId, isSavvy, isOwn]);

  useEffect(() => {
    setLoadingPreviewReport(loadingPreviewUrlAtOnce);
  }, [loadingPreviewUrlAtOnce]);

  useEffect(() => {
    setLoadingPreviewReport(loadingSharedPreviewUrlAtOnce);
  }, [loadingSharedPreviewUrlAtOnce]);

  useEffect(() => {
    setLoadingPreviewReport(loadingOwnPreviewUrlAtOnce);
  }, [loadingOwnPreviewUrlAtOnce]);

  useEffect(() => {
    if (dataPreviewUrlAtOnce?.savvy?.aircraft?.borescopeImageSets[0]?.images?.length) {
      setImages([...dataPreviewUrlAtOnce.savvy.aircraft.borescopeImageSets[0].images]);
    } else if (dataSharedPreviewUrlAtOnce?.sharedAircraftWithUploadBorescope[0]?.aircraft?.borescopeImageSets[0]?.images?.length) {
      setImages([...dataSharedPreviewUrlAtOnce.sharedAircraftWithUploadBorescope[0].aircraft.borescopeImageSets[0].images]);
    } else if (dataOwnPreviewUrlAtOnce?.me?.aircraft[0]?.borescopeImageSets[0]?.images?.length) {
      setImages([...dataOwnPreviewUrlAtOnce.me.aircraft[0].borescopeImageSets[0].images]);
    }
  }, [dataPreviewUrlAtOnce, dataSharedPreviewUrlAtOnce, dataOwnPreviewUrlAtOnce]);

  return (
    <Box
      sx={{
        minHeight: '500px',
      }}
      py={3}
    >
      <ComponentLoading loading={loadingPreviewUrlAtOnce || loadingSendReport || loadingOwnPreviewUrlAtOnce}>
        <Container fixed maxWidth="xl">
          <Box
            px={2}
            mb={1}
            sx={{
              border: '1px solid',
              borderColor: 'grey.200',
            }}
          >
            {Array.apply(null, Array(cylinderCount)).map((value, key: number) => {
              if (!images.filter((item: IBorescopeImageFileType) => item.cylinder === key + 1).length) {
                return null;
              }
              return (
                <Box py={1} key={key}>
                  <Typography variant="h3" gutterBottom>
                    Cylinder {key + 1}
                  </Typography>
                  <Box>
                    {images
                      .sort((item1: IBorescopeImageFileType, item2: IBorescopeImageFileType) =>
                        getSubjectIndex(item1.subject) > getSubjectIndex(item2.subject) ? 1 : -1,
                      )
                      .map((item: IBorescopeImageFileType, key1: number) => {
                        if (item.cylinder !== key + 1) {
                          return null;
                        }
                        return (
                          <Box py={1} key={`${key}_${key1}`}>
                            <Typography
                              variant="h4"
                              gutterBottom
                              sx={{
                                textTransform: 'capitalize',
                              }}
                            >
                              {getSubjectLabel(subjectList, item.subject)}
                            </Typography>
                            <Grid container spacing={2}>
                              <Grid item xs={12} md={6} lg={4} xl={4}>
                                <Box>
                                  <Box
                                    component={'img'}
                                    src={item.previewUrl}
                                    sx={{
                                      maxWidth: '500px',
                                      width: '100%',
                                    }}
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={12} md={6} lg={8} xl={8}>
                                <Box px={2}>
                                  <Typography>{item.textualStatus}</Typography>
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>
                        );
                      })}
                  </Box>
                </Box>
              );
            })}
          </Box>
          {!sentReport && isSavvy && (
            <Box>
              <Box
                py={1}
                sx={{
                  textAlign: 'center',
                }}
              >
                <TextField
                  name="message"
                  label="Message"
                  rows={10}
                  value={message}
                  onChange={(e) => {
                    setMessage(e.target.value);
                    setMessageError('');
                  }}
                  error={!!messageError}
                  helperText={messageError}
                  FormHelperTextProps={{
                    sx: {
                      ml: 0,
                    },
                  }}
                  sx={{
                    mb: 0,
                    maxWidth: '700px',
                    mx: 'auto',
                  }}
                  multiline
                  fullWidth
                />
              </Box>
              <Box
                py={1}
                sx={{
                  maxWidth: '500px',
                  mx: 'auto',
                }}
              >
                <Checkbox
                  value={autoCloseTicket}
                  checked={autoCloseTicket}
                  label={
                    <Typography
                      sx={{
                        display: 'inline-block',
                      }}
                    >
                      Snooze & Close in 5 days?
                    </Typography>
                  }
                  onChange={() => {
                    setAutoCloseTicket(!autoCloseTicket);
                  }}
                  noMargin
                />
              </Box>
            </Box>
          )}
          {sentReport && (
            <Box pt={6} pb={1}>
              <Typography
                align="center"
                color="success.main"
                sx={{
                  fontSize: '16px',
                  lineHeight: '24px',
                }}
              >
                Report has been {isSavvy ? 'sent' : 'completed'}
              </Typography>
            </Box>
          )}
        </Container>
      </ComponentLoading>
    </Box>
  );
};

export default PreviewReport;
