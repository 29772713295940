import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Dialog, Box, DialogTitle, DialogContent, TextField, MenuItem, DialogActions, Grid, Button, Typography } from '@mui/material';

import dayjs, { Dayjs } from 'dayjs';

import { MUTATION_UPDATE_MAINTENANCE_ENTRY, QUERY_MAINTENANCE_RECORD_CATEGORIES, QUERY_SAVVY_AIRCRAFT_LOGBOOKS } from 'gql';
import { useMutation, useLazyQuery } from '@apollo/client';
import { setAlert } from 'state';

import { ComponentLoading, Checkbox, CustomDatePicker } from 'components';
import { IMaintenanceEntry, ILogbook } from 'types';
import { getMaintenanceCategoryName } from 'helpers';

interface EditLogDialogProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  maintenanceEntry: IMaintenanceEntry;
  aircraftId: string;
}

const EditLogDialog: React.FC<EditLogDialogProps> = (props) => {
  const { open, setOpen, maintenanceEntry, aircraftId } = props;

  const [updateMaintenanceEntry, { data, loading, error }] = useMutation(MUTATION_UPDATE_MAINTENANCE_ENTRY);
  const [fetchMaintenanceCategory, { data: dataCategory, loading: loadingCategory, error: errorCategory }] =
    useLazyQuery(QUERY_MAINTENANCE_RECORD_CATEGORIES);
  const dispatch = useDispatch();
  const [fetchLogbooks, { data: dataLogbooks, loading: loadingLogbooks, error: errorLogbooks }] =
    useLazyQuery(QUERY_SAVVY_AIRCRAFT_LOGBOOKS);

  const [isPerformedInspection, setIsPerformedInspection] = useState(maintenanceEntry.isPerformedInspection);
  const [isPerformedMaintenance, setIsPerformedMaintenance] = useState(maintenanceEntry.isPerformedMaintenance);

  const [entryDate, setEntryDate] = useState<Dayjs | null>(dayjs(maintenanceEntry.entryDate));

  const [logbookId, setLogbookId] = useState(maintenanceEntry.logbook.id);
  const [logbookIdError, setLogbookIdError] = useState('');

  const [maintenanceEntryCategoryId, setMaintenanceEntryCategoryId] = useState(maintenanceEntry.maintenanceEntryCategory.id);
  const [maintenanceEntryCategoryName, setMaintenanceEntryCategoryName] = useState('');
  const [recordTypeError, setRecordTypeError] = useState('');

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async () => {
    if (!maintenanceEntry) return;

    if (!logbookId || logbookId === '-1') {
      setLogbookIdError('Please select logbook');
      return;
    }

    if (!maintenanceEntryCategoryId || maintenanceEntryCategoryId === '-1') {
      setRecordTypeError('Please select maintenance record type');
      return;
    }

    await updateMaintenanceEntry({
      variables: {
        maintenanceEntryId: maintenanceEntry.id,
        isPerformedInspection,
        isPerformedMaintenance,
        entryDate,
        maintenanceEntryCategoryId,
        logbookId,
      },
    });
  };

  useEffect(() => {
    if (open) {
      setIsPerformedInspection(maintenanceEntry?.isPerformedInspection);
      setIsPerformedMaintenance(maintenanceEntry?.isPerformedMaintenance);
      setEntryDate(dayjs(maintenanceEntry?.entryDate));
      setMaintenanceEntryCategoryId(maintenanceEntry.maintenanceEntryCategory.id);
      setMaintenanceEntryCategoryName(maintenanceEntry.maintenanceEntryCategory.name);
      setLogbookId(maintenanceEntry.logbook.id);
    }
  }, [open, maintenanceEntry]);

  useEffect(() => {
    if (error) {
      dispatch(setAlert('error', 'Unable to update maintenance record'));
    } else if (data) {
      if (data.updateMaintenanceEntry?.ok) {
        dispatch(setAlert('success', 'Updated maintenance record'));
        setOpen(false);
      } else {
        dispatch(setAlert('error', data.updateMaintenanceEntry?.error || 'Unable to update maintenance record'));
      }
    }
  }, [error, data, dispatch, setOpen]);

  useEffect(() => {
    if (open) {
      fetchLogbooks({
        variables: {
          aircraftId,
        },
      });
    }
  }, [open, fetchLogbooks, aircraftId]);

  useEffect(() => {
    if (errorLogbooks) {
      dispatch(setAlert('error', 'Unable to fetch logbooks'));
    }
  }, [errorLogbooks, dispatch]);

  useEffect(() => {
    if (open) {
      fetchMaintenanceCategory();
    }
  }, [open, fetchMaintenanceCategory]);

  useEffect(() => {
    if (errorCategory) {
      dispatch(setAlert('error', 'Unable to fetch maintenance record category'));
    }
  }, [errorCategory, dispatch]);

  if (!maintenanceEntry) return null;

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={handleClose}
      PaperProps={{
        sx: {
          maxWidth: '400px',
        },
      }}
    >
      <DialogTitle
        sx={{
          textAlign: 'center',
          fontSize: '24px',
          fontWeight: 700,
        }}
      >
        Create Maintenance Record
      </DialogTitle>
      <DialogContent>
        <ComponentLoading loading={loading || loadingCategory || loadingLogbooks}>
          <Box py={1}>
            <Checkbox
              value={isPerformedMaintenance}
              checked={isPerformedMaintenance}
              label={
                <Typography
                  sx={{
                    display: 'inline-block',
                  }}
                >
                  Performed Maintenance?
                </Typography>
              }
              onChange={() => {
                setIsPerformedMaintenance(!isPerformedMaintenance);
              }}
              noMargin
            />
          </Box>
          <Box py={1}>
            <Checkbox
              value={isPerformedInspection}
              checked={isPerformedInspection}
              label={
                <Typography
                  sx={{
                    display: 'inline-block',
                  }}
                >
                  Performed Inspection?
                </Typography>
              }
              onChange={() => {
                setIsPerformedInspection(!isPerformedInspection);
              }}
              noMargin
            />
          </Box>
          <Box py={1}>
            <CustomDatePicker value={entryDate} setValue={setEntryDate} label="Entry Date" />
          </Box>
          <Box py={1}>
            <TextField
              name="logbookId"
              label="Logbook *"
              value={logbookId}
              onChange={(e) => {
                setLogbookId(e.target.value);
                setLogbookIdError('');
              }}
              error={!!logbookIdError}
              helperText={logbookIdError}
              FormHelperTextProps={{
                sx: {
                  ml: 0,
                },
              }}
              select
              fullWidth
            >
              <MenuItem value="-1">Select Logbook</MenuItem>
              {dataLogbooks?.savvy?.aircraft?.logbooks?.map((logbook: ILogbook, key: number) => {
                return (
                  <MenuItem key={key} value={logbook.id}>
                    {logbook.name}
                  </MenuItem>
                );
              })}
            </TextField>
          </Box>
          <Box py={1}>
            <TextField
              name="maintenanceRecordType"
              label="Maintenance Record Type *"
              value={maintenanceEntryCategoryId}
              onChange={(e) => {
                setMaintenanceEntryCategoryId(e.target.value);
                setMaintenanceEntryCategoryName(getMaintenanceCategoryName(e.target.value, dataCategory?.maintenanceEntryCategories || []));
                setRecordTypeError('');
              }}
              error={!!recordTypeError}
              helperText={recordTypeError}
              FormHelperTextProps={{
                sx: {
                  ml: 0,
                },
              }}
              select
              fullWidth
            >
              <MenuItem value="-1">Select record type</MenuItem>
              {dataCategory?.maintenanceEntryCategories?.map((category: any, key: number) => {
                return (
                  <MenuItem key={key} value={category.id}>
                    {category.name}
                  </MenuItem>
                );
              })}
            </TextField>
          </Box>
          {maintenanceEntryCategoryName === 'Combo' && (
            <Box py={1}>
              <Typography>You can also Cancel, split the files up and add individually</Typography>
            </Box>
          )}
        </ComponentLoading>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button variant="outlined" onClick={handleClose} disabled={loading} fullWidth>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="contained" onClick={onSubmit} disabled={loading} fullWidth>
              Submit
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default EditLogDialog;
