import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Typography, Button, IconButton } from '@mui/material';
import { DataGridPro, GridRenderCellParams, GridRowParams, GridSelectionModel } from '@mui/x-data-grid-pro';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { ILogbook, IMaintenanceEntry } from 'types';

import { CreateLogbookDialog, EditLogbookDialog, DeleteLogbookDialog, CreateComboLogsDialog } from './';
import { MUTATION_CREATE_COMBO_LOGS } from 'gql';
import { useMutation } from '@apollo/client';

import { AddLogDialog } from 'pages/Aircrafts/components/Dialog';
import { ComponentLoading } from 'components';
import { setAlert } from 'state';

interface LogbooksProps {
  logbooks: ILogbook[];
  aircraftId: string;
  maintenanceEntries: IMaintenanceEntry[];
  setTabValue?: (value: number) => void;
  setLogbookId?: (value: string) => void;
}

const Logbooks: React.FC<LogbooksProps> = (props) => {
  const { logbooks, aircraftId, maintenanceEntries, setTabValue, setLogbookId } = props;

  const [createComboLogsPdfForClient, { data: dataComboLogs, loading: loadingComboLogs, error: errorComboLogs }] =
    useMutation(MUTATION_CREATE_COMBO_LOGS);
  const dispatch = useDispatch();

  const [openDownloadDialog, setOpenDownloadDialog] = useState(false);

  const { isSavvy, isClient } = useSelector((state: any) => state.auth);

  const [openCreate, setOpenCreate] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [editLogbook, setEditLogbook] = useState<ILogbook>();

  const [openDelete, setOpenDelete] = useState(false);
  const [maintenanceLogbook, setMaintenanceLogbook] = useState<ILogbook>();

  const [openAddLog, setOpenAddLog] = useState(false);
  const [addLogbookId, setAddLogbookId] = useState('');

  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);

  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    if (errorComboLogs) {
      dispatch(setAlert('error', 'Unable to create pdf for combo logs'));
    } else if (dataComboLogs) {
      const { ok, error } = dataComboLogs.createComboLogsPdfForClient;
      if (error) {
        dispatch(setAlert('error', error));
      } else if (ok) {
        setOpenDownloadDialog(true);
      }
    }
  }, [errorComboLogs, dataComboLogs, dispatch]);

  return (
    <Box py={2}>
      <ComponentLoading loading={loadingComboLogs}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant="h3">Logbooks</Typography>
          {isSavvy && (
            <Button
              sx={{
                ml: 2,
              }}
              variant="contained"
              onClick={() => {
                setOpenCreate(true);
              }}
            >
              + Add Logbook
            </Button>
          )}
          {isClient && !!selectionModel.length && (
            <Button
              sx={{
                ml: 2,
              }}
              variant="contained"
              onClick={async () => {
                if (!selectionModel.length) {
                  return;
                }
                const logbooksIdList = selectionModel.map((item) => parseInt(item as string));
                await createComboLogsPdfForClient({
                  variables: {
                    logbooksIdList,
                  },
                });
              }}
            >
              Download Combo Logs
            </Button>
          )}
        </Box>
        <Box>
          <DataGridPro
            sx={{
              width: '100%',
              minWidth: '100px',
              '& .MuiDataGrid-columnSeparator': {
                display: 'none',
              },
              '& .MuiDataGrid-cell:focus': {
                outline: 'none',
              },
              '& .MuiDataGrid-row': {
                cursor: 'pointer',
                borderBottom: '1px solid',
                borderColor: 'grey.200',
              },
              '& .MuiDataGrid-columnHeader:focus': {
                outline: 'none',
              },
              '& .MuiDataGrid-cellContent': {
                whiteSpace: 'break-spaces',
              },
              border: 'none',
            }}
            autoHeight
            disableColumnResize={true}
            disableSelectionOnClick={true}
            rowsPerPageOptions={[5, 10, 25, 100]}
            pageSize={pageSize}
            onPageSizeChange={(newPage) => setPageSize(newPage)}
            pagination
            checkboxSelection={isClient}
            checkboxSelectionVisibleOnly={isClient}
            onSelectionModelChange={(newSelectionModel) => {
              setSelectionModel(newSelectionModel);
            }}
            selectionModel={selectionModel}
            showColumnRightBorder={false}
            disableColumnMenu={true}
            rows={logbooks}
            columnVisibilityModel={{
              id: isSavvy,
            }}
            columns={[
              {
                field: 'name',
                headerName: 'Name',
                flex: 1,
              },
              {
                field: 'entriesCount',
                headerName: 'Entries',
                flex: 1,
              },
              {
                field: 'id',
                headerName: 'Actions',
                sortable: false,
                flex: 0.8,
                renderCell: (params: GridRenderCellParams<string>) => {
                  return (
                    <Box>
                      <IconButton
                        title="Edit"
                        onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setEditLogbook(params.row);
                          setOpenEdit(true);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        title="Delete"
                        sx={{
                          ml: 1,
                        }}
                        onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setMaintenanceLogbook(params.row);
                          setOpenDelete(true);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                      {isSavvy && (
                        <IconButton
                          title="Add Maintenance Entry"
                          sx={{
                            ml: 1,
                          }}
                          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setAddLogbookId(params.value || '');
                            setOpenAddLog(true);
                          }}
                        >
                          <AddIcon />
                        </IconButton>
                      )}
                    </Box>
                  );
                },
              },
            ]}
            onRowClick={(params: GridRowParams) => {
              const { row } = params;
              if (row?.id) {
                setLogbookId && setLogbookId(row.id || '');
                setTabValue && setTabValue(1);
              } else {
                return false;
              }
            }}
          />
        </Box>
        <CreateLogbookDialog open={openCreate} setOpen={setOpenCreate} aircraftId={aircraftId} />
        {!!editLogbook && <EditLogbookDialog open={openEdit} setOpen={setOpenEdit} logbook={editLogbook} />}
        {!!maintenanceLogbook && (
          <DeleteLogbookDialog open={openDelete} setOpen={setOpenDelete} maintenanceLogbook={maintenanceLogbook} logbooks={logbooks} />
        )}
      </ComponentLoading>
      <AddLogDialog
        open={openAddLog}
        setOpen={setOpenAddLog}
        aircraftId={aircraftId}
        maintenanceEntries={maintenanceEntries}
        selectedLogbookId={addLogbookId}
      />
      <CreateComboLogsDialog
        open={openDownloadDialog}
        setOpen={setOpenDownloadDialog}
        workflowId={dataComboLogs?.createComboLogsPdfForClient?.workflowId || ''}
      />
    </Box>
  );
};

export default Logbooks;
