import { Route, Redirect } from 'react-router-dom';

import { useSelector } from 'react-redux';

import { PageLoading } from 'components';
import { PATH_BETA_DASHBOARD } from 'const';

interface IRedirectLoggedIn {
  component: any;
  path: string;
  exact: boolean;
}

// Redirect to dashboard for logged IN
const RedirectLoggedIn = (props: IRedirectLoggedIn) => {
  const { component: Component, ...rest } = props; // Get Component user try access
  const { loggedIn } = useSelector((state: any) => state.auth); // Get auth state (Redux)

  // If loading
  if (loggedIn === null) return <PageLoading />;

  // Already logged in
  if (loggedIn !== null && loggedIn) {
    // Redirect to root and selector will do the right thing from there.
    return (
      <Route
        render={() => (
          <Redirect
            to={{
              pathname: PATH_BETA_DASHBOARD,
            }}
          />
        )}
        {...rest}
      />
    );
  }

  // If logged OUT - proceed to page
  return <Route render={() => <Component />} {...rest} />;
};

export default RedirectLoggedIn;
