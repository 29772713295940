import { Redirect, useLocation } from 'react-router-dom';

interface IBetaSwitchRoute {
  path: string;
}

const BetaSwitchRoute = (props: IBetaSwitchRoute) => {
  const location = useLocation();
  const pathname = location ? location.pathname : '';

  if (pathname.includes('/beta/shared') || pathname.includes('/beta/staged-engine-data-files') || pathname.includes('/beta/cohorts')) {
    return <Redirect to={pathname.replace('/beta', '/flights')} />;
  }

  if (pathname.startsWith('/shared')) {
    return <Redirect to={'/flights' + pathname} />;
  }

  if (pathname.includes('/profile')) {
    return <Redirect to={'/account' + pathname} />;
  }

  if (
    pathname.includes('/beta/plan') ||
    pathname.includes('/beta/add-analysis-pro') ||
    pathname.includes('/beta/welcome') ||
    pathname.includes('/beta/staging-security-check') ||
    pathname.includes('/beta/security') ||
    pathname.includes('/beta/request-api-token')
  ) {
    return <Redirect to={pathname.replace('/beta', '/account')} />;
  }

  // If logged IN - proceed to page
  return <Redirect to={window.location.pathname.replace('/beta', '')} />;
};

export default BetaSwitchRoute;
