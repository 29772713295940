import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Typography, Button } from '@mui/material';
import { Dayjs } from 'dayjs';

import { ComponentLoading, CustomDatePicker } from 'components';

import { MUTATION_CHANGE_SUBSCRIPTION_END_DATE } from 'gql';
import { useMutation } from '@apollo/client';

import { ISubscriptionDetail } from 'types';
import { setAlert } from 'state';

interface ChangeEndDateProps {
  subscription: ISubscriptionDetail;
}

const ChangeEndDate: React.FC<ChangeEndDateProps> = (props) => {
  const { subscription } = props;

  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const [endDateError, setEndDateError] = useState('');
  const dispatch = useDispatch();

  const [endDateMutation, { data: dataEndDate, loading: loadingEndDate, error: errorEndDate }] = useMutation(
    MUTATION_CHANGE_SUBSCRIPTION_END_DATE,
  );

  const changeEndDate = async () => {
    if (!endDate) {
      setEndDateError('Please input end date');
      return;
    }

    if (!subscription.id) return;

    await endDateMutation({
      variables: {
        subscriptionId: subscription.id,
        endDate,
      },
    });
  };

  useEffect(() => {
    if (errorEndDate) {
      dispatch(setAlert('error', 'Unable to change end date'));
    } else if (dataEndDate) {
      if (dataEndDate?.changeSubscriptionEndDate?.ok) {
        dispatch(setAlert('success', 'Changed end date'));
      } else {
        dispatch(setAlert('error', dataEndDate?.changeSubscriptionEndDate?.error || 'Unable to change end date'));
      }
    }
  }, [errorEndDate, dispatch, dataEndDate]);

  return (
    <Box>
      <ComponentLoading loading={loadingEndDate}>
        <Box>
          <Typography
            variant="h3"
            sx={{
              color: 'error.main',
              fontWeight: 'bold',
            }}
          >
            Change End Date
          </Typography>
          <Box py={1}>
            <Typography>Subscription end time will be set to midnight.</Typography>
          </Box>
          <Box py={1}>
            <CustomDatePicker
              label="End date"
              value={endDate}
              setValue={setEndDate}
              error={endDateError}
              setError={setEndDateError}
              sx={{
                ml: 0,
              }}
              containerSx={{
                mb: 0,
              }}
            />
          </Box>
          <Box
            py={1}
            sx={{
              textAlign: 'left',
            }}
          >
            <Button variant="contained" onClick={changeEndDate}>
              Change End Date
            </Button>
          </Box>
        </Box>
      </ComponentLoading>
    </Box>
  );
};

export default ChangeEndDate;
