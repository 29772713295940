import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, TextField, Button } from '@mui/material';
import { ComponentLoading } from 'components';

import { useMutation } from '@apollo/client';
import { MUTATION_UPDATE_TICKET_NOTES } from 'gql';

import { setAlert } from 'state';

interface EditNotesProps {
  ticketId: string;
  originNotes: string;
  setOpen: (value: boolean) => void;
  open: boolean;
}

const EditNotes: React.FC<EditNotesProps> = (props) => {
  const { ticketId, originNotes, setOpen, open } = props;

  const [updateNotes, { data: dataNotes, error: errorNotes, loading: loadingNotes }] = useMutation(MUTATION_UPDATE_TICKET_NOTES);
  const dispatch = useDispatch();

  const [notes, setNotes] = useState('');

  const onSubmit = async () => {
    await updateNotes({
      variables: {
        ticketId,
        notes,
      },
    });
  };

  useEffect(() => {
    if (open) {
      setNotes(originNotes);
    }
  }, [open, originNotes]);

  useEffect(() => {
    if (errorNotes) {
      dispatch(setAlert('error', 'Unable to update ticket notes'));
    } else if (dataNotes) {
      if (dataNotes.updateTicketNotes?.ok) {
        dispatch(setAlert('success', 'Updated ticket notes'));
        setOpen(false);
      } else {
        dispatch(setAlert('error', dataNotes.updateTicketNotes?.error || 'Unable to update ticket notes'));
      }
    }
  }, [dataNotes, errorNotes, dispatch, setOpen]);

  return (
    <Box>
      <ComponentLoading loading={loadingNotes}>
        <Box py={1}>
          <TextField
            name="notes"
            label="Notes (visibly only to Savvy)"
            placeholder="Use this note to keep track of any pertinent information, reminders, or checklists about this ticket.  Visible to Savvy only."
            value={notes}
            onChange={(e: any) => {
              setNotes(e.target.value);
            }}
            multiline
            minRows={5}
            fullWidth
            sx={{
              mb: 0,
            }}
          />
        </Box>
        <Box
          py={1}
          sx={{
            textAlign: 'right',
          }}
        >
          <Button variant="contained" onClick={onSubmit}>
            Save
          </Button>
          <Button
            variant="outlined"
            sx={{
              ml: 1,
            }}
            onClick={() => {
              setOpen(false);
            }}
          >
            Cancel
          </Button>
        </Box>
      </ComponentLoading>
    </Box>
  );
};

export default EditNotes;
