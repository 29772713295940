import React, { useState, useEffect } from 'react';
import FormGroupInput from './FormGroupInput';

import { useLazyQuery } from '@apollo/client';
import { QUERY_EXISTING_SERVICE_CENTERS } from 'gql';

interface ServiceCenterInputProps {
  handleBlur: (e: React.FocusEvent<any, Element>) => void;
  handleChange: (e: React.ChangeEvent<any>) => void;
  touched: any;
  errors: any;
  setDuplicatedId: (value: string) => void;
}

const ServiceCenterInput: React.FC<ServiceCenterInputProps> = (props) => {
  const { handleBlur, handleChange, touched, errors, setDuplicatedId } = props;

  const [company, setCompany] = useState('');

  const [refetchCompanyExist, { data: dataCompanyExist, loading }] = useLazyQuery(QUERY_EXISTING_SERVICE_CENTERS);

  // Delay 500ms when change email, so don't make request per character
  useEffect(() => {
    if (!company || company.length < 3) {
      return;
    }

    const timeout: any = setTimeout(() => {
      refetchCompanyExist({
        variables: {
          companyName: company,
        },
      });
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [company, refetchCompanyExist]);

  useEffect(() => {
    if (!loading && dataCompanyExist) {
      if (dataCompanyExist?.existingServiceCenters?.length) {
        setDuplicatedId(dataCompanyExist.existingServiceCenters[0].id);
      }
    }
  }, [loading, dataCompanyExist, setDuplicatedId]);

  return (
    <FormGroupInput
      name="company"
      label="Company *"
      onBlur={handleBlur}
      onChange={(e) => {
        handleChange(e);
        setCompany(e.target.value);
        setDuplicatedId('');
      }}
      errors={errors}
      touched={touched}
      noMargin={true}
    />
  );
};

export default ServiceCenterInput;
