import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  TextField,
  Grid,
  Button,
  FormControlLabel,
  Checkbox,
  MenuItem,
} from '@mui/material';
import { Dayjs } from 'dayjs';
import { setAlert } from 'state';
import { aircraftRegistrationMakeModel } from 'helpers';

import { MUTATION_CREATE_CERTIFICATE_OF_INSURANCE_FROM_ATTACHMENT, QUERY_GET_TICKETS_AIRCRAFT } from 'gql';
import { useLazyQuery, useMutation } from '@apollo/client';

import { ComponentLoading, CustomDatePicker } from 'components';

interface CreateCoiFromAttachmentProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  attachmentId: string;
  attachmentName: string;
  aircraftId?: string;
  ticketId: string;
}

const CreateCoiFromAttachment: React.FC<CreateCoiFromAttachmentProps> = (props) => {
  const { open, setOpen, attachmentId, attachmentName, aircraftId = '', ticketId } = props;

  const [addCoi, { data: dataCoi, loading: loadingCoi, error: errorCoi }] = useMutation(
    MUTATION_CREATE_CERTIFICATE_OF_INSURANCE_FROM_ATTACHMENT,
  );
  const [fetchAircraft, { data: dataAircraft, loading: loadingAircraft, error: errorAircraft }] = useLazyQuery(QUERY_GET_TICKETS_AIRCRAFT);

  const dispatch = useDispatch();
  const { isSavvy } = useSelector((state: any) => state.auth);

  const [agent, setAgent] = useState('');

  const [approve, setApprove] = useState(false);

  const [underwriter, setUnderwriter] = useState('');

  const [expirationDate, setExpirationDate] = useState<Dayjs | null>(null);
  const [expirationDateError, setExpirationDateError] = useState('');

  const [receivedDate, setReceivedDate] = useState<Dayjs | null>(null);
  const [receivedDateError, setReceivedDateError] = useState('');

  const [selectAircraftId, setSelectAircraftId] = useState('');
  const [aircraftError, setAircraftError] = useState('');

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async () => {
    if (!aircraftId && !selectAircraftId) {
      setAircraftError('Please select aircraft');
      return;
    }

    if (!expirationDate) {
      setExpirationDateError('Please select expiration date');
      return;
    }

    if (isSavvy && !receivedDate) {
      setReceivedDateError('Please select received date');
      return;
    }

    await addCoi({
      variables: {
        agent,
        attachmentId,
        underwriter,
        receivedDate,
        expirationDate,
        approve,
        aircraftId: aircraftId ? aircraftId : selectAircraftId,
      },
    });
  };

  useEffect(() => {
    if (errorCoi) {
      dispatch(setAlert('error', 'Unable to add certificate of insurance'));
    } else if (dataCoi) {
      if (dataCoi?.createCertificateOfInsuranceFromAttachment?.ok) {
        dispatch(setAlert('success', 'Created certificate of insurance'));
        setOpen(false);
      } else {
        dispatch(setAlert('error', dataCoi?.createCertificateOfInsuranceFromAttachment?.error || 'Unable to add certificate of insurance'));
      }
    }
  }, [errorCoi, dispatch, dataCoi, setOpen]);

  useEffect(() => {
    if (!open) {
      setAgent('');
      setUnderwriter('');
      setExpirationDate(null);
      setReceivedDate(null);
      setSelectAircraftId('');
    }
  }, [open]);

  useEffect(() => {
    if (open && !aircraftId) {
      fetchAircraft({
        variables: {
          ticketId: parseInt(ticketId),
        },
      });
    }
  }, [open, aircraftId, ticketId, fetchAircraft]);

  useEffect(() => {
    if (errorAircraft) {
      dispatch(setAlert('error', "Unable to load ticket's aircraft"));
    }
  }, [dispatch, errorAircraft]);

  useEffect(() => {
    if (dataAircraft?.me?.tickets[0]?.client?.contact) {
      if (!dataAircraft?.me?.tickets[0]?.client.contact.aircraft?.length) {
        dispatch(setAlert('error', "This ticket doesn't have related aircraft."));
      }
    }
  }, [dispatch, dataAircraft]);

  return (
    <Dialog open={open} fullWidth onClose={handleClose}>
      <DialogContent>
        <Typography variant="h3" gutterBottom align="center">
          Submit {attachmentName} as Certificate of Insurance (COI)
        </Typography>
        <ComponentLoading loading={loadingCoi || loadingAircraft}>
          <Box>
            {!aircraftId && !!dataAircraft?.me?.tickets[0]?.client?.contact?.aircraft?.length && (
              <TextField
                select
                label="Aircraft *"
                name="aircraft"
                value={selectAircraftId}
                onChange={(e: any) => {
                  setSelectAircraftId(e.target.value);
                  setAircraftError('');
                }}
                error={!!aircraftError}
                helperText={aircraftError}
                FormHelperTextProps={{
                  sx: {
                    ml: 0,
                  },
                }}
                fullWidth
              >
                {dataAircraft?.me?.tickets[0]?.client?.contact?.aircraft?.map((item: any, key: number) => {
                  return (
                    <MenuItem value={item.id} key={key}>
                      {aircraftRegistrationMakeModel(item)}
                    </MenuItem>
                  );
                })}
              </TextField>
            )}
            <TextField
              label="Agent"
              name="agent"
              value={agent}
              onChange={(e) => {
                setAgent(e.target.value);
              }}
              fullWidth
            />
            <TextField
              label="Underwriter"
              name="underwriter"
              value={underwriter}
              onChange={(e) => {
                setUnderwriter(e.target.value);
              }}
              fullWidth
            />
            {isSavvy && (
              <FormControlLabel
                label={<Typography>Approve COI?</Typography>}
                control={
                  <Checkbox
                    checked={approve}
                    onChange={(event: any) => {
                      setApprove(event.target.checked);
                    }}
                    sx={{
                      padding: 0,
                    }}
                  />
                }
                sx={{
                  ml: 0,
                  mb: '15px',
                }}
              />
            )}
            <CustomDatePicker
              value={expirationDate}
              setValue={setExpirationDate}
              error={expirationDateError}
              setError={setExpirationDateError}
              label={'Expiration Date *'}
              sx={{
                ml: 0,
              }}
            />
            {isSavvy && (
              <CustomDatePicker
                value={receivedDate}
                setValue={setReceivedDate}
                error={receivedDateError}
                setError={setReceivedDateError}
                label={'Received Date *'}
                sx={{
                  ml: 0,
                }}
              />
            )}
          </Box>
        </ComponentLoading>
      </DialogContent>

      <DialogActions>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button variant="outlined" onClick={handleClose} disabled={loadingCoi} fullWidth>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="contained" onClick={onSubmit} disabled={loadingCoi} fullWidth>
              Submit
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default CreateCoiFromAttachment;
