import { useState } from 'react';

import { IInputError } from 'types';

const useCheckbox = () => {
  // State
  const [value, setValue] = useState<boolean>(false);
  const [error, setError] = useState<IInputError>(null);

  // Handle Change
  const onChange = (e: any) => {
    if (e) {
      setError(null); // Clear Error
      setValue(!value);
    }
  };

  // Return values and functions to use in component
  return {
    value,
    setValue,
    error,
    setError,
    onChange,
  };
};

export default useCheckbox;
