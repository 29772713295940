import React from 'react';

import { Grid, Typography, Button, Card, CardContent, Box } from '@mui/material';

import { LayoutLoggedOut, Section } from 'components';

import { logAmplitudeEvent } from 'services';

import { SIGNUP_STARTED } from 'const';

import { useXlUp } from 'hooks';

import { services } from 'api';

const Enroll: React.FC = () => {
  const isXlUp = useXlUp();

  // Amplitude Enroll Button Clicked
  const handleButtonClick = (serviceId: string) => {
    logAmplitudeEvent(`${SIGNUP_STARTED}${serviceId.toUpperCase()}`);
  };

  return (
    <LayoutLoggedOut>
      <Section>
        <Typography variant="h1" gutterBottom align="center">
          Services
        </Typography>
        <Grid container spacing={isXlUp ? 6 : 4}>
          {services.map((service) => (
            <Grid item xs={12} md={4} key={service.id}>
              <Card elevation={4} className="h-100">
                <CardContent className="h-100">
                  <Box display="flex" flexDirection="column" height="100%">
                    <div>
                      <Typography variant="h2" gutterBottom align="center">
                        {service.name}
                      </Typography>
                      <Typography component="div" paragraph>
                        {service.description}
                      </Typography>
                    </div>
                    <Box mt="auto">
                      <Button
                        href={service.link}
                        color="primary"
                        variant="contained"
                        fullWidth
                        onClick={() => handleButtonClick(service.id)}
                      >
                        Enroll
                      </Button>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Section>
    </LayoutLoggedOut>
  );
};

export default Enroll;
