import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Typography, Tooltip, Collapse, IconButton, Badge } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { PersonIcon } from 'customIcons';
import HandymanIcon from '@mui/icons-material/Handyman';

import { PATH_TICKETS_VIEW } from 'const';

import { ISavvyTicket, IPost, IContactType } from 'types';
import { humanDate } from 'helpers';

interface ITicketGroup {
  name: string;
  description: string;
  tickets: ISavvyTicket[];
}

interface CollapsedTicketsProps {
  groups?: ITicketGroup[];
  checkUnread: boolean;
}

const MobilePtd: React.FC<CollapsedTicketsProps> = (props) => {
  const { groups = [], checkUnread } = props;

  const [collapsedFlags, setCollpasedFlags] = useState<
    {
      name: string;
      open: boolean;
    }[]
  >([]);

  const history = useHistory();

  useEffect(() => {
    if (groups.length) {
      const tmpFlags = groups.map((group: ITicketGroup) => {
        return {
          name: group.name,
          open: true,
        };
      });

      setCollpasedFlags([...tmpFlags]);
    }
  }, [groups]);

  const activeOn = (name: string) => {
    const selectedFlags = collapsedFlags.filter((flag: any) => flag.name === name);

    // return selectedFlags.open;
    if (selectedFlags.length) return selectedFlags[0].open;

    return false;
  };

  const openGroupTickets = (name: string) => {
    const tmpFlags = collapsedFlags.map((flag: any) => {
      if (flag.name === name) {
        flag.open = !flag.open;
      }

      return flag;
    });

    setCollpasedFlags([...tmpFlags]);
  };

  const RenderLastPost = (post: IPost, poster: IContactType) => {
    return (
      <Box
        sx={{
          display: 'inline-flex',
          alignItems: 'center',
        }}
      >
        <Typography>{humanDate(post.created)} by</Typography>
        {poster?.role === 'M' && (
          <Box
            component="img"
            src="/images/logo.png"
            sx={{
              width: '14px',
              height: '14px',
              mx: 1,
            }}
          />
        )}
        {poster?.role === 'C' && (
          <PersonIcon
            sx={{
              color: 'warning.main',
              mx: 1,
              width: '14px',
              height: '14px',
            }}
          />
        )}
        {poster?.role === 'T' && (
          <HandymanIcon
            sx={{
              color: 'success.main',
              mx: 1,
              width: '20px',
              height: '20px',
            }}
          />
        )}
        <Typography>
          {poster?.firstName} {poster?.lastName}
        </Typography>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        background: '#F5F6FA',
        minHeight: {
          md: 'inherit',
          lg: 'calc(100vh - 75px)',
        },
      }}
    >
      <Box
        sx={{
          '@media (min-width: 1280px)': {
            maxHeight: 'calc(100vh - 70px)',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
              width: '0.6em',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(0,0,0,.1)',
              borderRadius: 2,
            },
          },
        }}
      >
        {groups.map((group: ITicketGroup, key: number) => {
          if (group.tickets?.length)
            return (
              <React.Fragment key={key}>
                <Box
                  sx={{
                    py: 1,
                    pl: {
                      xs: 2,
                      sm: 3,
                      md: 4,
                      lg: 5,
                      xl: 7,
                    },
                    pr: 2,
                    background: '#E8E9EF',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    openGroupTickets(group.name);
                  }}
                >
                  <Typography
                    paragraph
                    color="textPrimary"
                    sx={{
                      marginBottom: 0,
                      fontSize: '14px',
                      lineHeight: '19px',
                      fontWeight: 'bold',
                    }}
                  >
                    {group.name}
                  </Typography>
                  <IconButton>{activeOn(group.name) ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}</IconButton>
                </Box>
                <Collapse in={activeOn(group.name)}>
                  <Box
                    sx={{
                      background: {
                        xs: '#FFFFFF',
                        lg: 'inherit',
                      },
                    }}
                  >
                    {group.tickets?.map((ticket: ISavvyTicket, key: number) => {
                      if (ticket.state.toLowerCase() === 'c') return null;
                      if (checkUnread && !ticket.hasUnread) {
                        return null;
                      }
                      return (
                        <Box
                          key={key}
                          py={2}
                          sx={{
                            cursor: 'pointer',
                            px: {
                              xs: 2,
                              sm: 3,
                              md: 4,
                              lg: 5,
                              xl: 7,
                            },
                          }}
                          onClick={() => {
                            history.push(`${PATH_TICKETS_VIEW}/${ticket.id}`);
                          }}
                        >
                          {!!ticket.unreadCount && (
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              {ticket.unreadCount && (
                                <Badge
                                  badgeContent={ticket.unreadCount}
                                  color="primary"
                                  sx={{
                                    mr: 3,
                                    '& .MuiBadge-badge': {
                                      transform: 'scale(1) translate(0%, -50%)',
                                    },
                                  }}
                                  anchorOrigin={{
                                    horizontal: 'left',
                                    vertical: 'top',
                                  }}
                                ></Badge>
                              )}
                              <Typography
                                variant="body1"
                                sx={{
                                  fontSize: '16px',
                                  lineHeight: '22px',
                                  color: 'text.secondary',
                                }}
                              >
                                {ticket.subject}
                              </Typography>
                            </Box>
                          )}
                          {!ticket.unreadCount && (
                            <Typography
                              variant="body1"
                              sx={{
                                fontSize: '16px',
                                lineHeight: '22px',
                                color: 'text.secondary',
                              }}
                            >
                              {ticket.subject}
                            </Typography>
                          )}
                          {ticket?.aircraft && (
                            <Typography
                              sx={{
                                fontSize: '12px',
                                fontWeight: 600,
                              }}
                            >
                              <span style={{ fontWeight: 400 }}>Client: </span>
                              {`${ticket?.aircraft?.registration} ${ticket?.aircraft?.owner?.firstName} ${ticket?.aircraft?.owner?.lastName}`}
                            </Typography>
                          )}
                          {!!ticket?.posts?.length && ticket?.posts[ticket.posts.length - 1] && (
                            <Tooltip title={ticket.updated.toString()} placement="right-start">
                              {RenderLastPost(ticket?.posts[ticket.posts.length - 1], ticket?.lastPoster)}
                            </Tooltip>
                          )}
                          <Box></Box>
                        </Box>
                      );
                    })}
                  </Box>
                </Collapse>
              </React.Fragment>
            );
          else {
            return null;
          }
        })}
      </Box>
    </Box>
  );
};

export default MobilePtd;
