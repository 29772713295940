import { useState, useEffect } from 'react';

import { useLazyQuery } from '@apollo/client';
import { QUERY_FUZZY_AIRCRAFT_ENGINE_MONITOR_MODELS } from 'gql';

import { IOptionIdName } from 'types';

import { isEmpty } from 'helpers';

interface IUseFuzzyEngineMonitorModels {
  engineMonitorModel: IOptionIdName; // Input
  engineMonitorManufacturerId: string; // Input value (text field value which triggers re-fetch sugestions)
  engineMonitorModelInput: string;
}

// Get Aircraft Engine Monitor Models Suggestions (fuzzy). When type in input
const useFuzzyEngineMonitorModels = (props: IUseFuzzyEngineMonitorModels) => {
  const { engineMonitorModel, engineMonitorManufacturerId, engineMonitorModelInput } = props;

  // Suggestions Initial State
  const [fuzzy, setFuzzy] = useState<IOptionIdName[]>([]);

  // GraphQL Definition
  const [refetch, { data }] = useLazyQuery(QUERY_FUZZY_AIRCRAFT_ENGINE_MONITOR_MODELS, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    // Success
    setFuzzy(data?.fuzzyEngineMonitorModels ? data.fuzzyEngineMonitorModels : []); // Set suggestions array - id/name
  }, [data]);

  // Refetch Suggestions. When input changed
  useEffect(() => {
    // If input is empty do NOT refetch
    if (isEmpty(engineMonitorModelInput) || engineMonitorModelInput === '-- Add your own --') {
      return setFuzzy([]); // Clean suggestions and stop (return) (do NOT refetch)
    }
    refetch({
      variables: {
        search: engineMonitorModelInput,
        manufacturerId: isEmpty(engineMonitorManufacturerId) ? 100000 : engineMonitorManufacturerId,
      },
    });
  }, [engineMonitorModel, refetch, engineMonitorModelInput, engineMonitorManufacturerId]);

  return fuzzy;
};

export default useFuzzyEngineMonitorModels;
