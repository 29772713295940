import React, { useState } from 'react';
import { Box, Collapse, Typography, IconButton, TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { ICannedResponse } from 'types';

interface CannedResponseControlProps {
  cannedResponses: ICannedResponse[];
  setCannedResponseText: (value: string) => void;
}

const CannedResponseControl: React.FC<CannedResponseControlProps> = (props) => {
  let { cannedResponses, setCannedResponseText } = props;

  cannedResponses = cannedResponses.filter((item) => item.title.startsWith('Borescope Analysis - ', 0) === false);

  const [showList, setShowList] = useState(false);

  const [searchText, setSearchText] = useState('');

  return (
    <Box>
      <Box
        sx={{
          py: 2,
          width: '100%',
          px: {
            xs: 2,
            sm: 3,
            md: 4,
            lg: 5,
          },
          background: 'rgba(245, 246, 250, 0.5)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: 'pointer',
        }}
        onClick={() => {
          setShowList(!showList);
        }}
      >
        <Typography
          sx={{
            color: 'text.secondary',
            fontWeight: 700,
            fontSize: '18px',
            lineHeight: '25px',
          }}
        >
          Canned Responses
          <Box
            component={'span'}
            sx={{
              marginLeft: '10px',
              backgroundColor: 'grey.400',
              padding: '0 5px',
              borderRadius: '4px',
              color: 'background.default',
              fontSize: '13px',
              fontWeight: 600,
              lineHeight: '18px',
            }}
          >
            {cannedResponses.length}
          </Box>
        </Typography>
        <IconButton>{showList ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}</IconButton>
      </Box>
      <Collapse in={showList}>
        <Box
          sx={{
            width: '100%',
            px: {
              xs: 2,
              sm: 3,
              md: 4,
              lg: 5,
            },
            py: 1,
          }}
        >
          <Box py={1}>
            <TextField
              label="Search"
              name="search"
              value={searchText}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              fullWidth
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSearchText(e.target.value);
              }}
              sx={{
                marginBottom: 0,
              }}
            />
          </Box>
          {cannedResponses
            .filter((cannedResponses: ICannedResponse) => {
              if (searchText === '') {
                return cannedResponses;
              } else if (
                cannedResponses.title.toLowerCase().includes(searchText.toLowerCase()) &&
                !cannedResponses.title.startsWith('Borescope Analysis - ')
              ) {
                return cannedResponses;
              } else {
                return null;
              }
            })
            .map((cannedResponse: ICannedResponse, key: number) => {
              return (
                <Typography
                  sx={{
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    fontWeight: 600,
                    py: 1,
                  }}
                  key={key}
                  onClick={() => {
                    setCannedResponseText(cannedResponse.text);
                    setShowList(false);
                  }}
                >
                  {cannedResponse.title}
                </Typography>
              );
            })}
        </Box>
      </Collapse>
    </Box>
  );
};

export default CannedResponseControl;
