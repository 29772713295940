import React from 'react';
import { Dialog, Box, Typography, DialogContent, Button } from '@mui/material';

interface WarningCylindersDialogProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  onSubmitAction: () => void;
  cylinderList: number[];
  notTagged: boolean;
}

const WarningCylindersDialog: React.FC<WarningCylindersDialogProps> = (props) => {
  const { open, setOpen, onSubmitAction, cylinderList, notTagged } = props;

  const makeCylinderText = (list: number[]) => {
    let text = `${list[0]}`;
    if (list.length === 1) {
      return `${list[0]}`;
    }
    if (list.length === 2) {
      return `${list[0]}, ${list[1]}`;
    }

    for (let i = 1; i < list.length - 1; i++) {
      text += `, ${list[i]}`;
    }

    text += ` and ${list[list.length - 1]}`;
    return text;
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async () => {
    setOpen(false);
    onSubmitAction();
  };

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={handleClose}
      PaperProps={{
        sx: {
          maxWidth: '450px',
        },
      }}
    >
      <DialogContent>
        <Box>
          {!notTagged && (
            <Typography
              variant="body1"
              align="center"
              sx={{
                fontSize: '18px',
                lineHeight: '24px',
                mb: 4,
              }}
            >
              There are no uploaded images for cylinders: {makeCylinderText(cylinderList)}.<br />
              Are you sure you want to continue?
            </Typography>
          )}
          {notTagged && (
            <Typography
              variant="body1"
              align="center"
              sx={{
                fontSize: '18px',
                lineHeight: '24px',
                mb: 4,
              }}
            >
              There are uploaded images not tagged cylinder or location.
              <br />
              You can't continue without tagging.
            </Typography>
          )}
          <Box
            sx={{
              textAlign: 'center',
            }}
          >
            <Button
              variant="outlined"
              sx={{
                width: '100px',
              }}
              color="warning"
              onClick={handleClose}
            >
              Go back
            </Button>
            {!notTagged && (
              <Button
                color="primary"
                variant="contained"
                sx={{
                  width: '100px',
                  ml: 2,
                }}
                onClick={() => {
                  onSubmit();
                }}
              >
                Continue
              </Button>
            )}
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default WarningCylindersDialog;
