import React from 'react';

import { CircularProgress } from '@mui/material';

import styles from './style.module.scss';

const PageLoading: React.FC = () => {
  return (
    <div className={styles.pageLoading}>
      <CircularProgress color="primary" size={50} variant="indeterminate" />
    </div>
  );
};

export default PageLoading;
