import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button, TextField, MenuItem } from '@mui/material';
import { useMutation } from '@apollo/client';
import { MUTATION_CREATE_ACCOUNT_SUPPORT_TAG } from 'gql';
import { IDropDownOption } from 'types';
import { setAlert } from 'state';

interface AccountSupportTagProps {
  handleClose: () => void;
  setLoading: (value: boolean) => void;
  ticketId: string;
  aircrafts: IDropDownOption[];
}

const AccountSupportTag: React.FC<AccountSupportTagProps> = (props) => {
  const { ticketId, setLoading, handleClose, aircrafts } = props;

  const [createAccountSupportTag, { data: dataAccountSupportTag, error: errorAccountSupportTag, loading: loadingAccountSupportTag }] =
    useMutation(MUTATION_CREATE_ACCOUNT_SUPPORT_TAG);
  const dispatch = useDispatch();
  const [aircraftId, setAircraftId] = useState('');
  const [aircraftError, setAircraftError] = useState('');

  const onSubmit = async () => {
    if (!aircraftId) {
      setAircraftError('Please select aircraft');
      return;
    }

    await createAccountSupportTag({
      variables: {
        ticketId,
        aircraftId,
      },
    });
  };

  useEffect(() => {
    if (errorAccountSupportTag) {
      dispatch(setAlert('error', 'Unable to create account support tag'));
    } else if (dataAccountSupportTag && dataAccountSupportTag.createAccountSupportTag?.ok) {
      dispatch(setAlert('success', 'Created account support tag'));
      handleClose();
    }
  }, [errorAccountSupportTag, dataAccountSupportTag, dispatch, handleClose]);

  useEffect(() => {
    setLoading(loadingAccountSupportTag);
  }, [loadingAccountSupportTag, setLoading]);

  return (
    <React.Fragment>
      {!!aircrafts.length && (
        <Box py={1}>
          <TextField
            select
            label="Select Aircraft"
            name="aircraft"
            value={aircraftId}
            onChange={(e) => {
              setAircraftId(e.target.value);
              setAircraftError('');
            }}
            fullWidth
            error={!!aircraftError}
            helperText={aircraftError ? aircraftError : ''}
            FormHelperTextProps={{
              sx: {
                ml: 0,
              },
            }}
          >
            {aircrafts.map((aircraft: IDropDownOption, key: number) => {
              return (
                <MenuItem key={key} value={aircraft.id}>
                  {aircraft.name}
                </MenuItem>
              );
            })}
          </TextField>
        </Box>
      )}
      <Box
        sx={{
          textAlign: 'right',
        }}
        py={1}
      >
        <Button
          variant="outlined"
          sx={{
            width: '100px',
            mr: 2,
          }}
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          sx={{
            width: '100px',
          }}
          onClick={() => {
            onSubmit();
          }}
        >
          Create
        </Button>
      </Box>
    </React.Fragment>
  );
};

export default AccountSupportTag;
