import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Dialog, Box, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';

import { QUERY_SAVVY_REPORT_CARD_DETAIL } from 'gql';
import { useLazyQuery } from '@apollo/client';
import { setAlert } from 'state';

import { ComponentLoading } from 'components';

import { ReportContext } from 'pages/Reports/components';

interface ViewReportCardDialogProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  aircraftId: string;
  reportCardId: string;
}

const ViewReportCardDialog: React.FC<ViewReportCardDialogProps> = (props) => {
  const { open, setOpen, aircraftId, reportCardId } = props;
  const [fetchReportCard, { data, error, loading }] = useLazyQuery(QUERY_SAVVY_REPORT_CARD_DETAIL);
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (open && aircraftId && reportCardId) {
      fetchReportCard({
        variables: {
          aircraftId,
          reportCardId,
        },
      });
    }
  }, [open, aircraftId, reportCardId, fetchReportCard]);

  useEffect(() => {
    if (error) {
      dispatch(setAlert('error', 'Unable to load report card'));
      setOpen(false);
    }
  }, [error, dispatch, setOpen]);

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={handleClose}
      PaperProps={{
        sx: {
          maxWidth: '1048px',
        },
      }}
    >
      <DialogTitle
        sx={{
          textAlign: 'center',
          fontSize: '24px',
          fontWeight: 700,
        }}
      >
        {!!data?.savvy?.aircraft?.reportCards?.length && data?.savvy?.aircraft?.reportCards[0]?.kind === 'TA'
          ? 'View Trend Analysis'
          : 'View Report Card'}
      </DialogTitle>
      <DialogContent>
        <ComponentLoading loading={loading}>
          <Box py={2}>
            {!!data?.savvy?.aircraft?.reportCards?.length && (
              <ReportContext reportContext={data?.savvy?.aircraft?.reportCards[0]?.context} reportId={reportCardId} />
            )}
          </Box>
        </ComponentLoading>
      </DialogContent>
      <DialogActions>
        <Box
          py={1}
          sx={{
            textAlign: 'center',
          }}
        >
          <Button variant="contained" onClick={handleClose}>
            Close
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ViewReportCardDialog;
