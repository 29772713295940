import React from 'react';
import { Box, Collapse, Typography } from '@mui/material';
import { ICylinderAndSubject, IBorescopeImageFileType } from 'types';
import EastIcon from '@mui/icons-material/East';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import CloseIcon from '@mui/icons-material/Close';
import { convertTagNameToLabel } from 'helpers';
import { NORMAL_STATUS } from 'const';

interface CylinderListProps {
  subjectList: string[][];
  cylinderCount: number;
  selectedIndex: ICylinderAndSubject;
  setSelectedIndex: (value: ICylinderAndSubject) => void;
  images: IBorescopeImageFileType[];
}

const CylinderList: React.FC<CylinderListProps> = (props) => {
  const { subjectList, cylinderCount, selectedIndex, setSelectedIndex, images } = props;

  const matchedImage = (cylinder: number, subject: string) => {
    const matched = images.find((file) => file.cylinder === cylinder && file.subject === subject);
    return matched;
  };

  const getIcon = (cylinder: number, subject: string, selectedSubject: string) => {
    const matched = images.find((file) => file.cylinder === cylinder && file.subject === subject);
    if (!matched) {
      return (
        <CloseIcon
          sx={{
            color: 'info.main',
          }}
        />
      );
    } else {
      if (selectedSubject === subject) {
        return (
          <EastIcon
            sx={{
              color: 'primary.main',
            }}
          />
        );
      }
      if (!matched.status?.length) {
        return (
          <CircleOutlinedIcon
            sx={{
              color: 'grey.600',
            }}
          />
        );
      } else if (matched.status.includes(NORMAL_STATUS)) {
        return (
          <SentimentSatisfiedAltIcon
            sx={{
              color: 'success.main',
            }}
          />
        );
      } else {
        return (
          <SentimentVeryDissatisfiedIcon
            sx={{
              color: 'error.main',
            }}
          />
        );
      }
    }
  };

  return (
    <Box
      py={4}
      sx={{
        height: '100%',
        overflowY: 'auto',
      }}
    >
      {Array.apply(null, Array(cylinderCount)).map((item, key: number) => {
        return (
          <Box
            key={key}
            sx={{
              pt: key === 0 ? 0 : 0.5,
              pb: key === cylinderCount - 1 ? 0 : 0.5,
              backgroundColor: 'background.default',
            }}
          >
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  px: 5,
                  backgroundColor: selectedIndex.cylinder === key + 1 ? '#D9DBE3' : 'grey.200',
                  cursor: 'pointer',
                  py: 1.5,
                  justifyContent: 'space-between',
                  width: '280px',
                  opacity: images.filter((image) => image.cylinder === key + 1).length ? 1 : 0.5,
                }}
                onClick={() => {
                  if (images.filter((image) => image.cylinder === key + 1).length) {
                    setSelectedIndex({
                      cylinder: key + 1,
                      subject: subjectList[0][0],
                    });
                  }
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      color: 'text.secondary',
                      fontWeight: 700,
                      fontSize: '16px',
                      lineHeight: '22px',
                    }}
                  >
                    Cylinder {key + 1}
                  </Typography>
                  <Typography>
                    {images.filter((image) => image.cylinder === key + 1).length
                      ? images.filter((image) => image.cylinder === key + 1).length
                      : 'No'}{' '}
                    photos
                  </Typography>
                </Box>
                {selectedIndex.cylinder === key + 1 && <KeyboardArrowUpIcon />}
                {selectedIndex.cylinder !== key + 1 && <KeyboardArrowDownIcon />}
              </Box>
              <Collapse in={selectedIndex.cylinder === key + 1}>
                <Box
                  sx={{
                    backgroundColor: 'grey.800',
                    width: '280px',
                  }}
                >
                  {subjectList.map((item: string[], index: number) => {
                    return (
                      <Box
                        key={index}
                        sx={{
                          cursor: 'pointer',
                          backgroundColor: selectedIndex.cylinder === key + 1 && selectedIndex.subject === item[0] ? 'grey.500' : 'none',
                          display: 'flex',
                          py: 1.25,
                          alignItems: 'flex-start',
                          px: 5,
                        }}
                        onClick={() => {
                          if (matchedImage(key + 1, item[0])) {
                            setSelectedIndex({
                              cylinder: key + 1,
                              subject: item[0],
                            });
                          }
                        }}
                      >
                        {getIcon(key + 1, item[0], selectedIndex.subject)}
                        <Box ml={1.25}>
                          <Typography
                            sx={{
                              fontWeight: selectedIndex.cylinder === key + 1 && selectedIndex.subject === item[0] ? 700 : 500,
                              color: matchedImage(key + 1, item[0]) ? 'text.secondary' : 'grey.400',
                              fontSize: '15px',
                              lineHeight: '21px',
                              textTransform: 'capitalize',
                            }}
                          >
                            {item[1]}
                          </Typography>
                          {!!matchedImage(key + 1, item[0])?.status?.length && (
                            <Typography
                              sx={{
                                color: 'text.disabled',
                                fontSize: '12px',
                                lineHeight: '17px',
                              }}
                            >
                              {matchedImage(key + 1, item[0])
                                ?.status?.map((value) => convertTagNameToLabel(value))
                                ?.join(' | ')}
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              </Collapse>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default CylinderList;
