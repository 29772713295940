import React, { useState } from 'react';
import { Box, Typography, Popover, MenuList, MenuItem } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

interface ChartNumSettingsProps {
  chartNum: number;
  setChartNum: (value: number) => void;
}

const ChartNumSettings: React.FC<ChartNumSettingsProps> = (props) => {
  const { chartNum: numOfChart, setChartNum: setNumOfChart } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <React.Fragment>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          borderRight: '2px solid',
          borderRightColor: 'grey.600',
          pr: 1,
        }}
        onClick={handleClick}
      >
        {numOfChart === 1 && (
          <React.Fragment>
            <Typography
              sx={{
                fontSize: '13px',
              }}
            >
              One Chart
            </Typography>
            <ArrowDropDownIcon
              sx={{
                color: 'text.secondary',
                width: '15px',
                height: '15px',
              }}
            />
          </React.Fragment>
        )}
        {numOfChart === 2 && (
          <React.Fragment>
            <Typography
              sx={{
                fontSize: '13px',
              }}
            >
              Two Charts
            </Typography>
            <ArrowDropDownIcon
              sx={{
                color: 'text.secondary',
                width: '15px',
                height: '15px',
              }}
            />
          </React.Fragment>
        )}
        {numOfChart === 3 && (
          <React.Fragment>
            <Typography
              sx={{
                fontSize: '13px',
              }}
            >
              Three Charts
            </Typography>
            <ArrowDropDownIcon
              sx={{
                color: 'text.secondary',
                width: '15px',
                height: '15px',
              }}
            />
          </React.Fragment>
        )}
        {numOfChart === 4 && (
          <React.Fragment>
            <Typography
              sx={{
                fontSize: '13px',
              }}
            >
              Four Charts
            </Typography>
            <ArrowDropDownIcon
              sx={{
                color: 'text.secondary',
                width: '15px',
                height: '15px',
              }}
            />
          </React.Fragment>
        )}
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <MenuList>
          <MenuItem
            onClick={() => {
              setNumOfChart(1);
              handleClose();
            }}
          >
            <Typography
              sx={{
                color: 'text.secondary',
                fontWeight: 'bold',
                fontSize: '13px',
              }}
            >
              One Chart
            </Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              setNumOfChart(2);
              handleClose();
            }}
          >
            <Typography
              sx={{
                color: 'text.secondary',
                fontWeight: 'bold',
                fontSize: '13px',
              }}
            >
              Two Charts
            </Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              setNumOfChart(3);
              handleClose();
            }}
          >
            <Typography
              sx={{
                color: 'text.secondary',
                fontWeight: 'bold',
                fontSize: '13px',
              }}
            >
              Three Charts
            </Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              setNumOfChart(4);
              handleClose();
            }}
          >
            <Typography
              sx={{
                color: 'text.secondary',
                fontWeight: 'bold',
                fontSize: '13px',
              }}
            >
              Four Charts
            </Typography>
          </MenuItem>
        </MenuList>
      </Popover>
    </React.Fragment>
  );
};

export default ChartNumSettings;
