import React, { useState } from 'react';
import { IconButton, Popover, Dialog, Typography, DialogTitle, DialogContent, Box, Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

interface DeletePopupsProps {
  deleteFlightById: () => void;
}

const DeletePopups: React.FC<DeletePopupsProps> = (props) => {
  const { deleteFlightById } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const [showAlert, setShowAlert] = useState(false);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  const onSubmit = () => {
    deleteFlightById();
    setShowAlert(false);
  };

  return (
    <React.Fragment>
      <IconButton
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
          e.preventDefault();
          e.stopPropagation();
          setAnchorEl(e.currentTarget);
        }}
      >
        <MenuIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
      >
        <Typography
          paragraph
          sx={{
            padding: '10px',
            marginBlock: '0',
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: 'grey.200',
            },
          }}
          onClick={() => {
            setAnchorEl(null);
            setShowAlert(true);
          }}
        >
          Delete Flight
        </Typography>
      </Popover>
      <Dialog open={showAlert} fullWidth onClose={handleCloseAlert}>
        <DialogTitle
          sx={{
            textAlign: 'center',
            fontSize: '24px',
            fontWeight: 700,
          }}
        >
          Are you sure to delete flight?
        </DialogTitle>
        <DialogContent>
          <Box>
            <Box
              sx={{
                textAlign: 'center',
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  width: '100px',
                }}
                onClick={handleCloseAlert}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                variant="contained"
                sx={{
                  width: '100px',
                  ml: 2,
                }}
                onClick={onSubmit}
              >
                Delete
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default DeletePopups;
