import React, { useState } from 'react';
import { Box, Collapse, Typography, IconButton } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { ITimesheet } from 'types';
import { humanDateTime } from 'helpers';

interface TimeManagerProps {
  timesheets: ITimesheet[];
}

const TimeManager: React.FC<TimeManagerProps> = (props) => {
  const { timesheets } = props;

  const [showList, setShowList] = useState(false);

  return (
    <Box>
      <Box
        sx={{
          py: 2,
          width: '100%',
          px: {
            xs: 2,
            sm: 3,
            md: 4,
            lg: 5,
          },
          background: 'rgba(245, 246, 250, 0.5)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: 'pointer',
        }}
        onClick={() => {
          setShowList(!showList);
        }}
      >
        <Typography
          sx={{
            color: 'text.secondary',
            fontWeight: 700,
            fontSize: '18px',
            lineHeight: '25px',
          }}
        >
          Time Logged
          <Box
            component={'span'}
            sx={{
              marginLeft: '10px',
              backgroundColor: 'grey.400',
              padding: '0 5px',
              borderRadius: '4px',
              color: 'background.default',
              fontSize: '13px',
              fontWeight: 600,
              lineHeight: '18px',
            }}
          >
            {timesheets.length}
          </Box>
        </Typography>
        <IconButton>{showList ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}</IconButton>
      </Box>
      <Collapse in={showList}>
        <Box
          sx={{
            width: '100%',
            px: {
              xs: 2,
              sm: 3,
              md: 4,
              lg: 5,
            },
            py: 1,
          }}
        >
          {timesheets.map((timesheet: ITimesheet, key: number) => {
            return (
              <Box key={key} py={1}>
                {Boolean(timesheet?.user?.firstName) && (
                  <Typography
                    sx={{
                      fontWeight: 800,
                      mb: 0.5,
                    }}
                  >
                    {`${timesheet.user.firstName} ${timesheet.user.lastName}`}
                  </Typography>
                )}
                <Typography
                  sx={{
                    mb: 0.5,
                  }}
                >
                  {timesheet.category.name}&nbsp;&nbsp;&nbsp;({timesheet.minutes} minutes)
                </Typography>
                <Typography>{humanDateTime(timesheet.created)}</Typography>
              </Box>
            );
          })}
        </Box>
      </Collapse>
    </Box>
  );
};

export default TimeManager;
