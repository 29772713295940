import React from 'react';
import { CSSObject } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';

interface TicketIconProps {
  sx?: CSSObject;
  fill: string;
}

const TicketIcon: React.FC<TicketIconProps> = (props) => {
  const { sx, fill } = props;

  return (
    <SvgIcon viewBox="0 0 14 20" width="14" height="20" sx={sx ? { ...sx } : {}}>
      <path
        d="M3.33333 15.5556L3.33333 4.44444H10L10 15.5556H3.33333ZM6.66667 1.66667C7.58715 1.66667 8.33333 0.920486 8.33333 0H11.6667C12.5872 0 13.3333 0.74618 13.3333 1.66667L13.3333 18.3333C13.3333 19.2538 12.5872 20 11.6667 20H8.33333C8.33333 19.0795 7.58715 18.3333 6.66667 18.3333C5.74618 18.3333 5 19.0795 5 20H1.66667C0.74618 20 0 19.2538 0 18.3333L0 1.66667C0 0.74618 0.74618 0 1.66667 0H5C5 0.920486 5.74618 1.66667 6.66667 1.66667ZM3.05556 3.33333C2.59531 3.33333 2.22222 3.70642 2.22222 4.16667L2.22222 15.8333C2.22222 16.2936 2.59531 16.6667 3.05556 16.6667H10.2778C10.738 16.6667 11.1111 16.2936 11.1111 15.8333L11.1111 4.16667C11.1111 3.70642 10.738 3.33333 10.2778 3.33333H3.05556Z"
        fill={fill}
      />
    </SvgIcon>
  );
};

export default TicketIcon;
