import React, { Fragment } from 'react';

import { TextField, FormGroup } from '@mui/material';
import { default as MuiAutocomplete, createFilterOptions } from '@mui/material/Autocomplete';

import { InputLoading, InputError } from 'components';

import { hasInputError } from 'helpers';

import { IInputError, IFormatOption, IGetOptionLabel } from 'types';

import { ADD_OPTION_CLICKED_NO_VALUE, ADD_OPTION_CLICKED_TYPED_VALUE } from 'const';

interface IAutocomplete {
  value: any;
  name: string;
  onChange: (option: any) => void;
  onClick?: () => void;
  error: IInputError;
  options: any[];
  label: string;
  formatOption: IFormatOption;
  getOptionLabel: IGetOptionLabel;
  dialog?: React.ReactNode;
  disabled?: boolean;
  loading?: boolean;
  addOptionLabel?: string;
  placeholder?: string;
  renderOptionFlag?: boolean;
}

const Autocomplete: React.FC<IAutocomplete> = (props) => {
  const {
    value,
    name,
    error,
    label,
    formatOption,
    options,
    getOptionLabel,
    onChange,
    onClick,
    dialog,
    disabled,
    loading,
    addOptionLabel,
    placeholder = '',
    renderOptionFlag = false,
  } = props;
  const filter = createFilterOptions<any>(); // Filter typed

  const hasError = hasInputError(error);

  return (
    <FormGroup>
      <InputLoading loading={loading}>
        <MuiAutocomplete
          value={value} // useAutocomplete handles value
          options={options === undefined ? [] : options} // Options are undefined during GrapqhQL query. Use empty array
          onChange={(event, option) => onChange(option)} // useAutocomplete handles change
          getOptionLabel={getOptionLabel}
          // renderOption={renderOption && renderOption} // Show add label abd option labels
          filterOptions={(options, params: any) => {
            let filtered = filter(options, params) as any[]; // Array of options filtered by user typing

            // "Add option" feature
            if (addOptionLabel) {
              // "Add Option" In the End of List. If user scroll and no match
              if (!params.inputValue) {
                filtered.splice(-1, 1);
                filtered.push(formatOption('', addOptionLabel, ADD_OPTION_CLICKED_NO_VALUE));
              }

              // "Add typedValue". If user type and no match
              if (params.inputValue !== '') {
                filtered = filtered.filter((option) => option.id !== '');
                filtered.push(
                  // Send type: ADD_OPTION_CLICKED to open Add Option Dialog
                  // Send typed value to get it in Add Option Dialog
                  /*
                    formatOption will store typed value in id (not in name) because name is busy to show "Add Option" in autocomplete dropdown
                    it will return sonething like {id: typedValue, name: Add Option...}
                  type: ADD_OPTION_CLICKED_TYPED_VALUE used to open Add Dialog
                  */
                  formatOption('', `Add ${params.inputValue}`, ADD_OPTION_CLICKED_TYPED_VALUE),
                );
              }
            }

            return filtered;
          }}
          isOptionEqualToValue={(option: any, value: any) => {
            return option === value || option.id === value.id;
          }}
          renderInput={(params) => (
            <Fragment>
              <TextField
                {...params}
                name={name}
                onClick={onClick}
                error={hasError}
                label={label}
                variant="outlined"
                fullWidth
                placeholder={placeholder}
              />
              <InputError error={error} hasError={hasError} />
            </Fragment>
          )}
          renderOption={
            renderOptionFlag
              ? (props, option) => (
                  <li {...props} key={option.id}>
                    {option.name}
                  </li>
                )
              : undefined
          }
          disabled={disabled || loading}
          clearOnBlur
          data-testid={name}
        />
      </InputLoading>

      {/* If dialog passed - render it */}
      {dialog && dialog}
    </FormGroup>
  );
};

export default Autocomplete;
