import React from 'react';
import { Box, Typography, Link } from '@mui/material';

interface InfoLineProps {
  title: string;
  value: string;
  link?: string;
  isSubscription?: boolean;
}

const PARSER_LINK = '/files/:fileId/log';

const InfoLine: React.FC<InfoLineProps> = (props) => {
  const { title, value, link = '', isSubscription = false } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        py: 1,
      }}
    >
      <Typography
        sx={{
          width: '40%',
          color: 'grey.600',
          fontWeight: 600,
        }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          width: '60%',
          color: isSubscription ? 'success.dark' : 'text.secondary',
          fontSize: '14px',
          lineHeight: '19px',
        }}
      >
        {value}
        {Boolean(link) && (
          <React.Fragment>
            <br />
            <Link href={PARSER_LINK.replace(':fileId', link)} target="_blank">
              View Parser Logs
            </Link>
          </React.Fragment>
        )}
      </Typography>
    </Box>
  );
};

export default InfoLine;
