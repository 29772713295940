import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, styled, Typography } from '@mui/material';
import { FileUploader } from 'react-drag-drop-files';

import { QUERY_QIF_STATUS } from 'gql';
import { useLazyQuery } from '@apollo/client';

import { ComponentLoading } from 'components';
import { setAlert } from 'state';
import { uploadQifFile } from 'helpers';

import { useTimer } from 'hooks';
import { FLIGHT_FILE_MAX_SIZE } from 'const';

const MAX_CALL = 40;
const FETCH_INTERVAL = 15;

const classes = {
  dragDropUpload: 'dragDropUpload',
};

const StyledDiv = styled('div')({
  [`& .${classes.dragDropUpload}`]: {
    height: '240px !important',
    maxWidth: '1000px !important',
    border: '2px dashed #BBC0D4 !important',
    borderRadius: '6px !important',
    '& svg': {
      display: 'none',
    },
    '& div': {
      display: 'block',
      textAlign: 'center',
      '& span': {
        fontSize: '20px',
        fontWeight: 400,
        color: 'rgba(75, 84, 112, 0.5)',
        lineHeight: '27px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& span': {
          textDecoration: 'none',
          maxWidth: '19ch',
          display: 'block',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          marginRight: '5px',
        },
      },
    },
  },
});

const QifUploadForm: React.FC = () => {
  const [isUploading, setIsUploading] = useState(false);
  const [fetchQifStatus, { data: dataQifStatus, loading: loadingQifStatus }] = useLazyQuery(QUERY_QIF_STATUS, {
    fetchPolicy: 'cache-and-network',
  });
  const dispatch = useDispatch();

  const [count, setCount] = useState(0);

  const {
    create: createTimer,
    created: createdTimer,
    clear: clearTimer,
  } = useTimer({
    timeoutValue: FETCH_INTERVAL,
    timerAction: () => {
      fetchQifStatus();
      setCount((count: number) => count + 1);
    },
  });

  const handleChange = async (file: File) => {
    const result = await uploadQifFile(file);

    if (!result.success) {
      dispatch(setAlert('error', 'Unable to upload qif file'));
    }

    setIsUploading(false);
    fetchQifStatus();
  };

  useEffect(() => {
    fetchQifStatus();
  }, [fetchQifStatus]);

  useEffect(() => {
    if (!createdTimer && count === 0) {
      createTimer();
    }
  }, [createTimer, createdTimer, count]);

  useEffect(() => {
    if (count > MAX_CALL) {
      clearTimer();
    }
  }, [clearTimer, count]);

  return (
    <Box py={1}>
      <ComponentLoading loading={loadingQifStatus || isUploading}>
        <StyledDiv
          sx={{
            mb: 3.5,
          }}
        >
          <FileUploader
            handleChange={async (file: File) => {
              setIsUploading(true);
              await handleChange(file);
            }}
            name={'qif_import'}
            label={`Drag and Drop file here, or click to upload to QIF file`}
            classes={[classes.dragDropUpload]}
            maxSize={FLIGHT_FILE_MAX_SIZE}
            multiple={false}
          />
        </StyledDiv>
        {!!dataQifStatus?.qifStatus?.length && (
          <Box py={0.5}>
            {dataQifStatus.qifStatus.map((item: any, key: number) => {
              return (
                <Box py={0.5} key={key}>
                  <Typography>
                    {item.s3Key}: {item.status}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        )}
      </ComponentLoading>
    </Box>
  );
};

export default QifUploadForm;
