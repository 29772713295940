import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Box, Typography, Button, IconButton, Link } from '@mui/material';
import { DataGridPro, GridValueGetterParams, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid-pro';
import DeleteIcon from '@mui/icons-material/Delete';
import { IBorescopeImageSet } from 'types';
import dayjs from 'dayjs';
import { SINGLE_ENGINE, PATH_SAVVY_AIRCRAFT, PATH_SAVVY_BORESCOPE_ANALYSIS_REPORT_VIEW } from 'const';
import { getEngineNameFromBorescopeLabel } from 'helpers';
import { AddBorescopeImageSetDialog } from 'pages/BorescopeUpload/components';
import { MUTATION_DELETE_BORESCOPE_IMAGE_SET } from 'gql';
import { useMutation } from '@apollo/client';
import { DeleteDialog, ComponentLoading } from 'components';
import { ctrlCmdClick } from 'helpers';

import { setAlert } from 'state';

interface BorescopeImageSetsListProps {
  imageSets: IBorescopeImageSet[];
  aircraftId: string;
  enginesCount: string;
  setSuccess: (value: boolean) => void;
  cylinderCount: number;
}

const BorescopeImageSetsList: React.FC<BorescopeImageSetsListProps> = (props) => {
  const { imageSets, aircraftId, enginesCount, setSuccess, cylinderCount } = props;

  const [pageSize, setPageSize] = useState(10);
  const history = useHistory();
  const dispatch = useDispatch();

  const [showCreate, setShowCreate] = useState(false);

  const [deleteImageSet, { data: dataDeleteSet, loading: loadingDeleteSet, error: errorDeleteSet }] =
    useMutation(MUTATION_DELETE_BORESCOPE_IMAGE_SET);

  const [showDelete, setShowDelete] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [deleteName, setDeleteName] = useState('');

  const deleteBorescopeImageSet = async () => {
    if (!deleteId) {
      return;
    }
    await deleteImageSet({
      variables: {
        borescopeImageSetId: deleteId,
      },
    });
  };

  useEffect(() => {
    if (errorDeleteSet) {
      dispatch(setAlert('error', 'Unable to delete selected borescope image set'));
    } else if (dataDeleteSet) {
      const { ok, error } = dataDeleteSet.deleteBorescopeImageSet;
      if (ok) {
        dispatch(setAlert('success', 'Deleted borescope image set'));
        setSuccess(true);
      } else {
        dispatch(setAlert('error', error));
      }
    }
  }, [dispatch, errorDeleteSet, dataDeleteSet]);

  return (
    <Box py={2}>
      <ComponentLoading loading={loadingDeleteSet}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant="h3">Image Sets</Typography>
          <Button
            sx={{
              ml: 2,
            }}
            variant="contained"
            onClick={() => {
              setSuccess(false);
              setShowCreate(true);
            }}
          >
            + Add Image Set
          </Button>
        </Box>
        <Box>
          <DataGridPro
            sx={{
              width: '100%',
              minWidth: '100px',
              '& .MuiDataGrid-columnSeparator': {
                display: 'none',
              },
              '& .MuiDataGrid-cell:focus': {
                outline: 'none',
              },
              '& .MuiDataGrid-row': {
                cursor: 'pointer',
                borderBottom: '1px solid',
                borderColor: 'grey.200',
              },
              '& .MuiDataGrid-columnHeader:focus': {
                outline: 'none',
              },
              '& .MuiDataGrid-cellContent': {
                whiteSpace: 'break-spaces',
              },
              border: 'none',
            }}
            autoHeight
            disableColumnResize={true}
            disableSelectionOnClick={true}
            rowsPerPageOptions={[5, 10, 25, 100]}
            pageSize={pageSize}
            onPageSizeChange={(newPage) => setPageSize(newPage)}
            pagination
            showColumnRightBorder={false}
            disableColumnMenu={true}
            rows={[...imageSets].sort((item1: IBorescopeImageSet, item2: IBorescopeImageSet) => {
              if (item1.status !== 'CREATED' && item2.status !== 'CREATED') {
                return item1.created > item2.created ? -1 : 1;
              } else if (item1.status !== 'created') {
                return -1;
              } else if (item2.status !== 'created') {
                return 1;
              } else {
                return item1.created > item2.created ? -1 : 1;
              }
            })}
            columnVisibilityModel={{
              engine: enginesCount !== SINGLE_ENGINE,
            }}
            columns={[
              {
                field: 'name',
                headerName: 'Name',
                flex: 1,
              },
              {
                field: 'created',
                headerName: 'Created',
                valueGetter: (params: GridValueGetterParams) => {
                  return params?.value ? dayjs(params.value).format('YYYY-MM-DD') : '';
                },
                flex: 1,
              },
              {
                field: 'updated',
                headerName: 'Updated',
                valueGetter: (params: GridValueGetterParams) => {
                  return params?.value ? dayjs(params.value).format('YYYY-MM-DD') : '';
                },
                flex: 1,
              },
              {
                field: 'engine',
                headerName: 'Engine',
                valueGetter: (params: GridValueGetterParams) => {
                  return params?.value ? getEngineNameFromBorescopeLabel(params.value) : '';
                },
                flex: 1,
              },
              {
                field: 'clientComments',
                headerName: 'Client Comments',
                flex: 1,
              },
              {
                field: 'status',
                headerName: 'Status',
                renderCell: (params: GridRenderCellParams<string>) => {
                  const status = params.value;
                  return (
                    <Box
                      sx={{
                        p: '5px 10px',
                        borderRadius: '6px',
                        backgroundColor: status === 'IN_PROGRESS' ? '#F7E8D3' : status === 'REPORT_SENT' ? '#F5F6FA' : '#D1F2D8',
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 700,
                          color: status === 'IN_PROGRESS' ? '#D68E21' : status === 'REPORT_SENT' ? 'text.primary' : '#4C8F5B',
                        }}
                      >
                        {status?.replace('_', ' ')}
                      </Typography>
                    </Box>
                  );
                },
                flex: 1,
              },
              {
                field: 'report',
                headerName: 'Report',
                renderCell: (params: GridRenderCellParams<any>) => {
                  const { row } = params;
                  return row.status === 'REPORT_SENT' ? (
                    <Link
                      href={`${PATH_SAVVY_BORESCOPE_ANALYSIS_REPORT_VIEW}/${aircraftId}/${row.id}`}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        ctrlCmdClick(e, `${PATH_SAVVY_BORESCOPE_ANALYSIS_REPORT_VIEW}/${aircraftId}/${row.id}`, history);
                      }}
                    >
                      View Report
                    </Link>
                  ) : null;
                },
                flex: 1,
              },
              {
                field: 'id',
                headerName: 'Actions',
                sortable: false,
                flex: 0.8,
                renderCell: (params: GridRenderCellParams<string>) => {
                  return (
                    <Box>
                      <IconButton
                        title="Delete"
                        onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setDeleteId(params.value || '');
                          setDeleteName(params.row?.name || '');
                          setShowDelete(true);
                          setSuccess(false);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  );
                },
              },
            ]}
            onRowClick={(params: GridRowParams) => {
              const { row } = params;
              if (row?.id) {
                history.push(`${PATH_SAVVY_AIRCRAFT}/${aircraftId}/borescope-image-sets?setId=${row.id}`);
              } else {
                return false;
              }
            }}
          />
        </Box>
      </ComponentLoading>
      <AddBorescopeImageSetDialog
        open={showCreate}
        setOpen={setShowCreate}
        setSuccess={setSuccess}
        aircraftId={aircraftId}
        isSingle={enginesCount === SINGLE_ENGINE}
        cylinderCount={cylinderCount}
      />
      <DeleteDialog
        open={showDelete}
        setOpen={setShowDelete}
        onSubmitAction={deleteBorescopeImageSet}
        title={`Delete "${deleteName}"?`}
        text="Are you sure?"
      />
    </Box>
  );
};

export default BorescopeImageSetsList;
