import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Link } from '@mui/material';
import { LayoutDashboard, PageLoading } from 'components';
import { MUTATION_VERIFY_TECHNICIAN } from 'gql';
import { useMutation } from '@apollo/client';
import { PATH_UPDATE_TECHNICIAN } from 'const';

import { setAlert } from 'state';

const VerifyTechnician: React.FC = () => {
  const [verifyTechnician, { data: dataVerify, loading: loadingVerify, error: errorVerify }] = useMutation(MUTATION_VERIFY_TECHNICIAN);
  const dispatch = useDispatch();
  const history = useHistory();

  const { id: contactId } = useSelector((state: any) => state.auth);

  useEffect(() => {
    if (contactId) {
      verifyTechnician({
        variables: {
          contactId,
        },
      });
    }
  }, [contactId]);

  useEffect(() => {
    if (errorVerify) {
      dispatch(setAlert('error', 'Unable to verify service center'));
    } else if (dataVerify) {
      const { ok, error } = dataVerify.verifyTechnician;
      if (!ok) {
        dispatch(setAlert('error', error));
      }
    }
  }, [errorVerify, dataVerify, dispatch]);

  return (
    <LayoutDashboard backgroundColor="#fff">
      {loadingVerify && <PageLoading />}
      {dataVerify?.verifyTechnician?.ok && (
        <Box p={5}>
          <Typography
            variant="h2"
            sx={{
              color: 'success.main',
            }}
            align="center"
            gutterBottom
          >
            Technician Information Updated Successfully
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Box>
              <Box py={1}>
                <Typography align="center">
                  Thank you for taking the time to verify your information. If you need to, you can also make{' '}
                  <Link
                    href={PATH_UPDATE_TECHNICIAN}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      history.push(PATH_UPDATE_TECHNICIAN);
                    }}
                  >
                    further modifications
                  </Link>
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      {dataVerify?.verifyTechnician && !dataVerify.verifyTechnician?.ok && (
        <Box p={5}>
          <Typography
            variant="h2"
            sx={{
              color: 'error.main',
            }}
            align="center"
            gutterBottom
          >
            You can't verify your information.
          </Typography>
        </Box>
      )}
    </LayoutDashboard>
  );
};

export default VerifyTechnician;
