import {
  SET_POST_TEXT,
  REMOVE_POST_TEXT,
  SET_EXISTING_FILES,
  SET_UPLOADED_FILES,
  SET_TIMESHEETS,
  SET_SNOOZE,
  SET_TICKET_VISIBILITY,
  SET_CLOSE_TICKET_AFTER_POST,
  SET_START_TIME,
  SET_STOP_TIME,
  SET_STOPPED_TIME,
  SET_INITIAL_TIMER_OPTIONS,
  SET_TICKET_ID,
} from 'state/types';

// Initial state (user data)
const initialState = {
  postText: '',
  attachExistingFiles: [],
  uploadedFileList: [],
  timesheets: [],
  snoozeCustom: '',
  visibility: 'M,C',
  storedTicketId: '',
  closeTicketAfterPost: false,
  ticketId: '',
  startTime: new Date().getTime(),
  isStoppedTimer: false,
  stoppedTime: 0,
};

// Reducer
const ticketReducer = (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case SET_TICKET_ID:
      return {
        ...state,
        storedTicketId: payload.id,
      };
    case SET_POST_TEXT:
      return {
        ...state,
        postText: payload.postText,
      };
    case REMOVE_POST_TEXT:
      return {
        ...state,
        postText: '',
      };
    case SET_EXISTING_FILES:
      return {
        ...state,
        attachExistingFiles: payload.attachExistingFiles,
      };
    case SET_UPLOADED_FILES:
      return {
        ...state,
        uploadedFileList: payload.uploadedFileList,
      };
    case SET_TIMESHEETS:
      return {
        ...state,
        timesheets: payload.timesheets,
      };
    case SET_SNOOZE:
      return {
        ...state,
        snoozeCustom: payload.snoozeCustom,
      };
    case SET_TICKET_VISIBILITY:
      return {
        ...state,
        visibility: payload.visibility,
      };
    case SET_CLOSE_TICKET_AFTER_POST:
      return {
        ...state,
        closeTicketAfterPost: payload.closeTicketAfterPost,
      };
    case SET_START_TIME:
      return {
        ...state,
        ticketId: payload.ticketId,
        startTime: payload.startTime,
      };
    case SET_STOP_TIME:
      return {
        ...state,
        isStoppedTimer: payload.isStoppedTimer,
      };
    case SET_STOPPED_TIME:
      return {
        ...state,
        stoppedTime: payload.stoppedTime,
      };
    case SET_INITIAL_TIMER_OPTIONS:
      return {
        ...state,
        startTime: payload.startTime,
        ticketId: payload.ticketId,
        isStoppedTimer: false,
        stoppedTime: 0,
      };
    default:
      return state;
  }
};

export default ticketReducer;
