import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import MapGL, { NavigationControl, MapRef, Source, Layer, Popup, MapLayerMouseEvent } from 'react-map-gl';
import { GeoJSONSourceRaw } from 'mapbox-gl';
import WebMercatorViewport from 'viewport-mercator-project';
import * as GeoJSON from 'geojson';
import { default as GeocoderControl } from './GeocoderControl';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import 'mapbox-gl/dist/mapbox-gl.css';

import { IServiceCenter } from 'types';

import mapboxgl from 'mapbox-gl';

if (process.env.NODE_ENV !== 'test') {
  // @ts-ignore
  // eslint-disable-next-line import/no-webpack-loader-syntax
  mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;
}

interface IGeodata {
  type: string;
  geometry: {
    type: string;
    coordinates: number[];
  };
  properties: {
    title: string;
    description: string;
    type: string;
    color: string;
  };
}

type IPoints = {
  color?: string;
  points?: GeoJSON.Feature<GeoJSON.Geometry> | GeoJSON.FeatureCollection<GeoJSON.Geometry> | undefined;
};

interface ICoordinate {
  latitude: string;
  longitude: string;
}

interface MapProps {
  setContainedPoints: (value: IServiceCenter[]) => void;
  handleClickPoint: (value: string) => void;
  serviceCenters: IServiceCenter[];
  selectedColor: string;
  searchedCenter?: IServiceCenter | undefined;
  selectedSC?: IServiceCenter;
  airportCoordinates?: ICoordinate;
  goToAirport: (airportId: string) => void;
}

const SHOW_RADIUS = 100 * 1609; //far away 100 miles

const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const Map: React.FC<MapProps> = (props) => {
  const {
    setContainedPoints,
    handleClickPoint,
    serviceCenters,
    selectedColor,
    searchedCenter,
    selectedSC = undefined,
    airportCoordinates = undefined,
    goToAirport,
  } = props;

  const query = useQuery();

  const [viewPort, setViewPort] = useState({
    longitude: -94,
    latitude: 39.88,
    zoom: 3,
  });

  const mapRef = useRef<MapRef | null>(null);

  const [allPoints, setAllPoints] = useState<IPoints[]>([]);

  const [showPopup, setShowPopup] = useState(false);

  const [selectedFeature, setSelectedFeature] = useState<IGeodata | undefined>(undefined);

  const containerRef = React.createRef<HTMLDivElement>();

  const MAP_CONTAINER_ID = 'map-container';

  useEffect(() => {
    if (serviceCenters.length) {
      const mapPoints = serviceCenters.map((serviceCenter: IServiceCenter) => {
        let description = '';
        description += `<a class='popup-link' target='blank' href='/savvy/service-center/${serviceCenter.id}'>${serviceCenter.company}</a> ${serviceCenter.airportId}`;
        description += `<br/>${serviceCenter.city}, ${serviceCenter.state} `;

        if (serviceCenter.scAuth) {
          description += `(${serviceCenter.scAuth})`;
        }

        return {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [serviceCenter.longitude, serviceCenter.latitude],
          },
          properties: {
            title: serviceCenter.company,
            description: description,
            type: serviceCenter.scAuth,
            color: serviceCenter.flashColor,
            id: serviceCenter.id,
          },
        };
      });

      const geojson_white = [];
      const geojson_red = [];
      const geojson_green = [];
      const geojson_yellow = [];

      for (const index in mapPoints) {
        if (mapPoints[index].properties.color === 'white') {
          geojson_white.push(mapPoints[index]);
        } else if (mapPoints[index].properties.color === 'green') {
          geojson_green.push(mapPoints[index]);
        } else if (mapPoints[index].properties.color === 'red') {
          geojson_red.push(mapPoints[index]);
        } else if (mapPoints[index].properties.color === 'yellow') {
          geojson_yellow.push(mapPoints[index]);
        }
      }

      setAllPoints([
        {
          color: 'white',
          points: {
            type: 'FeatureCollection',
            features: geojson_white as GeoJSON.Feature[],
          },
        },
        {
          color: 'red',
          points: {
            type: 'FeatureCollection',
            features: geojson_red as GeoJSON.Feature[],
          },
        },
        {
          color: 'yellow',
          points: {
            type: 'FeatureCollection',
            features: geojson_yellow as GeoJSON.Feature[],
          },
        },
        {
          color: 'green',
          points: {
            type: 'FeatureCollection',
            features: geojson_green as GeoJSON.Feature[],
          },
        },
      ]);
    }
  }, [serviceCenters]);

  const isLayer = (id: string) => {
    if (!id) return false;

    if (
      id.includes('points-icons-red') ||
      id.includes('points-icons-white') ||
      id.includes('points-icons-green') ||
      id.includes('points-icons-yellow')
    )
      return true;
    return false;
  };

  const [cursor, setCursor] = useState('grabbing');

  const isSelectedColor = (colorCode: string, color: string | undefined) => {
    if (!color) return false;

    if (colorCode === 'all') return true;

    return colorCode.includes(color.toLowerCase());
  };

  const handleResults = (event: any) => {
    for (let i = 0; i < event?.length; i++) {
      if (event?.features[i]?.bbox === undefined) continue;

      const oneHundredAndFiftyMilesX = (110 * 1.852) / (111.32 * Math.cos((event.features[i].bbox[1] * Math.PI) / 180));
      event.features[i].bbox[0] = event.features[i].center[0] - oneHundredAndFiftyMilesX;
      event.features[i].bbox[2] = event.features[i].center[0] + oneHundredAndFiftyMilesX;
      const oneHundredAndFiftyMilesY = (110 * 1.852) / 110.574;
      event.features[i].bbox[1] = event.features[i].center[1] - oneHundredAndFiftyMilesY;
      event.features[i].bbox[3] = event.features[i].center[1] + oneHundredAndFiftyMilesY;
    }
  };

  const createGeoJSONCircle = function (center: number[], radiusInKm: number, points = 64) {
    const coords = {
      latitude: center[1],
      longitude: center[0],
    };

    const km = radiusInKm;

    const ret = [];
    const distanceX = km / (111.32 * Math.cos((coords.latitude * Math.PI) / 180));
    const distanceY = km / 110.574;

    let theta, x, y;
    for (let i = 0; i < points; i++) {
      theta = (i / points) * (2 * Math.PI);
      x = distanceX * Math.cos(theta);
      y = distanceY * Math.sin(theta);

      ret.push([coords.longitude + x, coords.latitude + y]);
    }
    ret.push(ret[0]);

    return {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: [ret],
            },
          },
        ],
      },
    };
  };

  const addRing = (center: number[]) => {
    const map = mapRef?.current?.getMap();

    if (!map) return;

    try {
      if (map.getLayer('polygon')) map.removeLayer('polygon');
      if (map.getSource('polygon')) map.removeSource('polygon');
    } catch (err) {
      console.log(err);
    }

    map.addSource('polygon', createGeoJSONCircle(center, 1.852 * 100) as GeoJSONSourceRaw);
    map.addLayer({
      id: 'polygon',
      type: 'line',
      source: 'polygon',
      layout: {},
      paint: {
        'line-opacity': 0.8,
        'line-color': 'purple',
        'line-width': 3,
      },
    });
  };

  const handleResult = (event: any) => {
    console.log(event.result);
    addRing(event.result.center);
  };

  useEffect(() => {
    const mapBoundary = mapRef?.current?.getMap()?.getBounds();

    let containPoints = [];

    if (mapBoundary) {
      containPoints = serviceCenters?.filter((item: IServiceCenter) => {
        let lng;
        const multLng = (item.longitude - mapBoundary.getNorthEast()['lng']) * (item.longitude - mapBoundary.getSouthWest()['lng']);

        if (mapBoundary.getNorthEast()['lng'] > mapBoundary.getSouthWest()['lng']) {
          lng = multLng < 0;
        } else {
          lng = multLng > 0;
        }

        const lat = (item.latitude - mapBoundary.getNorthEast()['lat']) * (item.latitude - mapBoundary.getSouthWest()['lat']) < 0;

        if (lng && lat) return item;
        return false;
      });
    } else {
      containPoints = serviceCenters ? [...serviceCenters] : [];
    }

    setContainedPoints(containPoints);
  }, [setContainedPoints, serviceCenters, mapRef, viewPort]);

  const flyToLocation = (latitude: number, longitude: number) => {
    const centerPoint = new mapboxgl.LngLat(longitude, latitude);
    const boundary = centerPoint.toBounds(SHOW_RADIUS).toArray();
    const mapCotainer = document.getElementById(MAP_CONTAINER_ID);
    if (mapCotainer) {
      const width = mapCotainer.offsetWidth;
      const height = mapCotainer.offsetHeight;
      const tmpViewPort = new WebMercatorViewport({
        width,
        height,
      }).fitBounds([
        [boundary[0][0], boundary[0][1]],
        [boundary[1][0], boundary[1][1]],
      ]);
      setViewPort(tmpViewPort);
    }
  };

  useEffect(() => {
    if (searchedCenter?.latitude && searchedCenter?.longitude) {
      flyToLocation(searchedCenter?.latitude, searchedCenter?.longitude);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedCenter]);

  useEffect(() => {
    if (airportCoordinates?.latitude && airportCoordinates?.longitude) {
      flyToLocation(parseFloat(airportCoordinates?.latitude), parseFloat(airportCoordinates?.longitude));
      addRing([parseFloat(airportCoordinates?.longitude), parseFloat(airportCoordinates?.latitude)]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [airportCoordinates]);

  return (
    <Box
      sx={{
        width: '100%',
        height: {
          xs: '40vh',
          lg: '600px',
        },
        position: 'relative',
      }}
      id={MAP_CONTAINER_ID}
    >
      <Box
        ref={containerRef}
        sx={{
          position: 'absolute',
          top: 20,
          left: 20,
          zIndex: 1,
          '@media (max-width: 540px)': {
            right: '20px',
          },
        }}
      />
      <MapGL
        {...viewPort}
        onMove={(evt) => setViewPort(evt.viewState)}
        mapStyle="mapbox://styles/thanossavvy/cit0gsho4001c2wpidkw2k667"
        style={{
          width: '100%',
          height: '100%',
        }}
        ref={mapRef}
        cursor={cursor}
        onClick={(e: MapLayerMouseEvent) => {
          let features = mapRef.current?.getMap().queryRenderedFeatures(e.point);
          if (features?.length) {
            for (let i = 0; i < features.length; i++) {
              if (isLayer(features[i]?.layer.id)) {
                features[i]?.properties?.id && handleClickPoint(features[i]?.properties?.id);
                return;
              }
            }
          }
        }}
        onMouseMove={(e: MapLayerMouseEvent) => {
          const features = mapRef.current?.getMap().queryRenderedFeatures(e.point);
          if (features?.length) {
            for (let i = 0; i < features.length; i++) {
              if (isLayer(features[i]?.layer.id)) {
                setCursor('pointer');
                setSelectedFeature({
                  type: features[i].type,
                  geometry: {
                    type: features[i].geometry.type,
                    coordinates: JSON.parse(JSON.stringify(features[i])).geometry.coordinates,
                  },
                  properties: {
                    title: features[i]?.properties?.title,
                    description: features[i]?.properties?.description,
                    type: features[i]?.properties?.type,
                    color: features[i]?.properties?.color,
                  },
                });
                setShowPopup(true);
                return;
              }
            }
            setCursor('grabbing');
            setShowPopup(false);
          } else {
            setCursor('grabbing');
            setShowPopup(false);
          }
        }}
        onLoad={() => {
          const tmp = query.get('homeAirport') !== null ? `${query.get('homeAirport')}` : '';
          goToAirport(tmp);
        }}
        mapboxAccessToken={process.env?.REACT_APP_MAPBOX_ACCESS_TOKEN ? process.env?.REACT_APP_MAPBOX_ACCESS_TOKEN : ''}
      >
        <GeocoderControl
          mapboxAccessToken={process.env?.REACT_APP_MAPBOX_ACCESS_TOKEN ? process.env?.REACT_APP_MAPBOX_ACCESS_TOKEN : ''}
          position="top-left"
          onResults={handleResults}
          onResult={handleResult}
        />
        <NavigationControl
          style={{
            top: 10,
            right: 10,
          }}
        />
        {!!selectedSC && (
          <Source
            type="geojson"
            data={{
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [selectedSC.longitude, selectedSC.latitude],
              },
              properties: {
                id: selectedSC.id,
              },
            }}
          >
            <Layer
              id={`points-circles`}
              type="circle"
              paint={{
                'circle-radius': 18,
                'circle-color': '#3F75FF',
                'circle-opacity': 0.5,
              }}
              layout={{
                visibility: 'visible',
                'circle-sort-key': -1,
              }}
            />
          </Source>
        )}
        {!!airportCoordinates?.longitude && !!airportCoordinates?.latitude && (
          <Source
            type="geojson"
            data={{
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [parseFloat(airportCoordinates?.longitude), parseFloat(airportCoordinates?.latitude)],
              },
              properties: {
                title: 'marker',
              },
            }}
          >
            <Layer
              id="marker-point"
              type="symbol"
              layout={{
                'icon-image': 'airport-15',
                'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
                'text-offset': [0, 0.8],
                'text-anchor': 'top',
                'text-size': 40,
                'icon-size': 1.5,
              }}
              paint={{}}
            />
          </Source>
        )}
        {allPoints.map((point: IPoints, key: number) => {
          if (isSelectedColor(selectedColor, point?.color))
            return (
              <Source type="geojson" data={point?.points} key={key}>
                <Layer
                  id={`points-icons-${point?.color}`}
                  type="symbol"
                  layout={{
                    'icon-image': `${point?.color}-wrench`,
                    'icon-allow-overlap': true,
                    'symbol-sort-key': key,
                    'symbol-z-order': 'source',
                  }}
                  paint={{}}
                />
              </Source>
            );
          else return null;
        })}
        {showPopup && selectedFeature && (
          <Popup
            latitude={selectedFeature?.geometry?.coordinates[1] ? selectedFeature?.geometry?.coordinates[1] : 0}
            longitude={selectedFeature?.geometry?.coordinates[0] ? selectedFeature?.geometry?.coordinates[0] : 0}
            closeButton={true}
            closeOnClick={false}
            onClose={() => setShowPopup(false)}
          >
            <div dangerouslySetInnerHTML={{ __html: selectedFeature?.properties.description }} />
          </Popup>
        )}
      </MapGL>
    </Box>
  );
};

export default Map;
