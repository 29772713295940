import React, { useState, useRef, useEffect } from 'react';
import { Box, Typography, FormControlLabel, Switch } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';

import { QUERY_TECHNICIAN_TICKETS } from 'gql';
import { useLazyQuery } from '@apollo/client';
import { IGroup } from 'types';

interface PTDTimerProps {
  setPersonalTickets: (value: IGroup[]) => void;
  initialLoaded: boolean;
  setComponentLoading: (value: boolean) => void;
  checkUnread: boolean;
  setCheckUnread: (value: boolean) => void;
}

const PTDTimer: React.FC<PTDTimerProps> = (props) => {
  const { setPersonalTickets, initialLoaded, setComponentLoading, checkUnread, setCheckUnread } = props;
  const COUNTER_TIMEOUT = 300;

  const [fetchData, { data: dataTickets, loading: loadingTickets }] = useLazyQuery(QUERY_TECHNICIAN_TICKETS);

  const [counter, setCounter] = useState(10);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const refreshPage = async () => {
    await fetchData({
      fetchPolicy: 'cache-and-network',
    });
  };

  const getMinuteSeconds = (value: number) => {
    return `${('0' + Math.floor(value / 60)).slice(-2)}m ${('0' + (value % 60)).slice(-2)}s`;
  };

  useEffect(() => {
    if (initialLoaded || dataTickets?.me?.tickets?.length) {
      setCounter(COUNTER_TIMEOUT);
      if (intervalRef?.current) {
        clearInterval(intervalRef.current);
      }
      intervalRef.current = setInterval(() => {
        setCounter((counter) => counter - 1);
      }, 1000);
    }

    return () => {
      if (intervalRef?.current) clearInterval(intervalRef.current);
    };
  }, [initialLoaded, setCounter, setPersonalTickets, dataTickets]);

  useEffect(() => {
    if (counter === 0) {
      if (intervalRef?.current) clearInterval(intervalRef.current);
      fetchData({
        fetchPolicy: 'network-only',
      });
    }
  }, [counter, fetchData]);

  useEffect(() => {
    if (setComponentLoading) {
      setComponentLoading(loadingTickets);
    }
  }, [loadingTickets, setComponentLoading]);

  return (
    <Box
      py={2.5}
      px={2.5}
      sx={{
        alignItems: 'center',
        width: '100%',
        justifyContent: 'space-between',
        display: {
          md: 'flex',
          xs: 'block',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="h2"
          sx={{
            mr: 3,
            fontSize: {
              lg: '22px',
              xs: '18px',
            },
          }}
        >
          Personal Ticket Dashboard
        </Typography>
        {!loadingTickets && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={refreshPage}
          >
            <RefreshIcon
              sx={{
                mr: 1,
              }}
            />
            <Typography>{getMinuteSeconds(counter)}</Typography>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <FormControlLabel
          control={
            <Switch
              checked={checkUnread}
              onChange={() => {
                setCheckUnread(!checkUnread);
              }}
            />
          }
          label="Unread"
          sx={{
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            height: '100%',
            width: '100%',
            '& .MuiFormControlLabel-label': {
              fontWeight: 600,
            },
            ml: 0,
            mr: 0,
          }}
        />
      </Box>
    </Box>
  );
};

export default PTDTimer;
