import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, Container, Slide } from '@mui/material';
import { LayoutDashboard, PageLoading } from 'components';
import { QUERY_SERVICE_CENTER_FOR_TECH } from 'gql';
import { useLazyQuery } from '@apollo/client';
import { IServiceCenter, IClassification } from 'types';

import { MainInfo, ClassificationsTab, EmployeesList } from './components';

const UpdateServiceCenter: React.FC = () => {
  const { id } = useParams<{
    id: string;
  }>();

  const [fetchServiceCenter, { data: dataServiceCenter, loading: loadingServiceCenter }] = useLazyQuery(QUERY_SERVICE_CENTER_FOR_TECH);

  const [serviceCenter, setServiceCenter] = useState<IServiceCenter>();
  const [selectedClassifications, setSelectedClassifications] = useState<string[]>([]);
  const [markForRemoval, setMarkForRemoval] = useState<string[]>([]);

  const [slideNum, setSlideNum] = useState(0);
  const [clickedBack, setClickedBack] = useState(false);

  useEffect(() => {
    if (id) {
      fetchServiceCenter();
    }
  }, [id, fetchServiceCenter]);

  useEffect(() => {
    if (dataServiceCenter?.me?.serviceCenter) {
      setServiceCenter(dataServiceCenter?.me?.serviceCenter);
      setSelectedClassifications([
        ...dataServiceCenter.me.serviceCenter.classifications.map((classification: IClassification) => classification.id),
      ]);
    }
  }, [dataServiceCenter]);

  return (
    <LayoutDashboard backgroundColor="#fff">
      {loadingServiceCenter && <PageLoading />}
      <Box p={5}>
        <Container maxWidth="lg" fixed>
          {slideNum === 0 && (
            <Typography
              variant="h3"
              style={{
                textAlign: 'center',
              }}
              gutterBottom
            >
              Please update service center {serviceCenter ? serviceCenter.company : ''}
            </Typography>
          )}
          {slideNum === 1 && (
            <Typography
              variant="h3"
              style={{
                textAlign: 'center',
              }}
              gutterBottom
            >
              Update Ratings & Capabilities
            </Typography>
          )}
          {slideNum === 2 && (
            <Box>
              <Typography
                variant="h3"
                style={{
                  textAlign: 'center',
                }}
                gutterBottom
              >
                Remove any unused / inactive users
              </Typography>
              <Typography gutterBottom align="center">
                If you need to add a new employee, please respond to the ticket requesting this update with their name and email address.
              </Typography>
            </Box>
          )}
        </Container>
        <Container maxWidth="lg" fixed>
          {slideNum === 0 && (
            <Slide in={slideNum === 0} direction={clickedBack ? 'right' : 'left'} unmountOnExit>
              <Box>
                <MainInfo
                  serviceCenter={serviceCenter}
                  setServiceCenter={setServiceCenter}
                  setSlideNum={setSlideNum}
                  setClickedBack={setClickedBack}
                />
              </Box>
            </Slide>
          )}
          {slideNum === 1 && (
            <Slide in={slideNum === 1} direction={clickedBack ? 'right' : 'left'} unmountOnExit>
              <Box>
                <ClassificationsTab
                  serviceCenter={serviceCenter}
                  selectedClassifications={selectedClassifications}
                  setSelectedClassifications={setSelectedClassifications}
                  setServiceCenter={setServiceCenter}
                  setSlideNum={setSlideNum}
                  setClickedBack={setClickedBack}
                />
              </Box>
            </Slide>
          )}
          {slideNum === 2 && (
            <Slide in={slideNum === 2} direction={clickedBack ? 'right' : 'left'} unmountOnExit>
              <Box>
                <EmployeesList
                  serviceCenter={serviceCenter}
                  selectedClassifications={selectedClassifications}
                  setServiceCenter={setServiceCenter}
                  setSlideNum={setSlideNum}
                  setClickedBack={setClickedBack}
                  markForRemoval={markForRemoval}
                  setMarkForRemoval={setMarkForRemoval}
                  isLoad={slideNum === 2}
                />
              </Box>
            </Slide>
          )}
          {slideNum === 3 && (
            <Slide in={slideNum === 3} direction={clickedBack ? 'right' : 'left'} unmountOnExit>
              <Box p={5}>
                <Typography
                  variant="h2"
                  sx={{
                    color: 'success.main',
                  }}
                  align="center"
                  gutterBottom
                >
                  Success
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Box>
                    <Box py={1}>
                      <Typography align="center">Thank you for updating your service center information.</Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Slide>
          )}
        </Container>
      </Box>
    </LayoutDashboard>
  );
};

export default UpdateServiceCenter;
