import React from 'react';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import { ISavvyTicket } from 'types';

import { Managers } from 'pages/SavvyAircraft/components';

interface AssignManagersDialogProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  ticket: ISavvyTicket;
}

const AssignManagersDialog: React.FC<AssignManagersDialogProps> = (props) => {
  const { open, setOpen, ticket } = props;

  const handleClose = () => {
    setOpen(false);
  };

  return open ? (
    <Dialog open={open} fullWidth onClose={handleClose}>
      <DialogTitle
        sx={{
          textAlign: 'center',
          fontSize: '24px',
          fontWeight: 700,
        }}
      >
        Assign Managers for {ticket.aircraft.registration}
      </DialogTitle>
      <DialogContent
        sx={{
          px: 2,
          position: 'relative',
        }}
      >
        <Managers aircraftId={ticket.aircraft.id} isLoad={true} ticketId={ticket.id} handleClose={handleClose} />
      </DialogContent>
    </Dialog>
  ) : null;
};

export default AssignManagersDialog;
