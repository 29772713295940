import React from 'react';
import { IAircraftEligibility } from 'types';
import RequirePurchase from './RequirePurchase';
import { AnalysisFormComponent } from './Analysis';
import { QUERY_GET_CLIENT_ANALYSIS_PACKS } from 'gql';
import { useQuery } from '@apollo/client';
import { ComponentLoading } from 'components';
import { hasAnalysisPack } from 'helpers';

interface AnalysisFormProps {
  ticketAircraft: IAircraftEligibility;
}

const AnalysisForm: React.FC<AnalysisFormProps> = (props) => {
  const { ticketAircraft } = props;

  const { data: dataPacks, loading: loadingPacks } = useQuery(QUERY_GET_CLIENT_ANALYSIS_PACKS);

  return (
    <React.Fragment>
      <ComponentLoading loading={loadingPacks}>
        {!hasAnalysisPack(dataPacks?.me?.analysisPacks || []) && (
          <RequirePurchase ticketAircraft={ticketAircraft} eligibility={ticketAircraft.ticketEligibility.analysis} />
        )}
        <AnalysisFormComponent
          ticketAircraft={ticketAircraft}
          eligibility={ticketAircraft.ticketEligibility.analysis}
          analysisPacks={dataPacks?.me?.analysisPacks || []}
        />
      </ComponentLoading>
    </React.Fragment>
  );
};

export default AnalysisForm;
