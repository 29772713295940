import { SET_HOVER_POINT } from 'state/types';

// Initial state (user data)
const initialState = {
  hoverPointIndex: -1,
};

// Reducer
const mapReducer = (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    // Login
    case SET_HOVER_POINT:
      return {
        hoverPointIndex: payload.hoverPointIndex,
      };
    default:
      return state;
  }
};

export default mapReducer;
