import { SAVVY_MX, SAVVY_QA, SAVVY_ANALYSIS, SAVVY_PREBUY, SAVVY_BREAKDOWN, SAVVY_SUPPORT, SAVVY_BASIC, SAVVY_ANALYSIS_PRO } from 'const';

// Entry
export const PATH_HOME = '/';

export const LOGO_PATH = '/images/logo.svg';

// Auth
export const PATH_SIGNUP_FREE = '/signup';
export const PATH_LOGIN = '/login';
export const PATH_RESET_PASSWORD = '/login/reset-password';

// Enroll. Pre-Signup Landing Page
export const PATH_ENROLL = '/enroll';

// Signup for a Service
// :service - url slug to get Service name in component and render service page
export const PATH_SIGNUP_SERVICE = '/signup/:service';
export const PATH_SIGNUP_SERVICE_LEGACY = '/signup/:service/user-info/';
export const PATH_SIGNUP_SERVICE_PAYMENT = `${PATH_SIGNUP_SERVICE}/payment`;
export const PATH_SIGNUP_SERVICE_ADDITIONAL_INFO = `${PATH_SIGNUP_SERVICE}/additional-info`;
export const PATH_SIGNUP_COMPLETE_ADDITIONAL_INFO = '/account/profile/additional-info';

// SavvyMX
export const PATH_SIGNUP_SAVVY_MX = `/signup/${SAVVY_MX}`;
export const PATH_SIGNUP_SAVVY_MX_PAYMENT = `${PATH_SIGNUP_SAVVY_MX}/payment`;
export const PATH_SIGNUP_SAVVY_MX_ADDITIONAL_INFO = `/signup/${SAVVY_MX}/additional-info`;

// SavvyBasics
export const PATH_SIGNUP_SAVVY_BASIC = `/signup/${SAVVY_BASIC}`;
export const PATH_SIGNUP_SAVVY_BASIC_PAYMENT = `${PATH_SIGNUP_SAVVY_BASIC}/payment`;
export const PATH_SIGNUP_SAVVY_BASIC_ADDITIONAL_INFO = `/signup/${SAVVY_BASIC}/additional-info`;

// SavvyQa
export const PATH_SIGNUP_SAVVY_QA = `/signup/${SAVVY_QA}`;
export const PATH_SIGNUP_SAVVY_QA_PAYMENT = `${PATH_SIGNUP_SAVVY_QA}/payment`;
export const PATH_SIGNUP_SAVVY_QA_ADDITIONAL_INFO = `/signup/${SAVVY_QA}/additional-info`;

// SavvyAnalysis
export const PATH_SIGNUP_SAVVY_ANALYSIS = `/signup/${SAVVY_ANALYSIS}`;
export const PATH_SIGNUP_SAVVY_ANALYSIS_PAYMENT = `${PATH_SIGNUP_SAVVY_ANALYSIS}/payment`;
export const PATH_SIGNUP_SAVVY_ANALYSIS_ADDITIONAL_INFO = `/signup/${SAVVY_ANALYSIS}/additional-info`;

// SavvyAnalysisPro
export const PATH_SIGNUP_SAVVY_ANALYSIS_PRO = `/signup/${SAVVY_ANALYSIS_PRO}`;
export const PATH_SIGNUP_SAVVY_ANALYSIS_PRO_PAYMENT = `${PATH_SIGNUP_SAVVY_ANALYSIS_PRO}/payment`;
export const PATH_SIGNUP_SAVVY_ANALYSIS_PRO_ADDITIONAL_INFO = `/signup/${SAVVY_ANALYSIS_PRO}/additional-info`;

// SavvyPrebuy
export const PATH_SIGNUP_SAVVY_PREBUY = `/signup/${SAVVY_PREBUY}`;
export const PATH_SIGNUP_SAVVY_PREBUY_PAYMENT = `${PATH_SIGNUP_SAVVY_PREBUY}/payment`;
export const PATH_SIGNUP_SAVVY_PREBUY_ADDITIONAL_INFO = `/signup/${SAVVY_PREBUY}/additional-info`;

// SavvyBreakdown
export const PATH_SIGNUP_SAVVY_BREAKDOWN = `/signup/${SAVVY_BREAKDOWN}`;
export const PATH_SIGNUP_SAVVY_BREAKDOWN_PAYMENT = `${PATH_SIGNUP_SAVVY_BREAKDOWN}/payment`;
export const PATH_SIGNUP_SAVVY_BREAKDOWN_ADDITIONAL_INFO = `/signup/${SAVVY_BREAKDOWN}/additional-info`;

// SavvySupport
export const PATH_SIGNUP_SAVVY_SUPPORT = `/signup/${SAVVY_SUPPORT}`;
export const PATH_SIGNUP_SAVVY_SUPPORT_PAYMENT = `${PATH_SIGNUP_SAVVY_SUPPORT}/payment`;
export const PATH_SIGNUP_SAVVY_SUPPORT_ADDITIONAL_INFO = `/signup/${SAVVY_SUPPORT}/additional-info`;

// User dashboard
export const PATH_BETA_DASHBOARD = '/dashboard';

// Tikets
export const PATH_TICKETS = '/tickets';
export const PATH_TICKETS_CREATE = '/ticket/create';
export const PATH_TICKETS_VIEW = '/tickets';

// Aircraft
export const PATH_AIRCRAFT_LIST = '/aircraft';
export const PATH_AIRCRAFT_VIEW = '/aircraft/view';
export const PATH_AIRCRAFT_CREATE = '/aircraft/create';

// Flights
export const PATH_FLIGHTS = '/flights';
export const PATH_FLIGHTS_UPLOAD = '/files/upload';

export const PATH_FLIGHT_VIEW = '/flights';
export const PATH_SHARED_FLIGHT_VIEW = '/flights/shared/flight';

export const PATH_FLIGHTS_UPLOAD_LEGACY = '/files/upload';

export const PATH_LEGACY_FLIGHT_VIEW = '/flights';

export const PATH_ACCOUNT = '/account';
export const PATH_REPORTS_BETA = '/reports';

// 404. Not found
export const PATH_NOT_FOUND = '/not-found';

export const UPLOAD_FLIGHTS_ENDPOINT = '/legacy-upload/file';

export const PATH_BETA_PLANS = '/account/plan';

export const PATH_BETA_UPGRADE_PLAN = '/account/plan/:id/:type/:service';

export const PATH_LOGIN_WITH_EMAIL = '/login-via-email';
export const PATH_LOGIN_WITH_CODE = '/login-with-code';

export const PATH_HELP_SAVVY_RESOURCE = 'https://www.savvyaviation.com/resources/';
export const PATH_HELP_FAQ = 'https://www.savvyaviation.com/wp-content/uploads/savvy_pdf/Savvy-Flight-Test-Profile-Expanded.pdf';
export const PATH_HELP_ARTICLES = 'https://www.savvyaviation.com/category/magazine-articles/';
export const PATH_AGREEMENT = 'https://www.savvyaviation.com/master-service-agreement';
export const PATH_CLIENT_GUIDE = 'https://www.savvyaviation.com/wp-content/uploads/savvy_pdf/SavvyMx-New-Client-Guide.pdf';
export const PATH_INSURANCE_GUIDE = 'https://www.savvyaviation.com/savvymx-insurance-information/';
export const PATH_HELP_FLIGHT = 'https://www.savvyaviation.com/resources/#resources';
export const PATH_HELP_DOWNLOAD =
  'https://www.savvyaviation.com/wp-content/uploads/savvy_pdf/savvy-how-to-download-engine-monitor-data.pdf';
export const PATH_HELP_UPGRADE_ENGINE =
  'https://www.savvyaviation.com/wp-content/uploads/savvy_pdf/savvy-choosing-and-upgrading-your-engine-monitor.pdf';
export const PATH_CONTACT_US = 'https://www.savvyaviation.com/contact-us/';

export const PATH_WELCOME = '/account/welcome';
export const PATH_VERIFY_EMAIL = '/account/verify-email';
export const PATH_FORGOT_PASSWORD = '/account/password/reset/confirm';

export const PATH_LEGAL = '/legal';

export const PATH_TICKET_RATE = '/tickets/rate-ticket';

export const PATH_SHARE_ALL_FLIGHTS = '/flights/shared/aircraft';
export const PATH_SHARE_ALL_FLIGHTS_BY_CONTACT = '/flights/shared/contact';

export const PATH_SHARE_LOGS = '/flights/shared/logs';

export const PATH_ADD_ANALYSIS_PRO_PLAN = '/account/add-analysis-pro';
export const PATH_ADD_QA_PLAN = '/account/add-qa';
export const PATH_UPGRADE_QA_PLAN = '/account/upgrade-qa';

export const PATH_SECURITY_CHECK = '/account/staging-security-check';

export const PATH_VERIFY_SECURITY_TOKEN = '/account/security';
export const PATH_COHORTS = '/flights/cohorts';

export const PATH_STAGED_ENGINE_DATA_FILES = '/flights/staged-engine-data-files';
export const PATH_REQUEST_TOKEN = '/account/request-api-token';

/** Technician **/
export const PATH_SC_DASHBOARD = '/tech/personal-ticket-dashboard';
export const PATH_SC_FLIGHTS = '/tech/flights';
export const PATH_VERIFY_SERVICE_CENTER_FOR_TECH = '/tech/verify-sc';
export const PATH_UPDATE_SERVICE_CENTER_FOR_TECH = '/tech/update-sc';

// Internal
export const PATH_INTERNAL_TECHNICIAN = '/savvy/technician/:id';
export const PATH_INTERNAL_SERVICE_CENTER = '/savvy/service-center/:id';
export const PATH_SERVICE_CENTER_MANUAL_RATING = '/savvy/service-center-review';
export const PATH_SERVICE_CENTER_EDIT = '/savvy/service-center/:id/edit';
export const PATH_INTERNAL_SERVICE_CENTER_RATING = '/savvy/create-service-center-rating/:id';
export const PATH_INTERNAL_TICKETRATINGS = '/savvy/ticket-ratings';

export const PATH_SERVICE_CENTER_MAP = '/savvy/service-center-map';
export const PATH_SAVVY_TICKETS = '/savvy/tickets';
export const PATH_SAVVY_TICKETS_VIEW = '/savvy/tickets';
export const PATH_SAVVY_TICKETS_UNWATCH = '/savvy/unwatch/tickets';
export const PATH_TICKETS_DASHBOARD = '/savvy/ticket-dashboard';
export const PATH_FILTERED_TICKETS = '/savvy/filtered-tickets';
export const PATH_RENEWAL_TICKETS = '/savvy/renewal-tickets';
export const PATH_UNASSIGNED_TICKETS = '/savvy/unassigned-tickets';
export const PATH_UESR_TICKET_CREATE = '/savvy/ticket/create';
export const PATH_UESR_TICKET_CREATE_TYPE = '/savvy/ticket/create/type';

export const PATH_PERSONAL_TICKET_DASHBOARD = '/savvy/personal-ticket-dashboard';
export const PATH_INTERNAL_DASHBOARD = '/savvy/internal-dashboard';

export const PATH_CONTACT = '/savvy/contacts';
export const PATH_SERVICE_CENTER = '/savvy/service-centers';
export const PATH_SERVICE_CENTER_CREATE = '/savvy/service-center/create';
export const PATH_SAVVY_AIRCRAFT_FLIGHTS = '/savvy/flight';
export const PATH_SAVVY_SEARCH = '/savvy/search';
export const PATH_SAVVY_AIRCRAFT = '/savvy/aircraft';
export const PATH_SAVVY_ACCOUNT = '/savvy/account';
export const PATH_SAVVY_LOG_BACKFILL = '/savvy/backfill';
export const PATH_SAVVY_COI_MANAGEMENT = '/savvy/coi-management';

export const PATH_MANAGER_CONFLICTS = '/savvy/manager-conflicts';

export const PATH_SAVVY_COHORT_REPORTS = '/savvy/cohort-reports';

export const PATH_SAVVY_TIMESHEETS_DASHBOARD = '/savvy/timesheets/dashboard';
export const PATH_SAVVY_TIMESHEETS_YEAR = '/savvy/timesheets';
export const PATH_SAVVY_TIMESHEETS_WEEK = '/savvy/timesheets/weekly';
export const PATH_SAVVY_TIMESHEETS_MONTH = '/savvy/timesheets/monthly';
export const PATH_SAVVY_TIMESHEETS_RATE = '/savvy/timesheets/rate';

export const PATH_CREATE_TECHNICIAN = '/savvy/technician/create';
export const PATH_SAVVY_AIRCRAFT_CREATE = '/savvy/aircraft/create';
export const PATH_PREBUY_INQUIRY_SIGNUP = '/signup/preliminary-logbook-review';
export const PATH_ACTIVE_PREBUY = '/savvy/active-prebuy';
export const PATH_FEVA_ALERTS = '/savvy/feva/dashboard';
export const PATH_FEVA_VIEW = '/savvy/feva/alerts';
export const PATH_ANALYSIS_DASHBOARD = '/savvy/analysis-dashboard';
export const PATH_FLIGHT_REPORT = '/savvy/analysis/reports';

export const PATH_SAVVY_SEARCH_TICKET = '/savvy/search/tickets';
export const PATH_SAVVY_SERACH_CONTACT = '/savvy/search/contacts';
export const PATH_SAVVY_SEARCH_AIRCRAFT = '/savvy/search/aircraft';
export const PATH_SAVVY_SEARCH_SUBSCRIPTION = '/savvy/search/subscription';
export const PATH_SAVVY_SEARCH_SERVICE_CENTER = '/savvy/search/service-center';
export const PATH_SAVVY_SEARCH_FLIGHT_REPORT = '/savvy/search/flightReport';
export const PATH_SAVVY_SEARCH_PREBUY_INQUIRY = '/savvy/search/prebuy-inquiry';

export const PATH_SAVVY_OOP = '/savvy/oop';
export const PATH_SAVVY_KEY_RESULTS_REPORT = '/savvy/key-results-report';
export const PATH_SAVVY_CANNED_RESPONSES = '/savvy/canned-responses';

export const PATH_SAVVY_FUTURE_COHORTS = '/savvy/future-cohorts';
export const PATH_SAVVY_COHORTS = '/savvy/cohorts';

export const PATH_CREATE_FEVA_TICKET = '/savvy/create/ticket/feva';
export const PATH_SAVVY_PREBUY_INQUIRY = '/savvy/prebuy-inquiry';

export const PATH_SAVVY_ENGINE_PARAMETERS = '/savvy/power-models';
export const PATH_SAVVY_SUBMITTED_AIRCRAFT_DATA = '/savvy/user-submitted-aircraft-data';
export const PATH_SAVVY_AIRCRAFT_MANUFACTURERS_DATA = `${PATH_SAVVY_SUBMITTED_AIRCRAFT_DATA}/aircraft-manufacturers`;
export const PATH_SAVVY_AIRCRAFT_MODELS_DATA = `${PATH_SAVVY_SUBMITTED_AIRCRAFT_DATA}/aircraft-models`;
export const PATH_SAVVY_ENGINE_MANUFACTURERS_DATA = `${PATH_SAVVY_SUBMITTED_AIRCRAFT_DATA}/engine-manufacturers`;
export const PATH_SAVVY_ENGINE_MODELS_DATA = `${PATH_SAVVY_SUBMITTED_AIRCRAFT_DATA}/engine-models`;
export const PATH_SAVVY_ENGINE_MONITOR_MANUFACTURERS_DATA = `${PATH_SAVVY_SUBMITTED_AIRCRAFT_DATA}/engine-monitor-manufacturers`;
export const PATH_SAVVY_ENGINE_MONITOR_MODELS_DATA = `${PATH_SAVVY_SUBMITTED_AIRCRAFT_DATA}/engine-monitor-models`;
export const PATH_SAVVY_FINANCIALS = '/savvy/financials';
export const PATH_CREATE_PREBUY_CONFLICT_TICKET = '/savvy/create-prebuy-conflict-ticket';

export const PATH_UPDATE_TECHNICIAN = '/tech/update';
export const PATH_VERIFY_TECHNICIAN = '/tech/verify';

export const PATH_SAVVY_TECHS_WITH_UPDATE_REQUEST = `/savvy/techs-with-update-reuqest`;

export const PATH_BORESCOPE_UPLOAD = '/files/borescope/upload';

export const PATH_SAVVY_BORESCOPE_ANALYSIS_REPORT_GENERATE = '/savvy/borescope-analysis/reports/generate';
export const PATH_SAVVY_BORESCOPE_ANALYSIS_REPORT_VIEW = '/savvy/borescope-analysis/reports/view';

export const PATH_SAVVY_SEARCH_AIRCRAFT_TYPE = '/savvy/search-aircraft-type';

export const PATH_CREATE_BORESCOPE_REPORT = '/files/borescope/create-report';
export const PATH_CREATE_OWN_BORESCOPE_REPORT = '/files/borescope/create-own-report';
export const PATH_FAQ = 'https://www.savvyaviation.com/monitor-specific-faqs/';
export const PATH_MAKE_PAYMENTS = '/savvy/make-payments';

export const PATH_POSTMARK_TEMPLATES = '/savvy/postmark-templates';
export const PATH_API_TEMPLATES = '/savvy/api-templates';
