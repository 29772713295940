import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Box, Typography, Button, Fade } from '@mui/material';

import { IAircraftEligibility } from 'types';
import BreakdownFormComponent from './BreakdownFormComponent';
import { PATH_TICKETS_CREATE } from 'const';

interface BreakdownTicketProps {
  ticketAircraft: IAircraftEligibility;
}

const BreakdownFlow: React.FC<BreakdownTicketProps> = (props) => {
  const { ticketAircraft } = props;

  const [slideNum, setSlideNum] = useState(0);
  const [isFar, setIsFar] = useState(false);

  if (
    !ticketAircraft.ticketEligibility.breakdown.free &&
    !(ticketAircraft.ticketEligibility.breakdown.eligible && ticketAircraft.ticketEligibility.breakdown.currentlyEligibleVia.length)
  )
    return null;

  return (
    <Box>
      {slideNum === 0 && (
        <Fade in={slideNum === 0} unmountOnExit>
          <Box
            sx={{
              maxWidth: '400px',
              margin: 'auto',
              py: 5,
            }}
          >
            <Typography
              sx={{
                textAlign: 'center',
                fontSize: '16px',
                lineHeight: '22px',
                mb: 5,
              }}
            >
              Are you broken down, at least 50 miles away from your home airport?
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button
                sx={{
                  mr: 2,
                }}
                variant="contained"
                onClick={() => {
                  setSlideNum(1);
                  setIsFar(false);
                }}
              >
                Yes
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  setIsFar(true);
                  setSlideNum(1);
                }}
              >
                No
              </Button>
            </Box>
          </Box>
        </Fade>
      )}
      {slideNum === 1 && isFar && (
        <Fade in={slideNum === 1} unmountOnExit>
          <Box
            sx={{
              maxWidth: '400px',
              margin: 'auto',
              py: 5,
            }}
          >
            <Box mb={3}>
              <Typography variant="h2" color="error" textAlign="center" gutterBottom>
                Sorry...
              </Typography>
              <Typography
                sx={{
                  textAlign: 'center',
                  fontSize: '16px',
                  lineHeight: '22px',
                  mb: 5,
                }}
              >
                Savvy’s Breakdown Service is limited to assisting clients stranded away from their normal maintenance facility.
              </Typography>
            </Box>
            <Box mb={3}>
              <Typography variant="h2" color="error" textAlign="center" gutterBottom>
                SavvyMx or SavvyQA subscribers
              </Typography>
              <Typography
                sx={{
                  textAlign: 'center',
                  fontSize: '16px',
                  lineHeight: '22px',
                  mb: 5,
                }}
              >
                Please{' '}
                <NavLink
                  to={PATH_TICKETS_CREATE}
                  style={{
                    color: 'rgba(63, 117, 255, 1)',
                  }}
                >
                  select another ticket type
                </NavLink>{' '}
                and we will respond accordingly.
              </Typography>
            </Box>
            <Box>
              <Typography variant="h2" color="error" textAlign="center" gutterBottom>
                SavvyBreakdown subscribers
              </Typography>
              <Typography
                sx={{
                  textAlign: 'center',
                  fontSize: '16px',
                  lineHeight: '22px',
                  mb: 5,
                }}
              >
                Please consult with your normal A&P to resolve your issue, or consider subscribing to one of our other services.
              </Typography>
            </Box>
          </Box>
        </Fade>
      )}
      {slideNum === 1 && !isFar && (
        <Fade in={slideNum === 1} unmountOnExit>
          <Box
            sx={{
              maxWidth: '400px',
              margin: 'auto',
              py: 5,
            }}
          >
            <Box>
              <Typography
                sx={{
                  textAlign: 'center',
                  fontSize: '16px',
                  lineHeight: '22px',
                  mb: 3,
                }}
              >
                Savvy will call you within 15 minutes of receiving your request.
              </Typography>
              <Typography
                sx={{
                  textAlign: 'center',
                  fontSize: '16px',
                  lineHeight: '22px',
                  mb: 3,
                }}
              >
                Please do not engage the help of a local mechanic until we’ve had a chance to contact you.
              </Typography>
              <Box
                sx={{
                  textAlign: 'center',
                }}
              >
                <Button
                  variant="contained"
                  onClick={() => {
                    setSlideNum(2);
                  }}
                >
                  Acknowledge
                </Button>
              </Box>
            </Box>
          </Box>
        </Fade>
      )}
      {slideNum === 2 && (
        <Fade in={slideNum === 2} unmountOnExit>
          <Box>
            <BreakdownFormComponent ticketAircraft={ticketAircraft} eligibility={ticketAircraft.ticketEligibility.breakdown} />
          </Box>
        </Fade>
      )}
    </Box>
  );
};

export default BreakdownFlow;
