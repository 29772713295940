import React, { Fragment } from 'react';
import { Typography, Collapse, Hidden } from '@mui/material';

import { Subsection, List, ButtonMore } from 'components';
import { useMdUp } from 'hooks';

const newFeatures = [
  'Helps you select the select the best service centers and maintenance technicians',
  'Advises and prioritizes the most important tasks for your aircraft',
  'Provides written directions to the service centers and technicians',
  'Obtains written estimates before any work begins',
  'Reviews your maintenance invoices',
  'Reviews your maintenance logbook entries',
  'Analyzes your engine monitor data',
  'Manages a pre-purchase examination if you are considering buying the aircraft',
  'Provides 24/7 breakdown assistance',
];

const newFees = ['Piston single: $899/year', 'Piston twin: $1,199/year', 'Turboprop single: $1,799/year', 'Turbojet single: $2399/year'];

const SavvyMxDescription: React.FC = () => {
  const [open, setOpen] = React.useState(false);
  const isMdUp = useMdUp();

  return (
    <Fragment>
      <Subsection>
        <Typography paragraph>
          Our flagship service provides hassle-free maintenance management and has helped thousands of aircraft owners save millions of
          dollars on their aircraft maintenance. SavvyMX service also includes all the services of SavvyAnalysis and 24/7 breakdown
          assistance away from home. If you’re looking to buy an aircraft, we’ll manage a pre-purchase examination.
          <br />
          <br />
          By enrolling in SavvyMX, you receive concierge service by a dedicated, highly experienced A&P/IA with deep expertise in your
          aircraft make and model. Savvy A&P/IA are veterans, each with decades of GA maintenance experience.
        </Typography>
      </Subsection>
      <Collapse in={open || isMdUp}>
        <Subsection>
          <Typography variant="h4" gutterBottom>
            Your assigned SavvyMX A&P/IA expert:
          </Typography>
          <List list={newFeatures} />
        </Subsection>
        <Subsection>
          <Typography variant="h4" gutterBottom>
            SavvyMX Service Annual Fees:
          </Typography>
          <List list={newFees} />
          <Typography
            variant="body1"
            style={{
              fontStyle: 'italic',
            }}
          >
            *Turboprop twin not supported
          </Typography>
        </Subsection>
      </Collapse>
      {!isMdUp && (
        <Hidden mdUp>
          <Subsection>
            <ButtonMore open={open} setOpen={setOpen} />
          </Subsection>
        </Hidden>
      )}
    </Fragment>
  );
};

export default SavvyMxDescription;
