import { gql } from '@apollo/client';

export const MUTATION_DELETE_ENGINE_DATA_FILE = gql`
  mutation DeleteEngineDataFile($fileId: ID) {
    deleteEngineDataFile(fileId: $fileId) {
      ok
      error
    }
  }
`;

export const MUTATION_DELETE_FLIGHTS = gql`
  mutation DeleteFlights($flightIds: [ID]) {
    deleteFlights(flightIds: $flightIds) {
      ok
      me {
        id
        aircraft {
          id
          flights {
            id
          }
        }
      }
      error
    }
  }
`;

export const MUTATION_UNDELETE_FLIGHTS = gql`
  mutation UndeleteFlights($flightIds: [ID]) {
    undeleteFlights(flightIds: $flightIds) {
      ok
      error
    }
  }
`;

export const MUTATION_SAVE_STAGED_FLIGHTS = gql`
  mutation SaveStagedFlights($saveFlightIds: [ID], $discardFlightIds: [ID]) {
    saveStagedFlights(saveFlightIds: $saveFlightIds, discardFlightIds: $discardFlightIds) {
      ok
      error
    }
  }
`;

export const MUTATION_UPDATE_FLIGHT_DEPARTURE_AIRPORT = gql`
  mutation UpdateFlightDepartureAirport($departureName: String, $flightId: ID) {
    updateFlightDepartureAirport(departureName: $departureName, flightId: $flightId) {
      ok
      flight {
        id
        departureAirport
      }
      error
    }
  }
`;

export const MUTATION_UPDATE_FLIGHT_DESTINATION_AIRPORT = gql`
  mutation UpdateFlightDestinationAirport($destinationName: String, $flightId: ID) {
    updateFlightDestinationAirport(destinationName: $destinationName, flightId: $flightId) {
      ok
      flight {
        id
        destinationAirport
      }
      error
    }
  }
`;

export const MUTATION_UPDATE_FLIGHT_NOTES = gql`
  mutation UpdateFlightNote($note: String, $flightId: ID) {
    updateFlightNote(note: $note, flightId: $flightId) {
      ok
      flight {
        id
        notes
      }
      error
    }
  }
`;

export const MUTATION_UPDATE_FLIGHT_USER_DATE = gql`
  mutation UpdateFlightUserDate($flightId: ID, $userDate: DateTime) {
    updateFlightUserDate(flightId: $flightId, userDate: $userDate) {
      ok
      error
      flight {
        id
        date
      }
    }
  }
`;

export const MUTATION_CREATE_ANOMALY = gql`
  mutation CreateAnomaly($anomalyTypes: [ID], $anomalySeries: [String], $comment: String, $endTime: Int, $startTime: Int, $flightId: ID) {
    createAnomaly(
      anomalyTypes: $anomalyTypes
      anomalySeries: $anomalySeries
      comment: $comment
      endTime: $endTime
      startTime: $startTime
      flightId: $flightId
    ) {
      ok
      error
    }
  }
`;

export const MUTATION_UPDATE_AIRCRAFT_CHART_SETTINGS = gql`
  mutation UpdateAircraftChartSettings($aircraftId: ID, $chartNumber: Int, $seriesName: String, $seriesSelector: String) {
    updateAircraftChartSettings(
      aircraftId: $aircraftId
      chartNumber: $chartNumber
      seriesName: $seriesName
      seriesSelector: $seriesSelector
    ) {
      ok
      error
    }
  }
`;

export const MUTATION_DELETE_ALL_FLIGHTS_BY_AIRCRAFT = gql`
  mutation DeleteAllFlightsByAircraft($aircraftId: ID) {
    deleteFlightsByAircraft(aircraftId: $aircraftId) {
      ok
      error
    }
  }
`;

export const MUTATION_SET_FLIGHT_CHART_SETTINGS = gql`
  mutation SetFlightChartSettings($chartsNumber: String, $chartsSize: String) {
    setFlightChartSettings(chartsNumber: $chartsNumber, chartsSize: $chartsSize) {
      ok
      contact {
        id
        settings {
          chartsNumber
          chartsSize
          isFlightPanelVisible
        }
      }
      error
    }
  }
`;

export const MUTATION_PARSE_FLIGHTS_FROM_DOCUMENT = gql`
  mutation ParseFlightsFromDocument($aircraftId: ID, $documentId: ID, $stage: Boolean) {
    parseFlightsFromDocument(aircraftId: $aircraftId, documentId: $documentId, stage: $stage) {
      ok
      message
      flightCount
    }
  }
`;

export const MUTATION_PARSE_FLIGHTS_FROM_S3 = gql`
  mutation ParseFlightsFromS3File($aircraftId: ID, $documentPath: String, $stage: Boolean) {
    parseFlightsFromS3File(aircraftId: $aircraftId, documentPath: $documentPath, stage: $stage) {
      ok
      message
      flightCount
    }
  }
`;

export const MUTATION_CREATE_AIRCRAFT_CHART_SCALE_SETTINGS = gql`
  mutation CreateAircraftChartScaleSettingsMutation($aircraftId: ID!, $scaleMax: Float, $scaleMin: Float, $seriesName: String!) {
    createAircraftChartScaleSettings(aircraftId: $aircraftId, scaleMax: $scaleMax, scaleMin: $scaleMin, seriesName: $seriesName) {
      ok
      aircraft {
        id
        settings {
          id
          seriesName
          scaleMin
          scaleMax
          enabled
        }
      }
      error
    }
  }
`;

export const MUTATION_ENABLE_DISABLE_CHART_SCALE_SETTING = gql`
  mutation EnableDisableChartScaleSetting($aircraftChartScaleSettingsId: ID, $enabled: Boolean) {
    updateAircraftChartScaleSettings(aircraftChartScaleSettingsId: $aircraftChartScaleSettingsId, enabled: $enabled) {
      ok
      aircraft {
        id
        settings {
          id
          seriesName
          scaleMin
          scaleMax
          enabled
        }
      }
      error
    }
  }
`;

export const MUTATION_DELETE_AIRCRAFT_CHART_SCALE_SETTING = gql`
  mutation DeleteAircraftChartScaleSettingsMutation($aircraftChartScaleSettingsId: ID) {
    deleteAircraftChartScaleSettings(aircraftChartScaleSettingsId: $aircraftChartScaleSettingsId) {
      ok
      aircraft {
        id
        settings {
          id
          seriesName
          scaleMin
          scaleMax
          enabled
        }
      }
      error
    }
  }
`;

export const MUTATION_UPDATE_AIRCRAFT_CHART_SCALE_SETTING = gql`
  mutation UpdateAircraftChartScaleSettingsMutation(
    $aircraftChartScaleSettingsId: ID
    $scaleMax: Float
    $scaleMin: Float
    $seriesName: String
  ) {
    updateAircraftChartScaleSettings(
      aircraftChartScaleSettingsId: $aircraftChartScaleSettingsId
      scaleMax: $scaleMax
      scaleMin: $scaleMin
      seriesName: $seriesName
    ) {
      ok
      aircraft {
        id
        settings {
          id
          seriesName
          scaleMin
          scaleMax
          enabled
        }
      }
      error
    }
  }
`;

export const MUTATION_PASTE_FLIGHT_REPORT = gql`
  mutation PasteFlightReport($analysisTagId: ID, $reportClipboardId: ID) {
    pasteFlightReport(analysisTagId: $analysisTagId, reportClipboardId: $reportClipboardId) {
      ok
      error
      report {
        id
        flight {
          id
          aircraft {
            id
          }
        }
      }
    }
  }
`;
