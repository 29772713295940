// Amplitude Event Messages

// COMMON
// ------------------------------------
export const AMPLITUDE_INIT_SUCCESS = 'AMPLITUDE_INIT_SUCCESS';
export const AMPLITUDE_INIT_ERROR = 'AMPLITUDE_INIT_ERROR';
export const AMPLITUDE_LOG_EVENT_ERROR = 'AMPLITUDE_LOG_EVENT_ERROR';

// SIGNUP
// ------------------------------------
// Services
// Started. Enroll button clicked
export const SIGNUP_STARTED = 'SIGNUP_STARTED_'; // Common prefix to add service id in the end

// Signup Service Page loaded
export const SIGNUP_PAGE_LOADED_SAVVY = 'SIGNUP_PAGE_LOADED_SAVVY_'; // Common prefix to add service id in the end

export const SIGNUP_PAGE_LOADED_PAYMENT = 'SIGNUP_PAGE_LOADED_PAYMENT';
export const SIGNUP_PAGE_LOADED_ADDITIONAL_INFO = 'SIGNUP_PAGE_LOADED_ADDITIONAL_INFO';

// Steps
// 1. Personal and Aircraft Info
export const SIGNUP_CREATE_ACCOUNT_SUCCESS = 'SIGNUP_CREATE_ACCOUNT_SUCCESS';
export const SIGNUP_CREATE_ACCOUNT_ERROR = 'SIGNUP_CREATE_ACCOUNT_ERROR';
export const SIGNUP_CREATE_AIRCRAFT_SUCCESS = 'SIGNUP_CREATE_AIRCRAFT_SUCCESS';
export const SIGNUP_CREATE_AIRCRAFT_ERROR = 'SIGNUP_CREATE_AIRCRAFT_ERROR';

// 2. Payment
export const SIGNUP_PAYMENT_SUCCESS = 'SIGNUP_PAYMENT_SUCCESS';
export const SIGNUP_PAYMENT_ERROR = 'SIGNUP_PAYMENT_ERROR';

// 3. Additional Info
export const SIGNUP_ADDITIONAL_INFO_SUCCESS = 'SIGNUP_ADDITIONAL_INFO_SUCCESS';
export const SIGNUP_ADDITIONAL_INFO_ERROR = 'SIGNUP_ADDITIONAL_INFO_ERROR';

// Common
export const SIGNUP_SERVICE_SUCCESS = 'SIGNUP_SERVICE_SUCCESS';

// exit beta
export const EXIT_BETA = 'exit_beta';
