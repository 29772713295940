import { IOptionIdName, IFormatOption, IGetOptionLabel, IOptionCountryPhone } from 'types';

// Label (getOptionLabel in Autocomplete) is a value to put in autocomplete input. Don't mess with real autocomplete value which may include label, id, type, icon, etc
// Render Option (renderOption in Autocomplete, optional) used to render autocomplete dropdown options. If not specified getOptionLabel used
// Docs: https://material-ui.com/api/autocomplete/

// Get label. Returns only name
export const getOptionLabelIdName: IGetOptionLabel = (option: IOptionIdName) => {
  return option === null ? '' : option.name ? option.name : ''; // Return label depending on option format
};

// Get value. Returns id as value
export const getOptionValueIdName: IGetOptionLabel = (option: IOptionIdName) => {
  return option === null ? '' : option.id;
};

// Get label. Returns flag/country-name
export const getOptionLabelFlagCountry = (option: IOptionCountryPhone) => {
  return option === null ? '' : `${option.icon} ${option.name}`;
};

// Return option with or without type prop
export const formatOptionIdName: IFormatOption = (value, label, type?) => {
  return type // If type passed
    ? { id: value, name: label, type } // Add it. Type needed for Add Option Dialog
    : { id: value, name: label }; // If no - return id/name
};
