import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { Box, Typography, Link } from '@mui/material';

import { QUERY_PREBUY_INQUIRY } from 'gql';
import { useLazyQuery } from '@apollo/client';

import { LayoutDashboard, PageLoading } from 'components';
import { setAlert } from 'state';
import { ITicket } from 'types';
import { PATH_SAVVY_TICKETS } from 'const';
import { ctrlCmdClick } from 'helpers';

const PrebuyInquiry: React.FC = () => {
  const [fetchPrebuyInquiry, { data: dataPrebuyInquiry, loading: loadingPrebuyInquiry, error: errorPrebuyInquiry }] =
    useLazyQuery(QUERY_PREBUY_INQUIRY);
  const dispatch = useDispatch();
  const history = useHistory();

  const { id: prebuyInquiryId } = useParams<{
    id: string;
  }>();

  const InfoLine = (props: { label: string; text: string | React.ReactElement }) => {
    const { label, text = '' } = props;

    return (
      <Box
        py={1}
        sx={{
          maxWidth: '60em',
          display: 'flex',
        }}
      >
        <Typography
          sx={{
            width: '30%',
            textAlign: 'right',
            mr: 1,
          }}
        >
          {label}
        </Typography>
        <Box
          sx={{
            width: '70%',
          }}
        >
          {typeof text === 'string' && <Typography>{text ? text : 'None'}</Typography>}
          {typeof text !== 'string' && <Box>{text}</Box>}
        </Box>
      </Box>
    );
  };

  useEffect(() => {
    if (prebuyInquiryId) {
      fetchPrebuyInquiry({
        variables: {
          prebuyInquiryId,
        },
      });
    }
  }, [prebuyInquiryId, fetchPrebuyInquiry]);

  useEffect(() => {
    if (errorPrebuyInquiry) {
      dispatch(setAlert('error', 'Unable to load prebuy inquiry'));
    }
  }, [errorPrebuyInquiry, dispatch]);

  return (
    <LayoutDashboard backgroundColor="#fff">
      {loadingPrebuyInquiry && <PageLoading />}
      <Box p={4}>
        <Typography variant="h2" gutterBottom>
          View Prebuy Inquiry
        </Typography>
        {!!dataPrebuyInquiry?.prebuyInquiry?.length && (
          <Box>
            <InfoLine label="Created:" text={dataPrebuyInquiry?.prebuyInquiry[0]?.created.toString().substring(0, 10)} />
            <InfoLine
              label="Client:"
              text={
                dataPrebuyInquiry?.prebuyInquiry[0]?.contact
                  ? `${dataPrebuyInquiry?.prebuyInquiry[0]?.contact.firstName} ${dataPrebuyInquiry?.prebuyInquiry[0]?.contact.lastName}`
                  : `${dataPrebuyInquiry?.prebuyInquiry[0]?.emailContact.firstName} ${dataPrebuyInquiry?.prebuyInquiry[0]?.emailContact.firstName}`
              }
            />
            <InfoLine label="Status:" text={dataPrebuyInquiry?.prebuyInquiry[0]?.status} />
            <InfoLine label="Disposition:" text={dataPrebuyInquiry?.prebuyInquiry[0]?.disposition} />
            <InfoLine label="Phone:" text={dataPrebuyInquiry?.prebuyInquiry[0]?.phone} />
            <InfoLine label="Home Airport ID:" text={dataPrebuyInquiry?.prebuyInquiry[0]?.homeAirportId} />
            <InfoLine label="Candidate Airport:" text={dataPrebuyInquiry?.prebuyInquiry[0]?.candidateAirport} />
            <InfoLine label="Commit:" text={dataPrebuyInquiry?.prebuyInquiry[0]?.commitment} />
            <InfoLine label="First Time Buyer:" text={dataPrebuyInquiry?.prebuyInquiry[0]?.firstTimeBuyer} />
            <InfoLine label="Not Quite Sure:" text={dataPrebuyInquiry?.prebuyInquiry[0]?.notQuiteSure} />
            <InfoLine label="Know Exactly:" text={dataPrebuyInquiry?.prebuyInquiry[0]?.knowExactly} />
            <InfoLine label="Narrowed To A Few:" text={dataPrebuyInquiry?.prebuyInquiry[0]?.narrowedToAFew} />
            <InfoLine label="Narrowed To One:" text={dataPrebuyInquiry?.prebuyInquiry[0]?.narrowedToOne} />
            <InfoLine label="Broker Involved:" text={dataPrebuyInquiry?.prebuyInquiry[0]?.brokerInvolved} />
            <InfoLine label="Scanned Logbooks:" text={dataPrebuyInquiry?.prebuyInquiry[0]?.scannedLogbooks} />
            <InfoLine label="Model Decided:" text={dataPrebuyInquiry?.prebuyInquiry[0]?.modelDecided} />
            <InfoLine label="Models Considered:" text={dataPrebuyInquiry?.prebuyInquiry[0]?.modelsConsidered} />
            <InfoLine label="Candidate 1:" text={dataPrebuyInquiry?.prebuyInquiry[0]?.candidate1} />
            <InfoLine label="Candidate 2:" text={dataPrebuyInquiry?.prebuyInquiry[0]?.candidate2} />
            <InfoLine label="Candidate 3:" text={dataPrebuyInquiry?.prebuyInquiry[0]?.candidate3} />
            <InfoLine label="Candidate Chosen:" text={dataPrebuyInquiry?.prebuyInquiry[0]?.candidateChosen} />
            <InfoLine label="Candidate Registration:" text={dataPrebuyInquiry?.prebuyInquiry[0]?.candidateRegistration} />
            <InfoLine label="Mission Profile:" text={dataPrebuyInquiry?.prebuyInquiry[0]?.missionProfile} />
            <InfoLine label="Remarks:" text={dataPrebuyInquiry?.prebuyInquiry[0]?.remarks} />
            <InfoLine label="Attachments:" text={dataPrebuyInquiry?.prebuyInquiry[0]?.attachments} />
            <InfoLine label="Savvy Remarks:" text={dataPrebuyInquiry?.prebuyInquiry[0]?.savvyRemarks} />
            {!!dataPrebuyInquiry?.prebuyInquiry[0]?.relatedTickets?.length && (
              <InfoLine
                label="Related Tickets:"
                text={dataPrebuyInquiry.prebuyInquiry[0].relatedTickets.map((ticket: ITicket, key: number) => {
                  return (
                    <Box key={key}>
                      <Link
                        href={`${PATH_SAVVY_TICKETS}/${ticket.id}`}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          ctrlCmdClick(e, `${PATH_SAVVY_TICKETS}/${ticket.id}`, history);
                        }}
                      >
                        {ticket.subject}
                      </Link>
                    </Box>
                  );
                })}
              />
            )}
          </Box>
        )}
      </Box>
    </LayoutDashboard>
  );
};

export default PrebuyInquiry;
