import React from 'react';
import { NavLink } from 'react-router-dom';
import { Box, Tooltip, Typography } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';

import { PATH_TICKETS_CREATE } from 'const';
import { theme } from 'theme';

interface TooltipForAutotagProps {
  subjectList: string[][];
}

const TooltipForAutotag: React.FC<TooltipForAutotagProps> = (props) => {
  const { subjectList } = props;
  return (
    <Tooltip
      placement="right"
      arrow
      title={
        <Box p={5}>
          <Typography gutterBottom color="text.primary">
            You can automatically tag images by using the following naming file name pattern before you upload the files (file extensions
            are ignored):
          </Typography>
          <Typography
            sx={{
              color: 'success.main',
              fontSize: '14px',
              fontWeight: 'bold',
            }}
          >
            C-VIEW-DATE.extension
          </Typography>
          <Typography
            sx={{
              fontSize: '14px',
              lineHeight: '22px',
              color: 'rgba(75, 84, 112, 0.5)',
            }}
            gutterBottom
          >
            - C: cylinder number 1-9
            <br />
            - VIEW: name of the view (see list below)
            <br />- DATE (optional): use the following format YYYYMMDD (Current date used if date not provided).
          </Typography>
          <Typography
            sx={{
              color: 'error.dark',
              fontSize: '14px',
              fontWeight: 'bold',
            }}
          >
            For example:
          </Typography>
          <Typography
            sx={{
              fontSize: '14px',
              lineHeight: '22px',
              color: 'rgba(75, 84, 112, 0.5)',
            }}
            gutterBottom
          >
            - 1-piston-crown-20231215.png is the “piston crown” view for the #1 cylinder.
            <br />- 3-intake-valve-stem.jpg is the "Intake Valve Stem" view for the #3 cylinder.
          </Typography>
          <Typography
            sx={{
              color: 'text.primary',
              fontSize: '14px',
              fontWeight: 'bold',
            }}
          >
            Available views:
          </Typography>
          <Typography
            sx={{
              fontSize: '14px',
              lineHeight: '22px',
              color: 'rgba(75, 84, 112, 0.5)',
            }}
            gutterBottom
          >
            {subjectList.map((item, key: number) => {
              return (
                <React.Fragment key={key}>
                  {key !== 0 && <br />}
                  -&nbsp;{item[0].replace('face', 'head')}
                </React.Fragment>
              );
            })}
          </Typography>
          <Typography
            sx={{
              fontSize: '14px',
              lineHeight: '22px',
              color: 'rgba(75, 84, 112, 0.5)',
            }}
          >
            If you have an analyzer that is not listed, or something doesn't work,{' '}
            <NavLink
              to={PATH_TICKETS_CREATE}
              style={{
                color: theme.palette.text.primary,
                fontWeight: 500,
                textDecoration: 'underline',
              }}
            >
              contact us
            </NavLink>
            , and we'll do our best to support it.
          </Typography>
        </Box>
      }
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: theme.palette.background.default,
            border: '1px solid #E8E9EF',
            borderColor: theme.palette.grey['200'],
            boxShadow: '0px 1px 1px rgba(19, 30, 64, 0.06)',
            borderRadius: '6px !important',
            maxWidth: '520px !important',
            padding: '0 !important',
          },
        },
        arrow: {
          color: theme.palette.background.default + ' !important',
        },
      }}
    >
      <HelpIcon />
    </Tooltip>
  );
};

export default TooltipForAutotag;
