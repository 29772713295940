import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Box, Typography, Link } from '@mui/material';
import { LayoutDashboard, PageLoading } from 'components';
import { MUTATION_VERIFY_SERVICE_CENTER } from 'gql';
import { useMutation } from '@apollo/client';
import { PATH_UPDATE_SERVICE_CENTER_FOR_TECH } from 'const';

import { setAlert } from 'state';

const VerifyServiceCenter: React.FC = () => {
  const { id } = useParams<{
    id: string;
  }>();

  const [verifyServiceCenter, { data: dataServiceCenter, loading: loadingServiceCenter, error }] =
    useMutation(MUTATION_VERIFY_SERVICE_CENTER);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (id) {
      verifyServiceCenter({
        variables: {
          serviceCenterId: id,
        },
      });
    }
  }, [id, verifyServiceCenter]);

  useEffect(() => {
    if (error) {
      dispatch(setAlert('error', 'Unable to verify service center'));
    } else if (dataServiceCenter) {
      const { ok, error } = dataServiceCenter.verifyServiceCenter;
      if (!ok) {
        dispatch(setAlert('error', error));
      }
    }
  }, [error, dataServiceCenter, dispatch]);

  return (
    <LayoutDashboard backgroundColor="#fff">
      {loadingServiceCenter && <PageLoading />}
      {dataServiceCenter?.verifyServiceCenter?.ok && (
        <Box p={5}>
          <Typography
            variant="h2"
            sx={{
              color: 'success.main',
            }}
            align="center"
            gutterBottom
          >
            Service Center Information Updated Successfully
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Box>
              <Box py={1}>
                <Typography align="center">
                  Thank you for taking the time to verify your information. If you need to, you can also make{' '}
                  <Link
                    href={`${PATH_UPDATE_SERVICE_CENTER_FOR_TECH}/${id}`}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      history.push(`${PATH_UPDATE_SERVICE_CENTER_FOR_TECH}/${id}`);
                    }}
                  >
                    further modifications
                  </Link>
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      {dataServiceCenter?.verifyServiceCenter && !dataServiceCenter.verifyServiceCenter?.ok && (
        <Box p={5}>
          <Typography
            variant="h2"
            sx={{
              color: 'error.main',
            }}
            align="center"
            gutterBottom
          >
            You can't verify service center.
          </Typography>
        </Box>
      )}
    </LayoutDashboard>
  );
};

export default VerifyServiceCenter;
