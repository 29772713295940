import React from 'react';
import { TextField } from '@mui/material';

interface IFormInputUI {
  type?: string;
  name: string;
  label?: string;
  onChange: (event: any) => void;
  onBlur?: (event: any) => void;
  disabled?: boolean;
  placeholder?: string;
  maxLength?: number;
  InputProps?: any;
  noMargin?: boolean;
}

// Input UI
const FormInput: React.FC<IFormInputUI> = (props) => {
  const { type, name, label, onChange, onBlur, disabled, placeholder, maxLength = 50, noMargin = false, ...rest } = props;

  return (
    <TextField
      type={type ? type : 'text'}
      name={name}
      label={label}
      onChange={(e) => onChange(e)}
      onBlur={(e) => {
        onBlur && onBlur(e);
      }}
      variant="outlined"
      fullWidth
      disabled={disabled}
      placeholder={placeholder ? placeholder : ''}
      inputProps={{
        maxLength: maxLength,
      }}
      sx={{
        mb: noMargin ? '0px' : '15px',
      }}
      {...rest}
    />
  );
};

export default FormInput;
