export const CHART_VISIBLE_NORMAL = 'Normal';
export const CHART_VISIBLE_BOLD = 'Bold';
export const CHART_VISIBLE_HIDE = 'Hide';
export const CHART_VISIBLE_ISOLATE = 'Isolate';
export const INFO_TAB_WIDTH = 470;
export const INFO_SAVVY_TAB_WIDTH = 300;

export const X_MODE = 'x_mode';
export const TO_MODE = 'to_mode';
export const MIN_CHART_HEIGHT = 190;
export const TO_MODE_LEFT = 'to_mode_left';
export const TO_MODE_RIGHT = 'to_mode_right';
