import React, { Fragment } from 'react';

import { Typography, Collapse, Hidden, Link } from '@mui/material';

import { Subsection, ButtonMore } from 'components';
import { useMdUp } from 'hooks';

const SavvySupportDescription: React.FC = () => {
  const [open, setOpen] = React.useState(false);
  const isMdUp = useMdUp();

  return (
    <Fragment>
      <Subsection>
        <Typography paragraph>Consulting, Dispute Resolution, Litigation Support, and Expert Witness Services with Mike Busch</Typography>
      </Subsection>
      <Collapse in={open || isMdUp}>
        <Subsection>
          <Typography paragraph>
            Mike has more than 50 years of aviation experience, and possesses an unusual breadth and depth of technical knowledge as a
            pilot, flight instructor, mechanic, inspector, and aircraft owner, with focus on piston-powered general aviation airplanes.
          </Typography>
          <Typography paragraph>
            His decades of writing and teaching in support of aviation safety were acknowledged by the Federal Aviation Administration in
            2008 when the FAA Administrator honored him as “National Aviation Maintenance Technician of the Year.”
          </Typography>
          <Typography paragraph>
            In addition, Mike is a computer and mathematics expert with the ability to perform computer reconstruction of aircraft accidents
            and to develop demonstrative evidence in the form of digitized graphics, computer animation and video.
          </Typography>
        </Subsection>
        <Subsection>
          <Typography paragraph>
            Email: <Link href="mailto:mike.busch@savvyaviator.com">mike.busch@savvyaviator.com</Link>
          </Typography>
          <Typography paragraph>
            Phone: <Link href="tel:18058784597">1-805-878-4597</Link>
          </Typography>
        </Subsection>
      </Collapse>
      {!isMdUp && (
        <Hidden mdUp>
          <Subsection>
            <ButtonMore open={open} setOpen={setOpen} />
          </Subsection>
        </Hidden>
      )}
    </Fragment>
  );
};

export default SavvySupportDescription;
