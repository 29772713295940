import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Typography, Link } from '@mui/material';
import { ComponentLoading } from 'components';

import { QUERY_SAVVY_TICKET_SEARCH } from 'gql';
import { useLazyQuery } from '@apollo/client';
import { SAVVY_SEARCH_LIMIT, PATH_SAVVY_TICKETS, PATH_SAVVY_SEARCH_TICKET } from 'const';

import { ITicket } from 'types';
import { aircraftRegistrationMakeModel, humanDate, ctrlCmdClick } from 'helpers';

interface TicketSearchProps {
  searchQuery: string;
}

const TicketSearch: React.FC<TicketSearchProps> = (props) => {
  const { searchQuery } = props;

  const history = useHistory();

  const [fetchSavvyTicketSearch, { data: dataTicket, loading: loadingTicket }] = useLazyQuery(QUERY_SAVVY_TICKET_SEARCH);

  const [ticketList, setTicketList] = useState<ITicket[]>([]);

  const [count, setCount] = useState(0);

  const loadMore = () => {
    history.push(`${PATH_SAVVY_SEARCH_TICKET}?query=${searchQuery}`);
  };

  useEffect(() => {
    if (!searchQuery || searchQuery.length < 2) {
      return;
    }

    setTicketList([]);

    const timeout: NodeJS.Timeout = setTimeout(() => {
      fetchSavvyTicketSearch({
        variables: {
          search: searchQuery,
          limit: SAVVY_SEARCH_LIMIT,
          offset: 0,
        },
      });
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [searchQuery, fetchSavvyTicketSearch]);

  useEffect(() => {
    if (dataTicket?.savvy?.search?.tickets?.results?.length) {
      setTicketList((ticketList: ITicket[]) => {
        const newArray: ITicket[] = ticketList.concat(dataTicket.savvy?.search?.tickets?.results);
        return [...newArray];
      });
    }

    if (dataTicket?.savvy?.search?.tickets) {
      setCount(dataTicket.savvy?.search?.tickets?.count);
    }
  }, [dataTicket]);

  return (
    <Box>
      <ComponentLoading loading={loadingTicket}>
        <Box>
          {!!searchQuery && (
            <Typography
              variant="h5"
              sx={{
                fontWeight: 'bold',
                py: 1,
              }}
            >
              Tickets {count ? `(${count})` : ''}
            </Typography>
          )}
          <Box pl={4}>
            {ticketList?.map((ticket: ITicket, key: number) => {
              return (
                <Box
                  key={key}
                  py={1}
                  sx={{
                    cursor: 'pointer',
                  }}
                  onClick={(event: any) => {
                    ctrlCmdClick(event, `${PATH_SAVVY_TICKETS}/${ticket.id}`, history);
                  }}
                >
                  <Typography>
                    {ticket.subject}
                    {ticket.aircraft ? `, ${aircraftRegistrationMakeModel(ticket.aircraft)}` : ''}
                    {ticket.aircraft?.owner
                      ? `, ${ticket.aircraft.owner.firstName} ${ticket.aircraft.owner.lastName}`
                      : `, ${ticket.creator?.firstName} ${ticket.creator?.lastName}`}{' '}
                    (created at {humanDate(ticket.created)}) {ticket.state.toLowerCase() === 'c' ? '(CLOSED)' : 'Active'}
                  </Typography>
                </Box>
              );
            })}
            {!ticketList?.length && !!searchQuery && !loadingTicket && <Box py={1}>No matched tickets</Box>}
          </Box>
          {count > ticketList.length && (
            <Link
              href="#"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                loadMore();
              }}
            >
              Show More
            </Link>
          )}
        </Box>
      </ComponentLoading>
    </Box>
  );
};

export default TicketSearch;
