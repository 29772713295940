import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { AppBarLoggedOut, Content, Footer, AppBarLoggedIn } from './components';
import { ILayout } from 'types';

const LayoutNormal: React.FC<ILayout> = (props) => {
  const { children } = props;
  const { loggedIn } = useSelector((state: any) => state.auth);

  return (
    <Box display="flex" flexDirection="column" height="100%">
      {loggedIn ? <AppBarLoggedIn /> : <AppBarLoggedOut />}
      <Content>{children}</Content>
      <Footer />
    </Box>
  );
};

export default LayoutNormal;
