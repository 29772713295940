import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Box } from '@mui/material';
import { LayoutDashboard } from 'components';
import { Tickets, Aircrafts, BreakdownPromo } from './components';
import { setAlert } from 'state';

import { QUERY_USER_DATA } from 'gql';
import { useQuery } from '@apollo/client';

import { PageLoading } from 'components';

const Dashboard: React.FC = () => {
  const { data, loading, error } = useQuery(QUERY_USER_DATA, {
    fetchPolicy: 'cache-and-network',
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (error) {
      dispatch(setAlert('error', 'Unable to load your data.'));
    }
  }, [data, error, loading, dispatch]);

  return (
    <LayoutDashboard flexGrow={1}>
      {loading && <PageLoading />}
      <Box
        p={{
          xl: 5,
          lg: 3.75,
          md: 1.5,
          sm: 0,
        }}
      >
        <Grid
          container
          spacing={{
            sm: 0,
            md: 1.5,
            lg: 3.75,
            xl: 5,
          }}
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={3}>
            <Grid
              container
              spacing={{
                sm: 0,
                md: 1.5,
                lg: 3.75,
                xl: 5,
              }}
            >
              <Grid item xs={12}>
                <Tickets />
              </Grid>
              <Grid item xs={12}>
                {data?.me?.hasActiveBreakdown && <BreakdownPromo />}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xl={9} lg={12} md={12} sm={12} xs={12}>
            <Aircrafts />
          </Grid>
        </Grid>
      </Box>
    </LayoutDashboard>
  );
};

export default Dashboard;
