import React, { useState, useEffect } from 'react';
import { Box, Collapse, Typography, TextField, MenuItem, IconButton } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import RefreshIcon from '@mui/icons-material/Refresh';

import { QUERY_RELATED_TICKET_STICKY_STATUS, MUTATION_SET_RELATED_TICKET_STICKY_STATUS, QUERY_SAVVY_RELATED_TICKETS_FOR_DETAIL } from 'gql';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';

import { PATH_SAVVY_TICKETS_VIEW } from 'const';
import { humanDate, humanDateTime } from 'helpers';

import { default as MergeTicketDialog } from './MergeTicketDialog';
import { ComponentLoading } from 'components';

interface RelatedTicketsProps {
  tickets: any[];
  sourceTicketId: string;
  sourceTicketSubject: string;
  loading: boolean;
}

const RelatedTickets: React.FC<RelatedTicketsProps> = (props) => {
  const { tickets, sourceTicketId, sourceTicketSubject, loading } = props;

  const { data: dataRelatedTicketStickyStatus, loading: loadingRelatedTicketSticyStatus } = useQuery(QUERY_RELATED_TICKET_STICKY_STATUS);
  const [setRelatedTicketStickyStatus, { loading: loadingSetStatus }] = useMutation(MUTATION_SET_RELATED_TICKET_STICKY_STATUS);
  const [fetchRelatedTickets, { loading: loadingRelatedTickets }] = useLazyQuery(QUERY_SAVVY_RELATED_TICKETS_FOR_DETAIL, {
    fetchPolicy: 'network-only',
  });

  const [relatedOn, setRelatedOn] = useState(false);

  const [statusFilter, setStatusFilter] = useState('active');

  const [targetTicketId, setTargetTicketId] = useState('');
  const [targetTicketSubject, setTargetTicketSubject] = useState('');

  const [showMergeModal, setShowMergeModal] = useState(false);

  const submitStatus = async (status: string) => {
    setRelatedTicketStickyStatus({
      variables: {
        status,
      },
    });
  };

  useEffect(() => {
    if (dataRelatedTicketStickyStatus?.me?.settings?.relatedTicketsStickyStatus) {
      setStatusFilter(dataRelatedTicketStickyStatus?.me?.settings?.relatedTicketsStickyStatus);
    }
  }, [dataRelatedTicketStickyStatus]);

  return (
    <Box>
      <Box
        sx={{
          py: 2,
          width: '100%',
          px: {
            xs: 2,
            sm: 3,
            md: 4,
            lg: 5,
          },
          background: 'rgba(245, 246, 250, 0.5)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: 'pointer',
        }}
        onClick={() => {
          setRelatedOn(!relatedOn);
        }}
      >
        <Typography
          sx={{
            color: 'text.secondary',
            fontWeight: 700,
            fontSize: '18px',
            lineHeight: '25px',
          }}
        >
          Related Tickets
          <Box
            component={'span'}
            sx={{
              marginLeft: '10px',
              backgroundColor: 'grey.400',
              padding: '0 5px',
              borderRadius: '4px',
              color: 'background.default',
              fontSize: '13px',
              fontWeight: 600,
              lineHeight: '18px',
            }}
          >
            {tickets.length}
          </Box>
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <IconButton
            sx={{
              mr: 1,
            }}
            onClick={(e: any) => {
              e.preventDefault();
              e.stopPropagation();
              fetchRelatedTickets({
                variables: {
                  ticketId: parseInt(sourceTicketId),
                },
              });
            }}
          >
            <RefreshIcon />
          </IconButton>
          <IconButton>{relatedOn ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}</IconButton>
        </Box>
      </Box>
      <Collapse in={relatedOn}>
        <Box py={2}>
          <ComponentLoading loading={loadingSetStatus || loadingRelatedTicketSticyStatus || loading || loadingRelatedTickets}>
            <Box
              sx={{
                px: {
                  xs: 2,
                  sm: 3,
                  md: 4,
                  lg: 5,
                },
              }}
            >
              <TextField
                select
                label="Status Filter"
                name="filter"
                value={statusFilter}
                onChange={(e) => {
                  setStatusFilter(e.target.value);
                  submitStatus(e.target.value);
                }}
                fullWidth
              >
                <MenuItem value="all">All</MenuItem>
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="closed">Closed</MenuItem>
              </TextField>
            </Box>
            <Box>
              {tickets
                ?.filter((ticket: any) => {
                  if (statusFilter === 'all') return ticket;
                  if (statusFilter === 'active' && ticket.state.toLowerCase() === 'a') return ticket;
                  if (statusFilter === 'closed' && ticket.state.toLowerCase() === 'c') return ticket;
                  return null;
                })
                .map((relatedTicket: any, key: number) => {
                  return (
                    <Box
                      key={key}
                      py={1}
                      sx={{
                        cursor: 'pointer',
                        '&:hover': {
                          background: 'rgba(245, 246, 250, 0.5)',
                        },
                        px: {
                          xs: 2,
                          sm: 3,
                          md: 4,
                          lg: 5,
                        },
                        border: {
                          sm: '1px solid',
                          xl: 'none',
                        },
                        borderColor: {
                          sm: 'grey.200',
                        },
                        display: 'flex',
                      }}
                      onClick={() => {
                        window.open(`${PATH_SAVVY_TICKETS_VIEW}/${relatedTicket?.id}`, '_blank');
                      }}
                    >
                      <Typography
                        sx={{
                          width: '40%',
                          fontWeight: 400,
                          color: 'grey.400',
                          fontSize: '12px',
                          textTransform: 'uppercase',
                        }}
                      >
                        #{relatedTicket.id} ({relatedTicket.state})
                      </Typography>
                      <Box
                        sx={{
                          textAlign: 'left',
                          width: '60%',
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Box
                          sx={{
                            textAlign: 'left',
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: '14px',
                              fontWeight: 500,
                              lineHeight: '19px',
                            }}
                          >
                            {relatedTicket?.subject}
                          </Typography>
                          <Typography
                            sx={{
                              fontWeight: 600,
                              fontSize: '12px',
                              color: 'grey.400',
                              lineHeight: '17px',
                            }}
                          >
                            {relatedTicket?.state.toLowerCase() === 'c'
                              ? `CLOSED (${humanDate(relatedTicket?.updated)})`
                              : humanDateTime(relatedTicket?.updated)}
                          </Typography>
                          <Typography
                            sx={{
                              fontWeight: 600,
                              fontSize: '14px',
                              color: relatedTicket?.state.toLowerCase() === 'c' ? 'grey.400' : 'text.primary',
                              lineHeight: '17px',
                              textDecoration: 'underline',
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setTargetTicketId(relatedTicket.id);
                              setTargetTicketSubject(relatedTicket.subject);
                              setShowMergeModal(true);
                            }}
                          >
                            Merge
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
            </Box>
          </ComponentLoading>
          <MergeTicketDialog
            open={showMergeModal}
            setOpen={setShowMergeModal}
            sourceTicketId={sourceTicketId}
            sourceTicketSubject={sourceTicketSubject}
            targetTicketId={targetTicketId}
            targetTicketSubject={targetTicketSubject}
          />
        </Box>
      </Collapse>
    </Box>
  );
};

export default RelatedTickets;
