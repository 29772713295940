import React from 'react';
import { Typography, Grid, Box, Divider } from '@mui/material';
import { LayoutDashboard, Stepper } from 'components';

interface LayoutTicketCreateProps {
  activeStep: number;
  component: React.ReactNode;
}

const LayoutTicketCreate: React.FC<LayoutTicketCreateProps> = (props) => {
  const { activeStep, component } = props;

  const steps = ['Select Ticket Type', 'Create Ticket'];

  return (
    <LayoutDashboard backgroundColor="#FFFFFF">
      <Grid container spacing={2}>
        <Grid item xl={3} lg={2} xs={false}></Grid>
        <Grid item xl={6} lg={8} xs={12}>
          <Box pt={5}>
            <Typography variant="h2" gutterBottom textAlign="center">
              Create Ticket
            </Typography>
          </Box>
          <Box>
            <Stepper steps={steps} activeStep={activeStep} />
          </Box>
          <Divider />
          {component}
        </Grid>
        <Grid item xl={3} lg={2} xs={false}></Grid>
      </Grid>
    </LayoutDashboard>
  );
};

export default LayoutTicketCreate;
