import { PickersShortcutsItem } from '@mui/x-date-pickers/PickersShortcuts';
import { DateRange } from '@mui/x-date-pickers-pro';
import dayjs, { Dayjs } from 'dayjs';

export const dateRangeShortCutItems: PickersShortcutsItem<DateRange<Dayjs>>[] = [
  {
    label: 'Last 90 days',
    getValue: () => {
      const today = dayjs();
      const lastDay = today.subtract(90, 'day');
      return [lastDay, today];
    },
  },
  {
    label: 'Last year',
    getValue: () => {
      const today = dayjs();
      return [today.subtract(1, 'year'), today];
    },
  },
  {
    label: 'Reset',
    getValue: () => [null, null],
  },
];
