import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Typography, Button } from '@mui/material';

import { ComponentLoading } from 'components';

import { MUTATION_UNCANCEL_SUBSCRIPTION_RENEWAL } from 'gql';
import { useMutation } from '@apollo/client';
import { setAlert } from 'state';

import { ISubscription } from 'types';

interface UncancelRenewalProps {
  subscription: ISubscription;
}

const UncancelRenewal: React.FC<UncancelRenewalProps> = (props) => {
  const { subscription } = props;
  const dispatch = useDispatch();

  const [uncancelRenewal, { data: dataUncancelRenewal, loading: loadingUncancelRenewal, error: errorUncancelRenewal }] = useMutation(
    MUTATION_UNCANCEL_SUBSCRIPTION_RENEWAL,
  );

  const handleUncancel = async () => {
    if (!subscription?.id) return;

    await uncancelRenewal({
      variables: {
        subscriptionId: subscription.id,
      },
    });
  };

  useEffect(() => {
    if (errorUncancelRenewal) {
      dispatch(setAlert('error', 'Unable to uncancel renewal'));
    } else if (dataUncancelRenewal) {
      if (dataUncancelRenewal?.uncancelSubscriptionRenewal?.ok) {
        dispatch(setAlert('success', 'Uncanceled renewal'));
      } else {
        dispatch(setAlert('error', dataUncancelRenewal?.uncancelSubscriptionRenewal?.error || 'Unable to uncancel renewal'));
      }
    }
  }, [errorUncancelRenewal, dispatch, dataUncancelRenewal]);

  return (
    <Box>
      <ComponentLoading loading={loadingUncancelRenewal}>
        <Box>
          <Typography
            variant="h3"
            sx={{
              color: 'error.main',
              fontWeight: 'bold',
            }}
          >
            Uncancel Renewal
          </Typography>
          <Box py={1}>
            <Typography>This will uncancel subscription renewal right now.</Typography>
          </Box>
          <Box
            py={1}
            sx={{
              textAlign: 'left',
            }}
          >
            <Button variant="contained" onClick={handleUncancel}>
              Uncancel Renewal...
            </Button>
          </Box>
        </Box>
      </ComponentLoading>
    </Box>
  );
};

export default UncancelRenewal;
