import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field } from 'formik';
import { TextField } from 'formik-mui';
import { Box, Link, Button, CircularProgress, Typography } from '@mui/material';
import AttachmentIcon from '@mui/icons-material/Attachment';

import { uploadFileDirectS3, createDocumentFromS3 } from 'helpers';
import { IUploadedFile } from 'types';
import { setAlert } from 'state';
import { default as CannedResponsesControl } from './CannedResponsesControl';

interface TicketTextareaWithFileInterface {
  name: string;
  label: string;
  errors: any;
  touched: any;
  type?: string;
  onChange: (event: any) => void;
  onBlur?: (event: any) => void;
  maxLength?: number;
  disabled?: boolean;
  multiline?: boolean;
  rows: number;
  maxLine?: number;
  isUploading: boolean;
  setIsUploading: (value: boolean) => void;
  uploadedFileList: IUploadedFile[];
  setUploadedFileList: (value: IUploadedFile[]) => void;
  setFieldValue?: (field: string, value: any) => void;
}

// Input UI
const TicketTextareaWithFile: React.FC<TicketTextareaWithFileInterface> = (props) => {
  const {
    name,
    label,
    onBlur,
    onChange,
    errors,
    touched,
    maxLength = 50,
    type = 'text',
    disabled = false,
    rows,
    maxLine = 0,
    isUploading,
    setIsUploading,
    uploadedFileList,
    setUploadedFileList,
    setFieldValue,
    ...rest
  } = props;

  const [numRow, setNumRow] = useState(rows);
  const [bodyString, setBodyString] = useState('');

  const dispatch = useDispatch();

  const { isSavvy } = useSelector((state: any) => state.auth);
  const [cannedResponseText, setCannedResponseText] = useState('');

  useEffect(() => {
    if (bodyString) {
      const num = (bodyString.match(/\n/g) || []).length;
      if (num > rows - 1) {
        if (maxLine) {
          setNumRow(Math.min(num + 1, maxLine));
        } else {
          setNumRow(num + 1);
        }
      } else {
        setNumRow(rows);
      }
    }
  }, [bodyString, rows, maxLine]);

  const uploadAttachments = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files || [];

    setIsUploading(true);
    const tmp = [...uploadedFileList];
    for (let i = 0; i < files.length; i++) {
      const result = await uploadFileDirectS3(files[i], true, '');

      if (result.success) {
        const documentData = await createDocumentFromS3(result.key);
        if (!documentData.ok) {
          dispatch(setAlert('error', documentData.error || 'Unable to upload attachment'));
        } else {
          tmp.push({
            id: documentData.document?.id || '',
            name: files[i].name,
            success: true,
          });
        }
      } else {
        dispatch(setAlert('error', 'Unable to upload attachment'));
      }

      setUploadedFileList([...tmp]);
    }
    setIsUploading(false);
  };

  const removeFile = (index: number) => {
    const tmp = [...uploadedFileList];
    tmp.splice(index, 1);
    setUploadedFileList([...tmp]);
  };

  const insertStringToSpecific = (originString: string, substr: string, position: number) => {
    const originStringArr = originString.split('');
    originStringArr.splice(position, 0, substr);

    return originString ? originStringArr.join('') : substr;
  };

  useEffect(() => {
    if (cannedResponseText) {
      const element = document.getElementById('body') as HTMLInputElement;

      if (element) {
        setFieldValue &&
          setFieldValue(
            'body',
            element.selectionStart !== null
              ? insertStringToSpecific(bodyString, cannedResponseText, element.selectionStart)
              : bodyString + cannedResponseText,
          );
        setBodyString(
          element.selectionStart !== null
            ? insertStringToSpecific(bodyString, cannedResponseText, element.selectionStart)
            : bodyString + cannedResponseText,
        );
      }

      setCannedResponseText('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cannedResponseText, setCannedResponseText]);

  return (
    <React.Fragment>
      <Box>
        <Box
          sx={{
            position: 'relative',
          }}
        >
          <Field
            name={name}
            label={label}
            component={TextField}
            type={type}
            onBlur={onBlur}
            onChange={(e: any) => {
              setBodyString(e.target.value);
              onChange(e);
            }}
            id="body"
            errors={errors}
            touched={touched}
            variant="outlined"
            fullWidth
            FormHelperTextProps={{
              sx: {
                color: 'red',
                ml: 0,
                lineHeight: '24px',
                mt: 0,
              },
            }}
            inputProps={{ maxLength: maxLength }}
            sx={{
              mb: 0,
            }}
            InputProps={{
              sx: {
                pb: 3,
              },
            }}
            disabled={disabled}
            multiline={true}
            rows={numRow}
            {...rest}
          />
          <Box
            sx={{
              position: 'absolute',
              right: 0,
              bottom: errors[name] && touched[name] ? '32px' : '8px',
            }}
          >
            <Box px={1}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: isUploading ? 'space-between' : 'flex-end',
                }}
              >
                {isUploading && <CircularProgress color="inherit" size={20} variant="indeterminate" />}
                <Button
                  href="#"
                  component={'label'}
                  onClick={(e: any) => {
                    if (isUploading) {
                      e.preventDefault();
                      e.stopPropagation();
                      return false;
                    }
                  }}
                  sx={{
                    color: 'rgba(150, 158, 179, 0.6)',
                    p: 0,
                    minWidth: 'auto',
                  }}
                >
                  <AttachmentIcon
                    sx={{
                      transform: 'rotate(-45deg)',
                    }}
                  />
                  <input
                    type="file"
                    id="attachFile"
                    hidden
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      uploadAttachments(e);
                    }}
                    name="attachments"
                    multiple
                  />
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
        {uploadedFileList.length !== 0 && (
          <Box
            sx={{
              px: 2,
            }}
          >
            {uploadedFileList.map((uploadedFile: IUploadedFile, index: number) => {
              return (
                <Box
                  key={index}
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    py: 0.5,
                  }}
                >
                  <Typography>{uploadedFile.name}</Typography>
                  <Typography
                    sx={{
                      color: uploadedFile.success ? '#4C8F5B' : '#E61B23',
                      marginLeft: '15px',
                    }}
                  >
                    {uploadedFile.success ? 'Uploaded' : 'Failed'}
                  </Typography>
                  {uploadedFile.success && (
                    <Link
                      href="#"
                      onClick={(e: any) => {
                        e.preventDefault();
                        e.stopPropagation();
                        removeFile(index);
                      }}
                      sx={{
                        marginLeft: '15px',
                      }}
                    >
                      Remove
                    </Link>
                  )}
                </Box>
              );
            })}
          </Box>
        )}
      </Box>
      {isSavvy && (
        <Box>
          <CannedResponsesControl setCannedResponseText={setCannedResponseText} />
        </Box>
      )}
    </React.Fragment>
  );
};

export default TicketTextareaWithFile;
