import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Dialog, Box, DialogTitle, DialogContent, TextField, DialogActions, Grid, Button, MenuItem, InputAdornment } from '@mui/material';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { DateRange } from '@mui/x-date-pickers-pro';
import dayjs, { Dayjs } from 'dayjs';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import { MUTATION_CREATE_REPORT_CARD } from 'gql';
import { useMutation } from '@apollo/client';
import { setAlert } from 'state';

import { ComponentLoading } from 'components';
import { dateRangeShortCutItems, TOO_FEW_FLIGHTS } from 'const';

interface CreateReportCardDialogProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  aircraftId: string;
  setSuccess?: (value: boolean) => void;
  setIsDebug: (value: boolean) => void;
  dateRange: DateRange<Dayjs>;
  setDateRange: (value: DateRange<Dayjs>) => void;
}

const CreateReportCardDialog: React.FC<CreateReportCardDialogProps> = (props) => {
  const { open, setOpen, aircraftId, setSuccess, setIsDebug, dateRange, setDateRange } = props;

  const [createReport, { data: dataReport, error: errorReport, loading: loadingReport }] = useMutation(MUTATION_CREATE_REPORT_CARD);
  const dispatch = useDispatch();

  const [kind, setKind] = useState('report_card');
  const [dateError, setDateError] = useState('');

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async () => {
    if (!dateRange[0] || !dateRange[1]) {
      setDateError('Please select start & end date');
      return;
    }

    await createReport({
      variables: {
        aircraftId,
        kind,
        startDate: dateRange[0]?.format('YYYY-MM-DD'),
        endDate: dateRange[1]?.format('YYYY-MM-DD'),
      },
    });
  };

  useEffect(() => {
    if (errorReport) {
      dispatch(setAlert('error', 'Unable to create report card'));
    } else if (dataReport) {
      if (dataReport?.createReportCard?.ok) {
        dispatch(setAlert('success', 'Created report card.'));
        setOpen(false);
        setSuccess && setSuccess(true);
      } else {
        if (dataReport?.createReportCard?.errorCodes?.length && dataReport.createReportCard.errorCodes.includes(TOO_FEW_FLIGHTS)) {
          setIsDebug(true);
          setOpen(false);
        } else {
          dispatch(setAlert('error', dataReport?.createReportCard?.error || 'Unable to create report card'));
        }
      }
    }
  }, [dataReport, errorReport, setOpen, dispatch, setSuccess]);

  useEffect(() => {
    if (open) {
      setKind('report_card');
      const now = new Date();
      setDateRange([
        dayjs(`${now.getFullYear() - 1}-${now.getMonth() + 1}-${now.getDate()}`),
        dayjs(`${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}`),
      ]);
      setDateError('');
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={handleClose}
      PaperProps={{
        sx: {
          maxWidth: '400px',
        },
      }}
    >
      <DialogTitle
        sx={{
          textAlign: 'center',
          fontSize: '24px',
          fontWeight: 700,
        }}
      >
        Create Report Card
      </DialogTitle>
      <DialogContent>
        <ComponentLoading loading={loadingReport}>
          <Box py={1}>
            <TextField
              select
              value={kind}
              label="Report type"
              onChange={(e: any) => {
                setKind(e.target.value);
              }}
              sx={{
                mb: 0,
              }}
              fullWidth
            >
              <MenuItem value="report_card">Report Card</MenuItem>
              <MenuItem value="trend_analysis">Trend Analysis</MenuItem>
            </TextField>
          </Box>
          <Box py={1}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateRangePicker
                localeText={{
                  start: 'Check-in',
                  end: 'Check-out',
                }}
                value={dateRange}
                onChange={(newValue: DateRange<Dayjs>) => {
                  setDateError('');
                  setDateRange(newValue);
                }}
                slots={{ field: SingleInputDateRangeField }}
                slotProps={{
                  textField: {
                    variant: 'outlined',
                    label: 'Choose date',
                    inputProps: {
                      readOnly: true,
                    },
                    placeholder: 'Start-End',
                    InputProps: {
                      startAdornment: (
                        <InputAdornment position="start">
                          <DateRangeIcon />
                        </InputAdornment>
                      ),
                    },
                    error: Boolean(dateError),
                    helperText: dateError,
                    sx: {
                      minWidth: '280px',
                      mb: 0,
                    },
                    fullWidth: true,
                  },
                  shortcuts: {
                    items: dateRangeShortCutItems,
                  },
                }}
              />
            </LocalizationProvider>
          </Box>
        </ComponentLoading>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button variant="outlined" onClick={handleClose} disabled={loadingReport} fullWidth>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="contained" onClick={onSubmit} disabled={loadingReport} fullWidth>
              Submit
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default CreateReportCardDialog;
