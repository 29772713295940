import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { IAircraftEligibility } from 'types';
import RequirePurchase from './RequirePurchase';
import { PrebuyFormComponent } from './Prebuy';
import { setAlert } from 'state';

interface PrebuyFormProps {
  ticketAircraft: IAircraftEligibility | undefined;
}

const PrebuyForm: React.FC<PrebuyFormProps> = (props) => {
  const { ticketAircraft } = props;

  const { isTechnician } = useSelector((state: any) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isTechnician) {
      dispatch(setAlert('error', "You're not authorized to create prebuy ticket."));
    }
  }, [isTechnician, dispatch]);

  return (
    <React.Fragment>
      {!isTechnician && (
        <RequirePurchase
          ticketAircraft={ticketAircraft}
          eligibility={ticketAircraft?.ticketEligibility?.prebuy}
          serviceName="SavvyPrebuy"
        />
      )}
      {ticketAircraft && !isTechnician && (
        <PrebuyFormComponent ticketAircraft={ticketAircraft} eligibility={ticketAircraft?.ticketEligibility?.prebuy} />
      )}
    </React.Fragment>
  );
};

export default PrebuyForm;
