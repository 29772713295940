import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, IconButton, CircularProgress, Popover, List, ListItem, Typography } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DownloadIcon from '@mui/icons-material/Download';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { DataGridPro, GridValueGetterParams, GridRenderCellParams } from '@mui/x-data-grid-pro';

import { IAircraftDetail, ICertificatesOfInsurance } from 'types';
import { setAlert } from 'state';

import { AddCoiDialog, ConfirmDeleteCoiDialog, ConfirmApproveCoiDialog, EditCoiDialog, RequestCoiDialog } from '../Dialog';
import { PdfDialog } from 'pages/Tickets/components/ContentComponent';
import { ComponentLoading } from 'components';

import {
  QUERY_SAVVY_AIRCRAFT_COIS_PREVIEW,
  QUERY_ME_AIRCRAFT_COI_PREVIEW,
  MUTATION_DELETE_CERTIFICATE_OF_INSURANCE,
  MUTATION_APPROVE_CERTIFICATE_OF_INSURANCE,
  QUERY_SAVVY_AIRCRAFT_COIS_DOWNLOAD,
  QUERY_ME_AIRCRAFT_COI_DOWNLOAD,
} from 'gql';
import { useLazyQuery, useMutation } from '@apollo/client';
import { apolloClient } from 'services';

import { isHeic, downloadFileInNewTab } from 'helpers';

interface CoiDetailProps {
  aircraft: IAircraftDetail | undefined;
  canManageCois?: boolean;
}

const CoiDetail: React.FC<CoiDetailProps> = (props) => {
  const { aircraft, canManageCois = false } = props;

  const [showAddDialog, setShowAddDialog] = useState(false);

  const [fetchSavvyAttachment, { data: dataSavvyAttachment, loading: loadingSavvyAttachment, error: errorSavvyAttachment }] = useLazyQuery(
    QUERY_SAVVY_AIRCRAFT_COIS_PREVIEW,
    {
      fetchPolicy: 'network-only',
    },
  );
  const [fetchAttachment, { data: dataAttachment, loading: loadingAttachment, error: errorAttachment }] =
    useLazyQuery(QUERY_ME_AIRCRAFT_COI_PREVIEW);

  const [deleteCoi, { data: dataDeleteCoi, loading: loadingDeleteCoi, error: errorDeleteCoi }] = useMutation(
    MUTATION_DELETE_CERTIFICATE_OF_INSURANCE,
  );
  const [approveCoi, { data: dataApproveCoi, loading: loadingApproveCoi, error: errorApproveCoi }] = useMutation(
    MUTATION_APPROVE_CERTIFICATE_OF_INSURANCE,
  );

  const dispatch = useDispatch();
  const { isSavvy } = useSelector((state: any) => state.auth);

  const [showFileName, setShowFileName] = useState('');
  const [showFile, setShowFile] = useState('');
  const [showPdfFileDialog, setShowPdfFileDialog] = useState(false);
  const [previewAttachmentId, setPreviewAttachmentId] = useState('');

  const [certificateOfInsuranceId, setCertificateOfInsuranceId] = useState('');
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showApproveDialog, setShowApproveDialog] = useState(false);

  const [requestCoiDialog, setRequestCoiDialog] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [editCoi, setEditCoi] = useState<ICertificatesOfInsurance>();

  const [pageSize, setPageSize] = useState(5);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const onSubmitAction = async () => {
    await deleteCoi({
      variables: {
        certificateOfInsuranceId,
      },
    });
  };

  const approveAction = async () => {
    await approveCoi({
      variables: {
        certificateOfInsuranceId,
      },
    });
  };

  const loadPreviewSavvy = async (certificateOfInsuranceId: string) => {
    setPreviewAttachmentId(certificateOfInsuranceId);
    await fetchSavvyAttachment({
      variables: {
        aircraftId: aircraft?.id,
        certificateOfInsuranceId,
      },
      fetchPolicy: 'network-only',
    });
  };

  const loadPreview = async (certificateOfInsuranceId: string) => {
    setPreviewAttachmentId(certificateOfInsuranceId);
    if (aircraft) {
      await fetchAttachment({
        variables: {
          aircraftId: parseInt(aircraft?.id),
          certificateOfInsuranceId,
        },
        fetchPolicy: 'network-only',
      });
    }
  };

  const findMatchedCoi = (id: string) => {
    if (aircraft?.certificatesOfInsurance?.length) {
      for (let i = 0; i < aircraft?.certificatesOfInsurance.length; i++) {
        if (aircraft.certificatesOfInsurance[i].id === id) {
          setEditCoi(aircraft.certificatesOfInsurance[i]);
        }
      }
    }
  };

  const [loadingDownloadUrl, setLoadingDownloadUrl] = useState(false);

  const downloadDocument = async (certificateOfInsuranceId: string) => {
    if (aircraft?.id && certificateOfInsuranceId) {
      if (isSavvy) {
        setLoadingDownloadUrl(true);
        const { data: dataDownloadUrl } = await apolloClient.query({
          query: QUERY_SAVVY_AIRCRAFT_COIS_DOWNLOAD,
          variables: {
            aircraftId: aircraft.id,
            certificateOfInsuranceId,
          },
          fetchPolicy: 'no-cache',
        });
        setLoadingDownloadUrl(false);
        if (dataDownloadUrl?.savvy?.aircraft?.certificatesOfInsurance[0]?.document?.downloadUrl) {
          downloadFileInNewTab(dataDownloadUrl?.savvy?.aircraft?.certificatesOfInsurance[0]?.document?.downloadUrl);
        }
      } else {
        setLoadingDownloadUrl(true);
        const { data: dataDownloadUrl } = await apolloClient.query({
          query: QUERY_ME_AIRCRAFT_COI_DOWNLOAD,
          variables: {
            aircraftId: parseInt(aircraft.id),
            certificateOfInsuranceId,
          },
          fetchPolicy: 'no-cache',
        });
        setLoadingDownloadUrl(false);
        if (dataDownloadUrl?.me?.aircraft[0]?.certificatesOfInsurance[0]?.document?.downloadUrl) {
          downloadFileInNewTab(dataDownloadUrl?.me?.aircraft[0]?.certificatesOfInsurance[0]?.document?.downloadUrl);
        }
      }
    }
  };

  useEffect(() => {
    if (errorSavvyAttachment) {
      dispatch(setAlert('error', 'Unable to preview certificate of insurance'));
    } else if (dataSavvyAttachment) {
      if (dataSavvyAttachment?.savvy?.aircraft?.certificatesOfInsurance) {
        setShowFileName(dataSavvyAttachment?.savvy?.aircraft?.certificatesOfInsurance[0]?.document?.fileName);
        if (isHeic(dataSavvyAttachment?.savvy?.aircraft?.certificatesOfInsurance[0]?.document?.fileName)) {
          dataSavvyAttachment?.savvy?.aircraft?.certificatesOfInsurance[0]?.document?.base64Data &&
            setShowFile(dataSavvyAttachment?.savvy?.aircraft?.certificatesOfInsurance[0]?.document?.base64Data);
        } else {
          dataSavvyAttachment?.savvy?.aircraft?.certificatesOfInsurance[0]?.document?.previewUrl &&
            setShowFile(dataSavvyAttachment?.savvy?.aircraft?.certificatesOfInsurance[0]?.document?.previewUrl);
        }
        setShowPdfFileDialog(true);
      }
    }
  }, [dispatch, errorSavvyAttachment, dataSavvyAttachment]);

  useEffect(() => {
    if (errorAttachment) {
      dispatch(setAlert('error', 'Unable to preview certificate of insurance'));
    } else if (dataAttachment) {
      if (dataAttachment?.me?.aircraft[0]?.certificatesOfInsurance) {
        setShowFileName(dataAttachment?.me?.aircraft[0]?.certificatesOfInsurance[0]?.document?.fileName);
        if (isHeic(dataAttachment?.me?.aircraft[0]?.certificatesOfInsurance[0]?.document?.fileName)) {
          dataAttachment?.me?.aircraft[0]?.certificatesOfInsurance[0]?.document?.base64Data &&
            setShowFile(dataAttachment?.me?.aircraft[0]?.certificatesOfInsurance[0]?.document?.base64Data);
        } else {
          dataAttachment?.me?.aircraft[0]?.certificatesOfInsurance[0]?.document?.previewUrl &&
            setShowFile(dataAttachment?.me?.aircraft[0]?.certificatesOfInsurance[0]?.document?.previewUrl);
        }
        setShowPdfFileDialog(true);
      }
    }
  }, [dispatch, errorAttachment, dataAttachment]);

  useEffect(() => {
    if (errorDeleteCoi) {
      dispatch(setAlert('error', 'Unable to delete certificate of insurance'));
    } else if (dataDeleteCoi) {
      if (dataDeleteCoi.deleteCertificateOfInsurance?.ok) {
        dispatch(setAlert('success', 'Deleted certificate of insurance'));
      } else {
        dispatch(setAlert('error', dataDeleteCoi.deleteCertificateOfInsurance?.error || 'Unable to delete certificate of insurance'));
      }
    }
  }, [dataDeleteCoi, errorDeleteCoi, dispatch]);

  useEffect(() => {
    if (errorApproveCoi) {
      dispatch(setAlert('error', 'Unable to approve certificate of insurance'));
    } else if (dataApproveCoi) {
      if (dataApproveCoi.approveCertificateOfInsurance?.ok) {
        dispatch(setAlert('success', 'Approved certificate of insurance'));
      } else {
        dispatch(setAlert('error', dataApproveCoi.approveCertificateOfInsurance?.error || 'Unable to approve certificate of insurance'));
      }
    }
  }, [dataApproveCoi, errorApproveCoi, dispatch]);

  return (
    <Box>
      <ComponentLoading loading={loadingDeleteCoi || loadingApproveCoi}>
        <Box>
          {aircraft && aircraft.certificatesOfInsurance.length === 0 && isSavvy && (
            <Box py={1}>
              <Typography>Insurance Date: {aircraft.coiDate ? aircraft.coiDate.toString().substring(0, 10) : 'None'}</Typography>
              <Typography>Insurance Underwriter: {aircraft.coiUnderwriter ? aircraft.coiUnderwriter : 'None'}</Typography>
              <Typography>Insurance Agency: {aircraft.coiAgency ? aircraft.coiAgency : 'None'}</Typography>
              <Typography>Insurance Expiration: {aircraft.coiExpiration ? aircraft.coiExpiration.toString() : 'None'}</Typography>
            </Box>
          )}
        </Box>
        <Box py={1}>
          <Button
            variant="contained"
            onClick={() => {
              setShowAddDialog(true);
            }}
            disabled={isSavvy && !canManageCois}
          >
            + Add COI
          </Button>
          {canManageCois && (
            <Button
              variant="contained"
              onClick={() => {
                setRequestCoiDialog(true);
              }}
              sx={{
                ml: 1.5,
              }}
            >
              Request COI
            </Button>
          )}
        </Box>
        <Box
          py={1}
          sx={{
            width: '100%',
          }}
        >
          {!!aircraft?.certificatesOfInsurance?.length && (
            <DataGridPro
              sx={{
                width: '100%',
                minWidth: '100px',
                '& .MuiDataGrid-columnSeparator': {
                  display: 'none',
                },
                '& .MuiDataGrid-cell:focus': {
                  outline: 'none',
                },
                '& .MuiDataGrid-row': {
                  cursor: 'pointer',
                  borderBottom: '1px solid',
                  borderColor: 'grey.200',
                },
                '& .MuiDataGrid-columnHeader:focus': {
                  outline: 'none',
                },
                '& .MuiDataGrid-cellContent': {
                  whiteSpace: 'break-spaces',
                },
                '& .MuiDataGrid-columnHeader': {
                  padding: 0,
                },
                border: 'none',
              }}
              autoHeight
              disableColumnResize={true}
              disableSelectionOnClick={true}
              rowsPerPageOptions={[5, 10, 25, 100]}
              pageSize={pageSize}
              onPageSizeChange={(newPage) => setPageSize(newPage)}
              pagination
              showColumnRightBorder={false}
              disableColumnMenu={true}
              getRowHeight={() => 'auto'}
              rows={aircraft?.certificatesOfInsurance?.length ? aircraft?.certificatesOfInsurance : []}
              columns={[
                {
                  field: 'underwriter',
                  headerName: 'Underwriter',
                  flex: 0.8,
                },
                {
                  field: 'agent',
                  headerName: 'Agent',
                  flex: 0.7,
                },
                {
                  field: 'document',
                  headerName: 'Document',
                  valueGetter: (params: GridValueGetterParams) => {
                    return params.row.document?.fileName ? params.row.document?.fileName : '';
                  },
                  flex: 1,
                },
                {
                  field: 'created',
                  headerName: 'Created',
                  valueGetter: (value: GridValueGetterParams) => {
                    return value.row?.created ? value.row?.created.toString().substring(0, 10) : '';
                  },
                  flex: 0.7,
                },
                {
                  field: 'approved',
                  headerName: 'Approved',
                  valueGetter: (value: GridValueGetterParams) => {
                    return value.row?.approved ? value.row?.approved.toString().substring(0, 10) : '';
                  },
                  flex: 0.7,
                },
                {
                  field: 'receivedDate',
                  headerName: 'Received',
                  valueGetter: (value: GridValueGetterParams) => {
                    return value.row?.receivedDate ? value.row?.receivedDate.toString().substring(0, 10) : '';
                  },
                  flex: 0.7,
                },
                {
                  field: 'expirationDate',
                  headerName: 'Expiration',
                  valueGetter: (value: GridValueGetterParams) => {
                    return value.row?.expirationDate ? value.row?.expirationDate.toString().substring(0, 10) : '';
                  },
                  flex: 0.7,
                },
                {
                  field: 'id',
                  headerName: '',
                  sortable: false,
                  flex: 0.7,
                  renderCell: (params: GridRenderCellParams<string>) => {
                    return (!loadingSavvyAttachment && !loadingAttachment && !loadingDownloadUrl) ||
                      previewAttachmentId !== params.value ? (
                      <Box>
                        <IconButton
                          title="Preview"
                          onClick={() => {
                            if (isSavvy) {
                              loadPreviewSavvy(params.value || '');
                            } else {
                              loadPreview(params.value || '');
                            }
                          }}
                        >
                          <VisibilityIcon />
                        </IconButton>
                        <IconButton
                          title="Download"
                          sx={{
                            ml: 1,
                          }}
                          onClick={() => {
                            if (params?.value) {
                              downloadDocument(params.value);
                            }
                          }}
                        >
                          <DownloadIcon />
                        </IconButton>
                        {canManageCois && (
                          <IconButton
                            sx={{
                              ml: 1,
                            }}
                            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                              setCertificateOfInsuranceId(params.value || '');
                              setAnchorEl(e.currentTarget);
                            }}
                          >
                            <MoreVertIcon />
                          </IconButton>
                        )}
                      </Box>
                    ) : (
                      <CircularProgress color="inherit" size={20} variant="indeterminate" />
                    );
                  },
                },
              ]}
            />
          )}
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'right',
            }}
          >
            <List component="div">
              {isSavvy && (
                <ListItem
                  ContainerComponent="div"
                  button
                  onClick={() => {
                    findMatchedCoi(certificateOfInsuranceId);
                    setShowEditDialog(true);
                    setAnchorEl(null);
                  }}
                >
                  Edit
                </ListItem>
              )}
              <ListItem
                ContainerComponent="div"
                button
                onClick={() => {
                  setShowDeleteDialog(true);
                  setAnchorEl(null);
                }}
              >
                Delete
              </ListItem>
              <ListItem
                ContainerComponent="div"
                button
                onClick={() => {
                  setShowApproveDialog(true);
                  setAnchorEl(null);
                }}
              >
                Approve
              </ListItem>
            </List>
          </Popover>
        </Box>
      </ComponentLoading>
      <AddCoiDialog open={showAddDialog} setOpen={setShowAddDialog} aircraftId={aircraft?.id ? aircraft?.id : ''} />
      <PdfDialog
        showFileDialog={showPdfFileDialog}
        setShowFileDialog={setShowPdfFileDialog}
        showFileName={showFileName}
        showFile={showFile}
        previewAttachmentId={previewAttachmentId}
      />
      <ConfirmDeleteCoiDialog open={showDeleteDialog} setOpen={setShowDeleteDialog} onSubmitAction={onSubmitAction} />
      <ConfirmApproveCoiDialog open={showApproveDialog} setOpen={setShowApproveDialog} onSubmitAction={approveAction} />
      <RequestCoiDialog
        open={requestCoiDialog}
        setOpen={setRequestCoiDialog}
        aircraftId={aircraft?.id ? aircraft.id : ''}
        registration={aircraft?.registration ? aircraft.registration : ''}
      />
      {editCoi && <EditCoiDialog open={showEditDialog} setOpen={setShowEditDialog} editCoi={editCoi} />}
    </Box>
  );
};

export default CoiDetail;
