import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Dialog, DialogContent, DialogActions, Typography, Box, TextField, Grid, Button } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';

import { setAlert } from 'state';

import { MUTATION_UPDATE_CERTIFICATE_OF_INSURANCE } from 'gql';
import { useMutation } from '@apollo/client';

import { ComponentLoading, CustomDatePicker } from 'components';

import { ICertificatesOfInsurance } from 'types';

interface EditCoiDialogProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  editCoi: ICertificatesOfInsurance;
}

const EditCoiDialog: React.FC<EditCoiDialogProps> = (props) => {
  const { open, setOpen, editCoi } = props;

  const [updateCoi, { data: dataCoi, loading: loadingCoi, error: errorCoi }] = useMutation(MUTATION_UPDATE_CERTIFICATE_OF_INSURANCE);
  const dispatch = useDispatch();

  const [agent, setAgent] = useState('');

  const [underwriter, setUnderwriter] = useState('');

  const [expirationDate, setExpirationDate] = useState<Dayjs | null>(null);
  const [expirationDateError, setExpirationDateError] = useState('');

  const [receivedDate, setReceivedDate] = useState<Dayjs | null>(null);
  const [receivedDateError, setReceivedDateError] = useState('');

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async () => {
    if (!expirationDate) {
      setExpirationDateError('Please select expiration date');
      return;
    }

    if (!receivedDate) {
      setReceivedDateError('Please select received date');
      return;
    }

    await updateCoi({
      variables: {
        agent,
        underwriter,
        expirationDate,
        receivedDate,
        certificateOfInsuranceId: editCoi.id,
      },
    });
  };

  useEffect(() => {
    if (errorCoi) {
      dispatch(setAlert('error', 'Unable to edit certificate of insurance'));
    } else if (dataCoi) {
      if (dataCoi?.updateCertificateOfInsurance?.ok) {
        dispatch(setAlert('success', 'Updated certificate of insurance'));
        setOpen(false);
      } else {
        dispatch(setAlert('error', dataCoi?.updateCertificateOfInsurance?.error || 'Unable to edit certificate of insurance'));
      }
    }
  }, [errorCoi, dispatch, dataCoi, setOpen]);

  useEffect(() => {
    if (open) {
      setAgent(editCoi.agent ? editCoi.agent : '');
      setUnderwriter(editCoi.underwriter ? editCoi.underwriter : '');
      setExpirationDate(editCoi.expirationDate ? dayjs(editCoi.expirationDate) : null);
      setReceivedDate(editCoi.receivedDate ? dayjs(editCoi.receivedDate) : null);
    }
  }, [open, editCoi]);

  return (
    <Dialog open={open} fullWidth onClose={handleClose}>
      <DialogContent>
        <Typography variant="h2" gutterBottom align="center">
          Edit Certificate of Insurance
        </Typography>
        <ComponentLoading loading={loadingCoi}>
          <Box>
            <TextField
              label="Agent"
              name="agent"
              value={agent}
              onChange={(e) => {
                setAgent(e.target.value);
              }}
              fullWidth
            />
            <TextField
              label="Underwriter"
              name="underwriter"
              value={underwriter}
              onChange={(e) => {
                setUnderwriter(e.target.value);
              }}
              fullWidth
            />
            <CustomDatePicker
              label="Expiration Date *"
              value={expirationDate}
              setValue={setExpirationDate}
              error={expirationDateError}
              setError={setExpirationDateError}
              sx={{
                ml: 0,
              }}
            />
            <CustomDatePicker
              label="Received Date *"
              value={receivedDate}
              setValue={setReceivedDate}
              error={receivedDateError}
              setError={setReceivedDateError}
              sx={{
                ml: 0,
              }}
            />
          </Box>
        </ComponentLoading>
      </DialogContent>

      <DialogActions>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button variant="outlined" onClick={handleClose} disabled={loadingCoi} fullWidth>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="contained" onClick={onSubmit} disabled={loadingCoi} fullWidth>
              Submit
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default EditCoiDialog;
