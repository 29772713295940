import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Typography, IconButton } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { DataGridPro, GridValueGetterParams, GridRenderCellParams } from '@mui/x-data-grid-pro';

import { LayoutDashboard, PageLoading, CustomGridToolbar } from 'components';

import { QUERY_FINANCIAL_FILES } from 'gql';
import { useQuery } from '@apollo/client';

import { setAlert } from 'state';
import { getTimesheetDateString } from 'helpers';

import { QifUploadForm } from './components';

const Financials: React.FC = () => {
  const { data: dataFinancials, error: errorFinancials, loading: loadingFinancials } = useQuery(QUERY_FINANCIAL_FILES);
  const dispatch = useDispatch();

  const [pageSize, setPageSize] = useState(25);

  const [filterButtonEl, setFilterButtonEl] = useState<HTMLButtonElement | null>(null);

  const [list, setList] = useState<
    {
      id: number;
      name: string;
      date: Date;
      downloadUrl: string;
    }[]
  >([]);

  useEffect(() => {
    if (errorFinancials) {
      dispatch(setAlert('error', 'Unable to load financial data'));
    }
  }, [errorFinancials, dispatch]);

  useEffect(() => {
    if (dataFinancials?.financialFiles?.length) {
      const tmp = dataFinancials.financialFiles.map((item: any, key: number) => {
        return {
          id: key,
          name: item.name,
          date: item.date,
          downloadUrl: item.downloadUrl,
        };
      });

      setList(
        [...tmp].sort((item1, item2) => {
          return item1.date > item2.date ? -1 : 1;
        }),
      );
    }
  }, [dataFinancials]);

  return (
    <LayoutDashboard backgroundColor="#fff">
      {loadingFinancials && <PageLoading />}
      <Box p={4}>
        <Typography variant="h2" gutterBottom>
          Payroll
        </Typography>
        <QifUploadForm />
        <Box py={1}>
          <Box py={1}>
            <DataGridPro
              sx={{
                width: '100%',
                minWidth: '100px',
                '& .MuiDataGrid-cell:focus': {
                  outline: 'none',
                },
                '& .MuiDataGrid-row': {
                  cursor: 'pointer',
                  borderBottom: '1px solid',
                  borderColor: 'grey.200',
                },
                '& .MuiDataGrid-columnHeader:focus': {
                  outline: 'none',
                },
                '& .MuiDataGrid-toolbarContainer .MuiTextField-root': {
                  mb: 0,
                },
                border: 'none',
              }}
              autoHeight
              disableSelectionOnClick
              rowsPerPageOptions={[5, 10, 25, 100]}
              pageSize={pageSize}
              onPageSizeChange={(newPage) => {
                setPageSize(newPage);
              }}
              pagination
              showColumnRightBorder={false}
              disableColumnSelector
              disableColumnMenu
              rows={list}
              columns={[
                {
                  field: 'name',
                  headerName: 'Name',
                  flex: 1,
                },
                {
                  field: 'date',
                  headerName: 'Date',
                  valueGetter: (params: GridValueGetterParams) => {
                    return params.value ? getTimesheetDateString(params.value) : '';
                  },
                  flex: 1,
                },
                {
                  field: 'downloadUrl',
                  headerName: 'Download',
                  renderCell: (params: GridRenderCellParams) => {
                    if (!params.value) return '';
                    return (
                      <IconButton
                        onClick={() => {
                          window.open(params.value, '_blank');
                        }}
                      >
                        <DownloadIcon />
                      </IconButton>
                    );
                  },
                  flex: 0.5,
                },
              ]}
              components={{ Toolbar: CustomGridToolbar }}
              componentsProps={{
                toolbar: {
                  setFilterButtonEl,
                },
                filterPanel: {
                  sx: {
                    '& .MuiDataGrid-filterForm': {
                      '& .MuiTextField-root': {
                        mb: 0,
                      },
                    },
                  },
                },
                panel: {
                  anchorEl: filterButtonEl,
                  placement: 'bottom-end',
                },
              }}
            />
          </Box>
        </Box>
      </Box>
    </LayoutDashboard>
  );
};

export default Financials;
