import React from 'react';
import { useDispatch } from 'react-redux';
import { Box, Table, TableHead, TableBody, TableRow, TableCell, Typography } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { copyClipboard, copyClipboardTextArea } from 'helpers';
import { setAlert } from 'state';

interface GamiTableProps {
  diffData: any;
}

const GamiTable: React.FC<GamiTableProps> = (props) => {
  const { diffData } = props;

  const dispatch = useDispatch();

  const copyCylinder = () => {
    if (diffData.data) {
      let cylinderStrings: string[] = [];

      cylinderStrings = diffData.data.map((data: any) => {
        return data.name;
      });

      copyClipboard(cylinderStrings.join('\t'));
      dispatch(setAlert('success', 'Copied Cylinders'));
    }
  };

  const copyPeak = () => {
    if (diffData.data) {
      let valueStrings: string[] = [];

      valueStrings = diffData.data.map((data: any) => {
        return data.position;
      });

      copyClipboard(valueStrings.join('\t'));
      dispatch(setAlert('success', 'Copied Values'));
    }
  };

  const copyAll = () => {
    if (diffData.data) {
      let strings: string[] = [];

      strings = diffData.data.map((data: any) => {
        return `${data.name} peaked at ${data.position}`;
      });

      strings.push(`GAMI spread is ${diffData.spread}`);

      copyClipboardTextArea(`${diffData.selectedPoint}-${diffData.secondaryPoint}\n` + strings.join('\n'));
      dispatch(setAlert('success', 'Copied Gami Tables'));
    }
  };

  return (
    <Box>
      <Table>
        <TableHead>
          <TableRow
            sx={{
              backgroundColor: 'grey.800',
            }}
          >
            <TableCell
              sx={{
                fontSize: '12px',
                fontWeight: 500,
                textTransform: 'uppercase',
              }}
            >
              Cylinder
              <ContentCopyIcon
                sx={{
                  width: '12px',
                  height: '12px',
                  ml: 1,
                  cursor: 'pointer',
                }}
                onClick={copyCylinder}
              />
            </TableCell>
            <TableCell
              sx={{
                fontSize: '12px',
                fontWeight: 500,
                textTransform: 'uppercase',
              }}
            >
              Peaked At
              <ContentCopyIcon
                sx={{
                  width: '12px',
                  height: '12px',
                  ml: 1,
                  cursor: 'pointer',
                }}
                onClick={copyPeak}
              />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {diffData.data?.map((data: any, key: number) => {
            return (
              <TableRow key={key}>
                <TableCell>{data.name}</TableCell>
                <TableCell>{data.position}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {diffData.spread && (
        <Typography
          sx={{
            px: 2,
            py: 1,
            textAlign: 'right',
            fontSize: '16px',
          }}
        >
          GAMI spread is {diffData.spread}
          <ContentCopyIcon
            sx={{
              width: '12px',
              height: '12px',
              ml: 1,
              cursor: 'pointer',
            }}
            onClick={copyAll}
          />
        </Typography>
      )}
    </Box>
  );
};

export default GamiTable;
