import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography, Hidden } from '@mui/material';

import { IChartVisibleSetting, ISerieData, ISerieSet, IAircraftSetting, IFlightChartSetting, IFlightData, IUnitConversion } from 'types';
import { Chart, ZoomSync, ModeManager, GamiManager, SelectSeries, AppearanceSets, SeriesLegend } from './chartComponents';

interface FlightChartViewProps {
  seriesData: ISerieData[];
  chartSettings: IChartVisibleSetting[];
  secondarySeriesData: ISerieData[];
  chartSecondarySettings: IChartVisibleSetting[];
  timeSeries: number[];
  zoomSync: ZoomSync;
  modeManager: ModeManager;
  setDiffData: (value: any) => void;
  gamiManager?: GamiManager;
  leftSerie: ISerieSet | undefined;
  setLeftSerie: (value: ISerieSet) => void;
  cylinderCount: number | undefined;
  seriesList: ISerieSet[];
  rightSerie: ISerieSet | undefined;
  setRightSerie: (value: ISerieSet) => void;
  setChartSettings: (value: IChartVisibleSetting[]) => void;
  setChartSecondarySettings: (value: IChartVisibleSetting[]) => void;
  zoomController?: boolean;
  aircraftId: string;
  chartNumber: number;
  aircraftSetting: IAircraftSetting | undefined;
  aircraftSecondarySetting: IAircraftSetting | undefined;
  settings: IFlightChartSetting | undefined;
  flightData: IFlightData;
  isGami?: boolean;
  conversions?: IUnitConversion[];
  isMag?: boolean;
  setPresetMode?: (value: string) => void;
}

const FlightChartView: React.FC<FlightChartViewProps> = (props) => {
  const {
    seriesData,
    chartSettings,
    secondarySeriesData,
    chartSecondarySettings,
    timeSeries,
    zoomSync,
    modeManager,
    setDiffData,
    gamiManager = undefined,
    leftSerie,
    setLeftSerie,
    seriesList,
    cylinderCount,
    rightSerie,
    setRightSerie,
    setChartSettings,
    setChartSecondarySettings,
    aircraftId,
    chartNumber,
    aircraftSetting,
    aircraftSecondarySetting,
    settings,
    flightData,
    isGami = false,
    isMag = false,
    conversions = [],
    setPresetMode,
  } = props;

  const { isSavvy } = useSelector((state: any) => state.auth);

  const [leftConversion, setLeftConversion] = useState<IUnitConversion>();
  const [rightConversion, setRightConversion] = useState<IUnitConversion>();

  return (
    <React.Fragment>
      <Hidden mdDown>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'end',
            justifyContent: 'space-between',
            width: '100%',
            overflowX: 'auto',
            '&::-webkit-scrollbar': {
              width: '0.3em',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(0,0,0,.1)',
              borderRadius: 2,
            },
          }}
        >
          <Box>
            <Box>
              {!isSavvy && (
                <Typography
                  variant="body1"
                  sx={{
                    color: 'grey.600',
                    mb: 1,
                    '@media (max-width: 499px)': {
                      textAlign: 'right',
                    },
                  }}
                >
                  Primary
                </Typography>
              )}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  '@media (max-width: 499px)': {
                    justifyContent: 'flex-end',
                  },
                }}
              >
                <Box
                  sx={{
                    backgroundColor: 'grey.800',
                    height: '40px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <SelectSeries
                    serie={leftSerie}
                    setSerie={setLeftSerie}
                    seriesList={seriesList}
                    cylinderCount={cylinderCount}
                    aircraftId={aircraftId}
                    chartNumber={chartNumber}
                    seriesSelector="L"
                    setPresetMode={setPresetMode}
                  />
                </Box>
                <Box
                  sx={{
                    backgroundColor: 'grey.800',
                    height: '40px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <AppearanceSets
                    appearanceSets={leftSerie ? [...leftSerie.series] : []}
                    chartSettings={chartSettings}
                    setChartSettings={setChartSettings}
                    conversions={conversions}
                    setConversion={setLeftConversion}
                    conversion={leftConversion}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <Hidden mdDown>
            <Box>
              {(leftSerie?.name === 'EGT' || leftSerie?.name === 'CHT') && (
                <Box pb={0.75}>
                  <SeriesLegend appearanceSets={leftSerie ? [...leftSerie.series] : []} />
                </Box>
              )}
              {(rightSerie?.name === 'EGT' || rightSerie?.name === 'CHT') && (
                <Box pb={0.75}>
                  <SeriesLegend appearanceSets={rightSerie ? [...rightSerie.series] : []} />
                </Box>
              )}
            </Box>
          </Hidden>
          <Box>
            <Box
              sx={{
                '@media (max-width: 767px)': {
                  py: 1,
                },
              }}
            >
              {!isSavvy && (
                <Typography
                  variant="body1"
                  sx={{
                    color: 'grey.600',
                    mb: 1,
                    textAlign: 'right',
                    '@media (max-width: 767px)': {
                      textAlign: 'left',
                    },
                    '@media (max-width: 499px)': {
                      textAlign: 'right',
                    },
                  }}
                >
                  Secondary
                </Typography>
              )}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                <Box
                  sx={{
                    backgroundColor: 'grey.800',
                    height: '40px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <SelectSeries
                    serie={rightSerie}
                    setSerie={setRightSerie}
                    seriesList={seriesList}
                    cylinderCount={cylinderCount}
                    aircraftId={aircraftId}
                    chartNumber={chartNumber}
                    seriesSelector="R"
                    setPresetMode={setPresetMode}
                  />
                </Box>
                <Box
                  sx={{
                    backgroundColor: 'grey.800',
                    height: '40px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <AppearanceSets
                    appearanceSets={rightSerie ? [...rightSerie.series] : []}
                    chartSettings={chartSecondarySettings}
                    setChartSettings={setChartSecondarySettings}
                    conversions={conversions}
                    setConversion={setRightConversion}
                    conversion={rightConversion}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Hidden>
      <Hidden mdUp>
        <Box
          py={1}
          sx={{
            display: 'flex',
            '@media (max-width: 767px)': {
              display: 'block',
            },
            '@media (min-width: 1441px)': {
              justifyContent: 'center',
            },
            '@media (max-width: 1440px)': {
              justifyContent: 'space-between',
            },
          }}
        >
          <Box>
            {!isSavvy && (
              <Typography
                variant="body1"
                sx={{
                  color: 'grey.600',
                  mb: 1,
                }}
              >
                Primary
              </Typography>
            )}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  backgroundColor: 'grey.800',
                  height: '40px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <SelectSeries
                  serie={leftSerie}
                  setSerie={setLeftSerie}
                  seriesList={seriesList}
                  cylinderCount={cylinderCount}
                  aircraftId={aircraftId}
                  chartNumber={chartNumber}
                  seriesSelector="L"
                  setPresetMode={setPresetMode}
                />
              </Box>
              <Box
                sx={{
                  backgroundColor: 'grey.800',
                  height: '40px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <AppearanceSets
                  appearanceSets={leftSerie ? [...leftSerie.series] : []}
                  chartSettings={chartSettings}
                  setChartSettings={setChartSettings}
                  conversions={conversions}
                  setConversion={setLeftConversion}
                  conversion={leftConversion}
                />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              '@media (max-width: 768px)': {
                py: 1,
              },
            }}
          >
            {!isSavvy && (
              <Typography
                variant="body1"
                sx={{
                  color: 'grey.600',
                  mb: 1,
                  textAlign: 'right',
                  '@media (max-width: 768px)': {
                    textAlign: 'left',
                  },
                }}
              >
                Secondary
              </Typography>
            )}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                '@media (max-width: 499px)': {
                  flexDirection: 'inherit',
                  justifyContent: 'flex-start',
                },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                }}
              >
                <Box
                  sx={{
                    backgroundColor: 'grey.800',
                    height: '40px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <SelectSeries
                    serie={rightSerie}
                    setSerie={setRightSerie}
                    seriesList={seriesList}
                    cylinderCount={cylinderCount}
                    aircraftId={aircraftId}
                    chartNumber={chartNumber}
                    seriesSelector="R"
                    setPresetMode={setPresetMode}
                  />
                </Box>
                <Box
                  sx={{
                    backgroundColor: 'grey.800',
                    height: '40px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <AppearanceSets
                    appearanceSets={rightSerie ? [...rightSerie.series] : []}
                    chartSettings={chartSecondarySettings}
                    setChartSettings={setChartSecondarySettings}
                    conversions={conversions}
                    setConversion={setRightConversion}
                    conversion={rightConversion}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box>
            {(leftSerie?.name === 'EGT' || leftSerie?.name === 'CHT') && (
              <Box py={1}>
                <SeriesLegend appearanceSets={leftSerie ? [...leftSerie.series] : []} />
              </Box>
            )}
            {(rightSerie?.name === 'EGT' || rightSerie?.name === 'CHT') && (
              <Box py={1}>
                <SeriesLegend appearanceSets={rightSerie ? [...rightSerie.series] : []} />
              </Box>
            )}
          </Box>
        </Box>
      </Hidden>
      <Box py={1}>
        <Chart
          seriesData={seriesData}
          chartSettings={chartSettings}
          leftSerieName={leftSerie ? leftSerie.name : ''}
          secondarySeriesData={secondarySeriesData}
          chartSecondarySettings={chartSecondarySettings}
          rightSerieName={rightSerie ? rightSerie.name : ''}
          timeSeries={timeSeries}
          zoomSync={zoomSync}
          modeManager={modeManager}
          setDiffData={setDiffData}
          gamiManager={gamiManager}
          aircraftSetting={aircraftSetting}
          aircraftSecondarySetting={aircraftSecondarySetting}
          settings={settings}
          flightData={flightData}
          isGami={isGami}
          isMag={isMag}
          leftConversion={leftConversion}
          rightConversion={rightConversion}
        />
      </Box>
    </React.Fragment>
  );
};

export default FlightChartView;
