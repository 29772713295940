import React from 'react';
import { useSelector } from 'react-redux';

import { AppBar as MuiAppBar, Toolbar, Grid } from '@mui/material';

import { LoggedInMenu, Logo, LoggedInSavvyMenu, LoggedInTechnicianMenu } from './components';
import { PATH_BETA_DASHBOARD, PATH_PERSONAL_TICKET_DASHBOARD, PATH_SC_DASHBOARD } from 'const';

import styles from './style.module.scss';

const AppBar: React.FC = () => {
  const { isSavvy, isTechnician } = useSelector((state: any) => {
    return state.auth;
  });

  return (
    <MuiAppBar
      position="static"
      color="transparent"
      className={styles.appBar}
      sx={{
        backgroundColor: isSavvy ? 'rgba(230, 27, 35, 0.1)' : 'transparent',
        '@media (max-width: 400px)': {
          px: 1,
        },
      }}
    >
      <Toolbar
        className={styles.toolbar}
        sx={{
          '@media (max-width: 400px)': {
            px: 1,
          },
        }}
      >
        <Grid container alignItems="stretch" className="h-100">
          <Logo path={isSavvy ? PATH_PERSONAL_TICKET_DASHBOARD : isTechnician ? PATH_SC_DASHBOARD : PATH_BETA_DASHBOARD} />
          <Grid item xs>
            {isSavvy ? <LoggedInSavvyMenu /> : isTechnician ? <LoggedInTechnicianMenu /> : <LoggedInMenu />}
          </Grid>
        </Grid>
      </Toolbar>
    </MuiAppBar>
  );
};

export default AppBar;
