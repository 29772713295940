import { useParams } from 'react-router-dom';

import { services } from 'api';

import { IDescription, IParamsService, IService } from 'types';

interface IUseServiceReturn {
  name: string;
  description: IDescription;
  id: string;
}

// Returns Service Properties or null if service doesn't exist
const useService = (): IUseServiceReturn | null => {
  const { service: serviceId } = useParams<IParamsService>(); // Get service Id from url (Router slug - Router.tsx)
  const service = services.find((service) => service.id === serviceId) as IService; // Get Service by Id

  // Return Service or null. Null means that service is undefined (not found in api/services/services.tsx)
  // Reason is that url has not existing service id: website.com/signup/savvy-wrong-service-id
  return typeof service === 'undefined' ? null : service; // Pass service object completely to get latest added props from api/services/services.tsx
};

export default useService;
