import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import {
  DataGridPro,
  GridRenderCellParams,
  GridRowParams,
  GridFilterModel,
  GridSortModel,
  getGridDateOperators,
} from '@mui/x-data-grid-pro';

import { QUERY_USER_FLIGHTS_PER_FILE } from 'gql';
import { useLazyQuery } from '@apollo/client';
import { ComponentLoading, CustomGridToolbar } from 'components';
import { setAlert, setFlightTableConfig } from 'state';

import { PATH_FLIGHT_VIEW } from 'const';
import { ctrlCmdClick } from 'helpers';
import { useTableConfig } from 'hooks';

import { SET_TABLE_FLIGHT_FILTER, SET_TABLE_FLIGHT_SORT, SET_TABLE_FLIGHT_PAGE } from 'state/types';

const FlightsTableByFile: React.FC = () => {
  const [fetchFlights, { data: dataFlights, loading: loadingFlights, error: errorFlights }] = useLazyQuery(QUERY_USER_FLIGHTS_PER_FILE, {
    fetchPolicy: 'cache-and-network',
  });

  const dispatch = useDispatch();
  const history = useHistory();
  const { aircraftId, engineDataFileId } = useParams<{
    aircraftId: string | undefined;
    engineDataFileId: string;
  }>();

  const { rowsPerPage: pageSize } = useSelector((state: any) => state.flight);
  const setPageSize = (rows: number) => {
    dispatch(
      setFlightTableConfig({
        rowsPerPage: rows,
      }),
    );
  };
  const { density, setDensity } = useTableConfig();
  const { flightFilter: filterModel, page, sortModel } = useSelector((state: any) => state.flight);
  const setFilterModel = (newFilterModel: GridFilterModel) => {
    dispatch({
      type: SET_TABLE_FLIGHT_FILTER,
      payload: {
        filterModel: newFilterModel,
      },
    });
  };
  const setPage = (pageNum: number) => {
    if (page !== pageNum) {
      dispatch({
        type: SET_TABLE_FLIGHT_PAGE,
        payload: {
          page: pageNum,
        },
      });
    }
  };
  const setSortModel = (newSortModel: GridSortModel) => {
    if (sortModel !== newSortModel) {
      dispatch({
        type: SET_TABLE_FLIGHT_SORT,
        payload: {
          sortModel: newSortModel,
        },
      });
    }
  };
  const [filterButtonEl, setFilterButtonEl] = useState<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (errorFlights) {
      dispatch(setAlert('error', 'Unable to load your flights.'));
    }
  }, [errorFlights, dispatch]);

  useEffect(() => {
    if (aircraftId && engineDataFileId) {
      fetchFlights({
        variables: {
          id: parseInt(aircraftId),
          engineDataFileId,
        },
      });
    }
  }, [aircraftId, engineDataFileId, fetchFlights]);

  return (
    <Box
      p={{
        md: 0,
        lg: 3.75,
        xl: 5,
      }}
    >
      <ComponentLoading loading={loadingFlights}>
        <Typography variant="h2" gutterBottom>
          Flights of File{' '}
          {dataFlights?.me?.aircraft[0]?.engineDataFiles[0]
            ? `${dataFlights?.me?.aircraft[0]?.engineDataFiles[0].name} (${dataFlights?.me?.aircraft[0]?.engineDataFiles[0].uploadDate
                .toString()
                .substring(0, 10)})`
            : ''}
        </Typography>
        <Box
          sx={{
            minHeight: '500px',
          }}
        >
          {!!dataFlights?.me?.aircraft[0]?.engineDataFiles[0]?.flights?.length && (
            <DataGridPro
              sx={{
                width: '100%',
                minWidth: '100px',
                '& .MuiDataGrid-columnSeparator': {
                  display: 'none',
                },
                '& .MuiDataGrid-cell:focus': {
                  outline: 'none',
                },
                '& .MuiDataGrid-cell': {
                  padding: '10px',
                },
                '& .MuiDataGrid-row': {
                  cursor: 'pointer',
                  borderBottom: '1px solid',
                  borderColor: 'grey.200',
                },
                '& .MuiDataGrid-columnHeader:focus': {
                  outline: 'none',
                },
                '& .MuiDataGrid-cellContent': {
                  whiteSpace: 'break-spaces',
                },
                '& .MuiDataGrid-toolbarContainer .MuiTextField-root': {
                  mb: 0,
                },
                border: 'none',
              }}
              autoHeight
              disableColumnResize={true}
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              pageSize={pageSize}
              onPageSizeChange={(newPage) => setPageSize(newPage)}
              pagination
              page={page}
              onPageChange={(newPage) => setPage(newPage)}
              sortModel={sortModel}
              onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
              showColumnRightBorder={false}
              disableColumnSelector
              disableColumnMenu
              disableSelectionOnClick
              density={density}
              onStateChange={(params) => {
                if (params.density && params.density.value !== density) {
                  setDensity(params.density.value);
                }
              }}
              filterModel={filterModel}
              onFilterModelChange={(newFilterModel) => {
                setFilterModel(newFilterModel);
              }}
              rows={
                dataFlights?.me?.aircraft[0]?.engineDataFiles[0]?.flights?.length
                  ? dataFlights?.me?.aircraft[0]?.engineDataFiles[0]?.flights
                  : []
              }
              columns={[
                {
                  field: 'date',
                  headerName: 'Date',
                  valueFormatter: (params) => {
                    return params.value ? params.value.toString().substring(0, 10) : '';
                  },
                  flex: 0.5,
                  filterOperators: getGridDateOperators(),
                },
                {
                  field: 'departureAirport',
                  headerName: 'Departure Airport',
                  flex: 1,
                },
                {
                  field: 'destinationAirport',
                  headerName: 'Destination Airport',
                  flex: 1,
                },
                {
                  field: 'importFile',
                  headerName: 'File',
                  renderCell: (params: GridRenderCellParams<any>) => {
                    return (
                      <Typography
                        title={params.value ? params.value.name : ''}
                        sx={{
                          maxWidth: '100%',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {params.value ? params.value.name : ''}
                      </Typography>
                    );
                  },
                  flex: 1,
                },
                {
                  field: 'duration',
                  headerName: 'Duration',
                  valueFormatter: (params) => {
                    if (!params?.value) return '';
                    const hour = Math.floor(params.value / 3600);
                    const min = Math.floor((params.value % 3600) / 60);
                    return `${hour}h ${(min < 10 ? '0' : '') + min}m`;
                  },
                  flex: 0.5,
                },
                {
                  field: 'chtTemperatureAlert',
                  headerName: 'High CHT Alert',
                  valueFormatter: (params) => {
                    return params.value ? 'HI CHT' : '';
                  },
                  flex: 0.5,
                },
              ]}
              onRowClick={(params: GridRowParams, event?: any) => {
                const { row } = params;
                if (row.id) {
                  event?.preventDefault();
                  event?.stopPropagation();
                  ctrlCmdClick(event, `${PATH_FLIGHT_VIEW}/${row.id}`, history);
                } else {
                  return false;
                }
              }}
              components={{
                Toolbar: CustomGridToolbar,
              }}
              componentsProps={{
                toolbar: {
                  setFilterButtonEl,
                },
                filterPanel: {
                  sx: {
                    '& .MuiDataGrid-filterForm': {
                      '& .MuiTextField-root': {
                        mb: 0,
                      },
                    },
                  },
                },
                panel: {
                  anchorEl: filterButtonEl,
                  placement: 'bottom-end',
                },
              }}
            />
          )}
          {!dataFlights?.me?.aircraft[0]?.engineDataFiles[0]?.flights?.length && (
            <Typography
              sx={{
                color: 'error.main',
              }}
            >
              No Flights...
            </Typography>
          )}
        </Box>
      </ComponentLoading>
    </Box>
  );
};

export default FlightsTableByFile;
