import React from 'react';
import { GridToolbarContainer, GridToolbarFilterButton, GridToolbarQuickFilter, GridToolbarDensitySelector } from '@mui/x-data-grid-pro';

const CustomGridToolbar: React.FC<{
  setFilterButtonEl: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>;
}> = ({ setFilterButtonEl }) => {
  return (
    <GridToolbarContainer
      sx={{
        justifyContent: 'flex-end',
      }}
    >
      <GridToolbarQuickFilter />
      <GridToolbarFilterButton ref={setFilterButtonEl} />
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
};

export default CustomGridToolbar;
