import { Redirect, Route } from 'react-router-dom';

import { useSelector } from 'react-redux';

import { PageLoading } from 'components';

import { PATH_LOGIN, PATH_SECURITY_CHECK } from 'const';

interface IPrivateRoute {
  component: any;
  path: string;
  exact: boolean;
}

const PrivateRoute = (props: IPrivateRoute) => {
  const { component: Component, ...rest } = props; // Get Component user try access
  const { loggedIn, incompleteAircraft, missingToken } = useSelector((state: any) => {
    return state.auth;
  }); // Get auth state (Redux)

  // If loading
  if (loggedIn === null) return <PageLoading />;

  if (missingToken) {
    return <Route render={() => <Redirect to={PATH_SECURITY_CHECK} />} {...rest} />;
  }

  // NOT logged in
  if (loggedIn !== null && !loggedIn) {
    // Get next prop from url
    const next = window.location.pathname;
    const search = window.location.search;

    // Redirect to login
    return <Route render={() => <Redirect to={`${PATH_LOGIN}?next=${encodeURIComponent(next + search)}`} />} {...rest} />;
  }

  if (loggedIn && incompleteAircraft?.length > 0) {
    if (incompleteAircraft[0].subscriptions?.length > 0) {
      return <Redirect to={`/signup/${incompleteAircraft[0].subscriptions[0].serviceCode}/additional-info`} />;
    }
  }

  // If logged IN - proceed to page
  return <Route render={() => <Component {...rest} />} {...rest} />;
};

export default PrivateRoute;
