import React from 'react';

import { DialogAddAircraftManufacturerSuggestions } from './components';

import { IOptionIdName, IModelAdditionalFields } from 'types';

interface IDialogAddAircraft {
  open: boolean;
  setOpen: (value: boolean) => void;
  manufacturer: IOptionIdName; // Autocomplete value
  setManufacturer: (value: IOptionIdName | null) => void;
  setModel: (value: IOptionIdName | null) => void;
  setAdditionalFields: (value: IModelAdditionalFields | null) => void;
}

const DialogAddAircraftManufacturer: React.FC<IDialogAddAircraft> = (props) => {
  // Get open and other props from SignupServiceForm.tsx
  const { open, setOpen, setManufacturer, setModel, manufacturer, setAdditionalFields } = props;

  return (
    <DialogAddAircraftManufacturerSuggestions
      manufacturer={manufacturer}
      setManufacturer={setManufacturer}
      open={open}
      setOpen={setOpen}
      setModel={setModel}
      setAdditionalFields={setAdditionalFields}
    />
  );
};

export default DialogAddAircraftManufacturer;
