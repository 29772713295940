import React, { useState, useEffect } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Hidden, IconButton } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { IBorescopeImageFileType } from 'types';
import { ComponentLoading } from 'components';
import { QUERY_BORESCOPE_REPORT_FOR_CLIENT, QUERY_BORESCOPE_IMAGE_SUBJECTS_LIST, QUERY_BORESCOPE_REPORT_PDF_DOWNLOAD } from 'gql';
import { useLazyQuery } from '@apollo/client';
import { setAlert } from 'state';
import { PATH_SAVVY_BORESCOPE_ANALYSIS_REPORT_VIEW } from 'const';

import { BorescopeReportContext } from './';

interface BorescopeReportProps {
  setSlideNum?: (value: number) => void;
}

const BorescopeReport: React.FC<BorescopeReportProps> = (props) => {
  const { setSlideNum } = props;
  const { id, reportId: borescopeImageSetId } = useParams<{
    id?: string;
    reportType?: string;
    reportId?: string;
  }>();

  const [fetchBorescopeReport, { data: dataBorescopeReport, loading: loadingBorescopeReport }] =
    useLazyQuery(QUERY_BORESCOPE_REPORT_FOR_CLIENT);
  const [fetchSubjectsList, { data: dataSubjectsList }] = useLazyQuery(QUERY_BORESCOPE_IMAGE_SUBJECTS_LIST);
  const [fetchPdfUrl, { data: dataPdfUrl, loading: loadingPdfUrl, error: errorPdfUrl }] = useLazyQuery(QUERY_BORESCOPE_REPORT_PDF_DOWNLOAD);
  const [images, setImages] = useState<IBorescopeImageFileType[]>([]);

  const dispatch = useDispatch();
  const { isSavvy } = useSelector((state: any) => state.auth);

  const [cylinderCount, setCylinderCount] = useState(0);

  const downloadBorescopeReport = async (borescopeImageSetId: string) => {
    if (id) {
      await fetchPdfUrl({
        variables: {
          id: parseInt(id),
          borescopeImageSetId,
        },
        fetchPolicy: 'network-only',
      });
    }
  };

  useEffect(() => {
    if (id && borescopeImageSetId) {
      fetchBorescopeReport({
        variables: {
          id: parseInt(id),
          borescopeImageSetId,
        },
      });
      fetchSubjectsList();
      setImages([]);
      setCylinderCount(0);
    }
  }, [id, borescopeImageSetId]);

  useEffect(() => {
    if (dataBorescopeReport?.me?.aircraft[0]?.borescopeImageSets[0]?.images?.length) {
      setImages(
        [...dataBorescopeReport.me.aircraft[0].borescopeImageSets[0].images].sort(
          (item1: IBorescopeImageFileType, item2: IBorescopeImageFileType) => (item1.cylinder > item2.cylinder ? 1 : -1),
        ),
      );
    }
    setCylinderCount(dataBorescopeReport?.me?.aircraft[0]?.cylinderCount || 0);
    setSlideNum && setSlideNum(2);
  }, [dataBorescopeReport]);

  useEffect(() => {
    if (errorPdfUrl) {
      dispatch(setAlert('error', 'Unable to download borescope analysis report'));
    } else if (dataPdfUrl) {
      if (dataPdfUrl.me?.aircraft[0]?.borescopeImageSets[0]?.downloadUrl) {
        window.open(dataPdfUrl.me?.aircraft[0]?.borescopeImageSets[0]?.downloadUrl, '_blank');
      }
    }
  }, [dataPdfUrl, errorPdfUrl, dispatch]);

  if (isSavvy && id && borescopeImageSetId) {
    return <Redirect to={`${PATH_SAVVY_BORESCOPE_ANALYSIS_REPORT_VIEW}/${id}/${borescopeImageSetId}`} />;
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
        height: {
          xl: 'calc(100vh - 75px)',
          xs: 'auto',
        },
      }}
    >
      <Hidden xlUp>
        <Box py={1} px={2}>
          <IconButton
            sx={{
              '&:hover': {
                borderRadius: '6px',
              },
              borderRadius: '6px',
              boxShadow: '0px 1px 1px rgba(19, 30, 64, 0.05)',
              border: '1px solid #E8E9EF',
              background: '#FFFFFF',
            }}
            onClick={() => {
              if (setSlideNum) {
                setSlideNum(1);
              }
            }}
          >
            <ChevronLeftIcon />
          </IconButton>
        </Box>
      </Hidden>
      <ComponentLoading loading={loadingBorescopeReport || loadingPdfUrl}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            height: {
              xl: '100%',
              xs: 'auto',
            },
            px: {
              lg: 5,
              md: 2,
            },
            pt: {
              lg: 5,
              md: 2,
            },
          }}
        >
          {dataBorescopeReport?.me?.aircraft[0]?.borescopeImageSets[0] && (
            <BorescopeReportContext
              imageSet={dataBorescopeReport.me.aircraft[0].borescopeImageSets[0]}
              aircraft={dataBorescopeReport.me.aircraft[0]}
              downloadReport={downloadBorescopeReport}
              cylinderCount={cylinderCount}
              images={images}
              subjectList={dataSubjectsList?.borescopeImageSubjectsList || []}
            />
          )}
        </Box>
      </ComponentLoading>
    </Box>
  );
};

export default BorescopeReport;
