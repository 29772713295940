import React from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button, CircularProgress } from '@mui/material';
import AttachmentIcon from '@mui/icons-material/Attachment';
import { IUploadedFile } from 'types';
import { setAlert } from 'state';

import { uploadFileDirectS3, createDocumentFromS3 } from 'helpers';

interface AttachmentsProps {
  uploadingFiles: boolean;
  setUploadingFiles: (value: boolean) => void;
  uploadedFileList: IUploadedFile[];
  setUploadedFileList: (value: IUploadedFile[]) => void;
}

const Attachments: React.FC<AttachmentsProps> = (props) => {
  const { uploadingFiles, setUploadingFiles, uploadedFileList, setUploadedFileList } = props;
  const dispatch = useDispatch();

  const uploadAttachments = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files || [];

    setUploadingFiles(true);
    const tmp = [...uploadedFileList];
    for (let i = 0; i < files.length; i++) {
      const result = await uploadFileDirectS3(files[i], true, '');

      if (result.success) {
        const documentData = await createDocumentFromS3(result.key);
        if (!documentData.ok) {
          dispatch(setAlert('error', documentData.error || 'Unable to upload attachment'));
        } else {
          tmp.push({
            id: documentData.document?.id || '',
            name: files[i].name,
            success: true,
          });
        }
      } else {
        dispatch(setAlert('error', 'Unable to upload attachment'));
      }

      setUploadedFileList([...tmp]);
    }
    setUploadingFiles(false);
  };

  return (
    <Box p={1}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: uploadingFiles ? 'space-between' : 'flex-end',
        }}
      >
        {uploadingFiles && <CircularProgress color="inherit" size={20} variant="indeterminate" />}
        <Button
          href="#"
          component="label"
          onClick={(e: any) => {
            if (uploadingFiles) {
              e.preventDefault();
              e.stopPropagation();
              return false;
            }
          }}
          sx={{
            color: 'rgba(150, 158, 179, 0.6)',
          }}
        >
          <AttachmentIcon
            sx={{
              transform: 'rotate(-45deg)',
            }}
          />
          <input
            type="file"
            id="attachFile"
            hidden
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              uploadAttachments(e);
            }}
            name="attachments"
            multiple
          />
        </Button>
      </Box>
    </Box>
  );
};

export default Attachments;
