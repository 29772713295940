import React from 'react';

import { Box, Grid } from '@mui/material';
import { IServiceCenter } from 'types';

import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { MuiFormikInput, ButtonSubmit } from 'components';

interface MainInfoProps {
  serviceCenter: IServiceCenter | undefined;
  setServiceCenter: (value: IServiceCenter | undefined) => void;
  setSlideNum: (value: number) => void;
  setClickedBack: (value: boolean) => void;
}

const ServiceCenterSchema = Yup.object().shape({
  street: Yup.string().max(40, 'Too long: Maximum 40 characters'),
  city: Yup.string().max(40, 'Too long: Maximum 40 characters'),
  state: Yup.string().max(40, 'Too long: Maximum 40 characters'),
  zip: Yup.string().max(10, 'Too long: Maximum 10 characters'),
  country: Yup.string().max(20, 'Too long: Maximum 30 characters'),
  airportId: Yup.string().max(4, 'Too long: Maximum 4 characters'),
  phoneWork: Yup.string().max(20, 'Too long: Maximum 20 characters'),
  phoneFax: Yup.string().max(20, 'Too long: Maximum 20 characters'),
  website: Yup.string().max(100, 'Too long: Maximum 100 characters'),
});

const MainInfo: React.FC<MainInfoProps> = (props) => {
  const { serviceCenter = undefined, setServiceCenter, setSlideNum, setClickedBack } = props;

  if (!serviceCenter) {
    return null;
  }

  return (
    <Box p={1}>
      <Box>
        <Formik
          enableReinitialize
          initialValues={{
            street: serviceCenter?.street ? serviceCenter.street : '',
            city: serviceCenter?.city ? serviceCenter.city : '',
            state: serviceCenter?.state ? serviceCenter.state : '',
            zip: serviceCenter?.zip ? serviceCenter.zip : '',
            country: serviceCenter?.country ? serviceCenter.country : '',
            airportId: serviceCenter?.airportId ? serviceCenter.airportId : '',
            phoneWork: serviceCenter?.phoneWork ? serviceCenter.phoneWork : '',
            phoneFax: serviceCenter?.phoneFax ? serviceCenter.phoneFax : '',
            website: serviceCenter?.website ? serviceCenter.website : '',
          }}
          onSubmit={async (values) => {
            // TODO: Loader while writing. Disable button.
            setServiceCenter({
              ...serviceCenter,
              ...values,
            });
            setSlideNum(1);
            setClickedBack(false);
          }}
          validationSchema={ServiceCenterSchema}
        >
          {({ isSubmitting, handleChange, handleBlur, touched, errors }) => {
            return (
              <Form>
                <Grid container spacing={2}>
                  <Grid item lg={4} md={6} xs={12}>
                    <MuiFormikInput
                      name="street"
                      label="Street"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      noMargin
                    />
                  </Grid>
                  <Grid item lg={4} md={6} xs={12}>
                    <MuiFormikInput
                      name="city"
                      label="City"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      noMargin
                    />
                  </Grid>
                  <Grid item lg={4} md={6} xs={12}>
                    <MuiFormikInput
                      name="state"
                      label="State"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      noMargin
                    />
                  </Grid>
                  <Grid item lg={4} md={6} xs={12}>
                    <MuiFormikInput
                      name="zip"
                      label="Zip"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      noMargin
                    />
                  </Grid>
                  <Grid item lg={4} md={6} xs={12}>
                    <MuiFormikInput
                      name="country"
                      label="Country"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      noMargin
                    />
                  </Grid>
                  <Grid item lg={4} md={6} xs={12}>
                    <MuiFormikInput
                      name="airportId"
                      label="Airport Id"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      noMargin
                    />
                  </Grid>
                  <Grid item lg={4} md={6} xs={12}>
                    <MuiFormikInput
                      name="phoneWork"
                      label="Phone (work)"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      noMargin
                    />
                  </Grid>
                  <Grid item lg={4} md={6} xs={12}>
                    <MuiFormikInput
                      name="phoneFax"
                      label="Fax"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      noMargin
                    />
                  </Grid>
                  <Grid item lg={4} md={6} xs={12}>
                    <MuiFormikInput
                      name="website"
                      label="Website"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      noMargin
                    />
                  </Grid>
                </Grid>
                <Box py={2}>
                  <Grid container spacing={2}>
                    <Grid item md={3} xs={false}></Grid>
                    <Grid item md={6} xs={12}>
                      <ButtonSubmit text="Next" loading={isSubmitting} />
                    </Grid>
                  </Grid>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </Box>
  );
};

export default MainInfo;
