import React, { Fragment } from 'react';

import { AppBar as MuiAppBar, Toolbar, Grid } from '@mui/material';

import { LoggedOutMenu, Logo } from './components';

import { useSmUp } from 'hooks';

import styles from './style.module.scss';

const AppBarLoggedOut: React.FC = () => {
  const isSmUp = useSmUp();

  return (
    <Fragment>
      <MuiAppBar position="static" color="transparent" className={styles.appBar}>
        <Toolbar className={styles.toolbar}>
          <Grid container alignItems="stretch" className="h-100">
            <Logo />
            <Grid item xs>
              {/* Show LoggedOutMenu on the right in screens more than 600px */}
              {isSmUp && <LoggedOutMenu />}
            </Grid>
          </Grid>
        </Toolbar>
      </MuiAppBar>

      {/* Show LoggedOutMenu below AppBar in screen less than 599px */}
      {!isSmUp && (
        <Toolbar className={styles.toolbar}>
          <LoggedOutMenu />
        </Toolbar>
      )}
    </Fragment>
  );
};

export default AppBarLoggedOut;
