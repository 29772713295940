import { combineReducers } from 'redux';

import alertReducer from './alert/reducer';
import authReducer from './auth/reducer';
import signupReducer from './signup/reducer';
import mapReducer from './mapHoverPoint/reducer';
import ticketReducer from './ticket/reducer';
import analysisReducer from './analysis/reducer';
import flightTableReducer from './flight/reducer';
import unreadPostReducer from './post/reducer';
import tableConfigReducer from './tableConfig/reducer';

/*
  Combine reducers together.

  Left side: state name to access in components and pages.
  Right: state provider.

  Sample of access state in components and pages:
  const { email } = useSelector((state: any) => state.user);
*/
const rootReducer = combineReducers({
  alert: alertReducer,
  auth: authReducer,
  signup: signupReducer,
  mapReducer: mapReducer,
  ticket: ticketReducer,
  analysis: analysisReducer,
  flight: flightTableReducer,
  unreadPost: unreadPostReducer,
  tableConfig: tableConfigReducer,
});

export default rootReducer;
