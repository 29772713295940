import React, { useState, useEffect } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  Box,
  Typography,
  TextField,
  MenuItem,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Link,
  Container,
  Grid,
  Button,
} from '@mui/material';

import { IAircraft } from 'types';
import { PATH_BETA_PLANS } from 'const';
import { aircraftRegistrationMakeModel } from 'helpers';
import { ComponentLoading, DeleteDialog } from 'components';

import { QUERY_AIRCRAFT_RENEWALS, MUTATION_UNCANCEL_SUBSCRIPTION_RENEWAL } from 'gql';
import { useLazyQuery, useMutation } from '@apollo/client';
import { setAlert } from 'state';
import { IUpgradeCard } from 'types';

import { DoNotRenewDialog, TransferPlanToAnotherAircraft, TransferPlanToContact } from './DoNotRenewDialog';
import { default as UpgradeCard } from './UpgradeCard';
import { EditAircraftDialog } from './PlanComponents';

interface PlanTableProps {
  aircrafts: IAircraft[];
}

const useQuery = () => new URLSearchParams(useLocation().search);

const PlanTable: React.FC<PlanTableProps> = (props) => {
  const { aircrafts } = props;

  const { id } = useParams<{
    id?: string;
  }>();
  const history = useHistory();

  const dispatch = useDispatch();

  const query = useQuery();
  const doNotRenewId = query.get('doNotRenewId');

  const [fetchSubscriptions, { data: dataSubscriptions, loading: loadingSubscriptions, error: errorSubscriptions }] =
    useLazyQuery(QUERY_AIRCRAFT_RENEWALS);

  const [uncancelSubscriptionRenewal, { data: dataUncancel, error: errorUncancel, loading: loadingUncancel }] = useMutation(
    MUTATION_UNCANCEL_SUBSCRIPTION_RENEWAL,
  );

  const [openNotRenew, setOpenNotRenew] = useState(false);
  const [subscriptionId, setSubscriptionId] = useState('');

  const [openTransferAircraft, setOpenTransferAircraft] = useState(false);
  const [openTransferContact, setOpenTransferContact] = useState(false);

  const [openEditAircraft, setOpenEditAircraft] = useState(false);

  const [showUncancel, setShowUncancel] = useState(false);

  const onSubmitAction = async () => {
    await uncancelSubscriptionRenewal({
      variables: {
        subscriptionId,
      },
    });
  };

  useEffect(() => {
    if (id) {
      fetchSubscriptions({
        variables: {
          id: parseInt(id),
        },
      });
    }
  }, [id, fetchSubscriptions]);

  useEffect(() => {
    if (errorSubscriptions) {
      dispatch(setAlert('error', 'Unable to load subscriptions data.'));
    }
  }, [errorSubscriptions, dispatch]);

  useEffect(() => {
    if (errorUncancel) {
      dispatch(setAlert('error', 'Unable to uncancel subscription renewal'));
    } else if (dataUncancel) {
      if (dataUncancel.uncancelSubscriptionRenewal?.ok) {
        dispatch(setAlert('success', 'Uncanceled subscription renewal'));
      } else {
        dispatch(setAlert('error', dataUncancel.uncancelSubscriptionRenewal?.error || 'Unable to uncancel subscription renewal'));
      }
    }
  }, [errorUncancel, dataUncancel, dispatch]);

  useEffect(() => {
    if (dataSubscriptions?.me?.aircraft[0]?.upgradeContext && doNotRenewId) {
      setSubscriptionId(doNotRenewId);
      setOpenNotRenew(true);
    }
  }, [dataSubscriptions, doNotRenewId]);

  return (
    <Box
      py={5}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ComponentLoading loading={loadingSubscriptions || loadingUncancel}>
        <Box>
          <Container maxWidth="md" fixed>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                py: 2,
              }}
            >
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: 'bold',
                  mr: 2,
                }}
              >
                Current Plan for
              </Typography>
              <TextField
                select
                value={id ? id : ''}
                label="Aircraft"
                variant="outlined"
                sx={{
                  mb: 0,
                  minWidth: '280px',
                  textAlign: 'left',
                }}
                onChange={(e: any) => {
                  history.push(
                    `${PATH_BETA_PLANS}/${e.target.value}?category=${aircrafts?.find((item) => item.id === e.target.value)?.aircraftModel?.propulsion?.toLowerCase()}-${aircrafts?.find((item) => item.id === e.target.value)?.aircraftModel?.enginesCount?.toLowerCase()}`,
                  );
                }}
              >
                {aircrafts?.map((item: IAircraft, key: number) => {
                  return (
                    <MenuItem key={key} value={item.id} title={aircraftRegistrationMakeModel(item)}>
                      <Typography
                        sx={{
                          fontSize: '18px',
                          fontWeight: 'bold',
                          lineHeight: '25px',
                        }}
                      >
                        {aircraftRegistrationMakeModel(item)}
                      </Typography>
                    </MenuItem>
                  );
                })}
              </TextField>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {id && Boolean(dataSubscriptions?.me?.aircraft[0]?.upgradeContext?.renewals?.length) && (
                <Table
                  sx={{
                    width: '600px',
                  }}
                >
                  <TableHead>
                    <TableRow
                      sx={{
                        backgroundColor: 'grey.300',
                        '& th': {
                          textTransform: 'uppercase',
                          fontWeight: 'bold',
                        },
                        border: '4px solid',
                        borderColor: 'grey.300',
                        borderTop: '0',
                      }}
                    >
                      <TableCell>Service</TableCell>
                      <TableCell>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dataSubscriptions?.me?.aircraft[0]?.upgradeContext?.renewals?.map((subscription: any, key: number) => {
                      return (
                        <TableRow
                          key={key}
                          sx={{
                            border: '4px solid',
                            borderColor: 'grey.300',
                            borderTop: '0',
                            backgroundColor: 'background.default',
                          }}
                        >
                          <TableCell
                            sx={{
                              fontWeight: 'bold',
                            }}
                          >
                            {subscription.name}
                          </TableCell>
                          <TableCell
                            sx={{
                              color: 'green',
                              fontWeight: 'bold',
                            }}
                          >
                            {subscription.status}
                            {subscription.showDoNotRenew && (
                              <Link
                                href="#"
                                onClick={(e: any) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setSubscriptionId(subscription.id);
                                  setOpenNotRenew(true);
                                }}
                                sx={{
                                  ml: 2,
                                }}
                              >
                                Do Not Renew
                              </Link>
                            )}
                            {subscription.isCanceled && (
                              <Link
                                href="#"
                                onClick={(e: any) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setSubscriptionId(subscription.id);
                                  setShowUncancel(true);
                                }}
                                sx={{
                                  ml: 2,
                                }}
                              >
                                Undo Cancelation
                              </Link>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              )}
              {id && !dataSubscriptions?.me?.aircraft[0]?.upgradeContext?.renewals?.length && (
                <Typography
                  sx={{
                    fontSize: '16px',
                    textAlign: 'center',
                  }}
                >
                  {dataSubscriptions?.me?.aircraft[0]?.registration} is not currently enrolled in any paid services.
                </Typography>
              )}
              <DoNotRenewDialog
                open={openNotRenew}
                setOpen={setOpenNotRenew}
                subscriptionId={subscriptionId}
                registration={dataSubscriptions?.me?.aircraft[0]?.registration ? dataSubscriptions?.me?.aircraft[0]?.registration : ''}
                setOpenTransferAircraft={setOpenTransferAircraft}
                setOpenTransferContact={setOpenTransferContact}
                lessExpensiveOptions={
                  dataSubscriptions?.me?.aircraft[0]?.upgradeContext?.lessExpensiveOptions
                    ? dataSubscriptions?.me?.aircraft[0]?.upgradeContext?.lessExpensiveOptions
                    : []
                }
              />
              <TransferPlanToAnotherAircraft open={openTransferAircraft} setOpen={setOpenTransferAircraft} aircrafts={aircrafts} />
              <TransferPlanToContact open={openTransferContact} setOpen={setOpenTransferContact} />
            </Box>
          </Container>
          <Container
            maxWidth="lg"
            fixed
            sx={{
              '@media (min-width: 1280px)': {
                maxWidth: '1200px',
                '&.MuiContainer-maxWidthLg': {
                  maxWidth: '1200px',
                },
              },
            }}
          >
            {Boolean(dataSubscriptions?.me?.aircraft[0]?.upgradeContext?.options?.length) && (
              <Box
                sx={{
                  textAlign: 'center',
                }}
                py={3}
              >
                <Typography variant="h2">{dataSubscriptions?.me?.aircraft[0]?.upgradeContext.title}</Typography>
                <Box py={2}>
                  <Typography>Savvy Aviation provides experienced maintenance advice,</Typography>
                  <Typography>management and analysis to owners of general aviation aircraft.</Typography>
                </Box>
                {dataSubscriptions?.me?.aircraft[0]?.profileComplete && (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'stretch',
                      flexWrap: 'wrap',
                    }}
                  >
                    {dataSubscriptions?.me?.aircraft[0]?.upgradeContext?.options?.map((option: IUpgradeCard, key: number) => {
                      return <UpgradeCard card={option} aircraft={dataSubscriptions?.me?.aircraft[0]} key={key} />;
                    })}
                  </Box>
                )}
                {!dataSubscriptions?.me?.aircraft[0]?.profileComplete && (
                  <Box>
                    <Typography
                      sx={{
                        color: 'error.main',
                        mb: 2,
                      }}
                    >
                      Please complete the aircraft profile before purchasing a subscription.
                    </Typography>
                    <Button
                      variant="contained"
                      onClick={() => {
                        setOpenEditAircraft(true);
                      }}
                    >
                      Complete Profile...
                    </Button>
                  </Box>
                )}
              </Box>
            )}
          </Container>
          <Container maxWidth="md" fixed>
            {dataSubscriptions?.me?.aircraft[0]?.upgradeContext?.canTransferSubscriptions && (
              <Box
                py={3}
                sx={{
                  minWidth: '700px',
                }}
              >
                <Typography
                  variant="h2"
                  align="center"
                  sx={{
                    mb: 2,
                  }}
                >
                  Sold Your Aircraft?
                </Typography>
                <Grid container spacing={4}>
                  <Grid item xs={4}>
                    <Typography
                      variant="body1"
                      align="center"
                      sx={{
                        fontSize: '20px',
                        color: 'primary.main',
                        textDecoration: 'underline',
                        lineHeight: '28px',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setOpenTransferAircraft(true);
                      }}
                    >
                      Transfer plan
                      <br />
                      to another aircraft
                      <br />
                      you own
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      variant="body1"
                      align="center"
                      sx={{
                        fontSize: '20px',
                        color: 'primary.main',
                        textDecoration: 'underline',
                        lineHeight: '28px',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setOpenTransferContact(true);
                      }}
                    >
                      Transfer plan
                      <br />
                      to new owner
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      variant="body1"
                      align="center"
                      sx={{
                        fontSize: '20px',
                        color: 'primary.main',
                        textDecoration: 'underline',
                        lineHeight: '28px',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setSubscriptionId(dataSubscriptions?.me?.aircraft[0]?.upgradeContext?.renewals[0]?.id);
                        setOpenNotRenew(true);
                      }}
                    >
                      Do not renew:
                      <br />
                      allow plan to expire
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Container>
        </Box>
        <DeleteDialog
          open={showUncancel}
          setOpen={setShowUncancel}
          onSubmitAction={onSubmitAction}
          title="Auto-renew plan"
          text="Set this plan to automatically renew?"
        />
        {!!id && <EditAircraftDialog open={openEditAircraft} setOpen={setOpenEditAircraft} aircraftId={id} />}
      </ComponentLoading>
    </Box>
  );
};

export default PlanTable;
