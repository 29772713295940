import React from 'react';
import { CSSObject } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';

interface TicketInfoIconProps {
  sx?: CSSObject;
}

const TicketInfoIcon: React.FC<TicketInfoIconProps> = (props) => {
  const { sx } = props;

  return (
    <SvgIcon
      width="22"
      height="22"
      viewBox="0 0 22 22"
      sx={
        sx
          ? {
              width: '18px',
              height: '18px',
              ...sx,
            }
          : {
              width: '18px',
              height: '18px',
            }
      }
    >
      <g filter="url(#filter0_d_196_4275)">
        <rect x="2" y="1" width="18" height="18" rx="2" fill="white" />
        <rect x="1.5" y="0.5" width="19" height="19" rx="2.5" stroke="#E8E9EF" />
      </g>
      <path
        d="M9.7175 12.8388H10.055V10.4011H9.7175C9.5311 10.4011 9.38 10.25 9.38 10.0636V9.25743C9.38 9.07103 9.5311 8.91993 9.7175 8.91993H11.6075C11.7939 8.91993 11.945 9.07103 11.945 9.25743V12.8388H12.2825C12.4689 12.8388 12.62 12.9899 12.62 13.1763V13.9824C12.62 14.1688 12.4689 14.3199 12.2825 14.3199H9.7175C9.5311 14.3199 9.38 14.1688 9.38 13.9824V13.1763C9.38 12.9899 9.5311 12.8388 9.7175 12.8388ZM11 5.67993C10.329 5.67993 9.785 6.2239 9.785 6.89493C9.785 7.56597 10.329 8.10993 11 8.10993C11.671 8.10993 12.215 7.56597 12.215 6.89493C12.215 6.2239 11.671 5.67993 11 5.67993Z"
        fill="#969EB3"
      />
      <defs>
        <filter id="filter0_d_196_4275" x="0" y="0" width="22" height="22" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="0.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0745098 0 0 0 0 0.117647 0 0 0 0 0.25098 0 0 0 0.05 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_196_4275" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_196_4275" result="shape" />
        </filter>
      </defs>
    </SvgIcon>
  );
};

export default TicketInfoIcon;
