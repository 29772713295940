import React, { Fragment } from 'react';

import { Typography, Collapse, Hidden } from '@mui/material';

import { Subsection, List, ButtonMore } from 'components';
import { useMdUp } from 'hooks';

const features = [
  'Complimentary preliminary logbook review (we recommend this before purchasing Prebuy service)',
  'Advocacy for you as the buyer',
  'Recommendation for best service center and mechanic to conduct Prebuy examination',
  'Detailed checklist tailored to the aircraft under purchase for selected shop examination',
  'Test flight arrangement to verify functionality and performance of aircraft under purchase',
  'Review of prebuy and testflight findings',
  'Purchase/Sale Agreement',
  'Negotiations coaching and guidance',
];

const fees = ['Piston single: $750', 'Piston twin: $1,000', 'Turboprop single: $1,500', 'Turbojet single: $2,000'];

const SavvyPrebuyDescription: React.FC = () => {
  const [open, setOpen] = React.useState(false);
  const isMdUp = useMdUp();

  return (
    <Fragment>
      <Subsection>
        <Typography paragraph>
          Our SavvyPrebuy program offers you the experience and expertise of Savvy’s incomparable team of account managers, each of whom is
          a seasoned A&P/IA with 20+ years of GA maintenance experience.
          <br />
          We have a nationwide footprint and work with hundreds of maintenance shops and mechanics throughout the U.S. (and in a dozen other
          countries), so we are able to manage prebuys no matter where the aircraft you’re interested in is situated.
        </Typography>
      </Subsection>
      <Collapse in={open || isMdUp}>
        <Subsection>
          <Typography variant="h4" gutterBottom>
            Your SavvyPrebuy service includes:
          </Typography>
          <List list={features} />
        </Subsection>
        <Subsection>
          <Typography variant="h4" gutterBottom>
            SavvyPrebuy Service Fees:
          </Typography>
          <List list={fees} />
          <Typography
            variant="body1"
            style={{
              fontStyle: 'italic',
            }}
          >
            *Turboprop twin not supported
          </Typography>
        </Subsection>
      </Collapse>
      {!isMdUp && (
        <Hidden mdUp>
          <Subsection>
            <ButtonMore open={open} setOpen={setOpen} />
          </Subsection>
        </Hidden>
      )}
    </Fragment>
  );
};

export default SavvyPrebuyDescription;
