import React, { Fragment } from 'react';

import { Box } from '@mui/material';

import { CircularProgress } from '@mui/material';

interface IComponentLoading {
  loading: boolean;
  children: React.ReactNode;
}

const ComponentLoading: React.FC<IComponentLoading> = (props) => {
  const { loading, children } = props;

  return loading ? (
    <Box position="relative">
      <Box position="absolute" top="0" left="0" width="100%" height="100%" zIndex={1} bgcolor="rgba(255,255,255,0.4)" />
      <Box position="absolute" top="50%" marginTop="-10px" marginLeft="-10px" left="50%" zIndex={2}>
        <CircularProgress color="inherit" size={20} variant="indeterminate" />
      </Box>
      <Box zIndex={0}>{children}</Box>
    </Box>
  ) : (
    <Fragment>{children}</Fragment>
  );
};

export default ComponentLoading;
