import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Dialog, Box, Typography, DialogContent, DialogTitle, TextField, Button } from '@mui/material';
import { useMutation } from '@apollo/client';
import { MUTATION_ADD_POST_TO_TICKET } from 'gql';
import { setAlert } from 'state';
import { ComponentLoading } from 'components';

interface NotHappyDialogProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  ticketId: string;
}

const NotHappyDialog: React.FC<NotHappyDialogProps> = (props) => {
  const [addPostToTicket, { data: dataAddPostToTicket, loading: loadingAddPostToTicket, error: errorAddPostToTicket }] = useMutation(
    MUTATION_ADD_POST_TO_TICKET,
    {
      errorPolicy: 'all',
    },
  );

  const dispatch = useDispatch();

  const { open, setOpen, ticketId } = props;
  const [messageError, setMessageError] = useState('');
  const [message, setMessage] = useState('');

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async () => {
    if (!message) {
      setMessageError('Required');
      return;
    }

    await addPostToTicket({
      variables: {
        ticketId: parseInt(ticketId),
        body: message,
        documentIds: [],
        nothappy: true,
        visibility: '',
      },
    });
  };

  useEffect(() => {
    setMessage('');
    setMessageError('');
  }, [open, setMessage, setMessageError]);

  useEffect(() => {
    if (errorAddPostToTicket) {
      setMessageError(errorAddPostToTicket.graphQLErrors[0].message);
    } else if (dataAddPostToTicket) {
      if (dataAddPostToTicket?.addPostToTicket?.ok) {
        dispatch(setAlert('success', 'Post created.'));
        setOpen(false);
      } else {
        dispatch(setAlert('error', dataAddPostToTicket?.addPostToTicket?.error));
      }
    }
  }, [dataAddPostToTicket, dispatch, errorAddPostToTicket, setOpen]);

  return (
    <Dialog open={open} fullWidth onClose={handleClose}>
      <DialogTitle
        sx={{
          textAlign: 'center',
          fontSize: '24px',
          fontWeight: 700,
        }}
      >
        I Am Not Happy
      </DialogTitle>
      <DialogContent>
        <ComponentLoading loading={loadingAddPostToTicket}>
          <Box>
            <Typography variant="body1" gutterBottom align="left">
              Sorry to hear that, your satisfaction is our top priority.
            </Typography>
            <Typography gutterBottom>
              <b>Unhappy with the way the Savvy team has been handling your needs?</b> Tell us more below, and click the "Post" button. This
              form will send your ticket post directly to Savvy’s top management, who will do their best to make things right.
            </Typography>
            <Typography gutterBottom>
              <b>It will NOT be visible to your Savvy account manager or technicians.</b> Please make sure you’ve clearly explained the
              reason for your dissatisfaction.
            </Typography>
            <Typography gutterBottom>
              If you’re dissatisfied with something other than the Savvy team’s performance, please click on "Cancel" and then update this
              ticket normally so your account manager can see it and assist you.
            </Typography>
            <Box mb={2}>
              <TextField
                name="message"
                label="Add more details"
                multiline
                minRows={5}
                fullWidth
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value);
                  setMessageError('');
                }}
                error={Boolean(messageError)}
                helperText={messageError}
              />
            </Box>
            <Box
              sx={{
                textAlign: 'right',
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  width: '100px',
                }}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                variant="contained"
                sx={{
                  width: '100px',
                  ml: 2,
                }}
                onClick={() => {
                  onSubmit();
                }}
              >
                Send
              </Button>
            </Box>
          </Box>
        </ComponentLoading>
      </DialogContent>
    </Dialog>
  );
};

export default NotHappyDialog;
