import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Typography, Grid, Switch, Container, Tooltip, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { DataGridPro, GridValueGetterParams, GridRenderCellParams } from '@mui/x-data-grid-pro';

import { QUERY_GET_AIRCRAFT_COMMENTS, MUTATION_POST_AIRCRAFT_COMMENT, MUTATION_PIN_AIRCRAFT_COMMENT } from 'gql';
import { useLazyQuery, useMutation } from '@apollo/client';

import { MuiFormikTextarea, ButtonSubmit, ComponentLoading, Checkbox, CustomGridToolbar } from 'components';

import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { setAlert } from 'state';
import { useTableConfig } from 'hooks';
import { IComment } from 'types';

import { default as EditCommentDialog } from './EditCommentDialog';

interface CommentsProps {
  aircraftId: string;
  isLoad: boolean;
}

const ValidSubmitSchema = Yup.object().shape({
  comment: Yup.string()
    .min(1, 'Too short: 1-20 characters required')
    .max(1000, 'Too long: 1-1000 characters required')
    .required('Required'),
});

const Comments: React.FC<CommentsProps> = (props) => {
  const { aircraftId, isLoad } = props;

  const [fetchComments, { data: dataComments, error: errorComments, loading: loadingComments }] = useLazyQuery(
    QUERY_GET_AIRCRAFT_COMMENTS,
    {
      fetchPolicy: 'network-only',
    },
  );

  const [addComment, { data: dataAddComment, loading: loadingAddComment, error: errorAddComment }] =
    useMutation(MUTATION_POST_AIRCRAFT_COMMENT);

  const [pinComment, { data: dataPinComment, loading: loadingPinComment, error: errorPinComment }] =
    useMutation(MUTATION_PIN_AIRCRAFT_COMMENT);

  const [pinned, setPinned] = useState(false);
  const [pageSize, setPageSize] = useState(25);
  const [filterButtonEl, setFilterButtonEl] = useState<HTMLButtonElement | null>(null);

  const [editComment, setEditComment] = useState<IComment>();
  const [showEdit, setShowEdit] = useState(false);

  const dispatch = useDispatch();

  const { density, setDensity } = useTableConfig();

  const submitPinUpdate = async (aircraftCommentId: string, pinned: boolean) => {
    await pinComment({
      variables: {
        aircraftCommentId,
        pinned,
      },
    });
  };

  const onSubmit = async (value: any) => {
    await addComment({
      variables: {
        comment: value.comment,
        aircraftId: aircraftId,
        pinned,
      },
    });

    await fetchComments({
      variables: {
        aircraftId: parseInt(aircraftId),
      },
    });
  };

  useEffect(() => {
    if (isLoad && aircraftId) {
      fetchComments({
        variables: {
          aircraftId,
        },
      });
    }
  }, [isLoad, fetchComments, aircraftId]);

  useEffect(() => {
    if (errorComments) {
      dispatch(setAlert('error', 'Unable to load aircraft comments'));
    }
  }, [errorComments, dispatch]);

  useEffect(() => {
    if (errorAddComment) {
      dispatch(setAlert('error', 'Unable to add new comment'));
    } else if (dataAddComment) {
      if (dataAddComment?.postAircraftComment?.ok) {
        dispatch(setAlert('success', 'Added new comment'));
      } else {
        dispatch(setAlert('error', dataAddComment.postAircraftComment.error || 'Unable to add new comment'));
      }
    }
  }, [dataAddComment, errorAddComment, dispatch]);

  useEffect(() => {
    if (errorPinComment) {
      dispatch(setAlert('error', 'Unable to pin selected comment'));
    } else if (dataPinComment) {
      if (dataPinComment.pinAircraftComment?.ok) {
        dispatch(setAlert('success', 'Pinned comment'));
      } else {
        dispatch(setAlert('error', dataPinComment.pinAircraftComment?.error || 'Unable to pin selected comment'));
      }
    }
  }, [errorPinComment, dataPinComment, dispatch]);

  return (
    <Container fixed maxWidth="xl">
      <Box>
        <ComponentLoading loading={loadingAddComment || loadingPinComment || loadingComments}>
          <Box
            py={1}
            sx={{
              width: '100%',
            }}
          >
            {isLoad && (
              <DataGridPro
                sx={{
                  width: '100%',
                  minWidth: '100px',
                  '& .MuiDataGrid-columnSeparator': {
                    display: 'none',
                  },
                  '& .MuiDataGrid-cell:focus': {
                    outline: 'none',
                  },
                  '& .MuiDataGrid-row': {
                    cursor: 'pointer',
                    borderBottom: '1px solid',
                    borderColor: 'grey.200',
                  },
                  '& .MuiDataGrid-columnHeader:focus': {
                    outline: 'none',
                  },
                  '& .MuiDataGrid-cellContent': {
                    whiteSpace: 'break-spaces',
                  },
                  '& .MuiDataGrid-toolbarContainer .MuiTextField-root': {
                    mb: 0,
                  },
                  border: 'none',
                }}
                autoHeight
                getRowHeight={() => 'auto'}
                disableColumnResize={true}
                disableSelectionOnClick={true}
                rowsPerPageOptions={[5, 10, 25, 100]}
                pageSize={pageSize}
                onPageSizeChange={(newPage) => setPageSize(newPage)}
                pagination
                showColumnRightBorder={false}
                disableColumnSelector
                density={density}
                onStateChange={(params) => {
                  if (params.density && params.density.value !== density) {
                    setDensity(params.density.value);
                  }
                }}
                rows={dataComments?.savvy?.aircraft?.comments ? dataComments?.savvy?.aircraft?.comments : []}
                columns={[
                  {
                    field: 'date',
                    headerName: 'Date',
                    valueFormatter: (params) => {
                      return params.value ? params.value.toString().substring(0, 10) : '';
                    },
                    flex: 0.5,
                  },
                  {
                    field: 'poster',
                    headerName: 'Poster',
                    valueGetter: (params: GridValueGetterParams) => {
                      return params.row?.poster ? `${params.row.poster.firstName} ${params.row.poster.lastName}` : '';
                    },
                    flex: 0.7,
                  },
                  {
                    field: 'comment',
                    headerName: 'Comment',
                    flex: 1,
                    renderCell: (params: GridRenderCellParams<string>) => {
                      return (
                        <Tooltip title={params.value || ''}>
                          <Typography
                            sx={{
                              maxWidth: '100%',
                              overflow: 'hidden',
                              whiteSpace: 'break-spaces',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {params.value || ''}
                          </Typography>
                        </Tooltip>
                      );
                    },
                  },
                  {
                    field: 'pinned',
                    headerName: 'Pinned',
                    renderCell: (params: GridRenderCellParams<boolean>) => {
                      return (
                        <Switch
                          checked={params.value}
                          onChange={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            submitPinUpdate(params.row.id, !params.value);
                          }}
                          name=""
                          color="primary"
                        />
                      );
                    },
                    flex: 0.3,
                  },
                  {
                    field: 'id',
                    headerName: '',
                    renderCell: (params: GridRenderCellParams) => {
                      return (
                        <IconButton
                          onClick={() => {
                            setEditComment(params.row as IComment);
                            setShowEdit(true);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      );
                    },
                  },
                ]}
                components={{ Toolbar: CustomGridToolbar }}
                componentsProps={{
                  toolbar: {
                    setFilterButtonEl,
                  },
                  filterPanel: {
                    sx: {
                      '& .MuiDataGrid-filterForm': {
                        '& .MuiTextField-root': {
                          mb: 0,
                        },
                      },
                    },
                  },
                  panel: {
                    anchorEl: filterButtonEl,
                    placement: 'bottom-end',
                  },
                }}
              />
            )}
          </Box>
          <Box py={4}>
            <Typography
              variant="h4"
              sx={{
                textAlign: 'center',
              }}
              gutterBottom
            >
              New Comment
            </Typography>
            <Formik
              initialValues={{
                comment: '',
              }}
              validationSchema={ValidSubmitSchema}
              onSubmit={onSubmit}
            >
              {({ isSubmitting, handleChange, handleBlur, touched, errors }) => {
                return (
                  <Form>
                    <Grid container spacing={2}>
                      <Grid item md={3}></Grid>
                      <Grid item md={6}>
                        <MuiFormikTextarea
                          name="comment"
                          label="Comment"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          maxLength={10000}
                          rows={5}
                          maxLine={10}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item md={3}></Grid>
                      <Grid item md={6}>
                        <Checkbox
                          value={pinned}
                          label={
                            <Typography
                              sx={{
                                display: 'inline-block',
                              }}
                            >
                              Pinned
                            </Typography>
                          }
                          onChange={() => {
                            setPinned(!pinned);
                          }}
                          checked={pinned}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={false} sm={3}></Grid>
                      <Grid item xs={12} sm={6}>
                        <ButtonSubmit text="Add" loading={loadingAddComment || isSubmitting} disabled={isSubmitting} />
                      </Grid>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </Box>
          {editComment && <EditCommentDialog open={showEdit} setOpen={setShowEdit} editComment={editComment} />}
        </ComponentLoading>
      </Box>
    </Container>
  );
};

export default Comments;
