import React from 'react';

import { Box, Container } from '@mui/material';

import { Section } from 'components';

import { ILayout } from 'types';

// Content. Takes all available space
const Content: React.FC<ILayout> = (props) => {
  const { children, backgroundColor = '#FFFFFF' } = props;
  return (
    <Box flexGrow={1} width="100%">
      <Box
        width="100%"
        sx={{
          backgroundColor: backgroundColor,
          pl: {
            xs: 2,
            sm: 2,
            md: 2,
          },
          pr: {
            xs: 2,
            sm: 2,
            md: 2,
          },
        }}
      >
        <Section>
          <Container>
            <div>{children}</div>
          </Container>
        </Section>
      </Box>
    </Box>
  );
};

export default Content;
