import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Typography, Button, Container, Grid } from '@mui/material';

import { MUTATION_SET_MONITOR_CONFIG } from 'gql';
import { useMutation } from '@apollo/client';

import { ComponentLoading } from 'components';
import { setAlert } from 'state';

import { default as UbgMappingSeriesSelector } from './UbgMappingSeriesSelector';

interface MglMappingProps {
  aircraftId: string;
  monitorConfig: string | undefined;
}

const MglMapping: React.FC<MglMappingProps> = (props) => {
  const { aircraftId, monitorConfig = undefined } = props;

  const dispatch = useDispatch();

  const [setMonitorConfig, { data: dataMonitorConfig, error: errorMonitorConfig, loading: loadingMonitorConfig }] =
    useMutation(MUTATION_SET_MONITOR_CONFIG);

  const NUMBER_OF_SERIES = 28;

  const initialSeries = Array.apply(null, Array(NUMBER_OF_SERIES)).map(() => 'None');

  const [seriesValue, setSeriesValue] = useState<string[]>(initialSeries);

  const onSubmit = async () => {
    const resultSeries: any = {};

    for (let i = 0; i < seriesValue.length; i++) {
      let index = i;

      if (i >= 14) index = i - 14;

      const key = 'field_' + (i > 13 ? '02' : '01') + '_' + ('0' + index).slice(-2);
      resultSeries[key] = seriesValue[i];
    }

    await setMonitorConfig({
      variables: {
        aircraftId: aircraftId,
        monitorConfig: JSON.stringify(resultSeries),
      },
    });
  };

  const single4Setting = () => {
    for (let i = 0; i < seriesValue.length; i++) {
      seriesValue[i] = 'None';
    }

    seriesValue[0] = 'EGT1';
    seriesValue[1] = 'CHT1';
    seriesValue[2] = 'EGT2';
    seriesValue[3] = 'CHT2';
    seriesValue[4] = 'EGT3';
    seriesValue[5] = 'CHT3';
    seriesValue[6] = 'EGT4';
    seriesValue[7] = 'CHT4';

    setSeriesValue([...seriesValue]);
  };

  const single6Setting = () => {
    for (let i = 0; i < seriesValue.length; i++) {
      seriesValue[i] = 'None';
    }

    seriesValue[0] = 'EGT1';
    seriesValue[1] = 'CHT1';
    seriesValue[2] = 'EGT2';
    seriesValue[3] = 'CHT2';
    seriesValue[4] = 'EGT3';
    seriesValue[5] = 'CHT3';
    seriesValue[6] = 'EGT4';
    seriesValue[7] = 'CHT4';
    seriesValue[8] = 'EGT5';
    seriesValue[9] = 'CHT5';
    seriesValue[10] = 'EGT6';
    seriesValue[11] = 'CHT6';

    setSeriesValue([...seriesValue]);
  };

  const twin4Setting = () => {
    for (let i = 0; i < seriesValue.length; i++) {
      seriesValue[i] = 'None';
    }

    seriesValue[0] = 'L-EGT1';
    seriesValue[1] = 'L-CHT1';
    seriesValue[2] = 'L-EGT2';
    seriesValue[3] = 'L-CHT2';
    seriesValue[4] = 'L-EGT3';
    seriesValue[5] = 'L-CHT3';
    seriesValue[6] = 'L-EGT4';
    seriesValue[7] = 'L-CHT4';

    seriesValue[14] = 'R-EGT1';
    seriesValue[15] = 'R-CHT1';
    seriesValue[16] = 'R-EGT2';
    seriesValue[17] = 'R-CHT2';
    seriesValue[18] = 'R-EGT3';
    seriesValue[19] = 'R-CHT3';
    seriesValue[20] = 'R-EGT4';
    seriesValue[21] = 'R-CHT4';

    setSeriesValue([...seriesValue]);
  };

  const twin6Setting = () => {
    for (let i = 0; i < seriesValue.length; i++) {
      seriesValue[i] = 'None';
    }

    seriesValue[0] = 'L-EGT1';
    seriesValue[1] = 'L-CHT1';
    seriesValue[2] = 'L-EGT2';
    seriesValue[3] = 'L-CHT2';
    seriesValue[4] = 'L-EGT3';
    seriesValue[5] = 'L-CHT3';
    seriesValue[6] = 'L-EGT4';
    seriesValue[7] = 'L-CHT4';
    seriesValue[8] = 'L-EGT5';
    seriesValue[9] = 'L-CHT5';
    seriesValue[10] = 'L-EGT6';
    seriesValue[11] = 'L-CHT6';

    seriesValue[14] = 'R-EGT1';
    seriesValue[15] = 'R-CHT1';
    seriesValue[16] = 'R-EGT2';
    seriesValue[17] = 'R-CHT2';
    seriesValue[18] = 'R-EGT3';
    seriesValue[19] = 'R-CHT3';
    seriesValue[20] = 'R-EGT4';
    seriesValue[21] = 'R-CHT4';
    seriesValue[22] = 'R-EGT5';
    seriesValue[23] = 'R-CHT5';
    seriesValue[24] = 'R-EGT6';
    seriesValue[25] = 'R-CHT6';

    setSeriesValue([...seriesValue]);
  };

  useEffect(() => {
    setSeriesValue((seriesValue: string[]) => {
      if (!monitorConfig) return [...seriesValue];

      const monitorConfigJson = JSON.parse(monitorConfig);

      Object.keys(monitorConfigJson)?.map((key: string) => {
        const multiple = parseInt(key.split('_')[1]);
        const index = parseInt(key.split('_')[2]);

        seriesValue[(multiple - 1) * 14 + index] = monitorConfigJson[key];
        return null;
      });

      return [...seriesValue];
    });
  }, [monitorConfig]);

  useEffect(() => {
    if (errorMonitorConfig) {
      dispatch(setAlert('error', 'Unable to set monitor config'));
    } else if (dataMonitorConfig) {
      if (dataMonitorConfig?.setMonitorConfig?.ok) {
        dispatch(setAlert('success', 'Saved monitor config'));
      } else {
        dispatch(setAlert('error', dataMonitorConfig.setMonitorConfig.error || 'Unable to set monitor config'));
      }
    }
  }, [errorMonitorConfig, dispatch, dataMonitorConfig]);

  return (
    <Box
      sx={{
        p: {
          xs: 2,
          sm: 3,
          md: 4,
          lg: 5,
        },
      }}
    >
      <Typography variant="body1" gutterBottom>
        You can select one of the typical configurations as a starting point, and then edit the individual fields to match your exact
        aircraft configuration.
        <br />
        <br />
        Any changes you make here will only affect future uploads.
        <br />
        For MGL Extreme G2, TC1-12 maps to RDAC 1 Sensors 1-12.
      </Typography>
      <ComponentLoading loading={loadingMonitorConfig}>
        <Box
          py={1}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography>Single Engine:</Typography>
          <Button
            variant="outlined"
            onClick={single4Setting}
            sx={{
              ml: 1,
            }}
          >
            Typical 4-cyl
          </Button>
          <Button
            variant="outlined"
            sx={{
              ml: 1,
            }}
            onClick={single6Setting}
          >
            Typical 6-cyl
          </Button>
        </Box>
        <Box
          py={1}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography>Twin Engine:</Typography>
          <Button
            variant="outlined"
            onClick={twin4Setting}
            sx={{
              ml: 1,
            }}
          >
            Typical 4-cyl
          </Button>
          <Button
            variant="outlined"
            sx={{
              ml: 1,
            }}
            onClick={twin6Setting}
          >
            Typical 6-cyl
          </Button>
        </Box>
        <Container maxWidth="sm" fixed>
          <Grid container spacing={2}>
            {Array.apply(null, Array(NUMBER_OF_SERIES)).map((value: any, key: number) => {
              let label = '';
              if (key < 12) {
                label = 'RDAC 1: Sensor ' + (key + 1);
              } else if (key === 12) {
                label = 'RDAC 1: Rotax 912 1';
              } else if (key === 13) {
                label = 'RDAC 1: Rotax 912 2';
              } else if (key > 13 && key < 26) {
                label = 'RDAC 2: Sensor ' + (key - 13);
              } else {
                label = 'RDAC 2: Rotax 912 ' + (key - 25);
              }
              return (
                <Grid key={key} item xs={6}>
                  <UbgMappingSeriesSelector seriesValue={seriesValue} setSeriesValue={setSeriesValue} index={key} label={label} />
                </Grid>
              );
            })}
          </Grid>
        </Container>
        <Box
          sx={{
            textAlign: 'center',
          }}
        >
          <Button
            variant="contained"
            onClick={onSubmit}
            sx={{
              width: '350px',
            }}
          >
            Submit
          </Button>
        </Box>
      </ComponentLoading>
    </Box>
  );
};

export default MglMapping;
