import React, { useState } from 'react';
import FormGroupInput from './FormGroupInput';
import { InputAdornment, IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

interface SignupEmailProps {
  handleBlur: (e: React.FocusEvent<any, Element>) => void;
  handleChange: (e: React.ChangeEvent<any>) => void;
  touched: any;
  errors: any;
}

const SignupPassword: React.FC<SignupEmailProps> = (props) => {
  const { handleBlur, handleChange, touched, errors } = props;

  const [showPassword, setShowPassword] = useState(false);

  return (
    <FormGroupInput
      name="password"
      label="Password *"
      type={showPassword ? 'text' : 'password'}
      onBlur={handleBlur}
      onChange={handleChange}
      errors={errors}
      touched={touched}
      maxLength={30}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SignupPassword;
