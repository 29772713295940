import React from 'react';
import { CSSObject } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';

interface AppearanceIconProps {
  sx?: CSSObject;
}

const AppearanceIcon: React.FC<AppearanceIconProps> = (props) => {
  const { sx } = props;

  return (
    <SvgIcon
      width="18"
      height="15"
      viewBox="0 0 18 15"
      sx={{
        fill: 'none',
        ...sx,
      }}
    >
      <path
        d="M14.0046 6.35459L8.04541 0.39541C7.79224 0.142235 7.44886 1.87222e-06 7.09082 0H1.35C0.604406 0 0 0.604406 0 1.35V7.09082C1.87222e-06 7.44886 0.142235 7.79224 0.39541 8.04541L6.35459 14.0046C6.88177 14.5318 7.73654 14.5318 8.26377 14.0046L14.0046 8.26377C14.5318 7.73657 14.5318 6.8818 14.0046 6.35459ZM3.15 4.5C2.40441 4.5 1.8 3.8956 1.8 3.15C1.8 2.40441 2.40441 1.8 3.15 1.8C3.8956 1.8 4.5 2.40441 4.5 3.15C4.5 3.8956 3.8956 4.5 3.15 4.5ZM17.6046 8.26377L11.8638 14.0046C11.3366 14.5318 10.4818 14.5318 9.95459 14.0046L9.94447 13.9945L14.8399 9.09906C15.318 8.62096 15.5813 7.98531 15.5813 7.30918C15.5813 6.63306 15.318 5.99741 14.8399 5.51931L9.32054 0H10.6908C11.0489 1.87222e-06 11.3922 0.142235 11.6454 0.39541L17.6046 6.35459C18.1318 6.8818 18.1318 7.73657 17.6046 8.26377Z"
        fill="#131E40"
      />
    </SvgIcon>
  );
};

export default AppearanceIcon;
