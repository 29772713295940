import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  TextField,
  Grid,
  Button,
  CircularProgress,
  FormControlLabel,
  Checkbox,
} from '@mui/material';

import { uploadFileDirectS3 } from 'helpers';
import { setAlert } from 'state';

import { MUTATION_CREATE_CERTIFICATE_OF_INSURANCE } from 'gql';
import { useMutation } from '@apollo/client';
import { Dayjs } from 'dayjs';
import { ComponentLoading, CustomDatePicker } from 'components';

interface AddCoiDialogProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  aircraftId: string;
}

const AddCoiDialog: React.FC<AddCoiDialogProps> = (props) => {
  const { open, setOpen, aircraftId } = props;

  const [addCoi, { data: dataCoi, loading: loadingCoi, error: errorCoi }] = useMutation(MUTATION_CREATE_CERTIFICATE_OF_INSURANCE);
  const dispatch = useDispatch();
  const { isSavvy } = useSelector((state: any) => state.auth);

  const [agent, setAgent] = useState('');

  const [approve, setApprove] = useState(false);

  const [underwriter, setUnderwriter] = useState('');

  const [documentPath, setDocumentPath] = useState('');
  const [documentError, setDocumentError] = useState('');
  const [isUploading, setIsUploading] = useState(false);

  const [expirationDate, setExpirationDate] = useState<Dayjs | null>(null);
  const [expirationDateError, setExpirationDateError] = useState('');

  const [receivedDate, setReceivedDate] = useState<Dayjs | null>(null);
  const [receivedDateError, setReceivedDateError] = useState('');

  const [file, setFile] = useState<File | null>(null);

  const handleClose = () => {
    setOpen(false);
  };

  const uploadAttachments = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;

    if (!file) return;

    setIsUploading(true);
    try {
      const result = await uploadFileDirectS3(file, true, '');
      if (result.success) {
        setDocumentPath(result.key);
        setFile(file);
      } else {
        setFile(null);
        setDocumentError('Failed to upload document');
      }
      setIsUploading(false);
    } catch (error) {
      setIsUploading(false);
      setDocumentError('Failed to upload document');
    }
  };

  const onSubmit = async () => {
    if (!documentPath) {
      setDocumentError('Please add document');
      return;
    }

    if (!expirationDate) {
      setExpirationDateError('Please select expiration date');
      return;
    }

    if (!receivedDate && isSavvy) {
      setReceivedDateError('Please select received date');
      return;
    }

    await addCoi({
      variables: {
        agent,
        documentPath,
        aircraftId,
        underwriter,
        receivedDate,
        expirationDate,
        approve,
      },
    });
  };

  useEffect(() => {
    if (errorCoi) {
      dispatch(setAlert('error', 'Unable to add certificate of insurance'));
    } else if (dataCoi) {
      if (dataCoi?.createCertificateOfInsurance?.ok) {
        dispatch(setAlert('success', 'Created certificate of insurance'));
        setOpen(false);
      } else {
        dispatch(setAlert('error', dataCoi?.createCertificateOfInsurance?.error || 'Unable to add certificate of insurance'));
      }
    }
  }, [errorCoi, dispatch, dataCoi, setOpen]);

  useEffect(() => {
    if (!open) {
      setAgent('');
      setDocumentPath('');
      setUnderwriter('');
      setExpirationDate(null);
      setReceivedDate(null);
      setFile(null);
    }
  }, [open]);

  return (
    <Dialog open={open} fullWidth onClose={handleClose}>
      <DialogContent>
        <Typography variant="h2" gutterBottom align="center">
          Add Certificate of Insurance
        </Typography>
        <ComponentLoading loading={loadingCoi}>
          <Box>
            <TextField
              label="Agent"
              name="agent"
              value={agent}
              onChange={(e) => {
                setAgent(e.target.value);
              }}
              fullWidth
            />
            <TextField
              label="Underwriter"
              name="underwriter"
              value={underwriter}
              onChange={(e) => {
                setUnderwriter(e.target.value);
              }}
              fullWidth
            />
            {isSavvy && (
              <FormControlLabel
                label={<Typography>Approve COI?</Typography>}
                control={
                  <Checkbox
                    checked={approve}
                    onChange={(event: any) => {
                      setApprove(event.target.checked);
                    }}
                    sx={{
                      padding: 0,
                    }}
                  />
                }
                sx={{
                  ml: 0,
                  mb: '15px',
                }}
              />
            )}
            <Box
              sx={{
                mb: file ? '5px' : '15px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Button
                href="#"
                component="label"
                onClick={(e: any) => {
                  if (isUploading) {
                    e.preventDefault();
                    e.stopPropagation();
                    return false;
                  }
                }}
              >
                Add Document
                <input
                  type="file"
                  id="attachFile"
                  hidden
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setDocumentError('');
                    uploadAttachments(e);
                  }}
                  name="attachments"
                  accept=".pdf"
                />
              </Button>
              {isUploading && <CircularProgress color="inherit" size={20} variant="indeterminate" />}
            </Box>
            {file && (
              <Box>
                <Typography gutterBottom>{file.name}</Typography>
              </Box>
            )}
            {!!documentError && (
              <Typography
                sx={{
                  color: 'error.main',
                }}
                gutterBottom
              >
                {documentError}
              </Typography>
            )}
            <CustomDatePicker
              value={expirationDate}
              setValue={setExpirationDate}
              error={expirationDateError}
              setError={setExpirationDateError}
              label={'Expiration Date *'}
              sx={{
                ml: 0,
              }}
            />
            {isSavvy && (
              <CustomDatePicker
                value={receivedDate}
                setValue={setReceivedDate}
                error={receivedDateError}
                setError={setReceivedDateError}
                label={'Received Date *'}
                sx={{
                  ml: 0,
                }}
              />
            )}
          </Box>
        </ComponentLoading>
      </DialogContent>

      <DialogActions>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button variant="outlined" onClick={handleClose} disabled={loadingCoi || isUploading} fullWidth>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="contained" onClick={onSubmit} disabled={loadingCoi || isUploading} fullWidth>
              Submit
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default AddCoiDialog;
