import React, { Fragment, useState } from 'react';

import { useHistory } from 'react-router-dom';

import { PATH_FLIGHTS_UPLOAD, PATH_BORESCOPE_UPLOAD } from 'const';

import { Box, Button, List, ListItem, MenuItem, Typography, Collapse } from '@mui/material';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';

import { Dropdown, DropdownMenu } from 'components';
import { useXlUp } from 'hooks';
import FlightIcon from '@mui/icons-material/Flight';

const UploadMenu: React.FC = () => {
  const isXlUp = useXlUp();

  // Dropdown Menu
  const [open, setOpen] = useState(false);
  const toggleMenu = () => {
    setOpen(!open);
  };
  const closeMenu = () => {
    setOpen(false);
  };

  const history = useHistory();

  const handleEngineData = () => {
    history.push(PATH_FLIGHTS_UPLOAD);
  };

  const handleBorescope = () => {
    history.push(PATH_BORESCOPE_UPLOAD);
  };

  return (
    <Box
      height="100%"
      alignItems="center"
      sx={{
        display: {
          md: 'block',
          lg: 'inline-flex',
        },
      }}
    >
      {isXlUp && (
        <Box>
          <Dropdown onClickAway={closeMenu}>
            <Box display="flex" height="100%">
              <Button color="inherit" endIcon={<ExpandMoreOutlinedIcon />} onClick={toggleMenu}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <FlightIcon
                    sx={{
                      transform: 'rotate(45deg)',
                      mr: 1,
                    }}
                  />
                  <Typography fontWeight={600}>Upload</Typography>
                </Box>
              </Button>
            </Box>

            <DropdownMenu open={open}>
              <List
                component="div"
                sx={{
                  width: '250px',
                }}
              >
                <ListItem ContainerComponent="div" button onClick={handleEngineData}>
                  Engine Data
                </ListItem>
                <ListItem ContainerComponent="div" button onClick={handleBorescope}>
                  Borescope Images
                </ListItem>
              </List>
            </DropdownMenu>
          </Dropdown>
        </Box>
      )}
      {!isXlUp && (
        <Box>
          <MenuItem onClick={toggleMenu}>
            <Typography variant="body1">Upload</Typography>
            {!open ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
          </MenuItem>
          {open && (
            <Collapse in={open} timeout="auto">
              <Fragment>
                <MenuItem onClick={handleEngineData}>
                  <Typography variant="body1">Engine Data</Typography>
                </MenuItem>
                <MenuItem onClick={handleBorescope}>
                  <Typography variant="body1">Borescope Images</Typography>
                </MenuItem>
              </Fragment>
            </Collapse>
          )}
        </Box>
      )}
    </Box>
  );
};

export default UploadMenu;
