import { gql } from '@apollo/client';
import { FRAGMENT_ANALYSIS_PACK, FRAGMENT_SERVICE_KEY_INFO, FRAGMENT_SERVICE_NET_INFO } from 'gql';

export const QUERY_USER_SUBSCRIPTIONS = gql`
  query Subscription {
    me {
      id
      subscriptions {
        id
        serviceName
        serviceCode
        status
        aircraft {
          id
          registration
          managePlansLink
        }
        endDate
        amountPaid
        amountRefunded
      }
    }
  }
`;

export const QUERY_GET_USER_TRANSACTIONS = gql`
  query GetUserTransactions($contactId: ID) {
    savvy {
      id
      contacts(contactId: $contactId) {
        id
        payments {
          id
          date
          amount
          refunded
          description
          last4
          cardType
        }
        chargeCategories
      }
    }
  }
`;

export const QUERY_SAVVY_SUBSCRIPTION_SEARCH = gql`
  query SavvySubscriptionSearch($search: String, $limit: Int, $offset: Int) {
    savvy {
      id
      search(search: $search, limit: $limit) {
        subscriptions(offset: $offset, limit: $limit) {
          count
          results {
            id
            serviceName
            serviceCode
            status
            aircraft {
              id
              owner {
                id
                firstName
                lastName
              }
              registration
            }
            creationDate
            amountPaid
            amountRefunded
            endDate
            startDate
          }
        }
      }
    }
  }
`;

export const QUERY_SAVVY_CONTACT_SUBSCRIPTION = gql`
  query SavvyContactSubscription($contactId: ID, $subscriptionId: ID) {
    savvy {
      contacts(contactId: $contactId) {
        id
        firstName
        lastName
        subscriptions(subscriptionId: $subscriptionId) {
          id
          mxId
          creationDate
          testMode
          startDate
          endDate
          serviceName
          serviceCode
          terminationCode
          reasonToCancel
          cancelDate
          status
          amountPaid
          amountRefunded
          aircraft {
            id
            serial
            registration
            aircraftManufacturer {
              id
              name
            }
            aircraftModel {
              id
              name
            }
            owner {
              id
              firstName
              lastName
              email
            }
          }
          promoCode
          lastSentWarning
          succeededBySubscription {
            id
            aircraft {
              id
            }
          }
          preceededBySubscription {
            id
            aircraft {
              id
            }
          }
        }
      }
    }
  }
`;

export const QUERY_GET_USERS_TICKETS_FOR_SUBSCRIPTION = gql`
  query GetUsersTicketsForSubscription($contactId: ID) {
    savvy {
      id
      contacts(contactId: $contactId) {
        tickets {
          id
          subject
          type
          aircraft {
            id
            registration
          }
        }
      }
      me {
        id
      }
    }
  }
`;

export const QUERY_GET_USERS_AIRCRAFT_FOR_SUBSCRIPTION = gql`
  query GetUsersAircraftForSubscription($contactId: ID) {
    savvy {
      id
      contacts(contactId: $contactId) {
        aircraft {
          id
          serial
          registration
          aircraftManufacturer {
            id
            name
          }
          aircraftModel {
            id
            name
          }
        }
      }
      me {
        id
      }
    }
  }
`;

export const QUERY_GET_AIRCRAFT_SUBSCRIPTION = gql`
  query GetAircraftSubscriptions($aircraftId: ID) {
    savvy {
      id
      aircraft(aircraftId: $aircraftId) {
        id
        subscriptions {
          id
          serviceName
          startDate
          endDate
          terminationCode
        }
      }
    }
  }
`;

export const QUERY_GET_AIRCRAFT_SUBSCRIPTION_DETAIL = gql`
  query GetAircraftSubscriptionDetail($aircraftId: ID, $subscriptionId: ID) {
    savvy {
      id
      aircraft(aircraftId: $aircraftId) {
        id
        subscriptions(subscriptionId: $subscriptionId) {
          id
          mxId
          creationDate
          testMode
          startDate
          endDate
          serviceName
          serviceCode
          terminationCode
          reasonToCancel
          cancelDate
          status
          amountPaid
          amountRefunded
          aircraft {
            id
            serial
            registration
            aircraftManufacturer {
              id
              name
            }
            aircraftModel {
              id
              name
            }
            owner {
              id
              firstName
              lastName
              email
            }
          }
          promoCode
          lastSentWarning
          succeededBySubscription {
            id
            aircraft {
              id
            }
          }
          preceededBySubscription {
            id
            aircraft {
              id
            }
          }
        }
      }
    }
  }
`;

export const QUERY_GET_CLIENT_ANALYSIS_PACKS = gql`
  ${FRAGMENT_ANALYSIS_PACK}
  query GetClientAnalysisPacks {
    me {
      id
      analysisPacks {
        ...analysisPack
      }
      remainingAnalysisPacks
    }
  }
`;

export const QUERY_ACTIVE_PREBUYS = gql`
  query GetActivePrebuys($showOpen: Boolean) {
    savvy {
      id
      prebuys(showOpen: $showOpen) {
        id
        creationDate
        startDate
        endDate
        aircraft {
          id
          owner {
            id
            firstName
            lastName
          }
          registration
        }
        succeededBySubscription {
          id
          serviceName
          startDate
          endDate
        }
      }
    }
  }
`;

export const QUERY_KEY_RESULTS_REPORT = gql`
  ${FRAGMENT_SERVICE_KEY_INFO}
  ${FRAGMENT_SERVICE_NET_INFO}
  query KeyResultsReport($year: String, $month: String) {
    keyResultsReport(year: $year, month: $month) {
      services
      serviceDetails {
        ...ServiceKeyInfo
      }
      serviceRenewals {
        ...ServiceKeyInfo
      }
      savvyTotals {
        new
        newPrebuys
        nonRenew
      }
      serviceNets {
        ...ServiceNetInfo
      }
      serviceContributions {
        ...ServiceNetInfo
      }
      prebuyStats {
        ...ServiceNetInfo
      }
    }
  }
`;
