// Server contstants used in React. Synchronization. Should be the same on server and React

// Names
export const FIRST_NAME_MAX_LENGTH = 20;
export const LAST_NAME_MAX_LENGTH = 20;

// Aircraft
export const REGISTRATION_NUMBER_MAX_LENGTH = 20;
export const AIRCRAFT_MODEL_START_YEAR = 1900; // Type should be Number, NOT string for correct type comparison in api/aircraftModelYears.ts
export const CURRENT_YEAR = new Date().getFullYear();

// Password
export const PASSWORD_MIN_LENGTH = 8;
export const PASSWORD_MAX_LENGTH = 30;

// Search
export const SAVVY_SEARCH_LIMIT = 10;

// Auto refresh
export const AUTO_REFRESH_CHECK_TIME = 600; // check every 10 minutes
export const CHECK_REFRESH_PATH = 'static/js/main';

export const TIMEOUT_FOR_TESTING = 30000;
