import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Dialog, Box, DialogTitle, DialogContent, TextField, MenuItem, DialogActions, Grid, Button, Typography } from '@mui/material';

import { Dayjs } from 'dayjs';

import { MUTATION_CREATE_MAINTENANCE_ENTRY_FROM_DOC, QUERY_MAINTENANCE_RECORD_CATEGORIES, QUERY_SAVVY_AIRCRAFT_LOGBOOKS } from 'gql';
import { useLazyQuery, useMutation } from '@apollo/client';
import { setAlert } from 'state';

import { ComponentLoading, Checkbox, CustomDatePicker } from 'components';
import { ILogbook } from 'types';

interface CreateLogFromDocumentDialogProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  documentId: string;
  aircraftId: string;
  fileName: string;
}

const CreateLogFromDocumentDialog: React.FC<CreateLogFromDocumentDialogProps> = (props) => {
  const { open, setOpen, documentId, aircraftId } = props;

  const [createMaintenanceEntry, { data, loading, error }] = useMutation(MUTATION_CREATE_MAINTENANCE_ENTRY_FROM_DOC);
  const [fetchMaintenanceCategory, { data: dataCategory, loading: loadingCategory, error: errorCategory }] =
    useLazyQuery(QUERY_MAINTENANCE_RECORD_CATEGORIES);
  const [fetchLogbooks, { data: dataLogbooks, loading: loadingLogbooks, error: errorLogbooks }] =
    useLazyQuery(QUERY_SAVVY_AIRCRAFT_LOGBOOKS);
  const dispatch = useDispatch();

  const [isPerformedInspection, setIsPerformedInspection] = useState(false);
  const [isPerformedMaintenance, setIsPerformedMaintenance] = useState(false);

  const [entryDate, setEntryDate] = useState<Dayjs | null>(null);
  const [entryDateError, setEntryDateError] = useState('');

  const [maintenanceEntryCategoryId, setMaintenanceEntryCategoryId] = useState('-1');
  const [recordTypeError, setRecordTypeError] = useState('');

  const [logbookId, setLogbookId] = useState('-1');
  const [logbookIdError, setLogbookIdError] = useState('');

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async () => {
    if (!entryDate) {
      setEntryDateError('Please input entry date');
      return;
    }

    if (!logbookId || logbookId === '-1') {
      setLogbookIdError('Please select logbook');
      return;
    }

    if (!maintenanceEntryCategoryId || maintenanceEntryCategoryId === '-1') {
      setRecordTypeError('Please select maintenance record type');
      return;
    }

    await createMaintenanceEntry({
      variables: {
        aircraftId,
        documentId,
        isPerformedInspection,
        isPerformedMaintenance,
        entryDate,
        maintenanceEntryCategoryId,
        logbookId,
      },
    });
  };

  useEffect(() => {
    if (open) {
      fetchMaintenanceCategory();
    }
  }, [open, fetchMaintenanceCategory]);

  useEffect(() => {
    if (open) {
      fetchLogbooks({
        variables: {
          aircraftId,
        },
      });
    }
  }, [open, fetchLogbooks, aircraftId]);

  useEffect(() => {
    if (error) {
      dispatch(setAlert('error', 'Unable to create maintenance record'));
    } else if (data) {
      if (data?.createMaintenanceEntryWithExistingDocument?.ok) {
        dispatch(setAlert('success', 'Created maintenance record from document'));
        setOpen(false);
      } else {
        dispatch(setAlert('error', data?.createMaintenanceEntryWithExistingDocument?.error || 'Unable to create maintenance record'));
      }
    }
  }, [error, data, dispatch, setOpen]);

  useEffect(() => {
    if (errorCategory) {
      dispatch(setAlert('error', 'Unable to fetch maintenance record category'));
    }
  }, [errorCategory, dispatch]);

  useEffect(() => {
    if (errorLogbooks) {
      dispatch(setAlert('error', 'Unable to fetch logbooks'));
    }
  }, [errorLogbooks, dispatch]);

  useEffect(() => {
    if (!open) {
      setIsPerformedInspection(false);
      setIsPerformedMaintenance(false);
      setEntryDate(null);
      setMaintenanceEntryCategoryId('-1');
      setLogbookId('-1');
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={handleClose}
      PaperProps={{
        sx: {
          maxWidth: '400px',
        },
      }}
    >
      <DialogTitle
        sx={{
          textAlign: 'center',
          fontSize: '24px',
          fontWeight: 700,
        }}
      >
        Create Maintenance Record
      </DialogTitle>
      <DialogContent>
        <ComponentLoading loading={loading || loadingCategory || loadingLogbooks}>
          <Box py={1}>
            <Checkbox
              value={isPerformedMaintenance}
              checked={isPerformedMaintenance}
              label={
                <Typography
                  sx={{
                    display: 'inline-block',
                  }}
                >
                  Performed Maintenance?
                </Typography>
              }
              onChange={() => {
                setIsPerformedMaintenance(!isPerformedMaintenance);
              }}
              noMargin
            />
          </Box>
          <Box py={1}>
            <Checkbox
              value={isPerformedInspection}
              checked={isPerformedInspection}
              label={
                <Typography
                  sx={{
                    display: 'inline-block',
                  }}
                >
                  Performed Inspection?
                </Typography>
              }
              onChange={() => {
                setIsPerformedInspection(!isPerformedInspection);
              }}
              noMargin
            />
          </Box>
          <Box py={1}>
            <CustomDatePicker
              label="Entry Date"
              value={entryDate}
              setValue={setEntryDate}
              error={entryDateError}
              setError={setEntryDateError}
              sx={{
                ml: 0,
              }}
            />
          </Box>
          <Box py={1}>
            <TextField
              name="logbookId"
              label="Logbook *"
              value={logbookId}
              onChange={(e) => {
                setLogbookId(e.target.value);
                setLogbookIdError('');
              }}
              error={!!logbookIdError}
              helperText={logbookIdError}
              FormHelperTextProps={{
                sx: {
                  ml: 0,
                },
              }}
              select
              fullWidth
            >
              <MenuItem value="-1">Select Logbook</MenuItem>
              {dataLogbooks?.savvy?.aircraft?.logbooks?.map((logbook: ILogbook, key: number) => {
                return (
                  <MenuItem key={key} value={logbook.id}>
                    {logbook.name}
                  </MenuItem>
                );
              })}
            </TextField>
          </Box>
          <Box py={1}>
            <TextField
              name="maintenanceRecordType"
              label="Maintenance Record Type *"
              value={maintenanceEntryCategoryId}
              onChange={(e) => {
                setMaintenanceEntryCategoryId(e.target.value);
                setRecordTypeError('');
              }}
              error={!!recordTypeError}
              helperText={recordTypeError}
              FormHelperTextProps={{
                sx: {
                  ml: 0,
                },
              }}
              select
              fullWidth
            >
              <MenuItem value="-1">Select record type</MenuItem>
              {dataCategory?.maintenanceEntryCategories?.map((category: any, key: number) => {
                return (
                  <MenuItem key={key} value={category.id}>
                    {category.name}
                  </MenuItem>
                );
              })}
            </TextField>
          </Box>
        </ComponentLoading>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button variant="outlined" onClick={handleClose} disabled={loading} fullWidth>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="contained" onClick={onSubmit} disabled={loading} fullWidth>
              Submit
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default CreateLogFromDocumentDialog;
