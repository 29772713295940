import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Box, Typography, Link, TextField, InputAdornment, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import SubdirectoryArrowLeftIcon from '@mui/icons-material/SubdirectoryArrowLeft';

import { ComponentLoading } from 'components';
import { useQuery } from '@apollo/client';
import { QUERY_INTERNAL_DASHBOARD } from 'gql';
import { setAlert } from 'state';
import { humanDate, ctrlCmdClick } from 'helpers';

interface InternalDashboardProps {
  show: boolean;
}

const InternalDashboard: React.FC<InternalDashboardProps> = (props) => {
  const { show } = props;

  const inputRef = useRef<HTMLInputElement>();

  const { data, loading, error } = useQuery(QUERY_INTERNAL_DASHBOARD);

  const dispatch = useDispatch();
  const history = useHistory();

  const [searchMenu, setSearchMenu] = useState('');

  const clickFirstMenu = (internalGroup: any, search: string) => {
    if (!internalGroup || !search) {
      return;
    }

    for (let i = 0; i < internalGroup.groups.length; i++) {
      if (!internalGroup.groups[i]) {
        continue;
      }
      for (let j = 0; j < internalGroup.groups[i].items.length; j++) {
        if (internalGroup.groups[i].items[j].name.toLowerCase().includes(search.toLowerCase())) {
          if (internalGroup.groups[i].items[j].isInternal) {
            history.push(internalGroup.groups[i].items[j].link);
          } else {
            window.location.href = internalGroup.groups[i].items[j].link;
          }
          return;
        }
      }
    }
  };

  const findFirstMatch = (internalGroup: any, search: string) => {
    if (!internalGroup || !search) {
      return {
        firstIndex: -1,
        secondIndex: -1,
      };
    }
    for (let i = 0; i < internalGroup.groups.length; i++) {
      if (!internalGroup.groups[i]) {
        continue;
      }
      for (let j = 0; j < internalGroup.groups[i].items.length; j++) {
        if (internalGroup.groups[i].items[j].name.toLowerCase().includes(search.toLowerCase())) {
          return {
            firstIndex: i,
            secondIndex: j,
          };
        }
      }
    }

    return {
      firstIndex: -1,
      secondIndex: -1,
    };
  };

  useEffect(() => {
    if (error) {
      dispatch(setAlert('error', "You can't load this page."));
    } else {
      if (data && !data?.savvy?.me?.isSavvy) {
        dispatch(setAlert('error', 'Your role is not accessible this page.'));
      }
    }
  }, [data, error, dispatch]);

  useEffect(() => {
    if (show) {
      inputRef?.current && inputRef.current?.focus();
    }
  }, [show]);

  if (!data?.savvy?.me?.isSavvy || error) return null;

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
      }}
      px={2.5}
      py={1.5}
    >
      <Box>
        <Box py={1}>
          {data?.savvy?.internalDashboard.currentlyOop?.map((item: any, key: number) => {
            return (
              <Box key={key} py={1}>
                <Typography align="center">
                  {`${item.user.firstName} ${item.user.lastName} is out through ${humanDate(item.endTime)}`}
                </Typography>
              </Box>
            );
          })}
        </Box>
        <Box
          px={1}
          sx={{
            width: '50%',
          }}
        >
          <TextField
            label="Search Menu"
            name="searchMenu"
            value={searchMenu}
            onChange={(e) => setSearchMenu(e.target.value)}
            fullWidth
            inputRef={inputRef}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {searchMenu ? (
                    <IconButton onClick={() => setSearchMenu('')}>
                      <CloseIcon />
                    </IconButton>
                  ) : (
                    <SearchIcon />
                  )}
                </InputAdornment>
              ),
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                clickFirstMenu(data?.savvy?.internalDashboard, searchMenu);
              }
            }}
          />
        </Box>
        <ComponentLoading loading={loading}>
          {data.savvy?.internalDashboard && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                '@media (max-width: 499px)': {
                  display: 'block',
                },
              }}
            >
              <Box
                px={1}
                sx={{
                  width: '50%',
                }}
              >
                {data?.savvy?.internalDashboard?.groups?.map((group: any, key: number) => {
                  const firstIndex = findFirstMatch(data.savvy.internalDashboard, searchMenu).firstIndex;
                  const secondIndex = findFirstMatch(data.savvy.internalDashboard, searchMenu).secondIndex;
                  if (key < data?.savvy?.internalDashboard.groups.length / 2) {
                    if (searchMenu && !group.items.filter((item: any) => item.name.toLowerCase().includes(searchMenu.toLowerCase())).length)
                      return null;
                    return (
                      <Box key={key}>
                        <Typography
                          variant="h3"
                          sx={{
                            color: 'error.main',
                          }}
                        >
                          {group.name}
                        </Typography>
                        <Box py={1.5}>
                          {group.items.map((item: any, key1: number) => {
                            if (searchMenu && !item.name.toLowerCase().includes(searchMenu.toLowerCase())) return null;
                            if (firstIndex === key && secondIndex === key1) {
                              return (
                                <Box
                                  key={key1}
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <Box
                                    sx={{
                                      backgroundColor: 'rgba(230, 27, 35, 0.1)',
                                      py: 0.5,
                                    }}
                                  >
                                    <Link
                                      href={item.link}
                                      onClick={(e: any) => {
                                        if (item.isInternal) {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          ctrlCmdClick(e, item.link, history);
                                        } else {
                                          window.location.href = item.link;
                                        }
                                      }}
                                    >
                                      {item.name}
                                    </Link>
                                  </Box>
                                  <SubdirectoryArrowLeftIcon />
                                </Box>
                              );
                            }
                            return (
                              <Box key={key1}>
                                <Link
                                  href={item.link}
                                  onClick={(e: any) => {
                                    if (item.isInternal) {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      ctrlCmdClick(e, item.link, history);
                                    } else {
                                      window.location.href = item.link;
                                    }
                                  }}
                                >
                                  {item.name}
                                </Link>
                              </Box>
                            );
                          })}
                        </Box>
                      </Box>
                    );
                  } else return null;
                })}
              </Box>
              <Box
                px={1}
                sx={{
                  width: '50%',
                }}
              >
                {data?.savvy?.internalDashboard.groups?.map((group: any, key: number) => {
                  const firstIndex = findFirstMatch(data.savvy.internalDashboard, searchMenu).firstIndex;
                  const secondIndex = findFirstMatch(data.savvy.internalDashboard, searchMenu).secondIndex;
                  if (key >= data?.savvy?.internalDashboard.groups.length / 2) {
                    if (searchMenu && !group.items.filter((item: any) => item.name.toLowerCase().includes(searchMenu.toLowerCase())).length)
                      return null;
                    return (
                      <Box key={key}>
                        <Typography
                          variant="h3"
                          sx={{
                            color: 'error.main',
                          }}
                          gutterBottom
                        >
                          {group.name}
                        </Typography>
                        <Box py={1.5}>
                          {group.items.map((item: any, key1: number) => {
                            if (searchMenu && !item.name.toLowerCase().includes(searchMenu.toLowerCase())) return null;
                            if (firstIndex === key && secondIndex === key1) {
                              return (
                                <Box
                                  key={key1}
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <Box
                                    sx={{
                                      backgroundColor: 'rgba(230, 27, 35, 0.1)',
                                      py: 0.5,
                                    }}
                                  >
                                    <Link
                                      href={item.link}
                                      onClick={(e: any) => {
                                        if (item.isInternal) {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          ctrlCmdClick(e, item.link, history);
                                        } else {
                                          window.location.href = item.link;
                                        }
                                      }}
                                    >
                                      {item.name}
                                    </Link>
                                  </Box>
                                  <SubdirectoryArrowLeftIcon />
                                </Box>
                              );
                            }
                            return (
                              <Box key={key1}>
                                <Link
                                  href={item.link}
                                  onClick={(e: any) => {
                                    if (item.isInternal) {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      ctrlCmdClick(e, item.link, history);
                                    } else {
                                      window.location.href = item.link;
                                    }
                                  }}
                                >
                                  {item.name}
                                </Link>
                              </Box>
                            );
                          })}
                        </Box>
                      </Box>
                    );
                  } else return null;
                })}
              </Box>
            </Box>
          )}
        </ComponentLoading>
      </Box>
    </Box>
  );
};

export default InternalDashboard;
