import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { DataGridPro, GridValueGetterParams } from '@mui/x-data-grid-pro';

import { LayoutDashboard, PageLoading, CustomGridToolbar } from 'components';

import { QUERY_COHORTS } from 'gql';
import { useQuery } from '@apollo/client';
import { sortCohortsByAircraftName } from 'helpers';

import { setAlert } from 'state';

const ClientCohorts: React.FC = () => {
  const {
    data: dataCohorts,
    error: errorCohorts,
    loading: loadingCohorts,
  } = useQuery(QUERY_COHORTS, {
    fetchPolicy: 'cache-and-network',
  });
  const dispatch = useDispatch();

  const [pageSize, setPageSize] = useState(25);

  const [filterButtonEl, setFilterButtonEl] = useState<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (errorCohorts) {
      dispatch(setAlert('error', 'Unable to load cohorts'));
    }
  }, [errorCohorts, dispatch]);

  useEffect(() => {
    if (dataCohorts?.cohorts?.length) {
      setPageSize(dataCohorts.cohorts.length);
    }
  }, [dataCohorts]);

  return (
    <LayoutDashboard backgroundColor="#fff">
      {loadingCohorts && <PageLoading />}
      <Box p={4}>
        <Typography variant="h2" gutterBottom>
          Supported Cohorts for Report Cards and Trend Analysis
        </Typography>
        <Box py={1}>
          <Box py={1}>
            <DataGridPro
              sx={{
                width: '100%',
                minWidth: '100px',
                '& .MuiDataGrid-cell:focus': {
                  outline: 'none',
                },
                '& .MuiDataGrid-row': {
                  cursor: 'pointer',
                  borderBottom: '1px solid',
                  borderColor: 'grey.200',
                },
                '& .MuiDataGrid-columnHeader:focus': {
                  outline: 'none',
                },
                '& .MuiDataGrid-toolbarContainer .MuiTextField-root': {
                  mb: 0,
                },
                border: 'none',
              }}
              autoHeight
              disableSelectionOnClick
              rowsPerPageOptions={dataCohorts?.cohorts?.length ? [5, 10, 25, dataCohorts.cohorts.length] : [5, 10, 25, 100]}
              pageSize={pageSize}
              onPageSizeChange={(newPage) => {
                setPageSize(newPage);
              }}
              pagination
              showColumnRightBorder={false}
              disableColumnSelector
              disableColumnMenu
              rows={dataCohorts?.cohorts?.length ? sortCohortsByAircraftName(dataCohorts.cohorts) : []}
              columns={[
                {
                  field: 'id',
                  headerName: 'ID',
                  flex: 0.3,
                },
                {
                  field: 'aircraftModel',
                  headerName: 'Aircraft',
                  valueGetter: (params: GridValueGetterParams) => {
                    if (!params?.value) return '';
                    return `${params.value.manufacturer.name} ${params.value.name}`;
                  },
                  flex: 1,
                },
                {
                  field: 'engineModel',
                  headerName: 'Engine',
                  valueGetter: (params: GridValueGetterParams) => {
                    if (!params?.value) return '';
                    return `${params.value.manufacturer.name} ${params.value.name}`;
                  },
                  flex: 1,
                },
              ]}
              components={{ Toolbar: CustomGridToolbar }}
              componentsProps={{
                toolbar: {
                  setFilterButtonEl,
                },
                filterPanel: {
                  sx: {
                    '& .MuiDataGrid-filterForm': {
                      '& .MuiTextField-root': {
                        mb: 0,
                      },
                    },
                  },
                },
                panel: {
                  anchorEl: filterButtonEl,
                  placement: 'bottom-end',
                },
              }}
            />
          </Box>
        </Box>
      </Box>
    </LayoutDashboard>
  );
};

export default ClientCohorts;
