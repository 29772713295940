import { Redirect, Route } from 'react-router-dom';

import { useSelector } from 'react-redux';

import { PageLoading } from 'components';
import { PATH_SIGNUP_COMPLETE_ADDITIONAL_INFO } from 'const';

interface IRedirectLoggedIn {
  component: any;
  path: string;
  exact: boolean;
}

// Redirect to dashboard for logged IN
const RedirectStepLoggedIn = (props: IRedirectLoggedIn) => {
  const { component: Component, ...rest } = props; // Get Component user try access
  const { loggedIn, incompleteAircraft } = useSelector((state: any) => state.auth); // Get auth state (Redux)

  // If loading
  if (loggedIn === null) return <PageLoading />;

  if (loggedIn && incompleteAircraft?.length > 0) {
    if (incompleteAircraft[0]?.subscriptions?.length) {
      return <Redirect to={`/signup/${incompleteAircraft[0].subscriptions[0].serviceCode}/additional-info`} />;
    } else {
      return <Redirect to={PATH_SIGNUP_COMPLETE_ADDITIONAL_INFO} />;
    }
  } else if (loggedIn) {
    window.location.href = '/';
    return null;
  }

  // If logged OUT - proceed to page
  return <Route render={() => <Component />} {...rest} />;
};

export default RedirectStepLoggedIn;
