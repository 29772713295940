import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';

import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';

import { ISerieData, IChartVisibleSetting, IAircraftSetting, IFlightChartSetting, IFlightData, IUnitConversion } from 'types';
import { CHART_VISIBLE_NORMAL, CHART_VISIBLE_BOLD, CHART_VISIBLE_HIDE, CHART_VISIBLE_ISOLATE, MIN_CHART_HEIGHT } from 'const';

import { SET_HOVER_POINT } from 'state/types';
import { getSerieColor } from 'helpers';

import TooltipSync from './TooltipSync';
import ZoomSync from './ZoomSync';
import ModeManager from './ModeManager';
import GamiManager from './GamiManager';

interface ChartProps {
  seriesData: ISerieData[];
  chartSettings: IChartVisibleSetting[];
  leftSerieName: string;
  secondarySeriesData: ISerieData[];
  chartSecondarySettings: IChartVisibleSetting[];
  rightSerieName: string;
  timeSeries: number[];
  zoomSync: ZoomSync;
  modeManager: ModeManager;
  setDiffData: (value: any) => void;
  gamiManager?: GamiManager;
  aircraftSetting: IAircraftSetting | undefined;
  aircraftSecondarySetting: IAircraftSetting | undefined;
  settings: IFlightChartSetting | undefined;
  flightData: IFlightData;
  isGami?: boolean;
  leftConversion: IUnitConversion | undefined;
  rightConversion: IUnitConversion | undefined;
  isMag?: boolean;
}

Highcharts.setOptions({
  lang: {
    thousandsSep: '',
  },
});

const tooltipSync = new TooltipSync();

function tooltipFormatter(this: any) {
  let result = "<span style='color: black;'>" + Highcharts.dateFormat('%H:%M:%S', this.x) + '</span><br/>';
  const compare = function (a: Highcharts.Point, b: Highcharts.Point) {
    return a.y !== undefined && b.y !== undefined ? b.y - a.y : -1;
  };
  this.points.sort(compare);
  for (const i in this.points) {
    if (!this.points[i]) continue;

    const series = this.points[i].series;
    const color = this.points[i].series.color;
    const units = ''; // unitsForSeriesName(series.name);

    if (series.visible)
      result =
        result +
        `<span style='color: ${color};'>●</span>&nbsp;` +
        '<span>' +
        series.name +
        '</span>: <b>' +
        this.points[i].y +
        units +
        '</b>';

    if (series.visible) result = result + '<br/>';
  }

  return result;
}

const Chart: React.FC<ChartProps> = (props) => {
  const {
    seriesData,
    chartSettings,
    leftSerieName,
    secondarySeriesData,
    chartSecondarySettings,
    rightSerieName,
    timeSeries,
    zoomSync,
    modeManager,
    setDiffData,
    gamiManager,
    aircraftSetting,
    aircraftSecondarySetting,
    settings,
    flightData,
    isGami = false,
    isMag = false,
    leftConversion,
    rightConversion,
  } = props;

  const dispatch = useDispatch();
  const { isSavvy } = useSelector((state: any) => state.auth);

  if (timeSeries.length) {
    // zoomSync.xAxis_data = [...timeSeries];
    const tmpSerie: number[] = [];

    for (let i = 0; i < timeSeries.length; i++) {
      tmpSerie.push((timeSeries[i] - timeSeries[0]) * 1000);
    }

    zoomSync.xAxis_data = [...tmpSerie];
    tooltipSync.timeSeries = [...tmpSerie];
  }

  const getLineWidth = (name: string, chartSettings: IChartVisibleSetting[]) => {
    const Line = chartSettings.filter((chartSetting: IChartVisibleSetting) => chartSetting.name === name);

    if (Line[0]) {
      if (Line[0].value === CHART_VISIBLE_NORMAL) {
        return 2;
      } else if (Line[0].value === CHART_VISIBLE_BOLD) {
        return 4;
      } else if (Line[0].value === CHART_VISIBLE_HIDE) {
        return 0;
      }
    }

    return 2;
  };

  const getChartHeight = (size: string, chartNum: number) => {
    let height = 0;

    const chartContainer = document.getElementById('chart-container');

    const controllerHeight = 80 * chartNum; // 80 is height of apperance set

    if (size === 'fitPage') {
      height = (window.innerHeight - (chartContainer ? chartContainer.offsetTop : 0) - controllerHeight) / chartNum;
    } else if (size === 'fitChart') {
      height = (window.innerHeight - controllerHeight) / chartNum;
    } else if (size === 'tall') {
      height = 800;
    } else if (size === 'medium') {
      height = 600;
    } else {
      height = MIN_CHART_HEIGHT;
    }

    height = Math.max(height - 16, MIN_CHART_HEIGHT);
    return height;
  };

  const includesIsolate = (data: IChartVisibleSetting[], name: string) => {
    if (!data.length) {
      return true;
    }
    for (let i = 0; i < data.length; i++) {
      if (data[i].name === name) {
        return true;
      }
    }

    return false;
  };

  const getSeries = (data: ISerieData[]) => {
    if (!data) return [];
    const existsIsolate = chartSettings.filter((chartSetting: IChartVisibleSetting) => chartSetting.value === CHART_VISIBLE_ISOLATE);

    const tmp = data.map((value: ISerieData) => {
      const tmpSeries: number[][] = [];

      for (let i = 0; i < value.series?.length; i++) {
        tmpSeries.push([
          (timeSeries[i] - timeSeries[0]) * 1000,
          leftConversion ? Math.floor((value.series[i] * leftConversion.bParam + leftConversion.aParam) * 10) / 10 : value.series[i],
        ]);
      }

      if (leftConversion && gamiManager) {
        for (let i = 0; i < gamiManager.originalData.length; i++) {
          if (gamiManager.originalData[i].name === value.name) {
            gamiManager.originalData[i].data = tmpSeries;
          }
        }
      }

      zoomSync.addSeries(value.name, tmpSeries);

      const { data: filterData } = zoomSync.downSample(value.name);

      return {
        type: 'line',
        name: value.name,
        data: filterData,
        marker: {
          enabled: false,
        },
        visible: getLineWidth(value.name, chartSettings) === 0 ? false : includesIsolate(existsIsolate, value.name),
        lineWidth: getLineWidth(value.name, chartSettings),
        yAxis: 0,
        color: getSerieColor(value.name),
      };
    });

    return tmp;
  };

  const getSecondarySeries = (data: ISerieData[]) => {
    if (!data) return [];

    const existsIsolate = chartSecondarySettings.filter(
      (chartSetting: IChartVisibleSetting) => chartSetting.value === CHART_VISIBLE_ISOLATE,
    );

    const tmp = data.map((value: ISerieData) => {
      const tmpSeries: number[][] = [];

      for (let i = 0; i < value.series.length; i++) {
        tmpSeries.push([
          (timeSeries[i] - timeSeries[0]) * 1000,
          rightConversion ? Math.floor((value.series[i] * rightConversion.bParam + rightConversion.aParam) * 10) / 10 : value.series[i],
        ]);
      }

      if (rightConversion && gamiManager) {
        for (let i = 0; i < gamiManager.originalData.length; i++) {
          if (gamiManager.originalData[i].name === value.name) {
            gamiManager.originalData[i].data = tmpSeries;
          }
        }
      }

      zoomSync.addSeries(value.name, tmpSeries);
      const { data: filterData } = zoomSync.downSample(value.name);

      return {
        type: 'line',
        name: value.name,
        data: filterData,
        marker: {
          enabled: false,
        },
        visible: getLineWidth(value.name, chartSecondarySettings) === 0 ? false : includesIsolate(existsIsolate, value.name),
        lineWidth: getLineWidth(value.name, chartSecondarySettings),
        yAxis: 1,
        color: getSerieColor(value.name),
      };
    });

    return tmp;
  };

  type RefObjectForHighchartsReact = {
    chart: Highcharts.Chart;
    container: React.RefObject<HTMLDivElement>;
  };

  const chartComponentRef = useRef<RefObjectForHighchartsReact>(null);

  const addChtWarningTemparatureLine = (axis: Highcharts.Axis | undefined, value: number) => {
    if (!axis) {
      return;
    }
    axis.addPlotLine({
      value,
      color: 'red',
      width: 3,
      id: 'cht-limit',
      zIndex: 5,
    });
  };

  const options = {
    colors: ['#7cb5ec', '#434348', '#90ed7d', '#f7a35c', '#8085e9', '#f15c80', '#b6a83f', '#2b908f', '#f45b5b', '#91e8e1'],
    chart: {
      alignTricks: false,
      type: 'line',
      animation: false,
      zoomType: 'x',
      resetZoomButton: {
        position: {
          y: -100,
        },
      },
      panning: {
        enabled: true,
        type: 'x',
      },
      panKey: 'shift',
      marginRight: 70,
      marginLeft: 70,
      events: {
        redraw: function () {
          if (!chartComponentRef.current?.chart) {
            return;
          }
          zoomSync.noteZoomLevels(chartComponentRef.current?.chart);
          gamiManager?.redrawGamiLines(chartComponentRef.current?.chart);
          chartComponentRef.current?.chart?.yAxis[0]?.removePlotLine('cht-limit');
          chartComponentRef.current?.chart?.yAxis[1]?.removePlotLine('cht-limit');
          if (leftSerieName === 'L-CHT' || leftSerieName === 'R-CHT' || leftSerieName === 'CHT') {
            if (flightData.aircraft.chtWarningTemperature) {
              addChtWarningTemparatureLine(chartComponentRef.current?.chart?.yAxis[0], flightData.aircraft.chtWarningTemperature);
            }
          }

          if (rightSerieName === 'L-CHT' || rightSerieName === 'R-CHT' || rightSerieName === 'CHT') {
            if (flightData.aircraft.chtWarningTemperature) {
              addChtWarningTemparatureLine(chartComponentRef.current?.chart?.yAxis[1], flightData.aircraft.chtWarningTemperature);
            }
          }

          if (modeManager._minMax) {
            modeManager.drawMinMax();
          }
        },
        click: (event: any) => {
          tooltipSync.chartClick(event, modeManager, gamiManager);
          if (tooltipSync.diff_data.selectedPoint) {
            setDiffData({ ...tooltipSync.diff_data });
          } else {
            setDiffData({});
          }
        },
        mouseOut: () => {
          dispatch({
            type: SET_HOVER_POINT,
            payload: {
              hoverPointIndex: -1,
            },
          });
        },
      },
      height: isGami
        ? getChartHeight('fitChart', 1)
        : isMag
          ? getChartHeight('fitChart', 2)
          : getChartHeight(
              settings?.chartsSize ? settings.chartsSize : 'fitPage',
              settings?.chartsNumber ? parseInt(settings?.chartsNumber) : 2,
            ),
    },
    title: {
      text: '',
    },
    xAxis: {
      type: 'datetime',
      dateTimeLabelFormats: {
        day: '%H:%M',
      },
      crosshair: true,
      events: {
        setExtremes: function (e: any) {
          if (!chartComponentRef.current?.chart) {
            return;
          }
          zoomSync.chartZoomed.call(zoomSync, chartComponentRef.current?.chart, e.min, e.max);
        },
      },
    },
    yAxis: [
      {
        startOnTick: false,
        endOnTick: false,
        title: {
          text: leftSerieName,
        },
        min: !isSavvy ? (aircraftSetting ? aircraftSetting.scaleMin : null) : null,
        max: !isSavvy ? (aircraftSetting ? aircraftSetting.scaleMax : null) : null,
      },
      {
        startOnTick: false,
        endOnTick: false,
        opposite: true,
        gridLineWidth: 0,
        title: {
          text: rightSerieName,
        },
        min: !isSavvy ? (aircraftSecondarySetting ? aircraftSecondarySetting.scaleMin : null) : null,
        max: !isSavvy ? (aircraftSecondarySetting ? aircraftSecondarySetting.scaleMax : null) : null,
      },
    ],
    plotOptions: {
      line: {
        lineWidth: 2.5,
        dataLabels: {
          color: '#CCC',
        },
        shadow: false,
        marker: {
          enabled: false,
        },
      },
      series: {
        point: {
          events: {
            mouseOver: (event: any) => {
              if (event.target.series) {
                tooltipSync.mouseOver.call(tooltipSync, event.target.series.chart, event.target.x);
                // setHoverChartPoint(tooltipSync.hoverPointIndex);
                dispatch({
                  type: SET_HOVER_POINT,
                  payload: {
                    hoverPointIndex: tooltipSync.hoverPointIndex,
                  },
                });
              }
            },
            click: (event: any) => {
              tooltipSync.chartClick(event, modeManager, gamiManager);
              if (tooltipSync.diff_data.selectedPoint) {
                setDiffData({ ...tooltipSync.diff_data });
              } else {
                setDiffData({});
              }
            },
          },
        },
        animation: false,
        states: {
          hover: {
            enabled: false,
          },
        },
      },
    },
    tooltip: {
      shared: true,
      useHTML: true,
      formatter: tooltipFormatter,
      followTouchMove: true,
    },
    credits: {
      href: 'https://www.savvyanalysis.com/',
      text: 'savvyanalysis.com',
    },
    legend: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    series: [...getSeries(seriesData), ...getSecondarySeries(secondarySeriesData)],
  };

  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      <HighchartsReact highcharts={Highcharts} options={options} allowChartUpdate={true} immutable={false} ref={chartComponentRef} />
    </Box>
  );
};

export default Chart;
