import React, { useState } from 'react';

import { Box, Grid, Typography } from '@mui/material';

import { Subsection } from 'components';

import { AppBarLogoOnly, Content, Footer } from 'components/Layout/components';

import { useMdUp } from 'hooks';

import { SignupServiceAdditionalInfoForm } from './components';

const SignupCompleteAdditionalInfo: React.FC = () => {
  const isMdUp = useMdUp();
  const [registration, setRegistration] = useState('');

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <AppBarLogoOnly />
      <Content>
        <Grid container spacing={isMdUp ? 4 : 0}>
          <Grid item xs={false} md={3}></Grid>
          <Grid item xs={12} md={6}>
            <Subsection>
              <Typography variant="h1" align="center" gutterBottom>
                Complete your profile{registration ? ` (${registration})` : ''}
              </Typography>
            </Subsection>

            <Grid container justifyContent="center">
              <Grid item xs={12} md={10} lg={11} xl={10}>
                <SignupServiceAdditionalInfoForm setRegistration={setRegistration} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Content>
      <Footer />
    </Box>
  );
};

export default SignupCompleteAdditionalInfo;
