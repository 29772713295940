import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Dialog, DialogTitle, DialogContent, Box, Typography, Button } from '@mui/material';
import { ComponentLoading } from 'components';

import { useMutation } from '@apollo/client';
import { MUTATION_DELETE_TAG } from 'gql';
import { setAlert } from 'state';

interface ConfirmDeleteTagModalProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  tagId: string;
  tagName: string;
}

const ConfirmDeleteTagModal: React.FC<ConfirmDeleteTagModalProps> = (props) => {
  const { open, setOpen, tagId, tagName } = props;

  const dispatch = useDispatch();

  const [deleteTagMutation, { data: dataDeleteTag, error: errorDeleteTag, loading: loadingDeleteTag }] = useMutation(MUTATION_DELETE_TAG);

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async () => {
    if (!tagId) return;

    await deleteTagMutation({
      variables: {
        ticketTagId: tagId,
      },
    });
  };

  useEffect(() => {
    if (errorDeleteTag) {
      dispatch(setAlert('error', 'Unable to delete selected tag'));
    } else if (dataDeleteTag) {
      if (dataDeleteTag.deleteTicketTag?.ok) {
        dispatch(setAlert('success', 'Deleted Tag'));
        setOpen(false);
      } else {
        dispatch(setAlert('error', dataDeleteTag.deleteTicketTag?.error || 'Unable to delete selected tag'));
      }
    }
  }, [errorDeleteTag, dataDeleteTag, dispatch, setOpen]);

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={handleClose}
      PaperProps={{
        sx: {
          maxWidth: '440px',
        },
      }}
    >
      <DialogTitle
        sx={{
          textAlign: 'center',
          fontSize: '24px',
          fontWeight: 700,
        }}
      >
        Delete Tag
      </DialogTitle>
      <DialogContent
        sx={{
          px: {
            md: 5,
            xs: 2,
          },
        }}
      >
        <ComponentLoading loading={loadingDeleteTag}>
          <Box>
            <Typography
              variant="body1"
              textAlign="center"
              sx={{
                fontSize: '16px',
                lineHeight: '23px',
              }}
            >
              Are you sure you want to delete this tag?
            </Typography>
            <Typography
              variant="body1"
              textAlign="center"
              gutterBottom
              sx={{
                lineHeight: '23px',
              }}
            >
              Current Tag: {tagName}
            </Typography>
            <Box
              sx={{
                textAlign: 'center',
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  width: '100px',
                }}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                variant="contained"
                sx={{
                  width: '100px',
                  ml: 2,
                }}
                onClick={() => {
                  onSubmit();
                }}
              >
                Confirm
              </Button>
            </Box>
          </Box>
        </ComponentLoading>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmDeleteTagModal;
