import React, { useState } from 'react';
import { Box, Collapse, IconButton } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { ITicket } from 'types';

import TicketInfoBox from './TicketInfoBox';

interface TicketInfoProps {
  ticket: ITicket | undefined;
  closeTicket?: (id: number, close: boolean) => void;
}

const TicketInfo: React.FC<TicketInfoProps> = (props) => {
  const { ticket = undefined, closeTicket } = props;

  const [leftSlideOn, setLeftSlideOn] = useState(true);

  if (!ticket) return null;

  return (
    <Box
      sx={{
        background: '#FFFFFF',
        position: 'relative',
        minHeight: {
          md: 'inherit',
          lg: 'calc(100vh - 75px)',
        },
        borderLeft: '1px solid',
        borderLeftColor: 'grey.200',
        '@media (min-width: 1350px)': {
          maxWidth: '400px',
        },
        '@media (min-width: 1450px)': {
          maxWidth: '450px',
        },
      }}
    >
      <Collapse
        in={leftSlideOn}
        orientation="horizontal"
        sx={{
          minWidth: '400px',
        }}
      >
        <TicketInfoBox ticket={ticket} closeTicket={closeTicket} />
      </Collapse>
      <IconButton
        sx={{
          position: 'absolute',
          left: 0,
          top: '50%',
          transform: 'translate(-50%, -50%)',
          '&:hover': {
            backgroundColor: 'background.default',
          },
          borderRadius: '6px',
          border: '1px solid',
          borderColor: 'grey.200',
          backgroundColor: 'background.default',
          zIndex: 999,
        }}
        onClick={() => {
          setLeftSlideOn(!leftSlideOn);
        }}
      >
        {leftSlideOn ? <ChevronRightIcon /> : <ChevronLeftIcon />}
      </IconButton>
    </Box>
  );
};

export default TicketInfo;
