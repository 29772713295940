import React from 'react';

import { Box, IconButton, Typography } from '@mui/material';
import { GridSelectionModel, GridFooterContainer, GridPagination, GridRowId } from '@mui/x-data-grid-pro';
import DeleteIcon from '@mui/icons-material/Delete';

interface CustomGridSelectionFooterProps {
  selection: GridSelectionModel;
  setSelection: (value: GridSelectionModel) => void;
  fullData: GridRowId[];
  pageSize: number;
  deleteAction?: (value: GridSelectionModel) => void;
  showDelete?: boolean;
}

const CustomGridSelectionFooter: React.FC<CustomGridSelectionFooterProps> = (props) => {
  const { selection, setSelection, fullData, pageSize, deleteAction, showDelete = true } = props;

  return (
    <GridFooterContainer
      sx={{
        justifyContent: selection.length ? 'space-between' : 'flex-end',
      }}
    >
      {!!selection.length && (
        <Box
          py={1}
          sx={{
            display: 'flex',
            alignItems: 'center',
            pl: 2,
          }}
        >
          <Typography>
            Selected {selection.length} {selection.length > 1 ? 'rows' : 'row'} of {fullData.length}.
          </Typography>
          {selection.length === pageSize && selection.length !== fullData.length && (
            <Typography
              onClick={() => {
                setSelection(fullData);
              }}
              sx={{
                ml: 1,
                cursor: 'pointer',
                textDecoration: 'underline',
              }}
            >
              Select All?
            </Typography>
          )}
          {selection.length === fullData.length && (
            <Typography
              onClick={() => {
                setSelection([]);
              }}
              sx={{
                ml: 1,
                cursor: 'pointer',
                textDecoration: 'underline',
              }}
            >
              Deselect All?
            </Typography>
          )}
          {showDelete && (
            <IconButton
              sx={{
                ml: 1,
              }}
              onClick={async () => {
                if (deleteAction) {
                  await deleteAction(selection);
                }
              }}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </Box>
      )}
      <GridPagination />
    </GridFooterContainer>
  );
};

export default CustomGridSelectionFooter;
