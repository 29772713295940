import { useState, useEffect } from 'react';

import { useLazyQuery } from '@apollo/client';
import { QUERY_FUZZY_AIRCRAFT_MODELS } from 'gql';

import { IOptionIdName } from 'types';

import { isEmpty } from 'helpers';

interface IUseFuzzyModels {
  model: IOptionIdName; // Model Autocomplete value
  manufacturerId: string; // Used to get models by manufacturer id
  modelInput: string; // Input value (text field value which triggers re-fetch sugestions)
}

// Get Aircraft Model Suggestions (fuzzy). When type in input
const useFuzzyModels = (props: IUseFuzzyModels) => {
  const { model, manufacturerId, modelInput } = props;

  // Suggestions Initial State
  const [fuzzy, setFuzzy] = useState<IOptionIdName[]>([]);

  // GraphQL Definition
  const [refetch, { data, error }] = useLazyQuery(QUERY_FUZZY_AIRCRAFT_MODELS, {
    fetchPolicy: 'no-cache',
  });

  // Refetch if Error
  error && refetch();

  // Response
  useEffect(() => {
    // Success
    data && setFuzzy(data?.fuzzyAircraftModels); // Set suggestions array - id/name
  }, [data]);

  // Refetch Suggestions. When input changed
  useEffect(() => {
    // If input is empty or value was selected in model (model?.type is undefined)
    if (isEmpty(modelInput) || modelInput === '-- Add your own --') {
      return setFuzzy([]); // Clean suggestions and stop (return) (do NOT refetch)
    }
    refetch({
      variables: {
        search: modelInput,
        manufacturerId: isEmpty(manufacturerId) ? 100000 : manufacturerId, // If id is empty (autocomplete can be null) use dummy number to prevent infinite GraphQL error. ID is required
      },
    });
  }, [model, modelInput, refetch, manufacturerId]);

  return fuzzy;
};

export default useFuzzyModels;
