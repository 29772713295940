import { SET_ANALYSIS_TAB } from 'state/types';

// Initial state (user data)
const initialState = {
  isAnalysisTab: true,
};

// Reducer
const mapReducer = (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    // Login
    case SET_ANALYSIS_TAB:
      return {
        isAnalysisTab: payload.isAnalysisTab,
      };
    default:
      return state;
  }
};

export default mapReducer;
