import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Dialog, DialogContent, DialogActions, Typography, Box, TextField, Grid, Button, MenuItem, Link } from '@mui/material';

import { setAlert } from 'state';

import { MUTATION_CREATE_BORESCOPE_IMAGE_SET } from 'gql';
import { useMutation } from '@apollo/client';
import { ComponentLoading } from 'components';
import { IOptionValueLabel, IBorescopeImageSet } from 'types';
import { enginesForBorescope } from 'api';
import { PATH_AIRCRAFT_VIEW, PATH_SAVVY_AIRCRAFT } from 'const';

interface AddBorescopeImageSetDialogProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  aircraftId: string;
  setBorescopeImageSet?: (value: IBorescopeImageSet | undefined) => void;
  setSlideNum?: (value: number) => void;
  setSuccess?: (value: boolean) => void;
  isSingle: boolean;
  cylinderCount: number | undefined;
  isShared?: boolean;
}

const AddBorescopeImageSetDialog: React.FC<AddBorescopeImageSetDialogProps> = (props) => {
  const { open, setOpen, aircraftId, setBorescopeImageSet, setSlideNum, setSuccess, isSingle, cylinderCount, isShared = false } = props;

  const [addBorescopeImageSet, { data: dataBorescopeImageSet, loading: loadingBorescopeImageSet, error: errorBorescopeImageSet }] =
    useMutation(MUTATION_CREATE_BORESCOPE_IMAGE_SET);

  const dispatch = useDispatch();
  const history = useHistory();
  const { isSavvy } = useSelector((state: any) => state.auth);

  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');

  const [engine, setEngine] = useState('');
  const [engineError, setEngineError] = useState('');

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async () => {
    let isFullFilled = true;

    if (!name) {
      isFullFilled = false;
      setNameError('Required');
    }

    if (!engine) {
      isFullFilled = false;
      setEngineError('Required');
    }

    if (!isFullFilled) {
      return;
    }

    if (!cylinderCount) {
      if (!isShared) {
        dispatch(
          setAlert(
            'warning',
            <Typography>
              Aircraft engine does not have the number of cylinders correctly set.{' '}
              <Link
                href={isSavvy ? `${PATH_SAVVY_AIRCRAFT}/${aircraftId}` : `${PATH_AIRCRAFT_VIEW}/${aircraftId}`}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setOpen(false);
                  history.push(`${isSavvy ? PATH_SAVVY_AIRCRAFT : PATH_AIRCRAFT_VIEW}/${aircraftId}`);
                }}
              >
                Click here to update
              </Link>
            </Typography>,
          ),
        );
      } else {
        dispatch(
          setAlert(
            'warning',
            'Aircraft engine does not have the number of cylinders correctly set. Please request owner to set cylinders.',
          ),
        );
      }
      return;
    }

    await addBorescopeImageSet({
      variables: {
        aircraftId,
        name,
        engine,
      },
    });
  };

  useEffect(() => {
    if (errorBorescopeImageSet) {
      dispatch(setAlert('error', 'Unable to create borescope image set'));
    } else if (dataBorescopeImageSet) {
      if (dataBorescopeImageSet.createBorescopeImageSet?.ok) {
        dispatch(setAlert('success', 'Created borescope image set!'));
        setBorescopeImageSet && setBorescopeImageSet(dataBorescopeImageSet.createBorescopeImageSet.borescopeImageSet);
        setSlideNum && setSlideNum(1);
        setSuccess && setSuccess(true);
        setOpen(false);
      } else {
        dispatch(setAlert('error', dataBorescopeImageSet.createBorescopeImageSet?.error || 'Unable to create borescope image set'));
      }
    }
  }, [errorBorescopeImageSet, dataBorescopeImageSet, dispatch]);

  useEffect(() => {
    if (open) {
      setName('');
      setNameError('');
      if (isSingle) {
        setEngine('S');
      } else {
        setEngine('');
      }
      setEngineError('');
    }
  }, [open, isSingle]);

  return (
    <Dialog open={open} fullWidth onClose={handleClose}>
      <DialogContent>
        <Typography variant="h2" gutterBottom align="center">
          Create Borescope Image Set
        </Typography>
        <ComponentLoading loading={loadingBorescopeImageSet}>
          <Box py={1}>
            <TextField
              label="Name"
              name="name"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
                setNameError('');
              }}
              error={!!nameError}
              helperText={nameError}
              sx={{
                mb: 0,
              }}
              FormHelperTextProps={{
                sx: {
                  ml: 0,
                },
              }}
              inputProps={{
                maxLength: 255,
              }}
              fullWidth
            />
          </Box>
          <Box py={1}>
            <TextField
              label="Engine"
              name="engine"
              value={engine}
              onChange={(e) => {
                setEngine(e.target.value);
                setEngineError('');
              }}
              error={!!engineError}
              helperText={engineError}
              sx={{
                mb: 0,
              }}
              FormHelperTextProps={{
                sx: {
                  ml: 0,
                },
              }}
              disabled={isSingle}
              select
              fullWidth
            >
              {enginesForBorescope.map((item: IOptionValueLabel, key: number) => {
                if (!isSingle && item.label === 'S') {
                  return null;
                }
                return (
                  <MenuItem key={key} value={item.label}>
                    {item.value}
                  </MenuItem>
                );
              })}
            </TextField>
          </Box>
        </ComponentLoading>
      </DialogContent>

      <DialogActions>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button variant="outlined" onClick={handleClose} disabled={loadingBorescopeImageSet} fullWidth>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="contained" onClick={onSubmit} disabled={loadingBorescopeImageSet} fullWidth>
              Submit
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default AddBorescopeImageSetDialog;
