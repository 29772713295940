import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, CircularProgress, TextField, Autocomplete } from '@mui/material';

import { useMutation } from '@apollo/client';
import { MUTATION_REASSIGN_TICKET } from 'gql';
import { IAssignee } from 'types';
import { setAlert } from 'state';

import { setContextInSentry } from 'helpers';

interface AddAssignPopupDialogProps {
  ticketId: string;
  allowedAssignees: IAssignee[];
  assignee: IAssignee | undefined;
}

const AddAssignPopupDialog: React.FC<AddAssignPopupDialogProps> = (props) => {
  const { ticketId, allowedAssignees, assignee } = props;

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const [searchText, setSearchText] = useState('');
  const [autocompleteValue, setAutocompleteValue] = useState<IAssignee | undefined>(assignee);

  const [addWathcerMutation, { data: dataAddAssigneeMutation, error: errorAddAssigneeMutation }] = useMutation(MUTATION_REASSIGN_TICKET);

  const addAssignee = async (contactId: string, ticketId: string) => {
    setLoading(true);
    setContextInSentry('reAssignTicket', {
      contactId,
      ticketId,
    });
    await addWathcerMutation({
      variables: {
        contactId,
        ticketId,
      },
    });

    setLoading(false);
  };

  useEffect(() => {
    if (errorAddAssigneeMutation) {
      dispatch(setAlert('error', 'Failed to re-assign ticket'));
    } else if (dataAddAssigneeMutation) {
      if (dataAddAssigneeMutation.reassignTicket?.ok) {
        dispatch(setAlert('success', 'Re-assigned ticket'));
      } else {
        dispatch(setAlert('error', dataAddAssigneeMutation.reassignTicket?.error || 'Failed to re-assign ticket'));
      }
    }
  }, [dataAddAssigneeMutation, errorAddAssigneeMutation, dispatch]);

  useEffect(() => {
    setAutocompleteValue(assignee);
  }, [assignee]);

  return (
    <Box>
      <Box py={1}>
        <Autocomplete
          options={allowedAssignees}
          isOptionEqualToValue={(option: IAssignee, value: IAssignee) => option?.id === value?.id}
          getOptionLabel={(option) => (option?.id ? `${option.fullNameWithNick}` : '')}
          value={autocompleteValue ? autocompleteValue : undefined}
          onChange={(event: any, value: any) => {
            if (value && value.id) {
              setAutocompleteValue(value);
              addAssignee(value.id, ticketId);
            }
          }}
          filterOptions={(options: IAssignee[], params: any) => {
            let filtered: IAssignee[] = [];
            if (params.inputValue !== '') {
              filtered = options.filter((option) => {
                const name = `${option.fullNameWithNick}`;
                return name.toLowerCase().includes(params.inputValue.trim().toLowerCase());
              });
            }
            return filtered;
          }}
          loading={loading}
          renderInput={(params) => (
            <TextField
              {...params}
              label={autocompleteValue ? 'Re-assign ticket' : 'Assign ticket'}
              placeholder="Type assignee's name"
              value={searchText}
              onChange={(e: any) => {
                setSearchText(e.target.value);
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
              sx={{
                mb: 0,
              }}
              fullWidth
            />
          )}
          renderOption={(props: any, option: IAssignee) => {
            return option?.id ? <li {...props} key={option.id}>{`${option.fullNameWithNick}`}</li> : null;
          }}
          disableClearable={true}
        />
      </Box>
    </Box>
  );
};

export default AddAssignPopupDialog;
