import React, { useState, useEffect } from 'react';
import { Dialog, Box, DialogContent, Button, DialogTitle, TextField } from '@mui/material';

import { ComponentLoading } from 'components';

interface RequestImageDialogProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  propsPost: string;
  requestUpdatedImageAction: (value: string) => void;
  loading: boolean;
}

const RequestImageDialog: React.FC<RequestImageDialogProps> = (props) => {
  const { open, setOpen, propsPost, requestUpdatedImageAction, loading } = props;

  const [message, setMessage] = useState('');
  const [messageError, setMessageError] = useState('');

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async () => {
    if (!message) {
      setMessageError('Required');
      return;
    }

    await requestUpdatedImageAction(message);
  };

  useEffect(() => {
    if (open) {
      setMessage(propsPost);
      setMessageError('');
    }
  }, [open, propsPost]);

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={handleClose}
      PaperProps={{
        sx: {
          maxWidth: '450px',
        },
      }}
    >
      <DialogTitle
        sx={{
          textAlign: 'center',
          fontSize: '24px',
          fontWeight: 700,
        }}
      >
        Request Updated Images
      </DialogTitle>
      <DialogContent>
        <ComponentLoading loading={loading}>
          <Box py={1}>
            <TextField
              name="message"
              label="Message"
              rows={5}
              value={message}
              onChange={(e) => {
                setMessage(e.target.value);
                setMessageError('');
              }}
              error={!!messageError}
              helperText={messageError}
              FormHelperTextProps={{
                sx: {
                  ml: 0,
                },
              }}
              multiline
              fullWidth
            />
          </Box>
          <Box
            py={1}
            sx={{
              textAlign: 'center',
            }}
          >
            <Button
              variant="contained"
              onClick={onSubmit}
              sx={{
                minWidth: '120px',
              }}
              data-testid="request_bad_image"
            >
              Request
            </Button>
          </Box>
        </ComponentLoading>
      </DialogContent>
    </Dialog>
  );
};

export default RequestImageDialog;
