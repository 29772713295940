import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, Box, Button, Autocomplete, TextField, Typography } from '@mui/material';
import { IExsitingFiles, ISavvyTicket, IAttachment } from 'types';
import { QUERY_SAVVY_TICKET_AIRCRAFT_ATTACHMENT } from 'gql';
import { useLazyQuery } from '@apollo/client';
import { ComponentLoading } from 'components';

interface AddExistingAttachmentsModalProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  ticket: ISavvyTicket | undefined;
  attachExistingFiles: IExsitingFiles[];
  setAttachExistingFiles: (value: IExsitingFiles[]) => void;
}

const AddExistingAttachmentsModal: React.FC<AddExistingAttachmentsModalProps> = (props) => {
  const { open, setOpen, ticket, attachExistingFiles, setAttachExistingFiles } = props;
  const [fetchAttachmentsList, { data: dataAttachmentsList, loading: loadingAttachmentsList }] = useLazyQuery(
    QUERY_SAVVY_TICKET_AIRCRAFT_ATTACHMENT,
    {
      fetchPolicy: 'cache-and-network',
    },
  );
  const [existingValues, setExistingValues] = useState<IAttachment[]>([]);
  const [existingFiles, setExistingFiles] = useState<IAttachment[]>([]);

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = () => {
    // setAttachExistingFiles([...existingValues]);
    for (let i = 0; i < existingValues.length; i++) {
      attachExistingFiles.push(existingValues[i]);
    }

    setAttachExistingFiles([...attachExistingFiles]);
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      setExistingValues([]);
      if (ticket?.id) {
        fetchAttachmentsList({
          variables: {
            ticketId: parseInt(ticket.id),
          },
        });
      }
    }
  }, [open, ticket]);

  useEffect(() => {
    if (dataAttachmentsList) {
      if (dataAttachmentsList?.me?.tickets[0]?.aircraft?.attachments?.length) {
        const sortedAttachments = [...dataAttachmentsList.me.tickets[0].aircraft.attachments].sort((a: IAttachment, b: IAttachment) => {
          return a.created > b.created ? -1 : 1;
        });
        setExistingFiles([...sortedAttachments]);
      } else if (ticket && ticket.posts.length) {
        const attachmentsTmp: IAttachment[] = [];

        for (let i = 0; i < ticket.posts.length; i++) {
          if (!ticket.posts[i].attachments) continue;

          if (ticket.posts[i].attachments?.length) {
            ticket.posts[i].attachments?.map((attachment: any) => {
              attachmentsTmp.push({
                ...attachment,
                postId: ticket.posts[i].id,
              });
              return null;
            });
          }
        }
        const sortedAttachments = attachmentsTmp.sort((a: IAttachment, b: IAttachment) => {
          return a.created > b.created ? -1 : 1;
        });

        setExistingFiles([...sortedAttachments]);
      }
    }
  }, [ticket, dataAttachmentsList]);

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={handleClose}
      PaperProps={{
        sx: {
          maxWidth: '440px',
        },
      }}
    >
      <DialogTitle
        sx={{
          textAlign: 'center',
          fontSize: '24px',
          fontWeight: 700,
          py: 1.5,
        }}
      >
        Select files...
      </DialogTitle>
      <DialogContent
        sx={{
          px: {
            md: 5,
            xs: 2,
          },
        }}
      >
        <ComponentLoading loading={loadingAttachmentsList}>
          <Box>
            <Box py={1}>
              {!!existingFiles.length && (
                <Autocomplete
                  multiple
                  options={existingFiles}
                  getOptionLabel={(option) => option.name}
                  value={existingValues}
                  isOptionEqualToValue={(option: IAttachment, value: IAttachment) => option.id === value.id}
                  onChange={(event: any, value: any) => {
                    setExistingValues([...value]);
                  }}
                  renderInput={(params) => <TextField {...params} label="Existing Files" />}
                  renderOption={(props: any, option: IAttachment) => {
                    if (attachExistingFiles.find((file: IExsitingFiles) => file.id === option.id)) {
                      return null;
                    }
                    return option?.id ? (
                      <li {...props} key={option.id}>
                        {option.name}
                        {option.description ? ` (${option.description})` : ''}
                      </li>
                    ) : null;
                  }}
                />
              )}
              {!existingFiles.length && (
                <Typography
                  align="center"
                  sx={{
                    color: 'error.main',
                  }}
                  gutterBottom
                >
                  This ticket has no existing attachments.
                </Typography>
              )}
            </Box>
            <Box
              sx={{
                textAlign: 'right',
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  width: '100px',
                }}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                variant="contained"
                sx={{
                  width: '100px',
                  ml: 2,
                }}
                onClick={() => {
                  onSubmit();
                }}
                disabled={!existingFiles.length}
              >
                Confirm
              </Button>
            </Box>
          </Box>
        </ComponentLoading>
      </DialogContent>
    </Dialog>
  );
};

export default AddExistingAttachmentsModal;
