import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button, TextField, MenuItem, Typography } from '@mui/material';
import { useMutation } from '@apollo/client';
import { MUTATION_CREATE_SERVICE_TAG } from 'gql';
import { IDropDownOption } from 'types';
import { setAlert } from 'state';

interface ServiceTagProps {
  handleClose: () => void;
  setLoading: (value: boolean) => void;
  ticketId: string;
  services: IDropDownOption[];
}

const ServiceTag: React.FC<ServiceTagProps> = (props) => {
  const { ticketId, setLoading, handleClose, services } = props;

  const [createServiceTag, { data: dataServiceTag, error: errorServiceTag, loading: loadingServiceTag }] =
    useMutation(MUTATION_CREATE_SERVICE_TAG);
  const dispatch = useDispatch();
  const [serviceId, setServiceId] = useState('');
  const [serviceError, setServiceError] = useState('');

  const onSubmit = async () => {
    if (!serviceId) {
      setServiceError('Please select service');
      return;
    }

    await createServiceTag({
      variables: {
        ticketId,
        serviceId,
      },
    });
  };

  useEffect(() => {
    if (errorServiceTag) {
      dispatch(setAlert('error', 'Unable to create service tag'));
    } else if (dataServiceTag && dataServiceTag.createServiceTag?.ok) {
      dispatch(setAlert('success', 'Created service tag'));
      handleClose();
    }
  }, [errorServiceTag, dataServiceTag, dispatch, handleClose]);

  useEffect(() => {
    setLoading(loadingServiceTag);
  }, [loadingServiceTag, setLoading]);

  return (
    <React.Fragment>
      {!!services?.length && (
        <Box py={1}>
          <TextField
            select
            label="Select Service"
            name="service"
            value={serviceId}
            onChange={(e) => {
              setServiceId(e.target.value);
              setServiceError('');
            }}
            fullWidth
            error={!!serviceError}
            helperText={serviceError ? serviceError : ''}
            FormHelperTextProps={{
              sx: {
                ml: 0,
              },
            }}
          >
            {services.map((service: IDropDownOption, key: number) => {
              return (
                <MenuItem key={key} value={service.id}>
                  {service.name}
                </MenuItem>
              );
            })}
          </TextField>
        </Box>
      )}
      {!services?.length && (
        <Typography
          align="center"
          sx={{
            color: 'error.main',
          }}
        >
          You can't assign service tag, because ticket aircraft doesn't have any services.
        </Typography>
      )}
      <Box
        sx={{
          textAlign: 'right',
        }}
        py={1}
      >
        <Button
          variant="outlined"
          sx={{
            width: '100px',
            mr: 2,
          }}
          onClick={handleClose}
        >
          Cancel
        </Button>
        {!!services?.length && (
          <Button
            color="primary"
            variant="contained"
            sx={{
              width: '100px',
            }}
            onClick={() => {
              onSubmit();
            }}
          >
            Create
          </Button>
        )}
      </Box>
    </React.Fragment>
  );
};

export default ServiceTag;
