import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Dialog, Box, DialogTitle, DialogContent, TextField, DialogActions, Grid, Button } from '@mui/material';
import { MUTATION_UPDATE_LOGBOOK } from 'gql';
import { useMutation } from '@apollo/client';
import { setAlert } from 'state';

import { ComponentLoading } from 'components';
import { ILogbook } from 'types';

interface EditLogbookDialogProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  logbook: ILogbook;
}

const EditLogbookDialog: React.FC<EditLogbookDialogProps> = (props) => {
  const { open, setOpen, logbook } = props;

  const [updateLogbook, { data: dataUpdateLogbook, error: errorUpdateLogbook, loading: loadingUpdateLogbook }] =
    useMutation(MUTATION_UPDATE_LOGBOOK);
  const dispatch = useDispatch();

  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async () => {
    if (!name) {
      setNameError('Please input logbook name.');
      return;
    }

    await updateLogbook({
      variables: {
        maintenanceLogbookId: logbook.id,
        name,
      },
    });
  };

  useEffect(() => {
    if (errorUpdateLogbook) {
      dispatch(setAlert('error', 'Unable to update logbook'));
    } else if (dataUpdateLogbook) {
      if (dataUpdateLogbook?.updateMaintenanceLogbook?.ok) {
        dispatch(setAlert('success', 'Updated logbook.'));
        setOpen(false);
      } else {
        dispatch(setAlert('error', dataUpdateLogbook?.updateMaintenanceLogbook?.error || 'Unable to update logbook'));
      }
    }
  }, [dataUpdateLogbook, errorUpdateLogbook, setOpen, dispatch]);

  useEffect(() => {
    if (open) {
      setName(logbook.name);
      setNameError('');
    }
  }, [open, logbook]);

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={handleClose}
      PaperProps={{
        sx: {
          maxWidth: '400px',
        },
      }}
    >
      <DialogTitle
        sx={{
          textAlign: 'center',
          fontSize: '24px',
          fontWeight: 700,
        }}
      >
        Update Logbook
      </DialogTitle>
      <DialogContent>
        <ComponentLoading loading={loadingUpdateLogbook}>
          <Box py={1}>
            <TextField
              name="logbook"
              label="Logbook Name *"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
                setNameError('');
              }}
              error={!!nameError}
              helperText={nameError}
              FormHelperTextProps={{
                sx: {
                  ml: 0,
                },
              }}
              fullWidth
            />
          </Box>
        </ComponentLoading>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button variant="outlined" onClick={handleClose} disabled={loadingUpdateLogbook} fullWidth>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="contained" onClick={onSubmit} disabled={loadingUpdateLogbook} fullWidth>
              Update
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default EditLogbookDialog;
