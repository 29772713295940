import React from 'react';
import { Box, List, ListItem, Typography } from '@mui/material';
import { IServiceCenter } from 'types';

interface ContainedPointsProps {
  points: IServiceCenter[];
  handleClickPoint: (value: string) => void;
  selectedSC?: IServiceCenter;
  selectedColor: string;
}

const ContainedPoints: React.FC<ContainedPointsProps> = (props) => {
  const { points, handleClickPoint, selectedSC = undefined, selectedColor } = props;

  return (
    <Box
      sx={{
        '@media (max-width: 600px)': {
          maxHeight: '40vh',
          overflowY: 'auto',
        },
        overflowX: 'hidden',
      }}
    >
      <List>
        {points?.map((point: IServiceCenter, key: number) => {
          if (selectedColor === 'all' || (point.flashColor && selectedColor.includes(point.flashColor.toLowerCase()))) {
            return (
              <ListItem
                key={key}
                sx={{
                  color: point.flashColor ? point.flashColor : 'white',
                  display: 'list-item',
                  listStyle: 'disc',
                  left: '20px',
                  cursor: 'pointer',
                  backgroundColor: point.id === selectedSC?.id ? 'rgba(63, 117, 254, 0.2)' : 'none',
                }}
                onClick={() => {
                  handleClickPoint(point.id);
                }}
              >
                <Typography variant="body1" color="textPrimary">
                  {point.company}({point.airportId})
                </Typography>
              </ListItem>
            );
          } else {
            return null;
          }
        })}
      </List>
    </Box>
  );
};

export default ContainedPoints;
