import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Dialog, Box, DialogTitle, DialogContent, TextField, DialogActions, Grid, Button } from '@mui/material';

import { MUTATION_UPDATE_ATTACHMENT_DESCRIPTION } from 'gql';
import { useMutation } from '@apollo/client';
import { setAlert } from 'state';

import { ComponentLoading } from 'components';

import { IAttachment } from 'types';

interface AttachmentDescriptionDialogProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  attachment: IAttachment | null;
}

const AttachmentDescriptionDialog: React.FC<AttachmentDescriptionDialogProps> = (props) => {
  const { open, setOpen, attachment } = props;

  const [updateDescription, { data: dataDescription, error: errorDescription, loading: loadingDescription }] = useMutation(
    MUTATION_UPDATE_ATTACHMENT_DESCRIPTION,
  );
  const dispatch = useDispatch();

  const [description, setDescription] = useState('');
  const [descriptionError, setDescriptionError] = useState('');
  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async () => {
    if (!description) {
      setDescriptionError('Please input description');
      return;
    }

    if (!attachment) return;

    await updateDescription({
      variables: {
        attachmentId: attachment.id,
        description,
      },
    });
  };

  useEffect(() => {
    if (open && attachment) {
      setDescription(attachment.description);
    }
  }, [attachment, open]);

  useEffect(() => {
    if (errorDescription) {
      dispatch(setAlert('error', 'Unable to update attachment description'));
    } else if (dataDescription) {
      if (dataDescription.updateAttachmentDescription?.ok) {
        dispatch(setAlert('success', 'Updated attachment description'));
        setOpen(false);
      } else {
        dispatch(setAlert('error', dataDescription.updateAttachmentDescription?.error || 'Unable to update attachment description'));
      }
    }
  }, [errorDescription, dataDescription, dispatch, setOpen]);

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={handleClose}
      PaperProps={{
        sx: {
          maxWidth: '400px',
        },
      }}
    >
      <DialogTitle
        sx={{
          textAlign: 'center',
          fontSize: '24px',
          fontWeight: 700,
        }}
      >
        Attachment description
      </DialogTitle>
      <DialogContent>
        <ComponentLoading loading={loadingDescription}>
          <Box py={1}>
            <TextField
              name="body"
              label="Description"
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
                setDescriptionError('');
              }}
              FormHelperTextProps={{
                sx: {
                  ml: 0,
                },
              }}
              error={!!descriptionError}
              helperText={descriptionError}
              multiline
              rows={5}
              fullWidth
            />
          </Box>
        </ComponentLoading>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button variant="outlined" onClick={handleClose} disabled={loadingDescription} fullWidth>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="contained" onClick={onSubmit} disabled={loadingDescription} fullWidth>
              Submit
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default AttachmentDescriptionDialog;
