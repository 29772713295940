import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Box, Typography, Link } from '@mui/material';

import { AppBarLoggedIn, Footer } from './components';

import { ILayout } from 'types';
import {
  AUTO_REFRESH_CHECK_TIME,
  CHECK_REFRESH_PATH,
  PATH_TICKETS,
  PATH_REPORTS_BETA,
  PATH_SAVVY_TICKETS,
  NOTIFICATION_BAR_ID,
} from 'const';

import { useMutation } from '@apollo/client';
import { MUTATION_RELEASE_HIJACK, MUTATION_RESEND_VERIFICATION_EMAIL } from 'gql';
import { setAlert } from 'state';

const LayoutDashboard: React.FC<ILayout> = (props) => {
  const { children, backgroundColor = '#F5F6FA', flexGrow = 0 } = props;

  const [releaseHijackMutation, { data }] = useMutation(MUTATION_RELEASE_HIJACK);
  const [resendEmailVerification, { data: dataEmailVerification, error: errorEmailVerification }] =
    useMutation(MUTATION_RESEND_VERIFICATION_EMAIL);

  const dispatch = useDispatch();
  const location = useLocation();

  const { isHijacked, firstName, lastName, emailVerified } = useSelector((state: any) => state.auth);

  const intervalRef = React.useRef<NodeJS.Timeout | null>(null);
  const [showUpdateNotify, setShowUpdateNotify] = useState(false);

  const releaseHijack = async () => {
    await releaseHijackMutation();
  };

  const resend = async () => {
    await resendEmailVerification();
  };

  useEffect(() => {
    if (data && data?.releaseHijack?.ok) {
      window.location.reload();
    }
  }, [data]);

  useEffect(() => {
    if (!intervalRef.current) {
      intervalRef.current = setInterval(async () => {
        await checkRefresh();
      }, AUTO_REFRESH_CHECK_TIME * 1000);
    }

    return () => {
      intervalRef.current && clearInterval(intervalRef.current);
    };
  }, []);

  useEffect(() => {
    if (errorEmailVerification) {
      dispatch(setAlert('error', 'Unable to resend verification email. Please refresh and try again.'));
    } else if (dataEmailVerification) {
      if (dataEmailVerification.resendVerificationEmail?.ok) {
        dispatch(setAlert('success', 'Sent verification email! Please check your email.'));
      } else {
        dispatch(setAlert('error', 'Unable to resend verification email. Please refresh and try again.'));
      }
    }
  }, [dataEmailVerification, errorEmailVerification, dispatch]);

  const checkRefresh = async () => {
    fetch('/')
      .then((response) => {
        return response.text();
      })
      .then((html) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        const pageScripts = doc.querySelectorAll('head script');
        let pageScript = '';
        if (pageScripts && pageScripts.length) {
          for (let i = 0; i < pageScripts.length; i++) {
            if ((pageScripts[i] as any).src?.includes(CHECK_REFRESH_PATH)) {
              pageScript = (pageScripts[i] as any).src;
            }
          }
        }

        const docScripts = document.querySelectorAll('head script');
        let docScript = '';
        if (docScripts && docScripts.length) {
          for (let i = 0; i < docScripts.length; i++) {
            if ((docScripts[i] as any).src?.includes(CHECK_REFRESH_PATH)) {
              docScript = (docScripts[i] as any).src;
            }
          }
        }

        if (pageScript && docScript && pageScript !== docScript) {
          setShowUpdateNotify(true);
        } else {
          setShowUpdateNotify(false);
        }
      })
      .catch((error) => {
        console.warn(error);
      });
  };

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <AppBarLoggedIn />
      <Box
        sx={{
          backgroundColor: backgroundColor,
          flexGrow: flexGrow,
        }}
      >
        <Box id={NOTIFICATION_BAR_ID}>
          {isHijacked && (
            <Box p={1}>
              <Box
                py={1.5}
                sx={{
                  backgroundColor: 'warning.light',
                  borderRadius: '5px',
                  margin: '0 auto',
                  width: {
                    md: '66%',
                    sm: '100%',
                  },
                  px: {
                    md: 2.5,
                    xs: 3.5,
                  },
                  position: 'relative',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: 500,
                    lineHeight: '22px',
                    color: 'warning.main',
                    '& a': {
                      color: 'warning.main',
                      textDecoration: 'underline',
                    },
                    textAlign: 'center',
                  }}
                >
                  Working on behalf of {firstName} {lastName}{' '}
                  <Box
                    component="span"
                    sx={{
                      textDecoration: 'underline',
                      ml: 2,
                      cursor: 'pointer',
                    }}
                    onClick={releaseHijack}
                  >
                    Release
                  </Box>
                </Typography>
              </Box>
            </Box>
          )}
          {!emailVerified && (
            <Box
              pt={5}
              pb={
                location?.pathname?.includes(PATH_TICKETS) ||
                location?.pathname?.includes(PATH_SAVVY_TICKETS) ||
                location?.pathname?.includes(PATH_REPORTS_BETA)
                  ? 5
                  : 0
              }
            >
              <Box
                py={1.5}
                sx={{
                  backgroundColor: 'error.main',
                  borderRadius: '5px',
                  margin: '0 auto',
                  width: {
                    md: '66%',
                    sm: '100%',
                  },
                  px: {
                    md: 2.5,
                    xs: 3.5,
                  },
                  position: 'relative',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: 500,
                    lineHeight: '22px',
                    color: 'background.default',
                    '& a': {
                      color: 'background.default',
                      textDecoration: 'underline',
                    },
                    textAlign: 'center',
                  }}
                >
                  Your email address has not been verified. Please check your email for instructions.
                  <Box
                    component="span"
                    sx={{
                      textDecoration: 'underline',
                      ml: 1,
                      cursor: 'pointer',
                    }}
                    onClick={resend}
                  >
                    Resend verification email
                  </Box>
                  .
                </Typography>
              </Box>
            </Box>
          )}
          {showUpdateNotify && (
            <Box p={1}>
              <Box
                py={1.5}
                sx={{
                  backgroundColor: 'warning.light',
                  borderRadius: '5px',
                  margin: '0 auto',
                  width: {
                    md: '66%',
                    sm: '100%',
                  },
                  px: {
                    md: 2.5,
                    xs: 3.5,
                  },
                  position: 'relative',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: 500,
                    lineHeight: '22px',
                    color: 'warning.main',
                    '& a': {
                      color: 'warning.main',
                      textDecoration: 'underline',
                    },
                    textAlign: 'center',
                  }}
                >
                  <Link
                    href={window.location.href}
                    onClick={(e: any) => {
                      e.preventDefault();
                      e.stopPropagation();
                      window.location.reload();
                    }}
                  >
                    A new version of SavvyAviation is available. Refresh the page to update.
                  </Link>
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
        {children}
      </Box>
      <Footer />
    </Box>
  );
};

export default LayoutDashboard;
