import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Typography, Hidden, IconButton, Link } from '@mui/material';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import FlightLandIcon from '@mui/icons-material/FlightLand';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

import Highcharts from 'highcharts';

import { useLazyQuery } from '@apollo/client';
import { QUERY_SHARED_FLIGHT_CHART_DETAIL_BY_ID } from 'gql';

import { humanDate, limitSerieSet, isGami, ctrlCmdClick } from 'helpers';
import { IFlightData, ISerieSet, ISerieData, IChartVisibleSetting, IAircraftSetting, IFlightChartSetting } from 'types';
import {
  CHART_VISIBLE_NORMAL,
  INFO_TAB_WIDTH,
  INFO_SAVVY_TAB_WIDTH,
  PATH_FLIGHTS,
  PATH_SAVVY_AIRCRAFT,
  FLAG_TEST_DOWNSAMPLING,
} from 'const';
import { useLgUp } from 'hooks';
import { setAlert } from 'state';

import { ZoomSync, ModeManager, GamiManager } from './chartComponents';
import FlightChartView from './FlightChartView';
import { QUERY_HAS_FEATURE_FLAG } from 'gql';
import { useQuery } from '@apollo/client';
import { PageLoading } from 'components';

interface SharedFlightChartProps {
  flightData: IFlightData;
  modeManager: ModeManager;
  setDiffData: (value: any) => void;
  analysisMode: string;
  chartNum: number;
  isShare?: boolean;
  showInfo: boolean;
  setShowInfo: (value: boolean) => void;
  settings: IFlightChartSetting | undefined;
}

interface ISerieChartData {
  [name: string]: number[];
}

const zoomSync = new ZoomSync();
const gamiManager = new GamiManager();

const SharedFlightChart: React.FC<SharedFlightChartProps> = (props) => {
  const { flightData, modeManager, setDiffData, analysisMode, chartNum, showInfo, settings } = props;

  const [fetchFlightData, { data: dataFetchFlightData, error: errorSeriesData }] = useLazyQuery(QUERY_SHARED_FLIGHT_CHART_DETAIL_BY_ID, {
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  });

  const { data: dataFlag, loading: loadingFlag } = useQuery(QUERY_HAS_FEATURE_FLAG, {
    variables: {
      flagName: FLAG_TEST_DOWNSAMPLING,
    },
  });

  useEffect(() => {
    if (dataFlag?.hasFeatureFlag) {
      zoomSync.setFeatureFlag(true);
    } else {
      zoomSync.setFeatureFlag(false);
    }
  }, [dataFlag]);

  const [chartContainerWidth, setChartContainerWidth] = useState(window.innerWidth - 48);

  const chartData = JSON.parse(flightData.data);

  const dispatch = useDispatch();
  const { isSavvy } = useSelector((state: any) => state.auth);
  const { isAnalysisTab } = useSelector((state: any) => state.analysis);

  const [seriesChartData, setSeriesChartData] = useState<ISerieChartData>(chartData ? chartData.series_data : {});
  const SERIES_SETS = chartData.series_sets.filter((serie_set: ISerieSet) => {
    const tmp_serie = {
      name: serie_set.name,
      series: [] as string[],
    };

    for (const serie of serie_set.series) {
      if (chartData.series_data[serie]?.length) {
        if (chartData.series_data[serie][0].toString().includes('0x')) continue;
        else {
          tmp_serie.series.push(serie);
        }
      } else {
        tmp_serie.series.push(serie);
      }
    }

    if (!tmp_serie.series.length && serie_set.name !== 'None') return false;
    return tmp_serie;
  });

  modeManager.setGamiManager(gamiManager);

  const isLgUp = useLgUp();

  const history = useHistory();

  const findFirstOf = (availableSeriesSet: ISerieSet[], desiredSet: string) => {
    for (const serie of availableSeriesSet) {
      if (serie?.name && serie.name === desiredSet) {
        return serie;
      }
    }

    return {
      name: 'None',
      series: [],
    };
  };

  const getInitialSerie = (chartNumber: number, seriesSelector: string) => {
    const series_sets = JSON.parse(flightData.data).series_sets;

    for (let i = 0; i < flightData.initialSeries?.length; i++) {
      if (flightData.initialSeries[i].chartNumber === chartNumber && flightData.initialSeries[i].seriesSelector === seriesSelector) {
        for (let j = 0; j < flightData.initialSeries[i].seriesNames.length; j++) {
          for (const serie of series_sets) {
            if (serie && serie.name === flightData.initialSeries[i].seriesNames[j]) {
              return limitSerieSet(findFirstOf(series_sets, serie.name), flightData.aircraft.cylinderCount);
            }
          }
        }
      }
    }

    return {
      name: 'None',
      series: [],
    };
  };

  const [primarySerie, setPrimarySerie] = useState<ISerieSet | undefined>(
    chartData
      ? getInitialSerie(1, 'L')
      : {
          name: 'None',
          series: [],
        },
  );
  const primarySerieRef = useRef<ISerieSet | undefined>(undefined);
  const [secondarySerie, setSecondarySerie] = useState<ISerieSet | undefined>(
    chartData
      ? getInitialSerie(1, 'R')
      : {
          name: 'None',
          series: [],
        },
  );
  const secondarySerieRef = useRef<ISerieSet | undefined>(undefined);

  const [thirdSerie, setThirdSerie] = useState<ISerieSet | undefined>(
    chartData
      ? getInitialSerie(2, 'L')
      : {
          name: 'None',
          series: [],
        },
  );
  const thirdSerieRef = useRef<ISerieSet | undefined>(undefined);

  const [forthSerie, setForthSerie] = useState<ISerieSet | undefined>(
    chartData
      ? getInitialSerie(2, 'R')
      : {
          name: 'None',
          series: [],
        },
  );
  const forthSerieRef = useRef<ISerieSet | undefined>(undefined);

  const [chartSettings, setChartSettings] = useState<IChartVisibleSetting[]>([]);
  const [chartSecondarySettings, setChartSecondarySettings] = useState<IChartVisibleSetting[]>([]);
  const [chartThirdSettings, setChartThirdSettings] = useState<IChartVisibleSetting[]>([]);
  const [chartForthSettings, setChartForthSettings] = useState<IChartVisibleSetting[]>([]);

  const [primaryAdditionalSerie, setPrimaryAdditionalSerie] = useState<ISerieSet | undefined>(
    chartData
      ? getInitialSerie(3, 'L')
      : {
          name: 'None',
          series: [],
        },
  );
  const primaryAdditionalSerieRef = useRef<ISerieSet | undefined>(undefined);

  const [secondaryAdditionalSerie, setSecondaryAdditionalSerie] = useState<ISerieSet | undefined>(
    chartData
      ? getInitialSerie(3, 'R')
      : {
          name: 'None',
          series: [],
        },
  );
  const secondaryAdditionalSerieRef = useRef<ISerieSet | undefined>(undefined);

  const [thirdAdditionalSerie, setThirdAdditionalSerie] = useState<ISerieSet | undefined>(
    chartData
      ? getInitialSerie(4, 'L')
      : {
          name: 'None',
          series: [],
        },
  );
  const thirdAdditionalSerieRef = useRef<ISerieSet | undefined>(undefined);

  const [forthAdditionalSerie, setForthAdditionalSerie] = useState<ISerieSet | undefined>(
    chartData
      ? getInitialSerie(4, 'R')
      : {
          name: 'None',
          series: [],
        },
  );
  const forthAdditionalSerieRef = useRef<ISerieSet | undefined>(undefined);

  const [chartAdditionalSettings, setChartAdditionalSettings] = useState<IChartVisibleSetting[]>([]);
  const [chartAdditionalSecondarySettings, setChartAdditionalSecondarySettings] = useState<IChartVisibleSetting[]>([]);
  const [chartAdditionalThirdSettings, setChartAdditionalThirdSettings] = useState<IChartVisibleSetting[]>([]);
  const [chartAdditionalForthSettings, setChartAdditionalForthSettings] = useState<IChartVisibleSetting[]>([]);

  // const timeSeries = chartData?.series_data['TIME_UTC'] || chartData?.series_data['TIME_LOCAL'] || [];
  const [initialLoaded, setInitialLoaded] = useState(false);

  const fetchFlightDataRequest = useCallback(
    async (flightId: string, flightUuid: string, serieNames: string[]) => {
      if (flightId && flightUuid) {
        await fetchFlightData({
          variables: {
            flightId,
            flightUuid,
            serieNames,
          },
        });
      }
    },
    [fetchFlightData],
  );

  const handleWindowResize = useCallback(() => {
    setChartContainerWidth(
      window.innerWidth -
        48 -
        (showInfo && window.innerWidth >= 1024 ? (isSavvy && isAnalysisTab ? INFO_SAVVY_TAB_WIDTH : INFO_TAB_WIDTH) : 0),
    );
  }, [showInfo, isSavvy, isAnalysisTab]);

  useEffect(() => {
    const containerWidth =
      window.innerWidth - (showInfo && window.innerWidth >= 1024 ? (isSavvy && isAnalysisTab ? INFO_SAVVY_TAB_WIDTH : INFO_TAB_WIDTH) : 0);
    setChartContainerWidth(containerWidth);
    for (const chart of Highcharts.charts) {
      if (chart) {
        chart.update({
          chart: {
            width: containerWidth - (window.innerWidth >= 1024 ? 80 : 0),
          },
        });
      }
    }
  }, [showInfo, isSavvy, isAnalysisTab]);

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [handleWindowResize]);

  useEffect(() => {
    if (
      primarySerie &&
      secondarySerie &&
      thirdSerie &&
      forthSerie &&
      primaryAdditionalSerie &&
      secondaryAdditionalSerie &&
      thirdAdditionalSerie &&
      forthAdditionalSerie &&
      !initialLoaded
    ) {
      const tmpSerieNames = primarySerie.series
        .concat(secondarySerie.series)
        .concat(thirdSerie.series)
        .concat(forthSerie.series)
        .concat(primaryAdditionalSerie.series)
        .concat(secondaryAdditionalSerie.series)
        .concat(thirdAdditionalSerie.series)
        .concat(forthAdditionalSerie.series)
        .concat(['TIME_UTC', 'TIME_LOCAL']);

      primarySerieRef.current = primarySerie;
      secondarySerieRef.current = secondarySerie;
      thirdSerieRef.current = thirdSerie;
      forthSerieRef.current = forthSerie;
      primaryAdditionalSerieRef.current = primaryAdditionalSerie;
      secondaryAdditionalSerieRef.current = secondaryAdditionalSerie;
      thirdAdditionalSerieRef.current = thirdAdditionalSerie;
      forthAdditionalSerieRef.current = forthAdditionalSerie;

      fetchFlightDataRequest(flightData.id, flightData.publicUuid, tmpSerieNames);
      setInitialLoaded(true);
    }
  }, [
    primarySerie,
    secondarySerie,
    thirdSerie,
    forthSerie,
    primaryAdditionalSerie,
    secondaryAdditionalSerie,
    thirdAdditionalSerie,
    forthAdditionalSerie,
    initialLoaded,
    flightData,
    fetchFlightDataRequest,
  ]);

  useEffect(() => {
    // setChartSettings([]);
    let tmp: IChartVisibleSetting[] = [];
    if (primarySerie && primarySerieRef?.current !== primarySerie) {
      tmp = primarySerie?.series.map((serie: string) => {
        return {
          name: serie,
          value: CHART_VISIBLE_NORMAL,
        };
      });

      if (primarySerie.series?.length && flightData && initialLoaded) {
        for (let i = 0; i < primarySerie.series.length; i++) {
          if (!seriesChartData[primarySerie.series[i]]) {
            fetchFlightDataRequest(flightData.id, flightData.publicUuid, primarySerie.series);
            break;
          }
        }
      }

      primarySerieRef.current = primarySerie;
    }

    setChartSettings([...tmp]);
  }, [primarySerie, seriesChartData, flightData, initialLoaded, fetchFlightDataRequest]);

  useEffect(() => {
    if (dataFetchFlightData?.sharedFlight) {
      const series_data = JSON.parse(dataFetchFlightData.sharedFlight.data).series_data;
      setSeriesChartData((seriesChartData: ISerieChartData) => {
        Object.keys(series_data).map((key) => {
          seriesChartData[key] = series_data[key];
          return null;
        });

        return { ...seriesChartData };
      });
    }
  }, [dataFetchFlightData]);

  useEffect(() => {
    if (errorSeriesData) {
      dispatch(setAlert('error', 'Unable to load data for selected serie'));
    }
  }, [errorSeriesData, dispatch]);

  useEffect(() => {
    let tmp: IChartVisibleSetting[] = [];
    if (secondarySerie && secondarySerieRef.current !== secondarySerie) {
      tmp = secondarySerie?.series.map((serie: string) => {
        return {
          name: serie,
          value: CHART_VISIBLE_NORMAL,
        };
      });

      if (secondarySerie.series?.length && flightData && initialLoaded) {
        for (let i = 0; i < secondarySerie.series.length; i++) {
          if (!seriesChartData[secondarySerie.series[i]]) {
            fetchFlightDataRequest(flightData.id, flightData.publicUuid, secondarySerie.series);
            break;
          }
        }
      }

      secondarySerieRef.current = secondarySerie;
    }

    setChartSecondarySettings([...tmp]);
  }, [secondarySerie, seriesChartData, flightData, initialLoaded, fetchFlightDataRequest]);

  useEffect(() => {
    if (analysisMode === '') {
      setDiffData({});
    }
  }, [analysisMode, setDiffData]);

  useEffect(() => {
    if (isGami(analysisMode) && primarySerie && secondarySerie) {
      const timeSeries = seriesChartData['TIME_UTC'] || seriesChartData['TIME_LOCAL'] || [];
      const seriesData = getChartSeries(primarySerie, seriesChartData);
      const secondarySeriesData = getChartSeries(secondarySerie, seriesChartData);

      if (seriesData && secondarySeriesData && timeSeries) {
        const tmp = seriesData.map((value: ISerieData) => {
          const tmpSeries: number[][] = [];

          for (let i = 0; i < value.series.length; i++) {
            tmpSeries.push([(timeSeries[i] - timeSeries[0]) * 1000, value.series[i]]);
          }

          return {
            name: value.name,
            data: tmpSeries,
          };
        });

        const tmp1 = secondarySeriesData.map((value: ISerieData) => {
          const tmpSeries: number[][] = [];

          for (let i = 0; i < value.series.length; i++) {
            tmpSeries.push([(timeSeries[i] - timeSeries[0]) * 1000, value.series[i]]);
          }
          return {
            name: value.name,
            data: tmpSeries,
          };
        });

        gamiManager.setOriginalData([...tmp, ...tmp1]);
      }
    }
  }, [primarySerie, secondarySerie, analysisMode, seriesChartData]);

  useEffect(() => {
    if (analysisMode && flightData) {
      let series: ISerieSet | undefined = undefined;

      const chartData = JSON.parse(flightData.data);

      if (analysisMode === 'mag') {
        series = limitSerieSet(findFirstOf(chartData.series_sets, 'EGT'), flightData.aircraft.cylinderCount);
      } else if (analysisMode === 'mag-left') {
        series = limitSerieSet(findFirstOf(chartData.series_sets, 'L-EGT'), flightData.aircraft.cylinderCount);
      } else if (analysisMode === 'mag-right') {
        series = limitSerieSet(findFirstOf(chartData.series_sets, 'R-EGT'), flightData.aircraft.cylinderCount);
      } else {
        if (analysisMode === 'gami') {
          setPrimarySerie(limitSerieSet(findFirstOf(chartData.series_sets, 'EGT'), flightData.aircraft.cylinderCount, true));
          setSecondarySerie(limitSerieSet(findFirstOf(chartData.series_sets, 'FF'), flightData.aircraft.cylinderCount));
          return;
        } else if (analysisMode === 'gami-left') {
          setPrimarySerie(limitSerieSet(findFirstOf(chartData.series_sets, 'L-EGT'), flightData.aircraft.cylinderCount, true));
          setSecondarySerie(limitSerieSet(findFirstOf(chartData.series_sets, 'L-FF'), flightData.aircraft.cylinderCount));
          return;
        } else if (analysisMode === 'gami-right') {
          setPrimarySerie(limitSerieSet(findFirstOf(chartData.series_sets, 'R-EGT'), flightData.aircraft.cylinderCount, true));
          setSecondarySerie(limitSerieSet(findFirstOf(chartData.series_sets, 'R-FF'), flightData.aircraft.cylinderCount));
          return;
        }
      }

      if (series) {
        const odd_egts = new RegExp(/(?:[LR]-)?(EGT)[13579]/);
        const even_egts = new RegExp(/(?:[LR]-)?(EGT)[2468]/);

        const topSeries: string[] = [];
        const bottomSeries: string[] = [];

        for (const serie of series.series) {
          if (odd_egts.exec(serie)) {
            topSeries.push(serie);
          } else if (even_egts.exec(serie)) {
            bottomSeries.push(serie);
          }
        }

        setPrimarySerie({
          name: series.name,
          series: [...topSeries],
        });

        setSecondarySerie({
          name: 'None',
          series: [],
        });

        setThirdSerie({
          name: series.name,
          series: [...bottomSeries],
        });

        setForthSerie({
          name: 'None',
          series: [],
        });
      }
    }
  }, [analysisMode, flightData]);

  useEffect(() => {
    let tmp: IChartVisibleSetting[] = [];
    if (thirdSerie && thirdSerieRef.current !== thirdSerie) {
      tmp = thirdSerie?.series.map((serie: string) => {
        return {
          name: serie,
          value: CHART_VISIBLE_NORMAL,
        };
      });

      if (thirdSerie.series?.length && flightData && initialLoaded) {
        for (let i = 0; i < thirdSerie.series.length; i++) {
          if (!seriesChartData[thirdSerie.series[i]]) {
            fetchFlightDataRequest(flightData.id, flightData.publicUuid, thirdSerie.series);
            break;
          }
        }
      }

      thirdSerieRef.current = thirdSerie;
    }

    setChartThirdSettings([...tmp]);
  }, [thirdSerie, flightData, seriesChartData, initialLoaded, fetchFlightDataRequest]);

  useEffect(() => {
    let tmp: IChartVisibleSetting[] = [];
    if (forthSerie && forthSerieRef.current !== forthSerie) {
      tmp = forthSerie?.series.map((serie: string) => {
        return {
          name: serie,
          value: CHART_VISIBLE_NORMAL,
        };
      });

      if (forthSerie.series?.length && flightData && initialLoaded) {
        for (let i = 0; i < forthSerie.series.length; i++) {
          if (!seriesChartData[forthSerie.series[i]]) {
            fetchFlightDataRequest(flightData.id, flightData.publicUuid, forthSerie.series);
            break;
          }
        }
      }

      forthSerieRef.current = forthSerie;
    }

    setChartForthSettings([...tmp]);
  }, [forthSerie, flightData, seriesChartData, initialLoaded, fetchFlightDataRequest]);

  useEffect(() => {
    let tmp: IChartVisibleSetting[] = [];
    if (primaryAdditionalSerie && primaryAdditionalSerieRef.current !== primaryAdditionalSerie) {
      tmp = primaryAdditionalSerie?.series.map((serie: string) => {
        return {
          name: serie,
          value: CHART_VISIBLE_NORMAL,
        };
      });

      if (primaryAdditionalSerie.series?.length && flightData && initialLoaded) {
        for (let i = 0; i < primaryAdditionalSerie.series.length; i++) {
          if (!seriesChartData[primaryAdditionalSerie.series[i]]) {
            fetchFlightDataRequest(flightData.id, flightData.publicUuid, primaryAdditionalSerie.series);
            break;
          }
        }
      }

      primaryAdditionalSerieRef.current = primaryAdditionalSerie;
    }

    setChartAdditionalSettings([...tmp]);
  }, [primaryAdditionalSerie, flightData, seriesChartData, initialLoaded, fetchFlightDataRequest]);

  useEffect(() => {
    let tmp: IChartVisibleSetting[] = [];
    if (secondaryAdditionalSerie && secondaryAdditionalSerieRef.current !== secondaryAdditionalSerie) {
      tmp = secondaryAdditionalSerie?.series.map((serie: string) => {
        return {
          name: serie,
          value: CHART_VISIBLE_NORMAL,
        };
      });

      if (secondaryAdditionalSerie.series?.length && flightData && initialLoaded) {
        for (let i = 0; i < secondaryAdditionalSerie.series.length; i++) {
          if (!seriesChartData[secondaryAdditionalSerie.series[i]]) {
            fetchFlightDataRequest(flightData.id, flightData.publicUuid, secondaryAdditionalSerie.series);
            break;
          }
        }
      }

      secondaryAdditionalSerieRef.current = secondaryAdditionalSerie;
    }

    setChartAdditionalSecondarySettings([...tmp]);
  }, [secondaryAdditionalSerie, flightData, seriesChartData, initialLoaded, fetchFlightDataRequest]);

  useEffect(() => {
    let tmp: IChartVisibleSetting[] = [];
    if (thirdAdditionalSerie && thirdAdditionalSerieRef.current !== thirdAdditionalSerie) {
      tmp = thirdAdditionalSerie?.series.map((serie: string) => {
        return {
          name: serie,
          value: CHART_VISIBLE_NORMAL,
        };
      });

      if (thirdAdditionalSerie.series?.length && flightData && initialLoaded) {
        for (let i = 0; i < thirdAdditionalSerie.series.length; i++) {
          if (!seriesChartData[thirdAdditionalSerie.series[i]]) {
            fetchFlightDataRequest(flightData.id, flightData.publicUuid, thirdAdditionalSerie.series);
            break;
          }
        }
      }

      thirdAdditionalSerieRef.current = thirdAdditionalSerie;
    }

    setChartAdditionalThirdSettings([...tmp]);
  }, [thirdAdditionalSerie, flightData, seriesChartData, initialLoaded, fetchFlightDataRequest]);

  useEffect(() => {
    let tmp: IChartVisibleSetting[] = [];
    if (forthAdditionalSerie && forthAdditionalSerieRef.current !== forthAdditionalSerie) {
      tmp = forthAdditionalSerie?.series.map((serie: string) => {
        return {
          name: serie,
          value: CHART_VISIBLE_NORMAL,
        };
      });

      if (forthAdditionalSerie.series?.length && flightData && initialLoaded) {
        for (let i = 0; i < forthAdditionalSerie.series.length; i++) {
          if (!seriesChartData[forthAdditionalSerie.series[i]]) {
            fetchFlightDataRequest(flightData.id, flightData.publicUuid, forthAdditionalSerie.series);
            break;
          }
        }
      }

      forthAdditionalSerieRef.current = forthAdditionalSerie;
    }

    setChartAdditionalForthSettings([...tmp]);
  }, [forthAdditionalSerie, flightData, seriesChartData, initialLoaded, fetchFlightDataRequest]);

  const getChartSeries = (serie: ISerieSet, chartData: any) => {
    let tmp: ISerieData[] = [];

    if (serie && serie.series.length) {
      if (!chartData[serie.series[0]]) {
        return [];
      }
      tmp = serie.series.map((value: string) => {
        return {
          name: value,
          series: chartData[value],
        };
      });
    }

    return tmp;
  };

  const getAircraftSettings = (serie: ISerieSet | undefined, aircraftSettings: IAircraftSetting[] | undefined) => {
    if (!aircraftSettings) return undefined;
    for (const aircraftSetting of aircraftSettings) {
      if (aircraftSetting.seriesName === serie?.name) {
        return aircraftSetting;
      }
    }

    return undefined;
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        maxWidth: isLgUp ? `${chartContainerWidth}px` : 'auto',
      }}
    >
      {loadingFlag && <PageLoading />}
      <Box
        sx={{
          px: 2,
          '@media (max-width: 768px)': {
            px: 0,
          },
        }}
      >
        <Box
          sx={{
            py: {
              md: 0,
              xs: 1,
            },
            display: 'flex',
            mb: {
              md: 2,
              xs: 0,
            },
            alignItems: 'center',
          }}
        >
          <Typography
            variant="h2"
            color="text.secondary"
            sx={{
              textAlign: {
                md: 'left',
                xs: 'center',
              },
            }}
          >
            {isSavvy && flightData?.aircraft?.registration && (
              <Link
                href={`${PATH_SAVVY_AIRCRAFT}/${flightData?.aircraft?.id}`}
                target="_blank"
                sx={{
                  mr: 1,
                }}
              >
                {flightData.aircraft.registration}
              </Link>
            )}
            {humanDate(flightData.date)}
            <Box
              component="span"
              sx={{
                color: 'grey.400',
                fontSize: '20px',
                ml: 3,
                fontWeight: 400,
              }}
            >
              {flightData.date.toString().substring(11, 16)} UTC
            </Box>
          </Typography>
          <Box
            sx={{
              ml: 2,
            }}
          >
            <IconButton
              onClick={() => {
                if (flightData.previousFlight) {
                  window.location.href = `${PATH_FLIGHTS}/${flightData.previousFlight?.id}`;
                } else {
                  return;
                }
              }}
            >
              <ArrowBackIcon />
            </IconButton>
            {isSavvy && (
              <IconButton
                sx={{
                  ml: 2,
                }}
                onClick={(e: any) => {
                  ctrlCmdClick(e, `${PATH_SAVVY_AIRCRAFT}/${flightData.aircraft.id}/flights`, history);
                }}
              >
                <ListAltIcon />
              </IconButton>
            )}
            <IconButton
              sx={{
                ml: 2,
              }}
              onClick={() => {
                if (flightData.nextFlight) {
                  window.location.href = `${PATH_FLIGHTS}/${flightData.nextFlight?.id}`;
                } else {
                  return;
                }
              }}
            >
              <ArrowForwardIcon />
            </IconButton>
          </Box>
        </Box>
        <Box
          sx={{
            display: {
              md: 'flex',
              xs: 'block',
            },
            mb: {
              lg: 3.5,
              md: 2,
            },
          }}
        >
          <Box
            sx={{
              width: {
                xl: '40%',
                lg: showInfo ? '100%' : '40%',
                md: '100%',
              },
            }}
          >
            <Box
              sx={{
                display: {
                  md: 'flex',
                  xs: 'block',
                },
                alignItems: 'center',
                justifyContent: {
                  md: 'flex-start',
                  xs: 'center',
                },
                py: {
                  md: 0,
                  xs: 1,
                },
                height: '100%',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mr: {
                    md: 1,
                    xs: 0,
                  },
                }}
                title={flightData.departureAirport}
              >
                <FlightTakeoffIcon
                  sx={{
                    color: 'grey.400',
                    mr: 1,
                    cursor: 'pointer',
                  }}
                />
                <Typography
                  sx={{
                    fontWeight: 600,
                  }}
                >
                  <Hidden mdUp>From:&nbsp;</Hidden>
                  {flightData.departureAirport ? flightData.departureAirport : ''}
                </Typography>
              </Box>
              <Hidden mdDown>
                <ArrowRightAltIcon
                  sx={{
                    mr: 1,
                  }}
                />
              </Hidden>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
                title={flightData.destinationAirport}
              >
                <FlightLandIcon
                  sx={{
                    color: 'grey.400',
                    mr: 1,
                    cursor: 'pointer',
                  }}
                />
                <Typography
                  sx={{
                    fontWeight: 600,
                  }}
                >
                  <Hidden mdUp>To:&nbsp;</Hidden>
                  {flightData.destinationAirport ? flightData.destinationAirport : ''}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              py: {
                xl: 0,
                lg: showInfo ? 1 : 0,
                md: 1,
              },
              width: {
                xl: '20%',
                lg: showInfo ? '100%' : '20%',
                md: '100%',
              },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'center',
                height: '100%',
              }}
            >
              <Box
                sx={{
                  backgroundColor: 'grey.800',
                  height: '40px',
                  display: 'flex',
                  alignItems: 'center',
                  borderRadius: '6px',
                  width: '40px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  zoomSync.resetZoom();
                }}
              >
                <RestartAltIcon
                  sx={{
                    margin: '0 auto',
                  }}
                />
              </Box>
              <Box
                sx={{
                  backgroundColor: 'grey.800',
                  height: '40px',
                  display: 'flex',
                  alignItems: 'center',
                  borderRadius: '6px',
                  width: '40px',
                  ml: 1,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  zoomSync.undoZoom();
                }}
              >
                <ZoomOutIcon
                  sx={{
                    margin: '0 auto',
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        {chartData && (
          <Box id="chart-container">
            <FlightChartView
              seriesData={primarySerie ? getChartSeries(primarySerie, seriesChartData) : []}
              chartSettings={chartSettings}
              secondarySeriesData={secondarySerie ? getChartSeries(secondarySerie, seriesChartData) : []}
              chartSecondarySettings={chartSecondarySettings}
              timeSeries={seriesChartData['TIME_UTC'] || seriesChartData['TIME_LOCAL'] || []}
              zoomSync={zoomSync}
              modeManager={modeManager}
              setDiffData={setDiffData}
              gamiManager={gamiManager}
              leftSerie={primarySerie}
              setLeftSerie={setPrimarySerie}
              seriesList={SERIES_SETS}
              cylinderCount={flightData.aircraft.cylinderCount}
              rightSerie={secondarySerie}
              setRightSerie={setSecondarySerie}
              setChartSettings={setChartSettings}
              setChartSecondarySettings={setChartSecondarySettings}
              zoomController={true}
              aircraftId={flightData.aircraft?.id ? flightData.aircraft.id : ''}
              chartNumber={1}
              aircraftSetting={getAircraftSettings(primarySerie, flightData?.aircraft?.settings)}
              aircraftSecondarySetting={getAircraftSettings(secondarySerie, flightData?.aircraft?.settings)}
              settings={settings}
              flightData={flightData}
              isGami={isGami(analysisMode)}
            />
            {!isGami(analysisMode) && chartNum !== 1 && (
              <React.Fragment>
                <FlightChartView
                  seriesData={thirdSerie ? getChartSeries(thirdSerie, seriesChartData) : []}
                  chartSettings={chartThirdSettings}
                  secondarySeriesData={forthSerie ? getChartSeries(forthSerie, seriesChartData) : []}
                  chartSecondarySettings={chartForthSettings}
                  timeSeries={seriesChartData['TIME_UTC'] || seriesChartData['TIME_LOCAL'] || []}
                  zoomSync={zoomSync}
                  modeManager={modeManager}
                  setDiffData={setDiffData}
                  leftSerie={thirdSerie}
                  setLeftSerie={setThirdSerie}
                  seriesList={SERIES_SETS}
                  cylinderCount={flightData.aircraft.cylinderCount}
                  rightSerie={forthSerie}
                  setRightSerie={setForthSerie}
                  setChartSettings={setChartThirdSettings}
                  setChartSecondarySettings={setChartForthSettings}
                  aircraftId={flightData.aircraft?.id ? flightData.aircraft.id : ''}
                  chartNumber={2}
                  aircraftSetting={getAircraftSettings(thirdSerie, flightData?.aircraft?.settings)}
                  aircraftSecondarySetting={getAircraftSettings(forthSerie, flightData?.aircraft?.settings)}
                  settings={settings}
                  flightData={flightData}
                />
              </React.Fragment>
            )}
            {!isGami(analysisMode) && chartNum === 4 && (
              <React.Fragment>
                <FlightChartView
                  seriesData={primaryAdditionalSerie ? getChartSeries(primaryAdditionalSerie, seriesChartData) : []}
                  chartSettings={chartAdditionalSettings}
                  secondarySeriesData={secondaryAdditionalSerie ? getChartSeries(secondaryAdditionalSerie, seriesChartData) : []}
                  chartSecondarySettings={chartAdditionalSecondarySettings}
                  timeSeries={seriesChartData['TIME_UTC'] || seriesChartData['TIME_LOCAL'] || []}
                  zoomSync={zoomSync}
                  modeManager={modeManager}
                  setDiffData={setDiffData}
                  leftSerie={primaryAdditionalSerie}
                  setLeftSerie={setPrimaryAdditionalSerie}
                  seriesList={SERIES_SETS}
                  cylinderCount={flightData.aircraft.cylinderCount}
                  rightSerie={secondaryAdditionalSerie}
                  setRightSerie={setSecondaryAdditionalSerie}
                  setChartSettings={setChartAdditionalSettings}
                  setChartSecondarySettings={setChartAdditionalSecondarySettings}
                  aircraftId={flightData.aircraft?.id ? flightData.aircraft.id : ''}
                  chartNumber={3}
                  aircraftSetting={getAircraftSettings(primaryAdditionalSerie, flightData?.aircraft?.settings)}
                  aircraftSecondarySetting={getAircraftSettings(secondaryAdditionalSerie, flightData?.aircraft?.settings)}
                  settings={settings}
                  flightData={flightData}
                />
                <FlightChartView
                  seriesData={thirdAdditionalSerie ? getChartSeries(thirdAdditionalSerie, seriesChartData) : []}
                  chartSettings={chartAdditionalThirdSettings}
                  secondarySeriesData={forthAdditionalSerie ? getChartSeries(forthAdditionalSerie, seriesChartData) : []}
                  chartSecondarySettings={chartAdditionalForthSettings}
                  timeSeries={seriesChartData['TIME_UTC'] || seriesChartData['TIME_LOCAL'] || []}
                  zoomSync={zoomSync}
                  modeManager={modeManager}
                  setDiffData={setDiffData}
                  leftSerie={thirdAdditionalSerie}
                  setLeftSerie={setThirdAdditionalSerie}
                  seriesList={SERIES_SETS}
                  cylinderCount={flightData.aircraft.cylinderCount}
                  rightSerie={forthAdditionalSerie}
                  setRightSerie={setForthAdditionalSerie}
                  setChartSettings={setChartAdditionalThirdSettings}
                  setChartSecondarySettings={setChartAdditionalForthSettings}
                  aircraftId={flightData.aircraft?.id ? flightData.aircraft.id : ''}
                  chartNumber={4}
                  aircraftSetting={getAircraftSettings(thirdAdditionalSerie, flightData?.aircraft?.settings)}
                  aircraftSecondarySetting={getAircraftSettings(forthAdditionalSerie, flightData?.aircraft?.settings)}
                  settings={settings}
                  flightData={flightData}
                />
              </React.Fragment>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default SharedFlightChart;
