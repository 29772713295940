import React from 'react';
import { Badge } from '@mui/material';

interface BadgeIconProps {
  backgroundColor?: string;
}

const BadgeIcon: React.FC<BadgeIconProps> = (props) => {
  const { backgroundColor = undefined } = props;

  return (
    <Badge
      badgeContent=""
      color="error"
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      sx={{
        marginRight: '20px',
        fontSize: '10px',
        '& .MuiBadge-badge': {
          fontSize: '10px',
          height: '12px',
          width: '12px',
          minWidth: '12px',
          borderRadius: '100%',
          backgroundColor: backgroundColor ? backgroundColor : 'error.dark',
          transform: 'scale(1) translate(100%, -8px)',
        },
      }}
    ></Badge>
  );
};

export default BadgeIcon;
