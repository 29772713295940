import { services } from 'api';

export const getServiceName = (value: string) => {
  const result = services.find((service) => service.id === value);
  return typeof result === 'undefined' ? '' : result.name;
};

export const joinOrString = (arr: string[]) => {
  let result = getServiceName(arr[0]);

  for (let i = 1; i < arr.length - 1; i++) {
    result += ', ' + getServiceName(arr[i]);
  }
  if (arr.length > 1) result += ' or ' + getServiceName(arr[arr.length - 1]);

  return result;
};
