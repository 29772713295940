import React, { useState, useEffect } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Collapse, Typography, IconButton, Hidden, Grid, Button, styled, Link, Tooltip } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import EmailIcon from '@mui/icons-material/Email';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import InfoIcon from '@mui/icons-material/Info';

import { AttachmentsList } from './ContentComponent';
import { ITicket, IFlight } from 'types';
import { humanDateTime, humanDate, secondsToHms, isSharedLog } from 'helpers';
import { PATH_TICKETS_VIEW, PATH_AIRCRAFT_VIEW, PATH_FLIGHT_VIEW, PATH_SHARE_LOGS } from 'const';
import { CloseTicketIcon } from 'customIcons';
import NotHappyDialog from './NotHappyDialog';

const classes = {
  grayFieldTypo: 'grayFieldTypo',
  optionTypo: 'optionTypo',
};

const StyledDiv = styled('div')({
  [`& .${classes.grayFieldTypo}`]: {
    fontSize: '13px !important',
  },
  [`& .${classes.optionTypo}`]: {
    fontSize: '14px !important',
  },
});

interface TicketInfoBoxProps {
  ticket: ITicket | undefined;
  closeTicket?: (id: number, close: boolean) => void;
}

const TicketInfoBox: React.FC<TicketInfoBoxProps> = (props) => {
  const { ticket, closeTicket } = props;

  const [relatedOn, setRelatedOn] = useState(false);

  const history = useHistory();
  const { isTechnician } = useSelector((state: any) => state.auth);

  const getPriority = (value: string) => {
    if (value === 'N') {
      return 'Normal';
    } else if (value === 'A') {
      return 'Aircraft Not Flyable';
    } else if (value === 'B') {
      return 'Breakdown Assistance';
    } else if (value === 'U') {
      return 'Urgent';
    } else {
      return '?';
    }
  };

  const [analysisFlights, setAnalysisFlights] = useState<
    {
      tagId: string;
      flight: IFlight;
    }[]
  >([]);

  const [openNotHappy, setOpenNotHappy] = useState(false);

  const isVisibleNotHappy = (ticketValue: ITicket) => {
    if (ticketValue?.notHappy) return true;

    return ticketValue?.canSetNotHappy;
  };

  useEffect(() => {
    if (ticket?.tags) {
      const tmpFlights: {
        tagId: string;
        flight: IFlight;
      }[] = [];
      for (const tag of ticket.tags as any) {
        if (tag.__typename === 'AnalysisTagType') {
          if (tag.flight) {
            tmpFlights.push({
              tagId: tag.id,
              flight: { ...tag.flight },
            });
          }
        }
      }

      setAnalysisFlights([...tmpFlights]);
    }
  }, [ticket]);

  if (!ticket) return null;

  return (
    <Box
      sx={{
        '@media (min-width: 1280px)': {
          maxHeight: 'calc(100vh - 70px)',
          overflowY: 'auto',
          '&::-webkit-scrollbar': {
            width: '0.6em',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
            borderRadius: 2,
          },
        },
      }}
    >
      <StyledDiv
        sx={{
          py: 5,
          px: {
            xs: 2,
            sm: 3,
            md: 4,
            lg: 5,
          },
        }}
      >
        <Grid container>
          <Grid item xl={12} lg={6} md={6} sm={12}>
            <Box
              sx={{
                display: 'flex',
                py: 1,
                pb: 3,
                width: '320px',
                '& p': {
                  fontWeight: 600,
                },
              }}
            >
              {isVisibleNotHappy(ticket) && (
                <Button
                  variant={ticket?.notHappy ? 'contained' : 'outlined'}
                  sx={{
                    color: `${ticket?.notHappy ? 'white' : 'grey.600'}`,
                    borderColor: 'grey.200',
                    mr: 2,
                  }}
                  onClick={() => {
                    setOpenNotHappy(true);
                  }}
                >
                  <SentimentVeryDissatisfiedIcon />
                  I'm not happy
                </Button>
              )}
              {(!isTechnician || (isTechnician && ticket?.state.toLowerCase() === 'c')) && (
                <Button
                  variant="outlined"
                  sx={{
                    color: 'grey.600',
                    borderColor: 'grey.200',
                  }}
                  onClick={() => {
                    if (closeTicket) {
                      closeTicket(parseInt(ticket.id), ticket.state.toLowerCase() !== 'c');
                    }
                  }}
                >
                  <CloseTicketIcon />
                  {ticket?.state.toLowerCase() === 'c' ? 'Reopen Ticket' : 'Close Ticket'}
                </Button>
              )}
            </Box>
          </Grid>
          <Hidden xlDown>
            <Box
              sx={{
                display: 'flex',
                pb: 1,
                width: '300px',
                '& p': {
                  fontWeight: 600,
                },
              }}
            >
              <Typography
                className={classes.grayFieldTypo}
                sx={{
                  width: '40%',
                  color: 'grey.600',
                }}
              >
                Title
              </Typography>
              <Typography
                sx={{
                  width: '60%',
                  color: 'text.secondary',
                }}
                className={classes.optionTypo}
              >
                {ticket.subject}
              </Typography>
            </Box>
          </Hidden>
          <Grid item xl={12} lg={6} md={6} sm={12}>
            <Box
              sx={{
                display: 'flex',
                py: 1,
                width: '300px',
                '& p': {
                  fontWeight: 600,
                },
              }}
            >
              <Typography
                className={classes.grayFieldTypo}
                sx={{
                  width: '40%',
                  color: 'grey.600',
                }}
              >
                Status
              </Typography>
              <Typography
                sx={{
                  width: '60%',
                  color: ticket.state.toLowerCase() !== 'c' ? '#4C8F5B' : '#131E40',
                }}
                className={classes.optionTypo}
              >
                {ticket.state.toLowerCase() === 'c' ? 'Closed' : 'Active'}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                py: 1,
                width: '300px',
              }}
            >
              <Typography
                className={classes.grayFieldTypo}
                sx={{
                  width: '40%',
                  fontWeight: 600,
                  color: 'grey.600',
                }}
              >
                Priority
              </Typography>
              <Typography
                sx={{
                  width: '60%',
                  color: 'text.secondary',
                }}
                className={classes.optionTypo}
              >
                {getPriority(ticket.priority)}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                py: 1,
                width: '300px',
              }}
            >
              <Typography
                className={classes.grayFieldTypo}
                sx={{
                  width: '40%',
                  fontWeight: 600,
                  color: 'grey.600',
                }}
              >
                Creation Date
              </Typography>
              <Typography
                sx={{
                  width: '60%',
                  color: 'text.secondary',
                }}
                className={classes.optionTypo}
              >
                {humanDateTime(ticket.created)}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                py: 1,
                width: '300px',
              }}
            >
              <Typography
                className={classes.grayFieldTypo}
                sx={{
                  width: '40%',
                  fontWeight: 600,
                  color: 'grey.600',
                }}
              >
                Updated
              </Typography>
              <Typography
                sx={{
                  width: '60%',
                  color: 'text.secondary',
                }}
                className={classes.optionTypo}
              >
                {humanDateTime(ticket.updated)}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                py: 1,
                width: '300px',
                '& p': {
                  fontWeight: 600,
                },
              }}
            >
              <Typography
                className={classes.grayFieldTypo}
                sx={{
                  width: '40%',
                  fontWeight: 600,
                  color: 'grey.600',
                }}
              >
                Created By
              </Typography>
              <Typography
                sx={{
                  width: '60%',
                  color: 'text.secondary',
                }}
                className={classes.optionTypo}
              >
                {`${ticket.creator.firstName} ${ticket.creator.lastName}`}
              </Typography>
            </Box>
            {Boolean(ticket?.watchers?.length) && (
              <Box
                sx={{
                  display: 'flex',
                  py: 1,
                  width: '300px',
                  '& p': {
                    fontWeight: 600,
                  },
                }}
              >
                <Typography
                  className={classes.grayFieldTypo}
                  sx={{
                    width: '40%',
                    fontWeight: 600,
                    color: 'grey.600',
                  }}
                >
                  Watchers
                </Typography>
                <Box
                  sx={{
                    width: '60%',
                  }}
                >
                  {ticket?.watchers?.map((watcher: any, key: number) => {
                    return (
                      <Typography
                        key={key}
                        className={classes.optionTypo}
                        sx={{
                          color: 'text.secondary',
                        }}
                      >
                        {watcher.fullNameWithNick}
                        {watcher?.contact?.company && watcher?.contact?.isTechnician ? `   (${watcher.contact.company})` : ''}
                        {watcher.isEmailWatcher && (
                          <EmailIcon
                            sx={{
                              ml: 0.5,
                              width: '17px',
                              height: '17px',
                              verticalAlign: 'bottom',
                            }}
                          />
                        )}
                      </Typography>
                    );
                  })}
                </Box>
              </Box>
            )}
            {Boolean(ticket?.aircraft?.year) && (
              <Box
                sx={{
                  display: 'flex',
                  py: 1,
                  width: '300px',
                  '& p': {
                    fontWeight: 600,
                  },
                }}
              >
                <Typography
                  className={classes.grayFieldTypo}
                  sx={{
                    width: '40%',
                    fontWeight: 600,
                    color: 'grey.600',
                  }}
                >
                  Year
                </Typography>
                <Typography
                  sx={{
                    width: '60%',
                    color: 'text.secondary',
                  }}
                  className={classes.optionTypo}
                >
                  {ticket?.aircraft?.year}
                </Typography>
              </Box>
            )}
            {Boolean(ticket?.aircraft?.registration) && (
              <Box
                sx={{
                  display: 'flex',
                  py: 1,
                  width: '300px',
                  '& p': {
                    fontWeight: 600,
                  },
                }}
              >
                <Typography
                  className={classes.grayFieldTypo}
                  sx={{
                    width: '40%',
                    fontWeight: 600,
                    color: 'grey.600',
                  }}
                >
                  Registration
                </Typography>
                <NavLink to={`${PATH_AIRCRAFT_VIEW}/${ticket?.aircraft?.id}`}>{ticket?.aircraft?.registration}</NavLink>
              </Box>
            )}
            {!(isTechnician && ticket.state.toLowerCase() === 'c') && isSharedLog(ticket?.tags || []) && (
              <Box
                sx={{
                  display: 'flex',
                  py: 1,
                  width: '300px',
                  '& p': {
                    fontWeight: 600,
                  },
                  alignItems: 'center',
                }}
              >
                <Typography
                  className={classes.grayFieldTypo}
                  sx={{
                    width: '40%',
                    fontWeight: 600,
                  }}
                >
                  Maintenance
                </Typography>
                <Box
                  sx={{
                    width: '60%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Link href={`${PATH_SHARE_LOGS}/${ticket.id}`} target="_blank">
                    View Logs
                  </Link>
                  {ticket.state.toLowerCase() === 'c' && (
                    <Tooltip title="Ticket Closed. Logbooks no longer visible to Service Center">
                      <InfoIcon />
                    </Tooltip>
                  )}
                </Box>
              </Box>
            )}
            {!!analysisFlights.length && (
              <Box
                sx={{
                  display: 'flex',
                  width: '300px',
                  '& p': {
                    fontWeight: 600,
                  },
                }}
              >
                <Typography
                  className={classes.grayFieldTypo}
                  sx={{
                    width: '40%',
                    fontWeight: 600,
                    py: 1,
                    color: 'grey.600',
                  }}
                >
                  Analysis Flights
                </Typography>
                <Box
                  sx={{
                    width: '60%',
                  }}
                >
                  {analysisFlights.map((analysisFlight, key: number) => {
                    return (
                      <Box key={key} py={1}>
                        <Typography
                          sx={{
                            textDecoration: 'underline',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            window.open(`${PATH_FLIGHT_VIEW}/${analysisFlight.flight.id}`, '_blank');
                          }}
                        >
                          {`${
                            analysisFlight.flight.date.toString().substring(0, 10) +
                            ' ' +
                            analysisFlight.flight.date.toString().substring(11, 16)
                          }, ${secondsToHms(analysisFlight.flight.duration)}, ${analysisFlight.flight.departureAirport} -> ${
                            analysisFlight.flight.destinationAirport
                          }`}
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            )}
          </Grid>
        </Grid>
      </StyledDiv>
      {ticket && <AttachmentsList ticket={ticket} />}
      <Box
        sx={{
          py: 2,
          width: '100%',
          px: {
            xs: 2,
            sm: 3,
            md: 4,
            lg: 5,
          },
          background: 'rgba(245, 246, 250, 0.5)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: 'pointer',
        }}
        onClick={() => {
          setRelatedOn(!relatedOn);
        }}
      >
        <Typography
          sx={{
            color: 'text.secondary',
            fontWeight: 700,
            fontSize: '18px',
            lineHeight: '25px',
          }}
        >
          Related Tickets
          <Box
            component="span"
            sx={{
              marginLeft: '10px',
              backgroundColor: 'grey.400',
              padding: '0 5px',
              borderRadius: '4px',
              color: 'background.default',
              fontSize: '13px',
              fontWeight: 600,
              lineHeight: '18px',
            }}
          >
            {ticket.relatedTickets?.length}
          </Box>
        </Typography>
        <IconButton>{relatedOn ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}</IconButton>
      </Box>
      <Collapse in={relatedOn}>
        <Box>
          {ticket.relatedTickets?.map((relatedTicket: any, key: number) => {
            return (
              <Box
                key={key}
                py={1}
                sx={{
                  cursor: 'pointer',
                  '&:hover': {
                    background: 'rgba(245, 246, 250, 0.5)',
                  },
                  px: {
                    xs: 2,
                    sm: 3,
                    md: 4,
                    lg: 5,
                  },
                  border: {
                    sm: '1px solid',
                    xl: 'none',
                  },
                  borderColor: {
                    sm: 'grey.200',
                  },
                  display: 'flex',
                }}
                onClick={() => {
                  history.push(`${PATH_TICKETS_VIEW}/${relatedTicket?.id}`);
                }}
              >
                <Typography
                  sx={{
                    width: '40%',
                    fontWeight: 400,
                    color: 'grey.400',
                    fontSize: '12px',
                    textTransform: 'uppercase',
                  }}
                >
                  #{relatedTicket.id} ({relatedTicket.state})
                </Typography>
                <Box
                  sx={{
                    textAlign: 'left',
                    width: '60%',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: 500,
                      lineHeight: '19px',
                    }}
                  >
                    {relatedTicket?.subject}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: '12px',
                      color: 'grey.400',
                      lineHeight: '17px',
                    }}
                  >
                    {relatedTicket?.state.toLowerCase() === 'c'
                      ? `CLOSED (${humanDate(relatedTicket?.updated)})`
                      : humanDateTime(relatedTicket?.updated)}
                  </Typography>
                </Box>
              </Box>
            );
          })}
        </Box>
      </Collapse>
      <NotHappyDialog open={openNotHappy} setOpen={setOpenNotHappy} ticketId={ticket.id} />
    </Box>
  );
};

export default TicketInfoBox;
