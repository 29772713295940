import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Typography, Collapse, IconButton, Tooltip } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CloseIcon from '@mui/icons-material/Close';
import WarningIcon from '@mui/icons-material/Warning';
import { IBorescopeImageFile, IBorescopeImageFileType } from 'types';
import { UPLOAD_STATUS_PROCESSING } from 'const';
import { MUTATION_UPDATE_BORESCOPE_IMAGE } from 'gql';
import { useMutation } from '@apollo/client';

import { default as EditSubjectComponent } from './EditSubjectComponent';
import { ComponentLoading } from 'components';
import { setAlert } from 'state';
import { default as UploadingStatusBar } from './UploadingStatusBar';

interface CollapsedFilesProps {
  uploadedFiles: IBorescopeImageFileType[];
  uploadingFiles: IBorescopeImageFile[];
  cylinderIndex: number;
  subjectList: string[][];
  isBorder: boolean;
  deleteBorescopeImage: (id: string) => void;
}

const CollapsedFilesByCylinder: React.FC<CollapsedFilesProps> = (props) => {
  const { uploadedFiles, uploadingFiles, cylinderIndex, subjectList, isBorder, deleteBorescopeImage } = props;
  const [openCollapse, setOpenCollapse] = useState(false);

  const [updateImage, { data: dataUpdateImage, loading: loadingUpdateImage, error: errorUpdateImage }] =
    useMutation(MUTATION_UPDATE_BORESCOPE_IMAGE);
  const dispatch = useDispatch();

  const getNoSubjectFiles = () => {
    const noUploadedFiles = uploadedFiles.filter((file) => !file.subject);
    const noUploadingFiles = uploadingFiles.filter((file) => !file.subject);

    return noUploadedFiles.length + noUploadingFiles.length;
  };

  const isExistingMatchedFiles = (subject: string) => {
    const noUploadedFiles = uploadedFiles.filter((file) => file.subject === subject);
    const noUploadingFiles = uploadingFiles.filter((file) => file.subject === subject);

    return Boolean(noUploadedFiles.length || noUploadingFiles.length);
  };

  const updateImageLocation = async (borescopeImageId: string, cylinder: number, subject: string) => {
    await updateImage({
      variables: {
        borescopeImageId,
        cylinder,
        subject,
      },
    });
  };

  useEffect(() => {
    if (uploadingFiles.length) {
      const isProcessing = uploadingFiles.filter(
        (file) => cylinderIndex && file.cylinderIndex === cylinderIndex && file.status === UPLOAD_STATUS_PROCESSING,
      );
      if (isProcessing) {
        setOpenCollapse(true);
      }
    }
  }, [uploadingFiles, cylinderIndex]);

  useEffect(() => {
    if (errorUpdateImage) {
      dispatch(setAlert('error', 'Unable to change location'));
    } else if (dataUpdateImage) {
      const { ok, error } = dataUpdateImage.updateBorescopeImage;
      if (ok) {
        dispatch(setAlert('success', 'Tagged image with view', undefined, 3000));
      } else {
        dispatch(setAlert('error', error || 'Unable to change location'));
      }
    }
  }, [errorUpdateImage, dataUpdateImage, dispatch]);

  useEffect(() => {
    if (uploadedFiles.length) {
      const noUploadedFiles = uploadedFiles.filter((file) => !file.subject);
      if (noUploadedFiles.length) {
        setOpenCollapse(true);
      }
    }
  }, [uploadedFiles]);

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          py: 1.25,
          backgroundColor: 'rgba(245, 246, 250, 0.5)',
          px: 2.5,
          borderBottom: isBorder ? '1px solid' : '0px',
          borderColor: 'grey.200',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box
            onClick={() => {
              setOpenCollapse(!openCollapse);
            }}
            sx={{
              cursor: 'pointer',
              mr: 1,
            }}
          >
            {openCollapse ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
          </Box>
          <Typography
            sx={{
              color: 'text.secondary',
              fontWeight: 700,
              fontSize: '18px',
              lineHeight: '26px',
              mr: 1,
            }}
          >
            Cylinder {cylinderIndex}
          </Typography>
          <Box
            sx={{
              display: 'inline-block',
              backgroundColor: 'grey.400',
              borderRadius: '4px',
              padding: '1px 5px',
            }}
          >
            <Typography
              sx={{
                color: 'background.default',
                fontWeight: 600,
              }}
            >
              {uploadedFiles.length + uploadingFiles.length}
            </Typography>
          </Box>
          {!!uploadedFiles.filter((file) => file.isBadImage).length && (
            <Tooltip
              title={<Typography>Here are 'bad' marked images.</Typography>}
              componentsProps={{
                tooltip: {
                  sx: {
                    backgroundColor: '#000',
                    color: 'background.default',
                  },
                },
              }}
            >
              <WarningIcon
                sx={{
                  ml: 1,
                  width: '0.8em',
                  height: '0.8em',
                  color: 'warning.main',
                }}
              />
            </Tooltip>
          )}
          {!!getNoSubjectFiles() && (
            <Typography
              sx={{
                fontWeight: 700,
                color: 'text.secondary',
                ml: 2,
              }}
            >
              Not Tagged {getNoSubjectFiles()} Image(s)
            </Typography>
          )}
        </Box>
      </Box>
      <Collapse in={openCollapse}>
        <Box>
          <ComponentLoading loading={loadingUpdateImage}>
            {!!getNoSubjectFiles() && (
              <Box>
                <Box
                  py={1}
                  px={3}
                  sx={{
                    backgroundColor: 'grey.200',
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 700,
                      textTransform: 'uppercase',
                    }}
                  >
                    Not Tagged
                  </Typography>
                </Box>
                {uploadedFiles
                  .filter((file) => !file.subject)
                  .map((file: IBorescopeImageFileType, key: number) => {
                    return (
                      <Box
                        key={key}
                        py={1}
                        px={3}
                        sx={{
                          display: 'flex',
                        }}
                      >
                        <Box
                          sx={{
                            width: '40%',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <Box
                            component="img"
                            src={file.previewUrl}
                            sx={{
                              objectFit: 'cover',
                              width: '80px',
                              height: '50px',
                              mr: 2,
                            }}
                          />
                          <Typography color="text.secondary">{file.filename}</Typography>
                        </Box>
                        <Box
                          sx={{
                            width: '60%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}
                        >
                          <EditSubjectComponent
                            subjectList={subjectList}
                            uploadedFiles={uploadedFiles}
                            updateImageLocation={updateImageLocation}
                            activeFile={file}
                            noTagged={true}
                          />
                          <IconButton
                            onClick={async () => {
                              await deleteBorescopeImage(file.id);
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Box>
                      </Box>
                    );
                  })}
                {uploadingFiles
                  .filter((file) => !file.subject)
                  .map((file: IBorescopeImageFile, key: number) => {
                    return (
                      <ComponentLoading loading key={key}>
                        <Box
                          py={1}
                          px={3}
                          sx={{
                            display: 'flex',
                          }}
                        >
                          <Box
                            sx={{
                              width: '40%',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <Box
                              component="img"
                              src={file.src || '/images/tempImage.png'}
                              sx={{
                                objectFit: 'cover',
                                width: '80px',
                                height: '50px',
                                mr: 2,
                              }}
                            />
                            <Box>
                              <UploadingStatusBar uploadedFile={file} />
                            </Box>
                          </Box>
                        </Box>
                      </ComponentLoading>
                    );
                  })}
              </Box>
            )}
            {subjectList.map((item: string[], key1: number) => {
              if (!isExistingMatchedFiles(item[0])) {
                return null;
              }
              return (
                <Box key={`subject_${key1}`}>
                  <Box
                    py={1}
                    px={3}
                    sx={{
                      backgroundColor: 'grey.200',
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 700,
                        textTransform: 'uppercase',
                      }}
                    >
                      {item[1]}
                    </Typography>
                  </Box>
                  {uploadingFiles.map((file: IBorescopeImageFile, key: number) => {
                    if (file.subject !== item[0]) {
                      return null;
                    }
                    return (
                      <ComponentLoading loading key={key}>
                        <Box
                          py={1}
                          px={3}
                          sx={{
                            display: 'flex',
                          }}
                        >
                          <Box
                            sx={{
                              width: '40%',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <Box
                              component="img"
                              src={'/images/tempImage.png'}
                              sx={{
                                objectFit: 'cover',
                                width: '80px',
                                height: '50px',
                                mr: 2,
                              }}
                            />
                            <Box>
                              <UploadingStatusBar uploadedFile={file} />
                            </Box>
                          </Box>
                        </Box>
                      </ComponentLoading>
                    );
                  })}
                  {uploadedFiles.map((file: IBorescopeImageFileType, key: number) => {
                    if (file.subject !== item[0]) {
                      return null;
                    }
                    return (
                      <Box
                        key={key}
                        py={1}
                        px={3}
                        sx={{
                          display: 'flex',
                        }}
                      >
                        <Box
                          sx={{
                            width: '40%',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          {!!file.previewUrl && (
                            <Tooltip
                              title={
                                <Box
                                  component="img"
                                  src={file.previewUrl}
                                  sx={{
                                    objectFit: 'cover',
                                    width: '320px',
                                    height: '200px',
                                  }}
                                />
                              }
                              placement="right"
                            >
                              <Box
                                component="img"
                                src={file.previewUrl}
                                sx={{
                                  objectFit: 'cover',
                                  width: '80px',
                                  height: '50px',
                                  cursor: 'pointer',
                                  mr: 2,
                                }}
                              />
                            </Tooltip>
                          )}
                          <Typography color="text.secondary">{file.filename}</Typography>
                          {file.isBadImage && (
                            <Tooltip
                              title={<Typography>It's marked as bad image</Typography>}
                              componentsProps={{
                                tooltip: {
                                  sx: {
                                    backgroundColor: '#000',
                                    color: 'background.default',
                                  },
                                },
                              }}
                            >
                              <WarningIcon
                                sx={{
                                  ml: 1,
                                  width: '0.8em',
                                  height: '0.8em',
                                  color: 'warning.main',
                                }}
                              />
                            </Tooltip>
                          )}
                        </Box>
                        <Box
                          sx={{
                            width: '60%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}
                        >
                          <EditSubjectComponent
                            subjectList={subjectList}
                            uploadedFiles={uploadedFiles}
                            updateImageLocation={updateImageLocation}
                            activeFile={file}
                          />
                          <IconButton
                            onClick={async () => {
                              await deleteBorescopeImage(file.id);
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              );
            })}
          </ComponentLoading>
        </Box>
      </Collapse>
    </Box>
  );
};

export default CollapsedFilesByCylinder;
