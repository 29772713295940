import React from 'react';

import { LayoutDashboard } from 'components';
import { BorescopeAnalysisReports } from './components';

const CreateBorescopeAnalysisReports: React.FC = () => {
  return (
    <LayoutDashboard backgroundColor="#fff">
      <BorescopeAnalysisReports />
    </LayoutDashboard>
  );
};

export default CreateBorescopeAnalysisReports;
