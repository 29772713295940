import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Typography, Button, Container } from '@mui/material';
import { IContactType } from 'types';
import { PATH_CONTACT } from 'const';
import { getContactAddress } from 'helpers';

interface OwnerProps {
  owner: IContactType;
}

const Owner: React.FC<OwnerProps> = (props) => {
  const { owner } = props;

  const history = useHistory();

  return (
    <Container fixed maxWidth="xl">
      <Box py={5}>
        <Typography gutterBottom>
          Name: {owner.firstName} {owner.lastName}
        </Typography>
        <Typography gutterBottom>Email: {owner.email}</Typography>
        <Typography gutterBottom>Address: {getContactAddress(owner) ? getContactAddress(owner) : 'None'}</Typography>
        <Button
          variant="contained"
          onClick={() => {
            history.push(`${PATH_CONTACT}/${owner.id}`);
          }}
        >
          Go to owner
        </Button>
      </Box>
    </Container>
  );
};

export default Owner;
