import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Box, Container, IconButton, Popover, List, ListItem } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DownloadIcon from '@mui/icons-material/Download';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { DataGridPro, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid-pro';
import {
  QUERY_AIRCRAFT_DOCUMENTS,
  QUERY_AIRCRAFT_DOCUMENT_DATA,
  MUTATION_PARSE_FLIGHTS_FROM_DOCUMENT,
  QUERY_AIRCRAFT_DOCUMENT_DOWNLOAD_URL,
} from 'gql';
import { useLazyQuery, useMutation } from '@apollo/client';

import { ComponentLoading, DeleteDialog, CustomGridToolbar } from 'components';
import { setAlert } from 'state';
import { PdfDialog, ImgDialog } from 'pages/Tickets/components/ContentComponent';
import { default as CreateLogFromDocumentDialog } from './CreateLogFromDocumentDialog';
import { default as AttachmentDescriptionDialog } from './AttachmentDescriptionDialog';
import { IAttachment } from 'types';
import { PATH_SAVVY_AIRCRAFT } from 'const';
import { useTableConfig } from 'hooks';
import { isHeic, downloadFileInNewTab } from 'helpers';
import { apolloClient } from 'services';

interface DocumentsProps {
  aircraftId: string;
  isLoad: boolean;
}

const Documents: React.FC<DocumentsProps> = (props) => {
  const { aircraftId, isLoad } = props;

  const [fetchDocuments, { data, loading }] = useLazyQuery(QUERY_AIRCRAFT_DOCUMENTS);

  const [fetchDocumentAttachment, { data: dataAttachment, loading: loadingAttachment, error: errorAttachment }] =
    useLazyQuery(QUERY_AIRCRAFT_DOCUMENT_DATA);
  const [parseDocument, { data: dataParseDocument, loading: loadingParseDocument, error: errorParseDocument }] = useMutation(
    MUTATION_PARSE_FLIGHTS_FROM_DOCUMENT,
  );

  const [showLogConfirm, setShowLogConfirm] = useState(false);
  const [documentId, setDocumentId] = useState('');
  const [fileName, setFileName] = useState('');

  const { density, setDensity } = useTableConfig();

  const [showImgFileDialog, setShowImgFileDialog] = useState(false);
  const [showPdfFileDialog, setShowPdfFileDialog] = useState(false);

  const [showFileName, setShowFileName] = useState('');
  const [showFile, setShowFile] = useState('');
  const [previewAttachmentId, setPreviewAttachmentId] = useState('');

  const [attachment, setAttachment] = useState<IAttachment | null>(null);
  const [showAttachmentDialog, setShowAttachmentDialog] = useState(false);

  const [showParseDialog, setShowParseDialog] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [filterButtonEl, setFilterButtonEl] = useState<HTMLButtonElement | null>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const sortList = (data: undefined | any[]) => {
    if (!data) {
      return [];
    }

    return [...data].sort((a: any, b: any) => {
      if (new Date(a.created) > new Date(b.created)) {
        return -1;
      } else {
        return 1;
      }
    });
  };

  const [loadingDownloadUrl, setLoadingDownloadUrl] = useState(false);

  const downloadDocument = async (documentId: string) => {
    setLoadingDownloadUrl(true);
    const { data: dataDownloadUrl } = await apolloClient.query({
      query: QUERY_AIRCRAFT_DOCUMENT_DOWNLOAD_URL,
      variables: {
        aircraftId,
        documentId,
      },
      fetchPolicy: 'no-cache',
    });

    setLoadingDownloadUrl(false);

    if (dataDownloadUrl) {
      if (dataDownloadUrl?.savvy?.aircraft?.documents[0]?.downloadUrl) {
        downloadFileInNewTab(dataDownloadUrl.savvy.aircraft.documents[0].downloadUrl);
      }
    }
  };

  const loadPreview = async (documentId: string) => {
    if (dataAttachment && dataAttachment?.savvy?.aircraft?.documents[0]?.id === documentId) {
      if (isHeic(dataAttachment?.savvy?.aircraft?.documents[0]?.fileName)) {
        if (dataAttachment?.savvy?.aircraft?.documents[0]?.base64Data) {
          setShowFile(dataAttachment?.savvy?.aircraft?.documents[0]?.base64Data);
          setPreviewAttachmentId(dataAttachment?.savvy?.aircraft?.documents[0]?.id);
          setShowFileName(dataAttachment?.savvy?.aircraft?.documents[0]?.fileName);
          setShowImgFileDialog(true);
          return;
        }
      }
    }
    await fetchDocumentAttachment({
      variables: {
        aircraftId,
        documentId,
      },
      fetchPolicy: 'network-only',
    });
  };

  const onParseAction = async () => {
    await parseDocument({
      variables: {
        aircraftId,
        documentId,
        stage: true,
      },
    });
  };

  const getAttachmentFromDocId = (documentId: string) => {
    const filterDocument = data?.savvy?.aircraft?.documents?.filter((document: any) => document.id === documentId);
    if (filterDocument) {
      return filterDocument[0].attachment;
    }
    return null;
  };

  const [pageSize, setPageSize] = useState(25);
  const [pageNum, setPageNum] = useState(0);

  useEffect(() => {
    if (aircraftId && isLoad) {
      fetchDocuments({
        variables: {
          aircraftId,
        },
      });
    }
  }, [aircraftId, isLoad, fetchDocuments]);

  useEffect(() => {
    if (errorAttachment) {
      dispatch(setAlert('error', 'Unable to load attachment'));
    } else if (dataAttachment && dataAttachment?.savvy?.aircraft?.documents[0]) {
      setShowFileName(dataAttachment?.savvy?.aircraft?.documents[0]?.fileName);
      if (isHeic(dataAttachment?.savvy?.aircraft?.documents[0]?.fileName)) {
        dataAttachment?.savvy?.aircraft?.documents[0]?.base64Data && setShowFile(dataAttachment?.savvy?.aircraft?.documents[0]?.base64Data);
      } else {
        dataAttachment?.savvy?.aircraft?.documents[0]?.previewUrl && setShowFile(dataAttachment?.savvy?.aircraft?.documents[0]?.previewUrl);
      }
      setPreviewAttachmentId(dataAttachment?.savvy?.aircraft?.documents[0]?.id);
      if (dataAttachment?.savvy?.aircraft?.documents[0]?.isImage) {
        setShowImgFileDialog(true);
      } else if (dataAttachment?.savvy?.aircraft?.documents[0]?.isPdf) {
        setShowPdfFileDialog(true);
      } else {
        dispatch(setAlert('warning', 'Unable to open your document'));
      }
    }
  }, [errorAttachment, dataAttachment, dispatch]);

  useEffect(() => {
    if (errorParseDocument) {
      dispatch(setAlert('error', 'Unable to parse flights file'));
    } else if (dataParseDocument) {
      if (dataParseDocument.parseFlightsFromDocument?.ok) {
        dispatch(setAlert('success', 'Parsed flights file'));
        history.push(`${PATH_SAVVY_AIRCRAFT}/${aircraftId}/flights`); // move to edf tab
      } else {
        dispatch(setAlert('error', 'Unable to parse flights file'));
      }
    }
  }, [errorParseDocument, dataParseDocument, dispatch, aircraftId, history]);

  return (
    <Container fixed maxWidth="xl">
      <ComponentLoading loading={loading || loadingAttachment || loadingParseDocument || loadingDownloadUrl}>
        <Box
          py={1}
          sx={{
            width: '100%',
          }}
        >
          {isLoad && (
            <DataGridPro
              sx={{
                width: '100%',
                minWidth: '100px',
                '& .MuiDataGrid-columnSeparator': {
                  display: 'none',
                },
                '& .MuiDataGrid-cell:focus': {
                  outline: 'none',
                },
                '& .MuiDataGrid-row': {
                  cursor: 'pointer',
                  borderBottom: '1px solid',
                  borderColor: 'grey.200',
                },
                '& .MuiDataGrid-columnHeader:focus': {
                  outline: 'none',
                },
                '& .MuiDataGrid-cellContent': {
                  whiteSpace: 'break-spaces',
                },
                '& .MuiDataGrid-toolbarContainer .MuiTextField-root': {
                  mb: 0,
                },
                border: 'none',
              }}
              autoHeight
              disableColumnResize={true}
              disableSelectionOnClick
              rowsPerPageOptions={[5, 10, 25, 100]}
              pageSize={pageSize}
              onPageSizeChange={(newPage) => setPageSize(newPage)}
              page={pageNum}
              onPageChange={(page) => setPageNum(page)}
              pagination
              showColumnRightBorder={false}
              disableColumnSelector
              disableColumnMenu
              density={density}
              onStateChange={(params) => {
                if (params.density && params.density.value !== density) {
                  setDensity(params.density.value);
                }
              }}
              rows={sortList(data?.savvy?.aircraft?.documents)}
              columns={[
                {
                  field: 'fileName',
                  headerName: 'File Name',
                  valueGetter: (params: GridValueGetterParams) => {
                    return params.row?.attachment?.description ? `${params.value} (${params.row?.attachment?.description})` : params.value;
                  },
                  flex: 1,
                },
                {
                  field: 'created',
                  headerName: 'Created',
                  valueFormatter: (params) => {
                    return params.value ? params.value.toString().substring(0, 10) : '';
                  },
                  flex: 0.7,
                },
                {
                  field: 'fileType',
                  headerName: 'Type',
                  flex: 0.5,
                },
                {
                  field: 'fileSize',
                  headerName: 'File Size',
                  valueFormatter: (params) => {
                    if (!params.value) return 0;

                    const kb = Math.floor(params.value / 1024);
                    if (kb < 1024) return `${kb}kb`;

                    return `${Math.floor(kb / 1024)}Mb`;
                  },
                  flex: 0.7,
                },
                {
                  field: 'id',
                  headerName: 'Actions',
                  renderCell: (params: GridRenderCellParams<string>) => {
                    return (
                      <Box>
                        <IconButton
                          title="Preview"
                          onClick={() => {
                            if (params.value) loadPreview(params.value);
                          }}
                        >
                          <VisibilityIcon />
                        </IconButton>
                        <IconButton
                          title="Download"
                          sx={{
                            ml: 1,
                          }}
                          onClick={() => {
                            if (params.value) {
                              downloadDocument(params.value);
                            }
                          }}
                        >
                          <DownloadIcon />
                        </IconButton>
                        <IconButton
                          sx={{
                            ml: 1,
                          }}
                          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                            if (params.value) {
                              setDocumentId(params.value);
                              setFileName(params.row.fileName);
                              setAnchorEl(e.currentTarget);
                              setAttachment(getAttachmentFromDocId(params.value));
                            }
                          }}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      </Box>
                    );
                  },
                  flex: 0.5,
                  sortable: false,
                  filterable: false,
                },
              ]}
              components={{ Toolbar: CustomGridToolbar }}
              componentsProps={{
                toolbar: {
                  setFilterButtonEl,
                },
                filterPanel: {
                  sx: {
                    '& .MuiDataGrid-filterForm': {
                      '& .MuiTextField-root': {
                        mb: 0,
                      },
                    },
                  },
                },
                panel: {
                  anchorEl: filterButtonEl,
                  placement: 'bottom-end',
                },
              }}
            />
          )}
        </Box>
      </ComponentLoading>
      <PdfDialog
        showFileDialog={showPdfFileDialog}
        setShowFileDialog={setShowPdfFileDialog}
        showFileName={showFileName}
        showFile={showFile}
        previewAttachmentId={previewAttachmentId}
      />
      <ImgDialog
        showFileDialog={showImgFileDialog}
        setShowFileDialog={setShowImgFileDialog}
        showFileName={showFileName}
        showFile={showFile}
        previewAttachmentId={previewAttachmentId}
      />
      <CreateLogFromDocumentDialog
        open={showLogConfirm}
        setOpen={setShowLogConfirm}
        documentId={documentId}
        aircraftId={aircraftId}
        fileName={fileName}
      />
      <AttachmentDescriptionDialog open={showAttachmentDialog} setOpen={setShowAttachmentDialog} attachment={attachment} />
      <DeleteDialog
        open={showParseDialog}
        setOpen={setShowParseDialog}
        onSubmitAction={onParseAction}
        title={`Upload ${fileName} to ${data?.savvy?.aircraft?.registration}?`}
        text={'Are you sure to parse flights file?'}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
      >
        <List component="div">
          <ListItem
            ContainerComponent="div"
            button
            onClick={() => {
              setAnchorEl(null);
              if (data?.savvy?.permissions?.aircraft?.canCreateMaintenanceEntry) {
                setShowLogConfirm(true);
              } else {
                dispatch(setAlert('warning', "You don't have permission to create maintenance record"));
              }
            }}
          >
            Use as Log
          </ListItem>
          <ListItem
            ContainerComponent="div"
            button
            onClick={() => {
              setAnchorEl(null);
              if (!attachment) {
                dispatch(setAlert('warning', "Document doesn't have attachment"));
              } else {
                setShowAttachmentDialog(true);
              }
            }}
          >
            Attachment Description
          </ListItem>
          <ListItem
            ContainerComponent="div"
            button
            onClick={() => {
              setAnchorEl(null);
              setShowParseDialog(true);
            }}
          >
            Upload as Engine Data File
          </ListItem>
        </List>
      </Popover>
    </Container>
  );
};

export default Documents;
