import React from 'react';
import { Box, Typography, Grid, IconButton } from '@mui/material';
import { IBorescopeImageSet, IAircraftDetail, IBorescopeImageFileType } from 'types';
import DownloadIcon from '@mui/icons-material/Download';
import { getSubjectLabel } from 'helpers';

interface BorescopeReportContextProps {
  downloadReport: (borescopeImageSetId: string) => void;
  imageSet: IBorescopeImageSet;
  aircraft: IAircraftDetail;
  cylinderCount: number;
  subjectList: string[][];
  images: IBorescopeImageFileType[];
}

const BorescopeReportContext: React.FC<BorescopeReportContextProps> = (props) => {
  const { downloadReport, imageSet, aircraft, cylinderCount, subjectList, images } = props;

  const getSubjectIndex = (subject: string) => {
    if (!subjectList?.length) {
      return -1;
    }
    for (let i = 0; i < subjectList.length; i++) {
      if (subjectList[i][0] === subject) {
        return i;
      }
    }
    return -1;
  };

  return (
    <Box
      sx={{
        '@media (min-width: 1440px)': {
          width: '1000px',
        },
        '@media (max-width: 1399px) and (min-width: 1280px)': {
          width: '880px',
        },
        width: '1000px',
        border: '1px solid',
        borderColor: 'grey.200',
        maxHeight: {
          lg: 'calc(100vh - 150px)',
          xs: 'none',
        },
        height: 'auto',
        overflowY: {
          lg: 'auto',
          xs: 'hidden',
        },
        py: 2.5,
      }}
    >
      <Box px={2.5}>
        <Box
          py={1}
          sx={{
            borderBottom: '2px solid',
            borderColor: 'text.secondary',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box component="img" src={'/images/logo.svg'} />
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: '20px',
                  lineHeight: '28px',
                }}
              >
                Borescope Analysis Report
              </Typography>
              <IconButton
                onClick={async () => {
                  if (imageSet.id) {
                    await downloadReport(imageSet.id);
                  }
                }}
                data-testid={'download_ba_report'}
              >
                <DownloadIcon />
              </IconButton>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            minHeight: '100%',
          }}
          pt={2}
        >
          <Box>
            <Box
              sx={{
                px: 2.5,
                py: 1.25,
                borderRadius: '5px',
                backgroundColor: 'grey.500',
              }}
            >
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: '16px',
                  lineHeight: '22px',
                  color: 'text.secondary',
                  textTransform: 'uppercase',
                }}
              >
                Client Comments
              </Typography>
            </Box>
            <Box py={1.5} px={2.5}>
              <Typography>{imageSet?.clientComments || 'None'}</Typography>
            </Box>
          </Box>
          <Box>
            <Box
              sx={{
                px: 2.5,
                py: 1.25,
                borderRadius: '5px',
                backgroundColor: 'grey.500',
              }}
            >
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: '16px',
                  lineHeight: '22px',
                  color: 'text.secondary',
                  textTransform: 'uppercase',
                }}
              >
                Comments
              </Typography>
            </Box>
            <Box py={1.5} px={2.5}>
              <Typography>{imageSet?.comments || 'None'}</Typography>
            </Box>
          </Box>
          {Array.apply(null, Array(cylinderCount)).map((value, key: number) => {
            if (!images.filter((item) => item.cylinder === key + 1).length) {
              return null;
            }
            return (
              <Box key={key}>
                <Box
                  sx={{
                    px: 2.5,
                    py: 1.25,
                    borderRadius: '5px',
                    backgroundColor: 'grey.500',
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 700,
                      fontSize: '16px',
                      lineHeight: '22px',
                      color: 'text.secondary',
                      textTransform: 'uppercase',
                    }}
                  >
                    Cylinder {key + 1}
                  </Typography>
                </Box>
                <Box py={1.25}>
                  <Grid container spacing={0}>
                    {images
                      .sort((item1: IBorescopeImageFileType, item2: IBorescopeImageFileType) =>
                        getSubjectIndex(item1.subject) > getSubjectIndex(item2.subject) ? 1 : -1,
                      )
                      .map((item: IBorescopeImageFileType, key1: number) => {
                        if (item.cylinder !== key + 1) {
                          return null;
                        }
                        return (
                          <Grid item xs={12} md={6} key={`${key}_${key1}`}>
                            <Box pb={2.5}>
                              <Grid container spacing={2}>
                                <Grid item xs={12} md={6} lg={6} xl={6}>
                                  <Box>
                                    <Box
                                      component={'img'}
                                      src={item.previewUrl}
                                      sx={{
                                        maxWidth: '500px',
                                        width: '100%',
                                        borderRadius: '5px',
                                        height: '175px',
                                        objectFit: 'cover',
                                      }}
                                    />
                                  </Box>
                                </Grid>
                                <Grid item xs={12} md={6} lg={6} xl={6}>
                                  <Box
                                    sx={{
                                      display: 'inline-block',
                                      px: 1,
                                      py: 0.5,
                                      borderRadius: '6px',
                                      backgroundColor: item.imageAnalysisState === 'Satisfactory' ? '#D1F2D8' : '#F7E8D3',
                                      mb: 0.5,
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontWeight: 700,
                                        fontSize: '13px',
                                        lineHeight: '18px',
                                        color: item.imageAnalysisState === 'Satisfactory' ? 'success.dark' : 'warning.main',
                                      }}
                                    >
                                      {item.imageAnalysisState}
                                    </Typography>
                                  </Box>
                                  <Typography
                                    variant="h4"
                                    sx={{
                                      textTransform: 'capitalize',
                                      fontWeight: 700,
                                      fontSize: '15px',
                                      lineHeight: '20px',
                                      color: 'text.secondary',
                                      mb: 0.5,
                                    }}
                                  >
                                    {getSubjectLabel(subjectList, item.subject)}
                                  </Typography>
                                  <Box pr={1}>
                                    <Typography
                                      sx={{
                                        fontSize: '13px',
                                        lineHeight: '20px',
                                      }}
                                    >
                                      {item.textualStatus}
                                    </Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>
                        );
                      })}
                  </Grid>
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
      <Box
        py={2.5}
        sx={{
          borderTop: '1px solid',
          borderBottom: '1px solid',
          borderColor: 'grey.200',
        }}
      >
        <Box px={2.5}>
          <Grid container spacing={0}>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  display: 'flex',
                  py: 0.5,
                }}
              >
                <Box
                  sx={{
                    width: '40%',
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: '13px',
                      lineHeight: '18px',
                      color: 'grey.600',
                    }}
                  >
                    Client
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: '60%',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '13px',
                      lineHeight: '18px',
                      color: 'text.secondary',
                    }}
                  >
                    {aircraft.owner?.firstName || ''} {aircraft.owner?.lastName || ''}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  display: 'flex',
                  py: 0.5,
                }}
              >
                <Box
                  sx={{
                    width: '40%',
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: '13px',
                      lineHeight: '18px',
                      color: 'grey.600',
                    }}
                  >
                    A/C Type
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: '60%',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '13px',
                      lineHeight: '18px',
                      color: 'text.secondary',
                    }}
                  >
                    {aircraft.aircraftManufacturer?.name || ''} {aircraft.aircraftModel?.name || ''}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  display: 'flex',
                  py: 0.5,
                }}
              >
                <Box
                  sx={{
                    width: '40%',
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: '13px',
                      lineHeight: '18px',
                      color: 'grey.600',
                    }}
                  >
                    Aircraft
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: '60%',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '13px',
                      lineHeight: '18px',
                      color: 'text.secondary',
                    }}
                  >
                    {aircraft.registration || ''}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  display: 'flex',
                  py: 0.5,
                }}
              >
                <Box
                  sx={{
                    width: '40%',
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: '13px',
                      lineHeight: '18px',
                      color: 'grey.600',
                    }}
                  >
                    Engine
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: '60%',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '13px',
                      lineHeight: '18px',
                      color: 'text.secondary',
                    }}
                  >
                    {aircraft.engineManufacturer?.name || ''} {aircraft.engineModel?.name || ''}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  display: 'flex',
                  py: 0.5,
                }}
              >
                <Box
                  sx={{
                    width: '40%',
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: '13px',
                      lineHeight: '18px',
                      color: 'grey.600',
                    }}
                  >
                    Borescope Image Set
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: '60%',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '13px',
                      lineHeight: '18px',
                      color: 'text.secondary',
                    }}
                  >
                    {aircraft.borescopeImageSets[0]?.name || ''}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  display: 'flex',
                  py: 0.5,
                }}
              >
                <Box
                  sx={{
                    width: '40%',
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: '13px',
                      lineHeight: '18px',
                      color: 'grey.600',
                    }}
                  >
                    Monitor
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: '60%',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '13px',
                      lineHeight: '18px',
                      color: 'text.secondary',
                    }}
                  >
                    {aircraft.engineMonitorManufacturer
                      ? `${aircraft.engineMonitorManufacturer?.name || ''} ${aircraft.engineMonitorModel?.name || ''}`
                      : 'None'}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            {aircraft?.borescopeImageSets[0]?.reportCreator && (
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    display: 'flex',
                    py: 0.5,
                  }}
                >
                  <Box
                    sx={{
                      width: '40%',
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 600,
                        fontSize: '13px',
                        lineHeight: '18px',
                        color: 'grey.600',
                      }}
                    >
                      Created By
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: '60%',
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '13px',
                        lineHeight: '18px',
                        color: 'text.secondary',
                      }}
                    >
                      {`${aircraft.borescopeImageSets[0].reportCreator.firstName} ${aircraft.borescopeImageSets[0].reportCreator.lastName}`}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>
      <Box pt={2.5}>
        <Typography
          align="center"
          sx={{
            fontWeight: 600,
            fontSize: '13px',
            lineHeight: '18px',
            color: 'grey.600',
          }}
        >
          Copyright 2012-{new Date().getFullYear()} by Savvy Aviation, Inc. All rights reserved.
        </Typography>
      </Box>
    </Box>
  );
};

export default BorescopeReportContext;
