import { useState, useEffect } from 'react';

import { useLazyQuery } from '@apollo/client';
import { QUERY_FUZZY_AIRCRAFT_ENGINE_MANUFACTURERS } from 'gql';

import { IOptionIdName } from 'types';

import { isEmpty } from 'helpers';

interface IUseFuzzyEngineManufacturers {
  engineManufacturer: IOptionIdName; // Input value (text field value which triggers re-fetch sugestions)
  engineManufacturerInput: string;
}

// Get Aircraft Engine Manufacturer Suggestions (fuzzy). When type in input
const useFuzzyEngineManufacturers = (props: IUseFuzzyEngineManufacturers) => {
  const { engineManufacturer, engineManufacturerInput } = props;

  // Suggestions Initial State
  const [fuzzy, setFuzzy] = useState<IOptionIdName[]>([]);

  // GraphQL Definition
  const [refetch, { data }] = useLazyQuery(QUERY_FUZZY_AIRCRAFT_ENGINE_MANUFACTURERS, {
    fetchPolicy: 'no-cache',
  });

  // Response
  useEffect(() => {
    // Success
    data && setFuzzy(data?.fuzzyEngineManufacturers); // Set suggestions array - id/name
  }, [data]);

  // Refetch Suggestions. When input changed
  useEffect(() => {
    // If input is empty do NOT refetch
    if (isEmpty(engineManufacturerInput) || engineManufacturerInput === '-- Add your own --') {
      return setFuzzy([]); // Clean suggestions and stop (return) (do NOT refetch)
    }
    refetch({ variables: { search: engineManufacturerInput } });
  }, [engineManufacturer, engineManufacturerInput, refetch]);

  return fuzzy;
};

export default useFuzzyEngineManufacturers;
