import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Box, Button, Typography } from '@mui/material';
import { LayoutNormal, PageLoading } from 'components';

import { MUTATION_CREATE_TOKEN } from 'gql';
import { useMutation } from '@apollo/client';
import { MAX_API_TOKEN_NAME } from 'const';

import { setAlert } from 'state';

const useQuery = () => new URLSearchParams(useLocation().search);

const CreateAppToken: React.FC = () => {
  const query = useQuery();
  const name = query.get('app_name');
  const callback = query.get('callback_url');

  const [createTokenMutation, { data: dataCreateTokenMutation, error: errorCreateTokenMutation, loading: loadingCreateTokenMutation }] =
    useMutation(MUTATION_CREATE_TOKEN, {
      errorPolicy: 'all',
    });
  const dispatch = useDispatch();

  const [token, setToken] = useState('');

  const createToken = async () => {
    if (!name) {
      return;
    }
    if (name.length > MAX_API_TOKEN_NAME) {
      dispatch(setAlert('error', `Too long: 3-${MAX_API_TOKEN_NAME} characters required`));
      return;
    }
    if (name) {
      await createTokenMutation({
        variables: {
          name,
        },
      });
    }
  };

  const backToCallback = () => {
    if (token && callback) {
      window.location.href = `${callback}?token=${token}`;
    }
  };

  useEffect(() => {
    if (errorCreateTokenMutation) {
      dispatch(setAlert('error', 'Unable to create new token'));
    } else if (dataCreateTokenMutation) {
      const { me, ok, error } = dataCreateTokenMutation.createToken;

      if (ok) {
        dispatch(setAlert('success', 'Created New Token'));
        if (me.apiTokens?.length) {
          for (let i = 0; i < me.apiTokens?.length; i++) {
            if (me.apiTokens[i].name === name) {
              setToken(me.apiTokens[i].token);
              return;
            }
          }
        }
      } else {
        dispatch(setAlert('error', error || 'Unable to create new token'));
      }
    }
  }, [dataCreateTokenMutation, errorCreateTokenMutation, dispatch]);

  return (
    <LayoutNormal>
      {loadingCreateTokenMutation && <PageLoading />}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box py={1}>
          {!token && (
            <Typography align="center" variant="h3" gutterBottom>
              Click on the button below to create an authentication token and share it with the {name} app.
              <br />
              The app will be able to access your aircraft list and upload files.
            </Typography>
          )}
          {!!token && (
            <Typography align="center" variant="h3" gutterBottom>
              Token created. Click the button below to open the application.
            </Typography>
          )}
          <Box
            sx={{
              textAlign: 'center',
            }}
          >
            <Button
              variant="contained"
              onClick={() => {
                if (token) {
                  backToCallback();
                } else {
                  createToken();
                }
              }}
            >
              {token ? `Go to ${name}` : 'Create'}
            </Button>
          </Box>
        </Box>
      </Box>
    </LayoutNormal>
  );
};

export default CreateAppToken;
