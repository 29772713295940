import Highcharts from 'highcharts';
import { isMatchedGamiSerie } from 'helpers';

class GamiManager {
  gami_array: any[];
  range: any;
  originalData: any[];
  gamiData: any;

  constructor() {
    this.gami_array = [];
    this.range = {};
    this.originalData = [];
    this.gamiData = {};
  }

  init(this: GamiManager) {
    this.gami_array = [];
    this.range = {};
    this.originalData = [];
    this.gamiData = {};
  }

  setOriginalData(this: GamiManager, chartData: any[]) {
    this.originalData = chartData;
  }

  drawLines(this: GamiManager, chart: Highcharts.Chart) {
    const xpoints: any = {};
    let direction = 1;

    const gamiLineData: any[] = [];

    for (let i = 0; i < this.gami_array.length; i++) {
      let offset = this.gami_array[i].offset;

      while (Object.prototype.hasOwnProperty.call(xpoints, offset)) {
        offset = offset + 2 * direction;
      }

      xpoints[offset] = true;
      if (this.gami_array[i].offset !== offset) {
        direction = -1;
      }

      chart.renderer
        .path(['M', offset, this.gami_array[i]['yoffset'], 'L', offset, this.gami_array[i]['foffset']])
        .attr({
          'stroke-width': 2,
          stroke: this.gami_array[i]['color'],
          'dash-array': '1,1',
          id: 'gami-line-' + i,
        })
        .add();

      gamiLineData.push({
        name: this.gami_array[i].name,
        position: this.gami_array[i]['ff_y'],
      });
    }

    this.gamiData.data = [...gamiLineData];
  }

  indexOfPeak(this: GamiManager, chartData: any[], from: number, to: number, isOriginal: boolean) {
    let max = 0;
    let max_index_first = -1;
    let max_index_last = -1;
    let current;
    for (let index = 0; index < chartData.length; index++) {
      const timestamp = isOriginal ? chartData[index][0] : chartData[index] ? chartData[index].x : undefined;
      if (!timestamp) continue;
      if (timestamp < from || timestamp > to) continue;

      if (isOriginal) current = chartData[index][1];
      else current = chartData[index].y;

      if (current > max) {
        max_index_first = index;
        max = current;
      }
      if (current >= max) {
        max_index_last = index;
      }
    }
    if (max_index_first === max_index_last) return max_index_first;
    else return Math.round((max_index_last - max_index_first) / 2) + max_index_first;
  }

  findOriginalData(this: GamiManager, name: string) {
    for (let i = 0; i < this.originalData.length; i++) {
      if (this.originalData[i]?.name === name) {
        return this.originalData[i].data;
      }
    }

    return [];
  }

  addGamiLines(this: GamiManager, chart: Highcharts.Chart, start: number, end: number) {
    if (start > end) {
      const temp = start;
      start = end;
      end = temp;
    }

    this.range[chart.container.id] = {
      start: start,
      end: end,
    };

    let fuel_serie: any = undefined;

    let firstChart: Highcharts.Chart | undefined = undefined;

    for (const chart of Highcharts.charts) {
      if (!chart) continue;
      else {
        firstChart = chart;
        break;
      }
    }

    if (!firstChart) return;

    for (const serie of firstChart.series) {
      if (serie.name.match(/([LR]-)?FF/)) {
        fuel_serie = serie;
      }
    }

    if (!fuel_serie) return;

    const fuel_data = this.findOriginalData(fuel_serie.name);

    this.removeGamiLines();

    this.gami_array = [];
    this.gamiData = {
      selectedPoint: Highcharts.dateFormat('%H:%M:%S', start),
      secondaryPoint: Highcharts.dateFormat('%H:%M:%S', end),
    };

    const maxYOffset = (chart.xAxis[0] as any).axisLine?.pathArray[0][2];

    for (const serie of firstChart.series as any) {
      if (serie.visible && isMatchedGamiSerie(serie.name)) {
        const index = this.indexOfPeak(serie.data, start, end, false);
        const originalIndex = this.indexOfPeak(this.findOriginalData(serie.name), start, end, true);

        const point_index = index - serie.cropStart;
        const data_point = fuel_data[originalIndex];

        if (!serie.points[point_index]) continue;

        const offset = chart.plotLeft + serie.points[point_index].plotX;
        if (typeof data_point === 'object') {
          this.gami_array.push({
            name: serie.name,
            ff_y: data_point[1],
            x: data_point[0],
            color: serie.color,
            offset: offset,
            yoffset: Math.min(chart.plotTop + serie.points[point_index].plotY, maxYOffset ? maxYOffset : 9999),
            foffset: chart.plotTop + (fuel_serie?.points[point_index]?.plotY || 0),
          });
        } else {
          this.gami_array.push([serie.name, data_point, 0]);
        }
      }
    }

    const compare_gami = function (a: any, b: any) {
      return b['ff_y'] - a['ff_y'];
    };

    this.gami_array.sort(compare_gami);

    this.drawLines(chart);

    if (this.gami_array.length < 2) {
      this.gamiData.error = true;
    } else {
      this.gamiData.spread = Math.abs(this.gami_array[0]['ff_y'] - this.gami_array[this.gami_array.length - 1]['ff_y']).toFixed(1);
    }
  }

  removeGamiLines(this: GamiManager) {
    for (let i = 0; i < this.gami_array.length; i++) {
      const element = document.getElementById('gami-line-' + i);
      element?.remove();
    }
  }

  redrawGamiLines(this: GamiManager, chart: Highcharts.Chart | undefined) {
    if (!chart) return;

    if (this.range && this.range[chart.container.id]) {
      this.addGamiLines(chart, this.range[chart.container.id].start, this.range[chart.container.id].end);
      return;
    }
  }

  getGamiData(this: GamiManager) {
    return this.gamiData;
  }
}

export default GamiManager;
