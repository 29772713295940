import React from 'react';
import { CSSObject } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';

interface PersonIconProps {
  sx?: CSSObject;
}

const PersonIcon: React.FC<PersonIconProps> = (props) => {
  const { sx } = props;

  return (
    <SvgIcon
      viewBox="0 0 24 24"
      sx={{
        width: '20px',
        height: '20px',
        ...sx,
      }}
    >
      <path d="M 12 12 c 3.315 0 6 -2.685 6 -6 s -2.685 -6 -6 -6 s -6 2.685 -6 6 s 2.685 6 6 6 z m 0 3 c -4.005 0 -12 2.01 -12 6 v 3 h 24 v -3 c 0 -3.99 -7.995 -6 -12 -6 z" />
    </SvgIcon>
  );
};

export default PersonIcon;
