import React from 'react';
import { NavLink } from 'react-router-dom';
import { Box, Typography, Tooltip } from '@mui/material';
import { TicketInfoIcon, SupportTicketIcon } from 'customIcons';
import { IAircraftEligibility } from 'types';
import { PATH_TICKETS_CREATE } from 'const';

interface SupportCategoryProps {
  title: string;
  mappingArr: {
    field: string;
    label: string;
    urlType: string;
  }[];
  aircraft: IAircraftEligibility | undefined;
}

const SupportCategory: React.FC<SupportCategoryProps> = (props) => {
  const { title, mappingArr, aircraft } = props;

  return (
    <Box
      pt={2.5}
      mx={2}
      sx={{
        border: '1px solid #E8E9EF',
        borderRadius: '6px',
        width: {
          md: 'calc(50% - 15px)',
          xs: '100%',
        },
        mb: {
          md: 0,
          xs: 1.5,
        },
        textAlign: 'center',
      }}
    >
      <Box
        sx={{
          width: '100px',
          height: '100px',
          backgroundColor: 'grey.800',
          borderRadius: '100%',
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <SupportTicketIcon
          sx={{
            width: '32px',
            height: '42px',
          }}
        />
      </Box>
      <Box py={1.25}>
        <Typography
          variant="h2"
          sx={{
            fontWeight: 700,
            fontSize: '20px',
            lineHeight: '27px',
            color: 'text.secondary',
          }}
        >
          {title}
        </Typography>
      </Box>
      <Box py={1.5} pl={3.5}>
        {mappingArr.map((item: any, key: number) => {
          return (
            <Box key={key} mb={1.5}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Tooltip title={item.tooltip ? item.tooltip : ''} placement="top" arrow>
                  <Box
                    sx={{
                      cursor: 'pointer',
                      mr: 1,
                      display: 'inline-flex',
                    }}
                  >
                    <TicketInfoIcon
                      sx={{
                        fill: 'none',
                      }}
                    />
                  </Box>
                </Tooltip>
                <NavLink to={aircraft ? `${PATH_TICKETS_CREATE}/${item.urlType}/${aircraft.id}` : `${PATH_TICKETS_CREATE}/${item.urlType}`}>
                  <Typography
                    sx={{
                      color: 'primary.main',
                      fontWeight: 600,
                      fontSize: '14px',
                      lineHeight: '19px',
                      textAlign: 'left',
                      cursor: 'pointer',
                    }}
                  >
                    {item.label}
                    <Box
                      component="span"
                      sx={{
                        fontSize: '13px',
                        fontWeight: 500,
                        color: 'grey.400',
                        whiteSpace: 'nowrap',
                        marginLeft: '8px',
                      }}
                    >
                      free
                    </Box>
                  </Typography>
                </NavLink>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default SupportCategory;
