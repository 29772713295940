import React from 'react';

import { Typography, Link, Button, List, ListItem } from '@mui/material';

import { Subsection } from 'components';

import { IOptionIdName } from 'types';

interface IAircraftSuggestions {
  label: string;
  suggestions: IOptionIdName[];
  handleSuggestionClick: (value: IOptionIdName) => void; // Set autocomplete value
  notListLabel?: string;
  notListClick?: () => void;
}

const AircraftSuggestions: React.FC<IAircraftSuggestions> = (props) => {
  const { label, suggestions, handleSuggestionClick, notListLabel, notListClick } = props;

  // If suggestions not fetched yet (GraphQL data undefined) or suggestions empty
  if (typeof suggestions === 'undefined' || suggestions === null || suggestions?.length === 0) {
    return null; // Return null
  }

  return (
    <Subsection>
      <Typography variant="h4">{label}</Typography>
      <List>
        {suggestions.map((suggestion: IOptionIdName, key: number) => {
          return (
            <ListItem key={key} style={{ padding: '4px 8px' }}>
              <Link onClick={() => handleSuggestionClick(suggestion)}>
                <Typography variant="subtitle1">{suggestion.name}</Typography>
              </Link>
            </ListItem>
          );
        })}
      </List>
      {Boolean(notListLabel) && (
        <Button color="primary" variant="contained" onClick={notListClick}>
          {notListLabel}
        </Button>
      )}
    </Subsection>
  );
};

export default AircraftSuggestions;
