import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Typography,
  Grid,
  Link,
  ToggleButtonGroup,
  ToggleButton,
  Switch,
  Tooltip,
  Popover,
  ClickAwayListener,
  IconButton,
  Grow,
  Paper,
  List,
  ListItem,
  Button,
  styled,
  CSSObject,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ChatIcon from '@mui/icons-material/Chat';
import MenuIcon from '@mui/icons-material/Menu';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import EditIcon from '@mui/icons-material/Edit';
import EmailIcon from '@mui/icons-material/Email';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';

import { ISavvyTicket, ICannedResponse, IAssignee, IComment, IFlight, IBorescopeImageSet } from 'types';
import { humanDateTime, getTagName, secondsToHms, humanDate, isSharedLog, downloadFileInNewTab, existingTextOnUpdate } from 'helpers';
import {
  PATH_FLIGHT_VIEW,
  PATH_AIRCRAFT_VIEW,
  PATH_CONTACT,
  PATH_SAVVY_AIRCRAFT,
  PATH_SERVICE_CENTER_MAP,
  PATH_SHARE_LOGS,
  PATH_FLIGHT_REPORT,
  PATH_INTERNAL_SERVICE_CENTER,
  PATH_SAVVY_BORESCOPE_ANALYSIS_REPORT_GENERATE,
  PATH_SAVVY_BORESCOPE_ANALYSIS_REPORT_VIEW,
} from 'const';
import { setAlert } from 'state';

import { NotHappyDialog } from 'pages/Tickets/components';
import { AddLogDialog } from 'pages/Aircrafts/components/Dialog';
import {
  CannedResponseControl,
  AddWatcherPopupDialog,
  AddAssignPopupDialog,
  AttachmentsList,
  EditSubjectDialog,
  TimeManager,
  ConfirmDeleteTagModal,
  AddUserTagComponent,
  RelatedTickets,
  AddTagDialog,
  ConsumedBar,
  ContactTooltip,
  AssignManagersDialog,
  WatcherColor,
  ShareLogsDialog,
} from './TicketInfoComponents';

import {
  MUTATION_SAVVY_TICKET_TEXT_ON_UPDATE,
  MUTATION_SAVVY_TICKET_DONT_REQUEST_RATING,
  MUTATION_REMOVE_WATCHER_FROM_TICKET,
  MUTATION_UNSNOOZE_TICKET,
  MUTATION_UPDATE_TICKET_PRIORITY,
  MUTATION_RESET_UNHAPPY_STATUS,
  QUERY_SAVVY_AIRCRAFT_LOGS,
  QUERY_GET_SAVVY_TICKET_PERMISSIONS,
  MUTATION_EXPORT_TICKET,
  QUERY_SAVVY_FLIGHT_REPORT_DOWNLOAD_URL,
  QUERY_PERSONAL_TICKET_DASHBOARD,
  QUERY_SAVVY_TEXT_ON_UPDATE,
  QUERY_SAVVY_RELATED_TICKETS_FOR_DETAIL,
} from 'gql';
import { useMutation, useLazyQuery, useQuery } from '@apollo/client';
import { primaryDark, grey600 } from 'theme/variables';
import { apolloClient } from 'services';
import { ComponentLoading } from 'components';

const classes = {
  grayFieldTypo: 'grayFieldTypo',
  optionTypo: 'optionTypo',
};

const StyledDiv = styled('div')({
  [`& .${classes.grayFieldTypo}`]: {
    fontSize: '13px !important',
    color: grey600,
  },
  [`& .${classes.optionTypo}`]: {
    fontSize: '14px !important',
    color: primaryDark,
  },
});

interface TicketInfoBoxProps {
  ticket: ISavvyTicket | undefined;
  closeTicket?: (id: number, close: boolean) => void;
  cannedResponses: ICannedResponse[] | undefined;
  setCannedResponseText?: (value: string) => void;
  allowedAssignees: IAssignee[];
  canResetUnhappy: boolean;
}

const tooltipSx: CSSObject = {
  backgroundColor: 'background.default',
  border: '1px solid',
  borderColor: 'grey.200',
  boxShadow: '0px 1px 1px rgba(19, 30, 64, 0.06)',
  borderRadius: '6px !important',
  '& p': {
    color: 'text.primary',
  },
};

const TicketInfoBox: React.FC<TicketInfoBoxProps> = (props) => {
  const { ticket, closeTicket, cannedResponses, setCannedResponseText, allowedAssignees, canResetUnhappy } = props;

  const dispatch = useDispatch();

  const [openEditSubject, setOpenEditSubject] = useState(false);
  const { id: viewerId } = useSelector((state: any) => state.auth);

  const [openNotHappy, setOpenNotHappy] = useState(false);

  const [setTextOnUpdate] = useMutation(MUTATION_SAVVY_TICKET_TEXT_ON_UPDATE);
  const [setRequestRating] = useMutation(MUTATION_SAVVY_TICKET_DONT_REQUEST_RATING);

  const { data: dataSavvyTextOnUpdate } = useQuery(QUERY_SAVVY_TEXT_ON_UPDATE, {
    fetchPolicy: 'cache-and-network',
  });

  const [updateTicketDashboard] = useLazyQuery(QUERY_PERSONAL_TICKET_DASHBOARD, {
    fetchPolicy: 'cache-and-network',
  });
  const [removeWatcherMutation, { data: dataRemoveWatcherMutation, error: errorRemoveWatcherMutation }] = useMutation(
    MUTATION_REMOVE_WATCHER_FROM_TICKET,
    {
      errorPolicy: 'all',
    },
  );
  const [fetchSavvyPermissions, { data: dataSavvyPermissions }] = useLazyQuery(QUERY_GET_SAVVY_TICKET_PERMISSIONS);
  const [fetchRelatedTickets, { data: dataRelatedTickets, loading: loadingRelatedTickets }] = useLazyQuery(
    QUERY_SAVVY_RELATED_TICKETS_FOR_DETAIL,
  );
  const [exportTicket, { data: dataExportTicket, error: errorExportTicket }] = useMutation(MUTATION_EXPORT_TICKET);

  const [fetchAircraftLogs, { data: dataAircraftLogs }] = useLazyQuery(QUERY_SAVVY_AIRCRAFT_LOGS);

  const [unsnoozeMutation, { data: dataUnsnoozeMutation, error: errorUnsnoozeMutation }] = useMutation(MUTATION_UNSNOOZE_TICKET);

  const [updateTicketMutation, { data: dataTicketMutation, error: errorTicketMutation }] = useMutation(MUTATION_UPDATE_TICKET_PRIORITY);
  const [analysisFlights, setAnalysisFlights] = useState<
    {
      tagId: string;
      flight: IFlight;
      reportId: string;
    }[]
  >([]);

  const [borescopeImageSets, setBorescopeImageSets] = useState<
    {
      tagId: string;
      borescopeImageSet: IBorescopeImageSet;
      reportId: string;
    }[]
  >([]);

  const [resetUnhappyTicket, { data: dataUnhappyTicket, error: errorUnhappyTicket }] = useMutation(MUTATION_RESET_UNHAPPY_STATUS);

  const [textOnUpdateFlg, setTextOnUpdateFlg] = useState(false);
  const [requestRatingFlg, setRequestRatingFlg] = useState(ticket?.dontRequestRating);

  const [deleteTagId, setDeleteTagId] = useState('');
  const [deleteTagConfirm, setDeleteTagConfirm] = useState(false);
  const [deleteTagName, setDeleteTagName] = useState('');

  const [openAssignManagers, setOpenAssignManagers] = useState(false);

  const [showAddTag, setShowAddTag] = useState(false);

  const [showHamburger, setShowHamburger] = useState(false);

  const [showLog, setShowLog] = useState(false);

  const [showShareLog, setShowShareLog] = useState(false);
  const [previousLogIds, setPreviousLogIds] = useState<string[]>([]);

  const [showWatcherColorAnchorEl, setShowWatcherColorAnchorEl] = useState<HTMLElement | null>(null);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [tooltipContactId, setTooltipContactId] = useState('');
  const [tooltipEmail, setTooltipEmail] = useState('');
  const handleTooltipOpen = (event: React.MouseEvent<HTMLElement>, id: string, email?: string) => {
    setAnchorEl(event.currentTarget);
    if (id && !email) {
      setTooltipContactId(id);
      setTooltipEmail('');
    } else if (!id && email) {
      setTooltipContactId('');
      setTooltipEmail(email);
    }
  };

  const handleTooltipClose = () => {
    setAnchorEl(null);
    setTooltipContactId('');
    setTooltipEmail('');
  };

  const [loadingReportDownloadUrl, setLoadingReportDownloadUrl] = useState(false);

  const downloadReport = async (aircraftId: string, reportId: string) => {
    if (aircraftId && reportId) {
      setLoadingReportDownloadUrl(true);
      const { data: dataDownloadUrl } = await apolloClient.query({
        query: QUERY_SAVVY_FLIGHT_REPORT_DOWNLOAD_URL,
        variables: {
          aircraftId,
          reportId,
        },
        fetchPolicy: 'no-cache',
      });
      setLoadingReportDownloadUrl(false);
      if (dataDownloadUrl?.savvy?.aircraft?.flightReports[0]?.downloadUrl) {
        downloadFileInNewTab(dataDownloadUrl?.savvy?.aircraft?.flightReports[0]?.downloadUrl);
      }
    }
  };

  const openTooltip = Boolean(anchorEl);

  const isVisibleNotHappy = (ticketValue: ISavvyTicket) => {
    return ticketValue?.canSetNotHappy && !ticketValue?.notHappy;
  };

  const dontRequestRating = async () => {
    if (!ticket?.id) return;

    setRequestRatingFlg(!requestRatingFlg);

    await setRequestRating({
      variables: {
        ticketId: ticket?.id,
        status: !requestRatingFlg,
      },
    });
  };

  const textOnUpdate = async () => {
    if (!ticket?.id) return;

    setTextOnUpdateFlg(!textOnUpdateFlg);

    await setTextOnUpdate({
      variables: {
        ticketId: ticket?.id,
        status: !textOnUpdateFlg,
      },
    });
  };

  const getPriority = (value: string) => {
    if (value === 'N') {
      return 'Normal';
    } else if (value === 'A') {
      return 'Aircraft Not Flyable';
    } else if (value === 'B') {
      return 'Breakdown Assistance';
    } else if (value === 'U') {
      return 'Urgent';
    } else {
      return '?';
    }
  };

  const removeWatcher = async (watcherId: string) => {
    if (!ticket?.id) return;

    await removeWatcherMutation({
      variables: {
        watcherId,
        ticketId: ticket.id,
      },
    });
  };

  const isMxOrPrebuySignupTag = (tags: any[]) => {
    for (let i = 0; i < tags.length; i++) {
      if (tags[i].name === 'service-signup' && (tags[i].service.serviceCode === 'mx' || tags[i].service.serviceCode === 'prebuy'))
        return true;
    }
    return false;
  };

  const isServiceOrAnnualTag = (tags: any[]) => {
    for (let i = 0; i < tags.length; i++) {
      if (tags[i].name === 'service' || tags[i].reportingTag?.name === 'service-annual') {
        return true;
      }
    }

    return false;
  };

  const openAddLog = async () => {
    if (ticket?.aircraft) {
      setShowLog(true);
      await fetchAircraftLogs({
        variables: {
          aircraftId: ticket.aircraft?.id,
        },
      });
    }
  };

  const submitExportTicket = async (ticketId: string) => {
    await exportTicket({
      variables: {
        ticketId,
      },
    });
  };

  const unsnooze = async () => {
    if (!ticket?.id) return;

    await unsnoozeMutation({
      variables: {
        ticketId: ticket.id,
      },
    });
  };

  const renderMobileBrief = () => {
    if (!ticket) return null;
    return (
      <Box
        sx={{
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            py: 1,
            width: '100%',
            '& p': {
              fontWeight: 600,
            },
          }}
        >
          <Typography
            className={classes.grayFieldTypo}
            sx={{
              width: '40%',
            }}
          >
            Status
          </Typography>
          <Typography
            sx={{
              width: '60%',
              color: ticket.state.toLowerCase() !== 'c' ? 'success.dark' : 'text.secondary',
            }}
            className={classes.optionTypo}
          >
            {ticket.state.toLowerCase() === 'c' ? 'Closed' : 'Active'}
          </Typography>
        </Box>
        {ticket.state.toLowerCase() !== 'c' && (
          <Box
            sx={{
              display: 'flex',
              py: 1,
              width: '100%',
              '& p': {
                fontWeight: 600,
              },
            }}
          >
            <Typography
              className={classes.grayFieldTypo}
              sx={{
                width: '40%',
              }}
            >
              Services
            </Typography>
            <Box
              sx={{
                width: '60%',
              }}
            >
              {!!ticket.aircraft?.subscriptions?.length &&
                ticket.aircraft?.subscriptions?.map((subscription: any, key: number) => {
                  return (
                    <Tooltip
                      key={key}
                      title={
                        <Box p={1}>
                          <Typography>
                            {humanDate(subscription.startDate)} - {humanDate(subscription.endDate)}
                          </Typography>
                        </Box>
                      }
                      PopperProps={{
                        sx: {
                          '& .MuiTooltip-tooltip': {
                            backgroundColor: 'background.default',
                            boxShadow:
                              '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
                            borderRadius: '6px !important',
                            '& p': {
                              color: 'text.primary',
                            },
                          },
                        },
                      }}
                    >
                      <Typography
                        className={classes.optionTypo}
                        sx={{
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          window.open(`${PATH_SAVVY_AIRCRAFT}/${ticket.aircraft?.id}/subscriptions/${subscription.id}`, '_blank');
                        }}
                      >
                        {subscription.serviceName}
                      </Typography>
                    </Tooltip>
                  );
                })}
              {!ticket.aircraft &&
                !!ticket.creator?.contact?.subscriptions?.length &&
                ticket.creator?.contact?.subscriptions?.map((subscription: any, key: number) => {
                  return (
                    <Tooltip
                      key={key}
                      title={
                        <Box p={1}>
                          <Typography>
                            {humanDate(subscription.startDate)} - {humanDate(subscription.endDate)}
                          </Typography>
                        </Box>
                      }
                      PopperProps={{
                        sx: {
                          '& .MuiTooltip-tooltip': {
                            backgroundColor: 'background.default',
                            boxShadow:
                              '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
                            borderRadius: '6px !important',
                            '& p': {
                              color: 'text.primary',
                            },
                          },
                        },
                      }}
                    >
                      <Typography
                        className={classes.optionTypo}
                        sx={{
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          if (ticket.creator.contact) {
                            window.open(`${PATH_CONTACT}/${ticket.creator.contact?.id}/subscription/${subscription.id}`, '_blank');
                          }
                        }}
                      >
                        {subscription.serviceName}
                      </Typography>
                    </Tooltip>
                  );
                })}
              {((ticket.aircraft && !ticket.aircraft?.subscriptions?.length) ||
                (!ticket.aircraft && !ticket.creator?.contact?.subscriptions?.length)) && (
                <Typography className={classes.optionTypo}>No Service</Typography>
              )}
            </Box>
          </Box>
        )}
        {ticket?.aircraft?.accountManager && (
          <Box
            sx={{
              display: 'flex',
              py: 1,
              width: '100%',
              alignItems: 'center',
            }}
          >
            <Typography
              className={classes.grayFieldTypo}
              sx={{
                width: '40%',
                fontWeight: 600,
              }}
            >
              Manager
            </Typography>
            <Typography
              className={classes.optionTypo}
              sx={{
                width: '50%',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
              onClick={() => {
                if (ticket?.aircraft?.accountManager?.id) {
                  window.open(`${PATH_CONTACT}/${ticket?.aircraft?.accountManager?.id}`, '_blank');
                } else {
                  return;
                }
              }}
              onMouseEnter={(e: React.MouseEvent<HTMLElement>) => {
                if (ticket?.aircraft?.accountManager?.id) handleTooltipOpen(e, ticket?.aircraft?.accountManager?.id);
              }}
              onMouseLeave={handleTooltipClose}
            >
              {ticket?.aircraft?.accountManager
                ? `${ticket?.aircraft?.accountManager.firstName} ${ticket?.aircraft?.accountManager.lastName}`
                : ''}
            </Typography>
          </Box>
        )}
        {ticket?.aircraft?.backupManager && (
          <Box
            sx={{
              display: 'flex',
              py: 1,
              width: '100%',
              alignItems: 'center',
            }}
          >
            <Typography
              className={classes.grayFieldTypo}
              sx={{
                width: '40%',
                fontWeight: 600,
              }}
            >
              Backup Manager
            </Typography>
            <Typography
              className={classes.optionTypo}
              sx={{
                width: '50%',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
              onClick={() => {
                if (ticket?.aircraft?.backupManager?.id) {
                  window.open(`${PATH_CONTACT}/${ticket?.aircraft?.backupManager?.id}`, '_blank');
                } else {
                  return;
                }
              }}
              onMouseEnter={(e: React.MouseEvent<HTMLElement>) => {
                if (ticket?.aircraft?.backupManager?.id) handleTooltipOpen(e, ticket?.aircraft?.backupManager?.id);
              }}
              onMouseLeave={handleTooltipClose}
            >
              {ticket?.aircraft?.backupManager
                ? `${ticket?.aircraft?.backupManager.firstName} ${ticket?.aircraft?.backupManager.lastName}`
                : ''}
            </Typography>
          </Box>
        )}
        {!!ticket?.allowedPriorities?.length && ticket?.allowedPriorities?.length > 1 && (
          <Box
            sx={{
              display: 'flex',
              py: 1,
              width: '100%',
              alignItems: 'center',
            }}
          >
            <Typography
              className={classes.grayFieldTypo}
              sx={{
                width: '40%',
                fontWeight: 600,
              }}
            >
              Priority
            </Typography>
            <ToggleButtonGroup
              size="medium"
              value={ticket?.priority ? ticket.priority : ''}
              onChange={(event, priority) => {
                if (!priority || ticket?.allowedPriorities?.length === 1) {
                  return;
                }
                if (priority !== ticket?.priority) {
                  updateTicketPriority(priority);
                }
              }}
              exclusive={true}
            >
              {ticket?.allowedPriorities?.map((property: any) => {
                return (
                  <ToggleButton
                    value={property.code}
                    key={property.code}
                    sx={{
                      '&.Mui-selected': {
                        backgroundColor: property.code === 'N' ? 'success.dark' : 'error.dark',
                        color: 'background.default',
                        '&:hover': {
                          backgroundColor: property.code === 'N' ? 'success.dark' : 'error.dark',
                        },
                      },
                      width: '80px',
                      textTransform: 'uppercase',
                      fontSize: '12px',
                      fontWeight: 700,
                      py: 1,
                    }}
                  >
                    {property.description}
                  </ToggleButton>
                );
              })}
            </ToggleButtonGroup>
          </Box>
        )}
        {(!ticket?.allowedPriorities?.length || ticket?.allowedPriorities?.length < 2) && (
          <Box
            sx={{
              display: 'flex',
              py: 1,
              width: '100%',
              alignItems: 'center',
            }}
          >
            <Typography
              className={classes.grayFieldTypo}
              sx={{
                width: '40%',
                fontWeight: 600,
              }}
            >
              Priority
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '60%',
              }}
            >
              <Typography
                className={classes.optionTypo}
                sx={{
                  color: 'text.secondary',
                }}
              >
                {getPriority(ticket.priority)}
              </Typography>
              <Tooltip
                title={
                  <Typography>
                    This ticket type does not support any additional priorities. Priorities are supported on tickets with the ServiceTag or
                    the AnalysisTag.
                  </Typography>
                }
                componentsProps={{
                  tooltip: {
                    sx: tooltipSx,
                  },
                }}
              >
                <InfoIcon
                  sx={{
                    ml: 1,
                  }}
                />
              </Tooltip>
            </Box>
          </Box>
        )}
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            '& p': {
              fontWeight: 600,
            },
            alignItems: 'center',
          }}
        >
          <Typography
            className={classes.grayFieldTypo}
            sx={{
              width: '40%',
              fontWeight: 600,
            }}
          >
            Color
          </Typography>
          <Box
            sx={{
              width: '60%',
            }}
          >
            {!!ticket.color && (
              <Box
                sx={{
                  width: '20px',
                  height: '20px',
                  backgroundColor: ticket?.color ? ticket.color : '#e5e5e5',
                  borderRadius: '2px',
                  border: '1px solid',
                  borderColor: 'text.secondary',
                  cursor: 'pointer',
                  display: 'block',
                }}
                onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                  setShowWatcherColorAnchorEl(e.currentTarget);
                }}
                title="Change Color"
              />
            )}
            {!ticket.color && (
              <Box
                onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                  setShowWatcherColorAnchorEl(e.currentTarget);
                }}
              >
                <Typography
                  sx={{
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                  className={classes.optionTypo}
                >
                  Select Color
                </Typography>
              </Box>
            )}
            <WatcherColor setOpen={setShowWatcherColorAnchorEl} anchorEl={showWatcherColorAnchorEl} ticketId={ticket.id} />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            py: 1,
            width: '100%',
          }}
        >
          <Typography
            className={classes.grayFieldTypo}
            sx={{
              width: '40%',
              fontWeight: 600,
            }}
          >
            Created
          </Typography>
          <Typography
            sx={{
              width: '60%',
            }}
            className={classes.optionTypo}
          >
            {humanDateTime(ticket.created)}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            py: 1,
            width: '100%',
          }}
        >
          <Typography
            className={classes.grayFieldTypo}
            sx={{
              width: '40%',
              fontWeight: 600,
            }}
          >
            Updated
          </Typography>
          <Typography
            sx={{
              width: '60%',
            }}
            className={classes.optionTypo}
          >
            {humanDateTime(ticket.updated)}
          </Typography>
        </Box>
        {Boolean(ticket?.snoozedUntil) && (
          <Box
            sx={{
              display: 'flex',
              py: 1,
              width: '100%',
              alignItems: 'center',
            }}
          >
            <Typography
              className={classes.grayFieldTypo}
              sx={{
                width: '40%',
                fontWeight: 600,
              }}
            >
              Snoozed Until
            </Typography>
            <Box
              sx={{
                width: '60%',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography className={classes.optionTypo}>{humanDate(ticket.snoozedUntil)}</Typography>
              <CloseIcon
                sx={{
                  cursor: 'pointer',
                  ml: 1,
                }}
                onClick={unsnooze}
              />
            </Box>
          </Box>
        )}
      </Box>
    );
  };

  useEffect(() => {
    if (ticket && viewerId) {
      setTextOnUpdateFlg(existingTextOnUpdate(ticket, viewerId));
    }
  }, [ticket, viewerId]);

  useEffect(() => {
    if (!dataSavvyPermissions) {
      fetchSavvyPermissions();
    }
  }, [dataSavvyPermissions]);

  useEffect(() => {
    if (ticket?.id) {
      fetchRelatedTickets({
        variables: {
          ticketId: parseInt(ticket.id),
        },
      });
    }
  }, [ticket]);

  useEffect(() => {
    if (errorRemoveWatcherMutation) {
      dispatch(setAlert('error', 'Unable to remove watcher'));
    } else if (dataRemoveWatcherMutation) {
      if (dataRemoveWatcherMutation?.removeWatcherFromTicket?.ok) {
        dispatch(setAlert('success', 'Removed watcher'));
      } else {
        dispatch(setAlert('error', 'Unable to remove watcher'));
      }
    }
  }, [dataRemoveWatcherMutation, errorRemoveWatcherMutation, dispatch]);

  const updateTicketPriority = async (priority: string) => {
    if (!ticket?.id) return;
    await updateTicketMutation({
      variables: {
        priority,
        ticketId: ticket.id,
      },
    });
  };

  const handleResetUnhappy = async () => {
    if (!ticket) return;
    await resetUnhappyTicket({
      variables: {
        ticketId: parseInt(ticket.id),
      },
    });
  };

  useEffect(() => {
    if (errorTicketMutation) {
      dispatch(setAlert('error', 'Unable to update ticket priority'));
    } else if (dataTicketMutation) {
      if (dataTicketMutation?.updateTicketPriority?.ok) {
        dispatch(setAlert('success', 'Updated ticket priority'));
      } else {
        dispatch(setAlert('error', dataTicketMutation?.updateTicketPriority?.error || 'Unable to update ticket priority'));
      }
    }
  }, [dataTicketMutation, errorTicketMutation, dispatch]);

  useEffect(() => {
    if (errorUnhappyTicket) {
      dispatch(setAlert('error', 'Unable to reset unhappy status'));
    } else if (dataUnhappyTicket) {
      if (dataUnhappyTicket?.resetUnhappyStatus?.ok) {
        dispatch(setAlert('success', 'Reset unhappy status'));
      } else {
        dispatch(setAlert('error', dataUnhappyTicket?.resetUnhappyStatus?.error || 'Unable to reset unhappy status'));
      }
    }
  }, [dataUnhappyTicket, errorUnhappyTicket, dispatch]);

  useEffect(() => {
    if (ticket?.tags) {
      const tmpFlights: {
        tagId: string;
        flight: IFlight;
        reportId: string;
      }[] = [];

      const tmpBorescopeImageSets: {
        tagId: string;
        borescopeImageSet: IBorescopeImageSet;
        reportId: string;
      }[] = [];
      for (const tag of ticket.tags as any) {
        if (tag.__typename === 'AnalysisTagType') {
          if (tag.flight) {
            tmpFlights.push({
              tagId: tag.id,
              flight: { ...tag.flight },
              reportId: tag.report ? tag.report.id : '',
            });
          }
        }
        if (tag.__typename === 'BorescopeAnalysisTagType') {
          if (tag.borescopeImageSet) {
            tmpBorescopeImageSets.push({
              tagId: tag.tagId,
              borescopeImageSet: { ...tag.borescopeImageSet },
              reportId: tag.report ? tag.report.id : '',
            });
          }
        }
      }

      setAnalysisFlights([...tmpFlights]);
      setBorescopeImageSets([...tmpBorescopeImageSets]);
    }
  }, [ticket]);

  useEffect(() => {
    if (errorExportTicket) {
      dispatch(setAlert('error', 'Unable to export ticket'));
    } else if (dataExportTicket) {
      if (dataExportTicket.exportTicket?.ok) {
        dispatch(setAlert('success', 'Exported ticket'));
      } else {
        dispatch(setAlert('error', dataExportTicket.exportTicket?.error || 'Unable to export ticket'));
      }
    }
  }, [dataExportTicket, errorExportTicket, dispatch]);

  useEffect(() => {
    if (errorUnsnoozeMutation) {
      dispatch(setAlert('error', 'Unable to unsnooze ticket'));
    } else if (dataUnsnoozeMutation && dataUnsnoozeMutation.unsnoozeTicket) {
      const { ok, error } = dataUnsnoozeMutation.unsnoozeTicket;
      if (ok) {
        dispatch(setAlert('success', 'Unsnoozed ticket'));
        updateTicketDashboard();
      } else {
        dispatch(setAlert('error', error || 'Unable to unsnooze ticket'));
      }
    }
  }, [dataUnsnoozeMutation, errorUnsnoozeMutation, dispatch]);

  if (!ticket) return null;

  return (
    <Box
      sx={{
        '@media (min-width: 1280px)': {
          maxHeight: 'calc(100vh - 70px)',
          overflowY: 'auto',
          '&::-webkit-scrollbar': {
            width: '0.6em',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
            borderRadius: 2,
          },
          width: '400px',
        },
      }}
    >
      <StyledDiv
        sx={{
          py: 5,
          px: {
            xs: 2,
            sm: 3,
            md: 4,
            lg: 5,
          },
        }}
      >
        <Grid container>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Box
              sx={{
                py: 1,
                pb: 3,
                '& p': {
                  fontWeight: 600,
                },
                textAlign: 'right',
              }}
            >
              <ClickAwayListener onClickAway={() => setShowHamburger(false)}>
                <Box
                  sx={{
                    position: 'relative',
                    display: 'inline-block',
                  }}
                >
                  <IconButton
                    onClick={() => {
                      setShowHamburger(true);
                    }}
                  >
                    <MenuIcon />
                  </IconButton>
                  {showHamburger && (
                    <Grow in={showHamburger}>
                      <Paper
                        sx={{
                          position: 'absolute',
                          zIndex: 999,
                          right: 0,
                          minWidth: '200px',
                        }}
                      >
                        <List component="div">
                          {isVisibleNotHappy(ticket) && (
                            <ListItem
                              ContainerComponent="div"
                              button
                              onClick={() => {
                                setShowHamburger(false);
                                setOpenNotHappy(true);
                              }}
                            >
                              <SentimentVeryDissatisfiedIcon />
                              I'm not happy
                            </ListItem>
                          )}
                          {ticket?.notHappy && canResetUnhappy && (
                            <ListItem
                              ContainerComponent="div"
                              button
                              onClick={() => {
                                setShowHamburger(false);
                                handleResetUnhappy();
                              }}
                            >
                              Reset unhappy status
                            </ListItem>
                          )}
                          <ListItem
                            ContainerComponent="div"
                            button
                            onClick={() => {
                              setShowHamburger(false);
                              if (closeTicket) {
                                closeTicket(parseInt(ticket.id), ticket.state.toLowerCase() !== 'c');
                              }
                            }}
                          >
                            {ticket?.state.toLowerCase() === 'c' ? 'Reopen Ticket' : 'Close Ticket'}
                          </ListItem>
                          <ListItem
                            ContainerComponent="div"
                            button
                            onClick={() => {
                              setShowAddTag(true);
                            }}
                          >
                            Manage Tags
                          </ListItem>
                          {ticket.aircraft && (
                            <ListItem
                              ContainerComponent="div"
                              button
                              onClick={() => {
                                if (ticket?.tags?.length) {
                                  for (let i = 0; i < ticket?.tags?.length; i++) {
                                    if (ticket.tags[i].name === 'share-maintenance-logs') {
                                      const logs = JSON.parse(ticket.tags[i].logs);
                                      setPreviousLogIds(logs.logbookIds);
                                    }
                                  }

                                  if (!isSharedLog(ticket.tags)) {
                                    setPreviousLogIds([]);
                                  }
                                }
                                setShowHamburger(false);
                                setShowShareLog(true);
                              }}
                            >
                              Share Logs
                            </ListItem>
                          )}
                          <ListItem
                            ContainerComponent="div"
                            button
                            onClick={() => {
                              let queryParam = '';
                              if (ticket.aircraft?.owner?.airportId) {
                                queryParam = '?homeAirport=' + ticket.aircraft?.owner?.airportId;
                              } else if (ticket.aircraft?.owner?.coordinates) {
                                queryParam = `?latitude=${ticket.aircraft?.owner?.coordinates?.latitude}&longitude=${ticket.aircraft?.owner?.coordinates?.longitude}`;
                              }
                              window.open(PATH_SERVICE_CENTER_MAP + queryParam, '_blank');
                            }}
                          >
                            Find Service Center
                          </ListItem>
                          {dataSavvyPermissions?.savvy?.permissions?.ticket?.canExportTicket && (
                            <ListItem
                              ContainerComponent="div"
                              button
                              onClick={() => {
                                setShowHamburger(false);
                                submitExportTicket(ticket.id);
                              }}
                            >
                              Export Ticket
                            </ListItem>
                          )}
                        </List>
                      </Paper>
                    </Grow>
                  )}
                </Box>
              </ClickAwayListener>
            </Box>
            <Box
              sx={{
                display: {
                  xl: 'none',
                  lg: 'block',
                  md: 'block',
                  xs: 'none',
                },
              }}
            >
              {renderMobileBrief()}
            </Box>
          </Grid>
          <Grid item xl={12} lg={false} md={false} sm={false}>
            <Box
              sx={{
                display: {
                  xs: 'none',
                  xl: 'block',
                },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  pb: 1,
                  width: '100%',
                  '& p': {
                    fontWeight: 600,
                  },
                }}
              >
                <Typography
                  className={classes.grayFieldTypo}
                  sx={{
                    width: '40%',
                  }}
                >
                  Title
                </Typography>
                <Box
                  sx={{
                    width: '60%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography
                    sx={{
                      mr: 1,
                    }}
                    className={classes.optionTypo}
                  >
                    {ticket.subject}
                  </Typography>
                  <EditIcon
                    sx={{
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setOpenEditSubject(true);
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xl={12} lg={false} md={false} sm={false} xs={false}>
            <Box
              sx={{
                display: {
                  xs: 'none',
                  xl: 'block',
                },
              }}
            >
              {renderMobileBrief()}
            </Box>
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Box
              sx={{
                display: 'flex',
                py: 1,
                width: '100%',
                '& p': {
                  fontWeight: 600,
                },
              }}
            >
              <Typography
                className={classes.grayFieldTypo}
                sx={{
                  width: '40%',
                  fontWeight: 600,
                }}
              >
                Created By
              </Typography>
              <Typography
                sx={{
                  width: '50%',
                  cursor: 'pointer',
                  textDecoration: 'underline',
                }}
                className={classes.optionTypo}
                onClick={() => {
                  if (ticket.creator?.contact?.id) window.open(`${PATH_CONTACT}/${ticket.creator.contact.id}`, '_blank');
                }}
                onMouseEnter={(e: React.MouseEvent<HTMLElement>) => {
                  if (!ticket.creator?.isEmailClient) {
                    handleTooltipOpen(e, ticket.creator.id);
                  } else {
                    handleTooltipOpen(e, '', ticket.creator.email);
                  }
                }}
                onMouseLeave={handleTooltipClose}
              >
                {`${ticket.creator.fullNameWithNick}`}
                {ticket.creator.isEmailClient && (
                  <EmailIcon
                    sx={{
                      ml: 0.5,
                      width: '17px',
                      height: '17px',
                      verticalAlign: 'bottom',
                    }}
                  />
                )}
              </Typography>
              {Boolean(ticket.creator.contact?.comments?.filter((comment: IComment) => comment.pinned)?.length) &&
                !ticket.aircraft?.owner && (
                  <Tooltip
                    title={
                      <Box>
                        {ticket.creator.contact?.comments
                          ?.filter((comment: IComment) => comment.pinned)
                          ?.map((comment: IComment, key: number) => {
                            return (
                              <Typography key={key}>
                                {comment.comment} ({comment.date.toString().substring(0, 10)})
                              </Typography>
                            );
                          })}
                      </Box>
                    }
                    componentsProps={{
                      tooltip: {
                        sx: tooltipSx,
                      },
                    }}
                  >
                    <ChatIcon />
                  </Tooltip>
                )}
            </Box>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                '& p': {
                  fontWeight: 600,
                },
                alignItems: 'center',
              }}
            >
              <Typography
                className={classes.grayFieldTypo}
                sx={{
                  width: '40%',
                  fontWeight: 600,
                }}
              >
                Assignee
              </Typography>
              <Box
                sx={{
                  width: '60%',
                }}
              >
                <AddAssignPopupDialog ticketId={ticket.id} allowedAssignees={allowedAssignees} assignee={ticket.assignee} />
              </Box>
            </Box>
            {Boolean(ticket?.watchers?.length) && (
              <Box
                sx={{
                  display: 'flex',
                  py: 1,
                  width: '100%',
                  '& p': {
                    fontWeight: 600,
                  },
                }}
              >
                <Typography
                  className={classes.grayFieldTypo}
                  sx={{
                    width: '40%',
                    fontWeight: 600,
                  }}
                >
                  Watchers
                </Typography>
              </Box>
            )}
            {Boolean(ticket?.watchers?.length) && (
              <Box>
                <Box
                  sx={{
                    display: 'flex',
                    width: '100%',
                  }}
                >
                  <Box
                    sx={{
                      width: {
                        xl: '0%',
                        lg: '40%',
                        md: '40%',
                        xs: '0',
                      },
                    }}
                  ></Box>
                  <Box
                    sx={{
                      width: {
                        xl: '100%',
                        lg: '60%',
                        md: '60%',
                        xs: '100%',
                      },
                      '& p': {
                        fontWeight: 600,
                      },
                    }}
                  >
                    {ticket?.watchers?.map((watcher: any, key: number) => {
                      return (
                        <Box
                          key={key}
                          sx={{
                            display: 'flex',
                            alignItems: 'start',
                            justifyContent: 'space-between',
                            py: 0.5,
                          }}
                        >
                          <Typography
                            className={classes.optionTypo}
                            sx={{
                              cursor: !watcher?.contact?.isSavvy ? 'pointer' : 'inherit',
                              textDecoration: !watcher?.contact?.isSavvy ? 'underline' : 'none',
                            }}
                            onClick={() => {
                              if (!watcher?.contact?.isSavvy) {
                                if (watcher.contact?.id) {
                                  window.open(`${PATH_CONTACT}/${watcher.contact?.id}`, '_blank');
                                } else {
                                  return;
                                }
                              }
                            }}
                            onMouseEnter={(e: React.MouseEvent<HTMLElement>) => {
                              if (!watcher?.contact?.isSavvy) {
                                if (watcher.contact?.id) {
                                  handleTooltipOpen(e, watcher.contact?.id);
                                } else if (watcher.isEmailWatcher) {
                                  handleTooltipOpen(e, '', watcher.email);
                                }
                              }
                            }}
                            onMouseLeave={handleTooltipClose}
                          >
                            {watcher.fullNameWithNick}
                            {watcher?.contact?.company && watcher?.contact?.isTechnician ? `   (${watcher.contact.company})` : ''}
                            {watcher.isEmailWatcher && (
                              <EmailIcon
                                sx={{
                                  ml: 0.5,
                                  width: '17px',
                                  height: '17px',
                                  verticalAlign: 'bottom',
                                }}
                              />
                            )}
                          </Typography>
                          <Typography
                            sx={{
                              textDecoration: watcher.removable ? 'underline' : 'none',
                              cursor: watcher.removable ? 'pointer' : 'inherit',
                            }}
                            onClick={() => {
                              if (watcher.removable) removeWatcher(watcher.id);
                            }}
                          >
                            {watcher.removable ? '(Remove)' : watcher.cannotRemoveExplanation}
                          </Typography>
                          {watcher.contact?.id === ticket?.aircraft?.owner?.id &&
                            Boolean(ticket.aircraft?.owner?.comments?.filter((comment: IComment) => comment.pinned)?.length) && (
                              <Tooltip
                                title={
                                  <Box>
                                    {ticket?.aircraft?.owner?.comments
                                      ?.filter((comment: IComment) => comment.pinned)
                                      ?.map((comment: IComment, key: number) => {
                                        return (
                                          <Typography key={key}>
                                            {comment.comment} ({comment.date.toString().substring(0, 10)})
                                          </Typography>
                                        );
                                      })}
                                  </Box>
                                }
                                componentsProps={{
                                  tooltip: {
                                    sx: tooltipSx,
                                  },
                                }}
                              >
                                <ChatIcon
                                  sx={{
                                    height: '30px',
                                    width: '30px',
                                    mt: '-5px',
                                  }}
                                />
                              </Tooltip>
                            )}
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              </Box>
            )}
            <AddWatcherPopupDialog ticketId={ticket.id} />
            {Boolean(ticket?.aircraft?.registration) && (
              <Box
                sx={{
                  display: 'flex',
                  py: 1,
                  width: '100%',
                  '& p': {
                    fontWeight: 600,
                  },
                  alignItems: 'center',
                }}
              >
                <Typography
                  className={classes.grayFieldTypo}
                  sx={{
                    width: '40%',
                    fontWeight: 600,
                  }}
                >
                  Registration
                </Typography>
                <Tooltip
                  title={
                    <Box px={2} py={1}>
                      <Box py={1}>
                        <Typography>Year: {ticket?.aircraft?.year}</Typography>
                      </Box>
                      <Box py={1}>
                        <Typography>Registration: {ticket?.aircraft?.registration}</Typography>
                      </Box>
                      <Box py={1}>
                        <Typography>Serial: {ticket?.aircraft?.serial}</Typography>
                      </Box>
                      <Box py={1}>
                        <Typography>
                          Make/Model: {ticket?.aircraft?.aircraftManufacturer?.name} / {ticket?.aircraft?.aircraftModel?.name}
                        </Typography>
                      </Box>
                      <Box py={1}>
                        <Typography>Owner: {ticket?.aircraft?.owner?.fullNameWithNick}</Typography>
                      </Box>
                      <Box py={1}>
                        <Typography>
                          Annual Due: {ticket?.aircraft?.annualDue ? ticket.aircraft.annualDue.toString().substring(0, 10) : 'None'}
                        </Typography>
                      </Box>
                      {ticket?.aircraft?.preferredServiceCenter && (
                        <Box py={1}>
                          <Typography>
                            Preferred SC:{' '}
                            <Link
                              href={PATH_INTERNAL_SERVICE_CENTER.replace(':id', ticket.aircraft.preferredServiceCenter.id)}
                              target="_blank"
                            >
                              {ticket.aircraft.preferredServiceCenter.company}
                            </Link>
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  }
                  PopperProps={{
                    sx: {
                      '& .MuiTooltip-tooltip': {
                        backgroundColor: 'background.default',
                        boxShadow:
                          '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
                        borderRadius: '6px !important',
                        '& p': {
                          color: 'text.primary',
                        },
                      },
                    },
                  }}
                >
                  <Typography
                    className={classes.optionTypo}
                    sx={{
                      width: '50%',
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      if (ticket?.aircraft?.owner) {
                        window.open(`${PATH_SAVVY_AIRCRAFT}/${ticket?.aircraft?.id}`, '_blank');
                      } else {
                        return;
                      }
                    }}
                  >
                    {ticket?.aircraft?.registration}
                  </Typography>
                </Tooltip>
                {Boolean(ticket?.aircraft?.comments?.filter((comment: IComment) => comment.pinned)?.length) && (
                  <Tooltip
                    title={
                      <Box>
                        {ticket?.aircraft?.comments
                          ?.filter((comment: IComment) => comment.pinned)
                          ?.map((comment: IComment, key: number) => {
                            return (
                              <Typography key={key}>
                                {comment.comment} ({comment.date.toString().substring(0, 10)})
                              </Typography>
                            );
                          })}
                      </Box>
                    }
                    componentsProps={{
                      tooltip: {
                        sx: tooltipSx,
                      },
                    }}
                  >
                    <ChatIcon
                      sx={{
                        width: '30px',
                        height: '30px',
                      }}
                    />
                  </Tooltip>
                )}
              </Box>
            )}
            {Boolean(ticket?.aircraft?.registration) && (
              <Box
                sx={{
                  display: 'flex',
                  py: 1,
                  width: '100%',
                  '& p': {
                    fontWeight: 600,
                  },
                }}
              >
                <Typography
                  className={classes.grayFieldTypo}
                  sx={{
                    width: '40%',
                  }}
                >
                  FAA Registry
                </Typography>
                <Link
                  sx={{
                    width: '60%',
                  }}
                  href={`https://registry.faa.gov/AircraftInquiry/Search/NNumberResult?nNumberTxt=${ticket?.aircraft?.registration}`}
                  target="_blank"
                >
                  Click here to find aircraft
                </Link>
              </Box>
            )}
            {Boolean(ticket?.aircraft?.year) && (
              <Box
                sx={{
                  display: 'flex',
                  py: 1,
                  width: '100%',
                  '& p': {
                    fontWeight: 600,
                  },
                  alignItems: 'center',
                }}
              >
                <Typography
                  className={classes.grayFieldTypo}
                  sx={{
                    width: '40%',
                    fontWeight: 600,
                  }}
                >
                  Year
                </Typography>
                <Typography
                  className={classes.optionTypo}
                  sx={{
                    width: '60%',
                  }}
                >
                  {ticket?.aircraft?.year}
                </Typography>
              </Box>
            )}
            {Boolean(ticket?.aircraft?.serial) && (
              <Box
                sx={{
                  display: 'flex',
                  py: 1,
                  width: '100%',
                  '& p': {
                    fontWeight: 600,
                  },
                }}
              >
                <Typography
                  className={classes.grayFieldTypo}
                  sx={{
                    width: '40%',
                    fontWeight: 600,
                  }}
                >
                  Serial
                </Typography>
                <Typography
                  className={classes.optionTypo}
                  sx={{
                    width: '60%',
                  }}
                >
                  {ticket?.aircraft?.serial}
                </Typography>
              </Box>
            )}
            {Boolean(ticket?.aircraft?.aircraftModel?.name) && (
              <Box
                sx={{
                  display: 'flex',
                  py: 1,
                  width: '100%',
                  '& p': {
                    fontWeight: 600,
                  },
                }}
              >
                <Typography
                  className={classes.grayFieldTypo}
                  sx={{
                    width: '40%',
                    fontWeight: 600,
                  }}
                >
                  Make/Model
                </Typography>
                <Typography
                  className={classes.optionTypo}
                  sx={{
                    width: '60%',
                  }}
                >
                  {ticket?.aircraft?.aircraftManufacturer?.name + ' ' + ticket?.aircraft?.aircraftModel?.name}
                </Typography>
              </Box>
            )}
            {Boolean(ticket?.aircraft?.engineModel?.name) && Boolean(ticket?.aircraft?.engineManufacturer?.name) && (
              <Box
                sx={{
                  display: 'flex',
                  py: 1,
                  width: '100%',
                  '& p': {
                    fontWeight: 600,
                  },
                }}
              >
                <Typography
                  className={classes.grayFieldTypo}
                  sx={{
                    width: '40%',
                    fontWeight: 600,
                  }}
                >
                  Engine Make/Model
                </Typography>
                <Typography
                  className={classes.optionTypo}
                  sx={{
                    width: '60%',
                  }}
                >
                  {ticket?.aircraft?.engineManufacturer?.name + ' ' + ticket?.aircraft?.engineModel?.name}
                </Typography>
              </Box>
            )}
            {Boolean(ticket?.aircraft?.engineMonitorManufacturer?.name) && Boolean(ticket?.aircraft?.engineMonitorModel?.name) && (
              <Box
                sx={{
                  display: 'flex',
                  py: 1,
                  width: '100%',
                  '& p': {
                    fontWeight: 600,
                  },
                }}
              >
                <Typography
                  className={classes.grayFieldTypo}
                  sx={{
                    width: '40%',
                    fontWeight: 600,
                  }}
                >
                  EngineMonitor Make/Model
                </Typography>
                <Typography
                  className={classes.optionTypo}
                  sx={{
                    width: '60%',
                  }}
                >
                  {ticket?.aircraft?.engineMonitorManufacturer?.name + ' ' + ticket?.aircraft?.engineMonitorModel?.name}
                </Typography>
              </Box>
            )}
            {ticket?.aircraft?.owner && (
              <Box
                sx={{
                  display: 'flex',
                  py: 1,
                  width: '100%',
                  '& p': {
                    fontWeight: 600,
                  },
                }}
              >
                <Typography
                  className={classes.grayFieldTypo}
                  sx={{
                    width: '40%',
                    fontWeight: 600,
                  }}
                >
                  Flights
                </Typography>
                <Link
                  href={`${PATH_SAVVY_AIRCRAFT}/${ticket?.aircraft?.id}/flights`}
                  sx={{
                    width: '60%',
                  }}
                  target="_blank"
                >
                  View Charts
                </Link>
              </Box>
            )}
            {Boolean(ticket?.aircraft) && (
              <Box
                sx={{
                  display: 'flex',
                  py: 1,
                  width: '100%',
                  '& p': {
                    fontWeight: 600,
                  },
                  alignItems: 'center',
                }}
              >
                <Typography
                  className={classes.grayFieldTypo}
                  sx={{
                    width: '40%',
                    fontWeight: 600,
                  }}
                >
                  RC/TA
                </Typography>
                <Box
                  sx={{
                    width: '60%',
                  }}
                >
                  {!ticket?.aircraft?.checkRcta?.length && (
                    <Link
                      href={`${PATH_SAVVY_AIRCRAFT}/${ticket?.aircraft?.id}/reports`}
                      sx={{
                        width: '60%',
                      }}
                      target="_blank"
                    >
                      View Reports
                    </Link>
                  )}
                  {!!ticket?.aircraft?.checkRcta?.length && (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Typography className={classes.optionTypo}>RC/TA unavailable</Typography>
                      <Tooltip
                        title={<Typography>{ticket.aircraft.checkRcta.join('\n')}</Typography>}
                        componentsProps={{
                          tooltip: {
                            sx: tooltipSx,
                          },
                        }}
                      >
                        <InfoIcon
                          sx={{
                            ml: 1,
                          }}
                        />
                      </Tooltip>
                    </Box>
                  )}
                </Box>
              </Box>
            )}
            {isSharedLog(ticket?.tags || []) && (
              <Box
                sx={{
                  display: 'flex',
                  py: 1,
                  width: '100%',
                  '& p': {
                    fontWeight: 600,
                  },
                  alignItems: 'center',
                }}
              >
                <Typography
                  className={classes.grayFieldTypo}
                  sx={{
                    width: '40%',
                    fontWeight: 600,
                  }}
                >
                  Maintenance
                </Typography>
                <Box
                  sx={{
                    width: '60%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Link href={`${PATH_SHARE_LOGS}/${ticket.id}`} target="_blank">
                    View Logs
                  </Link>
                  {ticket.state.toLowerCase() === 'c' && (
                    <Tooltip
                      title="Ticket Closed. Logbooks no longer visible to Service Center"
                      componentsProps={{
                        tooltip: {
                          sx: tooltipSx,
                        },
                      }}
                    >
                      <InfoIcon />
                    </Tooltip>
                  )}
                </Box>
              </Box>
            )}
            <Box
              sx={{
                display: 'flex',
                py: 1,
                width: '100%',
                '& p': {
                  fontWeight: 600,
                },
              }}
            >
              <Typography
                className={classes.grayFieldTypo}
                sx={{
                  width: '40%',
                  fontWeight: 600,
                }}
              >
                Tags
              </Typography>
              <Box
                sx={{
                  width: '60%',
                }}
              >
                {ticket?.tags?.map((tag: any, key: number) => {
                  if (tag.name === 'user' && tag.userTag?.name === 'Text On Update') return null;

                  if (tag.name === 'user' && tag.userTag?.name === "Don't Request Review") return null;

                  if (!tag.id && !tag.tagId) return null;

                  return (
                    <Box
                      key={key}
                      pb={1}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Tooltip
                        title={
                          <Box py={2}>
                            <Typography
                              sx={{
                                mb: 1,
                              }}
                            >
                              Id: {tag.id || tag.tagId}
                            </Typography>
                            <Typography
                              sx={{
                                mb: 1,
                              }}
                            >
                              Type: {tag.__typename.replace('Type', '')}
                            </Typography>
                            <Typography
                              sx={{
                                mb: 1,
                              }}
                            >
                              Ticket: {`#${ticket.id} ${ticket.subject}`}
                              {Boolean(ticket.aircraft?.registration) && (
                                <Link href={`${PATH_AIRCRAFT_VIEW}/${ticket.aircraft?.id}`} target="_blank">
                                  ({ticket.aircraft?.registration})
                                </Link>
                              )}
                            </Typography>
                            <Typography
                              sx={{
                                mb: 1,
                              }}
                            >
                              Reporting Tag: {getTagName(tag)}
                            </Typography>
                            <Typography
                              sx={{
                                mb: 1,
                              }}
                            >
                              Created: {tag.created}
                            </Typography>
                            <Typography
                              sx={{
                                mb: 1,
                              }}
                            >
                              Metadata: {tag.metadata}
                            </Typography>
                            {tag.__typename === 'ServiceTagType' && (
                              <Typography
                                sx={{
                                  mb: 1,
                                }}
                              >
                                Service: {tag.service.serviceName}
                              </Typography>
                            )}
                          </Box>
                        }
                        arrow
                        PopperProps={{
                          sx: {
                            '& .MuiTooltip-tooltip': {
                              backgroundColor: 'background.default',
                              border: '1px solid',
                              borderColor: 'grey.200',
                              boxShadow: '0px 1px 1px rgba(19, 30, 64, 0.06)',
                              borderRadius: '6px !important',
                              '& p': {
                                color: 'text.primary',
                              },
                            },
                          },
                        }}
                      >
                        <Box
                          sx={{
                            fontSize: '10px',
                            lineHeight: '14px',
                            fontWeight: 'bold',
                            color: 'background.default',
                            textTransform: 'uppercase',
                            padding: '6px 18px',
                            backgroundColor: 'text.primary',
                            borderRadius: '6px',
                            display: 'inline-flex',
                            cursor: 'pointer',
                          }}
                        >
                          {getTagName(tag)}
                        </Box>
                      </Tooltip>

                      <CloseIcon
                        sx={{
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          setDeleteTagId(tag.id);
                          setDeleteTagConfirm(true);
                          setDeleteTagName(getTagName(tag));
                        }}
                      />
                    </Box>
                  );
                })}
                <Box>
                  <AddUserTagComponent ticketId={ticket.id} />
                </Box>
                {isMxOrPrebuySignupTag(ticket?.tags || []) && (
                  <Box py={1}>
                    <Button
                      variant="contained"
                      onClick={() => {
                        setOpenAssignManagers(true);
                      }}
                    >
                      Assign Managers
                    </Button>
                  </Box>
                )}
                {isServiceOrAnnualTag(ticket?.tags || []) && !!ticket?.aircraft?.id && (
                  <Box py={1}>
                    <Button variant="contained" onClick={openAddLog}>
                      Add Log Entries
                    </Button>
                  </Box>
                )}
              </Box>
            </Box>
            {!!analysisFlights.length && (
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  '& p': {
                    fontWeight: 600,
                  },
                }}
              >
                <Typography
                  className={classes.grayFieldTypo}
                  sx={{
                    width: '40%',
                    fontWeight: 600,
                    py: 1,
                  }}
                >
                  Analysis Flights
                </Typography>
                <ComponentLoading loading={loadingReportDownloadUrl}>
                  <Box
                    sx={{
                      width: '60%',
                    }}
                  >
                    {analysisFlights.map((analysisFlight, key: number) => {
                      return (
                        <Box key={key} py={1}>
                          <Typography
                            sx={{
                              textDecoration: 'underline',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              window.open(`${PATH_FLIGHT_VIEW}/${analysisFlight.flight.id}`, '_blank');
                            }}
                          >
                            {`${
                              analysisFlight.flight.date.toString().substring(0, 10) +
                              ' ' +
                              analysisFlight.flight.date.toString().substring(11, 16)
                            }, ${secondsToHms(analysisFlight.flight.duration)}, ${analysisFlight.flight.departureAirport} -> ${
                              analysisFlight.flight.destinationAirport
                            }`}
                            {analysisFlight.reportId ? (
                              <React.Fragment>
                                <br />
                                <Link
                                  href={`${PATH_FLIGHT_REPORT}/${analysisFlight.flight.aircraft.id}/${analysisFlight.reportId}`}
                                  target="_blank"
                                  onClick={(e: any) => {
                                    e.stopPropagation();
                                  }}
                                >
                                  View Report #{analysisFlight.reportId}
                                </Link>
                                <br />
                                <Link
                                  href="#"
                                  target="_blank"
                                  onClick={(e: any) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    downloadReport(analysisFlight?.flight?.aircraft?.id, analysisFlight?.reportId);
                                  }}
                                >
                                  Download Report #{analysisFlight.reportId}
                                </Link>
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                <br />
                                <Link
                                  href={`${PATH_FLIGHT_REPORT}/generate/${analysisFlight.tagId}`}
                                  target="_blank"
                                  onClick={(e: any) => {
                                    e.stopPropagation();
                                  }}
                                >
                                  Generate Report
                                </Link>
                              </React.Fragment>
                            )}
                          </Typography>
                        </Box>
                      );
                    })}
                  </Box>
                </ComponentLoading>
              </Box>
            )}
            {!!borescopeImageSets.length && (
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  '& p': {
                    fontWeight: 600,
                  },
                }}
              >
                <Typography
                  className={classes.grayFieldTypo}
                  sx={{
                    width: '40%',
                    fontWeight: 600,
                    py: 1,
                  }}
                >
                  Borescope Analysis
                </Typography>
                <Box
                  sx={{
                    width: '60%',
                  }}
                >
                  {borescopeImageSets.map((borescopeImageSet, key: number) => {
                    return (
                      <Box key={key} py={1}>
                        <Typography
                          sx={{
                            textDecoration: 'underline',
                            cursor: 'pointer',
                            mb: 1,
                          }}
                          onClick={() => {
                            window.open(
                              `${PATH_SAVVY_AIRCRAFT}/${ticket.aircraft?.id}/borescope-image-sets?setId=${borescopeImageSet.borescopeImageSet.id}`,
                              '_blank',
                            );
                          }}
                        >
                          {borescopeImageSet.borescopeImageSet.name}
                        </Typography>
                        <Link
                          href={
                            borescopeImageSet.borescopeImageSet.status === 'REPORT_SENT'
                              ? `${PATH_SAVVY_BORESCOPE_ANALYSIS_REPORT_VIEW}/${ticket.aircraft?.id}/${borescopeImageSet.borescopeImageSet.id}`
                              : `${PATH_SAVVY_BORESCOPE_ANALYSIS_REPORT_GENERATE}/${ticket.aircraft?.id}/${borescopeImageSet.borescopeImageSet.id}`
                          }
                          target="_blank"
                          onClick={(e: any) => {
                            e.stopPropagation();
                          }}
                        >
                          {borescopeImageSet.borescopeImageSet.status === 'REPORT_SENT' ? 'View Report' : 'Create/Edit Report'}
                        </Link>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            )}
            <Box
              sx={{
                display: 'flex',
                py: 1,
                width: '100%',
                '& p': {
                  fontWeight: 600,
                },
                alignItems: 'center',
              }}
            >
              <Typography
                className={classes.grayFieldTypo}
                sx={{
                  width: '40%',
                  fontWeight: 600,
                }}
              >
                Don't request rating
              </Typography>
              <Switch checked={requestRatingFlg} onChange={dontRequestRating} />
            </Box>
            <Box
              sx={{
                display: 'flex',
                py: 1,
                width: '100%',
                '& p': {
                  fontWeight: 600,
                },
                alignItems: 'center',
              }}
            >
              <Typography
                className={classes.grayFieldTypo}
                sx={{
                  width: '40%',
                  fontWeight: 600,
                }}
              >
                Text On Update
              </Typography>
              <Box
                sx={{
                  width: '60%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Switch checked={textOnUpdateFlg} onChange={textOnUpdate} />
                {!dataSavvyTextOnUpdate?.savvy?.textOnUpdate && textOnUpdateFlg && (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Tooltip
                      title={<Typography>To enable text updates, please turn on the setting from your PTD</Typography>}
                      componentsProps={{
                        tooltip: {
                          sx: tooltipSx,
                        },
                      }}
                    >
                      <WarningIcon
                        sx={{
                          color: 'warning.main',
                        }}
                      />
                    </Tooltip>
                  </Box>
                )}
              </Box>
            </Box>
            {ticket.budget.map((budget, key: number) => {
              const color = budget.budgetConsumedPct < 75 ? 'success.main' : budget.budgetConsumedPct < 100 ? 'warning.main' : 'error.main';
              return (
                <React.Fragment key={key}>
                  <Box
                    sx={{
                      display: 'flex',
                      py: 1,
                      width: '100%',
                      '& p': {
                        fontWeight: 600,
                      },
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      className={classes.grayFieldTypo}
                      sx={{
                        width: '40%',
                        fontWeight: 600,
                      }}
                    >
                      Budget Consumed ({budget.name})
                    </Typography>
                    <Box
                      sx={{
                        width: '60%',
                      }}
                    >
                      <ConsumedBar color={color} width={budget.budgetConsumedPct} blink={budget.blink === 'blink'} />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      py: 1,
                      width: '100%',
                      '& p': {
                        fontWeight: 600,
                      },
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      className={classes.grayFieldTypo}
                      sx={{
                        width: '40%',
                        fontWeight: 600,
                      }}
                    >
                      Subscription Elapsed
                    </Typography>
                    <Box
                      sx={{
                        width: '60%',
                      }}
                    >
                      <ConsumedBar color={'success.dark'} width={budget.elapsedPct} />
                    </Box>
                  </Box>
                </React.Fragment>
              );
            })}
            {Boolean(ticket?.lastViewedBy.length) && (
              <Box
                sx={{
                  display: 'flex',
                  py: 1,
                  width: '100%',
                  '& p': {
                    fontWeight: 600,
                  },
                }}
              >
                <Typography
                  className={classes.grayFieldTypo}
                  sx={{
                    width: '40%',
                    fontWeight: 600,
                  }}
                >
                  Recent Viewers
                </Typography>
                <Box
                  sx={{
                    width: '60%',
                  }}
                >
                  {ticket?.lastViewedBy.map((lastContact: any, key: number) => {
                    return (
                      <Typography
                        key={key}
                        className={classes.optionTypo}
                        sx={{
                          cursor: 'pointer',
                          textDecoration: 'underline',
                        }}
                        onClick={() => {
                          window.open(`${PATH_CONTACT}/${lastContact.id}`, '_blank');
                        }}
                        onMouseEnter={(e: React.MouseEvent<HTMLElement>) => {
                          handleTooltipOpen(e, lastContact.id);
                        }}
                        onMouseLeave={handleTooltipClose}
                      >
                        {`${lastContact.firstName} ${lastContact.lastName}`}
                      </Typography>
                    );
                  })}
                </Box>
              </Box>
            )}
          </Grid>
        </Grid>
      </StyledDiv>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={openTooltip}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handleTooltipClose}
        disableRestoreFocus
      >
        <ContactTooltip open={openTooltip} contactId={tooltipContactId} email={tooltipEmail} />
      </Popover>
      {Boolean(cannedResponses?.length) && setCannedResponseText && (
        <CannedResponseControl cannedResponses={cannedResponses ? cannedResponses : []} setCannedResponseText={setCannedResponseText} />
      )}
      {ticket && <AttachmentsList ticket={ticket} />}
      {Boolean(ticket?.timesheets?.length) && <TimeManager timesheets={ticket.timesheets} />}
      <RelatedTickets
        tickets={dataRelatedTickets?.me?.tickets[0]?.relatedTickets ? dataRelatedTickets.me.tickets[0].relatedTickets : []}
        sourceTicketId={ticket.id}
        sourceTicketSubject={ticket.subject}
        loading={loadingRelatedTickets}
      />
      <EditSubjectDialog
        open={openEditSubject}
        setOpen={setOpenEditSubject}
        subject={ticket?.subject ? ticket.subject : ''}
        ticketId={ticket.id}
      />
      <ConfirmDeleteTagModal open={deleteTagConfirm} setOpen={setDeleteTagConfirm} tagId={deleteTagId} tagName={deleteTagName} />
      <NotHappyDialog open={openNotHappy} setOpen={setOpenNotHappy} ticketId={ticket.id} />
      <AddTagDialog open={showAddTag} setOpen={setShowAddTag} ticket={ticket} />
      <AssignManagersDialog open={openAssignManagers} setOpen={setOpenAssignManagers} ticket={ticket} />
      <AddLogDialog
        open={showLog}
        setOpen={setShowLog}
        aircraftId={ticket?.aircraft?.id ? ticket?.aircraft?.id : ''}
        maintenanceEntries={
          dataAircraftLogs?.savvy?.aircraft?.maintenanceEntries ? dataAircraftLogs?.savvy?.aircraft?.maintenanceEntries : []
        }
      />
      <ShareLogsDialog
        open={showShareLog}
        setOpen={setShowShareLog}
        aircraftId={ticket?.aircraft?.id ? ticket?.aircraft?.id : ''}
        ticketId={ticket.id}
        previousLogIds={previousLogIds}
      />
    </Box>
  );
};

export default TicketInfoBox;
