import React, { useState, useEffect } from 'react';
import { Dialog, Box } from '@mui/material';
import { ComponentLoading } from 'components';
import CloseIcon from '@mui/icons-material/Close';

interface ImageZoomDialogProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  src: string;
}

const ImageZoomDialog: React.FC<ImageZoomDialogProps> = (props) => {
  const { open, setOpen, src } = props;

  const [loadingImage, setLoadingImage] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (open && src) {
      setLoadingImage(true);
    }
  }, [open, src]);

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={handleClose}
      onClick={handleClose}
      PaperProps={{
        sx: {
          maxWidth: 'none',
        },
      }}
    >
      <ComponentLoading loading={loadingImage}>
        <Box
          sx={{
            position: 'relative',
            cursor: 'zoom-out',
          }}
        >
          <Box
            component={'img'}
            src={src}
            sx={{
              width: '100%',
              height: 'auto',
            }}
            onLoad={() => {
              setLoadingImage(false);
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              cursor: 'pointer',
              top: 0,
              right: 0,
              py: '3px',
              px: '4.5px',
              borderRadius: '6px',
              backgroundColor: 'text.secondary',
            }}
            onClick={handleClose}
          >
            <CloseIcon
              sx={{
                color: 'white',
                fontSize: '2em',
              }}
            />
          </Box>
        </Box>
      </ComponentLoading>
    </Dialog>
  );
};

export default ImageZoomDialog;
