import { IDropDownOption } from 'types';
const aircraftIgnitions: IDropDownOption[] = [
  {
    id: 'NONE',
    name: 'Not Set',
  },
  {
    id: 'BENDIX_MAG',
    name: 'Bendix unpressurized mag',
  },
  {
    id: 'BENDIX_PRESSURIZED_MAG',
    name: 'Bendix pressurized mag',
  },
  {
    id: 'BENDIX_DUAL_MAG',
    name: 'Bendix dual mag',
  },
  {
    id: 'SLICK_MAG',
    name: 'Slick unpressurized mag',
  },
  {
    id: 'SLICK_PRESSURIZED_MAG',
    name: 'Slick pressurized mag',
  },
  {
    id: 'EFII',
    name: 'Efii',
  },
  {
    id: 'ELECTROAIRE',
    name: 'ElectroAire',
  },
  {
    id: 'LIGHTSPEED_PLASMA_II',
    name: 'LightSpeed Plasma II',
  },
  {
    id: 'LIGHTSPEED_PLASMA_III',
    name: 'LightSpeed Plasma III',
  },
  {
    id: 'EMAG',
    name: 'EMag',
  },
  {
    id: 'PMAG',
    name: 'PMag',
  },
  {
    id: 'SUREFLY',
    name: 'SureFly',
  },
  {
    id: 'UNISON_LASAR',
    name: 'Unison Lasar',
  },
];

export default aircraftIgnitions;
