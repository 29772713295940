import Highcharts from 'highcharts';
import GamiManager from './GamiManager';

class ModeManager {
  _normal: boolean;
  _diff: boolean;
  _rate: boolean;
  _gami_single: boolean;
  _gami_right: boolean;
  _gami_left: boolean;
  _mag_single: boolean;
  _mag_left: boolean;
  _mag_right: boolean;
  _rate_period: number;
  gamiManager: GamiManager | undefined;
  _anomaly: boolean;
  _minMax: boolean;
  _series_index: string[];

  constructor() {
    this._normal = true;
    this._diff = false;
    this._rate = false;
    this._gami_single = false;
    this._gami_right = false;
    this._gami_left = false;
    this._mag_left = false;
    this._mag_right = false;
    this._mag_single = false;
    this._rate_period = 60;
    this._anomaly = false;
    this.gamiManager = undefined;
    this._minMax = false;
    this._series_index = [];
  }

  init(this: ModeManager) {
    this._normal = true;
    this._diff = false;
    this._rate = false;
    this._gami_single = false;
    this._gami_right = false;
    this._gami_left = false;
    this._mag_left = false;
    this._mag_right = false;
    this._mag_single = false;
    this._rate_period = 60;
    this._anomaly = false;
    this._minMax = false;
    this.gamiManager = undefined;
  }

  setGamiManager(this: ModeManager, gamiManager: GamiManager) {
    this.gamiManager = gamiManager;
  }

  rate(this: ModeManager) {
    return this._rate;
  }

  diff(this: ModeManager) {
    return this._diff;
  }

  normal(this: ModeManager) {
    return this._normal;
  }

  gami(this: ModeManager) {
    return this._gami_single || this._gami_left || this._gami_right;
  }

  mag(this: ModeManager) {
    return this._mag_single || this._mag_left || this._mag_right;
  }

  anomaly(this: ModeManager) {
    return this._anomaly;
  }

  minMax(this: ModeManager) {
    return this._minMax;
  }

  set_mode_mag_off(this: ModeManager) {
    this._normal = true;
    this._diff = false;
    this._rate = false;
    this._mag_single = false;
    this._mag_right = false;
    this._mag_left = false;
    this._anomaly = false;
    this._minMax = false;

    this.clearAnalysis();
  }

  set_mode_gami_off(this: ModeManager) {
    this._normal = true;
    this._diff = false;
    this._rate = false;
    this._gami_single = false;
    this._gami_right = false;
    this._gami_left = false;
    this._anomaly = false;
    this._minMax = false;

    this.clearAnalysis();
  }

  set_anomaly_off(this: ModeManager) {
    this._normal = true;
    this._diff = false;
    this._rate = false;
    this._anomaly = false;
    this._minMax = false;

    this.clearAnalysis();
  }

  set_minMax_off(this: ModeManager) {
    this._normal = true;
    this._diff = false;
    this._rate = false;
    this._anomaly = false;
    this._minMax = false;

    this.clearAnalysis();
  }

  set_diff(this: ModeManager) {
    if (this.gami() || !this.diff()) {
      this.set_mode_gami_off();
    }

    if (this.mag() || !this.diff()) {
      this.set_mode_mag_off();
    }

    if (this.anomaly() || !this.diff()) {
      this.set_anomaly_off();
    }

    if (this.minMax() || !this.diff()) {
      this.set_minMax_off();
    }

    this._diff = true;
    if (this._diff) {
      this._normal = false;
      this._rate = false;
      this._gami_single = false;
      this._gami_right = false;
      this._gami_left = false;

      this._mag_single = false;
      this._mag_right = false;
      this._mag_left = false;
      this._anomaly = false;
      this._minMax = false;
    }
  }

  toggle_diff(this: ModeManager) {
    if (this.gami() || (!this.diff() && !this.rate())) {
      this.set_mode_gami_off();
    }

    if (this.mag() || (!this.diff() && !this.rate())) {
      this.set_mode_mag_off();
    }

    if (this.anomaly() || (!this.diff() && !this.rate())) {
      this.set_anomaly_off();
    }

    if (this.minMax() || (!this.diff() && !this.rate())) {
      this.set_minMax_off();
    }

    this._diff = !this._diff;
    if (this._diff) {
      this._normal = false;
      this._rate = false;
      this._gami_single = false;
      this._gami_right = false;
      this._gami_left = false;

      this._mag_single = false;
      this._mag_right = false;
      this._mag_left = false;
      this._anomaly = false;
      this._minMax = false;
    } else {
      this.clearAnalysis();
    }
  }

  toggle_anomaly(this: ModeManager) {
    if (this.gami() || !this.anomaly()) {
      this.set_mode_gami_off();
    }

    if (this.mag() || !this.anomaly()) {
      this.set_mode_mag_off();
    }

    if (this.diff() || !this.anomaly()) {
      this._diff = false;
    }

    if (this.rate() || !this.anomaly()) {
      this._rate = false;
    }

    if (this.minMax() || !this.anomaly()) {
      this._minMax = false;
    }

    this._anomaly = !this._anomaly;

    if (this._anomaly) {
      this._normal = false;
      this._diff = false;
      this._rate = false;
      this._gami_single = false;
      this._gami_right = false;
      this._gami_left = false;

      this._mag_single = false;
      this._mag_right = false;
      this._mag_left = false;
      this._minMax = false;
    } else {
      this.clearAnalysis();
    }
  }

  toggle_minMax(this: ModeManager) {
    if (this.gami() || !this.minMax()) {
      this.set_mode_gami_off();
    }

    if (this.mag() || !this.minMax()) {
      this.set_mode_mag_off();
    }

    if (this.diff() || !this.minMax()) {
      this._diff = false;
    }

    if (this.rate() || !this.minMax()) {
      this._rate = false;
    }

    this._minMax = !this._minMax;

    if (this._minMax) {
      this._normal = false;
      this._diff = false;
      this._rate = false;
      this._gami_single = false;
      this._gami_right = false;
      this._gami_left = false;

      this._mag_single = false;
      this._mag_right = false;
      this._mag_left = false;

      this._anomaly = false;
    } else {
      this.clearAnalysis();
    }
  }

  toggle_rate(this: ModeManager) {
    if (this.gami() || (!this.rate() && !this.diff())) {
      this.set_mode_gami_off();
    }

    if (this.mag() || (!this.rate() && !this.diff())) {
      this.set_mode_mag_off();
    }

    if (this.anomaly() || (!this.diff() && !this.rate())) {
      this.set_anomaly_off();
    }

    if (this.minMax() || (!this.diff() && !this.rate())) {
      this.set_minMax_off();
    }

    this._rate = !this._rate;
    if (this._rate) {
      this._normal = false;
      this._diff = false;
      this._gami_single = false;
      this._gami_right = false;
      this._gami_left = false;

      this._mag_single = false;
      this._mag_right = false;
      this._mag_left = false;
      this._anomaly = false;
      this._minMax = false;
    } else {
      this.clearAnalysis();
    }
  }

  toggle_rate_period(this: ModeManager) {
    if (this._rate_period === 1) {
      this._rate_period = 60;
    } else {
      this._rate_period = 1;
    }
  }

  rate_period(this: ModeManager) {
    return this._rate_period;
  }

  toggle_gami(this: ModeManager, gami_mode: string) {
    if (gami_mode === 'gami') {
      this._normal = false;
      this._diff = false;
      this._rate = false;
      this._gami_single = true;
      this._gami_right = false;
      this._gami_left = false;
      this._mag_single = false;
      this._mag_right = false;
      this._mag_left = false;
      this._anomaly = false;
      this._minMax = false;
    } else if (gami_mode === 'gami-left') {
      if (!this._gami_left && this.gami()) {
        this.set_mode_gami_off();
      }
      this._normal = false;
      this._diff = false;
      this._rate = false;
      this._gami_single = false;
      this._gami_right = false;
      this._gami_left = true;
      this._mag_single = false;
      this._mag_right = false;
      this._mag_left = false;
      this._anomaly = false;
      this._minMax = false;
    } else if (gami_mode === 'gami-right') {
      if (!this._gami_right && this.gami()) {
        this.set_mode_gami_off();
      }
      this._normal = false;
      this._diff = false;
      this._rate = false;
      this._gami_single = false;
      this._gami_right = true;
      this._gami_left = false;
      this._mag_single = false;
      this._mag_right = false;
      this._mag_left = false;
      this._anomaly = false;
      this._minMax = false;
    }

    let firstChart: Highcharts.Chart | undefined = undefined;

    for (const chart of Highcharts.charts) {
      if (!chart) continue;

      firstChart = chart;
      break;
    }

    // chart_controller.setChartSeriesData(chart);

    // Bold FF and hide TITs

    // var tit_match = new RegExp(/(?:[LR]-)?(TIT)(\d)/)
    const ff_match = new RegExp(/(?:[LR]-)?FF/);

    if (!firstChart) return;

    for (const serie of firstChart.series as any) {
      // if (tit_match.exec(serie.name) !== null) {
      //   serie.hide();
      // }

      if (ff_match.exec(serie.name) !== null) {
        serie.graph?.attr('stroke-width', 4);
        serie.options.lineWidth = 4;
      }
    }
  }

  toggle_mag(this: ModeManager, mag_mode: string) {
    if (this.gami()) {
      this.set_mode_gami_off();
      return false;
    }

    if (this.mag()) {
      this.set_mode_mag_off();
      return false;
    }

    if (mag_mode === 'mag') {
      this._normal = true;
      this._diff = false;
      this._rate = false;
      this._mag_single = false;
      this._mag_right = false;
      this._mag_left = false;
      this._gami_single = false;
      this._gami_right = false;
      this._gami_left = false;
      this._anomaly = false;
      this._minMax = false;
    } else if (mag_mode === 'mag-left') {
      this._normal = true;
      this._diff = false;
      this._rate = false;
      this._mag_single = false;
      this._mag_right = false;
      this._mag_left = false;
      this._gami_single = false;
      this._gami_right = false;
      this._gami_left = false;
      this._anomaly = false;
      this._minMax = false;
    } else if (mag_mode === 'mag-right') {
      this._normal = true;
      this._diff = false;
      this._rate = false;
      this._mag_single = false;
      this._mag_right = false;
      this._mag_left = false;
      this._gami_single = false;
      this._gami_right = false;
      this._gami_left = false;
      this._anomaly = false;
      this._minMax = false;
    }

    this.gamiManager?.removeGamiLines();
    this.clearMinMax();
  }

  clearAnalysis(this: ModeManager) {
    this._normal = true;

    for (const chart of Highcharts.charts) {
      if (!chart) continue;

      chart.xAxis[0]?.removePlotLine('primary-selector');
      chart.xAxis[0]?.removePlotLine('secondary-selector');
    }

    this.gamiManager?.removeGamiLines();
    this.gamiManager?.init();

    this.clearMinMax();
  }

  clearMinMax(this: ModeManager) {
    for (const chart of Highcharts.charts) {
      if (!chart) continue;

      for (let i = 0; i < this._series_index.length; i++) {
        chart.xAxis[0]?.removePlotLine(`${this._series_index[i]}-min-line`);
        chart.xAxis[0]?.removePlotLine(`${this._series_index[i]}-max-line`);
      }
    }

    return;
  }

  drawMinMax(this: ModeManager) {
    this.clearMinMax();
    if (this._minMax) {
      this._series_index = [];
      for (const chart of Highcharts.charts) {
        if (!chart) continue;

        for (const serie of chart.series) {
          if (!serie) continue;

          this._series_index.push(serie.name);

          const series_data = [...serie.data];

          series_data.sort((a: Highcharts.Point, b: Highcharts.Point) => {
            if (a.y !== undefined && b.y !== undefined && a.y > b.y) return -1;
            return 1;
          });

          for (let i = 0; i < series_data.length; i++) {
            if (series_data[i].x !== undefined && series_data[i].y !== undefined) {
              chart?.xAxis[0]?.addPlotLine({
                color: 'red',
                value: series_data[i].x,
                width: 1,
                id: `${serie.name}-max-line`,
                label: { text: `${serie.name}-max-line`, style: { color: '#000' } },
                zIndex: 5,
              });

              break;
            }
          }

          for (let i = series_data.length - 1; i >= 0; i--) {
            if (series_data[i]?.x !== undefined && series_data[i]?.y !== undefined) {
              chart?.xAxis[0]?.addPlotLine({
                color: 'blue',
                value: series_data[i].x,
                width: 1,
                id: `${serie.name}-min-line`,
                label: { text: `${serie.name}-min-line`, style: { color: '#000' } },
                zIndex: 5,
              });

              break;
            }
          }
        }
      }
    }
  }
}

export default ModeManager;
