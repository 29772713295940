import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Link, Typography, Box, Container, Divider, Grid } from '@mui/material';
import { PATH_LEGAL, PATH_TICKETS_CREATE } from 'const';
import { ctrlCmdClick } from 'helpers';

// Footer. Fixed to bottom
const Footer: React.FC = () => {
  const history = useHistory();
  const { loggedIn } = useSelector((state: any) => state.auth);

  return (
    <Box mt="auto">
      <Container>
        <Box py={2} mb={2} bgcolor="grey.200" borderRadius={2} textAlign="center" component="footer">
          <Grid container justifyContent="center">
            <Grid item lg={6}>
              <Grid container justifyContent="center">
                <Grid item>
                  <Link
                    href={PATH_LEGAL}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      ctrlCmdClick(e, PATH_LEGAL, history);
                    }}
                  >
                    Terms &amp; Privacy
                  </Link>
                </Grid>
                <Divider
                  orientation="vertical"
                  variant="middle"
                  sx={{
                    width: '10px',
                  }}
                  flexItem
                />
                <Grid item>
                  <Link
                    href={loggedIn ? `${PATH_TICKETS_CREATE}` : 'mailto:support@savvyaviation.com'}
                    onClick={(e) => {
                      if (loggedIn) {
                        e.preventDefault();
                        e.stopPropagation();
                        ctrlCmdClick(e, `${PATH_TICKETS_CREATE}`, history);
                      }
                    }}
                  >
                    Support / Contact
                  </Link>
                </Grid>
              </Grid>

              <Typography variant="body1">© {new Date().getFullYear()} - Savvy Aviation, Inc. - All Rights Reserved</Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
