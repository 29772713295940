// Use App.tsx to include providers - state, theme, etc
// To include UI components (like Menu, Footer, etc) use layouts from javascript/components/Layout/ folder
import React, { useEffect } from 'react';

// Stripe
// https://stripe.com/docs/stripe-js/react
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { LicenseInfo } from '@mui/x-license-pro';

// Sentry
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/browser';

// GraphQL
import { ApolloProvider } from '@apollo/client';
import { apolloClient, initAmplitude } from 'services';

// Redux state
import { Provider as StateProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from 'state';

// Theme
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { theme } from 'theme';
import { CssBaseline } from '@mui/material';

import { Router } from 'components';

// Tag Manager
import TagManager from 'react-gtm-module';

// Styles that cannot be set with Material
import 'theme/scss/index.scss';

let _csrfToken = '';

async function getCsrfToken() {
  if (_csrfToken === null) {
    const response = await fetch(`/csrf`, {
      credentials: 'include',
    });
    const data = await response.json();
    _csrfToken = data.csrfToken;
  }
}

if (process.env.REACT_APP_GTM) {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM,
  };

  if (process.env.NODE_ENV !== 'test') {
    TagManager.initialize(tagManagerArgs);
  }
}

// Init Stripe
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY as string);

const App: React.FC = () => {
  // License Mui x
  LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_X_KEY as string);

  // Init Metrics
  initAmplitude();

  useEffect(() => {
    getCsrfToken();
  });

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      new BrowserTracing(),
      new Sentry.Replay({
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
    release: process.env.REACT_APP_BUILD_SHA,
    tunnel: '/debug-events',
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });

  return (
    <ApolloProvider client={apolloClient}>
      <PersistGate loading={null} persistor={persistor}>
        <StateProvider store={store}>
          <ThemeProvider theme={theme}>
            {/*Material styles. injectFirst - put component styles style.module.scss on top of Material*/}
            <StyledEngineProvider injectFirst>
              {/*Base styles - font, text, etc. All pages and components goes after to have base styles*/}
              <CssBaseline />

              {/* Stripe UI provider */}
              <Elements stripe={stripePromise}>
                <Router />
                {/*App pages*/}
              </Elements>
            </StyledEngineProvider>
          </ThemeProvider>
        </StateProvider>
      </PersistGate>
    </ApolloProvider>
  );
};

export default App;
