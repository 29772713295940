// State action types

// Auth
export const AUTH_LOG_IN = 'AUTH_LOG_IN';
export const AUTH_LOG_OUT = 'AUTH_LOG_OUT';

export const AUTH_MISSING_TOKEN = 'AUTH_MISSING_TOKEN';
export const SET_MISSING_TOKEN = 'SET_MISSING_TOKEN';

// Account
export const CREATE_ACCOUNT_SUCCESS = 'CREATE_ACCOUNT_SUCCESS';
export const CREATE_ACCOUNT_ERROR = 'CREATE_ACCOUNT_ERROR';
export const ACCOUNT_EXIST_ERROR = 'ACCOUNT_EXIST_ERROR';

// Login
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';

// Logout
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'LOGOUT_ERROR';

// Reset Password
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';

// Signup for a Service
export const SIGNUP_SERVICE_SUCCESS = 'SIGNUP_SERVICE_SUCCESS';

// Aircraft
export const CREATE_AIRCRAFT_ERROR = 'CREATE_AIRCRAFT_ERROR';
export const CREATE_AIRCRAFT_SUCCESS = 'CREATE_AIRCRAFT_SUCCESS';

// Alert
export const ALERT_SET = 'ALERT_SET';
export const ALERT_REMOVE = 'ALERT_REMOVE';

// Personal Info
export const CHANGE_PERSONAL_INFO_ERROR = 'CHANGE_PERSONAL_INFO_ERROR';
export const CHANGE_PERSONAL_INFO_SUCCESS = 'CHANGE_PERSONAL_INFO_SUCCESS';

// Payment
export const PAYMENT_SUCCESS = 'PAYMENT_SUCCESS';
export const PAYMENT_ERROR = 'PAYMENT_ERROR';

export const SET_PRICE_FOR_SERVICE = 'SET_PRICE_FOR_SERVICE';

export const SET_SIGNUP_INFO = 'SET_AIRCRAFT_ID';

export const SET_HOVER_POINT = 'SET_HOVER_POINT';

export const SET_POST_TEXT = 'SET_POST_TEXT';
export const SET_TICKET_ID = 'SET_TICKET_ID';
export const REMOVE_POST_TEXT = 'REMOVE_POST_TEXT';

export const SET_EXISTING_FILES = 'SET_EXISTING_FILES';
export const SET_UPLOADED_FILES = 'SET_UPLOADED_FILES';
export const SET_TIMESHEETS = 'SET_TIMESHEETS';
export const SET_SNOOZE = 'SET_SNOOZE';
export const SET_TICKET_VISIBILITY = 'SET_TICKET_VISIBILITY';
export const SET_CLOSE_TICKET_AFTER_POST = 'SET_CLOSE_TICKET_AFTER_POST';
export const SET_ANALYSIS_TAB = 'SET_ANALYSIS_TAB';

export const SET_FLIGHT_TABLE_CONFIG = 'SET_FLIGHT_TABLE_CONFIG';

export const SET_UNREAD_POST = 'SET_UNREAD_POST';

export const SET_START_TIME = 'SET_START_TIME';
export const SET_STOP_TIME = 'SET_STOP_TIME';
export const SET_STOPPED_TIME = 'SET_STOPPED_TIME';
export const SET_INITIAL_TIMER_OPTIONS = 'SET_INITIAL_TIMER_OPTIONS';

export const SET_TABLE_VIEW_CONFIG = 'SET_TABLE_VIEW_CONFIG';
export const SET_TABLE_FLIGHT_FILTER = 'SET_TABLE_FLIGHT_FILTER';
export const SET_TABLE_FLIGHT_SORT = 'SET_TABLE_FLIGHT_SORT';
export const SET_TABLE_FLIGHT_PAGE = 'SET_TABLE_FLIGHT_PAGE';
export const SET_TABLE_FLIGHT_HIDE_SHORT = 'SET_TABLE_FLIGHT_HIDE_SHORT';

export const SET_SELECTED_AIRCRAFT = 'SET_SELECTED_AIRCRAFT';

export const SET_SERVICE_FEATURE_FLAG = 'SET_SERVICE_FEATURE_FLAG';
export const SET_VIEW_ANONYMIZED = 'SET_VIEW_ANONYMIZED';
