import React, { useState, useEffect } from 'react';

import { Grid } from '@mui/material';
import { useQuery } from '@apollo/client';

import { Autocomplete } from 'components';
import { QUERY_ENGINE_MONITOR_MANUFACTURER } from 'gql';
import { IDropDownOption, IInputError } from 'types';
import { ADD_OPTION_CLICKED_NO_VALUE, ADD_OPTION_CLICKED_TYPED_VALUE } from 'const';
import { getOptionLabelIdName, formatOptionIdName } from 'helpers';

import { DialogAddEngineMonitorManufacturerSuggestions, DialogAddEngineMonitorModelSuggestions } from '../DialogAddAircraft';

interface IEngineMonitorOption {
  id: string;
  name: string;
  enginemonitormodelSet: any[];
}

interface EngineMonitorMakeModelInterface {
  engineMonitorManufacturer: any;
  setEngineMonitorManufacturer: (value: any) => void;
  errorEngineMonitorManufacturer: IInputError;
  onChangeEngineMonitorManufacturer: (option: any) => void;
  engineMonitorModel: any;
  setEngineMonitorModel: (value: any) => void;
  errorEngineMonitorModel: IInputError;
  onChangeEngineMonitorModel: (option: any) => void;
  installedEngineMonitor: boolean;
  disabled?: boolean;
}

const EngineMonitorMakeModel: React.FC<EngineMonitorMakeModelInterface> = (props) => {
  const {
    engineMonitorManufacturer,
    setEngineMonitorManufacturer,
    errorEngineMonitorManufacturer,
    onChangeEngineMonitorManufacturer,
    engineMonitorModel,
    setEngineMonitorModel,
    errorEngineMonitorModel,
    onChangeEngineMonitorModel,
    installedEngineMonitor,
    disabled = false,
  } = props;

  const { data: dataEngineMonitorManufacturer, loading: loadingEngineMonitorManufacturer } = useQuery(QUERY_ENGINE_MONITOR_MANUFACTURER);
  const [engineMonitorManufacturerOptions, setEngineMonitorManufacturerOptions] = useState<IEngineMonitorOption[]>([]);
  const [engineMonitorModelOptions, setEngineMonitorModelOptions] = useState<IDropDownOption[]>([]);

  const ADD_AUTOCOMPLETE_TEXT = '-- Add your own --';

  const [openAddEngineMonitorManufacturer, setOpenAddEngineMonitorManufacturer] = useState(false);
  const [openAddEngineMonitorModel, setOpenAddEngineMonitorModel] = useState(false);

  const [engineMonitorModelDisabled, setEngineMonitorModelDisabled] = useState(true);

  useEffect(() => {
    if (dataEngineMonitorManufacturer?.engineMonitorManufacturer !== undefined) {
      const sortEngineMonitorManufacturer = [...dataEngineMonitorManufacturer.engineMonitorManufacturer];
      sortEngineMonitorManufacturer.sort((x: IEngineMonitorOption, y: IEngineMonitorOption) => {
        if (x.name > y.name) return 1;
        else return -1;
      });
      setEngineMonitorManufacturerOptions([
        ...sortEngineMonitorManufacturer,
        {
          id: '',
          name: ADD_AUTOCOMPLETE_TEXT,
        },
      ]);
    }
  }, [dataEngineMonitorManufacturer]);

  useEffect(() => {
    setEngineMonitorModel(null);

    if (engineMonitorManufacturer?.id && engineMonitorManufacturer.enginemonitormodelSet) {
      const engineMonitorModelSet = [...engineMonitorManufacturer.enginemonitormodelSet];
      engineMonitorModelSet.sort((x: IDropDownOption, y: IDropDownOption) => {
        if (x.name > y.name) return 1;
        else return -1;
      });
      setEngineMonitorModelOptions([
        ...engineMonitorModelSet,
        {
          id: '',
          name: ADD_AUTOCOMPLETE_TEXT,
        },
      ]);
    } else {
      setEngineMonitorModelOptions([
        {
          id: '',
          name: ADD_AUTOCOMPLETE_TEXT,
        },
      ]);
    }

    if (
      engineMonitorManufacturer?.type === ADD_OPTION_CLICKED_NO_VALUE ||
      engineMonitorManufacturer?.type === ADD_OPTION_CLICKED_TYPED_VALUE
    ) {
      setEngineMonitorManufacturer({
        id: '',
        name:
          engineMonitorManufacturer?.name && engineMonitorManufacturer.name !== '-- Add your own --'
            ? engineMonitorManufacturer.name.replace('Add ', '')
            : '',
      });
      setOpenAddEngineMonitorManufacturer(true);
      setOpenAddEngineMonitorModel(false);
    }

    if (engineMonitorManufacturer === null || (!engineMonitorManufacturer.id && !engineMonitorManufacturer.name)) {
      setEngineMonitorModelDisabled(true);
    } else {
      setEngineMonitorModelDisabled(false);
    }
  }, [engineMonitorManufacturer, setEngineMonitorModelOptions, setEngineMonitorManufacturer, setEngineMonitorModel]);

  useEffect(() => {
    if (engineMonitorModel?.type === ADD_OPTION_CLICKED_NO_VALUE || engineMonitorModel?.type === ADD_OPTION_CLICKED_TYPED_VALUE) {
      setEngineMonitorModel({
        id: '',
        name:
          engineMonitorModel?.name && engineMonitorModel.name !== '-- Add your own --' ? engineMonitorModel.name.replace('Add ', '') : '',
      });

      setOpenAddEngineMonitorModel(true); // Open dialog
      setOpenAddEngineMonitorManufacturer(false);
    }
  }, [engineMonitorModel, setEngineMonitorModel]);

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Autocomplete
          label="Engine Monitor Manufacturer *"
          name="engineMonitorManufacturer"
          value={engineMonitorManufacturer}
          error={!installedEngineMonitor ? errorEngineMonitorManufacturer : ''}
          onChange={onChangeEngineMonitorManufacturer}
          options={engineMonitorManufacturerOptions}
          getOptionLabel={getOptionLabelIdName}
          formatOption={formatOptionIdName}
          loading={loadingEngineMonitorManufacturer}
          addOptionLabel={ADD_AUTOCOMPLETE_TEXT}
          placeholder="Start typing to search..."
          disabled={installedEngineMonitor || disabled}
          renderOptionFlag={true}
        />
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          label="Engine Monitor Model *"
          name="engineMonitorModel"
          value={engineMonitorModel}
          error={!installedEngineMonitor ? errorEngineMonitorModel : ''}
          onChange={onChangeEngineMonitorModel}
          options={engineMonitorModelOptions}
          getOptionLabel={getOptionLabelIdName}
          formatOption={formatOptionIdName}
          loading={loadingEngineMonitorManufacturer}
          addOptionLabel={ADD_AUTOCOMPLETE_TEXT}
          disabled={engineMonitorModelDisabled || installedEngineMonitor || disabled}
          placeholder="Start typing to search..."
          renderOptionFlag={true}
        />
      </Grid>
      <DialogAddEngineMonitorManufacturerSuggestions
        engineMonitorManufacturer={engineMonitorManufacturer}
        setEngineMonitorManufacturer={setEngineMonitorManufacturer}
        open={openAddEngineMonitorManufacturer}
        setOpen={setOpenAddEngineMonitorManufacturer}
      />

      <DialogAddEngineMonitorModelSuggestions
        engineMonitorManufacturer={engineMonitorManufacturer}
        engineMonitorModel={engineMonitorModel}
        setEngineMonitorModel={setEngineMonitorModel}
        open={openAddEngineMonitorModel}
        setOpen={setOpenAddEngineMonitorModel}
      />
    </React.Fragment>
  );
};

export default EngineMonitorMakeModel;
