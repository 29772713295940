import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { MUTATION_LOGIN_WITH_CODE } from 'gql';
import { useMutation } from '@apollo/client';

import { LayoutLoggedOut, PageLoading } from 'components';

import { LOGIN_SUCCESS_MESSAGE, PATH_SIGNUP_COMPLETE_ADDITIONAL_INFO } from 'const';
import { setAlert } from 'state';

const useQuery = () => new URLSearchParams(useLocation().search);

const LoginWithCode: React.FC = () => {
  const { uuid } = useParams<{
    uuid: string;
  }>();

  const [LoginWithCode, { data, loading, error }] = useMutation(MUTATION_LOGIN_WITH_CODE);

  const dispatch = useDispatch();

  const query = useQuery();
  const nextUrl = query.get('next');

  useEffect(() => {
    if (uuid) {
      LoginWithCode({
        variables: {
          code: uuid,
        },
      });
    }
  }, [uuid, LoginWithCode]);

  useEffect(() => {
    if (!URLSearchParams) {
      dispatch(setAlert('error', 'Your browser is out of date. Please update to a newer version.'));
    }
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      dispatch(setAlert('error', error.message, 'ALERT_SERVER_ERROR_ID'));
    } else if (data) {
      const { ok, me, redirect } = data.loginWithCode;

      if (redirect) {
        window.location.href = redirect;
        return;
      }

      if (ok) {
        dispatch(setAlert('success', LOGIN_SUCCESS_MESSAGE, 'ALERT_LOGIN_SUCCESS_ID'));

        setTimeout(() => {
          if (!me?.incompleteAircraft?.length) {
            if (nextUrl) window.location.href = nextUrl;
            else window.location.href = '/';
          } else {
            if (me?.incompleteAircraft[0]?.subscriptions?.length) {
              window.location.href = `/signup/${me.incompleteAircraft[0].subscriptions[0].serviceCode}/additional-info`;
            } else {
              window.location.href = PATH_SIGNUP_COMPLETE_ADDITIONAL_INFO;
            }
          }
        }, 500);
      }
    }
  }, [error, data, dispatch, nextUrl]);

  return <LayoutLoggedOut>{loading && <PageLoading />}</LayoutLoggedOut>;
};

export default LoginWithCode;
