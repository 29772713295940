import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Dialog, DialogTitle, DialogContent, MenuItem, Grid, Button, Box } from '@mui/material';
import { ComponentLoading, MuiFormikInput, ButtonSubmit } from 'components';
import { Formik, Form, Field } from 'formik';
import { ReportCardParameterSchema } from './';
import { TextField } from 'formik-mui';

import { QUERY_REPORT_CARD_PARAMETER_NAMES, QUERY_REPORT_CARD_PARAMETER_UNITS, MUTATION_UPDATE_REPORT_CARD_PARAMETER } from 'gql';
import { useLazyQuery, useMutation } from '@apollo/client';

import { setAlert } from 'state';
import { IReportCardParameter } from 'types';

interface UpdateReportCardParameterDialogProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  parameter: IReportCardParameter;
}

const UpdateReportCardParameterDialog: React.FC<UpdateReportCardParameterDialogProps> = (props) => {
  const { open, setOpen, parameter } = props;

  const [fetchParameterNames, { data: dataNames, loading: loadingNames }] = useLazyQuery(QUERY_REPORT_CARD_PARAMETER_NAMES);
  const [fetchParameterUnits, { data: dataUnits, loading: loadingUnits }] = useLazyQuery(QUERY_REPORT_CARD_PARAMETER_UNITS);
  const [
    updateReportCardParameter,
    { data: dataUpdateReportCardParameter, loading: loadingUpdateReportCardParameter, error: errorUpdate },
  ] = useMutation(MUTATION_UPDATE_REPORT_CARD_PARAMETER);
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async (values: any) => {
    await updateReportCardParameter({
      variables: {
        reportCardParamId: parameter.id,
        ...values,
      },
    });
  };

  useEffect(() => {
    if (open) {
      fetchParameterNames();
      fetchParameterUnits();
    }
  }, [open]);

  useEffect(() => {
    if (errorUpdate) {
      dispatch(setAlert('error', 'Unable to update report card parameter'));
    } else if (dataUpdateReportCardParameter) {
      if (dataUpdateReportCardParameter.updateReportCardParameter?.ok) {
        dispatch(setAlert('success', 'Updated report card parameter'));
        setOpen(false);
      } else {
        dispatch(
          setAlert('error', dataUpdateReportCardParameter.updateReportCardParameter?.error || 'Unable to update report card parameter'),
        );
      }
    }
  }, [dataUpdateReportCardParameter, errorUpdate, dispatch]);

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={handleClose}
      PaperProps={{
        sx: {
          maxWidth: '768px',
        },
      }}
    >
      <DialogTitle
        sx={{
          textAlign: 'center',
          fontSize: '24px',
          fontWeight: 700,
        }}
      >
        Create Report Card Parameter
      </DialogTitle>
      <DialogContent>
        <ComponentLoading loading={loadingNames || loadingUnits}>
          <Box py={1}>
            <Formik
              initialValues={{
                colorBarReverseStatus: parameter.colorBarReverseStatus || '',
                name: parameter.name || '',
                outlierLeftStdDevLimit: parameter.outlierLeftStdDevLimit || '',
                outlierLeftValueLimit: parameter.outlierLeftValueLimit || '',
                outlierRightStdDevLimit: parameter.outlierRightStdDevLimit || '',
                outlierRightValueLimit: parameter.outlierRightValueLimit || '',
                presentationOrder: parameter.presentationOrder || '',
                scalingMultiplier: parameter.scalingMultiplier || '',
                specMax: parameter.specMax || '',
                specMin: parameter.specMin || '',
                titleName: parameter.titleName || '',
                units: parameter.units || '',
              }}
              validationSchema={ReportCardParameterSchema}
              onSubmit={onSubmit}
            >
              {({ isSubmitting, handleChange, handleBlur, touched, errors }) => {
                return (
                  <Form>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Field
                          name="name"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          component={TextField}
                          label="Name"
                          select
                          variant="outlined"
                          fullWidth
                          FormHelperTextProps={{
                            style: {
                              color: 'red',
                              marginLeft: 0,
                            },
                          }}
                        >
                          {dataNames?.reportCardParameterNames?.map((item: string, key: number) => {
                            return (
                              <MenuItem value={item} key={key}>
                                {item}
                              </MenuItem>
                            );
                          })}
                        </Field>
                      </Grid>
                      <Grid item xs={6}>
                        <MuiFormikInput
                          name="colorBarReverseStatus"
                          label="Color Bar Reverse Status"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          type="number"
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <MuiFormikInput
                          name="titleName"
                          label="Title Name"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          name="units"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          component={TextField}
                          label="Units"
                          select
                          variant="outlined"
                          fullWidth
                          FormHelperTextProps={{
                            style: {
                              color: 'red',
                              marginLeft: 0,
                            },
                          }}
                        >
                          {dataUnits?.reportCardParameterUnits?.map((item: string, key: number) => {
                            return (
                              <MenuItem value={item} key={key}>
                                {item}
                              </MenuItem>
                            );
                          })}
                        </Field>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <MuiFormikInput
                          name="outlierLeftStdDevLimit"
                          label="Outlier Left Std Dev Limit"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          type="number"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <MuiFormikInput
                          name="outlierLeftValueLimit"
                          label="Outlier Left Value Limit"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          type="number"
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <MuiFormikInput
                          name="outlierRightStdDevLimit"
                          label="Outlier Right Std Dev Limit"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          type="number"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <MuiFormikInput
                          name="outlierRightValueLimit"
                          label="Outlier Right Value Limit"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          type="number"
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <MuiFormikInput
                          name="presentationOrder"
                          label="Presentation Order"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          type="number"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <MuiFormikInput
                          name="scalingMultiplier"
                          label="Scaling Multiplier"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          type="number"
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <MuiFormikInput
                          name="specMax"
                          label="Spec Max"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          type="number"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <MuiFormikInput
                          name="specMin"
                          label="Spec Min"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          type="number"
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Button
                          variant="outlined"
                          onClick={handleClose}
                          disabled={loadingNames || loadingUnits || loadingUpdateReportCardParameter}
                          fullWidth
                        >
                          Cancel
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <ButtonSubmit
                          text={'Update'}
                          loading={isSubmitting}
                          disabled={loadingNames || loadingUnits || loadingUpdateReportCardParameter}
                        />
                      </Grid>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </Box>
        </ComponentLoading>
      </DialogContent>
    </Dialog>
  );
};

export default UpdateReportCardParameterDialog;
