import React from 'react';
import { Box } from '@mui/material';
import { LayoutDashboard } from 'components';
import { FlightsTable } from './components';

const Flights: React.FC = () => {
  return (
    <LayoutDashboard backgroundColor="#FFFFFF">
      <Box>
        <FlightsTable />
      </Box>
    </LayoutDashboard>
  );
};

export default Flights;
