import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Typography, TextField, Button, Dialog, DialogTitle, DialogContent, MenuItem } from '@mui/material';
import { Dayjs } from 'dayjs';

import { ComponentLoading, CustomDatePicker } from 'components';

import { MUTATION_TERMINATE_SUBSCRIPTION, QUERY_GET_USERS_TICKETS_FOR_SUBSCRIPTION } from 'gql';
import { useMutation, useLazyQuery } from '@apollo/client';

import { ISubscriptionDetail } from 'types';
import { setAlert } from 'state';

interface TerminateProps {
  subscription: ISubscriptionDetail;
  contactId: string;
}

const Terminate: React.FC<TerminateProps> = (props) => {
  const { subscription, contactId } = props;
  const dispatch = useDispatch();

  const [
    terminateSubscription,
    { data: dataTerminateSubscription, loading: loadingTerminateSubscription, error: errorTerminateSubscription },
  ] = useMutation(MUTATION_TERMINATE_SUBSCRIPTION);
  const [getTicketData, { data: dataTicket, loading: loadingTicket, error: errorTicket }] = useLazyQuery(
    QUERY_GET_USERS_TICKETS_FOR_SUBSCRIPTION,
  );

  const [terminateReason, setTerminateReason] = useState('voluntary'); // "voluntary" | "forced" | "savvy"
  const [reasonError, setReasonError] = useState('');

  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const [endDateError, setEndDateError] = useState('');

  const [postTicketOption, setPostTicketOption] = useState('skip'); // "skip" | "existing" : "new"
  const [existingTicketId, setExistingTicketId] = useState('');
  const [existingTicketIdError, setExistingTicketIdError] = useState('');

  const [newTicketSubject, setNewTicketSubject] = useState(`Cancel Renewal ${subscription.aircraft?.registration}`);
  const [newTicketSubjectError, setNewTicketSubjectError] = useState('');

  const [postBody, setPostBody] = useState(
    `We have canceled the renewal for your subscription for ${
      subscription.aircraft ? subscription.aircraft.registration : ''
    }.\n\nYour SavvyAviation Team`,
  );
  const [postBodyError, setPostBodyError] = useState('');

  const [showModal, setShowModal] = useState(false);

  const handleClose = () => {
    setShowModal(false);
  };

  const onSubmit = async (refund: boolean) => {
    let validation = true;

    if (!terminateReason) {
      setReasonError('Please input reason why you are canceling renewal');
      validation = false;
    }

    if (!endDate) {
      setEndDateError('Please input end date');
      validation = false;
    }

    if (postTicketOption === 'existing') {
      if (!existingTicketId) {
        setExistingTicketIdError('Please select existing ticket');
        validation = false;
      }
    }

    if (postTicketOption === 'new') {
      if (!newTicketSubject) {
        setNewTicketSubject('Please input new ticket subject');
        validation = false;
      }
    }

    if (postTicketOption !== 'skip') {
      if (!postBody) {
        setPostBodyError('Please input post body');
        validation = false;
      }
    }

    if (!validation) {
      return;
    }

    await terminateSubscription({
      variables: {
        subscriptionId: subscription.id,
        endDate: endDate ? endDate : new Date(),
        existingTicketId: postTicketOption === 'existing' ? existingTicketId : undefined,
        newTicketSubject: postTicketOption === 'new' ? newTicketSubject : undefined,
        terminationReason: terminateReason,
        postBody: postTicketOption !== 'skip' ? postBody : undefined,
        ticketSwitcher: postTicketOption,
        refund,
      },
    });
  };

  useEffect(() => {
    if (errorTerminateSubscription) {
      dispatch(setAlert('error', 'Unable to terminate subscription'));
    } else if (dataTerminateSubscription) {
      if (dataTerminateSubscription?.terminateSubscription?.ok) {
        dispatch(setAlert('success', 'Terminated subscription'));
        setShowModal(false);
      } else {
        dispatch(setAlert('error', dataTerminateSubscription?.terminateSubscription?.error || 'Unable to terminate subscription'));
      }
    }
  }, [errorTerminateSubscription, dispatch, dataTerminateSubscription]);

  useEffect(() => {
    if (errorTicket) {
      dispatch(setAlert('error', "Unable to load contact's existing tickets"));
    }
  }, [errorTicket, dispatch]);

  useEffect(() => {
    if (postTicketOption === 'existing' && !dataTicket) {
      getTicketData({
        variables: {
          contactId,
        },
      });
    }
  }, [postTicketOption, getTicketData, contactId, dataTicket]);

  return (
    <Box>
      <Box>
        <Typography
          variant="h3"
          sx={{
            color: 'error.main',
            fontWeight: 'bold',
          }}
        >
          Terminate
        </Typography>
        <Box py={1}>
          <Typography>This will end the subscription right now, and optionally generate a refund.</Typography>
        </Box>
        <Box
          py={1}
          sx={{
            textAlign: 'left',
          }}
        >
          <Button
            variant="contained"
            onClick={() => {
              setShowModal(true);
            }}
          >
            Terminate Subscription...
          </Button>
        </Box>
      </Box>
      <Dialog
        open={showModal}
        fullWidth
        onClose={handleClose}
        PaperProps={{
          sx: {
            maxWidth: '560px',
          },
        }}
      >
        <DialogTitle
          sx={{
            textAlign: 'center',
            fontSize: '24px',
            fontWeight: 700,
          }}
        >
          Terminate Subscription
        </DialogTitle>
        <DialogContent
          sx={{
            px: {
              md: 5,
              xs: 2,
            },
          }}
        >
          <ComponentLoading loading={loadingTicket || loadingTerminateSubscription}>
            <Box py={1}>
              <TextField
                select
                label="Termination Reason *"
                value={terminateReason}
                onChange={(e) => {
                  setTerminateReason(e.target.value);
                  setReasonError('');
                }}
                error={!!reasonError}
                helperText={reasonError}
                FormHelperTextProps={{
                  sx: {
                    ml: 0,
                  },
                }}
                fullWidth
                sx={{
                  mb: 0,
                }}
              >
                <MenuItem value="voluntary">Voluntary</MenuItem>
                <MenuItem value="forced">Forced</MenuItem>
                <MenuItem value="savvy">SavvyEnded</MenuItem>
              </TextField>
            </Box>
            <Box py={1}>
              <CustomDatePicker
                label="End date"
                value={endDate}
                setValue={setEndDate}
                error={endDateError}
                setError={setEndDateError}
                sx={{
                  ml: 0,
                }}
                containerSx={{
                  mb: 0,
                }}
              />
            </Box>
            <Box py={1}>
              <TextField
                select
                label="Make post to a ticket*"
                value={postTicketOption}
                onChange={(e) => {
                  setPostTicketOption(e.target.value);
                }}
                sx={{
                  mb: 0,
                }}
                fullWidth
              >
                <MenuItem value="skip">Don't post</MenuItem>
                <MenuItem value="existing">Post to an existing ticket</MenuItem>
                <MenuItem value="new">Post to a new ticket</MenuItem>
              </TextField>
            </Box>
            {postTicketOption === 'existing' && !!dataTicket?.savvy?.contacts[0]?.tickets?.length && (
              <Box py={1}>
                <TextField
                  select
                  label="Existing Ticket"
                  value={existingTicketId}
                  onChange={(e) => {
                    setExistingTicketId(e.target.value);
                    setExistingTicketIdError('');
                  }}
                  error={!!existingTicketIdError}
                  helperText={existingTicketIdError}
                  FormHelperTextProps={{
                    sx: {
                      ml: 0,
                    },
                  }}
                  fullWidth
                  sx={{
                    mb: 0,
                  }}
                >
                  {dataTicket?.savvy?.contacts[0]?.tickets?.map((ticket: any, key: number) => {
                    return (
                      <MenuItem key={key} value={ticket.id}>
                        {`#${ticket.id} ${ticket.subject}${ticket.aircraft ? ' (' + ticket.aircraft.registration + ')' : ''}`}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Box>
            )}
            {postTicketOption === 'new' && (
              <Box py={1}>
                <TextField
                  label="New Ticket Subject"
                  value={newTicketSubject}
                  onChange={(e) => {
                    setNewTicketSubject(e.target.value);
                    setNewTicketSubjectError('');
                  }}
                  rows={5}
                  error={!!newTicketSubjectError}
                  helperText={newTicketSubjectError}
                  FormHelperTextProps={{
                    sx: {
                      ml: 0,
                    },
                  }}
                  fullWidth
                  sx={{
                    mb: 0,
                  }}
                />
              </Box>
            )}
            {postTicketOption !== 'skip' && (
              <Box py={1}>
                <TextField
                  multiline
                  label="With post body"
                  value={postBody}
                  onChange={(e) => {
                    setPostBody(e.target.value);
                    setPostBodyError('');
                  }}
                  rows={5}
                  error={!!postBodyError}
                  helperText={postBodyError}
                  FormHelperTextProps={{
                    sx: {
                      ml: 0,
                    },
                  }}
                  fullWidth
                  sx={{
                    mb: 0,
                  }}
                />
              </Box>
            )}
            <Box
              sx={{
                textAlign: 'right',
              }}
              py={1}
            >
              <Button
                variant="outlined"
                sx={{
                  width: '100px',
                }}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                color="warning"
                variant="outlined"
                sx={{
                  ml: 2,
                }}
                onClick={() => {
                  onSubmit(true);
                }}
              >
                Terminate & Full Refund
              </Button>
              <Button
                color="primary"
                variant="contained"
                sx={{
                  ml: 2,
                }}
                onClick={() => {
                  onSubmit(false);
                }}
              >
                Terminate
              </Button>
            </Box>
          </ComponentLoading>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default Terminate;
