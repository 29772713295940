import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { useQuery } from '@apollo/client';

import { Autocomplete } from 'components';
import { QUERY_ENGINE_MANUFACTURER } from 'gql';
import { IDropDownOption, IInputError } from 'types';
import { ADD_OPTION_CLICKED_NO_VALUE, ADD_OPTION_CLICKED_TYPED_VALUE } from 'const';
import { getOptionLabelIdName, formatOptionIdName } from 'helpers';

import { DialogAddEngineManufacturerSuggestions, DialogAddEngineModelSuggestions } from '../DialogAddAircraft';

interface IEngineOption {
  id: string;
  name: string;
  enginemodelSet?: any[];
}

interface EngineMakeModelInterface {
  engineManufacturer: any;
  setEngineManufacturer: (value: any) => void;
  errorEngineManufacturer: IInputError;
  onChangeEngineManufacturer: (option: any) => void;
  engineModel: any;
  setEngineModel: (value: any) => void;
  errorEngineModel: IInputError;
  onChangeEngineModel: (option: any) => void;
  disabled?: boolean;
}

const EngineMakeModel: React.FC<EngineMakeModelInterface> = (props) => {
  const {
    engineManufacturer,
    setEngineManufacturer,
    errorEngineManufacturer,
    onChangeEngineManufacturer,
    engineModel,
    setEngineModel,
    errorEngineModel,
    onChangeEngineModel,
    disabled = false,
  } = props;

  const { data: dataEngineManufacturer, loading: loadingEngineManufacturer } = useQuery(QUERY_ENGINE_MANUFACTURER);

  const [engineManufacturerOptions, setEngineManufacturerOptions] = useState<IEngineOption[]>([]);
  const [engineModelOptions, setEngineModelOptions] = useState<IDropDownOption[]>([]);

  const ADD_AUTOCOMPLETE_TEXT = '-- Add your own --';

  const [openAddEngineManufacturer, setOpenAddEngineManufacturer] = useState(false);
  const [openAddEngineModel, setOpenAddEngineModel] = useState(false);
  const [engineModelDisabled, setEngineModelDisabled] = useState(true);

  useEffect(() => {
    if (dataEngineManufacturer?.engineManufacturer !== undefined) {
      const sort = [...dataEngineManufacturer.engineManufacturer];

      sort.sort((x: IEngineOption, y: IEngineOption) => {
        if (x.name > y.name) return 1;
        else return -1;
      });

      setEngineManufacturerOptions([
        ...sort,
        {
          id: '',
          name: ADD_AUTOCOMPLETE_TEXT,
        },
      ]);
    }
  }, [dataEngineManufacturer]);

  useEffect(() => {
    setEngineModel(null);

    if (engineManufacturer?.id && engineManufacturer?.enginemodelSet) {
      const engineModelSet = [...engineManufacturer.enginemodelSet];
      engineModelSet.sort((x: IDropDownOption, y: IDropDownOption) => {
        if (x.name > y.name) return 1;
        else return -1;
      });
      setEngineModelOptions([
        ...engineModelSet,
        {
          id: '',
          name: ADD_AUTOCOMPLETE_TEXT,
        },
      ]);
    } else {
      setEngineModelOptions([
        {
          id: '',
          name: ADD_AUTOCOMPLETE_TEXT,
        },
      ]);
    }

    if (engineManufacturer?.type === ADD_OPTION_CLICKED_NO_VALUE || engineManufacturer?.type === ADD_OPTION_CLICKED_TYPED_VALUE) {
      setEngineManufacturer({
        id: '',
        name:
          engineManufacturer?.name && engineManufacturer.name !== '-- Add your own --' ? engineManufacturer.name.replace('Add ', '') : '',
      });
      setOpenAddEngineManufacturer(true);
      setOpenAddEngineModel(false);
    }

    if (engineManufacturer === null || (!engineManufacturer.id && !engineManufacturer.name)) {
      setEngineModelDisabled(true);
    } else {
      setEngineModelDisabled(false);
    }
  }, [engineManufacturer, setEngineManufacturer, setEngineModel]);

  useEffect(() => {
    if (engineModel?.type === ADD_OPTION_CLICKED_NO_VALUE || engineModel?.type === ADD_OPTION_CLICKED_TYPED_VALUE) {
      setEngineModel({
        id: '',
        name: engineModel?.name && engineModel.name !== '-- Add your own --' ? engineModel.name.replace('Add ', '') : '',
      });

      setOpenAddEngineModel(true); // Open dialog
      setOpenAddEngineManufacturer(false);
    }
  }, [engineModel, setEngineModel]);

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Autocomplete
          label="Engine Manufacturer *"
          name="engineManufacturer"
          value={engineManufacturer}
          error={errorEngineManufacturer}
          onChange={onChangeEngineManufacturer}
          options={engineManufacturerOptions}
          getOptionLabel={getOptionLabelIdName}
          formatOption={formatOptionIdName}
          loading={loadingEngineManufacturer}
          addOptionLabel={ADD_AUTOCOMPLETE_TEXT}
          placeholder="Start typing to search..."
          renderOptionFlag={true}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          label="Engine Model *"
          name="engineModel"
          value={engineModel}
          error={errorEngineModel}
          onChange={onChangeEngineModel}
          options={engineModelOptions}
          getOptionLabel={getOptionLabelIdName}
          formatOption={formatOptionIdName}
          loading={loadingEngineManufacturer}
          addOptionLabel={ADD_AUTOCOMPLETE_TEXT}
          disabled={engineModelDisabled || disabled}
          placeholder="Start typing to search..."
          renderOptionFlag={true}
        />
      </Grid>
      <DialogAddEngineManufacturerSuggestions
        engineManufacturer={engineManufacturer}
        setEngineManufacturer={setEngineManufacturer}
        open={openAddEngineManufacturer}
        setOpen={setOpenAddEngineManufacturer}
      />

      <DialogAddEngineModelSuggestions
        engineManufacturer={engineManufacturer}
        engineModel={engineModel}
        setEngineModel={setEngineModel}
        open={openAddEngineModel}
        setOpen={setOpenAddEngineModel}
      />
    </React.Fragment>
  );
};

export default EngineMakeModel;
