import { useState, useEffect } from 'react';

import { useLazyQuery } from '@apollo/client';
import { QUERY_FUZZY_AIRCRAFT_ENGINE_MODELS } from 'gql';

import { IOptionIdName } from 'types';

import { isEmpty } from 'helpers';

interface IUseFuzzyEngineManufacturers {
  engineModel: IOptionIdName;
  engineManufacturerId: string;
  engineModelInput: string;
}

// Get Aircraft Engine Model Suggestions (fuzzy). When type in input
const useFuzzyEngineModels = (props: IUseFuzzyEngineManufacturers) => {
  const { engineModel, engineManufacturerId, engineModelInput } = props;

  // Suggestions Initial State
  const [fuzzy, setFuzzy] = useState<IOptionIdName[]>([]);

  // GraphQL Definition
  const [refetch, { data }] = useLazyQuery(QUERY_FUZZY_AIRCRAFT_ENGINE_MODELS, {
    fetchPolicy: 'no-cache',
  });

  // Response
  useEffect(() => {
    // Success
    data && setFuzzy(data?.fuzzyEngineModels); // Set suggestions array - id/name
  }, [data]);

  // Refetch Suggestions. When input changed
  useEffect(() => {
    // If input is empty do NOT refetch
    if (isEmpty(engineModelInput) || engineModelInput === '-- Add your own --') {
      return setFuzzy([]); // Clean suggestions and stop (return) (do NOT refetch)
    }
    refetch({
      variables: {
        search: engineModelInput,
        manufacturerId: isEmpty(engineManufacturerId) ? 100000 : engineManufacturerId,
      },
    });
  }, [engineModel, refetch, engineModelInput, engineManufacturerId]);

  return fuzzy;
};

export default useFuzzyEngineModels;
