import React from 'react';
import { Box } from '@mui/material';
import { LayoutDashboard } from 'components';
import { FlightsTableByFile } from './components';

const FlightsByFile: React.FC = () => {
  return (
    <LayoutDashboard backgroundColor="#FFFFFF">
      <Box>
        <FlightsTableByFile />
      </Box>
    </LayoutDashboard>
  );
};

export default FlightsByFile;
