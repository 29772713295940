import { SET_SELECTED_AIRCRAFT } from 'state/types';

import { IDispatch } from 'types';

export const storeSelectedAircraft = (payload: string) => (dispatch: IDispatch) => {
  dispatch({
    type: SET_SELECTED_AIRCRAFT,
    payload: {
      storedAircraftId: payload,
    },
  });
};
