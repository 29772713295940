import React from 'react';

import { IAircraftEligibility } from 'types';
import RequirePurchase from './RequirePurchase';
import { BreakdownFlow } from './Breakdown';

interface BreakdownTicketProps {
  ticketAircraft: IAircraftEligibility;
}

const BreakdownTicket: React.FC<BreakdownTicketProps> = (props) => {
  const { ticketAircraft } = props;

  return (
    <React.Fragment>
      <RequirePurchase ticketAircraft={ticketAircraft} eligibility={ticketAircraft.ticketEligibility.breakdown} />
      <BreakdownFlow ticketAircraft={ticketAircraft} />
    </React.Fragment>
  );
};

export default BreakdownTicket;
