import { gql } from '@apollo/client';
import { FRAGMENT_RATING_SC, FRAGMENT_STATISTIC_SC } from 'gql';

export const MUTATION_CREATE_SERVICE_CENTER_REVIEW = gql`
  ${FRAGMENT_RATING_SC}
  ${FRAGMENT_STATISTIC_SC}
  mutation ReviewServiceCenter(
    $ticket: ID
    $comment: String
    $communications: Int
    $approval: Int
    $quality: Int
    $philosophy: Int
    $cost: Int
    $serviceCenter: ID
  ) {
    reviewServiceCenter(
      input: {
        ticket: $ticket
        comment: $comment
        communications: $communications
        approval: $approval
        quality: $quality
        philosophy: $philosophy
        cost: $cost
        serviceCenter: $serviceCenter
      }
    ) {
      ok
      error
      serviceCenter {
        id
        flashColor
        averageRatingScore
        flashColorSource
        dashRating {
          communications {
            ...Rating
          }
          approval {
            ...Rating
          }
          quality {
            ...Rating
          }
          philosophy {
            ...Rating
          }
          cost {
            ...Rating
          }
        }
        drillRating {
          reviews {
            ratings {
              communications {
                ...Rating
              }
              approval {
                ...Rating
              }
              quality {
                ...Rating
              }
              philosophy {
                ...Rating
              }
              cost {
                ...Rating
              }
            }
            reviewer {
              id
              firstName
              lastName
            }
            ticket {
              id
              subject
            }
            comment
            date
          }
          statistics {
            communications {
              ...StatisticItem
            }
            approval {
              ...StatisticItem
            }
            quality {
              ...StatisticItem
            }
            philosophy {
              ...StatisticItem
            }
            cost {
              ...StatisticItem
            }
          }
        }
      }
    }
  }
`;

export const MUTATION_SET_SERVICE_CENTER_UPDATE_FLAG = gql`
  mutation SetServiceCenterUpdateFlag($serviceCenterId: ID, $status: Boolean) {
    setServiceCenterUpdateFlag(serviceCenterId: $serviceCenterId, status: $status) {
      ok
      serviceCenter {
        id
        needsUpdate
        updated
      }
      error
    }
  }
`;

export const MUTATION_SET_TECHNICIAN_UPDATE_FLAG = gql`
  mutation SetTechnicianUpdateFlag($technicianId: ID, $status: Boolean) {
    setTechnicianUpdateFlag(technicianId: $technicianId, status: $status) {
      ok
      contact {
        id
        needsUpdate
        updated
      }
      error
    }
  }
`;

export const MUTATION_UPDATE_SERVICE_CENTER = gql`
  mutation UpdateServiceCenter($id: ID!, $input: UpdateServiceCenterInput!) {
    updateServiceCenter(id: $id, input: $input) {
      serviceCenter {
        id
        company
        street
        city
        state
        zip
        country
        airportId
        phoneWork
        phoneFax
        latitude
        longitude
        scAuth
        scCode
        classifications {
          id
          name
          category
        }
        website
      }
      ok
      error
    }
  }
`;

export const MUTATION_VERIFY_SERVICE_CENTER = gql`
  mutation VerifyServiceCenter($serviceCenterId: ID) {
    verifyServiceCenter(serviceCenterId: $serviceCenterId) {
      ok
      serviceCenter {
        id
        updated
        needsUpdate
        verifiedDate
      }
      error
    }
  }
`;

export const MUTATION_REQUEST_SERVICE_CENTER_UPDATE = gql`
  mutation RequestServiceCenterUpdate($contactId: ID) {
    requestServiceCenterUpdate(contactId: $contactId) {
      ok
      error
    }
  }
`;

export const MUTATION_CREATE_SERVICE_CENTER = gql`
  mutation CreateServiceCenter($input: CreateServiceCenterInput!) {
    createServiceCenter(input: $input) {
      serviceCenter {
        id
      }
      ok
      error
    }
  }
`;

export const MUTATION_PIN_SERVICE_CENTER_COMMENT = gql`
  mutation PinServiceCenterComment($commentId: ID, $pinned: Boolean) {
    pinServiceCenterComment(commentId: $commentId, pinned: $pinned) {
      ok
      comment {
        id
        pinned
      }
      error
    }
  }
`;

export const MUTATION_POST_SERVICE_CENTER_COMMENT = gql`
  mutation PostServiceCenterComment($serviceCenterId: ID, $text: String, $pinned: Boolean) {
    postServiceCenterComment(serviceCenterId: $serviceCenterId, text: $text, pinned: $pinned) {
      ok
      serviceCenter {
        id
        comments {
          id
          comment
          date
          pinned
          poster {
            id
            firstName
            lastName
          }
        }
      }
      error
    }
  }
`;

export const MUTATION_TOGGLE_SERVICE_CENTER_STATUS = gql`
  mutation ToggleServiceCenterStatus($serviceCenterId: ID) {
    toggleServiceCenterStatus(serviceCenterId: $serviceCenterId) {
      ok
      serviceCenter {
        id
        isActive
      }
      error
    }
  }
`;

export const MUTATION_UPDATE_SERVICE_CENTER_FOR_TECH = gql`
  mutation UpdateServiceCenterForTechnician(
    $airportId: String
    $city: String
    $classifications: [ID]
    $country: String
    $phoneFax: String
    $phoneWork: String
    $serviceCenterId: ID
    $state: String
    $street: String
    $zipCode: String
    $website: String
    $removalTechnicians: [ID]
  ) {
    updateServiceCenterForTechnician(
      airportId: $airportId
      city: $city
      classifications: $classifications
      country: $country
      phoneFax: $phoneFax
      phoneWork: $phoneWork
      serviceCenterId: $serviceCenterId
      state: $state
      street: $street
      zipCode: $zipCode
      website: $website
      removalTechnicians: $removalTechnicians
    ) {
      serviceCenter {
        company
        id
        street
        city
        state
        zip
        country
        airportId
        phoneWork
        phoneFax
        classifications {
          id
          name
          category
        }
        website
        technicians {
          id
        }
      }
      ok
      error
    }
  }
`;

export const MUTATION_REQUEST_TECH_UPDATE = gql`
  mutation RequestTechUpdate($contactId: ID) {
    requestTechUpdate(contactId: $contactId) {
      ok
      error
      contact {
        id
        informationUpdateTicket {
          id
          subject
        }
      }
    }
  }
`;
