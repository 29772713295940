import React from 'react';

import { LayoutDashboard } from 'components';
import { BorescopeAnalysisReports } from 'pages/BorescopeAnalysisReports/components';

const CreateBaReportsWithPermission: React.FC = () => {
  return (
    <LayoutDashboard backgroundColor="#fff">
      <BorescopeAnalysisReports />
    </LayoutDashboard>
  );
};

export default CreateBaReportsWithPermission;
