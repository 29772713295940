import React, { useState } from 'react';
import { TextField, FormHelperText } from '@mui/material';

interface MuiFormikInputWithWarningInterface {
  name: string;
  label: string;
  errors: any;
  touched: any;
  onChange: (event: any) => void;
  onBlur?: (event: any) => void;
  maxLength: number;
  closeLength: number;
  disabled?: boolean;
  multiline?: boolean;
  rows?: number;
  placeholder?: string;
}

// Input UI
const MuiFormikInputWithWarning: React.FC<MuiFormikInputWithWarningInterface> = (props) => {
  const { name, label, onBlur, onChange, errors, touched, maxLength, closeLength, disabled = false, ...rest } = props;

  const [warning, setWarning] = useState('');

  return (
    <React.Fragment>
      <TextField
        name={name}
        label={label}
        onBlur={onBlur}
        onChange={(e: any) => {
          onChange(e);
          const length = e.target.value.length;
          if (length >= closeLength && length <= maxLength) {
            setWarning(`${maxLength - length} character${maxLength - length > 1 ? 's' : ''} remaining.`);
          } else {
            setWarning('');
          }
        }}
        error={touched[name] && !!errors[name]}
        helperText={errors[name]}
        FormHelperTextProps={{
          sx: {
            ml: 0,
          },
        }}
        inputProps={{ maxLength: maxLength }}
        fullWidth
        sx={{
          mb: warning ? 0 : '15px',
        }}
        disabled={disabled}
        {...rest}
      />
      {!!warning && (
        <FormHelperText
          sx={{
            color: 'warning.main',
            ml: 0,
            textAlign: 'right',
          }}
        >
          {warning}
        </FormHelperText>
      )}
    </React.Fragment>
  );
};

export default MuiFormikInputWithWarning;
