import React from 'react';
import { Typography } from '@mui/material';

interface IList {
  list: string[];
}

const List: React.FC<IList> = (props) => {
  const { list } = props;
  return (
    <ul>
      {list.map((item) => (
        <li key={item}>
          <Typography variant="body1">{item}</Typography>
        </li>
      ))}
    </ul>
  );
};

export default List;
