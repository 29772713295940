import React, { Fragment } from 'react';

import { Typography, Collapse, Hidden } from '@mui/material';

import { Subsection, List, ButtonMore } from 'components';
import { useMdUp } from 'hooks';

const features = [
  'Unlimited & comprehensive flight analysis reports prepared by Savvy’s professional analysts',
  'Actionable feedback on your engine’s performance and other significant systems of your aircraft',
  'Regular Report Cards and Trend Reports that compare your aircraft’s and engine’s performance with all the other aircraft we follow of the same make and model',
  'Failing Exhaust Valve Analytics (FEVA) using machine learning to monitor your exhaust valve health using your recent history of engine monitor data',
  'Cylinder condition reports based on your uploaded borescope images',
  '24/7/365 quick response breakdown assistance when you’re away from home',
];

const fees = ['Piston single: $189/year', 'Piston twin: $289/year'];

const SavvyAnalysisDescription: React.FC = () => {
  const [open, setOpen] = React.useState(false);
  const isMdUp = useMdUp();

  return (
    <Fragment>
      <Subsection>
        <Typography paragraph>
          SavvyAnalysis is the only service in the industry that analyzes piston engine monitor data and cylinder borescope images. Upload
          your raw engine data and borescope images and we’ll turn it into the most useful, actionable information about your engine's
          health and your operating technique. With more than 5 million flights uploaded and Savvy's proprietary predictive analytics
          models, this service helps you to extend the life of your engine and operate your aircraft more economically. SavvyAnalysis
          includes 24/7/365 quick response breakdown assistance when you’re away from home.
        </Typography>
      </Subsection>
      <Collapse in={open || isMdUp}>
        <Subsection>
          <Typography variant="h4" gutterBottom>
            For a modest annual subscription fee, you'll receive the following benefits:
          </Typography>
          <List list={features} />
        </Subsection>
        <Subsection>
          <Typography variant="h4" gutterBottom>
            SavvyAnalysis Plan Annual Fees:
          </Typography>
          <List list={fees} />
        </Subsection>
      </Collapse>
      {!isMdUp && (
        <Hidden mdUp>
          <Subsection>
            <ButtonMore open={open} setOpen={setOpen} />
          </Subsection>
        </Hidden>
      )}
    </Fragment>
  );
};

export default SavvyAnalysisDescription;
