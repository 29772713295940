import React from 'react';
import { Dialog, Box, Typography, DialogContent, DialogTitle, Button } from '@mui/material';

interface ConfirmApproveCoiDialogProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  onSubmitAction: () => void;
}

const ConfirmApproveCoiDialog: React.FC<ConfirmApproveCoiDialogProps> = (props) => {
  const { open, setOpen, onSubmitAction } = props;

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async () => {
    setOpen(false);
    onSubmitAction();
  };

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={handleClose}
      PaperProps={{
        sx: {
          maxWidth: '400px',
        },
      }}
    >
      <DialogTitle
        sx={{
          textAlign: 'center',
          fontSize: '24px',
          fontWeight: 700,
        }}
      >
        Approve COI
      </DialogTitle>
      <DialogContent>
        <Box>
          <Typography
            variant="body1"
            gutterBottom
            align="center"
            sx={{
              fontSize: '18px',
            }}
          >
            Are you sure to approve COI?
          </Typography>
          <Box
            sx={{
              textAlign: 'center',
            }}
          >
            <Button
              variant="outlined"
              sx={{
                width: '100px',
              }}
              onClick={handleClose}
            >
              No
            </Button>
            <Button
              color="primary"
              variant="contained"
              sx={{
                width: '100px',
                ml: 2,
              }}
              onClick={() => {
                onSubmit();
              }}
            >
              Yes
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmApproveCoiDialog;
