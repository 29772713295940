import React from 'react';
import { Field } from 'formik';
import { CheckboxWithLabel } from 'formik-mui';

type CheckBoxLabelProps = {
  id: string;
  value: string;
  group: string;
};

const CheckBoxLabel: React.FC<CheckBoxLabelProps> = (props) => {
  return <Field type="checkbox" name="classifications" value={props.id} component={CheckboxWithLabel} Label={{ label: props.value }} />;
};

export default CheckBoxLabel;
