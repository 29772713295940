import React from 'react';
import { CSSObject } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';

interface PrebuyTicketIconProps {
  sx?: CSSObject;
}

const PrebuyTicketIcon: React.FC<PrebuyTicketIconProps> = (props) => {
  const { sx } = props;

  return (
    <SvgIcon width="55" height="34" viewBox="0 0 55 34" fill="" sx={sx ? { ...sx } : {}}>
      <path
        d="M52.4452 0.290039C51.0362 0.290039 1.27742 0.290039 1.27742 0.290039C0.571006 0.290039 0 0.761975 0 1.34347V32.9463C0 33.5278 0.571006 33.9997 1.27742 33.9997H52.4452C53.8541 33.9997 55 33.0548 55 31.8929V2.39689C55 1.23496 53.8541 0.290039 52.4452 0.290039ZM4.80553 31.8929H2.55484V2.39689H4.80553V31.8929ZM52.4452 31.8929H7.36037V2.39689H52.4452V31.8929Z"
        fill="#4B5470"
      />
      <rect
        x="30.1613"
        y="9.16089"
        width="6.20968"
        height="9.75806"
        rx="2"
        stroke="#4B5470"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.5568 11.3828C39.5568 9.58789 41.0118 8.13281 42.8068 8.13281H45.0164C46.8114 8.13281 48.2664 9.58789 48.2664 11.3828V12.9312H45.7664V11.3828C45.7664 10.9686 45.4307 10.6328 45.0164 10.6328H42.8068C42.3926 10.6328 42.0568 10.9686 42.0568 11.3828V17.1409C42.0568 17.5551 42.3925 17.8909 42.8068 17.8909H45.0164C45.4307 17.8909 45.7664 17.5551 45.7664 17.1409V16.4796H43.9681C43.6919 16.4796 43.4681 16.2557 43.4681 15.9796V15.2054C43.4681 14.9293 43.6919 14.7054 43.9681 14.7054L45.7664 14.7054L46.5164 14.7054L48.2664 14.7054V17.1409C48.2664 18.9358 46.8114 20.3909 45.0164 20.3909H42.8068C41.0118 20.3909 39.5568 18.9358 39.5568 17.1409V11.3828Z"
        fill="#4B5470"
      />
      <path d="M21.2903 9.16089V18.919H25.7258" stroke="#4B5470" strokeWidth="2.7" strokeLinecap="round" strokeLinejoin="round" />
      <mask id="path-5-inside-1_159_5867" fill="white">
        <rect x="36.371" y="26.0159" width="11.5323" height="2.21774" rx="1" />
      </mask>
      <rect
        x="36.371"
        y="26.0159"
        width="11.5323"
        height="2.21774"
        rx="1"
        stroke="#4B5470"
        strokeWidth="2.21774"
        mask="url(#path-5-inside-1_159_5867)"
      />
    </SvgIcon>
  );
};

export default PrebuyTicketIcon;
