import React from 'react';
import { Typography, Link } from '@mui/material';
import { IAircraftDetail } from 'types';

import { PATH_AGREEMENT, PATH_CLIENT_GUIDE } from 'const';

interface SavvyPrebuyDescriptionProps {
  aircraft: IAircraftDetail | undefined;
}

const SavvyPrebuyDescription: React.FC<SavvyPrebuyDescriptionProps> = (props) => {
  const { aircraft } = props;

  if (!aircraft) {
    return null;
  }

  return (
    <React.Fragment>
      <Typography
        sx={{
          mb: 1,
        }}
      >
        Welcome to SavvyPrebuy! We're delighted that you have hired us to manage a pre-buy examination of a {aircraft?.year}{' '}
        {aircraft?.aircraftManufacturer?.name} {aircraft?.aircraftModel?.name}.
      </Typography>
      <Typography
        sx={{
          mb: 1,
        }}
      >
        You have agreed to be bound by the terms and conditions of the{' '}
        <Link href={PATH_AGREEMENT} target="_blank">
          SavvyAviation Service Agreement
        </Link>
        .
      </Typography>
      <Typography
        sx={{
          mb: 1,
        }}
      >
        Please take a few minutes to review the{' '}
        <Link href={PATH_CLIENT_GUIDE} target="_blank">
          SavvyMx New Client Guide
        </Link>{' '}
        that explains what to expect from us and how you can work with us to ensure that you get the greatest possible benefit from Savvy’s
        professional maintenance management. Please review it carefully.
      </Typography>
      <Typography
        sx={{
          mb: 1,
        }}
      >
        We look forward to working with you. If you have any questions, please contact us by email at{' '}
        <Link href="mailto:operations@savvyaviation.com">operations@savvyaviation.com</Link> or by telephone toll-free at 1-888-465-8038.
      </Typography>
      <Typography>Welcome!</Typography>
    </React.Fragment>
  );
};

export default SavvyPrebuyDescription;
