import { useState, useEffect } from 'react';
import { hasInputError } from 'helpers';
import { IInputError, IFormError } from 'types';

/*
  Receive array of form errors nad return formError true or false

  Usage:
  import { useFormError } from 'hooks';
  ...
  const Component: React.FC = () => {
    ...
    // Form error
    // =================================
     const formHasError = useFormError([
      errorEmail,
      errorPassword,
      errorConfirmPassword,
      errorFirstName,
      errorLastName,
      errorRegistrationNumber,
      errorAircraftManufacturer,
      errorAircraftModel
    ]);
    ...
    // Form submit
    // =================================
    const onSubmit = (e: any) => {
      e.preventDefault(); // Prevent sending
      // React useState/setState doesn't prevent sending. Plain variable used
      let formError = false; // Used for onSubmit function scope

      // Last name
      if (isEmpty(lastName)) {
        setErrorLastName(ERROR_LAST_NAME_EMPTY);
        formError = true;
      };

      // If at least one error
      if (formError) {
        console.log('Form has errors');
        return; // Stop sending
      };
      console.log('Send form);
    };
    ...
    return(
      ...
      <Button
        ...
        disabled={formHasError}
      >
        Next
      </Button>
    ...
    );
  };
*/

const useFormError = (errors: IInputError[]): IFormError => {
  const [formError, setFormError] = useState<IFormError>(true); // Use true as initial to prevent hook long update

  // Watch all form inputs error change
  useEffect(() => {
    // If some of errors returns true from hasInputError - form will have error
    if (errors.some((error) => hasInputError(error))) {
      setFormError(true); // Set error
    } else {
      setFormError(false); // Remove error
    }
  }, [errors]);

  return formError; // Return true/false to use in component
};

export default useFormError;
