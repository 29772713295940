import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Typography, Box, TextField, InputAdornment, Tooltip } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useSmDown } from 'hooks';
import { humanDate } from 'helpers';
import { ITicket } from 'types';
import { PATH_TICKETS, PATH_TICKETS_VIEW } from 'const';
import { ComponentLoading } from 'components';
import { useLazyQuery } from '@apollo/client';
import { QUERY_USER_TICKETS } from 'gql';
import { setAlert } from 'state';
import { getBodyHeight } from 'helpers';
import { BadgeIcon } from 'customIcons';

const Tickets: React.FC = () => {
  const [fetchTickets, { data: dataTickets, loading: loadingTickets, error: errorTickets }] = useLazyQuery(QUERY_USER_TICKETS);

  const [subjectSearch, setSubjectSearch] = useState('');

  const dispatch = useDispatch();
  const bodyHeight = getBodyHeight();

  useEffect(() => {
    fetchTickets({
      variables: {
        subjectSearch: subjectSearch,
        last: !subjectSearch && 10,
      },
    });
  }, [subjectSearch, fetchTickets]);

  const [sortTickets, setSortTickets] = useState<ITicket[]>([]);

  useEffect(() => {
    if (errorTickets) {
      dispatch(setAlert('error', 'Unable to load your tickets.'));
    } else if (dataTickets?.me?.tickets) {
      const tmp = [...dataTickets.me.tickets];

      tmp.sort((a: ITicket, b: ITicket) => {
        if (a.state.toLowerCase() === 'c' && b.state.toLowerCase() !== 'c') {
          return 1;
        } else if (a.state.toLowerCase() !== 'c' && b.state.toLowerCase() === 'c') {
          return -1;
        } else if (a.state === b.state) {
          if (new Date(a.updated) > new Date(b.updated)) {
            return -1;
          } else {
            return 1;
          }
        }

        return 1;
      });

      setSortTickets(tmp);
    }
  }, [dataTickets, errorTickets, dispatch]);

  const isSmDown = useSmDown();

  return (
    <Box
      sx={{
        border: '1px solid #E8E9EF',
        background: '#FFFFFF',
        height: '100%',
      }}
    >
      <Box
        px={2.5}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderBottom: '1px solid #E8E9EF',
          py: '17px',
        }}
      >
        <Typography
          variant="h3"
          gutterBottom
          style={{
            marginBottom: 0,
            lineHeight: '25px',
            color: 'primaryDark',
            fontWeight: 700,
          }}
        >
          Latest Tickets
        </Typography>
        <NavLink to={PATH_TICKETS}>
          <Typography
            paragraph
            color="textPrimary"
            style={{
              marginBottom: 0,
            }}
          >
            {isSmDown ? 'All' : 'View All Tickets'}
          </Typography>
        </NavLink>
      </Box>
      <Box
        px={2.5}
        py={2.25}
        sx={{
          borderBottom: '1px solid',
          borderBottomColor: 'grey.200',
        }}
      >
        <TextField
          label="Search"
          name="search"
          value={subjectSearch}
          variant="outlined"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          fullWidth
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setSubjectSearch(e.target.value);
          }}
          style={{
            marginBottom: 0,
          }}
        />
      </Box>
      <ComponentLoading loading={loadingTickets}>
        <Box
          sx={{
            maxHeight: {
              xl: bodyHeight - 230 + 'px', // minus container padding, header and search of ticket box
            },
            overflowY: 'auto',
            borderBottom: '1px solid',
            borderBottomColor: 'grey.200',
            height: {
              xl: 90 * (sortTickets.length > 5 ? 5 : sortTickets.length === 0 ? 1 : sortTickets.length), // 90 is height of ticket item in list
            },
          }}
        >
          {sortTickets.map((ticket: ITicket, key: number) => {
            if (ticket.state.toLowerCase() === 'c' && (Date.now() - new Date(ticket.updated).getTime()) / 1000 / 3600 / 24 > 90)
              return null;
            return (
              <Box
                key={key}
                px={2.5}
                py={1.5}
                sx={{
                  borderBottom: '1px solid',
                  borderColor: 'grey.200',
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    marginBottom: '5px',
                    textTransform: 'uppercase',
                    color: 'grey.400',
                    fontSize: '12px',
                    lineHeight: '16px',
                    minHeight: '16px',
                  }}
                >
                  {ticket.type ? ticket.type : ' '}
                  {ticket.state.toLowerCase() === 'c' ? '  [CLOSED]' : ''}
                </Typography>
                <NavLink to={`${PATH_TICKETS_VIEW}/${ticket.id}`}>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: '16px',
                      lineHeight: '22px',
                      color: 'text.secondary',
                      fontWeight: 500,
                      marginBottom: '5px',
                      maxWidth: '100%',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {ticket.hasUnread && <BadgeIcon />}
                    {ticket.subject}
                  </Typography>
                </NavLink>
                <Tooltip title={ticket.created.toString()} placement="right-start">
                  <Typography
                    variant="body1"
                    sx={{
                      display: 'inline-block',
                      cursor: 'pointer',
                      color: 'grey.400',
                    }}
                  >
                    {humanDate(ticket.created)}
                  </Typography>
                </Tooltip>
              </Box>
            );
          })}
        </Box>
      </ComponentLoading>
    </Box>
  );
};

export default Tickets;
