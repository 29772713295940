import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box, styled } from '@mui/material';
import { FileUploader } from 'react-drag-drop-files';
import { uploadFileDirectS3 } from 'helpers';
import { UploadingFileStatusBar } from './';

import { FLIGHT_FILE_MAX_SIZE } from 'const';

import { QUERY_AIRCRAFT_RECENT_UPLOADS, QUERY_ME_RECENT_EDFS } from 'gql';
import { useLazyQuery } from '@apollo/client';

import { setAlert } from 'state';
import { IUploadedFlightFile, IAircraft } from 'types';
import { ComponentLoading } from 'components';

interface WorkflowUploadProps {
  aircrafts: IAircraft[];
  selectedAircraft: IAircraft | undefined;
  fromTime: Date;
}

const classes = {
  dragDropUpload: 'dragDropUpload',
};

const StyledDiv = styled('div')({
  [`& .${classes.dragDropUpload}`]: {
    height: '240px !important',
    maxWidth: '1000px !important',
    border: '2px dashed #BBC0D4 !important',
    borderRadius: '6px !important',
    '& svg': {
      display: 'none',
    },
    '& div': {
      display: 'block',
      textAlign: 'center',
      '& span': {
        fontSize: '20px',
        fontWeight: 400,
        color: 'rgba(75, 84, 112, 0.5)',
        lineHeight: '27px',
        display: 'block',
        alignItems: 'start',
        justifyContent: 'center',
        '& span': {
          textDecoration: 'none',
          display: 'contents',
          whiteSpace: 'nowrap',
          marginRight: '5px',
        },
      },
    },
  },
});

const WorkflowUpload: React.FC<WorkflowUploadProps> = (props) => {
  const { aircrafts, selectedAircraft, fromTime } = props;

  const [uploadedFiles, setUploadedFiles] = useState<IUploadedFlightFile[]>([]);

  const [recentUploads, { error }] = useLazyQuery(QUERY_AIRCRAFT_RECENT_UPLOADS, {
    fetchPolicy: 'cache-and-network',
  });

  const [fetchRecentEdfs] = useLazyQuery(QUERY_ME_RECENT_EDFS, {
    fetchPolicy: 'cache-and-network',
  });

  const dispatch = useDispatch();

  const { id } = useParams<{
    id: string;
  }>();

  const [isUploading, setIsUploading] = useState(false);

  const handleChange = async (uploadedFile: File) => {
    const tmp = {
      fileName: uploadedFile.name,
      fileSize: uploadedFile.size,
      status: '',
      flightCount: 0,
      details: '',
      id: 0,
      edfId: '',
    };
    tmp.status = 'Processing';

    const fileIndexOfArray = uploadedFiles.length;
    uploadedFiles.push(tmp);
    setUploadedFiles([...uploadedFiles]);
    if (selectedAircraft) {
      const result = await uploadFileDirectS3(uploadedFile, false, selectedAircraft.id);
      if (result.success) {
        uploadedFiles[fileIndexOfArray].status = 'Success';
        uploadedFiles[fileIndexOfArray].id = result.key;
        uploadedFiles[fileIndexOfArray].edfId = result.edfId;
      } else {
        uploadedFiles[fileIndexOfArray].status = 'Error';
      }

      setUploadedFiles([...uploadedFiles]);
    }
  };

  useEffect(() => {
    if (error) {
      dispatch(setAlert('error', 'unable to load recent uploads'));
    }
  }, [error, dispatch]);

  useEffect(() => {
    if (aircrafts && id) {
      setUploadedFiles([]);
    }
  }, [id, aircrafts]);

  if (!selectedAircraft) {
    return null;
  }

  return (
    <Box>
      <ComponentLoading loading={isUploading}>
        {aircrafts &&
          aircrafts.map((aircraft: IAircraft, key: number) => {
            return (
              <StyledDiv
                key={key}
                sx={{
                  display: aircraft.id === id ? 'block' : 'none',
                  mb: 3.5,
                }}
              >
                <FileUploader
                  handleChange={async (files: File[]) => {
                    setIsUploading(true);
                    for (let i = 0; i < files.length; i++) {
                      await handleChange(files[i]);
                    }

                    setTimeout(() => {
                      setUploadedFiles([]);
                    }, 10000);

                    if (selectedAircraft) {
                      await recentUploads({
                        variables: {
                          id: parseInt(selectedAircraft.id),
                          fromTime,
                        },
                      });

                      await fetchRecentEdfs({
                        variables: {
                          id: parseInt(selectedAircraft.id),
                          fromTime,
                        },
                      });
                    }

                    setIsUploading(false);
                  }}
                  name={'name_' + id}
                  label={`Drag and Drop files here; or click to upload to ${aircraft.registration}`}
                  classes={[classes.dragDropUpload]}
                  maxSize={FLIGHT_FILE_MAX_SIZE}
                  onSizeError={() => {
                    dispatch(setAlert('error', 'File is over 100MB limit.'));
                  }}
                  multiple={true}
                />
              </StyledDiv>
            );
          })}
      </ComponentLoading>
      {Boolean(uploadedFiles.length) && (
        <Box
          py={1}
          mb={3.5}
          sx={{
            border: '1px solid #E8E9EF',
            borderRadius: '6px',
          }}
        >
          {uploadedFiles.map((uploadedFile: IUploadedFlightFile, key: number) => {
            return <UploadingFileStatusBar uploadedFile={uploadedFile} key={key} />;
          })}
        </Box>
      )}
    </Box>
  );
};

export default WorkflowUpload;
