import { lazy } from 'react';

const lazyRetry = (componentImport: any, name: string) =>
  lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(window.localStorage.getItem(`page-has-been-force-refreshed-${name}`) || 'false');

    try {
      const component = await componentImport();

      window.localStorage.setItem(`page-has-been-force-refreshed-${name}`, 'false');

      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        window.localStorage.setItem(`page-has-been-force-refreshed-${name}`, 'true');
        return window.location.reload();
      }
    }
  });

export const LazySavvyTickets = lazyRetry(() => import('../../pages/SavvyTickets/SavvyTickets'), 'SavvyTickets');
export const LazyServiceCenterPage = lazyRetry(() => import('../../pages/Internal/ServiceCenterPage'), 'ServiceCenterPage');
export const LazyInternalDashboard = lazyRetry(() => import('../../pages/Internal/Dashboard'), 'Dashboard');
export const LazyPersonalTicketDashboard = lazyRetry(
  () => import('../../pages/Internal/PersonalTicketDashboard/PersonalTicketDashboard'),
  'PersonalTicketDashboard',
);
export const LazyServiceCentersList = lazyRetry(() => import('../../pages/ServiceCenters/ServiceCentersList'), 'ServiceCentersList');
export const LazyEditContact = lazyRetry(
  () => import(/* webpackChunkName: "editContact" */ '../../pages/ContactManagement/EditContact'),
  'EditContact',
);
export const LazyContactFlight = lazyRetry(() => import('../../pages/ContactManagement/ContactFlight'), 'ContactFlight');
export const LazySavvySubscriptions = lazyRetry(() => import('../../pages/SavvySubscriptions/SavvySubscriptions'), 'SavvySubscriptions');
export const LazyTicketsDashboard = lazyRetry(() => import('../../pages/Internal/TicketsDashboard'), 'TicketsDashboard');
export const LazyFilteredTicketsPage = lazyRetry(() => import('../../pages/SavvyManagement/FilteredTicketsPage'), 'FilteredTicketsPage');
export const LazyRenewalDashboard = lazyRetry(() => import('../../pages/SavvyManagement/RenewalDashboard'), 'RenewalDashboard');
export const LazyRenewalTicketsPage = lazyRetry(() => import('../../pages/SavvyManagement/RenewalTicketsPage'), 'RenewalTicketsPage');
export const LazySavvyFileUploads = lazyRetry(() => import('../../pages/ContactManagement/SavvyFileUploads'), 'SavvyFileUploads');
export const LazySelectUserTicketType = lazyRetry(
  () => import('../../pages/SavvyTicketCreate/SelectUserTicketType'),
  'SelectUserTicketType',
);
export const LazyUserTicketCreate = lazyRetry(() => import('../../pages/SavvyTicketCreate/UserTicketCreate'), 'UserTicketCreate');
export const LazySavvyAircraft = lazyRetry(() => import('../../pages/SavvyAircraft/SavvyAircraft'), 'SavvyAircraft');
export const LazyUnassignedTicketsPage = lazyRetry(
  () => import('../../pages/SavvyManagement/UnassignedTicketsPage'),
  'UnassignedTicketsPage',
);
export const LazyServiceCenterView = lazyRetry(() => import('../../pages/ServiceCenters/ServiceCenterView'), 'ServiceCenterView');
export const LazyServiceCenterManualRatingPage = lazyRetry(
  () => import('../../pages/Internal/ServiceCenterManualRatingPage'),
  'ServiceCenterManualRatingPage',
);
export const LazySavvyAccount = lazyRetry(() => import('../../pages/SavvyAccount/SavvyAccount'), 'SavvyAccount');
export const LazyAdvancedSearch = lazyRetry(() => import('../../pages/Internal/AdvancedSearch'), 'AdvancedSearch');
export const LazySavvyBackfillLogPage = lazyRetry(() => import('../../pages/Internal/SavvyBackfillLogPage'), 'SavvyBackfillLogPage');
export const LazyManagementCoiPage = lazyRetry(() => import('../../pages/Internal/ManagementCoiPage'), 'ManagementCoiPage');
export const LazyServiceCenterRatingPage = lazyRetry(
  () => import('../../pages/Internal/ServiceCenterRatingPage'),
  'ServiceCenterRatingPage',
);
export const LazyTicketRatingPage = lazyRetry(() => import('../../pages/Internal/TicketRatingPage'), 'TicketRatingPage');
export const LazyManagerConflicts = lazyRetry(() => import('../../pages/ManagerConflicts/ManagerConflicts'), 'ManagerConflicts');
export const LazyCohortReports = lazyRetry(() => import('../../pages/CohortReports/CohortReports'), 'CohortReports');
export const LazyTechnicianCreate = lazyRetry(() => import('../../pages/TechnicianCreate/TechnicianCreate'), 'TechnicianCreate');
export const LazyTimesheetOverview = lazyRetry(() => import('../../pages/Timesheets/TimesheetsOverview'), 'TimesheetsOverview');
export const LazySavvyAircraftCreate = lazyRetry(() => import('../../pages/SavvyAircraft/SavvyAircraftCreate'), 'SavvyAircraftCreate');
export const LazyActivePrebuy = lazyRetry(() => import('../../pages/ActivePrebuy/ActivePrebuy'), 'ActivePrebuy');
export const LazyFevaAlerts = lazyRetry(() => import('../../pages/FevaAlerts/FevaAlerts'), 'FevaAlerts');
export const LazyFevaAlertView = lazyRetry(() => import('../../pages/FevaAlerts/FevaAlertView'), 'FevaAlertView');
export const LazyAnalysisDashboard = lazyRetry(() => import('../../pages/AnalysisDashboard/AnalysisDashboard'), 'AnalysisDashboard');
export const LazyCreateAnalysisReport = lazyRetry(() => import('../../pages/AnalysisReports/CreateAnalysisReport'), 'CreateAnalysisReport');
export const LazyAnalysisReports = lazyRetry(() => import('../../pages/AnalysisReports/AnalysisReports'), 'AnalysisReports');
export const LazyTimesheetRates = lazyRetry(() => import('../../pages/Timesheets/TimesheetRates'), 'TimesheetRates');
export const LazyTicketSearch = lazyRetry(() => import('../../pages/AdvancedSearch/TicketSearch'), 'TicketSearch');
export const LazyContactSearch = lazyRetry(() => import('../../pages/AdvancedSearch/ContactSearch'), 'ContactSearch');
export const LazyAircraftSearch = lazyRetry(() => import('../../pages/AdvancedSearch/AircraftSearch'), 'AircraftSearch');
export const LazySubscriptionSearch = lazyRetry(() => import('../../pages/AdvancedSearch/SubscriptionSearch'), 'SubscriptionSearch');
export const LazyServiceCenterSearch = lazyRetry(() => import('../../pages/AdvancedSearch/ServiceCenterSearch'), 'ServiceCenterSearch');
export const LazyFlightReportSearch = lazyRetry(() => import('../../pages/AdvancedSearch/FlightReportSearch'), 'FlightReportSearch');
export const LazyPrebuyInquirySearch = lazyRetry(() => import('../../pages/AdvancedSearch/PrebuyInquirySearch'), 'PrebuyInquirySearch');
export const LazyOopList = lazyRetry(() => import('../../pages/OopList/OopList'), 'OopList');
export const LazyKeyResultsReport = lazyRetry(() => import('../../pages/KeyResultsReport/KeyResultsReport'), 'KeyResultsReport');
export const LazyCannedResponses = lazyRetry(() => import('../../pages/CannedResponses/CannedResponses'), 'CannedResponses');
export const LazyFutureCohorts = lazyRetry(() => import('../../pages/Admin/FutureCohorts'), 'FutureCohorts');
export const LazyPrebuyInquiry = lazyRetry(() => import('../../pages/Admin/PrebuyInquiry'), 'PrebuyInquiry');
export const LazyCohorts = lazyRetry(() => import('../../pages/Admin/Cohorts'), 'Cohorts');
export const LazyViewCohort = lazyRetry(() => import('../../pages/Admin/ViewCohort'), 'ViewCohort');
export const LazyFinancials = lazyRetry(() => import('../../pages/Admin/Financials'), 'Financials');
export const LazyAircraftSubmittedData = lazyRetry(() => import('../../pages/AircraftData/AircraftSubmittedData'), 'AircraftSubmittedData');
export const LazyViewSubmittedData = lazyRetry(() => import('../../pages/AircraftData/ViewSubmittedData'), 'ViewSubmittedData');
export const LazyPowerModelList = lazyRetry(() => import('../../pages/PowerModels/PowerModelList'), 'PowerModelList');
export const LazyCreatePowerModel = lazyRetry(() => import('../../pages/PowerModels/CreatePowerModel'), 'CreatePowerModel');
export const LazyViewPowerModel = lazyRetry(() => import('../../pages/PowerModels/ViewPowerModel'), 'ViewPowerModel');
export const LazyEditPowerModel = lazyRetry(() => import('../../pages/PowerModels/EditPowerModel'), 'EditPowerModel');
export const LazyCreatePrebuyConflictTicket = lazyRetry(
  () => import('../../pages/SavvyTicketCreate/CreatePrebuyConflictTicket'),
  'CreatePrebuyConflictTicket',
);

export const LazyTechsWithUpdateRequest = lazyRetry(
  () => import('../../pages/TechsWithUpdateRequest/TechsWithUpdateRequest'),
  'TechsWithUpdateRequest',
);

export const LazyCreateBorescopeAnalysisReport = lazyRetry(
  () => import('../../pages/BorescopeAnalysisReports/CreateBorescopeAnalysisReports'),
  'CreateBorescopeAnalysisReport',
);

export const LazySavvyBorescopeReport = lazyRetry(
  () => import('../../pages/SavvyBorescopeReport/BorescopeAnalysisReport'),
  'SavvyBorescopeAnalysisReport',
);

export const LazySearchAircraftByType = lazyRetry(() => import('../../pages/AdvancedSearch/SearchAircraftByType'), 'SearchAircraftByType');
export const LazyMakePayments = lazyRetry(() => import('../../pages/VendorPayments/MakePayments'), 'MakePayments');
export const LazyPostmark = lazyRetry(() => import('../../pages/Admin/Postmark'), 'Postmark');
export const LazyAPItemplate = lazyRetry(() => import('../../pages/Admin/APItemplate'), 'APItemplate');
