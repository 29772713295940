import React from 'react';
import { Typography, Link } from '@mui/material';
import { IAircraftDetail } from 'types';

import { PATH_AGREEMENT } from 'const';

interface SavvyBasicsDescriptionProps {
  aircraft: IAircraftDetail | undefined;
}

const SavvyBasicsDescription: React.FC<SavvyBasicsDescriptionProps> = (props) => {
  const { aircraft } = props;

  if (!aircraft) {
    return null;
  }

  return (
    <React.Fragment>
      <Typography
        sx={{
          mb: 1,
        }}
      >
        Welcome to SavvyBasics! We look forward to providing you automated reports based on the engine data from your {aircraft?.year}{' '}
        {aircraft?.aircraftManufacturer?.name} {aircraft?.aircraftModel?.name}, as well as to provide you 24/7/365 Breakdown Assistance
        while away from your home base.
      </Typography>
      <Typography
        sx={{
          mb: 1,
        }}
      >
        You have agreed to be bound by the terms and conditions of the{' '}
        <Link href={PATH_AGREEMENT} target="_blank">
          SavvyBasics Service Agreement
        </Link>
        .
      </Typography>
      <Typography
        sx={{
          mb: 1,
        }}
      >
        We look forward to working with you. If you have any questions, please contact us by email at{' '}
        <Link href="mailto:operations@savvyaviation.com">operations@savvyaviation.com</Link> or by telephone toll-free at 1-888-465-8038.
      </Typography>
      <Typography>Welcome!</Typography>
    </React.Fragment>
  );
};

export default SavvyBasicsDescription;
