import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Dialog,
  Box,
  DialogTitle,
  DialogContent,
  TextField,
  MenuItem,
  DialogActions,
  Grid,
  Button,
  Typography,
  CircularProgress,
} from '@mui/material';
import { Dayjs } from 'dayjs';
import { MUTATION_CREATE_MAINTENANCE_ENTRY, QUERY_MAINTENANCE_RECORD_CATEGORIES, QUERY_SAVVY_AIRCRAFT_LOGBOOKS } from 'gql';
import { useMutation, useLazyQuery } from '@apollo/client';
import { setAlert } from 'state';
import { uploadFileDirectS3, getMaintenanceCategoryName } from 'helpers';

import { ComponentLoading, Checkbox, CustomDatePicker } from 'components';
import { IMaintenanceEntry, ILogbook } from 'types';

interface AddLogDialogProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  aircraftId: string;
  maintenanceEntries: IMaintenanceEntry[];
  selectedLogbookId?: string;
}

const AddLogDialog: React.FC<AddLogDialogProps> = (props) => {
  const { open, setOpen, aircraftId, maintenanceEntries, selectedLogbookId = '' } = props;

  const [fetchMaintenanceCategory, { data: dataCategory, loading: loadingCategory, error: errorCategory }] =
    useLazyQuery(QUERY_MAINTENANCE_RECORD_CATEGORIES);
  const [createMaintenanceEntry, { data, loading, error }] = useMutation(MUTATION_CREATE_MAINTENANCE_ENTRY);
  const [fetchLogbooks, { data: dataLogbooks, loading: loadingLogbooks, error: errorLogbooks }] =
    useLazyQuery(QUERY_SAVVY_AIRCRAFT_LOGBOOKS);
  const dispatch = useDispatch();

  const [isPerformedInspection, setIsPerformedInspection] = useState(false);
  const [isPerformedMaintenance, setIsPerformedMaintenance] = useState(false);

  const [entryDate, setEntryDate] = useState<Dayjs | null>(null);
  const [entryDateError, setEntryDateError] = useState('');

  const [logbookId, setLogbookId] = useState(selectedLogbookId ? selectedLogbookId : '-1');
  const [logbookIdError, setLogbookIdError] = useState('');

  const [documentPath, setDocumentPath] = useState('');
  const [documentError, setDocumentError] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [file, setFile] = useState<File | null>(null);

  const [maintenanceEntryCategoryId, setMaintenanceEntryCategoryId] = useState('-1');
  const [maintenanceEntryCategoryName, setMaintenanceEntryCategoryName] = useState('');
  const [recordTypeError, setRecordTypeError] = useState('');

  const handleClose = () => {
    setOpen(false);
  };

  const uploadAttachments = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;

    if (!file) return;

    setIsUploading(true);
    try {
      const result = await uploadFileDirectS3(file, true, '');
      if (result.success) {
        setDocumentPath(result.key);
        setFile(file);
      } else {
        setFile(null);
        setDocumentError('Failed to upload document');
      }
      setIsUploading(false);
    } catch (error) {
      setIsUploading(false);
      setDocumentError('Failed to upload document');
    }
  };

  const isExistingCombo = () => {
    for (const entry of maintenanceEntries) {
      if (entry) {
        if (entry.isCombo) return entry;
      }
    }
    return null;
  };

  const onSubmit = async () => {
    if (!entryDate) {
      setEntryDateError('Please input record date');
      return;
    }

    if (!logbookId || logbookId === '-1') {
      setLogbookIdError('Please select logbook');
      return;
    }

    if (!maintenanceEntryCategoryId || maintenanceEntryCategoryId === '-1') {
      setRecordTypeError('Please select maintenance record type');
      return;
    }

    if (!documentPath) {
      setDocumentError('Please add document');
      return;
    }

    await createMaintenanceEntry({
      variables: {
        aircraftId,
        documentPath,
        isPerformedInspection,
        isPerformedMaintenance,
        entryDate,
        maintenanceEntryCategoryId,
        logbookId,
      },
    });
  };

  useEffect(() => {
    if (open) {
      fetchMaintenanceCategory();
    }
  }, [open, fetchMaintenanceCategory]);

  useEffect(() => {
    if (open) {
      fetchLogbooks({
        variables: {
          aircraftId,
        },
      });
    }
  }, [open, fetchLogbooks, aircraftId]);

  useEffect(() => {
    if (error) {
      dispatch(setAlert('error', 'Unable to create maintenance record'));
    } else if (data) {
      if (data?.createMaintenanceEntry?.ok) {
        dispatch(setAlert('success', 'Created new maintenance record'));
        setOpen(false);
      } else {
        dispatch(setAlert('error', data?.createMaintenanceEntry?.error || 'Unable to create maintenance record'));
      }
    }
  }, [error, data, dispatch, setOpen]);

  useEffect(() => {
    if (errorCategory) {
      dispatch(setAlert('error', 'Unable to fetch maintenance record category'));
    }
  }, [errorCategory, dispatch]);

  useEffect(() => {
    if (errorLogbooks) {
      dispatch(setAlert('error', 'Unable to fetch logbooks'));
    }
  }, [errorLogbooks, dispatch]);

  useEffect(() => {
    if (!open) {
      setIsPerformedInspection(false);
      setIsPerformedMaintenance(false);
      setEntryDate(null);
      setMaintenanceEntryCategoryId('-1');
      setLogbookId(selectedLogbookId || '-1');
      setMaintenanceEntryCategoryName('');
      setFile(null);
    }
  }, [open, selectedLogbookId]);

  useEffect(() => {
    if (open) {
      setLogbookId(selectedLogbookId ? selectedLogbookId : '-1');
    }
  }, [open, selectedLogbookId]);

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={handleClose}
      PaperProps={{
        sx: {
          maxWidth: '400px',
        },
      }}
    >
      <DialogTitle
        sx={{
          textAlign: 'center',
          fontSize: '24px',
          fontWeight: 700,
        }}
      >
        Create Maintenance Record
      </DialogTitle>
      <DialogContent>
        <ComponentLoading loading={loading || loadingCategory || loadingLogbooks}>
          <Box py={1}>
            <Checkbox
              value={isPerformedMaintenance}
              checked={isPerformedMaintenance}
              label={
                <Typography
                  sx={{
                    display: 'inline-block',
                  }}
                >
                  Performed Maintenance?
                </Typography>
              }
              onChange={() => {
                setIsPerformedMaintenance(!isPerformedMaintenance);
              }}
              noMargin
            />
          </Box>
          <Box py={1}>
            <Checkbox
              value={isPerformedInspection}
              checked={isPerformedInspection}
              label={
                <Typography
                  sx={{
                    display: 'inline-block',
                  }}
                >
                  Performed Inspection?
                </Typography>
              }
              onChange={() => {
                setIsPerformedInspection(!isPerformedInspection);
              }}
              noMargin
            />
          </Box>
          <Box py={1}>
            <CustomDatePicker
              value={entryDate}
              setValue={setEntryDate}
              error={entryDateError}
              setError={setEntryDateError}
              label="Entry Date"
              sx={{
                ml: 0,
              }}
            />
          </Box>
          <Box py={1}>
            <TextField
              name="logbookId"
              label="Logbook *"
              value={logbookId}
              onChange={(e) => {
                setLogbookId(e.target.value);
                setLogbookIdError('');
              }}
              error={!!logbookIdError}
              helperText={logbookIdError}
              FormHelperTextProps={{
                sx: {
                  ml: 0,
                },
              }}
              select
              fullWidth
            >
              <MenuItem value="-1">Select Logbook</MenuItem>
              {dataLogbooks?.savvy?.aircraft?.logbooks?.map((logbook: ILogbook, key: number) => {
                return (
                  <MenuItem key={key} value={logbook.id}>
                    {logbook.name}
                  </MenuItem>
                );
              })}
            </TextField>
          </Box>
          <Box py={1}>
            <TextField
              name="maintenanceRecordType"
              label="Maintenance Record Type *"
              value={maintenanceEntryCategoryId}
              onChange={(e) => {
                setMaintenanceEntryCategoryId(e.target.value);
                setMaintenanceEntryCategoryName(getMaintenanceCategoryName(e.target.value, dataCategory?.maintenanceEntryCategories || []));
                setRecordTypeError('');
              }}
              error={!!recordTypeError}
              helperText={recordTypeError}
              FormHelperTextProps={{
                sx: {
                  ml: 0,
                },
              }}
              select
              fullWidth
            >
              <MenuItem value="-1">Select record type</MenuItem>
              {dataCategory?.maintenanceEntryCategories?.map((category: any, key: number) => {
                return (
                  <MenuItem key={key} value={category.id}>
                    {category.name}
                  </MenuItem>
                );
              })}
            </TextField>
          </Box>
          {!!maintenanceEntryCategoryId && (
            <React.Fragment>
              <Box
                sx={{
                  mb: '5px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Button
                  href="#"
                  component="label"
                  onClick={(e: any) => {
                    if (isUploading) {
                      e.preventDefault();
                      e.stopPropagation();
                      return false;
                    }
                  }}
                >
                  {maintenanceEntryCategoryName === 'Combo' && !isExistingCombo() ? 'Add combined PDF logs' : 'Add document'}
                  <input
                    type="file"
                    id="attachFile"
                    hidden
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setDocumentError('');
                      uploadAttachments(e);
                    }}
                    name="attachments"
                    accept=".pdf"
                  />
                </Button>
                {isUploading && <CircularProgress color="inherit" size={20} variant="indeterminate" />}
              </Box>
              {file && (
                <Box>
                  <Typography gutterBottom>{file.name}</Typography>
                </Box>
              )}
              {!!documentError && (
                <Typography
                  sx={{
                    color: 'error.main',
                  }}
                  gutterBottom
                >
                  {documentError}
                </Typography>
              )}
            </React.Fragment>
          )}
          {maintenanceEntryCategoryName === 'Combo' && (
            <Box py={1}>
              <Typography>You can also Cancel, split the files up and add individually</Typography>
            </Box>
          )}
        </ComponentLoading>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button variant="outlined" onClick={handleClose} disabled={loading} fullWidth>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="contained" onClick={onSubmit} disabled={loading} fullWidth>
              Submit
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default AddLogDialog;
