import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Container, Grid, Typography, Button } from '@mui/material';
import { Formik, Form } from 'formik';

import {
  QUERY_TECHNICIAN_CLASSIFICATION_CATEGORIES,
  MUTATION_CHANGE_CLASSIFICATIONS,
  QUERY_TECHNICIAN_CLASSIFICATIONS,
  QUERY_ME_CLASSIFICATIONS,
} from 'gql';
import { useLazyQuery, useMutation } from '@apollo/client';

import { setAlert } from 'state';
import { ComponentLoading, ButtonSubmit, CheckBoxLabel } from 'components';
import { IServiceCenterClassificationCategory, IClassification } from 'types';
import { ClassificationType } from 'pages/Internal/components';

interface ClassificationsTabProps {
  isLoad: boolean;
}

const ClassificationsTab: React.FC<ClassificationsTabProps> = (props) => {
  const { isLoad } = props;

  const [fetchCategories, { data: dataCategories, loading: loadingCategories }] = useLazyQuery(QUERY_TECHNICIAN_CLASSIFICATION_CATEGORIES);
  const [fetchClassifications, { data: dataClassifications, loading: loadingClassifications }] =
    useLazyQuery(QUERY_TECHNICIAN_CLASSIFICATIONS);
  const [fetchUserClassifications, { data: dataUserClassifications, loading: loadingUserClassifications }] =
    useLazyQuery(QUERY_ME_CLASSIFICATIONS);

  const dispatch = useDispatch();

  const [classifications, setClassifications] = useState<string[]>([]);
  const [editMode, setEditMode] = useState(false);

  const [updateClassifications, { data, loading, error }] = useMutation(MUTATION_CHANGE_CLASSIFICATIONS);

  useEffect(() => {
    if (error) {
      dispatch(setAlert('error', 'Unable to update classifications'));
    } else if (data) {
      if (data.changePersonalInfo?.ok) {
        dispatch(setAlert('success', 'Updated classifications'));
        setEditMode(false);
      } else {
        dispatch(setAlert('error', data.changePersonalInfo?.error || 'Unable to update classifications'));
      }
    }
  }, [error, data, dispatch]);

  useEffect(() => {
    if (isLoad) {
      fetchCategories();
      fetchClassifications();
      fetchUserClassifications();
    }
  }, [isLoad, fetchCategories, fetchUserClassifications]);

  useEffect(() => {
    if (dataUserClassifications?.me?.classifications?.length) {
      setClassifications(dataUserClassifications.me.classifications.map((classification: IClassification) => classification.id));
    }
  }, [dataClassifications]);

  return (
    <Box px={1} py={5}>
      <Container maxWidth="xl" fixed>
        <Box>
          <ComponentLoading loading={loadingCategories || loadingClassifications || loading || loadingUserClassifications}>
            <Formik
              enableReinitialize
              initialValues={{
                classifications: classifications,
              }}
              onSubmit={async (values) => {
                setClassifications(values.classifications);
                await updateClassifications({
                  variables: {
                    ...values,
                  },
                });
              }}
            >
              {({ isSubmitting }) => {
                return (
                  <Form>
                    {!loadingCategories && !loadingClassifications && (
                      <Grid container spacing={2}>
                        {!!dataCategories?.technicianClassificationCategories?.length &&
                          dataCategories.technicianClassificationCategories.map((category: IServiceCenterClassificationCategory) => {
                            const sortClassifications = dataClassifications?.technicianClassifications
                              ? [...dataClassifications.technicianClassifications]
                              : [];

                            const classifications = sortClassifications
                              .filter((x: ClassificationType) => x.category === category.name)
                              ?.map((classification: ClassificationType) => {
                                const existingItem = dataUserClassifications?.me?.classifications?.find(
                                  (item: IClassification) => item.id === classification.id,
                                );
                                if (!existingItem && !editMode) {
                                  return null;
                                } else if (!editMode) {
                                  return (
                                    <Box py={1} key={classification.id}>
                                      <Typography>{classification.name}</Typography>
                                    </Box>
                                  );
                                }
                                return (
                                  <CheckBoxLabel
                                    key={classification.id}
                                    id={classification.id}
                                    group={category.name}
                                    value={classification.name}
                                  />
                                );
                              });
                            const filterClassifications = classifications.filter((item: React.ReactNode | null) => item !== null);
                            if (filterClassifications.length) {
                              return (
                                <Grid item key={category.id} md={2}>
                                  <Box pb={1}>
                                    <Typography variant="h5">{category.name}</Typography>
                                  </Box>
                                  <Box
                                    role="group"
                                    aria-labelledby="checkbox-group"
                                    key={category.id}
                                    sx={{
                                      display: 'flex',
                                      flexFlow: 'column',
                                    }}
                                  >
                                    {filterClassifications}
                                  </Box>
                                </Grid>
                              );
                            } else {
                              return null;
                            }
                          })}
                      </Grid>
                    )}
                    <Grid container spacing={2}>
                      {editMode && (
                        <React.Fragment>
                          <Grid item md={6} xs={false}>
                            <ButtonSubmit text="Save" loading={isSubmitting || loading} />
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <Button
                              variant="outlined"
                              sx={{
                                ml: 1,
                              }}
                              onClick={() => {
                                setEditMode(false);
                              }}
                              color="warning"
                              fullWidth
                            >
                              Cancel
                            </Button>
                          </Grid>
                        </React.Fragment>
                      )}
                      {!editMode && (
                        <React.Fragment>
                          <Grid item md={3} xs={false}></Grid>
                          <Grid item md={6} xs={12}>
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                              }}
                            >
                              <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                  setEditMode(true);
                                }}
                                sx={{
                                  flexGrow: 1,
                                }}
                              >
                                Edit
                              </Button>
                            </Box>
                          </Grid>
                        </React.Fragment>
                      )}
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </ComponentLoading>
        </Box>
      </Container>
    </Box>
  );
};

export default ClassificationsTab;
