export const NOT_HAPPT_TICKET = 'NOT HAPPY';
export const COI_WARNING = 'Aircraft is missing COI';

export const MAX_SUBJECT_LENGTH = 60;
export const SHOW_SUBJECT_WARNING = 50;
export const TICKET_COLOR_LIST = ['#F44723', '#34AFEE', '#FBDC2B', '#4AAF61', '#EA7741', '#7759EF', '#000000'];

export const PTD_ROW_HEIGHT = 72;
export const FEVA_ALERTS_CHART_FILTER = 'chart';
export const FEVA_ALERTS_PHYSICAL_FILTER = 'physical';

export const RATING_DESCRIPTION = [
  'Awful.',
  'Barely OK - had some serious problems.',
  'OK, but had moderate problems.',
  'Great, with only minor problems.',
  'Everything was great!',
];

export const URGENT_TICKET_BACKGROUND = 'rgba(214,33,33,0.2)';

export const MAX_REMARKS_LENGTH = 499;
