import useMediaQuery from '@mui/material/useMediaQuery';
import { theme } from 'theme';

// Returns true or false depending on breakpoint match
// Down breakpoint includes itself (per Material-UI principle). For example mdDown is 0-1023px (!!!NOT 0-767px)
export const useXsDown = () => {
  return useMediaQuery(theme.breakpoints.down('xs'));
};
export const useSmDown = () => {
  return useMediaQuery(theme.breakpoints.down('sm'));
};
export const useMdDown = () => {
  return useMediaQuery(theme.breakpoints.down('md'));
};
export const useLgDown = () => {
  return useMediaQuery(theme.breakpoints.down('lg'));
};
export const useXlDown = () => {
  return useMediaQuery(theme.breakpoints.down('xl'));
};

export const useSmUp = () => {
  return useMediaQuery(theme.breakpoints.up('sm'));
};
export const useMdUp = () => {
  return useMediaQuery(theme.breakpoints.up('md'));
};
export const useLgUp = () => {
  return useMediaQuery(theme.breakpoints.up('lg'));
};
export const useXlUp = () => {
  return useMediaQuery(theme.breakpoints.up('xl'));
};
