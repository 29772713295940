import React from 'react';
import { Box, Typography, Skeleton } from '@mui/material';

import { sizeWithUnit } from 'helpers';
import { IBorescopeImageFile } from 'types';

import { UPLOAD_STATUS_ERROR, UPLOAD_STATUS_SUCCESS, UPLOAD_STATUS_PROCESSING } from 'const';

interface UploadingStatusBarProps {
  uploadedFile: IBorescopeImageFile;
}

const UploadingStatusBar: React.FC<UploadingStatusBarProps> = (props) => {
  const { uploadedFile } = props;

  const getColorByStatus = (status: string) => {
    if (status === UPLOAD_STATUS_SUCCESS) {
      return 'success.main';
    }

    if (status === UPLOAD_STATUS_PROCESSING) {
      return 'primary.main';
    }

    if (status === UPLOAD_STATUS_ERROR) {
      return 'error.dark';
    }
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
      }}
    >
      <Typography color="text.secondary">
        {uploadedFile.fileName}{' '}
        <Box
          component="span"
          sx={{
            fontSize: '14px',
            fontWeight: 'normal',
            lineHeight: '19px',
            color: 'text.primary',
            opacity: 0.5,
          }}
        >
          {sizeWithUnit(uploadedFile.fileSize)}
        </Box>
      </Typography>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Skeleton
          variant="rectangular"
          animation="wave"
          sx={{
            height: '12px',
            width: '220px',
            backgroundColor: getColorByStatus(uploadedFile.status),
            borderRadius: '6px',
            mr: 1,
            flexGrow: 1,
          }}
        />
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '19px',
            color: getColorByStatus(uploadedFile.status),
          }}
        >
          {uploadedFile.status}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          width: uploadedFile.status === UPLOAD_STATUS_ERROR ? '25%' : '0%',
          pl: 2,
        }}
      >
        <Typography
          sx={{
            fontSize: '14px',
            lineHeight: '19px',
            color: uploadedFile.status === UPLOAD_STATUS_ERROR ? 'error.dark' : 'text.primary',
          }}
        >
          {uploadedFile.status === UPLOAD_STATUS_SUCCESS ? '' : uploadedFile.details}
        </Typography>
      </Box>
    </Box>
  );
};

export default UploadingStatusBar;
