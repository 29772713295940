import { gql } from '@apollo/client';
import { FRAGMENT_COHORTS, FRAGMENT_OOP, FRAGMENT_CANNED_RESPONSE, FRAGMENT_BANK_INFO } from 'gql';

export const QUERY_USER_DATA = gql`
  query Me {
    me {
      id
      email
      nickname
      firstName
      lastName
      avatar
      incompleteAircraft {
        id
        registration
        subscriptions {
          id
          serviceCode
        }
        aircraftModel {
          id
          name
          propulsion
          enginesCount
        }
      }
      isSavvy
      isClient
      isTechnician
      isHijacked
      emailVerified
      created
      hasActiveBreakdown
      role
    }
  }
`;

export const QUERY_USER_DETAIL_DATA = gql`
  query MeInfo {
    me {
      id
      email
      nickname
      firstName
      lastName
      avatar
      company
      country
      zip
      street
      city
      state
      airportId
      id
      phoneMobile
      sharingMode
      sharingUrl
      apiTokens {
        id
        name
        token
      }
      settings {
        emailNotificationsForOwnPosts
        alwaysResetSeriesWhenViewingFlights
      }
      timezone
    }
  }
`;

export const QUERY_ME_TECHNICIAN_DETAIL = gql`
  query MeTechnician {
    me {
      id
      email
      nickname
      firstName
      lastName
      avatar
      company
      country
      zip
      street
      city
      state
      airportId
      id
      timezone
      phoneMobile
      classifications {
        id
        name
        category
      }
    }
  }
`;

export const QUERY_RESET_SERIES_SETTING = gql`
  query MeResetSeriesSetting {
    me {
      id
      settings {
        alwaysResetSeriesWhenViewingFlights
      }
    }
  }
`;

export const QUERY_USER_CREDIT = gql`
  query MeCreditCard {
    me {
      id
      creditCard {
        cardType
        last4
        expirationMonth
        expirationYear
      }
    }
  }
`;

export const QUERY_CHECK_ADDITIONAL_INFO = gql`
  query CheckStep3 {
    me {
      id
      airportId
      phoneMobile
      country
    }
  }
`;

export const QUERY_ACCOUNT_EXIST = gql`
  query AccountExist($email: String!) {
    accounts(email: $email) {
      exists
    }
  }
`;

export const QUERY_TECHNICIAN = gql`
  query Technicians($technicianId: ID!) {
    technicians(technicianId: $technicianId) {
      firstName
      lastName
      nickname
      email
      country
      zip
      street
      city
      state
      phoneMobile
      sharingMode
      classifications {
        id
        name
        category
      }
    }
  }
`;

export const QUERY_TECHNICIAN_CLASSIFICATION_CATEGORIES = gql`
  query {
    technicianClassificationCategories {
      id
      name
    }
  }
`;

export const QUERY_TECHNICIAN_CLASSIFICATIONS = gql`
  query {
    technicianClassifications {
      id
      name
      category
    }
  }
`;

export const QUERY_INTERNAL_DASHBOARD = gql`
  query SavvyInternalDashboard {
    savvy {
      id
      me {
        id
        isSavvy
      }
      internalDashboard {
        groups {
          name
          items {
            name
            link
            isInternal
          }
        }
      }
    }
  }
`;

export const QUERY_SAVYY_OOP = gql`
  query GetOopInfo {
    savvy {
      id
      me {
        id
        isSavvy
      }
      internalDashboard {
        currentlyOop {
          id
          user {
            id
            firstName
            lastName
          }
          createdBy {
            id
            firstName
            lastName
          }
          startTime
          endTime
        }
      }
    }
  }
`;

export const QUERY_SAVVY_ALLOWED_WATCHERS = gql`
  query SavvyAllowedWatchers($queryFilter: String) {
    savvy {
      id
      me {
        id
      }
      allowedWatchers(queryFilter: $queryFilter) {
        id
        firstName
        lastName
        company
        serviceCenter {
          id
          company
          state
          country
        }
      }
    }
  }
`;

export const QUERY_SAVVY_ALLOWED_ASSIGNEES = gql`
  query SavvyAllowedAssignees($queryFilter: String) {
    savvy {
      id
      me {
        id
      }
      allowedAssignees(queryFilter: $queryFilter) {
        id
        firstName
        lastName
        fullNameWithNick
      }
    }
  }
`;

export const QUERY_SAVVY_ANOMALY = gql`
  query SavvyAnomaly {
    savvy {
      id
      anomalyTypes {
        id
        name
      }
    }
  }
`;

export const QUERY_VIEW_ANONYMIZE = gql`
  query SavvyViewAnonymizedPermission {
    savvy {
      id
      permissions {
        flight {
          viewAnonymizedFlights
        }
      }
    }
  }
`;

export const QUERY_SAVVY_USER_TAGS = gql`
  query SavvyUserTags {
    savvy {
      id
      userTagTypes {
        id
        name
      }
    }
  }
`;

export const QUERY_TECHNICIAN_LIST_BY_SERVICE_CENTER = gql`
  query GetTechniciansServiceCenters($serviceCenterId: ID, $showAll: Boolean) {
    serviceCenters(serviceCenterId: $serviceCenterId) {
      id
      technicians(showAll: $showAll) {
        id
        firstName
        lastName
        classifications {
          id
          name
          category
        }
        verifiedDate
        isActive
      }
    }
  }
`;

export const QUERY_TECHNICIAN_DETAIL_BY_ID = gql`
  query GetTechnicianById($contactId: ID) {
    savvy {
      contacts(contactId: $contactId) {
        id
        updated
        firstName
        lastName
        email
        needsUpdate
        classifications {
          id
          name
          category
        }
        isSavvy
        isTechnician
        isClient
        isActive
        verifiedDate
        informationUpdateTicket {
          id
          subject
        }
      }
    }
  }
`;

export const QUERY_GET_ALL_CONTACTS = gql`
  query GetAllContacts {
    savvy {
      id
      contacts {
        id
        firstName
        lastName
        isSavvy
        isTechnician
        isClient
        company
        city
        state
        street
        country
        email
        phoneMobile
        updated
      }
    }
  }
`;

export const QUERY_GET_USER_DATA = gql`
  query GetUserData($contactId: ID) {
    savvy {
      id
      contacts(contactId: $contactId) {
        id
        firstName
        lastName
        isSavvy
        isTechnician
        isClient
        city
        state
        street
        zip
        country
        email
        phoneMobile
        airportId
        classifications {
          id
          name
          category
        }
        needsUpdate
        isActive
        assignedGroups
        nickname
        emailCcs
        serviceCenter {
          id
          company
        }
      }
    }
  }
`;

export const QUERY_GET_USER_DATA_WITH_LOCATION = gql`
  query GetUserDataWithLocation($contactId: ID) {
    savvy {
      id
      contacts(contactId: $contactId) {
        id
        firstName
        lastName
        company
        isSavvy
        isTechnician
        isClient
        city
        state
        street
        zip
        country
        email
        phoneMobile
        airportId
        classifications {
          id
          name
          category
        }
        needsUpdate
        isActive
        assignedGroups
        nickname
        emailCcs
        serviceCenter {
          id
          company
        }
        coordinates {
          latitude
          longitude
        }
      }
    }
  }
`;

export const QUERY_GET_SAVVY_USER_DATA = gql`
  query GetSavvyUserData($contactId: ID) {
    savvy {
      id
      contacts(contactId: $contactId) {
        id
        firstName
        lastName
      }
    }
  }
`;

export const QUERY_GET_SAVVY_PERMISSIONS = gql`
  query GetSavvyPermissions {
    savvy {
      id
      permissions {
        contact {
          canResetPassword
          canExpungeEmail
          canEnableAndDisableContact
          canAssignGroups
          canLoginAs
        }
        aircraft {
          canEditManagers
          canBackfillMaintenanceLogs
        }
        payment {
          managePaymentInfo
        }
      }
    }
  }
`;

export const QUERY_GET_USER_COMMENTS = gql`
  query GetUserComments($contactId: ID) {
    savvy {
      id
      contacts(contactId: $contactId) {
        id
        comments {
          id
          pinned
          comment
          poster {
            firstName
            lastName
          }
          date
        }
      }
    }
  }
`;

export const QUERY_ME_UNIT_CONVERSIONS = gql`
  query MeUnitConversions {
    me {
      id
      conversions {
        id
        fromName
        toName
        groupName
        aParam
        bParam
      }
    }
  }
`;

export const QUERY_AVAILABLE_CONTACT_GROUPS = gql`
  query AvailableContactGroups {
    availableContactGroups {
      groups
    }
  }
`;

export const QUERY_SAVVY_SEARCH = gql`
  query SavvySearch($search: String, $limit: Int, $offset: Int) {
    savvy {
      search(search: $search, limit: $limit) {
        aircrafts(offset: $offset, limit: $limit) {
          count
          results {
            id
            registration
            serial
            aircraftManufacturer {
              name
            }
            aircraftModel {
              name
            }
            owner {
              id
            }
          }
        }
        tickets(offset: $offset, limit: $limit) {
          count
          results {
            id
            created
            updated
            state
            subject
            type
            creator {
              id
              firstName
              lastName
            }
            aircraft {
              id
              registration
              serial
              aircraftManufacturer {
                name
              }
              aircraftModel {
                name
              }
              owner {
                id
                firstName
                lastName
              }
            }
          }
        }
        serviceCenters(offset: $offset, limit: $limit) {
          count
          results {
            id
            company
            street
            city
            state
            zip
            country
            airportId
            phoneWork
            phoneFax
            latitude
            longitude
            scAuth
            scCode
          }
        }
        subscriptions(offset: $offset, limit: $limit) {
          count
          results {
            id
            serviceName
            serviceCode
            status
            aircraft {
              id
            }
          }
        }
      }
    }
  }
`;

export const QUERY_SAVVY_CONTACT_SEARCH = gql`
  query SavvyContactSearch($search: String, $limit: Int, $offset: Int) {
    savvy {
      id
      search(search: $search, limit: $limit) {
        contacts(offset: $offset, limit: $limit) {
          count
          results {
            id
            firstName
            lastName
            isSavvy
            isTechnician
            isClient
            company
            city
            state
            street
            country
            email
            phoneMobile
            updated
            serviceCenter {
              id
              company
            }
            isActive
          }
        }
      }
    }
  }
`;

export const QUERY_SAVVY_REPORT_SEARCH = gql`
  query SavvyReportSearch($search: String, $limit: Int, $offset: Int) {
    savvy {
      id
      search(search: $search, limit: $limit) {
        flightReports(offset: $offset, limit: $limit) {
          count
          results {
            id
            flight {
              id
              date
              duration
              destinationAirport
              departureAirport
              aircraft {
                id
                registration
                aircraftManufacturer {
                  id
                  name
                }
                aircraftModel {
                  id
                  name
                }
                owner {
                  id
                  firstName
                  lastName
                }
              }
            }
            ticket {
              id
              subject
            }
            engine
            createdOn
            lastUpdateOn
          }
        }
      }
    }
  }
`;

export const QUERY_SAVVY_CONTACT_LIST = gql`
  query SavvyContactList($contactId: ID) {
    savvy {
      id
      contacts(contactId: $contactId) {
        id
        email
        firstName
        lastName
      }
    }
  }
`;

export const QUERY_GET_AVAILABLE_MANAGERS = gql`
  query GetAvailableManagers($queryFilter: String) {
    savvy {
      id
      allManagers(queryFilter: $queryFilter) {
        id
        firstName
        lastName
        email
      }
      permissions {
        aircraft {
          canEditManagers
        }
      }
    }
  }
`;

export const QUERY_GET_AVAILABLE_MANAGERS_WITHOUT_PERMISSION = gql`
  query GetAvailableManagers($queryFilter: String) {
    savvy {
      id
      allManagers(queryFilter: $queryFilter) {
        id
        firstName
        lastName
        email
      }
    }
  }
`;

export const QUERY_SAVVY_TICKET_NOTIFICATION = gql`
  query GetSavvyTicketNOtification {
    savvy {
      id
      sendNewTicketNotification
    }
  }
`;

export const QUERY_EXISTING_TAG_NAMES = gql`
  query ExistingTagNames {
    existingTagNames
  }
`;

export const QUERY_SAVVY_CONTACT_STRIPE_EMAIL = gql`
  query GetContactStripeEmail($contactId: ID) {
    savvy {
      id
      contacts(contactId: $contactId) {
        id
        stripeEmail
      }
    }
  }
`;

export const QUERY_ATTACHMENT_DETAIL = gql`
  query GetAttachmentDetail($attachmentId: ID) {
    savvy {
      id
      attachment(attachmentId: $attachmentId) {
        id
        name
        previewUrl
        isPdf
        isImage
        downloadUrl
        base64Data
      }
    }
  }
`;

export const QUERY_ATTACHMENT_DOWNLOAD_URL = gql`
  query GetAttachmentDownloadUrl($attachmentId: ID) {
    savvy {
      id
      attachment(attachmentId: $attachmentId) {
        id
        downloadUrl
      }
    }
  }
`;

export const QUERY_POSSIBLE_DESTINATION_TICKETS = gql`
  query PossibleDestinationTickets($postId: ID) {
    possibleDestinationTickets(postId: $postId) {
      id
      created
      subject
    }
  }
`;

export const QUERY_COHORTS = gql`
  ${FRAGMENT_COHORTS}
  query Cohorts {
    cohorts {
      ...Cohort
      active
    }
  }
`;

export const QUERY_COHORTS_DETAIL = gql`
  ${FRAGMENT_COHORTS}
  query CohortsDetail($cohortId: ID) {
    cohorts(cohortId: $cohortId) {
      ...Cohort
      history {
        date
        changes {
          field
          oldValue
          newValue
        }
      }
      active
      clearOnNextCycle
      valueDict
      quantileDict
      reportCardsParams {
        id
        name
        titleName
        outlierLeftValueLimit
        outlierRightValueLimit
        outlierLeftStdDevLimit
        outlierRightStdDevLimit
        specMin
        specMax
        presentationOrder
        scalingMultiplier
        units
        colorBarReverseStatus
      }
    }
  }
`;

export const QUERY_COHORT_PARAMS = gql`
  query CohortParams($cohortId: ID) {
    cohorts(cohortId: $cohortId) {
      id
      reportCardsParams {
        id
        name
        titleName
        outlierLeftValueLimit
        outlierRightValueLimit
        outlierLeftStdDevLimit
        outlierRightStdDevLimit
        specMin
        specMax
        presentationOrder
        scalingMultiplier
        units
        colorBarReverseStatus
      }
    }
  }
`;

export const QUERY_COHORTS_BRIEF = gql`
  query CohortsBrief {
    cohorts {
      id
      aircraftModel {
        id
        name
        manufacturer {
          id
          name
        }
      }
      engineModel {
        id
        name
        manufacturer {
          id
          name
        }
      }
    }
  }
`;

export const QUERY_OOP = gql`
  ${FRAGMENT_OOP}
  query OopList {
    oopList {
      ...OutOfPocketInstance
    }
  }
`;

export const QUERY_RELATED_TICKET_STICKY_STATUS = gql`
  query GetRelatedTicketStickyStatus {
    me {
      id
      settings {
        relatedTicketsStickyStatus
      }
    }
  }
`;

export const QUERY_CANNED_RESPONSES = gql`
  ${FRAGMENT_CANNED_RESPONSE}
  query GetCannedResponses {
    savvy {
      id
      cannedResponses {
        ...CannedResponse
      }
    }
  }
`;

export const QUERY_ME_CLASSIFICATIONS = gql`
  query MeClassifications {
    me {
      id
      classifications {
        id
        name
        category
      }
    }
  }
`;

export const QUERY_HAS_FEATURE_FLAG = gql`
  query HasFeatureFlag($flagName: String) {
    hasFeatureFlag(flagName: $flagName)
  }
`;

export const QUERY_TECHS_WITH_UPDATE_REQUESTS = gql`
  query GetTechsWithUpdateRequests {
    savvy {
      id
      techsWithUpdateRequests {
        id
        firstName
        lastName
        verifiedDate
        serviceCenter {
          id
          company
        }
        classifications {
          id
          name
        }
        informationUpdateTicket {
          id
          subject
          created
        }
      }
    }
  }
`;

export const QUERY_SAVVY_BANK_INFO = gql`
  ${FRAGMENT_BANK_INFO}
  query SavvyBankInfo {
    me {
      id
      quickenPayee
      bankInfo {
        ...BankInfo
      }
    }
  }
`;
