import { SET_UNREAD_POST } from 'state/types';

import { IDispatch, IDispatchPost } from 'types';

// Alert. Messages for user. For example, "Signed up success"
export const setUnreadPostIndex = (payload: IDispatchPost) => (dispatch: IDispatch) => {
  dispatch({
    type: SET_UNREAD_POST,
    payload,
  });
};
