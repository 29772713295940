import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Typography, Link } from '@mui/material';

import { QUERY_ME_AIRCRAFT_COI } from 'gql';
import { useLazyQuery } from '@apollo/client';
import { setAlert } from 'state';

import { ComponentLoading } from 'components';
import { CoiDetail } from '../EditComponents';

import { ISubscription } from 'types';

interface CertificationOfInsuranceProps {
  isLoad: boolean;
  aircraftId: string;
  subscriptions: ISubscription[];
}

const CertificationOfInsurance: React.FC<CertificationOfInsuranceProps> = (props) => {
  const { isLoad, aircraftId, subscriptions } = props;

  const [fetchLogs, { data: dataLogs, error: errorLogs, loading: loadingLogs }] = useLazyQuery(QUERY_ME_AIRCRAFT_COI);
  const dispatch = useDispatch();

  const isIncludeMx = (subscriptions: ISubscription[]) => {
    const filtered = subscriptions.filter((item) => item.serviceCode === 'mx');
    if (filtered) return true;
    return false;
  };

  useEffect(() => {
    if (isLoad && aircraftId) {
      fetchLogs({
        variables: {
          id: parseInt(aircraftId),
        },
      });
    }
  }, [isLoad, aircraftId, fetchLogs]);

  useEffect(() => {
    if (errorLogs) {
      dispatch(setAlert('error', 'Unable to load aircraft COI'));
    }
  }, [errorLogs, dispatch]);

  return isIncludeMx(subscriptions) ? (
    <Box
      sx={{
        p: {
          xs: 2,
          sm: 3,
          md: 4,
          lg: 5,
        },
      }}
    >
      <Typography
        sx={{
          textAlign: 'left',
        }}
        gutterBottom
      >
        For US aircraft enrolled in the SavvyMx program, Savvy requires to be added as an "Additional Insured" on the aircraft's insurance
        policy. You can read more about this requirement{' '}
        <Link href="https://resources.savvyaviation.com/insurance-requirements-for-savvymx/" target="_blank">
          here
        </Link>
        .
      </Typography>
      <ComponentLoading loading={loadingLogs}>
        {dataLogs?.me?.aircraft[0] && <CoiDetail aircraft={dataLogs?.me?.aircraft[0]} />}
      </ComponentLoading>
    </Box>
  ) : null;
};

export default CertificationOfInsurance;
