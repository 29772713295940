import {
  PATH_TICKETS,
  PATH_FLIGHTS,
  PATH_REPORTS_BETA,
  PATH_FLIGHTS_UPLOAD,
  PATH_SIGNUP_FREE,
  PATH_LOGIN,
  PATH_TICKETS_CREATE,
  PATH_HELP_SAVVY_RESOURCE,
  PATH_HELP_FAQ,
  PATH_HELP_ARTICLES,
  PATH_HELP_FLIGHT,
  PATH_HELP_DOWNLOAD,
  PATH_HELP_UPGRADE_ENGINE,
  PATH_INTERNAL_DASHBOARD,
  PATH_CONTACT_US,
  PATH_BORESCOPE_UPLOAD,
} from 'const';

import FlightIcon from '@mui/icons-material/Flight';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { TicketIcon } from 'customIcons';

import { IAppBarLink } from 'types';

// Logged in left links
export const LOGGED_IN_LEFT_LINKS: IAppBarLink[] = [
  {
    text: 'Tickets',
    path: PATH_TICKETS,
    exact: false,
  },
  {
    text: 'Flights',
    path: PATH_FLIGHTS,
    exact: false,
  },
  {
    text: 'Reports',
    path: PATH_REPORTS_BETA,
    exact: false,
  },
];

export const LOGGED_IN_TECHNICIAN_LEFT_LINKS: IAppBarLink[] = [
  {
    text: 'Tickets',
    path: PATH_TICKETS,
    exact: false,
  },
];

export const LOGGED_IN_TECHNICIAN_RIGHT_LINKS: IAppBarLink[] = [
  {
    text: 'Create Ticket',
    path: PATH_TICKETS_CREATE,
    exact: false,
    startIcon: <TicketIcon fill="" />,
  },
  {
    text: 'Borescope Images',
    path: PATH_BORESCOPE_UPLOAD,
    exact: false,
  },
];

export const LOGGED_IN_SAVVY_LINKS: IAppBarLink[] = [
  {
    text: 'Dashboard',
    path: PATH_INTERNAL_DASHBOARD,
    exact: true,
  },
];

// Logged in right links
export const LOGGED_IN_RIGHT_LINKS: IAppBarLink[] = [
  {
    text: 'Create Ticket',
    path: PATH_TICKETS_CREATE,
    exact: false,
    startIcon: <TicketIcon fill="" />,
  },
  {
    text: 'Upload Flight',
    path: PATH_FLIGHTS_UPLOAD,
    exact: false,
    startIcon: (
      <FlightIcon
        sx={{
          transform: 'rotate(45deg)',
        }}
      />
    ),
  },
];

// Logged out links (right)
export const LOGGED_OUT_LINKS: IAppBarLink[] = [
  {
    text: 'Signup',
    path: PATH_SIGNUP_FREE,
    endIcon: <PersonAddIcon />,
    exact: false,
  },
  {
    text: 'Login',
    path: PATH_LOGIN,
    endIcon: <AccountCircleIcon />,
    exact: false,
  },
];

export const HELP_LINKS: IAppBarLink[] = [
  {
    text: 'Savvy Resources',
    path: PATH_HELP_SAVVY_RESOURCE,
    exact: false,
  },
  {
    text: 'Flight Test Profile Expanded',
    path: PATH_HELP_FAQ,
    exact: false,
  },
  {
    text: "Mike's Articles",
    path: PATH_HELP_ARTICLES,
    exact: false,
  },
  {
    text: 'Flight Test Profiles',
    path: PATH_HELP_FLIGHT,
    exact: false,
  },
  {
    text: 'How to download data',
    path: PATH_HELP_DOWNLOAD,
    exact: false,
  },
  {
    text: 'Upgrading Your Engine Monitor',
    path: PATH_HELP_UPGRADE_ENGINE,
    exact: false,
  },
  {
    text: 'Contact Us',
    path: PATH_CONTACT_US,
    exact: false,
  },
];
