import { gql } from '@apollo/client';
import { FRAGEMENT_SERVICE_CENTER, FRAGMENT_RATING_SC, FRAGMENT_STATISTIC_SC } from 'gql';

export const QUERY_SERVICE_CENTER = gql`
  query ServiceCenters($serviceCenterId: ID!, $mapView: Boolean) {
    serviceCenters(serviceCenterId: $serviceCenterId, mapView: $mapView) {
      id
      company
      street
      city
      state
      zip
      country
      airportId
      phoneWork
      phoneFax
      latitude
      longitude
      scAuth
      scCode
      classifications {
        id
        name
        category
      }
      website
      isActive
    }
  }
`;

export const QUERY_SAVVY_SC_PERMISSIONS = gql`
  query SavvyScPermissions {
    savvy {
      id
      permissions {
        serviceCenter {
          canEnable
        }
      }
    }
  }
`;

export const QUERY_SERVICE_CENTER_COMMENTS = gql`
  query ServiceCenterComments($serviceCenterId: ID!) {
    serviceCenters(serviceCenterId: $serviceCenterId) {
      id
      comments {
        id
        comment
        date
        pinned
        poster {
          id
          firstName
          lastName
        }
      }
      technicians {
        id
        firstName
        lastName
        comments {
          id
          comment
          date
          pinned
          poster {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export const QUERY_ALL_SERVICES = gql`
  query ServiceCenters($classifications: [ID], $mapView: Boolean) {
    serviceCenters(classifications: $classifications, mapView: $mapView) {
      id
      company
      street
      city
      state
      zip
      country
      airportId
      latitude
      longitude
      scAuth
      scCode
      flashColor
      flashColorSource
    }
  }
`;

export const QUERY_DETAIL_SERVICE = gql`
  query ServiceCenters($serviceCenterId: ID) {
    serviceCenters(serviceCenterId: $serviceCenterId) {
      id
      company
      street
      city
      state
      zip
      country
      airportId
      latitude
      longitude
      scAuth
      scCode
      classifications {
        id
        name
        category
      }
    }
  }
`;

export const QUERY_SERVICE_CENTER_CLASSIFICATION_CATEGORIES = gql`
  query ServiceCenterClassificationCategories($serviceCenterId: ID) {
    serviceCenterClassificationCategories(serviceCenterId: $serviceCenterId) {
      id
      name
      order
    }
  }
`;

export const QUERY_SERVICE_CENTER_CLASSIFICATIONS = gql`
  query {
    serviceCenterClassifications {
      id
      name
      category
      order
    }
  }
`;

export const QUERY_SERVICE_CENTER_REVIEW_REQUEST = gql`
  query ServiceCenterReviewRequest($ticketId: ID) {
    serviceCenterReviewRequest(ticketId: $ticketId) {
      ticket {
        id
        subject
      }
      serviceCenter {
        id
        company
      }
    }
  }
`;

export const QUERY_SERVICE_CENTER_LIST = gql`
  query ServiceCenters($showWithOutstandingUpdateRequests: Boolean) {
    serviceCenters(showWithOutstandingUpdateRequests: $showWithOutstandingUpdateRequests) {
      id
      company
      street
      city
      state
      zip
      country
      airportId
      phoneWork
      phoneFax
      latitude
      longitude
      scAuth
      scCode
      classifications {
        id
        name
        category
      }
      needsUpdate
      technicians {
        id
        updated
      }
      updated
      flashColor
      scAuth
      verificationStatus
      isActive
      verifiedDate
    }
  }
`;

export const QUERY_SAVVY_SERVICE_CENTER_SEARCH = gql`
  query SavvyServiceCenterSearch($search: String, $limit: Int, $offset: Int) {
    savvy {
      id
      search(search: $search, limit: $limit) {
        serviceCenters(offset: $offset, limit: $limit) {
          count
          results {
            id
            company
            street
            city
            state
            zip
            country
            airportId
            phoneWork
            phoneFax
            latitude
            longitude
            scAuth
            scCode
            updated
            isActive
          }
        }
      }
    }
  }
`;

export const QUERY_EXISTING_SERVICE_CENTERS = gql`
  query ExistingServiceCenters($companyName: String) {
    existingServiceCenters(companyName: $companyName) {
      id
      company
      street
      zip
      city
      state
      country
      airportId
    }
  }
`;

export const QUERY_SERVICE_CENTER_DETAIL = gql`
  ${FRAGEMENT_SERVICE_CENTER}
  query ServiceCenterDetail($serviceCenterId: ID) {
    serviceCenters(serviceCenterId: $serviceCenterId) {
      ...ServiceCenter
      dashRating {
        communications {
          score
        }
        approval {
          score
        }
        quality {
          score
        }
        philosophy {
          score
        }
        cost {
          score
        }
      }
      tickets {
        id
        updated
      }
      comments {
        id
      }
      classifications {
        id
        name
        category
      }
      aircraftTypes {
        make
        models {
          name
          count
        }
      }
      latitude
      longitude
    }
  }
`;

export const QUERY_SERVICE_CENTER_RATINGS = gql`
  ${FRAGMENT_RATING_SC}
  ${FRAGMENT_STATISTIC_SC}
  query ServiceCenterRating($serviceCenterId: ID) {
    serviceCenters(serviceCenterId: $serviceCenterId) {
      id
      flashColor
      averageRatingScore
      flashColorSource
      dashRating {
        communications {
          ...Rating
        }
        approval {
          ...Rating
        }
        quality {
          ...Rating
        }
        philosophy {
          ...Rating
        }
        cost {
          ...Rating
        }
      }
      drillRating {
        reviews {
          ratings {
            communications {
              ...Rating
            }
            approval {
              ...Rating
            }
            quality {
              ...Rating
            }
            philosophy {
              ...Rating
            }
            cost {
              ...Rating
            }
          }
          reviewer {
            id
            firstName
            lastName
          }
          ticket {
            id
            subject
          }
          comment
          date
        }
        statistics {
          communications {
            ...StatisticItem
          }
          approval {
            ...StatisticItem
          }
          quality {
            ...StatisticItem
          }
          philosophy {
            ...StatisticItem
          }
          cost {
            ...StatisticItem
          }
        }
      }
    }
  }
`;

export const QUERY_AIRPORT_COORDINATES = gql`
  query AirportCoordinates($airportId: String) {
    airportCoordinates(airportId: $airportId) {
      latitude
      longitude
    }
  }
`;

export const QUERY_SERVICE_CENTER_TICKET_RATINGS = gql`
  query ServiceCenterTicketRatings($serviceCenterId: ID) {
    serviceCenters(serviceCenterId: $serviceCenterId) {
      id
      ratings {
        id
        created
        updated
        ticket {
          id
          subject
        }
        rater {
          id
          firstName
          lastName
        }
        status
        rating
      }
    }
  }
`;

export const QUERY_SERVICE_CENTER_FOR_TECH = gql`
  query ServiceCenterForTech {
    me {
      id
      serviceCenter {
        id
        company
        street
        city
        state
        zip
        country
        airportId
        phoneWork
        phoneFax
        latitude
        longitude
        scAuth
        scCode
        classifications {
          id
          name
          category
        }
        website
        isActive
        technicians {
          id
          updated
          firstName
          lastName
          email
          needsUpdate
          classifications {
            id
            name
            category
          }
          isActive
          lastLogin
        }
      }
    }
  }
`;

export const QUERY_SERVICE_CENTER_UPDATE_TICKETS = gql`
  query ServiceCenterUpdateTickets($serviceCenterId: ID) {
    serviceCenters(serviceCenterId: $serviceCenterId) {
      id
      updatesTickets {
        id
        subject
        state
        updated
        lastPoster {
          id
          firstName
          lastName
        }
      }
    }
  }
`;
