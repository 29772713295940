import React from 'react';
import { Typography, Link } from '@mui/material';
import { IAircraftDetail } from 'types';

import { PATH_AGREEMENT, PATH_CLIENT_GUIDE, PATH_INSURANCE_GUIDE } from 'const';

interface SavvyMxDescriptionProps {
  aircraft: IAircraftDetail | undefined;
}

const SavvyMxDescription: React.FC<SavvyMxDescriptionProps> = (props) => {
  const { aircraft } = props;

  if (!aircraft) {
    return null;
  }

  return (
    <React.Fragment>
      <Typography
        sx={{
          mb: 1,
        }}
      >
        Welcome to SavvyMx! We're delighted that you have hired us to manage the maintenance of your {aircraft?.year}{' '}
        {aircraft?.aircraftManufacturer?.name} {aircraft?.aircraftModel?.name}, as well as to provide you 24/7/365 Breakdown Assistance
        while away from your home base.
      </Typography>
      <Typography
        sx={{
          mb: 1,
        }}
      >
        You have agreed to be bound by the terms and conditions of the{' '}
        <Link href={PATH_AGREEMENT} target="_blank">
          SavvyMx Service Agreement
        </Link>
        .
      </Typography>
      <Typography
        sx={{
          mb: 1,
        }}
      >
        Please take a few minutes to review the{' '}
        <Link href={PATH_CLIENT_GUIDE} target="_blank">
          SavvyMx New Client Guide
        </Link>{' '}
        that explains what to expect from us and how you can work with us to ensure that you get the greatest possible benefit from Savvy’s
        professional maintenance management. Please review it carefully.
      </Typography>
      <Typography
        sx={{
          mb: 1,
        }}
      >
        <b>IMPORTANT:</b> Please be sure to contact your aircraft insurance agent and ask to have Savvy added to your aircraft liability
        policy as an "additional insured" and for a certificate of insurance (COI) to be emailed or faxed to Savvy.{' '}
        <Link href={PATH_INSURANCE_GUIDE} target="_blank">
          Here are detailed instructions for doing this.
        </Link>{' '}
        It normally takes only a day or two to accomplish. If you run into any difficulty, let us know and we'll be happy to help.
      </Typography>
      <Typography
        sx={{
          mb: 1,
        }}
      >
        We look forward to working with you. If you have any questions, please contact us by email at{' '}
        <Link href="mailto:operations@savvyaviation.com">operations@savvyaviation.com</Link> or by telephone toll-free at 1-888-465-8038.
      </Typography>
      <Typography>Welcome!</Typography>
    </React.Fragment>
  );
};

export default SavvyMxDescription;
