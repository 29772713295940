import React from 'react';
import { useDispatch } from 'react-redux';
import { Box, Table, TableHead, TableBody, TableRow, TableCell, Typography } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { copyClipboard, copyClipboardTextArea } from 'helpers';

import { setAlert } from 'state';

interface DiffTableProps {
  diffData: any;
}

const DiffTable: React.FC<DiffTableProps> = (props) => {
  const { diffData } = props;

  const dispatch = useDispatch();

  const copySeries = () => {
    if (diffData.firstSelect) {
      let serieString: string[] = [];

      serieString = Object.keys(diffData.firstSelect).map((key: string) => {
        return key;
      });

      copyClipboard(serieString.join('\t'));

      dispatch(setAlert('success', 'Copied Series'));
    }
  };

  const copyFirst = () => {
    if (diffData.firstSelect) {
      let serieString: string[] = [];

      serieString = Object.keys(diffData.firstSelect).map((key: string) => {
        return diffData.firstSelect[key] ? diffData.firstSelect[key].y + '' : '';
      });

      copyClipboard(serieString.join('\t'));

      dispatch(setAlert('success', 'Copied First Pick Values'));
    }
  };

  const copySecond = () => {
    if (diffData.firstSelect && diffData.secondSelect) {
      let serieString: string[] = [];

      serieString = Object.keys(diffData.firstSelect).map((key: string) => {
        return diffData.secondSelect[key] ? diffData.secondSelect[key].y + '' : '';
      });

      copyClipboard(serieString.join('\t'));

      dispatch(setAlert('success', 'Copied Second Pick Values'));
    }
  };

  const copyDiff = () => {
    if (diffData.firstSelect && diffData.secondSelect) {
      let serieString: string[] = [];

      serieString = Object.keys(diffData.firstSelect).map((key: string) => {
        return diffData.secondSelect && diffData.secondSelect[key]
          ? (diffData.secondSelect[key].y - diffData.firstSelect[key].y).toFixed(1)
          : '';
      });

      copyClipboard(serieString.join('\t'));

      dispatch(setAlert('success', 'Copied Diff Values'));
    }
  };

  const copyAll = () => {
    if (diffData.firstSelect && diffData.secondSelect) {
      let serieString: string[] = [];

      serieString = Object.keys(diffData.firstSelect).map((key: string) => {
        return diffData.secondSelect[key] && diffData.firstSelect[key]
          ? `${key}: ${diffData.firstSelect[key].y}, ${(diffData.secondSelect[key].y - diffData.firstSelect[key].y).toFixed(1)}`
          : '';
      });

      copyClipboardTextArea(`Time: ${diffData.selectedPoint}-${diffData.secondaryPoint}\n` + serieString.join('\n'));

      dispatch(setAlert('success', 'Copied Diff Tables'));
    }
  };

  return (
    <Box>
      <Table
        sx={{
          '& tr th': {
            p: 1,
          },
          '& tr td': {
            p: 1,
          },
        }}
      >
        <TableHead>
          <TableRow
            sx={{
              backgroundColor: 'grey.800',
            }}
          >
            <TableCell
              sx={{
                fontSize: '12px',
                fontWeight: 500,
                textTransform: 'uppercase',
              }}
            >
              Series
              <ContentCopyIcon
                sx={{
                  width: '12px',
                  height: '12px',
                  ml: 1,
                  cursor: 'pointer',
                }}
                onClick={copySeries}
              />
            </TableCell>
            <TableCell
              sx={{
                fontSize: '12px',
                fontWeight: 500,
                textTransform: 'uppercase',
              }}
            >
              Value 1
              <ContentCopyIcon
                sx={{
                  width: '12px',
                  height: '12px',
                  ml: 1,
                  cursor: 'pointer',
                }}
                onClick={copyFirst}
              />
            </TableCell>
            <TableCell
              sx={{
                fontSize: '12px',
                fontWeight: 500,
                textTransform: 'uppercase',
              }}
            >
              Value 2
              <ContentCopyIcon
                sx={{
                  width: '12px',
                  height: '12px',
                  ml: 1,
                  cursor: 'pointer',
                }}
                onClick={copySecond}
              />
            </TableCell>
            <TableCell
              sx={{
                fontSize: '12px',
                fontWeight: 500,
                textTransform: 'uppercase',
              }}
            >
              Δ
              <ContentCopyIcon
                sx={{
                  width: '12px',
                  height: '12px',
                  ml: 1,
                  cursor: 'pointer',
                }}
                onClick={copyDiff}
              />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {diffData.firstSelect &&
            Object.keys(diffData.firstSelect).map((key: string, index) => {
              if (!diffData.firstSelect[key]) return null;

              return (
                <TableRow key={index}>
                  <TableCell>{key}</TableCell>
                  <TableCell>{diffData.firstSelect[key].y}</TableCell>
                  <TableCell>{diffData.secondSelect && diffData.secondSelect[key] ? diffData.secondSelect[key].y : ''}</TableCell>
                  <TableCell>
                    {diffData.secondSelect && diffData.secondSelect[key]
                      ? (diffData.secondSelect[key].y - diffData.firstSelect[key].y).toFixed(1)
                      : ''}
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      <Box
        sx={{
          padding: '12px 20px',
          textAlign: 'right',
        }}
      >
        <Typography
          sx={{
            color: 'grey.600',
            cursor: 'pointer',
          }}
          onClick={copyAll}
        >
          <ContentCopyIcon
            sx={{
              width: '12px',
              height: '12px',
              mr: 1,
              cursor: 'pointer',
            }}
          />
          Copy all
        </Typography>
      </Box>
    </Box>
  );
};

export default DiffTable;
