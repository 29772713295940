import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Container } from '@mui/material';

import { QUERY_SAVVY_AIRCRAFT_COIS } from 'gql';
import { useLazyQuery } from '@apollo/client';

import { setAlert } from 'state';

import { ComponentLoading } from 'components';
import { CoiDetail } from 'pages/Aircrafts/components/EditComponents';

interface CertificateOfInsuranceProps {
  isLoad: boolean;
  aircraftId: string;
}

const CertificateOfInsurance: React.FC<CertificateOfInsuranceProps> = (props) => {
  const { isLoad, aircraftId } = props;

  const [fetchCoi, { data: dataCoi, loading: loadingCoi, error: errorCoi }] = useLazyQuery(QUERY_SAVVY_AIRCRAFT_COIS);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoad && aircraftId) {
      fetchCoi({
        variables: {
          aircraftId,
        },
      });
    }
  }, [isLoad, aircraftId, fetchCoi]);

  useEffect(() => {
    if (errorCoi) {
      dispatch(setAlert('error', 'Unable to load COI data'));
    }
  }, [errorCoi, dispatch]);

  return (
    <Container fixed maxWidth="lg">
      <Box>
        <ComponentLoading loading={loadingCoi}>
          <CoiDetail aircraft={dataCoi?.savvy?.aircraft} canManageCois={dataCoi?.savvy?.permissions?.aircraft?.canManageCois} />
        </ComponentLoading>
      </Box>
    </Container>
  );
};

export default CertificateOfInsurance;
