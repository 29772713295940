import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Box } from '@mui/material';

import { useQuery } from '@apollo/client';

import { setAlert } from 'state';
import { LayoutDashboard, PageLoading } from 'components';
import { MyAccount, PlanHistory, CreditCard } from './components';
import { QUERY_USER_DETAIL_DATA } from 'gql';
import { PATH_SAVVY_ACCOUNT } from 'const';

const Account: React.FC = () => {
  const { data, loading, error } = useQuery(QUERY_USER_DETAIL_DATA);

  const dispatch = useDispatch();
  const { isSavvy, isTechnician } = useSelector((state: any) => state.auth);

  useEffect(() => {
    if (error) {
      dispatch(setAlert('error', 'Unable to load your data.'));
    }
  }, [error, dispatch]);

  if (isSavvy) {
    return <Redirect to={PATH_SAVVY_ACCOUNT} />;
  }

  return (
    <LayoutDashboard>
      {loading && <PageLoading />}
      <Box
        p={{
          md: 0,
          lg: 3.75,
          xl: 5,
        }}
      >
        <Grid
          container
          spacing={{
            md: 0,
            lg: 3.75,
            xl: 5,
          }}
        >
          <Grid item xl={isTechnician ? 12 : 8} lg={12} md={12} xs={12}>
            {data && <MyAccount userData={data?.me} />}
          </Grid>
          {!isTechnician && (
            <Grid item xl={4} lg={12} md={12} xs={12}>
              <Grid
                container
                spacing={{
                  lg: 3.75,
                  xl: 5,
                }}
              >
                <Grid item xl={12} lg={6} md={6} xs={12}>
                  <PlanHistory />
                </Grid>
                <Grid item xl={12} lg={6} md={6} xs={12}>
                  <CreditCard />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Box>
    </LayoutDashboard>
  );
};

export default Account;
