import React from 'react';
import { Field } from 'formik';
import { CheckboxWithLabel } from 'formik-mui';
import { Grid, Typography, FormControlLabel, Radio, RadioGroup } from '@mui/material';

// TODO: Pull these out as components in the correct place

export type ContactParams = {
  id: string;
};

type CheckBoxLabelProps = {
  id: string;
  value: string;
  group: string;
};

type RadioBoxProps = {
  value: string;
  color: string;
  defaultValue: string | null;
  label: string;
};

type RadioGroupProps = {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const CheckBoxLabel: React.FC<CheckBoxLabelProps> = (props) => {
  return <Field type="checkbox" name="classifications" value={props.id} component={CheckboxWithLabel} Label={{ label: props.value }} />;
};

type CategoryType = {
  id: string;
  name: string;
};

export type ClassificationType = {
  id: string;
  name: string;
  category: string;
  order: number;
};

type ClassificationsProps = {
  categories: [CategoryType];
  classifications: [ClassificationType];
};

export const Classifications: React.FC<ClassificationsProps> = (props) => {
  const items = props.categories.map((category) => {
    const classifications = props.classifications
      .filter((x) => x.category === category.name)
      ?.map((classification) => (
        <CheckBoxLabel key={classification.id} id={classification.id} group={category.name} value={classification.name} />
      ));
    return (
      <Grid item key={category.id} md={2}>
        <div>
          <Typography variant="h5">{category.name}</Typography>
        </div>
        <div
          role="group"
          aria-labelledby="checkbox-group"
          key={category.id}
          style={{
            display: 'flex',
            flexFlow: 'column',
          }}
        >
          {classifications}
        </div>
      </Grid>
    );
  });
  return (
    <Grid container spacing={2}>
      {items}
    </Grid>
  );
};

export const RadioBox: React.FC<RadioBoxProps> = (props) => {
  const { value, color, defaultValue, label } = props;
  return (
    <FormControlLabel
      control={
        <Radio
          style={{
            color: color,
          }}
          checked={value === defaultValue || (!value && !defaultValue)}
        />
      }
      label={<Typography>{label}</Typography>}
      value={defaultValue}
    />
  );
};

export const CustomRadioGroup: React.FC<RadioGroupProps> = (props) => {
  const { value, onChange } = props;

  return (
    <RadioGroup
      row
      style={{
        marginBottom: 0,
      }}
      value={value}
      onChange={onChange}
    >
      <RadioBox value={value} defaultValue="1" color="green" label="GOOD" />
      <RadioBox value={value} defaultValue="0" color="orange" label="OK" />
      <RadioBox value={value} defaultValue="-1" color="red" label="POOR" />
      <RadioBox value={value} defaultValue={''} color="gray" label="NOT APPLICABLE" />
    </RadioGroup>
  );
};
