import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Typography, Container, Button, Grid } from '@mui/material';
import { DataGridPro, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { IServiceCenter, ITechnician } from 'types';
import { getTechnicianColor } from 'helpers';
import { Checkbox, ComponentLoading } from 'components';

import { useMutation } from '@apollo/client';
import { MUTATION_UPDATE_SERVICE_CENTER_FOR_TECH } from 'gql';

import { setAlert } from 'state';

interface EmployeesListProps {
  serviceCenter: IServiceCenter | undefined;
  selectedClassifications: string[];
  setServiceCenter: (value: IServiceCenter) => void;
  setSlideNum: (value: number) => void;
  setClickedBack: (value: boolean) => void;
  isLoad: boolean;
  markForRemoval: string[];
  setMarkForRemoval: (value: string[]) => void;
}

const EmployeesList: React.FC<EmployeesListProps> = (props) => {
  const {
    serviceCenter,
    setServiceCenter,
    setSlideNum,
    setClickedBack,
    isLoad,
    selectedClassifications,
    markForRemoval,
    setMarkForRemoval,
  } = props;

  const [updateServiceCenter, { data, loading, error }] = useMutation(MUTATION_UPDATE_SERVICE_CENTER_FOR_TECH);
  const dispatch = useDispatch();

  const [pageSize, setPageSize] = useState(10);

  const getTechnicianColorById = (technicianId: string) => {
    const selectedTechnician = serviceCenter?.technicians?.filter((item: ITechnician) => item.id === technicianId);
    if (selectedTechnician?.length) {
      return getTechnicianColor(selectedTechnician[0]);
    }

    return 'success.main';
  };

  const onSubmit = async () => {
    const cloneServiceCenter = JSON.parse(JSON.stringify(serviceCenter));
    const zipCode = cloneServiceCenter.zip;
    delete cloneServiceCenter.id;
    delete cloneServiceCenter.__typename;
    delete cloneServiceCenter.zip;
    await updateServiceCenter({
      variables: {
        serviceCenterId: serviceCenter?.id,
        ...cloneServiceCenter,
        classifications: selectedClassifications,
        zipCode,
        removalTechnicians: [...markForRemoval],
      },
    });
  };

  useEffect(() => {
    if (error) {
      dispatch(setAlert('error', 'Failed to update service center'));
    } else if (data) {
      if (data.updateServiceCenterForTechnician?.ok) {
        dispatch(setAlert('success', 'Updated service center'));
        setServiceCenter(data.updateServiceCenterForTechnician.serviceCenter);
        setSlideNum(3);
      } else {
        dispatch(setAlert('error', data.updateServiceCenterForTechnician?.error || 'Failed to update service center'));
      }
    }
  }, [error, dispatch, data]);

  if (!serviceCenter) {
    return null;
  }

  return (
    <Box>
      <ComponentLoading loading={loading}>
        {isLoad && (
          <Box py={1}>
            <DataGridPro
              sx={{
                width: '100%',
                minWidth: '100px',
                '& .MuiDataGrid-columnSeparator': {
                  display: 'none',
                },
                '& .MuiDataGrid-cell:focus': {
                  outline: 'none',
                },
                '& .MuiDataGrid-cell': {
                  padding: '10px',
                },
                '& .MuiDataGrid-row': {
                  cursor: 'pointer',
                  borderBottom: '1px solid',
                  borderColor: 'grey.200',
                },
                '& .MuiDataGrid-columnHeader:focus': {
                  outline: 'none',
                },
                '& .MuiDataGrid-cellContent': {
                  whiteSpace: 'break-spaces',
                  wordBreak: 'break-all',
                },
                '& .MuiDataGrid-toolbarContainer': {
                  '& .MuiButton-root': {
                    display: 'none',
                  },
                  '& .MuiTextField-root': {
                    mb: 0,
                  },
                },
                border: 'none',
              }}
              autoHeight
              disableColumnResize={true}
              rowsPerPageOptions={[5, 10, 25, 100]}
              pageSize={pageSize}
              onPageSizeChange={(newPage) => setPageSize(newPage)}
              pagination
              showColumnRightBorder={false}
              disableColumnSelector
              disableDensitySelector
              disableColumnMenu
              disableColumnFilter
              disableSelectionOnClick
              getRowHeight={() => 'auto'}
              rows={serviceCenter.technicians || []}
              columns={[
                {
                  field: 'id',
                  headerName: 'ID',
                  flex: 0.5,
                },
                {
                  field: 'name',
                  headerName: 'Name',
                  renderCell: (params: GridRenderCellParams<string>) => {
                    return (
                      <Typography
                        sx={{
                          color: params?.row?.id ? getTechnicianColorById(params?.row?.id) : 'success.main',
                          whiteSpace: 'break-spaces',
                        }}
                      >
                        {params.row.firstName} {params.row.lastName}
                      </Typography>
                    );
                  },
                  flex: 1,
                },
                {
                  field: 'email',
                  headerName: 'Email',
                  flex: 1,
                },
                {
                  field: 'updated',
                  headerName: 'Last Updated',
                  valueFormatter: (params) => {
                    return params.value ? params.value.toString().substring(0, 10) : '';
                  },
                  flex: 1,
                },
                {
                  field: 'lastLogin',
                  headerName: 'Last Login',
                  valueFormatter: (params) => {
                    return params.value ? params.value.toString().substring(0, 10) : '';
                  },
                  flex: 1,
                },
                {
                  field: 'mark',
                  headerName: 'Mark For Removal',
                  renderCell: (params: GridRenderCellParams<string>) => {
                    const id = params?.row?.id || undefined;
                    if (!id) {
                      return '';
                    }
                    return (
                      <Checkbox
                        value={markForRemoval.includes(id)}
                        checked={markForRemoval.includes(id)}
                        label=""
                        onChange={() => {
                          if (markForRemoval.includes(id)) {
                            console.log(markForRemoval);
                            const index = markForRemoval.indexOf(id);
                            const tmp = [...markForRemoval];
                            tmp.splice(index, 1);
                            setMarkForRemoval([...tmp]);
                          } else {
                            const tmp = [...markForRemoval];
                            tmp.push(id);
                            setMarkForRemoval([...tmp]);
                          }
                        }}
                        noMargin
                      />
                    );
                  },
                  flex: 1,
                },
              ]}
            />
          </Box>
        )}
        <Box py={2}>
          <Container maxWidth="md" fixed>
            <Grid container spacing={2}>
              <Grid item md={6} xs={6}>
                <Button variant="contained" onClick={onSubmit} fullWidth>
                  Save
                </Button>
              </Grid>
              <Grid item md={6} xs={6}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setClickedBack(true);
                    setSlideNum(1);
                  }}
                  fullWidth
                >
                  Back
                </Button>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </ComponentLoading>
    </Box>
  );
};

export default EmployeesList;
