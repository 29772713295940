import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import MenuIcon from '@mui/icons-material/Menu';

import { Grid, Box, ClickAwayListener, IconButton, Grow, Paper } from '@mui/material';

import { UserMenu, CollpasedSavvyMenu } from '.';
import { default as HelpMenu } from './HelpMenu';
import { SearchBar } from './SearchBar';
import { default as InternalDashboard } from './InternalDashboard';

import { useXlUp } from 'hooks';

const LoggedInSavvyMenu: React.FC = () => {
  const role = useSelector((state: any) => {
    if (state.auth?.isClient) {
      return 'client';
    } else if (state.auth?.isSavvy) {
      return 'savvy';
    } else if (state.auth?.isTechnician) {
      return 'technician';
    } else {
      return '';
    }
  });

  const [showInternal, setShowInternal] = useState(false);

  const isXlUp = useXlUp();

  const onClickAway = () => {
    setShowInternal(false);
  };

  const addFocusEvent = useCallback(
    (event: any) => {
      if (!event.ctrlKey && !event.metaKey) {
        return;
      }
      if (event.key === 'k' || event.key === 'K') {
        event.preventDefault();
        event.stopPropagation();
        setShowInternal(!showInternal);
      }

      return;
    },
    [showInternal],
  );

  useEffect(() => {
    document.addEventListener('keydown', addFocusEvent);
    return () => {
      document.removeEventListener('keydown', addFocusEvent);
    };
  }, [addFocusEvent]);

  return (
    <Grid container justifyContent="space-between" alignItems="stretch" className="h-100" columnSpacing={1}>
      {/* Left */}
      {isXlUp && (
        <React.Fragment>
          <Grid item xs={3}></Grid>
          {/* Right */}
          <Grid item xs={9}>
            <Grid container className="h-100">
              <Grid item xs={8}>
                <Box
                  className="h-100"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <SearchBar />
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Grid container justifyContent="flex-end" className="h-100 nowrap">
                  <Grid item>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                      className="h-100"
                    >
                      <ClickAwayListener onClickAway={onClickAway}>
                        <Box
                          sx={{
                            position: 'relative',
                          }}
                        >
                          <IconButton
                            onClick={() => {
                              setShowInternal(!showInternal);
                            }}
                          >
                            <MenuIcon />
                          </IconButton>
                          <Grow in={showInternal}>
                            <Paper
                              sx={{
                                position: 'absolute',
                                zIndex: 999,
                                right: 0,
                              }}
                            >
                              <Box
                                sx={{
                                  minWidth: {
                                    sm: '100%',
                                    md: '700px',
                                  },
                                }}
                              >
                                <InternalDashboard show={showInternal} />
                              </Box>
                            </Paper>
                          </Grow>
                        </Box>
                      </ClickAwayListener>
                    </Box>
                  </Grid>
                  <Grid item>
                    <UserMenu role={role} />
                  </Grid>
                  <Grid item>
                    <HelpMenu />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </React.Fragment>
      )}
      {!isXlUp && (
        <React.Fragment>
          <Grid item xs={2}></Grid>
          <Grid item xs={8}>
            <Box
              className="h-100"
              sx={{
                display: 'flex',
                alignItems: 'center',
                pl: 2,
                '@media (max-width: 450px)': {
                  pl: 0.5,
                },
              }}
            >
              <SearchBar />
            </Box>
          </Grid>
          <Grid item xs>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                height: '100%',
              }}
            >
              <CollpasedSavvyMenu role={role} />
            </Box>
          </Grid>
        </React.Fragment>
      )}
    </Grid>
  );
};

export default LoggedInSavvyMenu;
