import React, { useState, useEffect } from 'react';

import { Dialog, DialogContent, DialogActions, Typography, Button, Grid, Box, CircularProgress } from '@mui/material';

import { ButtonSubmit } from 'components';

import { AircraftSuggestions } from './';

import { useFuzzyEngineMonitorModels } from 'hooks';

import { IOptionIdName } from 'types';

import { useMutation } from '@apollo/client';
import { MUTATION_CREATE_ENGINE_MONITOR_MODEL } from 'gql';

import { useInputWithSuggestions } from 'hooks';

import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-mui';

interface IDialogAddEngineMonitorSuggestions {
  engineMonitorManufacturer: IOptionIdName; // Autocomplete value
  engineMonitorModel: IOptionIdName; // Autocomplete value
  setEngineMonitorModel: (value: IOptionIdName | null) => void; // Set Autocomplete value
  open: boolean;
  setOpen: (value: boolean) => void;
}

const validationSchema = Yup.object({
  engineMonitorModel: Yup.string()
    .min(1, 'Too short: 1-50 characters required')
    .max(50, 'Too long: 1-50 characters required')
    .required('Required'),
});

interface createFormValues {
  engineMonitorModel: string;
}

const DialogAddEngineMonitorModelSuggestions: React.FC<IDialogAddEngineMonitorSuggestions> = (props) => {
  const { engineMonitorManufacturer, engineMonitorModel, setEngineMonitorModel, open, setOpen } = props;

  const [addState, setAddState] = useState(false);

  const [engineMonitorModelInput, setEngineMonitorModelInput] = useState('');
  const [engineMonitorModelInputTmp, setEngineMonitorModelInputTmp] = useState('');
  // EngineMonitorModel Logic
  const { showFuzzy: showFuzzyEngineMonitorModels } = useInputWithSuggestions({ input: engineMonitorModelInput });

  const handleSuggestionClickEngineMonitorModelHelper = (suggestion: IOptionIdName) => {
    setEngineMonitorModel(suggestion);
    handleClose();
  };

  const [
    createEngineMonitorModel,
    { data: dataCreateEngineMonitorModel, error: errorCreateEngineMonitorModel, loading: loadingCreateEngineMonitorModel },
  ] = useMutation(MUTATION_CREATE_ENGINE_MONITOR_MODEL);

  useEffect(() => {
    if (engineMonitorModel?.id) return;
    setEngineMonitorModelInput(engineMonitorModel?.name ? engineMonitorModel.name : '');
  }, [engineMonitorModel, setEngineMonitorModelInput]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!engineMonitorModelInputTmp) {
      setEngineMonitorModelInput('');
      setLoading(false);
      return;
    }

    setLoading(true);

    const timeout: any = setTimeout(() => {
      setEngineMonitorModelInput(engineMonitorModelInputTmp);
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [engineMonitorModelInputTmp, setEngineMonitorModelInput]);

  // Fuzzy Logic
  const fuzzyEngineMonitorModels = useFuzzyEngineMonitorModels({
    engineMonitorModel,
    engineMonitorManufacturerId: engineMonitorManufacturer?.id,
    engineMonitorModelInput,
  }); // Fuzzy EngineMonitorModels

  const handleClose = () => {
    setOpen(false);
    setAddState(false);
  };

  React.useEffect(() => {
    setLoading(false);
    if (!fuzzyEngineMonitorModels.length) {
      setLoading(false);
      setAddState(true);
    } else setAddState(false);
  }, [fuzzyEngineMonitorModels]);

  const [responseError, setResponseError] = useState('');

  const onSubmit = async (values: createFormValues) => {
    if (!engineMonitorManufacturer?.id) {
      setResponseError('Please select/add engine monitor manufacturer first.');
    } else {
      await createEngineMonitorModel({
        variables: {
          manufacturerId: engineMonitorManufacturer.id,
          name: values.engineMonitorModel,
        },
      });
    }
  };

  useEffect(() => {
    if (errorCreateEngineMonitorModel) {
      setResponseError('Failed to create new engine monitor model, please reload page and retry.');
    } else if (dataCreateEngineMonitorModel) {
      const { ok, engineMonitorModel: newEngineMonitorModel, error } = dataCreateEngineMonitorModel.createEngineMonitorModel;

      if (!ok) {
        setResponseError(error || 'Failed to create new engine monitor model, please reload page and retry.');
      } else {
        setEngineMonitorModel(newEngineMonitorModel);
        setOpen(false);
        setAddState(false);
      }
    }
  }, [errorCreateEngineMonitorModel, dataCreateEngineMonitorModel, setAddState, setOpen, setEngineMonitorModel]);

  const notListClick = () => {
    setAddState(true);
  };

  return (
    <Dialog
      open={open}
      fullWidth
      PaperProps={{
        style: {
          height: '400px',
        },
      }}
    >
      <Formik
        initialValues={{
          engineMonitorModel: engineMonitorModel?.name ? engineMonitorModel.name : '',
        }}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, handleChange, handleBlur }) => {
          return (
            <Form
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <DialogContent>
                <Typography variant="h2" gutterBottom align="center">
                  Add engine monitor model for{' '}
                  <Box color="primary.main" component="span">
                    {engineMonitorManufacturer?.name}
                  </Box>
                </Typography>

                <Field
                  name="engineMonitorModel"
                  label="Engine Monitor Model *"
                  component={TextField}
                  onBlur={handleBlur}
                  onChange={(e: any) => {
                    handleChange(e);
                    setEngineMonitorModelInputTmp(e.target.value);
                  }}
                  placeholder={'Start typing to search...'}
                  fullWidth
                  autoFocus
                  variant="outlined"
                  inputProps={{ maxLength: 50 }}
                  FormHelperTextProps={{
                    style: {
                      color: 'red',
                      marginLeft: 0,
                    },
                  }}
                  helperText={responseError ? responseError : ''}
                />
                {loading && <CircularProgress color="inherit" size={20} variant="indeterminate" />}
                {!loading && !addState && showFuzzyEngineMonitorModels && (
                  <AircraftSuggestions
                    label="Did you perhaps mean:"
                    suggestions={fuzzyEngineMonitorModels}
                    handleSuggestionClick={handleSuggestionClickEngineMonitorModelHelper}
                    notListLabel="My enginemonitor model is not listed."
                    notListClick={notListClick}
                  />
                )}
              </DialogContent>

              <DialogActions>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Button color="secondary" variant="outlined" onClick={handleClose} fullWidth>
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <ButtonSubmit text="Add" loading={isSubmitting || loadingCreateEngineMonitorModel} disabled={!addState} />
                  </Grid>
                </Grid>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default DialogAddEngineMonitorModelSuggestions;
