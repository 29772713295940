import React, { useState } from 'react';
import { Grid, Typography, Box, Button, Link, IconButton, Popover } from '@mui/material';

import MoreVertIcon from '@mui/icons-material/MoreVert';

import { DataGridPro, GridRenderCellParams } from '@mui/x-data-grid-pro';

import { Formik, Form } from 'formik';
import { MuiFormikInput, ComponentLoading } from 'components';
import * as Yup from 'yup';
import { useSmDown } from 'hooks';
import { MAX_API_TOKEN_NAME } from 'const';

interface ThirdPartyProps {
  apiTokens: {
    id: string;
    name: string;
    token: string;
  }[];
  createNewToken: (value: string) => void;
  deleteTokenAction: (value: string) => void;
  loading: boolean;
  isLoad: boolean;
}

const createTokenSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, `Too short: 3-${MAX_API_TOKEN_NAME} characters required`)
    .max(MAX_API_TOKEN_NAME, `Too long: 3-${MAX_API_TOKEN_NAME} characters required`)
    .required('Required'),
});

const ThirdPartyApplications: React.FC<ThirdPartyProps> = (props) => {
  const { apiTokens, createNewToken, deleteTokenAction, loading, isLoad } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isSmDown = useSmDown();

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const [selectedToken, setSelectedToken] = useState('');

  const onSubmit = (values: any) => {
    createNewToken(values.name);
  };

  return (
    <Box p={isSmDown ? 2 : 5}>
      <ComponentLoading loading={loading}>
        <Box py={1}>
          <Typography
            variant="h3"
            sx={{
              marginBottom: '0px',
              lineHeight: '25px',
              fontWeight: 700,
            }}
            color="textSecondary"
          >
            About third-party integration
          </Typography>
        </Box>
        <Box py={1}>
          <Typography
            paragraph
            sx={{
              marginBottom: 0,
              fontSize: '14px',
              lineHeight: '24px',
              color: 'text.disabled',
            }}
          >
            SavvyAviation exposes an <Link href="https://github.com/savvyaviation/api-docs">API</Link> for integration with third party
            applications.
            <br />
            <br />
            Instead of providing your email and password to third party apps, we will generate a unique token, which grants those apps
            access to your account. Unlike your email and password, this token can be revoked on a per-app basis, making this a safe way to
            integrate with 3rd parties.
            <br />
            <br />
            Currently, the tokens below will allow 3rd party apps to get a list of your aircraft, and upload files to your account.
            <br />
          </Typography>
        </Box>
        <Box py={1}>
          <Typography
            variant="h3"
            sx={{
              marginBottom: '0px',
              lineHeight: '25px',
              fontWeight: 700,
            }}
            color="textSecondary"
          >
            Existing tokens
          </Typography>
        </Box>
        {isLoad && (
          <Box py={1}>
            <DataGridPro
              sx={{
                width: '100%',
                '& .MuiDataGrid-columnHeader': {
                  padding: 0,
                },
                '& .MuiDataGrid-cell': {
                  padding: 0,
                },
                '& .MuiDataGrid-columnSeparator': {
                  display: 'none',
                },
                '& .MuiDataGrid-cell:focus': {
                  outline: 'none',
                },
                '& .MuiDataGrid-row': {
                  cursor: 'pointer',
                  borderBottom: '1px solid',
                  borderColor: 'grey.200',
                },
                '& .MuiDataGrid-columnHeader:focus': {
                  outline: 'none',
                },
                border: 'none',
              }}
              autoHeight
              disableColumnResize={true}
              disableSelectionOnClick={true}
              showColumnRightBorder={false}
              disableColumnMenu={true}
              rowsPerPageOptions={[5, 10, 20]}
              pagination
              rows={apiTokens}
              columns={[
                {
                  field: 'name',
                  headerName: 'Name',
                  flex: 0.5,
                },
                {
                  field: 'token',
                  headerName: 'Token',
                  flex: 2,
                },
                {
                  field: 'id',
                  headerName: '',
                  renderCell: (params: GridRenderCellParams<string>) => {
                    return (
                      <IconButton
                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                          setAnchorEl(e.currentTarget);
                          setSelectedToken(params.value || '');
                        }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    );
                  },
                  sortable: false,
                },
              ]}
            />
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'center',
                horizontal: 'right',
              }}
            >
              <Typography
                paragraph
                style={{
                  padding: '10px',
                  marginBlock: '0',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  deleteTokenAction(selectedToken);
                  setAnchorEl(null);
                }}
              >
                DELETE
              </Typography>
            </Popover>
          </Box>
        )}
        {apiTokens.length === 0 && (
          <Box py={1}>
            <Typography>There are no existing tokens. You can create one below.</Typography>
          </Box>
        )}
        <Box py={1}>
          <Typography
            variant="h3"
            style={{
              marginBottom: '0px',
              lineHeight: '25px',
              fontWeight: 700,
            }}
            color="textSecondary"
          >
            Create a token
          </Typography>
        </Box>
        <Box py={1}>
          <Formik
            initialValues={{
              name: '',
            }}
            onSubmit={onSubmit}
            validationSchema={createTokenSchema}
          >
            {({ handleChange, handleBlur, touched, errors }) => {
              return (
                <Form>
                  <Grid container spacing={2}>
                    <Grid item md={6} sm={12} xs={12}>
                      <MuiFormikInput
                        name="name"
                        label="Name *"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        maxLength={MAX_API_TOKEN_NAME}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item md={6} sm={12}>
                      <Button
                        variant="contained"
                        style={{
                          marginRight: '20px',
                        }}
                        color="primary"
                        type="submit"
                      >
                        Create Token
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </ComponentLoading>
    </Box>
  );
};

export default ThirdPartyApplications;
