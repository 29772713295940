import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Grid, Typography, Button, Container } from '@mui/material';
import { IContactType } from 'types';

import { Form, Formik } from 'formik';
import { ComponentLoading, ButtonSubmit } from 'components';
import { ClassificationType } from 'pages/Internal/components';

import { useLazyQuery, useMutation } from '@apollo/client';
import { QUERY_TECHNICIAN_CLASSIFICATION_CATEGORIES, QUERY_TECHNICIAN_CLASSIFICATIONS, MUTATION_UPDATE_CONTACT_FOR_TECHNICIAN } from 'gql';

import { Field } from 'formik';
import { CheckboxWithLabel } from 'formik-mui';
import { IServiceCenterClassificationCategory } from 'types';
import { setAlert } from 'state';

interface ClassificationsTabProps {
  userData: IContactType;
  setSlideNum: (value: number) => void;
  setClickedBack: (value: boolean) => void;
  selectedClassifications: string[];
  setSelectedClassifications: (value: string[]) => void;
}

type CheckBoxLabelProps = {
  id: string;
  value: string;
  group: string;
};

const CheckBoxLabel: React.FC<CheckBoxLabelProps> = (props) => {
  return <Field type="checkbox" name="classifications" value={props.id} component={CheckboxWithLabel} Label={{ label: props.value }} />;
};

const ClassificationsTab: React.FC<ClassificationsTabProps> = (props) => {
  const { userData, setSlideNum, setClickedBack, selectedClassifications, setSelectedClassifications } = props;

  const [fetchCategories, { data: dataCategories, loading: loadingCategories }] = useLazyQuery(QUERY_TECHNICIAN_CLASSIFICATION_CATEGORIES);
  const [fetchClassifications, { data: dataClassifications, loading: loadingClassifications }] =
    useLazyQuery(QUERY_TECHNICIAN_CLASSIFICATIONS);

  const [updateTechnician, { data: dataTechnician, loading: loadingTechnician, error: errorTechnician }] = useMutation(
    MUTATION_UPDATE_CONTACT_FOR_TECHNICIAN,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (userData) {
      fetchCategories();
      fetchClassifications();
    }
  }, [fetchCategories, fetchClassifications, userData]);

  useEffect(() => {
    if (errorTechnician) {
      dispatch(setAlert('error', 'Unable to update technician information'));
    } else if (dataTechnician) {
      if (dataTechnician.updateContactForTechnician?.ok) {
        dispatch(setAlert('success', 'Updated your information'));
        setSlideNum(2);
      } else {
        dispatch(setAlert('error', dataTechnician.updateContactForTechnician?.error || 'Unable to update technician information'));
      }
    }
  }, [dataTechnician, errorTechnician, dispatch]);

  return (
    <Box p={4}>
      <ComponentLoading loading={loadingCategories || loadingClassifications || loadingTechnician}>
        <Box>
          <Formik
            enableReinitialize
            initialValues={{
              classifications: selectedClassifications,
            }}
            onSubmit={async (values) => {
              await updateTechnician({
                variables: {
                  ...userData,
                  classifications: values.classifications,
                  mobilePhone: userData.phoneMobile,
                },
              });
            }}
          >
            {({ isSubmitting, values }) => {
              return (
                <Form>
                  {!loadingCategories && !loadingClassifications && (
                    <Box>
                      <Grid
                        container
                        spacing={2}
                        sx={{
                          justifyContent: 'center',
                        }}
                      >
                        {!!dataCategories?.technicianClassificationCategories?.length &&
                          [...dataCategories.technicianClassificationCategories].map((category: IServiceCenterClassificationCategory) => {
                            const sortClassifications = dataClassifications?.technicianClassifications
                              ? [...dataClassifications.technicianClassifications]
                              : [];

                            const classifications = sortClassifications
                              .filter((x: ClassificationType) => x.category === category.name)
                              ?.map((classification: ClassificationType) => {
                                return (
                                  <CheckBoxLabel
                                    key={classification.id}
                                    id={classification.id}
                                    group={category.name}
                                    value={classification.name}
                                  />
                                );
                              });
                            const filterClassifications = classifications.filter((item: React.ReactNode | null) => item !== null);
                            if (filterClassifications.length) {
                              return (
                                <Grid item key={category.id} md={2}>
                                  <Box pb={1}>
                                    <Typography variant="h5">{category.name}</Typography>
                                  </Box>
                                  <Box
                                    role="group"
                                    aria-labelledby="checkbox-group"
                                    key={category.id}
                                    sx={{
                                      display: 'flex',
                                      flexFlow: 'column',
                                    }}
                                  >
                                    {filterClassifications}
                                  </Box>
                                </Grid>
                              );
                            } else {
                              return null;
                            }
                          })}
                      </Grid>
                    </Box>
                  )}
                  <Box py={2}>
                    <Container maxWidth="md" fixed>
                      <Grid container spacing={2}>
                        <Grid item md={6} xs={6}>
                          <ButtonSubmit text="Update" loading={isSubmitting || loadingTechnician} />
                        </Grid>
                        <Grid item md={6} xs={6}>
                          <Button
                            variant="outlined"
                            onClick={() => {
                              setSelectedClassifications([...values.classifications]);
                              setClickedBack(true);
                              setSlideNum(0);
                            }}
                            fullWidth
                            disabled={loadingTechnician}
                          >
                            Back
                          </Button>
                        </Grid>
                      </Grid>
                    </Container>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </ComponentLoading>
    </Box>
  );
};

export default ClassificationsTab;
