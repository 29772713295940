const ticketTypeArray = {
  maintenance: [
    {
      field: 'serviceAnnual',
      label: 'Annual Service',
      tooltip:
        'Choose this option to open a new ticket for advice during your aircraft’s annual inspection (e.g. to record the progress of your annual, document maintenance and inspection issues discovered and proactively develop plans of action for issue resolution).',
      urlType: 'service-annual',
    },
    {
      field: 'service',
      label: 'Maintenance',
      tooltip:
        'Choose this option to open a new ticket for advice regarding non-annual related maintenance (e.g. your ‘low voltage’ light is illuminated and you need help diagnosing why).',
      urlType: 'service',
    },
    {
      field: 'analysis',
      label: 'Engine Analysis',
      tooltip:
        'Choose this option to open a new ticket for advice regarding engine analysis information (e.g. sensor/probe readings from an engine analyzer, oil analysis readings, compression tests, etc.).',
      urlType: 'analysis',
    },
    {
      field: 'borescopeAnalysis',
      label: 'Borescope Image Analysis',
      tooltip: 'Choose this option to open a new ticket for advice regarding borescope analysis information',
      urlType: 'borescope-analysis',
    },
  ],
  support: [
    {
      field: 'uploadSupport',
      label: 'Upload Support (failed data uploads)',
      tooltip:
        'Choose this option to open a new ticket for a request for upload support relating to our web-site (e.g. trouble uploading data, or getting some kind of error ).',
      urlType: 'technical-support',
    },
    {
      field: 'techSupport',
      label: 'Technical Support (logging in, etc.)',
      tooltip:
        'Choose this option to open a new ticket for a request for technical support relating to our web-site (e.g. trouble logging in, or getting some kind of error ).',
      urlType: 'technical-support',
    },
    {
      field: 'accountSupport',
      label: 'Account Support (billing, etc.)',
      tooltip:
        'Choose this option to open a new ticket for request for support with your Savvy account (e.g. problems with a subscription plan, payments, etc.).',
      urlType: 'account-support',
    },
  ],
  prebuy: [
    {
      field: 'preliminaryLogbookReview',
      label: 'Free Buyer Assistance',
      tooltip:
        "Choose this option to open a new ticket requesting FREE assistance with the preparatory steps of buying an aircraft (e.g. help deciding what make/model to purchase, feedback on a shortlist of aircraft you're looking at, FREE preliminary review of the maintenance logs for a specific aircraft).",
      urlType: 'prebuy-inquiry',
    },
    {
      field: 'prebuy',
      label: 'Prebuy Examination',
      tooltip:
        'Choose this option to open a new ticket for a Savvy Prebuy Examination (e.g. to schedule an aircraft you are considering purchasing for a Prebuy Examination using Savvy’s Prebuy Concierge service).',
      urlType: 'prebuy',
    },
  ],
  breakdown: [
    {
      field: 'breakdown',
      label: 'Breakdown Assistance',
      tooltip:
        'Choose this option if you are broken down more than 50 miles from your home base and need to facilitate a rapid resolution to get you flying again.',
      urlType: 'breakdown',
    },
  ],
};

export const ticketTypeArrayByName = [
  {
    field: 'serviceAnnual',
    label: 'Annual Service',
    urlType: 'service-annual',
  },
  {
    field: 'analysis',
    label: 'Engine Analysis',
    urlType: 'analysis',
  },
  {
    field: 'techSupport',
    label: 'Technical Support',
    urlType: 'technical-support',
  },
  {
    field: 'accountSupport',
    label: 'Account Support',
    urlType: 'account-support',
  },
  {
    field: 'preliminaryLogbookReview',
    label: 'Logbook Preview',
    urlType: 'prebuy-inquiry',
  },
  {
    field: 'prebuy',
    label: 'Prebuy Inspection',
    urlType: 'prebuy',
  },
  {
    field: 'breakdown',
    label: 'Breakdown Assistance',
    urlType: 'breakdown',
  },
  {
    field: 'service',
    label: 'Maintenance',
    urlType: 'service',
  },
  {
    field: 'borescopeAnalysis',
    label: 'Borescope Image Analysis',
    urlType: 'borescope-analysis',
  },
];

export default ticketTypeArray;
