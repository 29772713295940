import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Dialog, Box, DialogTitle, DialogContent, TextField, DialogActions, Grid, Button, MenuItem } from '@mui/material';
import { MUTATION_DELETE_LOGBOOK } from 'gql';
import { useMutation } from '@apollo/client';
import { setAlert } from 'state';

import { ComponentLoading } from 'components';
import { ILogbook } from 'types';

interface DeleteLogbookDialogProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  maintenanceLogbook: ILogbook;
  logbooks: ILogbook[];
}

const DeleteLogbookDialog: React.FC<DeleteLogbookDialogProps> = (props) => {
  const { open, setOpen, maintenanceLogbook, logbooks } = props;

  const [deleteLogbook, { data: dataDeleteLogbook, error: errorDeleteLogbook, loading: loadingDeleteLogbook }] =
    useMutation(MUTATION_DELETE_LOGBOOK);
  const dispatch = useDispatch();

  const [transferEntriesToLogbookId, setTransferEntriesToLogbookId] = useState('');
  const [transferEntriesToLogbookError, setTransferEntriesToLogbookError] = useState('');

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async () => {
    if (!!maintenanceLogbook.maintenanceEntries.length && !transferEntriesToLogbookId) {
      setTransferEntriesToLogbookError('Please select logbook to transfer entries.');
      return;
    }

    await deleteLogbook({
      variables: {
        maintenanceLogbookId: maintenanceLogbook.id,
        transferEntriesToLogbookId,
      },
    });
  };

  useEffect(() => {
    if (errorDeleteLogbook) {
      dispatch(setAlert('error', 'Unable to delete logbook'));
    } else if (dataDeleteLogbook) {
      if (dataDeleteLogbook?.deleteMaintenanceLogbook?.ok) {
        dispatch(setAlert('success', 'Deleted logbook.'));
        setOpen(false);
      } else {
        dispatch(setAlert('error', dataDeleteLogbook?.deleteMaintenanceLogbook?.error || 'Unable to delete logbook'));
      }
    }
  }, [dataDeleteLogbook, errorDeleteLogbook, setOpen, dispatch]);

  useEffect(() => {
    if (open) {
      setTransferEntriesToLogbookId('');
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={handleClose}
      PaperProps={{
        sx: {
          maxWidth: '400px',
        },
      }}
    >
      <DialogTitle
        sx={{
          textAlign: 'center',
          fontSize: '24px',
          fontWeight: 700,
        }}
      >
        Delete Logbook
      </DialogTitle>
      <DialogContent>
        <ComponentLoading loading={loadingDeleteLogbook}>
          {!!maintenanceLogbook.maintenanceEntries.length && (
            <Box py={1}>
              <TextField
                name="logbook"
                label="Logbook to transfer entries *"
                value={transferEntriesToLogbookId}
                onChange={(e) => {
                  setTransferEntriesToLogbookId(e.target.value);
                  setTransferEntriesToLogbookError('');
                }}
                error={!!transferEntriesToLogbookError}
                helperText={transferEntriesToLogbookError}
                FormHelperTextProps={{
                  sx: {
                    ml: 0,
                  },
                }}
                fullWidth
                select
              >
                {logbooks.map((logbook: ILogbook, key: number) => {
                  if (logbook.id === maintenanceLogbook.id) {
                    return null;
                  }
                  return (
                    <MenuItem value={logbook.id} key={key}>
                      {logbook.name}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Box>
          )}
        </ComponentLoading>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button variant="outlined" onClick={handleClose} disabled={loadingDeleteLogbook} fullWidth>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="contained" onClick={onSubmit} disabled={loadingDeleteLogbook} fullWidth>
              Delete
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteLogbookDialog;
