export const ANALYSIS_TAG = 'analysis';
export const BREAKDOWN_TAG = 'breakdown';
export const TECHNICAL_SUPPORT_TAG = 'technical-support';
export const ACCOUNT_SUPPORT_TAG = 'account-support';
export const SERVICE_ANNUAL_TAG = 'service-annual';
export const SERVICE_TAG = 'service';
export const PARSE_FAILURE_TAG = 'parse-failure';
export const FEVA_TAG = 'feva';
export const SERVICE_SIGNUP_TAG = 'service-signup';
export const PREBUY_TAG = 'prebuy';
export const PREBUY_INQUIRY_TAG = 'prebuy-inquiry';
