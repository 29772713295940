import React, { useState } from 'react';
import { FormHelperText } from '@mui/material';
import { Field } from 'formik';
import { TextField } from 'formik-mui';

interface FormikInputForReportInterface {
  name: string;
  label: string;
  errors: any;
  touched: any;
  onChange: (event: any) => void;
  onBlur: (event: any) => void;
  maxLength: number;
  disabled?: boolean;
  multiline?: boolean;
  rows?: number;
  placeholder?: string;
}

// Input UI
const FormikInputForReport: React.FC<FormikInputForReportInterface> = (props) => {
  const { name, label, onBlur, onChange, errors, touched, maxLength, disabled = false, ...rest } = props;

  const [warning, setWarning] = useState(`Characters: 0/${maxLength}`);
  const [warningColor, setWarningColor] = useState('text.primary');
  const [showWarning, setShowWarning] = useState(false);
  const closeLength = maxLength - 10;

  const handleWarning = (length: number) => {
    setWarning(`Characters: ${length}/${maxLength}`);
    if (length >= closeLength && length <= maxLength) {
      setWarningColor('warning.main');
    } else if (length > maxLength) {
      setWarningColor('error.main');
    } else {
      setWarningColor('text.primary');
    }
  };

  return (
    <React.Fragment>
      <Field
        name={name}
        label={label}
        component={TextField}
        sx={{
          mb: warning && showWarning ? 0 : '15px',
        }}
        onBlur={(e: any) => {
          setShowWarning(false);
          onBlur(e);
        }}
        onChange={(e: any) => {
          onChange(e);
          const length = e.target.value.length;
          handleWarning(length);
        }}
        errors={errors}
        touched={touched}
        variant="outlined"
        fullWidth={true}
        FormHelperTextProps={{
          sx: {
            color: 'red',
            ml: '0',
          },
        }}
        inputProps={{
          maxLength: maxLength,
          onFocus: (e: any) => {
            handleWarning(e.target.value.length);
            setShowWarning(true);
          },
        }}
        disabled={disabled}
        {...rest}
      />
      {!!warning && showWarning && !errors[name] && (
        <FormHelperText
          sx={{
            color: warningColor,
            ml: 0,
            textAlign: 'right',
            mt: 0,
            fontSize: '12px',
            lineHeight: '15px',
          }}
        >
          {warning}
        </FormHelperText>
      )}
    </React.Fragment>
  );
};

export default FormikInputForReport;
