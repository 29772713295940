import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Box, Slide, Hidden } from '@mui/material';

import {
  QUERY_GET_AIRCRAFT_TICKETS,
  QUERY_SAVVY_CONFIG_FOR_TICKET,
  QUERY_SAVVY_DETAILT_TICKET,
  MUTATION_CLOSE_TICKET,
  MUTATION_REOPEN_TICKET,
  MUTATION_MARK_TICKET_AS_RECENTLY_VIEWED,
} from 'gql';
import { useLazyQuery, useMutation } from '@apollo/client';
import { setAlert } from 'state';

import { ComponentLoading } from 'components';
import { TicketLoading } from 'pages/Tickets/components';
import { CollapsedTickets } from 'pages/ContactManagement/components/TicketsComponents';

import { TicketInfo, TicketContents } from 'pages/SavvyTickets/components';

import { PATH_SAVVY_AIRCRAFT } from 'const';
import { useTimer } from 'hooks';

interface TicketsProps {
  contactId: string;
  isLoad: boolean;
  aircraftId: string;
}

const FETCH_INTERVAL = 60;
const LAST_VIEWED_TIMEOUT = 300;

const Tickets: React.FC<TicketsProps> = (props) => {
  const { contactId, isLoad, aircraftId } = props;

  const [fetchTickets, { data: dataTickets, loading: loadingTickets }] = useLazyQuery(QUERY_GET_AIRCRAFT_TICKETS);
  const [fetchTicketsTimer] = useLazyQuery(QUERY_GET_AIRCRAFT_TICKETS, {
    fetchPolicy: 'network-only',
  });
  const [fetchSavvy, { data: dataSavvy, loading: loadingSavvy }] = useLazyQuery(QUERY_SAVVY_CONFIG_FOR_TICKET);
  const [fetchDetailTicket, { data: dataDetailTicket, loading: loadingDetailTicket, error: errorDetailTicket }] =
    useLazyQuery(QUERY_SAVVY_DETAILT_TICKET);
  const [fetchDetailTicketTimer] = useLazyQuery(QUERY_SAVVY_DETAILT_TICKET, {
    fetchPolicy: 'network-only',
  });

  const history = useHistory();
  const dispatch = useDispatch();

  const { id: ticketId } = useParams<{
    id: string;
  }>();

  const [cannedResponseText, setCannedResponseText] = useState('');

  const [closeTicketMutation, { data: dataCloseTicket, loading: loadingCloseTicket, error: errorCloseTicket }] =
    useMutation(MUTATION_CLOSE_TICKET);
  const [reopenTicketMutation, { data: dataReopenTicket, loading: loadingReopenTicket, error: errorReopenTicket }] =
    useMutation(MUTATION_REOPEN_TICKET);
  const [setLastViewed] = useMutation(MUTATION_MARK_TICKET_AS_RECENTLY_VIEWED);

  const [slideNum, setSlideNum] = useState(1);

  const clickTicket = (id: string) => {
    history.push(`${PATH_SAVVY_AIRCRAFT}/${aircraftId}/tickets/${id}`);
  };

  const closeTicket = async (ticketId: number, close: boolean) => {
    if (close)
      await closeTicketMutation({
        variables: {
          ticketId,
        },
      });
    else
      await reopenTicketMutation({
        variables: {
          ticketId,
        },
      });
  };

  const fetchTicketAction = () => {
    if (contactId && aircraftId)
      fetchTicketsTimer({
        variables: {
          contactId: contactId,
          aircraftId: parseInt(aircraftId),
        },
        fetchPolicy: 'network-only',
      });
  };

  const fetchDetailTicketAction = () => {
    if (ticketId) {
      fetchDetailTicketTimer({
        variables: {
          ticketId: parseInt(ticketId),
        },
        fetchPolicy: 'network-only',
      });
    }
  };

  const LastViewedAction = async () => {
    if (ticketId) {
      await setLastViewed({
        variables: {
          ticketId: parseInt(ticketId),
        },
      });
    }
  };

  const {
    create: createTimer,
    created: createdTimer,
    clear: clearTimer,
  } = useTimer({
    timeoutValue: FETCH_INTERVAL,
    timerAction: fetchTicketAction,
  });

  const { create: createDetailTimer, clear: clearDetailTimer } = useTimer({
    timeoutValue: FETCH_INTERVAL,
    timerAction: fetchDetailTicketAction,
  });

  const { create: createLastViewedTimer, clear: clearLastViewedTimer } = useTimer({
    timeoutValue: LAST_VIEWED_TIMEOUT,
    timerAction: LastViewedAction,
  });

  useEffect(() => {
    if (!loadingTickets && dataTickets) {
      if (!createdTimer) {
        createTimer();
      }
    }
  }, [dataTickets, loadingTickets, createTimer, createdTimer]);

  useEffect(() => {
    if (!isLoad) {
      clearTimer();
      clearDetailTimer();
      clearLastViewedTimer();
    }
  }, [isLoad, clearTimer, clearDetailTimer, clearLastViewedTimer]);

  useEffect(() => {
    if (ticketId && isLoad) {
      clearDetailTimer();
      createDetailTimer();
      clearLastViewedTimer();
      createLastViewedTimer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketId, isLoad]);

  useEffect(() => {
    if (contactId && isLoad && !dataTickets && aircraftId) {
      fetchTickets({
        variables: {
          contactId: contactId,
          aircraftId: parseInt(aircraftId),
        },
      });
    }

    if (contactId && isLoad) {
      fetchSavvy();
    }
  }, [fetchTickets, dataTickets, isLoad, contactId, fetchSavvy, aircraftId]);

  useEffect(() => {
    if (ticketId) {
      fetchDetailTicket({
        variables: {
          ticketId: parseInt(ticketId),
        },
      });
    }
  }, [ticketId, fetchDetailTicket]);

  useEffect(() => {
    if (errorDetailTicket) {
      dispatch(setAlert('error', 'Unable to load selected ticket'));
    }
  }, [errorDetailTicket, dispatch]);

  useEffect(() => {
    if (errorCloseTicket) {
      dispatch(setAlert('error', 'Unable to close selected ticket.'));
    } else if (dataCloseTicket) {
      if (dataCloseTicket.closeTicket?.ok) {
        dispatch(setAlert('success', 'Closed selected ticket.'));
      } else {
        dispatch(setAlert('error', dataCloseTicket.closeTicket?.error || 'Unable to close selected ticket.'));
      }
    }
  }, [dataCloseTicket, errorCloseTicket, dispatch]);

  useEffect(() => {
    if (errorReopenTicket) {
      dispatch(setAlert('error', 'Unable to reopen selected ticket.'));
    } else {
      if (dataReopenTicket && dataReopenTicket.reopenTicket?.ok) {
        dispatch(setAlert('success', 'Re-opened selected ticket.'));
      }
    }
  }, [dataReopenTicket, errorReopenTicket, dispatch]);

  useEffect(() => {
    if (!loadingDetailTicket && ticketId && dataDetailTicket) {
      setSlideNum(2);
    }
  }, [ticketId, dataDetailTicket, loadingDetailTicket]);

  return (
    <Box>
      <ComponentLoading loading={loadingTickets}>
        <Hidden xlDown>
          <Box
            sx={{
              minHeight: 'calc(100% - 75px)',
              display: 'flex',
            }}
          >
            <Box
              sx={{
                minHeight: '80%',
                display: 'flex',
                py: 2,
                flexGrow: 1,
              }}
            >
              <CollapsedTickets
                tickets={dataTickets?.savvy?.contacts[0]?.tickets ? dataTickets?.savvy?.contacts[0]?.tickets : []}
                clickTicket={clickTicket}
              />
              <Box
                sx={{
                  display: 'flex',
                  flexGrow: 1,
                  position: 'relative',
                  zIndex: 0,
                }}
              >
                <TicketLoading loading={loadingDetailTicket || loadingSavvy || loadingCloseTicket || loadingReopenTicket}>
                  {
                    <TicketContents
                      ticket={ticketId && dataDetailTicket?.me?.tickets[0]}
                      cannedResponseText={cannedResponseText}
                      setCannedResponseText={setCannedResponseText}
                      allowedTimesheetCategories={
                        dataSavvy?.savvy?.allowedTimesheetCategories ? dataSavvy.savvy.allowedTimesheetCategories : []
                      }
                      lastTimesheetCategory={dataSavvy?.savvy?.lastTimesheetCategory ? dataSavvy.savvy.lastTimesheetCategory : undefined}
                      showTimesheetForm={dataSavvy?.savvy?.showTimesheetForm}
                      savvyId={dataSavvy?.savvy?.me?.id}
                    />
                  }
                  {ticketId && (
                    <TicketInfo
                      ticket={dataDetailTicket?.me?.tickets[0]}
                      closeTicket={closeTicket}
                      cannedResponses={dataSavvy?.savvy?.cannedResponses}
                      setCannedResponseText={setCannedResponseText}
                      allowedAssignees={dataSavvy?.savvy?.allowedAssignees ? dataSavvy.savvy.allowedAssignees : []}
                    />
                  )}
                </TicketLoading>
              </Box>
            </Box>
          </Box>
        </Hidden>
        <Hidden xlUp>
          <ComponentLoading loading={loadingDetailTicket || loadingSavvy || loadingCloseTicket || loadingReopenTicket}>
            <Box
              sx={{
                overflowX: 'hidden',
              }}
            >
              <Slide
                direction="left"
                in={slideNum === 1}
                unmountOnExit
                timeout={{
                  exit: 0,
                  enter: 300,
                }}
              >
                <Box>
                  <CollapsedTickets
                    tickets={dataTickets?.savvy?.contacts[0]?.tickets ? dataTickets?.savvy?.contacts[0]?.tickets : []}
                    clickTicket={clickTicket}
                  />
                </Box>
              </Slide>
              {ticketId && (
                <Slide
                  direction="left"
                  in={slideNum === 2}
                  mountOnEnter
                  unmountOnExit
                  timeout={{
                    exit: 0,
                    enter: 300,
                  }}
                >
                  <Box>
                    <TicketContents
                      ticket={ticketId && dataDetailTicket?.me?.tickets[0]}
                      setSlideNum={setSlideNum}
                      closeTicket={closeTicket}
                      cannedResponseText={cannedResponseText}
                      cannedResponses={dataTickets?.savvy?.cannedResponses}
                      setCannedResponseText={setCannedResponseText}
                      allowedAssignees={dataTickets?.savvy?.allowedAssignees ? dataTickets.savvy.allowedAssignees : []}
                      allowedTimesheetCategories={
                        dataTickets?.savvy?.allowedTimesheetCategories ? dataTickets.savvy.allowedTimesheetCategories : []
                      }
                      lastTimesheetCategory={
                        dataTickets?.savvy?.lastTimesheetCategory ? dataTickets.savvy.lastTimesheetCategory : undefined
                      }
                      showTimesheetForm={dataTickets?.savvy?.showTimesheetForm}
                      savvyId={dataTickets?.savvy?.me?.id}
                    />
                  </Box>
                </Slide>
              )}
            </Box>
          </ComponentLoading>
        </Hidden>
      </ComponentLoading>
    </Box>
  );
};

export default Tickets;
