import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Typography, Link } from '@mui/material';
import { ComponentLoading } from 'components';

import { QUERY_SAVVY_SERVICE_CENTER_SEARCH } from 'gql';
import { useLazyQuery } from '@apollo/client';
import { SAVVY_SEARCH_LIMIT, PATH_INTERNAL_SERVICE_CENTER, PATH_SAVVY_SEARCH_SERVICE_CENTER } from 'const';

import { IServiceCenter } from 'types';
import { ctrlCmdClick } from 'helpers';

interface ServiceCenterSearchProps {
  searchQuery: string;
}

const ServiceCenterSearch: React.FC<ServiceCenterSearchProps> = (props) => {
  const { searchQuery } = props;

  const history = useHistory();

  const [fetchSavvyServiceCenterSearch, { data: dataServiceCenter, loading: loadingServiceCenter }] =
    useLazyQuery(QUERY_SAVVY_SERVICE_CENTER_SEARCH);

  const [serviceCenterList, setServiceCenterList] = useState<IServiceCenter[]>([]);

  const [count, setCount] = useState(0);

  const loadMore = () => {
    history.push(`${PATH_SAVVY_SEARCH_SERVICE_CENTER}?query=${searchQuery}`);
  };

  useEffect(() => {
    if (!searchQuery || searchQuery.length < 2) {
      return;
    }

    setServiceCenterList([]);

    const timeout: NodeJS.Timeout = setTimeout(() => {
      fetchSavvyServiceCenterSearch({
        variables: {
          search: searchQuery,
          limit: SAVVY_SEARCH_LIMIT,
          offset: 0,
        },
      });
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [searchQuery, fetchSavvyServiceCenterSearch]);

  useEffect(() => {
    if (dataServiceCenter?.savvy?.search?.serviceCenters?.results?.length) {
      setServiceCenterList((serviceCenterList: IServiceCenter[]) => {
        const newArray: IServiceCenter[] = serviceCenterList.concat(dataServiceCenter.savvy?.search?.serviceCenters?.results);
        return [...newArray];
      });
    }

    if (dataServiceCenter?.savvy?.search?.serviceCenters) {
      setCount(dataServiceCenter.savvy?.search?.serviceCenters?.count);
    }
  }, [dataServiceCenter]);

  return (
    <Box>
      <ComponentLoading loading={loadingServiceCenter}>
        <Box>
          {!!searchQuery && (
            <Typography
              variant="h5"
              sx={{
                fontWeight: 'bold',
                py: 1,
              }}
            >
              ServiceCenters {count ? `(${count})` : ''}
            </Typography>
          )}
          <Box pl={4}>
            {serviceCenterList?.map((serviceCenter: IServiceCenter, key: number) => {
              return (
                <Box
                  key={key}
                  py={1}
                  sx={{
                    cursor: 'pointer',
                  }}
                  onClick={(event: any) => {
                    ctrlCmdClick(event, PATH_INTERNAL_SERVICE_CENTER.replace(':id', serviceCenter.id), history);
                  }}
                >
                  <Typography
                    sx={{
                      textDecoration: serviceCenter.isActive ? 'none' : 'line-through',
                    }}
                  >
                    {`${serviceCenter.company}${serviceCenter.state || serviceCenter.country ? ' - ' : ''} (${
                      serviceCenter.state ? serviceCenter.state : serviceCenter.country ? serviceCenter.country : ''
                    })`}
                  </Typography>
                </Box>
              );
            })}
            {!serviceCenterList?.length && !!searchQuery && !loadingServiceCenter && <Box py={1}>No matched service centers</Box>}
          </Box>
          {count > serviceCenterList.length && (
            <Link
              href="#"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                loadMore();
              }}
            >
              Show More
            </Link>
          )}
        </Box>
      </ComponentLoading>
    </Box>
  );
};

export default ServiceCenterSearch;
