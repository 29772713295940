import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';

import { useLazyQuery, useQuery } from '@apollo/client';
import {
  QUERY_FLIGHT_DETAIL_BY_ID,
  QUERY_SAVVY_ANOMALY,
  QUERY_FLIGHT_CHART_SETTINGS,
  QUERY_PINNED_COMMENTS_BY_FLIGHT,
  QUERY_ME_UNIT_CONVERSIONS,
  QUERY_FLIGHT_CHART_SCALE_SETTINGS,
} from 'gql';
import { LayoutDashboard, PageLoading } from 'components';
import { setAlert } from 'state';

import { FlightChart, FlightInfo } from './components';
import { ModeManager } from './components/chartComponents';
import { secondsToHms } from 'helpers';

import { X_MODE, TO_MODE } from 'const';

const modeManager = new ModeManager();

const FlightView: React.FC = () => {
  const [fetchFlightData, { data: dataFlight, loading: loadingFlight, error: errorFlight }] = useLazyQuery(QUERY_FLIGHT_DETAIL_BY_ID, {
    fetchPolicy: 'no-cache',
  });

  const [fetchScaleSettings, { data: dataScaleSettings, loading: loadingScaleSettings, error: errorScaleSettings }] = useLazyQuery(
    QUERY_FLIGHT_CHART_SCALE_SETTINGS,
    {
      fetchPolicy: 'cache-and-network',
    },
  );

  const [fetchAnomalyData, { data: dataFetchAnomalyData, loading: loadingAnomalyData }] = useLazyQuery(QUERY_SAVVY_ANOMALY);

  const [fetchSettings, { data: dataFetchSettings, loading: loadingFetchSettings }] = useLazyQuery(QUERY_FLIGHT_CHART_SETTINGS);

  const [fetchComments, { data: dataComments, loading: loadingComments }] = useLazyQuery(QUERY_PINNED_COMMENTS_BY_FLIGHT);

  const { data: dataConversions, loading: loadingConversions } = useQuery(QUERY_ME_UNIT_CONVERSIONS);

  const { id } = useParams<{
    id: string;
  }>();

  const { isSavvy } = useSelector((state: any) => state.auth);

  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      fetchFlightData({
        variables: {
          id: parseInt(id),
        },
      });

      fetchScaleSettings({
        variables: {
          id: parseInt(id),
        },
      });

      fetchSettings();

      modeManager.init();
    }
  }, [id]);

  useEffect(() => {
    if (errorFlight) {
      dispatch(setAlert('error', 'Unable to load flight data.'));
    }
  }, [errorFlight, dispatch, dataFlight]);

  useEffect(() => {
    if (errorScaleSettings) {
      dispatch(setAlert('error', 'Unable to load flight chart scale.'));
    }
  }, [errorScaleSettings, dispatch]);

  useEffect(() => {
    if (isSavvy && !dataFetchAnomalyData) {
      fetchAnomalyData();
    }
  }, [isSavvy, dataFetchAnomalyData, fetchAnomalyData]);

  useEffect(() => {
    if (dataFlight?.me?.flight[0]) {
      const flight = dataFlight?.me?.flight[0];
      let str = 'Flight: ';
      str +=
        (flight.aircraft ? `${flight.aircraft.registration} ` : '') +
        `${flight.date.toString().substring(0, 10) + ' ' + flight.date.toString().substring(11, 16)}, ${secondsToHms(flight.duration)}`;
      document.title = str;
    }
    return () => {
      document.title = 'SavvyAviation';
    };
  }, [dataFlight]);

  useEffect(() => {
    if (isSavvy && id) {
      fetchComments({
        variables: {
          id: parseInt(id),
        },
      });
    }
  }, [isSavvy, id, fetchComments]);

  const [diffData, setDiffData] = useState<any>({});

  const [analysisMode, setAnalysisMode] = useState('');

  const [presetMode, setPresetMode] = useState('');

  const [chartNum, setChartNum] = useState(2);
  const [showInfo, setShowInfo] = useState(true);

  useEffect(() => {
    if (id) {
      setAnalysisMode('');
      setPresetMode('');
    }
  }, [id]);

  useEffect(() => {
    if (dataFetchSettings && dataFetchSettings.me) {
      setShowInfo(dataFetchSettings.me?.settings?.isFlightPanelVisible);
      if (dataFetchSettings.me?.settings?.chartsNumber) {
        setChartNum(parseInt(dataFetchSettings.me?.settings?.chartsNumber));
      }
    }
  }, [dataFetchSettings]);

  useEffect(() => {
    if (presetMode === X_MODE) {
      setChartNum(4);
    }

    if (presetMode === TO_MODE) {
      setChartNum(3);
    }
  }, [presetMode]);

  return (
    <LayoutDashboard backgroundColor="background.default">
      {(loadingFlight || loadingAnomalyData || loadingFetchSettings || loadingComments || loadingConversions || loadingScaleSettings) && (
        <PageLoading />
      )}
      <Box
        flexGrow={1}
        width="100%"
        sx={{
          mb: 1,
        }}
      >
        <Box
          sx={{
            minHeight: {
              lg: 'calc(100% - 75px)',
              xs: 'inherit',
            },
            display: {
              lg: 'flex',
              xs: 'block',
            },
            px: {
              lg: 0,
              xs: 1,
            },
            overflowX: 'hidden',
          }}
        >
          {dataFlight && dataFlight.me?.flight[0] && (
            <FlightChart
              flightData={dataFlight.me?.flight[0]}
              modeManager={modeManager}
              setDiffData={setDiffData}
              analysisMode={analysisMode}
              presetMode={presetMode}
              setPresetMode={setPresetMode}
              chartNum={chartNum}
              showInfo={showInfo}
              setShowInfo={setShowInfo}
              settings={dataFetchSettings?.me?.settings}
              conversions={dataConversions?.me?.conversions ? dataConversions?.me?.conversions : []}
              scaleSettings={dataScaleSettings?.me?.flight[0]?.aircraft?.settings || []}
            />
          )}
          {dataFlight && dataFlight.me?.flight[0] && (
            <FlightInfo
              flightData={dataFlight.me?.flight[0]}
              modeManager={modeManager}
              diffData={diffData}
              analysisMode={analysisMode}
              setAnalysisMode={setAnalysisMode}
              setPresetMode={setPresetMode}
              presetMode={presetMode}
              chartNum={chartNum}
              setChartNum={setChartNum}
              anomalyTypes={dataFetchAnomalyData?.savvy?.anomalyTypes}
              showInfo={showInfo}
              aircraftComments={dataComments?.me?.flight[0]?.aircraft?.comments}
              contactComments={dataComments?.me?.flight[0]?.aircraft?.owner?.comments}
              settings={dataFetchSettings?.me?.settings}
              aircraftSettings={dataScaleSettings?.me?.flight[0]?.aircraft?.settings || []}
            />
          )}
        </Box>
      </Box>
    </LayoutDashboard>
  );
};

export default FlightView;
