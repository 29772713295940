import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Dialog, Box, DialogTitle, DialogContent, TextField, DialogActions, Grid, Button, MenuItem } from '@mui/material';
import { QUERY_FLIGHT_AIRCRAFT_RECENT_SERIES, MUTATION_CREATE_AIRCRAFT_CHART_SCALE_SETTINGS } from 'gql';
import { useMutation, useLazyQuery } from '@apollo/client';
import { setAlert } from 'state';
import { IFlightData } from 'types';

import { ComponentLoading } from 'components';
import { RAW_SERIES_NAMES } from 'const';

interface AddScaleDialogProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  flightData: IFlightData;
}

const AddScaleDialog: React.FC<AddScaleDialogProps> = (props) => {
  const { open, setOpen, flightData } = props;

  const [fetchRecentSeries, { data: dataSeries, loading: loadingSeries, error: errorSeries }] =
    useLazyQuery(QUERY_FLIGHT_AIRCRAFT_RECENT_SERIES);
  const [createAircraftSetting, { data: dataSetting, loading: loadingSetting, error: errorSetting }] = useMutation(
    MUTATION_CREATE_AIRCRAFT_CHART_SCALE_SETTINGS,
  );
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpen(false);
  };

  const [seriesName, setSeriesName] = useState('');
  const [seriesNameError, setSeriesNameError] = useState('');

  const [scaleMax, setScaleMax] = useState('');
  const [scaleMaxError, setScaleMaxError] = useState('');

  const [scaleMin, setScaleMin] = useState('');
  const [scaleMinError, setScaleMinError] = useState('');

  const onSubmit = async () => {
    if (!seriesName) {
      setSeriesNameError('Required!');
      return;
    }

    if (flightData.aircraft?.settings?.length) {
      for (const setting of flightData.aircraft.settings) {
        if (setting.seriesName === seriesName) {
          setSeriesNameError('Duplicated series name. Please edit existing settings.');
          return;
        }
      }
    }

    if (!scaleMax && !scaleMin) {
      setScaleMinError('Please select one of ScaleMin or ScaleMax.');
      return;
    }

    await createAircraftSetting({
      variables: {
        aircraftId: flightData.aircraft.id,
        scaleMax: scaleMax ? parseFloat(scaleMax) : null,
        scaleMin: scaleMin ? parseFloat(scaleMin) : null,
        seriesName,
      },
    });
  };

  useEffect(() => {
    if (open && flightData) {
      fetchRecentSeries({
        variables: {
          id: parseInt(flightData.id),
          rawSeriesNames: RAW_SERIES_NAMES,
        },
      });
    }
  }, [open, flightData, fetchRecentSeries]);

  useEffect(() => {
    if (errorSeries) {
      dispatch(setAlert('error', 'Unable to load recent flight series'));
    }
  }, [errorSeries, dispatch]);

  useEffect(() => {
    if (errorSetting) {
      dispatch(setAlert('error', 'Unable to create series Y scale'));
    } else if (dataSetting) {
      if (dataSetting.createAircraftChartScaleSettings?.ok) {
        dispatch(setAlert('success', 'Added new series Y scale'));
        setOpen(false);
      } else {
        dispatch(setAlert('error', dataSetting.createAircraftChartScaleSettings?.error || 'Unable to create series Y scale'));
      }
    }
  }, [errorSetting, dataSetting, dispatch, setOpen]);

  useEffect(() => {
    if (open) {
      setSeriesName('');
      setSeriesNameError('');

      setScaleMin('');
      setScaleMinError('');

      setScaleMax('');
      setScaleMaxError('');
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={handleClose}
      PaperProps={{
        sx: {
          maxWidth: '400px',
        },
      }}
    >
      <DialogTitle
        sx={{
          textAlign: 'center',
          fontSize: '24px',
          fontWeight: 700,
        }}
      >
        Add series Y scale
      </DialogTitle>
      <DialogContent>
        <ComponentLoading loading={loadingSeries || loadingSetting}>
          <Box py={1}>
            <Box py={1}>
              {dataSeries?.me?.flight[0]?.aircraft && (
                <TextField
                  select
                  name="seriesName"
                  label="Series Name *"
                  value={seriesName}
                  onChange={(e: any) => {
                    setSeriesName(e.target.value);
                    setSeriesNameError('');
                  }}
                  error={!!seriesNameError}
                  helperText={seriesNameError ? seriesNameError : ''}
                  FormHelperTextProps={{
                    sx: {
                      ml: 0,
                    },
                  }}
                  sx={{
                    mb: 0,
                  }}
                  fullWidth
                >
                  {dataSeries?.me?.flight[0]?.aircraft?.recentFlightSeries?.map((series: string, key: number) => {
                    return (
                      <MenuItem value={series} key={key}>
                        {series}
                      </MenuItem>
                    );
                  })}
                </TextField>
              )}
            </Box>
            <Box py={1}>
              <TextField
                type="number"
                name="scaleMin"
                label="Scale Min *"
                value={scaleMin}
                onChange={(e: any) => {
                  setScaleMin(e.target.value);
                  setScaleMinError('');
                  setScaleMaxError('');
                }}
                error={!!scaleMinError}
                helperText={scaleMinError ? scaleMinError : ''}
                FormHelperTextProps={{
                  sx: {
                    ml: 0,
                  },
                }}
                sx={{
                  mb: 0,
                }}
                fullWidth
              />
            </Box>
            <Box py={1}>
              <TextField
                type="number"
                name="scaleMax"
                label="Scale Max *"
                value={scaleMax}
                onChange={(e: any) => {
                  setScaleMax(e.target.value);
                  setScaleMinError('');
                  setScaleMaxError('');
                }}
                error={!!scaleMaxError}
                helperText={scaleMaxError ? scaleMaxError : ''}
                FormHelperTextProps={{
                  sx: {
                    ml: 0,
                  },
                }}
                sx={{
                  mb: 0,
                }}
                fullWidth
              />
            </Box>
          </Box>
        </ComponentLoading>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button variant="outlined" onClick={handleClose} disabled={loadingSeries || loadingSetting} fullWidth>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="contained" onClick={onSubmit} disabled={loadingSeries || loadingSetting} fullWidth>
              Submit
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default AddScaleDialog;
