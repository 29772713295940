import React from 'react';
import { Box } from '@mui/material';

import { ChangeEndDate, CancelRenewal, Terminate, Transfer, UncancelRenewal, CompletePurchase } from './ActionComponents';

import { ISubscriptionDetail } from 'types';

interface SubscriptionActionsProps {
  subscription: ISubscriptionDetail;
  contactId: string;
}

const SubscriptionActions: React.FC<SubscriptionActionsProps> = (props) => {
  const { subscription, contactId } = props;

  return (
    <Box>
      <Box mb={2}>
        <ChangeEndDate subscription={subscription} />
      </Box>
      {!subscription.cancelDate && (
        <Box mb={2}>
          <CancelRenewal subscription={subscription} contactId={contactId} />
        </Box>
      )}
      {!!subscription.cancelDate && (
        <Box mb={2}>
          <UncancelRenewal subscription={subscription} />
        </Box>
      )}
      {!subscription.terminationCode && (
        <Box mb={2}>
          <Terminate subscription={subscription} contactId={contactId} />
        </Box>
      )}
      {subscription.status === 'Active' && (
        <Box mb={2}>
          <Transfer subscription={subscription} contactId={contactId} />
        </Box>
      )}
      <Box mb={2}>
        <CompletePurchase subscription={subscription} />
      </Box>
    </Box>
  );
};

export default SubscriptionActions;
