import React from 'react';
import { IAircraftEligibility } from 'types';
import { LogbookPreviewFormComponent } from './LogbookPreview';

interface PrebuyFormProps {
  ticketAircraft: IAircraftEligibility | undefined;
}

const PrebuyForm: React.FC<PrebuyFormProps> = (props) => {
  const { ticketAircraft } = props;

  return (
    <React.Fragment>
      <LogbookPreviewFormComponent ticketAircraft={ticketAircraft} />
    </React.Fragment>
  );
};

export default PrebuyForm;
