import React from 'react';

import { Redirect } from 'react-router-dom';

import { useSelector } from 'react-redux';

import { LayoutSignupService } from 'components';

import { SignupServicePaymentForm } from './components';

import { PATH_NOT_FOUND } from 'const';

import { useService } from 'hooks';

const SignupService: React.FC = () => {
  const service = useService(); // Get service by id from url

  const price = useSelector((state: any) => {
    return state?.signup?.price ? state.signup.price : 0;
  });

  // If Service Not Found in Services array
  if (service === null) return <Redirect to={PATH_NOT_FOUND} />;

  return (
    <LayoutSignupService service={service} form={<SignupServicePaymentForm />} activeStep={1} showPriceTotalInForm servicePlan={price} />
  );
};

export default SignupService;
