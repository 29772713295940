import React, { useState, useEffect } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Hidden, Tabs, Tab, styled } from '@mui/material';

import { PATH_AIRCRAFT_VIEW, ClientAircraftTabs } from 'const';
import { IAircraftDetail } from 'types';
import { TabPanel } from 'components';
import { getTabSuffix } from 'helpers';
import { theme } from 'theme';

import {
  AircraftInformation,
  UnitConversion,
  UbgMapping,
  MglMapping,
  Logs,
  CertificationOfInsurance,
  Files,
  BorescopeImageSets,
  AircraftAccess,
} from './Tabs';

interface MainAircraftInfoProps {
  aircraftDetail?: IAircraftDetail;
}

const classes = {
  root: 'root',
  tab: 'tab',
};

const StyledDiv = styled('div')({
  [`& .${classes.root}`]: {
    background: theme.palette.grey['800'],
    color: theme.palette.grey['600'],
    borderRadius: '6px 6px 0 0',
  },
  [`& .${classes.tab}`]: {
    '&.Mui-selected': {
      backgroundColor: theme.palette.background.default,
      borderRadius: '6px 6px 0 0',
    },
    '&.MuiTab-root': {
      color: theme.palette.grey['600'],
      borderBottom: '1px solid',
      borderBottomColor: theme.palette.grey['200'],
      '&:last-child': {
        borderRight: '0px',
      },
      width: '90px',
      '@media (min-width: 1450px)': {
        width: '180px',
      },
    },
    '@media (max-width: 768px)': {
      padding: '6px',
    },
  },
});

const MainAircraftInfo: React.FC<MainAircraftInfoProps> = (props) => {
  const { aircraftDetail = undefined } = props;

  const history = useHistory();
  const { id } = useParams<{
    id: string;
  }>();

  const { isTechnician } = useSelector((state: any) => state.auth);

  const [tabValue, setTabValue] = useState(0);
  const handleChange = (event: React.ChangeEvent<unknown>, newValue: number) => {
    history.push(`${PATH_AIRCRAFT_VIEW}/${id}/${getTabSuffix(ClientAircraftTabs, newValue)}`);
  };

  const location = useLocation();

  useEffect(() => {
    if (aircraftDetail && !(aircraftDetail?.showMglSettings || aircraftDetail?.showUbg16Settings)) {
      setTabValue((tabValue: number) => {
        if (tabValue === 7) return 0;

        return tabValue;
      });
    }
  }, [aircraftDetail]);

  useEffect(() => {
    if (aircraftDetail) {
      if (location?.pathname.includes('unit-conversions')) {
        setTabValue(1);
      } else if (location?.pathname.includes('mdr')) {
        setTabValue(2);
      } else if (location?.pathname.includes('coi')) {
        setTabValue(3);
      } else if (location?.pathname.includes('files')) {
        setTabValue(4);
      } else if (location?.pathname.includes('borescope')) {
        setTabValue(5);
      } else if (location?.pathname.includes('aircraft-access')) {
        setTabValue(6);
      } else if (location?.pathname.includes('mgl') || location?.pathname.includes('ubg')) {
        setTabValue(7);
      } else {
        setTabValue(0);
      }
    }
  }, [location, aircraftDetail]);

  return (
    <StyledDiv
      sx={{
        border: '1px solid',
        borderColor: 'grey.200',
        backgroundColor: 'background.default',
      }}
    >
      {aircraftDetail && (
        <React.Fragment>
          <Box>
            <Box
              sx={{
                display: 'flex',
              }}
            >
              <Hidden smDown>
                <Box>
                  <Tabs
                    value={tabValue}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                    className={classes.root}
                    orientation="vertical"
                  >
                    <Tab label="Aircraft Information" className={classes.tab} />
                    <Tab label="Unit Conversions" className={classes.tab} />
                    <Tab label="Maintenance Records" className={classes.tab} />
                    <Tab
                      label="Insurance"
                      className={classes.tab}
                      sx={{
                        display: !isTechnician ? 'block' : 'none',
                      }}
                    />
                    <Tab label="Engine Data Files" className={classes.tab} />
                    <Tab label="Borescope Image Sets" className={classes.tab} />
                    <Tab label="Permissions" className={classes.tab} />
                    {aircraftDetail.showMglSettings && <Tab label="MGL Sensor Mappings" className={classes.tab} />}
                    {aircraftDetail.showUbg16Settings && <Tab label="UBG-16 Parameters" className={classes.tab} />}
                  </Tabs>
                </Box>
              </Hidden>
              <Box
                sx={{
                  flexGrow: 1,
                }}
              >
                <TabPanel value={tabValue} index={0}>
                  <AircraftInformation aircraftDetail={aircraftDetail} />
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                  <UnitConversion isLoad={tabValue === 1} aircraftId={aircraftDetail.id} />
                </TabPanel>
                <TabPanel value={tabValue} index={2}>
                  <Logs isLoad={tabValue === 2} aircraftId={aircraftDetail.id} />
                </TabPanel>
                <TabPanel value={tabValue} index={3}>
                  <CertificationOfInsurance
                    isLoad={tabValue === 3}
                    aircraftId={aircraftDetail.id}
                    subscriptions={aircraftDetail.subscriptions}
                  />
                </TabPanel>
                <TabPanel value={tabValue} index={4}>
                  <Files aircraftId={aircraftDetail.id} isLoad={tabValue === 4} />
                </TabPanel>
                <TabPanel value={tabValue} index={5}>
                  <BorescopeImageSets aircraftId={aircraftDetail.id} isLoad={tabValue === 5} cylinderCount={aircraftDetail.cylinderCount} />
                </TabPanel>
                <TabPanel value={tabValue} index={6}>
                  <AircraftAccess aircraftId={aircraftDetail.id} isLoad={tabValue === 6} />
                </TabPanel>
                <TabPanel value={tabValue} index={7}>
                  {aircraftDetail.showUbg16Settings && (
                    <UbgMapping aircraftId={aircraftDetail.id} monitorConfig={aircraftDetail.monitorConfig} />
                  )}
                  {aircraftDetail.showMglSettings && (
                    <MglMapping aircraftId={aircraftDetail.id} monitorConfig={aircraftDetail.monitorConfig} />
                  )}
                </TabPanel>
              </Box>
            </Box>
          </Box>
        </React.Fragment>
      )}
    </StyledDiv>
  );
};

export default MainAircraftInfo;
