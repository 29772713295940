import React from 'react';
import { Box } from '@mui/material';

interface SimpleTabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

export const a11yProps = (index: number) => {
  return {
    id: `simple-tabpanel-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const SimpleTabPanel: React.FC<SimpleTabPanelProps> = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      <Box>{children}</Box>
    </div>
  );
};

export default SimpleTabPanel;
