// https://developers.amplitude.com/docs/javascript
import amplitude from 'amplitude-js';

import { AMPLITUDE_INIT_SUCCESS, AMPLITUDE_INIT_ERROR, AMPLITUDE_LOG_EVENT_ERROR } from 'const';

const instance = amplitude.getInstance();

// Init
export const initAmplitude = () => {
  try {
    instance.init(process.env.REACT_APP_AMPLITUDE_API_KEY as string); // Init
    instance.logEvent(AMPLITUDE_INIT_SUCCESS); // Success
  } catch (error: any) {
    instance.logEvent(AMPLITUDE_INIT_ERROR); // Error
  }
};

// Log Event with Properties
export const logAmplitudeEvent = async (eventType: string, eventProperties?: any) => {
  try {
    await instance.logEvent(eventType, eventProperties && eventProperties); // eventProperties if exist
  } catch (error: any) {
    await instance.logEvent(AMPLITUDE_LOG_EVENT_ERROR);
  }
};
