import React from 'react';

import { Grow, Paper } from '@mui/material';

import styles from './style.module.scss';

interface IDropdownMenu {
  children: React.ReactNode;
  open: boolean;
}

const DropdownMenu: React.FC<IDropdownMenu> = (props) => {
  const { children, open } = props;

  return (
    <Grow in={open}>
      <Paper className={styles.paper}>{children}</Paper>
    </Grow>
  );
};

export default DropdownMenu;
