import { useState, useEffect } from 'react';

import { useLazyQuery } from '@apollo/client';
import { QUERY_FUZZY_AIRCRAFT_MANUFACTURERS } from 'gql';

import { IOptionIdName } from 'types';

import { isEmpty } from 'helpers';

interface IUseFuzzyManufacturers {
  manufacturer: IOptionIdName; // Manufacturer Autocomplete value
  manufacturerInput: string; // Input value (text field value which triggers re-fetch sugestions)
}

// Get Aircraft Manufacturer Suggestions (fuzzy). When type in input
const useFuzzyManufacturers = (props: IUseFuzzyManufacturers) => {
  const { manufacturer, manufacturerInput } = props;

  // Suggestions Initial State
  const [fuzzy, setFuzzy] = useState<IOptionIdName[]>([]);

  // GraphQL Definition
  const [refetch, { data }] = useLazyQuery(QUERY_FUZZY_AIRCRAFT_MANUFACTURERS, {
    fetchPolicy: 'no-cache',
  });

  // Response
  useEffect(() => {
    // Success
    data && setFuzzy(data?.fuzzyAircraftManufacturers); // Set suggestions array - id/name
  }, [data]);

  // Refetch Suggestions. When input changed
  useEffect(() => {
    // If input is empty or value was selected in autocomplete (autocomplete?.type is undefined)
    if (isEmpty(manufacturerInput) || manufacturerInput === '-- Add your own --') {
      return setFuzzy([]); // Clean suggestions and stop (return) (do NOT refetch)
    }
    refetch({ variables: { search: manufacturerInput } });
  }, [manufacturer, manufacturerInput, refetch]);

  return fuzzy;
};

export default useFuzzyManufacturers;
