// Service Ids. Used as id in Router url, and to render particular service page depending on url id
export const SAVVY_MX = 'mx';
export const SAVVY_QA = 'qa';
export const SAVVY_ANALYSIS = 'analysis';
export const SAVVY_ANALYSIS_PRO = 'pro';
export const SAVVY_PREBUY = 'prebuy';
export const SAVVY_BREAKDOWN = 'breakdown';
export const SAVVY_SUPPORT = 'support';
export const SAVVY_BASIC = 'basics';

export const NEW_PLAN = 'purchase-new';
export const UPGRADE_PLAN = 'upgrade';
export const ADD_PLAN = 'add';
export const CHANGE_PLAN = 'change';
export const RENEW_PLAN = 'renew';
