import React, { useState, useEffect, Fragment } from 'react';

import { Dialog, DialogContent, DialogActions, Typography, Button, Grid, Box, CircularProgress, MenuItem } from '@mui/material';

import { useMutation } from '@apollo/client';

import { ButtonSubmit } from 'components';

import { AircraftSuggestions } from './';

import { useFuzzyEngineModels } from 'hooks';

import { IOptionIdName } from 'types';

import { useInputWithSuggestions } from 'hooks';

import { MUTATION_CREATE_ENGINE_MODEL } from 'gql';

import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-mui';

interface IDialogAddEngineSuggestions {
  engineManufacturer: IOptionIdName; // Autocomplete value
  engineModel: IOptionIdName; // Autocomplete value
  setEngineModel: (value: IOptionIdName | null) => void; // Set Autocomplete value
  open: boolean;
  setOpen: (value: boolean) => void;
}

const validationSchema = Yup.object({
  engineModel: Yup.string()
    .min(1, 'Too short: 1-50 characters required')
    .max(50, 'Too long: 1-50 characters required')
    .required('Required'),
  cylinderCount: Yup.string().required('Required'),
});

interface createFormValues {
  engineModel: string;
  cylinderCount: string;
}

const DialogAddEngineEngineModelSuggestions: React.FC<IDialogAddEngineSuggestions> = (props) => {
  const { engineManufacturer, engineModel, setEngineModel, open, setOpen } = props;

  const [addState, setAddState] = useState(false);

  const [engineModelInput, setEngineModelInput] = useState('');
  const [engineModelInputTmp, setEngineModelInputTmp] = useState('');
  // EngineModel Logic
  const { showFuzzy: showFuzzyEngineModels } = useInputWithSuggestions({ input: engineModelInput });

  const [createEngineModel, { data: dataCreateEngineModel, error: errorCreateEngineModel, loading: loadingCreateEngineModel }] =
    useMutation(MUTATION_CREATE_ENGINE_MODEL);

  const [responseError, setResponseError] = useState<any>({});

  const handleSuggestionClickEngineModelHelper = (suggestion: IOptionIdName) => {
    setEngineModel(suggestion);
    handleClose();
  };

  useEffect(() => {
    if (engineModel?.id) return;
    setEngineModelInput(engineModel?.name ? engineModel.name : '');
  }, [engineModel, setEngineModelInput]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!engineModelInputTmp) {
      setEngineModelInput('');
      setLoading(false);
      return;
    }

    setLoading(true);

    const timeout: any = setTimeout(() => {
      setEngineModelInput(engineModelInputTmp);
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [engineModelInputTmp, setEngineModelInput]);

  // Fuzzy Logic
  const fuzzyEngineModels = useFuzzyEngineModels({ engineModel, engineManufacturerId: engineManufacturer?.id, engineModelInput }); // Fuzzy EngineModels

  const handleClose = () => {
    setOpen(false);
    setAddState(false);
  };

  React.useEffect(() => {
    setLoading(false);
    if (!fuzzyEngineModels.length) {
      setLoading(false);
      setAddState(true);
    } else setAddState(false);
  }, [fuzzyEngineModels]);

  const onSubmit = async (values: createFormValues) => {
    if (!engineManufacturer?.id) {
      setResponseError({
        engineModel: 'Please select/add engine manufacturer first.',
      });
      return;
    }

    await createEngineModel({
      variables: {
        manufacturerId: engineManufacturer.id,
        name: values.engineModel,
        cylinderCount: parseInt(values.cylinderCount),
      },
    });
  };

  useEffect(() => {
    if (errorCreateEngineModel) {
      setResponseError({
        engineModel: 'Failed to create new engine model, please reload page and retry.',
      });
    } else if (dataCreateEngineModel) {
      const { ok, engineModel: newEngineModel, error } = dataCreateEngineModel.createEngineModel;

      if (!ok) {
        setResponseError({
          engineModel: error || 'Failed to create new engine model, please reload page and retry.',
        });
      } else {
        setEngineModel(newEngineModel);
        setAddState(false);
        setOpen(false);
      }
    }
  }, [dataCreateEngineModel, errorCreateEngineModel, setResponseError, setEngineModel, setAddState, setOpen]);

  const notListClick = () => {
    setAddState(true);
  };

  return (
    <Dialog
      open={open}
      fullWidth
      PaperProps={{
        style: {
          height: '550px',
        },
      }}
    >
      <Formik
        initialValues={{
          engineModel: engineModel?.name ? engineModel.name : '',
          cylinderCount: '',
        }}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, handleChange, handleBlur }) => {
          return (
            <Form
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <DialogContent>
                <Typography variant="h2" gutterBottom align="center">
                  Add engine model for{' '}
                  <Box color="primary.main" component="span">
                    {engineManufacturer?.name}
                  </Box>
                </Typography>

                <Field
                  name="engineModel"
                  label="Engine Model *"
                  component={TextField}
                  onBlur={handleBlur}
                  onChange={(e: any) => {
                    handleChange(e);
                    setEngineModelInputTmp(e.target.value);
                  }}
                  placeholder={'Start typing to search...'}
                  fullWidth
                  autoFocus
                  variant="outlined"
                  inputProps={{ maxLength: 50 }}
                  FormHelperTextProps={{
                    style: {
                      color: 'red',
                      marginLeft: 0,
                    },
                  }}
                  helperText={responseError && responseError.engineModel}
                />
                {loading && <CircularProgress color="inherit" size={20} variant="indeterminate" />}
                {!loading && !addState && showFuzzyEngineModels && (
                  <AircraftSuggestions
                    label="Did you perhaps mean:"
                    suggestions={fuzzyEngineModels}
                    handleSuggestionClick={handleSuggestionClickEngineModelHelper}
                    notListLabel="My engine model is not listed."
                    notListClick={notListClick}
                  />
                )}
                {addState && (
                  <Fragment>
                    <Field
                      name="cylinderCount"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      component={TextField}
                      label="Number of Cylinders"
                      select
                      variant="outlined"
                      fullWidth
                      FormHelperTextProps={{
                        style: {
                          color: 'red',
                          marginLeft: 0,
                        },
                      }}
                      id="mui-component-select-cylinderCount"
                    >
                      <MenuItem value={'2'}>2</MenuItem>
                      <MenuItem value={'4'}>4</MenuItem>
                      <MenuItem value={'6'}>6</MenuItem>
                      <MenuItem value={'7'}>7</MenuItem>
                      <MenuItem value={'8'}>8</MenuItem>
                      <MenuItem value={'9'}>9</MenuItem>
                      <MenuItem value={'11'}>11</MenuItem>
                      <MenuItem value={'14'}>14</MenuItem>
                      <MenuItem value={'28'}>28</MenuItem>
                    </Field>
                  </Fragment>
                )}
              </DialogContent>

              <DialogActions>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Button color="secondary" variant="outlined" onClick={handleClose} fullWidth>
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <ButtonSubmit text="Add" loading={isSubmitting || loadingCreateEngineModel} disabled={!addState} />
                  </Grid>
                </Grid>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default DialogAddEngineEngineModelSuggestions;
