import { useState } from 'react';

import { IInputError, IInputSetError } from 'types';

interface IUseAutocompleteReturn {
  value: any;
  setValue: (value: any) => void;
  onChange: (option: any) => void;
  error: IInputError;
  setError: IInputSetError;
}

const useAutocomplete = (): IUseAutocompleteReturn => {
  const [value, setValue] = useState<any>(null);
  const [error, setError] = useState<IInputError>(null); // Error

  // Handle change
  const onChange = (value: any) => {
    setError(null); // Clear error
    setValue(value); // Set autocomplete value
  };

  // Return values and functions to use in component
  return { value, setValue, onChange, error, setError };
};

export default useAutocomplete;
