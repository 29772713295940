import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Hidden } from '@mui/material';
import { LayoutDashboard, PageLoading, ComponentLoading } from 'components';

import { PTDTimer, TicketTable, MobilePtd } from './components';

import { useLazyQuery } from '@apollo/client';
import { QUERY_TECHNICIAN_TICKETS } from 'gql';
import { setAlert } from 'state';
import { aircraftRegistrationMakeModel } from 'helpers';

import { ISavvyTicket, IGroup } from 'types';

const TechDashboard: React.FC = () => {
  const [fetchData, { data: dataTickets, loading: loadingTickets, error: errorTickets }] = useLazyQuery(QUERY_TECHNICIAN_TICKETS, {
    fetchPolicy: 'cache-and-network',
  });

  const dispatch = useDispatch();

  const [initialLoading, setInitialLoading] = useState(false);
  const [componentLoading, setComponentLoading] = useState(false);

  const [checkUnread, setCheckUnread] = useState(false);

  const [personalTickets, setPersonalTickets] = useState<IGroup[]>([]);

  useEffect(() => {
    if (errorTickets) {
      dispatch(setAlert('error', 'Unable to load tickets'));
      setInitialLoading(false);
    }
  }, [errorTickets, dispatch]);

  useEffect(() => {
    setInitialLoading((initialLoading) => (!initialLoading ? true : false));
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    setComponentLoading(loadingTickets);
  }, [loadingTickets]);

  useEffect(() => {
    if (dataTickets?.me?.tickets?.length) {
      setInitialLoading(false);
      const newTickets = [...dataTickets.me.tickets]
        ?.sort((a: ISavvyTicket, b: ISavvyTicket) => {
          return a.firstUnreadDatetime > b.firstUnreadDatetime ? 1 : -1;
        })
        ?.map((ticket: ISavvyTicket) => {
          const client = ticket.client
            ? `${ticket.client.firstName} ${ticket.client.lastName}`
            : ticket.aircraft?.owner
            ? `${ticket.aircraft.owner.firstName} ${ticket.aircraft.owner.lastName}`
            : '';
          const aircraft = aircraftRegistrationMakeModel(ticket.aircraft);
          const poster = ticket?.lastPoster ? `${ticket.lastPoster?.firstName} ${ticket.lastPoster?.lastName}` : '';
          return {
            id: ticket.id,
            hasUnread: ticket.hasUnread,
            subject: ticket.subject,
            client: client + (ticket.aircraft && aircraft ? ` ${aircraft}` : ''),
            updated: ticket.updated,
            poster: poster,
            state: ticket.state,
            tags: ticket.tags,
            coveringFor: ticket.coveringFor ? ticket.coveringFor : '',
            firstUnreadDatetime: ticket.firstUnreadDatetime,
            unreadCount: ticket.unreadCount,
            color: ticket.color,
          };
        });

      const newTicketGroups: IGroup[] = [];
      newTicketGroups.push({
        name: 'Active',
        tickets: newTickets.filter((ticket) => ticket.state.toLowerCase() === 'a'),
        description: '',
      });

      newTicketGroups.push({
        name: 'Closed',
        tickets: newTickets.filter((ticket) => ticket.state.toLowerCase() === 'c'),
        description: '',
      });

      setPersonalTickets([...newTicketGroups]);
    }
  }, [dataTickets]);

  return (
    <LayoutDashboard>
      {loadingTickets && <PageLoading />}
      <Box
        sx={{
          p: {
            xl: 5,
            md: 3,
            xs: 0.5,
          },
        }}
      >
        <Box
          sx={{
            backgroundColor: 'background.default',
            border: '1px solid',
            borderColor: 'grey.200',
          }}
        >
          <PTDTimer
            setPersonalTickets={setPersonalTickets}
            initialLoaded={!!dataTickets?.me?.tickets?.length}
            setComponentLoading={setComponentLoading}
            checkUnread={checkUnread}
            setCheckUnread={setCheckUnread}
          />
          <ComponentLoading loading={!initialLoading && componentLoading}>
            <Hidden smDown>
              {personalTickets?.map((group: IGroup, key: number) => {
                if (group.tickets.length) return <TicketTable key={key} group={group} checkUnread={checkUnread} />;
                else return null;
              })}
            </Hidden>
            <Hidden smUp>
              <MobilePtd
                groups={
                  dataTickets?.me?.tickets?.length
                    ? [
                        {
                          name: 'Active',
                          description: '',
                          tickets: dataTickets?.me?.tickets.filter((ticket: ISavvyTicket) => ticket.state.toLowerCase() === 'a'),
                        },
                        {
                          name: 'Closed',
                          description: '',
                          tickets: dataTickets?.me?.tickets.filter((ticket: ISavvyTicket) => ticket.state.toLowerCase() === 'c'),
                        },
                      ]
                    : []
                }
                checkUnread={checkUnread}
              />
            </Hidden>
          </ComponentLoading>
        </Box>
      </Box>
    </LayoutDashboard>
  );
};

export default TechDashboard;
