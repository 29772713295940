import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isSafari } from 'react-device-detect';
import { Box, Typography, TextField, MenuItem, Button, Link, Tooltip, List, ListItem, Divider, Menu, styled } from '@mui/material';
import { FileUploader } from 'react-drag-drop-files';
import HelpIcon from '@mui/icons-material/Help';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { ISavvyTicket, IUploadedFile, IExsitingFiles, IDropDownOption } from 'types';
import { AddExistingAttachmentsModal, Attachments, DraftPosts } from './ContentComponent';
import { LogTimer } from './TicketInfoComponents';
import { snoozeDuration, getVisibilityName, isEqualTwoArrays } from 'helpers';
import { ComponentLoading, Checkbox } from 'components';

import { useMutation, useLazyQuery } from '@apollo/client';
import {
  MUTATION_ADD_POST_TO_TICKET,
  MUTATION_SNOOZE_TICKET,
  MUTATION_CREATE_TIMESHEETS,
  QUERY_PERSONAL_TICKET_DASHBOARD,
  MUTATION_CREATE_DRAFT_POST,
  QUERY_SAVVY_CONFIG_FOR_TICKET,
  QUERY_FETCH_SAVVY_RECOMMENDED_TIMESHEET_CATEGORY,
} from 'gql';

import { apolloClient } from 'services';

import { setAlert } from 'state';
import {
  SET_POST_TEXT,
  SET_EXISTING_FILES,
  SET_UPLOADED_FILES,
  SET_TIMESHEETS,
  SET_SNOOZE,
  SET_TICKET_VISIBILITY,
  SET_CLOSE_TICKET_AFTER_POST,
  SET_TICKET_ID,
} from 'state/types';
import { FLIGHT_FILE_MAX_SIZE, MAX_REMARKS_LENGTH } from 'const';

interface TicketPostFormProps {
  ticket: ISavvyTicket | undefined;
  cannedResponseText: string;
  setCannedResponseText: (value: string) => void;
  allowedTimesheetCategories: IDropDownOption[];
  lastTimesheetCategory: IDropDownOption | undefined;
  showTimesheetForm: boolean;
  savvyId: string;
  closeTicket?: (id: number, close: boolean) => void;
  canViewUnhappyState?: boolean;
}

interface ITimesheet {
  categoryId: string;
  minutes: string;
  remarks: string;
}

const SAVE_DRAFT_INTERVAL = 30 * 1000;

const classes = {
  dragDropUpload: 'dragDropUpload',
};

const StyledDiv = styled('div')({
  [`& .${classes.dragDropUpload}`]: {
    height: '100% !important',
    width: '100% !important',
    maxWidth: '100% !important',
    border: '2px dashed #BBC0D4 !important',
    borderRadius: '6px !important',
    '& svg': {
      display: 'none',
    },
    '& div': {
      display: 'block',
      textAlign: 'center',
      '& span': {
        fontSize: '24px',
        fontWeight: 400,
        color: 'rgba(75, 84, 112, 0.5)',
        lineHeight: '27px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& span': {
          textDecoration: 'none',
          maxWidth: '19ch',
          display: 'block',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          marginRight: '5px',
        },
      },
    },
  },
});

const TicketPostForm: React.FC<TicketPostFormProps> = (props) => {
  const {
    ticket = undefined,
    cannedResponseText,
    setCannedResponseText,
    allowedTimesheetCategories,
    showTimesheetForm: propsShowTimesheetForm,
    closeTicket,
    canViewUnhappyState,
  } = props;

  const message = useSelector((state: any) => {
    return state.ticket.postText;
  });

  const [messageError, setMessageError] = useState('');

  const [updateTicketDashboard] = useLazyQuery(QUERY_PERSONAL_TICKET_DASHBOARD, {
    fetchPolicy: 'cache-and-network',
  });

  const [fetchSavvyConfig, { loading: loadingSavvyConfig }] = useLazyQuery(QUERY_SAVVY_CONFIG_FOR_TICKET, {
    fetchPolicy: 'network-only',
  });

  const [fetchRecommendedTimesheetCategory, { data: dataCategory }] = useLazyQuery(QUERY_FETCH_SAVVY_RECOMMENDED_TIMESHEET_CATEGORY, {
    fetchPolicy: 'network-only',
  });

  const [renderLastTimesheetCategory, setRenderLastTimeSheetCategory] = useState<IDropDownOption | undefined>();

  const [showTimesheetForm, setShowTimesheetForm] = useState(propsShowTimesheetForm);

  const { id } = useParams<{
    id?: string;
  }>();

  const sortPosts = ticket?.posts ? [...ticket.posts] : [];

  const [saveDraftPost] = useMutation(MUTATION_CREATE_DRAFT_POST);
  const SaveDraftIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const [lastDraftPost, setLastDraftPost] = useState('');

  sortPosts.sort((a: any, b: any) => {
    if (new Date(a.created) > new Date(b.created)) {
      return -1;
    } else {
      return 1;
    }
  });

  const dispatch = useDispatch();

  const [openExistingFilesModal, setOpenExistingFilesModal] = useState(false);

  const insertStringToSpecific = (originString: string, substr: string, position: number) => {
    const originStringArr = originString.split('');
    originStringArr.splice(position, 0, substr);

    return originString ? originStringArr.join('') : substr;
  };

  const [snoozeError, setSnoozeError] = useState('');

  const [isResetTimer, setIsResetTimer] = useState(false);

  const [anchorVisibility, setAnchorVisibility] = useState<null | HTMLElement>(null);
  const openVisibility = Boolean(anchorVisibility);

  const { attachExistingFiles, uploadedFileList, timesheets, snoozeCustom, visibility, closeTicketAfterPost, storedTicketId } = useSelector(
    (state: any) => {
      return state.ticket;
    },
  );

  const [dropFiles, setDropFiles] = useState<File[]>([]);

  const [dropZoneZ, setDropZoneZ] = useState(-1);

  const setTicketId = (id: string) => {
    if (storedTicketId !== id) {
      dispatch({
        type: SET_TICKET_ID,
        payload: {
          id,
        },
      });
    }
  };

  const setAttachExistingFiles = (inputFiles: IExsitingFiles[]) => {
    if (!isEqualTwoArrays(attachExistingFiles, inputFiles)) {
      dispatch({
        type: SET_EXISTING_FILES,
        payload: {
          attachExistingFiles: [...inputFiles],
        },
      });
    }
  };

  const setUploadedFileList = (inputFiles: IUploadedFile[]) => {
    if (!isEqualTwoArrays(uploadedFileList, inputFiles)) {
      dispatch({
        type: SET_UPLOADED_FILES,
        payload: {
          uploadedFileList: [...inputFiles],
        },
      });
    }
  };

  const setTimesheets = (inputSheets: ITimesheet[]) => {
    if (!isEqualTwoArrays(timesheets, inputSheets)) {
      dispatch({
        type: SET_TIMESHEETS,
        payload: {
          timesheets: [...inputSheets],
        },
      });
    }
  };

  const setSnoozeCustom = (input: string) => {
    if (snoozeCustom !== input) {
      dispatch({
        type: SET_SNOOZE,
        payload: {
          snoozeCustom: input,
        },
      });
    }
  };

  const setVisibility = (input: string) => {
    if (visibility !== input) {
      dispatch({
        type: SET_TICKET_VISIBILITY,
        payload: {
          visibility: input,
        },
      });
    }
  };

  const setCloseTicketAfterPost = (input: boolean) => {
    if (closeTicketAfterPost !== input) {
      dispatch({
        type: SET_CLOSE_TICKET_AFTER_POST,
        payload: {
          closeTicketAfterPost: input,
        },
      });
    }
  };

  const handleClose = () => {
    setAnchorVisibility(null);
  };

  const setMessage = (text: string) => {
    dispatch({
      type: SET_POST_TEXT,
      payload: {
        postText: text,
      },
    });
  };

  useEffect(() => {
    if (id) {
      fetchRecommendedTimesheetCategory({
        variables: {
          ticketId: parseInt(id),
        },
      });
    }
  }, [id]);

  useEffect(() => {
    if (id !== storedTicketId && id) {
      setMessage('');
      setMessageError('');
      setVisibility('M,C');
      setUploadedFileList([]);
      setAttachExistingFiles([]);
      setCloseTicketAfterPost(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, storedTicketId]);

  const saveDraftTimer = useCallback(
    (id: string) => {
      if (lastDraftPost === message) {
        return false;
      } else {
        const draft = message.replaceAll(/\s+/g, '');
        if (draft) {
          saveDraftPost({
            variables: {
              body: message,
              ticketId: parseInt(id),
            },
          });
          setLastDraftPost(message);
        }
      }
    },
    [message, saveDraftPost, lastDraftPost],
  );

  useEffect(() => {
    if (id) {
      SaveDraftIntervalRef.current && clearInterval(SaveDraftIntervalRef.current);

      SaveDraftIntervalRef.current = setInterval(() => {
        saveDraftTimer(id);
      }, SAVE_DRAFT_INTERVAL);
    }

    return () => {
      SaveDraftIntervalRef.current && clearInterval(SaveDraftIntervalRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, saveDraftTimer]);

  useEffect(() => {
    if (id && id !== storedTicketId && dataCategory && dataCategory?.me?.tickets[0]?.id === id) {
      setTicketId(id);
      if (showTimesheetForm) {
        setTimesheets([
          {
            categoryId: dataCategory?.me?.tickets[0]?.recommendedTimesheetCategory?.timesheetCategory?.id
              ? dataCategory.me.tickets[0].recommendedTimesheetCategory.timesheetCategory.id
              : '',
            minutes: '',
            remarks: '',
          },
        ]);
      } else {
        setTimesheets([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showTimesheetForm, id, storedTicketId, dataCategory]);

  useEffect(() => {
    if (cannedResponseText) {
      const element = document.getElementById('message') as HTMLInputElement;

      if (element) {
        setMessage(
          element.selectionStart !== null
            ? insertStringToSpecific(message, cannedResponseText, element.selectionStart)
            : message + cannedResponseText,
        );
      }

      setCannedResponseText('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cannedResponseText, setCannedResponseText]);

  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    if (!ticket?.id) return;

    const documentIds = uploadedFileList
      .filter((file: IUploadedFile) => file.success)
      .map((file: IUploadedFile) => {
        return file.id;
      });

    const existingFilesIds = attachExistingFiles.map((file: IExsitingFiles) => {
      return file.id;
    });

    const postTimesheets: {
      minutes: number;
      remarks: string;
      categoryId: string;
    }[] = [];

    timesheets.map((timesheet: ITimesheet) => {
      if (timesheet.minutes && parseInt(timesheet.minutes)) {
        postTimesheets.push({
          minutes: parseInt(timesheet.minutes),
          remarks: timesheet.remarks,
          categoryId: timesheet.categoryId,
        });
      }

      return null;
    });

    if (postTimesheets.length === 0 && !snoozeCustom) {
      if (!message && !closeTicketAfterPost) {
        setMessageError('Required');
        dispatch(setAlert('error', 'Message Required'));
        return;
      }
    }

    if (ticket.canSnooze && !closeTicketAfterPost) {
      if (snoozeCustom && !validSnooze(snoozeCustom)) {
        setSnoozeError('Snooze Error');
        dispatch(setAlert('error', 'Invalid Snooze'));
        return;
      } else if (!snoozeCustom && message) {
        setSnoozeError('Snooze Error');
        dispatch(setAlert('error', 'Snooze Required'));
        return;
      }
    }

    if (message) {
      setLoading(true);
      try {
        const { data: dataAddPost } = await apolloClient.mutate({
          mutation: MUTATION_ADD_POST_TO_TICKET,
          variables: {
            ticketId: parseInt(ticket.id),
            body: message,
            documentIds,
            existingAttachmentIds: existingFilesIds ? existingFilesIds : [],
            visibility: visibility,
          },
          errorPolicy: 'all',
        });
        const { ok, error } = dataAddPost.addPostToTicket;
        if (!ok) {
          dispatch(setAlert('error', error));
          return;
        }
      } catch (err) {
        setLoading(false);
        if (err) {
          dispatch(setAlert('error', 'Unable to create post'));
          return;
        }
      }
      setLoading(false);
    }

    if (postTimesheets?.length) {
      let timesheetFlg = true;
      for (let i = 0; i < postTimesheets.length; i++) {
        if (!postTimesheets[i].categoryId) {
          timesheetFlg = false;
          break;
        }
      }

      if (timesheetFlg) {
        setLoading(true);
        try {
          const { data: dataCreateTimesheets } = await apolloClient.mutate({
            mutation: MUTATION_CREATE_TIMESHEETS,
            variables: {
              ticketId: parseInt(ticket.id),
              timesheets: postTimesheets ? postTimesheets : [],
            },
            errorPolicy: 'all',
          });
          const { ok, error } = dataCreateTimesheets.createTimesheet;
          if (!ok) {
            dispatch(setAlert('error', error));
            return;
          }
        } catch (err) {
          setLoading(false);
          if (err) {
            dispatch(setAlert('error', 'Unable to create timesheets'));
            return;
          }
        }
        setLoading(false);

        await fetchSavvyConfig();
      }
    }

    if (snoozeCustom && ticket.canSnooze) {
      setLoading(true);
      try {
        const { data: dataSnoozeTicket } = await apolloClient.mutate({
          mutation: MUTATION_SNOOZE_TICKET,
          variables: {
            ticketId: ticket.id,
            snooze: closeTicketAfterPost ? '0m' : snoozeCustom,
          },
          errorPolicy: 'all',
        });
        const { ok, error } = dataSnoozeTicket.snoozeTicket;
        if (!ok) {
          dispatch(setAlert('error', error));
          return;
        }
        await updateTicketDashboard();
      } catch (err) {
        setLoading(false);
        if (err) {
          dispatch(setAlert('error', 'Unable to snooze ticket'));
          return;
        }
      }
      setLoading(false);
    }

    dispatch(setAlert('success', 'Post created'));

    if (closeTicketAfterPost && closeTicket) {
      await closeTicket(parseInt(ticket?.id), true);
      setCloseTicketAfterPost(false);
    }

    setIsResetTimer(true);

    if (showTimesheetForm) {
      setTimesheets([
        {
          categoryId: renderLastTimesheetCategory?.id ? renderLastTimesheetCategory.id : '',
          minutes: '',
          remarks: '',
        },
      ]);
    }
    setMessage('');
    setSnoozeCustom('');
    setUploadedFileList([]);
    setAttachExistingFiles([]);
    setVisibility('M,C');
  };

  const getVisibilityColorTheme = (visibility: string) => {
    return visibility === 'M,C,T'
      ? 'rgba(74, 175, 97, 1)'
      : visibility === 'M'
        ? 'rgba(214,33,33,1)'
        : visibility === 'M,C'
          ? 'rgba(214, 142, 33, 1)'
          : 'rgba(25,140,222,1)';
  };

  const removeFile = (index: number) => {
    const tmp = [...uploadedFileList];
    tmp.splice(index, 1);
    setUploadedFileList([...tmp]);
  };

  const removeExistingFile = (index: number) => {
    const tmp = [...attachExistingFiles];
    tmp.splice(index, 1);
    setAttachExistingFiles([...tmp]);
  };

  const validSnooze = (value: string) => {
    if (value === '') return true;

    if (value === '0m') return true;

    if (!value.length) return false;

    const lastX = value[value.length - 1];

    if (lastX !== 'm' && lastX !== 'h' && lastX !== 'd' && lastX !== 'w') return false;

    const mainValue = value.substring(0, value.length - 1);

    if (parseInt(mainValue) && parseInt(mainValue) > 0) return true;

    return false;
  };

  const setTimesheetMinute = (value: string, index: number) => {
    const tmpSheets = JSON.parse(JSON.stringify(timesheets));
    tmpSheets[index].minutes = value;
    setTimesheets([...tmpSheets]);
  };

  const setTimesheetCategory = (value: string, index: number) => {
    const tmpSheets = JSON.parse(JSON.stringify(timesheets));
    tmpSheets[index].categoryId = value;
    if (index === tmpSheets.length - 1) {
      setRenderLastTimeSheetCategory((renderLastTimesheetCategory: IDropDownOption | undefined) => {
        if (renderLastTimesheetCategory) {
          renderLastTimesheetCategory = {
            id: value,
            name: renderLastTimesheetCategory.name,
          };
        } else {
          renderLastTimesheetCategory = {
            id: value,
            name: '',
          };
        }
        return renderLastTimesheetCategory;
      });
    }
    setTimesheets([...tmpSheets]);
  };

  const setTimesheetRemarks = (value: string, index: number) => {
    const tmpSheets = JSON.parse(JSON.stringify(timesheets));
    tmpSheets[index].remarks = value;
    setTimesheets([...tmpSheets]);
  };

  const removeTimesheet = (index: number) => {
    const tmpSheets = JSON.parse(JSON.stringify(timesheets));
    tmpSheets.splice(index, 1);
    setTimesheets([...tmpSheets]);
  };

  const addTimesheetEntry = () => {
    setShowTimesheetForm(true);
    const tmpSheets = JSON.parse(JSON.stringify(timesheets));
    tmpSheets.push({
      categoryId: dataCategory?.me?.tickets[0]?.recommendedTimesheetCategory?.timesheetCategory?.id
        ? dataCategory.me.tickets[0].recommendedTimesheetCategory.timesheetCategory.id
        : '',
      minutes: '',
      remarks: '',
    });

    setTimesheets([...tmpSheets]);
  };

  return (
    <React.Fragment>
      <ComponentLoading loading={loading || loadingSavvyConfig}>
        {ticket && ticket.state !== 'C' && (
          <React.Fragment>
            <Box
              sx={{
                pt: {
                  xs: 2,
                  xl: 0,
                },
              }}
            >
              <Box
                sx={{
                  position: 'relative',
                }}
                onDragOver={() => {
                  if (!isSafari) {
                    setDropZoneZ(999);
                  }
                }}
                onDragEnter={() => {
                  if (!isSafari) {
                    setDropZoneZ(999);
                  }
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                  }}
                >
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{
                      borderRightWidth: 0,
                      width: '20px',
                      borderRadius: '6px',
                      marginRight: '-19px',
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                      backgroundColor: getVisibilityColorTheme(visibility),
                    }}
                  />
                  <TextField
                    name="message"
                    multiline
                    minRows={5}
                    fullWidth
                    value={message}
                    onChange={(e) => {
                      setMessage(e.target.value);
                      setMessageError('');
                    }}
                    id="message"
                    error={Boolean(messageError)}
                    placeholder="Type your message here..."
                    sx={{
                      mb: 0,
                      '& .MuiInputBase-root': {
                        pl: 3,
                        pb: 4.5,
                      },
                      '& textarea': {
                        pl: 2.5,
                      },
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    position: 'absolute',
                    right: 0,
                    bottom: 0,
                    width: '100%',
                  }}
                >
                  <Box
                    pl={4}
                    pr={2}
                    pb={1}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <DraftPosts ticket={ticket} />
                    <Attachments
                      uploadedFileList={uploadedFileList}
                      setUploadedFileList={setUploadedFileList}
                      setOpenExistingFilesModal={setOpenExistingFilesModal}
                      dropFiles={dropFiles}
                      setDropFiles={setDropFiles}
                    />
                  </Box>
                </Box>
                <StyledDiv
                  sx={{
                    position: 'absolute',
                    right: 0,
                    bottom: 0,
                    width: '100%',
                    height: '100%',
                    zIndex: dropZoneZ,
                    display: dropZoneZ > 0 ? 'flex' : 'none',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'background.default',
                    border: '1px dashed',
                    borderColor: 'grey.200',
                    borderRadius: '6px',
                  }}
                >
                  <FileUploader
                    handleChange={(files: File[]) => {
                      setDropFiles([...files]);
                      setDropZoneZ(-1);
                    }}
                    name={'name_' + id}
                    label={'Drag attachments here'}
                    classes={[classes.dragDropUpload]}
                    maxSize={FLIGHT_FILE_MAX_SIZE}
                    multiple={true}
                    onDraggingStateChange={(dragging: boolean) => {
                      if (!dragging) {
                        setDropZoneZ(-1);
                      }
                    }}
                    hoverTitle=" "
                  />
                </StyledDiv>
              </Box>
              {(!!uploadedFileList.length || !!attachExistingFiles.length) && (
                <Box
                  sx={{
                    pb: 2,
                    px: 2,
                  }}
                >
                  {uploadedFileList?.map((uploadedFile: IUploadedFile, index: number) => {
                    return (
                      <Box
                        key={index}
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          py: 0.5,
                        }}
                      >
                        <Typography>{uploadedFile.name}</Typography>
                        <Typography
                          sx={{
                            color: uploadedFile.success ? '#4C8F5B' : '#E61B23',
                            marginLeft: '15px',
                          }}
                        >
                          {uploadedFile.success ? 'Uploaded' : 'Failed'}
                        </Typography>
                        {uploadedFile.success && (
                          <Link
                            href="#"
                            onClick={(e: any) => {
                              e.preventDefault();
                              e.stopPropagation();
                              removeFile(index);
                            }}
                            sx={{
                              marginLeft: '15px',
                            }}
                          >
                            Remove
                          </Link>
                        )}
                      </Box>
                    );
                  })}
                  {attachExistingFiles?.map((attachExistingFile: IExsitingFiles, index: number) => {
                    return (
                      <Box
                        key={index}
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          py: 0.5,
                        }}
                      >
                        <Typography>
                          {attachExistingFile.name}
                          {attachExistingFile.description ? ` (${attachExistingFile.description})` : ''}
                        </Typography>
                        <Typography
                          sx={{
                            color: '#E61B23',
                            marginLeft: '15px',
                          }}
                        >
                          Existing
                        </Typography>
                        <Link
                          href="#"
                          onClick={(e: any) => {
                            e.preventDefault();
                            e.stopPropagation();
                            removeExistingFile(index);
                          }}
                          sx={{
                            marginLeft: '15px',
                          }}
                        >
                          Remove
                        </Link>
                      </Box>
                    );
                  })}
                </Box>
              )}
            </Box>
            <Box py={2}>
              <Box>
                {timesheets.length !== 0 && (
                  <Typography
                    sx={{
                      textAlign: 'right',
                      '@media (min-width: 1550px)': {
                        textAlign: 'left',
                      },
                    }}
                  >
                    Timesheets
                  </Typography>
                )}
                {allowedTimesheetCategories.length !== 0 &&
                  timesheets?.map((timesheet: ITimesheet, key: number) => {
                    return (
                      <Box key={key}>
                        <Box
                          sx={{
                            display: 'block',
                            '@media (min-width: 1550px)': {
                              display: 'flex',
                              alignItems: 'center',
                            },
                          }}
                        >
                          <Box
                            sx={{
                              display: 'block',
                              '@media (min-width: 1550px)': {
                                display: 'flex',
                                alignItems: 'center',
                                flexGrow: 1,
                              },
                            }}
                          >
                            <Box
                              py={1}
                              sx={{
                                textAlign: 'right',
                                '@media (min-width: 1550px)': {
                                  mr: 1,
                                },
                              }}
                            >
                              <TextField
                                type="number"
                                label="Mins *"
                                placeholder="Mins *"
                                inputProps={{
                                  min: 0,
                                }}
                                value={timesheet.minutes}
                                onChange={(e) => {
                                  setTimesheetMinute(e.target.value, key);
                                }}
                                onWheel={(e: any) => {
                                  e.target?.blur();
                                }}
                                sx={{
                                  mb: 0,
                                  '@media (min-width: 1550px)': {
                                    width: '90px',
                                  },
                                }}
                              />
                            </Box>
                            <Box
                              py={1}
                              sx={{
                                textAlign: 'right',
                                '@media (min-width: 1550px)': {
                                  mr: 1,
                                },
                              }}
                            >
                              <TextField
                                select
                                label="Category *"
                                value={timesheet.categoryId}
                                onChange={(e) => {
                                  setTimesheetCategory(e.target.value, key);
                                }}
                                sx={{
                                  mb: 0,
                                  '& .MuiSelect-select': {
                                    textAlign: 'left',
                                  },
                                  width: '210px',
                                }}
                              >
                                {allowedTimesheetCategories.map((timesheetCategory: IDropDownOption, index: number) => {
                                  return (
                                    <MenuItem key={index} value={timesheetCategory.id}>
                                      {timesheetCategory.name}
                                    </MenuItem>
                                  );
                                })}
                              </TextField>
                            </Box>
                            {dataCategory?.me?.tickets[0]?.recommendedTimesheetCategory && (
                              <Box
                                py={1}
                                sx={{
                                  textAlign: 'right',
                                  '@media (min-width: 1550px)': {
                                    mr: 1,
                                  },
                                }}
                              >
                                <Tooltip
                                  title={
                                    <Typography>
                                      Category "{dataCategory?.me?.tickets[0]?.recommendedTimesheetCategory?.timesheetCategory?.name || ''}"
                                      was pre-selected {dataCategory?.me?.tickets[0]?.recommendedTimesheetCategory?.reason || ''}
                                    </Typography>
                                  }
                                  PopperProps={{
                                    sx: {
                                      '& .MuiTooltip-tooltip': {
                                        backgroundColor: 'background.default',
                                        border: '1px solid',
                                        borderColor: 'grey.200',
                                        boxShadow: '0px 1px 1px rgba(19, 30, 64, 0.06)',
                                        borderRadius: '6px !important',
                                        '& p': {
                                          color: 'text.primary',
                                        },
                                      },
                                    },
                                  }}
                                >
                                  <HelpIcon />
                                </Tooltip>
                              </Box>
                            )}
                            <Box
                              py={1}
                              sx={{
                                textAlign: 'right',
                                flexGrow: 1,
                                '@media (min-width: 1441px)': {
                                  pr: 1,
                                },
                              }}
                            >
                              <TextField
                                label="Notes"
                                value={timesheet.remarks}
                                onChange={(e) => {
                                  setTimesheetRemarks(e.target.value, key);
                                }}
                                sx={{
                                  mb: 0,
                                }}
                                inputProps={{
                                  maxLength: MAX_REMARKS_LENGTH,
                                }}
                                fullWidth
                              />
                            </Box>
                          </Box>
                          <Box
                            py={1}
                            title="Remove timesheet"
                            sx={{
                              textAlign: 'right',
                            }}
                          >
                            <CloseIcon
                              sx={{
                                cursor: 'pointer',
                                display: 'none',
                                '@media (min-width: 1441px)': {
                                  display: 'block',
                                },
                              }}
                              onClick={() => {
                                removeTimesheet(key);
                              }}
                            />
                            <Typography
                              sx={{
                                cursor: 'pointer',
                                textDecoration: 'underline',
                                display: 'inline-block',
                                '@media (min-width: 1441px)': {
                                  display: 'none',
                                },
                              }}
                              onClick={() => {
                                removeTimesheet(key);
                              }}
                            >
                              Remove
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    );
                  })}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Typography
                  sx={{
                    cursor: 'pointer',
                    textDecoration: 'underline',
                    display: 'inline-block',
                  }}
                  onClick={addTimesheetEntry}
                >
                  {timesheets.length && showTimesheetForm ? 'More timesheets' : 'Show timesheet entry'}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    '& p': {
                      fontWeight: 600,
                    },
                    justifyContent: 'flex-end',
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 600,
                      mr: 2,
                    }}
                  >
                    Current Timer
                  </Typography>
                  <Box>
                    <LogTimer ticketId={ticket.id} isResetTimer={isResetTimer} setIsResetTimer={setIsResetTimer} />
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                pb: 2,
                display: 'block',
                '@media (min-width: 1550px)': {
                  display: 'flex',
                  justifyContent: ticket.canSnooze ? 'space-between' : 'flex-end',
                },
                borderBottom: '1px solid',
                borderColor: 'grey.200',
              }}
            >
              {ticket.canSnooze && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    pb: 2,
                    '@media (min-width: 1550px)': {
                      pb: 0,
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      sx={{
                        cursor: 'pointer',
                        textDecoration: 'underline',
                        mr: 1,
                      }}
                      onClick={() => {
                        setSnoozeCustom(snoozeDuration('0m'));
                      }}
                    >
                      No Snooze
                    </Typography>
                    <Typography
                      sx={{
                        cursor: 'pointer',
                        textDecoration: 'underline',
                        mr: 1,
                      }}
                      onClick={() => {
                        setSnoozeCustom(snoozeDuration('tm'));
                      }}
                    >
                      Tomorrow a.m
                    </Typography>
                    <Typography
                      sx={{
                        cursor: 'pointer',
                        textDecoration: 'underline',
                        mr: 1,
                      }}
                      onClick={() => {
                        setSnoozeCustom(snoozeDuration('nm'));
                      }}
                    >
                      Next Mon
                    </Typography>
                    <Typography
                      sx={{
                        cursor: 'pointer',
                        textDecoration: 'underline',
                        mr: 1,
                      }}
                      onClick={() => {
                        setSnoozeCustom(snoozeDuration('24h'));
                      }}
                    >
                      24h
                    </Typography>
                    <Typography
                      sx={{
                        cursor: 'pointer',
                        textDecoration: 'underline',
                        mr: 1,
                      }}
                      onClick={() => {
                        setSnoozeCustom(snoozeDuration('3d'));
                      }}
                    >
                      3d
                    </Typography>
                    <Typography
                      sx={{
                        cursor: 'pointer',
                        textDecoration: 'underline',
                        mr: 1,
                      }}
                      onClick={() => {
                        setSnoozeCustom(snoozeDuration('1w'));
                      }}
                    >
                      1w
                    </Typography>
                  </Box>
                  <TextField
                    name="snooze"
                    label="Snooze"
                    placeholder="e.g. 24h"
                    value={snoozeCustom}
                    onChange={(e) => {
                      setSnoozeCustom(e.target.value);
                      setSnoozeError('');
                    }}
                    error={!validSnooze(snoozeCustom) || Boolean(snoozeError)}
                    sx={{
                      mb: 0,
                      mr: 1,
                      width: '80px',
                    }}
                  />
                  <Tooltip
                    title={
                      <Box py={2}>
                        <Typography color="text.primary">
                          To snooze a ticket, click on one of the links, or enter an amount followed by a letter. See examples:
                        </Typography>
                        <List
                          sx={{
                            px: 2,
                            py: 0.5,
                            mb: 1,
                          }}
                        >
                          <ListItem
                            sx={{
                              color: 'text.primary',
                              py: 0.5,
                              pl: 0,
                            }}
                          >
                            <Typography color="text.primary">30m: 30 minutes</Typography>
                          </ListItem>
                          <ListItem
                            sx={{
                              color: 'text.primary',
                              py: 0.5,
                              pl: 0,
                            }}
                          >
                            <Typography color="text.primary">2h: 2 hours</Typography>
                          </ListItem>
                          <ListItem
                            sx={{
                              color: 'text.primary',
                              py: 0.5,
                              pl: 0,
                            }}
                          >
                            <Typography color="text.primary">3d: 3 days</Typography>
                          </ListItem>
                          <ListItem
                            sx={{
                              color: 'text.primary',
                              py: 0.5,
                              pl: 0,
                            }}
                          >
                            <Typography color="text.primary">1w: 1 week</Typography>
                          </ListItem>
                        </List>
                      </Box>
                    }
                    PopperProps={{
                      sx: {
                        '& .MuiTooltip-tooltip': {
                          backgroundColor: 'background.default',
                          border: '1px solid',
                          borderColor: 'grey.200',
                          boxShadow: '0px 1px 1px rgba(19, 30, 64, 0.06)',
                          borderRadius: '6px !important',
                        },
                      },
                    }}
                  >
                    <HelpIcon />
                  </Tooltip>
                </Box>
              )}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  '@media (min-width: 1550px)': {
                    justifyContent: 'flex-end',
                  },
                }}
              >
                <Box>
                  <Checkbox
                    value={closeTicketAfterPost}
                    label="Close ticket after post?"
                    onChange={() => {
                      setCloseTicketAfterPost(!closeTicketAfterPost);
                    }}
                    noMargin
                    noPadding
                  />
                </Box>
                <Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      borderRadius: '8px',
                      backgroundColor: getVisibilityColorTheme(visibility),
                      ml: 1,
                    }}
                  >
                    <Button
                      disableElevation
                      sx={{
                        color: 'background.default',
                        borderRadius: '0px',
                        borderTopLeftRadius: '8px',
                        borderBottomLeftRadius: '8px',
                        px: '10px',
                        '&:hover': {
                          borderRadius: '0px',
                          borderTopLeftRadius: '8px',
                          borderBottomLeftRadius: '8px',
                        },
                        py: '10px',
                      }}
                      onClick={() => {
                        onSubmit();
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 500,
                        }}
                      >
                        Send to {getVisibilityName(visibility)}
                      </Typography>
                    </Button>
                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={{
                        borderColor: 'background.default',
                      }}
                    />
                    <ExpandMoreOutlinedIcon
                      sx={{
                        color: 'background.default',
                        height: '37px',
                        cursor: 'pointer',
                      }}
                      onClick={(e: any) => {
                        setAnchorVisibility(e.currentTarget);
                      }}
                    />
                  </Box>
                  <Menu
                    open={openVisibility}
                    onClose={handleClose}
                    anchorEl={anchorVisibility}
                    sx={{
                      py: 0,
                      borderRadius: 0,
                      '& .MuiMenu-list': {
                        py: 0,
                        '& li': {
                          py: 1,
                        },
                      },
                    }}
                  >
                    <MenuItem
                      value="M,C,T"
                      onClick={() => {
                        setVisibility('M,C,T');
                        setAnchorVisibility(null);
                      }}
                      divider
                    >
                      Everybody
                    </MenuItem>
                    <MenuItem
                      value="M,C"
                      onClick={() => {
                        setVisibility('M,C');
                        setAnchorVisibility(null);
                      }}
                      divider
                    >
                      Savvy and Clients
                    </MenuItem>
                    <MenuItem
                      value="M"
                      onClick={() => {
                        setVisibility('M');
                        setAnchorVisibility(null);
                      }}
                      divider
                    >
                      Savvy Only
                    </MenuItem>
                    {ticket?.notHappy && canViewUnhappyState && (
                      <MenuItem
                        value="C,X"
                        onClick={() => {
                          setVisibility('C,X');
                          setAnchorVisibility(null);
                        }}
                      >
                        Client and Savvy Management
                      </MenuItem>
                    )}
                    {ticket?.notHappy && canViewUnhappyState && (
                      <MenuItem
                        value="X"
                        onClick={() => {
                          setVisibility('X');
                          setAnchorVisibility(null);
                        }}
                      >
                        Savvy Management
                      </MenuItem>
                    )}
                  </Menu>
                </Box>
              </Box>
            </Box>
          </React.Fragment>
        )}
      </ComponentLoading>
      <AddExistingAttachmentsModal
        open={openExistingFilesModal}
        setOpen={setOpenExistingFilesModal}
        ticket={ticket}
        attachExistingFiles={attachExistingFiles}
        setAttachExistingFiles={setAttachExistingFiles}
      />
    </React.Fragment>
  );
};

export default TicketPostForm;
