import React from 'react';
import { IAircraftEligibility, IBorescopeImageSet } from 'types';
import RequirePurchase from './RequirePurchase';
import { BorescopeAnalysisFormComponent } from './BorescopeAnalysis';
import { ComponentLoading } from 'components';

interface BorescopeAnalysisFormProps {
  ticketAircraft: IAircraftEligibility;
  borescopeImageSet?: IBorescopeImageSet;
}

const BorescopeAnalysisForm: React.FC<BorescopeAnalysisFormProps> = (props) => {
  const { ticketAircraft, borescopeImageSet } = props;

  return (
    <React.Fragment>
      <ComponentLoading loading={false}>
        <RequirePurchase ticketAircraft={ticketAircraft} eligibility={ticketAircraft.ticketEligibility.borescopeAnalysis} />
        <BorescopeAnalysisFormComponent
          ticketAircraft={ticketAircraft}
          eligibility={ticketAircraft.ticketEligibility.borescopeAnalysis}
          borescopeImageSet={borescopeImageSet}
        />
      </ComponentLoading>
    </React.Fragment>
  );
};

export default BorescopeAnalysisForm;
