// Keep the same variables values in typescript and scss - theme/variables.ts, theme/css/_variables.scss

// Colors
// --------------------------------
// Primary
const primaryBase = '63, 117, 255';
export const primaryMain = `rgba(${primaryBase}, 1)`;
export const primaryHover = `rgba(${primaryBase}, 0.8)`;
export const primaryDark = '#131E40';

export const contrast = '#737C97';

// Warning
const warningBase = '214, 142, 33';
export const warningMain = `rgba(${warningBase}, 1)`;
export const warningLight = `rgba(${warningBase}, 0.1)`;
export const warningDark = '#E7A036';

// Error
const errorBase = '255, 0, 0';
export const errorMain = `rgba(${errorBase}, 1)`;
export const errorDark = '#E61B23';

// Success
const successBase = '74, 175, 97';
export const successMain = `rgba(${successBase}, 1)`;

export const successDark = '#4C8F5B';

// Text
export const textPrimary = '#4B5470'; // lighter color is main
// export const textSecondary = '#131E40'; // secondary is dark, headings

// Grey
export const grey200 = '#E8E9EF'; // Button Disabled Background
export const grey300 = '#CCCDCE';
export const grey400 = '#B4B9CC'; // Button Disabled Color
export const grey500 = '#EFEFF3';
export const grey600 = '#969EB3';
export const grey700 = '#D9DDED';
export const grey800 = '#F5F6FA';
export const grey900 = '#BABECD';

export const infoBase = '#E8E9EF';
export const infoMain = '#E8E9EF';
export const infoDark = '#CCCDCE';

// Disabled - Button
export const disabledBackground = grey200;
export const disabledColor = grey400;

// Font
export const fontFamily = "'Noto Sans', sans-serif";
export const fontWeightBold = 700;
export const fontWeightHeadings = fontWeightBold;
export const fontSizeBase = 14;

export const lineHeightBase = '17px';

// Spacing
export const marginBottom = 15;

// Breakpoints
// --------------------------------
// Works correctly. theme.breakpoints.down/up doesn't work correctly inside createMuiTheme (they use default values, not custom)
export const xs = 0;
export const sm = 600;
export const md = 768; // 960 was in Material
export const lg = 1024; // 1280 was in Material
export const xl = 1280; // 1920 was in Material

// Media queris. Material createBreakpoints doesn't work inside theme. They use default outdated values
// Down
export const xsDown = `@media (max-width: ${sm - 1}px)`;
export const smDown = `@media (max-width: ${md - 1}px)`;
export const mdDown = `@media (max-width: ${lg - 1}px)`;
export const lgDown = `@media (max-width: ${xl - 1}px)`;

// Up
export const smUp = `@media (min-width: ${sm}px)`;
export const mdUp = `@media (min-width: ${md}px)`;
export const lgUp = `@media (min-width: ${lg}px)`;
export const xlUp = `@media (min-width: ${xl}px)`;

// Shape
export const borderRadius = 6; // Input

// Input
export const inputBorderColor = '#BBC0D4';
export const inputPadding = '18.5px 14px';

// Form Label
export const formLabelColor = grey600;
