import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Typography, Button, IconButton } from '@mui/material';

import { QUERY_AIRCRAFT_ACCESS_PERMISSIONS, QUERY_AVAILABLE_ACCESS_PERMISSIONS, MUTATION_DELETE_AIRCRAFT_ACCESS_PERMISSION } from 'gql';
import { useLazyQuery, useMutation } from '@apollo/client';
import { ComponentLoading, DeleteDialog } from 'components';
import { TicketInfoIcon } from 'customIcons';
import { DataGridPro, GridValueGetterParams, GridRenderCellParams } from '@mui/x-data-grid-pro';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import dayjs from 'dayjs';
import { IBorescopeStatusChoice, IAircraftAccess } from 'types';
import { AddAircraftAccessDialog, UpdateAircraftAccessDialog } from '../../components/Dialog';

import { setAlert } from 'state';

interface AircraftAccessProps {
  aircraftId: string;
  isLoad: boolean;
}

const AircraftAccess: React.FC<AircraftAccessProps> = (props) => {
  const { aircraftId, isLoad } = props;
  const [fetchAircraftAccessPermissions, { data: dataPermissions, loading: loadingPermissions }] =
    useLazyQuery(QUERY_AIRCRAFT_ACCESS_PERMISSIONS);
  const [fetchAvailablePermissions, { data: dataAvailablePermissions }] = useLazyQuery(QUERY_AVAILABLE_ACCESS_PERMISSIONS);
  const [deleteAccessMutation, { data: dataDeleteMutation, loading: loadingDeleteMutation, error: errorDeleteMutation }] = useMutation(
    MUTATION_DELETE_AIRCRAFT_ACCESS_PERMISSION,
  );
  const dispatch = useDispatch();
  const [success, setSuccess] = useState(false);
  const [showTooltip, setShowTooltip] = useState(true);

  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);

  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [selectedPermission, setSelectedPermission] = useState<IAircraftAccess>();

  const [availablePermissions, setAvailablePermissions] = useState<IBorescopeStatusChoice[]>([]);

  const deleteAircraftAccess = async () => {
    await deleteAccessMutation({
      variables: {
        accessId: selectedPermission?.id || '',
      },
    });
  };

  const getNameFromSlug = (value: string) => {
    const matched = availablePermissions.find((item) => item.slug === value);
    if (matched) {
      return matched.name;
    }
    return value;
  };

  useEffect(() => {
    if (isLoad && aircraftId) {
      fetchAircraftAccessPermissions({
        variables: {
          id: parseInt(aircraftId),
        },
      });
    }
  }, [isLoad, aircraftId]);

  useEffect(() => {
    if (success) {
      fetchAircraftAccessPermissions({
        variables: {
          id: parseInt(aircraftId),
        },
        fetchPolicy: 'network-only',
      });
    }
  }, [success]);

  useEffect(() => {
    fetchAvailablePermissions();
  }, []);

  useEffect(() => {
    if (dataAvailablePermissions?.availableAccessPermissions?.length) {
      setAvailablePermissions([...dataAvailablePermissions.availableAccessPermissions]);
    }
  }, [dataAvailablePermissions]);

  useEffect(() => {
    if (errorDeleteMutation) {
      dispatch(setAlert('error', 'Unable to delete access'));
    } else if (dataDeleteMutation) {
      const { ok, error } = dataDeleteMutation.deleteAircraftAccessPermission;
      if (ok) {
        dispatch(setAlert('success', 'Deleted access'));
        setSuccess(true);
      } else if (error) {
        dispatch(setAlert('error', error));
      }
    }
  }, [errorDeleteMutation, dataDeleteMutation, dispatch]);

  return (
    <Box
      sx={{
        p: {
          xs: 2,
          sm: 3,
          md: 4,
          lg: 5,
        },
      }}
    >
      <ComponentLoading loading={loadingPermissions || loadingDeleteMutation}>
        <Box>
          {showTooltip && (
            <Box
              sx={{
                backgroundColor: 'rgba(63, 117, 255, 0.1)',
                px: 2,
                py: 1.5,
                borderRadius: '6px',
                display: 'flex',
                mb: 1.5,
              }}
            >
              <TicketInfoIcon
                sx={{
                  fill: 'none',
                  height: '36px',
                  width: '36px',
                }}
              />
              <Box ml={1.5}>
                <Typography
                  sx={{
                    fontWeight: 700,
                    mb: 0.5,
                    lineHeight: '19px',
                  }}
                >
                  Customize how other users interact with your aircraft's data.
                </Typography>
                <Typography
                  sx={{
                    mb: 0.5,
                    lineHeight: '22px',
                  }}
                >
                  Adjusting these settings allows you to grant or restrict permissions on a per-user basis for specific actions, such as the
                  ability to upload engine data, allow the upload of borescope images, and create detailed borescope reports.
                  <br />
                  Please note, to assign these permissions, the individuals you wish to grant access to must have a Savvy account. If they
                  do not already have an account, they can easily create one for free. This step is necessary before you can grant them
                  permissions.
                </Typography>
                <Typography
                  sx={{
                    color: 'primary.main',
                    textDecoration: 'underline',
                    fontWeight: 600,
                    cursor: 'pointer',
                    lineHeight: '19px',
                  }}
                  onClick={() => {
                    setShowTooltip(false);
                  }}
                >
                  Don't show more
                </Typography>
              </Box>
            </Box>
          )}
          <Button
            variant="contained"
            onClick={() => {
              setSuccess(false);
              setShowCreateModal(true);
            }}
          >
            + Add Access
          </Button>
          <Box
            sx={{
              py: 1,
              width: '100%',
            }}
          >
            {isLoad && (
              <DataGridPro
                sx={{
                  width: '100%',
                  minWidth: '100px',
                  '& .MuiDataGrid-columnSeparator': {
                    display: 'none',
                  },
                  '& .MuiDataGrid-cell:focus': {
                    outline: 'none',
                  },
                  '& .MuiDataGrid-row': {
                    cursor: 'pointer',
                    borderBottom: '1px solid',
                    borderColor: 'grey.200',
                  },
                  '& .MuiDataGrid-columnHeader:focus': {
                    outline: 'none',
                  },
                  border: 'none',
                }}
                autoHeight
                disableColumnResize={true}
                disableSelectionOnClick={true}
                rowsPerPageOptions={[5, 10, 20, 100]}
                pageSize={pageSize}
                onPageSizeChange={(newPage) => setPageSize(newPage)}
                pagination
                showColumnRightBorder={false}
                disableColumnMenu={true}
                page={page}
                onPageChange={(newPage) => setPage(newPage)}
                rows={
                  dataPermissions?.me?.aircraft[0]?.accessPermissions?.length
                    ? [...dataPermissions.me.aircraft[0].accessPermissions].sort((item1: any, item2: any) =>
                        item1.created > item2.created ? -1 : 1,
                      )
                    : []
                }
                columns={[
                  {
                    field: 'name',
                    headerName: 'Name',
                    valueGetter: (params: GridValueGetterParams) => {
                      return params?.row?.contact ? `${params.row.contact.firstName} ${params.row.contact.lastName}` : '';
                    },
                    flex: 0.7,
                  },
                  {
                    field: 'contact',
                    headerName: 'Email',
                    valueGetter: (params: GridValueGetterParams) => {
                      return params.value ? params.value.email : '';
                    },
                    flex: 1,
                  },
                  {
                    field: 'permissions',
                    headerName: 'Permissions',
                    renderCell: (params: GridRenderCellParams) => {
                      if (!params.value?.length) {
                        return '';
                      }
                      return (
                        <Typography
                          sx={{
                            textTransform: 'capitalize',
                            whiteSpace: 'break-spaces',
                          }}
                        >
                          {params.value.map((value: string) => getNameFromSlug(value)).join(' / ')}
                        </Typography>
                      );
                    },
                    flex: 1,
                  },
                  {
                    field: 'created',
                    headerName: 'Added',
                    valueGetter: (params: GridValueGetterParams) => {
                      return params.value ? dayjs(params.value).format('YYYY-MM-DD') : '';
                    },
                    flex: 0.7,
                  },
                  {
                    field: 'role',
                    headerName: 'Role',
                    valueGetter: (params: GridValueGetterParams) => {
                      if (params?.row?.contact) {
                        switch (params.row.contact.role) {
                          case 'C':
                            return 'Client';
                          case 'M':
                            return 'Savvy';
                          case 'T':
                            return 'Technician';
                        }
                      }
                      return '';
                    },
                    flex: 0.7,
                  },
                  {
                    field: 'id',
                    headerName: 'Action',
                    renderCell: (params: GridRenderCellParams<string>) => {
                      return (
                        <Box>
                          <IconButton
                            onClick={() => {
                              const selectPermission = dataPermissions?.me?.aircraft[0]?.accessPermissions?.find(
                                (item: any) => item.id === params.value,
                              );
                              if (selectPermission) {
                                setShowUpdateModal(true);
                                setSelectedPermission(selectPermission);
                              }
                            }}
                            sx={{
                              mr: 1,
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            onClick={(e: any) => {
                              const selectPermission = dataPermissions?.me?.aircraft[0]?.accessPermissions?.find(
                                (item: any) => item.id === params.value,
                              );
                              if (selectPermission) {
                                setSelectedPermission(selectPermission);
                              }
                              setShowDeleteModal(true);
                              setSuccess(false);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      );
                    },
                    flex: 1,
                  },
                ]}
              />
            )}
          </Box>
        </Box>
      </ComponentLoading>
      <AddAircraftAccessDialog
        open={showCreateModal}
        setOpen={setShowCreateModal}
        setSuccess={setSuccess}
        aircraftId={aircraftId}
        availablePermissions={availablePermissions}
      />
      {selectedPermission && (
        <DeleteDialog
          open={showDeleteModal}
          setOpen={setShowDeleteModal}
          onSubmitAction={deleteAircraftAccess}
          title={`Remove Access for ${selectedPermission.contact.firstName} ${selectedPermission.contact.lastName}?`}
          text="Are you sure? After removal, the user will no longer have access to your airplane data."
        />
      )}
      {selectedPermission && (
        <UpdateAircraftAccessDialog
          open={showUpdateModal}
          setOpen={setShowUpdateModal}
          availablePermissions={availablePermissions}
          access={selectedPermission}
          aircraftId={aircraftId}
        />
      )}
    </Box>
  );
};

export default AircraftAccess;
