import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Autocomplete, TextField, Button } from '@mui/material';

import { ComponentLoading } from 'components';

import { IDropDownOption } from 'types';
import { setAlert } from 'state';

import { useMutation } from '@apollo/client';
import { MUTATION_CREATE_ANOMALY } from 'gql';

interface AnomalyComponentProps {
  diffData: any;
  flightId: string;
  anomalyTypes?: IDropDownOption[];
  seriesList: IDropDownOption[];
}

const AnomalyComponent: React.FC<AnomalyComponentProps> = (props) => {
  const { diffData, flightId, anomalyTypes = [], seriesList = [] } = props;

  const [createAnomalyMutation, { data: dataCreateAnomaly, error: errorCreateAnomaly, loading: loadingCreateAnomaly }] =
    useMutation(MUTATION_CREATE_ANOMALY);

  const dispatch = useDispatch();

  const [selectedTypes, setSelectedTypes] = useState<IDropDownOption[]>([]);
  const [selectedSeries, setSelectedSeries] = useState<IDropDownOption[]>([]);
  const [comment, setComment] = useState('');

  const [anomalyTypeError, setAnomalyTypeError] = useState('');
  const [anomalySeriesError, setAnomalySeriesError] = useState('');
  const [commentError, setCommentError] = useState('');

  const changeHmsToSeconds = (value: string) => {
    const hms = value.split(':');

    let seconds = 0;

    if (hms[0] && parseInt(hms[0])) seconds += 3600 * parseInt(hms[0]);

    if (hms[1] && parseInt(hms[1])) seconds += 60 * parseInt(hms[1]);

    if (hms[2] && parseInt(hms[2])) seconds += parseInt(hms[2]);

    return seconds;
  };

  const submit = async () => {
    if (!selectedTypes.length) {
      setAnomalyTypeError('Please select anomaly types');
      return;
    }

    if (!selectedSeries.length) {
      setAnomalySeriesError('Please select anomaly series');
      return;
    }

    if (!comment.length) {
      setCommentError('Please input comment');
      return;
    }

    let startTime = changeHmsToSeconds(diffData.selectedPoint);
    let endTime = changeHmsToSeconds(diffData.secondaryPoint);

    if (startTime > endTime) {
      const tmp = startTime;
      startTime = endTime;
      endTime = tmp;
    }

    const Ids = selectedTypes.map((selectedType: IDropDownOption) => selectedType.id);
    const seriesNames = selectedSeries.map((seriesName: IDropDownOption) => seriesName.name);

    await createAnomalyMutation({
      variables: {
        flightId: flightId,
        anomalyTypes: [...Ids],
        anomalySeries: [...seriesNames],
        comment,
        startTime,
        endTime,
      },
    });
  };

  useEffect(() => {
    if (errorCreateAnomaly) {
      dispatch(setAlert('error', 'Unable to create analysis for anomaly'));
    } else if (dataCreateAnomaly) {
      if (dataCreateAnomaly?.createAnomaly?.ok) {
        dispatch(setAlert('success', 'Anomaly saved'));
        setComment('');
        setSelectedTypes([]);
      } else {
        dispatch(setAlert('error', dataCreateAnomaly?.createAnomaly?.error || 'Unable to create analysis for anomaly'));
      }
    }
  }, [errorCreateAnomaly, dataCreateAnomaly, dispatch]);

  return (
    <Box px={2}>
      {diffData.selectedPoint && diffData.secondaryPoint && (
        <ComponentLoading loading={loadingCreateAnomaly}>
          <Box>
            <Box>
              <Autocomplete
                multiple
                value={selectedTypes}
                options={anomalyTypes}
                getOptionLabel={(option: IDropDownOption) => option.name}
                onChange={(e, value: any) => {
                  setAnomalyTypeError('');
                  setSelectedTypes(value);
                }}
                renderInput={(param) => {
                  return (
                    <TextField
                      {...param}
                      label="Select Anomaly Types"
                      variant="outlined"
                      sx={{
                        mb: 0,
                        '& .Mui-error': {
                          ml: 0,
                        },
                      }}
                      error={Boolean(anomalyTypeError)}
                      helperText={anomalyTypeError}
                    />
                  );
                }}
              />
            </Box>
            <Box mt={2}>
              <Autocomplete
                multiple
                value={selectedSeries}
                options={seriesList}
                getOptionLabel={(option: IDropDownOption) => option.name}
                onChange={(e, value: any) => {
                  setAnomalySeriesError('');
                  setSelectedSeries(value);
                }}
                renderInput={(param) => {
                  return (
                    <TextField
                      {...param}
                      label="Select Anomaly Series"
                      variant="outlined"
                      sx={{
                        mb: 0,
                        '& .Mui-error': {
                          ml: 0,
                        },
                      }}
                      error={Boolean(anomalySeriesError)}
                      helperText={anomalySeriesError}
                    />
                  );
                }}
              />
            </Box>
            <Box py={2}>
              <TextField
                name="comment"
                label="Comment"
                value={comment}
                onChange={(e) => {
                  setCommentError('');
                  setComment(e.target.value);
                }}
                sx={{
                  mb: 0,
                }}
                error={Boolean(commentError)}
                helperText={commentError}
                fullWidth
              />
            </Box>
            <Box
              sx={{
                textAlign: 'center',
              }}
            >
              <Button onClick={submit} variant="contained">
                Submit
              </Button>
            </Box>
          </Box>
        </ComponentLoading>
      )}
    </Box>
  );
};

export default AnomalyComponent;
