import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, IconButton, Popover, Typography, Container, MenuItem, Grid } from '@mui/material';
import { DataGridPro, GridValueGetterParams, GridRenderCellParams } from '@mui/x-data-grid-pro';

import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-mui';

import * as Yup from 'yup';

import MoreVertIcon from '@mui/icons-material/MoreVert';

import { IUnitConversion } from 'types';

import {
  QUERY_AIRCRAFT_CONVERSIONS,
  QUERY_AIRCRAFT_RECENT_FLIGHT_SERIES,
  QUERY_UNIT_CONVERSIONS,
  MUTATION_DELETE_AIRCRAFT_CONVERSION,
  MUTATION_CREATE_AIRCRAFT_CONVERSION,
} from 'gql';
import { useLazyQuery, useMutation } from '@apollo/client';

import { ComponentLoading, ButtonSubmit } from 'components';

import { setAlert } from 'state';

interface UnitConversionProps {
  aircraftId: string;
  isLoad: boolean;
}

const ValidationFormSchema = Yup.object().shape({
  series: Yup.string().required('Required'),
  conversion: Yup.string().required('Required'),
});

const UnitConversion: React.FC<UnitConversionProps> = (props) => {
  const { aircraftId, isLoad } = props;

  const [fetchConversions, { data: dataAircraftConversions, error: errorAircraftConversions, loading: loadingAircraftConversions }] =
    useLazyQuery(QUERY_AIRCRAFT_CONVERSIONS);
  const [fetchRecentFlightSeries, { data: dataRecentFlightSeries, error: errorRecentFlightSeries, loading: loadingRecentFlightSeries }] =
    useLazyQuery(QUERY_AIRCRAFT_RECENT_FLIGHT_SERIES);
  const [fetchUnitConversions, { data: dataUnitConversions, error: errorUnitConversions, loading: loadingUnitConversions }] =
    useLazyQuery(QUERY_UNIT_CONVERSIONS);

  const [deleteConversion, { data: dataDeleteConversion, error: errorDeleteConversion, loading: loadingDeleteConversion }] =
    useMutation(MUTATION_DELETE_AIRCRAFT_CONVERSION);

  const [addConversion, { data: dataAddConversion, error: errorAddConversion, loading: loadingAddConversion }] =
    useMutation(MUTATION_CREATE_AIRCRAFT_CONVERSION);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const [selectedConversionId, setSelectedConversionId] = useState('');
  const [pageSize, setPageSize] = useState(5);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const dispatch = useDispatch();

  const deleteConversionAction = async (conversionId: string) => {
    await deleteConversion({
      variables: {
        conversionId,
      },
    });
  };

  const onAddNewConversion = async (values: any, actions: any) => {
    // console.log(values);
    await addConversion({
      variables: {
        aircraftId: aircraftId,
        series: values.series,
        unitConversionId: values.conversion,
      },
    });

    if (actions.resetForm)
      actions.resetForm({
        values: {
          series: '',
          conversion: '',
        },
      });
  };

  useEffect(() => {
    if (aircraftId && isLoad) {
      fetchConversions({
        variables: {
          aircraftId: parseInt(aircraftId),
        },
      });
    }
  }, [aircraftId, isLoad, fetchConversions]);

  useEffect(() => {
    if (aircraftId && isLoad) {
      fetchRecentFlightSeries({
        variables: {
          aircraftId: parseInt(aircraftId),
        },
      });
    }
  }, [aircraftId, isLoad, fetchRecentFlightSeries]);

  useEffect(() => {
    if (isLoad) fetchUnitConversions();
  }, [isLoad, fetchUnitConversions]);

  useEffect(() => {
    if (errorAircraftConversions) {
      dispatch(setAlert('error', 'Unable to load aircraft conversions'));
    }
  }, [errorAircraftConversions, dispatch]);

  useEffect(() => {
    if (errorRecentFlightSeries) {
      dispatch(setAlert('error', 'Unable to load recent flight series'));
    }
  }, [errorRecentFlightSeries, dispatch]);

  useEffect(() => {
    if (errorDeleteConversion) {
      dispatch(setAlert('error', 'Unable to delete conversion'));
    } else if (dataDeleteConversion) {
      if (dataDeleteConversion.deleteAircraftConversion?.ok) {
        dispatch(setAlert('success', 'Deleted conversion'));
      } else {
        dispatch(setAlert('error', dataDeleteConversion.deleteAircraftConversion?.error || 'Unable to delete conversion'));
      }
    }
  }, [errorDeleteConversion, dataDeleteConversion, dispatch]);

  useEffect(() => {
    if (errorUnitConversions) {
      dispatch(setAlert('error', 'Unable to load unit conversion data'));
    }
  }, [errorUnitConversions, dispatch]);

  useEffect(() => {
    if (errorAddConversion) {
      dispatch(setAlert('error', 'Unable to add new conversion'));
    } else if (dataAddConversion) {
      if (dataAddConversion.createAircraftConversion?.ok) {
        dispatch(setAlert('success', 'Added new conversion'));
      } else {
        dispatch(setAlert('error', dataAddConversion.createAircraftConversion?.error || 'Unable to add new conversion'));
      }
    }
  }, [errorAddConversion, dataAddConversion, dispatch]);

  return (
    <Box
      sx={{
        p: {
          xs: 2,
          sm: 3,
          md: 4,
          lg: 5,
        },
      }}
    >
      <ComponentLoading
        loading={loadingAircraftConversions || loadingRecentFlightSeries || loadingDeleteConversion || loadingUnitConversions}
      >
        <Box
          py={1}
          sx={{
            width: '100%',
          }}
        >
          {!!dataAircraftConversions?.me?.aircraft[0]?.aircraftConversions?.length && (
            <DataGridPro
              sx={{
                width: '100%',
                minWidth: '100px',
                '& .MuiDataGrid-columnHeader': {
                  padding: 0,
                },
                '& .MuiDataGrid-cell': {
                  padding: 0,
                },
                '& .MuiDataGrid-columnSeparator': {
                  display: 'none',
                },
                '& .MuiDataGrid-cell:focus': {
                  outline: 'none',
                },
                '& .MuiDataGrid-row': {
                  cursor: 'pointer',
                  borderBottom: '1px solid',
                  borderColor: 'grey.200',
                },
                '& .MuiDataGrid-columnHeader:focus': {
                  outline: 'none',
                },
                border: 'none',
              }}
              autoHeight
              disableColumnResize={true}
              disableSelectionOnClick={true}
              rowsPerPageOptions={[5, 10, 20, 100]}
              pageSize={pageSize}
              onPageSizeChange={(newPage) => setPageSize(newPage)}
              pagination
              showColumnRightBorder={false}
              loading={!isLoad}
              disableColumnMenu={true}
              rows={
                dataAircraftConversions?.me?.aircraft[0]?.aircraftConversions
                  ? dataAircraftConversions?.me?.aircraft[0]?.aircraftConversions
                  : []
              }
              columns={[
                {
                  field: 'seriesName',
                  headerName: 'Series',
                  flex: 1,
                },
                {
                  field: 'fromName',
                  headerName: 'Convert From',
                  valueGetter: (params: GridValueGetterParams) => {
                    return params.row.unitconversion?.fromName ? params.row.unitconversion?.fromName : '';
                  },
                  flex: 1,
                },
                {
                  field: 'toName',
                  headerName: 'Convert To',
                  valueGetter: (params: GridValueGetterParams) => {
                    return params.row.unitconversion?.toName ? params.row.unitconversion?.toName : '';
                  },
                  flex: 1,
                },
                {
                  field: 'id',
                  headerName: 'Action',
                  renderCell: (params: GridRenderCellParams<string>) => {
                    return (
                      <IconButton
                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                          setAnchorEl(e.currentTarget);
                          setSelectedConversionId(params.value || '');
                        }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    );
                  },
                },
              ]}
            />
          )}
          <Box py={2}>
            <Container maxWidth="md" fixed>
              <Box>
                <Typography
                  variant="h4"
                  sx={{
                    textAlign: 'center',
                  }}
                  gutterBottom
                >
                  New Conversion
                </Typography>
                {dataRecentFlightSeries?.me?.aircraft[0]?.recentFlightSeries && dataUnitConversions?.unitConversions && (
                  <Formik
                    initialValues={{
                      series: '',
                      conversion: '',
                    }}
                    validationSchema={ValidationFormSchema}
                    onSubmit={onAddNewConversion}
                  >
                    {({ isSubmitting, handleChange, handleBlur }) => {
                      return (
                        <Form>
                          <Field
                            name="series"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            component={TextField}
                            label="Series Name *"
                            select
                            variant="outlined"
                            fullWidth
                            FormHelperTextProps={{
                              style: {
                                color: 'red',
                                marginLeft: 0,
                              },
                            }}
                          >
                            {dataRecentFlightSeries?.me?.aircraft[0]?.recentFlightSeries?.map((series: string, key: number) => {
                              return (
                                <MenuItem key={key} value={series}>
                                  {series}
                                </MenuItem>
                              );
                            })}
                          </Field>
                          <Field
                            name="conversion"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            component={TextField}
                            label="Conversion *"
                            select
                            variant="outlined"
                            fullWidth
                            FormHelperTextProps={{
                              style: {
                                color: 'red',
                                marginLeft: 0,
                              },
                            }}
                          >
                            {dataUnitConversions?.unitConversions?.map((series: IUnitConversion, key: number) => {
                              return (
                                <MenuItem key={key} value={series.id}>
                                  {`${series.fromName} -> ${series.toName}`}
                                </MenuItem>
                              );
                            })}
                          </Field>
                          <Grid container spacing={2}>
                            <Grid item md={3}></Grid>
                            <Grid item md={6}>
                              <ButtonSubmit
                                text="Add"
                                loading={isSubmitting || loadingAddConversion}
                                disabled={isSubmitting || loadingAddConversion}
                              />
                            </Grid>
                          </Grid>
                        </Form>
                      );
                    }}
                  </Formik>
                )}
              </Box>
            </Container>
          </Box>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'right',
            }}
          >
            <Typography
              paragraph
              style={{
                padding: '10px',
                marginBlock: '0',
                cursor: 'pointer',
              }}
              onClick={() => {
                setAnchorEl(null);
                deleteConversionAction(selectedConversionId);
              }}
            >
              DELETE
            </Typography>
          </Popover>
        </Box>
      </ComponentLoading>
    </Box>
  );
};

export default UnitConversion;
