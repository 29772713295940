import { gql } from '@apollo/client';
import { FRAGMENT_PREBUY_INQUIRY } from 'gql';

export const QUERY_FUTURE_COHORTS = gql`
  query FutureCohorts {
    futureCohorts {
      aircraft
      engine
      count
      subscriptions
    }
  }
`;

export const QUERY_PREBUY_INQUIRY_SEARCH = gql`
  ${FRAGMENT_PREBUY_INQUIRY}
  query SavvyPrebuyInquirySearch($search: String, $limit: Int, $offset: Int) {
    savvy {
      id
      search(search: $search, limit: $limit) {
        prebuys(offset: $offset, limit: $limit) {
          count
          results {
            ...PrebuyInquiry
          }
        }
      }
    }
  }
`;

export const QUERY_PREBUY_INQUIRY = gql`
  ${FRAGMENT_PREBUY_INQUIRY}
  query PrebuyInquiry($prebuyInquiryId: ID) {
    prebuyInquiry(prebuyInquiryId: $prebuyInquiryId) {
      ...PrebuyInquiry
      relatedTickets {
        id
        subject
      }
    }
  }
`;

export const QUERY_FINANCIAL_FILES = gql`
  query FinancialFiles {
    financialFiles {
      name
      date
      downloadUrl
    }
  }
`;

export const QUERY_QIF_STATUS = gql`
  query QifStatus {
    qifStatus {
      s3Key
      status
    }
  }
`;

export const QUERY_AVAILABLE_TIMEZONES = gql`
  query AvailableTimezones {
    availableTimezones
  }
`;

export const QUERY_REPORT_CARD_PARAMETER_NAMES = gql`
  query ReportCardParameterNames {
    reportCardParameterNames
  }
`;

export const QUERY_REPORT_CARD_PARAMETER_UNITS = gql`
  query ReportCardParameterUnits {
    reportCardParameterUnits
  }
`;

export const QUERY_VENDOR_PAYMENTS = gql`
  query VendorPayments {
    savvy {
      id
      vendorPayments {
        id
        status
        source
        amount
        creator {
          id
          firstName
          lastName
        }
        payee {
          id
          firstName
          lastName
        }
        created
        updated
        description
        startTime
        exportToQif
        error
      }
    }
  }
`;

export const QUERY_ALLOWED_PAYEES = gql`
  query AllowedPayees {
    savvy {
      id
      allowedPayees {
        id
        firstName
        lastName
      }
    }
  }
`;
