import React, { useState, useEffect } from 'react';

import { Dialog, DialogContent, DialogActions, Typography, Button, Grid, CircularProgress } from '@mui/material';
import { AircraftSuggestions } from './';

import { useFuzzyEngineMonitorManufacturers, useInputWithSuggestions } from 'hooks';

import { IOptionIdName } from 'types';

import { useMutation } from '@apollo/client';
import { MUTATION_CREATE_ENGINE_MONITOR_MANUFACTURER } from 'gql';

import * as Yup from 'yup';
import { ButtonSubmit } from 'components';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-mui';

interface IDialogAddEngineMonitorSuggestions {
  engineMonitorManufacturer: IOptionIdName; // Autocomplete value
  setEngineMonitorManufacturer: (value: IOptionIdName | null) => void; // Set Autocomplete value
  open: boolean;
  setOpen: (value: boolean) => void;
}

const validationSchema = Yup.object({
  engineMonitorManufacturer: Yup.string()
    .min(1, 'Too short: 1-50 characters required')
    .max(50, 'Too long: 1-50 characters required')
    .required('Required'),
});

interface createFormValues {
  engineMonitorManufacturer: string;
}

const DialogAddEngineMonitorManufacturerSuggestions: React.FC<IDialogAddEngineMonitorSuggestions> = (props) => {
  const { engineMonitorManufacturer, setEngineMonitorManufacturer, open, setOpen } = props;

  const [addState, setAddState] = useState(false);

  // Monitor Manufacturer and Model Inputs Logic Start
  // -----------------------------------------
  const [engineMonitorManufacturerInput, setEngineMonitorManufacturerInput] = useState('');

  const [engineMonitorManufacturerInputTmp, setEngineMonitorManufacturerInputTmp] = useState('');

  const { showFuzzy: showFuzzyEngineMonitorManufacturers } = useInputWithSuggestions({ input: engineMonitorManufacturerInput }); //  Manufacturer Input id/name with Suggestions

  const handleSuggestionClickManufacturerHelper = (suggestion: IOptionIdName) => {
    setEngineMonitorManufacturer(suggestion);
    handleClose();
  };

  const [
    createEngineMonitorManufacturer,
    {
      data: dataCreateEngineMonitorManufacturer,
      error: errorCreateEngineMonitorManufacturer,
      loading: loadingCreateEngineMonitorManufacturer,
    },
  ] = useMutation(MUTATION_CREATE_ENGINE_MONITOR_MANUFACTURER);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (engineMonitorManufacturer?.id) return;
    setEngineMonitorManufacturerInput(engineMonitorManufacturer?.name ? engineMonitorManufacturer.name : '');
  }, [engineMonitorManufacturer, setEngineMonitorManufacturerInput]);

  useEffect(() => {
    if (!engineMonitorManufacturerInputTmp) {
      setEngineMonitorManufacturerInput('');
      setLoading(false);
      return;
    }

    setLoading(true);

    const timeout: any = setTimeout(() => {
      setEngineMonitorManufacturerInput(engineMonitorManufacturerInputTmp);
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [engineMonitorManufacturerInputTmp, setEngineMonitorManufacturerInput]);

  // Fuzzy Logic
  const fuzzyEngineMonitorManufacturers = useFuzzyEngineMonitorManufacturers({ engineMonitorManufacturer, engineMonitorManufacturerInput }); // Fuzzy Manufacturers

  const [responseError, setResponseError] = useState('');

  React.useEffect(() => {
    setLoading(false);
    if (!fuzzyEngineMonitorManufacturers?.length) setAddState(true);
    else setAddState(false);
  }, [fuzzyEngineMonitorManufacturers]);

  // Close (Cancel click)
  const handleClose = () => {
    setOpen(false);
    setAddState(false);
    setEngineMonitorManufacturerInput('');
  };

  const onSubmit = async (values: createFormValues) => {
    // setEngineMonitorManufacturer({
    //   id: '',
    //   name: values.engineMonitorManufacturer,
    // });

    // handleClose();
    await createEngineMonitorManufacturer({
      variables: {
        name: values.engineMonitorManufacturer,
      },
    });
  };

  useEffect(() => {
    if (errorCreateEngineMonitorManufacturer) {
      setResponseError('Failed to create new engine monitor manufacturer, please reload page and retry.');
    } else if (dataCreateEngineMonitorManufacturer) {
      const {
        ok,
        engineMonitorManufacturer: newEngineMonitorManufacturer,
        error,
      } = dataCreateEngineMonitorManufacturer.createEngineMonitorManufacturer;

      if (!ok) {
        setResponseError(error || 'Failed to create new engine monitor manufacturer, please reload page and retry.');
      } else {
        setEngineMonitorManufacturer(newEngineMonitorManufacturer);
        setOpen(false);
        setAddState(false);
        setEngineMonitorManufacturerInputTmp('');
      }
    }
  }, [
    dataCreateEngineMonitorManufacturer,
    errorCreateEngineMonitorManufacturer,
    setEngineMonitorManufacturer,
    setOpen,
    setAddState,
    setEngineMonitorManufacturerInputTmp,
  ]);

  const notListClick = () => {
    setAddState(true);
  };

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={handleClose}
      PaperProps={{
        style: {
          height: '400px',
        },
      }}
    >
      <Formik
        initialValues={{
          engineMonitorManufacturer: engineMonitorManufacturer?.name,
        }}
        onSubmit={onSubmit}
        keepMounted
        validationSchema={validationSchema}
      >
        {({ isSubmitting, handleChange, handleBlur }) => {
          return (
            <Form
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <DialogContent>
                <Typography variant="h2" gutterBottom align="center">
                  Add engine monitor manufacturer
                </Typography>
                <Field
                  name="engineMonitorManufacturer"
                  label="EngineMonitor Manufacturer *"
                  onBlur={handleBlur}
                  onChange={(e: any) => {
                    handleChange(e);
                    setEngineMonitorManufacturerInputTmp(e.target.value);
                  }}
                  placeholder="Start typing to search..."
                  component={TextField}
                  fullWidth
                  variant="outlined"
                  FormHelperTextProps={{
                    style: {
                      color: 'red',
                      marginLeft: 0,
                    },
                  }}
                  autoFocus
                  inputProps={{ maxLength: 50 }}
                  helperText={responseError ? responseError : ''}
                />
                {loading && <CircularProgress color="inherit" size={20} variant="indeterminate" />}
                {!loading && !addState && showFuzzyEngineMonitorManufacturers && (
                  <AircraftSuggestions
                    label="Did you perhaps mean:"
                    suggestions={fuzzyEngineMonitorManufacturers}
                    handleSuggestionClick={handleSuggestionClickManufacturerHelper}
                    notListLabel="My engine monitor manufacturer is not listed"
                    notListClick={notListClick}
                  />
                )}
              </DialogContent>

              <DialogActions>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Button color="secondary" variant="outlined" onClick={handleClose} fullWidth>
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <ButtonSubmit text="Add" loading={isSubmitting || loadingCreateEngineMonitorManufacturer} disabled={!addState} />
                  </Grid>
                </Grid>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default DialogAddEngineMonitorManufacturerSuggestions;
