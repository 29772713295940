import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dialog, DialogContent, DialogActions, Typography, Box, TextField, Grid, Button, FormControlLabel, Checkbox } from '@mui/material';
import { IBorescopeStatusChoice } from 'types';
import { MUTATION_CREATE_AIRCRAFT_ACCESS_PERMISSION } from 'gql';
import { useMutation } from '@apollo/client';
import { ComponentLoading } from 'components';
import { setAlert } from 'state';

interface AddAircraftAccessDialogProps {
  aircraftId: string;
  availablePermissions: IBorescopeStatusChoice[];
  open: boolean;
  setOpen: (value: boolean) => void;
  setSuccess?: (value: boolean) => void;
}

const AddAircraftAccessDialog: React.FC<AddAircraftAccessDialogProps> = (props) => {
  const { open, setOpen, aircraftId, availablePermissions, setSuccess } = props;
  const [createMutation, { data: dataMutation, loading: loadingMutation, error: errorMutation }] = useMutation(
    MUTATION_CREATE_AIRCRAFT_ACCESS_PERMISSION,
  );
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  const [permissions, setPermissions] = useState<string[]>([]);
  const [permissionError, setPermissionError] = useState('');

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async () => {
    if (!email) {
      setEmailError('Please input email');
      return;
    }

    if (!permissions.length) {
      setPermissionError('Please select at least one permission');
      return;
    }

    await createMutation({
      variables: {
        aircraftId,
        email,
        permissions,
      },
    });
  };

  useEffect(() => {
    if (errorMutation) {
      dispatch(setAlert('error', 'Unable to add new access'));
    } else if (dataMutation) {
      const { ok, error } = dataMutation.createOrUpdateAircraftAccessPermission;
      if (ok) {
        dispatch(setAlert('success', 'Access was added'));
        setSuccess && setSuccess(true);
        setOpen(false);
      } else if (error) {
        dispatch(setAlert('error', error));
      }
    }
  }, [errorMutation, dataMutation, setOpen, setSuccess, dispatch]);

  return (
    <Dialog open={open} fullWidth onClose={handleClose}>
      <DialogContent>
        <Typography variant="h2" gutterBottom align="center">
          Add Access
        </Typography>
        <ComponentLoading loading={loadingMutation}>
          <Box>
            <TextField
              label="Email *"
              name="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setEmailError('');
              }}
              type="email"
              error={!!emailError}
              helperText={emailError}
              FormHelperTextProps={{
                sx: {
                  ml: 0,
                },
              }}
              fullWidth
            />
            <Box py={1}>
              <Typography
                sx={{
                  fontWeight: 700,
                }}
              >
                Access Type
              </Typography>
            </Box>
            {availablePermissions.map((availablePermission: IBorescopeStatusChoice, key: number) => {
              return (
                <Box key={key}>
                  <FormControlLabel
                    label={
                      <Typography
                        sx={{
                          textTransform: 'capitalize',
                        }}
                      >
                        {availablePermission.name}
                      </Typography>
                    }
                    control={
                      <Checkbox
                        checked={permissions.includes(availablePermission.slug)}
                        onChange={(event: any) => {
                          setPermissionError('');
                          if (permissions.includes(availablePermission.slug)) {
                            const newPermissions = permissions.filter((item) => item !== availablePermission.slug);
                            setPermissions([...newPermissions]);
                          } else {
                            permissions.push(availablePermission.slug);
                            setPermissions([...permissions]);
                          }
                        }}
                        sx={{
                          padding: 0,
                        }}
                      />
                    }
                    sx={{
                      ml: 0,
                      mb: 1,
                    }}
                  />
                </Box>
              );
            })}
            {!!permissionError && <Typography color="error">{permissionError}</Typography>}
          </Box>
        </ComponentLoading>
      </DialogContent>

      <DialogActions>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button variant="outlined" onClick={handleClose} disabled={loadingMutation} fullWidth>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="contained" onClick={onSubmit} disabled={loadingMutation} fullWidth>
              Add Access
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default AddAircraftAccessDialog;
