import { SET_PRICE_FOR_SERVICE, SET_SIGNUP_INFO } from 'state/types';

// Initial state (user data)
const initialState = {
  price: 0,
  aircraftId: '',
  subscriptionId: '',
};

// Reducer
const planReducer = (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    // Login
    case SET_PRICE_FOR_SERVICE:
      return {
        ...state,
        price: payload.price,
      };
    case SET_SIGNUP_INFO:
      return {
        ...state,
        aircraftId: payload.aircraftId,
        subscriptionId: payload.subscriptionId,
      };
    default:
      return state;
  }
};

export default planReducer;
