import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Tabs, Tab } from '@mui/material';

import { QUERY_ME_AIRCRAFT_LOGS, QUERY_ME_AIRCRAFT_LOGBOOKS } from 'gql';
import { useLazyQuery } from '@apollo/client';
import { setAlert } from 'state';

import { ComponentLoading, SimpleTabPanel, a11yProps } from 'components';
import { LogDetail } from '../EditComponents';
import { Logbooks } from 'pages/SavvyAircraft/components/Tabs';

interface LogsProps {
  isLoad: boolean;
  aircraftId: string;
}

const Logs: React.FC<LogsProps> = (props) => {
  const { isLoad, aircraftId } = props;

  const [fetchLogs, { data: dataLogs, error: errorLogs, loading: loadingLogs }] = useLazyQuery(QUERY_ME_AIRCRAFT_LOGS);
  const [fetchLogbooks, { data: dataLogbooks, error: errorLogbooks, loading: loadingLogbooks }] = useLazyQuery(QUERY_ME_AIRCRAFT_LOGBOOKS);
  const dispatch = useDispatch();

  const [tabValue, setTabValue] = useState(0);
  const handleChange = (event: React.ChangeEvent<unknown>, newValue: number) => {
    setTabValue(newValue);
  };

  const [logbookId, setLogbookId] = useState('');

  useEffect(() => {
    if (isLoad && aircraftId) {
      fetchLogbooks({
        variables: {
          id: parseInt(aircraftId),
        },
      });
    }
  }, [isLoad, aircraftId, fetchLogbooks]);

  useEffect(() => {
    if (errorLogbooks) {
      dispatch(setAlert('error', 'Unable to load aircraft logbooks'));
    }
  }, [errorLogbooks, dispatch]);

  useEffect(() => {
    if (isLoad && aircraftId) {
      fetchLogs({
        variables: {
          id: parseInt(aircraftId),
        },
      });
    }
  }, [isLoad, aircraftId, fetchLogs]);

  useEffect(() => {
    if (errorLogs) {
      dispatch(setAlert('error', 'Unable to load aircraft logs'));
    }
  }, [errorLogs, dispatch]);

  return (
    <Box
      sx={{
        p: {
          xs: 2,
          sm: 3,
          md: 4,
          lg: 5,
        },
      }}
    >
      <ComponentLoading loading={loadingLogs || loadingLogbooks}>
        <Tabs
          value={tabValue}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{
            '& .MuiTabs-indicator': {
              backgroundColor: 'error.dark',
            },
            borderBottom: '1px solid',
            borderBottomColor: 'grey.200',
            '& .MuiTabs-flexContainer': {
              justifyContent: 'flex-start',
            },
          }}
          variant={'standard'}
        >
          <Tab
            label="Logbooks"
            {...a11yProps(0)}
            sx={{
              py: 2,
              px: 1,
              '&.Mui-selected': {
                color: 'error.dark',
              },
              fontSize: '16px',
              lineHeight: '22px',
              fontWeight: 600,
            }}
          />
          <Tab
            label="Maintenance Entries"
            {...a11yProps(1)}
            sx={{
              py: 2,
              px: 1,
              '&.Mui-selected': {
                color: 'error.dark',
              },
              fontSize: '16px',
              lineHeight: '22px',
              fontWeight: 600,
            }}
          />
        </Tabs>
        <Box>
          <SimpleTabPanel value={tabValue} index={0}>
            {isLoad && tabValue === 0 && (
              <Logbooks
                logbooks={dataLogbooks?.me?.aircraft[0]?.logbooks || []}
                aircraftId={aircraftId}
                maintenanceEntries={dataLogs?.me?.aircraft[0]?.maintenanceEntries || []}
                setTabValue={setTabValue}
                setLogbookId={setLogbookId}
              />
            )}
          </SimpleTabPanel>
          <SimpleTabPanel value={tabValue} index={1}>
            {isLoad && tabValue === 1 && (
              <LogDetail
                aircraft={dataLogs?.me?.aircraft[0]}
                logbookId={logbookId}
                setLogbookId={setLogbookId}
                logbooks={dataLogbooks?.me?.aircraft[0]?.logbooks || []}
              />
            )}
          </SimpleTabPanel>
        </Box>
      </ComponentLoading>
    </Box>
  );
};

export default Logs;
