import { createTheme, ThemeOptions } from '@mui/material/styles';

import {
  fontFamily,
  fontSizeBase,
  lineHeightBase,
  primaryMain,
  primaryHover,
  primaryDark,
  warningMain,
  warningLight,
  warningDark,
  infoMain,
  infoDark,
  textPrimary,
  grey200,
  grey300,
  grey400,
  grey500,
  grey600,
  grey700,
  grey800,
  grey900,
  marginBottom,
  borderRadius,
  disabledBackground,
  disabledColor,
  xs,
  sm,
  md,
  lg,
  xl,
  errorMain,
  errorDark,
  successMain,
  successDark,
  formLabelColor,
  inputBorderColor,
  contrast,
  // xsDown,
  smDown,
  mdDown,
  // lgDown,
  // smUp,
  mdUp,
  lgUp,
  // xlUp,
} from './variables';

// Customize theme
// https://material-ui.com/customization/components/#5-global-theme-variation
const theme = createTheme({
  typography: {
    fontFamily: fontFamily,
    htmlFontSize: fontSizeBase,
    fontSize: fontSizeBase,

    h1: {
      fontSize: 32,
      fontWeight: 500,
      color: primaryDark,
      [smDown]: {
        fontSize: 28,
      },
    },
    h2: {
      fontSize: 24,
      fontWeight: 500,
      color: primaryDark,
      [smDown]: {
        fontSize: 22,
      },
    },
    h3: {
      fontSize: 18,
      fontWeight: 500,
      color: primaryDark,
    },
    h4: {
      fontSize: 16,
      fontWeight: 500,
      color: primaryDark,
    },
    h5: {
      fontSize: 14,
      fontWeight: 500,
      color: primaryDark,
    },
    h6: {
      fontSize: 12,
      fontWeight: 500,
      color: primaryDark,
    },
    body1: {
      fontSize: fontSizeBase,
      lineHeight: lineHeightBase,
      marginBottom: 0,
    },
    button: {
      fontWeight: 600,
      textTransform: 'none',
    },
  },
  palette: {
    primary: {
      main: primaryMain,
      // dark: primaryDark, // Primary Button Hover
    },
    error: {
      main: errorMain,
      dark: errorDark,
    },
    text: {
      primary: textPrimary,
      secondary: primaryDark,
      disabled: contrast,
    },
    grey: {
      200: grey200, // divider
      300: grey300,
      400: grey400,
      500: grey500,
      600: grey600,
      700: grey700,
      800: grey800,
      900: grey900,
    },
    background: {
      default: '#fff',
    },
    action: {
      selected: 'rgba(0, 0, 0, 0.1)',
      hover: 'rgba(0, 0, 0, 0.09)', // Skeleton
    },
    success: {
      main: successMain,
      dark: successDark,
    },
    warning: {
      main: warningMain,
      light: warningLight,
      dark: warningDark,
    },
    info: {
      main: infoMain,
      dark: infoDark,
    },
    secondary: {
      main: textPrimary,
    },
  },
  // Custom breakpoints
  breakpoints: {
    values: {
      xs,
      sm,
      md,
      lg,
      xl,
    },
  },
  shape: {
    borderRadius: borderRadius,
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          borderBottom: `${grey200} 1px solid`,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        paragraph: {
          marginBottom: marginBottom,
        },
        gutterBottom: {
          marginBottom: marginBottom,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 40,
          transitionProperty: 'all',
          boxShadow: 'none',

          // Disabled All
          '&.MuiButton-contained.Mui-disabled, &.MuiButton-outlined.Mui-disabled': {
            backgroundColor: disabledBackground,
            borderColor: disabledBackground,
            color: disabledColor,
          },
        },
        outlined: {
          borderWidth: '2px !important',
        },
        // Primary Contained
        containedPrimary: {
          '&:hover': {
            backgroundColor: primaryHover,
          },
        },
        // Primary Outlined
        outlinedPrimary: {
          borderColor: primaryMain,
          '&:hover, &:focus': {
            backgroundColor: primaryMain,
            color: '#fff',
          },
        },
        containedInfo: {
          backgroundColor: infoMain,
          '&:hover': {
            backgroundColor: infoDark,
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          cursor: 'pointer', // pointer even for links without href. For example links that opens dialog
        },
      },
      defaultProps: {
        underline: 'hover',
      },
    },
    MuiFormGroup: {
      styleOverrides: {
        root: {
          marginBottom: marginBottom, // Form group has its own margin
          '& .MuiFormControl-root': {
            marginBottom: 0, // Make input error text right after input, without bottom margin
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          marginBottom: marginBottom,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: inputBorderColor,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          paddingLeft: 5,
          paddingRight: 5,
          fontSize: fontSizeBase,
          color: grey600,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: inputBorderColor,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          // fontSize: fontSizeBase,
          marginBottom: 10,
          color: formLabelColor,
        },
      },
    },
    // Checkbox Error
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          '&.Mui-Error': {
            color: errorMain, // Label
            '& .MuiSvgIcon-root': {
              fill: errorMain, // Icon
            },
          },
        },
      },
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          padding: 0,
          marginBottom: marginBottom,
          '& .MuiStepLabel-label': {
            fontWeight: 600,
            color: grey400,
            [smDown]: {
              fontSize: 14,
            },
          },
          '& .MuiStepLabel-iconContainer': {
            width: 55,
            height: 55,
            [smDown]: {
              width: 38,
              height: 38,
            },
          },
          '& .MuiStepIcon-root': {
            width: '100%',
            height: '100%',
            border: `${grey400} 2px solid`,
            borderRadius: '50%',
            color: '#fff',
          },
          '& .MuiStepIcon-text': {
            fill: grey400,
            fontSize: 10,
            fontWeight: 500,
          },
          '& .MuiStepConnector-line': {
            borderColor: grey400,
            borderTopWidth: 2,
          },
          '& .MuiStepConnector-alternativeLabel': {
            top: 25,
            left: 'calc(-50% + 26px)',
            right: 'calc(50% + 26px)',
            [smDown]: {
              top: 18,
            },
          },
          // Active and Completed
          '& .MuiStepIcon-root.Mui-completed, & .MuiStepIcon-root.Mui-active': {
            position: 'relative',
            zIndex: 2,
            color: primaryMain,
            borderColor: '#fff',
            boxShadow: `0 0 0 2px ${grey400}`,
          },
          '& .MuiStepLabel-active, & .MuiStepLabel-completed': {
            color: grey600,
          },
          '& .MuiStepLabel-active': {
            color: primaryMain,
          },
          // Active
          '& .MuiStepIcon-root.Mui-active': {
            fill: primaryMain,
            boxShadow: `0 0 0 2px ${primaryMain}`,

            '& .MuiStepIcon-text': {
              fill: '#fff',
            },
          },
          '& .MuiStepConnector-active': {
            '& .MuiStepConnector-line': {
              borderColor: grey400,
            },
          },
          // Completed
          '& .MuiStepIcon-root.Mui-completed': {
            fill: grey600,
          },
        },
      },
    },
    // Dialog
    MuiDialogContent: {
      styleOverrides: {
        root: {
          '&:first-of-child': {
            paddingTop: 25,
          },
          '&:last-of-child': {
            paddingBottom: 25,
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          paddingBottom: 25,
          paddingRight: 24, // same as DialogContent padding
          paddingLeft: 24,
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingRight: 20,
          paddingLeft: 20,
          [mdUp]: {
            paddingRight: 30,
            paddingLeft: 30,
          },
          [lgUp]: {
            paddingRight: 40,
            paddingLeft: 40,
          },
          [mdDown]: {
            paddingRight: 0,
            paddingLeft: 0,
          },
          '&.MuiContainer-maxWidthLg': {
            maxWidth: '100%', // Default container (without maxWidth attribute)
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          marginBottom: marginBottom,
        },
      },
    },
  },
} as ThemeOptions);

export default theme;
