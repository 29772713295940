import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Tabs, Tab } from '@mui/material';
import InformationTab from './InformationTab';
import AnalysisTab from './AnalysisTab';
import FlightMapTab from './FlightMapTab';
import { IFlightData, IDropDownOption, IComment, IFlightChartSetting, IAircraftSetting } from 'types';
import { ModeManager } from '../../components/chartComponents';
import { isTwinEngine } from 'helpers';
import { SET_ANALYSIS_TAB } from 'state/types';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, index, value, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      <Box>{children}</Box>
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

interface FlightInfoBoxProps {
  flightData: IFlightData;
  modeManager: ModeManager;
  diffData: any;
  analysisMode: string;
  setAnalysisMode: (value: string) => void;
  chartNum: number;
  setChartNum: (value: number) => void;
  isShare?: boolean;
  anomalyTypes?: IDropDownOption[];
  aircraftComments?: IComment[];
  contactComments?: IComment[];
  settings: IFlightChartSetting | undefined;
  setPresetMode?: (value: string) => void;
  presetMode: string;
  aircraftSettings: IAircraftSetting[];
}

const FlightInfoBox: React.FC<FlightInfoBoxProps> = (props) => {
  const {
    flightData,
    modeManager,
    diffData,
    analysisMode,
    setAnalysisMode,
    chartNum,
    setChartNum,
    isShare = false,
    anomalyTypes,
    aircraftComments,
    contactComments,
    settings,
    setPresetMode,
    presetMode,
    aircraftSettings,
  } = props;

  const [tabIndex, setTabIndex] = useState(0);

  const previousTabIndexRef = useRef<number | undefined>(undefined);

  const dispatch = useDispatch();
  const { isSavvy } = useSelector((state: any) => state.auth);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    dispatch({
      type: SET_ANALYSIS_TAB,
      payload: {
        isAnalysisTab: newValue === 0,
      },
    });
    setTabIndex(newValue);
  };

  useEffect(() => {
    if (previousTabIndexRef.current !== tabIndex) {
      if (previousTabIndexRef.current === 0) {
        setAnalysisMode('');
        modeManager._normal = true;
        modeManager._diff = false;
        modeManager._rate = false;
        modeManager._gami_single = false;
        modeManager._gami_right = false;
        modeManager._gami_left = false;
        modeManager._mag_left = false;
        modeManager._mag_right = false;
        modeManager._mag_single = false;
        modeManager._anomaly = false;
        modeManager.clearAnalysis();
      }
      previousTabIndexRef.current = tabIndex;
    }
  }, [tabIndex, setAnalysisMode, modeManager]);

  return (
    <Box>
      <Box>
        <Box>
          <Tabs
            value={tabIndex}
            onChange={handleChange}
            aria-label="basic tabs example"
            sx={{
              '& .MuiTabs-indicator': {
                backgroundColor: 'error.dark',
              },
              borderBottom: '1px solid',
              borderBottomColor: 'grey.200',
              '& .MuiTabs-flexContainer': {
                justifyContent: 'space-between',
              },
            }}
          >
            <Tab
              label={isSavvy ? 'Analysis' : 'Information'}
              {...a11yProps(0)}
              sx={{
                py: 3,
                px: 2.5,
                '&.Mui-selected': {
                  color: 'error.dark',
                },
                fontSize: '16px',
                lineHeight: '22px',
                fontWeight: 600,
              }}
            />
            <Tab
              label="Flight Map"
              {...a11yProps(1)}
              sx={{
                py: 3,
                px: 2.5,
                '&.Mui-selected': {
                  color: 'error.dark',
                },
                fontSize: '16px',
                lineHeight: '22px',
                fontWeight: 600,
              }}
            />
            {!isSavvy && (
              <Tab
                label={isSavvy ? 'Information' : 'Analysis'}
                {...a11yProps(2)}
                sx={{
                  py: 3,
                  px: 2.5,
                  '&.Mui-selected': {
                    color: 'error.dark',
                  },
                  fontSize: '16px',
                  lineHeight: '22px',
                  fontWeight: 600,
                }}
              />
            )}
          </Tabs>
        </Box>
        <TabPanel value={tabIndex} index={isSavvy ? 2 : 0}>
          <InformationTab
            flightData={flightData}
            chartNum={chartNum}
            setChartNum={setChartNum}
            isShare={isShare}
            aircraftComments={aircraftComments}
            contactComments={contactComments}
            settings={settings}
            aircraftSettings={aircraftSettings}
          />
        </TabPanel>
        <TabPanel value={tabIndex} index={1}>
          <FlightMapTab flightData={flightData} />
        </TabPanel>
        <TabPanel value={tabIndex} index={isSavvy ? 0 : 2}>
          <AnalysisTab
            flightData={flightData}
            modeManager={modeManager}
            diffData={diffData}
            isShare={isShare}
            analysisMode={analysisMode}
            setAnalysisMode={setAnalysisMode}
            isTwin={isTwinEngine(JSON.parse(flightData.data).series_sets)}
            anomalyTypes={anomalyTypes}
            chartNum={chartNum}
            setChartNum={setChartNum}
            aircraftComments={aircraftComments}
            contactComments={contactComments}
            settings={settings}
            setPresetMode={setPresetMode}
            presetMode={presetMode}
            aircraftSettings={aircraftSettings}
          />
        </TabPanel>
      </Box>
    </Box>
  );
};

export default FlightInfoBox;
