import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Link, Dialog, DialogTitle, DialogContent, Button, Typography, Tooltip } from '@mui/material';
import HistoryIcon from '@mui/icons-material/History';
import { ISavvyTicket, IPost } from 'types';
import { SET_POST_TEXT } from 'state/types';
import { humanDateTime } from 'helpers';

interface DraftPostsProps {
  ticket: ISavvyTicket;
}

const DraftPosts: React.FC<DraftPostsProps> = (props) => {
  const { ticket } = props;
  const dispatch = useDispatch();

  const [draftDialog, setDraftDialog] = useState(false);

  const handleClose = () => {
    setDraftDialog(false);
  };

  const getFirstLine = (input: string) => {
    const firstLine = input.split('\n')[0];
    return firstLine;
  };

  const getLastLine = (input: string) => {
    const splits = input.split('\n');
    if (splits.length > 1) {
      return splits[splits.length - 1] ? splits[splits.length - 1] : splits[splits.length - 2];
    }
    return null;
  };

  const setDraftMessage = (input: string) => {
    dispatch({
      type: SET_POST_TEXT,
      payload: {
        postText: input,
      },
    });
    setDraftDialog(false);
  };

  return (
    <Box>
      <Link
        title="Open Draft Posts"
        href="#"
        sx={{
          color: 'rgba(150, 158, 179, 0.6)',
          ml: 1,
        }}
        onClick={(e: any) => {
          e.preventDefault();
          e.stopPropagation();
          setDraftDialog(true);
        }}
      >
        <HistoryIcon />
      </Link>
      <Dialog open={draftDialog} fullWidth onClose={handleClose} maxWidth="md">
        <DialogTitle
          sx={{
            textAlign: 'center',
            fontSize: '24px',
            fontWeight: 700,
          }}
        >
          Restore your draft post
        </DialogTitle>
        <DialogContent
          sx={{
            px: {
              md: 5,
              xs: 2,
            },
          }}
        >
          <Box py={1}>
            {ticket.draftPosts.map((post: IPost, key: number) => {
              return (
                <Tooltip
                  key={key}
                  title={
                    <Typography
                      dangerouslySetInnerHTML={{
                        __html: post.body.replaceAll('\n', '<br />'),
                      }}
                    />
                  }
                >
                  <Box
                    key={key}
                    py={1}
                    px={1}
                    my={1}
                    sx={{
                      border: '1px solid',
                      borderRadius: '6px',
                      borderColor: 'grey.200',
                      backgroundColor: 'transparent',
                    }}
                  >
                    <Typography>{humanDateTime(post.created)}</Typography>
                    <Typography>{getFirstLine(post.body)}</Typography>
                    <Typography>...</Typography>
                    {getLastLine(post.body) && <Typography>{getLastLine(post.body)}</Typography>}
                    <Box
                      sx={{
                        textAlign: 'right',
                      }}
                    >
                      <Button
                        variant="contained"
                        onClick={() => {
                          setDraftMessage(post.body);
                        }}
                      >
                        Restore
                      </Button>
                    </Box>
                  </Box>
                </Tooltip>
              );
            })}
            {!ticket.draftPosts.length && <Typography align="center">You don't have any draft posts.</Typography>}
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default DraftPosts;
