import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Box, Typography, FormControlLabel, Checkbox as MuiCheckbox, Button } from '@mui/material';
import { DataGridPro, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { IEngineDataFile, IFlight, IDuplicateFlight } from 'types';

import { DuplicateTableComponent } from './fileUploadComponents';
import { ButtonLoading, ComponentLoading } from 'components';
import WarningIcon from '@mui/icons-material/Warning';

import { useMutation } from '@apollo/client';
import { MUTATION_SAVE_STAGED_FLIGHTS } from 'gql';
import { setAlert } from 'state';
import { PATH_FLIGHTS, MAX_ROW_TO_SHOW_STAGED_PAGINATION, MAX_DURATION_OF_SHORT_FLIGHT } from 'const';

interface StagedEngineFlightFilesProps {
  stagedEngineDataFiles: IEngineDataFile[];
}

const StagedEngineFlightFiles: React.FC<StagedEngineFlightFilesProps> = (props) => {
  const { stagedEngineDataFiles } = props;

  const [duplicatedFlights, setDuplicatedFlights] = useState<IDuplicateFlight[]>([]);

  const [showStagedEngineDataFiles, setShowStagedEngineDataFiles] = useState<IEngineDataFile[]>([]);

  const [saveFlightIds, setSaveFlightIds] = useState<string[]>([]);
  const [discardFlightIds, setDiscardFlightIds] = useState<string[]>([]);

  const [emptyFlights, setEmptyFlights] = useState<IDuplicateFlight[]>([]);

  const [pageSize, setPageSize] = useState(MAX_ROW_TO_SHOW_STAGED_PAGINATION);

  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams<{
    id: string;
  }>();

  const [saveFlights, { data: dataSaveFlights, error: errorSaveFlights, loading: loadingSaveFlights }] =
    useMutation(MUTATION_SAVE_STAGED_FLIGHTS);

  useEffect(() => {
    if (!stagedEngineDataFiles) return;
    const tmp: IDuplicateFlight[] = [];
    const tmpShowStagedEngineDataFiles: IEngineDataFile[] = [];

    const tmpEmpty: IDuplicateFlight[] = [];

    const tmpSaveFlightIds: string[] = [];
    const tmpDiscardFlightIds: string[] = [];

    for (let i = 0; i < stagedEngineDataFiles.length; i++) {
      const tmpStagedFlights: IFlight[] = [];
      for (let j = 0; j < stagedEngineDataFiles[i].stagedFlights.length; j++) {
        if (stagedEngineDataFiles[i].stagedFlights[j].duration < MAX_DURATION_OF_SHORT_FLIGHT) {
          tmpEmpty.push({
            ...stagedEngineDataFiles[i].stagedFlights[j],
            fileName: stagedEngineDataFiles[i].name,
          });

          if (tmpDiscardFlightIds.indexOf(stagedEngineDataFiles[i].stagedFlights[j].id) < 0) {
            tmpDiscardFlightIds.push(stagedEngineDataFiles[i].stagedFlights[j].id);
          }
        } else if (stagedEngineDataFiles[i].stagedFlights[j]?.aircraftDuplicate) {
          tmp.push({
            ...stagedEngineDataFiles[i].stagedFlights[j],
            fileName: stagedEngineDataFiles[i].name,
          });

          if (tmpDiscardFlightIds.indexOf(stagedEngineDataFiles[i].stagedFlights[j].id) < 0) {
            tmpDiscardFlightIds.push(stagedEngineDataFiles[i].stagedFlights[j].id);
          }
        } else {
          tmpStagedFlights.push(stagedEngineDataFiles[i].stagedFlights[j]);

          if (tmpSaveFlightIds.indexOf(stagedEngineDataFiles[i].stagedFlights[j].id) < 0) {
            tmpSaveFlightIds.push(stagedEngineDataFiles[i].stagedFlights[j].id);
          }
        }
      }

      tmpShowStagedEngineDataFiles.push({
        id: stagedEngineDataFiles[i].id,
        name: stagedEngineDataFiles[i].name,
        uploadDate: stagedEngineDataFiles[i].uploadDate,
        flights: [...stagedEngineDataFiles[i].flights],
        stagedFlights: [...tmpStagedFlights],
      });
    }

    setDuplicatedFlights([...tmp]);
    setEmptyFlights([...tmpEmpty]);

    setShowStagedEngineDataFiles([...tmpShowStagedEngineDataFiles]);

    setSaveFlightIds([...tmpSaveFlightIds]);
    setDiscardFlightIds([...tmpDiscardFlightIds]);
  }, [stagedEngineDataFiles]);

  const onChange = (flightId: string) => {
    // console.log('here');
    const flightIndex = saveFlightIds.indexOf(flightId);
    if (flightIndex >= 0) {
      saveFlightIds.splice(flightIndex, 1);

      setSaveFlightIds([...saveFlightIds]);

      if (discardFlightIds.indexOf(flightId) < 0) {
        discardFlightIds.push(flightId);
        setDiscardFlightIds([...discardFlightIds]);
      }
    } else {
      saveFlightIds.push(flightId);
      setSaveFlightIds([...saveFlightIds]);

      if (discardFlightIds.indexOf(flightId) >= 0) {
        discardFlightIds.splice(discardFlightIds.indexOf(flightId), 1);
        setDiscardFlightIds([...discardFlightIds]);
      }
    }
  };

  const saveStagedFlights = async () => {
    await saveFlights({
      variables: {
        saveFlightIds,
        discardFlightIds,
      },
    });
  };

  const makeWarningText = (duplicateCount: number, emptyCount: number) => {
    let tmp = '';

    if (duplicateCount) {
      tmp = duplicateCount + ' duplicate flight(s)';
    }

    if (emptyCount) {
      if (duplicateCount) {
        tmp += ' and ' + emptyCount + ' empty flight(s)';
      } else {
        tmp += emptyCount + ' empty Flight(s)';
      }
    }

    return 'Detected ' + tmp;
  };

  const nextProcessedFiles = useCallback(
    async (id: string) => {
      history.push(`${PATH_FLIGHTS}/aircraft/${id}`);
    },
    [history],
  );

  useEffect(() => {
    if (errorSaveFlights) {
      dispatch(setAlert('error', 'Unable to save staged flights'));
    } else if (dataSaveFlights) {
      if (dataSaveFlights.saveStagedFlights?.ok) {
        dispatch(setAlert('success', 'Saved staged flights'));
        nextProcessedFiles(id);
      } else {
        dispatch(setAlert('error', dataSaveFlights.saveStagedFlights?.error || 'Unable to save staged flights'));
      }
    }
  }, [dataSaveFlights, errorSaveFlights, dispatch, history, nextProcessedFiles, id]);

  if (stagedEngineDataFiles.length === 0) return null;

  return (
    <ComponentLoading loading={loadingSaveFlights}>
      <Box
        sx={{
          height: '100%',
          overflowY: 'auto',
          backgroundColor: 'background.default',
          borderLeft: '1px solid',
          borderColor: 'grey.200',
        }}
      >
        {(showStagedEngineDataFiles || duplicatedFlights || emptyFlights) &&
          (Boolean(saveFlightIds.length) || Boolean(discardFlightIds.length)) && (
            <Box
              py={2.5}
              sx={{
                textAlign: 'center',
                borderBottom: '1px solid',
                borderColor: 'grey.200',
              }}
            >
              <Button variant="contained" disabled={loadingSaveFlights} color="primary" onClick={saveStagedFlights}>
                {loadingSaveFlights ? <ButtonLoading /> : 'Save Selected Flights'}
              </Button>
            </Box>
          )}
        {(duplicatedFlights.length !== 0 || emptyFlights.length !== 0) && (
          <Box p={2.5}>
            <Box
              sx={{
                backgroundColor: 'warning.light',
                p: 1.75,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <WarningIcon
                sx={{
                  color: 'warning.main',
                  mr: 1.5,
                }}
              />
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: 500,
                  color: 'warning.main',
                  lineHeight: '22px',
                }}
              >
                {makeWarningText(duplicatedFlights.length, emptyFlights.length)}
              </Typography>
            </Box>
          </Box>
        )}
        {showStagedEngineDataFiles &&
          showStagedEngineDataFiles.map((dataFile: IEngineDataFile, key: number) => {
            if (dataFile.stagedFlights.length === 0) {
              return null;
            }

            return (
              <Box key={key}>
                <Box
                  px={3}
                  py={3}
                  sx={{
                    backgroundColor: 'background.paper',
                  }}
                >
                  <Typography
                    variant="h2"
                    sx={{
                      fontWeight: 700,
                    }}
                  >
                    {dataFile.name}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    backgroundColor: 'background.default',
                  }}
                >
                  <DataGridPro
                    sx={{
                      width: '100%',
                      minWidth: '100px',
                      '& .MuiDataGrid-columnSeparator': {
                        display: 'none',
                      },
                      '& .MuiDataGrid-cell:focus': {
                        outline: 'none',
                      },
                      '& .MuiDataGrid-row': {
                        cursor: 'pointer',
                        borderBottom: '1px solid',
                        borderColor: 'grey.200',
                      },
                      '& .MuiDataGrid-columnHeader:focus': {
                        outline: 'none',
                      },
                      '& .MuiDataGrid-cellContent': {
                        whiteSpace: 'break-spaces',
                      },
                      border: 'none',
                    }}
                    autoHeight
                    disableColumnResize={true}
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    pageSize={pageSize}
                    onPageSizeChange={(newPage) => setPageSize(newPage)}
                    pagination={!!dataFile.stagedFlights?.length && dataFile.stagedFlights?.length >= MAX_ROW_TO_SHOW_STAGED_PAGINATION}
                    showColumnRightBorder={false}
                    disableColumnSelector
                    disableDensitySelector
                    disableColumnMenu
                    disableSelectionOnClick
                    hideFooter
                    rows={dataFile.stagedFlights || []}
                    columns={[
                      {
                        field: 'aircraftDuplicate',
                        headerName: '',
                        renderCell: (params: GridRenderCellParams<boolean>) => {
                          const flightId = params.row.id;
                          return (
                            <FormControlLabel
                              control={<MuiCheckbox color="primary" checked={saveFlightIds.indexOf(flightId) >= 0} />}
                              label=""
                              labelPlacement="end"
                              onChange={() => {
                                onChange(flightId);
                              }}
                            />
                          );
                        },
                        flex: 0.5,
                        sortable: false,
                      },
                      {
                        field: 'date',
                        headerName: 'Date',
                        valueFormatter: (params) => {
                          return params.value ? params.value.toString().substring(0, 10) : '';
                        },
                        flex: 0.5,
                      },
                      {
                        field: 'departureAirport',
                        headerName: 'Departure Airport',
                        flex: 1,
                      },
                      {
                        field: 'destinationAirport',
                        headerName: 'Destination Airport',
                        flex: 1,
                      },
                      {
                        field: 'duration',
                        headerName: 'Duration',
                        valueFormatter: (params) => {
                          if (!params?.value) return '';
                          const hour = Math.floor(params.value / 3600);
                          const min = Math.floor((params.value % 3600) / 60);
                          const sec = params.value % 60;

                          return `${hour}h ${min}m ${sec}s`;
                        },
                        flex: 0.5,
                      },
                    ]}
                  />
                </Box>
              </Box>
            );
          })}
        {duplicatedFlights.length > 0 && (
          <Box>
            <Box
              px={3}
              py={3}
              sx={{
                backgroundColor: 'grey.800',
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  fontWeight: 700,
                }}
              >
                Duplicate flights
              </Typography>
            </Box>
            <Box
              sx={{
                backgroundColor: 'background.default',
              }}
            >
              <DuplicateTableComponent flights={duplicatedFlights} checkAction={onChange} duplicated={true} saveFlightIds={saveFlightIds} />
            </Box>
          </Box>
        )}
        {emptyFlights.length > 0 && (
          <Box>
            <Box
              px={3}
              py={3}
              sx={{
                backgroundColor: 'grey.800',
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  fontWeight: 700,
                }}
              >
                Short/Empty flights
              </Typography>
            </Box>
            <Box
              sx={{
                backgroundColor: 'background.default',
              }}
            >
              <DuplicateTableComponent flights={emptyFlights} checkAction={onChange} duplicated={false} saveFlightIds={saveFlightIds} />
            </Box>
          </Box>
        )}
      </Box>
    </ComponentLoading>
  );
};

export default StagedEngineFlightFiles;
