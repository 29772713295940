import React, { Fragment } from 'react';

import { Typography, Collapse, Hidden } from '@mui/material';

import { Subsection, List, ButtonMore } from 'components';
import { useMdUp } from 'hooks';

const features = [
  'Report Cards that compare your aircraft’s and engine’s performance with all the other aircraft we follow of the same make and model',
  'Trend Reports that track changes over time in key engine parameters, alerting you when the changes are statistically significant',
  'FEVA2 (Failing Exhaust Valve Analytics 2) that predicts when your exhaust valves are at elevated risk for failure, based on Savvy’s extensive data and machine learning technology',
  '24/7/365 quick response breakdown assistance when you’re away from home.',
];

const fees = ['Piston single: $99/year', 'Piston twin: $149/year'];

const SavvyBasicDescription: React.FC = () => {
  const [open, setOpen] = React.useState(false);
  const isMdUp = useMdUp();

  return (
    <Fragment>
      <Subsection>
        <Typography paragraph>
          Savvy Basics combines Savvy’s suite of proprietary analytical reports based on your engine monitor data with 24/7/365 quick
          response breakdown assistance when you’re away from home.
        </Typography>
      </Subsection>
      <Collapse in={open || isMdUp}>
        <Subsection>
          <Typography variant="h4" gutterBottom>
            For a modest annual subscription fee, you'll receive the following benefits:
          </Typography>
          <List list={features} />
        </Subsection>
        <Subsection>
          <Typography variant="h4" gutterBottom>
            Savvy Basics Plan Annual Fees:
          </Typography>
          <List list={fees} />
          <Typography
            variant="body1"
            style={{
              fontStyle: 'italic',
            }}
          >
            Aircraft with very basic engine monitors or unusual makes and models of aircraft might receive a simplified version of Report
            Cards, Trend Reports or FEVA2.
          </Typography>
        </Subsection>
      </Collapse>
      {!isMdUp && (
        <Hidden mdUp>
          <Subsection>
            <ButtonMore open={open} setOpen={setOpen} />
          </Subsection>
        </Hidden>
      )}
    </Fragment>
  );
};

export default SavvyBasicDescription;
