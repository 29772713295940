import React from 'react';
import { NavLink as RouterLink } from 'react-router-dom';

import { Box, Button } from '@mui/material';

import styles from './style.module.scss';

import { IAppBarLink } from 'types';

interface IAppBarMenu {
  links: IAppBarLink[];
  linkClass?: string;
}

const AppBarMenu: React.FC<IAppBarMenu> = (props) => {
  const { links, linkClass } = props;

  return (
    <Box display="flex" flexWrap="nowrap" height="100%">
      {links.map((link: IAppBarLink) => (
        <RouterLink
          to={link.path}
          className={`${styles.appBarLink} ${linkClass}`}
          activeClassName={styles.appBarLinkActive}
          exact={link.exact}
          key={link.path}
        >
          <Button color="inherit" className={styles.appBarLinkButton}>
            {link.startIcon && (
              <Box className={styles.icon} display="inherit" color="grey.400" mr={1}>
                {link.startIcon}
              </Box>
            )}
            {link.text}
            {link.endIcon && (
              <Box className={styles.icon} display="inherit" color="grey.400" ml={1}>
                {link.endIcon}
              </Box>
            )}
          </Button>
        </RouterLink>
      ))}
    </Box>
  );
};

export default AppBarMenu;
