import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useQuery, useLazyQuery } from '@apollo/client';
import { QUERY_AIRCRAFT_MANUFACTURERS, QUERY_AIRCRAFT_MODELS } from 'gql';

import { Autocomplete } from 'components';
import { setAlert } from 'state';
import {
  AIRCRAFT_MODEL_SELECT_MANUFACTURER_ERROR,
  AIRCRAFT_MANUFACTURER_EMPTY_ERROR,
  ADD_OPTION_CLICKED_NO_VALUE,
  ADD_OPTION_CLICKED_TYPED_VALUE,
  GRAPHQL_NETWORK_ERROR_MESSAGE,
} from 'const';
import { IDropDownOption, IInputError, IInputSetError, IModelAdditionalFields, IParamsService } from 'types';
import { getOptionLabelIdName, formatOptionIdName, isEmpty } from 'helpers';

import { DialogAddAircraftManufacturer, DialogAddAircraftModel } from '../DialogAddAircraft';

interface AircraftManufacturerProps {
  setServicePlan?: (arg0: number) => void;
  manufacturer: any;
  setManufacturer: (value: any) => void;
  errorManufacturer: IInputError;
  setErrorManufacturer: IInputSetError;
  onChangeManufacturer: (option: any) => void;
  model: any;
  setModel: (value: any) => void;
  errorModel: IInputError;
  setErrorModel: IInputSetError;
  onChangeModel: (option: any) => void;
  setAdditionalFields: (value: IModelAdditionalFields | null) => void;
  setResponseError: (value: any[]) => void;
}

const AircraftMakeModel: React.FC<AircraftManufacturerProps> = (props) => {
  const {
    setServicePlan,
    manufacturer,
    setManufacturer,
    errorManufacturer,
    setErrorManufacturer,
    onChangeManufacturer,
    model,
    setModel,
    errorModel,
    setErrorModel,
    onChangeModel,
    setAdditionalFields,
    setResponseError,
  } = props;

  const dispatch = useDispatch();

  const { data: dataManufacturer, loading: loadingManufacturer, error: errorFetchManufacturer } = useQuery(QUERY_AIRCRAFT_MANUFACTURERS);
  const [openAddManufacturer, setOpenAddManufacturer] = useState(false);
  const [openAddModel, setOpenAddModel] = useState(false);

  const [modelOptions, setModelOptions] = useState<IDropDownOption[]>([]);
  const [modelDisabled, setModelDisabled] = useState(true);

  const [fetchModels, { data: dataFetchModels, loading: loadingFetchModels }] = useLazyQuery(QUERY_AIRCRAFT_MODELS);

  const { service } = useParams<IParamsService>();

  const ADD_OWN_TEXT = '-- Add your own --';

  // Call QUERY_AIRCRAFT_MODELS to get aircraftModelSet
  useEffect(() => {
    if (manufacturer?.id && model?.id) {
      fetchModels({
        variables: {
          name: model?.name,
          manufacturerId: manufacturer.id,
        },
      });
    }
  }, [manufacturer, model, fetchModels]);

  useEffect(() => {
    if (model?.id) {
      setAdditionalFields(null);
    }
  }, [model, setAdditionalFields]);

  // Set service plan
  useEffect(() => {
    if (!loadingFetchModels && dataFetchModels?.aircraftModel?.length > 0) {
      const { eligibleServices } = dataFetchModels.aircraftModel[0];

      const matchedService = eligibleServices?.find((element: any) => element.serviceCode === service);

      if (setServicePlan) {
        setServicePlan(matchedService && matchedService.price ? matchedService.price : 0);
      }
    } else {
      if (setServicePlan) {
        setServicePlan(0);
      }
    }
  }, [model, setServicePlan, service, dataFetchModels, loadingFetchModels, setAdditionalFields]);

  useEffect(() => {
    if (errorFetchManufacturer) {
      dispatch(setAlert('error', GRAPHQL_NETWORK_ERROR_MESSAGE, 'ALERT_SERVER_ERROR_ID'));
    }
  }, [errorFetchManufacturer, dispatch]);

  useEffect(() => {
    setResponseError([]);
    if (manufacturer?.type === ADD_OPTION_CLICKED_NO_VALUE || manufacturer?.type === ADD_OPTION_CLICKED_TYPED_VALUE) {
      setManufacturer({
        id: '',
        name: manufacturer?.name && manufacturer.name !== ADD_OWN_TEXT ? manufacturer.name.replace('Add ', '') : '',
      });
      setOpenAddManufacturer(true);
      setOpenAddModel(false);
    } else if (model?.type === ADD_OPTION_CLICKED_NO_VALUE || model?.type === ADD_OPTION_CLICKED_TYPED_VALUE) {
      setModel({
        id: '',
        name: model?.name && model.name !== ADD_OWN_TEXT ? model.name.replace('Add ', '') : '',
      });
      setOpenAddModel(true); // Open dialog
      setOpenAddManufacturer(false);
    }
  }, [manufacturer, model, setManufacturer, setModel, setResponseError]);

  let manufacturerOptions: IDropDownOption[] = [];

  if (dataManufacturer) {
    const aircraftManufacturer = [...dataManufacturer.aircraftManufacturer];
    aircraftManufacturer.sort((x: IDropDownOption, y: IDropDownOption) => {
      if (x.name > y.name) return 1;
      else return -1;
    });
    manufacturerOptions = [...aircraftManufacturer]; // Update options
    manufacturerOptions.push({
      id: '',
      name: ADD_OWN_TEXT,
    });
  }

  useEffect(() => {
    let tmp: IDropDownOption[] = [];
    if (manufacturer?.id && manufacturer?.aircraftmodelSet) {
      tmp = [...manufacturer.aircraftmodelSet];
      tmp.sort((x: IDropDownOption, y: IDropDownOption) => {
        if (x.name > y.name) return 1;
        else return -1;
      });
    }

    tmp.push({
      id: '',
      name: ADD_OWN_TEXT,
    });

    setModelOptions(tmp);
  }, [manufacturer]);

  useEffect(() => {
    // If Manufacturer changed
    if (manufacturer?.id) {
      setModel(null);
    }

    // If Manufacturer Empty
    if (manufacturer === null || (!manufacturer.id && !manufacturer.name)) {
      setModelDisabled(true); // Disable Model
    } else {
      // If Manufacturer NOT Empty
      setErrorModel(null); // Clean Model Error
      setModelDisabled(false); // Enable Model
    }
  }, [manufacturer, setModel, setErrorModel]);

  const requireManufacturer = () => {
    if (manufacturer !== null && isEmpty(manufacturer.name)) {
      setErrorManufacturer(AIRCRAFT_MANUFACTURER_EMPTY_ERROR);
      setErrorModel(AIRCRAFT_MODEL_SELECT_MANUFACTURER_ERROR);
    }
  };

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Autocomplete
          label="Aircraft manufacturer *"
          name="aircraft_manufacturer"
          value={manufacturer}
          error={errorManufacturer}
          onChange={onChangeManufacturer}
          options={manufacturerOptions}
          getOptionLabel={getOptionLabelIdName}
          formatOption={formatOptionIdName}
          loading={loadingManufacturer}
          addOptionLabel={ADD_OWN_TEXT}
          renderOptionFlag={true}
          placeholder="Start typing to search..."
        />
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          label="Aircraft model *"
          name="aircraft_model"
          value={model}
          error={errorModel}
          onChange={onChangeModel}
          options={modelOptions}
          getOptionLabel={getOptionLabelIdName}
          formatOption={formatOptionIdName}
          addOptionLabel={ADD_OWN_TEXT}
          disabled={modelDisabled}
          onClick={requireManufacturer}
          renderOptionFlag={true}
          placeholder="Start typing to search..."
        />
      </Grid>
      <DialogAddAircraftManufacturer
        open={openAddManufacturer}
        setOpen={setOpenAddManufacturer}
        manufacturer={manufacturer}
        setManufacturer={setManufacturer}
        setModel={setModel}
        setAdditionalFields={setAdditionalFields}
      />
      <DialogAddAircraftModel
        open={openAddModel}
        setOpen={setOpenAddModel}
        manufacturer={manufacturer}
        setManufacturer={setManufacturer}
        model={model}
        setModel={setModel}
        setAdditionalFields={setAdditionalFields}
      />
    </React.Fragment>
  );
};

export default AircraftMakeModel;
