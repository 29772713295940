import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Box, Grid, MenuItem, FormControl, Typography, RadioGroup, FormControlLabel, Radio, FormHelperText } from '@mui/material';

import { Formik, Form, Field } from 'formik';
import { CheckboxWithLabel, TextField } from 'formik-mui';
import { MuiFormikInput, ButtonSubmit, Subsection, PageLoading } from 'components';

import { EngineMakeModel, EngineMonitorMakeModel } from 'pages/SignupService/components';

import { DialogAddAircraftManufacturer, DialogAddAircraftModel } from 'pages/Aircrafts/components/Dialog';

import * as Yup from 'yup';

import { useQuery, useMutation } from '@apollo/client';
import { QUERY_CHECK_ADDITIONAL_INFO, QUERY_AIRCRAFT_MANUFACTURERS, MUTATION_CREATE_AIRCRAFT, MUTATION_CHANGE_PERSONAL_INFO } from 'gql';

import { getOptionLabelIdName, formatOptionIdName, isEmpty, getOptionLabelFlagCountry } from 'helpers';
import { Autocomplete } from 'components';
import { useAutocomplete } from 'hooks';

import { setAlert } from 'state';
import {
  AIRCRAFT_MODEL_SELECT_MANUFACTURER_ERROR,
  AIRCRAFT_MANUFACTURER_EMPTY_ERROR,
  ADD_OPTION_CLICKED_NO_VALUE,
  ADD_OPTION_CLICKED_TYPED_VALUE,
  GRAPHQL_NETWORK_ERROR_MESSAGE,
  PATH_AIRCRAFT_VIEW,
} from 'const';

import { countries } from 'api';

import { apolloClient } from 'services';

const ValidationAircraftInfoSchema = Yup.object().shape({
  registration: Yup.string().max(20, 'Too long: Maximum 20 characters').required('Required'),
  year: Yup.string().max(4, 'Too long: Maximum 4 characters').required('Required'),
  serial: Yup.string().max(50, 'Too long: Maximum 50 characters').required('Required'),
  chtWarningTemperature: Yup.number().integer('Integer'),
  cylinderCount: Yup.string().required('Required'),
  airportId: Yup.string().max(4, 'Too long: Maximum 4 characters').required('Required'),
  phoneMobile: Yup.string().min(1, 'Too short: 1-20 characters required').max(20, 'Too long: Maximum 20 characters').required('Required'),
});

interface IDropDownOption {
  id: string;
  name: string;
}

const AircraftCreateForm: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { data: dataContact, loading: loadingContact } = useQuery(QUERY_CHECK_ADDITIONAL_INFO);
  const [createAircraft, { data: dataCreateAircraft, loading: loadingCreateAircraft, error: errorCreateAircraft }] =
    useMutation(MUTATION_CREATE_AIRCRAFT);
  const {
    value: country,
    error: errorCountry,
    setError: setErrorCountry,
    onChange: onChangeCountry,
    setValue: setCountry,
  } = useAutocomplete();

  const { data: dataManufacturer, loading: loadingManufacturer, error: errorFetchManufacturer } = useQuery(QUERY_AIRCRAFT_MANUFACTURERS);
  useEffect(() => {
    if (errorFetchManufacturer) {
      dispatch(setAlert('error', GRAPHQL_NETWORK_ERROR_MESSAGE, 'ALERT_SERVER_ERROR_ID'));
    }
  }, [errorFetchManufacturer, dispatch]);

  const {
    value: manufacturer,
    setValue: setManufacturer,
    error: errorManufacturer,
    setError: setErrorManufacturer,
    onChange: onChangeManufacturer,
  } = useAutocomplete();
  const [manufacturerOptions, setManufacturerOptions] = useState<IDropDownOption[]>([]);

  const { value: model, setValue: setModel, error: errorModel, setError: setErrorModel, onChange: onChangeModel } = useAutocomplete();

  const [openAddManufacturer, setOpenAddManufacturer] = useState(false);
  const [openAddModel, setOpenAddModel] = useState(false);
  const [modelOptions, setModelOptions] = useState<IDropDownOption[]>([]);

  const [prebuy, setPrebuy] = useState('0');
  const [prebuyError, setPrebuyError] = useState('');

  const {
    value: engineManufacturer,
    setValue: setEngineManufacturer,
    error: errorEngineManufacturer,
    setError: setErrorEngineManufacturer,
    onChange: onChangeEngineManufacturer,
  } = useAutocomplete();
  const {
    value: engineModel,
    setValue: setEngineModel,
    error: errorEngineModel,
    setError: setErrorEngineModel,
    onChange: onChangeEngineModel,
  } = useAutocomplete();

  const {
    value: engineMonitorManufacturer,
    setValue: setEngineMonitorManufacturer,
    error: errorEngineMonitorManufacturer,
    setError: setErrorEngineMonitorManufacturer,
    onChange: onChangeEngineMonitorManufacturer,
  } = useAutocomplete();

  const {
    value: engineMonitorModel,
    setValue: setEngineMonitorModel,
    error: errorEngineMonitorModel,
    setError: setErrorEngineMonitorModel,
    onChange: onChangeEngineMonitorModel,
  } = useAutocomplete();

  const [installedEngineMonitor, setInstalledEngineMonitor] = useState(false);

  const requireManufacturer = () => {
    if (manufacturer !== null && isEmpty(manufacturer.name)) {
      setErrorManufacturer(AIRCRAFT_MANUFACTURER_EMPTY_ERROR);
      setErrorModel(AIRCRAFT_MODEL_SELECT_MANUFACTURER_ERROR);
    }
  };

  // Open if "Add option" clicked in Autocomplete
  useEffect(() => {
    if (manufacturer?.type === ADD_OPTION_CLICKED_NO_VALUE || manufacturer?.type === ADD_OPTION_CLICKED_TYPED_VALUE) {
      setManufacturer({
        id: '',
        name: manufacturer?.name && manufacturer.name !== '-- Add your own --' ? manufacturer.name.replace('Add ', '') : '',
      });

      setOpenAddManufacturer(true);
      setOpenAddModel(false);
    } else if (model?.type === ADD_OPTION_CLICKED_NO_VALUE || model?.type === ADD_OPTION_CLICKED_TYPED_VALUE) {
      setModel({
        id: '',
        name: model?.name && model.name !== '-- Add your own --' ? model.name.replace('Add ', '') : '',
      });

      setOpenAddModel(true); // Open dialog
      setOpenAddManufacturer(false);
    }
  }, [manufacturer, model, setManufacturer, setModel]);

  const [modelDisabled, setModelDisabled] = useState(true);
  useEffect(() => {
    // If Manufacturer changed
    if (manufacturer) setModel(null);

    // If Manufacturer Empty
    if (manufacturer === null || (!manufacturer?.id && !manufacturer?.name)) {
      setModelDisabled(true); // Disable Model
    } else {
      // If Manufacturer NOT Empty
      setErrorModel(null); // Clean Model Error
      setModelDisabled(false); // Enable Model
    }
  }, [manufacturer, setModel, setErrorModel]);

  useEffect(() => {
    if (dataManufacturer) {
      const aircraftManufacturer = [...dataManufacturer.aircraftManufacturer];
      aircraftManufacturer.sort((x: IDropDownOption, y: IDropDownOption) => {
        if (x.name > y.name) return 1;
        else return -1;
      });
      const tmp = [...aircraftManufacturer]; // Update options
      tmp.push({
        id: '',
        name: '-- Add your own --',
      });

      setManufacturerOptions([...tmp]);
    }
  }, [dataManufacturer, setManufacturerOptions]);

  useEffect(() => {
    let tmp: IDropDownOption[] = [];
    if (manufacturer?.id && manufacturer?.aircraftmodelSet) {
      tmp = [...manufacturer.aircraftmodelSet];
      tmp.sort((x: IDropDownOption, y: IDropDownOption) => {
        if (x.name > y.name) return 1;
        else return -1;
      });
    }

    tmp.push({
      id: '',
      name: '-- Add your own --',
    });

    setModelOptions(tmp);
  }, [manufacturer]);

  const onSubmit = async (values: any) => {
    let hasError = false;

    if (!country) {
      setErrorCountry('Required');
      hasError = true;
    }

    if (!manufacturer?.id) {
      setErrorManufacturer('Required');
      hasError = true;
    }

    if (!model?.id) {
      setErrorModel('Required');
      hasError = true;
    }

    if (!engineManufacturer?.id) {
      setErrorEngineManufacturer('Required');
      hasError = true;
    }

    if (!engineModel?.id) {
      setErrorEngineModel('Required');
      hasError = true;
    }

    if (!installedEngineMonitor) {
      if (!engineMonitorManufacturer?.id) {
        setErrorEngineMonitorManufacturer('Required');
        hasError = true;
      }

      if (!engineMonitorModel?.id) {
        setErrorEngineMonitorModel('Required');
        hasError = true;
      }
    }

    if (prebuy === '0') {
      setPrebuyError('Required');
      hasError = true;
    }

    if (hasError) return;

    if (!dataContact?.me?.airportId || !dataContact?.me?.country || !dataContact?.me?.phoneMobile) {
      const { errors } = await apolloClient.mutate({
        mutation: MUTATION_CHANGE_PERSONAL_INFO,
        variables: {
          airportId: values.airportId,
          countryCode: country.id,
          mobilePhone: values.phoneMobile,
        },
      });

      if (errors) {
        dispatch(setAlert('error', 'Unable to update personal info'));
        return;
      }
    }

    await createAircraft({
      variables: {
        aircraftManufacturerId: manufacturer.id,
        aircraftModelId: model.id,
        engineManufacturerId: engineManufacturer.id,
        engineModelId: engineModel.id,
        aircraftHasNoMonitor: installedEngineMonitor,
        engineMonitorManufacturerId: installedEngineMonitor ? undefined : engineMonitorManufacturer.id,
        engineMonitorModelId: installedEngineMonitor ? undefined : engineMonitorModel.id,
        chtWarningTemperature: parseInt(values.chtWarningTemperature),
        cylinderCount: values.cylinderCount ? values.cylinderCount : '',
        registration: values.registration,
        serial: values.serial,
        year: values.year,
        prebuy: prebuy === '1' ? false : true,
      },
    });
  };

  useEffect(() => {
    if (errorCreateAircraft) {
      dispatch(setAlert('error', 'Unable to create new aircraft with info'));
    } else {
      if (dataCreateAircraft) {
        if (dataCreateAircraft.createAircraft?.ok) {
          dispatch(setAlert('success', 'Created new aircraft'));
          history.push(`${PATH_AIRCRAFT_VIEW}/${dataCreateAircraft.createAircraft?.aircraft?.id}`);
        } else {
          dispatch(setAlert('error', dataCreateAircraft.createAircraft?.error));
        }
      }
    }
  }, [dataCreateAircraft, errorCreateAircraft, dispatch, history]);

  useEffect(() => {
    if (dataContact?.me?.country) {
      const filteredCountry = countries.filter((country) => country.id === dataContact.me.country);
      if (filteredCountry?.length) {
        setCountry(filteredCountry[0]);
      }
    }
  }, [dataContact, setCountry]);

  return (
    <Box
      sx={{
        p: {
          xs: 2,
          sm: 3,
          md: 4,
          lg: 5,
        },
      }}
    >
      {(loadingContact || loadingManufacturer) && <PageLoading />}
      {dataContact?.me && (
        <Formik
          initialValues={{
            registration: '',
            year: '',
            serial: '',
            installedEngineMonitor: false,
            chtWarningTemperature: '',
            cylinderCount: '',
            airportId: dataContact?.me?.airportId || '',
            phoneMobile: dataContact?.me?.phoneMobile || '',
          }}
          onSubmit={onSubmit}
          validationSchema={ValidationAircraftInfoSchema}
        >
          {({ isSubmitting, handleChange, handleBlur, touched, errors }) => {
            return (
              <Form>
                <Subsection>
                  <Grid container spacing={2}>
                    {!dataContact?.me?.phoneMobile && (
                      <Grid item xs={12}>
                        <MuiFormikInput
                          name="phoneMobile"
                          label="Mobile Phone *"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                        />
                      </Grid>
                    )}
                    {!dataContact?.me?.airportId && (
                      <Grid item xs={6}>
                        <MuiFormikInput
                          name="airportId"
                          label="Home Airport ID *"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                        />
                      </Grid>
                    )}
                    {!dataContact?.me?.country && (
                      <Grid item xs={6}>
                        <Autocomplete
                          label="Country *"
                          name="country"
                          value={country}
                          error={errorCountry}
                          onChange={(e) => onChangeCountry(e)}
                          options={countries}
                          getOptionLabel={getOptionLabelFlagCountry}
                          formatOption={formatOptionIdName}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <MuiFormikInput
                        name="registration"
                        label="Registration Number *"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <MuiFormikInput
                        name="serial"
                        label="Serial *"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <MuiFormikInput
                        name="year"
                        label="Model Year *"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Autocomplete
                        label="Aircraft manufacturer *"
                        name="aircraft_manufacturer"
                        value={manufacturer ? manufacturer : null}
                        error={errorManufacturer}
                        onChange={onChangeManufacturer}
                        options={manufacturerOptions}
                        getOptionLabel={getOptionLabelIdName}
                        formatOption={formatOptionIdName}
                        loading={loadingManufacturer}
                        addOptionLabel="-- Add your own --"
                        renderOptionFlag={true}
                        placeholder="Start typing to search..."
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Autocomplete
                        label="Aircraft model *"
                        name="aircraft_model"
                        value={model ? model : null}
                        error={errorModel}
                        onChange={onChangeModel}
                        options={modelOptions}
                        getOptionLabel={getOptionLabelIdName}
                        formatOption={formatOptionIdName}
                        addOptionLabel="-- Add your own --"
                        disabled={modelDisabled}
                        onClick={requireManufacturer}
                        renderOptionFlag={true}
                        placeholder="Start typing to search..."
                      />
                    </Grid>
                    <EngineMakeModel
                      engineManufacturer={engineManufacturer}
                      setEngineManufacturer={setEngineManufacturer}
                      errorEngineManufacturer={errorEngineManufacturer}
                      onChangeEngineManufacturer={onChangeEngineManufacturer}
                      engineModel={engineModel}
                      setEngineModel={setEngineModel}
                      errorEngineModel={errorEngineModel}
                      onChangeEngineModel={onChangeEngineModel}
                      disabled={isSubmitting}
                    />
                    <Grid item sm={6} xs={12}>
                      <MuiFormikInput
                        name="chtWarningTemperature"
                        label="CHT Warning Temperature"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <Field
                        name="cylinderCount"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        component={TextField}
                        label="Number of Cylinders"
                        select
                        variant="outlined"
                        fullWidth
                        FormHelperTextProps={{
                          style: {
                            color: 'red',
                            marginLeft: 0,
                          },
                        }}
                      >
                        <MenuItem value={'2'}>2</MenuItem>
                        <MenuItem value={'4'}>4</MenuItem>
                        <MenuItem value={'6'}>6</MenuItem>
                        <MenuItem value={'7'}>7</MenuItem>
                        <MenuItem value={'8'}>8</MenuItem>
                        <MenuItem value={'9'}>9</MenuItem>
                      </Field>
                    </Grid>
                    <EngineMonitorMakeModel
                      engineMonitorManufacturer={engineMonitorManufacturer}
                      setEngineMonitorManufacturer={setEngineMonitorManufacturer}
                      errorEngineMonitorManufacturer={errorEngineMonitorManufacturer}
                      onChangeEngineMonitorManufacturer={onChangeEngineMonitorManufacturer}
                      engineMonitorModel={engineMonitorModel}
                      setEngineMonitorModel={setEngineMonitorModel}
                      errorEngineMonitorModel={errorEngineMonitorModel}
                      onChangeEngineMonitorModel={onChangeEngineMonitorModel}
                      installedEngineMonitor={installedEngineMonitor}
                      disabled={isSubmitting}
                    />
                    <Grid item sm={6} xs={12}>
                      <Field
                        type="checkbox"
                        name="installedEngineMonitor"
                        component={CheckboxWithLabel}
                        Label={{ label: 'Engine Monitor Not Installed' }}
                        onChange={(e: any) => {
                          setInstalledEngineMonitor(!installedEngineMonitor);
                          handleChange(e);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Subsection>
                <Subsection>
                  <FormControl
                    sx={{
                      p: 0,
                      display: 'block',
                      mb: 0,
                    }}
                    error={!!prebuyError}
                  >
                    <Typography variant="h4" gutterBottom align="center">
                      Is this for a Prebuy?
                    </Typography>
                    <Box
                      sx={{
                        display: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={prebuy}
                        onChange={(e) => {
                          setPrebuy(e.target.value);
                          setPrebuyError('');
                        }}
                        sx={{
                          mb: 0,
                        }}
                      >
                        <FormControlLabel
                          value={'1'}
                          control={
                            <Radio
                              sx={{
                                color: '#BBC0D4',
                              }}
                            />
                          }
                          sx={{
                            color: 'grey.600',
                            display: 'inline-block',
                          }}
                          label="No: I already own this aircraft"
                        />
                        <FormControlLabel
                          value={'2'}
                          control={
                            <Radio
                              sx={{
                                color: '#BBC0D4',
                              }}
                            />
                          }
                          sx={{
                            color: 'grey.600',
                            display: 'inline-block',
                          }}
                          label="Yes: I am interested in buying this aircraft"
                        />
                      </RadioGroup>
                    </Box>
                    <FormHelperText
                      sx={{
                        textAlign: 'center',
                        p: 0,
                        m: 0,
                      }}
                    >
                      {prebuyError}
                    </FormHelperText>
                  </FormControl>
                </Subsection>
                <DialogAddAircraftManufacturer
                  open={openAddManufacturer}
                  setOpen={setOpenAddManufacturer}
                  manufacturer={manufacturer}
                  setManufacturer={setManufacturer}
                />

                <DialogAddAircraftModel
                  open={openAddModel}
                  setOpen={setOpenAddModel}
                  manufacturer={manufacturer}
                  setManufacturer={setManufacturer}
                  model={model}
                  setModel={setModel}
                />
                <Subsection>
                  <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12} md={6}>
                      <ButtonSubmit
                        text="Done"
                        loading={isSubmitting || loadingCreateAircraft}
                        disabled={isSubmitting || loadingCreateAircraft}
                      />
                    </Grid>
                  </Grid>
                </Subsection>
              </Form>
            );
          }}
        </Formik>
      )}
    </Box>
  );
};

export default AircraftCreateForm;
