import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Container, TextField, MenuItem, Button, Tooltip } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';

import { QUERY_GET_AIRCRAFT_MANAGER, QUERY_GET_AVAILABLE_MANAGERS, MUTATION_UPDATE_AIRCRAFT_MANAGERS } from 'gql';
import { useLazyQuery, useMutation } from '@apollo/client';

import { ComponentLoading } from 'components';
import { IContactType } from 'types';
import { setAlert } from 'state';

interface ManagersProps {
  aircraftId: string;
  isLoad: boolean;
  ticketId?: string;
  handleClose?: () => void;
}

const Managers: React.FC<ManagersProps> = (props) => {
  const { aircraftId, isLoad, ticketId = '', handleClose } = props;

  const [fetchAvailableManagers, { data: dataManagers, error: errorManagers, loading: loadingManagers }] =
    useLazyQuery(QUERY_GET_AVAILABLE_MANAGERS);
  const [fetchAircraftManager, { data: dataAircraftManager, error: errorAircraftManager, loading: loadingAircraftManager }] =
    useLazyQuery(QUERY_GET_AIRCRAFT_MANAGER);
  const [updateAircraftManager, { data: dataUpdateManager, error: errorUpdateManager, loading: loadingUpdateManager }] =
    useMutation(MUTATION_UPDATE_AIRCRAFT_MANAGERS);

  const dispatch = useDispatch();

  const [selectedAccountManager, setSelectedAccountManager] = useState('');
  const [selectedBackupManager, setSelectedBackupManager] = useState('');

  const onSubmit = async () => {
    await updateAircraftManager({
      variables: {
        aircraftId,
        accountManagerId: selectedAccountManager !== '-1' ? selectedAccountManager : '',
        backupManagerId: selectedBackupManager !== '-1' ? selectedBackupManager : '',
        initialUpdateTicketId: ticketId ? ticketId : null,
      },
    });
  };

  const onClear = async () => {
    setSelectedAccountManager('-1');
    setSelectedBackupManager('-1');
  };

  useEffect(() => {
    if (isLoad) {
      fetchAvailableManagers();
    }
  }, [isLoad, fetchAvailableManagers]);

  useEffect(() => {
    if (aircraftId && isLoad) {
      fetchAircraftManager({
        variables: {
          aircraftId,
        },
      });
    }
  }, [aircraftId, isLoad, fetchAircraftManager]);

  useEffect(() => {
    if (dataAircraftManager) {
      setSelectedAccountManager(dataAircraftManager?.savvy?.aircraft?.accountManager?.id);
      setSelectedBackupManager(dataAircraftManager?.savvy?.aircraft?.backupManager?.id);
    }
  }, [dataAircraftManager]);

  useEffect(() => {
    if (errorUpdateManager) {
      dispatch(setAlert('error', 'Unable to update aircraft managers'));
    } else if (dataUpdateManager) {
      if (dataUpdateManager?.updateAircraftManagers?.ok) {
        dispatch(setAlert('success', 'Updated aircraft managers'));
        if (handleClose) {
          handleClose();
        }
      } else {
        dispatch(setAlert('error', dataUpdateManager?.updateAircraftManagers?.error || 'Unable to update aircraft managers'));
      }
    }
  }, [errorUpdateManager, dataUpdateManager, dispatch, handleClose]);

  useEffect(() => {
    if (errorAircraftManager) {
      dispatch(setAlert('error', 'Unable to load aircraft manager'));
    }
  }, [errorAircraftManager, dispatch]);

  useEffect(() => {
    if (errorManagers) {
      dispatch(setAlert('error', 'Unable to load available managers list'));
    }
  }, [errorManagers, dispatch]);

  return (
    <Container fixed maxWidth="xl">
      <Box py={ticketId ? 2 : 5}>
        <ComponentLoading loading={loadingAircraftManager || loadingManagers || loadingUpdateManager}>
          <Box p={ticketId ? 0 : 5}>
            <Box
              pb={2}
              sx={{
                maxWidth: ticketId ? 'inherit' : '400px',
              }}
            >
              {!!dataManagers?.savvy?.allManagers?.length && (
                <TextField
                  select
                  label="Account Manager"
                  name="accountManager"
                  value={selectedAccountManager ? selectedAccountManager : '-1'}
                  onChange={(e) => {
                    setSelectedAccountManager(e.target.value);
                  }}
                  sx={{
                    mb: 0,
                  }}
                  fullWidth
                  disabled={!dataManagers?.savvy?.permissions?.aircraft.canEditManagers}
                >
                  <MenuItem value="-1">-- None --</MenuItem>
                  {[...dataManagers.savvy.allManagers]
                    ?.sort((x: IContactType, y: IContactType) => {
                      return `${x.firstName} ${x.lastName}` > `${y.firstName} ${y.lastName}` ? 1 : -1;
                    })
                    ?.map((manager: IContactType, key: number) => {
                      return (
                        <MenuItem key={key} value={manager.id}>
                          {manager.firstName} {manager.lastName}
                        </MenuItem>
                      );
                    })}
                </TextField>
              )}
            </Box>
            <Box
              pb={2}
              sx={{
                maxWidth: ticketId ? 'inherit' : '400px',
              }}
            >
              {!!dataManagers?.savvy?.allManagers?.length && (
                <TextField
                  select
                  label="BackupManager Manager"
                  name="backupManager"
                  value={selectedBackupManager ? selectedBackupManager : ''}
                  onChange={(e) => {
                    setSelectedBackupManager(e.target.value);
                  }}
                  sx={{
                    mb: 0,
                  }}
                  fullWidth
                  disabled={!dataManagers?.savvy?.permissions?.aircraft.canEditManagers}
                >
                  <MenuItem value="-1">-- None --</MenuItem>
                  {[...dataManagers.savvy.allManagers]
                    ?.sort((x: IContactType, y: IContactType) => {
                      return `${x.firstName} ${x.lastName}` > `${y.firstName} ${y.lastName}` ? 1 : -1;
                    })
                    ?.map((manager: IContactType, key: number) => {
                      return (
                        <MenuItem key={key} value={manager.id}>
                          {manager.firstName} {manager.lastName}
                        </MenuItem>
                      );
                    })}
                </TextField>
              )}
            </Box>
            <Box
              sx={{
                maxWidth: ticketId ? 'inherit' : '400px',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Button
                variant="outlined"
                disabled={!dataManagers?.savvy?.permissions?.aircraft.canEditManagers}
                sx={{
                  minWidth: '120px',
                }}
                onClick={onClear}
              >
                Clear
              </Button>
              <Button
                variant="contained"
                onClick={onSubmit}
                disabled={!dataManagers?.savvy?.permissions?.aircraft.canEditManagers}
                sx={{
                  ml: 2,
                  minWidth: '120px',
                }}
              >
                Update
              </Button>
              {!dataManagers?.savvy?.permissions?.aircraft.canEditManagers && (
                <Tooltip title="You don't have permission to edit aircraft manager">
                  <HelpIcon
                    sx={{
                      ml: 1,
                    }}
                  />
                </Tooltip>
              )}
            </Box>
          </Box>
        </ComponentLoading>
      </Box>
    </Container>
  );
};

export default Managers;
