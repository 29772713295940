import React from 'react';
import { useDispatch } from 'react-redux';
import { Typography, Box, Switch, FormControlLabel, TextField, InputAdornment, Button } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { setAlert } from 'state';
import { useSmDown } from 'hooks';
import { ComponentLoading } from 'components';

interface SharingOptionsProps {
  sharingMode: boolean;
  sharingUrl: string;
  setSharingMode: (value: boolean) => void;
  changeSharingMode: (value: boolean) => void;
  resetSharingUrl: () => void;
  loading: boolean;
}

const SharingOptions: React.FC<SharingOptionsProps> = (props) => {
  const { sharingMode, sharingUrl, setSharingMode, changeSharingMode, resetSharingUrl, loading } = props;

  const handleChangeEdit = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setSharingMode(event.target.checked);
    changeSharingMode(event.target.checked);
  };

  const dispatch = useDispatch();
  const isSmDown = useSmDown();

  const copySharingUrl = () => {
    if (sharingMode) {
      const inputElem = document.body.appendChild(document.createElement('input'));
      inputElem.value = sharingUrl;
      inputElem.focus();
      inputElem.select();
      document.execCommand('copy');
      inputElem.parentNode?.removeChild(inputElem);
      dispatch(setAlert('success', 'Copied link'));
    } else {
      dispatch(setAlert('error', 'Unable to share private link'));
    }
  };

  return (
    <Box p={isSmDown ? 2 : 5}>
      <ComponentLoading loading={loading}>
        <Box py={1}>
          <FormControlLabel
            control={<Switch checked={sharingMode} onChange={handleChangeEdit} name="checkedB" color="primary" />}
            label="Sharing"
          />
        </Box>
        <Box py={1}>
          <Typography
            variant="h3"
            style={{
              marginBottom: 0,
              lineHeight: '25px',
              fontWeight: 700,
            }}
            color="textSecondary"
          >
            Sharing {!sharingMode ? 'Disabled' : 'Enabled'}
          </Typography>
        </Box>
        <Box py={1}>
          <Typography
            paragraph
            sx={{
              marginBottom: 0,
              color: 'text.disabled',
              lineHeight: '24px',
            }}
          >
            Share all your flights with this link (individual flights can be shared using a public link available on each flight page)
          </Typography>
        </Box>
        <Box
          py={1}
          sx={{
            maxWidth: '500px',
          }}
        >
          <TextField
            name="sharingUrl"
            value={sharingUrl}
            disabled
            variant="outlined"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment
                  style={{
                    cursor: 'pointer',
                  }}
                  position="start"
                  onClick={copySharingUrl}
                >
                  <ContentCopyIcon sx={{ transform: 'scaleX(-1)' }} />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box py={1}>
          <Button
            variant="contained"
            style={{
              marginRight: '20px',
            }}
            color="primary"
            onClick={resetSharingUrl}
          >
            Reset Link
          </Button>
        </Box>
      </ComponentLoading>
    </Box>
  );
};

export default SharingOptions;
