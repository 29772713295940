import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, TextField, MenuItem, Typography, Divider, styled } from '@mui/material';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

import { IAircraftEligibility } from 'types';
import { aircraftRegistrationMakeModel } from 'helpers';
import { MaintenanceTicketIcon, BreakdownTicketIcon } from 'customIcons';
import { ticketTypeArray } from 'api';
import { QUERY_GET_CLIENT_ANALYSIS_PACKS } from 'gql';
import { useLazyQuery } from '@apollo/client';

import { TicketCategory, SupportCategory, PrebuyCategory } from './categoryComponents';
import { PATH_TICKETS_CREATE } from 'const';
import { ComponentLoading } from 'components';

interface NewTicketCategoryProps {
  aircrafts: IAircraftEligibility[];
  setTicketAircraft: (value: IAircraftEligibility) => void;
  ticketAircraft: IAircraftEligibility | undefined;
}

const classes = {
  flicker: 'flicker',
  hide: 'hide',
};

const Keyframes = styled('div')({
  '@keyframes flicker': {
    from: {
      opacity: 1,
    },
    to: {
      opacity: 0.5,
    },
  },
  [`& .${classes.flicker}`]: {
    animationName: 'flicker',
    animationDuration: '1000ms',
    animationIterationCount: 'infinite',
    animationDirection: 'alternate',
    animationTimingFunction: 'ease-in-out',
  },
  [`& .${classes.hide}`]: {
    display: 'none',
  },
});

const NewTicketCategory: React.FC<NewTicketCategoryProps> = (props) => {
  const { aircrafts, setTicketAircraft, ticketAircraft } = props;

  const [selectedAircraft, setSelectedAircraft] = useState<IAircraftEligibility | undefined>(ticketAircraft);

  const { isTechnician } = useSelector((state: any) => state.auth);
  const [fetchPacks, { data: dataPacks, loading: loadingPacks }] = useLazyQuery(QUERY_GET_CLIENT_ANALYSIS_PACKS);

  useEffect(() => {
    if (aircrafts.length === 1) {
      setSelectedAircraft(aircrafts[0]);
      setTicketAircraft(aircrafts[0]);
    }
  }, [aircrafts, setTicketAircraft]);

  useEffect(() => {
    if (selectedAircraft) {
      fetchPacks();
    }
  }, [selectedAircraft, fetchPacks]);

  return (
    <Box
      px={{
        xl: 6,
        lg: 4,
        md: 3,
        xs: 2,
      }}
    >
      <ComponentLoading loading={loadingPacks}>
        {aircrafts.length !== 0 && (
          <React.Fragment>
            <Box
              py={4}
              sx={{
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Keyframes
                sx={{
                  width: '20%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                <ArrowRightAltIcon
                  sx={{
                    color: 'error.dark',
                    fontSize: '4.5em',
                  }}
                  className={selectedAircraft ? classes.hide : classes.flicker}
                />
              </Keyframes>
              <Box
                sx={{
                  width: '60%',
                }}
              >
                {aircrafts.length !== 0 && (
                  <TextField
                    select
                    value={selectedAircraft ? selectedAircraft.id : ''}
                    label="Choose your aircraft"
                    variant="outlined"
                    style={{
                      marginBottom: 0,
                      minWidth: '300px',
                      textAlign: 'left',
                    }}
                    onChange={(e: any) => {
                      const tmpAircraft = aircrafts.filter((aircraft: IAircraftEligibility) => aircraft.id === e.target.value);

                      setSelectedAircraft(tmpAircraft[0]);
                      setTicketAircraft(tmpAircraft[0]);
                    }}
                  >
                    {aircrafts?.map((item: IAircraftEligibility, key: number) => {
                      return (
                        <MenuItem key={key} value={item.id} title={aircraftRegistrationMakeModel(item)}>
                          <Typography
                            sx={{
                              fontSize: '18px',
                              fontWeight: 500,
                              lineHeight: '25px',
                            }}
                          >
                            {aircraftRegistrationMakeModel(item)}
                          </Typography>
                        </MenuItem>
                      );
                    })}
                  </TextField>
                )}
              </Box>
            </Box>
            <Divider />
          </React.Fragment>
        )}
        <Box
          sx={{
            maxWidth: {
              xl: '755px',
            },
            margin: '0 auto',
          }}
        >
          <Box
            sx={{
              maxWidth: {
                xl: '755px',
              },
              margin: '0 auto',
            }}
          >
            <Box py={3.75}>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: '16px',
                  lineHeight: '23px',
                  color: 'grey.600',
                }}
              >
                Please select an option below:
              </Typography>
            </Box>
            <Box
              sx={{
                display: {
                  md: 'flex',
                  xs: 'block',
                },
                justifyContent: 'center',
              }}
              mb={3.75}
            >
              {!isTechnician && selectedAircraft && (
                <TicketCategory
                  icon={
                    <MaintenanceTicketIcon
                      sx={{
                        width: '45px',
                        height: '45px',
                      }}
                    />
                  }
                  mappingArr={ticketTypeArray['maintenance']}
                  title="Maintenance Advice"
                  aircraft={selectedAircraft}
                  analysisPacks={dataPacks?.me?.analysisPacks || []}
                />
              )}
              {!isTechnician && selectedAircraft && (
                <TicketCategory
                  icon={
                    <BreakdownTicketIcon
                      sx={{
                        width: '43px',
                        height: '42px',
                      }}
                    />
                  }
                  mappingArr={ticketTypeArray['breakdown']}
                  title="Breakdown Assistance"
                  aircraft={selectedAircraft}
                />
              )}
              {isTechnician && (
                <SupportCategory mappingArr={ticketTypeArray['support']} title="Website Issues Support" aircraft={selectedAircraft} />
              )}
            </Box>
            {!isTechnician && (
              <Box
                sx={{
                  display: {
                    md: 'flex',
                    xs: 'block',
                  },
                  justifyContent: 'center',
                }}
              >
                <SupportCategory mappingArr={ticketTypeArray['support']} title="Website Issues Support" aircraft={selectedAircraft} />
                <PrebuyCategory mappingArr={ticketTypeArray['prebuy']} title="Prebuy" aircraft={selectedAircraft} />
              </Box>
            )}
            <Box py={6}>
              <Typography
                sx={{
                  textAlign: 'center',
                  fontSize: '16px',
                  fontWeight: 400,
                  lineHeight: '23px',
                }}
              >
                If you don't know what to do next, please click{' '}
                <NavLink
                  to={`${PATH_TICKETS_CREATE}/account-support`}
                  style={{
                    color: 'rgba(63, 117, 255, 1)',
                  }}
                >
                  here
                </NavLink>
                .
              </Typography>
            </Box>
          </Box>
        </Box>
      </ComponentLoading>
    </Box>
  );
};

export default NewTicketCategory;
