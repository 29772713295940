import Highcharts from 'highcharts';
import ModeManager from './ModeManager';
import GamiManager from './GamiManager';

import { binarySearchInexact } from 'helpers';

class TooltipSync {
  x: number;
  hoverCount: number;
  selected_points: any;
  selected_index: number;
  selected_x: number;
  secondary_points: any;
  secondary_index: number;
  secondary_x: number;
  disable_fake_mouseover: boolean;
  chart_container_id: string;
  diff_data: any;
  hoverPointIndex: number;
  timeSeries: number[];

  constructor() {
    this.x = -100;
    this.hoverCount = 0;

    this.selected_points = {};
    this.selected_index = -1;
    this.selected_x = -100;

    this.secondary_points = {};
    this.secondary_index = -1;
    this.secondary_x = -100;

    this.disable_fake_mouseover = false;
    this.chart_container_id = '';

    this.diff_data = {};

    this.hoverPointIndex = -1;

    this.timeSeries = [];
  }

  mouseOver(this: TooltipSync, chart: Highcharts.Chart | undefined, x: number) {
    if (!chart) return;

    if (chart.container.id !== this.chart_container_id || this.x !== x) this.hoverCount = 0;
    this.chart_container_id = chart.container.id;
    this.x = x;
  }

  binarySearch(data: number[] | undefined, p: number) {
    if (!data) return null;

    const candidate = Math.floor((data.length * (p - data[0])) / (data[data.length - 1] - data[0]));
    if (candidate > -1 && candidate < data.length && data[candidate] === p) {
      return candidate;
    }

    let low = 0;
    let high = data.length - 1;

    while (low <= high) {
      const mid = Math.floor((low + high) / 2);
      const attempt = data[mid];
      if (attempt > p) high = mid - 1;
      else if (attempt < p) low = mid + 1;
      else return mid;
    }

    return -1;
  }

  fakeMouseOver(this: TooltipSync) {
    this.hoverCount++;
    if (this.hoverCount === 5 && this.x !== undefined) {
      this.disable_fake_mouseover = true;
      this.syncTooltip(this.chart_container_id, this.x);
      this.disable_fake_mouseover = false;
    }
  }

  timer = window.setInterval(this.fakeMouseOver.bind(this), 30);

  syncTooltip(this: TooltipSync, chart_container_id: string, p: number) {
    let search_data: any[] | undefined;

    for (let i = 0; i < Highcharts.charts.length; i++) {
      if (!Highcharts.charts[i]) continue;

      const current_chart = Highcharts.charts[i];
      const extremes = current_chart?.xAxis[0].getExtremes();
      if (!extremes) {
        continue;
      }

      current_chart?.xAxis[0]?.removePlotLine('crosshair-selector');

      // if(chart_container_id !== current_chart?.container.id) {
      let sx = 0;
      const series_count = current_chart?.series?.length ? current_chart.series.length : 0;
      do {
        search_data = (current_chart?.series[sx] as any)?.xData;
      } while (!search_data?.length && series_count && sx++ < series_count);
      if (!search_data) {
        continue;
      }

      const j = this.binarySearch(search_data, p);

      if (this.timeSeries.length) {
        this.hoverPointIndex = binarySearchInexact(this.timeSeries, p);
      }
      if (j !== null && j > -1) {
        if (extremes !== undefined && extremes.min <= p && extremes.max >= p) {
          const points: Highcharts.Point[] = [];
          if (current_chart?.series) {
            for (let x = 0; x < current_chart?.series?.length; x++) points.push(current_chart.series[x].data[j]);
            const newPoints = points.filter((point) => !!point && point?.series?.options?.visible);
            if (!newPoints.length) {
              return;
            }
            current_chart?.tooltip?.refresh(newPoints);
            current_chart?.xAxis[0]?.removePlotLine('crosshair-selector');
            current_chart?.xAxis[0]?.addPlotLine({
              color: '#bbb',
              value: p,
              width: 1,
              id: 'crosshair-selector',
              label: { text: '', style: { color: '#000' } },
              zIndex: 5,
            });
          }
        } else {
          this.hoverPointIndex = -1;
          current_chart?.xAxis[0].removePlotLine('crosshair-selector');
          current_chart?.tooltip.hide();
        }
      } else {
        this.hoverPointIndex = -1;
      }
      // }
    }
  }

  chartClick(this: TooltipSync, event: any, mode_manager: ModeManager, gami_manager?: GamiManager) {
    const two_click_mode = !mode_manager.normal();

    const primary_click = (this.selected_index === -1 && this.secondary_index === -1) || !two_click_mode;
    const secondary_click = this.selected_index > -1 && this.secondary_index === -1 && two_click_mode;
    const tertiary_click = this.selected_index > -1 && this.secondary_index > -1 && two_click_mode;

    let firstChart: Highcharts.Chart | undefined = undefined;

    for (const chart of Highcharts.charts) {
      if (!chart) continue;

      firstChart = chart;
      break;
    }

    if (!firstChart || !firstChart.series?.length) return;

    for (const chart of Highcharts.charts) {
      if (!chart) continue;

      chart.xAxis[0]?.removePlotLine('primary-selector');
      chart.xAxis[0]?.removePlotLine('secondary-selector');
    }

    let selected_index: number | undefined = undefined;
    let xValue: number | undefined = undefined;

    if (event.point) {
      xValue = event.point.x;
      selected_index = binarySearchInexact((firstChart?.series[0] as any).xData, xValue as number);
    } else if (event.xAxis[0].value) {
      xValue = event.xAxis[0].value;
      selected_index = binarySearchInexact((firstChart?.series[0] as any).xData, xValue as number);
      xValue = (firstChart?.series[0] as any).xData[selected_index];
    }

    if (xValue === undefined) return;

    if (selected_index === undefined) return;

    if (event.xAxis && event.xAxis[0]) {
      if (firstChart?.series[0]?.data[selected_index]) xValue = firstChart.series[0].data[selected_index].x;
    }

    if (primary_click) {
      this.selected_index = selected_index;
      this.selected_x = xValue;
      this.selected_points = {};
    } else if (secondary_click) {
      this.secondary_index = selected_index;
      this.secondary_x = xValue;
      this.secondary_points = {};
    } else if (tertiary_click) {
      this.selected_index = selected_index;
      this.selected_x = xValue;
      this.selected_points = {};

      this.secondary_index = -1;
      this.secondary_x = -100;
      this.secondary_points = {};
    }

    for (const chart of Highcharts.charts) {
      if (!chart) continue;

      if (primary_click || tertiary_click) {
        if (two_click_mode) {
          chart.xAxis[0]?.addPlotLine({
            color: '#000',
            value: this.selected_x,
            width: 1,
            id: 'primary-selector',
            label: { text: '', style: { color: '#000' } },
            zIndex: 5,
          });
        } else {
          chart.xAxis[0]?.addPlotLine({
            color: '#000',
            value: this.selected_x,
            width: 1,
            id: 'primary-selector',
            label: { text: '', style: { color: '#000' } },
            zIndex: 5,
          });
        }
      } else {
        chart.xAxis[0]?.addPlotLine({
          color: '#000',
          value: this.selected_x,
          width: 1,
          id: 'primary-selector',
          label: { text: '', style: { color: '#000' } },
          zIndex: 5,
        });
        chart.xAxis[0]?.addPlotLine({
          color: '#000',
          value: this.secondary_x,
          width: 1,
          id: 'secondary-selector',
          label: { text: '', style: { color: '#000' } },
          zIndex: 5,
        });
      }

      for (const serie of chart.series as any) {
        if (!serie) continue;

        if (primary_click || tertiary_click) this.selected_points[serie.name] = { ...serie.points[this.selected_index - serie.cropStart] };
        else this.secondary_points[serie.name] = { ...serie.points[this.secondary_index - serie.cropStart] };
      }
    }

    if (mode_manager.gami()) {
      if (secondary_click) {
        gami_manager?.addGamiLines(firstChart, this.selected_x, this.secondary_x);
        // this.diff_data = {
        //   gami_manager?.getGamiData()
        // }
        this.diff_data = gami_manager ? { ...gami_manager.getGamiData() } : {};
      } else {
        if (gami_manager) {
          gami_manager.gamiData = {};
        }
        this.diff_data = {};
      }
    } else {
      if (primary_click) {
        this.diff_data = {};
      } else if (secondary_click) {
        this.diff_data = {
          firstSelect: { ...this.selected_points },
          secondSelect: { ...this.secondary_points },
          selectedPoint: Highcharts.dateFormat('%H:%M:%S', this.selected_x),
          secondaryPoint: Highcharts.dateFormat('%H:%M:%S', this.secondary_x),
        };
      } else if (tertiary_click) {
        this.diff_data = {
          firstSelect: { ...this.selected_points },
          secondSelect: undefined,
          selectedPoint: Highcharts.dateFormat('%H:%M:%S', this.selected_x),
          secondaryPoint: '',
        };
      }
    }
  }

  getDiffData(this: TooltipSync) {
    return this.diff_data;
  }
}

export default TooltipSync;
