import { gql } from '@apollo/client';

export const MUTATION_TEST_POSTMARK_TEMPLATES = gql`
  mutation TestPostmarkTemplate($templateAliases: [String]) {
    testPostmarkTemplate(templateAliases: $templateAliases) {
      ok
      error
    }
  }
`;

export const MUTATION_TEST_API_TEMPLATES = gql`
  mutation TestApiTemplate($templateIds: [String]) {
    testApiTemplate(templateIds: $templateIds) {
      ok
      error
      downloadUrls
    }
  }
`;
