import React, { useState, useEffect } from 'react';
import FormGroupInput from './FormGroupInput';

import { useLazyQuery } from '@apollo/client';
import { QUERY_ACCOUNT_EXIST } from 'gql';

interface SignupEmailProps {
  handleBlur: (e: React.FocusEvent<any, Element>) => void;
  handleChange: (e: React.ChangeEvent<any>) => void;
  touched: any;
  errors: any;
  emailError: string;
  setEmailError: (value: string) => void;
}

const SignupEmail: React.FC<SignupEmailProps> = (props) => {
  const { handleBlur, handleChange, touched, errors, emailError, setEmailError } = props;

  const [email, setEmail] = useState('');

  const [refetchAccountExist, { loading: loadingAccountExist, data: dataAccountExist, error: errorFetchAccountExist }] =
    useLazyQuery(QUERY_ACCOUNT_EXIST);

  // Delay 500ms when change email, so don't make request per character
  useEffect(() => {
    if (!email) {
      return;
    }

    const timeout: any = setTimeout(() => {
      refetchAccountExist({
        variables: {
          email,
        },
      });
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [email, refetchAccountExist]);

  // If email already exists, display error message
  useEffect(() => {
    if (!loadingAccountExist && dataAccountExist) {
      const { errors, exists } = dataAccountExist.accounts;
      if (!errors) {
        if (exists) {
          setEmailError('The email already exists.');
        }
      }
    }
  }, [errorFetchAccountExist, loadingAccountExist, dataAccountExist, setEmailError]);

  return (
    <FormGroupInput
      name="email"
      label="Email *"
      onBlur={handleBlur}
      onChange={(e) => {
        handleChange(e);
        setEmail(e.target.value);
        setEmailError('');
      }}
      errors={errors}
      touched={touched}
      existEmail={Boolean(emailError)}
    />
  );
};

export default SignupEmail;
