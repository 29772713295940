import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Container, Tabs, Tab, Box } from '@mui/material';

import { ComponentLoading, a11yProps, SimpleTabPanel } from 'components';

import { QUERY_SAVVY_AIRCRAFT_LOGS, QUERY_SAVVY_AIRCRAFT_LOGBOOKS } from 'gql';
import { useLazyQuery } from '@apollo/client';

import { LogDetail } from 'pages/Aircrafts/components/EditComponents';
import { default as Logbooks } from './Logbooks';

import { setAlert } from 'state';

interface LogsProps {
  isLoad: boolean;
  aircraftId: string;
}

const Logs: React.FC<LogsProps> = (props) => {
  const { isLoad, aircraftId } = props;

  const [fetchLogs, { data: dataLogs, error: errorLogs, loading: loadingLogs }] = useLazyQuery(QUERY_SAVVY_AIRCRAFT_LOGS, {
    fetchPolicy: 'cache-and-network',
  });
  const [fetchLogBooks, { data: dataLogBooks, error: errorLogBooks, loading: loadingLogBooks }] =
    useLazyQuery(QUERY_SAVVY_AIRCRAFT_LOGBOOKS);
  const dispatch = useDispatch();

  const [logbookId, setLogbookId] = useState('');

  const [tabValue, setTabValue] = useState(0);
  const handleChange = (event: React.ChangeEvent<unknown>, newValue: number) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (isLoad && aircraftId) {
      fetchLogBooks({
        variables: {
          aircraftId,
        },
      });
    }
  }, [isLoad, aircraftId, fetchLogBooks]);

  useEffect(() => {
    if (errorLogBooks) {
      dispatch(setAlert('error', 'Unable to load aircraft logbooks'));
    }
  }, [errorLogBooks, dispatch]);

  useEffect(() => {
    if (isLoad && aircraftId) {
      fetchLogs({
        variables: {
          aircraftId,
        },
      });
    }
  }, [isLoad, aircraftId, fetchLogs]);

  useEffect(() => {
    if (errorLogs) {
      dispatch(setAlert('error', 'Unable to load aircraft logs'));
    }
  }, [errorLogs, dispatch]);

  return (
    <Container fixed maxWidth="xl">
      <ComponentLoading loading={loadingLogs || loadingLogBooks}>
        <Tabs
          value={tabValue}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{
            '& .MuiTabs-indicator': {
              backgroundColor: 'error.dark',
            },
            borderBottom: '1px solid',
            borderBottomColor: 'grey.200',
            '& .MuiTabs-flexContainer': {
              justifyContent: 'flex-start',
            },
          }}
          variant={'standard'}
        >
          <Tab
            label="Logbooks"
            {...a11yProps(0)}
            sx={{
              py: 2,
              px: 1,
              '&.Mui-selected': {
                color: 'error.dark',
              },
              fontSize: '16px',
              lineHeight: '22px',
              fontWeight: 600,
            }}
          />
          <Tab
            label="Maintenance Entries"
            {...a11yProps(1)}
            sx={{
              py: 2,
              px: 1,
              '&.Mui-selected': {
                color: 'error.dark',
              },
              fontSize: '16px',
              lineHeight: '22px',
              fontWeight: 600,
            }}
          />
        </Tabs>
        <Box>
          <SimpleTabPanel value={tabValue} index={0}>
            {isLoad && tabValue === 0 && (
              <Logbooks
                logbooks={dataLogBooks?.savvy?.aircraft?.logbooks || []}
                aircraftId={aircraftId}
                maintenanceEntries={dataLogBooks?.savvy?.aircraft?.maintenanceEntries || []}
                setTabValue={setTabValue}
                setLogbookId={setLogbookId}
              />
            )}
          </SimpleTabPanel>
          <SimpleTabPanel value={tabValue} index={1}>
            {isLoad && tabValue === 1 && (
              <LogDetail
                aircraft={dataLogs?.savvy?.aircraft}
                canCreateMaintenanceEntry={dataLogBooks?.savvy?.permissions?.aircraft?.canCreateMaintenanceEntry}
                logbookId={logbookId}
                setLogbookId={setLogbookId}
                logbooks={dataLogBooks?.savvy?.aircraft?.logbooks || []}
              />
            )}
          </SimpleTabPanel>
        </Box>
      </ComponentLoading>
    </Container>
  );
};

export default Logs;
