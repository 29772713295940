const seriesOrder = [
  'EGT',
  'CHT',
  'FF',
  'RPM',
  'MAP',
  'OIL_TEMP',
  'OIL_PRESS',
  'VOLTS',
  'CARB_TEMP',
  'Altitudes',
  'AltMSL',
  'AltGPS',
  'ALtB',
  'ALT',
  'BARO',
  'HDG',
  'CRS',
  'LAT',
  'LON',
  'TAS',
  'GndSpd',
  'WndDr',
  'WndSpd',
  'FUEL_PRESS',
  'FQtyL',
  'FQtyR',
];

export default seriesOrder;
