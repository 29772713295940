import React from 'react';
import { IAircraftEligibility } from 'types';
import RequirePurchase from './RequirePurchase';
import { ServiceAnnualFormComponent } from './ServiceAnnual';

interface ServiceAnnualFormProps {
  ticketAircraft: IAircraftEligibility;
}

const ServiceAnnualForm: React.FC<ServiceAnnualFormProps> = (props) => {
  const { ticketAircraft } = props;

  return (
    <React.Fragment>
      <RequirePurchase ticketAircraft={ticketAircraft} eligibility={ticketAircraft.ticketEligibility.serviceAnnual} />
      <ServiceAnnualFormComponent ticketAircraft={ticketAircraft} eligibility={ticketAircraft.ticketEligibility.serviceAnnual} />
    </React.Fragment>
  );
};

export default ServiceAnnualForm;
