import React from 'react';
import { Box, Typography, Skeleton } from '@mui/material';

interface ConsumedBarProps {
  color?: string;
  width: number;
  blink?: boolean;
}

const ConsumedBar: React.FC<ConsumedBarProps> = (props) => {
  const { color = 'darkslategray', width, blink = false } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          width: '100px',
          height: '15px',
          display: 'inline-block',
          backgroundColor: 'grey.200',
        }}
      >
        <Skeleton
          sx={{
            width: `${width > 100 ? 100 : width}%`,
            backgroundColor: blink ? 'error.main' : color,
            transform: 'scale(1, 1)',
            borderRadius: 0,
          }}
          height={15}
          animation={blink ? 'pulse' : false}
        ></Skeleton>
      </Box>
      <Typography
        variant="body1"
        sx={{
          ml: 1,
        }}
      >
        {width}%
      </Typography>
    </Box>
  );
};

export default ConsumedBar;
