import React from 'react';
import { CSSObject } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';

interface TicketIconProps {
  sx?: CSSObject;
}

const CloseTicketIcon: React.FC<TicketIconProps> = (props) => {
  const { sx } = props;

  return (
    <SvgIcon
      viewBox="0 0 14 20"
      width="14"
      height="20"
      sx={{
        fill: 'none',
        ...sx,
      }}
    >
      <path
        d="M6.66667 2.16667C7.6912 2.16667 8.54964 1.45557 8.77536 0.5H11.6667C12.311 0.5 12.8333 1.02232 12.8333 1.66667V18.3333C12.8333 18.9777 12.311 19.5 11.6667 19.5H8.77536C8.54964 18.5444 7.6912 17.8333 6.66667 17.8333C5.64213 17.8333 4.78369 18.5444 4.55798 19.5H1.66667C1.02232 19.5 0.5 18.9777 0.5 18.3333V1.66667C0.5 1.02232 1.02232 0.5 1.66667 0.5H4.55798C4.78369 1.45557 5.64213 2.16667 6.66667 2.16667Z"
        stroke="#969EB3"
      />
      <path
        d="M9.84471 6.6606C9.63766 6.45355 9.30319 6.45355 9.09613 6.6606L6.5 9.25142L3.90387 6.65529C3.69681 6.44824 3.36234 6.44824 3.15529 6.65529C2.94824 6.86234 2.94824 7.19681 3.15529 7.40387L5.75142 10L3.15529 12.5961C2.94824 12.8032 2.94824 13.1377 3.15529 13.3447C3.36234 13.5518 3.69681 13.5518 3.90387 13.3447L6.5 10.7486L9.09613 13.3447C9.30319 13.5518 9.63766 13.5518 9.84471 13.3447C10.0518 13.1377 10.0518 12.8032 9.84471 12.5961L7.24858 10L9.84471 7.40387C10.0465 7.20212 10.0465 6.86234 9.84471 6.6606Z"
        fill="#969EB3"
      />
    </SvgIcon>
  );
};

export default CloseTicketIcon;
