import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { MUTATION_VERIFY_SECURITY_TOKEN } from 'gql';
import { useMutation } from '@apollo/client';

import { LayoutLoggedOut, PageLoading } from 'components';

import { setAlert } from 'state';
import { SET_MISSING_TOKEN } from 'state/types';

const useQuery = () => new URLSearchParams(useLocation().search);

const SetCookieFromToken: React.FC = () => {
  const [verifySecurityToken, { data, loading, error: errorToken }] = useMutation(MUTATION_VERIFY_SECURITY_TOKEN);

  const dispatch = useDispatch();

  const query = useQuery();
  const slug = query.get('token');

  useEffect(() => {
    if (slug) {
      verifySecurityToken({
        variables: {
          slug,
        },
      });
    }
  }, [slug, verifySecurityToken]);

  useEffect(() => {
    if (!URLSearchParams) {
      dispatch(setAlert('error', 'Your browser is out of date. Please update to a newer version.'));
    }
  }, [dispatch]);

  useEffect(() => {
    if (errorToken) {
      dispatch(setAlert('error', 'Unable to verify your token'));
    } else if (data) {
      const { ok, error } = data.verifySecurityToken;

      if (ok) {
        dispatch(setAlert('success', 'Valid token'));
        dispatch({ type: SET_MISSING_TOKEN });

        setTimeout(() => {
          window.location.href = '/';
        }, 500);
      } else {
        dispatch(setAlert('error', error));
      }
    }
  }, [errorToken, data, dispatch]);

  return <LayoutLoggedOut>{loading && <PageLoading />}</LayoutLoggedOut>;
};

export default SetCookieFromToken;
