import { SET_UNREAD_POST } from 'state/types';

// Initial state (user data)
const initialState = {
  postId: '',
  ticketId: '',
};

// Reducer
const unreadPostReducer = (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    // Login
    case SET_UNREAD_POST:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

export default unreadPostReducer;
