import { gql } from '@apollo/client';
import { FRAGMENT_MANAGER_CONFLICT } from 'gql';

export const MUTATION_CREATE_MANAGER_CONFLICT = gql`
  ${FRAGMENT_MANAGER_CONFLICT}
  mutation CreateManagerConflict($contactId: ID, $serviceCenterId: ID) {
    createManagerConflict(contactId: $contactId, serviceCenterId: $serviceCenterId) {
      ok
      managerConflict {
        ...conflict
      }
      error
    }
  }
`;

export const MUTATION_UPDATE_MANAGER_CONFLICT = gql`
  ${FRAGMENT_MANAGER_CONFLICT}
  mutation UpdateManagerConflict($contactId: ID, $managerConflictId: ID, $serviceCenterId: ID) {
    updateManagerConflict(contactId: $contactId, managerConflictId: $managerConflictId, serviceCenterId: $serviceCenterId) {
      ok
      managerConflict {
        ...conflict
      }
      error
    }
  }
`;

export const MUTATION_DELETE_MANAGER_CONFLICT = gql`
  mutation DeleteManagerConflict($managerConflictId: ID) {
    deleteManagerConflict(managerConflictId: $managerConflictId) {
      ok
      error
    }
  }
`;
