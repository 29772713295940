// Pieces of GrahpQl to use in queries and mutations
// https://www.apollographql.com/docs/react/data/fragments/
import { gql } from '@apollo/client';

export const FRAGMENT_ME = gql`
  fragment Me on ContactType {
    id
    email
    nickname
    firstName
    lastName
    avatar
    incompleteAircraft {
      id
      registration
      subscriptions {
        id
        serviceCode
      }
    }
    isSavvy
    isClient
    isTechnician
    emailVerified
    created
    role
  }
`;

export const FRAGMENT_TICKETS_DASHBOARD_ROW = gql`
  fragment TicketDashboard on TicketsDashboardRow {
    category
    person
    count
  }
`;

export const FRAGMENT_DOCUMENT = gql`
  fragment Document on DocumentType {
    id
    created
    updated
    fileSize
    owner {
      id
      firstName
      lastName
    }
    fileType
    fileName
    file
    attachment {
      id
      name
      description
    }
  }
`;

export const FRAGEMENT_SERVICE_CENTER = gql`
  fragment ServiceCenter on ServiceCenterType {
    id
    created
    updated
    company
    street
    zip
    city
    state
    country
    airportId
    phoneWork
    phoneFax
  }
`;

export const FRAGMENT_ELIGIBILITY_DETAILS = gql`
  fragment EligibilityDetails on EligibilityDetails {
    eligible
    free
    currentlyEligibleVia
    currentlyEligibleViaSubscription
    eligibleViaPurchase
  }
`;

export const FRAGMENT_RATING_SC = gql`
  fragment Rating on RatingType {
    score
    color
    alert
  }
`;

export const FRAGMENT_STATISTIC_SC = gql`
  fragment StatisticItem on CategoryStatisticItemType {
    timestamp
    score
    color
    ticket {
      id
      subject
    }
  }
`;

export const FRAGMENT_COI = gql`
  fragment AircraftCOI on CertificateOfInsuranceType {
    id
    underwriter
    agent
    document {
      id
      created
      updated
      owner {
        id
        firstName
        lastName
      }
      fileName
      fileType
    }
    deleted
    created
    approved
    receivedDate
    expirationDate
  }
`;

export const FRAGMENT_MAINTENANCE_ENTRY = gql`
  fragment MaintenanceEntry on MaintenanceEntryType {
    id
    body
    maintenanceEntryCategory {
      id
      name
    }
    entryDate
    isPerformedInspection
    isPerformedMaintenance
    signerFirstName
    signerLastName
    document {
      id
      fileName
      isImage
      isPdf
      previewUrl
      attachment {
        id
        description
      }
    }
    logbook {
      id
    }
    created
    updated
    deleted
    isCombo
  }
`;

export const FRAGMENT_AIRCRAFT_UPGRADE_CONTEXT = gql`
  fragment upgradeContext on AircraftType {
    upgradeContext {
      title
      renewals {
        id
        name
        status
        expiration
        showDoNotRenew
        isCanceled
      }
      options {
        title
        titleColor
        price
        credit
        total
        description
        learnMoreLink
        actionLink
        buttonText
        subscriptionId
        discount
      }
      lessExpensiveOptions {
        title
        titleColor
        description
        price
        credit
        total
        buttonText
        learnMoreLink
        actionLink
        subscriptionId
        discount
      }
      canTransferSubscriptions
    }
  }
`;

export const FRAGMENT_AIRCRAFT_LOGBOOK = gql`
  fragment Logbook on MaintenanceLogbookType {
    id
    name
    created
    updated
    deleted
    maintenanceEntries {
      id
    }
    entriesCount
  }
`;

export const FRAGMENT_MANAGER_CONFLICT = gql`
  fragment conflict on ManagerConflictType {
    id
    serviceCenter {
      id
      company
      airportId
      street
      city
      state
      country
      zip
    }
    contact {
      id
      firstName
      lastName
      isActive
      email
    }
  }
`;

export const FRAGMENT_ANALYSIS_PACK = gql`
  fragment analysisPack on AnalysisPackType {
    id
    creationDate
    startDate
    endDate
    promoCode
    incidents
    remainingIncidents
    packDefinition {
      id
      name
      longName
      duration
      durationUnits
      inactive
      amount
      incidents
    }
  }
`;

export const FRAGMENT_COHORTS = gql`
  fragment Cohort on CohortType {
    id
    updated
    aircraftModel {
      id
      name
      manufacturer {
        id
        name
      }
    }
    engineModel {
      id
      name
      manufacturer {
        id
        name
      }
    }
    numberOfFlights
    parentCohort {
      id
    }
    engineParameters {
      id
      name
    }
  }
`;

export const FRAGMENT_TIMESHEET = gql`
  fragment Timesheet on TimesheetType {
    id
    created
    updated
    user {
      id
      firstName
      lastName
    }
    category {
      id
      name
    }
    minutes
    state
    remarks
    ticket {
      id
      subject
    }
  }
`;

export const FRAGMENT_FLIGHT_REPORT = gql`
  fragment FlightReport on FlightReportType {
    id
    ticket {
      id
      subject
    }
    engine
    createdOn
    lastUpdateOn
    flight {
      id
      date
      duration
      destinationAirport
      departureAirport
      aircraft {
        id
        registration
        owner {
          id
          firstName
          lastName
        }
      }
    }
    clientComments
    gami1
    gami2
    gami3
    gami4
    gamiSummary
    ignition1
    ignition2
    ignition3
    ignition4
    ignitionSummary
    power1
    power2
    power3
    power4
    powerSummary
    temperatures1
    temperatures2
    temperatures3
    temperatures4
    temperaturesSummary
    monitor1
    monitor2
    monitor3
    monitor4
    monitorSummary
    powerplant1
    powerplant2
    powerplant3
    powerplant4
    powerplantSummary
    electrical1
    electrical2
    electrical3
    electrical4
    electricalSummary
    findings
    recommendations
    additional
    sisterReport {
      id
      engine
      gami1
      gami2
      gami3
      gami4
      gamiSummary
      ignition1
      ignition2
      ignition3
      ignition4
      ignitionSummary
      power1
      power2
      power3
      power4
      powerSummary
      temperatures1
      temperatures2
      temperatures3
      temperatures4
      temperaturesSummary
      monitor1
      monitor2
      monitor3
      monitor4
      monitorSummary
      powerplant1
      powerplant2
      powerplant3
      powerplant4
      powerplantSummary
      electrical1
      electrical2
      electrical3
      electrical4
      electricalSummary
      findings
      recommendations
      additional
    }
  }
`;

export const FRAGMENT_OOP = gql`
  fragment OutOfPocketInstance on OutOfPocketInstanceType {
    id
    created
    createdBy {
      id
      firstName
      lastName
      email
    }
    user {
      id
      firstName
      lastName
      email
    }
    startTime
    endTime
  }
`;

export const FRAGMENT_SERVICE_KEY_INFO = gql`
  fragment ServiceKeyInfo on ServiceKeyInfoType {
    service
    series {
      name
      y
      isIntermediateSum
      isSum
      color
    }
  }
`;

export const FRAGMENT_SERVICE_NET_INFO = gql`
  fragment ServiceNetInfo on ServiceNetInfoType {
    name
    data
    dataLabels {
      enabled
    }
  }
`;

export const FRAGMENT_CANNED_RESPONSE = gql`
  fragment CannedResponse on CannedResponseType {
    id
    title
    text
    owner {
      id
      firstName
      lastName
    }
    deleted
  }
`;

export const FRAGMENT_FEVA_ALERT = gql`
  fragment FevaAlert on FevaAlertType {
    id
    cylinders
    flight {
      id
      duration
      date
      aircraft {
        id
        registration
        owner {
          id
          firstName
          lastName
          email
        }
        year
        serial
        aircraftManufacturer {
          id
          name
        }
        aircraftModel {
          id
          name
        }
        engineManufacturer {
          id
          name
        }
        engineModel {
          id
          name
        }
        engineMonitorManufacturer {
          id
          name
        }
        engineMonitorModel {
          id
          name
        }
      }
    }
    notificationDate
    chartVerificationStatus
    physicalVerificationStatus
    ticket {
      id
      subject
    }
    manuallyCreated
    comment
    fevaTags {
      id
      created
      name
    }
  }
`;

export const FRAGMENT_PREBUY_INQUIRY = gql`
  fragment PrebuyInquiry on PrebuyInquiryType {
    id
    created
    updated
    contact {
      id
      firstName
      lastName
    }
    emailContact {
      id
      firstName
      lastName
      email
    }
    status
    disposition
    phone
    homeAirportId
    candidateAirport
    commitment
    firstTimeBuyer
    notQuiteSure
    knowExactly
    narrowedToAFew
    narrowedToOne
    brokerInvolved
    scannedLogbooks
    modelDecided
    modelsConsidered
    candidate1
    candidate2
    candidate3
    candidateChosen
    candidateRegistration
    missionProfile
    remarks
    attachments
    savvyRemarks
    prebuyinquirytagSet {
      id
      created
      metadata
      name
      verboseName
    }
  }
`;

export const FRAGMENT_ENGINE_PARAMETERS = gql`
  fragment EngineParameters on EngineParametersType {
    id
    name
    horsePower
    parameters {
      model
      params {
        rpmDecay1
        rpmDecay2
        hpMax
        mapMax
        mapDecay
        compressionRatio
        hp18RpmMax
        altCompensationMultiplier
        rpmMax
        rpmRatio
      }
      values
    }
  }
`;

export const FRAGMENT_TICKET_TAG = gql`
  fragment TagType on TicketType {
    tags {
      ... on AccountSupportTagType {
        id
        name
        verboseName
        created
        metadata
      }
      ... on AnalysisTagType {
        id
        name
        verboseName
        created
        metadata
        flight {
          id
          duration
          date
          departureAirport
          destinationAirport
          aircraft {
            id
          }
        }
        report {
          id
        }
      }
      ... on AccountSupportRenewalTagType {
        id
        name
        verboseName
        created
        metadata
      }
      ... on MergedTicketTagType {
        id
        name
        verboseName
        created
        metadata
      }
      ... on ServiceTagType {
        id
        name
        verboseName
        service {
          id
          serviceName
        }
        created
        metadata
      }
      ... on ParseFailureTagType {
        id
        name
        verboseName
        created
        metadata
      }
      ... on PrebuyInquiryTagType {
        id
        name
        verboseName
        created
        metadata
      }
      ... on FevaTagType {
        id
        name
        verboseName
        created
        metadata
      }
      ... on Feva2TagType {
        id
        name
        verboseName
        created
        metadata
      }
      ... on FeedbackTagType {
        id
        name
        verboseName
        created
        metadata
      }
      ... on ServiceSignupTagType {
        id
        name
        verboseName
        created
        metadata
        service {
          serviceCode
        }
      }
      ... on FABATagType {
        id
        name
        verboseName
        created
        metadata
      }
      ... on UserTagType {
        id
        name
        verboseName
        userTag {
          id
          name
        }
        created
        metadata
      }
      ... on TicketTagType {
        id
        reportingTag {
          id
          name
        }
        created
        metadata
      }
      ... on COIRequestTagType {
        id
        name
        verboseName
        created
        metadata
      }
      ... on ShareMaintenanceLogsTagType {
        id
        reportingTag {
          id
          name
        }
        name
        verboseName
        logs
        metadata
      }
      ... on BorescopeAnalysisTagType {
        tagId
        name
        borescopeImageSet {
          id
          name
          status
        }
      }
    }
  }
`;

export const FRAGMENT_BORESCOPE_IMAGE_SET = gql`
  fragment BorescopeImageSet on BorescopeImageSetType {
    id
    created
    updated
    name
    engine
    deleted
    clientComments
    images(completedOnly: true) {
      id
      isBadImage
    }
    status
  }
`;

export const FRAGMENT_BANK_INFO = gql`
  fragment BankInfo on BankInfoType {
    routingNumber
    accountNumber
    accountType
    legalEntityType
    type
    accountHolderName
    tin
  }
`;
