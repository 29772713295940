import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { RESET_PASSWORD_ERROR, RESET_PASSWORD_SUCCESS } from 'state/types';

import { useMutation } from '@apollo/client';
import { MUTATION_RESET_PASSWORD } from 'gql';

import { Grid, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';

import { Formik, Form } from 'formik';

import { LayoutLoggedOut, Subsection, ButtonSubmit, FormGroupInput } from 'components';

import * as Yup from 'yup';

interface ResetPasswordFormValues {
  email: string;
}

const validFormSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
});

// Main export
const ResetPassword: React.FC = () => {
  const dispatch = useDispatch();

  const [emailError, setEmailError] = useState('');

  const onSubmit = async (values: ResetPasswordFormValues) => {
    await resetPassword({
      variables: {
        email: values.email,
      },
    });
  };

  // GraphQL
  const [resetPassword, { loading, data, error }] = useMutation(MUTATION_RESET_PASSWORD, {
    errorPolicy: 'all',
  });

  // Send Error
  useEffect(() => {
    if (error?.graphQLErrors?.length) {
      setEmailError(error?.graphQLErrors[0].message);
    } else if (data) {
      const { errors } = data.resetPassword;

      if (errors) {
        dispatch({ type: RESET_PASSWORD_ERROR });
      } else {
        // Success
        dispatch({ type: RESET_PASSWORD_SUCCESS });
      }
    }
  }, [error, data, dispatch]);

  // Default return - Form
  return (
    <LayoutLoggedOut>
      {(error || !data) && (
        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12} sm={10} md={6} lg={5}>
            <Subsection>
              <Typography variant="h1" align="center" gutterBottom>
                Reset Password
              </Typography>
            </Subsection>

            <Formik initialValues={{ email: '' }} onSubmit={onSubmit} validationSchema={validFormSchema}>
              {({ isSubmitting, handleChange, handleBlur, touched, errors }) => {
                if (emailError) {
                  errors.email = emailError;
                }

                return (
                  <Form
                    onChange={() => {
                      setEmailError('');
                    }}
                  >
                    <Subsection>
                      <FormGroupInput
                        name="email"
                        label="Email *"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                          setEmailError('');
                        }}
                        errors={errors}
                        touched={touched}
                        maxLength={50}
                      />
                    </Subsection>
                    <Subsection>
                      <ButtonSubmit text="Reset" loading={isSubmitting || loading} />
                    </Subsection>
                    <Subsection>
                      <Typography paragraph color="textSecondary" align="center">
                        Check your spam folder and/or add "@SavvyAviation.com" to your spam filter's whitelist.
                      </Typography>
                    </Subsection>
                  </Form>
                );
              }}
            </Formik>
          </Grid>
        </Grid>
      )}
      {!error && data && (
        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12} sm={10} md={6} lg={8}>
            <Subsection>
              <Typography variant="h1" align="center" gutterBottom>
                Reset Password
              </Typography>
            </Subsection>

            {/* Alert */}
            <Alert color="success" variant="filled">
              We've e-mailed you instructions for setting your password to the e-mail address you submitted. You should be receiving it
              shortly. If you do not receive this email within 5 minutes or so, please check your spam or junk folder.
            </Alert>
          </Grid>
        </Grid>
      )}
    </LayoutLoggedOut>
  );
};

export default ResetPassword;
