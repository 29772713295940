import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box, Collapse, IconButton, Typography, TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { ICannedResponse } from 'types';
import { ComponentLoading } from 'components';

import { QUERY_CANNED_RESPONSES } from 'gql';
import { useLazyQuery } from '@apollo/client';

interface CannedResponsesControlProps {
  setCannedResponseText: (value: string) => void;
}

const CannedResponsesControl: React.FC<CannedResponsesControlProps> = (props) => {
  const { setCannedResponseText } = props;

  const { isSavvy } = useSelector((state: any) => state.auth);
  const [fetchCannedResponses, { data: dataCannedResponses, loading: loadingCannedResponses }] = useLazyQuery(QUERY_CANNED_RESPONSES);

  const [showList, setShowList] = useState(false);

  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    if (isSavvy) {
      fetchCannedResponses();
    }
  }, [isSavvy]);

  if (!isSavvy) {
    return null;
  }

  return (
    <Box py={2}>
      <ComponentLoading loading={loadingCannedResponses}>
        <Box
          sx={{
            border: '2px solid',
            borderColor: 'grey.800',
          }}
        >
          <Box
            sx={{
              py: 1,
              width: '100%',
              px: 1,
              background: 'rgba(245, 246, 250, 0.5)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              cursor: 'pointer',
            }}
            onClick={() => {
              setShowList(!showList);
            }}
          >
            <Typography
              sx={{
                color: 'text.secondary',
                fontWeight: 700,
                fontSize: '18px',
                lineHeight: '25px',
              }}
            >
              Canned Responses
              <Box
                component={'span'}
                sx={{
                  marginLeft: '10px',
                  backgroundColor: 'grey.400',
                  padding: '0 5px',
                  borderRadius: '4px',
                  color: 'background.default',
                  fontSize: '13px',
                  fontWeight: 600,
                  lineHeight: '18px',
                }}
              >
                {dataCannedResponses?.savvy?.cannedResponses?.length || 0}
              </Box>
            </Typography>
            <IconButton>{showList ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}</IconButton>
          </Box>
          <Collapse in={showList}>
            <Box
              sx={{
                maxHeight: '300px',
                overflowY: 'auto',
              }}
              px={1}
            >
              <Box
                py={1}
                sx={{
                  maxWidth: '350px',
                }}
              >
                <TextField
                  label="Search"
                  name="search"
                  value={searchText}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setSearchText(e.target.value);
                  }}
                  sx={{
                    marginBottom: 0,
                  }}
                />
              </Box>
              {dataCannedResponses?.savvy?.cannedResponses
                ?.filter((cannedResponses: ICannedResponse) => {
                  if (searchText === '') {
                    return cannedResponses;
                  } else if (cannedResponses.title.toLowerCase().includes(searchText.toLowerCase())) {
                    return cannedResponses;
                  } else {
                    return null;
                  }
                })
                .map((cannedResponse: ICannedResponse, key: number) => {
                  return (
                    <Box key={key}>
                      <Typography
                        sx={{
                          textDecoration: 'underline',
                          cursor: 'pointer',
                          fontWeight: 600,
                          py: 1,
                          display: 'inline-block',
                        }}
                        onClick={() => {
                          setCannedResponseText(cannedResponse.text);
                        }}
                      >
                        {cannedResponse.title}
                      </Typography>
                    </Box>
                  );
                })}
            </Box>
          </Collapse>
        </Box>
      </ComponentLoading>
    </Box>
  );
};

export default CannedResponsesControl;
