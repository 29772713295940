import React from 'react';
import { Box } from '@mui/material';
import { LayoutDashboard } from 'components';
import { QUERY_USER_AIRCRAFTS_WITHOUT_SUBSCRIPTION } from 'gql';
import { useQuery } from '@apollo/client';

import { PageLoading } from 'components';
import { PlanTable } from './components';

const Plans: React.FC = () => {
  const { data, loading } = useQuery(QUERY_USER_AIRCRAFTS_WITHOUT_SUBSCRIPTION);

  return (
    <LayoutDashboard backgroundColor="#FFFFFF">
      {loading && <PageLoading />}
      <Box>{data?.me?.aircraft && <PlanTable aircrafts={data?.me?.aircraft ? data.me.aircraft : []} />}</Box>
    </LayoutDashboard>
  );
};

export default Plans;
