import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';

import { QUERY_GET_DETAIL_AIRCRAFT } from 'gql';
import { useLazyQuery } from '@apollo/client';

import { ComponentLoading } from 'components';
import { AircraftInformation } from 'pages/Aircrafts/components/Tabs';
import { setAlert } from 'state';

interface EditAircraftDialogProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  aircraftId: string;
}

const EditAircraftDialog: React.FC<EditAircraftDialogProps> = (props) => {
  const { aircraftId, open, setOpen } = props;

  const [fetchDetailAircraft, { data: dataDetailAircraft, loading: loadingDetailAircraft, error: errorDetailAircraft }] =
    useLazyQuery(QUERY_GET_DETAIL_AIRCRAFT);
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (open && aircraftId) {
      fetchDetailAircraft({
        variables: {
          id: aircraftId,
        },
      });
    }
  }, [open, aircraftId, fetchDetailAircraft]);

  useEffect(() => {
    if (errorDetailAircraft) {
      dispatch(setAlert('error', 'Unable to load aircraft information'));
    }
  }, [errorDetailAircraft, dispatch]);

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={handleClose}
      PaperProps={{
        sx: {
          maxWidth: '968px',
        },
      }}
    >
      <DialogTitle
        sx={{
          textAlign: 'center',
          fontSize: '24px',
          fontWeight: 700,
        }}
      >
        Complete your aircraft
      </DialogTitle>
      <DialogContent>
        <ComponentLoading loading={loadingDetailAircraft}>
          {dataDetailAircraft?.me?.aircraft[0] && (
            <AircraftInformation
              aircraftDetail={dataDetailAircraft?.me?.aircraft[0]}
              asDialog={true}
              setOpenDialog={setOpen}
              contactInfo={{
                airportId: dataDetailAircraft?.me?.airportId || '',
                country: dataDetailAircraft?.me?.country || '',
                phoneMobile: dataDetailAircraft?.me?.phoneMobile || '',
              }}
            />
          )}
        </ComponentLoading>
      </DialogContent>
    </Dialog>
  );
};

export default EditAircraftDialog;
