import React from 'react';
import { Typography, Link } from '@mui/material';
import { IAircraftDetail } from 'types';

import { PATH_AGREEMENT } from 'const';

interface SavvyProDescriptionProps {
  aircraft: IAircraftDetail | undefined;
}

const SavvyProDescription: React.FC<SavvyProDescriptionProps> = (props) => {
  const { aircraft } = props;

  if (!aircraft) {
    return null;
  }

  return (
    <React.Fragment>
      <Typography
        sx={{
          mb: 1,
        }}
      >
        Welcome to SavvyAnalysis Pro! We're delighted that you have hired us to analyze engine data for your {aircraft?.year}{' '}
        {aircraft?.aircraftManufacturer?.name} {aircraft?.aircraftModel?.name}.
      </Typography>
      <Typography
        sx={{
          mb: 1,
        }}
      >
        You have agreed to be bound by the terms and conditions of the{' '}
        <Link href={PATH_AGREEMENT} target="_blank">
          Savvy Aircraft Breakdown Assistance Service Agreement
        </Link>
        .
      </Typography>
      <Typography
        sx={{
          mb: 1,
        }}
      >
        We look forward to working with you. If you have any questions, please contact us by email at{' '}
        <Link href="mailto:operations@savvyaviation.com">operations@savvyaviation.com</Link> or by telephone toll-free at 1-888-465-8038.
      </Typography>
      <Typography>Welcome!</Typography>
    </React.Fragment>
  );
};

export default SavvyProDescription;
