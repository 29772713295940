import React from 'react';
import { CSSObject } from '@mui/material';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers-pro';
import { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';

interface CustomDateTimePickerProps {
  value: Dayjs | null;
  setValue: (value: Dayjs | null) => void;
  error?: string;
  setError?: (value: string) => void;
  sx?: CSSObject;
  label: string;
  disabled?: boolean;
  containerSx?: CSSObject;
}

const CustomDateTimePicker: React.FC<CustomDateTimePickerProps> = (props) => {
  const { value, setValue, error, setError, label, sx, disabled = false, containerSx } = props;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        label={label}
        value={value}
        onChange={(newValue: Dayjs | null) => {
          setValue(newValue);
          setError && setError('');
        }}
        viewRenderers={{
          hours: renderTimeViewClock,
          minutes: renderTimeViewClock,
          seconds: renderTimeViewClock,
        }}
        slotProps={{
          textField: {
            FormHelperTextProps: {
              sx: sx ? sx : {},
            },
            error: !!error,
            helperText: error,
            fullWidth: true,
            sx: containerSx,
          },
        }}
        disabled={disabled}
      />
    </LocalizationProvider>
  );
};

export default CustomDateTimePicker;
