import React from 'react';
import { Box } from '@mui/material';

import { getSerieColor } from 'helpers';

interface SeriesLegendProps {
  appearanceSets: string[];
}

const SeriesLegend: React.FC<SeriesLegendProps> = (props) => {
  const { appearanceSets } = props;

  if (!appearanceSets.length) {
    return null;
  }

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      {appearanceSets.map((apperanceSet: string, key: number) => {
        return (
          <Box key={key}>
            <Box
              sx={{
                color: getSerieColor(apperanceSet),
                fontWeight: 600,
                borderRadius: '6px',
                mx: 1,
                border: '1px solid',
                borderColor: 'black',
                minWidth: '45px',
                py: 0.5,
                px: 0.5,
                textAlign: 'center',
                whiteSpace: 'nowrap',
              }}
            >
              {apperanceSet}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default SeriesLegend;
