import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Grid, Box, Switch, FormControlLabel, Button, MenuItem } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-mui';
import { MuiFormikInput, ButtonSubmit, ComponentLoading } from 'components';
import * as Yup from 'yup';

import { useMutation, useLazyQuery, useQuery } from '@apollo/client';
import {
  MUTATION_CHANGE_ADVANCED_SETTINGS,
  MUTATION_CHANGE_PERSONAL_INFO,
  QUERY_SAVVY_TICKET_NOTIFICATION,
  MUTATION_TOGGLE_SEND_NEW_TICKET_NOTIFICATION,
  QUERY_AVAILABLE_TIMEZONES,
} from 'gql';

import ChangePasswordDialog from './ChangePasswordDialog';
import { setAlert } from 'state';
import { useSmDown } from 'hooks';
import { IContactType } from 'types';

const ValidPersonalSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid Email')
    .min(3, 'Too short: 3-50 characters required')
    .max(50, 'Too long: 3-50 characters required')
    .required('Required'),
  firstName: Yup.string().min(1, 'Too short: 1-20 characters required').max(20, 'Too long: 1-20 characters required').required('Required'),
  lastName: Yup.string().min(1, 'Too short: 1-20 characters required').max(20, 'Too long: 1-20 characters required').required('Required'),
  airportId: Yup.string().max(4, 'Too long: Maximum 4 characters').required('Required'),
  phoneMobile: Yup.string()
    .min(1, 'Too short: 1-20 characters required')
    .max(20, 'Too long: 1-20 characters required')
    .required('Required'),
  timezone: Yup.string(),
});

interface PersonalSettingProps {
  userData: IContactType;
  emailNotificationsForOwnPosts: boolean;
  setEmailNotificationsForOwnPosts: (value: boolean) => void;
}

const PersonalSettings: React.FC<PersonalSettingProps> = (props) => {
  const { email, firstName, lastName, phoneMobile, airportId, timezone } = props.userData;
  const { emailNotificationsForOwnPosts, setEmailNotificationsForOwnPosts } = props;

  const [updateEmailNotification] = useMutation(MUTATION_CHANGE_ADVANCED_SETTINGS);

  const [changePersonalInfo, { data: dataChangePerosnalInfo, loading: loadingChangePersonalInfo, error: errorChangePersonalInfo }] =
    useMutation(MUTATION_CHANGE_PERSONAL_INFO, {
      errorPolicy: 'all',
    });
  const { data: dataTimezones, loading: loadingTimezones } = useQuery(QUERY_AVAILABLE_TIMEZONES);

  const [fetchSavvySetting, { data: dataSavvy, loading: loadingSavvy }] = useLazyQuery(QUERY_SAVVY_TICKET_NOTIFICATION);

  const [toggleSavvySetting, { data: dataSavvySetting, loading: loadingSavvySetting, error: errorSavvySetting }] = useMutation(
    MUTATION_TOGGLE_SEND_NEW_TICKET_NOTIFICATION,
  );

  const { isSavvy } = useSelector((state: any) => state.auth);

  const dispatch = useDispatch();

  const isSmDown = useSmDown();

  const onSubmit = async (values: any) => {
    await changePersonalInfo({
      variables: {
        firstName: values.firstName,
        lastName: values.lastName,
        airportId: values.airportId,
        mobilePhone: values.phoneMobile,
        email: values.email,
        timezone: values.timezone || '',
      },
    });
  };

  const [disableEditProfile, setDisableEditProfile] = useState(true);

  const [passwordDialogOpen, setPasswordDialogOpen] = useState(false);
  const [responseError, setResponseError] = useState('');

  const handleChangeEmailNotification = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailNotificationsForOwnPosts(event.target.checked);
    await updateEmailNotification({
      variables: {
        emailNotificationsForOwnPosts: event.target.checked,
      },
    });
  };

  const handleChangeSavvySetting = async () => {
    await toggleSavvySetting();
  };

  useEffect(() => {
    if (isSavvy) {
      fetchSavvySetting();
    }
  }, [isSavvy, fetchSavvySetting]);

  useEffect(() => {
    if (errorChangePersonalInfo) {
      setResponseError(errorChangePersonalInfo.graphQLErrors[0]?.message);
      return;
    }
  }, [errorChangePersonalInfo]);

  useEffect(() => {
    if (dataChangePerosnalInfo) {
      if (dataChangePerosnalInfo.changePersonalInfo?.ok) {
        dispatch(setAlert('success', 'Changed Personal Info'));
        setDisableEditProfile(true);
      } else {
        dispatch(setAlert('error', dataChangePerosnalInfo.changePersonalInfo?.error || ''));
      }
    }
  }, [changePersonalInfo, dataChangePerosnalInfo, dispatch]);

  useEffect(() => {
    if (errorSavvySetting) {
      dispatch(setAlert('error', 'Unable to change email notification setting for new tickets'));
    } else if (dataSavvySetting) {
      if (dataSavvySetting?.toggleSendNewTicketNotification?.ok) {
        dispatch(setAlert('success', 'Changed email notification setting for new tickets'));
      } else {
        dispatch(
          setAlert(
            'error',
            dataSavvySetting?.toggleSendNewTicketNotification?.error || 'Unable to change email notification setting for new tickets',
          ),
        );
      }
    }
  }, [errorSavvySetting, dataSavvySetting, dispatch]);

  return (
    <Box px={isSmDown ? 2 : 5} py={isSmDown ? 1 : 6}>
      <ComponentLoading loading={loadingSavvy || loadingChangePersonalInfo || loadingSavvySetting || loadingTimezones}>
        <Formik
          initialValues={{
            email: email,
            firstName: firstName ? firstName : '',
            lastName: lastName ? lastName : '',
            phoneMobile: phoneMobile ? phoneMobile : '',
            airportId: airportId ? airportId : '',
            timezone: timezone ? timezone : '',
          }}
          onSubmit={onSubmit}
          validationSchema={ValidPersonalSchema}
        >
          {({ isSubmitting, handleChange, handleBlur, touched, errors }) => {
            if (responseError && !errors.email) {
              errors.email = responseError;
            } else if (!responseError) {
              if (errors?.email && errors.email === errorChangePersonalInfo?.graphQLErrors[0].message) {
                delete errors.email;
              }
            }

            return (
              <Form
                onChange={() => {
                  setResponseError('');
                }}
              >
                <Grid container spacing={2}>
                  <Grid item md={6} xs={12}>
                    <MuiFormikInput
                      name="email"
                      label="Email *"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      disabled={disableEditProfile}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginBottom: {
                          xs: '10px',
                          sm: 0,
                        },
                      }}
                    >
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                          setPasswordDialogOpen(true);
                        }}
                      >
                        Change Password
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item md={6} xs={12}>
                    <MuiFormikInput
                      name="firstName"
                      label="First Name *"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      disabled={disableEditProfile}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <MuiFormikInput
                      name="lastName"
                      label="Last Name *"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      disabled={disableEditProfile}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item md={6} xs={12}>
                    <MuiFormikInput
                      name="phoneMobile"
                      label="Mobile Phone *"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      disabled={disableEditProfile}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <MuiFormikInput
                      name="airportId"
                      label="Home Airport Id *"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      disabled={disableEditProfile}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  {dataTimezones?.availableTimezones && (
                    <Grid item md={6} xs={12}>
                      <Field
                        name="timezone"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        component={TextField}
                        label="Timezone"
                        select
                        variant="outlined"
                        fullWidth
                        FormHelperTextProps={{
                          sx: {
                            color: 'red',
                            marginLeft: 0,
                          },
                        }}
                        sx={{
                          mb: 0,
                        }}
                        disabled={disableEditProfile}
                      >
                        {dataTimezones.availableTimezones.map((item: string, key: number) => (
                          <MenuItem key={key} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>
                  )}
                  <Grid item md={6} xs={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={emailNotificationsForOwnPosts}
                          onChange={handleChangeEmailNotification}
                          name="checkedB"
                          color="primary"
                        />
                      }
                      label="E-mail notifications for own posts"
                    />
                  </Grid>
                  {isSavvy && (
                    <Grid item md={6} xs={12}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={dataSavvy?.savvy?.sendNewTicketNotification}
                            onChange={handleChangeSavvySetting}
                            name="checkedB"
                            color="primary"
                          />
                        }
                        label="E-mail notifications for new tickets"
                      />
                    </Grid>
                  )}
                </Grid>
                <Box py={2}>
                  {disableEditProfile && (
                    <Grid container spacing={2}>
                      <Grid item md={3} xs={false}></Grid>
                      <Grid item md={6} xs={12}>
                        <Button
                          variant="outlined"
                          color="primary"
                          fullWidth
                          onClick={() => {
                            setDisableEditProfile(false);
                          }}
                        >
                          Edit Profile
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                  {!disableEditProfile && (
                    <Grid container spacing={2}>
                      <Grid item md={6} xs={12}>
                        <ButtonSubmit text="Save" loading={isSubmitting || loadingChangePersonalInfo} />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Button
                          variant="outlined"
                          fullWidth
                          color="warning"
                          onClick={() => {
                            setDisableEditProfile(true);
                          }}
                          disabled={isSubmitting || loadingChangePersonalInfo}
                        >
                          Cancel
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </Box>
              </Form>
            );
          }}
        </Formik>
        <ChangePasswordDialog open={passwordDialogOpen} setOpen={setPasswordDialogOpen} />
      </ComponentLoading>
    </Box>
  );
};

export default PersonalSettings;
