import React, { useState, useEffect } from 'react';
import { Box, Link, Typography } from '@mui/material';
import { GROUP_BORESCOPE_SUBJECT } from 'const';
import { IBorescopeImageFileType } from 'types';
import styles from './style.module.scss';

interface EditSubjectComponentProps {
  subjectList: string[][];
  uploadedFiles: IBorescopeImageFileType[];
  activeFile: IBorescopeImageFileType;
  updateImageLocation: (borescopeImageId: string, cylinder: number, subject: string) => void;
  noTagged?: boolean;
}

const EditSubjectComponent: React.FC<EditSubjectComponentProps> = (props) => {
  const { subjectList, uploadedFiles, activeFile: propsFile, updateImageLocation, noTagged = false } = props;
  const [showContainer, setShowContainer] = useState(false);

  useEffect(() => {
    if (noTagged) {
      setShowContainer(true);
    }
  }, [noTagged]);

  return (
    <Box>
      {!noTagged && (
        <Box mb={1}>
          <Link
            href="#"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setShowContainer(!showContainer);
            }}
            sx={{
              fontWeight: 600,
            }}
          >
            Change View
          </Link>
        </Box>
      )}
      {showContainer && (
        <Box>
          {GROUP_BORESCOPE_SUBJECT.map((groupName: string, groupIndex: number) => {
            return (
              <Box
                sx={{
                  mt: groupIndex ? 0.5 : 0,
                  display: 'flex',
                }}
                key={groupIndex}
              >
                {subjectList.map((subject: string[], subjectIndex: number) => {
                  if (!subject[1].includes(groupName)) {
                    return null;
                  }
                  const activeFile = uploadedFiles.find((file) => file.subject === subject[0]);
                  return (
                    <Box
                      key={subjectIndex}
                      px={1}
                      py={0.5}
                      className={
                        activeFile
                          ? activeFile.subject === propsFile.subject
                            ? styles.activeSubject
                            : styles.disableSubject
                          : styles.normalSubject
                      }
                      onClick={async () => {
                        if (activeFile) {
                          return;
                        }
                        await updateImageLocation(propsFile.id, propsFile.cylinder, subject[0]);
                      }}
                    >
                      <Typography>{subject[1]}</Typography>
                    </Box>
                  );
                })}
              </Box>
            );
          })}
        </Box>
      )}
    </Box>
  );
};

export default EditSubjectComponent;
