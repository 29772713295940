import React from 'react';
import { Box, Typography } from '@mui/material';

import { aircraftRegistrationMakeModel } from 'helpers';
import { IAircraftEligibility, IBorescopeImageSet } from 'types';

import {
  BreakdownTicket,
  AccountSupportForm,
  TechSupportForm,
  AnalysisForm,
  PrebuyForm,
  ServiceAnnualForm,
  LogbookPreviewForm,
  ServiceForm,
  BorescopeAnalysisForm,
} from './createComponents';

interface PostTicketProps {
  ticketAircraft: IAircraftEligibility | undefined;
  ticketType:
    | {
        label: string;
        field: string;
      }
    | undefined;
  borescopeImageSet?: IBorescopeImageSet;
}

const PostTicket: React.FC<PostTicketProps> = (props) => {
  const { ticketAircraft, ticketType, borescopeImageSet } = props;

  if (!ticketType) return null;

  return (
    <Box
      px={{
        xl: 6,
        lg: 4,
        md: 3,
        xs: 2,
      }}
    >
      <Box
        pt={3}
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {ticketAircraft && (
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 600,
              mr: 2.5,
            }}
          >
            {aircraftRegistrationMakeModel(ticketAircraft)}
          </Typography>
        )}
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: 600,
          }}
        >
          {ticketType.field === 'preliminaryLogbookReview' ? 'Free Buyer Assistance' : ticketType.label}
        </Typography>
      </Box>
      {ticketType.field === 'breakdown' && ticketAircraft && <BreakdownTicket ticketAircraft={ticketAircraft} />}
      {ticketType.field === 'accountSupport' && <AccountSupportForm ticketAircraft={ticketAircraft} />}
      {ticketType.field === 'techSupport' && <TechSupportForm />}
      {ticketType.field === 'analysis' && ticketAircraft && <AnalysisForm ticketAircraft={ticketAircraft} />}
      {ticketType.field === 'prebuy' && <PrebuyForm ticketAircraft={ticketAircraft} />}
      {ticketType.field === 'serviceAnnual' && ticketAircraft && <ServiceAnnualForm ticketAircraft={ticketAircraft} />}
      {ticketType.field === 'service' && ticketAircraft && <ServiceForm ticketAircraft={ticketAircraft} />}
      {ticketType.field === 'preliminaryLogbookReview' && <LogbookPreviewForm ticketAircraft={ticketAircraft} />}
      {ticketType.field === 'borescopeAnalysis' && ticketAircraft && (
        <BorescopeAnalysisForm ticketAircraft={ticketAircraft} borescopeImageSet={borescopeImageSet} />
      )}
    </Box>
  );
};

export default PostTicket;
