import React, { useState, useEffect } from 'react';
import { Box, FormControl, RadioGroup, FormControlLabel, Radio, IconButton, Typography, CircularProgress, Link } from '@mui/material';
import { DataGridPro, GridValueGetterParams, GridRenderCellParams } from '@mui/x-data-grid-pro';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { getFewDaysAgo } from 'helpers';

import { QUERY_ME_RECENT_EDFS } from 'gql';
import { useLazyQuery } from '@apollo/client';

import { ViewLogModal } from 'pages/Aircrafts/components/Dialog';
import { ComponentLoading } from 'components';

import { useTimer } from 'hooks';

interface RecentEdfTableProps {
  aircraftId: string;
  setEngineDataFileId: (value: string) => void;
}

const LABEL_24_HOURS_AGO = 'LABEL_24_HOURS_AGO';
const A_WEEK_AGO = 'A_WEEK_AGO';

const FETCH_INTERVAL_EDF = 30;
const MAXIMUM_FETCH_NUMBER = 20;

const RecentEdfTable: React.FC<RecentEdfTableProps> = (props) => {
  const { aircraftId, setEngineDataFileId } = props;
  const [fromTime, setFromTime] = useState(getFewDaysAgo(1));
  const [fromTimeLabel, setFromTimeLabel] = useState(LABEL_24_HOURS_AGO);

  const [fetchRecentEdfs, { data: dataRecentEdfs, loading: loadingRecent }] = useLazyQuery(QUERY_ME_RECENT_EDFS);

  const [fileId, setFileId] = useState('');
  const [showLogModal, setShowLogModal] = useState(false);

  const [pageSize, setPageSize] = useState(10);

  const [fetchCounter, setFetchCounter] = useState(0);

  const {
    create: createTimer,
    created: createdTimer,
    clear: clearTimer,
  } = useTimer({
    timeoutValue: FETCH_INTERVAL_EDF,
    timerAction: () => {
      fetchRecentEdfs({
        variables: {
          id: parseInt(aircraftId),
          fromTime,
        },
        fetchPolicy: 'network-only',
      });
      setFetchCounter((count: number) => count + 1);
    },
  });

  const isProcessing = (value: string) => {
    return value === 'FAILED' || value === 'SUCCESS' || value === 'NO_INFO' ? false : true;
  };

  useEffect(() => {
    if (fromTimeLabel === LABEL_24_HOURS_AGO) {
      setFromTime(getFewDaysAgo(1));
    } else {
      setFromTime(getFewDaysAgo(7));
    }
  }, [fromTimeLabel]);

  useEffect(() => {
    if (aircraftId && fromTime) {
      fetchRecentEdfs({
        variables: {
          id: parseInt(aircraftId),
          fromTime,
        },
      });
    }
  }, [aircraftId, fromTime]);

  useEffect(() => {
    if (aircraftId && fromTime) {
      if (createdTimer) {
        clearTimer();
      }
      setFetchCounter(0);
      createTimer();
    }
  }, [aircraftId, fromTime]);

  useEffect(() => {
    if (fetchCounter > MAXIMUM_FETCH_NUMBER) {
      clearTimer();
    }
  }, [fetchCounter, clearTimer]);

  useEffect(() => {
    return () => {
      clearTimer();
    };
  }, [aircraftId]);

  return (
    <Box>
      <Typography
        variant="h3"
        sx={{
          fontWeight: 700,
        }}
        align="center"
      >
        Recent Uploads
      </Typography>
      <FormControl
        sx={{
          p: 1,
          display: 'block',
        }}
      >
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={fromTimeLabel}
          onChange={(e) => {
            setFromTimeLabel(e.target.value);
          }}
          sx={{
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <FormControlLabel value={LABEL_24_HOURS_AGO} control={<Radio />} label="24 hours ago" />
          <FormControlLabel value={A_WEEK_AGO} control={<Radio />} label="A week ago" />
        </RadioGroup>
      </FormControl>
      <Box py={1}>
        <ComponentLoading loading={loadingRecent}>
          <DataGridPro
            sx={{
              width: '100%',
              minWidth: '100px',
              '& .MuiDataGrid-columnSeparator': {
                display: 'none',
              },
              '& .MuiDataGrid-cell:focus': {
                outline: 'none',
              },
              '& .MuiDataGrid-row': {
                cursor: 'pointer',
                borderBottom: '1px solid',
                borderColor: 'grey.200',
              },
              '& .MuiDataGrid-columnHeader:focus': {
                outline: 'none',
              },
              border: 'none',
            }}
            autoHeight
            disableColumnResize={true}
            disableSelectionOnClick={true}
            rowsPerPageOptions={[5, 10, 20, 100]}
            pageSize={pageSize}
            onPageSizeChange={(newPage) => setPageSize(newPage)}
            pagination
            showColumnRightBorder={false}
            disableColumnMenu={true}
            rows={dataRecentEdfs?.me?.aircraft[0]?.recentEngineDataFiles || []}
            columns={[
              {
                field: 'name',
                headerName: 'Name',
                flex: 1,
              },
              {
                field: 'uploadDate',
                headerName: 'Upload Date',
                valueGetter: (value: GridValueGetterParams) => {
                  return value.row?.uploadDate ? value.row?.uploadDate.toString().substring(0, 10) : '';
                },
                flex: 0.5,
              },
              {
                field: 'flightsCount',
                headerName: 'Number of Flights',
                renderCell: (params: GridRenderCellParams<number>) => {
                  return params.value ? (
                    <Typography>
                      {params.value} (
                      <Link
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setEngineDataFileId(params.row.id || '');
                        }}
                      >
                        Show Flights
                      </Link>
                      )
                    </Typography>
                  ) : (
                    <Typography>0</Typography>
                  );
                },
                flex: 0.8,
              },
              {
                field: 'parseLogs',
                headerName: 'View Logs',
                renderCell: (params: GridRenderCellParams<string>) => {
                  return !isProcessing(params.row?.workflowStatus) ? (
                    <IconButton
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setFileId(params.row?.id || '');
                        setShowLogModal(true);
                      }}
                    >
                      <TextSnippetIcon />
                    </IconButton>
                  ) : null;
                },
                flex: 0.5,
              },
              {
                field: 'workflowStatus',
                headerName: 'Status',
                renderCell: (params: GridRenderCellParams<string>) => {
                  return (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {isProcessing(params.value || '') && (
                        <CircularProgress
                          color="primary"
                          size={20}
                          variant="indeterminate"
                          sx={{
                            mr: 1,
                          }}
                        />
                      )}
                      <Typography>{params.value}</Typography>
                    </Box>
                  );
                },
                flex: 0.8,
              },
            ]}
          />
        </ComponentLoading>
      </Box>
      <ViewLogModal open={showLogModal} setOpen={setShowLogModal} fileId={fileId} aircraftId={aircraftId} />
    </Box>
  );
};

export default RecentEdfTable;
