import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Box, Container, Button } from '@mui/material';
import { DataGridPro, GridValueGetterParams, GridRowParams } from '@mui/x-data-grid-pro';
import { QUERY_SAVVY_AIRCRAFT_REPORT_CARDS } from 'gql';
import { useLazyQuery } from '@apollo/client';

import { ComponentLoading, CustomGridToolbar } from 'components';
import { getTimesheetDateString } from 'helpers';

import { setAlert } from 'state';
import { useTableConfig } from 'hooks';

import { default as CreateReportCardDialog } from './CreateReportCardDialog';
import { default as ViewReportCardDialog } from './ViewReportCardDialog';
import { default as DebugFlightForRcTaDialog } from './DebugFlightForRcTaDialog';
import { DateRange } from '@mui/x-date-pickers-pro';
import dayjs, { Dayjs } from 'dayjs';

interface ReportsProps {
  aircraftId: string;
  isLoad: boolean;
}

const Reports: React.FC<ReportsProps> = (props) => {
  const { aircraftId, isLoad } = props;

  const [fetchReports, { data: dataReports, loading: loadingReports, error: errorReports }] =
    useLazyQuery(QUERY_SAVVY_AIRCRAFT_REPORT_CARDS);
  const dispatch = useDispatch();

  const [pageSize, setPageSize] = useState(10);
  const [filterButtonEl, setFilterButtonEl] = useState<HTMLButtonElement | null>(null);

  const [success, setSuccess] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);

  const [isDebug, setIsDebug] = useState(false);

  const { density, setDensity } = useTableConfig();

  const [openView, setOpenView] = useState(false);
  const [reportCardId, setReportCardId] = useState('');

  const now = new Date();
  const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([
    dayjs(`${now.getFullYear() - 1}-${now.getMonth() + 1}-${now.getDate()}`),
    dayjs(`${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}`),
  ]);

  useEffect(() => {
    if (isLoad && aircraftId) {
      fetchReports({
        variables: {
          aircraftId,
        },
        fetchPolicy: 'cache-and-network',
      });
    }
  }, [aircraftId, isLoad, fetchReports]);

  useEffect(() => {
    if (errorReports) {
      dispatch(setAlert('error', 'Unable to load reports'));
    }
  }, [errorReports, dispatch]);

  useEffect(() => {
    if (success) {
      fetchReports({
        variables: {
          aircraftId,
        },
        fetchPolicy: 'cache-and-network',
      });
    }
  }, [success, aircraftId, fetchReports]);

  return (
    <Container fixed maxWidth="xl">
      <Box>
        <ComponentLoading loading={loadingReports}>
          <Box py={1}>
            <Button
              variant="contained"
              onClick={() => {
                setSuccess(false);
                setOpenCreate(true);
              }}
            >
              + Add New
            </Button>
          </Box>
          <Box
            py={1}
            sx={{
              width: '100%',
            }}
          >
            {isLoad && (
              <DataGridPro
                sx={{
                  width: '100%',
                  minWidth: '100px',
                  '& .MuiDataGrid-columnSeparator': {
                    display: 'none',
                  },
                  '& .MuiDataGrid-cell:focus': {
                    outline: 'none',
                  },
                  '& .MuiDataGrid-row': {
                    cursor: 'pointer',
                    borderBottom: '1px solid',
                    borderColor: 'grey.200',
                  },
                  '& .MuiDataGrid-columnHeader:focus': {
                    outline: 'none',
                  },
                  '& .MuiDataGrid-cellContent': {
                    whiteSpace: 'break-spaces',
                  },
                  '& .MuiDataGrid-toolbarContainer .MuiTextField-root': {
                    mb: 0,
                  },
                  border: 'none',
                }}
                autoHeight
                disableColumnResize={true}
                disableSelectionOnClick={true}
                rowsPerPageOptions={[5, 10, 25, 100]}
                pageSize={pageSize}
                onPageSizeChange={(newPage) => setPageSize(newPage)}
                pagination
                showColumnRightBorder={false}
                disableColumnSelector
                density={density}
                onStateChange={(params) => {
                  if (params.density && params.density.value !== density) {
                    setDensity(params.density.value);
                  }
                }}
                rows={
                  dataReports?.savvy?.aircraft?.reportCards
                    ? [...dataReports.savvy.aircraft.reportCards].sort((item1: any, item2: any) => {
                        return item1.created > item2.created ? -1 : 1;
                      })
                    : []
                }
                columns={[
                  {
                    field: 'kind',
                    headerName: 'Kind',
                    valueFormatter: (params) => {
                      return params?.value === 'RC' ? 'Report Card' : 'Trend Analysis';
                    },
                    flex: 0.4,
                  },
                  {
                    field: 'created',
                    headerName: 'Created',
                    valueGetter: (params: GridValueGetterParams) => {
                      return params?.value ? getTimesheetDateString(params.value) : '';
                    },
                    flex: 0.5,
                  },
                  {
                    field: 'dateStart',
                    headerName: 'Start',
                    flex: 0.5,
                    valueGetter: (params: GridValueGetterParams) => {
                      return params?.value ? getTimesheetDateString(params.value) : '';
                    },
                  },
                  {
                    field: 'dateEnd',
                    headerName: 'End',
                    valueGetter: (params: GridValueGetterParams) => {
                      return params?.value ? getTimesheetDateString(params.value) : '';
                    },
                    flex: 0.5,
                  },
                  {
                    field: 'flightCount',
                    headerName: 'Flight Count',
                    valueGetter: (params: GridValueGetterParams) => {
                      return params?.value ? params.value : 0;
                    },
                    flex: 0.5,
                  },
                  {
                    field: 'delivery',
                    headerName: 'Delivery',
                    valueGetter: (params: GridValueGetterParams) => {
                      return params.value === 'E' ? 'Email' : 'Web Page';
                    },
                    flex: 0.5,
                  },
                ]}
                onRowClick={(params: GridRowParams) => {
                  const { row } = params;
                  if (row.id) {
                    setReportCardId(row.id);
                    setOpenView(true);
                  }
                }}
                components={{ Toolbar: CustomGridToolbar }}
                componentsProps={{
                  toolbar: {
                    setFilterButtonEl,
                  },
                  filterPanel: {
                    sx: {
                      '& .MuiDataGrid-filterForm': {
                        '& .MuiTextField-root': {
                          mb: 0,
                        },
                      },
                    },
                  },
                  panel: {
                    anchorEl: filterButtonEl,
                    placement: 'bottom-end',
                  },
                }}
              />
            )}
          </Box>
        </ComponentLoading>
        <CreateReportCardDialog
          aircraftId={aircraftId}
          open={openCreate}
          setOpen={setOpenCreate}
          setSuccess={setSuccess}
          setIsDebug={setIsDebug}
          dateRange={dateRange}
          setDateRange={setDateRange}
        />
        <ViewReportCardDialog open={openView} setOpen={setOpenView} aircraftId={aircraftId} reportCardId={reportCardId} />
        <DebugFlightForRcTaDialog open={isDebug} setOpen={setIsDebug} aircraftId={aircraftId} dateRange={dateRange} />
      </Box>
    </Container>
  );
};

export default Reports;
