import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, IconButton, CircularProgress, Button, TextField, MenuItem, Popover, List, ListItem } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DownloadIcon from '@mui/icons-material/Download';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { DataGridPro, GridValueGetterParams, GridRenderCellParams } from '@mui/x-data-grid-pro';

import { ComponentLoading, DeleteDialog } from 'components';
import { PdfDialog } from 'pages/Tickets/components/ContentComponent';

import { IAircraftDetail, IMaintenanceEntry, ILogbook } from 'types';

import {
  QUERY_ME_AIRCRAFT_LOG_BASE64,
  QUERY_SAVVY_AIRCRAFT_LOG_BASE64,
  MUTATION_DELETE_MAINTENANCE_ENTRY,
  QUERY_SAVVY_AIRCRAFT_LOG_DOWNLOAD,
  QUERY_ME_AIRCRAFT_LOG_DOWNLOAD,
} from 'gql';
import { useLazyQuery, useMutation } from '@apollo/client';

import { AddLogDialog, EditLogDialog } from '../Dialog';

import { setAlert } from 'state';
import { isHeic, downloadFileInNewTab } from 'helpers';
import { apolloClient } from 'services';

interface LogDetailProps {
  aircraft: IAircraftDetail | undefined;
  canCreateMaintenanceEntry?: boolean;
  logbookId: string;
  setLogbookId: (value: string) => void;
  logbooks: ILogbook[];
}

const LogDetail: React.FC<LogDetailProps> = (props) => {
  const { aircraft, canCreateMaintenanceEntry = false, logbookId, setLogbookId, logbooks } = props;

  const [showFileName, setShowFileName] = useState('');
  const [showFile, setShowFile] = useState('');
  const [showPdfFileDialog, setShowPdfFileDialog] = useState(false);
  const [previewAttachmentId, setPreviewAttachmentId] = useState('');

  const [fetchAttachment, { data: dataAttachment, loading: loadingAttachment, error: errorAttachment }] = useLazyQuery(
    QUERY_ME_AIRCRAFT_LOG_BASE64,
    {
      fetchPolicy: 'network-only',
    },
  );

  const [fetchSavvyAttachment, { data: dataSavvyAttachment, loading: loadingSavvyAttachment, error: errorSavvyAttachment }] = useLazyQuery(
    QUERY_SAVVY_AIRCRAFT_LOG_BASE64,
    {
      fetchPolicy: 'network-only',
    },
  );

  const [deleteLog, { data: dataDeleteLog, loading: loadingDeleteLog, error: errorDeleteLog }] =
    useMutation(MUTATION_DELETE_MAINTENANCE_ENTRY);

  const dispatch = useDispatch();
  const { isSavvy } = useSelector((state: any) => state.auth);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const [showAddDialog, setShowAddDialog] = useState(false);

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleteMaintenanceEntryId, setDeleteMaintenanceEntryId] = useState('');

  const [editMaintenanceEntry, setEditMaintenanceEntry] = useState<IMaintenanceEntry>();
  const [editDialog, setEditDialog] = useState(false);

  const [pageSize, setPageSize] = useState(25);

  const loadPreview = async (maintenanceEntryId: string) => {
    setPreviewAttachmentId(maintenanceEntryId);
    if (aircraft) {
      await fetchAttachment({
        variables: {
          id: parseInt(aircraft?.id),
          maintenanceEntryId,
        },
        fetchPolicy: 'network-only',
      });
    }
  };

  const loadPreviewSavvy = async (maintenanceEntryId: string) => {
    setPreviewAttachmentId(maintenanceEntryId);
    if (aircraft) {
      await fetchSavvyAttachment({
        variables: {
          aircraftId: aircraft.id,
          maintenanceEntryId,
        },
        fetchPolicy: 'network-only',
      });
    }
  };

  const nextAttachmentLoad = (maintenanceEntryId: string) => {
    if (aircraft?.maintenanceEntries?.length) {
      for (let i = 0; i < aircraft?.maintenanceEntries?.length - 1; i++) {
        if (maintenanceEntryId === aircraft.maintenanceEntries[i].id) {
          if (isSavvy) {
            loadPreviewSavvy(aircraft.maintenanceEntries[i + 1].id);
          } else {
            loadPreview(aircraft.maintenanceEntries[i + 1].id);
          }
        }
      }
    }

    return;
  };

  const prevAttachmentLoad = (maintenanceEntryId: string) => {
    if (aircraft?.maintenanceEntries?.length) {
      for (let i = 1; i < aircraft?.maintenanceEntries?.length; i++) {
        if (maintenanceEntryId === aircraft.maintenanceEntries[i].id) {
          if (isSavvy) {
            loadPreviewSavvy(aircraft.maintenanceEntries[i - 1].id);
          } else {
            loadPreview(aircraft.maintenanceEntries[i - 1].id);
          }
        }
      }
    }

    return;
  };

  const deleteAction = async () => {
    await deleteLog({
      variables: {
        maintenanceEntryId: deleteMaintenanceEntryId,
      },
    });
  };

  const sortEntries = (data: IMaintenanceEntry[], logbookId: string) => {
    if (!data.length) return [];
    return [...data]
      .sort((a: any, b: any) => {
        if (new Date(a.entryDate) > new Date(b.entryDate)) {
          return -1;
        } else {
          return 1;
        }
      })
      .filter((item: IMaintenanceEntry) => !!logbookId && logbookId === item.logbook.id);
  };

  const [loadingDownloadUrl, setLoadingDownloadUrl] = useState(false);

  const downloadDocument = async (maintenanceEntryId: string) => {
    if (aircraft?.id && maintenanceEntryId) {
      if (isSavvy) {
        setLoadingDownloadUrl(true);
        const { data: dataDownloadUrl } = await apolloClient.query({
          query: QUERY_SAVVY_AIRCRAFT_LOG_DOWNLOAD,
          variables: {
            aircraftId: aircraft.id,
            maintenanceEntryId,
          },
          fetchPolicy: 'no-cache',
        });
        setLoadingDownloadUrl(false);
        if (dataDownloadUrl?.savvy?.aircraft?.maintenanceEntries[0]?.document?.downloadUrl) {
          downloadFileInNewTab(dataDownloadUrl?.savvy?.aircraft?.maintenanceEntries[0]?.document?.downloadUrl);
        }
      } else {
        setLoadingDownloadUrl(true);
        const { data: dataDownloadUrl } = await apolloClient.query({
          query: QUERY_ME_AIRCRAFT_LOG_DOWNLOAD,
          variables: {
            aircraftId: parseInt(aircraft.id),
            maintenanceEntryId,
          },
          fetchPolicy: 'no-cache',
        });
        setLoadingDownloadUrl(false);
        if (dataDownloadUrl?.me?.aircraft[0]?.maintenanceEntries[0]?.document?.downloadUrl) {
          downloadFileInNewTab(dataDownloadUrl?.me?.aircraft[0]?.maintenanceEntries[0]?.document?.downloadUrl);
        }
      }
    }
  };

  useEffect(() => {
    if (errorAttachment) {
      dispatch(setAlert('error', 'Unable to preview maintenance entry'));
    } else if (dataAttachment) {
      if (dataAttachment?.me?.aircraft[0]?.maintenanceEntries[0]?.document) {
        setShowFileName(dataAttachment?.me?.aircraft[0]?.maintenanceEntries[0]?.document.fileName);
        if (isHeic(dataAttachment?.me?.aircraft[0]?.maintenanceEntries[0]?.document?.fileName)) {
          dataAttachment?.me?.aircraft[0]?.maintenanceEntries[0]?.document?.base64Data &&
            setShowFile(dataAttachment?.me?.aircraft[0]?.maintenanceEntries[0]?.document?.base64Data);
        } else {
          dataAttachment?.me?.aircraft[0]?.maintenanceEntries[0]?.document?.previewUrl &&
            setShowFile(dataAttachment?.me?.aircraft[0]?.maintenanceEntries[0]?.document?.previewUrl);
        }
        setShowPdfFileDialog(true);
      }
    }
  }, [dispatch, errorAttachment, dataAttachment]);

  useEffect(() => {
    if (errorSavvyAttachment) {
      dispatch(setAlert('error', 'Unable to preview maintenance entry'));
    } else if (dataSavvyAttachment) {
      if (dataSavvyAttachment?.savvy?.aircraft?.maintenanceEntries[0]?.document) {
        setShowFileName(dataSavvyAttachment?.savvy?.aircraft?.maintenanceEntries[0]?.document.fileName);
        if (isHeic(dataSavvyAttachment?.savvy?.aircraft?.maintenanceEntries[0]?.document.fileName)) {
          dataSavvyAttachment?.savvy?.aircraft?.maintenanceEntries[0]?.document?.base64Data &&
            setShowFile(dataSavvyAttachment?.savvy?.aircraft?.maintenanceEntries[0]?.document?.base64Data);
        } else {
          dataSavvyAttachment?.savvy?.aircraft?.maintenanceEntries[0]?.document?.previewUrl &&
            setShowFile(dataSavvyAttachment?.savvy?.aircraft?.maintenanceEntries[0]?.document?.previewUrl);
        }
        setShowPdfFileDialog(true);
      }
    }
  }, [dispatch, errorSavvyAttachment, dataSavvyAttachment]);

  useEffect(() => {
    if (errorDeleteLog) {
      dispatch(setAlert('error', 'Unable to delete maintenance entry'));
    } else if (dataDeleteLog) {
      if (dataDeleteLog.deleteMaintenanceEntry?.ok) {
        dispatch(setAlert('success', 'Deleted maintenance entry'));
      } else {
        dispatch(setAlert('error', dataDeleteLog.deleteMaintenanceEntry?.error || 'Unable to delete maintenance entry'));
      }
    }
  }, [dispatch, errorDeleteLog, dataDeleteLog]);

  return (
    <Box>
      <ComponentLoading loading={loadingDeleteLog}>
        <Box
          py={2}
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {isSavvy && canCreateMaintenanceEntry && (
            <Button
              variant="contained"
              onClick={() => {
                setShowAddDialog(true);
              }}
              sx={{
                mr: 2,
              }}
            >
              + Add Maintenance Entry
            </Button>
          )}
          {!!logbooks.length && (
            <TextField
              select
              value={logbookId}
              label="Select Logbook"
              name="logbook"
              onChange={(e) => {
                setLogbookId(e.target.value);
              }}
              sx={{
                mb: 0,
                minWidth: '200px',
              }}
            >
              {logbooks.map((logbook: ILogbook, key: number) => {
                if (logbook.deleted) {
                  return null;
                }
                return (
                  <MenuItem key={key} value={logbook.id}>
                    {logbook.name}
                  </MenuItem>
                );
              })}
            </TextField>
          )}
        </Box>
        <Box
          py={1}
          sx={{
            width: '100%',
          }}
        >
          {aircraft && (
            <DataGridPro
              sx={{
                width: '100%',
                minWidth: '100px',
                '& .MuiDataGrid-columnSeparator': {
                  display: 'none',
                },
                '& .MuiDataGrid-cell:focus': {
                  outline: 'none',
                },
                '& .MuiDataGrid-row': {
                  cursor: 'pointer',
                  borderBottom: '1px solid',
                  borderColor: 'grey.200',
                },
                '& .MuiDataGrid-columnHeader:focus': {
                  outline: 'none',
                },
                '& .MuiDataGrid-cellContent': {
                  whiteSpace: 'break-spaces',
                },
                border: 'none',
              }}
              autoHeight
              disableColumnResize={true}
              disableSelectionOnClick={true}
              rowsPerPageOptions={[5, 10, 25, 100]}
              pageSize={pageSize}
              onPageSizeChange={(newPage) => setPageSize(newPage)}
              pagination
              showColumnRightBorder={false}
              disableColumnMenu={true}
              rows={aircraft?.maintenanceEntries?.length ? sortEntries(aircraft.maintenanceEntries, logbookId) : []}
              columns={[
                {
                  field: 'created',
                  headerName: 'Created',
                  hide: !isSavvy,
                  flex: 1,
                  valueGetter: (value: GridValueGetterParams) => {
                    return value.row?.created ? value.row?.created.toString().substring(0, 10) : '';
                  },
                },
                {
                  field: 'isPerformedMaintenance',
                  headerName: 'Maintenance',
                  valueFormatter: (params) => {
                    return params.value ? 'Yes' : 'No';
                  },
                  flex: 0.7,
                },
                {
                  field: 'isPerformedInspection',
                  headerName: 'Inspection',
                  valueFormatter: (params) => {
                    return params.value ? 'Yes' : 'No';
                  },
                  flex: 0.7,
                },
                {
                  field: 'document',
                  headerName: 'Document',
                  valueGetter: (params: GridValueGetterParams) => {
                    const fileName = params.row.document?.fileName ? params.row.document?.fileName : '';
                    return (
                      fileName + (params.row.document?.attachment?.description ? ` (${params.row.document?.attachment?.description})` : '')
                    );
                  },
                  flex: 1,
                },
                {
                  field: 'maintenanceEntryCategory',
                  headerName: 'Record Type',
                  valueGetter: (params: GridValueGetterParams) => {
                    return params.row?.maintenanceEntryCategory ? params.row.maintenanceEntryCategory.name : '';
                  },
                  flex: 0.7,
                },
                {
                  field: 'id',
                  headerName: 'Actions',
                  sortable: false,
                  flex: 0.8,
                  renderCell: (params: GridRenderCellParams<string>) => {
                    return (!loadingAttachment && !loadingSavvyAttachment && !loadingDownloadUrl) ||
                      previewAttachmentId !== params.value ? (
                      <Box>
                        <IconButton
                          title="Preview"
                          onClick={() => {
                            if (params.value)
                              if (isSavvy) {
                                loadPreviewSavvy(params.value);
                              } else {
                                loadPreview(params.value);
                              }
                          }}
                        >
                          <VisibilityIcon />
                        </IconButton>
                        <IconButton
                          title="Download"
                          sx={{
                            ml: 1,
                          }}
                          onClick={() => {
                            if (params?.value) {
                              downloadDocument(params.value);
                            }
                          }}
                        >
                          <DownloadIcon />
                        </IconButton>
                        {isSavvy && (
                          <IconButton
                            sx={{
                              ml: 1,
                            }}
                            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                              const matchedEntry = aircraft?.maintenanceEntries?.filter(
                                (entry: IMaintenanceEntry) => entry.id === params.value,
                              );
                              if (matchedEntry?.length) {
                                setEditMaintenanceEntry(matchedEntry[0]);
                              } else {
                                setEditMaintenanceEntry(undefined);
                              }
                              setDeleteMaintenanceEntryId(params.value || '');
                              setAnchorEl(e.currentTarget);
                            }}
                          >
                            <MoreVertIcon />
                          </IconButton>
                        )}
                      </Box>
                    ) : (
                      <CircularProgress color="inherit" size={20} variant="indeterminate" />
                    );
                  },
                },
              ]}
            />
          )}
        </Box>
      </ComponentLoading>
      <PdfDialog
        showFileDialog={showPdfFileDialog}
        setShowFileDialog={setShowPdfFileDialog}
        showFileName={showFileName}
        showFile={showFile}
        previewAttachmentId={previewAttachmentId}
        nextAttachmentLoad={nextAttachmentLoad}
        prevAttachmentLoad={prevAttachmentLoad}
        loadingAttachmentData={loadingAttachment || loadingSavvyAttachment}
      />
      <AddLogDialog
        open={showAddDialog}
        setOpen={setShowAddDialog}
        aircraftId={aircraft?.id ? aircraft?.id : ''}
        maintenanceEntries={aircraft?.maintenanceEntries ? aircraft?.maintenanceEntries : []}
        selectedLogbookId={logbookId}
      />
      <DeleteDialog
        open={showDeleteDialog}
        setOpen={setShowDeleteDialog}
        onSubmitAction={deleteAction}
        title="Delete maintenance entry"
        text="Are you sure to delete maintenance entry?"
      />
      {editMaintenanceEntry && (
        <EditLogDialog
          open={editDialog}
          setOpen={setEditDialog}
          maintenanceEntry={editMaintenanceEntry}
          aircraftId={aircraft?.id ? aircraft.id : ''}
        />
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
      >
        <List component="div">
          {isSavvy && (
            <ListItem
              ContainerComponent="div"
              button
              onClick={() => {
                setEditDialog(true);
                setAnchorEl(null);
              }}
            >
              Edit
            </ListItem>
          )}
          <ListItem
            ContainerComponent="div"
            button
            onClick={() => {
              setShowDeleteDialog(true);
              setAnchorEl(null);
            }}
          >
            Delete
          </ListItem>
        </List>
      </Popover>
    </Box>
  );
};

export default LogDetail;
