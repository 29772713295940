import { gql } from '@apollo/client';
import { FRAGMENT_MANAGER_CONFLICT } from 'gql';

export const QUERY_MANAGER_CONFLICTS = gql`
  ${FRAGMENT_MANAGER_CONFLICT}
  query ManagerConflicts {
    managerConflicts {
      ...conflict
    }
  }
`;
