import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Typography, IconButton, Tooltip, Link } from '@mui/material';
import { IFlight } from 'types';
import { DataGridPro, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid-pro';
import RestoreIcon from '@mui/icons-material/Restore';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { PATH_FLIGHTS } from 'const';
import { ctrlCmdClick } from 'helpers';

interface UploadFlightsTableProps {
  flights: IFlight[];
  action: (id: string) => void;
  label: string;
}

const DELETE_TEXT = 'Delete';

const UploadFlightsTable: React.FC<UploadFlightsTableProps> = (props) => {
  const { flights, action, label } = props;

  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);
  const history = useHistory();

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
      }}
    >
      {!!flights.length && (
        <Box>
          <DataGridPro
            sx={{
              width: '100%',
              minWidth: '100px',
              '& .MuiDataGrid-cell:focus': {
                outline: 'none',
              },
              '& .MuiDataGrid-row': {
                cursor: 'pointer',
                borderBottom: '1px solid',
                borderColor: 'grey.200',
              },
              '& .MuiDataGrid-columnHeader:focus': {
                outline: 'none',
              },
              '& .MuiDataGrid-cellContent': {
                whiteSpace: 'break-spaces',
              },
              border: 'none',
            }}
            autoHeight
            getRowHeight={() => 'auto'}
            rowsPerPageOptions={[5, 10, 25, 100]}
            pagination
            pageSize={pageSize}
            onPageSizeChange={(newPage) => setPageSize(newPage)}
            page={page}
            onPageChange={(newPage) => setPage(newPage)}
            showColumnRightBorder={false}
            disableColumnSelector
            disableDensitySelector
            disableColumnMenu
            disableSelectionOnClick
            hideFooter
            rows={[...flights].sort((a: IFlight, b: IFlight) => {
              if (a.date > b.date) {
                return -1;
              } else {
                return 1;
              }
            })}
            columns={[
              {
                field: 'date',
                headerName: 'Date',
                valueFormatter: (params) => {
                  return params.value ? params.value.toString().substring(0, 10) : '';
                },
                flex: 0.5,
              },
              {
                field: 'departureAirport',
                headerName: 'Departure Airport',
                renderCell: (params: GridRenderCellParams<string>) => {
                  return (
                    <Tooltip title={params.value || ''}>
                      <Typography
                        sx={{
                          maxWidth: '100%',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {params.value || ''}
                      </Typography>
                    </Tooltip>
                  );
                },
                flex: 1,
              },
              {
                field: 'destinationAirport',
                headerName: 'Destination Airport',
                renderCell: (params: GridRenderCellParams<string>) => {
                  return (
                    <Tooltip title={params.value || ''}>
                      <Typography
                        sx={{
                          maxWidth: '100%',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {params.value || ''}
                      </Typography>
                    </Tooltip>
                  );
                },
                flex: 1,
              },
              {
                field: 'duration',
                headerName: 'Duration',
                valueFormatter: (params) => {
                  if (!params?.value) return '';
                  const hour = Math.floor(params.value / 3600);
                  const min = Math.floor((params.value % 3600) / 60);
                  const sec = params.value % 60;

                  return `${hour}h ${min}m ${sec}s`;
                },
                flex: 0.5,
              },
              {
                field: 'id',
                headerName: 'Action',
                renderCell: (params: GridRenderCellParams<string>) => {
                  if (!params?.value) {
                    return '';
                  }

                  return (
                    <Box py={1}>
                      <Tooltip title={label} enterTouchDelay={100} placement="top">
                        <IconButton
                          onClick={(e: any) => {
                            e.preventDefault();
                            e.stopPropagation();
                            action(params.value || '');
                          }}
                        >
                          {label === DELETE_TEXT && <DeleteForeverIcon />}
                          {label !== DELETE_TEXT && <RestoreIcon />}
                        </IconButton>
                      </Tooltip>
                    </Box>
                  );
                },
                sortable: false,
              },
            ]}
            onRowClick={(params: GridRowParams, event?: any) => {
              const { row } = params;
              if (row?.id && label === DELETE_TEXT) {
                ctrlCmdClick(event, `${PATH_FLIGHTS}/${row.id}`, history);
              }
            }}
          />
          {pageSize < flights.length && (
            <Box
              py={1}
              sx={{
                textAlign: 'center',
              }}
            >
              <Link
                href="#"
                onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (pageSize < flights.length) {
                    setPageSize(flights.length);
                  }
                }}
              >
                Show More
              </Link>
            </Box>
          )}
        </Box>
      )}
      {!flights.length && (
        <Box py={1}>
          <Typography align="center">No recent uploads</Typography>
        </Box>
      )}
    </Box>
  );
};

export default UploadFlightsTable;
