import React, { Fragment } from 'react';

import { Box, CircularProgress } from '@mui/material';

import styles from './style.module.scss';

interface IInputLoading {
  children: React.ReactNode;
  loading: boolean | undefined;
}

const InputLoading: React.FC<IInputLoading> = (props) => {
  const { children, loading } = props;

  // Return component with loading overlay
  if (loading) {
    return (
      <Box position="relative">
        {children}
        <CircularProgress className={styles.loader} color="inherit" size={20} variant="indeterminate" />
      </Box>
    );
  }

  // Default - return component
  return <Fragment>{children}</Fragment>;
};

export default InputLoading;
