import React from 'react';
import { Avatar, Box, Typography } from '@mui/material';

interface SavvyAvatarProps {
  role: string;
  firstName: string;
  lastName: string;
  avatar: string;
}

const SavvyAvatar: React.FC<SavvyAvatarProps> = (props) => {
  const { role, firstName, lastName, avatar } = props;

  return (
    <React.Fragment>
      {role !== 'M' && (
        <Avatar
          src={avatar}
          alt={`${firstName} ${lastName}`}
          sx={{
            width: '36px',
            height: '36px',
          }}
        />
      )}
      {role === 'M' && (
        <Box
          sx={{
            position: 'relative',
          }}
        >
          <Box
            sx={{
              padding: '2px',
              border: '1px solid #D68E21',
              borderRadius: '100%',
            }}
          >
            <Avatar
              src={avatar}
              alt={`${firstName} ${lastName}`}
              sx={{
                width: '32px',
                height: '32px',
              }}
            />
          </Box>
          <Typography
            sx={{
              padding: '3px',
              backgroundColor: 'warning.dark',
              color: 'background.default',
              fontWeight: 800,
              fontSize: '8px',
              lineHeight: '11px',
              textAlign: 'center',
              borderRadius: '3px',
              display: 'inline-flex',
              position: 'absolute',
              left: '50%',
              transform: 'translate(-50%)',
              bottom: '-12px',
            }}
          >
            Savvy
          </Typography>
        </Box>
      )}
    </React.Fragment>
  );
};

export default SavvyAvatar;
