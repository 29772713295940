import React from 'react';

import { FormGroup, FormControlLabel, FormHelperText, Checkbox as MuiCheckbox } from '@mui/material';

import { InputError } from 'components';

import { IInputError } from 'types';

import { hasInputError } from 'helpers';

interface ICheckbox {
  value: boolean;
  label: string | React.ReactElement;
  error?: IInputError; // Error is optional because some inputs are not required. We don't validate them and they will never have error
  onChange: (event: any) => void;
  helperText?: string | React.ReactNode;
  checked?: boolean;
  noMargin?: boolean;
  noPadding?: boolean;
}

const Checkbox: React.FC<ICheckbox> = (props) => {
  const { checked, value, label, onChange, helperText, error, noMargin, noPadding } = props;
  const hasError = hasInputError(error);

  return (
    <FormGroup
      sx={{
        mb: noMargin ? '0px' : '15px',
      }}
    >
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      <FormControlLabel
        className={hasError ? 'Mui-Error' : ''}
        control={
          <MuiCheckbox
            color="primary"
            value={value}
            checked={checked}
            sx={{
              padding: noPadding ? '0px' : '9px',
              mr: noPadding ? '10px' : '0px',
            }}
          />
        }
        label={label}
        labelPlacement="end"
        onChange={(e) => onChange(e)}
        sx={{
          ml: noPadding ? '0px' : '-11px',
        }}
      />
      <InputError error={error} hasError={hasError} />
    </FormGroup>
  );
};

export default Checkbox;
