import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Typography, Button, TextField, MenuItem, Container, Grid } from '@mui/material';

import { MUTATION_SET_MONITOR_CONFIG } from 'gql';
import { useMutation } from '@apollo/client';

import { ComponentLoading } from 'components';
import { setAlert } from 'state';

import { default as UbgMappingSeriesSelector } from './UbgMappingSeriesSelector';

interface UbgMappingProps {
  aircraftId: string;
  monitorConfig: string | undefined;
}

const UbgMapping: React.FC<UbgMappingProps> = (props) => {
  const { aircraftId, monitorConfig = undefined } = props;

  const dispatch = useDispatch();

  const [setMonitorConfig, { data: dataMonitorConfig, error: errorMonitorConfig, loading: loadingMonitorConfig }] =
    useMutation(MUTATION_SET_MONITOR_CONFIG);

  const NUMBER_OF_SERIES = 48;

  const initialSeries = Array.apply(null, Array(NUMBER_OF_SERIES)).map(() => 'None');

  const [seriesValue, setSeriesValue] = useState<string[]>(initialSeries);

  const onSubmit = async () => {
    const resultSeries: any = {};

    for (let i = 0; i < seriesValue.length; i++) {
      const key = `field${('0' + i).slice(-2)}`;
      resultSeries[key] = seriesValue[i];
    }

    await setMonitorConfig({
      variables: {
        aircraftId: aircraftId,
        monitorConfig: JSON.stringify(resultSeries),
      },
    });
  };

  const singleSetting = () => {
    for (let i = 0; i < seriesValue.length; i++) {
      seriesValue[i] = 'None';
    }

    seriesValue[0] = 'EGT1';
    seriesValue[1] = 'CHT1';
    seriesValue[2] = 'EGT2';
    seriesValue[3] = 'CHT2';
    seriesValue[4] = 'EGT3';
    seriesValue[5] = 'CHT3';
    seriesValue[6] = 'EGT4';
    seriesValue[7] = 'CHT4';
    seriesValue[8] = 'EGT5';
    seriesValue[9] = 'CHT5';
    seriesValue[10] = 'EGT6';
    seriesValue[11] = 'CHT6';

    setSeriesValue([...seriesValue]);
  };

  const twinSetting = () => {
    for (let i = 0; i < seriesValue.length; i++) {
      seriesValue[i] = 'None';
    }

    seriesValue[0] = 'L-EGT1';
    seriesValue[1] = 'L-CHT1';
    seriesValue[2] = 'L-EGT2';
    seriesValue[3] = 'L-CHT2';
    seriesValue[4] = 'L-EGT3';
    seriesValue[5] = 'L-CHT3';
    seriesValue[6] = 'L-EGT4';
    seriesValue[7] = 'L-CHT4';
    seriesValue[8] = 'L-EGT5';
    seriesValue[9] = 'L-CHT5';
    seriesValue[10] = 'L-EGT6';
    seriesValue[11] = 'L-CHT6';

    seriesValue[16] = 'R-EGT1';
    seriesValue[17] = 'R-CHT1';
    seriesValue[18] = 'R-EGT2';
    seriesValue[19] = 'R-CHT2';
    seriesValue[20] = 'R-EGT3';
    seriesValue[21] = 'R-CHT3';
    seriesValue[22] = 'R-EGT4';
    seriesValue[23] = 'R-CHT4';
    seriesValue[24] = 'R-EGT5';
    seriesValue[25] = 'R-CHT5';
    seriesValue[26] = 'R-EGT6';
    seriesValue[27] = 'R-CHT6';

    setSeriesValue([...seriesValue]);
  };

  useEffect(() => {
    setSeriesValue((seriesValue: string[]) => {
      if (!monitorConfig) return [...seriesValue];

      const monitorConfigJson = JSON.parse(monitorConfig);

      Object.keys(monitorConfigJson)?.map((key: string) => {
        seriesValue[parseInt(key.replace('field', ''))] = monitorConfigJson[key];
        return null;
      });

      return [...seriesValue];
    });
  }, [monitorConfig]);

  useEffect(() => {
    if (errorMonitorConfig) {
      dispatch(setAlert('error', 'Unable to set monitor config'));
    } else if (dataMonitorConfig) {
      if (dataMonitorConfig?.setMonitorConfig?.ok) {
        dispatch(setAlert('success', 'Saved monitor config'));
      } else {
        dispatch(setAlert('error', dataMonitorConfig?.setMonitorConfig?.error || 'Unable to set monitor config'));
      }
    }
  }, [errorMonitorConfig, dispatch, dataMonitorConfig]);

  return (
    <Box
      sx={{
        p: {
          xs: 2,
          sm: 3,
          md: 4,
          lg: 5,
        },
      }}
    >
      <Typography variant="body1" gutterBottom>
        You can select one of the typical configurations as a starting point, and then edit the individual fields to match your exact
        aircraft configuration.
        <br />
        <br />
        Any changes you make here will only affect future uploads.
      </Typography>
      <ComponentLoading loading={loadingMonitorConfig}>
        <Box
          py={1}
          sx={{
            textAlign: 'center',
          }}
        >
          <Button variant="outlined" onClick={singleSetting}>
            Typical Single
          </Button>
          <Button
            variant="outlined"
            sx={{
              ml: 2,
            }}
            onClick={twinSetting}
          >
            Typical Twin
          </Button>
        </Box>
        <Container maxWidth="sm" fixed>
          <Box py={1}>
            <TextField select label="Series 1 (not configurable)" value="Date/Time" disabled fullWidth>
              <MenuItem value="Date/Time">Date/Time</MenuItem>
            </TextField>
          </Box>
          <Box py={1}>
            <TextField select label="Series 2 (not configurable)" value="On/Off" disabled fullWidth>
              <MenuItem value="On/Off">On/Off</MenuItem>
            </TextField>
          </Box>
          <Grid container spacing={2}>
            {Array.apply(null, Array(NUMBER_OF_SERIES)).map((value: any, key: number) => {
              return (
                <Grid key={key} item xs={6}>
                  <UbgMappingSeriesSelector
                    seriesValue={seriesValue}
                    setSeriesValue={setSeriesValue}
                    index={key}
                    label={`Series ${key + 3}`}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Container>
        <Box
          sx={{
            textAlign: 'center',
          }}
        >
          <Button
            variant="contained"
            onClick={onSubmit}
            sx={{
              width: '350px',
            }}
          >
            Submit
          </Button>
        </Box>
      </ComponentLoading>
    </Box>
  );
};

export default UbgMapping;
