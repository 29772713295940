import React from 'react';
import { Box, Typography, Link } from '@mui/material';
import { LayoutNormal } from 'components';

const Legal: React.FC = () => {
  return (
    <LayoutNormal>
      <Box>
        <Typography variant="h2" gutterBottom>
          Legal
        </Typography>
        <Box py={1}>
          <Typography
            variant="h1"
            sx={{
              color: 'error.main',
            }}
          >
            Savvy Aviation, Inc. Online Privacy Policy and Terms of Service
          </Typography>
          <Box py={0.5}>
            <Typography
              sx={{
                fontWeight: 'bold',
              }}
              color="text.secondary"
            >
              Effective June 15, 2012
            </Typography>
          </Box>
          <Box py={0.5}>
            <Typography>
              Savvy Aviation, Inc. (“Savvy”), a Wyoming corporation, is providing services (the “Online Services”) to individuals or
              entities (“Users”) when they access and use this website or when Savvy stores their information online. By using the Online
              Services, you are agreeing to these terms. Please read them carefully.
            </Typography>
          </Box>
          <Box py={0.5}>
            <Typography
              sx={{
                color: 'error.main',
              }}
              variant="h3"
            >
              How does Savvy collect information?
            </Typography>
          </Box>
          <Box py={0.5}>
            <Typography>
              Savvy collects information from Users by mail, email, fax, recordable media or when the User submits a website form or uploads
              information to Savvy's website. The information may be stored online.
            </Typography>
          </Box>
          <Box py={0.5}>
            <Typography
              sx={{
                color: 'error.main',
              }}
              variant="h3"
            >
              What information does Savvy collect and store online?
            </Typography>
          </Box>
          <Box py={0.5}>
            <Typography>
              Personally identifiable information Savvy collects can include credit card information, contact information (such as name,
              mailing address, email address and telephone numbers), insurance information, aircraft information, aircraft logbook data,
              engine monitor data, oil analysis data, communications with Savvy on the website ticket system and other information necessary
              to service the User. Savvy reserves the right to retain the User's information even if the User ceases using the Online
              Services. Users can review certain personally identifiable information on Savvy's website, and can request that Savvy modify
              certain personally identifiable information by contacting Savvy through email at{' '}
              <Link href="mailto:privacy@savvymx.com">privacy@savvymx.com</Link>.
            </Typography>
            <Typography>
              Savvy does not collect information from anyone under 13 years of age. Savvy's website and services are directed at persons
              older than 13 years of age.
            </Typography>
          </Box>
          <Box py={0.5}>
            <Typography
              sx={{
                color: 'error.main',
              }}
              variant="h3"
            >
              How does Savvy use the information it collects?
            </Typography>
          </Box>
          <Box py={0.5}>
            <Typography>
              Except as stated elsewhere in this Privacy Policy, personally identifiable User information is only used for the purpose of
              providing or improving Savvy’s services to its clients or other Users. Savvy will not sell, trade, or otherwise transfer to
              outside parties the User's personally identifiable information. This does not include trusted third parties who assist us in
              operating Savvy's website, conducting Savvy's business, or servicing you, so long as those parties agree to keep this
              information confidential. Certain information provided by Users is shared with the aircraft service centers that do
              examinations, inspections and/or maintenance of the User's aircraft. Information provided by service centers might be shared
              with Savvy's clients. Nevertheless, Savvy may disclose any User information to third parties if Savvy believes the disclosure
              to (i) be necessary to comply with any law, regulation, subpoena, or court order, or (ii) help prevent fraud or to enforce or
              protect the rights and properties of Savvy.
              <br />
            </Typography>
            <Typography>
              Savvy may, from time to time, share User information with third parties, provided that the information is not personally
              identifiable. The User grants Savvy a worldwide license to use, host, store, reproduce, modify, create derivative works,
              communicate, publish, and distribute such information. The license continues even if the User stops using Savvy's services.
            </Typography>
          </Box>
          <Box py={0.5}>
            <Typography
              sx={{
                color: 'error.main',
              }}
              variant="h3"
            >
              How we protect the information we collect from you
            </Typography>
          </Box>
          <Box py={0.5}>
            <Typography>
              Savvy takes seriously the trust you place in us. To protect your privacy and security, Savvy takes reasonable steps to verify
              your identity, such as requiring a password and user ID, before granting access to your data. For additional security, you may
              change your password at any time. To prevent unauthorized access or disclosure, to maintain data accuracy, and to ensure the
              appropriate use of the information, Savvy utilizes reasonable physical, technical and administrative procedures to safeguard
              the information Savvy collects.
              <br />
              Credit card information (including account numbers and CVV security codes) are transmitted directly to Savvy's credit card
              service provider over a secure, encrypted link and never stored on Savvy's server or archived by Savvy. Credit card
              information is never shared, sold or communicated to any company other than Savvy's credit card service provider. Savvy is not
              liable for disclosure of personal information due to circumstances beyond its control such as Acts of God, criminal activity
              such as sophisticated intrusion exploits, or if Savvy believes the disclosure to (i) be necessary to comply with any law,
              regulation, subpoena, or court order, or (ii) help prevent fraud or to enforce or protect the rights and properties of Savvy.
            </Typography>
          </Box>
          <Box py={0.5}>
            <Typography
              sx={{
                color: 'error.main',
              }}
              variant="h3"
            >
              Interpretation of Data by Savvy
            </Typography>
          </Box>
          <Box py={0.5}>
            <Typography>
              From time to time Savvy might offer summaries, interpretations and recommendations based on the data provided to Savvy by
              Users. The summaries, interpretations and recommendations shall not be construed as a recommendation to perform any specific
              repair or maintenance (as that term is defined in applicable regulations), a determination of an engine's or aircraft's
              airworthiness, or a definitive diagnosis of an engine's or aircraft's condition. User understands and acknowledges that such
              recommendations, determinations and definitive diagnoses can be made only by a qualified aviation maintenance technician after
              examining the physical engine or aircraft and its maintenance records, and cannot be made based solely upon a review of the
              data provided by Users to Savvy.
            </Typography>
          </Box>
          <Box py={0.5}>
            <Typography
              sx={{
                color: 'error.main',
              }}
              variant="h3"
            >
              Online Terms of Service
            </Typography>
          </Box>
          <Box py={0.5}>
            <Typography>
              You must follow any policies made available to you within the Online Services.
              <br />
              Don’t misuse the Online Services. For example, don’t interfere with the Online Services or try to access them using a method
              other than the interface and the instructions that we provide. You may use the Online Services only as permitted by law. We
              may suspend or stop providing the Online Services to you if you do not comply with Savvy's terms or policies or if we are
              investigating suspected misconduct.
              <br />
              By using the Online Services, you agree that Savvy can use such information in accordance with our privacy policies.
              <br />
              We provide the Online Services using a commercially reasonable level of skill and care and we hope that you will enjoy using
              them. But there are certain things that we don’t promise about the Online Services.
              <br />
              OTHER THAN AS EXPRESSLY SET OUT IN THESE TERMS, NEITHER SAVVY NOR ITS SUPPLIERS MAKE ANY SPECIFIC PROMISES ABOUT THE ONLINE
              SERVICES. FOR EXAMPLE, WE DON’T MAKE ANY COMMITMENTS ABOUT THE SPECIFIC FUNCTION OF THE ONLINE SERVICES, THEIR RELIABILITY,
              ACCURACY OF UNDERLYING ALGORITHMS, AVAILABILITY, OR ABILITY TO MEET YOUR NEEDS. WE PROVIDE THE ONLINE SERVICES “AS IS”.
              <br />
              SOME JURISDICTIONS PROVIDE FOR CERTAIN WARRANTIES, LIKE THE IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
              PURPOSE AND NON-INFRINGEMENT. TO THE EXTENT PERMITTED BY LAW, WE EXCLUDE ALL WARRANTIES.
              <br />
              WHEN PERMITTED BY LAW, SAVVY AND SAVVY’S SUPPLIERS WILL NOT BE RESPONSIBLE FOR LOST PROFITS, REVENUES, INFORMATION OR DATA,
              FINANCIAL LOSSES OR INDIRECT, SPECIAL, CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE DAMAGES.
              <br />
              TO THE EXTENT PERMITTED BY LAW, THE TOTAL LIABILITY OF SAVVY AND ITS SUPPLIERS, FOR ANY CLAIM UNDER THESE TERMS, INCLUDING FOR
              ANY IMPLIED WARRANTIES, IS LIMITED TO THE AMOUNT YOU PAID US TO USE THE ONLINE SERVICES (OR, IF WE CHOOSE, TO SUPPLYING YOU
              THE ONLINE SERVICES AGAIN).
              <br />
              IN ALL CASES, SAVVY AND ITS SUPPLIERS, WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE THAT IS NOT REASONABLY FORESEEABLE.
              <br />
              If you are using the Online Services on behalf of a business, that business accepts these terms. It will hold harmless and
              indemnify Savvy and its affiliates, officers, agents, and employees from any claim, suit or action arising from or related to
              the use of the Online Services or violation of these terms, including any liability or expense arising from claims, losses,
              damages, suits, judgments, litigation costs and attorneys’ fees.
              <br />
              Any dispute arising out of the Online Services which cannot be informally resolved between Savvy and User shall be submitted
              to the federal or state courts within the State of Wyoming, Sheridan County, and decided based on the law of the State of
              Wyoming, excluding its choice of law doctrine.
            </Typography>
          </Box>
          <Box py={0.5}>
            <Typography
              sx={{
                color: 'error.main',
              }}
              variant="h3"
            >
              Revisions to the Online Privacy Policy and Terms of Service
            </Typography>
          </Box>
          <Box py={0.5}>
            <Typography>
              We may modify these terms or any additional terms that apply to the Online Services to, for example, reflect changes to the
              law, changes to the Online Services, or for other reasons. You should look at the terms regularly. We’ll post notice of
              modifications to these terms on this page. If you do not agree to the modified terms, you should discontinue your use of the
              Online Services.
            </Typography>
          </Box>
        </Box>
      </Box>
    </LayoutNormal>
  );
};

export default Legal;
