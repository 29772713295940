import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, IconButton, Hidden, Tabs, Tab } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import PrintIcon from '@mui/icons-material/Print';
import { ITicket } from 'types';
import TicketInfoBox from './TicketInfoBox';

import { TicketPost, PdfDialog, ImgDialog } from './ContentComponent';
import { default as TicketPostForm } from './TicketPostForm';

interface TicketContentsProps {
  ticket: ITicket | undefined;
  setSlideNum?: (value: number) => void;
  closeTicket?: (id: number, close: boolean) => void;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const a11yProps = (index: number) => {
  return {
    id: `scrollable-prevent-tab-${index}`,
    'aria-controls': `scrollable-prevent-tabpanel-${index}`,
  };
};

const TabPanel = (props: TabPanelProps) => {
  const { children, index, value, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-prevent-tabpanel-${index}`}
      aria-labelledby={`scrollable-prevent-tab-${index}`}
      {...other}
    >
      <Box>{children}</Box>
    </div>
  );
};

const TicketContents: React.FC<TicketContentsProps> = (props) => {
  const { ticket = undefined, setSlideNum = undefined, closeTicket } = props;

  const { id } = useParams<{
    id?: string;
  }>();

  const sortPosts = ticket?.posts ? [...ticket.posts] : [];

  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = (event: React.ChangeEvent<unknown>, newValue: number) => {
    setTabValue(newValue);
  };

  sortPosts.sort((a: any, b: any) => {
    if (new Date(a.created) > new Date(b.created)) {
      return -1;
    } else {
      return 1;
    }
  });

  const [showPdfFileDialog, setPdfShowFileDialog] = useState(false);
  const [showImgFileDialog, setImgShowFileDialog] = useState(false);
  const [showFile, setShowFile] = useState('');
  const [showFileName, setShowFileName] = useState('');

  const [previewAttachmentId, setPreviewAttachmentId] = useState('');

  return (
    <Box
      sx={{
        flexGrow: 1,
      }}
    >
      <Hidden xlUp>
        <Box>
          <Box
            sx={{
              px: {
                xs: 2,
                lg: 5,
              },
              pt: 3,
              pb: 1,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              '@media (min-width: 1280px)': {
                px: 7,
              },
              '@media (min-width: 1450px)': {
                px: 10,
              },
            }}
          >
            <IconButton
              sx={{
                '&:hover': {
                  borderRadius: '6px',
                },
                borderRadius: '6px',
                boxShadow: '0px 1px 1px rgba(19, 30, 64, 0.05)',
                border: '1px solid #E8E9EF',
                background: '#FFFFFF',
              }}
              onClick={() => {
                if (setSlideNum) {
                  setSlideNum(1);
                }
              }}
            >
              <ChevronLeftIcon />
            </IconButton>
            <Typography
              variant="h2"
              sx={{
                fontWeight: 700,
                fontSize: {
                  xs: '18px',
                  sm: '22px',
                },
              }}
            >
              {ticket?.subject}
            </Typography>
            <IconButton
              sx={{
                '&:hover': {
                  borderRadius: '6px',
                },
                borderRadius: '6px',
                boxShadow: '0px 1px 1px rgba(19, 30, 64, 0.05)',
                border: '1px solid #E8E9EF',
                background: '#FFFFFF',
              }}
            >
              <PrintIcon />
            </IconButton>
          </Box>
          <Hidden mdDown>{ticket && <TicketInfoBox ticket={ticket} closeTicket={closeTicket} />}</Hidden>
        </Box>
      </Hidden>
      {!ticket && !id && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          <Typography>Please select ticket to see more information</Typography>
        </Box>
      )}
      {ticket && (
        <Box
          sx={{
            py: {
              md: 2,
              lg: 5,
            },
            px: {
              xs: 2,
              lg: 6,
            },
            '@media (min-width: 1280px)': {
              px: 8,
              maxHeight: 'calc(100vh - 70px)',
              overflowY: 'auto',
              '&::-webkit-scrollbar': {
                width: '0.6em',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(0,0,0,.1)',
                borderRadius: 2,
              },
            },
            '@media (min-width: 1450px)': {
              px: 10,
            },
          }}
        >
          <Hidden mdDown>
            {ticket.state !== 'C' && <TicketPostForm ticket={ticket} />}
            <Box py={3}>
              {sortPosts.map((post: any, key: number) => {
                return (
                  <TicketPost
                    post={post}
                    setPdfShowFileDialog={setPdfShowFileDialog}
                    setImgShowFileDialog={setImgShowFileDialog}
                    setShowFile={setShowFile}
                    setShowFileName={setShowFileName}
                    ticketId={ticket.id}
                    key={key}
                    setPreviewAttachmentId={setPreviewAttachmentId}
                    aircraftId={ticket.aircraft?.id}
                    registration={ticket.aircraft?.registration}
                  />
                );
              })}
            </Box>
          </Hidden>
        </Box>
      )}
      <Box>
        {ticket && (
          <Hidden mdUp>
            <Box py={2} px={1}>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                aria-label="basic tabs example"
                sx={{
                  '& .MuiTabs-indicator': {
                    backgroundColor: 'error.dark',
                  },
                  borderBottom: '1px solid',
                  borderBottomColor: 'grey.200',
                  '& .MuiTabs-flexContainer': {
                    justifyContent: 'flex-start',
                  },
                }}
              >
                <Tab
                  label="Ticket History"
                  {...a11yProps(0)}
                  sx={{
                    py: 3,
                    px: 3,
                    '&.Mui-selected': {
                      color: 'error.dark',
                    },
                    fontSize: '16px',
                    lineHeight: '22px',
                    fontWeight: 600,
                  }}
                />
                <Tab
                  label="Info"
                  {...a11yProps(1)}
                  sx={{
                    py: 3,
                    px: 3,
                    '&.Mui-selected': {
                      color: 'error.dark',
                    },
                    fontSize: '16px',
                    lineHeight: '22px',
                    fontWeight: 600,
                  }}
                />
              </Tabs>
              <Box>
                <TabPanel value={tabValue} index={0}>
                  <Box px={1}>
                    {ticket.state !== 'C' && <TicketPostForm ticket={ticket} />}
                    <Box py={3}>
                      {sortPosts.map((post: any, key: number) => {
                        return (
                          <TicketPost
                            post={post}
                            setPdfShowFileDialog={setPdfShowFileDialog}
                            setImgShowFileDialog={setImgShowFileDialog}
                            setShowFile={setShowFile}
                            setShowFileName={setShowFileName}
                            ticketId={ticket.id}
                            key={key}
                            setPreviewAttachmentId={setPreviewAttachmentId}
                            aircraftId={ticket.aircraft?.id}
                            registration={ticket.aircraft?.registration}
                          />
                        );
                      })}
                    </Box>
                  </Box>
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                  {ticket && <TicketInfoBox ticket={ticket} closeTicket={closeTicket} />}
                </TabPanel>
              </Box>
            </Box>
          </Hidden>
        )}
      </Box>
      <PdfDialog
        showFileDialog={showPdfFileDialog}
        setShowFileDialog={setPdfShowFileDialog}
        showFileName={showFileName}
        showFile={showFile}
        previewAttachmentId={previewAttachmentId}
      />
      <ImgDialog
        showFileDialog={showImgFileDialog}
        setShowFileDialog={setImgShowFileDialog}
        showFileName={showFileName}
        showFile={showFile}
        previewAttachmentId={previewAttachmentId}
      />
    </Box>
  );
};

export default TicketContents;
