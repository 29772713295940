import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setAlert } from 'state';
import { Box, FormHelperText, Button } from '@mui/material';

import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { inputPadding, inputBorderColor, borderRadius, errorMain } from 'theme/variables';

import { useMutation } from '@apollo/client';
import { MUTATION_CHANGE_CREDIT_CARD } from 'gql';
import { ComponentLoading, ButtonSubmit } from 'components';
import { PAYMENT_UPDATE_MESSAGE } from 'const';

interface ChangeCcFormProps {
  setShowDifferentCard: (value: boolean) => void;
  isNew?: boolean;
  errorMessage?: string;
}

const ChangeCcForm: React.FC<ChangeCcFormProps> = (props) => {
  const { setShowDifferentCard, isNew = false, errorMessage } = props;

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const [updateToken, { data: dataUpdateToken, error: errorUpdateToken }] = useMutation(MUTATION_CHANGE_CREDIT_CARD, {
    errorPolicy: 'all',
  });

  // Stripe.js has not loaded yet. Make sure to disable form submission until Stripe.js has loaded.
  const stripeLoading = !stripe || !elements;

  const CARD_ELEMENT_OPTIONS = {
    style: {
      invalid: {
        color: errorMain,
      },
    },
  };

  const [stripeError, setStripeError] = useState('');

  const onChangeStripe = () => {
    setStripeError('');
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();

    if (stripe === null || elements === null) return;

    const cardElement = elements.getElement(CardElement);

    // Prevent Typescript errors if Stripe still loading
    if (cardElement === null) {
      return;
    }

    setLoading(true);

    const { error, token } = await stripe.createToken(cardElement);

    if (error) {
      const { message } = error;

      if (message) {
        setStripeError(message);
        setLoading(false);
        return;
      }
    } else if (token) {
      await updateToken({
        variables: {
          token: token.id,
        },
      });
    }
  };

  useEffect(() => {
    if (errorUpdateToken) {
      if (errorUpdateToken.graphQLErrors) {
        setStripeError(errorUpdateToken.graphQLErrors[0].message);
        dispatch(setAlert('error', errorUpdateToken.graphQLErrors[0].message));
      } else {
        setStripeError(errorUpdateToken.message);
        dispatch(setAlert('error', errorUpdateToken.message));
      }
      setLoading(false);
    } else if (dataUpdateToken) {
      const { changeCreditCardToken } = dataUpdateToken;

      if (changeCreditCardToken.ok) {
        setLoading(false);
        dispatch(setAlert('success', PAYMENT_UPDATE_MESSAGE));
        setShowDifferentCard(false);
      } else {
        dispatch(setAlert('error', changeCreditCardToken.error));
      }
    }
  }, [dataUpdateToken, errorUpdateToken, dispatch, setLoading, setShowDifferentCard]);

  useEffect(() => {
    if (errorMessage) {
      setStripeError(errorMessage);
    }
  }, [errorMessage]);

  return (
    <Box>
      <ComponentLoading loading={stripeLoading}>
        <form onSubmit={onSubmit}>
          <Box mb={1}>
            <Box
              sx={{
                '& .StripeElement': {
                  padding: inputPadding,
                  border: `${inputBorderColor} 1px solid`,
                  borderRadius: `${borderRadius}px`,
                  '&.StripeElement--invalid': {
                    borderColor: errorMain,
                    color: errorMain,
                  },
                },
              }}
            >
              <CardElement options={CARD_ELEMENT_OPTIONS} onChange={onChangeStripe} />
            </Box>
            {stripeError && <FormHelperText error>{stripeError}</FormHelperText>}
          </Box>
          {!isNew && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'end',
              }}
            >
              {!isNew && (
                <Box
                  sx={{
                    maxWidth: '120px',
                    mr: 1,
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setShowDifferentCard(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Box>
              )}
              <Box
                sx={{
                  maxWidth: '120px',
                }}
              >
                <ButtonSubmit variant="outlined" loading={loading} text={isNew ? 'Add' : 'Update'} />
              </Box>
            </Box>
          )}
        </form>
      </ComponentLoading>
    </Box>
  );
};

export default ChangeCcForm;
