import React from 'react';

import { ClickAwayListener, Box } from '@mui/material';

interface IDropdown {
  onClickAway: () => void;
  children: React.ReactNode;
}

const Dropdown: React.FC<IDropdown> = (props) => {
  const { children, onClickAway } = props;

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <Box position="relative">{children}</Box>
    </ClickAwayListener>
  );
};

export default Dropdown;
