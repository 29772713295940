import React from 'react';
import { CSSObject } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers-pro';
import { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';

interface CustomDatePickerProps {
  value: Dayjs | null;
  setValue: (value: Dayjs | null) => void;
  error?: string;
  setError?: (value: string) => void;
  sx?: CSSObject;
  label: string;
  disabled?: boolean;
  containerSx?: CSSObject;
}

const CustomDatePicker: React.FC<CustomDatePickerProps> = (props) => {
  const { value, setValue, error, setError, label, sx, disabled = false, containerSx } = props;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label={label}
        value={value}
        onChange={(newValue: Dayjs | null) => {
          setValue(newValue);
          setError && setError('');
        }}
        slotProps={{
          textField: {
            FormHelperTextProps: {
              sx: sx ? sx : {},
            },
            error: !!error,
            helperText: error,
            fullWidth: true,
            sx: containerSx,
          },
        }}
        disabled={disabled}
      />
    </LocalizationProvider>
  );
};

export default CustomDatePicker;
