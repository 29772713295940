import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Dialog, Box, DialogTitle, DialogContent, TextField, DialogActions, Grid, Button, Typography } from '@mui/material';
import { MUTATION_UPDATE_AIRCRAFT_COMMENT } from 'gql';
import { useMutation } from '@apollo/client';
import { setAlert } from 'state';

import { ComponentLoading, Checkbox } from 'components';
import { IComment } from 'types';

interface EditCommentDialogProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  editComment: IComment;
}

const EditCommentDialog: React.FC<EditCommentDialogProps> = (props) => {
  const { open, setOpen, editComment } = props;

  const [updateComment, { data: dataComment, error: errorComment, loading: loadingComment }] =
    useMutation(MUTATION_UPDATE_AIRCRAFT_COMMENT);
  const dispatch = useDispatch();

  const [comment, setComment] = useState('');
  const [commentError, setCommentError] = useState('');

  const [pinned, setPinned] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async () => {
    if (!comment) {
      setCommentError('Required');
      return;
    }

    await updateComment({
      variables: {
        comment,
        pinned,
        commentId: editComment.id,
      },
    });
  };

  useEffect(() => {
    if (errorComment) {
      dispatch(setAlert('error', 'Unable to update comment'));
    } else if (dataComment) {
      if (dataComment?.updateAircraftComment?.ok) {
        dispatch(setAlert('success', 'Updated comment.'));
        setOpen(false);
      } else {
        dispatch(setAlert('error', dataComment?.updateAircraftComment?.error || 'Unable to update comment'));
      }
    }
  }, [dataComment, errorComment, setOpen, dispatch]);

  useEffect(() => {
    if (open) {
      setComment(editComment.comment);
      setCommentError('');
      setPinned(editComment.pinned);
    }
  }, [open, editComment]);

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={handleClose}
      PaperProps={{
        sx: {
          maxWidth: '400px',
        },
      }}
    >
      <DialogTitle
        sx={{
          textAlign: 'center',
          fontSize: '24px',
          fontWeight: 700,
        }}
      >
        Update Comment
      </DialogTitle>
      <DialogContent>
        <ComponentLoading loading={loadingComment}>
          <Box py={1}>
            <TextField
              name="comment"
              label="Comment *"
              value={comment}
              onChange={(e) => {
                setComment(e.target.value);
                setCommentError('');
              }}
              error={!!commentError}
              helperText={commentError}
              FormHelperTextProps={{
                sx: {
                  ml: 0,
                },
              }}
              sx={{
                mb: 0,
              }}
              multiline
              rows={5}
              fullWidth
            />
          </Box>
          <Box py={1}>
            <Checkbox
              value={pinned}
              label={
                <Typography
                  sx={{
                    display: 'inline-block',
                  }}
                >
                  Pinned
                </Typography>
              }
              onChange={() => {
                setPinned(!pinned);
              }}
              checked={pinned}
              noMargin
            />
          </Box>
        </ComponentLoading>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button variant="outlined" onClick={handleClose} disabled={loadingComment} fullWidth>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="contained" onClick={onSubmit} disabled={loadingComment} fullWidth>
              Update
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default EditCommentDialog;
