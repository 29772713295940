import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, Box, Typography, DialogContent, DialogTitle, Button } from '@mui/material';
import { QUERY_GET_ENGINE_DATA_FILE_LOG, QUERY_GET_SAVVY_ENGINE_DATA_FILE_LOG } from 'gql';
import { useLazyQuery } from '@apollo/client';

import { ComponentLoading } from 'components';
import { setAlert } from 'state';

interface ViewLogModalProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  fileId: string;
  aircraftId: string;
}

const ViewLogModal: React.FC<ViewLogModalProps> = (props) => {
  const { open, setOpen, fileId, aircraftId } = props;

  const [fetchLog, { data: dataLog, error: errorLog, loading: loadingLog }] = useLazyQuery(QUERY_GET_ENGINE_DATA_FILE_LOG);
  const [fetchSavvyLog, { data: dataSavvyLog, error: errorSavvyLog, loading: loadingSavvyLog }] = useLazyQuery(
    QUERY_GET_SAVVY_ENGINE_DATA_FILE_LOG,
  );
  const dispatch = useDispatch();
  const { isSavvy } = useSelector((state: any) => state.auth);

  const [logs, setLogs] = useState('');

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      if (aircraftId && fileId) {
        if (isSavvy) {
          fetchSavvyLog({
            variables: {
              aircraftId,
              engineDataFileId: fileId,
            },
          });
        } else {
          fetchLog({
            variables: {
              id: parseInt(aircraftId),
              engineDataFileId: fileId,
            },
          });
        }
      }
    }
  }, [open, aircraftId, fileId, fetchLog, fetchSavvyLog, isSavvy]);

  useEffect(() => {
    if (errorLog) {
      dispatch(setAlert('error', 'Unable to load log'));
    }
    if (errorSavvyLog) {
      dispatch(setAlert('error', 'Unable to load log'));
    }
  }, [dispatch, errorLog, errorSavvyLog]);

  useEffect(() => {
    if (dataLog?.me?.aircraft[0]?.engineDataFiles[0]?.parseLogs) {
      setLogs(dataLog.me.aircraft[0].engineDataFiles[0].parseLogs);
    }
  }, [dataLog]);

  useEffect(() => {
    if (dataSavvyLog?.savvy?.aircraft?.engineDataFiles[0]?.parseLogs) {
      setLogs(dataSavvyLog.savvy.aircraft.engineDataFiles[0].parseLogs);
    }
  }, [dataSavvyLog]);

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={handleClose}
      PaperProps={{
        sx: {
          maxWidth: '700px',
        },
      }}
    >
      <DialogTitle
        sx={{
          textAlign: 'center',
          fontSize: '24px',
          fontWeight: 700,
        }}
      >
        View Logs
      </DialogTitle>
      <DialogContent>
        <ComponentLoading loading={loadingLog || loadingSavvyLog}>
          <Box>
            <Typography
              variant="body1"
              gutterBottom
              align="left"
              sx={{
                fontSize: '18px',
              }}
              dangerouslySetInnerHTML={{
                __html: logs ? logs.replace(/\n/g, '<br/>') : 'No notes',
              }}
            />
            <Box
              sx={{
                textAlign: 'center',
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  width: '100px',
                }}
                onClick={handleClose}
              >
                Close
              </Button>
            </Box>
          </Box>
        </ComponentLoading>
      </DialogContent>
    </Dialog>
  );
};

export default ViewLogModal;
