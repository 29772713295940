import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Typography, Tooltip, Link } from '@mui/material';
import { ComponentLoading } from 'components';

import { QUERY_SAVVY_CONTACT_SEARCH } from 'gql';
import { useLazyQuery } from '@apollo/client';
import { SAVVY_SEARCH_LIMIT, PATH_CONTACT, PATH_SAVVY_SERACH_CONTACT } from 'const';

import { IContactType } from 'types';
import { ctrlCmdClick } from 'helpers';

interface ContactSearchProps {
  searchQuery: string;
}

const ContactSearch: React.FC<ContactSearchProps> = (props) => {
  const { searchQuery } = props;

  const history = useHistory();

  const [fetchSavvyContactSearch, { data: dataContact, loading: loadingContact }] = useLazyQuery(QUERY_SAVVY_CONTACT_SEARCH);

  const [contactList, setContactList] = useState<IContactType[]>([]);

  const [count, setCount] = useState(0);

  const loadMore = () => {
    history.push(`${PATH_SAVVY_SERACH_CONTACT}?query=${searchQuery}`);
  };

  useEffect(() => {
    if (!searchQuery || searchQuery.length < 2) {
      return;
    }

    setContactList([]);

    const timeout: NodeJS.Timeout = setTimeout(() => {
      fetchSavvyContactSearch({
        variables: {
          search: searchQuery,
          limit: SAVVY_SEARCH_LIMIT,
          offset: 0,
        },
      });
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [searchQuery, fetchSavvyContactSearch]);

  useEffect(() => {
    if (dataContact?.savvy?.search?.contacts?.results?.length) {
      setContactList((contactList: IContactType[]) => {
        const newArray: IContactType[] = contactList.concat(dataContact.savvy?.search?.contacts?.results);
        return [...newArray];
      });
    }

    if (dataContact?.savvy?.search?.contacts) {
      setCount(dataContact.savvy?.search?.contacts?.count);
    }
  }, [dataContact]);

  return (
    <Box>
      <ComponentLoading loading={loadingContact}>
        <Box>
          {!!searchQuery && (
            <Typography
              variant="h5"
              sx={{
                fontWeight: 'bold',
                py: 1,
              }}
            >
              Contacts {count ? `(${count})` : ''}
            </Typography>
          )}
          <Box pl={4}>
            {contactList?.map((contact: IContactType, key: number) => {
              return (
                <Box
                  key={key}
                  py={1}
                  sx={{
                    cursor: 'pointer',
                  }}
                  onClick={(event: any) => {
                    ctrlCmdClick(event, `${PATH_CONTACT}/${contact.id}`, history);
                  }}
                >
                  <Tooltip
                    title={
                      <Typography
                        sx={{
                          fontSize: '16px',
                          lineHeight: '24px',
                        }}
                      >
                        {contact?.email || 'No email'}
                      </Typography>
                    }
                    componentsProps={{
                      tooltip: {
                        sx: {
                          backgroundColor: '#000',
                          color: 'background.default',
                        },
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        textDecoration: contact.isActive ? 'none' : 'line-through',
                      }}
                    >
                      {contact.firstName ? `${contact.firstName} ${contact.lastName}` : ''}
                      <Box
                        component="span"
                        sx={{
                          color: 'error.main',
                          ml: 1,
                        }}
                      >
                        ({contact.isSavvy && 'Savvy'}
                        {contact.isTechnician && `Technician`}
                        {contact.isTechnician && contact.serviceCenter ? ` ${contact.serviceCenter.company}` : ''}
                        {contact.isClient && 'Client'})
                      </Box>
                    </Typography>
                  </Tooltip>
                </Box>
              );
            })}
            {!contactList.length && !!searchQuery && !loadingContact && <Box py={1}>No matched contacts</Box>}
          </Box>
          {count > contactList.length && (
            <Link
              href="#"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                loadMore();
              }}
            >
              Show More
            </Link>
          )}
        </Box>
      </ComponentLoading>
    </Box>
  );
};

export default ContactSearch;
