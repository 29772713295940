import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Dialog, DialogTitle, DialogContent, DialogActions, Box, TextField, MenuItem, Grid, Button, Typography } from '@mui/material';
import { ICohort } from 'types';
import { ComponentLoading } from 'components';

import { QUERY_COHORTS_BRIEF, MUTATION_DUPLICATE_COHORT_PARAMS } from 'gql';
import { useLazyQuery, useMutation } from '@apollo/client';

import { setAlert } from 'state';

interface DuplicateCohortDialogProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  sourceCohortId: string;
}

const DuplicateCohortDialog: React.FC<DuplicateCohortDialogProps> = (props) => {
  const { open, setOpen, sourceCohortId } = props;

  const [duplicateCohort, { data: dataDuplicate, loading: loadingDuplicate, error: errorDuplicate }] =
    useMutation(MUTATION_DUPLICATE_COHORT_PARAMS);
  const [fetchCohorts, { data: dataCohorts, loading: loadingCohorts, error: errorCohorts }] = useLazyQuery(QUERY_COHORTS_BRIEF, {
    fetchPolicy: 'cache-and-network',
  });
  const dispatch = useDispatch();

  const [destinationCohortId, setDestinationCohortId] = useState('');
  const [cohortError, setCohortError] = useState('');

  const onSubmit = async () => {
    if (!destinationCohortId) {
      setCohortError('Please select destination cohort.');
      return;
    }

    await duplicateCohort({
      variables: {
        sourceCohortId,
        destinationCohortId,
      },
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (errorCohorts) {
      dispatch(setAlert('error', 'Unable to laod destination cohorts'));
    }
  }, [errorCohorts, dispatch]);

  useEffect(() => {
    if (errorDuplicate) {
      dispatch(setAlert('error', 'Unable to duplicate cohort'));
    } else if (dataDuplicate) {
      if (dataDuplicate.duplicateCohortParams?.ok) {
        dispatch(setAlert('success', 'Duplicated cohort params'));
        setOpen(false);
      } else {
        dispatch(setAlert('error', dataDuplicate.duplicateCohortParams?.error || 'Unable to duplicate cohort'));
      }
    }
  }, [errorDuplicate, dataDuplicate, dispatch, setOpen]);

  useEffect(() => {
    if (open) {
      fetchCohorts();
      setDestinationCohortId('');
      setCohortError('');
    }
  }, [open, fetchCohorts]);

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={handleClose}
      PaperProps={{
        sx: {
          maxWidth: '400px',
        },
      }}
    >
      <DialogTitle
        sx={{
          textAlign: 'center',
          fontSize: '24px',
          fontWeight: 700,
        }}
      >
        Duplicate Paramters
      </DialogTitle>
      <DialogContent>
        <ComponentLoading loading={loadingDuplicate || loadingCohorts}>
          <Box py={1}>
            {!!dataCohorts?.cohorts?.length && dataCohorts.cohorts.length > 1 && (
              <TextField
                select
                name="destination_cohort"
                label="Destination Cohort *"
                value={destinationCohortId}
                onChange={(e) => {
                  setDestinationCohortId(e.target.value);
                }}
                error={!!cohortError}
                helperText={cohortError}
                FormHelperTextProps={{
                  sx: {
                    ml: 0,
                  },
                }}
                sx={{
                  mb: 0,
                }}
                fullWidth
              >
                {dataCohorts?.cohorts?.map((item: ICohort, key: number) => {
                  if (item.id === sourceCohortId) {
                    return null;
                  }
                  return (
                    <MenuItem value={item.id} key={key}>
                      #{item.id} {item.aircraftModel?.name || ''} {item.engineModel?.name || ''}
                    </MenuItem>
                  );
                })}
              </TextField>
            )}
            {(!dataCohorts?.cohorts?.length || dataCohorts.cohorts.length <= 1) && (
              <Typography
                align="center"
                sx={{
                  color: 'error.main',
                }}
              >
                You don't have destination cohort to duplicate parameters.
              </Typography>
            )}
          </Box>
        </ComponentLoading>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button variant="outlined" onClick={handleClose} disabled={loadingDuplicate || loadingCohorts} fullWidth>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="contained" onClick={onSubmit} disabled={loadingDuplicate || loadingCohorts} fullWidth>
              Submit
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default DuplicateCohortDialog;
