import React from 'react';

import { Grid } from '@mui/material';

import { AppBarMenu } from '..';

import { LOGGED_OUT_LINKS } from 'const';

import styles from './style.module.scss';

const LoggedOutMenu: React.FC = () => {
  return (
    <Grid container justifyContent="flex-end" alignItems="stretch" className="h-100">
      {/* Links to the right */}
      <Grid item xs={12} sm="auto">
        <AppBarMenu links={LOGGED_OUT_LINKS} linkClass={styles.loggedOutMenuLink} />
      </Grid>
    </Grid>
  );
};

export default LoggedOutMenu;
