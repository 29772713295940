import React, { useState, useEffect } from 'react';
import { Dialog, Box, Typography, DialogContent, DialogTitle, Button } from '@mui/material';
import { ComponentLoading } from 'components';
import { QUERY_COMBO_LOGS_PDF_DOWNLOAD_URL, QUERY_COMBO_LOGS_PDF_STATUS } from 'gql';
import { useLazyQuery } from '@apollo/client';
import { useTimer } from 'hooks';
import { downloadFileInNewTab } from 'helpers';

interface CreateComboLogsDialogProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  workflowId: string;
}

const DONE_STATUS = 'Generation Done';

const FETCH_INTERVAL = 3;

const CreateComboLogsDialog: React.FC<CreateComboLogsDialogProps> = (props) => {
  const { open, setOpen, workflowId } = props;
  const [loading, setLoading] = useState(false);

  const [fetchStatus, { data: dataFetchStatus }] = useLazyQuery(QUERY_COMBO_LOGS_PDF_STATUS, {
    fetchPolicy: 'no-cache',
  });

  const [fetchPdfDownloadUrl, { data: dataPdfDownloadUrl }] = useLazyQuery(QUERY_COMBO_LOGS_PDF_DOWNLOAD_URL, {
    fetchPolicy: 'no-cache',
  });
  const [readyDownload, setReadyDownload] = useState(false);

  const timerAction = () => {
    if (!workflowId) {
      return;
    }

    fetchStatus({
      variables: {
        workflowId,
      },
    });
  };

  const {
    create: createTimer,
    created: createdTimer,
    clear: clearTimer,
  } = useTimer({
    timeoutValue: FETCH_INTERVAL,
    timerAction,
  });

  useEffect(() => {
    if (open && workflowId) {
      setReadyDownload(false);
      setLoading(true);
      if (!createdTimer) {
        createTimer();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, workflowId]);

  useEffect(() => {
    if (dataFetchStatus && dataFetchStatus.comboLogsPdfGenerationStatus) {
      if (dataFetchStatus.comboLogsPdfGenerationStatus === DONE_STATUS) {
        fetchPdfDownloadUrl({
          variables: {
            workflowId,
          },
        });
        clearTimer();
        return;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFetchStatus, workflowId]);

  useEffect(() => {
    if (dataPdfDownloadUrl && dataPdfDownloadUrl.comboLogsPdfDownloadUrl) {
      setLoading(false);
      setReadyDownload(true);
    }
  }, [dataPdfDownloadUrl]);

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={() => {
        return;
      }}
      disableEscapeKeyDown
      onBackdropClick={() => {
        return;
      }}
    >
      <DialogTitle
        sx={{
          textAlign: 'center',
          fontSize: '24px',
          fontWeight: 700,
        }}
      >
        {readyDownload ? 'Download' : 'Compressing'} Combo Logs
      </DialogTitle>
      <DialogContent>
        <Box>
          {!readyDownload && (
            <Typography
              align="center"
              sx={{
                color: 'warning.main',
              }}
            >
              This may take a bit...
            </Typography>
          )}
          {loading && (
            <ComponentLoading loading={loading}>
              <Box py={2}></Box>
            </ComponentLoading>
          )}
          {readyDownload && (
            <Box
              sx={{
                textAlign: 'center',
              }}
            >
              <Button
                variant="contained"
                onClick={() => {
                  downloadFileInNewTab(dataPdfDownloadUrl.comboLogsPdfDownloadUrl);
                  setOpen(false);
                }}
              >
                Download Pdf
              </Button>
            </Box>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default CreateComboLogsDialog;
