import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Autocomplete, TextField } from '@mui/material';

import { IDropDownOption } from 'types';

import { QUERY_SAVVY_USER_TAGS, MUTATION_CREATE_TICKET_TAG } from 'gql';
import { useQuery, useMutation } from '@apollo/client';

import { setAlert } from 'state';

interface AddUserTagComponentProps {
  ticketId: string;
}

const AddUserTagComponent: React.FC<AddUserTagComponentProps> = (props) => {
  const { ticketId } = props;

  const { data: dataUserTags, loading: loadingUserTags } = useQuery(QUERY_SAVVY_USER_TAGS);

  const [addUserTagMutation, { data: dataAddUserTag, error: errorAddUserTag }] = useMutation(MUTATION_CREATE_TICKET_TAG);

  const [autocompleteValue, setAutocompleteValue] = useState<IDropDownOption | undefined>(undefined);

  const dispatch = useDispatch();

  const addUserTag = async (id: string, ticketId: string) => {
    await addUserTagMutation({
      variables: {
        userTagTypeId: id,
        ticketId,
      },
    });
  };

  useEffect(() => {
    if (errorAddUserTag) {
      dispatch(setAlert('error', 'Unable to add user tag'));
    } else if (dataAddUserTag) {
      if (dataAddUserTag.createTicketTag?.ok) {
        dispatch(setAlert('success', 'Added user tag'));
        setAutocompleteValue(undefined);
      } else {
        dispatch(setAlert('error', dataAddUserTag.createTicketTag?.error || 'Unable to add user tag'));
      }
    }
  }, [dataAddUserTag, errorAddUserTag, dispatch]);

  return (
    <Box>
      <Box pt={1}>
        <Autocomplete
          options={dataUserTags?.savvy?.userTagTypes ? dataUserTags?.savvy?.userTagTypes : []}
          getOptionLabel={(option: IDropDownOption) => (option?.id ? `${option.name}` : '')}
          value={autocompleteValue ? autocompleteValue : null}
          onChange={(event: any, value: any) => {
            if (value && value.id) {
              setAutocompleteValue(value);
              addUserTag(value.id, ticketId);
            }
          }}
          loading={loadingUserTags}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Add user tag"
              placeholder="Type tag's name"
              fullWidth
              sx={{
                mb: 0,
              }}
            />
          )}
          renderOption={(props: any, option: IDropDownOption) => {
            return option?.id ? <li {...props} key={option.id}>{`${option.name}`}</li> : null;
          }}
        />
      </Box>
    </Box>
  );
};

export default AddUserTagComponent;
