import React from 'react';
import { useDispatch } from 'react-redux';
import { Box, styled, Typography } from '@mui/material';
import { FileUploader } from 'react-drag-drop-files';
import { FLIGHT_FILE_MAX_SIZE } from 'const';

import { TicketInfoIcon } from 'customIcons';
import { default as TooltipForAutotag } from './TooltipForAutotag';
import { setAlert } from 'state';
import { IBorescopeImageSet } from 'types';

const classes = {
  dragDropUpload: 'dragDropUpload',
};

const StyledDiv = styled('div')({
  [`& .${classes.dragDropUpload}`]: {
    height: '240px !important',
    maxWidth: '1200px !important',
    width: '100%',
    backgroundColor: 'white',
    border: '2px dashed #BBC0D4 !important',
    borderRadius: '6px !important',
    padding: 0,
    '& svg': {
      display: 'none',
    },
    '& div': {
      display: 'block',
      textAlign: 'center',
      '& span': {
        fontSize: '20px',
        fontWeight: 400,
        color: 'rgba(75, 84, 112, 0.5)',
        lineHeight: '27px',
        display: 'block',
        alignItems: 'start',
        justifyContent: 'center',
        padding: '0 8px',
        '& span': {
          textDecoration: 'none',
          display: 'contents',
          whiteSpace: 'nowrap',
          marginRight: '5px',
        },
      },
      '& .file-types': {
        display: 'none',
      },
    },
  },
});

interface AutoTagUploaderProps {
  handleChangeFiles: (files: File[]) => void;
  borescopeImageSet: IBorescopeImageSet;
  setDoneUpload: (value: boolean) => void;
  subjectList: string[][];
}

const AutoTagUploader: React.FC<AutoTagUploaderProps> = (props) => {
  const { handleChangeFiles, setDoneUpload, borescopeImageSet, subjectList } = props;
  const dispatch = useDispatch();
  return (
    <Box>
      <Box>
        <StyledDiv
          sx={{
            mb: 2.5,
            width: '100%',
          }}
        >
          <FileUploader
            handleChange={async (files: File[]) => {
              setDoneUpload(false);
              handleChangeFiles(files);
            }}
            id={'borescope_image_uploader'}
            name={`borescopeImageUploader_${borescopeImageSet.id}`}
            label={`Click or Drag/Drop files here for any cylinder. See optional naming convention below.`}
            classes={[classes.dragDropUpload]}
            maxSize={FLIGHT_FILE_MAX_SIZE}
            onSizeError={() => {
              dispatch(setAlert('error', 'File is over 100MB limit.'));
            }}
            hoverTitle={'Drop Here'}
            types={['jpg', 'jpeg', 'png']}
            onTypeError={(err: any) => {
              dispatch(setAlert('error', 'File type error. Please use PNG or JPG/JPEG files.'));
              return;
            }}
            multiple={true}
          />
        </StyledDiv>
      </Box>
      <Box
        py={1}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <TicketInfoIcon
          sx={{
            width: '24px',
            height: '24px',
            fill: 'none',
          }}
        />
        <Typography
          sx={{
            mx: 1,
          }}
        >
          You can use filenames to auto-tags
        </Typography>
        <TooltipForAutotag subjectList={subjectList} />
      </Box>
    </Box>
  );
};

export default AutoTagUploader;
