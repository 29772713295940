import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Box, Avatar, Badge, Tabs, Tab, Hidden, styled } from '@mui/material';
import { PersonalSettings, SharingOptions, ThirdPartyApplications, ProPacks, PersonalTechnicianSettings, ClassificationsTab } from './Tabs';
import { theme } from 'theme';

import { useMutation } from '@apollo/client';
import { MUTATION_CHANGE_ADVANCED_SETTINGS, MUTATION_CREATE_TOKEN, MUTATION_DELETE_TOKEN, MUTATION_RESET_SHARING_URL } from 'gql';
import { setAlert } from 'state';

import { TabPanel } from 'components';
import { IContactType } from 'types';

const classes = {
  root: 'root',
  tab: 'tab',
  badge: 'badge',
};

const StyledDiv = styled('div')({
  [`& .${classes.root}`]: {
    background: theme.palette.grey['800'],
    color: theme.palette.grey['600'],
    borderRadius: '6px 6px 0 0',
  },
  [`& .${classes.tab}`]: {
    '&.Mui-selected': {
      backgroundColor: theme.palette.background.default,
      borderRadius: '6px 6px 0 0',
    },
    '&.MuiTab-root': {
      color: theme.palette.grey['600'],
      borderRight: '1px solid',
      borderRightColor: theme.palette.grey['200'],
      '&:last-child': {
        borderRight: '0px',
      },
      width: '200px',
      '@media (max-width: 1440px)': {
        width: '170px',
      },
      '@media (max-width: 1380px)': {
        width: '150px',
      },
    },
    '@media (max-width: 768px)': {
      padding: '6px',
    },
  },
  [`& .${classes.badge}`]: {
    '& .MuiBadge-badge': {
      height: 'auto',
      bottom: '-20px',
      left: '40px',
      '@media (max-width: 768px)': {
        left: '0',
      },
      transform: 'inherit',
      transformOrigin: 'inherit',
    },
  },
});

interface MyAccountProps {
  userData: IContactType;
}

const MyAccount: React.FC<MyAccountProps> = (props) => {
  const { firstName, lastName, sharingUrl, settings } = props.userData;

  const userName = `${firstName} ${lastName}`;

  const [tabValue, setTabValue] = useState(0);

  const dispatch = useDispatch();
  const { isTechnician } = useSelector((state: any) => state.auth);

  const handleChange = (event: React.ChangeEvent<object>, newValue: number) => {
    setTabValue(newValue);
  };

  const [internalSharingUrl, setInternalSharingUrl] = useState(sharingUrl);

  const [apiTokens, setApiTokens] = useState<
    {
      id: string;
      name: string;
      token: string;
    }[]
  >(props.userData.apiTokens ? props.userData.apiTokens : []);

  const [
    changeAdvancedSettings,
    { data: dataChangeAdvancedSettings, error: errorChangeAdvancedSettings, loading: loadingChangeAdvancedSettings },
  ] = useMutation(MUTATION_CHANGE_ADVANCED_SETTINGS, {
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  });

  const [createTokenMutation, { data: dataCreateTokenMutation, error: errorCreateTokenMutation, loading: loadingCreateTokenMutation }] =
    useMutation(MUTATION_CREATE_TOKEN, {
      errorPolicy: 'all',
    });

  const [deleteToken, { data: dataDeleteToken, error: errorDeleteToken, loading: loadingDeleteToken }] = useMutation(MUTATION_DELETE_TOKEN);

  const [sharingMode, setSharingMode] = useState(props.userData.sharingMode === 'Public');

  const [resetSharingUrl, { data: dataResetSharingUrl, error: errorResetSharingUrl, loading: loadingResetSharingUrl }] = useMutation(
    MUTATION_RESET_SHARING_URL,
    {
      errorPolicy: 'all',
    },
  );
  const [emailNotificationsForOwnPosts, setEmailNotificationsForOwnPosts] = useState(settings.emailNotificationsForOwnPosts);

  const changeSharingMode = async (value: boolean) => {
    await changeAdvancedSettings({
      variables: {
        publicSharing: value,
      },
    });
  };

  useEffect(() => {
    if (errorChangeAdvancedSettings) {
      dispatch(setAlert('error', 'Unable to change sharing mode'));
    } else if (dataChangeAdvancedSettings) {
      if (dataChangeAdvancedSettings.changeAdvancedSettings?.ok) {
        dispatch(setAlert('success', 'Changed sharing mode'));
      } else {
        dispatch(setAlert('error', dataChangeAdvancedSettings.changeAdvancedSettings?.error || 'Unable to change sharing mode'));
      }
    }
  }, [dataChangeAdvancedSettings, errorChangeAdvancedSettings, dispatch]);

  const createNewToken = async (value: string) => {
    await createTokenMutation({
      variables: {
        name: value,
      },
    });
  };

  const deleteTokenAction = async (value: string) => {
    await deleteToken({
      variables: {
        tokenId: value,
      },
    });
  };

  const ResetSharingUrl = async () => {
    await resetSharingUrl();
  };

  useEffect(() => {
    if (errorResetSharingUrl) {
      dispatch(setAlert('error', 'Unable to reset sharing URL'));
    } else if (dataResetSharingUrl) {
      if (dataResetSharingUrl.resetSharingUrl?.ok) {
        setInternalSharingUrl(dataResetSharingUrl?.resetSharingUrl?.me?.sharingUrl);
        dispatch(setAlert('success', 'Reset Sharing URL'));
      } else {
        dispatch(setAlert('error', dataResetSharingUrl.resetSharingUrl?.error || 'Unable to reset sharing URL'));
      }
    }
  }, [dataResetSharingUrl, errorResetSharingUrl, dispatch]);

  useEffect(() => {
    if (errorCreateTokenMutation) {
      dispatch(setAlert('error', 'Unable to create new token'));
    } else if (dataCreateTokenMutation) {
      const { me, ok, error } = dataCreateTokenMutation.createToken;

      if (ok) {
        dispatch(setAlert('success', 'Created New Token'));
        setApiTokens(me.apiTokens);
      } else {
        dispatch(setAlert('error', error || 'Unable to create new token'));
      }
    }
  }, [dataCreateTokenMutation, errorCreateTokenMutation, dispatch]);

  useEffect(() => {
    if (errorDeleteToken) {
      dispatch(setAlert('error', 'Unable to delete your token'));
    } else if (dataDeleteToken) {
      const { me, ok, error } = dataDeleteToken.deleteToken;

      if (ok) {
        dispatch(setAlert('success', 'Token deleted.'));
        setApiTokens(me.apiTokens);
      } else {
        dispatch(setAlert('error', error || 'Unable to delete your token'));
      }
    }
  }, [dataDeleteToken, errorDeleteToken, dispatch]);

  return (
    <StyledDiv
      sx={{
        border: '1px solid',
        borderColor: 'grey.200',
        backgroundColor: 'background.default',
      }}
    >
      <Box pl={2.5} py={2.5}>
        <Typography
          variant="h3"
          sx={{
            marginBottom: 0,
            lineHeight: '27px',
            fontWeight: 700,
          }}
          color="textSecondary"
        >
          My Account
        </Typography>
      </Box>
      <Box
        sx={{
          position: 'relative',
        }}
      >
        <Hidden smDown>
          <Badge
            overlap="circular"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            className={classes.badge}
            badgeContent={
              <Box>
                <Avatar
                  src={''}
                  alt={userName}
                  sizes={'large'}
                  style={{
                    width: '100px',
                    height: '100px',
                  }}
                />
              </Box>
            }
            style={{
              width: '100%',
            }}
          >
            <img
              alt="Account background"
              src="/images/aircraft.webp"
              style={{
                width: '100%',
                objectFit: 'cover',
                maxHeight: '250px',
                objectPosition: '50% 70%',
              }}
            />
          </Badge>
        </Hidden>
        <Hidden smDown>
          <Box
            sx={{
              position: 'absolute',
              right: 0,
              bottom: 0,
            }}
          >
            <Tabs value={tabValue} onChange={handleChange} indicatorColor="primary" textColor="primary" centered className={classes.root}>
              <Tab label="Personal Settings" className={classes.tab} />
              <Tab label="Sharing Options" className={classes.tab} />
              <Tab label="Third-Party Applications" className={classes.tab} />
              <Tab label="Pro Packs" className={classes.tab} />
              <Tab
                label="Classifications"
                className={classes.tab}
                sx={{
                  display: !isTechnician ? 'none' : 'inline-flex',
                }}
              />
            </Tabs>
          </Box>
        </Hidden>
      </Box>
      <Box>
        <TabPanel value={tabValue} index={0}>
          {!isTechnician && (
            <PersonalSettings
              userData={props.userData}
              emailNotificationsForOwnPosts={emailNotificationsForOwnPosts}
              setEmailNotificationsForOwnPosts={setEmailNotificationsForOwnPosts}
            />
          )}
          {isTechnician && (
            <PersonalTechnicianSettings
              userData={props.userData}
              emailNotificationsForOwnPosts={emailNotificationsForOwnPosts}
              setEmailNotificationsForOwnPosts={setEmailNotificationsForOwnPosts}
            />
          )}
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <SharingOptions
            sharingMode={sharingMode}
            sharingUrl={internalSharingUrl}
            setSharingMode={setSharingMode}
            changeSharingMode={changeSharingMode}
            resetSharingUrl={ResetSharingUrl}
            loading={Boolean(loadingChangeAdvancedSettings) || Boolean(loadingResetSharingUrl)}
          />
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <ThirdPartyApplications
            apiTokens={apiTokens}
            createNewToken={createNewToken}
            deleteTokenAction={deleteTokenAction}
            loading={Boolean(loadingCreateTokenMutation) || Boolean(loadingDeleteToken)}
            isLoad={tabValue === 2}
          />
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
          <ProPacks isLoad={tabValue === 3} />
        </TabPanel>
        {isTechnician && (
          <TabPanel value={tabValue} index={4}>
            <ClassificationsTab isLoad={tabValue === 4} />
          </TabPanel>
        )}
      </Box>
    </StyledDiv>
  );
};

export default MyAccount;
