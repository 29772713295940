import React from 'react';
import styles from './style.module.scss';
import { ILayout } from 'types';

const Subsection: React.FC<ILayout> = (props) => {
  const { children } = props;
  return <div className={styles.subsection}>{children}</div>;
};

export default Subsection;
