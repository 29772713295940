import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button, TextField, MenuItem } from '@mui/material';
import { useMutation } from '@apollo/client';
import { MUTATION_CREATE_ANALYSIS_TAG } from 'gql';
import { IDropDownOption } from 'types';
import { setAlert } from 'state';

interface AnalysisTagProps {
  handleClose: () => void;
  setLoading: (value: boolean) => void;
  ticketId: string;
  flights: IDropDownOption[];
}

const AnalysisTag: React.FC<AnalysisTagProps> = (props) => {
  const { ticketId, setLoading, handleClose, flights } = props;

  const [createAnalysisTag, { data: dataAnalysisTag, error: errorAnalysisTag, loading: loadingAnalysisTag }] =
    useMutation(MUTATION_CREATE_ANALYSIS_TAG);
  const dispatch = useDispatch();
  const [flightId, setFlightId] = useState('');
  const [flightError, setFlightError] = useState('');

  const onSubmit = async () => {
    if (!flightId) {
      setFlightError('Please select flight');
      return;
    }

    await createAnalysisTag({
      variables: {
        ticketId,
        flightId,
      },
    });
  };

  useEffect(() => {
    if (errorAnalysisTag) {
      dispatch(setAlert('error', 'Unable to create analysis tag'));
    } else if (dataAnalysisTag) {
      if (dataAnalysisTag.createAnalysisTag?.ok) {
        dispatch(setAlert('success', 'Created analysis tag'));
        handleClose();
      } else {
        dispatch(setAlert('error', dataAnalysisTag.createAnalysisTag?.error || 'Unable to create analysis tag'));
      }
    }
  }, [errorAnalysisTag, dataAnalysisTag, dispatch, handleClose]);

  useEffect(() => {
    setLoading(loadingAnalysisTag);
  }, [loadingAnalysisTag, setLoading]);

  return (
    <React.Fragment>
      {!!flights.length && (
        <Box py={1}>
          <TextField
            select
            label="Select Flight"
            name="flight"
            value={flightId}
            onChange={(e) => {
              setFlightId(e.target.value);
              setFlightError('');
            }}
            fullWidth
            error={!!flightError}
            helperText={flightError ? flightError : ''}
            FormHelperTextProps={{
              sx: {
                ml: 0,
              },
            }}
          >
            {flights.map((flight: IDropDownOption, key: number) => {
              return (
                <MenuItem key={key} value={flight.id}>
                  {flight.name}
                </MenuItem>
              );
            })}
          </TextField>
        </Box>
      )}
      <Box
        sx={{
          textAlign: 'right',
        }}
        py={1}
      >
        <Button
          variant="outlined"
          sx={{
            width: '100px',
            mr: 2,
          }}
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          sx={{
            width: '100px',
          }}
          onClick={() => {
            onSubmit();
          }}
        >
          Create
        </Button>
      </Box>
    </React.Fragment>
  );
};

export default AnalysisTag;
