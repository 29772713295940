import React from 'react';
import { NavLink } from 'react-router-dom';
import { Box, Tooltip, Typography, Link } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';

import { PATH_TICKETS_CREATE, PATH_FAQ } from 'const';
import { theme } from 'theme';

const TooltipForUpload: React.FC = () => {
  return (
    <Tooltip
      placement="right"
      arrow
      title={
        <Box p={5}>
          <Typography
            sx={{
              color: 'success.main',
              fontSize: '14px',
              fontWeight: 'bold',
            }}
          >
            Valid files:
          </Typography>
          <Typography
            sx={{
              fontSize: '14px',
              lineHeight: '22px',
              color: 'rgba(75, 84, 112, 0.5)',
            }}
            gutterBottom
          >
            - Original files, as downloaded from your engine monitor.
            <br />
            - Common extensions: CSV, DAT, JPI, LOG, ALD, REC, TAB.
            <br />
            - Zip files containing the above are OK.
            <br />
            - Max file size 65MB.
            <br />- EI UBG-16 and MGL users, read the{' '}
            <Link href={PATH_FAQ} target="_blank">
              FAQ
            </Link>
          </Typography>
          <Typography
            sx={{
              color: 'error.dark',
              fontSize: '14px',
              fontWeight: 'bold',
            }}
          >
            Won't work:
          </Typography>
          <Typography
            sx={{
              fontSize: '14px',
              lineHeight: '22px',
              color: 'rgba(75, 84, 112, 0.5)',
            }}
            gutterBottom
          >
            - Opening files with spreadsheet or other apps alters their format and we cannot read them.
            <br />- Non engine Data files: DOC, EXE, JPG, PDF, PNG, XLS, XLSX, EFL.
          </Typography>
          <Typography
            sx={{
              color: 'text.primary',
              fontSize: '14px',
              fontWeight: 'bold',
            }}
          >
            Supported Engine Monitors:
          </Typography>
          <Typography
            sx={{
              fontSize: '14px',
              lineHeight: '22px',
              color: 'rgba(75, 84, 112, 0.5)',
            }}
            gutterBottom
          >
            - 360 Avionics (ENGOOD)
            <br />
            - Advanced Flight Systems (AF-series).
            <br />
            - Avidyne (R8/R9/EX5000)
            <br />
            - Chelton EFIS.
            <br />
            - E.I. (UBG16, MVP-50, & CGR-30P)
            <br />
            - Dynon (D10/D100-series, SkyView)
            <br />
            - Garmin Series (e.g. G1000, G2000, G3X/G3X Touch, G900X, GI275 and Cirrus Perspective)
            <br />
            - GRT Avionics
            <br />
            - Insight Avionics (GEM 610, Gemini 1200, G2, G3, G4)
            <br />
            - J.P. Instruments (700-,800-,900- series)
            <br />
            - Kanardia
            <br />
            - MGL Avionics
            <br />- Ultra-FEI/Flightline AuRACLE
          </Typography>
          <Typography
            sx={{
              fontSize: '14px',
              lineHeight: '22px',
              color: 'rgba(75, 84, 112, 0.5)',
            }}
          >
            If you have an analyzer that is not listed, or something doesn't work,{' '}
            <NavLink
              to={PATH_TICKETS_CREATE}
              style={{
                color: theme.palette.text.primary,
                fontWeight: 500,
                textDecoration: 'underline',
              }}
            >
              contact us
            </NavLink>
            , and we'll do our best to support it.
          </Typography>
        </Box>
      }
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: theme.palette.background.default,
            border: '1px solid #E8E9EF',
            borderColor: theme.palette.grey['200'],
            boxShadow: '0px 1px 1px rgba(19, 30, 64, 0.06)',
            borderRadius: '6px !important',
            maxWidth: '520px !important',
            padding: '0 !important',
          },
        },
        arrow: {
          color: theme.palette.background.default + ' !important',
        },
      }}
    >
      <HelpIcon />
    </Tooltip>
  );
};

export default TooltipForUpload;
