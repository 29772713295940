import React from 'react';
import { Box, Typography, Grid, IconButton } from '@mui/material';
import { ComponentLoading } from 'components';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import { ISubscription } from 'types';

import { SubscriptionInfo, SubscriptionActions } from './';

interface DetailProps {
  loading: boolean;
  subscription: ISubscription | undefined;
  setSlideNum?: (value: number) => void;
  contactId: string;
}

const Detail: React.FC<DetailProps> = (props) => {
  const { subscription, loading, setSlideNum, contactId } = props;

  if (!subscription) return null;

  return (
    <Box>
      <ComponentLoading loading={loading}>
        <Box
          sx={{
            px: {
              xs: 1,
              md: 2,
              lg: 4,
            },
            py: {
              xs: 2,
              lg: 0,
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                display: {
                  lg: 'none',
                  xs: 'block',
                },
              }}
            >
              <IconButton
                sx={{
                  '&:hover': {
                    borderRadius: '6px',
                  },
                  borderRadius: '6px',
                  boxShadow: '0px 1px 1px rgba(19, 30, 64, 0.05)',
                  border: '1px solid',
                  borderColor: 'grey.200',
                  backgroundColor: 'background.default',
                }}
                onClick={() => {
                  if (setSlideNum) {
                    setSlideNum(1);
                  }
                }}
              >
                <ChevronLeftIcon />
              </IconButton>
            </Box>
            <Typography variant="h2">
              {subscription?.serviceName} Subscription for {subscription?.aircraft?.registration}
            </Typography>
          </Box>
          <Box py={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={6}>
                <SubscriptionInfo subscription={subscription} />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <SubscriptionActions subscription={subscription} contactId={contactId} />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </ComponentLoading>
    </Box>
  );
};

export default Detail;
