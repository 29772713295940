import React, { useState, useEffect, Fragment } from 'react';

import { Dialog, DialogContent, DialogActions, Typography, Button, Grid, Box, CircularProgress, MenuItem } from '@mui/material';

import { ButtonSubmit } from 'components';

import { AircraftSuggestions } from './';

import { useFuzzyModels } from 'hooks';

import { IOptionIdName, IModelAdditionalFields } from 'types';

import { useInputWithSuggestions } from 'hooks';

import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-mui';

interface IDialogAddAircraftSuggestions {
  manufacturer: IOptionIdName; // Autocomplete value
  model: IOptionIdName; // Autocomplete value
  setModel: (value: IOptionIdName | null) => void; // Set Autocomplete value
  setManufacturer: (value: IOptionIdName | null) => void; // Set Autocomplete value
  open: boolean;
  setOpen: (value: boolean) => void;
  setAdditionalFields: (value: IModelAdditionalFields | null) => void;
}

const validationSchema = Yup.object({
  model: Yup.string().min(1, 'Too short: 1-50 characters required').max(50, 'Too long: 1-50 characters required').required('Required'),
  certification: Yup.string().required('Required'),
  propulsion: Yup.string().required('Required'),
  engine: Yup.string().required('Required'),
});

interface createFormValues {
  model: string;
  certification: string;
  propulsion: string;
  engine: string;
}

const DialogAddAircraftModelSuggestions: React.FC<IDialogAddAircraftSuggestions> = (props) => {
  const { manufacturer, model, setModel, open, setOpen, setAdditionalFields } = props;

  const [addState, setAddState] = useState(false);

  const [modelInput, setModelInput] = useState('');
  const [modelInputTmp, setModelInputTmp] = useState('');
  // Model Logic
  const { showFuzzy: showFuzzyModels } = useInputWithSuggestions({ input: modelInput });

  const handleSuggestionClickModelHelper = (suggestion: IOptionIdName) => {
    setModel(suggestion);
    handleClose();
  };

  useEffect(() => {
    if (model?.id) return;
    setModelInput(model?.name ? model.name : '');
  }, [model, setModelInput]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!modelInputTmp) {
      setModelInput('');
      setLoading(false);
      return;
    }

    setLoading(true);

    const timeout: any = setTimeout(() => {
      setModelInput(modelInputTmp);
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [modelInputTmp, setModelInput]);

  // Fuzzy Logic
  const fuzzyModels = useFuzzyModels({ model, manufacturerId: manufacturer?.id, modelInput }); // Fuzzy Models

  const handleClose = () => {
    setOpen(false);
    setAddState(false);
  };

  React.useEffect(() => {
    setLoading(false);
    if (!fuzzyModels.length) {
      setLoading(false);
      setAddState(true);
    } else setAddState(false);
  }, [fuzzyModels]);

  const onSubmit = (values: createFormValues) => {
    setModel({
      id: '',
      name: values.model,
    });

    setAdditionalFields({
      certification: values.certification,
      propulsion: values.propulsion,
      engine: values.engine,
    });

    handleClose();
  };

  const notListClick = () => {
    setAddState(true);
  };

  return (
    <Dialog
      open={open}
      fullWidth
      PaperProps={{
        style: {
          height: '550px',
        },
      }}
    >
      <Formik
        initialValues={{
          model: model?.name ? model.name : '',
          certification: '',
          propulsion: '',
          engine: '',
        }}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, handleChange, handleBlur }) => {
          return (
            <Form
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <DialogContent>
                <Typography variant="h2" gutterBottom align="center">
                  Add model for
                  <Box color="primary.main" component="span">
                    {manufacturer?.name}
                  </Box>
                </Typography>

                <Field
                  name="model"
                  label="Aircraft Model *"
                  component={TextField}
                  onBlur={handleBlur}
                  onChange={(e: any) => {
                    handleChange(e);
                    setModelInputTmp(e.target.value);
                  }}
                  placeholder={'Start typing to search...'}
                  fullWidth
                  autoFocus
                  variant="outlined"
                  inputProps={{ maxLength: 50 }}
                  FormHelperTextProps={{
                    style: {
                      color: 'red',
                      marginLeft: 0,
                    },
                  }}
                />
                {loading && <CircularProgress color="inherit" size={20} variant="indeterminate" />}
                {!loading && !addState && showFuzzyModels && (
                  <AircraftSuggestions
                    label="Did you perhaps mean:"
                    suggestions={fuzzyModels}
                    handleSuggestionClick={handleSuggestionClickModelHelper}
                    notListLabel="My aircraft model is not listed."
                    notListClick={notListClick}
                  />
                )}
                {addState && (
                  <Fragment>
                    <Field
                      name="certification"
                      id="mui-component-select-certification"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      component={TextField}
                      label="Certification"
                      select
                      variant="outlined"
                      fullWidth
                      FormHelperTextProps={{
                        style: {
                          color: 'red',
                          marginLeft: 0,
                        },
                      }}
                    >
                      <MenuItem value={'certificated'}>Certificated</MenuItem>
                      <MenuItem value={'experimental'}>Experimental</MenuItem>
                    </Field>
                    <Field
                      name="propulsion"
                      id="mui-component-select-propulsion"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      component={TextField}
                      label="Propulsion"
                      select
                      variant="outlined"
                      fullWidth
                      FormHelperTextProps={{
                        style: {
                          color: 'red',
                          marginLeft: 0,
                        },
                      }}
                    >
                      <MenuItem value={'piston'}>Piston</MenuItem>
                      <MenuItem value={'turbine'}>Turbine</MenuItem>
                      <MenuItem value={'jet'}>Jet</MenuItem>
                    </Field>
                    <Field
                      name="engine"
                      id="mui-component-select-engine"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      component={TextField}
                      label="Engine(s)"
                      select
                      variant="outlined"
                      fullWidth
                      FormHelperTextProps={{
                        style: {
                          color: 'red',
                          marginLeft: 0,
                        },
                      }}
                    >
                      <MenuItem value={'single'}>Single</MenuItem>
                      <MenuItem value={'twin'}>Twin</MenuItem>
                    </Field>
                  </Fragment>
                )}
              </DialogContent>

              <DialogActions>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Button color="secondary" variant="outlined" onClick={handleClose} fullWidth>
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <ButtonSubmit text="Add" loading={isSubmitting} disabled={!addState} />
                  </Grid>
                </Grid>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default DialogAddAircraftModelSuggestions;
