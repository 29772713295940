import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Typography, Button, Dialog, DialogTitle, DialogContent, TextField, MenuItem } from '@mui/material';

import { ComponentLoading } from 'components';

import { QUERY_GET_USERS_AIRCRAFT_FOR_SUBSCRIPTION, MUTATION_TRANSFER_SUBSCRIPTION_TO_OWNED_AIRCRAFT_FOR_ADMIN } from 'gql';
import { useLazyQuery, useMutation } from '@apollo/client';

import { ISubscriptionDetail } from 'types';
import { setAlert } from 'state';

import { aircraftRegistrationMakeModel } from 'helpers';

interface TransferProps {
  subscription: ISubscriptionDetail;
  contactId: string;
}

const Transfer: React.FC<TransferProps> = (props) => {
  const { subscription, contactId } = props;
  const dispatch = useDispatch();

  const [fetchAircraft, { data: dataAircraft, error: errorAircraft, loading: loadingAircraft }] = useLazyQuery(
    QUERY_GET_USERS_AIRCRAFT_FOR_SUBSCRIPTION,
  );

  const [transferSubscription, { data: dataTransferSubscription, error: errorTransferSubscription, loading: loadingTransferSubscription }] =
    useMutation(MUTATION_TRANSFER_SUBSCRIPTION_TO_OWNED_AIRCRAFT_FOR_ADMIN);

  const [showModal, setShowModal] = useState(false);

  const [targetAircraftId, setTargetAircraftId] = useState('');
  const [targetAircraftError, setTargetAircraftError] = useState('');

  const handleClose = () => {
    setShowModal(false);
  };

  const onSubmit = async () => {
    if (!targetAircraftId) {
      setTargetAircraftError('Please select new aircraft to transfer subscription');
      return;
    }

    await transferSubscription({
      variables: {
        sourceAircraftId: subscription.aircraft.id,
        targetAircraftId,
      },
    });
  };

  useEffect(() => {
    if (showModal) {
      fetchAircraft({
        variables: {
          contactId,
        },
      });
    }
  }, [showModal, fetchAircraft, contactId]);

  useEffect(() => {
    if (errorAircraft) {
      dispatch(setAlert('error', "Unable to load this contact's aircraft"));
    }
  }, [errorAircraft, dispatch]);

  useEffect(() => {
    if (errorTransferSubscription) {
      dispatch(setAlert('error', 'Unable to transfer this subscription to selected aircraft'));
    } else if (dataTransferSubscription) {
      if (dataTransferSubscription.transferSubscriptionsToOwnedAircraft?.ok) {
        dispatch(setAlert('success', 'Transferred subscription to new aircraft'));
        setShowModal(false);
      } else {
        dispatch(
          setAlert(
            'error',
            dataTransferSubscription.transferSubscriptionsToOwnedAircraft?.error ||
              'Unable to transfer this subscription to selected aircraft',
          ),
        );
      }
    }
  }, [errorTransferSubscription, dataTransferSubscription, dispatch]);

  return (
    <Box>
      <Box>
        <Typography
          variant="h3"
          sx={{
            color: 'error.main',
            fontWeight: 'bold',
          }}
        >
          Move subscription to another aircraft
        </Typography>
        <Box py={1}>
          <Typography>This is used to transfer a subscription from one aircraft to another.</Typography>
        </Box>
        <Box
          py={1}
          sx={{
            textAlign: 'left',
          }}
        >
          <Button
            variant="contained"
            onClick={() => {
              setShowModal(true);
            }}
          >
            Move subscription to another aircraft
          </Button>
        </Box>
      </Box>
      <Dialog
        open={showModal}
        fullWidth
        onClose={handleClose}
        PaperProps={{
          sx: {
            maxWidth: '560px',
          },
        }}
      >
        <DialogTitle
          sx={{
            textAlign: 'center',
            fontSize: '24px',
            fontWeight: 700,
          }}
        >
          Change aircraft for subscription: {subscription.id}
        </DialogTitle>
        <DialogContent
          sx={{
            px: {
              md: 5,
              xs: 2,
            },
          }}
        >
          <ComponentLoading loading={loadingAircraft || loadingTransferSubscription}>
            <Box py={1}>
              <Typography
                variant="body1"
                align="left"
                sx={{
                  fontWeight: 'bold',
                }}
                gutterBottom
              >
                Current Aircraft: {aircraftRegistrationMakeModel(subscription.aircraft)}
              </Typography>
              {!!dataAircraft?.savvy?.contacts[0]?.aircraft.length && (
                <TextField
                  select
                  label="New aircraft *"
                  value={targetAircraftId}
                  onChange={(e) => {
                    setTargetAircraftId(e.target.value);
                    setTargetAircraftError('');
                  }}
                  fullWidth
                  sx={{
                    mb: 0,
                  }}
                  error={!!targetAircraftError}
                  helperText={targetAircraftError}
                  FormHelperTextProps={{
                    sx: {
                      ml: 0,
                    },
                  }}
                >
                  {dataAircraft?.savvy?.contacts[0]?.aircraft?.map((aircraft: any, key: number) => {
                    if (aircraft.id === subscription.aircraft.id) {
                      return null;
                    }
                    return (
                      <MenuItem key={key} value={aircraft.id}>
                        {aircraftRegistrationMakeModel(aircraft)}
                      </MenuItem>
                    );
                  })}
                </TextField>
              )}
            </Box>
            <Box
              sx={{
                textAlign: 'right',
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  width: '100px',
                }}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                variant="contained"
                sx={{
                  ml: 2,
                }}
                onClick={() => {
                  onSubmit();
                }}
              >
                Change
              </Button>
            </Box>
          </ComponentLoading>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default Transfer;
