import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button, TextField, MenuItem } from '@mui/material';
import { useMutation } from '@apollo/client';
import { MUTATION_CREATE_PARSE_FAILURE_TAG } from 'gql';
import { IDropDownOption } from 'types';
import { setAlert } from 'state';

interface ParseFailureTagProps {
  handleClose: () => void;
  setLoading: (value: boolean) => void;
  ticketId: string;
  files: IDropDownOption[];
}

const ParseFailureTag: React.FC<ParseFailureTagProps> = (props) => {
  const { ticketId, setLoading, handleClose, files } = props;

  const [createParseFailureTag, { data: dataParseFailureTag, error: errorParseFailureTag, loading: loadingParseFailureTag }] =
    useMutation(MUTATION_CREATE_PARSE_FAILURE_TAG);
  const dispatch = useDispatch();
  const [fileId, setFileId] = useState('');
  const [fileError, setFileError] = useState('');

  const onSubmit = async () => {
    if (!fileId) {
      setFileError('Please select file');
      return;
    }

    await createParseFailureTag({
      variables: {
        ticketId,
        fileId,
      },
    });
  };

  useEffect(() => {
    if (errorParseFailureTag) {
      dispatch(setAlert('error', 'Unable to create parse failure tag'));
    } else if (dataParseFailureTag && dataParseFailureTag.createParseFailureTag?.ok) {
      dispatch(setAlert('success', 'Created parse failure tag'));
      handleClose();
    }
  }, [errorParseFailureTag, dataParseFailureTag, dispatch, handleClose]);

  useEffect(() => {
    setLoading(loadingParseFailureTag);
  }, [loadingParseFailureTag, setLoading]);

  return (
    <React.Fragment>
      {!!files.length && (
        <Box py={1}>
          <TextField
            select
            label="Select File"
            name="file"
            value={fileId}
            onChange={(e) => {
              setFileId(e.target.value);
              setFileError('');
            }}
            fullWidth
            error={!!fileError}
            helperText={fileError ? fileError : ''}
            FormHelperTextProps={{
              sx: {
                ml: 0,
              },
            }}
          >
            {files.map((file: IDropDownOption, key: number) => {
              return (
                <MenuItem key={key} value={file.id}>
                  {file.name}
                </MenuItem>
              );
            })}
          </TextField>
        </Box>
      )}
      <Box
        sx={{
          textAlign: 'right',
        }}
        py={1}
      >
        <Button
          variant="outlined"
          sx={{
            width: '100px',
            mr: 2,
          }}
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          sx={{
            width: '100px',
          }}
          onClick={() => {
            onSubmit();
          }}
        >
          Create
        </Button>
      </Box>
    </React.Fragment>
  );
};

export default ParseFailureTag;
