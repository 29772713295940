import React from 'react';
import { Field } from 'formik';
import { TextField } from 'formik-mui';

interface MuiFormikInputInterface {
  name: string;
  label: string;
  errors: any;
  touched: any;
  type?: string;
  onChange: (event: any) => void;
  onBlur?: (event: any) => void;
  maxLength?: number;
  disabled?: boolean;
  multiline?: boolean;
  rows?: number;
  placeholder?: string;
  noMargin?: boolean;
  readOnly?: boolean;
}

// Input UI
const MuiFormikInput: React.FC<MuiFormikInputInterface> = (props) => {
  const {
    name,
    label,
    onBlur,
    onChange,
    errors,
    touched,
    maxLength = 50,
    type = 'text',
    noMargin,
    disabled = false,
    readOnly = false,
    ...rest
  } = props;

  return (
    <Field
      name={name}
      label={label}
      component={TextField}
      sx={{
        mb: noMargin ? '0px' : '15px',
      }}
      type={type}
      onBlur={onBlur}
      onChange={onChange}
      errors={errors}
      touched={touched}
      variant="outlined"
      fullWidth={true}
      FormHelperTextProps={{
        style: {
          color: 'red',
          marginLeft: '0',
        },
      }}
      inputProps={{
        maxLength: maxLength,
        readOnly,
      }}
      disabled={disabled}
      {...rest}
    />
  );
};

export default MuiFormikInput;
