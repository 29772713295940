import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography, Popover, MenuList, MenuItem } from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { IFlightChartSetting } from 'types';

import { useMutation } from '@apollo/client';
import { MUTATION_SET_FLIGHT_CHART_SETTINGS } from 'gql';

interface ChartSizeSettingsProps {
  chartNum: number;
  settings: IFlightChartSetting | undefined;
}

const ChartSizeSettings: React.FC<ChartSizeSettingsProps> = (props) => {
  const { chartNum, settings } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [size, setSize] = useState(settings?.chartsSize ? settings?.chartsSize : 'fitChart');

  const [setChartsSetting] = useMutation(MUTATION_SET_FLIGHT_CHART_SETTINGS);

  const { loggedIn } = useSelector((state: any) => state.auth);

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const sizeArray = [
    {
      value: 'fitPage',
      label: 'Fit Page',
    },
    {
      value: 'fitChart',
      label: 'Fit Charts',
    },
    {
      value: 'tall',
      label: 'Tall Size',
    },
    {
      value: 'medium',
      label: 'Medium Size',
    },
    {
      value: 'short',
      label: 'Short Size',
    },
  ];

  const changeSizeToString = (size: string) => {
    for (const sizeElement of sizeArray) {
      if (sizeElement.value === size) return sizeElement.label;
    }
  };

  const changeChartSize = (size: string) => {
    const chartContainer = document.getElementById('chart-container');

    if (size === 'fitPage') {
      window.scroll({ top: 70, behavior: 'smooth' });
    } else if (size === 'fitChart') {
      window.scroll({ top: chartContainer ? chartContainer.offsetTop : 0, behavior: 'smooth' });
    }

    setSize(size);
  };

  useEffect(() => {
    if (chartNum && size && loggedIn) {
      setChartsSetting({
        variables: {
          chartsNumber: chartNum.toString(),
          chartsSize: size,
        },
      });
    }
  }, [setChartsSetting, chartNum, size, loggedIn]);

  return (
    <React.Fragment>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          pl: 1,
        }}
        onClick={handleClick}
      >
        <FullscreenIcon
          sx={{
            mr: '10px',
            color: 'grey.600',
          }}
        />
        <Typography>{changeSizeToString(size)}</Typography>
        <ArrowDropDownIcon
          sx={{
            color: 'text.secondary',
            width: '15px',
            height: '15px',
          }}
        />
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <MenuList>
          {sizeArray.map((sizeElement, key: number) => {
            return (
              <MenuItem
                key={key}
                onClick={() => {
                  changeChartSize(sizeElement.value);
                  handleClose();
                }}
              >
                <Typography
                  sx={{
                    color: 'text.secondary',
                  }}
                >
                  {sizeElement.label}
                </Typography>
              </MenuItem>
            );
          })}
        </MenuList>
      </Popover>
    </React.Fragment>
  );
};

export default ChartSizeSettings;
