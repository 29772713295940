import React, { Fragment } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { isIE, isChrome, isSafari, isEdge, isFirefox, isOpera, browserVersion } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import { setAlert } from 'state';

import {
  SignupFree,
  SignupService,
  SignupServicePayment,
  SignupServiceAdditionalInfo,
  Login,
  NotFound,
  Dashboard,
  ResetPassword,
  Flights,
  Account,
  Enroll,
  TechnicianPage,
  ServiceCenterMapPage,
  SignupCompleteAdditionalInfo,
  Aircrafts,
  TicketsCreate,
  SelectTicketType,
  FileUploads,
  AircraftCreate,
  Reports,
  Plans,
  FlightView,
  SharedFlightView,
  Tickets,
  LoginWithEmail,
  LoginWithCode,
  AircraftList,
  UpgradePlan,
  WelcomePage,
  VerifyEmail,
  ForgotPassword,
  TechDashboard,
  Legal,
  TicketRate,
  SharedFlightsByAircraft,
  SharedFlightsByContact,
  SharedLogs,
  UpgradeAnalysisPro,
  UpgradeQa,
  SecurityCheck,
  SetCookieFromToken,
  ClientCohorts,
  ViewStagedEngineDataFile,
  CreateAppToken,
  FlightsByFile,
  VerifyServiceCenter,
  UpdateServiceCenter,
  UpdateTechnician,
  VerifyTechnician,
  BorescopeUpload,
  CreateBaReportsWithPermission,
  CreateOwnBorescopeAnalysisReports,
} from 'pages';

import { Auth, Alerts } from 'components';

import {
  PrivateRoute,
  RedirectLoggedIn,
  RedirectStepLoggedIn,
  PrivateTicket,
  PrivateSavvyRoute,
  PrivateTechRoute,
  BetaSwitchRoute,
} from './components';

import {
  PATH_ENROLL,
  PATH_SIGNUP_FREE,
  PATH_SIGNUP_SERVICE,
  PATH_SIGNUP_SERVICE_LEGACY,
  PATH_SIGNUP_SERVICE_PAYMENT,
  PATH_LOGIN,
  PATH_RESET_PASSWORD,
  PATH_FLIGHTS,
  PATH_ACCOUNT,
  PATH_SIGNUP_SERVICE_ADDITIONAL_INFO,
  PATH_INTERNAL_TECHNICIAN,
  PATH_INTERNAL_SERVICE_CENTER,
  PATH_INTERNAL_TICKETRATINGS,
  PATH_SERVICE_CENTER_MAP,
  PATH_SIGNUP_COMPLETE_ADDITIONAL_INFO,
  PATH_INTERNAL_SERVICE_CENTER_RATING,
  PATH_TICKETS_VIEW,
  PATH_TICKETS_CREATE,
  PATH_AIRCRAFT_VIEW,
  PATH_BETA_DASHBOARD,
  PATH_FLIGHTS_UPLOAD,
  PATH_AIRCRAFT_CREATE,
  PATH_REPORTS_BETA,
  PATH_BETA_PLANS,
  PATH_FLIGHT_VIEW,
  PATH_SHARED_FLIGHT_VIEW,
  PATH_INTERNAL_DASHBOARD,
  PATH_SAVVY_TICKETS,
  PATH_SAVVY_TICKETS_VIEW,
  PATH_PERSONAL_TICKET_DASHBOARD,
  PATH_LOGIN_WITH_EMAIL,
  PATH_LOGIN_WITH_CODE,
  PATH_SERVICE_CENTER,
  PATH_CONTACT,
  PATH_SAVVY_AIRCRAFT_FLIGHTS,
  PATH_TICKETS_DASHBOARD,
  PATH_FILTERED_TICKETS,
  PATH_RENEWAL_TICKETS,
  PATH_UESR_TICKET_CREATE,
  PATH_UESR_TICKET_CREATE_TYPE,
  PATH_SAVVY_AIRCRAFT,
  PATH_UNASSIGNED_TICKETS,
  PATH_SERVICE_CENTER_CREATE,
  PATH_SERVICE_CENTER_MANUAL_RATING,
  PATH_SAVVY_ACCOUNT,
  PATH_SAVVY_SEARCH,
  PATH_SAVVY_LOG_BACKFILL,
  PATH_AIRCRAFT_LIST,
  PATH_SAVVY_COI_MANAGEMENT,
  PATH_BETA_UPGRADE_PLAN,
  PATH_WELCOME,
  PATH_VERIFY_EMAIL,
  PATH_FORGOT_PASSWORD,
  PATH_SC_DASHBOARD,
  PATH_SC_FLIGHTS,
  PATH_MANAGER_CONFLICTS,
  PATH_SAVVY_COHORT_REPORTS,
  PATH_SAVVY_TIMESHEETS_DASHBOARD,
  PATH_SAVVY_TICKETS_UNWATCH,
  PATH_CREATE_TECHNICIAN,
  PATH_SAVVY_AIRCRAFT_CREATE,
  PATH_PREBUY_INQUIRY_SIGNUP,
  PATH_ACTIVE_PREBUY,
  PATH_FEVA_ALERTS,
  PATH_ANALYSIS_DASHBOARD,
  PATH_FLIGHT_REPORT,
  PATH_SAVVY_SEARCH_TICKET,
  PATH_SAVVY_SERACH_CONTACT,
  PATH_SAVVY_SEARCH_AIRCRAFT,
  PATH_SAVVY_SEARCH_SUBSCRIPTION,
  PATH_SAVVY_SEARCH_SERVICE_CENTER,
  PATH_SAVVY_SEARCH_FLIGHT_REPORT,
  PATH_SAVVY_SEARCH_PREBUY_INQUIRY,
  PATH_SAVVY_TIMESHEETS_RATE,
  PATH_FEVA_VIEW,
  PATH_SAVVY_OOP,
  PATH_SAVVY_KEY_RESULTS_REPORT,
  PATH_SAVVY_CANNED_RESPONSES,
  PATH_SAVVY_FUTURE_COHORTS,
  PATH_LEGAL,
  PATH_SAVVY_PREBUY_INQUIRY,
  PATH_SAVVY_COHORTS,
  PATH_SAVVY_SUBMITTED_AIRCRAFT_DATA,
  PATH_SAVVY_ENGINE_PARAMETERS,
  PATH_SAVVY_FINANCIALS,
  PATH_TICKET_RATE,
  PATH_SHARE_ALL_FLIGHTS,
  PATH_SHARE_ALL_FLIGHTS_BY_CONTACT,
  PATH_SHARE_LOGS,
  PATH_ADD_ANALYSIS_PRO_PLAN,
  PATH_ADD_QA_PLAN,
  PATH_UPGRADE_QA_PLAN,
  PATH_SECURITY_CHECK,
  PATH_CREATE_PREBUY_CONFLICT_TICKET,
  PATH_VERIFY_SECURITY_TOKEN,
  PATH_COHORTS,
  PATH_STAGED_ENGINE_DATA_FILES,
  PATH_REQUEST_TOKEN,
  PATH_VERIFY_SERVICE_CENTER_FOR_TECH,
  PATH_UPDATE_SERVICE_CENTER_FOR_TECH,
  PATH_UPDATE_TECHNICIAN,
  PATH_TICKETS,
  PATH_VERIFY_TECHNICIAN,
  PATH_SAVVY_TECHS_WITH_UPDATE_REQUEST,
  PATH_BORESCOPE_UPLOAD,
  PATH_SAVVY_BORESCOPE_ANALYSIS_REPORT_GENERATE,
  PATH_SAVVY_BORESCOPE_ANALYSIS_REPORT_VIEW,
  PATH_SAVVY_SEARCH_AIRCRAFT_TYPE,
  PATH_CREATE_BORESCOPE_REPORT,
  PATH_CREATE_OWN_BORESCOPE_REPORT,
  PATH_MAKE_PAYMENTS,
  PATH_POSTMARK_TEMPLATES,
  PATH_API_TEMPLATES,
} from 'const';

import {
  LazySavvyTickets,
  LazyServiceCenterPage,
  LazyInternalDashboard,
  LazyPersonalTicketDashboard,
  LazyServiceCentersList,
  LazyEditContact,
  LazyContactFlight,
  LazySavvySubscriptions,
  LazyTicketsDashboard,
  LazyFilteredTicketsPage,
  LazyRenewalDashboard,
  LazyRenewalTicketsPage,
  LazySavvyFileUploads,
  LazySelectUserTicketType,
  LazyUserTicketCreate,
  LazySavvyAircraft,
  LazyUnassignedTicketsPage,
  LazyServiceCenterView,
  LazyServiceCenterManualRatingPage,
  LazySavvyAccount,
  LazyAdvancedSearch,
  LazySavvyBackfillLogPage,
  LazyManagementCoiPage,
  LazyManagerConflicts,
  LazyCohortReports,
  LazyTechnicianCreate,
  LazyTimesheetOverview,
  LazySavvyAircraftCreate,
  LazyActivePrebuy,
  LazyFevaAlerts,
  LazyFevaAlertView,
  LazyAnalysisDashboard,
  LazyCreateAnalysisReport,
  LazyAnalysisReports,
  LazyTimesheetRates,
  LazyTicketSearch,
  LazyContactSearch,
  LazyAircraftSearch,
  LazySubscriptionSearch,
  LazyServiceCenterSearch,
  LazyFlightReportSearch,
  LazyPrebuyInquirySearch,
  LazyOopList,
  LazyKeyResultsReport,
  LazyCannedResponses,
  LazyFutureCohorts,
  LazyPrebuyInquiry,
  LazyCohorts,
  LazyViewCohort,
  LazyFinancials,
  LazyAircraftSubmittedData,
  LazyViewSubmittedData,
  LazyPowerModelList,
  LazyCreatePowerModel,
  LazyViewPowerModel,
  LazyEditPowerModel,
  LazyCreatePrebuyConflictTicket,
  LazyServiceCenterRatingPage,
  LazyTicketRatingPage,
  LazyTechsWithUpdateRequest,
  LazyCreateBorescopeAnalysisReport,
  LazySavvyBorescopeReport,
  LazySearchAircraftByType,
  LazyMakePayments,
  LazyPostmark,
  LazyAPItemplate,
} from './LazyLoader';

import { PageLoading } from 'components';

// Advice: better route be a page, not component. It gives better organizing and fast finding and changing of components
const Router: React.FC = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (isIE) {
      dispatch(
        setAlert('warning', 'Internet Explorer is not supported. We support recent versions of Safari, Firefox, Edge, Opera and Chrome.'),
      );
    }
    if (isChrome && parseFloat(browserVersion) < 70) {
      dispatch(setAlert('warning', `Chrome v${browserVersion} is not supported. Please upgrade to version 70 or higher.`)); // Chrome v70 released on Oct 16, 2018
    }
    if (isSafari && parseFloat(browserVersion) < 11) {
      dispatch(setAlert('warning', `Safari v${browserVersion} is not supported. Please upgrade to version 11 or higher.`)); //safari 11 released on Sep 19, 2017
    }
    if (isFirefox && parseFloat(browserVersion) < 60) {
      dispatch(setAlert('warning', `Firefox v${browserVersion} is not supported. Please upgrade to version 60 or higher.`)); // Firefox v60 released on May 9, 2018
    }
    if (isEdge && parseFloat(browserVersion) < 79) {
      dispatch(setAlert('warning', `Edge v${browserVersion} is not supported. Please upgrade to version 79 or higher.`)); // Edge used new engine from v79. Jan 15, 2020
    }
    if (isOpera && parseFloat(browserVersion) < 50) {
      dispatch(setAlert('warning', `Opera v${browserVersion} is not supported. Please upgrade to version 50 or higher.`)); // Opera v50 released on Jan 4, 2018
    }
  }, [dispatch]);

  return (
    <Fragment>
      {/* Check is the user loggedIn on each page reload */}
      <Auth />

      <BrowserRouter>
        {/* App alerts - success, error, etc. Should be inside BrowserRouter to use react router Link */}
        <Alerts />
        <React.Suspense fallback={<PageLoading />}>
          <Switch>
            {/* Entry page */}
            {/*<RedirectLoggedIn exact path={PATH_HOME} component={SignupFree} />*/}

            {/* Enroll */}
            <Route exact path={PATH_ENROLL} component={Enroll} />
            <BetaSwitchRoute path={'/beta'} />
            <BetaSwitchRoute path={'/shared'} />
            <BetaSwitchRoute path={'/profile'} />
            <Route exact={false} path={'/beta'} render={() => <Redirect to={window.location.pathname.replace('/beta', '')} />} />

            {/* Signup Free */}
            <RedirectLoggedIn exact path={PATH_SIGNUP_FREE} component={SignupFree} />
            <RedirectLoggedIn exact path={PATH_PREBUY_INQUIRY_SIGNUP} component={SignupFree} />

            {/* Signup Service */}
            <RedirectLoggedIn exact path={PATH_SIGNUP_SERVICE} component={SignupService} />
            <Route exact path={PATH_SIGNUP_SERVICE_LEGACY} component={SignupService} />
            <Route exact path={PATH_SIGNUP_SERVICE_PAYMENT} component={SignupServicePayment} />
            <Route exact path={PATH_SIGNUP_SERVICE_ADDITIONAL_INFO} component={SignupServiceAdditionalInfo} />
            <Route exact path={`${PATH_VERIFY_EMAIL}/:uuid`} component={VerifyEmail} />
            <Route exact path={`${PATH_FORGOT_PASSWORD}/:token`} component={ForgotPassword} />
            <Route exact path={PATH_LEGAL} component={Legal} />
            <Route exact path={PATH_SECURITY_CHECK} component={SecurityCheck} />
            <Route exact path={PATH_VERIFY_SECURITY_TOKEN} component={SetCookieFromToken} />
            <PrivateRoute exact path={PATH_SIGNUP_COMPLETE_ADDITIONAL_INFO} component={SignupCompleteAdditionalInfo} />

            {/* Login */}
            <RedirectStepLoggedIn exact path={PATH_LOGIN} component={Login} />
            <RedirectLoggedIn exact path={PATH_RESET_PASSWORD} component={ResetPassword} />
            <RedirectLoggedIn exact path={PATH_LOGIN_WITH_EMAIL} component={LoginWithEmail} />
            <RedirectLoggedIn exact={false} path={`${PATH_LOGIN_WITH_CODE}/:uuid`} component={LoginWithCode} />

            <PrivateRoute exact path={PATH_REQUEST_TOKEN} component={CreateAppToken} />

            {/* Internal */}
            {/* TODO: Shouldn't exact be defaulting to False? */}
            <PrivateSavvyRoute exact={true} path={`${PATH_CREATE_TECHNICIAN}/:id`} component={LazyTechnicianCreate} />
            <PrivateSavvyRoute exact={false} path={PATH_INTERNAL_TECHNICIAN} component={TechnicianPage} />
            <PrivateSavvyRoute exact={false} path={PATH_SERVICE_CENTER_MAP} component={ServiceCenterMapPage} />

            {/* Temp Route */}
            {/* Dashboard */}
            <PrivateRoute exact path={PATH_BETA_DASHBOARD} component={Dashboard} />

            {/* Flights */}
            <PrivateRoute exact path={`${PATH_SHARE_LOGS}/:id`} component={SharedLogs} />
            <Route exact path={`${PATH_SHARED_FLIGHT_VIEW}/:id/:uuid`} component={SharedFlightView} />
            <Route exact path={`${PATH_SHARE_ALL_FLIGHTS}/:uuid`} component={SharedFlightsByAircraft} />
            <Route exact path={`${PATH_SHARE_ALL_FLIGHTS_BY_CONTACT}/:uuid`} component={SharedFlightsByContact} />
            <PrivateRoute exact={true} path={`${PATH_STAGED_ENGINE_DATA_FILES}`} component={ViewStagedEngineDataFile} />
            <PrivateRoute exact={true} path={`${PATH_STAGED_ENGINE_DATA_FILES}/:id`} component={ViewStagedEngineDataFile} />
            <PrivateRoute exact={true} path={PATH_COHORTS} component={ClientCohorts} />
            <PrivateRoute exact={true} path={`${PATH_REPORTS_BETA}`} component={Reports} />
            <PrivateRoute exact={true} path={`${PATH_REPORTS_BETA}/:id`} component={Reports} />
            <PrivateRoute exact={true} path={`${PATH_REPORTS_BETA}/:id/:reportType/:reportId`} component={Reports} />
            <PrivateRoute exact path={PATH_FLIGHTS} component={Flights} />
            <PrivateRoute exact={true} path={`${PATH_FLIGHTS}/aircraft/:aircraftId`} component={Flights} />
            <PrivateRoute exact={false} path={`${PATH_FLIGHTS}/aircraft/:aircraftId/files/:engineDataFileId`} component={FlightsByFile} />
            <PrivateRoute exact={false} path={`${PATH_FLIGHT_VIEW}/:id`} component={FlightView} />

            {/* Tickets */}
            <PrivateRoute exact={true} path={`${PATH_TICKET_RATE}/:uuid`} component={TicketRate} />
            <PrivateTicket exact={true} path={PATH_TICKETS} component={Tickets} />
            <PrivateTicket exact={false} path={`${PATH_TICKETS_VIEW}/:id`} component={Tickets} />
            <PrivateTicket exact={true} path={PATH_SAVVY_TICKETS} component={LazySavvyTickets} />
            <PrivateTicket exact={false} path={`${PATH_SAVVY_TICKETS_VIEW}/:id`} component={LazySavvyTickets} />
            <PrivateTicket exact={false} path={`${PATH_SAVVY_TICKETS_UNWATCH}/:id`} component={LazySavvyTickets} />
            <PrivateRoute exact={true} path={PATH_TICKETS_CREATE} component={SelectTicketType} />
            <PrivateRoute exact={true} path={`${PATH_TICKETS_CREATE}/:type`} component={TicketsCreate} />
            <PrivateRoute exact={false} path={`${PATH_TICKETS_CREATE}/:type/:id`} component={TicketsCreate} />

            <PrivateRoute exact={true} path={PATH_AIRCRAFT_LIST} component={AircraftList} />

            {/* Client aircraft tabs */}
            <PrivateRoute exact={true} path={`${PATH_AIRCRAFT_VIEW}/:id`} component={Aircrafts} />
            <PrivateRoute exact={true} path={`${PATH_AIRCRAFT_VIEW}/:id/unit-conversions`} component={Aircrafts} />
            <PrivateRoute exact={true} path={`${PATH_AIRCRAFT_VIEW}/:id/mdr`} component={Aircrafts} />
            <PrivateRoute exact={true} path={`${PATH_AIRCRAFT_VIEW}/:id/coi`} component={Aircrafts} />
            <PrivateRoute exact={true} path={`${PATH_AIRCRAFT_VIEW}/:id/files`} component={Aircrafts} />
            <PrivateRoute exact={true} path={`${PATH_AIRCRAFT_VIEW}/:id/ubg`} component={Aircrafts} />
            <PrivateRoute exact={true} path={`${PATH_AIRCRAFT_VIEW}/:id/mgl`} component={Aircrafts} />
            <PrivateRoute exact={true} path={`${PATH_AIRCRAFT_VIEW}/:id/borescope-image-sets`} component={Aircrafts} />
            <PrivateRoute exact={true} path={`${PATH_AIRCRAFT_VIEW}/:id/aircraft-access`} component={Aircrafts} />
            <PrivateRoute exact={true} path={`${PATH_AIRCRAFT_CREATE}`} component={AircraftCreate} />

            {/* Flights Upload */}
            <PrivateRoute exact={true} path={`${PATH_FLIGHTS_UPLOAD}`} component={FileUploads} />
            <PrivateRoute exact={false} path={`${PATH_FLIGHTS_UPLOAD}/:id`} component={FileUploads} />

            {/* Borescope Upload */}
            <PrivateRoute exact={true} path={`${PATH_BORESCOPE_UPLOAD}`} component={BorescopeUpload} />
            <PrivateRoute exact={false} path={`${PATH_BORESCOPE_UPLOAD}/:id`} component={BorescopeUpload} />

            {/* Account */}
            <PrivateRoute exact path={PATH_ACCOUNT} component={Account} />
            <PrivateRoute exact={true} path={`${PATH_BETA_PLANS}`} component={Plans} />
            <PrivateRoute exact={true} path={`${PATH_BETA_PLANS}/:id`} component={Plans} />
            <PrivateRoute exact={true} path={PATH_BETA_UPGRADE_PLAN} component={UpgradePlan} />
            <PrivateRoute exact={true} path={PATH_ADD_ANALYSIS_PRO_PLAN} component={UpgradeAnalysisPro} />
            <PrivateRoute exact={true} path={PATH_ADD_QA_PLAN} component={UpgradeQa} />
            <PrivateRoute exact={true} path={PATH_UPGRADE_QA_PLAN} component={UpgradeQa} />
            <PrivateRoute exact={true} path={`${PATH_WELCOME}/:aircraftId/:service`} component={WelcomePage} />
            <PrivateRoute
              exact={true}
              path={`${PATH_CREATE_BORESCOPE_REPORT}/:aircraftId/:borescopeImageSetId`}
              component={CreateBaReportsWithPermission}
            />
            <PrivateRoute
              exact={true}
              path={`${PATH_CREATE_OWN_BORESCOPE_REPORT}/:aircraftId/:borescopeImageSetId`}
              component={CreateOwnBorescopeAnalysisReports}
            />

            <PrivateSavvyRoute exact={true} path={PATH_SERVICE_CENTER_CREATE} component={LazyServiceCenterPage} />
            {/* Internal dashboard for savvy user*/}
            <PrivateSavvyRoute exact path={PATH_INTERNAL_DASHBOARD} component={LazyInternalDashboard} />
            <PrivateSavvyRoute exact path={PATH_PERSONAL_TICKET_DASHBOARD} component={LazyPersonalTicketDashboard} />
            <PrivateSavvyRoute exact path={PATH_SERVICE_CENTER} component={LazyServiceCentersList} />

            {/* Savvy contact tabs */}
            <PrivateSavvyRoute exact path={`${PATH_CONTACT}/:contactId/aircraft`} component={LazyEditContact} />
            <PrivateSavvyRoute exact path={`${PATH_CONTACT}/:contactId/tickets`} component={LazyEditContact} />
            <PrivateSavvyRoute exact path={`${PATH_CONTACT}/:contactId/tickets/:id`} component={LazyEditContact} />
            <PrivateSavvyRoute exact path={`${PATH_CONTACT}/:contactId/classifications`} component={LazyEditContact} />
            <PrivateSavvyRoute exact path={`${PATH_CONTACT}/:contactId/comments`} component={LazyEditContact} />
            <PrivateSavvyRoute exact path={`${PATH_CONTACT}/:contactId/transactions`} component={LazyEditContact} />
            <PrivateSavvyRoute exact path={`${PATH_CONTACT}/:contactId/emailcc`} component={LazyEditContact} />
            <PrivateSavvyRoute exact path={`${PATH_CONTACT}/:contactId/assigned`} component={LazyEditContact} />
            <PrivateSavvyRoute exact path={`${PATH_CONTACT}/:contactId`} component={LazyEditContact} />

            <PrivateSavvyRoute exact={true} path={`${PATH_SAVVY_AIRCRAFT_FLIGHTS}/:id`} component={LazyContactFlight} />
            <PrivateSavvyRoute exact path={`${PATH_CONTACT}/:contactId/subscription/:id`} component={LazySavvySubscriptions} />
            <PrivateSavvyRoute exact path={PATH_TICKETS_DASHBOARD} component={LazyTicketsDashboard} />
            <PrivateSavvyRoute exact={true} path={`${PATH_FILTERED_TICKETS}/:tag/:category`} component={LazyFilteredTicketsPage} />
            <PrivateSavvyRoute exact={false} path={`${PATH_FILTERED_TICKETS}/:tag/:category/:id`} component={LazyFilteredTicketsPage} />
            <PrivateSavvyRoute exact={true} path={PATH_RENEWAL_TICKETS} component={LazyRenewalDashboard} />
            <PrivateSavvyRoute exact={true} path={`${PATH_RENEWAL_TICKETS}/:serviceCode`} component={LazyRenewalTicketsPage} />
            <PrivateSavvyRoute exact={false} path={`${PATH_RENEWAL_TICKETS}/:serviceCode/:id`} component={LazyRenewalTicketsPage} />
            <PrivateSavvyRoute
              exact={false}
              path={`${PATH_CONTACT}/:contactId/aircraft/:id/file/upload`}
              component={LazySavvyFileUploads}
            />
            <PrivateSavvyRoute exact={true} path={PATH_UESR_TICKET_CREATE} component={LazySelectUserTicketType} />
            <PrivateSavvyRoute exact={true} path={`${PATH_UESR_TICKET_CREATE}/contacts/:contactId`} component={LazySelectUserTicketType} />
            <PrivateSavvyRoute
              exact={false}
              path={`${PATH_UESR_TICKET_CREATE}/contacts/:contactId/aircraft/:aircraftId`}
              component={LazySelectUserTicketType}
            />
            <PrivateSavvyRoute exact={true} path={`${PATH_UESR_TICKET_CREATE_TYPE}/:type/:contactId`} component={LazyUserTicketCreate} />
            <PrivateSavvyRoute
              exact={false}
              path={`${PATH_UESR_TICKET_CREATE_TYPE}/:type/:contactId/aircraft/:id`}
              component={LazyUserTicketCreate}
            />

            {/* Savvy aircraft tabs */}
            <PrivateSavvyRoute exact={true} path={`${PATH_SAVVY_AIRCRAFT}/:aircraftId`} component={LazySavvyAircraft} />
            <PrivateSavvyRoute exact={true} path={`${PATH_SAVVY_AIRCRAFT}/:aircraftId/owner`} component={LazySavvyAircraft} />
            <PrivateSavvyRoute exact={true} path={`${PATH_SAVVY_AIRCRAFT}/:aircraftId/comments`} component={LazySavvyAircraft} />
            <PrivateSavvyRoute exact={true} path={`${PATH_SAVVY_AIRCRAFT}/:aircraftId/managers`} component={LazySavvyAircraft} />
            <PrivateSavvyRoute exact={true} path={`${PATH_SAVVY_AIRCRAFT}/:aircraftId/flights`} component={LazySavvyAircraft} />
            <PrivateSavvyRoute exact={true} path={`${PATH_SAVVY_AIRCRAFT}/:aircraftId/tickets`} component={LazySavvyAircraft} />
            <PrivateSavvyRoute exact={true} path={`${PATH_SAVVY_AIRCRAFT}/:aircraftId/tickets/:id`} component={LazySavvyAircraft} />
            <PrivateSavvyRoute exact={true} path={`${PATH_SAVVY_AIRCRAFT}/:aircraftId/subscriptions`} component={LazySavvyAircraft} />
            <PrivateSavvyRoute
              exact={true}
              path={`${PATH_SAVVY_AIRCRAFT}/:aircraftId/subscriptions/:subscriptionId`}
              component={LazySavvyAircraft}
            />
            <PrivateSavvyRoute exact={true} path={`${PATH_SAVVY_AIRCRAFT}/:aircraftId/documents`} component={LazySavvyAircraft} />
            <PrivateSavvyRoute exact={true} path={`${PATH_SAVVY_AIRCRAFT}/:aircraftId/edf`} component={LazySavvyAircraft} />
            <PrivateSavvyRoute exact={true} path={`${PATH_SAVVY_AIRCRAFT}/:aircraftId/coi`} component={LazySavvyAircraft} />
            <PrivateSavvyRoute exact={true} path={`${PATH_SAVVY_AIRCRAFT}/:aircraftId/mdr`} component={LazySavvyAircraft} />
            <PrivateSavvyRoute exact={true} path={`${PATH_SAVVY_AIRCRAFT}/:aircraftId/analysis-reports`} component={LazySavvyAircraft} />
            <PrivateSavvyRoute exact={true} path={`${PATH_SAVVY_AIRCRAFT}/:aircraftId/reports`} component={LazySavvyAircraft} />
            <PrivateSavvyRoute
              exact={true}
              path={`${PATH_SAVVY_AIRCRAFT}/:aircraftId/borescope-image-sets`}
              component={LazySavvyAircraft}
            />

            <PrivateSavvyRoute exact={true} path={`${PATH_UNASSIGNED_TICKETS}`} component={LazyUnassignedTicketsPage} />
            <PrivateSavvyRoute exact={false} path={`${PATH_UNASSIGNED_TICKETS}/:id`} component={LazyUnassignedTicketsPage} />
            <PrivateSavvyRoute exact={true} path={PATH_INTERNAL_SERVICE_CENTER} component={LazyServiceCenterView} />
            <PrivateSavvyRoute exact={false} path={`${PATH_INTERNAL_SERVICE_CENTER}/tickets/:ticketId`} component={LazyServiceCenterView} />
            <PrivateSavvyRoute
              exact={true}
              path={`${PATH_SERVICE_CENTER_MANUAL_RATING}/:id`}
              component={LazyServiceCenterManualRatingPage}
            />
            <PrivateSavvyRoute exact={true} path={PATH_SAVVY_ACCOUNT} component={LazySavvyAccount} />
            <PrivateSavvyRoute exact={true} path={PATH_SAVVY_SEARCH} component={LazyAdvancedSearch} />
            <PrivateSavvyRoute exact={true} path={PATH_SAVVY_SEARCH_AIRCRAFT_TYPE} component={LazySearchAircraftByType} />
            <PrivateSavvyRoute exact={true} path={PATH_SAVVY_LOG_BACKFILL} component={LazySavvyBackfillLogPage} />
            <PrivateSavvyRoute exact={true} path={PATH_SAVVY_COI_MANAGEMENT} component={LazyManagementCoiPage} />
            <PrivateSavvyRoute exact={true} path={PATH_MANAGER_CONFLICTS} component={LazyManagerConflicts} />
            <PrivateSavvyRoute exact={true} path={PATH_SAVVY_COHORT_REPORTS} component={LazyCohortReports} />
            <PrivateSavvyRoute exact={false} path={`${PATH_SAVVY_COHORT_REPORTS}/:id`} component={LazyCohortReports} />
            <PrivateSavvyRoute exact={true} path={PATH_SAVVY_TIMESHEETS_DASHBOARD} component={LazyTimesheetOverview} />
            <PrivateSavvyRoute exact={true} path={`${PATH_SAVVY_AIRCRAFT_CREATE}/:id`} component={LazySavvyAircraftCreate} />
            <PrivateSavvyRoute exact={true} path={PATH_ACTIVE_PREBUY} component={LazyActivePrebuy} />
            <PrivateSavvyRoute exact={true} path={PATH_FEVA_ALERTS} component={LazyFevaAlerts} />
            <PrivateSavvyRoute exact={true} path={`${PATH_FEVA_VIEW}/:id`} component={LazyFevaAlertView} />
            <PrivateSavvyRoute exact={true} path={PATH_ANALYSIS_DASHBOARD} component={LazyAnalysisDashboard} />
            <PrivateSavvyRoute exact={true} path={`${PATH_FLIGHT_REPORT}/generate/:tagId`} component={LazyCreateAnalysisReport} />
            <PrivateSavvyRoute exact={true} path={`${PATH_FLIGHT_REPORT}/:aircraftId/:reportId`} component={LazyAnalysisReports} />
            <PrivateSavvyRoute exact={true} path={PATH_SAVVY_TIMESHEETS_RATE} component={LazyTimesheetRates} />

            <PrivateSavvyRoute exact={true} path={PATH_SAVVY_SEARCH_TICKET} component={LazyTicketSearch} />
            <PrivateSavvyRoute exact={true} path={PATH_SAVVY_SERACH_CONTACT} component={LazyContactSearch} />
            <PrivateSavvyRoute exact={true} path={PATH_SAVVY_SEARCH_AIRCRAFT} component={LazyAircraftSearch} />
            <PrivateSavvyRoute exact={true} path={PATH_SAVVY_SEARCH_SUBSCRIPTION} component={LazySubscriptionSearch} />
            <PrivateSavvyRoute exact={true} path={PATH_SAVVY_SEARCH_SERVICE_CENTER} component={LazyServiceCenterSearch} />
            <PrivateSavvyRoute exact={true} path={PATH_SAVVY_SEARCH_FLIGHT_REPORT} component={LazyFlightReportSearch} />
            <PrivateSavvyRoute exact={true} path={PATH_SAVVY_SEARCH_PREBUY_INQUIRY} component={LazyPrebuyInquirySearch} />

            <PrivateSavvyRoute exact={true} path={PATH_SAVVY_OOP} component={LazyOopList} />
            <PrivateSavvyRoute exact={true} path={PATH_SAVVY_KEY_RESULTS_REPORT} component={LazyKeyResultsReport} />
            <PrivateSavvyRoute exact={false} path={`${PATH_SAVVY_KEY_RESULTS_REPORT}/:year/:month`} component={LazyKeyResultsReport} />
            <PrivateSavvyRoute exact={true} path={PATH_SAVVY_CANNED_RESPONSES} component={LazyCannedResponses} />

            <PrivateSavvyRoute exact={true} path={PATH_SAVVY_FUTURE_COHORTS} component={LazyFutureCohorts} />
            <PrivateSavvyRoute exact={true} path={`${PATH_SAVVY_PREBUY_INQUIRY}/:id`} component={LazyPrebuyInquiry} />
            <PrivateSavvyRoute exact={true} path={PATH_SAVVY_COHORTS} component={LazyCohorts} />
            <PrivateSavvyRoute exact={true} path={`${PATH_SAVVY_COHORTS}/:id`} component={LazyViewCohort} />
            <PrivateSavvyRoute exact={true} path={PATH_SAVVY_FINANCIALS} component={LazyFinancials} />

            <PrivateSavvyRoute exact={true} path={PATH_SAVVY_SUBMITTED_AIRCRAFT_DATA} component={LazyAircraftSubmittedData} />
            <PrivateSavvyRoute exact={true} path={`${PATH_SAVVY_SUBMITTED_AIRCRAFT_DATA}/:type`} component={LazyViewSubmittedData} />

            <PrivateSavvyRoute exact={true} path={PATH_SAVVY_ENGINE_PARAMETERS} component={LazyPowerModelList} />
            <PrivateSavvyRoute exact={true} path={`${PATH_SAVVY_ENGINE_PARAMETERS}/create`} component={LazyCreatePowerModel} />
            <PrivateSavvyRoute exact={true} path={`${PATH_SAVVY_ENGINE_PARAMETERS}/:id`} component={LazyViewPowerModel} />
            <PrivateSavvyRoute exact={true} path={`${PATH_SAVVY_ENGINE_PARAMETERS}/:id/edit`} component={LazyEditPowerModel} />
            <PrivateSavvyRoute
              exact={true}
              path={`${PATH_CREATE_PREBUY_CONFLICT_TICKET}/:aircraftId`}
              component={LazyCreatePrebuyConflictTicket}
            />
            <PrivateSavvyRoute exact={false} path={PATH_INTERNAL_SERVICE_CENTER_RATING} component={LazyServiceCenterRatingPage} />
            <PrivateSavvyRoute exact={true} path={PATH_INTERNAL_TICKETRATINGS} component={LazyTicketRatingPage} />
            <PrivateSavvyRoute exact={true} path={PATH_SAVVY_TECHS_WITH_UPDATE_REQUEST} component={LazyTechsWithUpdateRequest} />
            <PrivateSavvyRoute exact={true} path={PATH_MAKE_PAYMENTS} component={LazyMakePayments} />

            <PrivateSavvyRoute
              exact={true}
              path={`${PATH_SAVVY_BORESCOPE_ANALYSIS_REPORT_GENERATE}/:aircraftId/:borescopeImageSetId`}
              component={LazyCreateBorescopeAnalysisReport}
            />

            <PrivateSavvyRoute
              exact={true}
              path={`${PATH_SAVVY_BORESCOPE_ANALYSIS_REPORT_VIEW}/:aircraftId/:borescopeImageSetId`}
              component={LazySavvyBorescopeReport}
            />

            <PrivateTechRoute exact={true} path={PATH_SC_DASHBOARD} component={TechDashboard} />
            <PrivateTechRoute exact path={PATH_SC_FLIGHTS} component={Flights} />
            <PrivateTechRoute exact={false} path={`${PATH_SC_FLIGHTS}/aircraft/:aircraftId`} component={Flights} />
            <PrivateTechRoute exact={true} path={`${PATH_VERIFY_SERVICE_CENTER_FOR_TECH}/:id`} component={VerifyServiceCenter} />
            <PrivateTechRoute exact={true} path={`${PATH_UPDATE_SERVICE_CENTER_FOR_TECH}/:id`} component={UpdateServiceCenter} />
            <PrivateTechRoute exact={true} path={PATH_UPDATE_TECHNICIAN} component={UpdateTechnician} />
            <PrivateTechRoute exact={true} path={PATH_VERIFY_TECHNICIAN} component={VerifyTechnician} />

            <PrivateSavvyRoute exact={true} path={PATH_POSTMARK_TEMPLATES} component={LazyPostmark} />
            <PrivateSavvyRoute exact={true} path={PATH_API_TEMPLATES} component={LazyAPItemplate} />

            {/* 404. Not found */}
            <Route component={NotFound} />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    </Fragment>
  );
};

export default Router;
