import React from 'react';

import { Stepper } from 'components';

interface IStepperSignup {
  activeStep: number;
}

const StepperSignup: React.FC<IStepperSignup> = (props) => {
  const { activeStep } = props;
  const steps = ['Sign Up', 'Payment', 'Additional Info'];

  return <Stepper steps={steps} activeStep={activeStep} />;
};

export default StepperSignup;
