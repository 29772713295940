import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, IconButton } from '@mui/material';
import { IBorescopeImageSet } from 'types';
import { QUERY_ELIGIBLE_BORESCOPE } from 'gql';
import { useLazyQuery } from '@apollo/client';
import { ComponentLoading } from 'components';
import { getEngineNameFromBorescopeLabel } from 'helpers';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { PostTicket } from 'pages/TicketsCreate/components';

interface RequestAnalysisProps {
  borescopeImageSet: IBorescopeImageSet;
  setSlideNum: (value: number) => void;
  isLoaded: boolean;
}

const RequestAnalysis: React.FC<RequestAnalysisProps> = (props) => {
  const { borescopeImageSet, setSlideNum, isLoaded } = props;
  const [fetchEligibility, { data: dataEligibility, loading: loadingEligibility }] = useLazyQuery(QUERY_ELIGIBLE_BORESCOPE);

  const { id } = useParams<{
    id: string;
  }>();

  useEffect(() => {
    if (isLoaded && id) {
      fetchEligibility({
        variables: {
          id: parseInt(id),
        },
        fetchPolicy: 'cache-and-network',
      });
    }
  }, [id, isLoaded]);

  return (
    <ComponentLoading loading={loadingEligibility}>
      <Box px={5}>
        <Box py={5}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <IconButton
              sx={{
                '&:hover': {
                  backgroundColor: 'grey.700',
                },
                borderRadius: '6px',
                border: '1px solid',
                borderColor: 'grey.200',
                backgroundColor: 'grey.700',
                p: '5px',
              }}
              onClick={() => {
                setSlideNum(1);
              }}
            >
              <ChevronLeftIcon />
            </IconButton>
            <Box ml={3.75}>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: '20px',
                  lineHeight: '28px',
                  color: 'text.secondary',
                }}
              >
                Request Analysis
              </Typography>
              <Typography
                sx={{
                  color: 'grey.600',
                }}
              >
                {borescopeImageSet?.name}
                {borescopeImageSet?.engine === 'S' ? '' : ` (engine: ${getEngineNameFromBorescopeLabel(borescopeImageSet?.engine || '')})`}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            backgroundColor: 'background.default',
          }}
        >
          <PostTicket
            ticketAircraft={dataEligibility?.me?.aircraft[0]}
            borescopeImageSet={borescopeImageSet}
            ticketType={{
              field: 'borescopeAnalysis',
              label: 'Borescope Analysis',
            }}
          />
        </Box>
      </Box>
    </ComponentLoading>
  );
};

export default RequestAnalysis;
