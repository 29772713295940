import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';

import { QUERY_GET_USER_DATA } from 'gql';
import { useLazyQuery } from '@apollo/client';

import { ComponentLoading } from 'components';
import { getContactAddress } from 'helpers';

interface ContactTooltipProps {
  open: boolean;
  contactId: string;
  email: string;
}

const ContactTooltip: React.FC<ContactTooltipProps> = (props) => {
  const { open, contactId, email } = props;
  const [fetchUserData, { data, loading }] = useLazyQuery(QUERY_GET_USER_DATA);

  useEffect(() => {
    if (open && contactId && !email) {
      fetchUserData({
        variables: {
          contactId,
        },
      });
    }
  }, [contactId, fetchUserData, open, email]);

  return (
    <Box>
      <ComponentLoading loading={loading}>
        <Box
          sx={{
            minHeight: !email ? '130px' : '50px',
          }}
          px={2}
          py={1}
        >
          {data?.savvy?.contacts[0] && !email && (
            <Box>
              <Box py={1}>
                <Typography>Email: {data.savvy.contacts[0].email}</Typography>
              </Box>
              <Box py={1}>
                <Typography>
                  Name: {data.savvy.contacts[0].firstName} {data.savvy.contacts[0].lastName}
                </Typography>
              </Box>
              {!!data.savvy.contacts[0].nickname && (
                <Box py={1}>
                  <Typography>Nickname: {data.savvy.contacts[0].nickname}</Typography>
                </Box>
              )}
              <Box py={1}>
                <Typography>Phone: {data.savvy.contacts[0].phoneMobile ? data.savvy.contacts[0].phoneMobile : 'None'}</Typography>
              </Box>
              {!!getContactAddress(data.savvy.contacts[0]) && (
                <Box py={1}>
                  <Typography>Address: {getContactAddress(data.savvy.contacts[0])}</Typography>
                </Box>
              )}
              {!!data.savvy.contacts[0]?.classifications?.length && (
                <Box py={1}>
                  <Typography>
                    Classifications: {data.savvy.contacts[0]?.classifications?.map((item: any) => item.name).join(', ')}
                  </Typography>
                </Box>
              )}
            </Box>
          )}
          {!!email && (
            <Box>
              <Box py={1}>
                <Typography>Email: {email}</Typography>
              </Box>
            </Box>
          )}
        </Box>
      </ComponentLoading>
    </Box>
  );
};

export default ContactTooltip;
