import React from 'react';
import { FormGroup } from '@mui/material';

import { FormInput, FormInputError } from './';

interface FormGroupInputProps {
  name: string;
  className?: string;
  errors: any;
  touched: any;
  type?: string;
  label?: string;
  onChange: (event: any) => void;
  onBlur?: (event: any) => void;
  disabled?: boolean;
  placeholder?: string;
  maxLength?: number;
  InputProps?: any;
  existEmail?: boolean;
  existCompany?: boolean;
  noMargin?: boolean;
}

const FormGroupInput: React.FC<FormGroupInputProps> = (props) => {
  const {
    name,
    errors,
    touched,
    type,
    label,
    onBlur,
    onChange,
    existEmail = false,
    existCompany = false,
    noMargin = false,
    ...rest
  } = props;

  return (
    <FormGroup
      sx={{
        mb: noMargin ? '0px' : '15px',
      }}
    >
      <FormInput name={name} label={label} onBlur={onBlur} onChange={onChange} type={type ? type : 'text'} noMargin={noMargin} {...rest} />
      <FormInputError name={name} errors={errors} touched={touched} existEmail={existEmail} existCompany={existCompany} />
    </FormGroup>
  );
};

export default FormGroupInput;
