import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Typography, Button } from '@mui/material';
import { DataGridPro, GridSelectionModel } from '@mui/x-data-grid-pro';

import { LayoutDashboard, ComponentLoading, CustomGridToolbar } from 'components';

import { QUERY_POSTMARK_TEMPLATES, MUTATION_TEST_POSTMARK_TEMPLATES } from 'gql';
import { useLazyQuery, useMutation } from '@apollo/client';

import { setAlert } from 'state';

const Postmark: React.FC = () => {
  const [fetchTemplates, { data: dataTemplates, error: errorTemplates, loading: loadingTemplates }] = useLazyQuery(
    QUERY_POSTMARK_TEMPLATES,
    {
      fetchPolicy: 'cache-and-network',
    },
  );
  const [testTemplates, { data: dataTest, loading: loadingTest, error: errorTest }] = useMutation(MUTATION_TEST_POSTMARK_TEMPLATES);
  const dispatch = useDispatch();

  const [filterButtonEl, setFilterButtonEl] = useState<HTMLButtonElement | null>(null);
  const [selectionModel, setSelectionModel] = React.useState<GridSelectionModel>([]);

  useEffect(() => {
    if (errorTemplates) {
      dispatch(setAlert('error', 'Unable to load templates'));
    }
  }, [errorTemplates, dispatch]);

  useEffect(() => {
    if (errorTest) {
      dispatch(setAlert('error', 'Unable to test templates'));
    } else if (dataTest) {
      dispatch(setAlert('success', 'Success'));
    }
  }, [errorTest, dataTest, dispatch]);

  useEffect(() => {
    fetchTemplates();
  }, [fetchTemplates]);

  const onTest = async (templateAliases: Array<any>) => {
    await testTemplates({
      variables: {
        templateAliases,
      },
    });
  };

  return (
    <LayoutDashboard backgroundColor="#fff">
      <Box p={4}>
        <Typography variant="h2" gutterBottom>
          Postmark Templates
        </Typography>
        <Box py={1}>
          {selectionModel.length > 0 && (
            <Button
              variant="contained"
              disabled={loadingTest}
              onClick={() => {
                onTest(selectionModel);
              }}
            >
              Selected Templates Test
            </Button>
          )}
        </Box>
        <Box py={1}>
          <ComponentLoading loading={loadingTemplates}>
            <Box py={1}>
              <DataGridPro
                sx={{
                  width: '100%',
                  minWidth: '100px',
                  '& .MuiDataGrid-cell:focus': {
                    outline: 'none',
                  },
                  '& .MuiDataGrid-row': {
                    cursor: 'pointer',
                    borderBottom: '1px solid',
                    borderColor: 'grey.200',
                  },
                  '& .MuiDataGrid-columnHeader:focus': {
                    outline: 'none',
                  },
                  '& .MuiDataGrid-toolbarContainer .MuiTextField-root': {
                    mb: 0,
                  },
                  border: 'none',
                }}
                autoHeight
                checkboxSelection
                onSelectionModelChange={(newSelectionModel) => {
                  setSelectionModel(newSelectionModel);
                }}
                selectionModel={selectionModel}
                rowsPerPageOptions={
                  dataTemplates?.postmarkTemplates?.length && dataTemplates.postmarkTemplates.length > 100
                    ? [5, 10, 25, dataTemplates.postmarkTemplates.length]
                    : [5, 10, 25, 100]
                }
                autoPageSize
                showColumnRightBorder={false}
                disableColumnSelector
                disableColumnMenu
                rows={dataTemplates?.postmarkTemplates?.length ? dataTemplates.postmarkTemplates : []}
                getRowId={(row) => row.filename}
                columns={[
                  {
                    field: 'filename',
                    headerName: 'Alias',
                    flex: 0.3,
                  },
                  {
                    field: 'subject',
                    headerName: 'Subject',
                    flex: 1,
                  },
                  {
                    field: 'templateName',
                    headerName: 'Template Name',
                    flex: 1,
                  },
                ]}
                components={{ Toolbar: CustomGridToolbar }}
                componentsProps={{
                  toolbar: {
                    setFilterButtonEl,
                  },
                  filterPanel: {
                    sx: {
                      '& .MuiDataGrid-filterForm': {
                        '& .MuiTextField-root': {
                          mb: 0,
                        },
                      },
                    },
                  },
                  panel: {
                    anchorEl: filterButtonEl,
                    placement: 'bottom-end',
                  },
                }}
              />
            </Box>
          </ComponentLoading>
        </Box>
      </Box>
    </LayoutDashboard>
  );
};

export default Postmark;
