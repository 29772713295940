import React, { Fragment } from 'react';

import { Typography, Collapse, Hidden } from '@mui/material';

import { Subsection, List, ButtonMore } from 'components';
import { useMdUp } from 'hooks';

const features = [
  'Unlimited & Comprehensive Flight Analysis Reports prepared by Savvy’s professional analysts',
  'Actional feedback on your engine’s performance and other significant systems of your aircraft',
  'Regular Report Cards and Trend Analysis reports that compare your engine’s performance with the same model aircraft',
  'Failing Exhaust Valve Analytics (FEVA) which monitors your exhaust valve health in the background',
];

const fees = ['Piston single: $129/year', 'Piston twin: $199/year'];

const SavvyAnalysisProDescription: React.FC = () => {
  const [open, setOpen] = React.useState(false);
  const isMdUp = useMdUp();

  return (
    <Fragment>
      <Subsection>
        <Typography paragraph>
          SavvyAnalysis Pro is the only service in the industry that analyzes piston engine monitor data. Upload your raw engine data and we
          turn it into the most useful, actionable information about your engine’s health and your operating technique. With more than 3
          million flights uploaded and Savvy’s proprietary predictive analytics models, this service allows you to extend the life of your
          engine and operate your aircraft more economically.
        </Typography>
      </Subsection>
      <Collapse in={open || isMdUp}>
        <Subsection>
          <Typography variant="h4" gutterBottom>
            For a modest annual subscription fee, you’ll receive the following benefits:
          </Typography>
          <List list={features} />
        </Subsection>
        <Subsection>
          <Typography variant="h4" gutterBottom>
            SavvyAnalysis Pro Service Annual Fees:
          </Typography>
          <List list={fees} />
          <Typography
            variant="body1"
            style={{
              fontStyle: 'italic',
            }}
          >
            *Turboprop & Turbojet not supported
          </Typography>
        </Subsection>
      </Collapse>
      {!isMdUp && (
        <Hidden mdUp>
          <Subsection>
            <ButtonMore open={open} setOpen={setOpen} />
          </Subsection>
        </Hidden>
      )}
    </Fragment>
  );
};

export default SavvyAnalysisProDescription;
