import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Link, IconButton, Popover, Divider, List, ListItem, Tooltip } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DownloadIcon from '@mui/icons-material/Download';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { SavvyAvatar, ComponentLoading } from 'components';
import { humanDateTime, post_html_processing, post_html_processing_remove_linebreak } from 'helpers';
import { IPost, IExsitingFiles, IAttachment } from 'types';

import {
  QUERY_USER_DETAIL_ATTACHMENT_DATA,
  MUTATION_CREATE_POST_ATTACHMENTS_ARCHIVE,
  QUERY_USER_DETAIL_ATTACHMENT_DOWNLOAD_URL,
} from 'gql';
import { useLazyQuery, useMutation } from '@apollo/client';

import EditPostDialog from './EditPostDialog';
import EditVisibilityDialog from './EditVisibilityDialog';
import MoveCopyPostDialog from './MoveCopyPostDialog';
import EmailIcon from '@mui/icons-material/Email';

import { CreateCoiFromAttachment, CreateLogFromAttachment, ParseFlightsFromAttachment } from 'pages/SavvyTickets/components';
import { default as AttachmentDescriptionDialog } from 'pages/SavvyAircraft/components/Tabs/AttachmentDescriptionDialog';

import { setAlert } from 'state';
import { SET_EXISTING_FILES } from 'state/types';
import { isHeic, downloadFileInNewTab } from 'helpers';
import { PostArchiveDialog } from './';
import { apolloClient } from 'services';

interface TicketPostProps {
  post: IPost;
  setPdfShowFileDialog: (value: boolean) => void;
  setImgShowFileDialog: (value: boolean) => void;
  setShowFile: (value: string) => void;
  setShowFileName: (value: string) => void;
  ticketId: string;
  postRef?: React.RefObject<HTMLDivElement> | null;
  setPreviewAttachmentId: (value: string) => void;
  aircraftId?: string;
  registration?: string;
}

const TicketPost: React.FC<TicketPostProps> = (props) => {
  const {
    post,
    setPdfShowFileDialog,
    setImgShowFileDialog,
    setShowFileName,
    setShowFile,
    ticketId,
    postRef = null,
    setPreviewAttachmentId,
    aircraftId = '',
    registration = '',
  } = props;

  const [fetchAttachmentData, { data: dataAttachmentData, loading: loadingAttachmentData, error: errorAttachmentData }] =
    useLazyQuery(QUERY_USER_DETAIL_ATTACHMENT_DATA);
  const [createArchiveAttachments, { data: dataArchive, loading: loadingArchive, error: errorArchive }] = useMutation(
    MUTATION_CREATE_POST_ATTACHMENTS_ARCHIVE,
  );

  const [editPostId, setEditPostId] = useState('');
  const [editPostBody, setEditPostBody] = useState('');
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openVisiblityDialog, setOpenVisibilityDialog] = useState(false);
  const [openCopyDialog, setOpenCopyDialog] = useState(false);
  const [showArchiveDialog, setShowArchiveDialog] = useState(false);

  const [selectedAttachment, setSelectedAttachment] = useState<IAttachment>();

  const [documentId, setDocumentId] = useState('');
  const [showParseDialog, setShowParseDialog] = useState(false);

  const dispatch = useDispatch();

  let visibility: string[] = [];
  if (post.visibility) {
    visibility = post.visibility.split(', ');
  }

  const loadAttachmentData = async (ticketId: string, postId: string, attachmentId: string) => {
    setPreviewAttachmentId(attachmentId);

    if (
      dataAttachmentData?.me?.tickets[0]?.posts[0]?.attachments[0]?.previewUrl &&
      dataAttachmentData?.me?.tickets[0]?.posts[0]?.attachments[0]?.id === attachmentId
    ) {
      const attachment = dataAttachmentData?.me?.tickets[0]?.posts[0]?.attachments[0];
      if (isHeic(attachment.name)) {
        if (!attachment.base64Data) {
          dispatch(setAlert('error', 'Unable to load image'));
          return;
        }
        setShowFile(attachment.base64Data);
        setImgShowFileDialog(true);
        setPdfShowFileDialog(false);
        setShowFileName(attachment.name);
        return;
      }
    }

    await fetchAttachmentData({
      variables: {
        ticketId: parseInt(ticketId),
        postId,
        attachmentId,
      },
      fetchPolicy: 'network-only',
    });
  };

  const [loadingDownloadUrl, setLoadingDownloadUrl] = useState(false);

  const downloadAttachment = async (ticketId: string, postId: string, attachmentId: string) => {
    setLoadingDownloadUrl(true);
    const { data: dataDownloadUrl } = await apolloClient.query({
      query: QUERY_USER_DETAIL_ATTACHMENT_DOWNLOAD_URL,
      variables: {
        ticketId: parseInt(ticketId),
        postId,
        attachmentId,
      },
      fetchPolicy: 'no-cache',
    });
    setLoadingDownloadUrl(false);

    if (dataDownloadUrl?.me?.tickets[0]?.posts[0]?.attachments[0]?.downloadUrl) {
      downloadFileInNewTab(dataDownloadUrl.me.tickets[0].posts[0].attachments[0].downloadUrl);
    }
  };

  const makeVisibilityString = (value: string[] | undefined) => {
    if (!value || value.length === 0) {
      return '';
    } else if (value.length === 1) {
      return `Visible for only ${value[0]}`;
    } else {
      let str = `Visible for ${value[0]}`;
      for (let i = 1; i < value.length; i++) {
        str += ' / ' + value[i];
      }
      return str;
    }
  };

  const getPostBackgroundColor = (value: string[] | undefined) => {
    if (!value || value.length === 0) return 'rgba(240, 240, 240, 1)';

    if (value.length === 1 && value[0] === 'Savvy') return 'rgba(214,33,33,0.2)';

    if (value.length === 2 && value.indexOf('Client') >= 0 && value.indexOf('Savvy') >= 0) return 'rgba(214, 142, 33, 0.1)';

    if (value.length > 2) return 'rgba(74, 175, 97, 0.1)';

    if (value.indexOf('Service Center') >= 0) {
      return 'rgba(214, 142, 33, 0.1)';
    }

    if (value.indexOf('Savvy Management') >= 0) return 'rgba(25,140,222,0.1)';

    return 'rgba(240, 240, 240, 1)';
  };

  const stringToVisibility = (value: string | undefined) => {
    if (!value) return '';

    const stringArray = value.split(', ');

    const resultArray: string[] = [];

    if (stringArray.indexOf('Savvy') >= 0) resultArray.push('M');

    if (stringArray.indexOf('Client') >= 0) resultArray.push('C');

    if (stringArray.indexOf('Service Center') >= 0) resultArray.push('T');

    return resultArray.join(',');
  };

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const [anchorElAttachment, setAnchorElAttachment] = useState<HTMLButtonElement | null>(null);

  const handleClose = () => {
    setEditPostId('');
    setEditPostBody('');
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleCloseAttachmentPopup = () => {
    setAnchorElAttachment(null);
  };

  const openAttachmentPopup = Boolean(anchorElAttachment);
  const attachmentPopupId = openAttachmentPopup ? 'attachment-popover' : undefined;

  const [showCoi, setShowCoi] = useState(false);
  const [attachmentId, setAttachmentId] = useState('');
  const [attachmentName, setAttachmentName] = useState('');
  const [showMaintenanceEntry, setShowMaintenanceEntry] = useState(false);

  const [showAttachmentDialog, setShowAttachmentDialog] = useState(false);

  const { isSavvy } = useSelector((state: any) => state.auth);
  const attachExistingFiles = useSelector((state: any) => {
    return state.ticket.attachExistingFiles;
  });

  const setAttachExistingFiles = (attachExistingFiles: IExsitingFiles[]) => {
    dispatch({
      type: SET_EXISTING_FILES,
      payload: {
        attachExistingFiles,
      },
    });
  };

  const addExistingFile = (id: string, name: string) => {
    const addedFile = attachExistingFiles.filter((file: IExsitingFiles) => file.id === id);

    if (addedFile && addedFile.length) {
      return;
    }

    attachExistingFiles.push({
      id,
      name,
    });

    setAttachExistingFiles([...attachExistingFiles]);
  };

  const downloadAllAttachments = async (postId: string) => {
    if (!postId || isNaN(parseInt(postId))) {
      return;
    }

    await createArchiveAttachments({
      variables: {
        postId: parseInt(postId),
      },
    });
  };

  useEffect(() => {
    if (errorAttachmentData) {
      return;
    } else if (!loadingAttachmentData && dataAttachmentData) {
      if (dataAttachmentData?.me?.tickets[0]?.posts[0]?.attachments[0]?.previewUrl) {
        const attachment = dataAttachmentData?.me?.tickets[0]?.posts[0]?.attachments[0];
        if (attachment.isImage) {
          setImgShowFileDialog(true);
        } else if (attachment.isPdf) {
          setPdfShowFileDialog(true);
        }
        if (isHeic(attachment.name)) {
          if (!attachment.base64Data) {
            dispatch(setAlert('error', 'Unable to load image'));
            return;
          }
          setShowFile(attachment.base64Data);
        } else {
          setShowFile(attachment.previewUrl);
        }
        setShowFileName(attachment.name);
      }
    }
  }, [
    dataAttachmentData,
    errorAttachmentData,
    loadingAttachmentData,
    setImgShowFileDialog,
    setPdfShowFileDialog,
    setShowFile,
    setShowFileName,
    dispatch,
  ]);

  useEffect(() => {
    if (errorArchive) {
      dispatch(setAlert('error', 'Unable to download all attachments'));
    } else if (dataArchive) {
      const { ok, error } = dataArchive.createPostAttachmentsArchive;
      if (!ok) {
        dispatch(setAlert('error', error || 'Unable to download all attachments'));
      } else {
        setShowArchiveDialog(true);
      }
    }
  }, [errorArchive, dataArchive, dispatch]);

  return (
    <Box py={1} ref={postRef}>
      <ComponentLoading loading={loadingAttachmentData || loadingArchive || loadingDownloadUrl}>
        <Box
          px={2}
          py={1.5}
          sx={{
            backgroundColor: getPostBackgroundColor(visibility),
            borderRadius: '6px',
            display: 'flex',
            '&:hover': {
              '& .editableBtn': {
                visibility: 'visible',
              },
            },
          }}
        >
          <Box mr={1.5}>
            <SavvyAvatar
              role={post.poster?.role ? post.poster.role : ''}
              firstName={post.poster?.firstName ? post.poster.firstName : ''}
              lastName={post.poster?.lastName ? post.poster.lastName : ''}
              avatar={post.poster?.avatar ? post.poster.avatar : ''}
            />
          </Box>
          <Box
            sx={{
              width: 'calc(100% - 48px)',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                mb: 1,
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography>
                <Box
                  component="span"
                  sx={{
                    fontSize: '16px',
                    fontWeight: 'bold',
                    lineHeight: '22px',
                    color: 'grey.A900',
                  }}
                >
                  {`${post.poster?.firstName} ${post.poster?.lastName}`}
                </Box>
                {post.poster?.isEmailClient && (
                  <Tooltip title={post.poster.email}>
                    <EmailIcon
                      sx={{
                        ml: 0.5,
                        width: '20px',
                        height: '20px',
                        verticalAlign: 'bottom',
                      }}
                    />
                  </Tooltip>
                )}
                <Box
                  component="span"
                  sx={{
                    fontSize: '14px',
                    fontWeight: '500',
                    color: 'grey.A900',
                    textTransform: 'uppercase',
                    marginLeft: '8px',
                  }}
                >
                  {humanDateTime(post.created)}
                </Box>
                <Box
                  component="span"
                  sx={{
                    fontSize: '14px',
                    fontWeight: '500',
                    color: 'grey.A900',
                    marginLeft: '8px',
                  }}
                >
                  {visibility && !!visibility.length ? '•' : ''}&nbsp;{makeVisibilityString(visibility)}
                </Box>
              </Typography>
              {post.editable && (
                <IconButton
                  sx={{
                    display: 'inline-flex',
                    visibility: 'hidden',
                  }}
                  size="small"
                  className="editableBtn"
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    if (!post?.editable) {
                      return false;
                    }
                    setAnchorEl(e.currentTarget);
                    setEditPostId(post.id);
                    setEditPostBody(post.body);
                  }}
                >
                  <MoreVertIcon />
                </IconButton>
              )}
            </Box>
            <Box>
              <Typography
                variant="body1"
                sx={{
                  lineHeight: '23px',
                  display: 'inline',
                  maxWidth: '100%',
                  wordBreak: 'break-word',
                  color: 'text.secondary',
                  '& table': {
                    maxWidth: '330px',
                  },
                  '& a': {
                    color: 'primary.main',
                  },
                }}
                dangerouslySetInnerHTML={{
                  __html: post_html_processing(post.body),
                }}
              />
            </Box>
            {Boolean(post?.metadata) && (
              <Box>
                <Divider
                  sx={{
                    my: 1,
                  }}
                />
                <Typography
                  variant="body1"
                  sx={{
                    lineHeight: '23px',
                    display: 'inline',
                    maxWidth: '100%',
                    wordBreak: 'break-word',
                    color: 'text.secondary',
                  }}
                  dangerouslySetInnerHTML={{
                    __html: post_html_processing_remove_linebreak(post?.metadata ? post.metadata : ''),
                  }}
                />
              </Box>
            )}
            {Boolean(post?.attachments?.length) && (
              <Box>
                <Divider
                  sx={{
                    my: 1,
                  }}
                />
                <Box>
                  {!!post?.attachments?.length &&
                    [...post.attachments]
                      .sort((a: IAttachment, b: IAttachment) => (a.created > b.created ? -1 : 1))
                      .map((attachment: any, index: number) => {
                        return (
                          <Box
                            key={index}
                            sx={{
                              py: 0.5,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Typography
                              key={index}
                              sx={{
                                py: 0.5,
                                color: 'text.secondary',
                              }}
                            >
                              Attachment:{' '}
                              <Link
                                href="#"
                                onClick={(e: any) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  if (attachment.isImage || attachment.isPdf) {
                                    loadAttachmentData(ticketId, post.id, attachment.id);
                                  } else {
                                    downloadAttachment(ticketId, post.id, attachment.id);
                                  }
                                }}
                                target="_blank"
                              >
                                {attachment.name}
                              </Link>
                              {attachment.description ? ` (${attachment.description})` : ''}
                            </Typography>
                            <Box
                              sx={{
                                display: 'flex',
                              }}
                            >
                              {(attachment.isImage || attachment.isPdf) && (
                                <IconButton
                                  onClick={() => {
                                    if (attachment.isImage || attachment.isPdf) {
                                      loadAttachmentData(ticketId, post.id, attachment.id);
                                    } else {
                                      dispatch(setAlert('warning', 'Unable to preview attachment'));
                                    }
                                  }}
                                >
                                  <VisibilityIcon />
                                </IconButton>
                              )}
                              <IconButton
                                onClick={() => {
                                  downloadAttachment(ticketId, post.id, attachment.id);
                                }}
                              >
                                <DownloadIcon />
                              </IconButton>
                              <IconButton
                                onClick={(e: any) => {
                                  setAnchorElAttachment(e.currentTarget);
                                  setSelectedAttachment(attachment);
                                }}
                              >
                                <MoreVertIcon />
                              </IconButton>
                            </Box>
                          </Box>
                        );
                      })}
                  {Boolean(post?.attachments?.length) && (
                    <Box py={0.5}>
                      <Link
                        href="#"
                        onClick={(e: any) => {
                          e.preventDefault();
                          e.stopPropagation();
                          downloadAllAttachments(post.id);
                        }}
                      >
                        Download all attachments
                      </Link>
                    </Box>
                  )}
                </Box>
              </Box>
            )}

            <EditPostDialog openDialog={openEditDialog} setOpenDialog={setOpenEditDialog} postId={editPostId} body={editPostBody} />
            <EditVisibilityDialog
              openDialog={openVisiblityDialog}
              setOpenDialog={setOpenVisibilityDialog}
              postId={editPostId}
              visibility={stringToVisibility(post.visibility)}
              role={post.poster?.role}
              body={editPostBody}
            />
            <MoveCopyPostDialog openDialog={openCopyDialog} setOpenDialog={setOpenCopyDialog} postId={editPostId} />
          </Box>
        </Box>
      </ComponentLoading>
      {selectedAttachment && (
        <AttachmentDescriptionDialog open={showAttachmentDialog} setOpen={setShowAttachmentDialog} attachment={selectedAttachment} />
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            py: 1,
            px: 1.5,
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: 'grey.200',
            },
          }}
          onClick={() => {
            setOpenEditDialog(true);
            setAnchorEl(null);
          }}
        >
          <EditIcon />
          <Typography
            paragraph
            sx={{
              marginBlock: '0',
              fontSize: '16px',
              lineHeight: '22px',
              fontWeight: '500',
              ml: 1.5,
            }}
          >
            Edit Post
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            py: 1,
            px: 1.5,
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: 'grey.200',
            },
          }}
          onClick={() => {
            setOpenVisibilityDialog(true);
            setAnchorEl(null);
          }}
        >
          <VisibilityIcon />
          <Typography
            paragraph
            sx={{
              marginBlock: '0',
              fontSize: '16px',
              lineHeight: '22px',
              fontWeight: '500',
              ml: 1.5,
            }}
          >
            Edit Visibility
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            py: 1,
            px: 1.5,
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: 'grey.200',
            },
          }}
          onClick={() => {
            setOpenCopyDialog(true);
            setAnchorEl(null);
          }}
        >
          <ContentCopyIcon />
          <Typography
            paragraph
            sx={{
              marginBlock: '0',
              fontSize: '16px',
              lineHeight: '22px',
              fontWeight: '500',
              ml: 1.5,
            }}
          >
            Copy/Move Post
          </Typography>
        </Box>
      </Popover>
      <Popover
        id={attachmentPopupId}
        open={openAttachmentPopup}
        anchorEl={anchorElAttachment}
        onClose={handleCloseAttachmentPopup}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
      >
        <List component="div">
          {isSavvy && (
            <ListItem
              ContainerComponent="div"
              button
              onClick={() => {
                if (!selectedAttachment) return;

                addExistingFile(selectedAttachment.id, selectedAttachment.name);
                handleCloseAttachmentPopup();
              }}
            >
              Attach again
            </ListItem>
          )}
          <ListItem
            ContainerComponent="div"
            button
            onClick={() => {
              if (!selectedAttachment) return;

              setShowCoi(true);
              setAttachmentId(selectedAttachment.id);
              setAttachmentName(selectedAttachment.name);
              handleCloseAttachmentPopup();
            }}
          >
            Create COI
          </ListItem>
          <ListItem
            ContainerComponent="div"
            button
            onClick={() => {
              if (!selectedAttachment) return;

              setShowMaintenanceEntry(true);
              setAttachmentId(selectedAttachment.id);
              setAttachmentName(selectedAttachment.name);
              handleCloseAttachmentPopup();
            }}
          >
            Create Maintenance Record
          </ListItem>
          {isSavvy && (
            <ListItem
              ContainerComponent="div"
              button
              onClick={() => {
                if (!selectedAttachment) return;

                setShowParseDialog(true);
                setDocumentId(selectedAttachment.document.id);
                setAttachmentName(selectedAttachment.name);
                handleCloseAttachmentPopup();
              }}
            >
              Upload as Engine Data File
            </ListItem>
          )}
          {isSavvy && (
            <ListItem
              ContainerComponent="div"
              button
              onClick={() => {
                if (!selectedAttachment) return;

                setShowAttachmentDialog(true);
                handleCloseAttachmentPopup();
              }}
            >
              Attachment Description
            </ListItem>
          )}
        </List>
      </Popover>
      <CreateCoiFromAttachment
        open={showCoi}
        setOpen={setShowCoi}
        attachmentId={attachmentId}
        attachmentName={attachmentName}
        aircraftId={aircraftId}
        ticketId={ticketId}
      />
      <CreateLogFromAttachment
        open={showMaintenanceEntry}
        setOpen={setShowMaintenanceEntry}
        attachmentId={attachmentId}
        aircraftId={aircraftId}
        fileName={attachmentName}
        ticketId={ticketId}
      />
      <ParseFlightsFromAttachment
        open={showParseDialog}
        setOpen={setShowParseDialog}
        documentId={documentId}
        fileName={attachmentName}
        ticketId={ticketId}
        aircraftId={aircraftId}
        registration={registration}
      />
      <PostArchiveDialog
        open={showArchiveDialog}
        setOpen={setShowArchiveDialog}
        workflowId={dataArchive?.createPostAttachmentsArchive?.workflowId || ''}
      />
    </Box>
  );
};

export default TicketPost;
