import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Dialog, DialogTitle, DialogContent, Box, TextField, MenuItem } from '@mui/material';
import { OverlayLoading } from 'components';
import { ISavvyTicket, IDropDownOption } from 'types';
import { convertTagNameToLabel, aircraftRegistrationMakeModel, secondsToHms } from 'helpers';

import { useLazyQuery } from '@apollo/client';
import { QUERY_TICKET_AIRCRAFT_INFO, QUERY_SAVVY_TICKET_ALLOWED_TAGS, QUERY_TICKET_CLIENT_INFO } from 'gql';

import {
  ANALYSIS_TAG,
  BREAKDOWN_TAG,
  TECHNICAL_SUPPORT_TAG,
  ACCOUNT_SUPPORT_TAG,
  SERVICE_ANNUAL_TAG,
  SERVICE_TAG,
  PARSE_FAILURE_TAG,
  FEVA_TAG,
  SERVICE_SIGNUP_TAG,
  PREBUY_TAG,
  PREBUY_INQUIRY_TAG,
} from 'const';
import { setAlert } from 'state';

import {
  AnalysisTag,
  BreakdownTag,
  TechnicalSupportTag,
  AccountSupportTag,
  ServiceAnnualTag,
  ServiceTag,
  ParseFailureTag,
  FevaTag,
  ServiceSignupTag,
  PrebuyTag,
} from './TagTypes';

interface AddTagDialogProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  ticket: ISavvyTicket;
}

const AddTagDialog: React.FC<AddTagDialogProps> = (props) => {
  const { open, setOpen, ticket } = props;

  const [aircrafts, setAircrafts] = useState<IDropDownOption[]>([]);
  const [files, setFiles] = useState<IDropDownOption[]>([]);
  const [flights, setFlights] = useState<IDropDownOption[]>([]);
  const [services, setServices] = useState<IDropDownOption[]>([]);

  const dispatch = useDispatch();

  const [fetchTicketCreatorInfo, { data: dataCreatorInfo, loading: loadingCreatorInfo, error: errorCreatorInfo }] =
    useLazyQuery(QUERY_TICKET_AIRCRAFT_INFO);
  const [fetchAllowedTags, { data: dataAllowedTags, loading: loadingAllowedTags, error: errorAllowedTags }] =
    useLazyQuery(QUERY_SAVVY_TICKET_ALLOWED_TAGS);
  const [fetchTicketClientInfo, { data: dataClientInfo, loading: loadingClientInfo, error: errorClientInfo }] =
    useLazyQuery(QUERY_TICKET_CLIENT_INFO);

  const [tagType, setTagType] = useState('');
  const [loadingTagCreate, setLoadingTagCreate] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (open && ticket.creator?.contact?.id) {
      fetchTicketCreatorInfo({
        variables: {
          contactId: ticket.aircraft?.owner ? ticket.aircraft?.owner.id : ticket.creator?.contact?.id,
          aircraftId: ticket.aircraft?.id ? parseInt(ticket.aircraft?.id) : undefined,
        },
      });
    }

    if (open && ticket) {
      fetchAllowedTags({
        variables: {
          ticketId: ticket.id,
        },
      });
    }
  }, [ticket, fetchTicketCreatorInfo, open, fetchAllowedTags]);

  useEffect(() => {
    if (open && ticket.aircraft) {
      let tmpServices: IDropDownOption[] | undefined = [];
      tmpServices = ticket.aircraft.subscriptions?.map((subscription: any) => {
        return {
          id: subscription.id,
          name: `${subscription.id}: ${subscription.serviceName}`,
        };
      });

      if (tmpServices && tmpServices.length) {
        setServices([...tmpServices]);
      }
    } else if (open && !ticket.aircraft && ticket.client) {
      fetchTicketClientInfo({
        variables: {
          contactId: ticket.client.id,
        },
      });
    }
  }, [open, ticket, fetchTicketClientInfo]);

  useEffect(() => {
    if (dataCreatorInfo && dataCreatorInfo.savvy?.contacts[0]) {
      const tmpFlights: IDropDownOption[] = [];
      const tmpFiles: IDropDownOption[] = [];

      const tmpAircrafts = dataCreatorInfo.savvy?.contacts[0].aircraft?.map((aircraft: any) => {
        aircraft?.flights?.map((flight: any) => {
          tmpFlights.push({
            id: flight.id,
            name: `${flight.date.toString().substring(0, 10) + ' ' + flight.date.toString().substring(11, 16)}, ${secondsToHms(
              flight.duration,
            )}, ${flight.departureAirport} -> ${flight.destinationAirport}`,
          });
          if (flight.importFile) {
            tmpFiles.push({
              id: flight.importFile.id,
              name: flight.importFile.name,
            });
          }

          return null;
        });

        return {
          id: aircraft.id,
          name: aircraftRegistrationMakeModel(aircraft),
        };
      });
      if (tmpAircrafts && tmpAircrafts.length) {
        setAircrafts([...tmpAircrafts]);
      }

      setFlights([...tmpFlights]);
      setFiles([...tmpFiles]);
    }
  }, [dataCreatorInfo]);

  useEffect(() => {
    if (dataClientInfo?.savvy?.contacts[0]) {
      const tmpServices: IDropDownOption[] | undefined = [];
      if (dataClientInfo?.savvy?.contacts[0].aircraft) {
        for (const aircraft of dataClientInfo.savvy.contacts[0].aircraft) {
          if (!aircraft) continue;

          for (const subscription of aircraft.subscriptions) {
            if (!subscription) continue;
            tmpServices.push({
              id: subscription.id,
              name: `${subscription.id}: ${subscription.serviceName}`,
            });
          }
        }
      }

      if (tmpServices && tmpServices.length) {
        setServices([...tmpServices]);
      }
    }
  }, [dataClientInfo]);

  useEffect(() => {
    if (errorCreatorInfo) {
      dispatch(setAlert('error', 'Unable to load ticket creator info'));
    }
  }, [errorCreatorInfo, dispatch]);

  useEffect(() => {
    if (errorClientInfo) {
      dispatch(setAlert('error', 'Unable to load ticket client info'));
    }
  }, [errorClientInfo, dispatch]);

  useEffect(() => {
    if (errorAllowedTags) {
      dispatch(setAlert('error', 'Unable to load allowed tags for ticket'));
    }
  }, [errorAllowedTags, dispatch]);

  return open ? (
    <Dialog open={open} fullWidth onClose={handleClose}>
      <DialogTitle
        sx={{
          textAlign: 'center',
          fontSize: '24px',
          fontWeight: 700,
        }}
      >
        Assign tag to #{ticket.id} {ticket.subject}
      </DialogTitle>
      <DialogContent
        sx={{
          px: {
            md: 5,
            xs: 2,
          },
          position: 'relative',
        }}
      >
        <Box py={1}>
          <TextField
            select={!!dataAllowedTags?.me?.tickets[0]?.allowedTags?.length}
            label="Select Tag"
            name="tagType"
            value={tagType}
            onChange={(e) => {
              setTagType(e.target.value);
            }}
            fullWidth
          >
            {dataAllowedTags?.me?.tickets[0]?.allowedTags?.map((allowedTag: string, key: number) => {
              if (allowedTag === PREBUY_INQUIRY_TAG) return null;
              return (
                <MenuItem key={key} value={allowedTag}>
                  {convertTagNameToLabel(allowedTag)}
                </MenuItem>
              );
            })}
          </TextField>
        </Box>
        <Box>
          {tagType === ANALYSIS_TAG && (
            <AnalysisTag ticketId={ticket.id} handleClose={handleClose} flights={flights} setLoading={setLoadingTagCreate} />
          )}
          {tagType === BREAKDOWN_TAG && <BreakdownTag ticketId={ticket.id} handleClose={handleClose} setLoading={setLoadingTagCreate} />}
          {tagType === TECHNICAL_SUPPORT_TAG && (
            <TechnicalSupportTag ticketId={ticket.id} handleClose={handleClose} setLoading={setLoadingTagCreate} />
          )}
          {tagType === ACCOUNT_SUPPORT_TAG && (
            <AccountSupportTag ticketId={ticket.id} handleClose={handleClose} aircrafts={aircrafts} setLoading={setLoadingTagCreate} />
          )}
          {tagType === SERVICE_ANNUAL_TAG && (
            <ServiceAnnualTag ticketId={ticket.id} handleClose={handleClose} setLoading={setLoadingTagCreate} />
          )}
          {tagType === SERVICE_TAG && (
            <ServiceTag ticketId={ticket.id} handleClose={handleClose} services={services} setLoading={setLoadingTagCreate} />
          )}
          {tagType === PARSE_FAILURE_TAG && (
            <ParseFailureTag ticketId={ticket.id} handleClose={handleClose} files={files} setLoading={setLoadingTagCreate} />
          )}
          {tagType === FEVA_TAG && (
            <FevaTag ticketId={ticket.id} handleClose={handleClose} aircrafts={aircrafts} setLoading={setLoadingTagCreate} />
          )}
          {tagType === SERVICE_SIGNUP_TAG && (
            <ServiceSignupTag ticketId={ticket.id} handleClose={handleClose} services={services} setLoading={setLoadingTagCreate} />
          )}
          {tagType === PREBUY_TAG && <PrebuyTag ticketId={ticket.id} handleClose={handleClose} setLoading={setLoadingTagCreate} />}
        </Box>
        <OverlayLoading loading={loadingCreatorInfo || loadingTagCreate || loadingAllowedTags || loadingClientInfo} />
      </DialogContent>
    </Dialog>
  ) : null;
};

export default AddTagDialog;
