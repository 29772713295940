import React, { useState, useEffect, Fragment } from 'react';

import { Dialog, DialogContent, DialogActions, Typography, Button, Grid, CircularProgress, MenuItem } from '@mui/material';
import { AircraftSuggestions } from './';

import { useFuzzyManufacturers } from 'hooks';

import { IOptionIdName, IModelAdditionalFields } from 'types';

import { useInputWithSuggestions } from 'hooks';

import * as Yup from 'yup';
import { ButtonSubmit } from 'components';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-mui';

interface IDialogAddAircraftSuggestions {
  manufacturer: IOptionIdName; // Autocomplete value
  setManufacturer: (value: IOptionIdName | null) => void; // Set Autocomplete value
  open: boolean;
  setOpen: (value: boolean) => void;
  setModel: (value: IOptionIdName | null) => void;
  setAdditionalFields: (value: IModelAdditionalFields | null) => void;
}

const validationSchema = Yup.object({
  manufacturer: Yup.string()
    .min(1, 'Too short: 1-50 characters required')
    .max(50, 'Too long: 1-50 characters required')
    .required('Required'),
  model: Yup.string().required('Required'),
  certification: Yup.string().required('Required'),
  propulsion: Yup.string().required('Required'),
  engine: Yup.string().required('Required'),
});

interface createFormValues {
  manufacturer: string;
  model: string;
  certification: string;
  propulsion: string;
  engine: string;
}

const DialogAddAircraftManufacturerSuggestions: React.FC<IDialogAddAircraftSuggestions> = (props) => {
  const { manufacturer, setManufacturer, open, setOpen, setModel, setAdditionalFields } = props;

  const [addState, setAddState] = useState(false);

  // Monitor Manufacturer and Model Inputs Logic Start
  // -----------------------------------------
  const [manufacturerInput, setManufacturerInput] = useState('');

  const [manufacturerInputTmp, setManufacturerInputTmp] = useState('');

  const { showFuzzy: showFuzzyManufacturers } = useInputWithSuggestions({ input: manufacturerInput }); //  Manufacturer Input id/name with Suggestions

  const handleSuggestionClickManufacturerHelper = (suggestion: IOptionIdName) => {
    setManufacturer(suggestion);
    handleClose();
  };

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (manufacturer?.id) return;
    setManufacturerInput(manufacturer?.name ? manufacturer.name : '');
  }, [manufacturer, setManufacturerInput]);

  useEffect(() => {
    if (!manufacturerInputTmp) {
      setManufacturerInput('');
      setLoading(false);
      return;
    }

    setLoading(true);

    const timeout: any = setTimeout(() => {
      setManufacturerInput(manufacturerInputTmp);
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [manufacturerInputTmp, setManufacturerInput]);

  // Fuzzy Logic
  const fuzzyManufacturers = useFuzzyManufacturers({ manufacturer, manufacturerInput }); // Fuzzy Manufacturers

  React.useEffect(() => {
    setLoading(false);
    if (!fuzzyManufacturers.length) setAddState(true);
    else setAddState(false);
  }, [fuzzyManufacturers]);

  // Close (Cancel click)
  const handleClose = () => {
    setOpen(false);
    setAddState(false);
    setManufacturerInput('');
  };

  const onSubmit = (values: createFormValues) => {
    setManufacturer({
      id: '',
      name: values.manufacturer,
    });

    setModel({
      id: '',
      name: values.model,
    });

    setAdditionalFields({
      certification: values.certification,
      propulsion: values.propulsion,
      engine: values.engine,
    });

    handleClose();
  };

  const notListClick = () => {
    setAddState(true);
  };

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={handleClose}
      PaperProps={{
        style: {
          height: '600px',
        },
      }}
    >
      <Formik
        initialValues={{
          manufacturer: manufacturer?.name,
          model: '',
          certification: '',
          propulsion: '',
          engine: '',
        }}
        onSubmit={onSubmit}
        keepMounted
        validationSchema={validationSchema}
      >
        {({ handleChange, handleBlur }) => {
          return (
            <Form
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <DialogContent>
                <Typography variant="h2" gutterBottom align="center">
                  Add custom manufacturer & model
                </Typography>
                <Field
                  name="manufacturer"
                  label="Manufacturer *"
                  onBlur={handleBlur}
                  onChange={(e: any) => {
                    handleChange(e);
                    setManufacturerInputTmp(e.target.value);
                  }}
                  placeholder="Start typing to search..."
                  component={TextField}
                  fullWidth
                  variant="outlined"
                  FormHelperTextProps={{
                    style: {
                      color: 'red',
                      marginLeft: 0,
                    },
                  }}
                  autoFocus
                  inputProps={{ maxLength: 50 }}
                />
                {loading && <CircularProgress color="inherit" size={20} variant="indeterminate" />}
                {!loading && !addState && showFuzzyManufacturers && (
                  <AircraftSuggestions
                    label="Did you perhaps mean:"
                    suggestions={fuzzyManufacturers}
                    handleSuggestionClick={handleSuggestionClickManufacturerHelper}
                    notListLabel="My aircraft manufacturer is not listed"
                    notListClick={notListClick}
                  />
                )}
                {addState && (
                  <Fragment>
                    <Field
                      name="model"
                      label="Model *"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      component={TextField}
                      fullWidth
                      variant="outlined"
                      FormHelperTextProps={{
                        style: {
                          color: 'red',
                          marginLeft: 0,
                        },
                      }}
                      inputProps={{ maxLength: 50 }}
                    />
                    <Field
                      name="certification"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      component={TextField}
                      label="Certification"
                      select
                      variant="outlined"
                      fullWidth
                      FormHelperTextProps={{
                        style: {
                          color: 'red',
                          marginLeft: 0,
                        },
                      }}
                    >
                      <MenuItem value={'certificated'}>Certificated</MenuItem>
                      <MenuItem value={'experimental'}>Experimental</MenuItem>
                    </Field>
                    <Field
                      name="propulsion"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      component={TextField}
                      label="Propulsion"
                      select
                      variant="outlined"
                      fullWidth
                      FormHelperTextProps={{
                        style: {
                          color: 'red',
                          marginLeft: 0,
                        },
                      }}
                    >
                      <MenuItem value={'piston'}>Piston</MenuItem>
                      <MenuItem value={'turbine'}>Turbine</MenuItem>
                      <MenuItem value={'jet'}>Jet</MenuItem>
                    </Field>
                    <Field
                      name="engine"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      component={TextField}
                      label="Engine(s)"
                      select
                      variant="outlined"
                      fullWidth
                      FormHelperTextProps={{
                        style: {
                          color: 'red',
                          marginLeft: 0,
                        },
                      }}
                    >
                      <MenuItem value={'single'}>Single</MenuItem>
                      <MenuItem value={'twin'}>Twin</MenuItem>
                    </Field>
                  </Fragment>
                )}
              </DialogContent>

              <DialogActions>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Button color="secondary" variant="outlined" onClick={handleClose} fullWidth>
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <ButtonSubmit text="Add" disabled={!addState} />
                  </Grid>
                </Grid>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default DialogAddAircraftManufacturerSuggestions;
