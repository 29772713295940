import React from 'react';
import { Typography, Box } from '@mui/material';
import { LayoutDashboard } from 'components';
import { ServiceCenterMap } from './components';

const ServiceCenterMapPage: React.FC = () => {
  return (
    <LayoutDashboard backgroundColor="#FFFFFF">
      <Box
        sx={{
          p: {
            lg: 5,
            xs: 2,
          },
        }}
      >
        <Typography variant="h2" gutterBottom>
          Service Center Map
        </Typography>
        <ServiceCenterMap />
      </Box>
    </LayoutDashboard>
  );
};

export default ServiceCenterMapPage;
