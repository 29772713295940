import { useSelector, useDispatch } from 'react-redux';
import { SET_TABLE_VIEW_CONFIG } from 'state/types';

const useTableConfig = () => {
  // State
  const dispatch = useDispatch();
  const { density } = useSelector((state: any) => state.tableConfig);
  const setDensity = (value: string) => {
    dispatch({
      type: SET_TABLE_VIEW_CONFIG,
      payload: {
        density: value,
      },
    });
  };

  // Return values and functions to use in component
  return {
    density,
    setDensity,
  };
};

export default useTableConfig;
