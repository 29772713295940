import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, Box, TextField, Button } from '@mui/material';

import { ComponentLoading } from 'components';
import { MUTATION_UPDATE_FLIGHT_NOTES } from 'gql';
import { useMutation } from '@apollo/client';
import { FLIGHT_NOTES_MAX_LENGTH } from 'const';

interface SaveNoteDialogProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  originalNote: string;
  setFlightNote: (value: string) => void;
  flightId: string;
}

const SaveNoteDialog: React.FC<SaveNoteDialogProps> = (props) => {
  const { open, setOpen, originalNote, setFlightNote, flightId } = props;

  const [note, setNote] = useState(originalNote);
  const [noteError, setNoteError] = useState(false);

  const [saveNotes, { data: dataSaveNotes, loading: loadingSaveNotes }] = useMutation(MUTATION_UPDATE_FLIGHT_NOTES);

  const handleClose = () => {
    setNote(originalNote);
    setOpen(false);
  };

  const onSubmit = async () => {
    if (note === '') {
      setNoteError(true);
      return;
    }

    await saveNotes({
      variables: {
        flightId: flightId,
        note: note,
      },
    });
  };

  useEffect(() => {
    if (!loadingSaveNotes && dataSaveNotes) {
      if (dataSaveNotes.updateFlightNote?.ok) {
        setOpen(false);
        setFlightNote(dataSaveNotes.updateFlightNote.flight.notes);
      }
    }
  }, [dataSaveNotes, loadingSaveNotes, setOpen]);

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={handleClose}
      PaperProps={{
        sx: {
          maxWidth: '440px',
        },
      }}
    >
      <DialogTitle
        sx={{
          textAlign: 'center',
          fontSize: '24px',
          fontWeight: 700,
        }}
      >
        Edit Note
      </DialogTitle>
      <DialogContent>
        <ComponentLoading loading={loadingSaveNotes}>
          <Box py={1}>
            <TextField
              name="note"
              label="Note"
              value={note}
              multiline
              rows={5}
              onChange={(e) => {
                setNote(e.target.value);
                setNoteError(false);
              }}
              fullWidth
              error={noteError}
              helperText={noteError ? 'Please type notes' : ''}
              FormHelperTextProps={{
                style: {
                  marginLeft: '5px',
                },
              }}
              inputProps={{
                maxLength: FLIGHT_NOTES_MAX_LENGTH,
              }}
            />
            <Box
              sx={{
                textAlign: 'right',
              }}
            >
              <Button
                color="primary"
                variant="contained"
                sx={{
                  width: '100px',
                }}
                onClick={() => {
                  onSubmit();
                }}
              >
                Save
              </Button>
              <Button
                variant="outlined"
                color="warning"
                sx={{
                  width: '100px',
                  ml: 2,
                }}
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </ComponentLoading>
      </DialogContent>
    </Dialog>
  );
};

export default SaveNoteDialog;
