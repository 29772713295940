import React from 'react';

import { IconButton, Menu, MenuItem, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { NavLink as RouterLink } from 'react-router-dom';

import { IAppBarLink } from 'types';

import styles from './style.module.scss';

import { LOGGED_IN_SAVVY_LINKS } from 'const';
import { UserMenu, HelpMenu } from '../';
import { logAmplitudeEvent } from 'services';

interface CollpasedSavvyMenuProps {
  role: string;
}

const CollpasedSavvyMenu: React.FC<CollpasedSavvyMenuProps> = (props) => {
  const { role } = props;

  const ALL_LINKS: IAppBarLink[] = [...LOGGED_IN_SAVVY_LINKS];

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleClick}>
        <MenuIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {ALL_LINKS.map((link: IAppBarLink) => (
          <MenuItem
            key={link.path}
            onClick={() => {
              logAmplitudeEvent('menu_' + link.text.replace(' ', '_').toLowerCase(), {
                role,
              });
            }}
          >
            <RouterLink to={link.path} exact={link.exact} className={styles.navLink}>
              <Typography variant="body1">{link.text}</Typography>
            </RouterLink>
          </MenuItem>
        ))}
        <UserMenu role={role} />
        <HelpMenu />
      </Menu>
    </>
  );
};

export default CollpasedSavvyMenu;
