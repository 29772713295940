import {
  SavvyMxDescription,
  SavvyQaDescription,
  SavvyAnalysisDescription,
  SavvyPrebuyDescription,
  SavvyBreakdownDescription,
  SavvySupportDescription,
  SavvyBasicDescription,
  SavvyAnalysisProDescription,
} from './components';

import {
  SAVVY_MX,
  SAVVY_QA,
  SAVVY_ANALYSIS,
  SAVVY_PREBUY,
  SAVVY_BREAKDOWN,
  SAVVY_SUPPORT,
  SAVVY_BASIC,
  SAVVY_ANALYSIS_PRO,
  PATH_SIGNUP_SAVVY_MX,
  PATH_SIGNUP_SAVVY_QA,
  PATH_SIGNUP_SAVVY_ANALYSIS,
  PATH_SIGNUP_SAVVY_PREBUY,
  PATH_SIGNUP_SAVVY_BREAKDOWN,
  PATH_SIGNUP_SAVVY_SUPPORT,
  PATH_SIGNUP_SAVVY_BASIC,
} from 'const';

import { IServices } from 'types';

// Service array. Used to render services in Enroll page and to show particular Service data in Signup-for-a-Service page
const services: IServices = [
  {
    id: SAVVY_MX,
    name: 'SavvyMX',
    description: <SavvyMxDescription />,
    link: PATH_SIGNUP_SAVVY_MX,
  },
  {
    id: SAVVY_QA,
    name: 'SavvyQA',
    description: <SavvyQaDescription />,
    link: PATH_SIGNUP_SAVVY_QA,
  },
  {
    id: SAVVY_ANALYSIS,
    name: 'SavvyAnalysis',
    description: <SavvyAnalysisDescription />,
    link: PATH_SIGNUP_SAVVY_ANALYSIS,
  },
  {
    id: SAVVY_ANALYSIS_PRO,
    name: 'SavvyAnalysisPro',
    description: <SavvyAnalysisProDescription />,
    link: PATH_SIGNUP_SAVVY_ANALYSIS,
  },
  {
    id: SAVVY_BASIC,
    name: 'SavvyBasics',
    description: <SavvyBasicDescription />,
    link: PATH_SIGNUP_SAVVY_BASIC,
  },
  {
    id: SAVVY_PREBUY,
    name: 'SavvyPrebuy',
    description: <SavvyPrebuyDescription />,
    link: PATH_SIGNUP_SAVVY_PREBUY,
  },
  {
    id: SAVVY_BREAKDOWN,
    name: 'SavvyBreakdown',
    description: <SavvyBreakdownDescription />,
    link: PATH_SIGNUP_SAVVY_BREAKDOWN,
  },
  {
    id: SAVVY_SUPPORT,
    name: 'SavvySupport',
    description: <SavvySupportDescription />,
    link: PATH_SIGNUP_SAVVY_SUPPORT,
  },
];

export default services;
