import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Typography, Link } from '@mui/material';
import { ComponentLoading } from 'components';

import { QUERY_PREBUY_INQUIRY_SEARCH } from 'gql';
import { useLazyQuery } from '@apollo/client';
import { SAVVY_SEARCH_LIMIT, PATH_SAVVY_PREBUY_INQUIRY, PATH_SAVVY_SEARCH_PREBUY_INQUIRY } from 'const';
import { ctrlCmdClick } from 'helpers';

interface PrebuyInquirySearchProps {
  searchQuery: string;
}

const PrebuyInquirySearch: React.FC<PrebuyInquirySearchProps> = (props) => {
  const { searchQuery } = props;

  const history = useHistory();

  const [fetchPrebuyInquiry, { data: dataPrebuyInquiry, loading: loadingPrebuyInquiry }] = useLazyQuery(QUERY_PREBUY_INQUIRY_SEARCH);

  const [count, setCount] = useState(0);

  const loadMore = () => {
    history.push(`${PATH_SAVVY_SEARCH_PREBUY_INQUIRY}?query=${searchQuery}`);
  };

  useEffect(() => {
    if (!searchQuery || searchQuery.length < 2) {
      return;
    }

    const timeout: NodeJS.Timeout = setTimeout(() => {
      fetchPrebuyInquiry({
        variables: {
          search: searchQuery,
          limit: SAVVY_SEARCH_LIMIT,
          offset: 0,
        },
      });
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [searchQuery, fetchPrebuyInquiry]);

  useEffect(() => {
    if (dataPrebuyInquiry?.savvy?.search?.prebuys) {
      setCount(dataPrebuyInquiry?.savvy?.search?.prebuys?.count);
    }
  }, [dataPrebuyInquiry]);

  return (
    <Box>
      <ComponentLoading loading={loadingPrebuyInquiry}>
        <Box>
          {!!searchQuery && (
            <Typography
              variant="h5"
              sx={{
                fontWeight: 'bold',
                py: 1,
              }}
            >
              Prebuy Inquiry {count ? `(${count})` : ''}
            </Typography>
          )}
          <Box pl={4}>
            {dataPrebuyInquiry?.savvy?.search?.prebuys?.results?.map((prebuy: any, key: number) => {
              return (
                <Box
                  key={key}
                  py={1}
                  sx={{
                    cursor: 'pointer',
                  }}
                  onClick={(event: any) => {
                    if (prebuy?.id) {
                      ctrlCmdClick(event, `${PATH_SAVVY_PREBUY_INQUIRY}/${prebuy.id}`, history);
                    }
                  }}
                >
                  <Typography>
                    {prebuy?.contact
                      ? `${prebuy.contact.firstName} ${prebuy.contact.lastName}`
                      : `${prebuy.emailContact.firstName} ${prebuy.emailContact.lastName}`}{' '}
                    {prebuy.candidateRegistration}
                  </Typography>
                </Box>
              );
            })}
            {!dataPrebuyInquiry?.savvy?.search?.prebuys?.results?.length && !!searchQuery && !loadingPrebuyInquiry && (
              <Box py={1}>No matched prebuy inquiry</Box>
            )}
          </Box>
          {count > dataPrebuyInquiry?.savvy?.search?.prebuys?.results?.length && (
            <Link
              href="#"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                loadMore();
              }}
            >
              Show More
            </Link>
          )}
        </Box>
      </ComponentLoading>
    </Box>
  );
};

export default PrebuyInquirySearch;
