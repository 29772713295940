import React, { Fragment } from 'react';

import { Box } from '@mui/material';

import { CircularProgress } from '@mui/material';

interface IAbsoluteLoading {
  loading: boolean;
}

const AbsoluteLoading: React.FC<IAbsoluteLoading> = (props) => {
  const { loading } = props;

  return loading ? (
    <Fragment>
      <Box position="absolute" top="0" left="0" width="100%" height="100%" zIndex={1} bgcolor="rgba(255,255,255,0.4)" />
      <Box position="absolute" top="50%" marginTop="-10px" marginLeft="-10px" left="50%" zIndex={2}>
        <CircularProgress color="inherit" size={20} variant="indeterminate" />
      </Box>
    </Fragment>
  ) : null;
};

export default AbsoluteLoading;
