import React, { Fragment } from 'react';
import { Typography, Collapse, Hidden } from '@mui/material';

import { Subsection, List, ButtonMore } from 'components';
import { useMdUp } from 'hooks';

const newFeatures = [
  'Unlimited access to Savvy’s top notch A&P/IA experts',
  'Impartial second opinions on repair services and estimates',
  'Recommendation for best service centers and maintenance technicians',
  'Invoice reviews upon request',
  'Maintenance logbook reviews upon request',
  'Systematic troubleshooting plans upon request',
  'Engine data analysis and interpretation by our professional analysts',
  'Automated engine monitor Report Cards, Trend Reports and FEVA2 exhaust valve analytics reports',
  '24/7/365 quick response breakdown assistance when you’re away from home',
];

const newFees = [
  'Piston single: $449/year',
  'Piston twin: $599/year',
  'Turboprop single: $899/year',
  'Turboprop twin: $1,199/year',
  'Turbojet single: $1,199/year',
];

const SavvyQaDescription: React.FC = () => {
  const [open, setOpen] = React.useState(false);
  const isMdUp = useMdUp();

  return (
    <Fragment>
      <Subsection>
        <Typography paragraph>
          SavvyQA is for experienced aircraft owners who prefer to manage their maintenance, deal directly with shops and mechanics, and
          handle their record keeping on their own but with access to our impartial, trusted and experienced advice when they need it.
          SavvyQA also includes all the data analysis services of SavvyAnalysis and 24/7 breakdown assistance away from home.
        </Typography>
      </Subsection>
      <Collapse in={open || isMdUp}>
        <Subsection>
          <Typography variant="h4" gutterBottom>
            By enrolling in SavvyQA, you will receive:
          </Typography>
          <List list={newFeatures} />
        </Subsection>
        <Subsection>
          <Typography variant="h4" gutterBottom>
            SavvyQA Service Annual Fees:
          </Typography>
          <List list={newFees} />
        </Subsection>
      </Collapse>
      {!isMdUp && (
        <Hidden mdUp>
          <Subsection>
            <ButtonMore open={open} setOpen={setOpen} />
          </Subsection>
        </Hidden>
      )}
    </Fragment>
  );
};

export default SavvyQaDescription;
