import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Dialog, DialogContent, Box, Typography, IconButton, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import { Document, Page } from 'react-pdf';
import { ComponentLoading } from 'components';
import { setAlert } from 'state';
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

interface PdfDialogProps {
  showFileDialog: boolean;
  setShowFileDialog: (value: boolean) => void;
  showFileName: string;
  showFile: string;
  previewAttachmentId: string;
  nextAttachmentLoad?: (id: string) => void;
  prevAttachmentLoad?: (id: string) => void;
  loadingAttachmentData?: boolean;
}

const PdfDialog: React.FC<PdfDialogProps> = (props) => {
  const {
    showFileDialog,
    setShowFileDialog,
    showFileName,
    showFile,
    previewAttachmentId,
    nextAttachmentLoad,
    prevAttachmentLoad,
    loadingAttachmentData = false,
  } = props;

  const [zoom, setZoom] = useState(1);
  const [rotate, setRotate] = useState(0);

  const dispatch = useDispatch();

  const getPdfWidth = () => {
    const windowWidth = window.innerWidth;
    if (windowWidth > 400) {
      return windowWidth - 64 - 48;
    } else {
      return windowWidth - 48;
    }
  };

  const [pageWidth, setPageWidth] = useState(getPdfWidth());

  const options = {
    cMapUrl: 'cmaps/',
    cMapPacked: true,
  };

  const [numPages, setNumPages] = useState<any>(null);
  const [pageNum, setPageNum] = useState(1);

  const [loaded, setLoaded] = useState(false);
  const [zoomed, setZoomed] = useState(false);

  const onDocumentLoadSuccess = (pdf: any) => {
    setNumPages(pdf?.numPages);
    setLoaded(true);
  };

  const containerRef = React.createRef<HTMLDivElement>();

  const onLoadSuccessPage = (page: any) => {
    let windowWidth = window.innerWidth - 48;
    let windowHeight = window.innerHeight - 124;
    if (windowWidth > 400) {
      windowWidth -= 64;
      windowHeight -= 64;
    }

    if (!zoomed) {
      setZoom(Math.min(windowWidth / page.view[2], windowHeight / page.view[3]));
      setZoomed(true);
    }
    setPageWidth(page.view[2]);
  };

  useEffect(() => {
    setLoaded(false);
    setZoomed(false);
  }, [showFile]);

  const handleClose = () => {
    setShowFileDialog(false);
    setNumPages(null);
    setZoom(1);
    setRotate(0);
    setPageNum(1);
    document.removeEventListener('keypress', keyDown);
  };

  const nextPage = () => {
    if (pageNum < numPages) setPageNum(pageNum + 1);
  };

  const previousPage = () => {
    if (pageNum > 1) setPageNum(pageNum - 1);
  };

  const zoomIn = () => {
    if (zoom < 1.5) setZoom(zoom + 0.1);
  };

  const zoomOut = () => {
    if (zoom > 0.1) setZoom(zoom - 0.1);
  };

  const rotateDocument = () => {
    if (rotate === 270) {
      setRotate(0);
    } else {
      setRotate(rotate + 90);
    }
  };

  const keyDown = useCallback(
    (event: any) => {
      if (!showFileDialog) return false;

      if (event.key === 'ArrowRight') {
        if (pageNum < numPages) {
          setPageNum(pageNum + 1);
        }
        return;
      }

      if (event.key === 'ArrowLeft') {
        if (pageNum > 1) setPageNum(pageNum - 1);
        return;
      }
    },
    [pageNum, numPages, showFileDialog],
  );

  useEffect(() => {
    document.addEventListener('keydown', keyDown);
    return () => {
      document.removeEventListener('keydown', keyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyDown]);

  useEffect(() => {
    if (showFile) {
      setPageNum(1);
    }
  }, [showFile]);

  return (
    <Dialog
      open={showFileDialog}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
      PaperProps={{
        sx: {
          m: {
            xs: 0,
            sm: 4,
          },
          maxWidth: {
            xs: '100%',
            sm: 'calc(100% - 64px)',
          },
          width: {
            xs: '100%',
            sm: 'calc(100% - 64px)',
          },
          height: {
            xs: '100vh',
            sm: 'calc(100vh - 64px)',
          },
        },
      }}
    >
      <DialogContent
        sx={{
          pt: 0,
        }}
      >
        <ComponentLoading loading={loadingAttachmentData}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              pt: 1,
            }}
          >
            <Typography
              variant="h2"
              sx={{
                fontWeight: 700,
                fontSize: {
                  lg: '22px',
                  md: '18px',
                  xs: '16px',
                },
                width: '45%',
              }}
            >
              {showFileName}
            </Typography>
            <Box
              sx={{
                width: '10%',
                textAlign: 'center',
              }}
            >
              <Box
                sx={{
                  display: 'inline-flex',
                  justifyContent: 'center',
                  margin: 'auto',
                  padding: '4px 10px',
                  backgroundColor: 'grey.800',
                  borderRadius: '6px',
                  alignItems: 'center',
                }}
              >
                <TextField
                  name="pageNum"
                  value={pageNum}
                  label=""
                  onChange={(e: any) => {
                    const value = parseInt(e.target.value);
                    if (value > numPages) {
                      setPageNum(numPages);
                    } else if (value < 1) {
                      setPageNum(1);
                    } else {
                      setPageNum(value);
                    }
                  }}
                  type="number"
                  sx={{
                    mb: 0,
                  }}
                  InputProps={{
                    sx: {
                      width: '50px',
                    },
                  }}
                  inputProps={{
                    sx: {
                      padding: '0 2px',
                      textAlign: 'center',
                      fontSize: '18px',
                      fontWeight: 700,
                      color: 'grey.600',
                    },
                    max: numPages,
                    min: 1,
                  }}
                />
                <Typography
                  sx={{
                    fontSize: {
                      md: '18px',
                      xs: '16px',
                    },
                    lineHeight: {
                      md: '28px',
                      xs: '24px',
                    },
                    fontWeight: 700,
                    color: 'grey.600',
                  }}
                >
                  /&nbsp;{numPages}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                width: '45%',
                textAlign: 'right',
              }}
            >
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <Box
            py={1}
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {prevAttachmentLoad && (
              <IconButton
                sx={{
                  mx: 1,
                }}
                onClick={() => {
                  prevAttachmentLoad(previewAttachmentId);
                }}
                title="Previous Attachment"
              >
                <SkipPreviousIcon />
              </IconButton>
            )}
            <IconButton
              sx={{
                mx: 1,
              }}
              onClick={previousPage}
            >
              <ArrowBackIosIcon />
            </IconButton>
            <IconButton
              sx={{
                mx: 1,
              }}
              onClick={zoomIn}
            >
              <AddIcon />
            </IconButton>
            <IconButton
              sx={{
                mx: 1,
              }}
              onClick={zoomOut}
            >
              <RemoveIcon />
            </IconButton>
            <IconButton
              sx={{
                mx: 1,
              }}
              onClick={rotateDocument}
            >
              <RotateRightIcon />
            </IconButton>
            <IconButton
              sx={{
                mx: 1,
              }}
              onClick={nextPage}
            >
              <ArrowForwardIosIcon />
            </IconButton>
            {nextAttachmentLoad && (
              <IconButton
                sx={{
                  mx: 1,
                }}
                onClick={() => {
                  nextAttachmentLoad(previewAttachmentId);
                }}
                title="Next Attachment"
              >
                <SkipNextIcon />
              </IconButton>
            )}
          </Box>
          <Box
            sx={{
              '& .react-pdf__Page': {
                background: '#B4B9CC',
                pt: 6,
              },
              '& .react-pdf__Page__canvas': {
                margin: 'auto',
              },
            }}
            ref={containerRef}
          >
            <Document
              file={showFile}
              onLoadSuccess={onDocumentLoadSuccess}
              options={options}
              rotate={rotate}
              onLoadError={(error: Error) => {
                if (error) {
                  dispatch(setAlert('error', error.message));
                }
              }}
            >
              {loaded && (
                <Page
                  pageNumber={pageNum}
                  onLoadSuccess={onLoadSuccessPage}
                  width={pageWidth}
                  scale={zoom}
                  renderAnnotationLayer={false}
                  renderTextLayer={false}
                />
              )}
            </Document>
          </Box>
        </ComponentLoading>
      </DialogContent>
    </Dialog>
  );
};

export default PdfDialog;
