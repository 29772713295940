import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, Box, DialogTitle, DialogContent, TextField, MenuItem, DialogActions, Grid, Button, Typography } from '@mui/material';

import { Dayjs } from 'dayjs';

import {
  MUTATION_CREATE_MAINTENANCE_ENTRY_FROM_ATTACHMENT,
  MUTATION_CREATE_DEFAULT_SET_OF_LOGBOOKS,
  QUERY_GET_TICKETS_AIRCRAFT,
  QUERY_MAINTENANCE_RECORD_CATEGORIES,
  QUERY_SAVVY_AIRCRAFT_LOGBOOKS,
  QUERY_ME_AIRCRAFT_LOGBOOKS,
} from 'gql';
import { useLazyQuery, useMutation } from '@apollo/client';
import { setAlert } from 'state';
import { aircraftRegistrationMakeModel } from 'helpers';

import { ComponentLoading, Checkbox, CustomDatePicker } from 'components';
import { ILogbook } from 'types';

interface CreateLogFromAttachmentProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  attachmentId: string;
  aircraftId: string;
  fileName: string;
  ticketId: string;
}

const CreateLogFromAttachment: React.FC<CreateLogFromAttachmentProps> = (props) => {
  const { open, setOpen, attachmentId, aircraftId, ticketId } = props;

  const [createMaintenanceEntry, { data, loading, error }] = useMutation(MUTATION_CREATE_MAINTENANCE_ENTRY_FROM_ATTACHMENT);
  const [fetchAircraft, { data: dataAircraft, loading: loadingAircraft, error: errorAircraft }] = useLazyQuery(QUERY_GET_TICKETS_AIRCRAFT);
  const [fetchMaintenanceCategory, { data: dataCategory, loading: loadingCategory, error: errorCategory }] =
    useLazyQuery(QUERY_MAINTENANCE_RECORD_CATEGORIES);

  const [fetchSavvyLogbooks, { data: dataSavvyLogbooks, loading: loadingSavvyLogbooks, error: errorSavvyLogbooks }] =
    useLazyQuery(QUERY_SAVVY_AIRCRAFT_LOGBOOKS);
  const [fetchLogbooks, { data: dataLogbooks, loading: loadingLogbooks, error: errorLogbooks }] = useLazyQuery(QUERY_ME_AIRCRAFT_LOGBOOKS);

  const [createDefaultLogbooks, { data: dataDefaultLogbooks, loading: loadingDefaultLogbooks, error: errorDefaultLogbooks }] = useMutation(
    MUTATION_CREATE_DEFAULT_SET_OF_LOGBOOKS,
  );

  const dispatch = useDispatch();
  const { isSavvy } = useSelector((state: any) => state.auth);

  const [isPerformedInspection, setIsPerformedInspection] = useState(false);
  const [isPerformedMaintenance, setIsPerformedMaintenance] = useState(false);

  const [entryDate, setEntryDate] = useState<Dayjs | null>(null);
  const [entryDateError, setEntryDateError] = useState('');

  const [logbookId, setLogbookId] = useState('-1');
  const [logbookIdError, setLogbookIdError] = useState('');

  const [maintenanceEntryCategoryId, setMaintenanceEntryCategoryId] = useState('-1');
  const [recordTypeError, setRecordTypeError] = useState('');

  const [selectAircraftId, setSelectAircraftId] = useState('');
  const [aircraftError, setAircraftError] = useState('');

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickDefaultLogbook = async () => {
    await createDefaultLogbooks({
      variables: {
        aircraftId,
      },
    });
  };

  const onSubmit = async () => {
    if (!aircraftId && !selectAircraftId) {
      setAircraftError('Please select aircraft');
      return;
    }

    if (!entryDate) {
      setEntryDateError('Please input entry date');
      return;
    }

    if (!logbookId || logbookId === '-1') {
      setLogbookIdError('Please select logbook');
      return;
    }

    if (!maintenanceEntryCategoryId || maintenanceEntryCategoryId === '-1') {
      setRecordTypeError('Please select maintenance record type');
      return;
    }

    await createMaintenanceEntry({
      variables: {
        aircraftId: aircraftId ? aircraftId : selectAircraftId,
        attachmentId,
        isPerformedInspection,
        isPerformedMaintenance,
        entryDate,
        maintenanceEntryCategoryId,
        logbookId,
      },
    });
  };

  useEffect(() => {
    if (open && isSavvy) {
      fetchSavvyLogbooks({
        variables: {
          aircraftId,
        },
      });
    } else if (open && !isSavvy && (aircraftId || selectAircraftId)) {
      fetchLogbooks({
        variables: {
          id: aircraftId ? parseInt(aircraftId) : parseInt(selectAircraftId),
        },
      });
    }
  }, [open, fetchLogbooks, aircraftId, isSavvy, fetchSavvyLogbooks, selectAircraftId]);

  useEffect(() => {
    if (error) {
      dispatch(setAlert('error', 'Unable to create maintenance record'));
    } else if (data) {
      if (data?.createMaintenanceEntryFromAttachment?.ok) {
        dispatch(setAlert('success', 'Created maintenance record from attachment'));
        setOpen(false);
      } else {
        dispatch(setAlert('error', data?.createMaintenanceEntryFromAttachment?.error || 'Unable to create maintenance record'));
      }
    }
  }, [error, data, dispatch, setOpen]);

  useEffect(() => {
    if (errorLogbooks) {
      dispatch(setAlert('error', 'Unable to fetch logbooks'));
    }
  }, [errorLogbooks, dispatch]);

  useEffect(() => {
    if (errorSavvyLogbooks) {
      dispatch(setAlert('error', 'Unable to fetch logbooks'));
    }
  }, [errorSavvyLogbooks, dispatch]);

  useEffect(() => {
    if (errorDefaultLogbooks) {
      dispatch(setAlert('error', 'Unable to set default logbook'));
    } else if (dataDefaultLogbooks) {
      if (!dataDefaultLogbooks.createDefaultSetOfLogbooks?.ok) {
        dispatch(setAlert('error', dataDefaultLogbooks.createDefaultSetOfLogbooks.error || 'Unable to set default logbook'));
      }
    }
  }, [errorDefaultLogbooks, dispatch, dataDefaultLogbooks]);

  useEffect(() => {
    if (!open) {
      setEntryDate(null);
      setIsPerformedMaintenance(false);
      setIsPerformedInspection(false);
      setMaintenanceEntryCategoryId('-1');
      setSelectAircraftId('');
      setLogbookId('-1');
    }
  }, [open]);

  useEffect(() => {
    if (open && !aircraftId) {
      fetchAircraft({
        variables: {
          ticketId: parseInt(ticketId),
        },
      });
    }
  }, [open, aircraftId, ticketId, fetchAircraft]);

  useEffect(() => {
    if (errorAircraft) {
      dispatch(setAlert('error', "Unable to load ticket's aircraft"));
    }
  }, [dispatch, errorAircraft]);

  useEffect(() => {
    if (dataAircraft?.me?.tickets[0]?.client?.contact) {
      if (!dataAircraft?.me?.tickets[0]?.client.contact.aircraft?.length) {
        dispatch(setAlert('error', "This ticket doesn't have related aircraft."));
      }
    }
  }, [dispatch, dataAircraft]);

  useEffect(() => {
    if (open) {
      fetchMaintenanceCategory();
    }
  }, [open, fetchMaintenanceCategory]);

  useEffect(() => {
    if (errorCategory) {
      dispatch(setAlert('error', 'Unable to fetch maintenance record category'));
    }
  }, [errorCategory, dispatch]);

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={handleClose}
      PaperProps={{
        sx: {
          maxWidth: '400px',
        },
      }}
    >
      <DialogTitle
        sx={{
          textAlign: 'center',
          fontSize: '24px',
          fontWeight: 700,
        }}
      >
        Create Maintenance Record
      </DialogTitle>
      <DialogContent>
        <ComponentLoading
          loading={loading || loadingAircraft || loadingCategory || loadingLogbooks || loadingSavvyLogbooks || loadingDefaultLogbooks}
        >
          {!aircraftId && !!dataAircraft?.me?.tickets[0]?.client?.contact?.aircraft?.length && (
            <Box py={1}>
              <TextField
                select
                label="Aircraft *"
                name="aircraft"
                value={selectAircraftId}
                onChange={(e: any) => {
                  setSelectAircraftId(e.target.value);
                  setAircraftError('');
                }}
                error={!!aircraftError}
                helperText={aircraftError}
                FormHelperTextProps={{
                  sx: {
                    ml: 0,
                  },
                }}
                fullWidth
              >
                {dataAircraft?.me?.tickets[0]?.client?.contact?.aircraft?.map((item: any, key: number) => {
                  return (
                    <MenuItem value={item.id} key={key}>
                      {aircraftRegistrationMakeModel(item)}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Box>
          )}
          <Box py={1}>
            <Checkbox
              value={isPerformedMaintenance}
              checked={isPerformedMaintenance}
              label={
                <Typography
                  sx={{
                    display: 'inline-block',
                  }}
                >
                  Performed Maintenance?
                </Typography>
              }
              onChange={() => {
                setIsPerformedMaintenance(!isPerformedMaintenance);
              }}
              noMargin
            />
          </Box>
          <Box py={1}>
            <Checkbox
              value={isPerformedInspection}
              checked={isPerformedInspection}
              label={
                <Typography
                  sx={{
                    display: 'inline-block',
                  }}
                >
                  Performed Inspection?
                </Typography>
              }
              onChange={() => {
                setIsPerformedInspection(!isPerformedInspection);
              }}
              noMargin
            />
          </Box>
          <Box py={1}>
            <CustomDatePicker
              label="Entry Date"
              value={entryDate}
              setValue={setEntryDate}
              error={entryDateError}
              setError={setEntryDateError}
              sx={{
                ml: 0,
              }}
              containerSx={{
                mb: 0,
              }}
            />
          </Box>
          <Box py={1}>
            <TextField
              name="logbookId"
              label="Logbook *"
              value={logbookId}
              onChange={(e) => {
                setLogbookId(e.target.value);
                setLogbookIdError('');
              }}
              error={!!logbookIdError}
              helperText={logbookIdError}
              FormHelperTextProps={{
                sx: {
                  ml: 0,
                },
              }}
              sx={{
                mb: 0,
              }}
              select
              fullWidth
            >
              <MenuItem value="-1">Select Logbook</MenuItem>
              {isSavvy &&
                dataSavvyLogbooks?.savvy?.aircraft?.logbooks?.map((logbook: ILogbook, key: number) => {
                  return (
                    <MenuItem key={key} value={logbook.id}>
                      {logbook.name}
                    </MenuItem>
                  );
                })}
              {!isSavvy &&
                dataLogbooks?.me?.aircraft[0]?.logbooks?.map((logbook: ILogbook, key: number) => {
                  return (
                    <MenuItem key={key} value={logbook.id}>
                      {logbook.name}
                    </MenuItem>
                  );
                })}
            </TextField>
          </Box>
          {!dataSavvyLogbooks?.savvy?.aircraft?.logbooks?.length && !dataLogbooks?.me?.aircraft[0]?.logbooks?.length && (
            <Box py={1}>
              <Typography
                sx={{
                  color: 'primary.main',
                  fontWeight: 'bold',
                  cursor: 'pointer',
                }}
                onClick={handleClickDefaultLogbook}
              >
                Create default logbooks
              </Typography>
            </Box>
          )}
          <Box py={1}>
            <TextField
              name="maintenanceRecordType"
              label="Maintenance Record Type *"
              value={maintenanceEntryCategoryId}
              onChange={(e) => {
                setMaintenanceEntryCategoryId(e.target.value);
                setRecordTypeError('');
              }}
              error={!!recordTypeError}
              helperText={recordTypeError}
              FormHelperTextProps={{
                sx: {
                  ml: 0,
                },
              }}
              sx={{
                mb: 0,
              }}
              select
              fullWidth
            >
              <MenuItem value="-1">Select record type</MenuItem>
              {dataCategory?.maintenanceEntryCategories?.map((category: any, key: number) => {
                return (
                  <MenuItem key={key} value={category.id}>
                    {category.name}
                  </MenuItem>
                );
              })}
            </TextField>
          </Box>
        </ComponentLoading>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button variant="outlined" onClick={handleClose} disabled={loading || loadingDefaultLogbooks} fullWidth>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="contained" onClick={onSubmit} disabled={loading || loadingDefaultLogbooks} fullWidth>
              Submit
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default CreateLogFromAttachment;
