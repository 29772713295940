import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogContent, DialogTitle, Box, Button, Grid, TextField, MenuItem, Typography } from '@mui/material';
import { Formik, Form } from 'formik';
import { ButtonSubmit } from 'components';

import { useMutation } from '@apollo/client';
import { MUTATION_UPDATE_POST } from 'gql';
import { setAlert } from 'state';

interface EditVisibilityDialogProps {
  openDialog: boolean;
  setOpenDialog: (value: boolean) => void;
  postId: string;
  visibility: string;
  role: string | undefined;
  body: string;
}

const EditVisibilityDialog: React.FC<EditVisibilityDialogProps> = (props) => {
  const { openDialog, setOpenDialog, postId, visibility, role, body } = props;

  const [updatePost, { data: dataUpdatePost, loading: loadingUpdatePost, error: errorUpdatePost }] = useMutation(MUTATION_UPDATE_POST, {
    errorPolicy: 'all',
  });

  const { isSavvy } = useSelector((state: any) => state.auth);
  const [visibilityValue, setVisibilityValue] = useState(visibility);

  const dispatch = useDispatch();

  const handleClose = () => {
    setOpenDialog(false);
  };

  const onSubmit = async () => {
    await updatePost({
      variables: {
        postId,
        body,
        visibility: visibilityValue,
      },
    });
  };

  useEffect(() => {
    if (openDialog) {
      setVisibilityValue(visibility);
    }
  }, [openDialog, visibility]);

  useEffect(() => {
    if (errorUpdatePost) {
      dispatch(setAlert('error', 'Unable to update post visibility'));
    } else if (dataUpdatePost) {
      if (dataUpdatePost.updatePost?.ok) {
        dispatch(setAlert('success', 'Updated Post Visibility'));
        setOpenDialog(false);
      } else {
        dispatch(setAlert('error', dataUpdatePost.updatePost?.error || 'Unable to update post visibility'));
      }
    }
  }, [dataUpdatePost, dispatch, setOpenDialog, errorUpdatePost]);

  return (
    <Dialog
      open={openDialog}
      fullWidth
      onClose={handleClose}
      PaperProps={{
        sx: {
          '@media (max-width: 768px)': {
            width: 'calc(100% - 16px)',
            mx: 1,
          },
          maxWidth: '400px',
        },
      }}
    >
      <DialogTitle
        sx={{
          textAlign: 'center',
          fontSize: '24px',
          fontWeight: 700,
        }}
      >
        Edit Post Visibility
      </DialogTitle>
      <DialogContent>
        {isSavvy && role === 'C' && (
          <Typography
            sx={{
              py: 1,
              color: 'warning.main',
            }}
            align="center"
          >
            Warning: changing visibility of client post
          </Typography>
        )}
        {isSavvy && role !== 'C' && visibility !== 'M' && visibility !== 'M,C' && (
          <Typography
            sx={{
              py: 1,
              color: 'warning.main',
            }}
            align="center"
          >
            You cannot restrict the visibility of a post, you can only expand it. This is because we send email notifications, and thus the
            proverbial cat is already out the bag.
          </Typography>
        )}
        <Formik
          initialValues={{
            visibility: visibility,
          }}
          onSubmit={onSubmit}
        >
          {({ isSubmitting }) => {
            return (
              <Form>
                <Box py={1}>
                  <TextField
                    select
                    name="visibility"
                    label="Visibility"
                    value={visibilityValue}
                    onChange={(e) => {
                      setVisibilityValue(e.target.value);
                    }}
                    fullWidth
                  >
                    {visibility === 'M' && <MenuItem value="M">Savvy Only</MenuItem>}
                    {(visibility === 'M' || visibility === 'M,C') && <MenuItem value="M,C">Savvy and Clients</MenuItem>}
                    <MenuItem value="M,C,T">Everybody</MenuItem>
                  </TextField>
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Button variant="outlined" fullWidth onClick={handleClose} disabled={isSubmitting || loadingUpdatePost}>
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <ButtonSubmit text="Update" loading={isSubmitting || loadingUpdatePost} disabled={isSubmitting || loadingUpdatePost} />
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default EditVisibilityDialog;
