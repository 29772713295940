import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Typography, Box, Button } from '@mui/material';

import { useQuery } from '@apollo/client';
import { DataGridPro, GridValueGetterParams, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid-pro';

import { QUERY_USER_SUBSCRIPTIONS } from 'gql';
import { setAlert } from 'state';

import MoreTimeIcon from '@mui/icons-material/MoreTime';

import { ComponentLoading } from 'components';

const PlanHistory: React.FC = () => {
  const dispatch = useDispatch();

  const { data, loading, error } = useQuery(QUERY_USER_SUBSCRIPTIONS);

  useEffect(() => {
    if (error) {
      dispatch(setAlert('error', 'Unable to load your plan history.'));
    }
  }, [error, dispatch]);

  return (
    <Box
      sx={{
        border: '1px solid',
        borderColor: 'grey.200',
        background: '#FFFFFF',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderBottom: '1px solid',
          borderColor: 'grey.200',
          padding: '20px 0',
          paddingLeft: {
            xs: 2,
            sm: 2.5,
          },
        }}
      >
        <Typography
          variant="h3"
          gutterBottom
          sx={{
            marginBottom: 0,
            lineHeight: '27px',
            color: 'primaryDark',
            fontWeight: 700,
          }}
        >
          Plan History
        </Typography>
        <Button
          variant="outlined"
          sx={{
            marginRight: '20px',
            display: 'none',
          }}
          color="primary"
        >
          <MoreTimeIcon />
          Manage Plans
        </Button>
      </Box>
      <ComponentLoading loading={loading}>
        <Box
          sx={{
            py: {
              xs: 2,
              sm: 0,
            },
            pb: {
              xs: 2,
              sm: 10,
            },
            px: {
              xs: 2,
              sm: 2.5,
            },
          }}
        >
          <Box>
            <DataGridPro
              sx={{
                width: '100%',
                '& .MuiDataGrid-columnHeader': {
                  padding: 0,
                },
                '& .MuiDataGrid-cell': {
                  padding: 0,
                },
                '& .MuiDataGrid-columnSeparator': {
                  display: 'none',
                },
                '& .MuiDataGrid-cell:focus': {
                  outline: 'none',
                },
                '& .MuiDataGrid-row': {
                  cursor: 'pointer',
                  borderBottom: '1px solid',
                  borderColor: 'grey.200',
                },
                '& .MuiDataGrid-columnHeader:focus': {
                  outline: 'none',
                },
                border: 'none',
              }}
              autoHeight
              disableColumnResize={true}
              disableSelectionOnClick={true}
              hideFooter={true}
              showColumnRightBorder={false}
              disableColumnMenu={true}
              rows={data?.me?.subscriptions ? data.me.subscriptions : []}
              columns={[
                {
                  field: 'aircraft',
                  headerName: 'Registration',
                  valueGetter: (params: GridValueGetterParams) => {
                    return params.row.aircraft?.registration ? params.row.aircraft?.registration : '';
                  },
                  width: 90,
                  sortable: false,
                },
                {
                  field: 'serviceName',
                  headerName: 'Service',
                  renderCell: (params: GridRenderCellParams<string>) => {
                    return (
                      <Typography
                        sx={{
                          fontSize: '14px',
                          fontWeight: 700,
                        }}
                        variant="body1"
                        color="textSecondary"
                      >
                        {params.value}
                      </Typography>
                    );
                  },
                  width: 130,
                  sortable: false,
                },
                {
                  field: 'status',
                  headerName: 'Status',
                  renderCell: (params: GridRenderCellParams<string>) => {
                    return (
                      <Typography
                        sx={{
                          fontSize: '14px',
                          fontWeight: 700,
                          color: params.value === 'Active' ? 'success.dark' : 'error.dark',
                        }}
                      >
                        {params.value}
                      </Typography>
                    );
                  },
                  width: 70,
                  sortable: false,
                },
                {
                  field: 'endDate',
                  headerName: 'Expires',
                  valueGetter: (params: GridRenderCellParams) => {
                    return params.row.endDate.toString().substring(0, 10);
                  },
                  width: 80,
                  sortable: false,
                },
              ]}
              onRowClick={(params: GridRowParams) => {
                const { row } = params;
                if (row.aircraft?.managePlansLink) {
                  window.location.href = row.aircraft?.managePlansLink;
                } else {
                  return false;
                }
              }}
            />
          </Box>
        </Box>
      </ComponentLoading>
    </Box>
  );
};

export default PlanHistory;
