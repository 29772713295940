import { IOptionIdName } from 'types';

// Placeholder for Development. Not currently used.
export const OPTIONS_PLACEHOLDER_ID_NAME: IOptionIdName[] = [
  {
    id: '1',
    name: 'Option 1',
  },
  {
    id: '2',
    name: 'Option 2',
  },
  {
    id: '3',
    name: 'Option 3',
  },
];

export const UPLOAD_STATUS_PROCESSING = 'Processing';
export const UPLOAD_STATUS_SUCCESS = 'Success';
export const UPLOAD_STATUS_ERROR = 'Error';
