import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button } from '@mui/material';
import { useMutation } from '@apollo/client';
import { MUTATION_CREATE_TECHNICAL_SUPPORT_TAG } from 'gql';
import { setAlert } from 'state';

interface TechnicalSupportTagProps {
  handleClose: () => void;
  setLoading: (value: boolean) => void;
  ticketId: string;
}

const TechnicalSupportTag: React.FC<TechnicalSupportTagProps> = (props) => {
  const { ticketId, setLoading, handleClose } = props;

  const [
    createTechnicalSupportTag,
    { data: dataTechnicalSupportTag, error: errorTechnicalSupportTag, loading: loadingTechnicalSupportTag },
  ] = useMutation(MUTATION_CREATE_TECHNICAL_SUPPORT_TAG);
  const dispatch = useDispatch();

  const onSubmit = async () => {
    await createTechnicalSupportTag({
      variables: {
        ticketId,
      },
    });
  };

  useEffect(() => {
    if (errorTechnicalSupportTag) {
      dispatch(setAlert('error', 'Unable to create technical support tag'));
    } else if (dataTechnicalSupportTag && dataTechnicalSupportTag.createTechnicalSupportTag?.ok) {
      dispatch(setAlert('success', 'Created technical support tag'));
      handleClose();
    }
  }, [errorTechnicalSupportTag, dataTechnicalSupportTag, dispatch, handleClose]);

  useEffect(() => {
    setLoading(loadingTechnicalSupportTag);
  }, [loadingTechnicalSupportTag, setLoading]);

  return (
    <React.Fragment>
      <Box
        sx={{
          textAlign: 'right',
        }}
        py={1}
      >
        <Button
          variant="outlined"
          sx={{
            width: '100px',
            mr: 2,
          }}
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          sx={{
            width: '100px',
          }}
          onClick={() => {
            onSubmit();
          }}
        >
          Create
        </Button>
      </Box>
    </React.Fragment>
  );
};

export default TechnicalSupportTag;
