import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Dialog, DialogTitle, DialogContent, DialogActions, Box, TextField, MenuItem, Grid, Button, Typography } from '@mui/material';
import { ICohort, IDropDownOption } from 'types';
import { ComponentLoading, Checkbox } from 'components';

import { QUERY_AIRCRAFT_MANUFACTURERS, QUERY_ENGINE_MANUFACTURER, QUERY_ENGINE_PARAMETERS, MUTATION_CREATE_COHORT } from 'gql';
import { useLazyQuery, useMutation } from '@apollo/client';

import { setAlert } from 'state';

interface CreateCohortDialogProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  parentCohorts: ICohort[];
  setSuccess?: (value: boolean) => void;
}

const CreateCohortDialog: React.FC<CreateCohortDialogProps> = (props) => {
  const { open, setOpen, parentCohorts, setSuccess } = props;

  const [
    fetchAircraftManufacturers,
    { data: dataAircraftManufacturers, loading: loadingAircraftManufacturers, error: errorAircraftManufacturers },
  ] = useLazyQuery(QUERY_AIRCRAFT_MANUFACTURERS, {
    fetchPolicy: 'cache-and-network',
  });
  const [
    fetchEngineManufacturers,
    { data: dataEngineManufacturers, loading: loadingEngineManufacturers, error: errorEngineManufacturers },
  ] = useLazyQuery(QUERY_ENGINE_MANUFACTURER, {
    fetchPolicy: 'cache-and-network',
  });
  const [fetchEngineParameters, { data: dataEngineParameters, loading: loadingEngineParameters, error: errorEngineParameters }] =
    useLazyQuery(QUERY_ENGINE_PARAMETERS, {
      fetchPolicy: 'cache-and-network',
    });

  const [createCohort, { data: dataCohort, loading: loadingCohort, error: errorCohort }] = useMutation(MUTATION_CREATE_COHORT);

  const dispatch = useDispatch();

  const [aircraftModelId, setAircraftModelId] = useState('');
  const [aircraftModelError, setAircraftModelError] = useState('');
  const [engineModelId, setEngineModelId] = useState('');
  const [engineModelError, setEngineModelError] = useState('');
  const [active, setActive] = useState(false);
  const [engineParametersId, setEngineParametersId] = useState('');
  const [engineParametersError, setEngineParametersError] = useState('');
  const [parentCohortId, setParentCohortId] = useState('');

  const [aircraftModelList, setAircraftModelList] = useState<IDropDownOption[]>([]);
  const [engineModelList, setEngineModelList] = useState<IDropDownOption[]>([]);

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async () => {
    if (!aircraftModelId) {
      setAircraftModelError('Please select aircraft model');
      return;
    }

    if (!engineModelId) {
      setEngineModelError('Please select engine model');
      return;
    }

    if (!engineParametersId) {
      setEngineParametersError('Please select engine parameters');
      return;
    }

    await createCohort({
      variables: {
        aircraftModelId,
        engineModelId,
        engineParametersId,
        parentCohortId,
        active,
      },
    });
  };

  useEffect(() => {
    if (open) {
      fetchAircraftManufacturers();
      fetchEngineManufacturers();
      fetchEngineParameters();
    }
  }, [open, fetchAircraftManufacturers, fetchEngineManufacturers, fetchEngineParameters]);

  useEffect(() => {
    if (open) {
      setAircraftModelId('');
      setAircraftModelError('');
      setEngineModelId('');
      setEngineModelError('');
      setEngineParametersId('');
      setEngineParametersError('');
      setActive(false);
      setParentCohortId('');
    }
  }, [open]);

  useEffect(() => {
    if (errorAircraftManufacturers) {
      dispatch(setAlert('error', 'Unable to load aircraft models'));
    } else if (dataAircraftManufacturers?.aircraftManufacturer?.length) {
      const tmp: IDropDownOption[] = [];
      dataAircraftManufacturers?.aircraftManufacturer?.map((item: any) => {
        item?.aircraftmodelSet?.map((modelItem: IDropDownOption) => {
          tmp.push({
            id: modelItem.id,
            name: item.name + ': ' + modelItem.name,
          });

          return null;
        });

        return null;
      });

      setAircraftModelList([...tmp]);
    }
  }, [errorAircraftManufacturers, dispatch, dataAircraftManufacturers]);

  useEffect(() => {
    if (errorEngineManufacturers) {
      dispatch(setAlert('error', 'Unable to load engine models'));
    } else if (dataEngineManufacturers?.engineManufacturer?.length) {
      const tmp: IDropDownOption[] = [];
      dataEngineManufacturers?.engineManufacturer?.map((item: any) => {
        item?.enginemodelSet?.map((modelItem: IDropDownOption) => {
          tmp.push({
            id: modelItem.id,
            name: item.name + ': ' + modelItem.name,
          });

          return null;
        });
        return null;
      });

      setEngineModelList([...tmp]);
    }
  }, [errorEngineManufacturers, dispatch, dataEngineManufacturers]);

  useEffect(() => {
    if (errorEngineParameters) {
      dispatch(setAlert('error', 'Unable to load engine parameters'));
    }
  }, [errorEngineParameters, dispatch]);

  useEffect(() => {
    if (errorCohort) {
      dispatch(setAlert('error', 'Unable to create cohort'));
    } else if (dataCohort) {
      if (dataCohort.createCohort?.ok) {
        dispatch(setAlert('success', 'Created new cohort'));
        setOpen(false);
        setSuccess && setSuccess(true);
      } else {
        dispatch(setAlert('error', dataCohort.createCohort?.error || 'Unable to create cohort'));
      }
    }
  }, [errorCohort, dataCohort, dispatch, setOpen, setSuccess]);

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={handleClose}
      PaperProps={{
        sx: {
          maxWidth: '400px',
        },
      }}
    >
      <DialogTitle
        sx={{
          textAlign: 'center',
          fontSize: '24px',
          fontWeight: 700,
        }}
      >
        Create Cohort
      </DialogTitle>
      <DialogContent>
        <ComponentLoading loading={loadingAircraftManufacturers || loadingEngineManufacturers || loadingEngineParameters}>
          <Box py={1}>
            {!!aircraftModelList?.length && (
              <TextField
                select
                name="aircraft_model"
                label="Aircraft Model *"
                value={aircraftModelId}
                onChange={(e) => {
                  setAircraftModelId(e.target.value);
                  setAircraftModelError('');
                }}
                error={!!aircraftModelError}
                helperText={aircraftModelError}
                FormHelperTextProps={{
                  sx: {
                    ml: 0,
                  },
                }}
                sx={{
                  mb: 0,
                }}
                fullWidth
              >
                {aircraftModelList?.map((item: IDropDownOption, key: number) => {
                  return (
                    <MenuItem value={item.id} key={key}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </TextField>
            )}
          </Box>
          <Box py={1}>
            {!!engineModelList?.length && (
              <TextField
                select
                name="engine_model"
                label="Engine Model *"
                value={engineModelId}
                onChange={(e) => {
                  setEngineModelId(e.target.value);
                  setEngineModelError('');
                }}
                error={!!engineModelError}
                helperText={engineModelError}
                FormHelperTextProps={{
                  sx: {
                    ml: 0,
                  },
                }}
                sx={{
                  mb: 0,
                }}
                fullWidth
              >
                {engineModelList?.map((item: IDropDownOption, key: number) => {
                  return (
                    <MenuItem value={item.id} key={key}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </TextField>
            )}
          </Box>
          <Box py={1}>
            {!!dataEngineParameters?.engineParameters?.length && (
              <TextField
                select
                name="engine_parameters"
                label="Engine Parameters *"
                value={engineParametersId}
                onChange={(e) => {
                  setEngineParametersId(e.target.value);
                  setEngineParametersError('');
                }}
                error={!!engineParametersError}
                helperText={engineParametersError}
                FormHelperTextProps={{
                  sx: {
                    ml: 0,
                  },
                }}
                sx={{
                  mb: 0,
                }}
                fullWidth
              >
                {dataEngineParameters?.engineParameters?.map((item: IDropDownOption, key: number) => {
                  return (
                    <MenuItem value={item.id} key={key}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </TextField>
            )}
          </Box>
          <Box py={1}>
            <TextField
              select
              name="parent_cohort"
              label="Parent Cohort"
              value={parentCohortId}
              onChange={(e) => {
                setParentCohortId(e.target.value);
              }}
              sx={{
                mb: 0,
              }}
              fullWidth
            >
              {parentCohorts?.map((item: ICohort, key: number) => {
                return (
                  <MenuItem value={item.id} key={key}>
                    Cohort #{item.id}
                  </MenuItem>
                );
              })}
            </TextField>
          </Box>
          <Box py={1}>
            <Checkbox
              value={active}
              checked={active}
              label={
                <Typography
                  sx={{
                    display: 'inline-block',
                  }}
                >
                  Active?
                </Typography>
              }
              onChange={() => {
                setActive(!active);
              }}
              noMargin
            />
          </Box>
        </ComponentLoading>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button variant="outlined" onClick={handleClose} disabled={loadingCohort} fullWidth>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="contained" onClick={onSubmit} disabled={loadingCohort} fullWidth>
              Submit
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default CreateCohortDialog;
