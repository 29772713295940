import {
  SET_FLIGHT_TABLE_CONFIG,
  SET_TABLE_FLIGHT_FILTER,
  SET_TABLE_FLIGHT_SORT,
  SET_TABLE_FLIGHT_PAGE,
  SET_TABLE_FLIGHT_HIDE_SHORT,
} from 'state/types';

// Initial state (user data)
const initialState = {
  rowsPerPage: 25,
  flightFilter: {
    items: [],
  },
  sortModel: [],
  page: 0,
  hideShortFlights: true,
};

// Reducer
const flightTableReducer = (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    // Login
    case SET_FLIGHT_TABLE_CONFIG:
      return {
        ...state,
        ...payload,
      };
    case SET_TABLE_FLIGHT_FILTER:
      return {
        ...state,
        flightFilter: {
          ...payload.filterModel,
        },
      };
    case SET_TABLE_FLIGHT_SORT:
      return {
        ...state,
        sortModel: [...payload.sortModel],
      };
    case SET_TABLE_FLIGHT_PAGE:
      return {
        ...state,
        page: payload.page,
      };
    case SET_TABLE_FLIGHT_HIDE_SHORT:
      return {
        ...state,
        hideShortFlights: payload.hideShortFlights,
      };
    default:
      return state;
  }
};

export default flightTableReducer;
